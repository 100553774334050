import React, { useRef, useEffect, useState } from 'react';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import useFetch from '../../../hooks/useFetch';
import { connect } from 'react-redux';
import DatasetPicker from '../actions/DatasetPicker';
import { EVENTS_API_URL } from '../../../config';
import { authFetch } from '../../../auth';
import { logInfoNotification, logErrorNotification } from '../../../actions/log';
import { replace } from "redux-first-history";
import Loading from '../../shared/Loading';
import Modal from '../../shared/Modal';
import AuditInfo from '../../shared/AuditInfo';
import { getValueWithFallback } from '../../../utility/property-utlility';
import { useParams } from 'react-router';
import PathNavLink from '../../shared/PathNavLink';

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUrl(key) {
      dispatch(replace(key));
    },
    logInfoNotification(message) {
      dispatch(logInfoNotification(message));
    },
    logErrorNotification(message) {
      dispatch(logErrorNotification(message));
    }
  };
};

function Editor({ viewConfiguration, id, setId, logInfoNotification, logErrorNotification, updateUrl, name, isEnabled, actionType, operationType, sourceKeys, datasets, parameters, eventParameters, reloadData, auditInfo }) {
  const [isDirty, setIsDirty] = useState(false);
  const [nameText, setNameText] = useState(name);
  const [isEnabledState, setIsEnabledState] = useState(isEnabled);
  const [actionTypeText, setActionTypeText] = useState(actionType);
  const [operationTypeText, setOperationTypeText] = useState(operationType);
  const [parametersText, setParametersText] = useState(parameters);
  const [eventParametersText, setEventParametersText] = useState(eventParameters);
  const [datasetOptions, setDatasetOptions] = useState(datasets);
  const [sourceKeyOptions, setSourceKeyOptions] = useState(sourceKeys);
  const [newSourceKeyText, setNewSourceKeyText] = useState("");
  const [newSourceIdText, setNewSourceIdText] = useState("");
  const modalRef = useRef();
  
  const [currentViewConfig, setCurrentViewConfig] = useState(getValueWithFallback(viewConfiguration, actionTypeText));

  useEffect(() => setCurrentViewConfig(getValueWithFallback(viewConfiguration, actionTypeText)), [actionTypeText, viewConfiguration]);

  useDidUpdateEffect(() => {
    setIsDirty(true);
  }, [nameText, isEnabledState, actionTypeText, parametersText, eventParametersText]);

  function onAddSourceKey(e, t) {
    e.preventDefault();
    if (!newSourceKeyText || !newSourceIdText || newSourceKeyText.trim().length === 0 || newSourceIdText.trim().length === 0)
      return;

    if (sourceKeyOptions.some(s => s.source === newSourceKeyText && s.id === newSourceIdText))
      return;

    setSourceKeyOptions([...sourceKeyOptions, {
      source: newSourceKeyText.trim(),
      id: newSourceIdText.trim()
    }]);

    setNewSourceIdText('');
    setIsDirty(true);
  }

  function onRemoveSourceKey(e, s) {
    e.preventDefault();
    setSourceKeyOptions(sourceKeyOptions.filter(i => !(i.source === s.source && i.id === s.id)));
    setIsDirty(true);
  }

  function onDelete(e) {
    e.preventDefault();
    modalRef.current.onShow({
      title: 'Delete Inline action',
      message: 'Are you sure you want to delete this action?',
      onApply: () => {
        authFetch(`${EVENTS_API_URL}/actions/inline-datapoints/${id}`, {
          method: 'DELETE'
        })
        .then(() => {
          logInfoNotification(`Inline action "${nameText}" deleted`);
          updateUrl("../../");
        })
        .catch(error => {
          logErrorNotification(error);
        });
      }
    });
  }

  function onSave(e) {
    e.preventDefault();

    const saveData = {
      id: id,
      name: nameText,
      isEnabled: isEnabledState,
      actionType: actionTypeText,
      operationType: operationTypeText,
      sourceKeys: sourceKeyOptions,
      datasetIds: datasetOptions.map(d => d.id),
      parameters: parametersText && JSON.parse(parametersText),
      eventParameters: eventParametersText && JSON.parse(eventParametersText)
    };

    if (saveData.id === undefined) {
      authFetch(`${EVENTS_API_URL}/actions/inline-datapoints`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(saveData)
      })
      .then(response => response.json())
      .then(key => {
        logInfoNotification(`Inline action "${saveData.name}" created`);
        setId(key.actionId);
        updateUrl(`${key.actionId}/edit`);
        setIsDirty(false);
      })
      .catch(error => {
        logErrorNotification(error);
      });
      return;
    }

    authFetch(`${EVENTS_API_URL}/actions/inline-datapoints/${saveData.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(saveData)
    })
    .then(response => response.json())
    .then(key => {
      logInfoNotification(`Inline action "${saveData.name}" saved`);
      setIsDirty(false);
      reloadData();
    })
    .catch(error => {
      logErrorNotification(error);
    });
  };

  return (
    <div className='container-fluid'>
      <form className='my-3' noValidate >
        <div className='form-row'>
          <div className='col-md-4'>
            {id > 0 ? <div>Edit Inline Action [id:{id}]</div> : <div>New Inline Action</div>}
          </div>
          <div className='col-md-8 d-flex align-content-center justify-content-end'>
            <div className='btn-toolbar'>
              {/* <button type='button' className={`ml-1 btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={onSave}>
                <i className='fas fa-arrow-left fa-fw' />
                <span className='ml-8'>Close</span>
              </button> */}
            </div>
          </div>
        </div>
      </form>
      <div className='row'>
        <div className='col-md-8 col-lg-5'>
          <div className='form-group'>
            <label className='font-weight-bold'>Name</label>
            <input className='form-control' type='text' value={nameText} onChange={e => setNameText(e.target.value)} />
          </div>

          <div className='form-group'>
            <label className='font-weight-bold'>Action Type</label>
            <select className='form-control'
              value={actionTypeText}
              onChange={e => setActionTypeText(e.target.value)}>
              {Object.keys(viewConfiguration).map(k => <option key={k} value={viewConfiguration[k].actionType}>{viewConfiguration[k].text}</option>)}
            </select>
          </div>

          <div className='form-group'>
            <div className='form-check d-inline-block'>
              <input className='form-check-input' type='checkbox' value='' id='is-enabled' checked={isEnabledState} onChange={e => setIsEnabledState(e.target.checked)} />
              <label className='form-check-label font-weight-bold' htmlFor='is-enabled'>Is Enabled</label>
            </div>
          </div>

          <div className='form-group'>
            <label className='font-weight-bold'>Operation Type</label>
            <select className='form-control'
              value={operationTypeText}
              onChange={e => setOperationTypeText(e.target.value)}>
              {Object.keys(currentViewConfig.operationTypes).map(k => <option key={k} value={k}>{currentViewConfig.operationTypes[k].text}</option>)}
            </select>
          </div>

          <div className='form-group'>
            <label className='font-weight-bold'>Source keys</label>
            <table className='table table-sm col-md-12'>
              <thead>
                <tr className='bg-light'>
                  <th>Source Name</th>
                  <th>Source Id</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input value={newSourceKeyText} onChange={e => setNewSourceKeyText(e.target.value)} placeholder='Key, eg: Pointconnect' className='form-control' type='text' />
                  </td>
                  <td>
                    <input value={newSourceIdText} onChange={e => setNewSourceIdText(e.target.value)} placeholder='Id, eg:113002342' className='form-control' type='text' />
                  </td>
                  <td>
                    <button type='button' className='btn btn-sm' onClick={e => onAddSourceKey(e)}>
                      <i className='fas fa-plus fa-fw' />
                    </button>
                  </td>
                </tr>
                {sourceKeyOptions.map(d => (
                  <tr key={d.id}>
                    <td className='text-nowrap w-25'>{d.source}</td>
                    <td className='w-100'>{d.id}</td>
                    <td >
                      <button type='button' className='btn btn-sm' onClick={e => onRemoveSourceKey(e, d)}>
                        <i className='fas fa-trash fa-fw' />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
          <label className='font-weight-bold'>Datasets</label>
            <DatasetPicker datasetOptions={datasetOptions} setDatasetOptions={setDatasetOptions} setIsDirty={setIsDirty} />
          </div> 

          {currentViewConfig.showParameters && <div className='form-group'>
            <label className='font-weight-bold'>Parameters</label>
            <button type='button' className='btn btn-sm float-right'
              title="Use default parameters"
              onClick={e => { setParametersText(JSON.stringify(currentViewConfig.operationTypes[operationTypeText].parameters, undefined, 2)); }}>
              <i className='fas fa-sort-amount-down fa-fw' />
            </button>
            <textarea className='form-control' rows='5' value={parametersText} onChange={e => setParametersText(e.target.value)} />
          </div>}

          {currentViewConfig.showEventParameters && <div className='form-group'>
            <label className='font-weight-bold'>Event Parameters</label>
            <button type='button' className='btn btn-sm float-right'
              title="Use default event parameters"
              onClick={e => { setEventParametersText(JSON.stringify(currentViewConfig.eventParameters, undefined, 2)); }}>
              <i className='fas fa-sort-amount-down fa-fw' />
            </button>
            <textarea className='form-control' rows='5' value={eventParametersText} onChange={e => setEventParametersText(e.target.value)} />
          </div>}

          <div className='d-flex flex-row w-100'>
            <div className='flex-fill form-group'>
              <PathNavLink className='btn btn-outline-secondary' to={ `/actions/` }>Return to actions</PathNavLink>
            </div>
            <div className='form-group'>
              {id === 'new' ? null :
                <button type='button' className='btn btn-secondary' onClick={onDelete}>
                  <i className='fas fa-trash fa-fw' />
                  <span className='ml-8'>Delete</span>
                </button>}
              <button type='button' className={`ml-1 btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={onSave}>
                <i className='fas fa-save fa-fw' />
                <span className='ml-8'>Save</span>
              </button>
            </div>
          </div>

          {auditInfo && <div className='form-group'>
            <AuditInfo owner={auditInfo.owner} createdDateUtc={auditInfo.createdUtc} updatedBy={auditInfo.updatedBy} updatedDateUtc={auditInfo.updatedUtc}/>
          </div>}

        </div>
      </div>
      <Modal ref={modalRef} />
    </div>
  );
}

const InlineDataPointsActionEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ logInfoNotification, logErrorNotification, updateUrl }) => {
  const params = useParams();
  const [id, setId] = useState(params.id);
  const [getActionUrl, setActionUrl] = useState('');
  const [getTimeseriesUrl, setTimeseriesUrl] = useState('');
  const [getDatasetsUrl, setDatasetsUrl] = useState('');
  const [, actionData, actionDataLoading] = useFetch(getActionUrl);
  const [, timeseriesData, timeseriesDataLoading] = useFetch(getTimeseriesUrl);
  const [, datasetsData, datasetsDataLoading] = useFetch(getDatasetsUrl);  
  const [, configData, configLoading] = useFetch(`${EVENTS_API_URL}/actions/inline-datapoints/config`);

  useEffect(() => {
    if (id !== undefined) {
      setActionUrl(`${EVENTS_API_URL}/actions/inline-datapoints/${id}`);
      setDatasetsUrl(`${EVENTS_API_URL}/actions/${id}/datasets`);
      setTimeseriesUrl(`${EVENTS_API_URL}/actions/inline-datapoints/${id}/sourcekeys`);
     }
  }, [id]);

  function reloadData(){
    setActionUrl(`${EVENTS_API_URL}/actions/inline-datapoints/${id}?${ Date.now()}`);
  }

  function camelize(str) {
    return `${str[0].toLowerCase()}${str.slice(1)}`
  }

  if (id === undefined) {
    return <Loading isLoading={configLoading || actionDataLoading || datasetsDataLoading|| timeseriesDataLoading}>
      {configData && <Editor
        viewConfiguration={configData}
        id={id}
        setId={setId}
        name={""}
        isEnabled={true}
        actionType={"inlineDataPoint"}
        operationType={"absoluteValueClean"}
        sourceKeys={[]}
        datasets={[]}
        parameters={JSON.stringify(configData['inlineDataPoint'].operationTypes["absoluteValueClean"].parameters || {}, undefined, 2)}
        eventParameters={JSON.stringify(configData['inlineDataPoint'].eventParameters || {}, undefined, 2)}
        logInfoNotification={logInfoNotification}
        logErrorNotification={logErrorNotification}
        reloadData={reloadData}
        updateUrl={updateUrl} />}
    </Loading>;
  }

  return <Loading isLoading={configLoading || actionDataLoading  || datasetsDataLoading|| timeseriesDataLoading}>
    {configData && actionData && datasetsData && timeseriesData && <Editor
      viewConfiguration={configData}
      id={id}
      name={actionData.name}
      isEnabled={actionData.isEnabled}
      actionType={actionData.actionType}
      operationType={camelize(actionData.operationType)}
      sourceKeys={timeseriesData}
      datasets={datasetsData}
      parameters={JSON.stringify(actionData.parameters || {}, undefined, 2)}
      eventParameters={JSON.stringify(actionData.eventParameters || {}, undefined, 2)}
      logInfoNotification={logInfoNotification}
      logErrorNotification={logErrorNotification}
      updateUrl={updateUrl}
      reloadData={reloadData}
      auditInfo={{
        createdUtc: actionData.createdUtc,
        owner: actionData.owner,
        updatedBy: actionData.updatedBy,
        updatedUtc: actionData.updatedUtc
      }} />}
  </Loading>;
});

export default InlineDataPointsActionEdit;