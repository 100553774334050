import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types'
import Loading from '../../shared/Loading';
import { SchemaSelectorConnected } from './SchemaSelectorConnected';
import { PaginationConnected } from './PaginationConnected';
import { SchemaFacetsConnected } from './SchemaFacetsConnected';
import { ToolbarConnected } from './ToolbarConnected';
import { SearchResultsConnected } from './SearchResultsConnected';
import CompositionTierToggleConnected from '../analysis/AnalysisCompositionTierToggle';
import { Allotment } from 'allotment';

const SCHEMA_FACETS_SIZE = 258;
const PAGER_SIZE = 48;
const RESULS_SIZE = ''; // let is resize automatically
const COMPOSITION_SIZE = 25;

export class Search extends Component {
  static propTypes = {
    stateContext: PropTypes.string.isRequired,
    isSearching: PropTypes.bool.isRequired,
    refreshRequired: PropTypes.bool.isRequired,
    enableExpandFacets: PropTypes.bool.isRequired,
    compositionMaxLevel: PropTypes.number.isRequired,
    schemaSelectorSize: PropTypes.number,
    displaySearchResults: PropTypes.bool.isRequired,
    setSchemaSelectorSize: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    searchHandler: PropTypes.func,
    canShowBusyIndicator: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);
    this.allotmentRef = createRef();
    if (props && props.refreshRequired === true) {
      props.search();
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.allotmentRef.current) {
        const { schemaSelectorSize} = this.props;
        this.allotmentRef.current.resize([schemaSelectorSize ?? SCHEMA_FACETS_SIZE, COMPOSITION_SIZE, RESULS_SIZE, PAGER_SIZE]);
      }
    }, 500); 
  }

  componentDidUpdate(prevProps) {
    if (this.props.refreshRequired === true && prevProps.refreshRequired !== true) {
      this.props.search();
    }
  }

  onPanelResized(panels) {
    this.props.setSchemaSelectorSize(panels[0]);
  }

  render() {
    const { stateContext, search, searchHandler, isSearching, refreshRequired, enableExpandFacets, compositionMaxLevel = 0, schemaSelectorSize, displaySearchResults } = this.props;
    return <Loading isLoading={this.props.canShowBusyIndicator && (isSearching || refreshRequired)} message='Searching...'>
      <div className='fota-cross-schema w-100 h-100 d-flex flex-column'>
        <div className='mb-2'>
          <ToolbarConnected stateContext={stateContext} searchHandler={searchHandler} />
        </div>
        <div className='d-flex' style={{ width: '100%', height: '100%' }}>
          <Allotment ref={this.allotmentRef} vertical={true} defaultSizes={[schemaSelectorSize ?? SCHEMA_FACETS_SIZE, COMPOSITION_SIZE, RESULS_SIZE, PAGER_SIZE]} proportionalLayout={false} onDragEnd={panels => this.onPanelResized(panels)} >
            <Allotment.Pane visible={enableExpandFacets} preferredSize={schemaSelectorSize}>
              {enableExpandFacets && <div className='d-flex flex-row w-100 h-100'>
                <div className='flex-fill d-flex flex-row w-100'>
                  <div style={{ width: '180px', overflowY: 'auto', overflowX: 'hidden' }}>
                    <SchemaSelectorConnected stateContext={stateContext} searchHandler={searchHandler} />
                  </div>

                  <div data_id='search-result-facets' className='ml-3' style={{ width: 'calc(100% - 180px)', overflow: 'auto' }}>
                    <SchemaFacetsConnected stateContext={stateContext} searchHandler={searchHandler} />
                  </div>
                </div>
              </div>}
            </Allotment.Pane>

            <Allotment.Pane visible={compositionMaxLevel > 1} minSize={COMPOSITION_SIZE} maxSize={COMPOSITION_SIZE} >
              <CompositionTierToggleConnected />
            </Allotment.Pane>

            <Allotment.Pane visible={displaySearchResults}>
              {displaySearchResults && <SearchResultsConnected stateContext={stateContext} />}
            </Allotment.Pane>

            <Allotment.Pane visible={displaySearchResults} minSize={PAGER_SIZE} maxSize={PAGER_SIZE}>
              {displaySearchResults && <div className='py-2 container-fluid'>
                <PaginationConnected search={search} stateContext={stateContext} />
              </div>}
            </Allotment.Pane>
          </Allotment>
        </div>
      </div>
    </Loading>
  }
}