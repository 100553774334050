import React, { forwardRef, useCallback, useState, useEffect } from 'react';
import { Combobox } from 'react-widgets';

const bigLimit = 200;

export default forwardRef(({ containerClassName, className, style, value, title, onChange, onGetData }, focusRef) => {
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [moreMessage, setMoreMessage] = useState('');

  const updateData = useCallback((list, filterValue) => {
    const text = (typeof filterValue === 'string' ? filterValue : value || '').trim().toLowerCase();

    let moreMessage;
    let results = text.length > 0
      ? list.filter(i => i.toLowerCase().indexOf(text) >= 0)
      : [...list];

    if (results.length < bigLimit) moreMessage = `${results.length} of ${list.length} items`;
    else {
      moreMessage = `Top ${bigLimit} filtered of ${list.length} items`;

      results = results.slice(0, bigLimit);
    }

    setDataList(results);
    setMoreMessage(moreMessage);
  }, [value]);

  const onLoadData = useCallback(value => {
    if (!onGetData) return;

    setIsLoading(true);
    onGetData({
      success(results) {
        updateData(results, value);
        setIsLoading(false);
      },
      error() {
        setDataList([]);
        setIsLoading(false);
      }
    });
  }, [updateData, onGetData]);

  const onValueSelected = useCallback(value => onChange(value), [onChange]);
  const onValueChanged = useCallback(value => {
    onChange(value);
    onLoadData(value);
  }, [onLoadData, onChange]);

  useEffect(() => {
    if (!!focusRef) onLoadData();
  }, [focusRef, onLoadData]);

  return <>
      <Combobox ref={ focusRef } autoFocus={ !!focusRef } suggest={ false } caseSensitive={ false } autoComplete={ false }
                messages={ {
                  emptyList: isLoading ? 'Searching for items...' : 'There are no items in this list'
                } }
                busy={ isLoading }
                data={ dataList }
                value={ value }
                className={ className }
                containerClassName={ containerClassName }
                style={ style }
                onChange={ onValueChanged }
                onSelect={ onValueSelected }
                onToggle={ onLoadData }
                groupComponent={ () => !!moreMessage && <span>{ moreMessage }</span> }
                groupBy={ () => '' } 
                title={title}/>  
    </>  
});