import React, { cloneElement } from 'react';
import { TimeSeriesColumnLabel } from './TimeSeriesColumnLabel';
import FormattedNumber, { FormattedNumeral } from '../../shared/FormattedNumber';
import {mapVerticalReportRow, isOnDemand, mapVerticalCellStyles} from '../../../utility/reportstable-utility'

export function ReportsTableVertical({ rows, columns, displayMap, toggleExpand, toggleOnDemand, openChart, getStyle, getSpecialStyle, warnings, errors }) {

    function onOpenChart(e, {id, keys, inputs})
    {
        e.stopPropagation();

        openChart(id, keys, inputs);
    }

    function onToggle(e, {displayChildrenMode, key, level, baseKey})
    {
        e.stopPropagation();

        if (isOnDemand(displayChildrenMode)) toggleOnDemand(key, level, baseKey);
        else if (displayChildrenMode !== 'Loading') toggleExpand(key, level, baseKey);
        else e.preventDefault();
    }

    let mappedRows = [];
    let parentIds = [];
    rows.map((row) => mapVerticalReportRow(mappedRows, row, displayMap, true, parentIds, warnings, errors, 0));

    const cellStyles = mapVerticalCellStyles(columns, mappedRows, getStyle, getSpecialStyle);

    return (<div className="px-1"><table className={`table table-sm table-hover table-bordered`}>
        <thead>
            <tr key='k-dummy' className={'bg-light sticky-header'}>
                <th className="text-nowrap align-top sticky-cell" style={{userSelect: "none"}}>
                    {cloneElement(columns[0].rightComponent)}
                </th>
                {mappedRows.map((r) => (TimeSeriesHeader(r, onToggle, onOpenChart)))}
                <th className="border-bottom-0 border-top-0" style={{width: "100%"}}></th>
            </tr>
        </thead>
        <tbody>
            { columns.slice(1).map((c, cx) => (
            <tr key={cx}>
                <td className="font-weight-bold sticky-cell" style={c.style}>
                   {c.title}
                </td>
                { mappedRows.map((r, rx) => <TableCell key={rx} r={r} cx={cx} rx={rx} cellStyles={cellStyles}/>) }
                <td></td>
              </tr>
                )) }
        </tbody>
      </table>
    </div>);
}

function TableCell({r, rx, cx, cellStyles}){
    const {cssStyles, specialisedStyles} = cellStyles[cx][rx];

    return <td key={rx} className="text-right pr-1" style={cssStyles}>
        <span>
            { specialisedStyles.valueFormat
              ? <FormattedNumeral displayFormat={specialisedStyles.valueFormat}>{r.values[cx].value}</FormattedNumeral>
              : <FormattedNumber useGrouping={specialisedStyles.commaSeparated} minDecimals={specialisedStyles.decimalPlaces} maxDecimals={specialisedStyles.decimalPlaces}>{r.values[cx].value}</FormattedNumber> }
        </span>
    </td>
}

function TimeSeriesHeader(timeSeries, onToggle, onOpenChart ) {
  let style = { userSelect: "none", whiteSpace: "pre", padding: "0.1rem", verticalAlign: "top" };
    return <th key={timeSeries.key} style={style}>
        {timeSeries.level > 0 && <div style={{height: `${timeSeries.level*1.5}em`}}>{[...Array(timeSeries.level-1)].map(x => '\n')}┐</div> }
        <div className="d-flex justify-content-between align-items-center">
            <span>
                <TimeSeriesColumnLabel timeSeries={timeSeries} onOpenChart={e => onOpenChart(e, timeSeries.openChartProps)} onToggleExpand={e => onToggle(e, timeSeries.toggleProps)} orientation="Vertical"></TimeSeriesColumnLabel>
            </span>
        </div>
    </th>;
}
