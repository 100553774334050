import React from 'react';
import FormattedDateTime from '../../../shared/FormattedDateTime';

export default function InstanceTable({ instanceRows, toggleSelectionFunction }) {
  return (
    <table className='table table-sm table-striped table-bordered'>
      <thead>
        <tr>
          <th>AsAt (UTC)</th>
          <th>Created (UTC)</th>
          <th>Updated (UTC)</th>
          <th>First DP (UTC)</th>
          <th>Last DP (UTC)</th>
        </tr>
      </thead>
      <tbody>
        {instanceRows.map((x,xi) => {
          return <tr key={xi} onClick={() => toggleSelectionFunction(!x.get('selected') ? x : null) }>
            <td><FormattedDateTime>{x.get('asAtUtc')}</FormattedDateTime></td>
            <td><FormattedDateTime>{x.get('createdUtc')}</FormattedDateTime></td>
            <td><FormattedDateTime>{x.get('updatedUtc')}</FormattedDateTime></td>
            <td><FormattedDateTime>{x.get('firstDataPointUtc')}</FormattedDateTime></td>
            <td><FormattedDateTime>{x.get('lastDataPointUtc')}</FormattedDateTime></td>
            <td className='text-primary' style={{minWidth: 30}}>
              <i className={'fas fa-check-circle fa-lg fa-fw ' + (x.get('selected') === true ? 'visible' : 'invisible')} />
            </td>
          </tr>;
        })}
      </tbody>
    </table>
  );
}