import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_URL } from '../config';
import { toJS } from '../utility/immutable-utility';
import {
  logErrorNotification
} from './log';

export const TIMESERIES_DETAILS_CATEGORIES_ADD = 'TIMESERIES_DETAILS_CATEGORIES_ADD';
export const timeSeriesDetailsCategoriesAdd = createAction(TIMESERIES_DETAILS_CATEGORIES_ADD, 'data');

export const TIMESERIES_DETAILS_CATEGORIES_UPDATE = 'TIMESERIES_DETAILS_CATEGORIES_UPDATE';
export const timeSeriesDetailsCategoriesUpdate = createAction(TIMESERIES_DETAILS_CATEGORIES_UPDATE, 'data');

export const TIMESERIES_DETAILS_CATEGORIES_DELETE = 'TIMESERIES_DETAILS_CATEGORIES_DELETE';
export const timeSeriesDetailsCategoriesDelete = createAction(TIMESERIES_DETAILS_CATEGORIES_DELETE, 'name');

export const TIMESERIES_DETAILS_CATEGORIES_DELETE_ALL = 'TIMESERIES_DETAILS_CATEGORIES_DELETE_ALL';
export const timeSeriesDetailsCategoriesDeleteAll = createAction(TIMESERIES_DETAILS_CATEGORIES_DELETE_ALL);

export const timeSeriesDetailsCategoriesCopyFrom = () => (dispatch, getState) => {
  const state = getState();
  const selectedTimeSeries = toJS((state.getIn(['timeseriesSearch2', 'copyCategoriesSearch', 'selectedTimeSeries']) || ''), []);

  if (selectedTimeSeries.length === 1) {
    const { id } = selectedTimeSeries[0];

    authFetch(`${ANALYSIS_API_URL}/timeseries?id=${id}`)
      .then(response => response.json())
      .then(response => {
        if (response.count === 0) return;
        dispatch(timeSeriesDetailsCategoriesCopyFromComplete(response.categories));
      })
      .catch(console.error);
  }
}

export const TIMESERIES_DETAILS_CATEGORIES_COPY_FROM_COMPLETE = 'TIMESERIES_DETAILS_CATEGORIES_COPY_FROM_COMPLETE';
export const timeSeriesDetailsCategoriesCopyFromComplete = createAction(TIMESERIES_DETAILS_CATEGORIES_COPY_FROM_COMPLETE, 'data');

export const timeSeriesDetailsCategoriesGetCategoryValues = (name) => (dispatch, getState) => {
  if (!name)
    return;

  dispatch(timeSeriesDetailsCategoriesGetCategoryValuesStarted());
  return authFetch(`${ANALYSIS_API_URL}/searchfacets/values?facets=${name}`)
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      const values = data[name] ? data[name].map(l => l.value) : [];
      dispatch(timeSeriesDetailsCategoriesGetCategoryValuesComplete({ name, values }));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
}

export const TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_STARTED = 'TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_STARTED';
export const timeSeriesDetailsCategoriesGetCategoryValuesStarted = createAction(TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_STARTED);

export const TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_COMPLETE = 'TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_COMPLETE';
export const timeSeriesDetailsCategoriesGetCategoryValuesComplete = createAction(TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_COMPLETE, 'data');