import React from 'react';

const groupStyle = { marginBottom: '.5em' };
const labelStyle = { fontSize: '.9em', marginBottom: 0 };

export default function InstanceToolbar({ fromUtc, toUtc, dataPointUtc, refreshFunction, setToolbarPropertyFunction }) {
  return (
      <div className='ml-0 form-row'>
        <div className='form-group ml-1' style={groupStyle}>
          <label className='font-weight-bold text-nowrap' style={labelStyle}>AsAt From (UTC)</label>
          <input className='form-control' type='datetime-local' min="0001-01-01T00:00" max="9999-12-31T23:59"
                 value={fromUtc} onChange={e => setToolbarPropertyFunction('fromUtc', e.target.value)} />
        </div>
        <div className='form-group ml-1' style={groupStyle}>
          <label className='font-weight-bold text-nowrap' style={labelStyle}>AsAt To (UTC)</label>
          <input className='form-control' type='datetime-local' min="0001-01-01T00:00" max="9999-12-31T23:59"
                 value={toUtc} onChange={e => setToolbarPropertyFunction('toUtc', e.target.value)} />
        </div>
        <div className='form-group ml-1' style={groupStyle}>
          <label className='font-weight-bold text-nowrap' style={labelStyle}>DP Date (UTC)</label>
          <input className='form-control' type='datetime-local' min="0001-01-01T00:00" max="9999-12-31T23:59"
                 value={dataPointUtc} onChange={e => setToolbarPropertyFunction('dataPointUtc', e.target.value)} />
        </div>        
        <div className='form-group ml-1 d-flex align-items-end' style={groupStyle}>
          <div className='btn-group btn-group-sm'>
            <button className='btn text-nowrap btn-primary rounded' onClick={e => refreshFunction()}>
              <i className='fas fa-sync-alt fa-fw' />
              <span className='px-2 d-none d-md-inline-block'>Refresh</span>
            </button>
          </div>
        </div>
      </div>

  );
}