import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import { formatDateToIso } from '../utility/date-utility';
import { stringify as qsStringify } from 'querystring';
import {
  logErrorNotification
} from './log';

export const loadInstances = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']);
  const fromUtc = state.getIn(['instanceViewer', 'toolbar', 'fromUtc']);
  const toUtc = state.getIn(['instanceViewer', 'toolbar', 'toUtc']);
  const dataPointUtc = state.getIn(['instanceViewer', 'toolbar', 'dataPointUtc']);
  const page = state.getIn(['instanceViewer', 'instances', 'page']) - 1;  // zero based in the API
  const pageSize = state.getIn(['instanceViewer', 'instances', 'pageSize']);

  const criteria = {
    fromUtc: formatDateToIso(fromUtc),
    toUtc: formatDateToIso(toUtc),
    dataPointUtc: formatDateToIso(dataPointUtc),
    pageNumber: page,
    pageSize: pageSize
  };

  const params = qsStringify(criteria);

  dispatch(instancesLoading(true));
  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/identities/${identityId}/instances?${params}`)
    .then(response => response.json())
    .then(data => {
      dispatch(instancesLoadComplete(data));
      dispatch(instancesLoading(false));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(instancesLoading(false));
    });
};

export const loadInstanceDataPoints = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']);
  const instanceId = state.getIn(['instanceViewer', 'selectedInstance', 'id']);
  if(!instanceId)
  {
    dispatch(instancesDataPointLoadComplete([]));
    return;
  }

  dispatch(instancesLoading(true));
  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/identities/${identityId}/instances/${instanceId}`)
    .then(response => response.json())
    .then(data => {
      dispatch(instancesDataPointLoadComplete(data));
      dispatch(instancesLoading(false));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(instancesLoading(false));
    });
};

export const archiveInstance = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']);
  const instance =  state.getIn(['instanceViewer', 'selectedInstance']);
  if(!instance) return;
  
  const request = {
    identityId: identityId,
    timestamps: [
      instance.get('asAtUtc')
    ]
  };

  dispatch(instancesLoading(true));
  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/instances/archive`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    })
    .then(_ => {
      dispatch(loadInstances());
      dispatch(instancesLoading(false));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(instancesLoading(false));
    });
};

export const promoteInstance = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']);
  const instanceId = state.getIn(['instanceViewer', 'selectedInstance', 'id']);
  if(!instanceId) {
    dispatch(instancesSetPromoteVisible(false));
    return;
  }
  
  const asAtUtc = state.getIn(['instanceViewer', 'instancePromoteAsAtUtc']);
  if(!asAtUtc) {
    dispatch(logErrorNotification('an AsAt must be specified'));
    return;
  }

  const request = {
    identityId: identityId,
    instanceId: instanceId,
    targetAsAtUtc: asAtUtc
  };

  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/instances/promote`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    })
    .then(_ => {
      dispatch(loadInstances());
      dispatch(instancesSetPromoteVisible(false))
    })
    .catch(error => {
      dispatch(logErrorNotification(error));      
      dispatch(instancesSetPromoteVisible(false));
    });  
};

export const INSTANCES_DATA_POINT_LOAD_COMPLETE = 'INSTANCES_DATA_POINT_LOAD_COMPLETE';
export const instancesDataPointLoadComplete = createAction(INSTANCES_DATA_POINT_LOAD_COMPLETE, 'data');

export const INSTANCES_LOAD_COMPLETE = 'INSTANCES_LOAD_COMPLETE';
export const instancesLoadComplete = createAction(INSTANCES_LOAD_COMPLETE, 'data');

export const INSTANCES_LOADING = 'INSTANCES_LOADING';
export const instancesLoading = createAction(INSTANCES_LOADING, 'isLoading');

export const INSTANCES_SET_TOOLBAR_PROPERTY = 'INSTANCES_SET_TOOLBAR_PROPERTY';
export const instancesSetToolbarProperty = createAction(INSTANCES_SET_TOOLBAR_PROPERTY, 'name', 'value');

export const INSTANCES_SET_PAGE = 'INSTANCES_SET_PAGE';
export const instancesSetPage = createAction(INSTANCES_SET_PAGE, 'page');

export const INSTANCES_TOGGLE_SELECTION = 'INSTANCES_TOGGLE_SELECTION';
export const instancesToggleSelection = createAction(INSTANCES_TOGGLE_SELECTION, 'instance');

export const INSTANCES_SET_PROMOTE_VISIBLE = 'INSTANCES_SET_PROMOTE_VISIBLE';
export const instancesSetPromoteVisible = createAction(INSTANCES_SET_PROMOTE_VISIBLE, 'value');

export const INSTANCES_SET_PROMOTED_ASATUTC = 'INSTANCES_SET_PROMOTED_ASATUTC';
export const instancesSetPromotedAsAtUtcFunction = createAction(INSTANCES_SET_PROMOTED_ASATUTC, 'value');