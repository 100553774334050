export const referenceData = {
  isLoading: true,
  isInitialised: {
    sharedLookups: false,
    conversionUnits: false,
    functions: false
  },
  comparisonLenses: {
    none: ['None', 'QuarterHour', 'HalfHour', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year'],
    yearOnYear: ['QuarterHour', 'HalfHour', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year'],
    gasYear: ['QuarterHour', 'HalfHour', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year'],
    gasSeason: ['QuarterHour', 'HalfHour', 'Hour', 'Day', 'Week', 'Month'],
    quarter: ['QuarterHour', 'HalfHour', 'Hour', 'Day', 'Week', 'Month', 'Quarter'],
    monthOnMonth: ['QuarterHour', 'HalfHour', 'Hour', 'Day', 'Week', 'Month'],
    dayOnDay: ['QuarterHour', 'HalfHour', 'Hour', 'Day']
  },
  lenses: ['QuarterHour', 'HalfHour', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year'],
  operations: ['WAvg', 'Avg', 'Min', 'Max', 'Sum', 'Invert'],
  timezones: ['America/New_York', 'UTC', 'Europe/London', 'Europe/Paris', 'Europe/GasDay', 'Asia/Shanghai'],
  granularities: [],
  timeSeriesStyles: ['Derived', 'Simple', 'Forecast', 'ForwardCurve'],
  timeSeriesDataTypes: [],
  timeSeriesInstanceStyles: [],
  categoriesByDataType: {
    fundamental: [],
    price: []
  },
  functions: {},
  searchSchemas: [],
  periods: [
    {
      name: 'Default',
      relFromDate: {
        period: 'D',
        offset: -14
      },
      relToDate: {
        period: 'M',
        offset: 1
      }
    }
  ]
};