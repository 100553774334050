window.activeFetchTimer = null;
window.activeFetchCount = 0;
window.refreshFetchTimer = () => {
  clearTimeout(window.activeFetchTimer);
  window.activeFetchTimer = setTimeout(() => { window.activeFetchTimer = null }, 500);
}
window.networkStatus = () => !window.activeFetchTimer && window.activeFetchCount === 0 ? 'idle' : 'active';

export const fetchEventStart = new Event('fetchEventStart');
window.addEventListener('fetchEventStart', () => {
  window.activeFetchCount++;
  window.refreshFetchTimer();
}, false);

export const fetchEventEnd = new Event('fetchEventEnd');
window.addEventListener('fetchEventEnd', () => {
  window.refreshFetchTimer();
  window.activeFetchCount--;
}, false);
