import React from 'react';
import { formatDate } from '../../../../utility/date-utility';
import UserNameLink from '../../../shared/UserNameLink';
import ConfirmButton from '../../../shared/ConfirmButton';

export default function AdjustmentRangeEditorTableRow({ isWizard, range, isDateOnly, deleteRange, selectRange }) {
  const { id: adjustmentId, periodStart, periodEnd, operation, adjustedValue, type, reason, createdBy: owner, updatedUtc, isHighlighted } = range;

  function onSelectRange(e) {
    e.preventDefault();
    e.stopPropagation();
    selectRange(adjustmentId);
  }

  function onDeleteRange(e) {
    e.preventDefault();
    e.stopPropagation();
    deleteRange(adjustmentId);
  }

  const dateFormat = isDateOnly ? 'DD-MMM-YYYY' : 'DD-MMM-YYYY HH:mm';

  return (
    <tr className={`adjustment-${adjustmentId} cursor-pointer` + (isHighlighted ? ' table-active' : '')} onClick={onSelectRange}>
      <td className='px-0' style={{ verticalAlign: 'middle', textAlign: 'right', fontSize: '10px' }}>
        <i className={'fas fa-check-circle fa-lg text-primary' + (isHighlighted ? ' visible' : ' invisible')} />
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        { formatDate(periodStart, { outFormat: dateFormat }) }
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        { formatDate(periodEnd, { outFormat: dateFormat }) }
      </td>
      <td style={{ verticalAlign: 'middle' }}>{adjustedValue}</td>
      <td style={{ verticalAlign: 'middle' }}>{operation}</td>
      <td style={{ verticalAlign: 'middle' }}>{type}</td>
      <td style={{ verticalAlign: 'middle' }}>
        <div title={reason} style={{ width: '60px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {reason}
        </div>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <UserNameLink>{owner}</UserNameLink>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        { formatDate(updatedUtc, { outFormat: 'DD-MMM-YYYY HH:mm:ss' }) }
      </td>
      {isWizard && (
        <td className='px-0' style={{ textAlign: 'center' }}>
          <ConfirmButton type='button'
            className='btn btn-xs btn-link p-0'
            confirmClassName='btn btn-xs btn-outline-warning p-0'
            onClick={onDeleteRange}
            content={
              <span style={{ whiteSpace: 'nowrap' }}>
                <i className='fas fa-trash fa-fw' />
                <span className='invisible'>?</span>
              </span>
            }
            confirm={
              <span style={{ whiteSpace: 'nowrap' }}>
                <i className='fas fa-trash fa-fw' />
                <span className='visible'>?</span>
              </span>
            }>
          </ConfirmButton>
        </td>
      )}
    </tr>
  );
}