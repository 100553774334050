import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logWarningNotification,
  logErrorNotification
} from './log';

export const TIMESERIES_DETAILS_BASED_ON_UPDATE_SEARCH_KEY_TEXT = 'TIMESERIES_DETAILS_BASED_ON_UPDATE_SEARCH_KEY_TEXT';
export const timeSeriesDetailsBasedOnUpdateSearchKeyText = createAction(TIMESERIES_DETAILS_BASED_ON_UPDATE_SEARCH_KEY_TEXT, 'value');

export const timeSeriesDetailsBasedOnLoad = () => (dispatch, getState) => {
  const state = getState();

  let baseIdentityId = -1;
  const style = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'style']);
  
  if(style === 'Analysis') {
    baseIdentityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'derivationData', 'forecastSeriesData', 'baseIdentityId']);
    const absFromDate = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'derivationData', 'forecastSeriesData', 'asAt','forecastWindowData', 'asAtWindow', 'absFromDate']);
    if(absFromDate) { dispatch(timeSeriesDetailsBasedOnUpdateSettings('fromDateMode', 'abs')); }
    const absToDate = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'derivationData', 'forecastSeriesData', 'asAt','forecastWindowData', 'asAtWindow', 'absToDate']);
    if(absToDate) { dispatch(timeSeriesDetailsBasedOnUpdateSettings('toDateMode', 'abs')); }
  }
  else if(style === 'Evolution') {
    baseIdentityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'derivationData', 'evolutionSeriesData', 'baseIdentityId']);
    const absFromDate = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'derivationData', 'evolutionSeriesData', 'dataPointWindow', 'absFromDate']);
    if(absFromDate) { dispatch(timeSeriesDetailsBasedOnUpdateSettings('fromDateMode', 'abs')); }
    const absToDate = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'derivationData', 'evolutionSeriesData', 'dataPointWindow', 'absToDate']);    
    if(absToDate) { dispatch(timeSeriesDetailsBasedOnUpdateSettings('toDateMode', 'abs')); }
  }

  if (baseIdentityId === -1) return;

  dispatch(timeSeriesDetailsBasedOnAddKeyStarted());
  const customFilter = `id eq '${baseIdentityId}'`;
  return authFetch(`${ANALYSIS_API_ROOT_URL}/v2/timeseriessearch?customFilter=${customFilter}&top=1000`)
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      const [identity] = data.results ?? [];
      if (!identity) {
        dispatch(logWarningNotification(`Time series '${baseIdentityId}' not found.`));
      }
      else {
        dispatch(timeSeriesDetailsBasedOnAddKeyCompleted(identity));
      }
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const timeSeriesDetailsBasedOnAddKey = () => (dispatch, getState) => {
  const state = getState();
  const searchKeyText = (state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'basedOnSettings', 'searchKeyText']) || '').trim();

  if (!searchKeyText) return;

  dispatch(timeSeriesDetailsBasedOnAddKeyStarted());
  const customFilter = `id eq '${searchKeyText}'`;
  return authFetch(`${ANALYSIS_API_ROOT_URL}/v2/timeseriessearch?customFilter=${customFilter}&top=1000`)
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      const [identity] = data.results ?? [];
      if (!identity) {
        dispatch(logWarningNotification(`Time series '${searchKeyText}' not found.`));
      }
      else {
        dispatch(timeSeriesDetailsBasedOnAddKeyCompleted(identity));
      }
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_BASED_ON_ADD_KEY_STARTED = 'TIMESERIES_DETAILS_BASED_ON_ADD_KEY_STARTED';
export const timeSeriesDetailsBasedOnAddKeyStarted = createAction(TIMESERIES_DETAILS_BASED_ON_ADD_KEY_STARTED);

export const TIMESERIES_DETAILS_BASED_ON_ADD_KEY_COMPLETED = 'TIMESERIES_DETAILS_BASED_ON_ADD_KEY_COMPLETED';
export const timeSeriesDetailsBasedOnAddKeyCompleted = createAction(TIMESERIES_DETAILS_BASED_ON_ADD_KEY_COMPLETED, 'data');

export const TIMESERIES_DETAILS_BASED_ON_DELETE_KEY = 'TIMESERIES_DETAILS_BASED_ON_DELETE_KEY';
export const timeSeriesDetailsBasedOnDeleteKey = createAction(TIMESERIES_DETAILS_BASED_ON_DELETE_KEY);

export const TIMESERIES_DETAILS_BASED_ON_UPDATE_SETTINGS = 'TIMESERIES_DETAILS_BASED_ON_UPDATE_SETTINGS';
export const timeSeriesDetailsBasedOnUpdateSettings = createAction(TIMESERIES_DETAILS_BASED_ON_UPDATE_SETTINGS, 'property', 'value')
