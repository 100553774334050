import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import { createReducer } from '../utility/redux-utility';
import {
    LNG_DEALS_ADD,
    LNG_DEALS_EDITED,
    LNG_DEALS_REFRESH_STARTED,
    LNG_DEALS_REFRESH_STOPPED,
    LNG_DEALS_REFRESH_COMPLETE    
} from '../actions/lngDeals';

export const lngDeals = createReducer(null, {
    [LNG_DEALS_ADD](state, action) {
      let deals = toJS(state.get('deals'));
      //deals.push(toJS(state.get('newDeal')));
      deals.splice(0, 0, toJS(state.get('newDeal')));
      return state.set('deals', fromJS(deals));
    },
    [LNG_DEALS_EDITED](state, action) {
        const deals = state.get('deals');        
        const updated = deals.update(
            deals.findIndex(function(item) { 
              return item.get('id') === action.id; 
            }), function(item) {
              return item.set(action.property, action.value)
                         .set('modified', true);
            }
          );  

        return state.set('deals', updated);
    },
    [LNG_DEALS_REFRESH_STARTED](state, action) {
        return state.set('isLoading', true)
                    .set('country', fromJS([]))
                    .set('delivery', fromJS([]))
                    .set('entity', fromJS([]))
                    .set('status', fromJS([]))
                    .set('deals', fromJS([]));
    },
    [LNG_DEALS_REFRESH_STOPPED](state, action) {
      return state.set('isLoading', false);
    },
    [LNG_DEALS_REFRESH_COMPLETE](state, action) {
       return state.set('isInitialised', true)
                   .set('isLoading', false)
                   .set('country', fromJS(action.data.country))
                   .set('delivery', fromJS(action.data.delivery))
                   .set('entity', fromJS(action.data.entity))
                   .set('status', fromJS(action.data.status))
                   .set('deals', fromJS(action.data.deals));
    }    
});