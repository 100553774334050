import React from 'react';
import { Converter } from 'showdown';
import TileCommonSettings from './TileCommonSettings';

export default function MarkdownTileOptions() {
  return {
    Create: (args) => Create(args),
    View: (args) => View(args),
    Edit: (args) => Edit(args),
    Settings: (args) => Settings(args),
  };
}

function Create({ propertyBag, setProperty, executeCreate, cancelCreate, dashboardTileType }) {
  const converter = new Converter({
    openLinksInNewWindow: true,
    simpleLineBreaks: true,
    tables: true
  });

  const html = converter.makeHtml(propertyBag.markdown);

  function onCreateNewTile(e) {
    e.preventDefault();
    executeCreate();
  }

  function onCancelCreateNewTile() {
    cancelCreate();
  }

  return <div className='modal-content' style={{ minWidth: '50em' }}>
    <div className='modal-header'>
      <h5 className='modal-title'>New tile</h5>
    </div>
    <div className='modal-body'>
      <div className='form-group'>
        <TileCommonSettings propertyBag={propertyBag} setProperty={setProperty} dashboardTileType={dashboardTileType} />
      </div>

      <div className='form-group'>
        <label className='font-weight-bold'>Scrolling</label>
        <div>
          <div className='form-check d-inline-block'>
            <input className='form-check-input' type='radio' value='none' name='clipContents-false' id='clipContents-false'
              checked={!propertyBag.clipContents}
              onChange={() => setProperty({ clipContents: false })} />
            <label className='form-check-label' htmlFor='clipContents-false'>Show scroll bars</label>
          </div>

          <div className='form-check d-inline-block ml-3'>
            <input className='form-check-input' type='radio' value='none' name='clipContents-true' id='clipContents-true'
              checked={propertyBag.clipContents}
              onChange={() => setProperty({ clipContents: true })} />
            <label className='form-check-label' htmlFor='clipContents-true'>Clip contents</label>
          </div>
        </div>
      </div>

      <div className='form-group'>
        <div className='form-row'>
          <div className='col-md-7'>
            <label className='font-weight-bold'>Markdown</label>
            <textarea rows="20" cols="50" className='form-control form-control-sm' value={propertyBag.markdown} onChange={e => setProperty({ markdown: e.target.value })} >
            </textarea>
          </div>
          <div className='col-md-5'>
            <label className='font-weight-bold'>Preview</label>
            <div style={{ height: '27em', overflowX: 'auto', overflowY: 'auto', border: 'solid 1px #EEEEEE' }} dangerouslySetInnerHTML={{ __html: html }}></div>
            <a target='_blank' rel='noopener noreferrer' href='https://www.markdownguide.org/cheat-sheet'>Markdown cheetsheet</a>
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <button className='btn btn-sml btn-secondary' onClick={() => onCancelCreateNewTile()}>Cancel</button>
        <button className='btn btn-sml btn-primary' onClick={(e) => onCreateNewTile(e)}>Create</button>
      </div>
    </div>
  </div>
}

function View({ propertyBag, setProperty }) {
  return <></>
}

function Edit({ propertyBag, setProperty }) {
  return <></>
}

function Settings({ propertyBag, setProperty, dashboardTileType }) {
  const converter = new Converter({
    openLinksInNewWindow: true,
    simpleLineBreaks: true,
    tables: true
  });

  const html = converter.makeHtml(propertyBag.markdown);

  return <>
    <div className='form-group'>
      <TileCommonSettings propertyBag={propertyBag} setProperty={setProperty} dashboardTileType={dashboardTileType} />
    </div>

    <div className='form-group'>
      <label className='font-weight-bold'>Scrolling</label>
      <div>
        <div className='form-check d-inline-block'>
          <input className='form-check-input' type='radio' value='none' name='clipContents-false' id='clipContents-false'
            checked={!propertyBag.clipContents}
            onChange={() => setProperty({ clipContents: false })} />
          <label className='form-check-label' htmlFor='clipContents-false'>Show scroll bars</label>
        </div>

        <div className='form-check d-inline-block ml-3'>
          <input className='form-check-input' type='radio' value='none' name='clipContents-true' id='clipContents-true'
            checked={propertyBag.clipContents}
            onChange={() => setProperty({ clipContents: true })} />
          <label className='form-check-label' htmlFor='clipContents-true'>Clip contents</label>
        </div>
      </div>
    </div>

    <div className='form-group'>
      <div className='form-row'>
        <div className='col-md-7'>
          <label className='font-weight-bold'>Markdown</label>
          <textarea rows="20" cols="50" className='form-control form-control-sm' value={propertyBag.markdown} onChange={e => setProperty({ markdown: e.target.value })} >
          </textarea>
        </div>
        <div className='col-md-5'>
          <label className='font-weight-bold'>Preview</label>
          <div style={{ height: '27em', overflowX: 'auto', overflowY: 'auto', border: 'solid 1px #EEEEEE' }} dangerouslySetInnerHTML={{ __html: html }}></div>
          <a target='_blank' rel='noopener noreferrer' href='https://www.markdownguide.org/cheat-sheet'>Markdown cheetsheet</a>
        </div>
      </div>
    </div>
  </>
}