import React from 'react';
import { connect } from 'react-redux';
import { SearchConnected } from '../../analysis-cross-schema-search/SearchConnected';
import { SearchResultsConnected } from '../../analysis-cross-schema-search/SearchResultsConnected';
import { PaginationConnected } from '../../analysis-cross-schema-search/PaginationConnected';
import { DebugPopup } from '../../../shared/DebugPopup';
import InfoPopup from '../../../shared/InfoPopup';
import JsonEditor from '../../../shared/JsonEditor';
import { toJS } from '../../../../utility/immutable-utility';
import { Allotment } from 'allotment';
import Loading from '../../../shared/Loading';
import { MultiselectDropdown } from '../../../shared/MultiselectDropdown';
import ConfirmButton from '../../../shared/ConfirmButton';

export function DynamicWorkspaceEditor({
  addDynamicFilter,
  deleteDynamicFilter,
  previewResultsCount,
  dynamicWorkspaceKey, isBusy, isDirty, categoriesSearch, dynamicRowsSearch,
  dynamicFilters,
  setAlias,
  setCategory, categoryOptions,
  setSelectedValue,
  setIsStrictValue, setStrictValue,
  setIsMultiSelect, setMultiSelectValue,
  onCancel, onSave, onDelete, previousDynamicKeys, substitutionKeyExclusionCategories, toggleExclusionCategory, rebuildExclusions, refreshRows }) {
  const canDelete = dynamicWorkspaceKey !== 'new';

  function AddtionalResultRowDisplay({ data }) {
    let newDymamicKey = data.dynamicKey;
    let previousDynamicKey = undefined;
    if (previousDynamicKeys)
      previousDynamicKey = data.index < previousDynamicKeys.length ? previousDynamicKeys[data.index] : undefined;

    const showPreviousKey = previousDynamicKey && previousDynamicKey !== newDymamicKey;
    const textColor = !previousDynamicKey || previousDynamicKey === newDymamicKey ? '#040' : '#F00';
    return <div className='text-nowrap fota-ellipsis-text' style={{ color: textColor }}>
      {showPreviousKey && <div style={{ color: textColor, opacity: 0.7 }}>{previousDynamicKey}</div>}
      <i className="fas fa-caret-right"></i>{newDymamicKey}
    </div>
  }

  const selectedSubstitutionKeyExclusionCategories = substitutionKeyExclusionCategories ? substitutionKeyExclusionCategories.filter(s => s.isExcluded) : [];
  const isMultiSelect = dynamicFilters ? dynamicFilters.some(d => d.isMultiSelect === true) : false;

  return <div style={{ height: 'calc(100vh - 100px)', display: 'flex', flexDirection: 'column' }}>
    <Loading isLoading={isBusy} message='Refreshing...'>
      <div className='modal-header'>
        <h5 className='modal-title'>Dynamic Workspace</h5>
        <DebugPopup>{() => <DynamicWorkspaceDesignDebug />}</DebugPopup>
        <button type='button' className='close' onClick={onCancel}>&times;</button>
      </div>
      <div className='modal-body' style={{ flex: '1', overflowY: 'auto', paddingBottom: '0px' }}>
        <Allotment vertical={true} proportionalLayout={false}>
          <Allotment.Pane preferredSize={350}>
            <div className='h-100 pb-2'>
              <SearchConnected stateContext={'dynamicWorkspaceDesign'} canShowBusyIndicator={false} searchHandler={categoriesSearch} />
            </div>
          </Allotment.Pane>

          <Allotment.Pane preferredSize={150}>
            <div className='d-flex flex-column h-100 overflow-y-auto'>
              <div className='pt-2'>
                <label className='font-weight-bold'>Excluded categories</label>
                <InfoPopup>
                  Each item in a dynamic workspace has a key. This key is made up of all categories except the filter category. This is used to substitute matching items in the basket when the filter is changed, keeping styles the same. However, it is necessary to remove some categories from key generation in order to generate a usable key. Excluded categories provides this option.
                </InfoPopup>
                <div className='d-flex flex-row'>
                  <div className='flex-fill'>
                    <MultiselectDropdown
                      style={{ maxWidth: 'unset', maxHeight: '30px !important' }}
                      data={substitutionKeyExclusionCategories}
                      dataKey='name'
                      textField='name'
                      onChange={value => toggleExclusionCategory(value)}
                      showRemoveAll={true}
                      immediateSelection={true}
                      value={selectedSubstitutionKeyExclusionCategories}
                    />
                  </div>
                  <div className='ml-2'>
                    <button className='btn btn-secondary' title='Fetch exclusions' type='button' onClick={rebuildExclusions}>Get Exclusions</button>
                  </div>
                </div>
              </div>

              <div className='flex-fill pt-2 d-flex flex-row justify-content-between' >
                <div>
                  <table style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    <thead>
                      <tr>
                        <th>
                          <label className='font-weight-bold'>Alias</label>
                        </th>
                        <th>
                          <label className='font-weight-bold'>Category</label>
                        </th>
                        <th>
                          <label className='font-weight-bold pr-1'></label>
                        </th>
                        <th>
                          <label className='font-weight-bold'>Strict Options</label>
                        </th>
                        <th>
                          <div className='d-flex flex-row justify-content-between'>
                            <label className='font-weight-bold'>{isMultiSelect ? 'Filter values' : 'Filter value'}</label>
                            <div>
                              <input id='set-is-multi-select' type='checkbox' className='xform-check-input' checked={isMultiSelect}
                                onChange={e => setIsMultiSelect(-1, e.target.checked)} />
                              <label htmlFor='set-is-multi-select' className='form-check-label font-weight-bold'>Multiselect</label>
                            </div>
                          </div>
                        </th>
                        <th>
                          {/* actions */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dynamicFilters.map((dynamicFilter, index) => <DynamicFilterEditor
                        key={dynamicFilter.key}
                        dynamicFilter={{ ...dynamicFilter, index }}
                        deleteDynamicFilter={deleteDynamicFilter}
                        setAlias={setAlias}
                        setMultiSelectValue={setMultiSelectValue} setIsMultiSelect={setIsMultiSelect} setSelectedValue={setSelectedValue}
                        setCategory={setCategory} categoryOptions={categoryOptions}
                        setStrictValue={setStrictValue} setIsStrictValue={setIsStrictValue} />
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  <button type='button' className='btn btn-primary' onClick={addDynamicFilter} >Add Filter</button>
                </div>
              </div>
            </div>
          </Allotment.Pane>

          <Allotment.Pane>
            <div className='d-flex flex-column h-100'>
              <div className='d-flex flex-row mt-2'>
                <label className='font-weight-bold flex-fill'>Preview {previewResultsCount > 0 && `(${previewResultsCount} results)`}</label>
                {!isBusy && isDirty && <div className='alert alert-warning py-0 pl-1 pr-3 my-0 mx-2'>
                  <div className='modal-title mt-1 ml-2'>
                    <strong>
                      <i className='fas fa-exclamation-triangle fa-fw' /> Warning
                    </strong>
                    <h6 className='d-inline-block ml-1'> - Your filters have changed and the preview below may differ</h6>
                  </div>
                </div>}
                <button type='button' className={'btn btn-primary'} onClick={refreshRows}>Refresh</button>
              </div>
              <div className='flex-fill overflow-y-auto'>
                <SearchResultsConnected stateContext={'dynamicWorkspaceDesign'} searchHandler={dynamicRowsSearch} displayOptions={{ noFilters: true, canSelectResults: false }}
                  addtionalResultRowDisplay={data => <AddtionalResultRowDisplay data={data} />} />
              </div>
            </div>
          </Allotment.Pane>
        </Allotment>
      </div>
      <div className='modal-footer d-flex flex-row justify-content-between'>
        <div></div>

        <div>
          <PaginationConnected stateContext={'dynamicWorkspaceDesign'} searchHandler={dynamicRowsSearch} />
        </div>

        <div className=''>
          {canDelete && <button type='button' className='btn btn-secondary mr-2' onClick={() => onDelete()}>Delete</button>}
          <button type='button' className='btn btn-secondary mr-1' onClick={onCancel}>Cancel</button>
          <button type='button' className='btn btn-primary' onClick={onSave}>Save</button>
        </div>
      </div>
    </Loading >
  </div >
}

const DynamicWorkspaceDesignDebug = connect(
  (state) => ({
    dynamicWorkspaceDesign: state.getIn(['analysis', 'dynamicWorkspaceDesign'])
  }),
  (dispatch) => ({
  })
)(({ dynamicWorkspaceDesign }) => {
  const _dynamicWorkspaceDesign = toJS(dynamicWorkspaceDesign);
  return <JsonEditor data={_dynamicWorkspaceDesign} />
});

function DynamicFilterEditor({ dynamicFilter, setAlias, categoryOptions, setCategory, setStrictValue, setIsStrictValue, setMultiSelectValue, setIsMultiSelect, setSelectedValue, deleteDynamicFilter }) {
  const {
    index,
    alias,
    selectedCategory,
    strict: isStrict, strictValues, strictOptions = [], strictSelectedValue,
    isMultiSelect, selectedValue, values, multiSelectValues } = dynamicFilter;

  const filterOptions = isStrict ? strictValues : values;

  return <tr>
    <td className='pr-1'>
      <input className='form-control' style={{ minWidth: '160px' }} value={alias} onChange={e => setAlias(index, e.target.value)} />
    </td>

    <td className='pr-1'>
      <select className='form-control' style={{ minWidth: '160px' }} value={selectedCategory}
        onChange={e => setCategory(index, e.target.value)}>
        {!categoryOptions.exists(c => c.name === selectedCategory) && <option></option>}
        {categoryOptions.map((c, ci) => <option key={`cat-${ci}`} value={c.name}>{c.name}</option>)}
      </select>
    </td>

    <td className='pr-1'>
      <div className='form-check'>
        <input id='set-is-strict' type='checkbox' checked={isStrict} title='Is Strict'
          onChange={e => setIsStrictValue(index, e.target.checked)} />
      </div>
    </td>

    <td className='pr-1'>
      <select className='form-control' disabled={isStrict === false} style={{ minWidth: '160px' }} value={strictSelectedValue}
        onChange={e => setStrictValue(index, e.target.value)}>
        {!strictOptions.find(s => s.name === strictSelectedValue) && <option></option>}
        {strictOptions.map((s, si) => <option style={{ color: '#000000' }} key={`sv-${si}`} value={s.name}>{s.name}</option>)}
      </select>
    </td>

    <td className='pr-1'>
      {!isMultiSelect && <select className='form-control' style={{ minWidth: '190px' }} value={selectedValue}
        onChange={e => setSelectedValue(index, e.target.value)}>
        {!filterOptions.exists(c => c.name === selectedValue) && <option></option>}
        {filterOptions.map((c, ci) => <option key={`cat-${ci}`} value={c.name}>{c.name}</option>)}
      </select>}

      {isMultiSelect && <MultiselectDropdown
        style={{ maxWidth: '350px', maxHeight: '34px' }}
        data={filterOptions}
        dataKey='name'
        textField='name'
        showRemoveAll={true}
        onChange={values => setMultiSelectValue(index, values.map(v => v.name))}
        value={multiSelectValues}
      />}
    </td>

    <td>
      <ConfirmButton type='button' className='btn btn-sm btn-link fota-btn-toolbar' confirmClassName='btn btn-sm btn-link btn-warning fota-btn-toolbar fota-color-black'
        onClick={() => deleteDynamicFilter(index)}
        content={<i style={{ color: 'rgba(0, 0, 0, .5)' }} className='fas fa-trash fa-fw' />}
        confirm={<><i style={{ color: 'rgba(0, 0, 0, .5)' }} className='fas fa-trash fa-fw' /><span>?</span></>} />
    </td>
  </tr>
}