import moment from "moment";

export const dashboardTiles = {
  isEditing: false,
  isDirty: false,
  dashboardTilesWorkspaceLoadStatus: '',
  dashboardPath: {
    isEditing: false
  },
  workspace: {},
  criteria: {
    lens: 'Day',
    timezoneId: 'UTC',
    fromDateMode: 'abs',
    toDateMode: 'abs',
    absFromDate: moment.utc().startOf('day').add(-14, 'day').format('YYYY-MM-DDTHH:mm'),
    absToDate: moment.utc().startOf('day').add(1, 'month').format('YYYY-MM-DDTHH:mm'),
    relFromDate: '-2M',
    relToDate: '1D',
    periods: []
  },
  dynamicWorkspaceDropdowns: [
    //   {
    //   filterName : 'location',
    //   options: ['AE', 'DE', 'ES', 'Global'], 
    //   selectedValue : 'DE',
    //   doNotShowInTile : false,
    //   tiles : [/*tileKey*/]
    // }, {
    //   filterName : 'technology',
    //   options: ['Barcelona', 'Bilbao', 'Cartagena', 'Huelva', 'Mugardos'],
    //   selectedValue : '',
    //   doNotShowInTile : false,
    //   tiles : [/*tileKey*/]
    // }
  ],
  dynamicWorkspaceDropdownsRevertState: [],
  tilesConfig: {
    /*
    'guid' : {
        "color": "#303f9f",
        "borderStyle": "none",
        "hideToolbar": false,
        "title": "",
        "width": 0,
        "height": 0,
        "x": 0,
        "y": 0,
        "type": "",
        "dashboardTileType": "",
        "statePath": "",
        "overrideDynamicWorkspace": [
            {
                "key": "dcd61710-1aa6-4696-e5d5-12a2b2bdb05c",
                "filters": [
                    {
                        "filterKey": "d4361c7d-5006-497f-6685-4c9975575344",
                        "value": "Rough",
                        "values" : ["", ""]
                    }
                ]
            }
        ]
    }
    */
  },
  tilesConfigRevertState: {},
  tilesState: {},
  tilesStateRevertState: {},
  dynamicWorkspaceDesign: {
    view: 'selection',
    isSelectingWorkspace: false,
    title: '',
    workspace: undefined,
    workspaceName: '',
    workspaceData: undefined,
    dynamicWorkspace: [],
    combinations:[],
    readingWorkspace: false,
    canGoBack: false,
    canGoNext: false
  },
  dynamicWorkspaceDropdownDesign: {
    isDirty: false,
    dropdowns: [/*dropdown*/]
  },
  workspacePicker: {
    title: '',
    selectedWorkspace: undefined
  },
  editorState: {},
  ui: {
    gridRows: [],
    layouts: [],
    expandedTile: {
      stateKey: undefined,
      dashboardTileType: undefined
    },
    periodsAbs: [],
    periodsUserSettings: [],
    periodsReferenceData: [],
    periodsWorkspaces: [],
    dashboardOptions: {
      useDashboardLens: false,
      useDashboardTimezone: false,
      useDashboardDates: false,
      useDashboardPeriods: false
    }
  },
  options: {
    dashboardWidth: 72
  }
};