import React from 'react';
import { Routes, Route } from 'react-router';
import NavBar from '../shared/NavBar';
import ErrorBoundary from '../shared/ErrorBoundary';
import HideOnScreenCapture, { isScreenCapture } from '../shared/HideOnScreenCapture';
import HomeConnected from './dashboard/HomeConnected';
import Analysis from './analysis/Analysis';
import Reports from './reports/Reports';
import EventsSearch from './events/EventsSearch';
import Datasets from './dataSets/DataSets';
import Functions from './functions/Functions';
import Actions from './actions/Actions';
import UserSettings from './user-settings/UserSettings';
import EtlJob from './etl/EtlJob';
import EtlJobs from './etl/EtlJobs';
import Log from './log/Log';
import '../../styles/app-content.css';

import TimeSeriesDetailsConnected from './timeSeriesDetails/TimeSeriesDetailsConnected';
import DashboardConnected from './dashboard-tiles/DashboardConnected';
import OutagesConnected from './outages/OutagesConnected';
import QueueStatusConnected from './queueStatus/QueueStatusConnected';
import LngDealsConnected from './lng-deals/LngDealsConnected';
import StorageAuctionsConnected from './storage-auctions/StorageAuctionsConnected';
import ScreenCaptureConnected from './screen-capture/ScreenCaptureConnected';

function MainAppContent() {
  const contentStyle = isScreenCapture() ? { paddingTop: 0 } : undefined;
  return (
    <div className='fota-content' style={contentStyle}>
      <HideOnScreenCapture>
        <header className='fota-header'>
          <NavBar />
        </header>
      </HideOnScreenCapture>
      <div className='fota-body h-100'>
        <ErrorBoundary className='container p-md-5'>
          <Routes>
            <Route path='/dashboard/*' element={ <DashboardConnected/> } />
            <Route path='/timeseries/:id/:view?' element={ <TimeSeriesDetailsConnected/> } />
            <Route path='/analysis/*' element={ <Analysis/> } />
            <Route path='/reports/*' element={ <Reports/> } />
            <Route path='/events/*' element={ <EventsSearch/> } />
            <Route path='/power/*' element={ <OutagesConnected/> } />
            <Route path='/datasets/*' element={ <Datasets/> } />
            <Route path='/actions/*' element={ <Actions/> } />
            <Route path='/etl-jobs/*' element={ <EtlJobs/> } />
            <Route path='/etl-job/:id' element={ <EtlJob/> } />
            <Route path='/functions/*' element={ <Functions/> } />
            <Route path='/user-settings/*' element={ <UserSettings/> } />
            <Route path='/log/*' element={ <Log/> } />
            <Route path='/lng-deals/*' element={ <LngDealsConnected/> } />
            <Route path='/storage-auctions/*' element={ <StorageAuctionsConnected stateKey={'default'}/> } />
            <Route path='/queue-status/*' element={ <QueueStatusConnected/> } />
            <Route path='/*' element={ <HomeConnected/> } />
          </Routes>
        </ErrorBoundary>
        <ScreenCaptureConnected />
      </div>
    </div>
  );
};

export default function AppContent() {
  return <MainAppContent/>
};