import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
  ACTIONS_EDITOR_REFRESH_STARTED,
  ACTIONS_EDITOR_REFRESH_COMPLETE,
  ACTIONS_RUN_SCHEDULED_ACTION_STARTED,
  ACTIONS_RUN_SCHEDULED_ACTION_COMPLETE,
  ACTIONS_SET_FILTER,
  ACTIONS_EDITOR_SET_SELECTED_TAB,
  ACTIONS_EDITOR_SET_ONLY_SHOW_MY_ACTIONS,
} from '../actions/actionsEditor';
import { getCurrentUserName } from '../auth';
import { toJS } from '../utility/immutable-utility';

function orderAndFilter(list, onlyShowMyActions, filterText, orderByDirection = 'asc') {
  const userName = getCurrentUserName();
  list = list.sort((a, b) => {
    const valueA = (a.name || '').toLocaleLowerCase();
    const valueB = (b.name || '').toLocaleLowerCase();

    if (valueA < valueB) return orderByDirection === 'desc' ? 1 : -1;
    if (valueA > valueB) return orderByDirection === 'desc' ? -1 : 1;
    return 0;
  });

  if (onlyShowMyActions) {
    list = list.filter(a => a.owner === userName);
  }

  if (filterText) {
    filterText = filterText.toLocaleLowerCase();
    list = list.filter(a => a.name.toLocaleLowerCase().indexOf(filterText) >= 0);
  }

  return list;
}

export const actionsEditor = createReducer(null, {
  [ACTIONS_EDITOR_REFRESH_STARTED](state, action) {
    return state.setIn(['isLoading'], true);
  },
  [ACTIONS_EDITOR_REFRESH_COMPLETE](state, action) {
    if (!action.data)
      return state.setIn(['isLoading'], false);

    let { inlineDataPointsActions, scheduledActions, triggeredActions } = action.data;

    const filter = state.getIn(['filter']);
    const onlyShowMyActions = state.getIn(['onlyShowMyActions']);
    inlineDataPointsActions = orderAndFilter(inlineDataPointsActions, onlyShowMyActions, filter);
    scheduledActions = orderAndFilter(scheduledActions, onlyShowMyActions, filter);
    triggeredActions = orderAndFilter(triggeredActions, onlyShowMyActions, filter);

    return state.setIn(['isLoading'], false)
      .setIn(['unorderedCollections'], fromJS(action.data))
      .setIn(['collections', 'inlineDataPointsActions'], fromJS(inlineDataPointsActions))
      .setIn(['collections', 'scheduledActions'], fromJS(scheduledActions))
      .setIn(['collections', 'triggeredActions'], fromJS(triggeredActions));
  },
  [ACTIONS_EDITOR_SET_SELECTED_TAB](state, action) {
    return state.setIn(['selectedTabIndex'], action.index);
  },
  [ACTIONS_EDITOR_SET_ONLY_SHOW_MY_ACTIONS](state, action) {
    const unorderedCollections = toJS(state.getIn(['unorderedCollections']));
    let { inlineDataPointsActions, scheduledActions, triggeredActions } = unorderedCollections;
    const filter = state.getIn(['filter']);
    const onlyShowMyActions = action.show;
    inlineDataPointsActions = orderAndFilter(inlineDataPointsActions, onlyShowMyActions, filter);
    scheduledActions = orderAndFilter(scheduledActions, onlyShowMyActions, filter);
    triggeredActions = orderAndFilter(triggeredActions, onlyShowMyActions, filter);

    return state
      .setIn(['onlyShowMyActions'], onlyShowMyActions)
      .setIn(['collections', 'inlineDataPointsActions'], fromJS(inlineDataPointsActions))
      .setIn(['collections', 'scheduledActions'], fromJS(scheduledActions))
      .setIn(['collections', 'triggeredActions'], fromJS(triggeredActions));
  },
  [ACTIONS_RUN_SCHEDULED_ACTION_STARTED](state, action) {
    if (!action.id)
      return state;

    const runInfo = {
      isRunning: true,
      name: action.name,
      data: undefined
    };

    return state.setIn(['scheduledRuns', action.id], fromJS(runInfo));
  },
  [ACTIONS_RUN_SCHEDULED_ACTION_COMPLETE](state, action) {
    if (!action.id)
      return state;

    const runInfo = {
      isRunning: false,
      name: action.name,
      data: action.data,
      error: action.error
    };

    return state.setIn(['scheduledRuns', action.id], fromJS(runInfo));
  },
  [ACTIONS_SET_FILTER](state, action) {
    const filter = action.text ?? '';

    const unorderedCollections = toJS(state.getIn(['unorderedCollections']));
    let { inlineDataPointsActions, scheduledActions, triggeredActions } = unorderedCollections;

    const onlyShowMyActions = state.getIn(['onlyShowMyActions']);
    inlineDataPointsActions = orderAndFilter(inlineDataPointsActions, onlyShowMyActions, filter);
    scheduledActions = orderAndFilter(scheduledActions, onlyShowMyActions, filter);
    triggeredActions = orderAndFilter(triggeredActions, onlyShowMyActions, filter);

    return state
      .setIn(['filter'], filter)
      .setIn(['collections', 'inlineDataPointsActions'], fromJS(inlineDataPointsActions))
      .setIn(['collections', 'scheduledActions'], fromJS(scheduledActions))
      .setIn(['collections', 'triggeredActions'], fromJS(triggeredActions));
  }
});