import moment from 'moment';
import {
  fromAddress
} from './analysis';

export function mapSelectionToGrid(isHorizontal, timeSeries, tableData, selectionRange) {
  let rows = {};
  let columns = {};

  Object.keys(selectionRange).forEach(key => {
    const address = fromAddress(key);
    if (rows[address.rowKey])
      rows[address.rowKey] = rows[address.rowKey] + 1;
    else
      rows[address.rowKey] = 1;

    if (columns[address.colKey])
      columns[address.colKey] = columns[address.colKey] + 1;
    else
      columns[address.colKey] = 1;
  });

  // check the selection is rectangular
  let rowsValidation = [...new Set(Object.keys(rows).map(key => rows[key]))];
  if (rowsValidation.length === 0)
    return { success: false, message: `Nothing selected` };

  if (rowsValidation.length > 1)
    return { success: false, message: `This action won't work on multiple selections` };

  let colsValidation = [...new Set(Object.keys(columns).map(key => columns[key]))];
  if (colsValidation.length === 0)
    return { success: false, message: `Nothing selected` };

  if (colsValidation.length > 1)
    return { success: false, message: `This action won't work on multiple selections` };

  rows = Object.keys(rows).map(key => Number(key));
  rows.sort((a, b) => a - b);

  if (isHorizontal) {
    const dates = Object.keys(tableData[0]).filter(key => moment(key).isValid()).map(key => ({ key, name: moment.utc(key).format('YYYY-MM-DD HH:mm:ss'), valueOf: moment.utc(key).valueOf() }));
    dates.sort((a, b) => a.valueOf - b.valueOf);
    columns = [{ key: 'title', isHeader: true, name: 'Name' }, ...dates].filter(ts => columns[ts.key] !== undefined).map(col => ({ colKey: col.key, name: col.name }));
  } else {
    columns = [{ key: 'dateTime', isHeader: true, name: 'Date' }, ...timeSeries].filter(ts => columns[ts.key] !== undefined).map(ts => ({ colKey: ts.key, identityId: ts.identityId, name: ts.name }));
  }

  return { success: true, rows, columns };
}

export function mapTableSelectionToCSV(isHorizontal, timeSeries, tableData, selectionRange, headerType, includeHeader) {
  if (!tableData || tableData.length === 0)
    return { success: false };

  const gridResult = mapSelectionToGrid(isHorizontal, timeSeries, tableData, selectionRange);
  if (!gridResult.success) {
    return { success: gridResult.success, message: gridResult.message };
  }

  const { rows, columns } = gridResult;
  const doc = [];
  if (isHorizontal) {
    if (includeHeader) {
      let docRow = [];
      docRow = [...docRow, ...columns.map(col => `"${col.name}"`)]
      doc.push(docRow.join('\t'));
    }

    rows.forEach(row => {
      let docRow = [];
      docRow = [
        ...docRow,
        ...columns.map(col => {
          const cell = tableData[row][col.colKey];
          const val = cell.adjustment !== undefined ? cell.adjustment : cell.value;
          if (val === null || val === undefined)
            return '';

          return val;
        })];

      doc.push(docRow.join('\t'));
    });
  }
  else {
    if (includeHeader) {
      let docRow = [];
      docRow = [...docRow, ...columns.map(col => `"${getHeaderTitle(col, headerType)}"`)]
      doc.push(docRow.join('\t'));
    }

    rows.forEach(row => {
      let docRow = [];
      docRow = [
        ...docRow,
        ...columns.map(col => {
          const cell = tableData[row][col.colKey];
          const val = cell.adjustment !== undefined ? cell.adjustment : cell.value;
          if (val === null || val === undefined)
            return '';

          if (col.colKey === 'dateTime') {
            return moment.utc(val).format('YYYY-MM-DD HH:mm:ss');
          }

          return val;
        })];

      doc.push(docRow.join('\t'));
    });

  }

  return { success: true, doc: doc.join('\r\n') };
}

function getHeaderTitle(column, headerType) {
  if (column.isHeader || !column.identityId) {
    return column.name;
  }

  switch (headerType) {
    case 'both': return `${column.identityId}: ${column.name}`;
    case 'name': return column.name;
    default: return `${column.identityId}`;
  }
}