import { ENVIRONMENT, ENVIRONMENT_TYPE, TITLE, VERSION } from './env';
import devSettings from './appsettings.json';
import betaSettings from './appsettings.beta.json';
import liveSettings from './appsettings.live.json';

let settings = liveSettings,
    appTitle = `${TITLE} ${VERSION}`,
    appTheme = 'navbar-light bg-light';

if (ENVIRONMENT === ENVIRONMENT_TYPE.LOCAL || ENVIRONMENT === ENVIRONMENT_TYPE.DEV) {
  settings = devSettings;
  appTitle = `${appTitle} ${ENVIRONMENT}`;
  appTheme = 'navbar-dark bg-secondary';
}
else if (ENVIRONMENT === ENVIRONMENT_TYPE.ALPHA || ENVIRONMENT === ENVIRONMENT_TYPE.BETA) {
  settings = betaSettings;
  appTitle = `${appTitle} ${ENVIRONMENT}`;
  appTheme = 'navbar-dark bg-dark';
}

export const APP_TITLE = appTitle;
export const APP_THEME = appTheme;

export const CLIENT_ID = settings.authentication.clientId;
export const SCOPES = settings.authentication.scopes;

export const ANALYSIS_API_URL = `${settings.analysisApi.endpoint}/${settings.analysisApi.version}`;
export const ANALYSIS_API_ROOT_URL = `${settings.analysisApi.endpoint}`;
export const ANALYSIS_REPORT_API_URL = `${settings.analysisReportApi.endpoint}/${settings.analysisReportApi.version}`;
export const ANALYSIS_REPORT_API_ROOT_URL = `${settings.analysisReportApi.endpoint}`;
export const ANALYSIS_SEARCH_API_URL = `${settings.analysisSearchApi.endpoint}/${settings.analysisSearchApi.version}`;
export const ANALYSIS_SEARCH_API_ROOT_URL = `${settings.analysisSearchApi.endpoint}`;

export const ETL_API_URL = `${settings.etlApi.endpoint}/${settings.etlApi.version}`;
export const ETL_API_ROOT_URL = `${settings.etlApi.endpoint}`;

export const EVENTS_API_URL = `${settings.eventsApi.endpoint}/${settings.eventsApi.version}`;

export const REPORTING_API_ROOT_URL = `${settings.reportingApi.endpoint}`;
export const SNAPSHOT_API_ROOT_URL = `${settings.snapshotApi.endpoint}`;

export const WORKSPACES_API_URL = `${settings.workspacesApi.endpoint}/${settings.workspacesApi.version}`;
export const WORKSPACES_API_URL_ROOT = `${settings.workspacesApi.endpoint}`;
export const WORKSPACES_IMAGE_URL = `${settings.workspaceImagesCdn.endpoint}`;
