import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import {
  analysisCrossSchemaSearch,
  analysisCrossSchemaSearchToggleSchemaExpansion,
  analysisCrossSchemaSearchToggleSchemaSelection,
  analysisCrossSchemaSearchToggleSchemaFacetValue,
} from '../../../actions/analysis-cross-schema-search';
import { SchemaFacets } from './SchemaFacets';

export const SchemaFacetsConnected = connect(
  (state, { stateContext = 'default' }) => ({
    schemaFacets: state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'schemaFacets']),
    schemas: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'schemas']),
    isMultiSelect: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'isMultiSelect']),
    facetStyle: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'facetStyle']),
  }),
  (dispatch, { stateContext = 'default', searchHandler }) => ({
    toggleSchemaExpansion(schemaName) {
      dispatch(analysisCrossSchemaSearchToggleSchemaExpansion(stateContext, schemaName));
    },
    toggleSchemaSelection(schemaName, facetKey) {
      dispatch(analysisCrossSchemaSearchToggleSchemaSelection(stateContext, schemaName, facetKey));
    },
    toggleSchemaFacetValue(schemaName, facetKey, value, isMultiSelect) {
      dispatch(analysisCrossSchemaSearchToggleSchemaFacetValue(stateContext, schemaName, facetKey, value, isMultiSelect));
      if (!isMultiSelect) {
        if (searchHandler){
          searchHandler(stateContext);
        } else {
          dispatch(analysisCrossSchemaSearch(stateContext));
        }
      }
    }
  })
)(({ stateContext, schemaFacets, schemas, isMultiSelect, facetStyle,
  toggleSchemaExpansion, toggleSchemaSelection, toggleSchemaFacetValue }) => {

  const _schemaFacets = toJS(schemaFacets) ?? [];
  return <SchemaFacets
    stateContext={stateContext}
    schemaFacets={_schemaFacets} isMultiSelect={isMultiSelect} facetStyle={facetStyle}
    toggleSchemaExpansion={toggleSchemaExpansion} toggleSchemaSelection={toggleSchemaSelection} toggleSchemaFacetValue={toggleSchemaFacetValue} />

})