import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ETL_API_URL, ETL_API_ROOT_URL } from '../config';
import {
  logErrorNotification
} from './log';

export const ETL_JOB_FETCH_STARTED = 'ETL_JOB_FETCH_STARTED';
export const etlJobFetchStarted = createAction(ETL_JOB_FETCH_STARTED, 'data');

export const ETL_JOB_FETCH_STOPPED = 'ETL_JOB_FETCH_STOPPED';
export const etlJobFetchStopped = createAction(ETL_JOB_FETCH_STOPPED);

export const ETL_JOB_FETCH_COMPLETE = 'ETL_JOB_FETCH_COMPLETE';
export const etlJobFetchComplete = createAction(ETL_JOB_FETCH_COMPLETE, 'data');

export const etlJobFetch = (jobId) => (dispatch, getState) => {
  dispatch(etlJobFetchStarted(jobId));
  return authFetch(`${ETL_API_URL}/job/${jobId}/overview`)
    .then(response => response.json())
    .then(data => {
      dispatch(etlJobFetchComplete(data));
    })
    .catch(error => {
      dispatch(etlJobFetchStopped());
      dispatch(logErrorNotification(error));
    });
};

export const etlJobHistoryFetch = (jobId, page) => (dispatch, getState) => {
  return authFetch(`${ETL_API_ROOT_URL}/v2/job/${jobId}/history?page=${page}`)
    .then(response => response.json())
    .then(data => {
      dispatch(etlJobHistoryFetchComplete(data.history, data.totalCount, page));
    })
    .catch(error => {
      dispatch(etlJobHistoryFetchComplete());
      dispatch(logErrorNotification(error));
    });
};

export const ETL_JOB_HISTORY_FETCH_STARTED = 'ETL_JOB_HISTORY_FETCH_STARTED';
export const etlJobHistoryFetchStarted = createAction(ETL_JOB_HISTORY_FETCH_STARTED);

export const ETL_JOB_HISTORY_FETCH_COMPLETE = 'ETL_JOB_HISTORY_FETCH_COMPLETE';
export const etlJobHistoryFetchComplete = createAction(ETL_JOB_HISTORY_FETCH_COMPLETE, 'history', 'totalCount', 'page');

export const ETL_JOB_RUN_FETCH_STARTED = 'ETL_JOB_RUN_FETCH_STARTED';
export const etlJobRunFetchStarted = createAction(ETL_JOB_RUN_FETCH_STARTED);

export const ETL_JOB_RUN_FETCH_COMPLETE = 'ETL_JOB_RUN_FETCH_COMPLETE';
export const etlJobRunFetchComplete = createAction(ETL_JOB_RUN_FETCH_COMPLETE, 'data');

export const etlJobRunFetch = (runId) => (dispatch, getState) => {
  dispatch(etlJobRunFetchStarted());
  authFetch(`${ETL_API_URL}/jobrun/${runId}`)
    .then(response => response.json())
    .then(data => {
      dispatch(etlJobRunFetchComplete(data));
    })
    .catch(error => {
      dispatch(etlJobRunFetchComplete());
      dispatch(logErrorNotification(error));
    });
};