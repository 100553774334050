import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import InfoPopup from '../../../shared/InfoPopup';

export default function MaterialiseRangeDialog({ dialogVisible, fromUtc, toUtc, suppressArchive, setDialogVisible, setDateFunction, materialiseWithRangeFunction, setSuppressArchive }) {
  return (
    <Dialog open={dialogVisible} onClose={() => setDialogVisible(false)}>
        <DialogTitle>Enter a range to materialise</DialogTitle>
        <DialogContent>
          <div className='form-row' style={{ display:'flex', flexDirection: 'column', minHeight: '370px', minWidth: '330px' }}>
            <label className='mr-2'>From Date:</label>
            <input className='form-control' 
                   type='date' 
                   min="1950-01-01" 
                   max="2050-01-01"
                   isDateOnly={true}
                   value={fromUtc}
                   onChange={e => setDateFunction('fromUtc', e.target.value)} />
            <label className='mt-2 mr-2'>To Date:</label>
            <input className='form-control' 
                   type='date' 
                   min="1950-01-01" 
                   max="2050-01-01"
                   isDateOnly={true}
                   value={toUtc}
                   onChange={e => setDateFunction('toUtc', e.target.value)} />
          </div>
          <div className='form-row'>
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckMateialisationMode" checked={suppressArchive} onChange={_ => setSuppressArchive(!suppressArchive)} />
              <label className="form-check-label" htmlFor="flexSwitchCheckMateialisationMode">Do not archive old data</label>
            </div>
            <InfoPopup>
                Uncheck this in order to archive all data points and instances prior to the start of the rematerialisation process.
              </InfoPopup>                          
          </div>

        </DialogContent>
        <DialogActions>
          <button className='btn btn-sm btn-primary rounded' onClick={() => materialiseWithRangeFunction()} >
              <span className='px-2 d-none d-md-inline-block'>Rematerialise</span>
          </button>
          <button className='btn btn-sm btn-secondary rounded' onClick={() => setDialogVisible(false)} >
              <span className='px-2 d-none d-md-inline-block'>Cancel</span>
          </button>
        </DialogActions>
    </Dialog>
  );
}