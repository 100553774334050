import React, { useEffect, useRef } from 'react';

const onColumnResize = function(e, table, th, onResize) {
  const thStyles = window.getComputedStyle(th);
  const headerWidth = parseInt(thStyles.width, 10);

  const x = e.clientX;
  let dx = e.clientX - x;

  const mouseMoveHandler = function(e) {
    dx = e.clientX - x;

    th.style.width = `${headerWidth + dx}px`;
    th.style.minWidth = `${headerWidth + dx}px`;
  };

  const mouseUpHandler = function() {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    if (typeof onResize === 'function') onResize(headerWidth + dx);
  };

  document.addEventListener('mousemove', mouseMoveHandler);
  document.addEventListener('mouseup', mouseUpHandler);
};

export default function ColResizer({ tableRef, colRef, onResize }) {
  const ref = useRef();

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const tableStyles = window.getComputedStyle(tableRef.current);
      let h = parseInt(tableStyles.height, 10);

      if (!isNaN(h)) ref.current.style.height = `${h}px`;
    }
  }, [ref, tableRef]);

  useEffect(() => {
    if (colRef && colRef.current) {
      const thStyles = window.getComputedStyle(colRef.current);
      let w = parseInt(thStyles.width, 10);

      if (!isNaN(w)) colRef.current.style.width = `${w}px`;
    }
  }, [colRef]);

  return (
    <div ref={ ref }
         style={{
           position: 'absolute',
           top: 0,
           right: 0,
           width: '5px',
           cursor: 'col-resize',
           userSelect: 'none',
           zIndex: 9
         }}
         onClick={ e => e.stopPropagation() }
         onMouseDown={ e => onColumnResize(e, tableRef.current, colRef.current, onResize) } />
  );
}

export function ResizeableCol({ children, className, style, onClick, tableRef, disableResize, onResize }) {
  const ref = useRef();

  return (
    <th ref={ ref } className={ `${className || ''}` } style={ style || {} } onClick={ onClick }>
      { children }
      { !disableResize && <ColResizer tableRef={ tableRef } colRef={ ref } onResize={ onResize } /> }
    </th>
  );
}