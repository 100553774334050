import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import { ReportsTierToggle } from './ReportsTierToggle';
import { ReportsTable } from './ReportsTable';
import { getScenarios } from '../../../utility/reportstable-utility';
import {
  reportsToggleAnnotationExpand,
  reportsToggleExpand,
  reportsToggleOnDemand,
  reportsChartRefresh,
  reportsSetAllScenarioOverride,
  reportsSetScenarioOverride,
  reportsSetScenarioWidth,
  reportsShowOverlay,
  reportsChartSetSelected,

  reportsAdjustmentsBeginEdit,
  reportsAdjustmentsEndEdit,
  reportsAdjustmentsSetSelectionStart,
  reportsAdjustmentsSetSelectionEnd,

  reportsAdjustmentsSetAdjustmentCell,
  reportsAdjustmentsSetAdjustmentValue,
  reportsAdjustmentsSetSelectionAdjustmentValue,
  reportsAdjustmentsNavigateCell,
  reportsAdjustmentsRemoveSelectedAdjustments,
  reportsAdjustmentsUndoSelectedAdjustments,
  reportsTableSelectionCopy,
  reportsTablePasteToSelection,

  reportAdjustmentsValidate,
  reportsAdjustmentsSetSaveConfirmationVisibility,

  reportsToggleExpandTier,
  reportsReloadOnDemandParams
} from '../../../actions/reports';

import {ReportChartView} from './ReportChartView';

const ReportsTableConnected = connect(
  (state) => ({
    rootScenario: state.getIn(['reports', 'criteria', 'rootScenario']),
    scenarioWidth: state.getIn(['reports', 'criteria', 'scenarioWidth']),
    fromDate: state.getIn(['reports', 'criteria', 'fromDate']),
    toDate: state.getIn(['reports', 'criteria', 'toDate']),
    displayMap: state.getIn(['reports', 'results', 'displayMap']),
    annotationsDisplayMap: state.getIn(['reports', 'results', 'annotationsDisplayMap']),
    scenarioOverrideMap: state.getIn(['reports', 'results', 'scenarioOverrideMap']),
    styles: state.getIn(['reports', 'results', 'styles']),
    specialisedStyles: state.getIn(['reports', 'results', 'specialisedStyles']),
    columns: state.getIn(['reports', 'results', 'columns']),
    rows: state.getIn(['reports', 'results', 'rows']),
    annotationSections: state.getIn(['reports', 'results', 'annotationSections']),
    errors: state.getIn(['reports', 'results', 'errors']),
    warnings: state.getIn(['reports', 'results', 'warnings']),
    scenarioMode: state.getIn(['reports', 'results', 'settings', 'scenarioMode']),
    orientation: state.getIn(['reports', 'results', 'settings', 'orientation']),
    isEditing: state.getIn(['reports', 'adjustments', 'isEditing']),
    horizontalView: state.getIn(['reports', 'results', 'horizontalView'])
  }),
  (dispatch) => ({
    setAllScenarioOverride(value) {
      dispatch(reportsSetAllScenarioOverride(value));
    },
    setScenarioOverride(key, value) {
      dispatch(reportsSetScenarioOverride(key, value));
    },
    toggleOnDemand(key, level, displayKey) {
      dispatch(reportsToggleOnDemand(key, level, displayKey));
    },
    toggleExpand(key, level, displayKey) {
      dispatch(reportsToggleExpand(key, level, displayKey));
    },
    toggleAnnotationExpand(sectionKey) {
      dispatch(reportsToggleAnnotationExpand(sectionKey));
    },
    openChart(id, parentKeys, children = []) {
      const timeSeriesIds = id !== 0 ? [id, ...children] : children;
      dispatch(reportsChartSetSelected(timeSeriesIds));
      dispatch(reportsShowOverlay());
      dispatch(reportsChartRefresh({ timeSeriesIds, parentKeys }));
    },
    onResize(_, value) {
      dispatch(reportsSetScenarioWidth(value));
    },
    beginEditMode(args) {
      dispatch(reportsAdjustmentsBeginEdit());
      dispatch(reportsAdjustmentsSetAdjustmentCell(args));
      dispatch(reportsAdjustmentsSetSelectionStart(args));
    },
    endEditMode() {
      dispatch(reportsAdjustmentsEndEdit());
    },
    setSelectionStart(args) {
      dispatch(reportsAdjustmentsSetAdjustmentCell(args));
      dispatch(reportsAdjustmentsSetSelectionStart(args));
    },
    setSelectionEnd(args) {
      dispatch(reportsAdjustmentsSetSelectionEnd(args));
    },
    setAdjustmentValue(args) {
      dispatch(reportsAdjustmentsSetAdjustmentValue(args));
      dispatch(reportAdjustmentsValidate());
    },
    setSelectionAdjustmentValue(args) {
      dispatch(reportsAdjustmentsSetSelectionAdjustmentValue(args));
      dispatch(reportAdjustmentsValidate());
    },
    navigateCell(args) {
      dispatch(reportsAdjustmentsNavigateCell(args));
    },
    removeSelectionAdjustments() {
      dispatch(reportsAdjustmentsRemoveSelectedAdjustments());
      dispatch(reportAdjustmentsValidate());
    },
    undoSelectionAdjustments() {
      dispatch(reportsAdjustmentsUndoSelectedAdjustments());
      dispatch(reportAdjustmentsValidate());
    },
    copySelection() {
      dispatch(reportsTableSelectionCopy(false));
    },
    pasteToSelection(text) {
      dispatch(reportsTablePasteToSelection(text));
      dispatch(reportAdjustmentsValidate());
    },
    setSaveConfirmationVisibility(isVisible) {
      dispatch(reportsAdjustmentsSetSaveConfirmationVisibility(isVisible));
    }
  })
)(({ rootScenario, scenarioWidth, fromDate, toDate, displayMap, annotationsDisplayMap, styles, specialisedStyles, columns, rows, annotationSections, errors, scenarioMode, orientation, scenarioOverrideMap, warnings,
  setAllScenarioOverride, setScenarioOverride, toggleOnDemand, toggleExpand, toggleAnnotationExpand, openChart, onResize,
  beginEditMode, endEditMode, isEditing, setSelectionStart, setSelectionEnd, setAdjustmentValue, setSelectionAdjustmentValue, navigateCell, removeSelectionAdjustments, undoSelectionAdjustments,
  copySelection, pasteToSelection, setSaveConfirmationVisibility, horizontalView }) => {
  const _horizontalView = orientation === 'Vertical' ? [] : toJS(horizontalView);
  const _displayMap = orientation === 'Vertical' ? toJS(displayMap, {}) : displayMap;
  const _rows = orientation === 'Vertical' ? toJS(rows, {}) : rows;
  const _annotationsDisplayMap = toJS(annotationsDisplayMap, {});
  const _annotationSections = toJS(annotationSections, []);
  const _scenarioOverrideMap = toJS(scenarioOverrideMap, {});
  const _specialisedStyles = toJS(specialisedStyles, {});
  const _styles = toJS(styles, {});
  const _errors = toJS(errors, {});
  const _warnings = toJS(warnings, {});
  const getSpecialStyle = useCallback(key => _specialisedStyles[key] || '', [_specialisedStyles]);
  const getStyle = useCallback(key => _styles[key] || {}, [_styles]);
  const scenarios = getScenarios(rows);
 
  const _columns = [{
    key: 'id',
    title: 'Name',
    className: 'sticky-cell',
    rightComponent: <ReportsTierToggleConnected />,
    style: {
      padding: '.1rem',
      minWidth: '29em'
    }
  }, ...(columns && columns.toJS ? columns.toJS() || [] : columns || []).map(i => ({
    ...i,
    noWrap: false,
    style: {
      whiteSpace: 'pre',
      padding: '.1rem',
      ...(i ? (i.className || '').split(' ') : []).reduce((accumulator, item) => ({ ...accumulator, ...getStyle(item) }), {})
    }
  }))];

  function getColumnStyleNames(ix) {
    return _columns && _columns.length > ix ? _columns[ix].className.split(' ') : [];
  }

  return <>
  <ReportChartView/>
  <ReportsTable
    rootScenario={rootScenario}
    scenarioWidth={scenarioWidth}
    fromDate={fromDate}
    toDate={toDate}
    displayMap={_displayMap}
    annotationsDisplayMap={_annotationsDisplayMap}
    styles={_styles}
    specialisedStyles={specialisedStyles}
    columns={_columns}
    rows={_rows}
    annotationSections={_annotationSections}
    errors={_errors}
    scenarios={scenarios}
    scenarioMode={scenarioMode}
    scenarioOverrideMap={_scenarioOverrideMap}
    warnings={_warnings}
    setAllScenarioOverride={setAllScenarioOverride}
    setScenarioOverride={setScenarioOverride}
    orientation={orientation}
    toggleOnDemand={toggleOnDemand}
    toggleExpand={toggleExpand}
    toggleAnnotationExpand={toggleAnnotationExpand}
    openChart={openChart}
    getColumnStyleNames={getColumnStyleNames}
    getSpecialStyle={getSpecialStyle}
    getStyle={getStyle}
    onResize={onResize}

    isEditing={isEditing}
    beginEditMode={beginEditMode}
    endEditMode={endEditMode}
    setSelectionStart={setSelectionStart}
    setSelectionEnd={setSelectionEnd}
    setAdjustmentValue={setAdjustmentValue}
    setSelectionAdjustmentValue={setSelectionAdjustmentValue}
    navigateCell={navigateCell}
    removeSelectionAdjustments={removeSelectionAdjustments}
    undoSelectionAdjustments={undoSelectionAdjustments}

    copySelection={copySelection}
    pasteToSelection={pasteToSelection}

    horizontalView={_horizontalView}
    setSaveConfirmationVisibility={setSaveConfirmationVisibility} />
  </>
});

export default ReportsTableConnected;

const ReportsTierToggleConnected = connect(
  (state) => ({
    selectedLevel: state.getIn(['reports', 'results', 'selectedLevel']),
    tierToggleMap: state.getIn(['reports', 'results', 'tierToggleMap'])
  }),
  (dispatch) => ({
    toggleExpandTier(tier, value) {
      dispatch(reportsToggleExpandTier(tier, value));
      dispatch(reportsReloadOnDemandParams());
    }
  })
)(({ selectedLevel, tierToggleMap = [], toggleExpandTier }) => {
  const _tierToggleMap = toJS(tierToggleMap);
  return <ReportsTierToggle selectedLevel={selectedLevel} tierToggleMap={_tierToggleMap} toggleExpandTier={toggleExpandTier} />
});