import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { WORKSPACES_API_URL } from '../config';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const DASHBOARD_WORKSPACES_LOAD_STARTED = 'DASHBOARD_WORKSPACES_LOAD_STARTED';
const dashboardWorkspacesLoadStarted = createAction(DASHBOARD_WORKSPACES_LOAD_STARTED, 'data');

export const dashboardWorkspacesLoad = ({ area, type, scope }) => (dispatch, getState) => {
  dispatch(dashboardWorkspacesLoadStarted({ area, type, scope }));

  let uri = `${WORKSPACES_API_URL}/workspaces`;

  if (type) uri = `${uri}/${type}`;
  if (scope) uri = `${uri}/${scope}`;

  authFetch(uri)
    .then(response => response.status === 204 ? null : response.json())
    .then(data => {
      dispatch(dashboardWorkspacesLoadComplete({ data, area, type, scope }));
    })
    .catch(error => {
      dispatch(dashboardWorkspacesLoadComplete());
      dispatch(logErrorNotification(error));
    });
};

export const DASHBOARD_WORKSPACES_LOAD_COMPLETE = 'DASHBOARD_WORKSPACES_LOAD_COMPLETE';
const dashboardWorkspacesLoadComplete = createAction(DASHBOARD_WORKSPACES_LOAD_COMPLETE, 'data');

export const dashboardWorkspaceCopy = ({ area, type, scope, folderPath, subFolderPath, name, callback }) => (dispatch, getState) => {
  const state = getState();

  const id = state.getIn(['dashboard', 'workspaces', 'selected', 'id']);

  if (!folderPath && !!subFolderPath) {
    const parts = subFolderPath.split('/').filter(i => !!i);

    if (parts.length === 1) {
      folderPath = parts[0];
      subFolderPath = '';
    }
    else {
      folderPath = parts[0];
      subFolderPath = parts.slice(1).join('/');
    }
  }

  const path = `${folderPath || ''}/${subFolderPath || ''}/${name || ''}`.replace(/\/+/g, '/');

  authFetch(`${WORKSPACES_API_URL}/workspaces/${id}/copy?path=${path}${scope ? `&scope=${scope}` : ''}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(data => {
      dispatch(logInfoNotification(`Workspace '${path}' saved`));
      dispatch(dashboardWorkspacesLoad({ area, type, scope }));

      if (typeof callback === 'function') callback(data);
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const DASHBOARD_WORKSPACE_DELETE_STARTED = 'DASHBOARD_WORKSPACE_DELETE_STARTED';
export const dashboardWorkspaceDeleteStarted = createAction(DASHBOARD_WORKSPACE_DELETE_STARTED, 'data');

export const dashboardWorkspaceDelete = ({ id, name, area, type, scope, callback }) => (dispatch, getState) => {
  dispatch(dashboardWorkspaceDeleteStarted(id));

  authFetch(`${WORKSPACES_API_URL}/workspaces/${id}`, { method: 'DELETE' })
    .then(() => {
      dispatch(dashboardWorkspaceDeleteComplete({ id, area, type, scope }));

      if (typeof callback === 'function') callback(id);

      dispatch(logInfoNotification(`Workspace '${name}' deleted`));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const DASHBOARD_WORKSPACE_DELETE_COMPLETE = 'DASHBOARD_WORKSPACE_DELETE_COMPLETE';
export const dashboardWorkspaceDeleteComplete = createAction(DASHBOARD_WORKSPACE_DELETE_COMPLETE, 'data');

export const DASHBOARD_WORKSPACE_SELECT = 'DASHBOARD_WORKSPACE_SELECT';
export const dashboardWorkspaceSelect = createAction(DASHBOARD_WORKSPACE_SELECT, 'data');

export const DASHBOARD_WORKSPACES_CLEAR = 'DASHBOARD_WORKSPACES_CLEAR';
export const dashboardWorkspacesClear = createAction(DASHBOARD_WORKSPACES_CLEAR, 'data');

export const DASHBOARD_WORKSPACES_FILTER = 'DASHBOARD_WORKSPACES_FILTER';
export const dashboardWorkspacesFilter = ({ area, filterType, filterText }) => (dispatch) => {
  dispatch(dashboardWorkspacesFilterUpdated({ area, filterType, filterText }));
};

export const DASHBOARD_WORKSPACES_FILTER_UPDATED = 'DASHBOARD_WORKSPACES_FILTER_UPDATED';
export const dashboardWorkspacesFilterUpdated = createAction(DASHBOARD_WORKSPACES_FILTER_UPDATED, 'data');

export const DASHBOARD_WORKSPACES_SORT = 'DASHBOARD_WORKSPACES_SORT';
export const dashboardWorkspacesSort = ({ area, type, scope, orderBy }) => (dispatch, getState) => {
  const state = getState();

  const orderByDirection = orderBy && state.getIn(['dashboard', 'workspaces', area, type, scope, 'orderBy']) === orderBy
    ? state.getIn(['dashboard', 'workspaces', area, type, scope, 'orderByDirection']) === 'desc' ? 'asc' : 'desc'
    : 'desc';
  dispatch(dashboardWorkspacesSortUpdated({ area, type, scope, orderBy, orderByDirection }));
};

export const DASHBOARD_WORKSPACES_SORT_UPDATED = 'DASHBOARD_WORKSPACES_SORT_UPDATED';
export const dashboardWorkspacesSortUpdated = createAction(DASHBOARD_WORKSPACES_SORT_UPDATED, 'data');