import { createAction } from '../utility/redux-utility';

export const ANALYSIS_CHARTING_BASKET_REMOVE_ITEM = 'ANALYSIS_CHARTING_BASKET_REMOVE_ITEM';
export const analysisChartingBasketRemoveItem = createAction(ANALYSIS_CHARTING_BASKET_REMOVE_ITEM, 'key');

export const ANALYSIS_SELECTION_BASKET_REMOVE_ALL = 'ANALYSIS_SELECTION_BASKET_REMOVE_ALL';
export const analysisSelectionBasketRemoveAll = createAction(ANALYSIS_SELECTION_BASKET_REMOVE_ALL);

export const ANALYSIS_CHARTING_BASKET_CLONE_TIMESERIES = 'ANALYSIS_CHARTING_BASKET_CLONE_TIMESERIES';
export const analysisChartingBasketCloneTimeSeries = createAction(ANALYSIS_CHARTING_BASKET_CLONE_TIMESERIES, 'key', 'nameStyle', 'suppressName', 'withProperty', 'withValue');

export const ANALYSIS_EVO_REFRESH_COMPLETE = 'ANALYSIS_EVO_REFRESH_COMPLETE';
export const analysisEvoRefreshComplete = createAction(ANALYSIS_EVO_REFRESH_COMPLETE, 'data');

export const ANALYSIS_REFRESH_COMPLETE = 'ANALYSIS_REFRESH_COMPLETE';
export const analysisRefreshComplete = createAction(ANALYSIS_REFRESH_COMPLETE, 'timeSeriesReport', 'timeSeriesExpressionNames', 'reportRequest', 'expressionRequest', 'reapplyPreviousAdjustments');

export const ANALYSIS_UPDATE_SERIES2 = 'ANALYSIS_UPDATE_SERIES2';
export const analysisUpdateSeries2 = createAction(ANALYSIS_UPDATE_SERIES2, 'key', 'data', 'requiresApiRefresh');

export const ANALYSIS_SET_FILTER_VALUE = 'ANALYSIS_SET_FILTER_VALUE';
export const analysisSetFillerValue = createAction(ANALYSIS_SET_FILTER_VALUE, 'key', 'value');

export const ANALYSIS_SET_XAXIS_SERIES = 'ANALYSIS_SET_XAXIS_SERIES';
export const analysisSetXAxisSeries = createAction(ANALYSIS_SET_XAXIS_SERIES, 'key');

export const ANALYSIS_UPDATE_ALL_SERIES = 'ANALYSIS_UPDATE_ALL_SERIES';
export const analysisUpdateAllSeries = createAction(ANALYSIS_UPDATE_ALL_SERIES, 'key', 'value', 'options');

export const ANALYSIS_TOGGLE_ALL_SERIES_VISIBILITY2 = 'ANALYSIS_TOGGLE_ALL_SERIES_VISIBILITY2';
export const analysisToggleAllSeriesVisibiliy2 = createAction(ANALYSIS_TOGGLE_ALL_SERIES_VISIBILITY2, 'value');

export const ANALYSIS_TOGGLE_SERIES_VISIBILITY2 = 'ANALYSIS_TOGGLE_SERIES_VISIBILITY2';
export const analysisToggleSeriesVisibiliy2 = createAction(ANALYSIS_TOGGLE_SERIES_VISIBILITY2, 'key');

export const ANALYSIS_TOGGLE_CHART_SERIES_VISIBILITY = 'ANALYSIS_TOGGLE_CHART_SERIES_VISIBILITY';
export const analysisToggleChartSeriesVisibility = createAction(ANALYSIS_TOGGLE_CHART_SERIES_VISIBILITY, 'key');

export const ANALYSIS_TOGGLE_SERIES_NAME_STYLE = 'ANALYSIS_TOGGLE_SERIES_NAME_STYLE';
export const analysisToggleSeriesNameStyle = createAction(ANALYSIS_TOGGLE_SERIES_NAME_STYLE, 'key');

export const ANALYSIS_UPDATE_ALL_SERIES_NAME_STYLE = 'ANALYSIS_UPDATE_ALL_SERIES_NAME_STYLE';
export const analysisUpdateAllSeriesNameStyle = createAction(ANALYSIS_UPDATE_ALL_SERIES_NAME_STYLE, 'nameStyle');

export const ANALYSIS_UPDATE_SERIES_CUSTOM_NAME = 'ANALYSIS_UPDATE_SERIES_CUSTOM_NAME';
export const analysisUpdateSeriesCustomName = createAction(ANALYSIS_UPDATE_SERIES_CUSTOM_NAME, 'key', 'value');

export const ANALYSIS_SELECTION_MOVE_TIME_SERIES = 'ANALYSIS_SELECTION_MOVE_TIME_SERIES';
export const analysisSelectionMoveTimeSeries = createAction(ANALYSIS_SELECTION_MOVE_TIME_SERIES, 'key', 'newIndex', 'direction'); // newIndex = legacy basket, to be removed

export const ANALYSIS_CHARTING_BASKET_BEGIN_EDIT = 'ANALYSIS_CHARTING_BASKET_BEGIN_EDIT';
export const analysisChartingBasketBeginEdit = createAction(ANALYSIS_CHARTING_BASKET_BEGIN_EDIT, 'key');

export const ANALYSIS_CHARTING_BASKET_SAVE_EDIT = 'ANALYSIS_CHARTING_BASKET_SAVE_EDIT';
export const analysisChartingBasketSaveEdit = createAction(ANALYSIS_CHARTING_BASKET_SAVE_EDIT);

export const ANALYSIS_CHARTING_BASKET_CANCEL_EDIT = 'ANALYSIS_CHARTING_BASKET_CANCEL_EDIT';
export const analysisChartingBasketCancelEdit = createAction(ANALYSIS_CHARTING_BASKET_CANCEL_EDIT);

export const ANALYSIS_CHARTING_BASKET_EDIT_VALUE = 'ANALYSIS_CHARTING_BASKET_EDIT_VALUE';
export const analysisChartingBasketEditValue = createAction(ANALYSIS_CHARTING_BASKET_EDIT_VALUE, 'keyPath', 'value');

export const ANALYSIS_CHARTING_BASKET_SET_VALUE = 'ANALYSIS_CHARTING_BASKET_SET_VALUE';
export const analysisChartingBasketSetValue = createAction(ANALYSIS_CHARTING_BASKET_SET_VALUE, 'key', 'property', 'value');

export const ANALYSIS_CHARTING_BASKET_EDIT_ASAT = 'ANALYSIS_CHARTING_BASKET_EDIT_ASAT';
export const analysisChartingBasketEditAsAt = createAction(ANALYSIS_CHARTING_BASKET_EDIT_ASAT, 'value');

export const ANALYSIS_SET_BASKET_VIEW = 'ANALYSIS_SET_BASKET_VIEW';
export const analysisSetBasketView = createAction(ANALYSIS_SET_BASKET_VIEW, 'basketView');

export const ANALYSIS_EDIT_HIGHCHARTS_JSON = 'ANALYSIS_EDIT_HIGHCHARTS_JSON';
export const analysisEditHighchartsJson = createAction(ANALYSIS_EDIT_HIGHCHARTS_JSON, 'key');

export const ANALYSIS_UPDATE_HIGHCHARTS_JSON = 'ANALYSIS_UPDATE_HIGHCHARTS_JSON';
export const analysisUpdateHighchartsJson = createAction(ANALYSIS_UPDATE_HIGHCHARTS_JSON, 'key', 'data');

export const ANALYSIS_CANCEL_HIGHCHARTS_JSON = 'ANALYSIS_CANCEL_HIGHCHARTS_JSON';
export const analysisCancelHighchartsJson = createAction(ANALYSIS_CANCEL_HIGHCHARTS_JSON, 'key');

export const ANALYSIS_PREVIEW_HIGHCHARTS_JSON = 'ANALYSIS_PREVIEW_HIGHCHARTS_JSON';
export const analysisPreviewHighchartsJson = createAction(ANALYSIS_PREVIEW_HIGHCHARTS_JSON, 'key');

export const ANALYSIS_APPLY_HIGHCHARTS_JSON = 'ANALYSIS_APPLY_HIGHCHARTS_JSON';
export const analysisApplyHighchartsJson = createAction(ANALYSIS_APPLY_HIGHCHARTS_JSON, 'key');