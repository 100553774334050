import React, { useEffect, useState, useRef } from 'react';
import LoadingIcon from '../../shared/LoadingIcon';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';

export default function EventsSearchMessage({ id, message, loadMessage, convertMessage, navigate, downloadSourceFile, isVisible }) {
  const [convertedMessage, setConvertedMessage] = useState('');
  const messageRef = useRef();

  function onLinkClick(e) {
    const href = e.target.getAttribute('href');

    if (!href || (href.indexOf('http://') === 0 || href.indexOf('https://') === 0)) return;

    e.preventDefault();
    e.stopPropagation();

    if (href.indexOf('/timeseries/') === 0) navigate(href);
    else downloadSourceFile(href);
  }

  useDidUpdateEffect(() => {
    if (message && !convertedMessage) setConvertedMessage(convertMessage(message));
  }, [message, convertedMessage, convertMessage])

  useDidUpdateEffect(() => {
    messageRef.current.addEventListener('click', onLinkClick);

    return () => messageRef.current.removeEventListener('click', onLinkClick);
  }, [convertedMessage])

  useEffect(() => {
    if (isVisible && !message) loadMessage(id);
  }, [message, id, loadMessage, isVisible])

  return !convertedMessage ? (
    <div className='d-flex align-items-center'>
      <LoadingIcon width='1.25em' /> <span className='pl-2'>Loading message...</span>
    </div>
  ) : (
    <div ref={ messageRef } dangerouslySetInnerHTML={{ __html: convertedMessage }}></div>
  );
}