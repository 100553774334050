import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import Loading from '../../shared/Loading';
import {
  homepageRefresh,
  homepageImageUpload
} from '../../../actions/homepage';

const emptyImgUrl = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D';

const HomeImageUploadContent = connect(
  (state) => ({
    isUploadingImage: state.getIn(['homepage', 'isUploadingImage'])
  }),
  (dispatch) => ({
    upload(form, onSuccess) {
      dispatch(homepageImageUpload(form, onSuccess));
    },
    refresh() {
      dispatch(homepageRefresh());
    }
  })
)(({ isUploadingImage, upload, onSubmit: onDismissModal }) => {
  const [preview, setPreview] = useState({ src: '', name: '' });
  const [canUpload, setCanUpload] = useState(false);

  const filesRef = useRef();

  function onChange(e) {
    if (!e.target.files || !e.target.files.length) return;

    const file = e.target.files[0];
    const fileName = file.name.substring(file.name.lastIndexOf('\\') + 1);
    const fileReader = new FileReader();

    fileReader.onload = e => setPreview({ src: e.target.result, name: fileName });
    fileReader.readAsDataURL(file);

    setCanUpload(true);
  }

  function onSubmit(e) {
    e.preventDefault();

    if (!canUpload) return;

    const files = filesRef.current.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++)
      formData.append('file', files[i]);

    const onSuccess = () => {
      if (filesRef && filesRef.current)
        filesRef.current.value = null;

      setPreview({ src: '', name: '' });
      setCanUpload(false);
      onDismissModal(e);
    };

    upload(formData, onSuccess);
  }

  return (
    <form className='modal-content' onSubmit={ onSubmit }>
      <Loading isLoading={isUploadingImage} message='Uploading image...'>
        <div className='modal-header'>
          <h5 className='modal-title'>Upload an image...</h5>
          <button type='button' className='close' data-dismiss='modal'>&times;</button>
        </div>
        <div className='modal-body'>
          <div className='card mb-3 d-flex flex-row flex-nowrap'>
            <div className='card-body d-flex flex-column justify-content-between align-items-center'>
              <img className='card-img bg-light border border-light' src={preview.src || emptyImgUrl} alt={preview.name} style={{ height: 150, width: 200 }} />
              <div className='w-100 mt-3'>
                <div className='custom-file'>
                  <input ref={ filesRef } id='homepage-image-upload' type='file' className='custom-file-input' accept='image/*' onChange={onChange}  />
                  <label htmlFor='homepage-image-upload' className='custom-file-label'>{ preview.name || 'Choose image file...' }</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
          <button type='submit' className='btn btn-primary text-nowrap' disabled={ !canUpload }>
            <i className='fas fa-file-upload fa-fw' /> Upload
          </button>
        </div>
      </Loading>
    </form>
  );
});

export default HomeImageUploadContent;