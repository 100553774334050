import React, { useState } from 'react';
import { connect } from 'react-redux';
import { colours, colourShades, defaultColourCycle, getHLS } from '../../utility/color-utility';
import { toJS } from '../../utility/immutable-utility';
import { PopoverButton } from './PopoverButton';

function UserColour({ colour, applyColour, selectColour, closePopover }) {
  const [textColour, setTextColour] = useState(colour);
  const hls = getHLS(textColour);
  const [textColourContrast, setTextColourContrast] = useState(hls.contrast);

  function onSetTextColour(hex) {
    setTextColour(hex);
    const hls = getHLS(hex);
    setTextColourContrast(hls.contrast);
    if (hls.isValid) {
      selectColour(hex);
    }
  }

  function onApplyColour(e) {
    e.stopPropagation();
    e.preventDefault();
    const hls = getHLS(textColour);
    if (hls.isValid) {
      applyColour();
      closePopover();
    }
  }

  return <form className='d-flex flex-row m-1' onSubmit={e => onApplyColour(e)}>
    <input autoFocus className='form-control form-control-sm' type='text' style={{ textTransform: 'uppercase' }} placeholder='hex colour' value={textColour} onChange={e => onSetTextColour(e.target.value)} autoComplete='off' />
    <button disabled={!hls.isValid} className='btn btn-outline-primary btn-sm p-1 ml-1' type='submit' >
      <div style={{ paddingLeft: '2px', paddingRight: '2px', backgroundColor: textColour, color: textColourContrast }}>
        <i className='fas fa-check' ></i>
      </div>
    </button>
  </form>
}

function ColourButton({ colour, selectedColour, onClick }) {
  const hls = getHLS(colour);
  const style = {
    color: hls.contrast,
    backgroundColor: colour,
  };

  return <div onClick={onClick} title={`${colour}`} style={style} className={`colour-button ${hls.isLight ? 'colour-button-contrast-hover-dark' : 'colour-button-contrast-hover-light'}`} >
    {colour === selectedColour && <i className='colour-button-selected fas fa-check'></i>}
  </div>
}

const ColourPicker = connect(
  (state) => ({
    usersColourCycle: state.getIn(['analysis', 'colourCycle'])
  })
)(({ colour, buttonClassName = 'btn', onColourChange, usersColourCycle }) => {
  const [selectedColour, setSelectedColour] = useState(colour);

  function onOpenPopover() {
    setSelectedColour(colour);
  }

  function onSelectUserColour(hex) {
    setSelectedColour(hex);
  }

  function onSelectColour(colour, shade, closePopover) {
    const col = colour[shade];
    setSelectedColour(col);
    onColourChange({ hex: col });
    closePopover();
  }

  function onApplyColour() {
    onColourChange({ hex: selectedColour });
  }

  const _usersColourCycle = toJS(usersColourCycle);
  const _customPalette = (_usersColourCycle && _usersColourCycle.length > 0 ? _usersColourCycle : defaultColourCycle).map(c => (['', { '100': c }]));

  const hls = getHLS(selectedColour);

  return <>
    <PopoverButton onOpenPopover={onOpenPopover} buttonContent={<span style={{ textTransform: 'uppercase' }}>{colour}</span>} buttonClassName={buttonClassName} buttonStyles={{ fontSize: '10px', fontWeight: '700', padding: '3px 6px', width: '55px', backgroundColor: colour, color: hls.contrast }}>
      {({ closePopover }) => (
        <>
          <UserColour colour={selectedColour} selectColour={onSelectUserColour} applyColour={onApplyColour} closePopover={closePopover} />
          <div className='d-flex flex-row'>
            {_customPalette && _customPalette.length > 0 && (
              <div className='mr-2'>
                {_customPalette.map(([key, value], ix) => (
                  <div key={`${key}-${ix}`}>
                    {[100].map(shade => (
                      <ColourButton key={`${key}-${shade}`} title={value[shade]} selectedColour={selectedColour} onClick={e => onSelectColour(value, shade, closePopover)}
                        colour={value[shade]} />
                    ))}
                  </div>
                ))}
              </div>)}
            <div>
              {colours.map(([key, value], ix) => (
                <div key={`${key}-${ix}`}>
                  {colourShades.map(shade => (
                    <ColourButton key={`${key}-${shade}`} title={value[shade]} selectedColour={selectedColour} onClick={e => onSelectColour(value, shade, closePopover)}
                      colour={value[shade]} />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </>)}
    </PopoverButton>
  </>
});

export default ColourPicker;