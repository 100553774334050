import React from 'react';
import { Combobox } from 'react-widgets';
import InfoPopup from '../../shared/InfoPopup';

export default function OutagesToolbarRow2({ isLoadingGenerationDataLookupList, generationDataLookupList, fotaStatus, messageVersions, messageStatus,
  generationData, lookupGenerationData, updateToolbar, fotaStatuses, groupStyle, labelStyle, isValueSelected, mapStatusToClassName }) {
  const getActiveMessageVersions = value => isValueSelected(value, messageVersions) ? 'active' : '';
  const getActiveMessageStatus = value => isValueSelected(value, messageStatus) ? 'active' : '';

  function onChangeLookup(value) {
    updateToolbar('generationData', value);
    lookupGenerationData();
  }

  return (
    <div className='form-row'>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold text-nowrap d-block' style={labelStyle}>
          Fota Status
          <InfoPopup>
            <p>The current status of the availability generation process:</p>
            <ul>
              <li>New - Added, but not yet validated or ignored</li>
              <li>Ignored - Not used in generation of availability data</li>
              <li>Invalid - Outage has failed mapping to a Unit and/or fuel type</li>
              <li>Validated - Outage has been mapped to a Unit and fuel type</li>
              <li>Processed - Generated availability data</li>
            </ul>
          </InfoPopup>
        </label>
        <div className='btn-group btn-group-sm btn-group-toggle' data-toggle='buttons'>
          {fotaStatuses.map(({ name, value }) => (
            <button key={name} type='button' className={`btn ${mapStatusToClassName(value, fotaStatus)}`}
              onClick={() => updateToolbar('fotaStatus', value)}>{ name }</button>
          ))}
        </div>
      </div>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold text-nowrap d-block' style={labelStyle}>
          Versions
          <InfoPopup>
            <p>The current status of the availability generation process:</p>
            <ul>
              <li>All - All outage message versions</li>
              <li>Latest - Latest outage message version only</li>
            </ul>
          </InfoPopup>
        </label>
        <div className='btn-group btn-group-sm'>
          <button type='button' className={'btn btn-outline-secondary ' + getActiveMessageVersions('')}
            onClick={() => updateToolbar('messageVersions', '')}>All</button>
          <button type='button' className={'btn btn-outline-secondary ' + getActiveMessageVersions('latest')}
            onClick={() => updateToolbar('messageVersions', 'Latest')}>Latest</button>
        </div>
      </div>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold text-nowrap d-block' style={labelStyle}>
          Message Status
          <InfoPopup>
            <p>The current status of the availability generation process:</p>
            <ul>
              <li>All - All outage messages</li>
              <li>Active - Active outage messages only</li>
              <li>Inactive - Inactive outage messages only</li>
              <li>Dismissed - Dismissed outage messages only</li>
              <li>Cancelled - Cancelled outage messages only</li>
              <li>Withdrawn - Withdrawn outage messages only</li>
            </ul>
          </InfoPopup>
        </label>
        <div className='btn-group btn-group-sm'>
          <button type='button' className={'btn btn-outline-secondary ' + getActiveMessageStatus('')}
            onClick={() => updateToolbar('messageStatus', '')}>All</button>
          <button type='button' className={'btn btn-outline-secondary ' + getActiveMessageStatus('active')}
            onClick={() => updateToolbar('messageStatus', 'Active')}>Active</button>
          <button type='button' className={'btn btn-outline-secondary ' + getActiveMessageStatus('inactive')}
            onClick={() => updateToolbar('messageStatus', 'Inactive')}>Inactive</button>
          <button type='button' className={'btn btn-outline-secondary ' + getActiveMessageStatus('dismissed')}
            onClick={() => updateToolbar('messageStatus', 'Dismissed')}>Dismissed</button>
          <button type='button' className={'btn btn-outline-secondary ' + getActiveMessageStatus('cancelled')}
            onClick={() => updateToolbar('messageStatus', 'Cancelled')}>Cancelled</button>
          <button type='button' className={'btn btn-outline-secondary ' + getActiveMessageStatus('withdrawn')}
            onClick={() => updateToolbar('messageStatus', 'Withdrawn')}>Withdrawn</button>
        </div>
      </div>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold text-nowrap d-block' style={labelStyle}>Generation Data</label>
        <Combobox suggest='false' autoComplete='false'
          messages={{
            emptyList: isLoadingGenerationDataLookupList ? 'Searching for items...' : 'There are no items in this list'
          }}
          busy={isLoadingGenerationDataLookupList}
          data={generationDataLookupList}
          value={generationData}
          className='fota-availability-generation-lookup'
          style={{ width: '300px' }}
          onChange={value => onChangeLookup(value)}
          onSelect={value => onChangeLookup(value)}
          onToggle={() => lookupGenerationData()} />
      </div>
    </div>
  );
}