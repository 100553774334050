import React, { Fragment } from 'react';
import { Combobox } from 'react-widgets';
import ConfirmButton from '../../shared/ConfirmButton';
import Loading from '../../shared/Loading';
import LoadingIcon from '../../shared/LoadingIcon';

export default function ShapesBuilder({
  selectionListTitle = 'Available shapes',
  canSelectNothing = false,
  shapeName, isBusy, isSavingShape, isDeletingShape, isSelectingShape, isExistingShape, availableShapes,
  shapeTemplateMap, setShapeName, selectShape, saveShape, deleteShape,
  shapeTemplateMapIsDirty, toggleShapeCellSelection, toggleShapeAllSelection, toggleShapeBucketSelection, toggleShapeGroupSelection
}) {

  function onSetShapeName(name) {
    setShapeName(name);
  }

  function onSelectShape(name) {
    const selectedShape = availableShapes.find(s => s.name === name);
    if (selectedShape)
      selectShape(selectedShape.id, selectedShape.name);
    else if (canSelectNothing)
      selectShape(undefined, undefined);
  }

  function onSaveShape() {
    saveShape();
  }

  function onDeleteShape() {
    deleteShape();
  }

  function onToggleShapeCellSelection(group, bucket) {
    toggleShapeCellSelection(group, bucket);
  }

  function onToggleShapeAllSelection() {
    toggleShapeAllSelection();
  }

  function onToggleShapeBucketSelection(bucketName) {
    toggleShapeBucketSelection(bucketName);
  }

  function onToggleShapeGroupSelection(group) {
    toggleShapeGroupSelection(group);
  }

  return <>
    <div className='form-row align-items-end'>
      <div className='col-md-6'>
        <div className='form-group m-0'>
          <label className='font-weight-bold'>{selectionListTitle}</label>
          <Combobox autoFocus
            busy={isBusy}
            messages={{
              emptyList: 'There are no available shapes'
            }}
            data={availableShapes && availableShapes.map(s => s.name)}
            value={shapeName}
            onChange={onSetShapeName}
            onSelect={onSelectShape} />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='btn-group'>
          <ConfirmButton disabled={!shapeName} requiresConfirmation={isExistingShape} onClick={onSaveShape} className={`btn ${shapeTemplateMapIsDirty ? 'btn-primary' : 'btn-outline-primary'} mx-1`}
            content={
              <Fragment>
                <span className='px-2 d-none d-md-inline-block'>{isExistingShape ? 'Save Changes' : 'Save New'}</span>
                {isSavingShape && (
                  <LoadingIcon width='1.25em' height='1.25em' stroke='#fff' style={{ marginTop: '-.2em' }} />
                )}
              </Fragment>
            }
            confirm={
              <Fragment>
                <i className='fas fa-exclamation-circle fa-fw' />
                <span className='px-2 d-none d-md-inline-block'>Confirm Overwrite?</span>
                {isSavingShape && (
                  <LoadingIcon width='1.25em' height='1.25em' stroke='#fff' style={{ marginTop: '-.2em' }} />
                )}
              </Fragment>
            } />

          {isExistingShape && shapeName && (
            <ConfirmButton className='btn btn-outline-secondary mx-1' confirmClassName='btn  btn-outline-warning mx-1' onClick={onDeleteShape} disabled={isBusy}
              content={
                <Fragment>
                  <i className='fas fa-trash fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Delete</span>
                  {isDeletingShape && (
                    <LoadingIcon width='1.25em' height='1.25em' stroke='#fff' style={{ marginTop: '-.2em' }} />
                  )}
                </Fragment>
              }
              confirm={
                <Fragment>
                  <i className='fas fa-exclamation-circle fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Confirm Deletion?</span>
                  {isDeletingShape && (
                    <LoadingIcon width='1.25em' height='1.25em' stroke='#fff' style={{ marginTop: '-.2em' }} />
                  )}
                </Fragment>
              }
            />
          )}
        </div>
      </div>
    </div>
    <div className='form-row'>
      <div className='col'>
        <Loading isLoading={isSelectingShape} message='Loading...'>
          <table className='grid-table mt-3 user-select-none'>
            <thead>
              <tr>
                <th className='spreadsheet-cursor-topLeft spreadsheet-topLeft' style={{ minWidth: '50px' }} onClick={onToggleShapeAllSelection}></th>
                {shapeTemplateMap && shapeTemplateMap.buckets.map(b => (
                  <th key={`trH${b}`} className='spreadsheet-cursor-top' style={{ minWidth: '30px' }} onClick={() => onToggleShapeBucketSelection(b)}>
                    {b}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {shapeTemplateMap && shapeTemplateMap.groups.map(g => <tr key={`g${g.name}`}>
                <td className='spreadsheet-cursor-left' key={`gc${g.name}`} style={{ minWidth: '50px' }} onClick={() => onToggleShapeGroupSelection(g)}>{g.name}</td>
                {g.buckets.map(b => <td key={`b${b.name}${g.name}`} style={{ minWidth: '30px' }}
                  className={(b.isSelected ? 'table-primary table-checked' : '')}
                  onClick={() => onToggleShapeCellSelection(g, b)} />
                )}
              </tr>
              )}
            </tbody>
          </table>
        </Loading>
      </div>
    </div>
  </>
}