import React from 'react';
import RequiredField from '../../../shared/RequiredField';
import CollapsePanel from '../../../shared/CollapsePanel';
import Tooltip from '../../../shared/Tooltip';
import RelativeDate from '../../../shared/RelativeDate';

const labelStyle = {
  fontSize: '11.6px',
  fontWeight: 700,
  margin: 0,
  cursor: 'default'
};

const inputStyle = {
  fontSize: '12px'
};

function ForecastWindowSettings({operations, basedOnSettings, forecastWindowData, basedOnUpdateSettings, updateTimeSeriesValue}) {
  const asAtOps = ['Close','Open'];
  const simpleChoice = ['true','false'];

  return (
    <>
     <div className='row no-gutters'>
        <div className='col mb-1'>
            <label style={labelStyle}>AsAt From</label>
            <div className='form-inline form-group'>
              {basedOnSettings.get('fromDateMode') === 'abs' && <div className='mt-2'>
                <input className='form-control form-control-sm' type='datetime-local' min='0001-01-01T00:00' max='9999-12-31T23:59'
                  value={forecastWindowData.asAtWindow.absFromDate}
                  onChange={e => updateTimeSeriesValue(['derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'asAtWindow', 'absFromDate'], e.target.value)} />
              </div>}

              {basedOnSettings.get('fromDateMode') === 'rel' && <div className='mt-2' >
                <RelativeDate controlClassName='form-control form-control-sm' options={['D', 'W', 'M', 'Y']}
                  value={forecastWindowData.asAtWindow.relFromDate}
                  onChange={value => updateTimeSeriesValue(['derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'asAtWindow', 'relFromDate'], value)} />
              </div>}

              <div className='form-check ml-2 mt-2'>
                <input className='form-check-input' type='radio' value='none' id='fw-from-abs'
                  checked={basedOnSettings.get('fromDateMode') === 'abs'}
                  onChange={() => basedOnUpdateSettings('fromDateMode', 'abs')} />
                <label className='form-check-label' htmlFor='fw-from-abs'>Absolute</label>
              </div>

              <div className='form-check ml-2 mt-2'>
                <input className='form-check-input' type='radio' value='none' id='fw-from-rel'
                  checked={basedOnSettings.get('fromDateMode') === 'rel'}
                  onChange={() => basedOnUpdateSettings('fromDateMode', 'rel')} />
                <label className='form-check-label' htmlFor='fw-from-rel'>Relative</label>
              </div>
            </div>
        </div>
     </div>   
     <div className='row no-gutters'>
        <div className='col mb-1'>
            <label style={labelStyle}>AsAt To</label>
            <div className='form-inline form-group'>
              {basedOnSettings.get('toDateMode') === 'abs' && <div className='mt-2'>
                <input className='form-control form-control-sm' type='datetime-local' min='0001-01-01T00:00' max='9999-12-31T23:59'
                  value={forecastWindowData.asAtWindow.absToDate}
                  onChange={e => updateTimeSeriesValue(['derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'asAtWindow', 'absToDate'], e.target.value)} />
              </div>}

              {basedOnSettings.get('toDateMode') === 'rel' && <div className='mt-2' >
                <RelativeDate controlClassName='form-control form-control-sm' options={['D', 'W', 'M', 'Y']}
                  value={forecastWindowData.asAtWindow.relToDate}
                  onChange={value => updateTimeSeriesValue(['derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'asAtWindow', 'relToDate'], value)} />
              </div>}

              <div className='form-check ml-2 mt-2'>
                <input className='form-check-input' type='radio' value='none' id='fw-to-abs'
                  checked={basedOnSettings.get('toDateMode') === 'abs'}
                  onChange={() => basedOnUpdateSettings('toDateMode', 'abs')} />
                <label className='form-check-label' htmlFor='fw-to-abs'>Absolute</label>
              </div>

              <div className='form-check ml-2 mt-2'>
                <input className='form-check-input' type='radio' value='none' id='fw-to-rel'
                  checked={basedOnSettings.get('toDateMode') === 'rel'}
                  onChange={() => basedOnUpdateSettings('toDateMode', 'rel')} />
                <label className='form-check-label' htmlFor='fw-to-rel'>Relative</label>
              </div>
            </div>
        </div>
     </div>        
     <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>AsAt selection operation</label>
          <select className='form-control' style={inputStyle}
            value={forecastWindowData.asAtSelectionOperation}
            onChange={e => updateTimeSeriesValue(['derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'asAtSelectionOperation'], e.target.value)}>
            {asAtOps.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>AsAt window relative to data points</label>
          <select className='form-control' style={inputStyle}
            value={forecastWindowData.asAtWindowRelativeToDataPoint}
            onChange={e => updateTimeSeriesValue(['derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'asAtWindowRelativeToDataPoint'], e.target.value)}>
            {simpleChoice.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
      </div>      
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Data point operation</label>
          <select className='form-control' style={inputStyle}
            value={forecastWindowData.dataPointOperation}
            onChange={e => updateTimeSeriesValue(['derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'dataPointOperation'], e.target.value)}>
            {operations.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
      </div>            
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Strict count for data point operation</label>
          <select className='form-control' style={inputStyle}
            value={forecastWindowData.strictCountForOperation}
            onChange={e => updateTimeSeriesValue(['derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'strictCountForOperation'], e.target.value)}>
            {simpleChoice.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
      </div>      
    </>
  );
}

export function AnalysisSeriesSettings({ timeSeries, updateTimeSeriesValue, requestRematerialiseWithRange, setMaterialiseStatusToEnabled, isContributor,
  basedOnSettings,
  basedOnAddKey, basedOnDeleteKey, basedOnUpdateSearchKeyText, basedOnUpdateSettings }) {

  const searchKeyText = basedOnSettings.get('searchKeyText');
  const selectedIdentity = basedOnSettings.get('selectedIdentity');
  const { derivationData, style } = timeSeries;
  const { derivationType, forecastSeriesData, materialisationStatus } = derivationData || {};
  const isVisible = style === 'Analysis';

  const operations = ['WAvg','Avg', 'Min', 'Max', 'Sum', 'Open', 'Close'];
  const asAtStyleOptions = ['None', 'Window' ]; // 'ForecastOffset', 'RelativeAsAt'];

  function onSearchKeyTextKeyPress(e) {
    const keyCode = e.charCode || e.keyCode || 0;
    if (keyCode === 13)
      basedOnAddKey();
  }
  return (
    isVisible ?
    <CollapsePanel idKey='timeSeriesEditorForecastSeriesSettings' headerStyle={{ cursor: 'pointer' }}
      title={<><span>Analysis</span>
        <a className='fota-small-text' href={`https://dev.azure.com/mercuria-fota/timeseries/_wiki/wikis/timeseries.wiki//476/Analysis`} target='_blank' rel='noopener noreferrer'>
          <i className='fas fa-book fa-fw' /> Wiki</a></>} >

      {isVisible && <div>
        <div className='row no-gutters'>
          <div className='col mb-1'>
            <div className='d-flex justify-content-between'>
              <label style={labelStyle}>Derivation Type <RequiredField /></label>
              <label style={labelStyle}>Materialisation Status = {materialisationStatus || 'NotSet'}</label>
            </div>
          </div>
        </div>          
        <div className='row no-gutters'>
          <div className='col mb-1'>
          <div className='d-flex justify-content-between'>
            <select className='form-control' style={inputStyle}
              value={derivationType || ''}
              onChange={e => updateTimeSeriesValue(['derivationData', 'derivationType'], e.target.value)}>
              <option>Standard</option>
              <option>Materialised</option>
              <option>Variant</option>
            </select>
            {isContributor && <Tooltip placement='bottom' title='Will rematerialise this and any dependant timeseries - see dependants tab'>
              <button type='button'
                      className='btn btn-outline-info ml-1' 
                      onClick={ requestRematerialiseWithRange }>
                  Rematerialise
              </button></Tooltip>}        
            {isContributor && materialisationStatus !== "Enabled" && <button data-tip='' 
                                      data-for='enable' 
                                      type='button'
                                      className='btn btn-outline-info ml-1' 
                                      onClick={ setMaterialiseStatusToEnabled }>
                  Enable
            </button>}
          </div>
          </div>
        </div>
      </div>}

      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Based on</label>
          <div className='input-group'>
            <input className='form-control' type='text' style={inputStyle} placeholder='Search for Identity' autoComplete='off' value={searchKeyText}
              onChange={e => basedOnUpdateSearchKeyText(e.target.value)}
              onKeyPress={onSearchKeyTextKeyPress} />
            <div className='input-group-append'>
              <button className='btn btn-secondary mr-1' title='Add identity' style={inputStyle} disabled={!searchKeyText} onClick={e => basedOnAddKey()} ><i className='fas fa-plus fa-fw' /></button>
            </div>
          </div>
        </div>
      </div>
      
      {selectedIdentity && <div className='row no-gutters'>
        <div className='row w-100' key={`dk${selectedIdentity.get('key')}`}>
          <div className='col' style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip placement='bottom' title={<>{selectedIdentity.get('identityId')} {selectedIdentity.get('name')}
              {selectedIdentity.get('granularity') && <><br/>Granularity:{selectedIdentity.get('granularity')}</>}
              {selectedIdentity.get('sourceTimeZoneId') && <><br/>TimeZone:{selectedIdentity.get('sourceTimeZoneId')}</>}</>}>
              <span>{selectedIdentity.get('identityId')} {selectedIdentity.get('name')}</span>
            </Tooltip>
          </div>
          <div className='btn-toolbar'>
            <button type='button' className='btn btn-sm' onClick={() => basedOnDeleteKey()}>
              <i className='fas fa-trash fa-fw' />
            </button> 
          </div>
        </div>
      </div>} 

      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Forecast Style <RequiredField /></label>
          <select className='form-control' style={inputStyle}
            value={forecastSeriesData.asAtType || ''}
            onChange={e => updateTimeSeriesValue(['derivationData','forecastSeriesData','asAtType'], e.target.value)}>
            {asAtStyleOptions.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
      </div>

      {forecastSeriesData.asAtType === 'Window' && <ForecastWindowSettings forecastWindowData={forecastSeriesData.asAt.forecastWindowData} 
                                                                           operations={operations}
                                                                           basedOnSettings={basedOnSettings}
                                                                           basedOnUpdateSettings={basedOnUpdateSettings}
                                                                           updateTimeSeriesValue={updateTimeSeriesValue} />}

    </CollapsePanel>
    : <></>
  );
}
