import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logWarningNotification,
  logErrorNotification
} from './log';
import { toJS } from '../utility/immutable-utility';

export const TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_STARTED = 'TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_STARTED';
export const timeSeriesDetailsDerivationKeysLoadStarted = createAction(TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_STARTED);

export const TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_COMPLETE = 'TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_COMPLETE';
export const timeSeriesDetailsDerivationKeysLoadComplete = createAction(TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_COMPLETE, 'data');

const toIdentityId = idKey => {
  if (idKey === null || idKey === undefined) return idKey;

  return `${idKey}`.split('|')[0];
};

export const timeSeriesDetailsDerivationKeysLoad = () => (dispatch, getState) => {
  dispatch(timeSeriesDetailsDerivationKeysLoadStarted());
  const state = getState();
  const inputKeys = toJS(state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'derivationData', 'keys']), []);
  const keys = inputKeys.map(inputKey => {
    if (typeof inputKey === 'string')
      return inputKey.trim();

    if (inputKey.type === 'Identity')
      return (inputKey.key ?? '').trim();

    return null;
  }).filter(i => i);

  if (!keys)
    return;

  const customFilter = `search.in(id,'${keys.map(k => toIdentityId(k)).join(',')}')`;
  return authFetch(`${ANALYSIS_API_ROOT_URL}/v2/timeseriessearch?customFilter=${customFilter}&top=1000`)
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      
      const results = [];
      inputKeys.forEach(inputKey => {
        if (typeof inputKey === 'string' || inputKey.type === 'Identity') {          
          const k = typeof inputKey === 'string' ? toIdentityId(inputKey) : inputKey.key;
          var timeSeries = data.results.find(i => `${i.id}` === `${k}`);
          if (timeSeries) {
            results.push({
              key: `${timeSeries.id}`,
              identityId: `${timeSeries.id}`,
              name: timeSeries.name,
              sourceTimeZoneId: timeSeries.sourceTimeZoneId,
              granularity: timeSeries.granularity,
              type: 'Identity'
            });
          } else {
            results.push({
              key: `${k}`,
              name: ''
            });
            dispatch(logWarningNotification(`Time series derivation key '${k}' not found.`));
          }
        } else if (typeof inputKey === 'object') {
          if (inputKey.type === 'Request') {
            const basket = toJS(state.getIn(['analysis', 'workspace', 'timeseries']), []);
            var requestTimeSeries = basket.find(r => `${r.key}` === `${inputKey.key}`);
            if (requestTimeSeries) {
              results.push({
                key: `${inputKey.key}`,
                identityId: `${requestTimeSeries.identityId}`,
                name: requestTimeSeries.name,
                sourceTimeZoneId: requestTimeSeries.sourceTimeZoneId,
                granularity: requestTimeSeries.granularity,
                type: inputKey.type
              });
            } else {
              results.push({
                key: `${inputKey.key}`,
                identityId: `${inputKey.key}`,
                name: '',
                type: inputKey.type
              });
              dispatch(logWarningNotification(`Time series derivation key '${inputKey.key}' is not in the basket.`));
            }
          } else {
            results.push({
              key: `${inputKey.key}`,
              identityId: `${inputKey.key}`,
              name: inputKey.name,
              type: inputKey.type
            });
          }
        }
      });

      dispatch(timeSeriesDetailsDerivationKeysLoadComplete(results));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
}

export const TIMESERIES_DETAILS_DERIVATION_KEYS_SAVE = 'TIMESERIES_DETAILS_DERIVATION_KEYS_SAVE';
export const timeSeriesDetailsDerivationKeysSave = createAction(TIMESERIES_DETAILS_DERIVATION_KEYS_SAVE);

export const TIMESERIES_DETAILS_DERIVATION_UPDATE_SEARCH_KEY_TEXT = 'TIMESERIES_DETAILS_DERIVATION_UPDATE_SEARCH_KEY_TEXT';
export const timeSeriesDetailDerivationUpdateSearchKeyText = createAction(TIMESERIES_DETAILS_DERIVATION_UPDATE_SEARCH_KEY_TEXT, 'value');

export const timeSeriesDerivationAddKey = () => (dispatch, getState) => {
  const state = getState();
  const searchKeyText = (state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'derivationSettings', 'searchKeyText']) || '').trim();
  const selectedKeys = toJS(state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'derivationSettings', 'selectedKeys']), []);
  if (!searchKeyText) return;

  dispatch(timeSeriesDetailsDerivationAddKeyStarted());
  const customFilter = `id eq '${searchKeyText}'`;
  return authFetch(`${ANALYSIS_API_ROOT_URL}/v2/timeseriessearch?customFilter=${customFilter}&top=1000`)
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      const [identity] = data.results ?? [];
      if (!identity) {
        dispatch(logWarningNotification(`Time series '${searchKeyText}' not found.`));
      }
      else {
        if (selectedKeys.some(i => `${i.id}` === `${identity.id}`)) {
          dispatch(logWarningNotification(`Time series '${searchKeyText}' is already in the keys collection.`));
        }
      }

      dispatch(timeSeriesDetailsDerivationAddKeyCompleted(identity));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_DERIVATION_ADD_KEY_STARTED = 'TIMESERIES_DETAILS_DERIVATION_ADD_KEY_STARTED';
export const timeSeriesDetailsDerivationAddKeyStarted = createAction(TIMESERIES_DETAILS_DERIVATION_ADD_KEY_STARTED);

export const TIMESERIES_DETAILS_DERIVATION_ADD_KEY_COMPLETED = 'TIMESERIES_DETAILS_DERIVATION_ADD_KEY_COMPLETED';
export const timeSeriesDetailsDerivationAddKeyCompleted = createAction(TIMESERIES_DETAILS_DERIVATION_ADD_KEY_COMPLETED, 'data');

export const TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_UP = 'TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_UP';
export const timeSeriesDetailsDerivationMoveKeyUp = createAction(TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_UP, 'key');

export const TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_DOWN = 'TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_DOWN';
export const timeSeriesDetailsDerivationMoveKeyDown = createAction(TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_DOWN, 'key');

export const TIMESERIES_DETAILS_DERIVATION_DELETE_KEY = 'TIMESERIES_DETAILS_DERIVATION_DELETE_KEY';
export const timeSeriesDetailsDerivationDeleteKey = createAction(TIMESERIES_DETAILS_DERIVATION_DELETE_KEY, 'key');

export const TIMESERIES_DETAILS_DERIVATION_ADD_KEYS = 'TIMESERIES_DETAILS_DERIVATION_ADD_KEYS';
export const timeSeriesDetailsDerivationAddKeys = () => (dispatch, getState) => {
  const state = getState();
  const selectedTimeSeries = toJS((state.getIn(['timeseriesSearch2', 'derivationKeySearch', 'selectedTimeSeries']) || ''), []);
  dispatch(timeSeriesDetailsDerivationAddKeysComplete(selectedTimeSeries));
}

export const TIMESERIES_DETAILS_DERIVATION_ADD_KEYS_COMPLETE = 'TIMESERIES_DETAILS_DERIVATION_ADD_KEYS_COMPLETE';
export const timeSeriesDetailsDerivationAddKeysComplete = createAction(TIMESERIES_DETAILS_DERIVATION_ADD_KEYS_COMPLETE, 'data');

export const timeSeriesDetailsDerivationMoveFromBasketToKeys = () => (dispatch, getState) => {
  dispatch(timeSeriesDetailsDerivationMoveFromBasketToKeysBegin());
  const state = getState();
  const basket = toJS(state.getIn(['analysis', 'workspace', 'timeseries'])) ?? [];
  dispatch(timeSeriesDetailsDerivationMoveFromBasketToKeysComplete(basket));
}

export const TIMESERIES_DETAILS_DERIVATION_MOVE_FROM_BASKET_TO_KEYS_BEGIN = 'TIMESERIES_DETAILS_DERIVATION_MOVE_FROM_BASKET_TO_KEYS_BEGIN';
const timeSeriesDetailsDerivationMoveFromBasketToKeysBegin = createAction(TIMESERIES_DETAILS_DERIVATION_MOVE_FROM_BASKET_TO_KEYS_BEGIN);

export const TIMESERIES_DETAILS_DERIVATION_MOVE_FROM_BASKET_TO_KEYS_COMPLETE = 'TIMESERIES_DETAILS_DERIVATION_MOVE_FROM_BASKET_TO_KEYS_COMPLETE';
const timeSeriesDetailsDerivationMoveFromBasketToKeysComplete = createAction(TIMESERIES_DETAILS_DERIVATION_MOVE_FROM_BASKET_TO_KEYS_COMPLETE, 'basket');

export const TIMESERIES_DETAILS_DERIVATION_TOGGLE_ALT_SELECTION = 'TIMESERIES_DETAILS_DERIVATION_TOGGLE_ALT_SELECTION';
export const timeSeriesDetailsDerivationToggleAltSelection = createAction(TIMESERIES_DETAILS_DERIVATION_TOGGLE_ALT_SELECTION, 'inputKey');

export const TIMESERIES_DETAILS_DERIVATION_SELECT_ALT_ALL = 'TIMESERIES_DETAILS_DERIVATION_SELECT_ALT_ALL';
export const timeSeriesDetailsDerivationSelectAltAll = createAction(TIMESERIES_DETAILS_DERIVATION_SELECT_ALT_ALL, 'value');

export const TIMESERIES_DETAILS_DERIVATION_ADD_ALT_SELECTION = 'TIMESERIES_DETAILS_DERIVATION_ADD_ALT_SELECTION';
export const timeSeriesDetailsDerivationAddAltSelection = createAction(TIMESERIES_DETAILS_DERIVATION_ADD_ALT_SELECTION);