import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { SchemaFacetItems } from './SchemaFacetItems';
import { formatTitle } from '../../../utility/analysis-search-utility';
import FormattedNumber from '../../shared/FormattedNumber';

export class SchemaFacets extends Component {
  static propTypes = {
    schemaFacets: PropTypes.arrayOf(PropTypes.shape({
      schemaName: PropTypes.string.isRequired,
      colour: PropTypes.string.isRequired,
      resultsCount: PropTypes.number.isRequired,
      errorMessage: PropTypes.string
    })),
    isMultiSelect: PropTypes.bool.isRequired,
    toggleSchemaExpansion: PropTypes.func.isRequired,
    toggleSchemaSelection: PropTypes.func.isRequired,
    toggleSchemaFacetValue: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isMultiSelect, schemaFacets, toggleSchemaFacetValue, toggleSchemaExpansion, toggleSchemaSelection } = this.props;
    return schemaFacets.map((schemaFacet, fi) => {
      const hasErrors = schemaFacet.errorMessage && schemaFacet.errorMessage.length > 0;
      return <div data_id={`facet-${fi}`} key={`r-${fi}`} className='mb-1'>
        <div style={{ color: schemaFacet.colour }} className='fota-cross-schema-schemafacets-title'>
          <span data_id='name'>{formatTitle(schemaFacet.schemaName)}</span>
          {!hasErrors && schemaFacet.resultsCount !== undefined && <span className='badge ml-2'><FormattedNumber>{schemaFacet.resultsCount}</FormattedNumber></span>}
          {hasErrors && <>
            <div style={{ fontWeight: 'bold' }} className='mb-2'>
              <span className="text-danger mr-1" ><i className="fas fa-exclamation-triangle fa-fw"></i></span>
              <span style={{ color: 'black' }}>{schemaFacet.errorMessage}</span>
            </div>
          </>
          }
        </div>
        <div data_id='facet-list' key={`sf-${fi}`} className='fota-schema-facets fota-facets container-fluid d-flex pl-0 pr-3' style={{ flex: '1 0 auto' }}>
          {schemaFacet.facets.map((facet, di) => <SchemaFacetItems data_id={`facet-items-${di}`} key={`ff-${di}`} isMultiSelect={isMultiSelect} facet={facet}
            toggleSchemaFacetValue={toggleSchemaFacetValue}
            toggleSchemaExpansion={toggleSchemaExpansion}
            toggleSchemaSelection={toggleSchemaSelection}
            schemaName={schemaFacet.schemaName} schemaColour={schemaFacet.colour} isExpanded={schemaFacet.isExpanded === true} />)}
          {!hasErrors && schemaFacet.facets.length === 0 && 
          <ul className='facet-group list-group mr-2' style={{opacity:'0.4'}}>
            <li className='list-group-item list-group-header d-flex align-items-center justify-content-center' style={{ backgroundColor: schemaFacet.colour, color: 'White' }}><span>No results</span></li>
          </ul>}
        </div>
      </div>
    });
  }
}