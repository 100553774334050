import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import { getTimeSeries, mapToTimeSeries } from '../../../utility/analysis-basket-utility';
import Timezone from '../../shared/Timezone';
import Table from '../../shared/Table';
import ConfirmButton from '../../shared/ConfirmButton';
import IdentityId from '../../shared/IdentityId2';
import FormattedDateTime from '../../shared/FormattedDateTime';
import {
  analysisSearchToggleInBasket,
  analysisSelectionAddOnDemandDerivedSelection,
  analysisAddRangeSelectionByCsvIds
} from '../../../actions/analysis';
import {
  analysisSelectionBasketRemoveAll
} from '../../../actions/analysis-basket-v2';
import {
  analysisCompositionToggleSelected,
  analysisCompositionRefreshSelected
} from '../../../actions/analysis-composition-v2';
import { OnDemandDerivedDetailsConnected } from '../onDemandDerivedDetails/OnDemandDerivedDetailsConnected';
import { IdentityQuickInput } from './IdentityQuickInput';
import { DynamicWorkspaceToolbarConnected } from './dynamic-workspace/DynamicWorkspaceToolbarConnected';
import { DebugPopup } from '../../shared/DebugPopup';
import JsonEditor from '../../shared/JsonEditor';

const TimeseriesRow = function ({ index, rawData, removeSelection, basketTimeseries }) {
  const { identityId, source, name, dataType, style, granularity, unit, sourceTimeZoneId, derivationType, lastUpdatedUtc, queryCount, derivationData, key /*, dynamicWorkspace*/ } = rawData;
  return <tr data_id={`row-${index}`} className={'border-top-0 fota-analysis-table-row-hover'}>
    <td data_id='identity-id' className='text-right'>
      <div className='d-flex justify-content-end flex-nowrap' style={{ width: '150px' }}>
        {identityId > 0 && <IdentityId isCompactView={true} isFavourite={false} timeSeriesKey={key}>{identityId}</IdentityId>}
        {derivationData && <OnDemandDerivedDetailsConnected basketTimeseries={basketTimeseries} timeSeriesKey={key} />}
      </div>
    </td>
    <td data_id='source' className='text-nowrap' style={{ minWidth: '120px' }}>{source}</td>
    <td data_id='name' className='w-100'><DebugPopup>{() => <JsonEditor data={rawData}/>}</DebugPopup>
    {/* {dynamicWorkspace && dynamicWorkspace.colour && <span style={{backgroundColor:dynamicWorkspace.colour, width:'3px', height:'100%', marginRight:'1px'}}>&nbsp;</span>} */}
    {name}</td>
    <td>{dataType}</td>
    <td>{style}</td>
    <td className='text-nowrap'>{derivationType}</td>
    <td className='text-nowrap'>{granularity}</td>
    <td className='text-nowrap'>{sourceTimeZoneId}</td>
    <td className='text-nowrap'>{unit}</td>
    <td className='text-nowrap text-right'><FormattedDateTime>{lastUpdatedUtc}</FormattedDateTime></td>
    <td className='text-right'>{queryCount && Number(queryCount).toLocaleString()}</td>
    <td className='py-1'>
      <button data_id='deselect' type='button' className='btn btn-sm btn-link fota-btn-toolbar' onClick={() => removeSelection(rawData)}>
          <i className='fas fa-trash fa-fw'></i>
      </button>
    </td>
  </tr>
}

export const SelectionBasketComponent = connect(
  (state) => ({
    basket: state.getIn(['analysis', 'workspace', 'timeseries']),
    userTimeSeriesSettings: state.getIn(['userSettings', 'settings', 'timeSeries']),
    dynamicWorkspaceToolbarIsPinned: state.getIn(['analysis', 'workspace', 'dynamicWorkspaceIsPinned'])
  }),
  (dispatch) => ({
    removeSelection(basketItem) {
      dispatch(analysisSearchToggleInBasket(basketItem.identityId, mapToTimeSeries(basketItem)));
      dispatch(analysisCompositionToggleSelected(basketItem.identityId));
    },
    removeAllSelections() {
      dispatch(analysisSelectionBasketRemoveAll());
      dispatch(analysisCompositionRefreshSelected());
    },
    addOnDemandDerivedSelection(userTimeSeriesSettings) {
      dispatch(analysisSelectionAddOnDemandDerivedSelection(userTimeSeriesSettings));
      dispatch(analysisCompositionRefreshSelected());
    },
    addSelectionByCsv(value) {
      dispatch(analysisAddRangeSelectionByCsvIds(value));
    },
  })
)(({ toggleIsPinned, dynamicWorkspaceToolbarIsPinned = true, basket, removeSelection, removeAllSelections, addOnDemandDerivedSelection, userTimeSeriesSettings, addSelectionByCsv }) => {
  const _basket = toJS(basket, []);
  const _timeSeries = getTimeSeries({basket:_basket});

  let _columns = [
    {
      title: 'Id'
    },
    {
      title: 'Source'
    },
    {
      title: 'Name'
    },
    {
      title: 'Type'
    },
    {
      title: 'Style'
    },
    {
      title: 'Derivation Type'
    },
    {
      title: 'Granularity'
    },
    {
      title: 'Source Timezone'
    },
    {
      title: 'Unit'
    },
    {
      title: <span>Last Updated <Timezone /></span>
    },
    {
      title: 'Queries'
    },
    {
    }];

  function onAddOnDemandDerived() {
    addOnDemandDerivedSelection(toJS(userTimeSeriesSettings, {}));
  }

  return <>
    <div data_id='selection-basket' className='w-100 d-flex justify-content-between p-1'>
      <div className='d-flex'>
        <div className="mr-2">
          <button className='btn btn-sm btn-outline-primary' type='button' onClick={toggleIsPinned}>
            <i className='fas fa-thumbtack fa-fw'></i>
          </button>
        </div>

        <div className="mr-2">
          <IdentityQuickInput addSelectionByCsv={addSelectionByCsv} />
        </div>

        <div className="mr-2">
          <button className='btn btn-sm btn-secondary' type='button' onClick={onAddOnDemandDerived}>
            <i className='fas fa-sitemap fa-fw mr-1' />Add Derived
          </button>
        </div>

        <DynamicWorkspaceToolbarConnected/>
      </div>

      <div className="d-flex">
        <ConfirmButton type='button' className='btn btn-sm btn-link fota-btn-toolbar' confirmClassName='btn btn-sm btn-link btn-warning fota-btn-toolbar fota-color-black' onClick={removeAllSelections}
          content={<i style={{ color: 'rgba(0, 0, 0, .5)' }} className='fas fa-trash fa-fw'></i>}
          confirm={<><i style={{ color: 'rgba(0, 0, 0, .5)' }} className='fas fa-trash fa-fw'></i><span>?</span></>} />
      </div>
    </div>

    <div className='sticky-table w-100' style={{ height: 'calc(100% - 36px)' }} >
      <Table data_id='selected-items' className='table-striped' columns={_columns} data={_timeSeries} useTBody={true} useStickyHeader={true} onSelectKey={i => i.identityId}>
        <TimeseriesRow
          removeSelection={removeSelection}
          basketTimeseries={_basket} />
      </Table>
    </div>
  </>
});