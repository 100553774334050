import React from 'react';
import ScreenCaptureTableRow from './ScreenCaptureTableRow';
import ScreenCaptureTableEditRowConnected from './ScreenCaptureTableEditRowConnected';

export default function ScreenCaptureTable({ isEditing, selectedId, editId, actions, openEdit, selectAction, deleteAction, goToAction }) {
  return (
    <div className='table-responsive sticky-table w-100' style={{ height: '500px' }}>
      <table className='table table-sm table-hover'>
        <thead>
          <tr className='sticky-header'>
            <th style={{ width: '30px' }} />
            <th style={{ width: '30px' }}>Enabled?</th>
            <th style={{ width: '100px' }} className='text-center'>Cron</th>
            <th style={{ width: '250px' }}>Subject</th>
            <th style={{ width: '300px' }}>Recipients</th>
            <th style={{ minWidth: '300px' }}>Target Url</th>
            <th style={{ width: '30px' }} />
            <th style={{ width: '25px' }} />
          </tr>
        </thead>
        <tbody>
          { actions.map((i, ix) => (
            <ScreenCaptureTableRow
              key={ix}
              isEditing={isEditing}
              selectedId={selectedId}
              editId={editId}
              action={i}
              openEdit={openEdit}
              selectAction={selectAction}
              deleteAction={deleteAction}
              goToAction={goToAction} />
          )) }
          { (isEditing && editId === 0) && <ScreenCaptureTableEditRowConnected /> }
        </tbody>
      </table>
    </div>
  );
}