import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import {
  analysisSearchAddRangeToBasket,
  analysisSearchRemoveRangeFromBasket,
} from '../../../actions/analysis';
import {
  analysisCrossSchemaSearchToggleExpandFacets,
  analysisCrossSchemaSearchToggleExpandDetails,
  analysisCrossSchemaSearchUpdateCustomFilterHeight,
  analysisCrossSchemaSearchAddFavourites,
  analysisCrossSchemaSearchRemoveFavourites,
  analysisCrossSchemaSearchExport,
  analysisCrossSchemaSearchSetSearchQueryText,
  analysisCrossSchemaSearchSetSearchCustomFilterText,
  analysisCrossSchemaSearch,
  analysisCrossSchemaSearchSetSearchSelectionMode,
  analysisCrossSchemaSearchClearSchemaFacetFilters,
  analysisCrossSchemaSearchToggleFavouritesFilter,
  analysisCrossSchemaSearchToggleExcludeDiscontinuedFilter,
} from '../../../actions/analysis-cross-schema-search';
import { Toolbar } from './Toolbar';

export const ToolbarConnected = connect(
  (state, { stateContext = 'default' }) => ({
    data: state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'data']),
    displayOptions: state.getIn(['analysisCrossSchemaSearch', stateContext, 'displayOptions']),
    query: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'query']),
    isMultiSelect: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'isMultiSelect']),
    filtersCount: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'filtersCount']),
    schemas: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'schemas']),
    customFilter: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'customFilter']),
    customFilterHeight: state.getIn(['analysisCrossSchemaSearch', stateContext, 'customFilterHeight']),
    discontinuedFilterMonths: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'discontinuedFilterMonths']),
    enableDiscontinuedFilter: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'enableDiscontinuedFilter']),
    enableFavouritesFilter: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'enableFavouritesFilter']),
    enableExpandFacets: state.getIn(['analysisCrossSchemaSearch', stateContext, 'enableExpandFacets']),
    enableExpandDetails: state.getIn(['analysisCrossSchemaSearch', stateContext, 'enableExpandDetails']),
    filters: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'filters']),
    basket: state.getIn(['analysis', 'workspace', 'timeseries']),
  }),
  (dispatch, { stateContext = 'default', searchHandler }) => ({
    search() {
      if (searchHandler) {
        searchHandler(stateContext);
      } else {
        dispatch(analysisCrossSchemaSearch(stateContext));
      }
    },
    updateCustomFilterHeight(value) {
      dispatch(analysisCrossSchemaSearchUpdateCustomFilterHeight(stateContext, value));
    },
    toggleDiscontinuedFilter(isMultiSelect) {
      dispatch(analysisCrossSchemaSearchToggleExcludeDiscontinuedFilter(stateContext));
      if (!isMultiSelect) {
        dispatch(analysisCrossSchemaSearch(stateContext));
        dispatch(analysisCrossSchemaSearchToggleExcludeDiscontinuedFilter(stateContext));
      }
    },
    toggleFavouritesFilter(isMultiSelect) {
      dispatch(analysisCrossSchemaSearchToggleFavouritesFilter(stateContext));
      if (!isMultiSelect) {
        dispatch(analysisCrossSchemaSearch(stateContext));
      }
    },
    addFavourites(ids) {
      dispatch(analysisCrossSchemaSearchAddFavourites(stateContext, ids));
    },
    removeFavourites(ids) {
      dispatch(analysisCrossSchemaSearchRemoveFavourites(stateContext, ids));
    },
    setSearchSelectionMode(isMultiSelect) {
      dispatch(analysisCrossSchemaSearchSetSearchSelectionMode(stateContext, isMultiSelect));
      if (!isMultiSelect) {
        dispatch(analysisCrossSchemaSearch(stateContext));
      }
    },
    clearFilters() {
      dispatch(analysisCrossSchemaSearchClearSchemaFacetFilters(stateContext));
      dispatch(analysisCrossSchemaSearch(stateContext));
    },
    exportData() {
      dispatch(analysisCrossSchemaSearchExport(stateContext));
    },
    toggleExpandFacets() {
      dispatch(analysisCrossSchemaSearchToggleExpandFacets(stateContext));
    },
    toggleExpandDetails() {
      dispatch(analysisCrossSchemaSearchToggleExpandDetails(stateContext));
    },
    addRangeSelection(keys, data) {
      dispatch(analysisSearchAddRangeToBasket(keys, data));
    },
    removeRangeSelection(keys) {
      dispatch(analysisSearchRemoveRangeFromBasket(keys));
    },
    setQuery(text) {
      dispatch(analysisCrossSchemaSearchSetSearchQueryText(stateContext, text));
    },
    setCustomFilter(text) {
      dispatch(analysisCrossSchemaSearchSetSearchCustomFilterText(stateContext, text));
    }
  })
)(({ stateContext,
  isCompactView = false, displayOptions, 
  data, filtersCount,
  query = '', setQuery, customFilter = '', setCustomFilter, customFilterHeight, updateCustomFilterHeight,
  enableDiscontinuedFilter, discontinuedFilterMonths, toggleDiscontinuedFilter,
  enableFavouritesFilter, toggleFavouritesFilter,
  addFavourites, removeFavourites, search, clearFilters, exportData,
  isMultiSelect, setSearchSelectionMode,
  addRangeSelection, removeRangeSelection,
  enableExpandFacets, enableExpandDetails, toggleExpandFacets, toggleExpandDetails, basket }) => {

  let isActiveTest;
  isActiveTest = useCallback((id) => {
    return toJS(basket, []).some(ts => `${ts.identityId}` === `${id}`)
  }, [basket]);

  const [selectCount, unselectCount] = useMemo(() => {
    const selectedCount = data.filter(i => isActiveTest(i.get('id'))).size;
    return [data.size - selectedCount, selectedCount];
  }, [data, isActiveTest]);

  const [favouriteCount, unfavouriteCount] = useMemo(() => {
    const selectedCount = data.filter(i => i.get('isFavourite')).size;
    return [data.size - selectedCount, selectedCount];
  }, [data]);

  const _displayOptions = toJS(displayOptions);
  return <Toolbar stateContext={stateContext} displayOptions={_displayOptions} queryText={query} isCompactView={isCompactView}
    data={data} filtersCount={filtersCount}
    query={query} setQuery={setQuery}
    customFilter={customFilter} setCustomFilter={setCustomFilter} customFilterHeight={Number(customFilterHeight)} updateCustomFilterHeight={updateCustomFilterHeight}
    enableDiscontinuedFilter={enableDiscontinuedFilter} discontinuedFilterMonths={discontinuedFilterMonths} toggleDiscontinuedFilter={toggleDiscontinuedFilter}
    enableFavouritesFilter={enableFavouritesFilter} toggleFavouritesFilter={toggleFavouritesFilter}
    addFavourites={addFavourites} removeFavourites={removeFavourites}
    isMultiSelect={isMultiSelect}
    search={search} clearFilters={clearFilters} exportData={exportData}
    setSearchSelectionMode={setSearchSelectionMode}
    addRangeSelection={addRangeSelection} removeRangeSelection={removeRangeSelection}
    enableExpandFacets={enableExpandFacets} enableExpandDetails={enableExpandDetails} toggleExpandFacets={toggleExpandFacets} toggleExpandDetails={toggleExpandDetails}
    selectCount={selectCount} unselectCount={unselectCount}
    favouriteCount={favouriteCount} unfavouriteCount={unfavouriteCount} />
});