import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import {
  dashboardTileEditAddNew,
  dashboardTileEditSetValue
} from '../../../actions/dashboard-tiles';

import { toJS } from '../../../utility/immutable-utility';
import { createNewTileContent } from '../../../mapper/dashboardTileMapper';

export const TileBuilderConnected = connect(
  (state) => ({
    editState: state.getIn(['dashboardTiles', 'editorState'])
  }),
  (dispatch) => ({
    setEditState(key, value) {
      dispatch(dashboardTileEditSetValue(key, value));
    },
    editAddTile({ dashboardTileType, properties }) {
      dispatch(dashboardTileEditAddNew(dashboardTileType, properties));
    },
  })
)(({ editState, setEditState, editAddTile }) => {
  const tileType = toJS(editState.get('newTileType')) ?? null;
  const propertyBag = toJS(editState.get('newTilePropertyBag'));

  const _tileContent = createNewTileContent(tileType);
  if (!_tileContent)
    return <></>

  function setProperty(props) {
    const newValue = { ...propertyBag, ...props };
    setEditState('newTilePropertyBag', newValue);
  }

  function executeCreate() {
    editAddTile({ dashboardTileType: propertyBag.dashboardTileType, properties: propertyBag });
    setEditState('newTileType', null);
  }

  function onClose() {
    setEditState('newTileType', null);
  }

  return <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth='lg' >
    {_tileContent.Create({ propertyBag, setProperty, executeCreate, cancelCreate: onClose })}
  </Dialog>
});
