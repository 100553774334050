import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { WORKSPACES_API_URL, WORKSPACES_API_URL_ROOT } from '../config';
import { logErrorNotification } from './log';
import qs from 'querystring';

export const HOMEPAGE_INITIALISED = 'HOMEPAGE_INITIALISED';
export const homepageInitialised = createAction(HOMEPAGE_INITIALISED);

export const HOMEPAGE_LOAD_STARTED = 'HOMEPAGE_LOAD_STARTED';
export const homepageLoadStarted = createAction(HOMEPAGE_LOAD_STARTED);

export const HOMEPAGE_LOAD_STOPPED = 'HOMEPAGE_LOAD_STOPPED';
export const homepageLoadStopped = createAction(HOMEPAGE_LOAD_STOPPED, 'data');

export const HOMEPAGE_LOAD_COMPLETE = 'HOMEPAGE_LOAD_COMPLETE';
export const homepageLoadComplete = createAction(HOMEPAGE_LOAD_COMPLETE, 'data');

export const homepageRefresh = () => (dispatch, getState) => {
  const state = getState();
  const scope = state.getIn(['homepage', 'scope']);
  const path = state.getIn(['homepage', 'path']);
  const onlyFavourites = state.getIn(['homepage', 'onlyFavourites']);
  const isSearchMode = state.getIn(['homepage', 'isSearchMode']);
  const searchText = state.getIn(['homepage', 'searchText']);
  const orderBy = state.getIn(['homepage', 'orderBy']);
  const orderByDirection = state.getIn(['homepage', 'orderByDirection']);

  let uri = `${WORKSPACES_API_URL_ROOT}/v2/homepage`;
  let params = {
    scope: scope || (path ? 'shared' : ''),
    path: path || ''
  };

  if (isSearchMode || onlyFavourites) {
    uri = `${uri}/search`;
    params.searchTerm = searchText;
    params.onlyFavourites = onlyFavourites;
  }

  if (orderBy && orderByDirection)
    params.orderBy = `${orderBy} ${orderByDirection}`;

  dispatch(homepageLoadStarted());

  return authFetch(`${uri}?${qs.stringify(params)}`)
    .then(response => response.json())
    .then(data => {
      dispatch(homepageLoadComplete(data));
    })
    .catch(error => {
      const parts = path.split('/').filter(i => !!i);
      const folder = parts[0];
      const subFolder = (parts.length === 0) ? '' : parts.slice(1).join('/');
      dispatch(homepageLoadStopped({scope, folder , subFolder}));
      dispatch(logErrorNotification(error));
    });
};

export const HOMEPAGE_SET_ITEM_FAVOURITE = 'HOMEPAGE_SET_ITEM_FAVOURITE';
export const homepageSetItemFavourite = createAction(HOMEPAGE_SET_ITEM_FAVOURITE, 'workspaceId', 'isFavourite');

export const HOMEPAGE_TOGGLE_ONLY_FAVOURITES = 'HOMEPAGE_TOGGLE_ONLY_FAVOURITES';
export const homepageToggleOnlyFavourites = createAction(HOMEPAGE_TOGGLE_ONLY_FAVOURITES);

export const homepageAddFavourite = (workspaceId, onSuccess) => (dispatch, getState) => {
  return authFetch(`${WORKSPACES_API_URL_ROOT}/v1/homepagefavourite/${workspaceId}`, {
    method: 'PUT'
  })
  .then(() => {
    dispatch(homepageSetItemFavourite(workspaceId, true));

    if (typeof onSuccess === 'function') onSuccess();
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const homepageDeleteFavourite = (workspaceId, onSuccess) => (dispatch, getState) => {
  return authFetch(`${WORKSPACES_API_URL_ROOT}/v1/homepagefavourite/${workspaceId}`, {
    method: 'DELETE'
  })
  .then(() => {
    dispatch(homepageSetItemFavourite(workspaceId, false));

    if (typeof onSuccess === 'function') onSuccess();
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const homepageImageUpload = (formData, onSuccess) => (dispatch, getState) => {
  dispatch(homepageUploadImagesStarted());

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v1/homepageimage/upload`, {
    method: 'POST',
    body: formData
  })
  .then(() => {
    dispatch(homepageUploadImagesComplete());

    if (typeof onSuccess === 'function') onSuccess();
  })
  .catch(error => {
    dispatch(homepageUploadImagesStopped());
    dispatch(logErrorNotification(error));
  });
};

export const HOMEPAGE_UPLOAD_IMAGE_STARTED = 'HOMEPAGE_UPLOAD_IMAGE_STARTED';
export const homepageUploadImagesStarted = createAction(HOMEPAGE_UPLOAD_IMAGE_STARTED);

export const HOMEPAGE_UPLOAD_IMAGE_STOPPED = 'HOMEPAGE_UPLOAD_IMAGE_STOPPED';
export const homepageUploadImagesStopped = createAction(HOMEPAGE_UPLOAD_IMAGE_STOPPED);

export const HOMEPAGE_UPLOAD_IMAGE_COMPLETE = 'HOMEPAGE_UPLOAD_IMAGE_COMPLETE';
export const homepageUploadImagesComplete = createAction(HOMEPAGE_UPLOAD_IMAGE_COMPLETE);

export const HOMEPAGE_LOAD_IMAGES_STARTED = 'HOMEPAGE_LOAD_IMAGES_STARTED';
export const homepageLoadImagesStarted = createAction(HOMEPAGE_LOAD_IMAGES_STARTED);

export const HOMEPAGE_LOAD_IMAGES_STOPPED = 'HOMEPAGE_LOAD_IMAGES_STOPPED';
export const homepageLoadImagesStopped = createAction(HOMEPAGE_LOAD_IMAGES_STOPPED);

export const HOMEPAGE_LOAD_IMAGES_COMPLETE = 'HOMEPAGE_LOAD_IMAGES_COMPLETE';
export const homepageLoadImagesComplete = createAction(HOMEPAGE_LOAD_IMAGES_COMPLETE, 'data');

export const homepageImagesRefresh = (onSuccess) => (dispatch, getState) => {
  dispatch(homepageLoadImagesStarted());

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v2/homepageimages`)
    .then(response => response.json())
    .then(data => {
      dispatch(homepageLoadImagesComplete(data));

    if (typeof onSuccess === 'function') onSuccess();
    })
    .catch(error => {
      dispatch(homepageLoadImagesStopped());
      dispatch(logErrorNotification(error));
    });
};

export const homepageSetFolderImage = (scope, itemPath, imageId, onSuccess) => (dispatch, getState) => {
  const params = {
    fullFolderPath: scope === 'Private' && itemPath === 'Private' ? '/' : itemPath,
    imageId: imageId
  };

  return authFetch(`${WORKSPACES_API_URL}/homepagefolder/${scope}/image?${qs.stringify(params)}`, {
    method: 'PUT'
  })
  .then(() => {
    if (typeof onSuccess === 'function') onSuccess();
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const homepageDeleteFolderImage = (scope, itemPath, onSuccess) => (dispatch, getState) => {
  const params = {
    fullFolderPath: scope === 'Private' && itemPath === 'Private' ? '/' : itemPath
  };

  return authFetch(`${WORKSPACES_API_URL}/homepagefolder/${scope}/image?${qs.stringify(params)}`, {
    method: 'DELETE'
  })
  .then(() => {
    if (typeof onSuccess === 'function') onSuccess();
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const homepageSetItemImage = (itemId, imageId, onSuccess) => (dispatch, getState) => {
  const params = {
    workspaceId: itemId,
    imageId: imageId
  };

  return authFetch(`${WORKSPACES_API_URL}/homepageitem/image?${qs.stringify(params)}`, {
    method: 'PUT'
  })
  .then(() => {
    if (typeof onSuccess === 'function') onSuccess();
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const homepageDeleteItemImage = (itemId, onSuccess) => (dispatch, getState) => {
  const params = {
    workspaceId: itemId
  };

  return authFetch(`${WORKSPACES_API_URL}/homepageitem/image?${qs.stringify(params)}`, {
    method: 'DELETE'
  })
  .then(() => {
    if (typeof onSuccess === 'function') onSuccess();
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const HOMEPAGE_SET_SELECTED_IMAGE = 'HOMEPAGE_SET_SELECTED_IMAGE';
export const homepageSetSelectedImage = createAction(HOMEPAGE_SET_SELECTED_IMAGE, 'id');

export const HOMEPAGE_SET_DISPLAY_MODE = 'HOMEPAGE_SET_DISPLAY_MODE';
export const homepageSetDisplayMode = createAction(HOMEPAGE_SET_DISPLAY_MODE, 'value');

export const HOMEPAGE_SET_ORDER_BY = 'HOMEPAGE_SET_ORDER_BY';
export const homepageSetOrderBy = createAction(HOMEPAGE_SET_ORDER_BY, 'name', 'direction');

export const HOMEPAGE_SET_SEARCH_TEXT = 'HOMEPAGE_SET_SEARCH_TEXT';
export const homepageSetSearchText = createAction(HOMEPAGE_SET_SEARCH_TEXT, 'value');

export const HOMEPAGE_ENABLE_SEARCH_MODE = 'HOMEPAGE_ENABLE_SEARCH_MODE';
export const homepageEnableSearchMode = createAction(HOMEPAGE_ENABLE_SEARCH_MODE);

export const HOMEPAGE_CLEAR_SEARCH = 'HOMEPAGE_CLEAR_SEARCH';
export const homepageClearSearch = createAction(HOMEPAGE_CLEAR_SEARCH);

export const HOMEPAGE_SET_LOCATION = 'HOMEPAGE_SET_LOCATION';
export const homepageSetLocation = createAction(HOMEPAGE_SET_LOCATION, 'path', 'scope');