import React from 'react';
import moment from 'moment';

const viewLookUp = {
  'Day': 'Date',
  'Week': 'Date',
  'Month': 'Month',
  'Quarter': 'Quarter',
  'GasSeason': 'GasSeason',
  'GasYear': 'GasYear',
  'Year': 'Year'
};

class LensedDateTime extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: moment.utc(props.value)
    }
  }

  componentDidUpdate(prevProps) {
    let newValue = moment.utc(this.props.value);

    if (prevProps.lens !== this.props.lens) {
      switch (viewLookUp[this.props.lens] ?? 'DateTime') {
        case 'Month':
          newValue = newValue.date(1).hour(0).seconds(0); break;
        case 'Quarter':
          newValue = newValue.month(0).date(1).hour(0).seconds(0); break;
        case 'GasSeason':
          newValue = newValue.month(9).date(1).hour(0).seconds(0); break;
        case 'GasYear':
          newValue = newValue.month(9).date(1).hour(0).seconds(0); break;
        case 'Year':
          newValue = newValue.month(0).date(1).hour(0).seconds(0); break;
        default:
          break;
      }

      this.setState({ ...this.state, value: newValue });
      this.delayedOnChange(newValue, newValue.isValid());
    }
  }

  delayedOnChange(newValue, isValid) {
    clearTimeout(this.timeoutId);
    if (isValid) {
      this.timeoutId = setTimeout((arg) => {
        this.props.onChange(arg);
      }, 500, newValue);
    }
  }

  onChangeDateTime(value) {
    let newValue = moment.utc(value);

    this.setState({ ...this.state, value: newValue });
    this.delayedOnChange(newValue.format(), newValue.isValid());
  }

  onChangeMonth(value) {
    let newValue = moment.utc(this.props.value).month(value - 1).date(1);
    this.setState({ ...this.state, value: newValue });
    if (isNaN(value)) return;

    value = parseInt(value);
    if (value < 1 || value > 12) return;

    this.delayedOnChange(newValue.format(), newValue.isValid());
  }

  onChangeYear(value) {
    let newValue = moment.utc(this.props.value).year(value).date(1);
    this.setState({ ...this.state, value: newValue });
    if (isNaN(value)) return;

    value = parseInt(value);
    if (value < 1) return;

    this.delayedOnChange(newValue.format(), newValue.isValid());
  }

  render() {
    const { lens, style = {}, dateTimeClassName, placeholder, disabled, data_id = '' } = this.props;
    const { value } = this.state;

    const _view = viewLookUp[lens ?? ''] ?? 'DateTime';
    return <>
      {_view === 'DateTime' && <input data_id={data_id + '-date'} disabled={disabled} style={style} type='datetime-local' min="0001-01-01T00:00" max="9999-12-31T23:59" placeholder={placeholder} className={dateTimeClassName} value={value.format('YYYY-MM-DDTHH:mm')} onChange={e => this.onChangeDateTime(e.target.value)} />}
      {_view === 'Date' && <input data_id={data_id + '-date'} disabled={disabled} style={style} type='date' placeholder={placeholder} className={dateTimeClassName} value={value.format('YYYY-MM-DD')} onChange={e => this.onChangeDateTime(e.target.value)} />}

      {_view === 'Month' && <div style={style} className={dateTimeClassName}>
        <input data_id={data_id + '-month'} disabled={disabled} style={{ border: 'none', outline: 0, textAlign: 'right' }} type='number' min='1' max='12' value={value.month() + 1} onChange={e => this.onChangeMonth(e.target.value)} />/
        <input data_id={data_id + '-year'} disabled={disabled} style={{ border: 'none', outline: 0 }} type='number' min='0' max='9999' value={value.year()} onChange={e => this.onChangeYear(e.target.value)} />
      </div>}

      {_view === 'GasSeason' && <div style={style} className={dateTimeClassName}>
        <select data_id={data_id + '-month'} disabled={disabled} style={{ border: 'none', outline: 0 }} value={value.month()  + 1} onChange={e => this.onChangeMonth(e.target.value)}>
          <option value={4}>Sum</option>
          <option value={10}>Win</option>
        </select>/
        <input data_id={data_id + '-year'} disabled={disabled} style={{ border: 'none', outline: 0 }} type='number' min='0' max='9999' value={value.year()} onChange={e => this.onChangeYear(e.target.value)} />
      </div>}

      {_view === 'Quarter' && <div style={style} className={dateTimeClassName}>
        <select data_id={data_id + '-month'} disabled={disabled} style={{ border: 'none', outline: 0 }} value={value.month() + 1} onChange={e => this.onChangeMonth(e.target.value)}>
          <option value={1}>Q1</option>
          <option value={4}>Q2</option>
          <option value={7}>Q3</option>
          <option value={10}>Q4</option>
        </select>/
        <input data_id={data_id + '-year'}  disabled={disabled} style={{ border: 'none', outline: 0 }} type='number' min='0' max='9999' value={value.year()} onChange={e => this.onChangeYear(e.target.value)} />
      </div>}

      {_view === 'Year' && <input data_id={data_id + '-year'} style={style} disabled={disabled} className={dateTimeClassName} type='number' min='0' max='9999' value={value.year()} onChange={e => this.onChangeYear(e.target.value)} />}

      {_view === 'GasYear' && <input data_id={data_id + '-year'} style={style} disabled={disabled} className={dateTimeClassName} type='number' min='0' max='9999' value={value.year()} onChange={e => this.onChangeYear(e.target.value)} />}
    </>
  }
}

export default LensedDateTime;