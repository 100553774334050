import moment from 'moment';
import { applyStyles } from '../utility/style-utility';
import { assertType } from '../utility/type-checking';
import PropTypes from 'prop-types';
import { HighchartsAxisConverter } from '../utility/highcharts-utility';
import { to2dp } from '../utility/property-utlility';

export function mapReportToTable(tableSettings, basket, source, timeSeriesWindows) {
  assertType({
    tableSettings: PropTypes.object.isRequired,
    basket: PropTypes.array.isRequired,
    source: PropTypes.object.isRequired,
    timeSeriesWindows: PropTypes.array
  }, { tableSettings, basket, source, timeSeriesWindows });
  let { type: sourceType, data: sourceData } = source;
  const data = [];

  if (sourceData) {
    if (timeSeriesWindows) {
      // convert dates to moment so we can compare ranges
      let { startDate, endDate } = getVisibleWindowExtremities(timeSeriesWindows, basket);
      startDate = moment(startDate);
      endDate = moment(endDate);
      if (startDate.isValid() && endDate.isValid()) {
        sourceData.forEach((lensData, i) => {
          const { startDateIndex, endDateIndex } = getStartAndEndIndex(lensData.rows, startDate, endDate);
          data.push({
            ...lensData,
            rows: lensData.rows.slice(startDateIndex, endDateIndex)
          });
        });
      }
    } else {
      sourceData.forEach((lensData, i) => {
        data.push(lensData);
      });
    }
  }

  const isHorizontal = tableSettings.displayData === 'horizontal';
  let tableStyles = {};
  let tableRowStyles = [];
  let tableData = [];
  let tableHeaders = [];
  let adjustments = {};
  let statistics = getDataStatistics(basket.map(x => x.key), data);
  if (sourceType === 'analysis-report')
    ({ tableStyles, tableRowStyles, tableData, tableHeaders, adjustments } = mapAnalysisReportToTable(isHorizontal, tableSettings, basket, data, statistics));

  if (sourceType === 'analysis-evo-report')
    ({ tableStyles, tableRowStyles, tableData, tableHeaders } = mapEvolutionReportToTable(isHorizontal, tableSettings, basket, data, statistics));

  return { tableStyles, tableRowStyles, tableData, tableHeaders, adjustments, isHorizontal, statistics };
}

export function getDataStatistics(basketKeys, frameRows) {
  const stats = Object.fromEntries(basketKeys.map(k => [k, { min: null, max: null }]));

  if (frameRows)
    frameRows.forEach(({ rows }) => {
      rows.forEach(row => {
        basketKeys.forEach(key => {
          var value = row[key];
          if (value !== undefined && value !== null) {
            const statsBasket = stats[key];
            if (statsBasket.min === null) statsBasket.min = value;
            else if (value < statsBasket.min) statsBasket.min = value;

            if (statsBasket.max === null) statsBasket.max = value;
            else if (value > statsBasket.max) statsBasket.max = value;
          }
        });
      });
    });

  basketKeys.forEach(key => {
    const statsBasket = stats[key];
    statsBasket.min = to2dp(statsBasket.min);
    statsBasket.max = to2dp(statsBasket.max);
  });
  return stats; 
}

function getStartAndEndIndex(rows, startDateUtc, endDateUtc) {
  // search from start to get start
  let startDateIndex = undefined;
  for (let index = 0; index < rows.length; index++) {
    const rowDateTime = moment.utc(rows[index].dateTime);
    if (rowDateTime >= startDateUtc) {
      startDateIndex = index;
      break;
    }
  }

  // search backwards from end to get last index
  let endDateIndex = undefined;
  for (let index = rows.length - 1; index >= 0; index--) {
    const rowDateTime = moment.utc(rows[index].dateTime);
    if (rowDateTime < endDateUtc) {
      endDateIndex = index + 1;
      break;
    }
  }

  return { startDateIndex, endDateIndex };
}

export const mapReportTimeSeriesCollectionDataToChartData = ({ basket, timeSeriesWindows, data, comparisonMode }) => {
  let seriesData = {};

  basket.forEach(({ key, responseLens: lens, isXAxis, xAxisKey, isDisabled }) => {
    if (isXAxis === true || isDisabled === true)
      return;

    const axisConverter = new HighchartsAxisConverter({ comparisonMode, lens, xAxisKey });
    const timeSeriesWindow = timeSeriesWindows.find(tsw => tsw.key === key);
    const startDate = moment.utc(timeSeriesWindow.startDate);
    const endDate = moment.utc(timeSeriesWindow.endDate);
    let rows = (data.find(i => i.lens === lens) ?? {}).rows ?? [];
    const { startDateIndex, endDateIndex } = getStartAndEndIndex(rows, startDate, endDate);
    rows = rows.slice(startDateIndex, endDateIndex);

    let basketSeriesData = rows.map(row => [axisConverter.getX(row), row[key], row]);  // xAxisTimeSeries uses 3rd arg `row` for tooltip formatter, likely not required and inefficient
    if (xAxisKey) // its possible that the xAxis doesnt exist for the point at the specified date, in this case we do not want to exclude it rather than show something that may intermittently change
      basketSeriesData = basketSeriesData.filter(r => r[0] !== undefined);

    seriesData[key] = basketSeriesData;
  });

  return seriesData;
}

const getVisibleWindowExtremities = (timeSeriesWindows, basket) => {
  let startDate = undefined;
  let endDate = undefined;

  if (timeSeriesWindows) {
    timeSeriesWindows.forEach(window => {
      const basketItem = basket.find(b => b.key === window.key);
      if (basketItem && basketItem.isDisabled !== true) {
        const start = moment.utc(window.startDate);
        if (startDate === undefined || start < startDate) {
          startDate = start;
        }

        const end = moment.utc(window.endDate);
        if (endDate === undefined || end > endDate) {
          endDate = end;
        }
      }
    });
  }

  return {
    startDate: startDate ? startDate.format() : undefined,
    endDate: endDate ? endDate.format() : undefined
  };
}

export function sortTableData(isHorizontal, data, orderBy, orderByDirection) {
  assertType({
    isHorizontal: PropTypes.bool,
    data: PropTypes.array.isRequired,
    orderBy: PropTypes.string,
    orderByDirection: PropTypes.string
  }, { isHorizontal, data, orderBy, orderByDirection });

  if (isHorizontal) {
    // currently only vertical view
    return data;
  }
  else {
    if (!orderBy || orderBy === 'dateTime') {
      if (orderByDirection === 'desc') {
        data.sort((a, b) => b.valueOf - a.valueOf);
      } else {
        data.sort((a, b) => a.valueOf - b.valueOf);
      }
    } else {
      if (!data || data.length === 0) {
        return data;
      }

      if (data[0].hasOwnProperty(orderBy)) {
        if (orderByDirection === 'desc') {
          data.sort((a, b) => (a[orderBy].value ?? 0) - (b[orderBy].value ?? 0));
        } else {
          data.sort((a, b) => (b[orderBy].value ?? 0) - (a[orderBy].value ?? 0));
        }
      }
    }

    data.forEach((row, index) => row.rowIndex = index);
    return data;
  }
}

function mapVerticalTableHeaders(headerType, series) {
  const getTitle = (headerType => {
    switch (headerType) {
      case 'both': return s => `${s.identityId}: ${s.name}`;
      case 'name': return s => `${s.name}`;
      default: return s => `${s.identityId}`;
    }
  })(headerType);

  let headers = [{
    key: 'dateTime',
    type: 'date',
    title: 'Date',
    isDisabled: false
  }, ...series.map(i => ({
    key: i.key,
    type: 'number',
    title: getTitle(i),
    information: i.information,
    warning: i.warning,
    error: i.error,
    isDisabled: i.isDisabled
  }))];

  return headers.map((header, index) => ({ ...header, index }));
}

function mapHorizontalTableHeaders(headerType, data) {
  let headers = [{
    key: 'title',
    type: 'string',
    title: headerType === 'both' ? 'Id: Name' : headerType === 'name' ? 'Name' : 'Id'
  }, ...data.map(({ bucket, dateTime }) => ({
    key: dateTime.key,
    otherKeys: { bucket },
    type: 'number',
    title: {
      dateTime: dateTime.value
    }
  }))];

  return headers.map((header, index) => ({ ...header, index }));
}

function mapHorizontalTableData(headerType, series, data) {
  const getTitle = (headerType => {
    switch (headerType) {
      case 'both': return s => `${s.identityId}: ${s.name}`;
      case 'name': return s => `${s.name}`;
      default: return s => `${s.identityId}`;
    }
  })(headerType);

  return series.map(s => {
    let item = {
      key: s.key,
      title: {
        value: getTitle(s),
        information: s.information,
        warning: s.warning,
        error: s.error,
        isDisabled: s.isDisabled
      }
    };

    data.forEach(i => item[i.dateTime.key] = i[s.key]);

    return item;
  });
}

function mapAnalysisReportToTable(isHorizontal, tableSettings, basket, data, statistics) {
  assertType({
    isHorizontal: PropTypes.bool.isRequired,
    tableSettings: PropTypes.object.isRequired,
    basket: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    statistics: PropTypes.object.isRequired
  }, { isHorizontal, tableSettings, basket, data, statistics });

  const { data: vertData, headers: vertHeaders, adjustments } = mapToVerticalTableData(tableSettings, basket, data);
  if (isHorizontal) {
    const { data: horzData, headers: horzHeaders } = mapToHorizontalTableData(tableSettings, basket, vertData);
    horzData.forEach((row, index) => row.rowIndex = index);
    const { tableStyles, tableRowStyles } = applyStyles(tableSettings, horzHeaders, horzData, statistics);
    return { tableStyles, tableRowStyles, tableData: horzData, tableHeaders: horzHeaders, adjustments };
  }
  else {
    vertData.forEach((row, index) => row.rowIndex = index);
    const { tableStyles, tableRowStyles } = applyStyles(tableSettings, vertHeaders, vertData, statistics);
    return { tableStyles, tableRowStyles, tableData: vertData, tableHeaders: vertHeaders, adjustments };
  }
}

function mapEvolutionReportToTable(isHorizontal, tableSettings, basket, data, statistics) {
  assertType({
    isHorizontal: PropTypes.bool.isRequired,
    tableSettings: PropTypes.object.isRequired,
    basket: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    statistics: PropTypes.object.isRequired
  }, { isHorizontal, tableSettings, basket, data, statistics });

  const { data: vertData, headers: vertHeaders } = mapEvoToVerticalTableData(tableSettings, basket, data);
  if (isHorizontal) {
    const { data: horzData, headers: horzHeaders } = mapToHorizontalTableData(tableSettings, basket, vertData);
    horzData.forEach((row, index) => row.rowIndex = index);
    const { tableStyles, tableRowStyles } = applyStyles(tableSettings, horzHeaders, horzData, statistics);
    return { tableStyles, tableRowStyles, tableData: horzData, tableHeaders: horzHeaders };
  }
  else {
    vertData.forEach((row, index) => row.rowIndex = index);
    const { tableStyles, tableRowStyles } = applyStyles(tableSettings, vertHeaders, vertData, statistics);
    return { tableStyles, tableRowStyles, tableData: vertData, tableHeaders: vertHeaders };
  }
}

function mapToVerticalTableData(tableSettings, basket, data) {
  let rowData = {};
  const allAdjustments = [];

  basket.forEach(({ key, responseLens: lens }) => {
    const lensBatch = data.find(i => i.lens === lens) ?? {};
    const { rows = [], adjustments = [] } = lensBatch;
    const adjustmentsMap = Object.fromEntries(adjustments.filter(a => a.hasOwnProperty(key)).map(a => [a.dateTime, true]));
    rows.forEach((row, rowIndex) => {
      const cellRow = rowData[row.dateTime] = rowData[row.dateTime] ?? { data: [], keys: {} };

      const valueOf = moment.utc(row.dateTime).valueOf();
      const offset = cellRow.keys[key] ?? 0;

      cellRow.data[offset] = cellRow.data[offset] ?? {};
      cellRow.data[offset].dateTime = { value: row.dateTime, key: createLongDayDateTimeFormat(row.dateTime, offset) };
      cellRow.data[offset].valueOf = valueOf + offset; // Add offset to ensure order for long days

      if (row.hasOwnProperty(key)) {
        cellRow.data[offset][key] = { value: row[key] };
        cellRow.keys[key] = offset + 1;
      }
      else {
        cellRow.data[offset][key] = {};
        cellRow.keys[key] = offset; // No increment, so long days can overwrite
      }

      if (adjustmentsMap[row.dateTime] === true) {
        cellRow.data[offset][key].hasExistingAdjustment = true;
        allAdjustments.push({ rowIndex, key, dateTime: row.dateTime });
      }
    });

    function createLongDayDateTimeFormat(date, shifted) {
      var m = moment.utc(date);
      var xHH = `${(m.hours() * 2) + (shifted ? 1 : 0)}`.padStart(2, '0');

      return m.format('YYYY-MM-DDT') + xHH + m.format(':mm:ss');
    }
  });

  const rows = Object.values(rowData).flatMap(({ data }) => data);
  const { headerType } = tableSettings;
  const headers = mapVerticalTableHeaders(headerType, basket);
  return { data: rows, headers, adjustments: allAdjustments };
}

function mapEvoToVerticalTableData(tableSettings, basket, data) {
  const timeSeriesKeys = basket.map(ts => ts.key);
  const rows = [];
  data.forEach(row => {
    const newRow = {
      dateTime: { value: row.dateTime },
      valueOf: moment.utc(row.dateTime).valueOf(),
    };

    timeSeriesKeys.forEach(k => {
      newRow[k] = { value: row[k] };
    });

    rows.push(newRow);
  });

  const { headerType } = tableSettings;
  const series = basket.filter(x => !x.isDisabled);
  const headers = mapVerticalTableHeaders(headerType, series);
  return { data: rows, headers };;
}

function mapToHorizontalTableData(tableSettings, basket, vertData) {
  const { headerType } = tableSettings;
  const headers = mapHorizontalTableHeaders(headerType, vertData);
  const data = mapHorizontalTableData(headerType, basket, vertData);
  return { data, headers };
}