import { memo } from 'react';
import numeral from 'numeral';

export default memo(function FormattedNumber({ children, useGrouping = true, displayIfEmpty = '', displayIfNaN = '', displayIfZero = '0', minDecimals, maxDecimals = 0 }) {
  
  return formatNumber({value: children, useGrouping, displayIfEmpty, displayIfNaN, displayIfZero, minDecimals, maxDecimals})
});

export const FormattedNumeral = memo(function FormattedNumeral({ children, displayFormat }) {
  let value = children;
  if ((value || value === 0) && displayFormat) try {
    value = numeral(value).format(displayFormat);
  } catch { }

  return value ?? '';
});

export function formatNumber({value, useGrouping = true, displayIfEmpty = '', displayIfNaN = '', displayIfZero = '0', minDecimals, maxDecimals = 0}){
  if (value === null || value === undefined || value === '') return displayIfEmpty;

  if (isNaN(value)) return displayIfNaN;

  value = Number(value);

  if (value === 0) return displayIfZero;

  let options = { useGrouping };

  if (!isNaN(minDecimals) && minDecimals >= 0) options.minimumFractionDigits = minDecimals > 20 ? 20 : minDecimals;
  if (!isNaN(maxDecimals) && maxDecimals >= 0) options.maximumFractionDigits = maxDecimals > 20 ? 20 : maxDecimals;

  return value.toLocaleString(undefined, options);
}

export function formatNumeral(value, displayFormat) {
  if ((value || value === 0) && displayFormat) try {
    value = numeral(value).format(displayFormat);
  } catch { }

  return value ?? '';
};

const [thousandsDelimeter, decimalDelimeter] = (new Intl.NumberFormat().format(111222.333).replace('111','').replace('222','').replace('333','')).split('').map(x => new RegExp(`\\${x}`, 'g'));

export function parseNumber(text){
  if (text === undefined || text === null)
    return undefined;

  text = text.trim();
  if (text === '')
    return undefined;

  text = text.replace(thousandsDelimeter, '').replace(decimalDelimeter, '.');
  const value = Number(text);
  if (isNaN(value) || value === null || value === undefined)
    return undefined;

  return value;
}