import '../utility/array-utility';
import {
  mapToBasketTimeSeries
} from '../utility/analysis-basket-utility';
import { getTimeSeriesSchema } from './analysisCrossSchemaSearch';
import PropTypes from 'prop-types';
import { assertType } from '../utility/type-checking';
import { cloneInstance } from '../utility/property-utlility';

PropTypes.dynamicWorkspace = PropTypes.shape({
  key: PropTypes.string.isRequired,
  searchCriteria: PropTypes.object.isRequired
});

PropTypes.dynamicRow = PropTypes.shape({
  replacesId: PropTypes.number,
  id: PropTypes.number.isRequired,
});

function sortBaselineToBasket(baselineBasket, basket) {
  return baselineBasket.sort((a, b) => {
    let _a = basket.findIndex(p => p.key === a.key);
    if (_a < 0) _a = basket.length + 1;

    let _b = basket.findIndex(p => p.key === b.key);
    if (_b < 0) _b = basket.length + 1;

    return _a - _b;
  });
}

export function updateBaseline(baselineBasket, basket, basketItem) {
  assertType({
    baselineBasket: PropTypes.arrayOf(PropTypes.timeSeries).isRequired,
    basketItem: PropTypes.timeSeries.isRequired
  }, { baselineBasket, basketItem });

  const existingBasketItem = baselineBasket.find(b => b.key === basketItem.key);
  if (existingBasketItem) {
    // copy all properties except id & identityId as these are part of the baseline
    const clone = cloneInstance(basketItem);
    delete clone.id;
    delete clone.identityId;
    Object.assign(existingBasketItem, clone);
  } else {
    return addToBaseline(baselineBasket, basket, basketItem);
  }

  return sortBaselineToBasket(baselineBasket, basket);;
}

export function addToBaseline(baselineBasket, basket, newBasketItem) {
  assertType({
    baselineBasket: PropTypes.arrayOf(PropTypes.timeSeries).isRequired,
    basket: PropTypes.arrayOf(PropTypes.timeSeries).isRequired,
    newBasketItem: PropTypes.timeSeries.isRequired
  }, { baselineBasket, basket, newBasketItem });

  newBasketItem = cloneInstance(newBasketItem);
  baselineBasket.push(newBasketItem);
  return sortBaselineToBasket(baselineBasket, basket);
}

export function removeFromBaseline(baselineBasket, basket, basketItemKey) {
  assertType({
    baselineBasket: PropTypes.arrayOf(PropTypes.timeSeries).isRequired,
    basket: PropTypes.arrayOf(PropTypes.timeSeries).isRequired,
    basketItemKey: PropTypes.string
  }, { baselineBasket, basket, basketItemKey });

  baselineBasket = baselineBasket.filter(b => b.key !== basketItemKey);
  return sortBaselineToBasket(baselineBasket, basket);
}

export function mergeBasketToBaseline(baselineBasket, basket) {
  assertType({
    baselineBasket: PropTypes.arrayOf(PropTypes.timeSeries).isRequired,
    basket: PropTypes.arrayOf(PropTypes.timeSeries).isRequired
  }, { baselineBasket, basket });

  // add or update
  basket.forEach(basketItem => {
    const existingItem = baselineBasket.find(e => e.key === basketItem.key);
    if (existingItem) {
      // copy all properties except id & identityId as these are part of the baseline
      const clone = cloneInstance(basketItem);
      delete clone.id;
      delete clone.identityId;
      Object.assign(existingItem, clone);
    } else {
      baselineBasket.push(basketItem);
    }
  });

  // remove
  // baselineBasket = baselineBasket.filter(e => basket.find(b => b.key !== e.key));

  return sortBaselineToBasket(baselineBasket, basket);
}

export function applyDynamicWorkspaceTimeSeries(basket, baselineBasket, dynamicWorkspace, dynamicRows, resetBaselineIds) {
  assertType({
    basket: PropTypes.arrayOf(PropTypes.timeSeries),
    baselineBasket: PropTypes.any,
    dynamicWorkspace: PropTypes.dynamicWorkspace,
    dynamicRows: PropTypes.array,
  }, { basket, baselineBasket, dynamicWorkspace, dynamicRows });

  basket = cloneInstance(basket);

  // remove all items of this dynamicWorkspace
  basket = basket.filter(b => !(b.dynamicWorkspace && b.dynamicWorkspace.key === dynamicWorkspace.key));

  baselineBasket.forEach(b => {
    if (b.dynamicWorkspace && b.dynamicWorkspace.key === dynamicWorkspace.key) {
      const replacementDynamic = dynamicRows.find(d => (d.replacesId ?? d.id) === b.baselineId);
      if (replacementDynamic) {
        replacementDynamic.isProcessed = true;
        const basketItem = cloneInstance(b);
        if (resetBaselineIds === true)
          basketItem.baselineId = replacementDynamic.id;

        applyDynamicPropertiesToTimeSeries(basketItem, replacementDynamic, dynamicWorkspace);
        basket.push(basketItem);
      }
    }
  });

  // new items
  dynamicRows.forEach(d => {
    if (!d.isProcessed) {
      const newBasketItem = mapToBasketTimeSeries(d, 'default', undefined, [...basket, ...baselineBasket]);
      newBasketItem.baselineId = d.replacesId ?? d.id;

      applyDynamicPropertiesToTimeSeries(newBasketItem, d, dynamicWorkspace);
      basket.push(newBasketItem);
    }
  });

  // update on-demand timeseries
  basket.forEach(b => {
    if (b.derivationData && b.derivationData.keys) {
      b.derivationData.keys.forEach(key => {
        if (key.type !== 'Request') {
          const match = dynamicRows.find(d => d.replacesId && `${key.key}` === `${d.replacesId}`);
          if (match) {
            key.key = `${match.id}`;
          }
        }
      });
    }
  });

  basket = basket.sort((a, b) => {
    let _a = baselineBasket.findIndex(p => p.key === a.key);
    if (_a < 0) _a = baselineBasket.length + 1;

    let _b = baselineBasket.findIndex(p => p.key === b.key);
    if (_b < 0) _b = baselineBasket.length + 1;

    return _a - _b;
  });

  return basket;
}

export function removeDynamicWorkspaceFromBaselineBasket(baselineBasket, dynamicWorkspace) {
  baselineBasket = baselineBasket.filter(b => !(b.dynamicWorkspace && b.dynamicWorkspace.key === dynamicWorkspace.key));
  return baselineBasket;
}

export function getTimeSeriesForDynamicWorkspace(basket, dynamicWorkspace) {
  assertType({
    basket: PropTypes.arrayOf(PropTypes.timeSeries),
    dynamicWorkspace: PropTypes.object
  }, { basket, dynamicWorkspace });

  return basket.filter(b => b.dynamicWorkspace && b.dynamicWorkspace.key === dynamicWorkspace.key);
}

function getDistinctCategories(schemaFacets) {
  const response = [];
  for (let si = 0; si < schemaFacets.length; si++) {
    const schema = schemaFacets[si];
    for (let fi = 0; fi < schema.facets.length; fi++) {
      const facet = schema.facets[fi];
      let category = response.find(c => c.name === facet.key);
      if (!category) {
        category = {
          name: facet.key,
          values: facet.results.map(r => r.value)
        };

        response.push(category);
      } else {
        category.values = [...new Set([...category.values, ...facet.results.map(r => r.value)])]
      }
    }
  }

  return response;
}

export function getPivotCategories(schemaFacets) {
  const response = getDistinctCategories(schemaFacets);
  response.forEach(r => {
    r.values = r.values.sort((a, b) => a.localeCompare(b));
  });

  return response.filter(c => c.values.length > 1);
}

export function updateSubstitionKeyExclusionCategories(substitutionKeyExclusionCategories = [], schemaFacets = []) {
  const response = [...substitutionKeyExclusionCategories];

  let categories = getDistinctCategories(schemaFacets);
  if (categories) {
    categories.forEach(p => {
      if (!response.find(s => s.name === p.name)) {
        response.push({
          name: p.name,
          isExcluded: false
        });
      }
    });
  }

  return response.sort((a, b) => a.name.localeCompare(b.name));
}

export function buildIntersectionOfCategories(schemaCategories, selectedCategory, schemas) {
  const selectedSchemasNames = schemas.filter(s => s.isSelected).map(s => s.schemaName);
  const selectedSchemas = schemaCategories.filter(s => selectedSchemasNames.indexOf(s.schemaName) >= 0);
  const intersectCount = selectedSchemas.length;

  const optionsCount = {};
  for (let index = 0; index < selectedSchemas.length; index++) {
    const selectedSchema = selectedSchemas[index];
    var facet = selectedSchema.facets.find(f => f.key === selectedCategory);
    if (facet && facet.results) {
      facet.results.forEach(r => {
        if (optionsCount[r.value]) {
          optionsCount[r.value]++;
        } else {
          optionsCount[r.value] = 1;
        }
      });
    }
  }

  // convert to an array
  let optionsCountArray = Object.keys(optionsCount).map(k => ({ name: k, count: optionsCount[k] }));
  // only keep the items that interset
  optionsCountArray = optionsCountArray.filter(r => r.count === intersectCount);
  return optionsCountArray.map(r => ({ name: r.name, isSelected: true }));
}

export function validateDynamicFiltersWithSearchResultsSchemaCategories(dynamicFilters, schemaCategories) {
  dynamicFilters.forEach(dynamicFilter => {
    const warnings = [];
    dynamicFilter.selectedSchemas.forEach(selectedSchema => {
      if (selectedSchema.isSelected) {
        const schema = schemaCategories.find(s => s.schemaName === selectedSchema.schemaName);
        if (schema) {
          const facets = schema.facets.find(fc => fc.key === dynamicFilter.selectedCategory);
          if (facets) {
            dynamicFilter.values.forEach(v => {
              if (!facets.results.find(fv => fv.value === v.name)) {
                warnings.push(`The option ${v.name} was not it the search results`);
              }
            })
          } else {
            warnings.push(`The selected category ${dynamicFilter.selectedCategory} was not it the search results`);
          }
        } else {
          warnings.push(`The selected schema ${selectedSchema.schemaName} was not it the search results`);
        }
      }
    });

    dynamicFilter.warnings = warnings.length > 0 ? warnings : undefined;
  })

  return dynamicFilters;
}

function applyDynamicPropertiesToTimeSeries(timeSeries, dynamicProperties, dynamicWorkspace) {
  timeSeries.id = dynamicProperties.id;
  timeSeries.identityId = dynamicProperties.id;
  if (!timeSeries.name || timeSeries.name.trim() === '')
    timeSeries.name = dynamicProperties.name;

  timeSeries.sourceTimeSeriesName = dynamicProperties.name;
  timeSeries.timeSeriesName = dynamicProperties.name;
  timeSeries.source = dynamicProperties.source;
  timeSeries.schemas = dynamicProperties.schemas;
  timeSeries.schemaName = getTimeSeriesSchema(dynamicProperties.schemas, dynamicWorkspace.searchCriteria.schemas);
  timeSeries.dynamicWorkspace = {
    key: dynamicWorkspace.key
  };

  return timeSeries;
}

function isCategoryMatch(filterA, filterB) {
  const selectedCategory = filterA.alias || filterA.selectedCategory;
  return (filterB.alias || filterB.selectedCategory) === selectedCategory;
}

export function getGroupedDynamicFilterSelections(dynamicWorkspaces) {
  let response = [];
  dynamicWorkspaces.forEach(dynamicWorkspace => {
    if (dynamicWorkspace.dynamicFilters) {
      dynamicWorkspace.dynamicFilters.forEach(dynamicFilter => {
        const category = dynamicFilter.alias || dynamicFilter.selectedCategory;
        let categoryGrouping = response.find(g => g.category === category);
        if (!categoryGrouping) {
          categoryGrouping = {
            selectedValue: dynamicFilter.selectedValue,
            category: category,
            filters: [],
            values: []
          };
          response.push(categoryGrouping);
        }

        categoryGrouping.filters.push({
          dynamicWorkspaceKey: dynamicWorkspace.key,
          dynamicFilterKey: dynamicFilter.key
        });

        categoryGrouping.values.push(dynamicFilter.values.filter(v => v.isSelected).map(v => v.name));
      });
    }
  });

  response = response.filter(cg => cg.values.length > 1);
  response.forEach(cg => {
    cg.selectedCategory = getCommonSelection(cg);
  });

  return response.flatMap(cg => cg.filters.map(f => ({ selectedValue: cg.selectedValue, dynamicWorkspaceKey: f.dynamicWorkspaceKey, dynamicFilterKey: f.dynamicFilterKey })))
}

export function getCommonSelection(categoryGrouping) {
  let selectedValue = categoryGrouping.selectedValue ?? '';
  if (categoryGrouping.values.length > 0 && (selectedValue === '' || !categoryGrouping.values.every(x => x.indexOf(selectedValue) >= 0))) {
    if (categoryGrouping.values[0].length > 0) {
      selectedValue = categoryGrouping.values[0][0];
    }

    for (let index = 0; index < categoryGrouping.values[0].length; index++) {
      const candidateValue = categoryGrouping.values[0][index];
      if (categoryGrouping.values.every(x => x.indexOf(candidateValue) >= 0)) {
        return candidateValue;
      }
    }
  }

  return selectedValue;
}

export function mergeGroupedDynamicFilters(dynamicWorkspaces) {
  dynamicWorkspaces = cloneInstance(dynamicWorkspaces);
  // make sure any multiSelect filters are first so that the grouped filters are presented as multi-select
  dynamicWorkspaces.sort((a,b) => {
    const aM = a.dynamicFilters ? a.dynamicFilters.filter(df => df.isMultiSelect).length : 0;
    const bM = b.dynamicFilters ? b.dynamicFilters.filter(df => df.isMultiSelect).length : 0;
    if (aM < bM){
      return 1;
    } else if (aM > bM)
    {
      return -1;
    } else {
      return 0;
    }
  });

  // break each filter out into groups
  const workspaceCategoryGroups = [];
  dynamicWorkspaces.forEach(dynamicWorkspace => {
    if (dynamicWorkspace.dynamicFilters) {
      dynamicWorkspace.dynamicFilters.forEach(dynamicFilter => {
        const name = dynamicFilter.alias || dynamicFilter.selectedCategory;

        const workspaceCategoryGroup = workspaceCategoryGroups.find(wcg => wcg.name === name);
        if (!workspaceCategoryGroup) {
          workspaceCategoryGroups.push({
            groupingKey: dynamicWorkspace.key,
            name: name,
            filters: [{
              dynamicWorkspace,
              dynamicFilter
            }]
          });
        } else {
          workspaceCategoryGroup.filters.push({
            dynamicWorkspace,
            dynamicFilter
          });
        }
      })
    }
  });

  // build into groups of dynamic filters
  let response = [];
  workspaceCategoryGroups.forEach(workspaceCategoryGroup => {
    const rootWorkspaceCategoryGroup = workspaceCategoryGroup.filters[0];
    let rootDynamicWorkspace = response.find(x => x.groupingKey === workspaceCategoryGroup.groupingKey);
    if (rootDynamicWorkspace){
      rootDynamicWorkspace.dynamicFilters.push(rootWorkspaceCategoryGroup.dynamicFilter);
    } else {
      rootDynamicWorkspace = {
        groupingKey: workspaceCategoryGroup.groupingKey,
        key: rootWorkspaceCategoryGroup.dynamicWorkspace.key,
        dynamicFilters: [rootWorkspaceCategoryGroup.dynamicFilter],
        associatedFilters: workspaceCategoryGroup.filters.map(f => ({
          dynamicWorkspaceKey :f.dynamicWorkspace.key,
          dynamicFilterKey : f.dynamicFilter.key
        }))
      }
      response.push(rootDynamicWorkspace);
    }

    const rootValues = rootDynamicWorkspace.dynamicFilters[0].values;
    for (let index = 1; index < workspaceCategoryGroup.filters.length; index++) {
      const filterValues = workspaceCategoryGroup.filters[index].dynamicFilter.values;
      workspaceCategoryGroup.filters[index].dynamicFilter.values = [];

      const dynamicWorkspace = {
        key: workspaceCategoryGroup.filters[index].dynamicWorkspace.key,
        dynamicFilters: [{
          ...workspaceCategoryGroup.filters[index].dynamicFilter,
          values: [],
          isCollapsed: true
        }]
      }

      filterValues.forEach(v => {
        if (!rootValues.some(_v => _v.name === v.name)) {
          rootValues.push(v);
        }
      });

      response.push(dynamicWorkspace);
    }
   
  });

  response.forEach(r => {
    delete r.groupingKey;
    if (r.dynamicFilters) {
      r.dynamicFilters.forEach(f => {
        f.values = f.values.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      });
    }
  });

  return response;
}

export function canGroupDynamicFilters(dynamicWorkspaces) {
  let response = false;
  const uniqueCategoryNames = {};
  dynamicWorkspaces.forEach(dynamicWorkspace => {
    if (dynamicWorkspace.dynamicFilters) {
      dynamicWorkspace.dynamicFilters.forEach(dynamicFilter => {
        let name = (dynamicFilter.alias ?? '').trim();
        if (name === '')
          name = dynamicFilter.selectedCategory;

        if (!uniqueCategoryNames[name]) {
          uniqueCategoryNames[name] = 1;
        } else {
          response = true;
          return;
        }
      });
    }
  });

  return response;
}

export function getGroupedFilterChanges(dynamicWorkspaceKey, dynamicFilterKey, isGroupingEnabled, dynamicWorkspaces) {
  const response = [];
  const dynamicWorkspace = dynamicWorkspaces.find(d => d.key === dynamicWorkspaceKey);
  const dynamicFilter = dynamicWorkspace.dynamicFilters.find(f => f.key === dynamicFilterKey);

  if (isGroupingEnabled) {
    dynamicWorkspaces.forEach(dw => {
      dw.dynamicFilters.forEach(df => {
        if (isCategoryMatch(df, dynamicFilter)) {
          response.push({ dynamicWorkspaceKey: dw.key, dynamicFilterKey: df.key, isMultiSelect: df.isMultiSelect });
        }
      });
    });
  } else {
    response.push({ 
      dynamicWorkspaceKey, 
      dynamicFilterKey, 
      isMultiSelect : dynamicFilter.isMultiSelect });
  }

  return response;
}

export function getDynamicFilterCombinations(state){
  const dynamicWorkspaces = state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace']).toJS();

  const allOptions = [];
    dynamicWorkspaces.forEach(dw => {
      dw.dynamicFilters.forEach(df => {
        if (df.isMultiSelect && df.groupMultiselectOptions) {
          const selectedValues = df.values.filter(v => v.isSelected);
          if (selectedValues.length > 0) {
            allOptions.push([{
              key: dw.key,
              dynamicFilterKey: df.dynamicFilterKey,
              values: selectedValues.map(v => v.name),
              isMultiSelect: true,
              groupMultiselectOptions: true
            }]);
          }
        } else {
          const selectedValues = df.values.filter(v => v.isSelected).map(v => ({
            key: dw.key,
            dynamicFilterKey: df.dynamicFilterKey,
            isMultiSelect: df.isMultiSelect === true,
            groupMultiselectOptions: df.groupMultiselectOptions === true,
            values: [v.name]
          }));

          if (selectedValues.length > 0)
            allOptions.push(selectedValues);          
        }
      });
    });

    const combinations = buildCombinationOfOptions(allOptions);
    return combinations;
}

export function buildCombinationOfOptions(options) {
  const c= combinations(options);
  return c.map(x => [...x]);
}

function combinations(collections) {
  if (collections.length === 1) {
    return collections[0].map(item => [item]);
  } else if (collections.length > 1) {
    return collections[0].flatMap(item => combinations(collections.slice(1)).map(tail => [item, ...tail]));
  }
  return [];
}