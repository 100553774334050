import React from 'react';
import RequiredField from '../../../shared/RequiredField';
import InfoPopup from '../../../shared/InfoPopup';
import CollapsePanel from '../../../shared/CollapsePanel';
import { TimeSeriesSearch2 } from './../../../shared/TimeSeriesSearch2';
import { ModalDialogButton } from '../../../shared/ModalDialogButton';
import Table from '../../../shared/Table';
import IdentityId from '../../../shared/IdentityId2';
import Tooltip from '../../../shared/Tooltip';

const labelStyle = {
  fontSize: '11.6px',
  fontWeight: 700,
  margin: 0,
  cursor: 'default'
};

const inputStyle = {
  fontSize: '12px'
};

export function DerivationSettings({ timeSeries, allSchemaNames, updateTimeSeriesValue, searchKeyText, selectedKeys, updateDerivationSearchKeyText, addDerivationSearchKeyText, moveDerivationKeyUp, moveDerivationKeyDown, deleteDerivationKey, addDerivationKeys,
  requestRematerialiseWithRange, setMaterialiseStatusToEnabled, isContributor }) {
  const { derivationData, style } = timeSeries;
  const { derivationType, materialisationStatus, searchRequest, dataQueryStart, dataQueryEnd } = derivationData || {};
  const { filter: derivationsFilter, orderBy: derivationsOrderBy, schema: derivationsSchema } = searchRequest || {};
  const isDerived = style === 'Derived';
  const isVisible = style !== 'Analysis' && style !== 'Evolution';
  
  function onSearchKeyTextKeyPress(e) {
    const keyCode = e.charCode || e.keyCode || 0;
    if (keyCode === 13)
      addDerivationSearchKeyText();
  }

  function searchActions({ closeDialog }) {
    return [
      {
        text: 'Cancel',
        className: 'btn-secondary',
        func: () => closeDialog()
      },
      {
        text: 'Apply', func: () => {
          addDerivationKeys();
          closeDialog();
        }
      }
    ];
  }

  const panelTitle = isDerived ? 'Derivation' : 'Inputs';
  const wikiPath = isDerived ? '65/Derived-time-series' : '157/Time-series-fields?anchor=inputs'


  return (
    isVisible ?
    <CollapsePanel idKey='timeSeriesEditorDerivationSettings' headerStyle={{ cursor: 'pointer' }}
      title={<><span>{panelTitle}</span>
        <a className='fota-small-text' href={`https://dev.azure.com/mercuria-fota/timeseries/_wiki/wikis/timeseries.wiki/${wikiPath}`} target='_blank' rel='noopener noreferrer'>
          <i className='fas fa-book fa-fw' /> Wiki</a></>} >

      {isDerived && <div>
        <div className='row no-gutters'>
          <div className='col mb-1'>
            <div className='d-flex justify-content-between'>
              <label style={labelStyle}>Derivation Type <RequiredField /></label>
              <label style={labelStyle}>Materialisation Status = {materialisationStatus || 'NotSet'}</label>
            </div>
          </div>
        </div>          
        <div className='row no-gutters'>
          <div className='col mb-1'>
          <div className='d-flex justify-content-between'>
            <select className='form-control' style={inputStyle}
              value={derivationType || ''}
              onChange={e => updateTimeSeriesValue(['derivationData', 'derivationType'], e.target.value)}>
              <option>Standard</option>
              <option>Materialised</option>
              <option>Variant</option>
            </select>
            {isContributor && <Tooltip placement='bottom' title='Will rematerialise this and any dependant timeseries - see dependants tab'>
              <button type='button'
                      className='btn btn-outline-info ml-1' 
                      onClick={ requestRematerialiseWithRange }>
                  Rematerialise
              </button></Tooltip>}        
            {isContributor && materialisationStatus !== "Enabled" && <button data-tip='' 
                                      data-for='enable' 
                                      type='button'
                                      className='btn btn-outline-info ml-1' 
                                      onClick={ setMaterialiseStatusToEnabled }>
                  Enable
            </button>}
          </div>
          </div>
        </div>
      </div>}

      <div className='row no-gutters'>
      <div className='col-9 mb-1 pr-1'>
          <label style={labelStyle}>Schema</label>
          <InfoPopup>Leave blank to use Native</InfoPopup>
          <select className='form-control' 
                  value={derivationsSchema}
                  onChange={e => updateTimeSeriesValue(['derivationData', 'searchRequest', 'schema'], e.target.value)}>
            {allSchemaNames.map(x => <option key={x} value={x}>{x}</option>)}
          </select>          
        </div>
        <div className='col-9 mb-1 pr-1'>
          <label style={labelStyle}>Filter</label>
          <input type='text' className='form-control' style={inputStyle}
            value={derivationsFilter || ''}
            title={derivationsFilter || ''}
            onChange={e => updateTimeSeriesValue(['derivationData', 'searchRequest', 'filter'], e.target.value)} />
        </div>
        <div className='col-3 mb-1 pl-1'>
          <label style={labelStyle}>Order By</label>
          <select className='form-control' style={inputStyle}
            value={derivationsOrderBy || ''}
            onChange={e => updateTimeSeriesValue(['derivationData', 'searchRequest', 'orderBy'], e.target.value)}>
            <option />
            <option>name asc</option>
            <option>name desc</option>
          </select>
        </div>
      </div>
      
      {isDerived && <div className='row no-gutters'>
        <div className='col-6 mb-1 pr-1'>
          <label style={labelStyle}>Data Query Start</label>
          <InfoPopup>An absolute or relative (eg:-1M minus one month.) start date used to trim the derived time series values</InfoPopup>
          <input type='text' className='form-control' style={inputStyle}
            value={dataQueryStart || ''}
            title={dataQueryStart || ''}
            onChange={e => updateTimeSeriesValue(['derivationData', 'dataQueryStart'], e.target.value)} />
        </div>
        <div className='col-6 mb-1 pl-1'>
          <label style={labelStyle}>Data Query End</label>
          <InfoPopup>An absolute or relative (eg:+2Y would be an offset of plus two years.) end date used to trim the derived time series values</InfoPopup>
          <input type='text' className='form-control' style={inputStyle}
            value={dataQueryEnd || ''}
            title={dataQueryEnd || ''}
            onChange={e => updateTimeSeriesValue(['derivationData', 'dataQueryEnd'], e.target.value)} />
        </div>
      </div>}

      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Keys</label>
          <div className='input-group'>
            <input className='form-control' type='text' style={inputStyle} placeholder='Search for Identity' autoComplete='off' value={searchKeyText}
              onChange={e => updateDerivationSearchKeyText(e.target.value)}
              onKeyPress={onSearchKeyTextKeyPress} />
            <div className='input-group-append'>
              <button className='btn btn-secondary mr-1' title='Add identity' style={inputStyle} disabled={!searchKeyText} onClick={e => addDerivationSearchKeyText()} ><i className='fas fa-plus fa-fw' /></button>

              <ModalDialogButton title='Search for time series' size='xl' buttonStyles={inputStyle} buttonClassName='btn btn-secondary mr-1' buttonContent={<i className='fas fa-search fa-fw' />}>
                {({ closeDialog }) => <TimeSeriesSearch2 title='Select Time Series' stateKey={'derivationKeySearch'} intitialSelection={selectedKeys} actions={searchActions({ closeDialog })} closeDialog={closeDialog}/>}
              </ModalDialogButton>
            </div>
          </div>
        </div>
      </div>

      <div className='row no-gutters'>
        {selectedKeys && selectedKeys.map((k, i) => <div className='row w-100' key={`dk${k.key}`}>
          <div className='col' style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip placement='bottom' title={<>{k.identityId} {k.name}
              {k.granularity && <><br/>Granularity:{k.granularity}</>}
              {k.sourceTimeZoneId && <><br/>TimeZone:{k.sourceTimeZoneId}</>}</>}>
              <span>{k.identityId} {k.name}</span>
            </Tooltip>
          </div>
          <div className='btn-toolbar'>
            <button type='button' className='btn btn-sm' disabled={i === 0} onClick={() => moveDerivationKeyUp(k)}>
              <i className='fas fa-arrow-up fa-fw' />
            </button>
            <button type='button' className='btn btn-sm' disabled={i === selectedKeys.length - 1} onClick={() => moveDerivationKeyDown(k)}>
              <i className='fas fa-arrow-down fa-fw' />
            </button>
            <button type='button' className='btn btn-sm' onClick={() => deleteDerivationKey(k)}>
              <i className='fas fa-trash fa-fw' />
            </button>
          </div>
        </div>)}
      </div>

    </CollapsePanel>
    :
    <></>
  );
}

export function OnDemandInputSettings({ timeSeries, updateTimeSeriesValue, searchKeyText, selectedKeys, updateDerivationSearchKeyText, addDerivationSearchKeyText,
  moveFromBasketToKeys, moveDerivationKeyUp, moveDerivationKeyDown, deleteDerivationKey, addDerivationKeys,
  altSelectedKeys, altSelectableTimeSeries, addAltSelection, toggleAltSelection, selectAltAll }) {
  const { derivationData } = timeSeries;
  const { dataQueryStart, dataQueryEnd } = derivationData || {};

  function onSearchKeyTextKeyPress(e) {
    const keyCode = e.charCode || e.keyCode || 0;
    if (keyCode === 13)
      addDerivationSearchKeyText();
  }

  function searchActions({ closeDialog }) {
    return [
      {
        text: 'Cancel',
        className: 'btn-secondary',
        func: () => closeDialog()
      },
      {
        text: 'Apply', func: () => {
          addDerivationKeys();
          closeDialog();
        }
      }
    ];
  }

  const selectedBasketKeys = selectedKeys ? selectedKeys.filter(k => k.type === 'Request') : [];
  const selectedSearchKeys = selectedKeys ? selectedKeys.filter(k => k.type !== 'Request') : [];
  const canMoveFromBasketToKeys = selectedKeys.length >0 && selectedKeys.some(k => k.type === 'Request');

  return <>
    <CollapsePanel idKey='timeSeriesEditorBasketInputs' headerStyle={{ cursor: 'pointer' }} title={<><span>Basket inputs</span>
      <a className='fota-small-text' href='https://dev.azure.com/mercuria-fota/timeseries/_wiki/wikis/timeseries.wiki/65/Derived-time-series#basketinputs' target='_blank' rel='noopener noreferrer'>
        <i className='fas fa-book fa-fw' /> Wiki</a></>} >

      <div className='row no-gutters'>
        <div className='col'>
          <label style={labelStyle}>Select time series from the basket</label>
        </div>
        <div className='col-0 mb-1'>
            {altSelectableTimeSeries && <ModalDialogButton title='Add from basket' size='sm' buttonStyles={inputStyle} buttonClassName='btn btn-secondary' buttonContent={<i className='fas fa-plus fa-fw' />}>
              {({ closeDialog }) => <AltTimeSeriesSelector selectedKeys={altSelectedKeys} timeSeriesList={altSelectableTimeSeries} toggleSelection={toggleAltSelection} selectAll={selectAltAll} addSelection={addAltSelection} closeDialog={closeDialog} ></AltTimeSeriesSelector>}
            </ModalDialogButton>}
        </div>
      </div>

      <div className='row no-gutters'>
        <InputKeyList inputKeys={selectedBasketKeys} moveDerivationKeyUp={moveDerivationKeyUp} moveDerivationKeyDown={moveDerivationKeyDown} deleteDerivationKey={deleteDerivationKey}/>
      </div>
    </CollapsePanel>

    <CollapsePanel idKey='timeSeriesEditorSearchInputs' headerStyle={{ cursor: 'pointer' }} title={<><span>Search inputs</span>
        <a className='fota-small-text' href='https://dev.azure.com/mercuria-fota/timeseries/_wiki/wikis/timeseries.wiki/65/Derived-time-series#searchinputs' target='_blank' rel='noopener noreferrer'>
          <i className='fas fa-book fa-fw' /> Wiki</a></>} >
      
      <div className='row no-gutters'>
        <div className='col-6 mb-1 pr-1'>
          <label style={labelStyle}>Data Query Start</label>
          <InfoPopup>An absolute or relative (eg:-1M minus one month.) start date used to trim the derived time series values</InfoPopup>
          <input type='text' className='form-control' style={inputStyle}
            value={dataQueryStart || ''}
            title={dataQueryStart || ''}
            onChange={e => updateTimeSeriesValue(['derivationData', 'dataQueryStart'], e.target.value)} />
        </div>
        <div className='col-6 mb-1 pl-1'>
          <label style={labelStyle}>Data Query End</label>
          <InfoPopup>An absolute or relative (eg:+2Y would be an offset of plus two years.) end date used to trim the derived time series values</InfoPopup>
          <input type='text' className='form-control' style={inputStyle}
            value={dataQueryEnd || ''}
            title={dataQueryEnd || ''}
            onChange={e => updateTimeSeriesValue(['derivationData', 'dataQueryEnd'], e.target.value)} />
        </div>
      </div>

      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Keys</label>
          <div className='input-group'>
            <input className='form-control' type='text' style={inputStyle} placeholder='Search for Identity' autoComplete='off' value={searchKeyText}
              onChange={e => updateDerivationSearchKeyText(e.target.value)}
              onKeyPress={onSearchKeyTextKeyPress} />
            <div className='input-group-append'>
              <button className='btn btn-secondary mr-1' title='Add identity' style={inputStyle} disabled={!searchKeyText} onClick={e => addDerivationSearchKeyText()} ><i className='fas fa-plus fa-fw' /></button>
              <button className='btn btn-secondary mr-1' title='Convert and move all basket inputs to key inputs' style={inputStyle} disabled={!canMoveFromBasketToKeys} onClick={e => moveFromBasketToKeys()} >Move From Basket</button>
              <ModalDialogButton title='Search for time series' size='xl' buttonStyles={inputStyle} buttonClassName='btn btn-secondary mr-1' buttonContent={<i className='fas fa-search fa-fw' />}>
                {({ closeDialog }) => <TimeSeriesSearch2 title='Select Time Series' stateKey={'derivationKeySearch'} intitialSelection={selectedKeys} actions={searchActions({ closeDialog })} closeDialog={closeDialog}/>}
              </ModalDialogButton>
            </div>
          </div>
        </div>
      </div>

      <div className='row no-gutters'>
        <InputKeyList inputKeys={selectedSearchKeys} moveDerivationKeyUp={moveDerivationKeyUp} moveDerivationKeyDown={moveDerivationKeyDown} deleteDerivationKey={deleteDerivationKey}/>
      </div>
    </CollapsePanel>
  </>
}

function AltTimeSeriesSelector({ timeSeriesList, closeDialog, selectedKeys, toggleSelection, addSelection, selectAll }) {
  const columns = [{
    key: 'identityId',
    title: 'Id'
  }, {
    key: 'name',
    title: 'Name'
  }, {
    key: 'source',
    title: 'Source'
  }, null];

  function isSelectedTest(key) {
    return selectedKeys && selectedKeys.some(i => `${i.key}` === `${key}`);
  }

  function onToggleSelection(inputKey){
    toggleSelection(inputKey);
  }

  function onAddSelection() {
    addSelection();
    closeDialog();
  }

  function onSelectAll(){
    selectAll(timeSeriesList);
  }

  function onDeselectAll(){
    selectAll([]);
  }

  return <div className='modal-content'>
    <div className='modal-header'>
      <h5 className='modal-title'>Select Time Series</h5>
    </div>

    <div className='modal-body pt-1' >
      <div className='d-flex w-100 justify-content-end mt-0 mb-1'>
        <button className="btn btn-sm btn-outline-secondary text-nowrap mr-1" type='button' onClick={onSelectAll}  >Select all</button>
        <button className="btn btn-sm btn-outline-secondary text-nowrap mr-1" type='button' onClick={onDeselectAll} >Deselect all</button>
      </div>
      <div style={{ height: '400px' }}>
        <div className='table-responsive sticky-table h-100'>
          <Table columns={columns} data={timeSeriesList} useTBody={false} useStickyHeader={true} >
            <AltTimeSeriesRow isSelectedTest={isSelectedTest} toggleSelection={onToggleSelection} />
          </Table>
        </div>
      </div>
    </div>

    <div className='form-footer mt-3' >
      <button className="btn btn-secondary ml-2" type='button' onClick={closeDialog}  >Cancel</button>
      <button className="btn btn-primary ml-2" type='button' onClick={onAddSelection} >Apply</button>
    </div>
  </div>
}

const AltTimeSeriesRow = function ({ rawData, isSelectedTest, toggleSelection }) {
  const { key, identityId, source, name, } = rawData;
  function onToggleSelection() {
    toggleSelection(rawData);
  }

  return <tbody className='border-top-0'>
    <tr onClick={() => onToggleSelection()}>
      <td className='text-right'>
        {identityId > 0 && <IdentityId isCompactView={true}>{identityId}</IdentityId>}
      </td>
      <td className='w-100'>{name}</td>
      <td className='text-nowrap'>{source}</td>
      <td className='text-primary' style={{ minWidth: 30 }}>
        <i className={'fas fa-check-circle fa-lg fa-fw ' + (isSelectedTest(key) ? 'visible' : 'invisible')} />
      </td>
    </tr>
  </tbody>;
}

function InputKeyList({ inputKeys, moveDerivationKeyUp, moveDerivationKeyDown, deleteDerivationKey}){
  return inputKeys.map((k, i) => <div className='row w-100' key={`dk${k.key}`}>
          <div className='col' style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip placement='bottom' title={<>{k.identityId} {k.name}</>} >
              <span>{k.identityId} {k.name}</span>
            </Tooltip>
          </div>
          <div className='btn-toolbar'>
            <button type='button' className='btn btn-sm' disabled={i === 0} onClick={() => moveDerivationKeyUp(k)}>
              <i className='fas fa-arrow-up fa-fw' />
            </button>
            <button type='button' className='btn btn-sm' disabled={i === inputKeys.length - 1} onClick={() => moveDerivationKeyDown(k)}>
              <i className='fas fa-arrow-down fa-fw' />
            </button>
            <button type='button' className='btn btn-sm' onClick={() => deleteDerivationKey(k)}>
              <i className='fas fa-trash fa-fw' />
            </button>
          </div>
        </div>)
}