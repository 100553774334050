import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import JsonEditor from '../../shared/JsonEditor';
import {
  analysisEditWorkspaceBegin,
  analysisEditWorkspaceEnd,
  analysisEditWorkspaceUpdate,
  analysisEditWorkspaceApplyChanges
} from '../../../actions/analysis';

export const WorkspaceEditorConnected = connect(
  (state) => ({
    workspace: state.getIn(['analysis', 'edit-workspace'])
  }),
  (dispatch) => ({
    begin(){
      dispatch(analysisEditWorkspaceBegin());
    },
    end(){
      dispatch(analysisEditWorkspaceEnd());
    },
    applyChanges(){
      dispatch(analysisEditWorkspaceApplyChanges());
    },
    update(data){
      dispatch(analysisEditWorkspaceUpdate(data));
    },
    
  })
)(({ workspace, begin, end, applyChanges, update }) => {
  const _workspace = toJS(workspace);
  return <WorkspaceEditor onBegin={begin} onEnd={end} onEdited={update} onApplyChanges={applyChanges} workspace={_workspace}/>
});

class WorkspaceEditor extends Component{
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    this.props.onBegin();
  }

  componentWillUnmount(){
    this.props.onEnd();
  }

  render() {
    return <div className='d-flex flex-column h-100'>
      <div className='flex-fill'>
        {this.props.workspace && <JsonEditor mode='code' style={{width:'100%', height:'500px'}} data={this.props.workspace} onEdited={this.props.onEdited}/>}
      </div>
      <div className='modal-footer'>
         <button data-dismiss type='button' className='btn btn-primary' onClick={this.props.onApplyChanges}>Apply Changes</button>
      </div>
     </div> 
  }
}