export function findParentNodes(element, parents = []) {
  return element.tagName !== 'BODY' && element.parentNode
    ? findParentNodes(element.parentNode, [...parents, element.parentNode])
    : parents;
}

export function getSafeClassName(value) {
  return value.toLowerCase().replace(/[^a-z0-9]/gi, s => {
    let c = s.charCodeAt(0);
    if (c === 32) return '-'; // Space
    return '__' + ('000' + c.toString(16)).slice(-4);
  });
}