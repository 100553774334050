import React from 'react';

export default function OutagesDetailsSourcePanel({ data }) {
  const { sourceUrl } = data ?? {};

  return (
    <table className='w-100 mt-3'>
      <thead>
        <tr>
          <th>Source</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ wordBreak: 'break-all' }}>
            <a href={sourceUrl} target='_blank' rel='noopener noreferrer'>{sourceUrl}</a>
          </td>
        </tr>
      </tbody>
    </table>
  );
}