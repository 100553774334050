import React, { useState } from 'react';
import { connect } from 'react-redux';
import { push } from "redux-first-history";
import { toJS } from '../../../utility/immutable-utility';
import Dialog from '@mui/material/Dialog';
import { copyToClipboard } from '../../../utility/text-utility';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { OnDemandDerivedDetailsEditor } from './OnDemandDerivedDetailsEditor';
import {
  timeSeriesDetailsInitialiseTimeSeries,
  timeSeriesDetailsUpdateObject,
  timeSeriesDetailsUpdateValue,
  timeSeriesDetailsSaveOnDemand,
  timeSeriesDetailsSetFocus,
  timeSeriesDetailsSetHasFocus,
  timeSeriesDetailsCopyOnDemandToNewTimeSeries
} from '../../../actions/timeSeriesDetails';
import {
  timeSeriesDetailDerivationUpdateSearchKeyText,
  timeSeriesDerivationAddKey,
  timeSeriesDetailsDerivationMoveKeyUp,
  timeSeriesDetailsDerivationMoveKeyDown,
  timeSeriesDetailsDerivationDeleteKey,
  timeSeriesDetailsDerivationAddKeys,
  timeSeriesDetailsDerivationMoveFromBasketToKeys,
  timeSeriesDetailsDerivationKeysSave,
  timeSeriesDetailsDerivationKeysLoad,
  timeSeriesDetailsDerivationToggleAltSelection,
  timeSeriesDetailsDerivationSelectAltAll,
  timeSeriesDetailsDerivationAddAltSelection
} from '../../../actions/timeSeriesDetails-derivation';
import {
  timeSeriesDetailssetFunctionsStepStyleToMultiStep,
  timeSeriesDetailsSetFunctionsType,
  timeSeriesDetailsFunctionsAddParameter,
  timeSeriesDetailsFunctionsDeleteParameter,
  timeSeriesDetailsFunctionsUpdateParameterValue,
  timeSeriesDetailsFunctionsAddFunction,
  timeSeriesDetailsFunctionsDeleteFunction,
  timeSeriesDetailsFunctionsMoveFunctionUp,
  timeSeriesDetailsFunctionsMoveFunctionDown,
  timeSeriesDetailsFunctionsAddFunctionKey,
  timeSeriesDetailsFunctionsDeleteFunctionKey,
  timeSeriesDetailsFunctionsMoveFunctionKeyUp,
  timeSeriesDetailsFunctionsMoveFunctionKeyDown,
  timeSeriesDetailsFunctionsUpdateFunctionOutputKey,
  timeSeriesDetailsFunctionsAddFunctionOutputCategory,
  timeSeriesDetailsFunctionsUpdateFunctionOutputCategoryValue,
  timeSeriesDetailsFunctionsDeleteFunctionOutputCategory,
  timeSeriesDetailsFunctionsSetFunctionDisabledState
} from '../../../actions/timeSeriesDetails-functions';
import { referenceDataLoadFunctions } from '../../../actions/referenceData';

export const OnDemandDerivedDetailsConnected = connect(
  (state) => ({
    comparisonMode: state.getIn(['analysis', 'workspace', 'comparisonMode']),
    timeSeriesEditor: state.getIn(['timeSeriesDetails', 'timeSeriesEditor']),
    timeSeriesDetails: state.get('timeSeriesDetails'),
    referenceData: state.getIn(['referenceData'])
  }),
  (dispatch, { timeSeriesKey }) => ({
    saveDerivationData() {
      dispatch(timeSeriesDetailsSaveOnDemand(timeSeriesKey));
    },

    updateTimeSeriesObject(data) {
      dispatch(timeSeriesDetailsUpdateObject(data));
    },
    updateTimeSeriesValue(keyPath, value) {
      dispatch(timeSeriesDetailsUpdateValue(keyPath, value));
    },

    initialiseTimeSeries(timeSeriesObject) {
      dispatch(referenceDataLoadFunctions());
      dispatch(timeSeriesDetailsInitialiseTimeSeries({ timeSeriesObject, inputKeysStyle: 'on-demand' }));
    },
    setInputFocus(id) {
      dispatch(timeSeriesDetailsSetFocus(id));
    },
    setInputHasFocus() {
      dispatch(timeSeriesDetailsSetHasFocus());
    },

    // Derivation
    updateDerivationSearchKeyText(value) {
      dispatch(timeSeriesDetailDerivationUpdateSearchKeyText(value));
    },
    addDerivationSearchKeyText(value) {
      dispatch(timeSeriesDerivationAddKey());
    },
    moveDerivationKeyUp(value) {
      dispatch(timeSeriesDetailsDerivationMoveKeyUp(value));
    },
    moveDerivationKeyDown(value) {
      dispatch(timeSeriesDetailsDerivationMoveKeyDown(value));
    },
    deleteDerivationKey(value) {
      dispatch(timeSeriesDetailsDerivationDeleteKey(value));
    },
    addDerivationKeys(keys) {
      dispatch(timeSeriesDetailsDerivationAddKeys());
    },
    moveFromBasketToKeys(keys) {
      dispatch(timeSeriesDetailsDerivationMoveFromBasketToKeys());
    },
    loadDerivationDetails() {
      dispatch(timeSeriesDetailsDerivationKeysLoad());
    },
    saveDerivationDetails() {
      dispatch(timeSeriesDetailsDerivationKeysSave());
    },
    addAltSelection() {
      dispatch(timeSeriesDetailsDerivationAddAltSelection());
    },
    toggleAltSelection(inputKey) {
      dispatch(timeSeriesDetailsDerivationToggleAltSelection(inputKey));
    },
    selectAltAll(value) {
      dispatch(timeSeriesDetailsDerivationSelectAltAll(value));
    },

    // Functions
    setFunctionsStepStyleToMultiStep(style) {
      dispatch(timeSeriesDetailssetFunctionsStepStyleToMultiStep(style));
    },
    setFunctionsType(data) {
      dispatch(timeSeriesDetailsSetFunctionsType(data));
    },
    addFunctionsStepParameter(data) {
      dispatch(timeSeriesDetailsFunctionsAddParameter(data));
    },
    deleteFunctionsStepParameter(data) {
      dispatch(timeSeriesDetailsFunctionsDeleteParameter(data));
    },
    updateFunctionParameterValue(data) {
      dispatch(timeSeriesDetailsFunctionsUpdateParameterValue(data));
    },
    addMultiStepFunction() {
      dispatch(timeSeriesDetailsFunctionsAddFunction());
    },
    deleteMultiStepFunction(index) {
      dispatch(timeSeriesDetailsFunctionsDeleteFunction(index));
    },
    moveMultiStepFunctionUp(index) {
      dispatch(timeSeriesDetailsFunctionsMoveFunctionUp(index));
    },
    moveMultiStepFunctionDown(index) {
      dispatch(timeSeriesDetailsFunctionsMoveFunctionDown(index));
    },
    addFunctionKey(data) {
      dispatch(timeSeriesDetailsFunctionsAddFunctionKey(data));
    },
    deleteFunctionKey(data) {
      dispatch(timeSeriesDetailsFunctionsDeleteFunctionKey(data));
    },
    moveFunctionKeyUp(data) {
      dispatch(timeSeriesDetailsFunctionsMoveFunctionKeyUp(data));
    },
    moveFunctionKeyDown(data) {
      dispatch(timeSeriesDetailsFunctionsMoveFunctionKeyDown(data));
    },
    addFunctionOutputCategory(data) {
      dispatch(timeSeriesDetailsFunctionsAddFunctionOutputCategory(data));
    },
    updateFunctionOutputKey(data) {
      dispatch(timeSeriesDetailsFunctionsUpdateFunctionOutputKey(data));
    },
    updateFunctionOutputCategoryValue(data) {
      dispatch(timeSeriesDetailsFunctionsUpdateFunctionOutputCategoryValue(data));
    },
    deleteFunctionOutputCategory(data) {
      dispatch(timeSeriesDetailsFunctionsDeleteFunctionOutputCategory(data));
    },
    setFunctionDisabled(data) {
      dispatch(timeSeriesDetailsFunctionsSetFunctionDisabledState(data));
    },
    convertToTimeSeries() {
      dispatch(timeSeriesDetailsCopyOnDemandToNewTimeSeries());
      dispatch(push(`/timeseries/new`));
    }
  })
)(({ basketTimeseries, referenceData, timeSeriesEditor, comparisonMode, timeSeriesKey, saveDerivationData,
  initialiseTimeSeries,
  setInputFocus, setInputHasFocus,
  updateTimeSeriesObject, updateTimeSeriesValue,
  updateDerivationSearchKeyText, addDerivationSearchKeyText, addDerivationKeys, moveFromBasketToKeys,
  moveDerivationKeyUp, moveDerivationKeyDown, deleteDerivationKey, loadDerivationDetails, saveDerivationDetails, addAltSelection, toggleAltSelection, selectAltAll,
  setFunctionsStepStyleToMultiStep, setFunctionsType, addFunctionsStepParameter, deleteFunctionsStepParameter, updateFunctionParameterValue, addMultiStepFunction, deleteMultiStepFunction, moveMultiStepFunctionUp, moveMultiStepFunctionDown, addFunctionKey, deleteFunctionKey, moveFunctionKeyUp, moveFunctionKeyDown, addFunctionOutputCategory, updateFunctionOutputKey, updateFunctionOutputCategoryValue, deleteFunctionOutputCategory, setFunctionDisabled,
  convertToTimeSeries
}) => {
  const [isDialogOpen, setDialogIsOpen] = useState(false);

  const _referenceData = toJS(referenceData, {});
  const _timeSeriesEditor = toJS(timeSeriesEditor);
  const [menuAnchor, setMenuAnchor] = useState();

  const openMenu = e => setMenuAnchor(e.currentTarget);
  const closeMenu = () => setMenuAnchor(null);
  const isOpen = !!menuAnchor;

  function onOpenDialog() {
    const ts = basketTimeseries.find(ts => ts.key === timeSeriesKey);
    initialiseTimeSeries({ derivationData: ts.derivationData });
    setDialogIsOpen(true);
  }

  function onCloseDialog() {
    setDialogIsOpen(false);
  }

  function onSaveSettings(close) {
    saveDerivationData();
    close();
  }

  function onCancelSettings(close) {
    close();
  }

  function onOpenMenu(e) {
    openMenu(e);
  };

  function onCloseMenu(e) {
    closeMenu();
  };

  function onCopyKey() {
    copyToClipboard(timeSeriesKey);
    closeMenu();
  }

  function onConvertToTimeSeries() {
    convertToTimeSeries();
  }

  return <>
    <div className='identity-menu-button-group' style={{height:'21px'}}>
      <button type='button' className='identity-menu-button identity-menu-button-no-border mr-1'  title='Derived timeseries' onClick={onOpenDialog} >
        <i className='fas fa-sitemap fa-fw'></i>
      </button>

      <button type='button' className='identity-menu-button m-0'  title='Derived timeseries' onClick={onOpenMenu} >
        <i className='fas fa-caret-down fa-fw' />
      </button>    
    </div>

    <Dialog open={isDialogOpen} onClose={onCloseDialog} fullWidth={true} maxWidth={'md'} >
      <OnDemandDerivedDetailsEditor
          closeDialog={onCloseDialog}
          timeSeriesKey={timeSeriesKey}
          basketTimeseries={basketTimeseries}
          comparisonMode={comparisonMode}

          inputFocusId={_timeSeriesEditor.inputFocusId}
          setInputFocus={setInputFocus}
          setInputHasFocus={setInputHasFocus}

          referenceData={_referenceData}
          timeSeriesEditor={_timeSeriesEditor}
          saveSettings={() => onSaveSettings(onCloseDialog)}
          cancelSave={() => onCancelSettings(onCloseDialog)}

          updateTimeSeriesObject={updateTimeSeriesObject}
          updateTimeSeriesValue={updateTimeSeriesValue}

          updateDerivationSearchKeyText={updateDerivationSearchKeyText}
          addDerivationSearchKeyText={addDerivationSearchKeyText}
          moveFromBasketToKeys={moveFromBasketToKeys}
          moveDerivationKeyUp={moveDerivationKeyUp}
          moveDerivationKeyDown={moveDerivationKeyDown}
          deleteDerivationKey={deleteDerivationKey}
          addDerivationKeys={addDerivationKeys}
          loadDerivationDetails={loadDerivationDetails}
          saveDerivationDetails={saveDerivationDetails}
          addAltSelection={addAltSelection}
          toggleAltSelection={toggleAltSelection}
          selectAltAll={selectAltAll}

          setFunctionsStepStyleToMultiStep={setFunctionsStepStyleToMultiStep}
          setFunctionsType={setFunctionsType}
          addFunctionsStepParameter={addFunctionsStepParameter}
          deleteFunctionsStepParameter={deleteFunctionsStepParameter}
          updateFunctionParameterValue={updateFunctionParameterValue}
          addMultiStepFunction={addMultiStepFunction}
          deleteMultiStepFunction={deleteMultiStepFunction}
          moveMultiStepFunctionUp={moveMultiStepFunctionUp}
          moveMultiStepFunctionDown={moveMultiStepFunctionDown}
          addFunctionKey={addFunctionKey}
          deleteFunctionKey={deleteFunctionKey}
          moveFunctionKeyUp={moveFunctionKeyUp}
          moveFunctionKeyDown={moveFunctionKeyDown}
          addFunctionOutputCategory={addFunctionOutputCategory}
          updateFunctionOutputKey={updateFunctionOutputKey}
          updateFunctionOutputCategoryValue={updateFunctionOutputCategoryValue}
          deleteFunctionOutputCategory={deleteFunctionOutputCategory}
          setFunctionDisabled={setFunctionDisabled}
          
          convertToTimeSeries={onConvertToTimeSeries}/>
    </Dialog>

    <Menu anchorEl={menuAnchor} open={isOpen} onClose={onCloseMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          TransitionComponent={Fade}>
      <MenuItem className='py-1 px-2 justify-content-between' onClick={onCopyKey}>
        <span><i className='fas fa-key fa-fw ml-2 mr-3' /> Copy Key to clipboard</span>
      </MenuItem>
    </Menu>
  </>
});