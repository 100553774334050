import React, { useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EVENTS_API_URL } from '../../../config';

function DatasetPicker({ datasetOptions, setDatasetOptions, setIsDirty }) {
  const [searchDatasetText, setSearchDatasetText] = useState("");
  const [filteredDatasetCount, setFilteredDatasetCount] = useState(0);
  const [filteredDatasetOptions, setFilteredDatasetOptions] = useState([]);
  const [allDatasetsUrl, setAllDatasetsUrl] = useState('');
  const [, allDatasetsData,] = useFetch(allDatasetsUrl);

  useEffect(() => {
    if (searchDatasetText && searchDatasetText !== "") {
      setAllDatasetsUrl(`${EVENTS_API_URL}/datasets?${searchDatasetText}`);
    }
  }, [searchDatasetText]);

  useEffect(() => {
    if (allDatasetsData) {
      const upperSearchDatasetText = searchDatasetText.toUpperCase();
      const filteredList = allDatasetsData.filter(i => i.name.toUpperCase().indexOf(upperSearchDatasetText) >= 0);
      setFilteredDatasetCount(filteredList.length);
      setFilteredDatasetOptions(filteredList.slice(0, 50));
    }
  }, [allDatasetsData, searchDatasetText]);

  function onOpenDatasetSearch(e) {
    e.preventDefault();

    if (filteredDatasetOptions.length === 0)
      setAllDatasetsUrl(`${EVENTS_API_URL}/datasets`);
  }

  function onAddDataset(e, dataset) {
    e.preventDefault();
    if (datasetOptions.some(d => d.id === dataset.id))
      return;

    setIsDirty(true);
    setDatasetOptions([...datasetOptions, dataset]);
  }

  function onRemoveDataset(e, dataset) {
    e.preventDefault();
    setIsDirty(true);
    setDatasetOptions(datasetOptions.filter(d => d.id !== dataset.id));
  }

  return <div>
    <div className='input-group' id='dropdownMenuLink' data-toggle="dropdown" onClick={onOpenDatasetSearch}>
      <input className='form-control' type='text' placeholder='Search data sets by' value={searchDatasetText} onChange={e => setSearchDatasetText(e.target.value)} autoComplete='off' />
      <div className="input-group-append">
        <span className="input-group-text" id="basic-addon2"><i className='fas fa-caret-down fa-fw' /></span>
      </div>
    </div>
    <div className='dropdown-menu col-md-12'>
      <div className='list-group' style={{overflowY:'auto', height:'200px'}}>
        {filteredDatasetOptions.map(d => (
          <button key={d.id} className='list-group-item list-group-item-action' onClick={e => onAddDataset(e, d)}>{d.name}</button>
        ))}
      </div>
      <div className='text-center'>
        Showing {filteredDatasetOptions ? filteredDatasetOptions.length : 0} of {filteredDatasetCount} suggestions
              </div>
    </div>
    <table className='col-md-12'>
      <tbody>
        {datasetOptions.map(d => (
          <tr key={d.id}>
            <td className='w-100'>{d.name}</td>
            <td className=''>
              <button type='button' className='btn btn-sm' onClick={e => onRemoveDataset(e, d)}>
                <i className='fas fa-trash fa-fw' />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>;
}

export default DatasetPicker;