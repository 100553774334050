import React from 'react';
import { connect } from 'react-redux';
import {
  analysisCrossSchemaSearchLoadIdentityInfo,
} from '../../../actions/analysis-cross-schema-search';
import { toJS } from '../../../utility/immutable-utility';
import { SearchResultInfo } from './SearchResultInfo';

export const SearchResultInfoConnected = connect(
  (state, { index, stateContext }) => ({
    isLoading: state.getIn(['analysisCrossSchemaSearch', stateContext,  'results', 'data', index, 'info', 'isLoading']),
    info: state.getIn(['analysisCrossSchemaSearch', stateContext,  'results', 'data', index, 'info', 'data'])
  }),
  (dispatch, { index, id, stateContext }) => ({
    loadInfo() {
      dispatch(analysisCrossSchemaSearchLoadIdentityInfo(stateContext, index, id));
    }
  })
)(({ loadInfo, isLoading, info }) => {
  const _info = info ? toJS(info) : undefined;
  return <SearchResultInfo isLoading={isLoading} loadInfo={loadInfo} info={_info} />
});