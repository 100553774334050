import React from 'react';
import { IndividualAbsoluteDatesSelector } from './IndividualAbsoluteDatesSelector';
import { IndividualRelativeDatesSelector } from './IndividualRelativeDatesSelector';
import { AggregateAbsoluteDatesSelector } from './AggregateAbsoluteDatesSelector';
import { AggregateRelativeDatesSelector } from './AggregateRelativeDatesSelector';
import { comparisonModes } from '../../../utility/comparisonmode-utility';

export function ComparisonsEditor({ setActiveView, applySelection, removeAllSelection, removeSelectedDate, comparisonMode, comparisonSettings,
  setComparisonModeSetRange, togglePreselection, addPreselectionToSelection, toggleColumn, toggleRow, clearPreselection, setDayActiveDate,
  addRangeToSelection, toggleAggregationOperation, addAggregatePreselectionToSelection,
  setIndividualRelativeDateText, addIndividualRelativeDate,
  setAggregateRelativeDate, addAggregateRelativeDatesToSelection, setAggregateRelativeFromDate, setAggregateRelativeToDate, addAggregateRelativeRangeToSelection, addAggregateRelativeToPreselection, removeAggregateRelativeFromPreselection }) {

  function onSetActiveView(currentView) {
    setActiveView(comparisonMode, currentView);
  }

  function onApply() {
    applySelection(comparisonMode);
  }

  const onRemoveSelectedDate = (value) => {
    removeSelectedDate(comparisonMode, value);
  }

  const onRemoveAllSelection = () => {
    removeAllSelection(comparisonMode);
  }

  function isActiveView(comparisonSettings, activeView) {
    return comparisonSettings.activeView === activeView;
  }

  return <form className='modal-content'>
    <div className='modal-header'>
      <h5 className='modal-title'>{comparisonSettings.windowTitle}</h5>
      <button type='button' className='close' data-dismiss='modal'>&times;</button>
    </div>
    <div className='modal-body'>

      <div className='row'>
        <div className='col-md-12 col-lg-12'>
          <ul className='nav nav-tabs mb-2'>
            <li className='nav-item'>
              <button className={`nav-link ${isActiveView(comparisonSettings, 'individualAbs') ? 'active' : ''}`} type='button' onClick={() => onSetActiveView('individualAbs')}>Standard</button>
            </li>
            <li className='nav-item ml-1' >
              <button className={`nav-link ${isActiveView(comparisonSettings, 'individualRel') ? 'active' : ''}`} type='button' onClick={() => onSetActiveView('individualRel')}>Relative</button>
            </li>
            <li className='nav-item ml-1'>
              <button className={`nav-link ${isActiveView(comparisonSettings, 'aggregationAbs') ? 'active' : ''}`} type='button' onClick={() => onSetActiveView('aggregationAbs')}>Aggregated</button>
            </li>
            <li className='nav-item ml-1'>
              <button className={`nav-link ${isActiveView(comparisonSettings, 'aggregationRel') ? 'active' : ''}`} type='button' onClick={() => onSetActiveView('aggregationRel')}>Relative Aggregated</button>
            </li>
          </ul>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12 col-lg-12' >
          <div style={{ minHeight: '434px' }}>
            {isActiveView(comparisonSettings, 'individualAbs') && <IndividualAbsoluteDatesSelector
              comparisonSettings={comparisonSettings} comparisonMode={comparisonMode}
              setComparisonModeSetRange={setComparisonModeSetRange} togglePreselection={togglePreselection} addPreselectionToSelection={addPreselectionToSelection} toggleColumn={toggleColumn} toggleRow={toggleRow} clearPreselection={clearPreselection} setDayActiveDate={setDayActiveDate} />}
         
            {isActiveView(comparisonSettings, 'individualRel') && <IndividualRelativeDatesSelector
              comparisonSettings={comparisonSettings} comparisonMode={comparisonMode}
              setRelativeDateText={setIndividualRelativeDateText} applyRelativeDate={addIndividualRelativeDate} />}
         
            {isActiveView(comparisonSettings, 'aggregationAbs') && <AggregateAbsoluteDatesSelector
              comparisonSettings={comparisonSettings} comparisonMode={comparisonMode}
              setComparisonModeSetRange={setComparisonModeSetRange} togglePreselection={togglePreselection} addPreselectionToSelection={addPreselectionToSelection} toggleColumn={toggleColumn} toggleRow={toggleRow} clearPreselection={clearPreselection} setDayActiveDate={setDayActiveDate}
              addRangeToSelection={addRangeToSelection} toggleAggregationOperation={toggleAggregationOperation} addAggregatePreselectionToSelection={addAggregatePreselectionToSelection} />}
   
            {isActiveView(comparisonSettings, 'aggregationRel') && <AggregateRelativeDatesSelector
              comparisonSettings={comparisonSettings} comparisonMode={comparisonMode}
              setAggregateRelativeDate={setAggregateRelativeDate} toggleAggregationOperation={toggleAggregationOperation} addAggregateRelativeDatesToSelection={addAggregateRelativeDatesToSelection} setAggregateRelativeFromDate={setAggregateRelativeFromDate} setAggregateRelativeToDate={setAggregateRelativeToDate} addAggregateRelativeRangeToSelection={addAggregateRelativeRangeToSelection} addAggregateRelativeToPreselection={addAggregateRelativeToPreselection} removeAggregateRelativeFromPreselection={removeAggregateRelativeFromPreselection}/>}
          </div>
        </div>
      </div>

      <hr />

      <div className='row'>
        <div className='col-md-12 col-lg-12' >
          <div className='form-group'>
            <label className='font-weight-bold'>Selected Dates</label>
            <div className='input-group' >
              <div className='form-control' style={{ height: "6em", overflowY: "auto" }}>
                {comparisonSettings.selection.map(s => <div key={`${s.mode}-${s.name}`} title={s.description} className='btn btn-outline-secondary btn-sm mr-1 mb-1 ml-1 mt-1' onClick={() => onRemoveSelectedDate(s)} >
                  {s.mode === comparisonModes.gasYear && <span>GY&nbsp;</span>}
                  {s.name}
                  <i className='fas fa-times fa-fw' />
                </div>)}
              </div>
              <div className='input-group-append dropdown'>
                {comparisonSettings.selection.length > 0 && <button type='button' className='btn btn-outline-secondary' onClick={onRemoveAllSelection} >
                  <i className='fas fa-times fa-fw' />
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
        <button type='button' className='btn btn-primary' data-dismiss='modal' onClick={onApply}>Apply</button>
      </div>
    </div>
  </form>
}