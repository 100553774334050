import React, { Fragment, cloneElement, useRef, useState, useEffect, useMemo, useCallback } from 'react';

export function TableColumn({ column, filters, orderBy, orderByDirection, onSelectFilter, onSortChange }) {
  const { className = '', style = {}, key, title, filterable, sortable, leftComponent, rightComponent, noWrap = true } = column || {};

  const columnComponentProps = {...column};
  delete columnComponentProps.leftComponent;
  delete columnComponentProps.rightComponent;

  return column
    ? <th className={ `${className} ${filterable ? 'filterable' : ''} ${sortable ? 'sortable' : ''} ${noWrap ? 'text-nowrap' : ''}` }
          style={{ userSelect: 'none', ...style }}
          onClick={ sortable ? () => onSortChange(key) : undefined }>
        <div className='d-flex justify-content-between align-items-end'>
          { leftComponent ? cloneElement(leftComponent, { ...columnComponentProps, key:'left' }) : '' }
          <span>{ title }</span>
          { rightComponent ? cloneElement(rightComponent, { ...columnComponentProps, key:'right' }) : '' }

          { filterable ? <TableFilterIcon columnKey={ key } filters={ filters } onSelectFilter={ onSelectFilter } /> : '' }
          { sortable ? <TableSortIcon columnKey={ key } orderBy={ orderBy } orderByDirection={ orderByDirection } onSortChange={ onSortChange } /> : '' }
        </div>
      </th>
    : <th />
}

export function TableEmpty({ message, columnsCount }) {
  return (
    <TableBody>
      <tr>
        <td colSpan={ columnsCount }>
          <div className='row no-gutters'>
            <div className='col text-center'>{ message || 'No data' }</div>
          </div>
        </td>
      </tr>
    </TableBody>
  );
}

export function TableBody({ children, useTBody = true }) {
  return useTBody ? <tbody className='border-top-0'>{ children }</tbody> : children;
}

export function TableFilterIcon({ filters, columnKey, onSelectFilter }) {
  const isActive = filters.some(f => f.name === columnKey && f.value !== undefined && f.value !== null && f.value !== '');

  return isActive
    ? <i className='fas fa-filter fa-fw' onClick={ (e) => onSelectFilter(e, columnKey) } />
    : <i className='fas fa-filter fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} onClick={ (e) => onSelectFilter(e, columnKey) } />;
}

export function TableSortIcon({ orderBy, orderByDirection, columnKey }) {
  return orderBy && orderBy === columnKey
    ? orderByDirection === 'desc'
    ? <i className='fas fa-sort-down fa-fw' />
    : <i className='fas fa-sort-up fa-fw' />
    : <i className='fas fa-sort fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} />;
}

export function TableFilter({ filter, onChange }) {
  const [isVisible, setVisibility] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const filterRef = useRef();
  const filterInput = useRef();

  useEffect(() => {
    window.$(filterRef.current).modal({
      show: false
    })
    .on('shown.bs.modal', () => filterInput.current.focus())
    .on('hidden.bs.modal', () => onDismiss());
  }, []);

  useEffect(() => {
    if (filter.name) {
      setFilterValue(filter.value || '');
      setVisibility(true);
    }
  }, [filter]);

  useEffect(() => {
    window.$(filterRef.current).modal(isVisible ? 'show' : 'hide');
  }, [isVisible]);

  function onSubmit(e) {
    e.preventDefault();

    onChange(filter.name, filterValue);
    onDismiss();
  }

  function onClear() {
    onChange(filter.name, '');
    onDismiss();
  }

  function onDismiss() {
    setFilterValue('');
    setVisibility(false);
  }

  return (
    <div ref={ filterRef } className='modal fade' tabIndex='-1'>
      <form className='modal-dialog' onSubmit={ onSubmit }>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Filter</h5>
            <button type='button' className='close' data-dismiss='modal'>&times;</button>
          </div>
          <div className='modal-body'>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <label className='input-group-text'>{ filter.name }</label>
              </div>
              <div className='input-group-prepend'>
                <label className='input-group-text'>eq</label>
              </div>
              <input ref={ filterInput } type='text' className='form-control' value={ filterValue } onChange={ (e) => setFilterValue(e.target.value) } />
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-outline-secondary mr-auto' data-dismiss='modal' onClick={ onClear }>Clear</button>
            <button type='button' className='btn btn-secondary' data-dismiss='modal'>Close</button>
            <button type='submit' className='btn btn-primary'>Apply filter</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default function Table({ children, columns, data, className, style, emptyMessage, useTBody, useStickyHeader = false, isActiveTest,
  filters, onFilterChange, orderBy, orderByDirection, data_id = 'table', onSortChange = () => {}, onSelectKey = (r) => r.id
}) {
  const [filter, setFilter] = useState('');

  const _columns = useMemo(() => columns && columns.toJS ? columns.toJS() || [] : columns || [] , [columns]);
  const _filters = useMemo(() => filters && filters.toJS ? filters.toJS() || [] : filters || [], [filters]);
  const _data = useMemo(() => data && data.toJS ? data.toJS() || [] : data || [], [data]);
  const _rows = useMemo(() => _data.map(d => {
          let row = {
            id: d.id,
            key: onSelectKey(d),
            cellData: {},
            rawData: { ...d }            
          };

          _columns.forEach(c => c && d[c.key] !== null && d[c.key] !== undefined ? row.cellData[c.key] = c.format ? c.format(d[c.key]) : d[c.key] : '');

          return row;
        }), [_columns, _data, onSelectKey]);

  const onSelectFilter = useCallback((e, name) => {
    e.stopPropagation();

    const value = _filters && _filters.length ? (_filters.find(f => f.name === name) || {}).value || '' : '';

    setFilter({ name: name, value: value });
  }, [_filters]);

  return (
    <Fragment>
      <table data_id={data_id} className={ `table table-sm ${className}` } style={style}>
        <thead>
          <tr className={ `bg-light ${useStickyHeader ? 'sticky-header' : ''}` }>
            { columns.map((c, cx) => (
              <TableColumn key={ cx } column={ c }
                           filters={ _filters } onSelectFilter={ onSelectFilter }
                           orderBy={ orderBy } orderByDirection={ orderByDirection } onSortChange={ onSortChange } />
            )) }
          </tr>
        </thead>
        { _rows && _rows.length
          ? (
            <TableBody useTBody={ useTBody }>
              { _rows.map((r, rx) => {
                const isActive = isActiveTest && isActiveTest(r);
                return cloneElement(children, { key: r.key || rx, index: rx, cellData: r.cellData, rawData: r.rawData, isActive })
              }) }
            </TableBody>
          )
          : <TableEmpty message={ emptyMessage } columnsCount={ _columns.length } /> }
      </table>
      { onFilterChange ? <TableFilter filter={ filter } onChange={ onFilterChange } /> : null }
    </Fragment>
  );
};