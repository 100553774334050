import React from 'react';
import { connect } from 'react-redux';
import { push } from "redux-first-history";
import ScreenCaptureModal from './ScreenCaptureModal';
import {
  screenCaptureClear,
  screenCaptureSelect,
  screenCaptureOpenNewEdit,
  screenCaptureOpenEdit,
  screenCaptureCloseEdit,
  screenCaptureSelectedNow,
  screenCaptureDelete
} from '../../../actions/screenCapture';

const ScreenCaptureConnected = connect(
  (state) => ({
    hasWarning: state.getIn(['screenCapture', 'hasWarning']),
    warningMessage: state.getIn(['screenCapture', 'warningMessage']),
    isVisible: state.getIn(['screenCapture', 'isVisible']),
    isLoading: state.getIn(['screenCapture', 'isLoading']),
    isSaving: state.getIn(['screenCapture', 'isSaving']),
    isEditing: state.getIn(['screenCapture', 'isEditing']),
    actions: state.getIn(['screenCapture', 'actions']),
    selectedId: state.getIn(['screenCapture', 'selectedId']),
    editId: state.getIn(['screenCapture', 'edit', 'id'])
  }),
  (dispatch) => ({
    openNewEdit() {
      dispatch(screenCaptureOpenNewEdit());
    },
    openEdit(id) {
      dispatch(screenCaptureOpenEdit(id));
    },
    closeEdit() {
      dispatch(screenCaptureCloseEdit());
    },
    selectAction(id) {
      dispatch(screenCaptureSelect(id));
    },
    deleteAction(id) {
      dispatch(screenCaptureDelete(id));
    },
    goToAction(id) {
      dispatch(screenCaptureClear());
      dispatch(push(`/actions/scheduled/${id}/edit`));
    },
    closeModal() {
      dispatch(screenCaptureClear());
    },
    captureNow() {
      dispatch(screenCaptureSelectedNow());
    }
  })
)(({ hasWarning, warningMessage, isVisible, isLoading, isSaving, isEditing, selectedId, editId, actions, openNewEdit, openEdit, closeEdit, selectAction, deleteAction, goToAction, closeModal, captureNow }) => {
  return (
    <ScreenCaptureModal
      hasWarning={hasWarning}
      warningMessage={warningMessage}
      isLoading={isLoading}
      isVisible={isVisible}
      isSaving={isSaving}
      isEditing={isEditing}
      selectedId={selectedId}
      editId={editId}
      actions={actions}
      openNewEdit={openNewEdit}
      openEdit={openEdit}
      closeEdit={closeEdit}
      selectAction={selectAction}
      deleteAction={deleteAction}
      goToAction={goToAction}
      closeModal={closeModal}
      captureNow={captureNow} />
  );
});

export default ScreenCaptureConnected;