import React from 'react';
import JSONEditor from 'jsoneditor';

class JsonEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editor = null;
  }

  expandAll() {
    if (typeof this.editor.expandAll === 'function') this.editor.expandAll();
  }

  componentDidMount() {
    this.initialiseEditor();
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate = this.props.data !== nextProps.data;
    return shouldUpdate;
  }

  initialiseEditor() {
    if (!this.props.data) return;

    if (!this.editor) {
      let container = document.getElementById('editor');

      let jsonEditor = this.editor = new JSONEditor(container, {
        onChange: this.onChanged.bind(this),
        mode: 'code',
        modes: ['code', 'tree'],
        onModeChange() {
          if (typeof jsonEditor.expandAll === 'function') jsonEditor.expandAll();
        }
      }, this.props.data);

      this.expandAll();
    }
    else {
      this.editor.set(this.props.data);
      this.editor.refresh();
      this.expandAll();
    }
  }

  onChanged() {
    try {
      let data = this.editor.get();
      this.props.onEdited(data);
    }
    catch {
      const text = this.editor.getText();
      if (text === '')
        this.props.onEdited({});
    }
  }

  render() {
    return (
      <div className='d-flex flex-column h-100'>
        <div id='editor' className='flex-fill' />
      </div>
    );
  }
}

export default JsonEditor;