import React from 'react';
import moment from 'moment';
import LensedDateTime from '../../shared/LensedDateTime';
import CalendarYearPicker from './CalendarYearPicker';
import CalendarMonthPicker from './CalendarMonthPicker';
import CalendarDayPicker from './CalendarDayPicker';
import { comparisonModeLensedDateOptions, comparisonModes } from '../../../utility/comparisonmode-utility';
import { gasSeasonNames, quarterNames } from '../../../utility/date-utility';

export function AggregateAbsoluteDatesSelector({ comparisonMode, comparisonSettings, setComparisonModeSetRange, togglePreselection, addAggregatePreselectionToSelection, addRangeToSelection, toggleColumn, toggleRow, clearPreselection, setDayActiveDate, toggleAggregationOperation }) {
  const onRangeStartChanged = (e) => {
    setComparisonModeSetRange(comparisonMode, e, comparisonSettings.rangeEnd);
  }

  const onRangeEndChanged = (e) => {
    setComparisonModeSetRange(comparisonMode, comparisonSettings.rangeStart, e);
  }

  const onTogglePreselection = (y) => {
    togglePreselection(comparisonMode, y);
  }

  const onAddPreselectionToSelection = () => {
    addAggregatePreselectionToSelection(comparisonMode);
  }

  const onAddRangeToSelection = () => {
    addRangeToSelection(comparisonMode);
  }

  const onToggleColumn = (column) => {
    toggleColumn(comparisonMode, column);
  }

  const onToggleRow = (row) => {
    toggleRow(comparisonMode, row);
  }

  const onClearPreselection = () => {
    clearPreselection(comparisonMode);
  }

  const onToggleAggregationOperation = (e) => {
    toggleAggregationOperation(comparisonMode, e.target.value, e.target.checked === true);
  }

  const onSetDayActiveDate = (date) => {
    if (date)
      setDayActiveDate(comparisonMode, moment().year(date.year).month(date.month).date(1).format());
    else
      setDayActiveDate(comparisonMode, undefined);
  }

  const isOperationSelected = (value) => {
    return comparisonSettings.view.aggregationAbs.operations.some(a => a === value);
  }

  return <div>
    <div className='form-group'>
      <label className='font-weight-bold mr-3'>Operation</label>
      <div className='form-check d-inline-block ml-3'>
        <input className='form-check-input' type='checkbox' id='grouping-avg' value='Avg' checked={isOperationSelected('Avg')} onChange={onToggleAggregationOperation} />
        <label className='form-check-label' htmlFor="grouping-avg">Avg</label>
      </div>

      <div className='form-check d-inline-block ml-3'>
        <input className='form-check-input' type='checkbox' id='grouping-sum' value='Sum' checked={isOperationSelected('Sum')} onChange={onToggleAggregationOperation} />
        <label className='form-check-label' htmlFor="grouping-sum">Sum</label>
      </div>

      <div className='form-check d-inline-block ml-3'>
        <input className='form-check-input' type='checkbox' id='grouping-min' value='Min' checked={isOperationSelected('Min')} onChange={onToggleAggregationOperation} />
        <label className='form-check-label' htmlFor="grouping-min">Min</label>
      </div>

      <div className='form-check d-inline-block ml-3'>
        <input className='form-check-input' type='checkbox' id='grouping-max' value='Max' checked={isOperationSelected('Max')} onChange={onToggleAggregationOperation} />
        <label className='form-check-label' htmlFor="grouping-max">Max</label>
      </div>
    </div>

    <div className='form-group'>
      <div className='input-group' >
        <label className='fota-input-group-label font-weight-bold'>Range</label>
        <LensedDateTime lens={comparisonModeLensedDateOptions[comparisonMode]} dateTimeClassName='form-control mr-2' placeholder='Start date' value={moment.utc(comparisonSettings.rangeStart).format('YYYY-MM-DD')} onChange={e => onRangeStartChanged(e)} />
        <LensedDateTime lens={comparisonModeLensedDateOptions[comparisonMode]} dateTimeClassName='form-control mr-2' placeholder='End date' value={moment.utc(comparisonSettings.rangeEnd).format('YYYY-MM-DD')} onChange={e => onRangeEndChanged(e)} />
        <button type='button' className='btn btn-primary' onClick={() => onAddRangeToSelection()}>Add Range</button>
      </div>
      {comparisonSettings.view.aggregationAbs.showAddRangePreselectionErrorMessage && <div className="alert alert-warning mt-2" >Please select 1 or more operations</div>}
    </div>

    <div className='form-group'>
      <label className='font-weight-bold  mr-3'>Dates</label>
      {(comparisonMode === comparisonModes.yearOnYear || comparisonMode === comparisonModes.gasYear) && (
          <CalendarYearPicker
            dates={comparisonSettings.view.aggregationAbs.options.dates}
            topLeftClick={onClearPreselection}
            columnClick={onToggleColumn}
            cellClick={onTogglePreselection} />)}

      {(comparisonMode === comparisonModes.gasSeason) &&
        <CalendarMonthPicker
          range={comparisonSettings.view.aggregationAbs.options}
          topLeftClick={onClearPreselection}
          rowClick={onToggleRow}
          cellClick={onTogglePreselection}
          columnHeaderStyle='spreadsheet-cursor-top'
          headers={gasSeasonNames}
        />}
      
      {(comparisonMode === comparisonModes.quarter) &&
        <CalendarMonthPicker
          range={comparisonSettings.view.aggregationAbs.options}
          topLeftClick={onClearPreselection}
          rowClick={onToggleRow}
          cellClick={onTogglePreselection}
          columnHeaderStyle='spreadsheet-cursor-top'
          headers={quarterNames}
        />}

      {comparisonMode === comparisonModes.monthOnMonth &&
        <CalendarMonthPicker
          range={comparisonSettings.view.aggregationAbs.options}
          topLeftClick={onClearPreselection}
          columnClick={onToggleColumn}
          rowClick={onToggleRow}
          cellClick={onTogglePreselection}
          columnHeaderStyle='spreadsheet-cursor-top'
          rowHeaderStyle='spreadsheet-cursor-left'
        />}

      {comparisonMode === comparisonModes.dayOnDay && comparisonSettings.view.aggregationAbs && !comparisonSettings.view.aggregationAbs.activeDate &&
        <CalendarMonthPicker
          range={comparisonSettings.view.aggregationAbs.yearView}
          topLeftClick={onClearPreselection}
          cellClick={onSetDayActiveDate}
          cellStyle='zoom-cursor' />}

      {comparisonMode === comparisonModes.dayOnDay && comparisonSettings.view.aggregationAbs && comparisonSettings.view.aggregationAbs.options &&
        <>
          <div>
            <label className='font-weight-bold'>{comparisonSettings.view.aggregationAbs.options.dateText}</label>
            <button type='button' className='close text-right' onClick={e => onSetDayActiveDate(undefined)}>&times;</button>
          </div>

          <CalendarDayPicker
            range={comparisonSettings.view.aggregationAbs.options}
            topLeftClick={onClearPreselection}
            columnClick={onToggleColumn}
            rowClick={onToggleRow}
            cellClick={onTogglePreselection}
            columnHeaderStyle='spreadsheet-cursor-top'
            rowHeaderStyle='spreadsheet-cursor-left' />
        </>}
    </div>

    {(comparisonMode !== comparisonModes.day || 
      (comparisonSettings.view.aggregationAbs && comparisonSettings.view.aggregationAbs.options)) && (
      <div>
        <div className='form-group text-right'>
          <button type='button' className='btn btn-primary' onClick={() => onAddPreselectionToSelection()}>Add Selected</button>
        </div>
        {comparisonSettings.view.aggregationAbs.showAddPreselectionErrorMessage && <div className="alert alert-warning" >Please select  1 or more dates and operations</div>}
      </div>)}
  </div>
}