import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../shared/Loading';
import {
  homepageRefresh,
  homepageImagesRefresh,
  homepageSetFolderImage,
  homepageDeleteFolderImage,
  homepageSetItemImage,
  homepageDeleteItemImage,
  homepageSetSelectedImage
} from '../../../actions/homepage';
import { WORKSPACES_IMAGE_URL } from '../../../config';

const emptyImgUrl = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D';

const HomeImageSelectContent = connect(
  (state) => ({
    isLoadingImages: state.getIn(['homepage', 'isLoadingImages']),
    selectedImageId: state.getIn(['homepage', 'selectedImage', 'id']),
    selectedImageName: state.getIn(['homepage', 'selectedImage', 'name']),
    images: state.getIn(['homepage', 'images'])
  }),
  (dispatch, { item }) => ({
    setSelectedImage(imageId) {
      dispatch(homepageSetSelectedImage(imageId));
    },
    saveFolderImage(selectedImageId, onSuccessCallback) {
      if (selectedImageId > 0)
        dispatch(homepageSetFolderImage(item.scope, item.path, selectedImageId, onSuccessCallback));
      else
        dispatch(homepageDeleteFolderImage(item.scope, item.path, onSuccessCallback));
    },
    saveItemImage(selectedImageId, onSuccessCallback) {
      if (selectedImageId > 0)
        dispatch(homepageSetItemImage(item.id, selectedImageId, onSuccessCallback));
      else
        dispatch(homepageDeleteItemImage(item.id, onSuccessCallback));
    },
    loadImages(onSuccess) {
      dispatch(homepageImagesRefresh(onSuccess));
    },
    refreshHome() {
      dispatch(homepageRefresh());
    }
  })
)(({ isVisible, isLoadingImages, images, item, selectedImageId, selectedImageName,
  setSelectedImage, saveFolderImage, saveItemImage, loadImages, refreshHome, onSubmit: onDismissModal }) => {
  const _images = images ? images.toJS() : [];

  useEffect(() => {
    if (isVisible) loadImages(() => setSelectedImage(item.imageId));
  }, [isVisible, loadImages, setSelectedImage, item.imageId]);
  
  const selectedImageUrl = selectedImageId ? `${WORKSPACES_IMAGE_URL}/${selectedImageId}` : null;
  function onSubmit(e) {
    e.preventDefault();

    const onSuccess = () => {
      onDismissModal(e);
      refreshHome();
    };

    if (item.type === 'Folder')
      saveFolderImage(selectedImageId, onSuccess);
    else
      saveItemImage(selectedImageId, onSuccess);
  }

  return (
    <form className='modal-content' onSubmit={ onSubmit }>
      <div className='modal-header'>
        <h5 className='modal-title'>Choose an image...</h5>
        <button type='button' className='close' data-dismiss='modal'>&times;</button>
      </div>
      <div className='modal-body'>
        <div className='card mb-3 d-flex flex-row flex-nowrap'>
          <img className='card-img bg-light border border-light' src={selectedImageUrl || emptyImgUrl} alt={selectedImageName} style={{ height: 150, width: 200 }} />
          <div className='card-body p-2 flex-1 d-flex flex-column justify-content-between'>
            <Loading isLoading={isLoadingImages} message='Loading...'>
              <Fragment>
                <div className='form-group'>
                  <label htmlFor='homepage-modal-content-image' className='font-weight-bold'>Image</label>
                  <select id='homepage-modal-content-image' className='custom-select' onChange={e => setSelectedImage(e.target.value)} value={selectedImageId}>
                    <option />
                    { _images.map(({ id, name }) => <option key={id} value={id}>{name}</option>) }
                  </select>
                </div>
                <div className='form-group m-0'>
                  <button type='button' className='btn btn-outline-primary text-nowrap' onClick={ loadImages }>
                    <i className='fas fa-sync-alt fa-fw' /> Refresh
                  </button>
                </div>
              </Fragment>
            </Loading>
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
        <button type='submit' className='btn btn-primary'>Save</button>
      </div>
    </form>
  );
});

export default HomeImageSelectContent;