import { fromJS } from 'immutable';
import {
  ANALYSIS_SEARCH_LOAD_COMPOSITION_COMPLETE
} from '../actions/analysis-composition-v1';

function buildCompositionDisplayMap(rows, displayMap = {}, path = [], selectedLevel = 0, depth = 0) {
  let maxLevel = depth;

  if (rows) for (let i = 0, row; i < rows.length && (row = rows[i]); i++) {
    const rowPath = [...path, `${row.id}`];
    const displayKey = rowPath.join('-');

    displayMap[displayKey] = depth > selectedLevel ? 'Closed' : 'Open';

    if (row.items) {
      let result = buildCompositionDisplayMap(row.items, displayMap, rowPath, selectedLevel, depth + 1);

      if (result.maxLevel > maxLevel)
        maxLevel = result.maxLevel;

      displayMap = result.displayMap;
    }
  }

  return { displayMap, maxLevel };
}

export const compositionReducerV1 = {
  [ANALYSIS_SEARCH_LOAD_COMPOSITION_COMPLETE](state, action) {
    if (!action.data) return state;

    const data = state.getIn(['search', 'results', 'data']);
    const index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    const selectedLevel = state.getIn(['search', 'compositionSelectedLevel']);
    const { displayMap, maxLevel } = action.data
      ? buildCompositionDisplayMap([action.data], {}, [], selectedLevel)
      : { displayMap: [], maxLevel: 0 };

    return state.setIn(['search', 'results', 'data', index, 'composition'], fromJS(action.data))
                .setIn(['search', 'results', 'data', index, 'displayMap'], fromJS(displayMap))
                .setIn(['search', 'results', 'data', index, 'maxLevel'], maxLevel)
                .setIn(['search', 'results', 'data', index, 'selectedLevel'], 0)
                .updateIn(['search', 'compositionMaxLevel'], value => value > maxLevel ? value : maxLevel );
  }
};