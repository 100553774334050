import React, { Component } from 'react';
import PropTypes from 'prop-types'

export class SchemaFacetItem extends Component {
  static propTypes = {
    isMultiSelect: PropTypes.bool.isRequired,
    facetKey: PropTypes.string.isRequired,
    schemaColour: PropTypes.string.isRequired,
    result: PropTypes.shape({
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isSelected: PropTypes.bool
    }).isRequired
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { result: current } = this.props;
    const { result: next } = nextProps ?? {};

    const shouldUpdate =
      this.props.isMultiSelect !== nextProps.isMultiSelect ||
      current.value !== next.value ||
      current.count !== next.count ||
      current.isSelected !== next.isSelected;

    return shouldUpdate;
  }

  render() {
    const { isMultiSelect, schemaColour, facetKey, result } = this.props;
    const { value, count, isSelected } = result;

    return <li data_id={this.props.data_id} key={value} data-facetkey={facetKey} data-value={value}
      className={'list-group-item list-group-item-action d-flex justify-content-between align-items-center ' + (!isMultiSelect && isSelected ? ' active' : '')}>
      <span data_id='name' style={{ pointerEvents: 'none' }}>{value}</span>
      <div style={{ whiteSpace: 'nowrap', pointerEvents: 'none' }}>
        <span className='badge text-nowrap'>{count.toLocaleString(undefined, true)}</span>
        {isMultiSelect && <i style={{ color: schemaColour, opacity: isSelected ? 1 : 0.5 }} className={isSelected ? 'fas fa-check-square fa-lg fa-fw' : 'far fa-square fa-lg fa-fw'} >
          <span data_id='is-selected' style={{display:'none'}}>{isSelected ? 'true' : 'false'}</span>
          </i>}
      </div>
    </li>
  }
}