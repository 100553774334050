import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { PopoverButton } from './PopoverButton';

export function TableHeaderCell({ columnData, orderBy, orderByDirection, applyOrderBy, filters, applyFilter }) {
  return <TableCell key={columnData.key}>
    <div className='d-flex justify-content-between align-items-end' >
      <span style={{ fontWeight: 'bold', whiteSpace:'nowrap' }}>{columnData.title}</span>
      {columnData.filterable === true && <TableHeaderFilter columnKey={columnData.key} filters={filters} applyFilter={applyFilter} />}
      {columnData.sortable === true && <TableHeaderSorter columnKey={columnData.key} orderBy={orderBy} orderByDirection={orderByDirection} applyOrderBy={applyOrderBy} />}
    </div>
  </TableCell>
}

export function TableHeaderSorter({ columnKey, orderBy, orderByDirection, applyOrderBy }) {
  return <span onClick={() => applyOrderBy(columnKey)}>
    {orderBy && orderBy === columnKey
      ? orderByDirection === 'desc'
        ? <i className='fas fa-sort-down fa-fw' />
        : <i className='fas fa-sort-up fa-fw' />
      : <i className='fas fa-sort fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} />}
  </span>
}

export function TableHeaderFilter({ filters, columnKey, applyFilter }) {
  const filter = filters ? filters.find(f => f.get('name') === columnKey) : undefined;
  const filterValue = filter ? filter.get('value') : null;
  const isActive = !!filter;

  return <PopoverButton buttonClassName='btn btn-sm p-0 m-0' buttonContent={<>{isActive ? <i className='fas fa-filter fa-fw' /> : <i className='fas fa-filter fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} />}</>}>
    {({ closePopover }) => <TableFilterDialog closePopover={closePopover} columnKey={columnKey} initialFilterText={filterValue} applyFilter={applyFilter} />}
  </PopoverButton>
}

function TableFilterDialog({ closePopover, columnKey, initialFilterText, applyFilter }) {
  const [filterText, setFilterText] = useState(initialFilterText);

  function onClearFilter() {
    applyFilter(columnKey, undefined);
  }

  function onApplyFilter(e, columnKey, filterText) {
    e.preventDefault();
    e.stopPropagation();
    applyFilter(columnKey, filterText);
    closePopover();
  }

  return <form onSubmit={e => onApplyFilter(e, columnKey, filterText)}>
    <div className='modal-content'>
      <div className='modal-header'>
        <h5 className='modal-title'>Filter</h5>
        <button type='button' className='close' data-close-popover>&times;</button>
      </div>
      <div className='modal-body'>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <label className='input-group-text'>{columnKey}</label>
          </div>
          <div className='input-group-prepend'>
            <label className='input-group-text'>eq</label>
          </div>
          <input type='text' autoFocus className='form-control' value={filterText} onChange={(e) => setFilterText(e.target.value)} />
        </div>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-outline-secondary mr-auto' data-dismiss='modal' onClick={onClearFilter}>Clear</button>
        <button type='button' className='btn btn-secondary' data-close-popover>Close</button>
        <button type='submit' className='btn btn-primary' >Apply filter</button>
      </div>
    </div>
  </form>
}