import React from 'react';
import RequiredField from '../../../shared/RequiredField';
import InfoPopup from '../../../shared/InfoPopup';
import CollapsePanel from '../../../shared/CollapsePanel';
import ComboBox from '../../../shared/ComboBox';

const labelStyle = {
  fontSize: '11.6px',
  fontWeight: 700,
  margin: 0,
  cursor: 'default'
};

const inputStyle = {
  fontSize: '12px'
};

export default function BasicSettings({ referenceData, lookupData, timeSeries, updateTimeSeriesValue, updateTimeSeriesDataType }) {
  const { id: timeSeriesId, dataType, name, style, source, sourceId, instanceStyle, unit, granularity, sourceTimeZoneId } = timeSeries;
  const { granularityType, granularityFrequency } = granularity || {};
  const { categoriesByDataType } = referenceData;

  const {
    source: sourceLookupData,
    unit: unitLookupData
  } = lookupData;

  const {
    timeSeriesDataTypes: dataTypeOptions,
    timeSeriesStyles: styleOptions,
    timeSeriesInstanceStyles: instanceStyleOptions,
    granularities: granularityOptions,
    timezones
  } = referenceData;

  const isNewOrClone = timeSeriesId === 0;
  const isExisting = timeSeriesId > 0;
  const isForecast = (style || '').toLowerCase() === 'forecast';
  const hasGranularityFrequency = granularityOptions.some(i => i.name === granularityType && i.hasFrequency);

  function onUpdateTimeSeriesDataType(value) {
    updateTimeSeriesDataType({ categoriesByDataType, value });
  }

  return (
    <CollapsePanel idKey='timeSeriesEditorBasicSettings' title='Basics' headerStyle={{ cursor: 'pointer' }}>
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Style <RequiredField /></label>
          <select className='form-control' style={inputStyle}
            value={style || ''}
            onChange={e => updateTimeSeriesValue('style', e.target.value)}>
            {styleOptions.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Name <RequiredField /></label>
          <input type='text' className='form-control' style={inputStyle}
            value={name || ''}
            title={name || ''}
            onChange={e => updateTimeSeriesValue('name', e.target.value)} />
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col-5 mb-1 pr-1'>
          <label style={labelStyle}>Source <RequiredField /></label>
          <ComboBox value={source || ''}
            title={source || ''}
            style={inputStyle}
            containerClassName='fota-rw-widget-picker'
            onChange={value => updateTimeSeriesValue('source', value)}
            onGetData={callback => callback.success(sourceLookupData)} />
        </div>
        <div className='col-7 mb-1 pl-1'>
          <label style={labelStyle}>Source Id {isExisting && <RequiredField />}</label>
          {isNewOrClone && (
            <InfoPopup>
              A unique identifier provided by the data source. Used to tie back to the FOTA id<br />For internal time series this can be left blank and the id will be used.
            </InfoPopup>
          )}
          <input type='text' className='form-control' style={inputStyle}
            value={sourceId || ''}
            title={sourceId || ''}
            placeholder={`${isNewOrClone ? 'Not required' : ''}`}
            onChange={e => updateTimeSeriesValue('sourceId', e.target.value)} />
        </div>
      </div>
      { isForecast && (
        <div className='row no-gutters'>
          <div className='col mb-1'>
            <label style={labelStyle}>Instance Style <RequiredField /></label>
            <InfoPopup>
              Impacts treatment of how instances are treated.
              <ul>
                <li>Default - the most recent version of each data point across all instances is displayed by default.</li>
                <li>Aggregate - each data point is summed across all relevant instances.</li>
                <li>LatestInstance - data points are only displayed from the relevant instance.</li>
              </ul>
            </InfoPopup>
            <select className='form-control' style={inputStyle}
              value={instanceStyle || ''}
              onChange={e => updateTimeSeriesValue('instanceStyle', e.target.value)}>
              {instanceStyleOptions.map(i => <option key={i} value={i}>{i}</option>)}
            </select>
          </div>
        </div>
      )}
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Unit <RequiredField /></label>
          <ComboBox value={unit || ''}
            title={unit || ''}
            style={inputStyle}
            containerClassName='fota-rw-widget-picker'
            onChange={value => updateTimeSeriesValue('unit', value)}
            onGetData={callback => callback.success(unitLookupData)} />
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col-3 mb-1 pr-1'>
          <label style={labelStyle}>Granularity <RequiredField /></label>
          <select className='form-control' style={inputStyle}
            value={granularityType || ''}
            onChange={e => updateTimeSeriesValue(['granularity', 'granularityType'], e.target.value)}>
            {granularityOptions.map(i => <option key={i.name} value={i.name}>{i.name}</option>)}
          </select>
        </div>
        <div className='col-3 mb-1 pr-1 pl-1'>
          <label style={labelStyle}>Frequency {hasGranularityFrequency && <RequiredField />}</label>
          <input className='form-control' style={inputStyle} type='text'
            value={granularityFrequency || ''}
            disabled={!hasGranularityFrequency}
            onChange={e => updateTimeSeriesValue(['granularity', 'granularityFrequency'], e.target.value)} />
        </div>
        <div className='col-6 mb-1 pl-1'>
          <label style={labelStyle}>Timezone <RequiredField /></label>
          <select className='form-control' style={inputStyle}
            value={sourceTimeZoneId || ''}
            onChange={e => updateTimeSeriesValue(['sourceTimeZoneId'], e.target.value)} >
            {timezones.map(t => <option key={t} value={t}>{t}</option>)}
          </select>
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Data Type</label>
          <select className='form-control' style={inputStyle}
            value={dataType || ''}
            onChange={e => onUpdateTimeSeriesDataType(e.target.value)}>
            <option key='none' value=''></option>
            {dataTypeOptions.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
      </div>
    </CollapsePanel>
  );
}