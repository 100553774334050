import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logErrorNotification
} from './log';

export const HISTORY_LOAD_COMPLETE = 'HISTORY_LOAD_COMPLETE';
export const historyLoadComplete = createAction(HISTORY_LOAD_COMPLETE, 'data');

export const HISTORY_LOADING = 'HISTORY_LOADING';
export const historyLoading = createAction(HISTORY_LOADING, 'isLoading');

export const HISTORY_TOGGLE_SELECTION = 'HISTORY_TOGGLE_SELECTION';
export const historyToggleSelection = createAction(HISTORY_TOGGLE_SELECTION, 'row');

export const loadHistory = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']);

  dispatch(historyLoading(true));
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/timeseries/${identityId}/history`)
    .then(response => response.json())
    .then(data => {
      dispatch(historyLoadComplete(data));
      dispatch(historyLoading(false));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(historyLoading(false));
    });
};

export const revertFromHistory = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']);
  const validFrom = state.getIn(['historyViewer','selectedAudit','validFrom']);

  dispatch(historyLoading(true));
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/timeseries/${identityId}/revert?validFrom=${validFrom}`)
    .then(response => response.json())
    .then(data => {
      dispatch(historyLoading(false));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(historyLoading(false));
    });
};
