import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';

import {
  ANALYSIS_SHAPES_INIT,
  ANALYSIS_SHAPES_REMOVE_SELECTED,
  ANALYSIS_SHAPES_REMOVE_ALL_SELECTION,

  ANALYSIS_SHAPES_TOGGLE_SHAPE_CELL_SELECTION,
  ANALYSIS_SHAPES_TOGGLE_SHAPE_ALL_SELECTION,
  ANALYSIS_SHAPES_TOGGLE_SHAPE_BUCKET_SELECTION,
  ANALYSIS_SHAPES_TOGGLE_SHAPE_GROUP_SELECTION,

  ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_STARTED,
  ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_COMPLETE,

  ANALYSIS_SHAPES_SAVE_SHAPE_STARTED,
  ANALYSIS_SHAPES_SAVE_SHAPE_COMPLETE,

  ANALYSIS_SHAPES_DELETE_SHAPE_STARTED,
  ANALYSIS_SHAPES_DELETE_SHAPE_COMPLETE,

  ANALYSIS_SHAPES_SET_SHAPE_NAME,
  ANALYSIS_SHAPES_SELECT_SHAPE_STARTED,
  ANALYSIS_SHAPES_SELECT_SHAPE_COMPLETED,

  ANALYSIS_SHAPES_ADD_SHAPE_TO_SELECTION,
  ANALYSIS_SHAPES_REMOVE_SELECTED_SHAPE,
  ANALYSIS_SHAPES_REMOVE_ALL_SELECTED_SHAPES,
  ANALYSIS_SHAPES_APPLY_SELECTION_TO_WORKSPACE
} from '../actions/analysis-shapes-v2';

export const shapesReducerV2 = {
  [ANALYSIS_SHAPES_INIT](state, action) {
    const shapesSettings = toJS(state.getIn(['ui', 'shapesSettings']));
    if (!shapesSettings.shapeTemplateMap)
      shapesSettings.shapeTemplateMap = buildShapeTemplate(shapesSettings.shapeTemplate);

    const workspaceSettings = toJS(state.getIn(['workspace', 'shapesSettings']));
    if (workspaceSettings)
        shapesSettings.selectedShapes = workspaceSettings.selection;

    return state.setIn(['ui', 'shapesSettings'], fromJS(shapesSettings));
  },
  [ANALYSIS_SHAPES_REMOVE_SELECTED](state, action) {
    let selection = toJS(state.getIn(['workspace', 'shapesSettings', 'selection']), []);
    const shape = action.shape;

    selection = selection.filter(s => s.name !== shape.name);

    return state.setIn(['workspace', 'shapesSettings', 'selection'], fromJS(selection));
  },
  [ANALYSIS_SHAPES_REMOVE_ALL_SELECTION](state, action) {
    return state.setIn(['workspace', 'shapesSettings', 'selection'], fromJS([]));
  },
  [ANALYSIS_SHAPES_TOGGLE_SHAPE_CELL_SELECTION](state, action) {
    const shapeTemplateMap = toJS(state.getIn(['ui', 'shapesSettings', 'shapeTemplateMap']));
    const bucket = action.bucket;
    const group = action.group;

    const targetGroup = shapeTemplateMap.groups.find(g => g.name === group.name);
    const targetBucket = targetGroup.buckets.find(b => b.name === bucket.name);
    targetBucket.isSelected = !targetBucket.isSelected;
    return state.setIn(['ui', 'shapesSettings', 'shapeTemplateMap'], fromJS(shapeTemplateMap))
      .setIn(['ui', 'shapesSettings', 'shapeTemplateMapIsDirty'], true);
  },
  [ANALYSIS_SHAPES_TOGGLE_SHAPE_ALL_SELECTION](state, action) {
    const shapeTemplateMap = toJS(state.getIn(['ui', 'shapesSettings', 'shapeTemplateMap']));
    let buckets = [];
    shapeTemplateMap.groups.forEach(group => {
      buckets = [...buckets, ...group.buckets];
    });

    if (buckets.some(b => !b.isSelected)) {
      buckets.forEach(b => {
        b.isSelected = true;
      });
    } else {
      buckets.forEach(b => {
        b.isSelected = false;
      });
    }
    return state.setIn(['ui', 'shapesSettings', 'shapeTemplateMap'], fromJS(shapeTemplateMap))
      .setIn(['ui', 'shapesSettings', 'shapeTemplateMapIsDirty'], true);
  },
  [ANALYSIS_SHAPES_TOGGLE_SHAPE_BUCKET_SELECTION](state, action) {
    const shapeTemplateMap = toJS(state.getIn(['ui', 'shapesSettings', 'shapeTemplateMap']));
    const bucketName = action.bucketName;
    const buckets = [];
    shapeTemplateMap.groups.forEach(group => {
      buckets.push(group.buckets.find(b => b.name === bucketName));
    });

    if (buckets.some(b => !b.isSelected)) {
      buckets.forEach(b => {
        b.isSelected = true;
      });
    } else {
      buckets.forEach(b => {
        b.isSelected = false;
      });
    }

    return state.setIn(['ui', 'shapesSettings', 'shapeTemplateMap'], fromJS(shapeTemplateMap))
      .setIn(['ui', 'shapesSettings', 'shapeTemplateMapIsDirty'], true);
  },
  [ANALYSIS_SHAPES_TOGGLE_SHAPE_GROUP_SELECTION](state, action) {
    const shapeTemplateMap = toJS(state.getIn(['ui', 'shapesSettings', 'shapeTemplateMap']));
    const group = action.group;
    const targetGroup = shapeTemplateMap.groups.find(g => g.name === group.name);
    if (targetGroup.buckets.some(b => !b.isSelected)) {
      targetGroup.buckets.forEach(b => {
        b.isSelected = true;
      });
    } else {
      targetGroup.buckets.forEach(b => {
        b.isSelected = false;
      });
    }

    return state.setIn(['ui', 'shapesSettings', 'shapeTemplateMap'], fromJS(shapeTemplateMap))
      .setIn(['ui', 'shapesSettings', 'shapeTemplateMapIsDirty'], true);
  },
  [ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_STARTED](state, action) {
    return state.setIn(['ui', 'shapesSettings', 'isLoadingShapes'], true);
  },
  [ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_COMPLETE](state, action) {
    if (!action.data) return state;

    const data = action.data.filter(i => i && !(i.name === undefined || i.name === null || i.name === ''));
    return state.setIn(['ui', 'shapesSettings', 'availableShapes'], fromJS(data))
      .setIn(['ui', 'shapesSettings', 'isLoadingShapes'], false);
  },
  [ANALYSIS_SHAPES_SET_SHAPE_NAME](state, action) {
    const name = action.name;
    const availableShapes = toJS(state.getIn(['ui', 'shapesSettings', 'availableShapes']), []);
    const isExistingShape = availableShapes.some(s => s.name === name);
    return state.setIn(['ui', 'shapesSettings', 'shapeName'], name)
      .setIn(['ui', 'shapesSettings', 'isExistingShape'], isExistingShape);
  },
  [ANALYSIS_SHAPES_SELECT_SHAPE_STARTED](state, action) {
    return state.setIn(['ui', 'shapesSettings', 'isSettingShape'], true);
  },
  [ANALYSIS_SHAPES_SELECT_SHAPE_COMPLETED](state, action) {
    const selectedShape = action.data;
    state = state.setIn(['ui', 'shapesSettings', 'onItems'], fromJS(selectedShape?.data?.onItems ?? []));
    const shapeTemplateMap = state.getIn(['ui', 'shapesSettings', 'shapeTemplateMap'])?.toJS();
    if (!shapeTemplateMap)
      return state;
    
    // deselect all
    shapeTemplateMap.groups.forEach(group => {
      group.buckets.forEach(b => { b.isSelected = false; });
    });

    if (selectedShape && selectedShape.data && selectedShape.data.onItems) {
      selectedShape.data.onItems.forEach(onItem => {
        const group = shapeTemplateMap.groups.find(g => `${g.index}` === `${onItem.group}`);
        const bucket = group.buckets.find(b => b.name === onItem.bucket);
        bucket.isSelected = true;
      });
    }

    return state.setIn(['ui', 'shapesSettings', 'shapeTemplateMap'], fromJS(shapeTemplateMap))
      .setIn(['ui', 'shapesSettings', 'shapeName'], selectedShape?.name ?? '')      
      .setIn(['ui', 'shapesSettings', 'isSettingShape'], false)
      .setIn(['ui', 'shapesSettings', 'isExistingShape'], true)
      .setIn(['ui', 'shapesSettings', 'shapeTemplateMapIsDirty'], false);
  },
  [ANALYSIS_SHAPES_SAVE_SHAPE_STARTED](state, action) {
    return state.setIn(['ui', 'shapesSettings', 'isSavingShape'], true);
  },
  [ANALYSIS_SHAPES_SAVE_SHAPE_COMPLETE](state, action) {
    const data = action.data;

    let availableShapes = toJS(state.getIn(['ui', 'shapesSettings', 'availableShapes']));
    let shapeName = state.getIn(['ui', 'shapesSettings', 'shapeName']);
    availableShapes.push({
      id: data.shapeId,
      name: shapeName
    });

    return state.setIn(['ui', 'shapesSettings', 'availableShapes'], fromJS(availableShapes))
      .setIn(['ui', 'shapesSettings', 'isSavingShape'], false)
      .setIn(['ui', 'shapesSettings', 'isExistingShape'], true)
      .setIn(['ui', 'shapesSettings', 'shapeTemplateMapIsDirty'], false);
  },
  [ANALYSIS_SHAPES_DELETE_SHAPE_STARTED](state, action) {
    return state.setIn(['ui', 'shapesSettings', 'isDeletingShape'], true);
  },
  [ANALYSIS_SHAPES_DELETE_SHAPE_COMPLETE](state, action) {
    let availableShapes = toJS(state.getIn(['ui', 'shapesSettings', 'availableShapes']));
    let shapeName = state.getIn(['ui', 'shapesSettings', 'shapeName']);
    availableShapes = availableShapes.filter(s => s.name !== shapeName);

    return state.setIn(['ui', 'shapesSettings', 'availableShapes'], fromJS(availableShapes))
      .setIn(['ui', 'shapesSettings', 'isDeletingShape'], false)
      .setIn(['ui', 'shapesSettings', 'isExistingShape'], false)
      .setIn(['ui', 'shapesSettings', 'shapeName'], '')
      .setIn(['ui', 'shapesSettings', 'shapeTemplateMapIsDirty'], false);
  },
  [ANALYSIS_SHAPES_ADD_SHAPE_TO_SELECTION](state, action) {
    const shapesSettings = toJS(state.getIn(['ui', 'shapesSettings']));

    const shapeName = (shapesSettings.shapeName || '').trim();
    if (shapeName === '') {
      shapesSettings.addShapeErrorMessage = 'Please specify a shape name';
    } else if (shapesSettings.selectedShapes.some(s => s.name === shapeName)) {
      shapesSettings.addShapeErrorMessage = `"${shapeName}" has already been added`;
    } else {
      const newShape = {
        template: shapesSettings.shapeTemplate,
        name: shapeName,
        onItems: []
      };

      shapesSettings.shapeTemplateMap.groups.forEach(group => {
        group.buckets.forEach(bucket => {
          if (bucket.isSelected) {
            newShape.onItems.push({
              group: `${group.index}`,
              bucket: bucket.name
            })
          }
        });
      });

      shapesSettings.selectedShapes.push(newShape);
      shapesSettings.addShapeErrorMessage = undefined;
    }

    return state.setIn(['ui', 'shapesSettings'], fromJS(shapesSettings));
  },
  [ANALYSIS_SHAPES_REMOVE_SELECTED_SHAPE](state, action) {
    const shameToRemove = action.name;
    const shapesSettings = toJS(state.getIn(['ui', 'shapesSettings']));

    shapesSettings.selectedShapes = shapesSettings.selectedShapes.filter(s => s.name !== shameToRemove.name);

    return state.setIn(['ui', 'shapesSettings'], fromJS(shapesSettings));
  },
  [ANALYSIS_SHAPES_REMOVE_ALL_SELECTED_SHAPES](state, action) {
    const shapesSettings = toJS(state.getIn(['ui', 'shapesSettings']));
    shapesSettings.selectedShapes = [];
    return state.setIn(['ui', 'shapesSettings'], fromJS(shapesSettings));
  },
  [ANALYSIS_SHAPES_APPLY_SELECTION_TO_WORKSPACE](state, action) {
    const shapesSettings = toJS(state.getIn(['ui', 'shapesSettings']));
    return state.setIn(['workspace', 'shapesSettings', 'selection'], fromJS(shapesSettings.selectedShapes));
  }
};

function buildShapeTemplate(shapeTemplate) {
  if (shapeTemplate === 'hours/weekday') {
    const groups = [
      { name: 'Mon', index: '1' },
      { name: 'Tue', index: '2' },
      { name: 'Wed', index: '3' },
      { name: 'Thu', index: '4' },
      { name: 'Fri', index: '5' },
      { name: 'Sat', index: '6' },
      { name: 'Sun', index: '0' }];

    const buckets = [];
    for (let index = 1; index <= 24; index++) {
      buckets.push(index);
    }

    return {
      buckets: buckets.map(c => `H${c}`),
      groups: groups.map(g => ({
        name: g.name,
        index: g.index,
        buckets: buckets.map(b => ({
          name: `H${b}`,
          isSelected: false
        }))
      }))
    };
  }
}