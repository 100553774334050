import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import {
  dashboardWorkspacesLoad,
  dashboardWorkspacesFilter
} from '../../../actions/dashboard';

const DashboardFilter = connect(
  (state, ownProps) => {
    const { area } = ownProps;

    return {
      filterType: state.getIn(['dashboard', 'workspaces', area, 'filter', 'filterType']),
      filterText: state.getIn(['dashboard', 'workspaces', area, 'filter', 'filterText'])
    };
  },
  (dispatch) => {
    return {
      updateFilter({ area, filterType, filterText }) {
        dispatch(dashboardWorkspacesFilter({ area, filterType, filterText }));
      },
      refreshWorkspaces(area, type, scope) {
        dispatch(dashboardWorkspacesLoad({ area, type, scope }));
      }
    }
  }
)(({ area, updateFilter, filterType, filterText, refreshWorkspaces }) => {

  const onChangeFilterText = useCallback(e => {
    updateFilter({ area, filterType, filterText: e.target.value });
  }, [area, updateFilter, filterType]);

  const onChangeFilterType = useCallback(e => {
    updateFilter({ area, filterType: e.target.value, filterText });
  }, [area, updateFilter, filterText]);

  const onRefreshAllWorkspaces = useCallback(e => {
    refreshWorkspaces(area, 'analysis', 'private');
    refreshWorkspaces(area, 'analysis', 'shared');
    refreshWorkspaces(area, 'report', 'private');
    refreshWorkspaces(area, 'report', 'shared');
  }, [area, refreshWorkspaces])

  return <div className='d-flex'>
    <div className="input-group">
      <div className="input-group-prepend">
        <select className="form-control form-control" value={filterType} onChange={onChangeFilterType}>
          <option value='name'>Name</option>
          <option value='updatedBy'>Last Updated By</option>
        </select>
      </div>
      <input style={{ width: '100px' }} placeholder='Filter text' className='form-control form-control ' type='text' value={filterText} onChange={onChangeFilterText} />
    </div>

    <button className='btn btn-primary text-nowrap ml-2' type='button' onClick={onRefreshAllWorkspaces}>
      <i className='fas fa-sync-alt fa-fw' />
      <span className='ml-2'>Refresh</span>
    </button>
  </div>;
});

export default DashboardFilter;