import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import { formatDateToIso } from '../utility/date-utility';
import { isNotEmpty } from '../utility/text-utility';
import { stringify as qsStringify } from 'querystring';
import {
  logErrorNotification
} from './log';

export const ADJUSTMENT_RANGE_EDITOR_SET_TIMESERIES_ID = 'ADJUSTMENT_RANGE_EDITOR_SET_TIMESERIES_ID';
export const adjustmentRangeEditorSetTimeSeriesId = createAction(ADJUSTMENT_RANGE_EDITOR_SET_TIMESERIES_ID, 'id');

export const ADJUSTMENT_RANGE_EDITOR_SET_IS_DATE_ONLY = 'ADJUSTMENT_RANGE_EDITOR_SET_IS_DATE_ONLY';
export const adjustmentRangeEditorSetIsDateOnly = createAction(ADJUSTMENT_RANGE_EDITOR_SET_IS_DATE_ONLY, 'granularityType');

export const ADJUSTMENT_RANGE_EDITOR_UNINITIALISE = 'ADJUSTMENT_RANGE_EDITOR_UNINITIALISE';
export const adjustmentRangeEditorUninitialise = createAction(ADJUSTMENT_RANGE_EDITOR_UNINITIALISE);

export const ADJUSTMENT_RANGE_EDITOR_LOAD_STARTED = 'ADJUSTMENT_RANGE_EDITOR_LOAD_STARTED';
const adjustmentRangeEditorLoadStarted = createAction(ADJUSTMENT_RANGE_EDITOR_LOAD_STARTED);

export const adjustmentRangeEditorLoad = () => (dispatch, getState) => {
  const state = getState();
  const timeSeriesId = state.getIn(['adjustmentRangeEditor', 'timeSeriesId']);
  if (!timeSeriesId) return;

  const periodStart = state.getIn(['adjustmentRangeEditor', 'toolbar', 'periodStart']);
  const periodEnd = state.getIn(['adjustmentRangeEditor', 'toolbar', 'periodEnd']);
  const criteria = {
    periodStart: formatDateToIso(periodStart),
    periodEnd: formatDateToIso(periodEnd)
  };
  const qsParams = qsStringify(criteria);

  dispatch(adjustmentRangeEditorLoadStarted());

  return authFetch(`${ANALYSIS_API_ROOT_URL}/v4/timeseries-adjustments/${timeSeriesId}?${qsParams}`)
    .then(response => response.json())
    .then(data => {
      dispatch(adjustmentRangeEditorLoadComplete(data));
    })
    .catch(error => {
      dispatch(adjustmentRangeEditorLoadStopped());
      dispatch(logErrorNotification(error));
    });
};

export const ADJUSTMENT_RANGE_EDITOR_LOAD_STOPPED = 'ADJUSTMENT_RANGE_EDITOR_LOAD_STOPPED';
const adjustmentRangeEditorLoadStopped = createAction(ADJUSTMENT_RANGE_EDITOR_LOAD_STOPPED);

export const ADJUSTMENT_RANGE_EDITOR_LOAD_COMPLETE = 'ADJUSTMENT_RANGE_EDITOR_LOAD_COMPLETE';
const adjustmentRangeEditorLoadComplete = createAction(ADJUSTMENT_RANGE_EDITOR_LOAD_COMPLETE, 'data');

export const ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_STARTED = 'ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_STARTED';
const adjustmentRangeEditorGeneratePeriodEndStarted = createAction(ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_STARTED);

export const adjustmentRangeEditorGeneratePeriodEnd = () => (dispatch, getState) => {
  const state = getState();
  const timeSeriesId = state.getIn(['adjustmentRangeEditor', 'timeSeriesId']);
  if (!timeSeriesId) return;

  const rangeEdit = toJS(state.getIn(['adjustmentRangeEditor', 'rangeEdit']), {});
  if (!rangeEdit) return;

  const { periodEnd, isInclusive } = rangeEdit;
  if (!periodEnd || !isInclusive) return;

  dispatch(adjustmentRangeEditorGeneratePeriodEndStarted());

  const criteria = { dateTime: formatDateToIso(periodEnd) };
  const qsParams = qsStringify(criteria);

  return authFetch(`${ANALYSIS_API_ROOT_URL}/v1/timeseries/${timeSeriesId}/get-next-period?${qsParams}`)
    .then(response => response.json())
    .then(data => {
      const { start: periodEnd } = data;
      dispatch(adjustmentRangeEditorGeneratePeriodEndComplete(periodEnd));
    })
    .catch(error => {
      dispatch(adjustmentRangeEditorGeneratePeriodEndStopped());
      dispatch(logErrorNotification(error));
    });
};

export const ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_STOPPED = 'ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_STOPPED';
const adjustmentRangeEditorGeneratePeriodEndStopped = createAction(ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_STOPPED);

export const ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_COMPLETE = 'ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_COMPLETE';
const adjustmentRangeEditorGeneratePeriodEndComplete = createAction(ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_COMPLETE, 'periodEnd');

export const ADJUSTMENT_RANGE_EDITOR_GENERATE_STARTED = 'ADJUSTMENT_RANGE_EDITOR_GENERATE_STARTED';
const adjustmentRangeEditorGenerateStarted = createAction(ADJUSTMENT_RANGE_EDITOR_GENERATE_STARTED);

export const adjustmentRangeEditorGenerate = (rangeProperty) => (dispatch, getState) => {
  const state = getState();
  const timeSeriesId = state.getIn(['adjustmentRangeEditor', 'timeSeriesId']);
  if (!timeSeriesId) return;

  const rangeEdit = toJS(state.getIn(['adjustmentRangeEditor', 'rangeEdit']), {});
  if (!rangeEdit) return;

  const { periodStart, periodEnd, adjustedValue, operation, type, isInclusive } = rangeEdit;
  if (!periodStart || !periodEnd || periodStart > periodEnd) return;

  dispatch(adjustmentRangeEditorGenerateStarted());

  const criteria = {
    periodStart: formatDateToIso(periodStart),
    periodEnd: formatDateToIso(periodEnd),
    adjustedValue: adjustedValue !== '-' ? adjustedValue : undefined,
    operation: operation !== '-' && operation !== 'Unset' ? operation : 'Overwrite',
    type: type !== '-' && type !== 'None' ? type : 'Standard',
    isInclusive
  };
  const qsParams = qsStringify(criteria);

  return authFetch(`${ANALYSIS_API_ROOT_URL}/v4/timeseries-adjustments/${timeSeriesId}/generate?${qsParams}`)
    .then(response => response.json())
    .then(data => {
      dispatch(adjustmentRangeEditorGenerateComplete(data, rangeProperty));
    })
    .catch(error => {
      dispatch(adjustmentRangeEditorGenerateStopped());
      dispatch(logErrorNotification(error));
    });
};

export const ADJUSTMENT_RANGE_EDITOR_GENERATE_STOPPED = 'ADJUSTMENT_RANGE_EDITOR_GENERATE_STOPPED';
const adjustmentRangeEditorGenerateStopped = createAction(ADJUSTMENT_RANGE_EDITOR_GENERATE_STOPPED);

export const ADJUSTMENT_RANGE_EDITOR_GENERATE_COMPLETE = 'ADJUSTMENT_RANGE_EDITOR_GENERATE_COMPLETE';
const adjustmentRangeEditorGenerateComplete = createAction(ADJUSTMENT_RANGE_EDITOR_GENERATE_COMPLETE, 'data', 'property');

export const ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_STARTED = 'ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_STARTED';
const adjustmentRangeEditorSaveRangeStarted = createAction(ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_STARTED);

export const adjustmentRangeEditorSaveRange = () => (dispatch, getState) => {
  const state = getState();
  const timeSeriesId = state.getIn(['adjustmentRangeEditor', 'timeSeriesId']);
  if (!timeSeriesId) return;

  const rangeEdit = toJS(state.getIn(['adjustmentRangeEditor', 'rangeEdit']), {});
  const editedDataPoints = toJS(state.getIn(['adjustmentRangeEditor', 'editedDataPoints']), []);

  dispatch(adjustmentRangeEditorSaveRangeStarted());

  const criteria = {
    range: {
      periodStart: formatDateToIso(rangeEdit.periodStart),
      periodEnd: formatDateToIso(rangeEdit.periodEnd),
      adjustedValue: rangeEdit.adjustedValue !== '-' ? rangeEdit.adjustedValue : undefined,
      operation: rangeEdit.operation !== '-' ? rangeEdit.operation : 'Unset',
      type: rangeEdit.type !== '-' ? rangeEdit.type : 'None',
      reason: rangeEdit.reason
    },
    items: editedDataPoints.filter(i => isNotEmpty(i.adjustedValue)
      || isNotEmpty(i.operation)
      || isNotEmpty(i.type)).map(i => ({
      periodStartUtc: formatDateToIso(i.periodStartUtc),
      periodStart: formatDateToIso(i.periodStart),
      adjustedValue: i.adjustedValue,
      operation: i.operation,
      type: i.type
    }))
  };

  return authFetch(`${ANALYSIS_API_ROOT_URL}/v4/timeseries-adjustments/${timeSeriesId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(criteria)
    })
    .then(response => response.json())
    .then(data => {
      dispatch(adjustmentRangeEditorLoad());
      dispatch(adjustmentRangeEditorClearEditRange());
      dispatch(adjustmentRangeEditorSaveRangeComplete(data));
    })
    .catch(error => {
      dispatch(adjustmentRangeEditorSaveRangeComplete());
      dispatch(logErrorNotification(error));
    });
};

export const ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_COMPLETE = 'ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_COMPLETE';
const adjustmentRangeEditorSaveRangeComplete = createAction(ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_COMPLETE, 'data');

export const ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_STARTED = 'ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_STARTED';
const adjustmentRangeEditorDeleteRangeStarted = createAction(ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_STARTED);

export const adjustmentRangeEditorDeleteRange = (adjustmentId) => (dispatch, getState) => {
  const state = getState();
  const timeSeriesId = state.getIn(['adjustmentRangeEditor', 'timeSeriesId']);
  if (!timeSeriesId) return;

  dispatch(adjustmentRangeEditorDeleteRangeStarted());

  return authFetch(`${ANALYSIS_API_ROOT_URL}/v4/timeseries-adjustments/${timeSeriesId}/${adjustmentId}`, {
      method: 'DELETE'
    })
    .then(response => response.json())
    .then(data => {
      dispatch(adjustmentRangeEditorDeleteRangeComplete(data));
      dispatch(adjustmentRangeEditorLoad());
    })
    .catch(error => {
      dispatch(adjustmentRangeEditorDeleteRangeComplete());
      dispatch(logErrorNotification(error));
    });
};

export const ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_COMPLETE = 'ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_COMPLETE';
const adjustmentRangeEditorDeleteRangeComplete = createAction(ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_COMPLETE, 'data');

export const ADJUSTMENT_RANGE_EDITOR_UPDATE_TOOLBAR_PROPERTY = 'ADJUSTMENT_RANGE_EDITOR_UPDATE_TOOLBAR_PROPERTY';
export const adjustmentRangeEditorUpdateToolbarProperty = createAction(ADJUSTMENT_RANGE_EDITOR_UPDATE_TOOLBAR_PROPERTY, 'key', 'value');

export const ADJUSTMENT_RANGE_EDITOR_CLEAR_EDIT_RANGE = 'ADJUSTMENT_RANGE_EDITOR_CLEAR_EDIT_RANGE';
export const adjustmentRangeEditorClearEditRange = createAction(ADJUSTMENT_RANGE_EDITOR_CLEAR_EDIT_RANGE);

export const ADJUSTMENT_RANGE_EDITOR_SELECT_RANGE = 'ADJUSTMENT_RANGE_EDITOR_SELECT_RANGE';
export const adjustmentRangeEditorSelectRange = createAction(ADJUSTMENT_RANGE_EDITOR_SELECT_RANGE, 'id');

export const ADJUSTMENT_RANGE_EDITOR_ADD_RANGE = 'ADJUSTMENT_RANGE_EDITOR_ADD_RANGE';
export const adjustmentRangeEditorAddRange = createAction(ADJUSTMENT_RANGE_EDITOR_ADD_RANGE, 'range');

export const ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_ITEMS = 'ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_ITEMS';
export const adjustmentRangeEditorEditRangeItems = createAction(ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_ITEMS, 'items');

export const ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_PROPERTY = 'ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_PROPERTY';
export const adjustmentRangeEditorEditRangeProperty = createAction(ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_PROPERTY, 'key', 'value');

export const adjustmentRangeEditorInitialise = (timeSeriesId, timeSeriesGranularityType) => (dispatch, getState) => {
  let refreshRequired = false;

  const state = getState();
  const isInitialised = state.getIn(['adjustmentRangeEditor', 'isInitialised']);
  const currentTimeSeriesId = state.getIn(['adjustmentRangeEditor', 'timeSeriesId']);

  if (!isInitialised) {
    dispatch(adjustmentRangeEditorSetTimeSeriesId(timeSeriesId));
    refreshRequired = true;
  }
  else if (!!timeSeriesId && `${currentTimeSeriesId}` !== `${timeSeriesId}`) {
    dispatch(adjustmentRangeEditorSetTimeSeriesId(timeSeriesId));
    dispatch(adjustmentRangeEditorUpdateToolbarProperty('periodStart', null));
    dispatch(adjustmentRangeEditorUpdateToolbarProperty('periodEnd', null));
    refreshRequired = true;
  }

  dispatch(adjustmentRangeEditorSetIsDateOnly(timeSeriesGranularityType));

  if (refreshRequired)
    dispatch(adjustmentRangeEditorLoad());
};