import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import ShapesPicker from './ShapesPicker'
import {
  analysisShapesRemoveSelected,
  analysisShapesRemoveAllSelection,
  analysisShapesInit,
  analysisShapesLoadAvailableShapes,
  analysisShapesUpdateBasketFromWorkspace
} from '../../../actions/analysis-shapes-v2';

import {
  analysisRefreshTemplateNameExpressions
} from '../../../actions/analysis';

export const ShapesPickerConnectComponent = connect(
  (state) => ({
    workspaceSelection: state.getIn(['analysis', 'workspace', 'shapesSettings', 'selection'])
  }),
  (dispatch) => ({
    shapesInit() {
      dispatch(analysisShapesInit());
    },
    loadAvailableShapes(){
      dispatch(analysisShapesLoadAvailableShapes());
    },
    removeSelectedFromWorkspace(shape) {
      dispatch(analysisShapesRemoveSelected(shape));
      dispatch(analysisShapesUpdateBasketFromWorkspace());
      dispatch(analysisRefreshTemplateNameExpressions());
    },
    removeAllShapesFromWorkspace() {
      dispatch(analysisShapesRemoveAllSelection());
      dispatch(analysisShapesUpdateBasketFromWorkspace());
      dispatch(analysisRefreshTemplateNameExpressions());
    }
  })
)(({ workspaceSelection, shapesInit, loadAvailableShapes, removeSelectedFromWorkspace, removeAllShapesFromWorkspace }) => {
  workspaceSelection = toJS(workspaceSelection || []);
  return <ShapesPicker shapesInit={shapesInit} loadAvailableShapes={loadAvailableShapes} workspaceSelection={workspaceSelection} removeSelectedFromWorkspace={removeSelectedFromWorkspace} removeAllShapesFromWorkspace={removeAllShapesFromWorkspace} />
});