import React, { PureComponent, createRef, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Html5Table } from 'window-table';
import useForceReMountEffect from '../../../hooks/useForceReMountEffect';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { organiseStyles } from '../../../utility/style-utility';
import { mapCSVToArray } from '../../../utility/text-utility';
import FormattedDateTime, {formatDateTime} from '../../shared/FormattedDateTime';
import { formatNumeral, formatNumber } from '../../shared/FormattedNumber';
import {formatValue} from '../../shared/FormattedValue';
import { TableDesignPanel } from './TableDesignPanel';
import { TableJsonEditorPanel } from './TableJsonEditorPanel';
import { TableToolbar } from './TableToolbar';
import { AdjustmentsPanel } from './AdjustmentsPanel';
import InfoPopup from '../../shared/InfoPopup';
import { Allotment } from 'allotment';

function SortIcon({ columnKey, orderBy, orderByDirection }) {
  return orderBy && orderBy === columnKey
    ? orderByDirection === 'desc'
    ? <i className='fas fa-sort-down fa-fw' />
    : <i className='fas fa-sort-up fa-fw' />
    : <i className='fas fa-sort fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} />;
}

function Table({ headerRef, contentRef, tableHeight, className, style, children, tableStyles, isEditing, 
    doubleClick, mouseDown, mouseMove, selectLine, navigateCell, revertSelectionAdjustments,
    copySelection, pasteToSelection, showSaveAdjustments }){
  const tableRef = useRef();

  useEffect(() => {
    const header = headerRef.current;
    const content = contentRef.current;
    const onScroll = () => header.querySelector('div').scrollLeft = content.scrollLeft;

    const onKeyDown =(e) => {
      switch (e.key) {
        case 's':
          if (e.ctrlKey) {
            e.preventDefault();
            showSaveAdjustments();
            return;
          }
          break;
        default:
          break;
      }

      if (!window.isInTable || !selectLine || !navigateCell || !revertSelectionAdjustments || !copySelection || !pasteToSelection)
        return;
      
      const isCellInput = e.target.nodeName === 'INPUT'
      switch (e.key) {
        case 'ArrowUp':
          if (isCellInput) return;

          e.preventDefault();
          if (e.shiftKey && e.ctrlKey){
            selectLine({ isRowSelection:false, direction: 'fromStart'});
          } else {
            navigateCell({direction:'up', continueSelection:e.shiftKey});
          }
          break;
        case 'ArrowDown':
          if (isCellInput) return;

          e.preventDefault();
          if (e.shiftKey && e.ctrlKey){
            selectLine({ isRowSelection:false, direction: 'toEnd'});
          } else {
            navigateCell({direction:'down', continueSelection:e.shiftKey});
          }
          break;
        case 'ArrowLeft':
          if (isCellInput) return;

          e.preventDefault();
          if (e.shiftKey && e.ctrlKey){
            selectLine({isRowSelection:true, direction: 'fromStart'});
          } else {
            navigateCell({direction:'left', continueSelection:e.shiftKey});
          }
          break;
        case 'ArrowRight':
          if (isCellInput) return;

          e.preventDefault();
          if (e.shiftKey && e.ctrlKey){
            selectLine({isRowSelection:true, direction: 'toEnd'});
          } else {
            navigateCell({direction:'right', continueSelection:e.shiftKey});
          }
          break;
        case 'Tab':
          if (isCellInput) return;

          e.preventDefault();
          navigateCell({direction: e.shiftKey ? 'left' : 'right'});
          break;
        case 'c':
          if (e.ctrlKey){
            if (isCellInput) return;
              
            e.preventDefault();
            copySelection();
          }
          break;
        case 'v':
          if (e.ctrlKey){
            if (isCellInput) return;
              
            e.preventDefault();
            pasteToSelection();
          }
          break;
        case 'z':
          if (e.ctrlKey){
            e.preventDefault();
            revertSelectionAdjustments();
          }
          break;
        default:
          break;
      }
    };

    if (content) {
      content.style.minHeight = `${tableHeight}px`; // Override the height set by Html5Table (it can set a height that's too small)
      content.style.width = '100%';
      
      if (content.clientWidth < content.scrollWidth) {
        content.addEventListener('scroll', onScroll);
      }
      else {
        content.removeEventListener('scroll', onScroll);
      }
      
      if (header) {
        header.style.width = '100%';
        
        if (content.clientHeight < content.scrollHeight)
        header.style.marginRight = '18px';
        else
          header.style.marginRight = 'auto';
      }
    }
    
    function onMouseUp(e){
      window.isInTable = isElementInTable(e.target);
    }

    function onFocus(e){
      window.isInTable = isElementInTable(e.target);
    }

    function isElementInTable(e){
      let maxNavigateCount = 10;
      while(e && maxNavigateCount > 0) {
        if (e === tableRef.current) return true;

        maxNavigateCount--;
        e = e.parentNode;
      }
    }

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('focusin', onFocus);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('focusin', onFocus);
      if (content) {
        content.removeEventListener('scroll', onScroll);
      }
    };
  }, [headerRef, contentRef, tableHeight, selectLine, navigateCell, revertSelectionAdjustments, copySelection, pasteToSelection, showSaveAdjustments]);

  function onDoubleClick(e){
    doubleClick(e);
  }

  function onMouseDown(e){
    if (e.buttons !== 1 || e.button !== 0) return;// Primary mouse button
    if (e.target.nodeName !== 'INPUT') if (tableRef && tableRef.current) tableRef.current.focus();
    mouseDown(e);
  }

  function onMouseMove(e){
    if (e.buttons !== 1 || e.button !== 0) return; // Primary mouse button
    mouseMove(e);
  }

  const { styles }  = organiseStyles(style, { position: 'relative'}, tableStyles);
  return (
    <table ref={tableRef} tabIndex='0' className={ `${className} ${(isEditing ? 'analysis-adjustments-enabled': 'spreadsheet-selection-enabled')}` } style={ styles } 
      onDoubleClick={onDoubleClick} 
      onMouseDown={onMouseDown} 
      onMouseMove={onMouseMove}
      >        
      { children }
    </table>
  );
}

class HeaderRow extends PureComponent {
  static propTypes = {
    className : PropTypes.string.isRequired,
    style : PropTypes.object.isRequired,
    children : PropTypes.any.isRequired,
    headerStyles : PropTypes.object
  }

  render() {
    const { className, style, children, headerStyles } = this.props;
    const { rowStyles } = headerStyles ?? {};
    const { default: defaultStyle } = rowStyles ?? {};
    const { styles } = organiseStyles(defaultStyle, style);
    
    return (
      <tr className={ className } style={ styles }>
        { children }
      </tr>
    );
  }
}

class HeaderCell extends PureComponent {
  static propTypes = {
    className : PropTypes.string.isRequired,
    style : PropTypes.object.isRequired,
    children : PropTypes.any.isRequired,
    column : PropTypes.object.isRequired,
    isEditing : PropTypes.bool,
    orderBy : PropTypes.string,
    orderByDirection : PropTypes.string,
    onSort : PropTypes.func,
    selectLine : PropTypes.func,
    onResize : PropTypes.func
  }

  constructor(props){
    super(props);
    this.ref = React.createRef();
  }

  onHeaderClick(e){
    const { column,  orderBy, orderByDirection, onSort, selectLine, isEditing } = this.props;
    if (isEditing){
      selectLine({isRowSelection:false, fullLine:true, keyOverride: column.key, ctrlKey: e.ctrlKey === true, shiftKey: e.shiftKey === true});
    }else{
      if (column.isSortable && onSort){
        onSort(column.key, orderBy, orderByDirection);
      }
    }
  }

  render(){
    const { className, style, children, column,  orderBy, orderByDirection, onResize, isEditing } = this.props;
    const { information,warning,error } = column;
    const { styles = {} } = organiseStyles(style, column.styles);  
    if (isEditing) delete styles.cursor;  
    
    return (
      <th ref={ this.ref } className={ `${className} ${isEditing ? ' spreadsheet-cursor-top' : ''}` } style={ styles } onClick={ this.onHeaderClick.bind(this) }>
        <div className='w-100 d-flex justify-content-around'>
          { children.dateTime && (
            <FormattedDateTime displayFormat={ column.specialisedStyles.dateFormat }>
              { children.dateTime }
            </FormattedDateTime>
          )}
          { !children.dateTime && children}
          <TimeSeriesInfo information={information} warning={warning} error={error}/>
          { !isEditing && column.isSortable && <SortIcon columnKey={ column.key } orderBy={ orderBy } orderByDirection={ orderByDirection } /> }
        </div>
        <HeaderResizer colRef={ this.ref } colKey={ column.key } onResize={ onResize } definedStyles={{ /* TODO */ }} />
      </th>
    );
  }
}

function TimeSeriesInfo({information, warning, error}){  
  return <>
    {information && information.length > 0 && <InfoPopup iconClassName={'text-info'} popupClassName='info-popup'>
      {information.map((i, x) => <div key={`i${x}`} className='info-line'>{i}</div>)}
    </InfoPopup>}

    {warning && warning.length > 0 && <InfoPopup iconClassName={'text-warning'} faIconClassName={'fa-exclamation-triangle'} popupClassName='info-popup'>
      {warning.map((i, x) => <div key={`w${x}`} className='info-line'>{i}</div>)}
    </InfoPopup>}
    
    {error && error.length > 0 && <InfoPopup iconClassName={'text-danger'} faIconClassName={'fa-exclamation-triangle'} popupClassName='info-popup'>
      {error.map((i, x) => <div key={`e${x}`} className='info-line'>{i}</div>)}
    </InfoPopup>}
  </>
}

class HeaderResizer extends PureComponent{
  static propTypes = {
    colRef : PropTypes.object.isRequired,
    colKey : PropTypes.string,
    onResize : PropTypes.func.isRequired,
    definedStyles : PropTypes.object
  }

  render(){
    const { colRef, colKey, onResize, definedStyles } = this.props;
    const baseStyle = {
      borderRight: '2px solid #dee2e6',
      width: '5px',
      cursor: 'col-resize'
    };

    const overrideStyle = {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      userSelect: 'none',
      zIndex: 99
    };

    const definedStyle = (definedStyles ?? {}).resizer; // TODO
    const { styles } = organiseStyles(baseStyle, definedStyle, overrideStyle);

    return (
    
      <div style={ styles }
          onClick={ e => e.stopPropagation() }
          onMouseDown={ e => onResize(e, colRef.current, colKey) } />
    );
  }
}

class Row extends PureComponent {
  static propTypes = {
    index : PropTypes.number.isRequired,
    className : PropTypes.string.isRequired,
    style : PropTypes.object.isRequired,
    children : PropTypes.object.isRequired,
    tableRowStyles : PropTypes.array.isRequired
  }

  render() {
    const { index: rowIndex, className, style, children, tableRowStyles} = this.props;
    const { styles }  = organiseStyles(tableRowStyles[rowIndex], style);
    return (
      <tr className={ className } style={ styles }>{ children }{/* cloneElement and pass rowKeys in props to cells */}</tr>
    ); 
  }
}

class Cell extends PureComponent {
  static propTypes = {
    className : PropTypes.string.isRequired,
    style : PropTypes.object.isRequired,
    children : PropTypes.object.isRequired,
    column : PropTypes.object.isRequired,
    setAdjustmentValue: PropTypes.func.isRequired,
    setSelectionAdjustmentValue: PropTypes.func.isRequired,
    selectLine: PropTypes.func.isRequired,
    removeSelectionAdjustments: PropTypes.func.isRequired,
    navigateCell: PropTypes.func.isRequired,
    copySelection: PropTypes.func.isRequired,
    pasteToSelection: PropTypes.func.isRequired
  }

  onHeaderClick(e){
    this.props.selectLine({isRowSelection:true, fullLine:true, ctrlKey: e.ctrlKey === true, shiftKey: e.shiftKey === true});
  }

  render() {
    let { className } = this.props;
    const { style, children, row, column, isEditing:isInAdjumentMode } = this.props;
    const { rowIndex } = row;
    const { information, warning, error } = row.title ?? {};
    const { type, index:colIndex, key:colKey } = column ?? {};  
    const cell = children.props.children;
    let {
      value,
      isSelected = false, 
      isEditing = false, 
      hasCursor = false, 
      adjustment, 
      adjustmentIsDirty = false, 
      hasExistingAdjustment = false
    } = cell;

    let { styles } = organiseStyles(style, cell.styles, {overflow:'hidden'});
    const specialisedStyles = cell.specialisedStyles;
    const tabIndex = cell.tabIndex;

    if (!isInAdjumentMode) isEditing = false;
    if (isSelected) className += ' is-selected ';
    if (hasCursor) className += ' has-cursor ';
    
    if (type !== 'number'){
      if (isEditing) delete styles.cursor;
      return <td className={ `${className} ${isInAdjumentMode ? ' spreadsheet-cursor-left' : ''}` } 
                style={ styles } 
                tabIndex={ tabIndex } 
                data-rowkey={rowIndex} 
                data-colkey={colKey}
                onClick={ this.onHeaderClick.bind(this) }>
                          <div style={{display:'flex'}}>
                            <div style={{flexGrow:'1', overflow:'hidden'}}>
                              <CellValue 
                                    rowKey={rowIndex} 
                                    colKey={colKey}
                                    type={ type }
                                    style={ styles } 
                                    { ...specialisedStyles } 
                                    value={value}/>
                            </div>
                            <div style={{display:'flex'}}>
                              <TimeSeriesInfo style={{display:'flex'}} information={information} warning={warning} error={error}/>
                            </div>
                          </div>
             </td>
    }
    
    let showAjustmentIndicator = (hasExistingAdjustment || adjustmentIsDirty);
    if (hasExistingAdjustment || adjustmentIsDirty) showAjustmentIndicator = true;
    if (hasExistingAdjustment && adjustment === undefined && adjustmentIsDirty === true) showAjustmentIndicator = false;
    if (showAjustmentIndicator) className += ' analysis-adjustments-is-adjusted ';      

    if (!isEditing) {
      let displayValue = value;
      if (isInAdjumentMode){
        if (adjustmentIsDirty) displayValue = (adjustment ?? '');
      }

      return <td className={ className } style={ styles } tabIndex={ tabIndex } data-rowkey={rowIndex} data-colkey={colIndex > 0 ? colKey : undefined}>
        <div className={`${adjustmentIsDirty ? 'asterisk' : ''}`}>
          <CellValue 
            rowKey={rowIndex} 
            colKey={colKey}
            type={ type } 
            style={ styles } 
            { ...specialisedStyles } 
            value={displayValue}/>
        </div>
      </td>
    }
    else{    
      className = this.props.className;
      let adjustedValue = undefined;
      if (adjustment === undefined){
        adjustedValue = formatNumber({value, useGrouping:false, minDecimals:specialisedStyles.decimalPlaces, maxDecimals:specialisedStyles.decimalPlaces});
      } else {
        adjustedValue = adjustment ?? '';
      }

      return <td className={ `${className} analysis-adjustment-cell` } style={ styles } tabIndex={ tabIndex } data-rowkey={rowIndex} data-colkey={colIndex > 0 ? colKey : undefined}>
          <div className={`${adjustmentIsDirty ? 'asterisk' : ''}`}>
            <CellInput
                value={adjustedValue}
                rowKey={rowIndex} 
                colKey={colKey}
                navigateCell={this.props.navigateCell}
                copySelection={this.props.copySelection}
                pasteToSelection={this.props.pasteToSelection}
                setAdjustmentValue={this.props.setAdjustmentValue}
                setSelectionAdjustmentValue={this.props.setSelectionAdjustmentValue}
                selectLine={this.props.selectLine}
                removeSelectionAdjustments={this.props.removeSelectionAdjustments}
                revertSelectionAdjustments={this.props.revertSelectionAdjustments}
                />
          </div>
        </td>
    }    
  }
}

const CellValue = ({ 
    value, 
    type, 
    minDecimals, 
    maxDecimals, 
    decimalPlaces, 
    commaSeparated, 
    dateFormat, 
    valueFormat}) => {

  switch(type){
    case 'string':
      // used in horizontal view for the time series details
      break;
    case 'date':
      value = formatDateTime(value, dateFormat);
      break;
    case 'number':
      if (valueFormat){
        value = formatNumeral(value, valueFormat);
      }
      else{
        value = formatNumber( {value, useGrouping:commaSeparated, minDecimals:minDecimals ?? decimalPlaces, maxDecimals:maxDecimals ?? decimalPlaces});
      }
      break;
    default : // should never get here
      value = formatValue( {value, decimalPlaces:decimalPlaces, commaSeparated:commaSeparated, dateFormat:dateFormat, valueFormat:valueFormat });
      break;
  }  

  return value || <>&nbsp;</>;
}

class CellInput extends React.Component{
  static propTypes = {
    value: PropTypes.any,
    rowKey : PropTypes.any.isRequired,
    colKey : PropTypes.string.isRequired,
    navigateCell: PropTypes.func.isRequired,
    copySelection: PropTypes.func.isRequired,
    pasteToSelection: PropTypes.func.isRequired,
    setAdjustmentValue: PropTypes.func.isRequired,
    setSelectionAdjustmentValue: PropTypes.func.isRequired,
    selectLine: PropTypes.func.isRequired,
    removeSelectionAdjustments: PropTypes.func.isRequired,
    revertSelectionAdjustments: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {isDirty:false, value:undefined};
  }

  componentWillUnmount() {
    const shouldSetValue = this.state.isDirty;
    if (shouldSetValue)
      this.props.setAdjustmentValue({value: this.state.value, rowKey: this.props.rowKey, colKey: this.props.colKey});
  }

  onBlur(){
    if (!this.state.isDirty)
      return;

    const args = {value: this.state.value, rowKey: this.props.rowKey, colKey: this.props.colKey};
    this.props.setAdjustmentValue(args);
    this.setState({...this.state, isDirty:false});
  }

  onKeyDown(e){
    const shouldSetValue = !e.shiftKey && this.state.isDirty;
    const args = {value: this.state.value, rowKey: this.props.rowKey, colKey: this.props.colKey};
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        if (e.shiftKey && e.ctrlKey){
          this.props.selectLine({isRowSelection:false, direction: 'fromStart'});
        } else {
          if (shouldSetValue) this.props.setAdjustmentValue(args);
          this.props.navigateCell({...args, direction:'up', continueSelection:e.shiftKey});
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (e.shiftKey && e.ctrlKey){
          this.props.selectLine({isRowSelection:false, direction: 'toEnd'});
        } else {
          if (shouldSetValue) this.props.setAdjustmentValue(args);
          this.props.navigateCell({...args, direction:'down', continueSelection:e.shiftKey});
        }
        break;
      case 'ArrowLeft':
        if (e.shiftKey && e.ctrlKey){
          this.props.selectLine({isRowSelection:true, direction: 'fromStart'});
        } else {
          if (e.target.selectionStart === 0) {
            e.preventDefault();
            if (shouldSetValue) this.props.setAdjustmentValue(args);
            this.props.navigateCell({...args, direction:'left', continueSelection:e.shiftKey});
          }
        }
        break;
      case 'ArrowRight':
        if (e.shiftKey && e.ctrlKey){
          this.props.selectLine({isRowSelection:true, direction: 'toEnd'});
        } else {
          if (e.target.selectionStart === e.target.value.length || (e.shiftKey && e.target.selectionEnd === e.target.value.length)) {
            e.preventDefault();
            if (shouldSetValue) this.props.setAdjustmentValue(args);
            this.props.navigateCell({...args, direction:'right', continueSelection:e.shiftKey});
          }
        }
        break;
      case 'Tab':
        e.preventDefault();
        if (shouldSetValue) this.props.setAdjustmentValue(args);
        this.props.navigateCell({...args, direction: e.shiftKey ? 'left' : 'right'});
        break;
      case 'Enter':
        e.preventDefault();
        if ((e.shiftKey && e.ctrlKey) || e.ctrlKey){
          if (shouldSetValue)
            this.props.setAdjustmentValue({...args, isDeferredValue:true });

          this.props.setSelectionAdjustmentValue(args);
        } else if (shouldSetValue) {
          this.props.setAdjustmentValue(args);
          this.props.navigateCell({...args, direction:'enter'});
        }
          
        break;
      case 'Escape':
        e.target.setSelectionRange(e.target.selectionStart, 0);
        break;
      case 'Delete':
        if (e.shiftKey && e.ctrlKey){
          e.preventDefault();
          this.props.revertSelectionAdjustments();
        }
        else if (e.ctrlKey){
          e.preventDefault();
          delete args.value;
          this.props.setAdjustmentValue(args);
        }
        break;
      default:
        break;
    }
  }
  
  onChange(e) {
    this.setState({...this.state, isDirty:true, value: e.target.value});
    this.props.setAdjustmentValue({value: e.target.value, rowKey: this.props.rowKey, colKey: this.props.colKey, isDeferredValue:true});
  }

  onCopy(e){
    e.preventDefault();
    this.props.copySelection();
  }

  onPaste(e){
    const text = e.clipboardData.getData('text/plain');
    const {success, table} = mapCSVToArray(text);
    if (success && (table.length > 1 || (table.length === 1 && table[0].length > 1))){
      e.preventDefault();
      this.props.pasteToSelection(text);
    }
  }

  render() {
    const value = this.state.value ?? this.props.value;

    function updateWidth(e) {
      if (e && e.parentElement) {
        const { width: parentWidth } = window.getComputedStyle(e.parentElement);
        e.style.width = `${parseFloat(parentWidth)}px`;
        e.style.display = 'unset';
      }
    }

    function onRef(e){
      updateWidth(e);
      if (this.state.hasFocus)
        return;

      this.setState({...this.state, hasFocus:true});
      setTimeout(() => {
        if (e){
          e.focus();
          if (e.value.length > 0 && e.selectionStart === 0 && e.selectionEnd === e.value.length) return;
          e.setSelectionRange(0, e.value.length);
          updateWidth(e);
        }
      },10);
    }

    return <input className='analysis-adjustments-enabled-input' ref={onRef.bind(this)} value={value ?? ''} 
                  onBlur={this.onBlur.bind(this)} 
                  onKeyDown={this.onKeyDown.bind(this)} 
                  onChange={this.onChange.bind(this)} 
                  onCopy={this.onCopy.bind(this)} 
                  onPaste={this.onPaste.bind(this)} />
  }
}

function createHeaderResizeHandler({ headers, updateHeaderWidth }) {
  const findHeaderIndex = key => headers.findIndex(i => i.key === key);

  return (e, th, key) => {
    const index = findHeaderIndex(key);

    if (index < 0)
      return;

    const styles = getComputedStyle(th);
    const width = parseInt(styles.width, 10);

    if (isNaN(width))
      return;

    const x = e.clientX;
    let dx = e.clientX - x;

    const mouseMoveHandler = e => {
      dx = e.clientX - x;
      th.style.width = `${width + dx}px`;
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      updateHeaderWidth(key, width + dx);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };
}

export default function AnalysisChartData({
  isToolbarVisible,
  isTableDesignPanelVisible,
  isTableJsonEditorPanelVisible,
  tableHeaders = [], tableData = [], tableStyles, tableRowStyles, tableSettings, 
  updateProperty, updateHeaderWidth, updateHeaderWidths, updateSort, updateFontSize, updateHideDate, updateRowSize, updateTitle, updateDateFormat, updateDecimalPlaces,
  isInlineAdjustmentsEditor, isEditing, beginEditMode, setSelectionStart, setSelectionEnd, selectLine,
  setAdjustmentValue, setSelectionAdjustmentValue, navigateCell, removeSelectionAdjustments, revertSelectionAdjustments,
  copySelection, pasteToSelection, showSaveAdjustments,
  reflowSwitch, resizeSwitch }) {
  const { displayData, fontSize, rowSize, headerStyles, headerType, orderBy, orderByDirection, dateFormat, decimalPlaces} = tableSettings;
  const [isMounted, forceReMount] = useForceReMountEffect();
  const [lastCellCoordinate, setLastCellCoordinate] = useState("");

  // filter out the disabled columns here, not it the reducer as that will require rebuilding all the table data
  const isHorizontal = tableSettings.displayData === 'horizontal';
  if (!isHorizontal)
    tableHeaders = tableHeaders.filter(h => !h.isDisabled);
  else
    tableData = tableData.filter(row => row.title === undefined || !row.title.isDisabled);

  useDidUpdateEffect(() => forceReMount(), [reflowSwitch, resizeSwitch, rowSize]);
  const updateHeaderSize = createHeaderResizeHandler({ headers: tableHeaders, updateHeaderWidth });

  function onDoubleClick(e){
    if (!isInlineAdjustmentsEditor || !beginEditMode)
      return;

    const {rowKey,colKey} = getCoord(e.target);
    if (!rowKey || !colKey)
      return;

    e.preventDefault();
    if (!isEditing){
      beginEditMode({rowKey, colKey});
    }
  }

  function onMouseDown(e){
    if (e.target.tagName === 'INPUT' || !setSelectionStart)
      return;

    const {rowKey,colKey} = getCoord(e.target);
    if (!rowKey || !colKey)
      return;
      
    if (lastCellCoordinate === `${rowKey}|${colKey}`)
      return;

    setLastCellCoordinate(`${rowKey}|${colKey}`);
    if (setSelectionStart){
      e.preventDefault();
      setSelectionStart({rowKey, colKey, ctrlKey: e.ctrlKey === true, shiftKey: e.shiftKey === true});
    }
  }

  function onMouseMove(e){
    if (e.buttons === 0 || !setSelectionEnd)
      return;
      
    const {rowKey,colKey} = getCoord(e.target);
    if (!rowKey || !colKey)
      return;
  
    if (setSelectionEnd && e.buttons > 0){
      if (lastCellCoordinate === `${rowKey}|${colKey}`)
        return;

      setLastCellCoordinate(`${rowKey}|${colKey}`);
      setSelectionEnd({rowKey, colKey, ctrlKey: e.ctrlKey === true, shiftKey: e.shiftKey === true});
    }
  }

  return (
    <div className='d-flex flex-row h-100'>
      <div className='flex-fill' style={{display:'flex', width:'100%', height:'100%'}}>
        <Allotment vertical={false} proportionalLayout={true} defaultSizes={['', 270]}>
          <Allotment.Pane>
            <ScrollingTable headers={tableHeaders}
                            data={tableData}
                            tableStyles={tableStyles}
                            tableRowStyles={tableRowStyles}
                            tableSettings={tableSettings}
                            fontSize={fontSize}
                            rowSize={rowSize}
                            headerStyles={headerStyles}
                            orderBy={orderBy}
                            orderByDirection={orderByDirection}
                            updateSort={updateSort}
                            updateHeaderSize={updateHeaderSize}
                            isMounted={isMounted}
                            isEditing={isEditing}
                            onDoubleClick= {onDoubleClick}
                            onMouseDown= {onMouseDown}
                            onMouseMove= {onMouseMove}
                            navigateCell={navigateCell}
                            setAdjustmentValue={setAdjustmentValue}
                            setSelectionAdjustmentValue={setSelectionAdjustmentValue}
                            selectLine={selectLine}
                            removeSelectionAdjustments={removeSelectionAdjustments}
                            revertSelectionAdjustments={revertSelectionAdjustments}
                            copySelection={copySelection}
                            pasteToSelection={pasteToSelection}
                            showSaveAdjustments={showSaveAdjustments}/>
          </Allotment.Pane>
          <Allotment.Pane visible={isTableDesignPanelVisible || isTableJsonEditorPanelVisible || isEditing} maxSize={450}>
            {isTableDesignPanelVisible === true && <div style={{height:'100%', overflowX:'auto'}}>
            <TableDesignPanel
                isHorizontal={displayData === 'horizontal'}
                headerType={headerType}
                fontSize={fontSize}
                rowSize={rowSize}
                dateFormat={dateFormat}
                decimalPlaces={decimalPlaces}
                updateDateFormat={updateDateFormat}
                updateDecimalPlaces={updateDecimalPlaces}
                updateProperty={updateProperty}
                updateFontSize={updateFontSize}
                updateHideDate={updateHideDate}
                updateRowSize={updateRowSize}
                updateTitle={updateTitle}
                updateHeaderWidths={updateHeaderWidths} 
                forceReMount={forceReMount}/>
            </div>}
            {isTableJsonEditorPanelVisible === true  && <div style={{ height:'100%', overflowX:'auto'}}>
              <TableJsonEditorPanel />
              </div>}
            {isEditing === true  && <div style={{ height:'100%', overflowX:'auto'}}>
              <AdjustmentsPanel />
              </div>}
          </Allotment.Pane>
        </Allotment>           
      </div>

      {isToolbarVisible === true && <div style={{width:'56px'}}><TableToolbar /></div>}
    </div>
  );
}

class ScrollingTable extends PureComponent {
  static propTypes = {
    isEditing : PropTypes.bool.isRequired,
    onDoubleClick : PropTypes.func.isRequired,
    onMouseDown : PropTypes.func.isRequired,
    onMouseMove : PropTypes.func.isRequired,
    selectLine : PropTypes.func.isRequired,
    navigateCell : PropTypes.func.isRequired,
    setAdjustmentValue : PropTypes.func.isRequired,
    setSelectionAdjustmentValue : PropTypes.func.isRequired,
    removeSelectionAdjustments : PropTypes.func.isRequired,
    revertSelectionAdjustments : PropTypes.func.isRequired,
    copySelection : PropTypes.func.isRequired,
    pasteToSelection : PropTypes.func.isRequired,
    showSaveAdjustments : PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.headerRef = createRef();
    this.state = { height: 1 }; // A height must be set to prevent Html5Table component from measuring and setting its own height & width
  }

  getDimensions() {
    const headerElement = this.headerRef.current;

    if (!headerElement)
      return {};

    const headerStyles = window.getComputedStyle(headerElement);
    const containerStyles = window.getComputedStyle(headerElement.parentNode);

    const headerHeight = parseInt(headerStyles.height);
    const containerHeight = parseInt(containerStyles.height);

    return { containerHeight, headerHeight, tableHeight: containerHeight - headerHeight };
  }

  setDimensions() {
    const { tableHeight } = this.getDimensions();

    if (this.state.height !== tableHeight)
      this.setState({ height: tableHeight });
  }

  componentDidMount() {
    this.setDimensions();
  }

  componentWillUnmount(){
  }

  componentDidUpdate() {
    this.setDimensions();
  }

  render() {
    return (
      <div className='fota-analysis-window-table' style={{height:'calc(100% - 3px)'}}>
          <div ref={ this.headerRef }>
            <Html5Table className='table table-xs'
                        classNamePrefix='window-'
                        style={{ fontSize: this.props.fontSize ? `${this.props.fontSize}px` : '11px', minHeight: 'auto', maxHeight: 'auto', overflow: 'hidden' }}
                        columns={ this.props.headers }
                        data={ [] }
                        HeaderRow={ props => (
                          <HeaderRow { ...props }
                                    headerStyles={ this.props.headerStyles } />
                        ) }
                        HeaderCell={ props => (
                          <HeaderCell { ...props }
                                      isEditing={this.props.isEditing}
                                      tableSettings={ this.props.tableSettings }
                                      headerStyles={ this.props.headerStyles }
                                      orderBy={ this.props.orderBy }
                                      orderByDirection={ this.props.orderByDirection }
                                      onSort={ this.props.updateSort }
                                      selectLine={ this.props.selectLine }
                                      onResize={ this.props.updateHeaderSize }/>
                        ) } />
          </div>
          { this.props.isMounted && (
            <ScrollingTableContent headers={this.props.headers}
                                   data={this.props.data}
                                   tableStyles={this.props.tableStyles}
                                   tableRowStyles={this.props.tableRowStyles}
                                   tableSettings={this.props.tableSettings}
                                   fontSize={this.props.fontSize}
                                   rowSize={this.props.rowSize}
                                   headerStyles={this.props.headerStyles}
                                   orderBy={this.props.orderBy}
                                   orderByDirection={this.props.orderByDirection}
                                   updateHeaderSize={this.props.updateHeaderSize}
                                   headerRef={this.headerRef}
                                   height={this.state.height}
                                   isEditing={this.props.isEditing}
                                   onDoubleClick= {this.props.onDoubleClick}
                                   onMouseDown= {this.props.onMouseDown}
                                   onMouseMove= {this.props.onMouseMove}
                                   navigateCell={this.props.navigateCell}
                                   setAdjustmentValue={this.props.setAdjustmentValue}
                                   setSelectionAdjustmentValue={this.props.setSelectionAdjustmentValue}
                                   selectLine={this.props.selectLine}
                                   removeSelectionAdjustments={this.props.removeSelectionAdjustments}
                                   revertSelectionAdjustments={this.props.revertSelectionAdjustments}
                                   copySelection={this.props.copySelection}
                                   pasteToSelection={this.props.pasteToSelection}
                                   showSaveAdjustments={this.props.showSaveAdjustments}/>
          ) }
      </div>
    );
  }
}

class ScrollingTableContent extends PureComponent {
  static propTypes = {
    fontSize : PropTypes.number,
    headers : PropTypes.array.isRequired,
    data : PropTypes.any.isRequired,
    headerRef : PropTypes.object,
    contentRef : PropTypes.object,
    rowSize : PropTypes.number,
    tableStyles : PropTypes.object,
    tableRowStyles : PropTypes.array,
    isEditing : PropTypes.bool.isRequired,
    onDoubleClick : PropTypes.func.isRequired,
    onMouseDown : PropTypes.func.isRequired,
    onMouseMove : PropTypes.func.isRequired,
    navigateCell : PropTypes.func.isRequired,
    setAdjustmentValue : PropTypes.func.isRequired,
    setSelectionAdjustmentValue : PropTypes.func.isRequired,
    selectLine : PropTypes.func.isRequired,
    removeSelectionAdjustments : PropTypes.func.isRequired,
    revertSelectionAdjustments : PropTypes.func.isRequired,
    copySelection : PropTypes.func.isRequired,
    pasteToSelection : PropTypes.func.isRequired,
    showSaveAdjustments : PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.contentRef = createRef();
  }

  render() {    
    return (
      <Html5Table className='table table-xs'
                  classNamePrefix='window-'
                  style={{ fontSize: this.props.fontSize ? `${this.props.fontSize}px` : '11px', overflow: 'hidden' }}
                  columns={ this.props.headers }
                  data={ this.props.data }
                  variableSizeRows={ true }
                  disableHeader={ true }
                  rowHeight={ Number(this.props.rowSize ?? 20) }
                  Table={ props => (
                    <Table { ...props }
                          headerRef={ this.props.headerRef }
                          contentRef={ this.contentRef }
                          tableHeight={ this.props.height }
                          tableStyles= { this.props.tableStyles} 
                          isEditing= {this.props.isEditing}
                          doubleClick= {this.props.onDoubleClick}
                          mouseDown= {this.props.onMouseDown}
                          mouseMove= {this.props.onMouseMove}
                          selectLine= {this.props.selectLine}
                          navigateCell= {this.props.navigateCell}
                          revertSelectionAdjustments= {this.props.revertSelectionAdjustments}
                          copySelection= {this.props.copySelection}
                          pasteToSelection= {this.props.pasteToSelection}
                          showSaveAdjustments={this.props.showSaveAdjustments}/>
                  ) }
                  Row={ props => (
                    <Row { ...props }
                         tableRowStyles={ this.props.tableRowStyles} />
                  ) }
                  Cell={ props => (
                    <Cell { ...props}
                      isEditing={this.props.isEditing}
                      navigateCell={this.props.navigateCell}
                      copySelection={this.props.copySelection}
                      pasteToSelection={this.props.pasteToSelection}
                      setAdjustmentValue={this.props.setAdjustmentValue}
                      setSelectionAdjustmentValue={this.props.setSelectionAdjustmentValue}
                      selectLine={this.props.selectLine}
                      removeSelectionAdjustments={this.props.removeSelectionAdjustments}
                      revertSelectionAdjustments={this.props.revertSelectionAdjustments}
                     />
                  ) }
                  tableOuterRef={ this.contentRef }
                  height={ this.props.height } />
    );
  }
}

function getCoord(e){
  while(e){
    const dataset = e.dataset;
    if (dataset){
      const rowKey = dataset.rowkey;
      const colKey = dataset.colkey;
      if (rowKey && colKey)
        return {rowKey, colKey};
    }

    e = e.parentNode;
  }

  return {};
}