import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logErrorNotification
} from './log';
import { toJS } from '../utility/immutable-utility';
import {
  analysisRefresh
} from './analysis';
import {
  mapToFilteredRequest
} from '../mapper/analysisDynamicWorkspaceMapper';
import {
  applyDynamicWorkspaceTimeSeries,
  getGroupedDynamicFilterSelections,
  getGroupedFilterChanges
} from '../reducer-functions/analysis-dynamic-workspace';

export const analysisDynamicWorkspaceToggleCategoryGrouping = () => async (dispatch, getState) => {
  const state = getState();
  let isGroupingEnabled = state.getIn(['analysis', 'workspace', 'dynamicWorkspaceGroupingEnabled']) === true;
  isGroupingEnabled = !isGroupingEnabled;
  dispatch(analysisDynamicWorkspaceToggleCategoryGroupingBegin());

  if (isGroupingEnabled) {
    const dynamicWorkspaces = toJS(state.getIn(['analysis', 'workspace', 'dynamicWorkspaces']));
    const categoryGroupings = getGroupedDynamicFilterSelections(dynamicWorkspaces);
    await categoryGroupings.forEachAsync(async cg => {
      await fetchFilteredBasketItemsAsync(dispatch, getState, cg.dynamicWorkspaceKey, cg.dynamicFilterKey, cg.selectedValue, cg.multiSelectValues);
    });
  }

  dispatch(analysisDynamicWorkspaceToggleCategoryGroupingComplete(isGroupingEnabled));
  dispatch(analysisRefresh());
}

export const ANALYSIS_DYNAMIC_WORKSPACE_TOGGLE_CATEGORY_GROUPING_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_TOGGLE_CATEGORY_GROUPING_BEGIN';
const analysisDynamicWorkspaceToggleCategoryGroupingBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_TOGGLE_CATEGORY_GROUPING_BEGIN);

export const ANALYSIS_DYNAMIC_WORKSPACE_TOGGLE_CATEGORY_GROUPING_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_TOGGLE_CATEGORY_GROUPING_COMPLETE';
const analysisDynamicWorkspaceToggleCategoryGroupingComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_TOGGLE_CATEGORY_GROUPING_COMPLETE, 'value');

export const analysisDynamicWorkspaceSetToolbarDynamicFilter = (dynamicWorkspaceKey, dynamicFilterKey, singleValue, multipleValues) => async (dispatch, getState) => {
  const state = getState();
  const isGroupingEnabled = state.getIn(['analysis', 'workspace', 'dynamicWorkspaceGroupingEnabled']) === true;
  const dynamicWorkspaces = toJS(state.getIn(['analysis', 'workspace', 'dynamicWorkspaces']));
  const dynamicWorkspaceKeyFilterGroupings = getGroupedFilterChanges(dynamicWorkspaceKey, dynamicFilterKey, isGroupingEnabled, dynamicWorkspaces);

  await dynamicWorkspaceKeyFilterGroupings.forEachAsync(async g => {
    await fetchFilteredBasketItemsAsync(dispatch, getState, g.dynamicWorkspaceKey, g.dynamicFilterKey, singleValue, multipleValues);
  });

  dispatch(analysisRefresh());
}

async function fetchFilteredBasketItemsAsync(dispatch, getState, dynamicWorkspaceKey, dynamicFilterKey, value, values) {
  const state = getState();
  const dynamicWorkspaces = toJS(state.getIn(['analysis', 'workspace', 'dynamicWorkspaces']));
  const dynamicWorkspace = dynamicWorkspaces.find(d => d.key === dynamicWorkspaceKey);
  const dynamicFilter = dynamicWorkspace.dynamicFilters.find(f => f.key === dynamicFilterKey);
  if (!dynamicFilter)
    return new Promise((resolve) => resolve(undefined));

  if (dynamicFilter.isMultiSelect) {
    dynamicFilter.multiSelectValues = values ? values.sort() : (value ? [value] : []);
  } else {
    dynamicFilter.selectedValue = value ? value : ((values && values.length > 0 ? values.sort()[0] : ''));
  }

  dispatch(analysisDynamicWorkspaceSetToolbarDynamicFilterValueBegin());
  const requestParams = mapToFilteredRequest(dynamicWorkspace.searchCriteria, dynamicWorkspace.searchCriteria, dynamicWorkspace.dynamicFilters, dynamicWorkspace.substitutionKeyExclusionCategories, false);
  if (requestParams.error) {
    dispatch(logErrorNotification(requestParams.error));
    return new Promise((resolve) => resolve(undefined));
  }

  return await authFetch(requestParams.isMultiSelect ? `${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/multifiltered` : `${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/filtered`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestParams)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(analysisDynamicWorkspaceSetToolbarDynamicFilterValueComplete(dynamicWorkspaces, dynamicWorkspace, data.dynamicRows));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(analysisDynamicWorkspaceSetToolbarDynamicFilterValueComplete(dynamicWorkspaces));
    });
}

export const ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_BEGIN';
const analysisDynamicWorkspaceSetToolbarDynamicFilterValueBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_BEGIN);

export const ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_COMPLETE';
const analysisDynamicWorkspaceSetToolbarDynamicFilterValueComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_COMPLETE, 'dynamicWorkspaces', 'dynamicWorkspace', 'dynamicRows');

export async function applyDynamicFilterOverridesToBasketAsync(dynamicWorkspaces, dynamicFilterOverrides, basket, baselineBasket) {
  const errors = [];
  for (let dsIndex = 0; dsIndex < dynamicFilterOverrides.length; dsIndex++) {
    const dynamicFilterOverride = dynamicFilterOverrides[dsIndex];
    const dynamicWorkspace = dynamicWorkspaces.find(ds => ds.key === dynamicFilterOverride.key);
    if (!dynamicWorkspace) {
      errors.push(`filter not found - ${dynamicFilterOverride.filters.map(f => `'${f.value}'`).join(',')} not applied`)
    } else {
      for (let filterIndex = 0; filterIndex < dynamicFilterOverride.filters.length; filterIndex++) {
        const overrideFilter = dynamicFilterOverride.filters[filterIndex];
        const { dynamicRows } = await fetchDynamicWorkspaceSetFilterValueAsync(dynamicWorkspaces, dynamicFilterOverride.key, overrideFilter.dynamicFilterKey, overrideFilter.value, overrideFilter.values);
        if (dynamicRows) {
          basket = applyDynamicWorkspaceTimeSeries(
            basket,
            baselineBasket,
            dynamicWorkspace,
            dynamicRows,
            false);
        } else {
          errors.push(`filter not found - '${overrideFilter.value}' not applied`)
        }
      }
    }
  }

  return { basket, errors: errors.length > 0 ? errors : undefined };

  async function fetchDynamicWorkspaceSetFilterValueAsync(dynamicWorkspaces, key, dynamicFilterKey, value, values) {
    const dynamicWorkspace = dynamicWorkspaces.find(d => d.key === key);
    if (!dynamicWorkspace) {
      return new Promise((resolve) => resolve(undefined));
    }

    const dynamicFilter = dynamicWorkspace.dynamicFilters.find(f => f.key === dynamicFilterKey);
    if (dynamicFilter.isMultiSelect) {
      dynamicFilter.multiSelectValues = values ? values.sort() : (value ? [value] : []);
    } else {
      dynamicFilter.selectedValue = value ? value : ((values && values.length > 0 ? values.sort()[0] : ''));
    }

    const requestParams = mapToFilteredRequest(dynamicWorkspace.searchCriteria, dynamicWorkspace.searchCriteria, dynamicWorkspace.dynamicFilters, dynamicWorkspace.substitutionKeyExclusionCategories, false);

    return await authFetch(requestParams.isMultiSelect ? `${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/multifiltered` : `${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/filtered`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestParams)
    })
      .then(response => response.json());
  }
}

export const ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BASELINE = 'ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BASELINE';
export const analysisDynamicWorkspaceSaveBaseline = createAction(ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BASELINE, 'baselineBasket');

export const ANALYSIS_DYNAMIC_WORKSPACE_DISASSOCIATE_FROM_DYNAMIC_FILTER = 'ANALYSIS_DYNAMIC_WORKSPACE_DISASSOCIATE_FROM_DYNAMIC_FILTER';
export const analysisDynamicWorkspaceDisassociateFromDynamicFilter = createAction(ANALYSIS_DYNAMIC_WORKSPACE_DISASSOCIATE_FROM_DYNAMIC_FILTER, 'key');