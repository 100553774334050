import { createAction } from '../utility/redux-utility';
import { analysisRefreshTemplateNameExpressions } from './analysis';

export const ANALYSIS_UPDATE_BASKET_FROM_COMPARISONMODE_SETTINGSV2 = 'ANALYSIS_UPDATE_BASKET_FROM_COMPARISONMODE_SETTINGSV2';
export const analysisUpdateBasketFromComparisonModeSettings2 = createAction(ANALYSIS_UPDATE_BASKET_FROM_COMPARISONMODE_SETTINGSV2);

export const ANALYSIS_COMPARISONMODE_INIT = 'ANALYSIS_COMPARISONMODE_INIT';
export const analysisComparisonModeInit = createAction(ANALYSIS_COMPARISONMODE_INIT, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_APPLY_SELECTION_TO_WORKSPACE = 'ANALYSIS_COMPARISONMODE_APPLY_SELECTION_TO_WORKSPACE';
export const analysisComparisonModeApplySelectionToWorkspace = createAction(ANALYSIS_COMPARISONMODE_APPLY_SELECTION_TO_WORKSPACE, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_SET_ACTIVE_VIEW = 'ANALYSIS_COMPARISONMODE_SET_ACTIVE_VIEW';
export const analysisComparisonModeSetActiveView = createAction(ANALYSIS_COMPARISONMODE_SET_ACTIVE_VIEW, 'comparisonMode', 'view');

export const ANALYSIS_COMPARISONMODE_SET_PLOT_ORIGIN = 'ANALYSIS_COMPARISONMODE_SET_PLOT_ORIGIN';
export const analysisComparisonModeSetPlotOrigin = createAction(ANALYSIS_COMPARISONMODE_SET_PLOT_ORIGIN, 'comparisonMode', 'mode', 'absOriginDate', 'relOriginDate');

export const ANALYSIS_COMPARISONMODE_SET_RANGE = 'ANALYSIS_COMPARISONMODE_SET_RANGE';
export const analysisComparisonModeSetRange = createAction(ANALYSIS_COMPARISONMODE_SET_RANGE, 'comparisonMode', 'start', 'end');

export const ANALYSIS_COMPARISONMODE_CLEAR_PRESELECTION = 'ANALYSIS_COMPARISONMODE_CLEAR_PRESELECTION';
export const analysisComparisonModeClearPreselection = createAction(ANALYSIS_COMPARISONMODE_CLEAR_PRESELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_COLUMN = 'ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_COLUMN';
export const analysisComparisonModeTogglePreselectionColumn = createAction(ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_COLUMN, 'comparisonMode', 'column');

export const ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_ROW = 'ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_ROW';
export const analysisComparisonModeTogglePreselectionRow = createAction(ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_ROW, 'comparisonMode', 'row');

export const ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION = 'ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION';
export const analysisComparisonModeTogglePreselection = createAction(ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION, 'comparisonMode', 'value');

export const ANALYSIS_COMPARISONMODE_ADD_PRESELECTION_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_ADD_PRESELECTION_TO_SELECTION';
export const analysisComparisonModeAddPreselectionToSelection = createAction(ANALYSIS_COMPARISONMODE_ADD_PRESELECTION_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_SET_DAY_ACTIVE_DATE = 'ANALYSIS_COMPARISONMODE_SET_DAY_ACTIVE_DATE';
export const analysisComparisonModeSetDayActiveDate = createAction(ANALYSIS_COMPARISONMODE_SET_DAY_ACTIVE_DATE, 'comparisonMode', 'date');

export const ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_PRESELECTION_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_PRESELECTION_TO_SELECTION';
export const analysisComparisonModeAggregateAddPreselectionToSelection = createAction(ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_PRESELECTION_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_RANGE_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_RANGE_TO_SELECTION';
export const analysisComparisonModeAggregateAddRangeToSelection = createAction(ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_RANGE_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_AGGREGATE_TOGGLE_OPERATION = 'ANALYSIS_COMPARISONMODE_AGGREGATE_TOGGLE_OPERATION';
export const analysisComparisonModeAggregateToggleOperation = createAction(ANALYSIS_COMPARISONMODE_AGGREGATE_TOGGLE_OPERATION, 'comparisonMode', 'operation', 'isSelected');

export const ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION = 'ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION';
export const analysisComparisonModeRemoveAllSelection = createAction(ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE = 'ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE';
export const analysisComparisonModeRemoveSelectedDate = createAction(ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE, 'comparisonMode', 'value');

export const ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION_FROM_WORKSPACE = 'ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION_FROM_WORKSPACE';
export const analysisComparisonModeRemoveAllSelectionFromWorkspace = createAction(ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION_FROM_WORKSPACE, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE_FROM_WORKSPACE = 'ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE_FROM_WORKSPACE';
export const analysisComparisonModeRemoveSelectedDateFromWorkspace = createAction(ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE_FROM_WORKSPACE, 'comparisonMode', 'value');

export const ANALYSIS_COMPARISONMODE_SET_INDIVIDUAL_RELATIVE_DATE = 'ANALYSIS_COMPARISONMODE_SET_INDIVIDUAL_RELATIVE_DATE';
export const analysisComparisonModeSetIndividualRelativeDate = createAction(ANALYSIS_COMPARISONMODE_SET_INDIVIDUAL_RELATIVE_DATE, 'comparisonMode', 'relativeDate');

export const ANALYSIS_COMPARISONMODE_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION';
export const analysisComparisonModeAddIndividualRelativeToSelection = createAction(ANALYSIS_COMPARISONMODE_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION, 'comparisonMode');




export const ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_DATE = 'ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_DATE';
export const analysisComparisonModeSetAggregateRelativeDate = createAction(ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_DATE, 'comparisonMode', 'relativeDate');

export const ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_TO_PRESELECTION = 'ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_TO_PRESELECTION';
export const analysisComparisonModeAddAggregateRelativeToPreselection = createAction(ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_TO_PRESELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_REMOVE_AGGREGATE_RELATIVE_FROM_PRESELECTION = 'ANALYSIS_COMPARISONMODE_REMOVE_AGGREGATE_RELATIVE_FROM_PRESELECTION';
export const analysisComparisonModeRemoveAggregateRelativeFromPreselection = createAction(ANALYSIS_COMPARISONMODE_REMOVE_AGGREGATE_RELATIVE_FROM_PRESELECTION, 'comparisonMode', 'relativeDate');



export const ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION';
export const analysisComparisonModeAddAggregateRelativeDatesToSelection = createAction(ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_FROM_DATE = 'ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_FROM_DATE';
export const analysisComparisonModeSetAggregateRelativeFromDate = createAction(ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_FROM_DATE, 'comparisonMode', 'relativeDate');

export const ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_TO_DATE = 'ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_TO_DATE';
export const analysisComparisonModeSetAggregateRelativeToDate = createAction(ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_TO_DATE, 'comparisonMode', 'relativeDate');

export const ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION';
export const analysisComparisonModeAddAggregateRelativeRangeToSelection = createAction(ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION, 'comparisonMode');


export const ANALYSIS_COMPARISONMODE_INIT_FROM_BASKET = 'ANALYSIS_COMPARISONMODE_INIT_FROM_BASKET';
export const analysisComparisonModeInitFromBasket = createAction(ANALYSIS_COMPARISONMODE_INIT_FROM_BASKET, 'key');

export const analysisComparisonModeApplyToBasket = (key, data, suppressExistsCheck) => (dispatch) => {
  dispatch(analysisComparisonModeApplyToBasketComplete(key, data, suppressExistsCheck));
  dispatch(analysisRefreshTemplateNameExpressions());
};

export const ANALYSIS_COMPARISONMODE_APPLY_TO_BASKET = 'ANALYSIS_COMPARISONMODE_APPLY_TO_BASKET';
const analysisComparisonModeApplyToBasketComplete = createAction(ANALYSIS_COMPARISONMODE_APPLY_TO_BASKET);

export const ANALYSIS_COMPARISONMODE_BASKET_LEVEL_SET_COMPARISON_MODE = 'ANALYSIS_COMPARISONMODE_BASKET_LEVEL_SET_COMPARISON_MODE';
export const analysisComparisonModeBasketLevelSetComparisonMode = createAction(ANALYSIS_COMPARISONMODE_BASKET_LEVEL_SET_COMPARISON_MODE, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION';
export const analysisComparisonModeBasketLevelAddIndividualRelativeToSelection = createAction(ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_PRESELECTION_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_PRESELECTION_TO_SELECTION';
export const analysisComparisonModeBasketLevelAddPreselectionToSelection = createAction(ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_PRESELECTION_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_PRESELECTION_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_PRESELECTION_TO_SELECTION';
export const analysisComparisonModeBasketLevelAggregateAddPreselectionToSelection = createAction(ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_PRESELECTION_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION';
export const analysisComparisonModeBasketLevelAddAggregateRelativeRangeToSelection = createAction(ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION';
export const analysisComparisonModeBasketLevelAddAggregateRelativeDatesToSelection = createAction(ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_RANGE_TO_SELECTION = 'ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_RANGE_TO_SELECTION';
export const analysisComparisonModeBasketLevelAggregateAddRangeToSelection = createAction(ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_RANGE_TO_SELECTION, 'comparisonMode');

export const ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_SELECTED_DATE = 'ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_SELECTED_DATE';
export const analysisComparisonModeBasketLevelRemoveSelectedDate = createAction(ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_SELECTED_DATE, 'comparisonMode', 'value');

export const ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_ALL_SELECTION = 'ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_ALL_SELECTION';
export const analysisComparisonModeBasketLevelRemoveAllSelection = createAction(ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_ALL_SELECTION, 'comparisonMode');