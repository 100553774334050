import React from 'react';
import ScreenCaptureTableEditRowConnected from './ScreenCaptureTableEditRowConnected';
import ScreenCaptureTableReadRow from './ScreenCaptureTableReadRow';

export default function ScreenCaptureTableRow({ isEditing, selectedId, editId, action, openEdit, selectAction, deleteAction, goToAction }) {
  return isEditing && editId === action.get('id')
    ? <ScreenCaptureTableEditRowConnected />
    : <ScreenCaptureTableReadRow
        isEditing={isEditing}
        action={action}
        selectedId={selectedId}
        openEdit={openEdit}
        selectAction={selectAction}
        deleteAction={deleteAction}
        goToAction={goToAction} />
}