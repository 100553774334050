import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const timeSeriesDetailsAnnotationsLoad = (callback) => (dispatch, getState) => {
  const state = getState();
  const timeZoneId = state.getIn(['timeSeriesDetails', 'annotationsEditor', 'selectedTimeZone']);
  const identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);

  if (!identityId || identityId === 0) return;

  let uri = `${ANALYSIS_API_ROOT_URL}/v1/identities/${identityId}/annotations`;
  if (timeZoneId) uri += `?timeZoneId=${timeZoneId}`;

  authFetch(uri)
    .then(response => response.json())
    .then(data => {
      dispatch(timeSeriesDetailsAnnotationsLoadComplete(data));

      if (typeof callback === 'function') callback();
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_ANNOTATIONS_LOAD_COMPLETE = 'TIMESERIES_DETAILS_ANNOTATIONS_LOAD_COMPLETE';
export const timeSeriesDetailsAnnotationsLoadComplete = createAction(TIMESERIES_DETAILS_ANNOTATIONS_LOAD_COMPLETE, 'data');

function timeSeriesDetailsAnnotationSaveNew(dispatch, annotation, identityId) {
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/identities/${identityId}/annotations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(annotation)
  })
    .then(response => response.json())
    .then(data => data.annotations && data.annotations.length ? data.annotations[0] : null)
    .then(annotation => {
      dispatch(timeSeriesDetailsAnnotationSaved(annotation));
      dispatch(logInfoNotification('Annotation added'));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

function timeSeriesDetailsAnnotationSaveExisting(dispatch, annotation) {
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/annotations/${annotation.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(annotation)
  })
    .then(() => {
      dispatch(timeSeriesDetailsAnnotationSaved(annotation));
      dispatch(logInfoNotification('Annotation saved'));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const timeSeriesDetailsAnnotationSave = () => (dispatch, getState) => {
  const state = getState();
  const timeZone = state.getIn(['timeSeriesDetails', 'annotationsEditor', 'selectedTimeZone']);
  const annotationEdit = toJS(state.getIn(['timeSeriesDetails', 'annotationsEditor', 'annotationUnderEdit']), null);
  if (!annotationEdit) return;

  const annotation = { ...annotationEdit, timeZone };

  if (!annotation.id) {
    const identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);

    timeSeriesDetailsAnnotationSaveNew(dispatch, annotation, identityId);
  }
  else timeSeriesDetailsAnnotationSaveExisting(dispatch, annotation);
};

export const TIMESERIES_DETAILS_ANNOTATION_SAVED = 'TIMESERIES_DETAILS_ANNOTATION_SAVED';
export const timeSeriesDetailsAnnotationSaved = createAction(TIMESERIES_DETAILS_ANNOTATION_SAVED, 'data');

export const TIMESERIES_DETAILS_ANNOTATION_ADD = 'TIMESERIES_DETAILS_ANNOTATION_ADD';
export const timeSeriesDetailsAnnotationAdd = createAction(TIMESERIES_DETAILS_ANNOTATION_ADD);

export const TIMESERIES_DETAILS_ANNOTATION_EDIT = 'TIMESERIES_DETAILS_ANNOTATION_EDIT';
export const timeSeriesDetailsAnnotationEdit = createAction(TIMESERIES_DETAILS_ANNOTATION_EDIT, 'id');

export const TIMESERIES_DETAILS_ANNOTATION_EDIT_CANCEL = 'TIMESERIES_DETAILS_ANNOTATION_EDIT_CANCEL';
export const timeSeriesDetailsAnnotationEditCancel = createAction(TIMESERIES_DETAILS_ANNOTATION_EDIT_CANCEL);

export const timeSeriesDetailsAnnotationDelete = (annotationId) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/annotations/${annotationId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(() => {
      dispatch(timeSeriesDetailsAnnotationDeleted(annotationId));
      dispatch(logInfoNotification('Annotation deleted'));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_ANNOTATION_DELETED = 'TIMESERIES_DETAILS_ANNOTATION_DELETED';
export const timeSeriesDetailsAnnotationDeleted = createAction(TIMESERIES_DETAILS_ANNOTATION_DELETED, 'id');

export const TIMESERIES_DETAILS_ANNOTATIONS_SET_TIMEZONE = 'TIMESERIES_DETAILS_ANNOTATIONS_SET_TIMEZONE';
export const timeSeriesDetailsAnnotationsSetTimeZone = createAction(TIMESERIES_DETAILS_ANNOTATIONS_SET_TIMEZONE, 'data');

export const TIMESERIES_DETAILS_ANNOTATIONS_FILTER_UPDATED = 'TIMESERIES_DETAILS_ANNOTATIONS_FILTER_UPDATED';
export const timeSeriesDetailsAnnotationsFilterUpdated = createAction(TIMESERIES_DETAILS_ANNOTATIONS_FILTER_UPDATED, 'data');

export const TIMESERIES_DETAILS_ANNOTATIONS_APPLY_FILTER = 'TIMESERIES_DETAILS_ANNOTATIONS_APPLY_FILTER';
export const timeSeriesDetailsAnnotationsApplyFilter = createAction(TIMESERIES_DETAILS_ANNOTATIONS_APPLY_FILTER);

export const TIMESERIES_DETAILS_ANNOTATIONS_APPLY_SORT = 'TIMESERIES_DETAILS_ANNOTATIONS_APPLY_SORT';
export const timeSeriesDetailsAnnotationsApplySort = createAction(TIMESERIES_DETAILS_ANNOTATIONS_APPLY_SORT, 'key');

export const TIMESERIES_DETAILS_ANNOTATION_SET_KEY = 'TIMESERIES_DETAILS_ANNOTATION_SET_KEY';
export const timeSeriesDetailsAnnotationSetKey = createAction(TIMESERIES_DETAILS_ANNOTATION_SET_KEY, 'data');

export const TIMESERIES_DETAILS_ANNOTATION_SET_FROMDATE = 'TIMESERIES_DETAILS_ANNOTATION_SET_FROMDATE';
export const timeSeriesDetailsAnnotationSetFromDate = createAction(TIMESERIES_DETAILS_ANNOTATION_SET_FROMDATE, 'data');

export const TIMESERIES_DETAILS_ANNOTATION_SET_TODATE = 'TIMESERIES_DETAILS_ANNOTATION_SET_TODATE';
export const timeSeriesDetailsAnnotationSetToDate = createAction(TIMESERIES_DETAILS_ANNOTATION_SET_TODATE, 'data');

export const TIMESERIES_DETAILS_ANNOTATION_SET_TEXT = 'TIMESERIES_DETAILS_ANNOTATION_SET_TEXT';
export const timeSeriesDetailsAnnotationSetText = createAction(TIMESERIES_DETAILS_ANNOTATION_SET_TEXT, 'data');

export const TIMESERIES_DETAILS_ANNOTATION_SET_PRIORITY = 'TIMESERIES_DETAILS_ANNOTATION_SET_PRIORITY';
export const timeSeriesDetailsAnnotationSetPriority = createAction(TIMESERIES_DETAILS_ANNOTATION_SET_PRIORITY, 'data');