import React, { useState, useEffect } from 'react';
import RequiredField from '../../../shared/RequiredField';
import InfoPopup from '../../../shared/InfoPopup';
import CollapsePanel from '../../../shared/CollapsePanel';
import ConfirmButton from '../../../shared/ConfirmButton';
import { Combobox } from 'react-widgets';
import { TimeSeriesSearch2 } from './../../../shared/TimeSeriesSearch2';
import { ModalDialogButton } from '../../../shared/ModalDialogButton';

const bigLimit = 200;

const inputStyle = {
  fontSize: '12px'
};

export default function CategorySettings({ referenceData, lookupData, timeSeries,
  inputFocusId, setInputFocus, setInputHasFocus,
  addCategory, updateCategoryValue, deleteCategory, deleteAllcategories, copyCategoriesCopyFrom, getCategoryValues }) {
  const { categoriesByDataType } = referenceData;
  const { categories = {}, dataType } = timeSeries;

  const categoriesList = categories ? Object.keys(categories).map(k => {
    if (categoriesByDataType && dataType) {
      const cat = categoriesByDataType[dataType.toLowerCase()].find(c => c.name === k);
      return {
        mandatory: cat ? cat.mandatory : false,
        name: k,
        value: categories[k]
      };
    } else {
      return {
        mandatory: false,
        name: k,
        value: categories[k]
      }
    }
  }).sort((a, b) => {
    let result = 0;
    if (a.name < b.name) {
      result = -1;
    } else if (a.name > b.name) {
      result = 1;
    }

    if (a.mandatory & !b.mandatory) {
      result = -1;
    } else if (!a.mandatory & b.mandatory) {
      result = 1;
    }

    return result;
  }) : [];

  const hasCategories = categoriesList.length > 0;
  const {
    categories: categoriesLookupData = [],
    categoryValues = {}
  } = lookupData;

  function copyCategoriesActions({closeDialog}) {
    return [
      {
        text: 'Close',
        className: 'btn-secondary',
        func: () => closeDialog()
      },
      {
        text: 'Copy Categories', func: ({ selectedIds }) => {
          if (selectedIds.length === 1) {
            copyCategoriesCopyFrom();
            closeDialog();
          }
        }
      }];
  }

  function onDeleteAllCategories() {
    deleteAllcategories();
  }

  function onAddCategory(name) {
    addCategory({ name });
    setInputFocus(`category-list-${name}`);
  }

  function onDeleteCategory(name) {
    deleteCategory(name);
  }

  function onUpdateCategoryValue(name, value) {
    updateCategoryValue({ name, value });
  }

  function onGetcategoryValues(name) {
    getCategoryValues(name);
  }

  return (
    <CollapsePanel idKey='timeSeriesEditorCategorySettings' headerStyle={{ cursor: 'pointer' }}
      title={<><span>Categories</span><InfoPopup>All blank categories will be removed on save</InfoPopup></>} >
      <div className='row no-gutters' >
        <div className='input-group'>
          <select className='form-control' value='' style={inputStyle} onChange={e => onAddCategory(e.target.value)}>
            <option value='' disabled>Select category</option>
            {categoriesLookupData.map(k => <option key={k.key}>{k.key}</option>)}
          </select>

          <div className='input-group-append'>
            <ModalDialogButton size='xl' buttonStyles={inputStyle} buttonClassName='btn btn-secondary mr-1' buttonContent={<i className='fas fa-search fa-fw' />}>
              {({ closeDialog }) => <TimeSeriesSearch2 title='Copy Categories' selectionStyle='single' stateKey={'copyCategoriesSearch'} intitialSelection={[]} actions={copyCategoriesActions({closeDialog})} closeDialog={closeDialog}/>} 
            </ModalDialogButton>
            {hasCategories && <ConfirmButton type='button' className='btn btn-secondary' style={inputStyle} confirmClassName='btn btn-outline-warning' onClick={onDeleteAllCategories}
              content={
                <span style={{ whiteSpace: 'nowrap',fontSize: '12px' }}><i className='fas fa-trash fa-fw' />&nbsp;Delete All</span>
              }
              confirm={
                <span style={{ whiteSpace: 'nowrap',fontSize: '12px' }}><i className='fas fa-trash fa-fw' />&nbsp;Delete All?</span>
              }>
            </ConfirmButton>}
          </div>
        </div>
      </div>

      <table className='col-12' style={{ marginBottom: '300px' }}>
        <tbody>
          {categoriesList.map(category => <tr key={category.name}>
            <td className='w-10' style={inputStyle}>{category.name} {category.mandatory && <RequiredField />} </td>
            <td className='w-80'>
              <ComboBoxWithDataLoad
                value={category.value}
                onDataRequested={() => onGetcategoryValues(category.name)}
                requestedData={categoryValues[category.name]}
                onChange={value => onUpdateCategoryValue(category.name, value)}
                inputFocusId={inputFocusId}
                focusId={`category-list-${category.name}`}
                setInputHasFocus={setInputHasFocus}
                setInputFocus={setInputFocus}
                style={inputStyle}/>
            </td>
            <td className='w-10'>
              {!category.mandatory && <button type='button' className='btn btn-sm' onClick={() => onDeleteCategory(category.name)}>
                  <i className='fas fa-trash fa-fw' />
              </button>}
            </td>
          </tr>)}

          {(!categoriesList || categoriesList.length === 0) && <tr><td colSpan='3' style={inputStyle}>No categories have been added</td></tr>}
        </tbody>
      </table>
    </CollapsePanel>
  );
}

function ComboBoxWithDataLoad({ className, style, value, onChange, onDataRequested, requestedData, onDataRequestingComplete, returnFocusId, inputFocusId, focusId, setInputFocus, setInputHasFocus }) {
  const [dataList, setDataList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [moreMessage, setMoreMessage] = useState('');

  function onLoadData(){
    if (!onDataRequested) return;
    setIsLoading(true);
    onDataRequested();
  }

  function onValueSelected(value) {
    onChange(value);
  }

  function onValueChanged(value) {
    onChange(value);
    onLoadData();
  }

  useEffect(() => {
    if (requestedData) {
      const text = (typeof value === 'string' ? value : value || '').trim().toLowerCase();

      let moreMessage;
      let results = text.length > 0
        ? requestedData.filter(i => i.toLowerCase().indexOf(text) >= 0)
        : [...requestedData];
  
      if (results.length < bigLimit) moreMessage = `${results.length} of ${requestedData.length} items`;
      else {
        moreMessage = `Top ${bigLimit} filtered of ${requestedData.length} items`;
  
        results = results.slice(0, bigLimit);
      }
  
      setDataList(results);
      setMoreMessage(moreMessage);
      setIsLoading(false);
      if (onDataRequestingComplete)
        onDataRequestingComplete();
    }
  }, [value, onDataRequestingComplete, requestedData]);

  function onInputKeyPress(e) {
    const keyCode = e.charCode || e.keyCode || 0;
    if (keyCode === 13 && returnFocusId) {
      setInputFocus(returnFocusId);
    }
  }
  
  function onInputFocus(){
    if (focusId && inputFocusId === focusId && !dataList && !isLoading){
      setInputHasFocus();
      onLoadData();
    }
  }
  
  function onToggle(isOpen){
    if (isOpen && !dataList && !isLoading) onLoadData();
  }
  
  let shouldFocus = focusId && inputFocusId === focusId ? true : false;

  return <>
      <Combobox title={value} autoFocus={shouldFocus} suggest={false} caseSensitive={false} autoComplete={false}
        inputProps={{
          onFocus: onInputFocus,
          onKeyUp: onInputKeyPress
        }}
        messages={{
          emptyList: isLoading ? 'Searching for items...' : 'There are no items in this list'
        }}
        busy={isLoading}
        data={dataList || []}
        value={value}
        className={className}
        style={style}
        onChange={onValueChanged}
        onSelect={onValueSelected}
        onToggle={onToggle}
        groupComponent={() => !!moreMessage && <span>{moreMessage}</span>}
        groupBy={() => ''} />      
    </>
};