import React, { useRef, useCallback, useState } from 'react';
import deepmerge from 'deepmerge';
import moment from 'moment';
import { toJS } from '../../../utility/immutable-utility';
import { HighchartsInfoProvider } from '../../../utility/highcharts-utility';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import ErrorBoundary from '../../shared/ErrorBoundary';

if (window.Highcharts) window.Highcharts.Point.prototype.highlight = function (e) {
  e = this.series.chart.pointer.normalize(e);
  this.onMouseOver();
  this.series.chart.tooltip.refresh(this);
  this.series.chart.xAxis[0].drawCrosshair(e, this);
};

function Chart({ reflowSwitch, reflowSwitchDelay, chartSeries, comparisonMode, lens,
  title, titleSize, hideLegend, syncCharts, toggleChartSeriesVisibility, chartSettings, chartCategories, hasXAxis,
  chartStartDate, chartEndDate }) {

  const [chart, setChart] = useState(null);
  const chartRef = useRef();

  const [currentCriteria, updateCurrentCriteria] = useState({
    comparisonMode,
    hasXAxis
  });

  const onSyncCharts = useCallback((e) => {
    for (let i = window.Highcharts.charts.length - 1; i >= 0; i--) {
      let otherChart = window.Highcharts.charts[i];
      if (!otherChart || chart === otherChart) continue;

      let event = chart.pointer.normalize(e);
      let point = otherChart.series[0].searchPoint(event, true);

      if (point && point.highlight) point.highlight(event);
    }
  }, [chart]);

  const createChart = useCallback((forceCreate, options) => {
    if (chart && !forceCreate)
      return chart;

    if (chart && forceCreate)
      chart.destroy();

    const chartsInfoProvider = new HighchartsInfoProvider({ comparisonMode, lens, hasXAxis, chartStartDate, chartEndDate, customFormat : options.xAxis && options.xAxis.customFormat });

    const defaultOptions = {
      chart: {
        animation: false,
        zoomType: 'xy'
      },
      boost: {
        seriesThreshold: 100 // (Override default 50) Series limit to trigger boost mode (stacking area time series does not render properly)
      },
      title: {
        text: title || 'Time series',
        style: {
          fontSize: titleSize || '14px'
        },
        margin: 0,
        floating: false
      },
      tooltip: {
        xDateFormat: chartsInfoProvider.getTooltipDateFormatter()
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0,
        type: chartsInfoProvider.getAxisStyle(),
        labels: {
          formatter: chartsInfoProvider.getUniqueAxisFormatter()
        },
        categories: hasXAxis === false ? chartCategories : undefined,
        crosshair: true,
        events: {
          setExtremes: function syncExtremes(e) {
            if (e.trigger === 'syncExtremes') return;

            let thisChart = this.chart;

            for (let i = window.Highcharts.charts.length - 1; i >= 0; i--) {
              let chart = window.Highcharts.charts[i];

              if (chart && chart !== thisChart && chart.xAxis[0].setExtremes)
                chart.xAxis[0].setExtremes(e.min, e.max, undefined, true, {
                  trigger: 'syncExtremes'
                });
            };
          }
        }
      },
      yAxis: [{
        title: {
          text: undefined
        }
      }],
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          }
        },
        series: {
          events: {
            legendItemClick() {
              if (typeof toggleChartSeriesVisibility === 'function') {
                toggleChartSeriesVisibility(this.options.id);

                return false;
              }
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        align: 'left',
        maxHeight: 100,
        itemStyle: {
          fontSize: '12px'
        },
        enabled: !hideLegend
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['downloadCSV', 'downloadXLS', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG', 'downloadPDF']
          }
        },
        csv: {
          columnHeaderFormatter(item, key, keyLength) {
            const { userOptions } = item;
            const { isX, id, name } = userOptions;

            return isX ? false : `${id}: ${name}`;
          }
        },
        fallbackToExportServer: false
      }
    };

    defaultOptions.tooltip.formatter = hasXAxis === true ? function (tooltip) {
      const [, , rawData] = this.series.options.data[this.point.index];
      if (rawData) {
        const dateText = rawData.dateTime ? moment.utc(rawData.dateTime).format('dddd, MMM DD, YYYY, HH:mm') : rawData.bucket;
        return `<span style="font-size: 10px">${dateText}</span><br/>` +
          `<span style="color: ${this.series.color}">● </span>${this.series.name}<br/>` +
          `x: <b>${this.x}</b><br/>` +
          `y: <b>${this.y}</b><br/>`;
      }

      // If not null, use the default formatter
      return tooltip.defaultFormatter.call(this, tooltip);
    } : undefined;

    const mergedOptions = deepmerge.all([defaultOptions, options], {
      arrayMerge: (_target, source, _options) => source
    });
    
    return window.Highcharts.chart(chartRef.current, { ...mergedOptions, series: chartSeries });
  }, [chartSeries, chartCategories, chart, hideLegend, title, titleSize, toggleChartSeriesVisibility, comparisonMode, lens, hasXAxis, chartStartDate, chartEndDate]);

  useDidUpdateEffect(() => {
    if (!chartSettings)
      return;
    let chart = createChart(true, toJS(chartSettings, {}));
    setChart(chart);
  }, [currentCriteria])
  
  useDidUpdateEffect(() => {
    if (comparisonMode !== currentCriteria.comparisonMode || 
        hasXAxis !== currentCriteria.hasXAxis) {
      updateCurrentCriteria({
        comparisonMode,
        hasXAxis
      })
    }
  }, [comparisonMode, hasXAxis]);

  useDidUpdateEffect(() => {
    if (chart && chart.series) {
      let cs = chart.series.slice();
      let csIds = cs.map(cs => cs.options.id);

      cs.forEach(s => {
        const sId = s.options.id;
        const series = chartSeries.find(cs => cs.id === sId);

        if (series)
          s.update(series, true);
        else
          s.remove(false);
      });

      chartSeries.filter(s => !csIds.some(sId => sId === s.id)).forEach(s => {
        chart.addSeries(s, false);
      });
    }
  }, [chart, chartSeries])

  useDidUpdateEffect(() => {
    if (chart) chart.redraw(true);
  }, [chart, chartSeries, chartSettings])

  useDidUpdateEffect(() => {
    if (chart && chart.reflow) chart.reflow();
  }, [chart, reflowSwitch])

  useDidUpdateEffect(() => {
    if (chart) setTimeout(() => {
      try { if (chart && chart.reflow) chart.reflow(); } catch { }
    }, 250);
  }, [chart, reflowSwitchDelay])

  useDidUpdateEffect(() => {
    let chart = createChart(true, toJS(chartSettings, {}));
    setChart(chart);
  }, [chartSettings])

  useDidMountEffect(() => {
    let chart = createChart(true, toJS(chartSettings, {}));
    let containerId = chartRef.current.firstChild.id;

    setChart(chart);

    return () => {
      try { chart.destroy(); }
      catch {
        chart = window.Highcharts.charts.find(i => i && i.container.id === containerId);
        if (chart && chart.destroy) chart.destroy();
      }
    };
  });

  return (
    <div ref={chartRef} style={{ width: '100%', height: '100%', zIndex: 0 }}
         onMouseMove={syncCharts ? onSyncCharts : undefined} />
  );
};

export default function AnalysisChart(props) {
  const { lens, comparisonMode, chartSeries, chartSettings, chartCategories, isEvolution, hasXAxis } = props;
  return (
    <ErrorBoundary className='w-100 px-2' resetProps={[chartSeries, chartSettings, lens, comparisonMode, isEvolution, chartCategories, hasXAxis]}>
      <Chart {...props} />
    </ErrorBoundary>
  )
}