import React from 'react';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import Loading from '../../../shared/Loading';
import Pagination from '../../../shared/Pagination';
import InstanceDataPointTable from './InstanceDataPointTable';
import InstanceTable from './InstanceTable';
import InstanceToolbar from './InstanceToolbar';
import InstanceDataPointToolbar from './InstanceDataPointToolbar';
import InstancePromoteDialog from './instancePromoteDialog';

export default function InstanceViewer({ identityId, instanceRows, instanceDataPointRows, instanceTotal, isWizard, isLoading, fromUtc, toUtc, dataPointUtc,
                                         page, pageSize, selectedInstance, instancePromoteDialogVisible, instancePromoteAsAtUtc,
                                         addToBasketFunction, archiveInstanceFunction, openInstancePromoteDialogFunction, closeInstancePromoteDialogFunction, loadInstancesFunction, refreshFunction, 
                                         savePromotedInstanceFunction, setPromotedAsAtUtcFunction, setToolbarPropertyFunction, toggleSelectionFunction, updatePageFunction }) {
  useDidMountEffect(() => loadInstancesFunction(identityId));
  return (
    <Loading isLoading={ isLoading } message='Loading...'>
      <div style={{ display:'flex', flexDirection:'row', height:'100%'}} >
        <div style={{ display:'flex', flexDirection:'column'}} >
          <div style={{ minHeight: '55px'}}>
            <InstanceToolbar fromUtc={fromUtc}
                             toUtc={toUtc}
                             dataPointUtc={dataPointUtc}
                             refreshFunction={refreshFunction}
                             setToolbarPropertyFunction={setToolbarPropertyFunction} />
          </div>
          <div style={{ overflowY:'auto', flexGrow:'1' }} >
            <InstanceTable instanceRows={instanceRows} toggleSelectionFunction={toggleSelectionFunction} />
          </div>
          <div style={{ marginTop: '10px', minHeight: '50px'}}>
            <Pagination currentPage={ page } 
                        steps={ 1 } 
                        pageSize={ pageSize } 
                        itemCount={ instanceTotal }
                        onPageChange={ updatePageFunction } 
                        hidePageSize={true} />
          </div>                    
        </div>
        <div style={{ marginLeft: '5px', display:'flex', flexDirection:'column'}} >
          <div style={{ minHeight: '55px'}}>
            <InstanceDataPointToolbar isWizard={isWizard} 
                                      selectedInstance={selectedInstance} 
                                      openInstancePromoteDialogFunction={openInstancePromoteDialogFunction} 
                                      addToBasketFunction={addToBasketFunction}
                                      archiveInstanceFunction={archiveInstanceFunction} />
          </div>
          <div style={{ overflowY:'auto', flexGrow:'1' }} >
            <InstanceDataPointTable rows={instanceDataPointRows} />
          </div>
          <div style={{ marginTop: '10px', minHeight: '50px'}} />
        </div>
        <InstancePromoteDialog dialogVisible={instancePromoteDialogVisible} 
                              promotedAsAtUtc={instancePromoteAsAtUtc}
                              closeDialogFunction={closeInstancePromoteDialogFunction}
                              saveFunction={savePromotedInstanceFunction}
                              setAsAtUtcFunction={setPromotedAsAtUtcFunction} />
      </div>
    </Loading>
  );
}