export function mergePeriodCollectionLists(...lists) {
  let response = [];

  lists.forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(p => {
        if (p && p.name) {
          p.isEditable = p.isEditable ?? false;
          const existing = response.find(_ => _.name === p.name);
          if (!existing) {
            response.push(p);
          } else {
            Object.assign(existing, p);
          }
        }
      });
    }
  });

  return response;
}

export function areAnyPeriodsChanged(list1, list2){
  let response = false;

  list1.forEach(p1 => {
    const p2 = list2.find(p => p.name === p1.name);
    if (p2){
      const isMatch = 
        ((p1.isEditable ?? false) === (p2.isEditable ?? false)) &&
        ((p1.hideAbsRelToggle ?? false) === (p2.hideAbsRelToggle ?? false)) &&
        ((p1.syncToDate ?? false) === (p2.syncToDate ?? false)) &&      
        areRelDatesEqual(p1.relFromDate, p2.relFromDate) && areRelDatesEqual(p1.relToDate, p2.relToDate);

      if (!isMatch)
        response = true;
    }
  });

  return response;

  function areRelDatesEqual(d1, d2){
    if (!d1 && !d2) return true;
    if (d1 && !d2) return false;
    if (!d1 && d2) return false;
    return d1.period === d2.period && d1.offset === d2.offset;
  }
}

export function getPeriods(state) {
  const userSettingPeriods = state.getIn(['analysis', 'ui', 'periodsUserSettings']);
  const referenceDataPeriods = state.getIn(['analysis', 'ui', 'periodsReferenceData']);
  const workspacePeriods = state.getIn(['analysis', 'workspace', 'periods']);

  return mergePeriodCollectionLists(
    referenceDataPeriods ? referenceDataPeriods.toJS() : undefined,
    userSettingPeriods ? userSettingPeriods.toJS() : undefined,
    workspacePeriods ? workspacePeriods.toJS() : undefined,
  );
}

export function updateAbsValues(target, source) {
  if (source) {
    target.from = {
      mode:'abs',
      abs : source.absFromDate
    };
    target.to= {
      mode:'abs',
      abs : source.absToDate
    }
  }

  return target;
}

export function ofPeriodTypes(periods){
  return periods.filter(p => p.hasOwnProperty('name') && p.hasOwnProperty('relFromDate') && p.hasOwnProperty('relToDate'));
}