import { fromJS } from 'immutable'
import {
  TIMESERIES_DETAILS_COMPOSITION_LOAD_STARTED,
  TIMESERIES_DETAILS_COMPOSITION_LOAD_COMPLETE
} from '../actions/timeSeriesDetails-composition';

export const timeSeriesDetailsCompositionReducer = {
  [TIMESERIES_DETAILS_COMPOSITION_LOAD_STARTED](state, action) {
    return state;
  },
  [TIMESERIES_DETAILS_COMPOSITION_LOAD_COMPLETE](state, action) {
    return state.setIn(['composition'], fromJS(action.data));
  }
};