import { createReducer } from '../utility/redux-utility';
import {
  ADD_SCHEMAS_COMPLETE,
  COPY_SCHEMAS_COMPLETE,
  EDIT_SCHEMA_CATEGORY,
  LOAD_SCHEMAS_COMPLETE,
  LOAD_SCHEMA_LOOKUP_COMPLETE,
  SAVE_LOOKUP_COMPLETE,
  SCHEMAS_LOADING,
  SET_COPY_FROM_IDENTITY_ID,
  SET_LOOKUP_VALUE,
  SET_SELECTED_LOOKUP,
  SET_SELECTED_SCHEMA,
  START_SCHEMA_EDIT,
  STOP_SCHEMA_EDIT
} from '../actions/schemas';
import { fromJS } from 'immutable';

export const schemas = createReducer(null, {
  [ADD_SCHEMAS_COMPLETE](state, action) {
    const availableSchemas = state.get('availableSchemas').toJS();
    const filtered = availableSchemas.filter(x => x.name !== action.schemaName);
    const identitySchemas = state.get('identitySchemas').toJS();    
    return state.set('availableSchemas', fromJS(filtered))
                .set('identitySchemas', fromJS([action.data].concat(identitySchemas)))
                .set('schemaInEdit', action.schemaName)                
                .set('selectedSchema', '');
  },
  [COPY_SCHEMAS_COMPLETE](state, action) {
    const schemaName = state.get('schemaInEdit');
    let identitySchemas = state.get('identitySchemas').toJS();
    let match = identitySchemas.find(x => x.searchSchema.name === schemaName);
    match.searchSchemaCategoryValues = action.data.searchSchemaCategoryValues;
    return state.set('isLoading', false)
                .set('identitySchemas', fromJS(identitySchemas));
  },
  [EDIT_SCHEMA_CATEGORY](state, action) {
    let identitySchemas = state.get('identitySchemas').toJS();
    let match = identitySchemas.find(x => x.searchSchema.name === action.schemaName);
    if (match) {
      let categoryValue = match.searchSchemaCategoryValues.find(x => x.category === action.category);
      if (categoryValue)
        categoryValue.value = action.value;
    }
    return state.set('identitySchemas', fromJS(identitySchemas));
  },
  [LOAD_SCHEMAS_COMPLETE](state, action) {
    const availableSchemas = [{ name: '' }].concat(action.data.availableSchemas);
    return state.set('isLoading', false)
                .set('availableSchemas', fromJS(availableSchemas))
                .set('identitySchemas', fromJS(action.data.schemaCategoryData));
  },
  [LOAD_SCHEMA_LOOKUP_COMPLETE](state, action) {
    const allSchemas = action.data.schemas ?? [];
    allSchemas.sort(function(a, b){
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    return state.set('allSchemasInitialised', true)
                .set('allSchemas', fromJS(allSchemas))
                .set('allSchemaNames', [''].concat(allSchemas.map(x => x.name)))
                .set('allSchemaLookups', fromJS(action.data.lookupsBySchema));
  },
  [SAVE_LOOKUP_COMPLETE](state, action) {
    return state.set('isLoading', false)
                .set('lookupValue', '')
                .set('selectedLookup', '');
  },
  [SCHEMAS_LOADING](state, action) {
    return state.set('isLoading', action.isLoading);
  },
  [SET_COPY_FROM_IDENTITY_ID](state, action) {
    return state.set('copyFromIdentityId', action.id);
  },
  [SET_LOOKUP_VALUE](state, action) {
    return state.set('lookupValue', action.lookupValue);
  },
  [SET_SELECTED_LOOKUP](state, action) {
    return state.set('selectedLookup', action.selectedLookup);
  },
  [SET_SELECTED_SCHEMA](state, action) {
    return state.set('selectedSchema', action.schemaName);
  },
  [START_SCHEMA_EDIT](state, action) {
    return state.set('schemaInEdit', fromJS(action.schemaName));
  },
  [STOP_SCHEMA_EDIT](state, action) {
    return state.set('schemaInEdit', fromJS(''));
  },
});