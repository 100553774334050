import React, { useState, useEffect } from 'react';
import { ClickAwayListener, Popover } from '@mui/material';

export function MultiselectDropdown({ className = 'multiselect', data = [], dataKey, textField, value = [], onChange, style, showRemoveAll = false, immediateSelection = false }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [localValue, setLocalValue] = useState(value);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setLocalValue(value);
  }, [setLocalValue, value]);

  function onOpenPopover(e) {
    setAnchorEl(e.currentTarget);
  }

  function onClickAway() {
    if (isDirty && anchorEl === null) {
      setIsDirty(false);
      onChange(localValue.map(getValue).filter(x => x));
    }
  }

  function onPopoverClosed() {
    setAnchorEl(null);

    if (isDirty) {
      setIsDirty(false);
      onChange(localValue.map(getValue).filter(x => x));
    }
  }

  function isMatch(xValue, yValue) {
    const xV = typeof (xValue) === 'string' ? xValue : xValue[dataKey];
    const yV = typeof (yValue) === 'string' ? yValue : yValue[dataKey];
    return xV === yV;
  }

  function getValue(dataKeyValue) {
    if (typeof (dataKeyValue) === 'string') {
      return data ? data.find(d => d[dataKey] === dataKeyValue) : undefined;
    }

    return dataKeyValue;
  }

  function isListOptionSelected(value) {
    return localValue.exists(x => isMatch(x, value));
  }

  function onTagClicked(value) {
    const newLocalValue = localValue.filter(x => !isMatch(x, value)).sortBy(x => x[dataKey]);
    setLocalValue(newLocalValue);
    if (immediateSelection) {
      onChange(newLocalValue.map(getValue).filter(x => x));
    } else {
      setIsDirty(true);
    }
  }

  function onRemoveAllClicked() {
    setIsDirty(false);
    onChange([]);
  }

  function onOptionClicked(value) {
    let newLocalValue = [];
    if (localValue.exists(x => isMatch(x, value))) {
      newLocalValue = localValue.filter(x => !isMatch(x, value));
    } else {
      newLocalValue = [...localValue, value];
    }

    newLocalValue = newLocalValue.sortBy(x => x[dataKey]);

    setLocalValue(newLocalValue);
    if (immediateSelection) {
      onChange(newLocalValue.map(getValue).filter(x => x));
    } else {
      setIsDirty(true);
    }
  }

  style = {
    position: 'relative',
    minWidth: '200px',
    maxWidth: '300px',
    overflowY: 'auto',
    height: 'unset',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    ...(style ?? {})
  };

  var tooltipText = localValue ? localValue.map(v => typeof (v) === 'string' ? v : v[textField]) : '';
  return <>
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={`${className ?? ''}`} >
        <div className='input-group'>
          <div className='form-control' title={tooltipText} style={style} onClick={onOpenPopover}>
            <div style={{ minHeight: '32px', paddingRight: '35px', border: isDirty ? 'rgba(0, 100, 200, 0.5) 1px solid' : 'transparent 1px solid' }}>
              {localValue && localValue.map(d => ({ value: getValue(d) })).filter(x => x.value).map(({ value }) => <Tag key={`tag-${value[dataKey]}`} value={value} textField={textField} onTagClicked={onTagClicked} />)}
              <div style={{ position: 'absolute', top: '5px', right: '7px' }}><i className='fas fa-caret-down' /></div>
            </div>
          </div>
          {showRemoveAll && localValue && localValue.length > 0 && <div className='input-group-append'><button className='btn btn-outline-secondary btn-sm pt-0 pb-0' style={{borderColor:'#ced4da'}} type='button' onClick={onRemoveAllClicked}>
            <i className='fas fa-times ml-1 mr-1'></i>
          </button></div>}
        </div>
      </div>
    </ClickAwayListener>
    <Popover anchorEl={anchorEl} open={!!anchorEl} onClose={onPopoverClosed}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
      <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
        {!data || data.length === 0 ? <div className='rw-list-option' >&nbsp;</div> : data.map(d => <Option key={`opt-${d[dataKey]}`} value={d} textField={textField} onOptionClicked={onOptionClicked} isListOptionSelected={isListOptionSelected} />)}
      </div>
    </Popover>
  </>
}

function Option({ value, textField, onOptionClicked, isListOptionSelected }) {
  return <div onClick={() => onOptionClicked(value)} className={`rw-list-option ${isListOptionSelected(value) ? 'rw-state-selected' : ''}`}>{value[textField]}</div>
}

function Tag({ value, textField, onTagClicked }) {
  function onClick(e) {
    e.preventDefault();
    e.stopPropagation();
    onTagClicked(value);
  }

  return <div title={value[textField]} style={{ padding: '2px 6px' }} className='rw-multiselect-tag'>
    {value[textField]}
    <button type='button' className='rw-multiselect-tag-btn btn-xs' onClick={onClick}>
      <i className='fas fa-times fa-fw' />
    </button>
  </div>
}