import React from 'react';
import moment from 'moment';
import LensedDateTime from '../../shared/LensedDateTime';
import CalendarYearPicker from './CalendarYearPicker';
import CalendarMonthPicker from './CalendarMonthPicker';
import CalendarDayPicker from './CalendarDayPicker';
import { comparisonModeLensedDateOptions, comparisonModes } from '../../../utility/comparisonmode-utility';
import { gasSeasonNames, quarterNames } from '../../../utility/date-utility';

export function IndividualAbsoluteDatesSelector({ comparisonMode, comparisonSettings, setComparisonModeSetRange, togglePreselection, addPreselectionToSelection, toggleColumn, toggleRow, clearPreselection, setDayActiveDate })
{
  const onRangeStartChanged = (e) => {
    setComparisonModeSetRange(comparisonMode, e, comparisonSettings.rangeEnd);
  }

  const onRangeEndChanged = (e) => {
    setComparisonModeSetRange(comparisonMode, comparisonSettings.rangeStart, e);
  }

  const onTogglePreselection = (y) => {
    togglePreselection(comparisonMode, y);
  }

  const onAddPreselectionToSelection = () => {
    addPreselectionToSelection(comparisonMode);
  }

  const onToggleColumn = (column) => {
    toggleColumn(comparisonMode, column);
  }

  const onToggleRow = (row) => {
    toggleRow(comparisonMode, row);
  }

  const onClearPreselection = () => {
    clearPreselection(comparisonMode);
  }

  const onSetDayActiveDate = (date) => {
    if (date)
      setDayActiveDate(comparisonMode, moment().year(date.year).month(date.month).date(1).format());
    else
      setDayActiveDate(comparisonMode, undefined);
  }

  return <div>
    <div className='form-group'>
      <div className='input-group' >
        <label className='fota-input-group-label font-weight-bold'>Range</label>
        <LensedDateTime lens={comparisonModeLensedDateOptions[comparisonMode]} dateTimeClassName='form-control mr-2' placeholder='Start date' value={moment.utc(comparisonSettings.rangeStart).format('YYYY-MM-DD')} onChange={e => onRangeStartChanged(e)} />
        <LensedDateTime lens={comparisonModeLensedDateOptions[comparisonMode]} dateTimeClassName='form-control' placeholder='End date' value={moment.utc(comparisonSettings.rangeEnd).format('YYYY-MM-DD')} onChange={e => onRangeEndChanged(e)} />
      </div>
    </div>

    <div className='form-group' >
      <label className='font-weight-bold fota-input-group-label'>Dates</label>
      {(comparisonMode === comparisonModes.yearOnYear ||
        comparisonMode === comparisonModes.gasYear) &&
        <CalendarYearPicker
          dates={comparisonSettings.view.individualAbs.options.dates}
          topLeftClick={onClearPreselection}
          columnClick={onToggleColumn}
          cellClick={onTogglePreselection} />}

      {(comparisonMode === comparisonModes.gasSeason) && 
        <CalendarMonthPicker
          range={comparisonSettings.view.individualAbs.options}
          topLeftClick={onClearPreselection}
          columnClick={onToggleColumn}
          cellClick={onTogglePreselection}
          columnHeaderStyle='spreadsheet-cursor-top'
          headers={gasSeasonNames}
        />}

      {(comparisonMode === comparisonModes.quarter) && 
        <CalendarMonthPicker
          range={comparisonSettings.view.individualAbs.options}
          topLeftClick={onClearPreselection}
          columnClick={onToggleColumn}
          cellClick={onTogglePreselection}
          columnHeaderStyle='spreadsheet-cursor-top'
          headers={quarterNames}
        />}

      {comparisonMode === comparisonModes.monthOnMonth && 
        <CalendarMonthPicker
          range={comparisonSettings.view.individualAbs.options}
          topLeftClick={onClearPreselection}
          columnClick={onToggleColumn}
          rowClick={onToggleRow}
          cellClick={onTogglePreselection}
          columnHeaderStyle='spreadsheet-cursor-top'
          rowHeaderStyle='spreadsheet-cursor-left'
        />}

      {comparisonMode === comparisonModes.dayOnDay && comparisonSettings.view.individualAbs && !comparisonSettings.view.individualAbs.activeDate &&
        <CalendarMonthPicker
          range={comparisonSettings.view.individualAbs.yearView}
          topLeftClick={onClearPreselection}
          cellClick={onSetDayActiveDate}
          cellStyle='zoom-cursor' />}

      {comparisonMode === comparisonModes.dayOnDay && comparisonSettings.view.individualAbs && comparisonSettings.view.individualAbs.options &&
        <>
          <div>
            <label className='font-weight-bold'>{comparisonSettings.view.individualAbs.options.dateText}</label>
            <button type='button' className='close text-right' onClick={e => onSetDayActiveDate(undefined)}>&times;</button>
          </div>

          <CalendarDayPicker
            range={comparisonSettings.view.individualAbs.options}
            topLeftClick={onClearPreselection}
            columnClick={onToggleColumn}
            rowClick={onToggleRow}
            cellClick={onTogglePreselection}
            columnHeaderStyle='spreadsheet-cursor-top'
            rowHeaderStyle='spreadsheet-cursor-left' />
        </>}
    </div>

    {(comparisonMode !== comparisonModes.dayOnDay || 
      (comparisonSettings.view.individualAbs && comparisonSettings.view.individualAbs.options)) && (
      <div>
        <div className='form-group text-right'>
          <button type='button' className='btn btn-primary' onClick={() => onAddPreselectionToSelection()}>Add Selected</button>
        </div>
        {comparisonSettings.view.individualAbs.showAddPreselectionErrorMessage && <div className="alert alert-warning" >Please select 1 or more dates</div>}
      </div>)}
  </div>
}