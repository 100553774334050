import React, { Component, Fragment } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      stack: '',
      hasError: false,
      viewStackTrace: false
    };
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidCatch(error, _errorInfo) {
    this.setState({
      message: error.message || '',
      stack: error.stack || ''
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.resetProps) return;

    if (this.props.resetProps.some((prop, ix) => prevProps.resetProps[ix] !== prop))
      this.setState({ message: '', stack: '', hasError: false, viewStackTrace: false });
  }

  render() {
    return this.state.hasError
      ? (
        <div className={ this.props.className }>
          <div className='alert alert-danger text-center'><strong>Error:</strong> { this.state.message }</div>
          { this.state.stack && this.state.stack.length && (
            <Fragment>
              <button className='btn btn-link text-decoration-none' onClick={ () => this.setState({ viewStackTrace: !this.state.viewStackTrace }) }>
                <i className={ `far ${(this.state.viewStackTrace ? 'fa-minus-square' : 'fa-plus-square')} fa-fw` }></i> Stack trace
              </button>
              <div className='row'>
                <div className='col'>
                  <div className={ `collapse ${(this.state.viewStackTrace ? 'show' : 'hide')}`}>
                    <div className='card card-body'>
                      <pre>{ this.state.stack }</pre>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ) }
        </div>
      ) : this.props.children;
  }
}

export default ErrorBoundary;