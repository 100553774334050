import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import {
  TIMESERIES_DETAILS_CATEGORIES_ADD,
  TIMESERIES_DETAILS_CATEGORIES_UPDATE,
  TIMESERIES_DETAILS_CATEGORIES_DELETE,
  TIMESERIES_DETAILS_CATEGORIES_DELETE_ALL,
  TIMESERIES_DETAILS_CATEGORIES_COPY_FROM_COMPLETE,
  TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_STARTED,
  TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_COMPLETE,
} from '../actions/timeSeriesDetails-categories';

export const timeSeriesDetailsCategoriesReducer = {
  [TIMESERIES_DETAILS_CATEGORIES_ADD](state, action) {
    if (!action.data)
      return state;

    const { name } = action.data;

    const categories = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'categories']), {});
    if (categories[name])
      return state;

    categories[name] = '';
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'categories'], fromJS(categories));
  },
  [TIMESERIES_DETAILS_CATEGORIES_UPDATE](state, action) {
    if (!action.data)
      return state;

    const { name, value } = action.data;
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'categories', name], value);
  },
  [TIMESERIES_DETAILS_CATEGORIES_DELETE](state, action) {
    if (!action.name)
      return state;

    const name = action.name;

    const categories = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'categories']), {});
    delete categories[name];
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'categories'], fromJS(categories));
  },
  [TIMESERIES_DETAILS_CATEGORIES_DELETE_ALL](state, action) {
    const timeSeries = toJS(state.getIn(['timeSeriesEditor', 'timeSeries']), {});
    let listOfMandatoryCategories = [];
    if (timeSeries.dataType === 'Price') {
      listOfMandatoryCategories = ['aggregation'];
    } else if (timeSeries.dataType === 'Fundamental') {
      listOfMandatoryCategories = ['commodity', 'location'];
    }

    const categories = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'categories']), {});
    Object.keys(categories).forEach(name => {
      if (listOfMandatoryCategories.indexOf(name) < 0)
        delete categories[name];
    });

    return state.setIn(['timeSeriesEditor', 'timeSeries', 'categories'], fromJS(categories));
  },
  [TIMESERIES_DETAILS_CATEGORIES_COPY_FROM_COMPLETE](state, action) {
    if (!action.data)
      return state;

    const categories = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'categories']), {});
    const selectedCategories = action.data;
    Object.keys(selectedCategories).forEach(k => {
      categories[k] = selectedCategories[k];
    });

    return state.setIn(['timeSeriesEditor', 'timeSeries', 'categories'], fromJS(categories));
  },
  [TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_STARTED](state, action) {
    return state;
  },
  [TIMESERIES_DETAILS_CATEGORIES_GET_CATEGORY_VALUES_COMPLETE](state, action) {
    if (!action.data)
      return state;

    const { name, values = [] } = action.data;
    return state.setIn(['timeSeriesEditor', 'lookupData', 'categoryValues', name], fromJS(values));
  }
}