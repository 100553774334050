import React from 'react';
import WorkspaceSelector from '../dashboard/WorkspaceSelector';
import TileCommonSettings from './TileCommonSettings';
import WorkspaceEditConnected from './WorkspaceEditConnected';
import { getFullPath } from '../../../utility/workspace-utility';
import { toJS } from '../../../utility/immutable-utility';
import { TabContainer } from '../../shared/TabContainer';

export default function DefinedReportTileOptions({ propertyBag, setProperty, workspace }) {
  return {
    View:  (args) => View(args),
    Edit: (args) => Edit(args),
    Settings: (args) => Settings(args),
  };
}

function onOpenWorkspace(workspace) {
  const _workspace = toJS(workspace, {});
  const { scope, folderPath, subFolderPath, name } = toJS(_workspace, {});
  const path = ['reports', scope, folderPath, subFolderPath, encodeURIComponent(name)].filter(s => s).join('/');
  window.open(`/${path}`, '_blank', 'noopener,noreferrer');
}

function View({ propertyBag, setProperty, workspace }) {
  return <>
    <button type='button' title='Open workspace' className='btn btn-sm' onClick={() => onOpenWorkspace(workspace)}><i className='fas fa-external-link-alt fa-fw' /></button>
  </>
}

function Edit({ propertyBag, setProperty, workspace }) {
  return <>
    <button type='button' title='Open workspace' className='btn btn-sm' onClick={() => onOpenWorkspace(workspace)}><i className='fas fa-external-link-alt fa-fw' /></button>
  </>
}

function Settings({ propertyBag, setProperty, settingsCallbacks, dashboardTileType }) {
  function onSelectWorkspace(workspace) {
    setProperty({
      workspacePath: getFullPath({
        workspace: workspace,
        includeType: false
      })
    });
  }

  return <TabContainer>
    <div header='Settings'>
      <div className='form-group'>
        <TileCommonSettings propertyBag={propertyBag} setProperty={setProperty} dashboardTileType={dashboardTileType} />
      </div>

      <div className='form-group'>
        <label className='font-weight-bold'>Workspace</label>
        <input type='text' readOnly className='form-control' value={propertyBag.workspacePath} onChange={e => setProperty({ workspacePath: e.target.value })} />
        <WorkspaceSelector style={{ marginTop: '1px', border: 'solid 1px #DDDDDD' }} showOnlyItems={['Folder', 'Report']} onChange={w => onSelectWorkspace(w)} />
      </div>
    </div>

    <div header='Workspace' style={{height:'653px'}}>
      <WorkspaceEditConnected workspaceType='Report' workspacePath={propertyBag.workspacePath} settingsCallbacks={settingsCallbacks}/>
    </div>
  </TabContainer>
}