import React from 'react';
import JSONEditor from 'jsoneditor';
import PropTypes from 'prop-types';

class JsonEditor extends React.Component {
  static propTypes = {
    data : PropTypes.any.isRequired,
    mode : PropTypes.string,
    onEdited : PropTypes.func
  }

  constructor(props) {
    super(props);
    this.editor = null;
    this.ref = React.createRef();
  }

  expandAll() {
    if (typeof this.editor.expandAll === 'function') this.editor.expandAll();
  }

  componentDidMount() {
    this.initialiseEditor();
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate = this.props.data !== nextProps.data;
    return shouldUpdate;
  }

  initialiseEditor() {
    if (!this.props.data || !this.ref.current) return;

    if (!this.editor) {
      let container = this.ref.current;

      let jsonEditor = this.editor = new JSONEditor(container, {
        onChange: this.props.onEdited ? this.onChanged.bind(this) : undefined,
        mode: 'tree',
        modes: ['code', 'tree'],
        sortObjectKeys: true,
        onModeChange() {
          if (typeof jsonEditor.expandAll === 'function') jsonEditor.expandAll();
        }
      });
      
      this.editor.set(this.props.data);
      if (this.props.mode)
        this.editor.setMode(this.props.mode);

      this.expandAll();
    }
    else {
      this.editor.set(this.props.data);
      this.editor.refresh();
      this.expandAll();
    }
  }

  onChanged() {
    try {
      let data = this.editor.get();
      this.props.onEdited(data);
    }
    catch {
      const text = this.editor.getText();
      if (text === '')
        this.props.onEdited({});
    }
  }

  render() {
    return <div ref={ this.ref } className={this.props.className} style={{height:'100%', ...this.props.style}} />
  }
}

export default JsonEditor;