import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  dashboardTileWeblinkFromWorkspace
} from '../../../actions/dashboard-tiles-webLink';
import { toJS } from '../../../utility/immutable-utility';
import { getWorkspaceImageUrl } from '../../../utility/image-utility';

const WebLinkTile = connect(
  (state, { stateKey }) => {
    const refreshRequired = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'refreshRequired']);
    const layoutChanged = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'layoutChanged']);

    const workspacePath = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'workspacePath']);
    const stretchImage = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'stretchImage']) ?? false;
    const hideLinkAddress = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'hideLinkAddress']) ?? false;
    const workspace = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'workspace']);
    return {
      layoutChanged,
      refreshRequired,
      workspacePath,
      workspace,
      stretchImage,
      hideLinkAddress
    };
  },
  (dispatch, { stateKey }) => ({
    refresh(workspacePath) {
      dispatch(dashboardTileWeblinkFromWorkspace({ stateKey, workspacePath }));
    }
  })
)(({ refresh, refreshRequired, workspacePath, layoutChanged, workspace, stretchImage, hideLinkAddress }) => {
  useEffect(() => {
    if (refreshRequired)
      refresh(workspacePath);
  }, [refresh, refreshRequired, workspacePath]);

  if (workspace) {
    const _workspace = toJS(workspace);
    const _imageUrl = getWorkspaceImageUrl({ imageId: _workspace.thumbImageId, type: _workspace.type });
    return <a href={_workspace.data} target='_blank' rel='noopener noreferrer'
      style={{ display: 'block', height: '100%', width: '100%' }}>
      <div style={{
        display: 'flex', height: '100%', alignItems: 'flex-end', justifyContent: 'center',
        backgroundImage: `url('${_imageUrl}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: stretchImage ? 'cover' : 'contain'
      }}>
        {!hideLinkAddress && _workspace.data}
      </div>
    </a>
  }
  return <div></div>
});

export default WebLinkTile;