import moment from 'moment';
import { toJS } from './immutable-utility';
import { fromJS } from 'immutable';
import { assertType } from '../utility/type-checking';
import PropTypes from 'prop-types';

export function buildTierToggleMap(rows, maxLevel) {
  return buildTierToggleMapRecursive(rows, 0, [], maxLevel);

  function buildTierToggleMapRecursive(rows, depth, tiers, maxLevel) {
    let children = [];
    for (let i = 0; i < rows.length; i++) {
      const parent = rows[i];

      if (parent.children && parent.children.length) children.push(...parent.children);
    }

    tiers[depth] = depth;

    if (depth + 1 < maxLevel) buildTierToggleMapRecursive(children, depth + 1, tiers, maxLevel);

    return tiers;
  }
}

export function isOnDemand(mode) {
  return mode === 'OnDemand'
    || mode === 'OnDemand-Open'
    || mode === 'OnDemand-Closed';
}

export function mapVerticalReportRow(mappedRows, row, displayMap, parentDisplayChildren, parentIds, warnings, errors, level) {
  const baseKey = [...parentIds, row.key].join('-');
  const displayChildrenMode = parentDisplayChildren && getDisplayMode(displayMap[baseKey]);
  const displayChildren = displayChildrenMode === 'Open';
  const hasChildren = !!(row.children && row.children.length)
    || isOnDemand(displayChildrenMode)
    || displayChildrenMode === 'Loading';

  const toggleProps = { displayChildrenMode, key: row.key, level: level + 1, baseKey };
  const openChartProps = { id: row.id, keys: [...parentIds, row.key], inputs: (displayChildren && row.children) || [] }

  parentIds.push(row.id);
  const rowStyles = row.styles.map(s => ([s]));
  if (row.cellStyles)
    Object.keys(row.cellStyles).forEach(n => {
      var indexes = row.cellStyles[n];
      indexes.forEach(i => {
        rowStyles[i].push(n);
      })
    });
  
  mappedRows.push({ 
    id: row.id,
    key: `${baseKey}-${row.key}`, 
    name: row.name, 
    values: row.values, 
    styles: rowStyles, 
    errors: errors[row.key], 
    warnings: warnings[row.key], 
    expandMode: displayChildrenMode, 
    children: row.children,
    hasChildren, 
    scenarioInfo: { className: '' }, 
    toggleProps, 
    openChartProps, 
    level });

  if (!!(hasChildren && displayChildren))
    row.children.map((c) => mapVerticalReportRow(mappedRows, c, displayMap, displayChildren, parentIds, warnings, errors, level + 1));

  parentIds.pop();
}

export function mapVerticalCellStyles(columns, mappedRows, getStyle, getSpecialStyle){
  function getCellStyle(styleNames){
      const cssStyles = styleNames ? styleNames.reduce((accumulator, item) => ({ ...accumulator, ...getStyle(item) }), {}) : {};
      return cssStyles;
  }

  function getNumberFormat(row, ix)
  {
      if (row.styles === undefined || (Array.isArray(row.Styles) && row.Styles.length === 0))
        return {
          commaSeparated: true,
          decimalPlaces: 2
        };

      const specialisedStyles = row.styles[ix] ? row.styles[ix].reduce((accumulator, item) => ({ ...accumulator, ...getSpecialStyle(item) }), {}) : {};
      return specialisedStyles;
  }

  return columns.map((c, cx) => {
      return [...mappedRows.map((r, rx) => ({
          cssStyles: getCellStyle(r.styles[cx]),
          specialisedStyles: getNumberFormat(r, cx)}))];}
  );
}


export function mapHorizontalCellStyles(cellStyles, rowSpecialisedStyles, getColumnStyleNames, getStyle, getSpecialStyle) {
  return cellStyles.map((_, ix) => {
    const styleNames = [
      ...getColumnStyleNames(ix + 1),
      ...cellStyles[ix]
    ];

    const cssStyles = styleNames.reduce((accumulator, item) => ({ ...accumulator, ...getStyle(item) }), {verticalAlign: 'middle'});
    const specialisedStyles = styleNames.reduce((accumulator, item) => ({ ...accumulator, ...getSpecialStyle(item) }), {...rowSpecialisedStyles});
    return {cssStyles, specialisedStyles};
  });
}

export function getInfoDisplayProps(row) {
  let infoClassName = null;
  let infoMessage = null;

  if (row.errors) {
    infoClassName = {
      iconClassName: 'text-danger ml-1',
      faIconClassName: 'fa-exclamation-triangle'
    };
    infoMessage = row.errors.details[0];
  } else if (row.warnings) {
    infoClassName = {
      iconClassName: 'text-warning ml-1',
      faIconClassName: 'fa-exclamation-triangle'
    };
    infoMessage = row.warnings.details[0];
  }
  return { infoClassName, infoMessage };
}

export function getDisplayMode(displayMapItem) {
  return displayMapItem ? displayMapItem.displayMode : undefined;
}

export function getDisplayMapItemDisplayModeWithinParent(isParentDisplayChildren, displayMapItem) {
  return isParentDisplayChildren && (displayMapItem ? displayMapItem.displayMode : undefined);
}

export function getOnDemandDisplayMapKeys(displayMap) {
  return Object.entries(displayMap).filter(([_displayKey, displayMapItem]) => getDisplayMode(displayMapItem) === 'OnDemand-Open').map(([displayKey, _displayMapItem]) => {
    const displayKeys = displayKey.split('-');

    const key = displayKeys[displayKeys.length - 1];
    const nodeLevel = displayKeys.length;

    return { key, displayKey, nodeLevel };
  });
}

export function getAnnotationDetails(details, ix, annotation) {
  const detail = details && details.length > ix ? details[ix] : null;
  const detailValues = (detail ?? {}).value ?? [];
  const filteredValues = detailValues.filter(i => !!i && !!i.periodStart);

  return filteredValues.length > 0
    ? filteredValues.map(i => `${moment.utc(i.periodStart).format('DD-MMM-YYYY')} ${moment.utc(i.periodEnd).format('DD-MMM-YYYY')} ${i.value}`)
    : [annotation];
}

function getHeaderStyle(level, id, overridenId, hasOverrides, isAdjusted) {
  const rowHeaderstyle = { paddingLeft: `${level * 16 - (level > 1 ? 14 : 0)}px` };

  let overrideTypeClassName = null;
  let overrideMessage = null;
  let scenarioHeader = null;

  if (id !== overridenId || isAdjusted) {
    rowHeaderstyle.background = 'lightgray';
    scenarioHeader = { background: 'lightgray' };
    // we use a negative id to determin if an intervention override has been applied
    if (Math.abs(id) !== Math.abs(overridenId)) {
      overrideTypeClassName = {
        iconClassName: 'ml-1',
        faIconClassName: 'fa-info-circle'
      };

      overrideMessage = `${Math.abs(overridenId)} has been overridden by ${Math.abs(id)}`;
    }
  } else if (hasOverrides) {
    rowHeaderstyle.background = '#f2f2f2';
    scenarioHeader = { background: '#f2f2f2' };
  }
  return { scenarioHeader, rowHeaderstyle, overrideTypeClassName, overrideMessage };
}

export function linkHorizontalViewCells(rows, view, scenarioOverrideMap = {}) {
  assertType({
    rows: PropTypes.immutable.isRequired,
    view: PropTypes.array.isRequired,
    scenarioOverrideMap: PropTypes.object.isRequired
  }, {rows, view, scenarioOverrideMap});

  let index = 0;
  rows.forEach(row => parse(row));

  function parse(row) {
    if (index < view.length) {
      const v = view[index];
      if (row.get('rowKey') === v.rowKey) {
        const parentId = row.get('parentId'); // at the root this is -1 otherwise the parentId
        const overridenId = row.get('overridenId');
        v.styles = toJS(row.get('styles'));
        v.scenarios = toJS(row.get('scenarioAlternatesSummary'));
        v.scenarioOverride = scenarioOverrideMap[`${parentId}|${overridenId}`] ?? (row.get('parentScenarioOverride') ?? '');
        v.values = row.get('values');

        v.cellStyles = [];
        const valuesCount = v.values.size;
        const cellStyles = row.get('cellStyles').toJS() ?? {};
        for (let ix = 0; ix < valuesCount; ix++) {
          v.cellStyles.push(Object.entries(cellStyles).filter(([, value]) => value.includes(ix)).map(([key]) => key) ?? []);
        }

        index++;
      }

      if (row.get('children')) {
        row.get('children').forEach(child => parse(child));
      }
    }
  }
}

export function buildHorizontalView(state, path = ['results']) {
  assertType({
    state: PropTypes.object.isRequired,
    path: PropTypes.array.isRequired
  }, {state, path});

  if (state.getIn([...path, 'settings', 'orientation']) === 'Vertical')
    return state.setIn([...path, 'horizontalView'], []);

  const displayMap = toJS(state.getIn([...path, 'displayMap']));
  const errors = toJS(state.getIn([...path, 'errors']));
  const warnings = toJS(state.getIn([...path, 'warnings']));
  const reportStyles = toJS(state.getIn([...path, 'styles']));
  const rows = state.getIn([...path, 'rows']);
  const view = flattenHorizontalReportRows(displayMap, errors, warnings, reportStyles, rows)
  return state.setIn([...path, 'horizontalView'], fromJS(view));
}

function flattenHorizontalReportRows(displayMap, errors, warnings, reportStyles, rows) {
  assertType({
    displayMap: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    warnings: PropTypes.object.isRequired,
    reportStyles: PropTypes.object,
    rows: PropTypes.immutable.isRequired
  }, {displayMap, errors, warnings, reportStyles, rows});

  const response = [];
  for (let index = 0; index < rows.size; index++) {
    flattenReportRowsRecursive(rows.get(index));
  }
  return response;

  function getStyle(key) {
    if (reportStyles) return reportStyles[key] ?? {};
    return {};
  }

  function flattenReportRowsRecursive(row) {
    const rowKey = row.get('rowKey');
    const id = row.get('id');
    const overridenId = row.get('overridenId');
    const key = row.get('key');
    const name = row.get('name');
    const children = row.get('children');
    const parentId = row.get('parentId') ?? -1;
    const parentIds = toJS(row.get('parentIds')) ?? [];
    const level = row.get('level') ?? 0;
    const isParentDisplayChildren = row.get('isParentDisplayChildren') ?? true;
    const parentScenarioOverride = row.get('parentScenarioOverride') ?? '';
    const isAdjusted = row.get('isAdjusted') === true;
    const hasOverrides = row.get('hasOverrides');
    const styles = toJS(row.get('styles')) ?? [];
    const overrideTypeClassName = row.get('overrideTypeClassName');
    const overrideMessage = row.get('overrideMessage');
    const isLastChild = row.get('isLastChild') === true;
    const scenarioOverride = row.get('scenarioOverride');
    const baseKey = [...parentIds, overridenId].join('-');
    const displayChildrenMode = getDisplayMapItemDisplayModeWithinParent(isParentDisplayChildren, displayMap[baseKey]);
    const displayChildren = displayChildrenMode === 'Open';
    const hasChildren = !!(children && children.size > 0) || isOnDemand(displayChildrenMode) || displayChildrenMode === 'Loading';
    const { scenarioHeader, rowHeaderstyle, overrideTypeClassName: overrideTypeClass, overrideMessage: overrideMessageText } = getHeaderStyle(level, id, overridenId, hasOverrides, isAdjusted);
  
    response.push({
      id,
      overridenId,
      key,
      rowKey,
      name,
      parentId,
      parentIds,
      level,
      isParentDisplayChildren,
      parentScenarioOverride,
      isAdjusted,
      hasOverrides,
      isLastChild: isLastChild === true,
      baseKey: baseKey,
      hasChildren: hasChildren,
      children : children ? children.map(i => i.get('id')).filter(id => id).filter(id => id !== 0) : [],
      displayChildren: displayChildren,
      displayChildrenMode: displayChildrenMode,
      scenarioHeader: scenarioHeader,
      rowStyle: styles.reduce((accumulator, item) => ({ ...accumulator, ...getStyle(item) }), {}),
      rowHeaderstyle: rowHeaderstyle,
      overrideTypeClassName: overrideTypeClass,
      overrideMessage: overrideMessageText,
      timeSeries: {
        id,
        key,
        name,
        errors: errors[key],
        warnings: warnings[key],
        expandMode: displayChildrenMode,
        hasChildren,
        scenarioInfo: { className: overrideTypeClassName, message: overrideMessage },
        level
      }
    });

    const displayMode = getDisplayMode(displayMap[rowKey]) ?? 'Open';
    if (hasChildren && displayChildren && (displayMode === 'Open' || displayMode === 'OnDemand-Open')) {
      for (let index = 0; index < children.size; index++) {
        let childRow = children.get(index);
        childRow = childRow.set('parentIds', fromJS([...parentIds, key]));
        childRow = childRow.set('parentId', overridenId);
        childRow = childRow.set('level', level + 1);
        childRow = childRow.set('isParentDisplayChildren', displayChildren);
        childRow = childRow.set('isLastChild', index === children.length - 1);
        childRow = childRow.set('parentScenarioOverride', scenarioOverride);
        flattenReportRowsRecursive(childRow);
      }
    }
  }
}

export function getScenarios(rows) {
  assertType({
    rows: PropTypes.immutable.isRequired
  }, {rows});

  const scenarioAlternatesSummary = rows.map(r => r.get('scenarioAlternatesSummary')).flatMap(x => x).map((sas, index) => ({ sas, index, scenarioName: sas.get('scenarioName') }));
  const scenarios = toJS(scenarioAlternatesSummary.filter((x, index, self) => {
    return ((x.scenarioName !== '' && x.scenarioName !== null && x.scenarioName !== undefined) && self.findIndex(i => i.scenarioName === x.scenarioName) === index);
  }).map(x => x.sas));
  return scenarios;
}