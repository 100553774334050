import React from 'react';
import { connect } from 'react-redux';
import { Search } from './Search';
import {
  analysisCrossSchemaSearch,
  analysisCrossSchemaSearchSetSchemaSelectorSize
} from '../../../actions/analysis-cross-schema-search';

export const SearchConnected = connect(
  (state, { stateContext = 'default' }) => ({
    isSearching: state.getIn(['analysisCrossSchemaSearch', stateContext, 'isSearching']),
    refreshRequired: state.getIn(['analysisCrossSchemaSearch', stateContext, 'refreshRequired']),
    enableExpandFacets: state.getIn(['analysisCrossSchemaSearch', stateContext, 'enableExpandFacets']),
    schemaSelectorSize: state.getIn(['analysisCrossSchemaSearch', stateContext, 'schemaSelectorSize']),
    displaySearchResults: state.getIn(['analysisCrossSchemaSearch', stateContext, 'displayOptions', 'displaySearchResults']),
    compositionMaxLevel: state.getIn(['analysis', 'composition', 'maxLevel']),
  }),
  (dispatch, { stateContext = 'default', searchHandler = undefined }) => ({
    search() {
      if (searchHandler) {
        searchHandler(stateContext);
      } else {
        dispatch(analysisCrossSchemaSearch(stateContext));
      }
    },
    setSchemaSelectorSize(size) {
      dispatch(analysisCrossSchemaSearchSetSchemaSelectorSize(stateContext, size));
    }
  })
)(({
  stateContext,
  refreshRequired, isSearching,
  schemaSelectorSize, setSchemaSelectorSize,
  search, searchHandler, enableExpandFacets, compositionMaxLevel,
  displaySearchResults,
  canShowBusyIndicator = true }) => {

  return <Search
    searchHandler={searchHandler}
    stateContext={stateContext}
    search={search}
    isSearching={isSearching}
    refreshRequired={refreshRequired}
    enableExpandFacets={enableExpandFacets}
    compositionMaxLevel={compositionMaxLevel}
    schemaSelectorSize={schemaSelectorSize}
    setSchemaSelectorSize={setSchemaSelectorSize}
    displaySearchResults={displaySearchResults}
    canShowBusyIndicator={canShowBusyIndicator} />
});