import React from 'react';
import { CheckboxInput } from '../../../shared/CheckboxInput';
import AbsRelDate from '../../../shared/AbsRelDate';

export function AsAtWindowFiller({ fillerValues, setFillerValue, updateAllSeries }) {
  const {
    isEnabled,
    dataRelativeToAsAt,
    from,
    to
  } = fillerValues.get('asAtWindow').toJS();

  function saveValues(e) {
    e.preventDefault();

    updateAllSeries(['asAtWindowEnabled'], isEnabled, { requiresRefresh: true, applyToHighcharts: false });
    if (isEnabled){
      updateAllSeries(['dataWindowRelativeToAsAt'], dataRelativeToAsAt, { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['asAtWindow', 'fromDateMode'], from.mode, { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['asAtWindow', 'absFromDate'], from.abs, { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['asAtWindow', 'relFromDate'], from.rel, { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['asAtWindow', 'toDateMode'], to.mode, { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['asAtWindow', 'absToDate'], to.abs, { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['asAtWindow', 'relToDate'], to.rel, { requiresRefresh: true, applyToHighcharts: false });
    }
  }

  return <form onSubmit={e => saveValues(e)} >
    <div className='modal-content'>
      <div className='modal-body'>
        <div className='form-inline form-group'>
          <CheckboxInput data_id='is-enabled' checked={isEnabled} onChange={(_, checked) => setFillerValue(['asAtWindow', 'isEnabled'], checked)}>Enable</CheckboxInput>
        </div>
        <div className='form-inline form-group' style={{ opacity: isEnabled ? '1' : '0.4' }}>
          <CheckboxInput data_id='date-relative-to-asat' disabled={!isEnabled} checked={dataRelativeToAsAt} onChange={(_, checked) => setFillerValue(['asAtWindow', 'dataRelativeToAsAt'], checked)}>Data relative to asAt</CheckboxInput>
        </div>
        <div className='form-row pb-1' style={{ opacity: isEnabled ? '1' : '0.4' }}>
          <label className='font-weight-bold col-sm-2 mt-2'>From</label>
          <div className='col-sm-10'>
            <AbsRelDate data_id='from' lens={'None'} disabled={!isEnabled} value={from} onChange={value => setFillerValue(['asAtWindow', 'from'], value)} />
          </div>
        </div>
        <div className='form-row pb-1' style={{ opacity: isEnabled ? '1' : '0.4' }}>
          <label className='font-weight-bold col-sm-2 mt-2'>To</label>
          <div className='col-sm-10'>
            <AbsRelDate data_id='to' lens={'None'} disabled={!isEnabled} value={to} onChange={value => setFillerValue(['asAtWindow', 'to'], value)} />
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <button data_id='apply' className='btn btn-sm btn-primary' type='submit' data-close-popover>Apply</button>
      </div>
    </div>
  </form>
}