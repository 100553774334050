import { fromJS } from 'immutable'
import { guid } from '../utility/uid-utility';
import {
  TIMESERIES_DETAILS_VARIANTS_CLEAR,
  TIMESERIES_DETAILS_VARIANTS_LOAD_STARTED,
  TIMESERIES_DETAILS_VARIANTS_LOAD_COMPLETE,
  TIMESERIES_DETAILS_VARIANTS_EDIT,
  TIMESERIES_DETAILS_VARIANTS_CANCEL_EDIT,
  TIMESERIES_DETAILS_VARIANTS_UPDATE_EDIT,
  TIMESERIES_DETAILS_VARIANTS_SAVE_STARTED,
  TIMESERIES_DETAILS_VARIANTS_SAVE_COMPLETE,
  TIMESERIES_DETAILS_VARIANTS_ACTION_FAILED
} from '../actions/timeSeriesDetails-variants';

export const timeSeriesDetailsVariantsReducer = {
  [TIMESERIES_DETAILS_VARIANTS_CLEAR](state, action) {
    return state.setIn(['variantsEditor', 'variants'], fromJS([]));
  },
  [TIMESERIES_DETAILS_VARIANTS_LOAD_STARTED](state, action) {
    return state.setIn(['variantsEditor', 'isLoading'], true);
  },
  [TIMESERIES_DETAILS_VARIANTS_LOAD_COMPLETE](state, action) {
    return state.setIn(['variantsEditor', 'variants'], fromJS(action.data || []))
                .setIn(['variantsEditor', 'isLoading'], false);
  },
  [TIMESERIES_DETAILS_VARIANTS_EDIT](state, action) {
    const variant = { key: guid() };

    return state.setIn(['variantsEditor', 'variantEdit'], fromJS(variant));
  },
  [TIMESERIES_DETAILS_VARIANTS_CANCEL_EDIT](state, action) {
    return state.setIn(['variantsEditor', 'variantEdit'], fromJS({}));
  },
  [TIMESERIES_DETAILS_VARIANTS_UPDATE_EDIT](state, action) {
    if (!action.keyPath) return state;

    return state.setIn(['variantsEditor', 'variantEdit', ...(Array.isArray(action.keyPath) ? action.keyPath : [action.keyPath])], action.value);
  },
  [TIMESERIES_DETAILS_VARIANTS_SAVE_STARTED](state, action) {
    return state.setIn(['variantsEditor', 'variantEdit', 'isSaving'], true)
                .setIn(['variantsEditor', 'variantEdit', 'errorMessage'], '');
  },
  [TIMESERIES_DETAILS_VARIANTS_SAVE_COMPLETE](state, action) {
    const data = action.data || {};
    const { identityId: variantId } = data;

    const index = state.getIn(['variantsEditor', 'variants']).findIndex(i => i.get('identityId') === variantId);

    let newState = state.setIn(['variantsEditor', 'variantEdit'], fromJS({}));

    return index >= 0
      ? newState.setIn(['variantsEditor', 'variants', index], fromJS(data))
      : newState.updateIn(['variantsEditor', 'variants'], v => v.push(fromJS(data)));
  },
  [TIMESERIES_DETAILS_VARIANTS_ACTION_FAILED](state, action) {
    return state.setIn(['variantsEditor', 'variantEdit', 'isSaving'], false)
                .setIn(['variantsEditor', 'variantEdit', 'errorMessage'], action.errorMessage || 'Unexpected error');
  }
};