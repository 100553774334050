import React from 'react';
import { connect } from 'react-redux';
import WorkspaceSelector from '../dashboard/WorkspaceSelector';
import {
  dashboardTilesDynamicWorkspaceSelectWorkspace,
  dashboardTilesDynamicWorkspaceDesignSetValue,
  dashboardTilesDynamicWorkspaceToggleAllOptions,
  dashboardTilesDynamicWorkspaceSetMultiSelectToCreateSingleTile,
  dashboardTilesDynamicWorkspaceAddToDashboard,
  dashboardTilesDynamicWorkspaceGoNext,
  dashboardTilesDynamicWorkspaceGoBack,
  dashboardTilesDynamicWorkspaceSetOption,
} from '../../../actions/dashboard-tiles-dynamic-workspace';
import { toJS } from '../../../utility/immutable-utility';
import { getToggleAllCheckStyle } from '../../../utility/selection-utility';
import {
  dashboardTilesWorkspacePickerSelectWorkspace
} from '../../../actions/dashboard-tiles-workspace-picker';
import { CheckboxInput } from '../../shared/CheckboxInput';
import LoadingIcon from '../../shared/LoadingIcon';

export const DynamicWorkspacePickerConnected = connect(
  (state) => ({
    showDebug: state.getIn(['userSettings', 'settings', 'show-debug']),
    title: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'title']),
    view: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'view']),
    readingWorkspace: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'readingWorkspace']),
    canGoNext: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'canGoNext']),
    canGoBack: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'canGoBack']),
    workspaceName: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'workspaceName']),
    dynamicWorkspace: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'dynamicWorkspace']),
    dynamicWorkspaceToolbarStyle: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'dynamicWorkspaceToolbarStyle']),
    selectedOptions: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'selectedOptions']),
    combinations: state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'combinations'])
  }),
  (dispatch) => ({
    goNext() {
      dispatch(dashboardTilesDynamicWorkspaceGoNext());
    },
    goBack() {
      dispatch(dashboardTilesDynamicWorkspaceGoBack());
    },
    setDesignerValue(key, value) {
      dispatch(dashboardTilesDynamicWorkspaceDesignSetValue(key, value));
    },
    setOption(dynamicWorkspaceKey, dynamicFilterKey, name, value) {
      dispatch(dashboardTilesDynamicWorkspaceSetOption(dynamicWorkspaceKey, dynamicFilterKey, name, value));
    },
    toggleAllOptions(dynamicWorkspaceKey, dynamicFilterKey) {
      dispatch(dashboardTilesDynamicWorkspaceToggleAllOptions(dynamicWorkspaceKey, dynamicFilterKey));
    },
    selectWorkspace(workspace) {
      dispatch(dashboardTilesDynamicWorkspaceSelectWorkspace(workspace));
      dispatch(dashboardTilesWorkspacePickerSelectWorkspace(workspace))
    },
    addTilesToDashboard() {
      dispatch(dashboardTilesDynamicWorkspaceAddToDashboard());
    },
    setMultiSelectToCreateSingleTile(dynamicWorkspaceKey, dynamicFilterKey, value) {
      dispatch(dashboardTilesDynamicWorkspaceSetMultiSelectToCreateSingleTile(dynamicWorkspaceKey, dynamicFilterKey, value));
    }
  })
)(({ goNext, goBack, canGoNext, canGoBack,
  view, workspaceName, dynamicWorkspace, title,
  selectWorkspace,
  setDesignerValue, dynamicWorkspaceToolbarStyle,
  workspaceTypeToTileTypeMap, closeDialog, addTilesToDashboard,
  setOption, toggleAllOptions, readingWorkspace,
  setMultiSelectToCreateSingleTile, selectedOptions, combinations, showDebug }) => {

  function onAddTiles() {
    if (!combinations || combinations.size === 0)
      return;

    closeDialog();
    addTilesToDashboard();
  }

  return <div className='modal-content' style={{ minWidth: '50em' }}>
    <div className='modal-header'>
      <h5 className='modal-title'>Add Dynamic Workspace</h5>
    </div>
    {view === 'selection' &&
      <WorkspaceSelectionView closeDialog={closeDialog} goNext={goNext} goBack={goBack} canGoNext={canGoNext} canGoBack={canGoBack} workspaceName={workspaceName} selectWorkspace={selectWorkspace} workspaceTypeToTileTypeMap={workspaceTypeToTileTypeMap} readingWorkspace={readingWorkspace} />}

    {view === 'building' &&
      <DynamicTitlesView showDebug={showDebug} closeDialog={closeDialog} goNext={goNext} goBack={goBack} canGoNext={canGoNext} canGoBack={canGoBack} dynamicWorkspace={toJS(dynamicWorkspace)}
        workspaceName={workspaceName} title={title}
        setDesignerValue={setDesignerValue} dynamicWorkspaceToolbarStyle={dynamicWorkspaceToolbarStyle}
        setOption={setOption} toggleAllOptions={toggleAllOptions} onAddTiles={onAddTiles} setMultiSelectToCreateSingleTile={setMultiSelectToCreateSingleTile}
        combinations={combinations}
        selectedOptions={selectedOptions} />}
  </div>
});

function WorkspaceSelectionView({ closeDialog, goNext, canGoNext, workspaceName, selectWorkspace, workspaceTypeToTileTypeMap, readingWorkspace }) {
  return <>

    <div className='modal-body'>
      <div className='form-group'>
        <label className='font-weight-bold'>Workspace</label>
        <input type='text' readOnly className='form-control' value={workspaceName ?? ''} />
        <WorkspaceSelector style={{ marginTop: '1px', border: 'solid 1px #DDDDDD' }} showSelectedWorkspath={false} showOnlyItems={['Folder', ...Object.keys(workspaceTypeToTileTypeMap), 'Dashboard']} onChange={w => selectWorkspace(w)} />
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sml btn-secondary ml-3' onClick={() => closeDialog()}>Cancel</button>
      <button style={{ width: '70px' }} className={`btn btn-sml ${!canGoNext || readingWorkspace ? 'btn-outline-primary' : 'btn-primary'}`} disabled={!canGoNext || readingWorkspace} onClick={() => goNext()}>{readingWorkspace ? <LoadingIcon /> : 'Add'}</button>
    </div></>
}

function DynamicTitlesView({ showDebug, closeDialog, goBack, canGoBack, canGoNext, workspaceName, title, dynamicWorkspace, setDesignerValue,
  setOption, toggleAllOptions, setMultiSelectToCreateSingleTile, onAddTiles, dynamicWorkspaceToolbarStyle = 'default', selectedOptions, combinations }) {

  return <>
    <div className='modal-body' style={{ minHeight: '555px' }}>
      <div className='form-group'>
        <label className='font-weight-bold'>Workspace</label>
        <input type='text' readOnly className='form-control' value={workspaceName} />
      </div>
      <div className='form-group'>
        <label className='font-weight-bold'>Title</label>
        <input type='text' className='form-control form-control-sm' value={title} onChange={e => setDesignerValue(['title'], e.target.value)} />
      </div>

      <div className='form-group'>
        <label className='font-weight-bold' >Dynamic workspace selectors behaviour</label>
        <select className='form-control form-control-sm' value={dynamicWorkspaceToolbarStyle}
          onChange={(e) => setDesignerValue(['dynamicWorkspaceToolbarStyle'], e.target.value)}>
          <option value='default' >Default</option>
          <option value='readOnly' >Read only</option>
          <option value='hidden' >Hidden</option>
        </select>
      </div>

      <div className='form-group'>
        <label className='font-weight-bold' >Tiles</label>
        <div className='d-flex' style={{ maxHeight: '270px', overflowX: 'auto', backgroundColor: '#eee' }}>
          {dynamicWorkspace.map((d, di) => <DynamicSetting key={`ds-${di}`} dynamicWorkspace={d} setOption={setOption} toggleAllOptions={toggleAllOptions} setMultiSelectToCreateSingleTile={setMultiSelectToCreateSingleTile} />)}
          <div className='ml-3 w-100' style={{ backgroundColor: '#eee', overflow: 'auto', maxHeight: '300px' }}>
            <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
              {combinations && combinations.map((c, cindex) =>
                <div key={`${cindex}`} className='text-nowrap px-3 py-2 m-2 ' style={{ background: '#fff', borderRadius: '0.5em', boxShadow: '0px 2px 5px rgba(0, 0, 0, .1)', height: '30px' }}>
                  {c.map(e => e.get('values').join(', ')).join(' > ')}
                </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sml btn-secondary mr-3' onClick={() => closeDialog()}>Cancel</button>
      <button className='btn btn-sml btn-secondary' disabled={!canGoBack} onClick={() => goBack()}>Back</button>
      <button className='btn btn-sml btn-primary' disabled={!canGoNext} onClick={() => onAddTiles()}>Add</button>
    </div>
  </>
}

function DynamicSetting({ dynamicWorkspace, setOption, toggleAllOptions, setMultiSelectToCreateSingleTile }) {
  return <div className='d-flex'>
    {dynamicWorkspace.dynamicFilters.map((df, di) => {
      if (df.values.length === 0)
        return <div key={`${di}`}></div>

      return <div key={`${di}`} className='sticky-table' style={{ backgroundColor: '#fff', margin: '2px', overflowX: 'hidden', padding: '5px', maxHeight: '300px', minWidth: '100px' }}>
        <table className='table-sm' style={{ overflowX: 'hidden' }}>
          <thead>
            <tr className='sticky-header'>
              <th >
                <div className='d-flex'>
                  <span className='pt-1 mr-1' style={{ cursor: 'pointer', width: '15px', marginRight: '0.3em' }} onClick={() => toggleAllOptions(dynamicWorkspace.key, df.dynamicFilterKey)}><i className={`${getToggleAllCheckStyle(df.values, r => r.isSelected).selectionIndicator} fa-lg`} /></span>
                  <span className='pt-1 mr-1'>
                    {df.title}
                  </span>
                </div>
                {df.isMultiSelect && <select style={{ minWidth: '125px' }} className='form-control form-control-sm mt-2 px-0' value={df.groupMultiselectOptions ? 'true' : 'false'} onChange={e => setMultiSelectToCreateSingleTile(dynamicWorkspace.key, df.dynamicFilterKey, e.target.value === 'true')}>
                  <option value='true'>Grouped</option>
                  <option value='false'>Ungrouped</option>
                </select>}

              </th>
            </tr>
          </thead>
          <tbody>
            {df.values.map((v, vi) =>
              <tr key={`${vi}`}>
                <td className='pr-3'>
                  <CheckboxInput checked={v.isSelected === true} onChange={(_, checked) => setOption(dynamicWorkspace.key, df.dynamicFilterKey, v.name, checked)}>{v.name}</CheckboxInput>
                </td>
              </tr>)}
          </tbody>
        </table>
      </div>
    })
    }
  </div>
}