import { unparse } from 'papaparse';

export const csvExport = (fileName, columns, data) => {
  if (!fileName || !columns || !columns.length || !data.length || !data.length) return;

  let fileContent = unparse({ fields: columns, data: data });

  saveAsInternal(`${fileName}.csv`, 'text/csv', fileContent);
};

export const csvExportText = (fileName, allLines) => {
  if (!fileName || !allLines || !allLines.length) return;
  saveAsInternal(`${fileName}.csv`, 'text/csv', allLines);
};

export const jsonExport = (fileName, columns, data) => {
  if (!fileName || !columns || columns.length || data.length || data.length) return;

  let fileContent = JSON.stringify(data.map(r => {
    let i = {};

    columns.forEach(f => i[f] = r[f]);

    return i;
  }));

  saveAsInternal(`${fileName}.json`, 'application/json', fileContent);
};

const saveAsInternal = (name, contentType, fileContent) => {
  const blob = new Blob([fileContent], { contentType});

  saveAs(name, blob);
};

export const saveAs = (name, blob) => {
  let blobUrl = window.URL.createObjectURL(blob),
      a = window.document.createElement('a');

  a.href = blobUrl;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(blobUrl);
};