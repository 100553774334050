import React from 'react';
import FormattedDateTime from '../../../shared/FormattedDateTime';

export default function HistoryTable({ rows, toggleSelectionFunction }) {
  return (
    <table className='table table-sm table-striped table-bordered'>
      <thead>
        <tr>
          <th>ValidFrom (UTC)</th>
          <th>ValidTo (UTC)</th>
          <th>Updated by</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((x,xi) => {
          return <tr key={xi} onClick={() => toggleSelectionFunction(!x.get('selected') ? x : null) }>
            <td><FormattedDateTime>{x.getIn(['audit','validFrom'])}</FormattedDateTime></td>
            <td><FormattedDateTime>{x.getIn(['audit','validTo'])}</FormattedDateTime></td>
            <td>{x.getIn(['audit','updatedBy'])}</td>
            <td className='text-primary' style={{ minWidth: 30 }}>
              <i className={'fas fa-check-circle fa-lg fa-fw ' + (x.get('selected') === true ? 'visible' : 'invisible')} />
            </td>
          </tr>;
        })}
      </tbody>
    </table>
  );
}