import React from 'react';
import InputSeries from './InputSeries';
import ResultSeries from './ResultSeries';
import Step from './Step';
import CollapsePanel from '../../../shared/CollapsePanel';

export default function ToolboxResults({ inputs, steps, result }) {
    const inputsTitle = inputs ?  `Inputs (${inputs.length})` : 'Inputs';
    return <div>
            <CollapsePanel idKey={'inputs'} title={inputsTitle} headerStyle={{ background: 'lightgray', cursor: 'pointer' }} collapsed={true}>
            {inputs && inputs.map(s=> <InputSeries key={s.key} 
                                                   seriesKey={s.key}
                                                   name={s.name}
                                                   granularity={s.granularity}
                                                   sourceTimeZoneId={s.sourceTimeZoneId}
                                                   dataPointCount={s.dataPointCount}
                                                   dataPoints={s.dataPoints} />)}
            </CollapsePanel>
            {steps && steps.map(s=> <Step key={s.step} 
                                          step={s.step} 
                                          functionName={s.functionName} 
                                          signature={s.signature}
                                          functionKeys={s.keys}
                                          functionParameters={s.parameters}
                                          inputs={inputs}
                                          inputStack={s.inputStack}
                                          output={s.output} />)}

            {result && <ResultSeries seriesKey={result.key}
                                     granularity={result.granularity}
                                     sourceTimeZoneId={result.sourceTimeZoneId}
                                     dataPointCount={result.dataPointCount}
                                     dataPoints={result.dataPoints}
                                     errors={result.errors}
                                     warnings={result.warnings}
                                     infos={result.infos} />}
        </div> 
      ;
}