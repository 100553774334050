import React from 'react';
import FormattedDateTime from '../../../shared/FormattedDateTime';

export default function InstanceDataPointTable({ rows }) {
  const showEnd = rows && rows.some(x => x.get('endDateTime') !== undefined);
  return (
    <table className='table table-sm table-striped table-bordered'>
      <thead>
        <tr>
          <th>Start (UTC)</th>
          {showEnd && <th>End (UTC)</th>}
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((x,xi) => {
          return <tr key={xi}>
            <td><FormattedDateTime>{x.get('startDateTime')}</FormattedDateTime></td>
            {showEnd && <td><FormattedDateTime>{x.get('endDateTime')}</FormattedDateTime></td>}
            <td>{x.get('value')}</td>
          </tr>;
        })}
      </tbody>
    </table>
  );
}