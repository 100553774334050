import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import { toJS } from '../../../../utility/immutable-utility';
import ConfirmButton from '../../../shared/ConfirmButton';
import FormattedDateTime from '../../../shared/FormattedDateTime';

function AnnotationsTableEditRow({ data_id, annotation, setAnnotationKey, setAnnotationFromDate, setAnnotationToDate, setAnnotationPriority, setAnnotationText, saveAnnotation, cancelEdit, isContributor }) {
  const { id: annotationId, annotationKey, periodStart, periodEnd, value, priority, updatedBy } = annotation;

  const focusRef = useRef();

  useEffect(() => {
    if (focusRef && focusRef.current) focusRef.current.focus();
  }, [focusRef]);

  const onSetAnnotationKey = useCallback(e => setAnnotationKey(e.target.value), [setAnnotationKey]);
  const onSetFromDate = useCallback(e => setAnnotationFromDate(e.target.value), [setAnnotationFromDate]);
  const onSetToDate = useCallback(e => setAnnotationToDate(e.target.value), [setAnnotationToDate]);
  const onSetText = useCallback(e => setAnnotationText(e.target.value), [setAnnotationText]);
  const onSetPriority = useCallback(e => setAnnotationPriority(e.target.value), [setAnnotationPriority]);
  const onSave = useCallback(() => saveAnnotation(annotation), [annotation, saveAnnotation]);

  return (
    <>
      <tr data_id={data_id} data-annotation-id={annotationId || ''}>
        <td style={{ width: '4em' }} />
        <td>
          <input data_id='key' className='form-control' type='text' value={annotationKey || ''} onChange={onSetAnnotationKey} />
        </td>
        <td style={{ width: '16em', marginBottom: '.5em' }}>
          <input data_id='date-from' ref={focusRef} type='datetime-local' min="0001-01-01T00:00" max="9999-12-31T23:59" className='form-control form-control-sm rounded-right'
            value={periodStart}
            onChange={onSetFromDate} />
        </td>
        <td style={{ width: '16em', marginBottom: '.5em' }}>
          <input data_id='date-to'  type='datetime-local' min="0001-01-01T00:00" max="9999-12-31T23:59" className='form-control form-control-sm rounded-right'
            value={periodEnd}
            onChange={onSetToDate} />
        </td>
        <td>
          <textarea data_id='text' value={value || ''} cols='60' rows='4' maxLength='400' onChange={onSetText} />
        </td>
        <td>
          <input data_id='priority' className='form-control' type='number' value={priority || ''} onChange={onSetPriority} />
        </td>
        <td style={{ width: '20em' }}>{updatedBy}</td>
        <td style={{ width: 'auto' }} colSpan={2}></td>
      </tr>
      <tr data_id={`${data_id}-buttons`}>
        <td className='border-top-0' colSpan={7} align='right'>
          <button data_id='cancel' type='button' className='btn btn-sm btn-link' onClick={cancelEdit}>Cancel</button>
          {isContributor && <button data_id='save' type='submit' className='btn btn-primary' onClick={onSave}>Save</button>}
        </td>
        <td className='border-top-0' colSpan={2}></td>
      </tr>
    </>
  );
}

function AnnotationsTableRow({ annotation, editAnnotation, deleteAnnotation, isContributor }) {
  const { id: annotationId, annotationKey, formattedStart, formattedEnd, value, priority, updatedBy, updated } = annotation;

  const onEdit = useCallback(() => editAnnotation(annotationId), [annotationId, editAnnotation]);
  const onDelete = useCallback(() => deleteAnnotation(annotationId), [annotationId, deleteAnnotation]);

  return (
    <tr data-annotation-id={annotationId}>
      <td style={{ width: '4em' }}>
        <button className='btn btn-sm btn-link' onClick={onEdit}>
          <i className='fas fa-edit fa-fw' />
        </button>
      </td>      
      <td style={{ width: '16em' }}>{annotationKey}</td>
      <td style={{ width: '16em' }}>{formattedStart}</td>
      <td style={{ width: '16em' }}>{formattedEnd}</td>
      <td><pre style={{ font: 'inherit', whiteSpace: 'pre-wrap' }}>{value}</pre></td>
      <td style={{ width: '20em' }}>{priority}</td>
      <td style={{ width: '20em' }}>{updatedBy}</td>
      <td style={{ width: 'auto', whiteSpace:'nowrap' }}><FormattedDateTime>{updated}</FormattedDateTime></td>
      <td style={{ width: '10em' }}>
        {isContributor && <ConfirmButton type='button' className='btn btn-sm' confirmClassName='btn btn-outline-warning btn-sm' onClick={onDelete}
          content={
            <i className='fas fa-trash fa-fw' />
          }
          confirm={
            <span style={{ whiteSpace: 'nowrap' }}><i className='fas fa-trash fa-fw' />?</span>
          }>
        </ConfirmButton>}
      </td>
    </tr>
  );
}

function SortIcon({ orderBy, orderByDirection, columnKey }) {
  return orderBy && orderBy === columnKey
    ? orderByDirection === 'desc'
      ? <i className='fas fa-sort-down fa-fw' />
      : <i className='fas fa-sort-up fa-fw' />
    : <i className='fas fa-sort fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} />;
}

function AnnotationsTableHeader({ header, orderBy, orderByDirection, applySort }) {
  const { key, title, sortable } = header || {};

  const onSortChange = useCallback(e => {
    e.stopPropagation();

    applySort(key);
  }, [applySort, key]);

  return (
    <th className={`text-nowrap ${sortable ? 'sortable' : ''}`} onClick={onSortChange}>
      { title || ''}
      { sortable ? <SortIcon columnKey={key} orderBy={orderBy} orderByDirection={orderByDirection} /> : ''}
    </th>
  );
}

export default function AnnotationsTable({ annotationEdit, annotations, timeZone, addAnnotation, editAnnotation, deleteAnnotation, saveAnnotation, cancelAnnotationEdit,
  setAnnotationKey, setAnnotationFromDate, setAnnotationToDate, setAnnotationPriority, setAnnotationText, orderBy, orderByDirection, sortAnnotations, isContributor }) {
  const _annotations = useMemo(() => toJS(annotations, []), [annotations]);
  const _annotationEdit = useMemo(() => toJS(annotationEdit, {}), [annotationEdit]);
  const _headers = useMemo(() => [null,
    { key: 'annotationKey', title: 'AnnotationKey', sortable: true },    
    { key: 'periodStart', title: `Start date (${timeZone})`, sortable: true },
    { key: 'periodEnd', title: `End date (${timeZone})`, sortable: true },
    { key: 'value', title: 'Text', sortable: true },
    { key: 'priority', title: 'Priority', sortable: true },
    { key: 'updatedBy', title: 'Updated by', sortable: true },
    { key: 'updated', title: 'Updated', sortable: true }
  ], [timeZone]);
  const _sortedAnnotations = useMemo(() => {
    let data = _annotations;

    if (!!orderBy && !!orderByDirection) switch (orderBy) {
      case 'periodStart':
      case 'periodEnd': data = data.sort((a, b) => {
        const dateA = moment.utc(a[orderBy]).toDate();
        const dateB = moment.utc(b[orderBy]).toDate();

        return orderByDirection === 'desc' ? dateB - dateA : dateA - dateB;
      }); break;
      default: data = data.sort((a, b) => {
        const valueA = (a[orderBy] || '').toLocaleLowerCase();
        const valueB = (b[orderBy] || '').toLocaleLowerCase();

        if (valueA < valueB) return orderByDirection === 'desc' ? 1 : -1;
        if (valueA > valueB) return orderByDirection === 'desc' ? -1 : 1;
        return 0;
      }); break;
    }

    return data;
  }, [_annotations, orderBy, orderByDirection]);

  const [_editId, _isEditing] = useMemo(() => {
    const editId = _annotationEdit && _annotationEdit.id;
    const isEditing = !isNaN(editId) && (editId === 0 || _sortedAnnotations.findIndex(i => i.id === editId) >= 0);

    return [editId, isEditing];
  }, [_sortedAnnotations, _annotationEdit]);

  return <div className='sticky-table'>
    <table className='table table-sm w-100'>
      <thead>
        <tr className='sticky-header'>
          {_headers.map((i, ix) => <AnnotationsTableHeader key={ix} header={i} applySort={sortAnnotations} orderBy={orderBy} orderByDirection={orderByDirection} />)}
          <th className='w-100'></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan='6'>
            {!_isEditing && isContributor && (
              <button data_id='add-annotation' className='btn btn-primary btn-sm' onClick={addAnnotation}>
                <i className='fas fa-plus fa-fw' /> Add annotation
              </button>
            )}
          </td>
        </tr>
        {isContributor && _editId === 0 && (
          <AnnotationsTableEditRow data_id='new-annotation' annotation={annotationEdit}
            setAnnotationKey={setAnnotationKey}
            setAnnotationFromDate={setAnnotationFromDate}
            setAnnotationToDate={setAnnotationToDate}
            setAnnotationText={setAnnotationText}
            setAnnotationPriority={setAnnotationPriority}
            saveAnnotation={saveAnnotation}
            cancelEdit={cancelAnnotationEdit}
            isContributor={isContributor} />
        )}
        {_sortedAnnotations.map((annotation, ix) => annotation.id !== _editId ? (
          <AnnotationsTableRow data_id={`view-annotation-${ix}`} key={ix} annotation={annotation}
            editAnnotation={editAnnotation}
            deleteAnnotation={deleteAnnotation}
            isContributor={isContributor} />
        ) : (
            <AnnotationsTableEditRow data_id={`edit-annotation-${ix}`} key={ix} annotation={_annotationEdit}
              setAnnotationKey={setAnnotationKey}
              setAnnotationFromDate={setAnnotationFromDate}
              setAnnotationToDate={setAnnotationToDate}
              setAnnotationText={setAnnotationText}
              setAnnotationPriority={setAnnotationPriority}
              saveAnnotation={saveAnnotation}
              cancelEdit={cancelAnnotationEdit}
              isContributor={isContributor} />
          ))}
      </tbody>
    </table>
  </div>
}