import React, { useState } from 'react';
import FormattedNumber from '../../shared/FormattedNumber';
import LoadingIcon from '../../shared/LoadingIcon';
import Tooltip from '../../shared/Tooltip';

export default function OutagesToolbarRow1({ isLoadingSources, isLoadingBiddingZoneList, isLoadingProductionTypeList, sourceList, biddingZoneList, productionTypeList,
  source, biddingZone, productionType, unitEic, eventStart, eventEnd, refreshButtonClassName, resultCount, updateToolbarSource, updateToolbar, shareUrl,
  groupStyle, labelStyle }) {
  const [shareClicked, setShareClicked] = useState(false);

  function onShare() {
    shareUrl();
    setShareClicked(true);
    setTimeout(() => setShareClicked(false), 750);
  };

  return (
    <div className='form-row'>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold' style={labelStyle}>Source</label>
        {isLoadingSources ? (
          <div>
            <LoadingIcon /> Loading sources...
          </div>
        ) : (
          <select className='form-control form-control-sm'
            value={source}
            onChange={e => updateToolbarSource(e.target.value)}>
            <option value='' />
            {sourceList.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        )}
      </div>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold' style={labelStyle}>Bidding Zone</label>
        {isLoadingBiddingZoneList ? (
          <div>
            <LoadingIcon /> Loading bidding zones...
          </div>
        ) : (
          <select className='form-control form-control-sm'
            value={biddingZone}
            onChange={e => updateToolbar('biddingZone', e.target.value)}>
            <option value='' />
            {biddingZoneList.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        )}
      </div>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold' style={labelStyle}>Production Type</label>
        {isLoadingProductionTypeList ? (
          <div>
            <LoadingIcon /> Loading production types...
          </div>
        ) : (
          <select className='form-control form-control-sm'
            value={productionType}
            onChange={e => updateToolbar('productionType', e.target.value)}>
            <option value='' />
            {productionTypeList.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        )}
      </div>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold text-nowrap' style={labelStyle}>Unit Eic</label>
        <input type={'text'} className='form-control form-control-sm rounded-right'
          value={unitEic}
          onChange={e => updateToolbar('unitEic', e.target.value)} />
      </div>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold text-nowrap' style={labelStyle}>Event Start</label>
        <input type={'date'} className='form-control form-control-sm rounded-right'
          min={''}
          max={'9999-12-31'}
          value={eventStart}
          onChange={e => updateToolbar('eventStart', e.target.value)} />
      </div>
      <div className='form-group ml-1' style={groupStyle}>
        <label className='font-weight-bold text-nowrap' style={labelStyle}>Event End</label>
        <input type={'date'} className='form-control form-control-sm rounded-right'
          min={''}
          max={'9999-12-31'}
          value={eventEnd}
          onChange={e => updateToolbar('eventEnd', e.target.value)} />
      </div>
      <div className='form-group ml-1 d-flex align-items-end' style={groupStyle}>
        <div className='btn-group btn-group-sm'>
          <button className={refreshButtonClassName} type='submit'>
            <i className='fas fa-sync-alt fa-fw' />
            <span className='px-2 d-none d-md-inline-block'>Refresh</span>
          </button>
          <Tooltip placement='right' title={shareClicked ? 'Copied' : 'Copy link to clipboard'}>
            <button data-type='button' onClick={onShare}
              className={'fota-toggle-button btn shadow ml-2 ' + (shareClicked ? 'bg-white text-primary border-primary' : 'btn-primary')}>
              <i className={`${shareClicked ? 'far fa-copy' : 'fas fa-share'} fa-fw`} />
              <span className='px-2 d-none d-md-inline-block'>Share</span>
            </button>
          </Tooltip>
        </div>
      </div>
      <div className='form-group ml-1 d-flex align-items-end pb-1 px-1' style={groupStyle}>
        {resultCount > 0 && <><FormattedNumber>{resultCount}</FormattedNumber> results</>}
      </div>
    </div>
  );
}