import React, { useState } from 'react';
import { memo } from 'react';

export default memo(function ConfirmButton(props) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  function onClickWithConfimation(e) {
    const requiresConfirmation = props.requiresConfirmation === undefined ? true : props.requiresConfirmation;
    if (!requiresConfirmation){
      props.onClick(e);
      return;
    }

    if (showConfirmation) {
      setShowConfirmation(false);
      props.onClick(e);
    }
    else {
      e.stopPropagation();
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);
    }
  }

  return <button
    tabIndex={props.tabIndex}
    type={props.type || 'button'}
    style={props.style}
    title={props.title}
    className={!showConfirmation ? props.className : (props.confirmClassName || props.className) }
    onClick={onClickWithConfimation}
    disabled={props.disabled}>
    {!showConfirmation && props.content}
    {showConfirmation && props.confirm}
  </button>
});