import { createAction } from '../utility/redux-utility';

export const DASHBOARD_TILE_WORKSPACE_LOAD_BEGIN = 'DASHBOARD_TILE_WORKSPACE_LOAD_BEGIN';
export const dashboardTileWorkspaceLoadBegin = createAction(DASHBOARD_TILE_WORKSPACE_LOAD_BEGIN, 'stateKey', 'dashboardTileType');

export const DASHBOARD_TILE_WORKSPACE_LOAD_COMPLETE = 'DASHBOARD_TILE_WORKSPACE_LOAD_COMPLETE';
export const dashboardTileWorkspaceLoadComplete = createAction(DASHBOARD_TILE_WORKSPACE_LOAD_COMPLETE, 'stateKey', 'dashboardTileType', 'workspace', 'data', 'tilesConfig');

export const DASHBOARD_TILE_SET_PROPERTY = 'DASHBOARD_TILE_SET_PROPERTY';
export const dashboardTileSetProperty = createAction(DASHBOARD_TILE_SET_PROPERTY, 'stateKey', 'key', 'value');

export const DASHBOARD_TILE_EDIT_START = 'DASHBOARD_TILE_EDIT_START';
export const dashboardTileEditStart = createAction(DASHBOARD_TILE_EDIT_START, 'stateKey', 'dashboardTileType');

export const DASHBOARD_TILE_EDIT_CANCEL = 'DASHBOARD_TILE_EDIT_CANCEL';
export const dashboardTileEditCancel = createAction(DASHBOARD_TILE_EDIT_CANCEL, 'stateKey', 'dashboardTileType');

export const DASHBOARD_TILE_EDIT_COMPLETE = 'DASHBOARD_TILE_EDIT_COMPLETE';
export const dashboardTileEditComplete = createAction(DASHBOARD_TILE_EDIT_COMPLETE, 'stateKey', 'dashboardTileType');

export const DASHBOARD_TILE_CLONE = 'DASHBOARD_TILE_CLONE';
export const dashboardTileClone = createAction(DASHBOARD_TILE_CLONE, 'fromStateKey', 'toStateKey', 'dashboardTileType', 'tileState', 'tileConfig');