import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { CLIENT_ID } from './config';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ") >= 0;
const msie11 = ua.indexOf("Trident/") >= 0;
const msedge = ua.indexOf("Edge/") >= 0;
const firefox = ua.indexOf("Firefox") >= 0;
const isIE = msie || msie11;
const isEdge = msedge;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
const isChrome = ua.indexOf("Chrome") >= 0;
const isSafari = ua.indexOf("Safari") >= 0;
const isNode = !(isSafari || isChrome || isFirefox || isEdge || isIE);

const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: "https://login.microsoftonline.com/mercuria365.onmicrosoft.com",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  //
  //import { LogLevel } from "@azure/msal-browser";
  // 
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level, message, containsPii) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case LogLevel.Info:
  //           console.info(message);
  //           return;
  //         case LogLevel.Verbose:
  //           console.debug(message);
  //           return;
  //         case LogLevel.Warning:
  //           console.warn(message);
  //           return;
  //         default:
  //           return;
  //       }
  //     }
  //   }
  // }
};

function createPCA() {
  if (isNode) // if node is defined do not create application
    return;

  const response = new PublicClientApplication(msalConfig);
  response.addEventCallback((event) => {
    switch(event.eventType){
      case EventType.LOGIN_FAILURE :
      case EventType.SSO_SILENT_FAILURE :
      case EventType.ACQUIRE_TOKEN_FAILURE :
        if (response.errors === undefined) 
          response.errors = [];

        response.errors.push(event.error);
        console.log({event});
        break;
      case EventType.LOGIN_SUCCESS :
        response.errors = undefined;
        if (event.payload.account){
          response.setActiveAccount(event.payload.account);
        }
        break;
      default :
        break;
    }
  });

  return response;
}

export const msalInstance = createPCA();