import React, { Component } from 'react';
import PropTypes from 'prop-types'
import LoadingIcon from '../../shared/LoadingIcon';
import { formatTitle } from '../../../utility/analysis-search-utility';

export class SearchResultCategories extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    schemaCategories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string
    })),
    selectedSchemaValues: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })),
    loadCategories: PropTypes.func.isRequired,
    selectSchema: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.schemaCategories) {
      this.props.loadCategories();
    }
  }

  render() {
    let { isLoading, schemaCategories, selectedSchemaValues = [] } = this.props;
    if (isLoading === true)
      return <div>
        <LoadingIcon width='1.25em' /><span className='pl-2'>Loading categories...</span>
      </div>

    if (!schemaCategories || schemaCategories.length === 0){
      schemaCategories = [{name:'Native'}];
    }

    return <div>
      <div>
        <ul className="nav nav-tabs">
          {schemaCategories && schemaCategories.map((schemaCategoryList, xi) => <li key={`s${xi}`} onClick={() => this.props.selectSchema(schemaCategoryList.name)} className='nav-item' >
              <button type='link' className={`nav-link${schemaCategoryList.isSelected ? ' active' : ''}`} >{formatTitle(schemaCategoryList.name)}</button>
            </li>)}
          <li>&nbsp;</li>
        </ul>
      </div>
      <div className='fota-cross-schema-search-row-schema-categories'>
        <table className='table table-sm table-borderless'>
          <tbody>
            {selectedSchemaValues && selectedSchemaValues.map((s, si) => <tr key={`k${si}`}>
              <td>
                {s.name}
              </td>
              <td>
                {s.value}
              </td>
            </tr>)}
            {(!selectedSchemaValues || selectedSchemaValues.length === 0) &&
              <td colSpan={2}>
                No categories
              </td>
            }
          </tbody>
        </table>
      </div>
    </div>
  }
}