import { createAction } from '../utility/redux-utility';
import { hasRole } from '../auth';
import { FOTA_CONTRIBUTOR, FOTA_IN_LINE_ADJUSTMENTS, FOTA_WIZARD } from '../roles';

export const loadRoles =  () => async (dispatch, getState) => {
  const roles = {};
  roles.isContributor = await hasRole(FOTA_CONTRIBUTOR);
  roles.isWizard = await hasRole(FOTA_WIZARD);
  roles.isInlineAdjustmentsEditor = await hasRole(FOTA_IN_LINE_ADJUSTMENTS);
  dispatch(rolesLoaded(roles));
}

export const ROLES_LOADED = 'ROLES_LOADED';
const rolesLoaded = createAction(ROLES_LOADED, 'data');