import React, { useRef, useEffect, useState, Fragment } from 'react';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import useFetch from '../../../hooks/useFetch';
import { connect } from 'react-redux';
import { EVENTS_API_URL, ANALYSIS_API_URL } from '../../../config';
import { authFetch } from '../../../auth';
import { logInfoNotification, logErrorNotification } from '../../../actions/log';
import { replace } from "redux-first-history";
import Loading from '../../shared/Loading';
import Modal from '../../shared/Modal';
import Table from '../../shared/Table';
import AuditInfo from '../../shared/AuditInfo';
import { useParams } from 'react-router';

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUrl(key) {
      dispatch(replace(key));
    },
    logInfoNotification(message) {
      dispatch(logInfoNotification(message));
    },
    logErrorNotification(message) {
      dispatch(logErrorNotification(message));
    }
  };
};

function TimeseriesItem({ cellData, changeEnabledState }) {
  return (
    <tr>
      {changeEnabledState && <td>
        <input type='checkbox' checked={cellData.isExcluded ? false : true} onChange={e => changeEnabledState(cellData)} />
      </td>}
      <td>
        {cellData.identityId}
      </td>
      <td >
        {cellData.name}
      </td>
      <td>
        {cellData.source}
      </td>
      <td>
        {cellData.sourceId}
      </td>
    </tr>
  );
};

function Editor({ setIsloading, id, setId, logInfoNotification, logErrorNotification, updateUrl, datasetTimeseries, name, searchFilter, priorityLevel, reloadData, auditInfo }) {
  const [nameText, setNameText] = useState(name);
  const [searchFilterText, setSearchFilterText] = useState(searchFilter);
  const [timeseries, setTimeseries] = useState(datasetTimeseries);
  const [isDirty, setIsDirty] = useState(false);
  const [getSearchResultsUrl, setGetSearchResultsUrl] = useState('');
  const [, searchResultsData] = useFetch(getSearchResultsUrl);
  const [flattenedData, setFlattenedData] = useState();
  const [priority, setPriority] = useState(priorityLevel);
  const modalRef = useRef();

  useEffect(() => {
    if (searchResultsData != null)
      setTimeseries(searchResultsData.items);
  }, [searchResultsData]);

  useDidUpdateEffect(() => {
    setIsDirty(true);
  }, [nameText, searchFilterText]);

  useEffect(() => {
    setNameText(name);
    setSearchFilterText(searchFilter);
    setIsDirty(false);
  }, [name, searchFilter]);

  function onSearchFilter(e) {
    e.preventDefault();
    setGetSearchResultsUrl(`${EVENTS_API_URL}/datasets/from-search?searchFilter=${searchFilterText}`);
  }

  function onDelete(e) {
    e.preventDefault();
    modalRef.current.onShow({
      title: 'Delete Dataset',
      message: 'Are you sure you want to delete dataset?',
      onApply: () => {
        authFetch(`${EVENTS_API_URL}/datasets/${id}`, {
          method: 'DELETE'
        })
          .then(() => {
            logInfoNotification(`Delete "${nameText}" deleted`);
            updateUrl("/datasets");
          })
          .catch(error => {
            logErrorNotification(error);
          });
      }
    });
  }

  function onSave(e) {
    e.preventDefault();
    const saveData = {
      id: id,
      name: nameText,
      searchFilter: searchFilterText,
      priorityLevel: priority,
      items: timeseries
    };

    if (saveData.id === "new") {
      setIsloading(true);
      authFetch(`${EVENTS_API_URL}/datasets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(saveData)
        })
        .then(response => response.json())
        .then(key => {
          logInfoNotification(`Dataset "${saveData.name}" saved`);
          setId(key.dataSetId);
          updateUrl(`${key.dataSetId}/edit`);
          setIsDirty(false);
          setIsloading(false);
        })
        .catch(error => {
          logErrorNotification(error);
          setIsloading(false);
        });
      return;
    }

    setIsloading(true);
    authFetch(`${EVENTS_API_URL}/datasets/${saveData.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(saveData)
      })
      .then(response => response.json())
      .then(key => {
        logInfoNotification(`Dataset "${saveData.name}" saved`);
        setIsDirty(false);
        reloadData();
        setIsloading(false);
      })
      .catch(error => {
        logErrorNotification(error);
        setIsloading(false);
      });
  };

  function onGetFlattenedList() {
    setIsloading(true);
    setFlattenedData(undefined);
    const ids = timeseries.map(ts => 'ids=' + ts.identityId).join('&');
    authFetch(`${ANALYSIS_API_URL}/flattened-timeseries?${ids}`, {
        method: 'GET'
      })
      .then(response => response.json())
      .then(data => {
        setFlattenedData(data);
        setIsloading(false);
      })
      .catch(error => {
        logErrorNotification(error);
        setIsloading(false);
      });
  }

  function onApplyFlattenedList() {
    const newList = [];
    flattenedData.forEach(datasetItem => {
      var existingDatasetItem = timeseries.find(i => i.identityId === datasetItem.identityId);
      newList.push(existingDatasetItem || datasetItem);
    });

    setTimeseries(newList);
    setFlattenedData(undefined);
  }

  function changeEnabledState(datasetItem) {
    datasetItem = timeseries.find(i => i.identityId === datasetItem.identityId);
    const currentIndex = timeseries.indexOf(datasetItem);
    timeseries.splice(currentIndex, 1, {
      isExcluded: !datasetItem.isExcluded,
      identityId: datasetItem.identityId,
      name: datasetItem.name,
      source: datasetItem.source,
      sourceId: datasetItem.sourceId
    });
    setTimeseries([...timeseries]);
  }

  return (
    <div className='container-fluid'>

      <form className='my-4' noValidate>
        <div className='form-row'>
          <div className='col-md-9'>
            {id > 0 ? <div>Edit Dataset [id:{id}]</div> : <div>New Dataset</div>}
          </div>
          <div className='col-md-3 d-flex align-content-center justify-content-end'>
            <div className='btn-toolbar'>
              {/* <button type='button' className={`ml-1 btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={onSave}>
                <i className='fas fa-arrow-left fa-fw' />
                <span className='ml-8'>Close</span>
              </button> */}
            </div>
          </div>
        </div>
      </form>

      <div className='row' >
        <div className='col-md-4'>
          <div className='form-group'>
            <label className='font-weight-bold'>Name</label>
            <input className='form-control' type='text' value={nameText} onChange={e => setNameText(e.target.value)} />
          </div>
          <div className='form-group'>
            <label className='font-weight-bold'>Search Filter</label>
            <div className='input-group'>
              <input className='form-control' type='text' value={searchFilterText} onChange={e => setSearchFilterText(e.target.value)} />
              <div className="input-group-append">
                <button type='button' className='btn btn-outline-secondary text-nowrap' onClick={onSearchFilter}>
                  <i className='fas fa-search fa-fw' />
                  <span className='ml-2'>Search</span>
                </button>
              </div>
            </div>

            <div style={{ marginLeft: "25px", opacity: 0.5 }}>
              <strong>Examples</strong><br />
              search.in(id, '14688175,14688111')<br />
              id eq '14688175'<br />
              search.ismatch('*Consumption Actual (Mercuria)','name','simple','all')<br />
              search.in(unit, 'mcm/d,mcm') and dataType eq 'Fundamental'<br />
            </div>
          </div>

          <div className='form-check d-inline-block ml-3'>
                <input className='form-check-input' type="checkbox" checked={priority === 1} name="filter-by" id="filter-by-dataset" onChange={(e) => setPriority(e.target.checked ? 1 : 2)} />
                <label className='form-check-label' htmlFor='filter-by-dataset'>High priority</label>
          </div>
          
          {auditInfo && <div className='form-group'>
            <AuditInfo owner={auditInfo.owner} updatedBy={auditInfo.updatedBy} updatedDateUtc={auditInfo.updatedUtc} createdDateUtc={auditInfo.createdUtc}/>
          </div>}

          <div className='form-group form-footer'>
            {id === 'new' ? null :
              <button type='submit' className='btn btn-secondary' onClick={onDelete}>
                <i className='fas fa-trash fa-fw' />
                <span className='ml-8'>Delete</span>
              </button>}
            <div className='btn-group'>
              <button type='submit' className={`ml-1 btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={onSave}>
                <i className='fas fa-save fa-fw' />
                <span className='ml-8'>Save</span>
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-8'>
          <label className='font-weight-bold'>Associated Timeseries ({timeseries != null ? timeseries.length : 0} timeseries)</label>
          <div className='sticky-table' style={{ height: '300px', overflowY: 'auto', border: '1px solid #AAAAAA' }}>
            <Table emptyMessage='No timeseries' useStickyHeader={ true } className='datasets-list' columns={[{ key: 'isExcluded' }, { key: 'identityId', title: 'Id' }, { key: 'name', title: 'Name' }, { key: 'source', title: 'Source' }, { key: 'sourceId', title: 'Source Id' }]} data={timeseries} useTBody={true}>
              <TimeseriesItem changeEnabledState={changeEnabledState} />
            </Table>
          </div>
          <div className='form-group form-footer'>
            <button type='button' className={`ml-1 btn btn-primary`} onClick={onGetFlattenedList}>
              <i className='fas fa-list fa-fw' />
              <span className='ml-8'>Get Flattened List</span>
            </button>
          </div>
          {flattenedData && <Fragment>
            <label className='font-weight-bold'>Flattened Timeseries ({flattenedData.length} timeseries)</label>
            <div className='sticky-table' style={{ height: '300px', overflowY: 'auto', border: '1px solid #AAAAAA' }}>
              <Table emptyMessage='No timeseries' useStickyHeader={ true } className='datasets-list' columns={[{  key: 'identityId',  title: 'Id'}, {  key: 'name',  title: 'Name'}, {  key: 'source',  title: 'Source'}, {  key: 'sourceId',  title: 'Source Id'}]} data={flattenedData} useTBody={true}>
                <TimeseriesItem />
              </Table>
            </div>
            <div className='form-group form-footer'>
              <button type='button' className={`ml-1 btn btn-primary`} onClick={onApplyFlattenedList}>
                <i className='fas fa-arrow-up fa-fw' />
                <span className='ml-8'>Apply</span>
              </button>
            </div>
          </Fragment>}
        </div>
      </div>

      <Modal ref={modalRef} />
    </div>);
}

const DatasetEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ logInfoNotification, logErrorNotification, updateUrl }) => {
  const params = useParams();
  const [id, setId] = useState(params.id);
  const [isLoading, setIsloading] = useState(false);
  const [getDatasetUrl, setGetDatasetUrl] = useState('');
  const [, datasetData, datasetDataLoading] = useFetch(getDatasetUrl);

  useEffect(() => {
    if (id !== "new") {
      setGetDatasetUrl(`${EVENTS_API_URL}/datasets/${id}`);
    }
  }, [id]);

  function reloadData() {
    setGetDatasetUrl(`${EVENTS_API_URL}/datasets/${id}?${Date.now()}`);
  }

  if (id === "new") {
    return <Loading isLoading={isLoading}><Editor
      setIsloading={setIsloading}
      id={id}
      setId={setId}
      name={""}
      searchFilter={""}
      priorityLevel={2}
      datasetTimeseries={[]}
      logInfoNotification={logInfoNotification}
      logErrorNotification={logErrorNotification}
      updateUrl={updateUrl}
      reloadData={reloadData} />
    </Loading>;
  }

  return <Loading isLoading={isLoading || !datasetData || datasetDataLoading}>
    {!datasetData ? null :
      <Editor
        setIsloading={setIsloading}
        id={id}
        name={datasetData.name}
        searchFilter={datasetData.searchFilter}
        priorityLevel={datasetData.priorityLevel}
        datasetTimeseries={datasetData.items}
        logInfoNotification={logInfoNotification}
        logErrorNotification={logErrorNotification}
        updateUrl={updateUrl}
        reloadData={reloadData}
        auditInfo={{
          createdUtc: datasetData.createdUtc,
          owner: datasetData.owner,
          updatedBy: datasetData.updatedBy,
          updatedUtc: datasetData.updatedUtc
        }} />}
  </Loading>;
});

export default DatasetEdit;