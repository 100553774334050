import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import { SchemaSelector } from './SchemaSelector';
import {
  analysisCrossSchemaSearch,
  analysisCrossSchemaSearchToggleSchema,
} from '../../../actions/analysis-cross-schema-search';
import { DebugPopup } from '../../shared/DebugPopup';
import JsonEditor from '../../shared/JsonEditor';

export const SchemaSelectorConnected = connect(
  (state, { stateContext = 'default' }) => ({
    isMultiSelect: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'isMultiSelect']),
    schemas: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'schemas']),
    userSchemas: state.getIn(['analysisCrossSchemaSearch', stateContext, 'userSchemas'])
  }),
  (dispatch, { stateContext = 'default', searchHandler }) => ({
    toggleSchema(schemaName, isMultiSelect) {
      dispatch(analysisCrossSchemaSearchToggleSchema(stateContext, schemaName));
      if (!isMultiSelect) {
        if (searchHandler) {
          searchHandler(stateContext);
        } else {
          dispatch(analysisCrossSchemaSearch(stateContext));
        }
      }
    }
  })
)(({ isMultiSelect, schemas, toggleSchema, userSchemas }) => {
  const _schemas = toJS(schemas) ?? [];

  function onToggleSchema(schemaName) {
    toggleSchema(schemaName, isMultiSelect);
  }

  return <><SchemaSelector toggleSchema={onToggleSchema} schemas={_schemas} />
    <DebugPopup>
      {() => <JsonEditor data={{
        schemas: _schemas,
        userSchemas: userSchemas
      }}/>}
    </DebugPopup>
  </>
});