import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import Loading from '../../shared/Loading';
import {
  homepageNewItemModalInitialise,
  homepageNewItemModalSetScope,
  homepageNewItemModalSetType,
  homepageNewItemModalSetData,
  homepageNewItemModalSetPath,
  homepageNewItemModalSave  
} from '../../../actions/homepageNewItemModal';
import {
  homepageRefresh
} from '../../../actions/homepage';
import Tooltip from '../../shared/Tooltip';

const badCharacters = [
  191, // /
  220  // \
];

const badCharactersWithShift = [
  56,  // *
  186, // :
  188, // <
  190, // >
  191, // ?
  220  // |
];

const isBadCharacter = e => e.shiftKey ? badCharactersWithShift.includes(e.keyCode) : badCharacters.includes(e.keyCode);

const newItemTypes = [
  { value: 'WebLink', label: 'Web Link' },
  { value: 'NetworkFile', label: 'Network File' },
  { value: 'Dashboard', label: 'Dashboard' }
];

const newItemScopes = [
  { value: 'Private', label: 'Private' },
  { value: 'Shared', label: 'Shared' }
];

const HomeNewItemContent = connect(
  (state) => ({
    isInitialised: state.getIn(['homepageNewItemModal', 'isInitialised']),
    isLoading: state.getIn(['homepageNewItemModal', 'isLoading']),
    isSaving: state.getIn(['homepageNewItemModal', 'isSaving']),
    sharedFolders: state.getIn(['homepageNewItemModal', 'sharedFolders']),
    mode: state.getIn(['homepageNewItemModal', 'mode']),
    type: state.getIn(['homepageNewItemModal', 'type']),
    scope: state.getIn(['homepageNewItemModal', 'scope']),
    data: state.getIn(['homepageNewItemModal', 'data']),
    folderPath: state.getIn(['homepageNewItemModal', 'folderPath']),
    subFolderPath: state.getIn(['homepageNewItemModal', 'subFolderPath']),
    name: state.getIn(['homepageNewItemModal', 'name']),
    fullPath: state.getIn(['homepageNewItemModal', 'fullPath']),
    selectedWorkspacePath: state.getIn(['homepageNewItemModal', 'selectedWorkspacePath'])
  }),
  (dispatch, { mode, workspaceId }) => ({
    initialise() {
      dispatch(homepageNewItemModalInitialise(mode, workspaceId));
    },
    setType(value) {
      dispatch(homepageNewItemModalSetType(value));
    },
    setScope(value) {
      dispatch(homepageNewItemModalSetScope(value));
    },
    setData(data) {
      dispatch(homepageNewItemModalSetData(data));
    },
    setPath(data) {
      dispatch(homepageNewItemModalSetPath(data));
    },
    save() {
      dispatch(homepageNewItemModalSave());
    },
    refreshHome() {
      dispatch(homepageRefresh());
    }
  })
)(({ isInitialised, isLoading, isSaving, sharedFolders, initialise, refreshHome, save, onSubmit: onDismissModal,
  mode, scope, type, data, folderPath, subFolderPath, name, fullPath, selectedWorkspacePath, setType, setScope, setData, setPath }) => {
  const isShared = scope !== 'Private';
  const canSetData = type !== 'Dashboard';

  let validationMessages = [];
  if (!name) validationMessages.push('Name is required');
  if (isShared && !folderPath) validationMessages.push('Folder path is required when sharing');
  else if (!folderPath && !!subFolderPath) validationMessages.push('Folder path is required when specifying a sub-folder');
  const canSave = !isLoading && !validationMessages.length;

  const _sharedFolders = toJS(sharedFolders, []);

  useEffect(() => {
    if (!isInitialised) initialise();
  }, [initialise, isInitialised]);

  function onChangeFolderPath(e) {
    if (isBadCharacter(e)) e.preventDefault();
  }

  function onChangeWorkspaceName(e) {
    if (isBadCharacter(e)) e.preventDefault();
    if (!canSave && e.keyCode === 13) e.preventDefault();
  }

  function onSubmit(e) {
    e.preventDefault();
    onDismissModal(e);
    refreshHome();
    save();
  }

  let modeTitle = '';
  switch (mode) {
    case 'Edit': modeTitle = `Edit item '${selectedWorkspacePath}'...`; break;
    default: modeTitle = `Create a new item...`; break;
  }

  return (
    <form className='modal-content' onSubmit={ onSubmit }>
      <div className='modal-header'>
        <h5 className='modal-title'>{modeTitle}</h5>
        <button type='button' className='close' data-dismiss='modal'>&times;</button>
      </div>
      <div className='modal-body'>
        <div className='card mb-3 d-flex flex-row flex-nowrap'>
          <div className='card-body p-2 flex-1 d-flex flex-column justify-content-between'>
            <Loading isLoading={isLoading || isSaving} message='Loading...'>
              <Fragment>
                <label className='font-weight-bold'>Type</label>
                <div className='form-group'>
                  { newItemTypes.map(({ value, label }) => (
                    <div key={value} className='form-check form-check-inline'>
                      <input className='form-check-input' type='radio' name='homepage-new-item-type' id={`homepage-new-item-${value}-type`} value={value}
                             checked={type === value}
                             onChange={e => setType(e.target.value)} />
                      <label className='form-check-label' htmlFor={`homepage-new-item-${value}-type`}>{label}</label>
                    </div>
                  )) }                  
                </div>
                <label className='font-weight-bold'>Scope</label>
                <div className='form-group'>
                  { newItemScopes.map(({ value, label }) => (
                    <div key={value} className='form-check form-check-inline'>
                      <input className='form-check-input' type='radio' name='homepage-new-item-scope' id={`homepage-new-item-${value}-scope`} value={value}
                             checked={scope === value}
                             onChange={e => setScope(e.target.value)} />
                      <label className='form-check-label' htmlFor={`homepage-new-item-${value}-scope`}>{label}</label>
                    </div>
                  )) }
                </div>
                { canSetData && (
                  <Fragment>
                    <label className='font-weight-bold'>Url</label>
                    <div className='form-group'>
                      <textarea className='w-100' value={data} rows='4' maxLength='400' onChange={e => setData(e.target.value)} style={{ resize: 'none' }} />
                    </div>
                  </Fragment>
                ) }
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <label className='input-group-text'>Name</label>
                  </div>
                  <input type='text' className='form-control' value={name || ''} maxLength='80'
                         onKeyDown={onChangeWorkspaceName}
                         onChange={e => setPath({ name: e.target.value })} />
                </div>
                <div className='input-group input-group-sm mt-2'>
                  <div className='input-group-prepend'>
                    <label className='input-group-text'>Folder Path</label>
                  </div>
                  { !isShared ? (
                    <input type='text' className='form-control' value={folderPath || ''} maxLength='250'
                           onKeyDown={onChangeFolderPath}
                           onChange={e => setPath({ folderPath: e.target.value })} />
                  ) : (
                    <select id='homepage-modal-content-image' className='custom-select'
                            onChange={e => setPath({ folderPath: e.target.value })} value={folderPath || ''}>
                      <option />
                      { _sharedFolders.map(({ folderPath }) => <option key={folderPath} value={folderPath}>{folderPath}</option>) }
                    </select>
                  ) }
                </div>
                <div className='input-group input-group-sm mt-2'>
                  <div className='input-group-prepend'>
                    <label className='input-group-text'>Sub-folder Path</label>
                  </div>
                  <input type='text' className='form-control' value={subFolderPath || ''} maxLength='250'
                         onChange={e => setPath({ subFolderPath: e.target.value })} />
                </div>
                <div className='text-muted text-italic mt-2'>
                  <strong>Full Path:</strong> {fullPath}
                </div>
              </Fragment>
            </Loading>
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
        <Tooltip placement='right' title={ (validationMessages.length > 0) && <div style={{ maxWidth: '400px', textAlign: 'left', wordBreak: 'break-word' }}>
              { validationMessages.map((i, ix) => <div key={ ix }>{ i }</div>) }
            </div>} >
           <button type='submit' className={ 'btn btn-primary' + (!canSave ? ' disabled' : '') } onClick={ !canSave ? e => e.preventDefault() : undefined }>Save</button>
        </Tooltip>
      </div>
    </form>
  );
});

export default HomeNewItemContent;