import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import { areEmpty } from '../../../utility/text-utility';
import Loading from '../../shared/Loading';
import Table from '../../shared/Table';
import Timezone from '../../shared/Timezone';
import FormattedDateTime from '../../shared/FormattedDateTime';
import {
  workspaceModalInitialise,
  workspaceModalSetScope,
  workspaceModalSetPath,
  workspaceModalSave
} from '../../../actions/workspaceModal';
import {
  homepageRefresh
} from '../../../actions/homepage';
import Tooltip from '../../shared/Tooltip';

const badCharacters = [
  191, // /
  220  // \
];

const badCharactersWithShift = [
  56,  // *
  186, // :
  188, // <
  190, // >
  191, // ?
  220  // |
];

const isBadCharacter = e => e.shiftKey ? badCharactersWithShift.includes(e.keyCode) : badCharacters.includes(e.keyCode);

const sharedFolderColumns = [{
  title: 'Folder'
}];

const workspaceColumns = [{
  title: 'Name'
}, {
  title: 'Path'
}, {
  title: 'Owner'
}, {
  title: <span>Last Updated <Timezone/></span>
}];

const getFullFolderPath = (folderPath, subFolderPath) => `${folderPath || ''}/${subFolderPath || ''}`.replace(/\/+/g, '/').replace(/^\/|\/$/, '');

function FolderRow({ rawData, setPath, currentFolderPath }) {
  const { folderPath } = rawData;
  const isActive = folderPath === currentFolderPath || areEmpty(folderPath, currentFolderPath);

  return (
    <tr className={ isActive ? 'table-primary' : '' }
        style={{ userSelect: 'none', cursor: 'pointer' }} onClick={ () => setPath({ folderPath }) }>
      <td className='w-50 text-nowrap'>
        <i className='fas fa-folder fa-fw' /> { folderPath || (
          <span className='text-muted'>/</span>
        ) }
      </td>
    </tr>
  );
}

function WorkspaceRow({ rawData, setPath, currentFolderPath, currentSubFolderPath, currentName }) {
  const { folderPath, subFolderPath, name, owner, updatedUtc } = rawData;
  const isActive = (folderPath === currentFolderPath || areEmpty(folderPath, currentFolderPath))
                && (subFolderPath === currentSubFolderPath || areEmpty(subFolderPath, currentSubFolderPath))
                && (name === currentName || areEmpty(name, currentName));

  const fullFolderPath = getFullFolderPath(folderPath, subFolderPath);
  const workspaceRowRef = useRef();

  useEffect(() => {
    if (isActive) workspaceRowRef.current.scrollIntoView({ block: 'center' });
  }, [isActive]);

  return (
    <tr ref={ workspaceRowRef } className={ isActive ? 'table-primary' : '' }
        style={{ userSelect: 'none', cursor: 'pointer' }} onClick={ () => setPath({ folderPath, subFolderPath, name }) }>
      <td className='w-50 text-nowrap'>{ name }</td>
      <td className='w-50 text-nowrap'>{ fullFolderPath }</td>
      <td className='w-25 text-nowrap'>{ owner }</td>
      <td className='text-nowrap'><FormattedDateTime>{ updatedUtc }</FormattedDateTime></td>
    </tr>
  );
}

const WorkspaceModalContent = connect(
  (state) => ({
    isInitialised: state.getIn(['workspaceModal', 'isInitialised']),
    isLoading: state.getIn(['workspaceModal', 'isLoading']),
    isSaving: state.getIn(['workspaceModal', 'isSaving']),
    privateWorkspaces: state.getIn(['workspaceModal', 'private']),
    sharedWorkspaces: state.getIn(['workspaceModal', 'filteredShared']),
    sharedFolders: state.getIn(['workspaceModal', 'sharedFolders']),
    mode: state.getIn(['workspaceModal', 'mode']),
    scope: state.getIn(['workspaceModal', 'scope']),
    folderPath: state.getIn(['workspaceModal', 'folderPath']),
    subFolderPath: state.getIn(['workspaceModal', 'subFolderPath']),
    name: state.getIn(['workspaceModal', 'name']),
    fullPath: state.getIn(['workspaceModal', 'fullPath']),
    selectedWorkspacePath: state.getIn(['workspaceModal', 'selectedWorkspacePath'])
  }),
  (dispatch, { mode, workspaceId }) => ({
    initialise() {
      dispatch(workspaceModalInitialise(mode, workspaceId));
    },
    setScope(value) {
      dispatch(workspaceModalSetScope(value));
    },
    setPath(data) {
      dispatch(workspaceModalSetPath(data));
    },
    save(onSuccessCallback) {
      dispatch(workspaceModalSave(workspaceId, onSuccessCallback));
    },
    refreshHome() {
      dispatch(homepageRefresh());
    }
  })
)(({ isInitialised, isLoading, isSaving, privateWorkspaces, sharedWorkspaces, sharedFolders, initialise, refreshHome, save, onSubmit: onDismissModal,
  mode, scope, folderPath, subFolderPath, name, fullPath, selectedWorkspacePath, setScope, setPath }) => {
  const isShared = scope !== 'Private';

  let validationMessages = [];
  if (!name) validationMessages.push('Name is required');
  if (isShared && !folderPath) validationMessages.push('Folder path is required when sharing');
  else if (!folderPath && !!subFolderPath) validationMessages.push('Folder path is required when specifying a sub-folder');
  const canSave = !isLoading && !validationMessages.length;

  let modeTitle = '';
  switch (mode) {
    case 'SaveAs': modeTitle = `Save '${selectedWorkspacePath}' as...`; break;
    case 'Edit': modeTitle = `Edit '${selectedWorkspacePath}'...`; break;
    default: break;
  }

  const _workspaces = toJS(isShared ? sharedWorkspaces : privateWorkspaces, []);
  const _sharedFolders = toJS(sharedFolders, []);

  useEffect(() => {
    if (!isInitialised) initialise();
  }, [initialise, isInitialised]);

  function onChangeFolderPath(e) {
    if (isBadCharacter(e)) e.preventDefault();
  }

  function onChangeWorkspaceName(e) {
    if (isBadCharacter(e)) e.preventDefault();
    if (!canSave && e.keyCode === 13) e.preventDefault();
  }

  function onSubmit(e) {
    e.preventDefault();

    save(() => {
      onDismissModal(e);
      refreshHome();
    });
  }

  const nameInputRef = useRef();

  return (
    <form className='modal-content' onSubmit={ onSubmit }>
      <div className='modal-header'>
        <h5 className='modal-title'>{ modeTitle }</h5>
        <button type='button' className='close' data-dismiss='modal'>&times;</button>
      </div>
      <div className='modal-body'>
        <Loading isLoading={ isLoading || isSaving } message='Loading...'>

          <ul className='nav nav-tabs mb-2'>
            <li className='nav-item'>
              <button className={ `nav-link ${!isShared ? 'active' : ''}` } type='button' onClick={ () => setScope('Private') }>Private</button>
            </li>
            <li className='nav-item ml-1'>
              <button className={ `nav-link ${isShared ? 'active' : ''}` } type='button' onClick={ () => setScope('Shared') }>Shared</button>
            </li>
          </ul>

          <div className='d-flex' style={{ position: 'relative' }}>
            { isShared && (
              <div className='table-responsive mb-3 mr-3' style={{ height: '20vh', maxWidth: '10vw', fontSize: '.9em' }}>
                <Table className='table-hover' columns={ sharedFolderColumns } data={ _sharedFolders } useTBody={ true } emptyMessage='No existing folders'>
                  <FolderRow setPath={ setPath } currentFolderPath={ folderPath } />
                </Table>
              </div>
            ) }
            <div className='table-responsive mb-3' style={{ height: '20vh', fontSize: '.9em' }}>
              <Table className='table-hover' columns={ workspaceColumns } data={ _workspaces } useTBody={ true } emptyMessage='No existing workspaces'>
                <WorkspaceRow setPath={ setPath } currentFolderPath={ folderPath } currentSubFolderPath={ subFolderPath } currentName={ name } />
              </Table>
            </div>
          </div>

          <div className='input-group'>
            <div className='input-group-prepend'>
              <label className='input-group-text'>Name</label>
            </div>
            <input ref={ nameInputRef } type='text' className='form-control' value={ name || '' } maxLength='80'
                   onKeyDown={ onChangeWorkspaceName }
                   onChange={ e => setPath({ name: e.target.value }) } />
          </div>

          { !isShared && (
            <div className='input-group input-group-sm mt-2'>
              <div className='input-group-prepend'>
                <label className='input-group-text'>Folder Path</label>
              </div>
              <input type='text' className='form-control' value={ folderPath || '' } maxLength='250'
                     onKeyDown={ onChangeFolderPath }
                     onChange={ e => setPath({ folderPath: e.target.value }) } />
            </div>
          ) }

          <div className='input-group input-group-sm mt-2'>
            <div className='input-group-prepend'>
              <label className='input-group-text'>Sub-folder Path</label>
            </div>
            <input type='text' className='form-control' value={ subFolderPath || '' } maxLength='250'
                   onChange={ e => setPath({ subFolderPath: e.target.value }) } />
          </div>

          <div className='text-muted text-italic mt-2'>
            <strong>Full Path:</strong> { fullPath }
          </div>

        </Loading>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
        <Tooltip placement='right' title={(validationMessages.length > 0) && <div style={{ maxWidth: '400px', textAlign: 'left', wordBreak: 'break-word' }}>
              { validationMessages.map((i, ix) => <div key={ ix }>{ i }</div>) }
            </div>}>
          <button type='submit' className={ 'btn btn-primary' + (!canSave ? ' disabled' : '') } onClick={ !canSave ? e => e.preventDefault() : undefined }>Save</button>
        </Tooltip>
      </div>
    </form>
  );
});

export default WorkspaceModalContent;