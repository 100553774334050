import React, { useState } from 'react';
import { connect } from 'react-redux';
import PathNavLink from '../../shared/PathNavLink';
import {
  analysisHideOverlay,
  analysisShowOverlay,
  analysisRefresh,
  analysisChartDataExport,
  analysisCopyWorkspaceUrlToClipboard
} from '../../../actions/analysis';
import { Route, Routes } from 'react-router';
import { AnalysisWorkspaceSaveAsModal } from '../../shared/WorkspaceSaveAsModal';
import { DebugPopup } from '../../shared/DebugPopup';
import { WorkspaceEditorConnected } from './WorkspaceEditorConnected';

const AnalysisSideBar = connect(
  (state) => ({
    history: state.getIn(['analysisCrossSchemaSearch', 'location', 'history']),
    workspace: state.getIn(['analysis', 'workspace']),
    isHighChartSettingsHidden: state.getIn(['analysis', 'ui', 'panelWidths', 'chartSettings', 'isHidden']),
    isChartSettingsHidden: state.getIn(['analysis', 'ui', 'panelWidths', 'reportSettings', 'isHidden']),
    isSeriesSettingsHidden: state.getIn(['analysis', 'ui', 'panelWidths', 'seriesSettings', 'isHidden']),
    panelName: state.getIn(['analysis', 'overlay', 'panelName']),
    timeseries: state.getIn(['analysis', 'workspace', 'timeseries']),
    isDirty: state.getIn(['analysis', 'workspace', 'isDirty'])
  }),
  (dispatch) => ({
    hideOverlay() {
      dispatch(analysisHideOverlay());
    },
    showOverlay(panelName) {
      dispatch(analysisShowOverlay(panelName));
    },
    refresh() {
      dispatch(analysisRefresh());
    },
    tableExport() {
      dispatch(analysisChartDataExport());
    },
    copyWorkspaceUrlToClipboard() {
      dispatch(analysisCopyWorkspaceUrlToClipboard());
    }
  }))(({ toggleIsCollapsed, history,
    isHighChartSettingsHidden, isChartSettingsHidden, isSeriesSettingsHidden,
    workspace, timeseries, showOverlay,
    refresh,
    tableExport,
    isDirty,
    copyWorkspaceUrlToClipboard
  }) => {
    const isSavedWorkspace = workspace && !isNaN(workspace.get('id')) && workspace.get('id') > 0;
    const canShowShare = isSavedWorkspace && workspace.get('scope') !== 'Private';
    const seriesCount = timeseries ? timeseries.size : 0; 
    
    function getLocation(pathname){
      return {
        to: pathname,
        search: history.get(pathname) || ''
      }
    }

    function ElementChartSettings() {
      if (!isChartSettingsHidden) return <></>
      return <>      
        <button title='View report criteria' className='fota-toggle-button btn btn-primary shadow mb-2' type='button' onClick={() => showOverlay('ReportSettingsPanel')}>
          <i className='fas fa-sliders-h fa-fw' />
        </button>
        <button title='Export data' className='fota-toggle-button btn btn-primary shadow mb-2' type='button' onClick={() => tableExport()}>
          <i className='fas fa-download fa-fw' />
        </button>
      </>
    }

    function ElementChartRefresh() {
      if (!isChartSettingsHidden) return <></>
      return <>
        <button title='Refresh report' className={`fota-toggle-button btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'} shadow mb-2`} type='button' onClick={refresh}>
          <i className='fas fa-sync-alt fa-fw' />
        </button>
      </>
    }

    function ElementHighChartSettings() {
      if (!isHighChartSettingsHidden) return <></>
      return <>
        <button title='View chart settings' className='fota-toggle-button btn btn-primary shadow mb-2' type='button' onClick={() => showOverlay('HighChartSettings')}>
          <i className='fas fa-chart-area fa-fw' />
        </button>
      </>
    }

    function onShare(){
      copyWorkspaceUrlToClipboard();
    }

    return <>
      <div data_id='analysis-sidebar' className='list-group'>
        <button className='list-group-item list-group-item-action bg-secondary text-light' type='button' onClick={toggleIsCollapsed}>
          <i className='fas fa-chevron-right fa-fw'></i>
          <i className='fas fa-chevron-left fa-fw'></i>
        </button>
        <PathNavLink data_id='sidebar-search' exact className='list-group-item list-group-item-action' {...getLocation('/analysis')}>
          <i className="fas fa-search fa-fw"></i>
          <span className='ml-4'>Search</span>
        </PathNavLink>
        <PathNavLink data_id='sidebar-chart' exact className='list-group-item list-group-item-action' {...getLocation('/analysis/chart')}>
          <i className='fas fa-chart-line fa-fw'></i>
          <span className='ml-4'>Chart</span>
        </PathNavLink>
        <PathNavLink data_id='sidebar-split-chart' exact className='list-group-item list-group-item-action' {...getLocation('/analysis/split-chart')}>
          <i className="fas fa-table fa-fw"></i>
          <span className='ml-4'>Split Chart</span>
        </PathNavLink>
        <PathNavLink data_id='sidebar-chart-data' exact className='list-group-item list-group-item-action' {...getLocation('/analysis/chart-data')}>
          <i className="fas fa-list fa-fw"></i>
          <span className='ml-4'>Chart Data</span>
        </PathNavLink>
      </div>

      <div className='list-group p-2' style={{maxWidth:'55px'}}>
        {isSeriesSettingsHidden && (
          <div style={{position:'relative'}}>
            <button title='View selected time series' className='fota-toggle-button btn btn-primary shadow mb-2' type='button' onClick={() => showOverlay('SeriesSettingsPanel')}>
              <i className='fas fa-box-open fa-fw' />
            </button>
            {seriesCount > 0 && <span className='series-count'>{seriesCount}</span>}
          </div>
        )}

        <SaveWorkspaceButtonAndModal/>

        <Routes>
          {<Route path='/chart/' element={<ElementChartSettings />} />}
          {<Route path='/split-chart/*' element={<ElementChartSettings />} />}
          {<Route path='/chart-data/*' element={<ElementChartSettings />} />}
        </Routes>
        
        <Routes>
          {<Route path='/chart/' element={<ElementHighChartSettings />} />}
          {<Route path='/split-chart/*' element={<ElementHighChartSettings />} />}
        </Routes>

        <Routes>
          {<Route path='/chart/' element={<ElementChartRefresh />} />}
          {<Route path='/split-chart/*' element={<ElementChartRefresh />} />}
          {<Route path='/chart-data/*' element={<ElementChartRefresh />} />}
        </Routes>

        {canShowShare && <button className='fota-toggle-button btn shadow mb-2 btn-primary' title='Copy Url to clipboard' type='button' onClick={onShare} >
          <i className='fas fa-share fa-fw' />
        </button>}

        <DebugPopup tooltipText='debug workspace view' linkClassName='btn mt-3 btn-light'>{() => <WorkspaceEditorConnected/>}</DebugPopup>
      </div>
    </>
  });

const SaveWorkspaceButtonAndModal = connect(
  (state) => ({
  }),
  (dispatch) => ({
  })
)(() => {
  const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState(false);

  return <>
   <button table='Save workspace' className='fota-toggle-button btn btn-primary shadow mb-2' type='button' onClick={() => setIsSaveAsModalVisible(true)}>
      <i className='fas fa-save fa-fw' />
   </button>
   {isSaveAsModalVisible && <AnalysisWorkspaceSaveAsModal modalTitle='Save Workspace' isVisible={ isSaveAsModalVisible } setVisibility={ setIsSaveAsModalVisible } isFoldersVisible={ true } showScopeSelector={ true } />}
  </>
});

export default AnalysisSideBar;