import React, { useState } from 'react';
import useForceReMountEffect from '../../../hooks/useForceReMountEffect';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import AnalysisChart from './AnalysisChart';

export default function AnalysisSplitChart({ reflowSwitch, columnCount = 4, chartSeries = [], chartCategories, hasXAxis, comparisonMode, lens, chartStartDate, chartEndDate }) {
  const [isMounted, forceReMount] = useForceReMountEffect();
  const [titles, setTitles] = useState();

  useDidUpdateEffect(() => forceReMount(), [hasXAxis]);

  useDidUpdateEffect(() => {
    const newTitles = chartSeries.map(cs => cs.name).join('|');
    if (newTitles !== titles) {
      setTitles(newTitles);
      forceReMount();
    }
  }, [chartSeries, titles, setTitles]);

  return (
    <div className='container-fluid' style={{ overflowY: 'auto' }}>
      <div className='card-columns' style={{ columnCount }}>
        { isMounted && chartSeries.map(cs => {
          const name = cs.name;
          return (
            <AnalysisChart key={ cs.id }
                            chartSeries={ [cs] }
                            chartCategories={ chartCategories }
                            hasXAxis={ hasXAxis }
                            hideLegend={ true }
                            syncCharts={ true }
                            reflowSwitch={ reflowSwitch }
                            comparisonMode={ comparisonMode }
                            lens={ lens }
                            title={ name.substring(0, 50) }
                            titleSize='12px'
                            chartStartDate={ chartStartDate }
                            chartEndDate={ chartEndDate } />
          );
        }) }
      </div>
    </div>
  );
};