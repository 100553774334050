import React, { Component } from 'react';
import PropTypes from 'prop-types'

export class Toolbar extends Component {
  static propTypes = {
    isMultiSelect: PropTypes.bool.isRequired,

    filtersCount: PropTypes.number.isRequired,
    selectCount: PropTypes.number.isRequired,
    unselectCount: PropTypes.number.isRequired,
    favouriteCount: PropTypes.number.isRequired,
    unfavouriteCount: PropTypes.number.isRequired,

    query: PropTypes.string.isRequired,
    setQuery: PropTypes.func.isRequired,

    customFilter: PropTypes.string.isRequired,
    customFilterHeight: PropTypes.number.isRequired,
    setCustomFilter: PropTypes.func.isRequired,
    updateCustomFilterHeight: PropTypes.func.isRequired,

    data: PropTypes.object.isRequired,
    enableFavouritesFilter: PropTypes.bool.isRequired,
    toggleFavouritesFilter: PropTypes.func.isRequired,

    addFavourites: PropTypes.func.isRequired,
    removeFavourites: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    exportData: PropTypes.func.isRequired,

    setSearchSelectionMode: PropTypes.func.isRequired,
    addRangeSelection: PropTypes.func.isRequired,
    removeRangeSelection: PropTypes.func.isRequired,

    enableExpandFacets: PropTypes.bool.isRequired,
    toggleExpandFacets: PropTypes.func.isRequired,

    enableExpandDetails: PropTypes.bool.isRequired,
    toggleExpandDetails: PropTypes.func.isRequired,

    discontinuedFilterMonths: PropTypes.number.isRequired,
    enableDiscontinuedFilter: PropTypes.bool.isRequired,
    toggleDiscontinuedFilter: PropTypes.func.isRequired,

    displayOptions: PropTypes.shape({
      exportButton: PropTypes.bool.isRequired,
      addRemoveButtons: PropTypes.bool.isRequired,
      favouritesToggle: PropTypes.bool.isRequired,
      excludeDiscontinued: PropTypes.bool.isRequired,
      expandDetails: PropTypes.bool.isRequired,
      expandFacets: PropTypes.bool.isRequired,
      multiSelectToggle: PropTypes.bool.isRequired
    }).isRequired
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectAll() {
    const dataIds = this.props.data.map(i => i.get('id')).toArray();
    this.props.addRangeSelection(dataIds, this.props.data.toJS());
  }

  onUnselectAll() {
    const dataIds = this.props.data.map(i => i.get('id')).toArray();
    this.props.removeRangeSelection(dataIds);
  }

  onFavouriteAll() {
    const dataIds = this.props.data.map(i => i.get('id')).toArray();
    this.props.addFavourites(dataIds, this.props.data.toJS());
  }

  onUnfavouriteAll() {
    const dataIds = this.props.data.map(i => i.get('id')).toArray();
    this.props.removeFavourites(dataIds);
  }

  onSearch(e) {
    e.preventDefault();
    this.props.search();
  }

  onClearQuery() {
    this.props.setQuery('');
    if (!this.props.isMultiSelect)
      this.props.search();
  }

  onClearCustomFilter() {
    this.props.setCustomFilter('');
    if (!this.props.isMultiSelect)
      this.props.search();
  }

  onCustomFilterKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.props.search(e);
    }
  }

  onCustomFilterMouseUp(e) {
    if (e.target.offsetHeight !== this.props.customFilterHeight)
      this.props.updateCustomFilterHeight(e.target.offsetHeight);
  }

  render() {
    const {
      stateContext, filtersCount,
      query = '', setQuery, customFilter = '', setCustomFilter, customFilterHeight, setSearchSelectionMode,
      enableFavouritesFilter,
      clearFilters, exportData, isMultiSelect,
      enableExpandFacets, enableExpandDetails, toggleExpandFacets, toggleExpandDetails,
      enableDiscontinuedFilter, discontinuedFilterMonths, toggleDiscontinuedFilter,
      selectCount, unselectCount,
      favouriteCount, unfavouriteCount, displayOptions = {} } = this.props;

    return <form noValidate onSubmit={this.onSearch.bind(this)}>
      <div className='form-row'>

        <div className='col-lg-8 col-md-8 col-sm-10'>
          <div className='form-row'>
            <div className='col-4'>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <div className='input-group-text'>Search</div>
                </div>
                <input data_id='search-text' autoFocus={true} type='text' className={'form-control' + (query.length ? '' : ' rounded-right')} placeholder='Search text'
                  value={query}
                  onChange={e => setQuery(e.target.value)} />
                <div className='input-group-append'>
                  <button type='button' className={'btn btn-outline-secondary' + (query.length ? '' : ' d-none')}
                    onClick={this.onClearQuery.bind(this)}>
                    <i className='fas fa-times' />
                  </button>
                </div>
              </div>
            </div>

            <div className='col-8'>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <div className='input-group-text'>Filter</div>
                </div>
                <textarea data_id='filter-text' className={'form-control' + (customFilter.length ? '' : ' rounded-right')} placeholder='Filter text'
                  style={{ minHeight: 'calc(1.5em + .75rem + 2px)', height: customFilterHeight || 'calc(1.5em + .75rem + 2px)' }}
                  value={customFilter}
                  onChange={e => setCustomFilter(e.target.value)}
                  onKeyDown={this.onCustomFilterKeyDown.bind(this)}
                  onMouseUp={this.onCustomFilterMouseUp.bind(this)} />
                <div className='input-group-append'>
                  <button type='button' className={'btn btn-outline-secondary' + (customFilter.length ? '' : ' d-none')}
                    onClick={this.onClearCustomFilter.bind(this)}>
                    <i className='fas fa-times' />
                  </button>
                </div>

                <button data_id='search' type='submit' className='btn btn-primary ml-2 mr-2'>
                  <i className='fas fa-search' />
                </button>
              </div>
            </div>
          </div>
          <div className='form-row mt-2'>
            <div className='col-12 d-flex justify-content-between'>
              {(enableExpandFacets && displayOptions.multiSelectToggle === true) ? (
                <>
                  <div className='btn-group btn-group-sm btn-group-toggle'>
                    <button data_id='facet-mode' type='button' className={'btn btn-outline-secondary text-nowrap' + (!isMultiSelect ? ' active' : '')}
                      onClick={() => setSearchSelectionMode(false)}>Facets
                    </button>
                    <button data_id='filter-mode' type='button' className={'btn btn-outline-secondary text-nowrap' + (isMultiSelect ? ' active' : '')}
                      onClick={() => setSearchSelectionMode(true)}>Filters
                    </button>
                  </div>
                </>
              ) : <div />}

              <div className='d-flex flex-wrap '>
                {displayOptions.expandFacets === true && <div className='custom-control custom-switch user-select-none ml-3'>
                  <input data_id='expand-facets' type='checkbox' className='custom-control-input' id={'expand-facets-toggle' + stateContext}
                    checked={enableExpandFacets}
                    onChange={toggleExpandFacets} />
                  <label className='custom-control-label' htmlFor={'expand-facets-toggle' + stateContext}>Expand Facets</label>
                </div>}
                {displayOptions.expandDetails === true && <div className='custom-control custom-switch user-select-none ml-3'>
                  <input data_id='expand-details' type='checkbox' className='custom-control-input' id={'expand-details-toggle' + stateContext}
                    checked={enableExpandDetails}
                    onChange={toggleExpandDetails} />
                  <label className='custom-control-label' htmlFor={'expand-details-toggle' + stateContext}>Expand Details</label>
                </div>}
                {displayOptions.excludeDiscontinued === true && <div className='custom-control custom-switch user-select-none ml-3'>
                  <input data_id='exclude-discontinued' type='checkbox' className='custom-control-input' id={'discontinued-filter-toggle' + stateContext}
                    checked={enableDiscontinuedFilter} disabled={discontinuedFilterMonths <= 0}
                    onChange={toggleDiscontinuedFilter} />
                  <label className='custom-control-label' htmlFor={'discontinued-filter-toggle' + stateContext} title={discontinuedFilterMonths <= 0 ? 'no stale months configured' : `${discontinuedFilterMonths} stale month(s) to filter`}>Exclude Discontinued</label>
                </div>}
                {displayOptions.favouritesToggle === true && <div className='custom-control custom-switch user-select-none ml-3'>
                  <input data_id='show-only-favourites' type='checkbox' className='custom-control-input' id={'favourites-filter-toggle' + stateContext}
                    checked={enableFavouritesFilter}
                    onChange={e => this.props.toggleFavouritesFilter(this.props.isMultiSelect)} />
                  <label className='custom-control-label' htmlFor={'favourites-filter-toggle' + stateContext}>Show Only Favourites</label>
                </div>}
              </div>

              <div></div>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 col-sm-2'>
            <div className='d-flex flex-wrap align-content-center justify-content-end btn-group-sm'>
              {displayOptions.exportButton === true && <button data_id='export-selection' type='button' className='btn btn-outline-secondary text-nowrap ml-3 mb-1 py-2' onClick={exportData}>
                <i className='fas fa-download fa-fw' />
                <span className='px-2 d-none d-md-inline-block'>Export</span>
              </button>}
              {filtersCount > 0 && (<button data_id='clear-filters' type='button' className={`btn btn-outline-secondary text-nowrap ml-1 mb-1 py-2 ${filtersCount ? '' : 'd-none'}`} onClick={clearFilters}>
                <i className='fas fa-filter fa-fw' />
                <span className='px-2 d-none d-md-inline-block'>Clear filters ({filtersCount})</span>
              </button>)}
              {displayOptions.addRemoveButtons === true && selectCount > 0 ? (
                <button data_id='add-all' type='button' className='btn btn-outline-secondary text-nowrap ml-1 mb-1 py-2' onClick={this.onSelectAll.bind(this)}>
                  <i className='fas fa-box-open fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Add all ({selectCount})</span>
                </button>
              ) : null}
              {displayOptions.addRemoveButtons === true && unselectCount > 0 ? (
                <button data_id='remove-all' type='button' className='btn btn-outline-secondary text-nowrap ml-1 mb-1 py-2' onClick={this.onUnselectAll.bind(this)}>
                  <i className='fas fa-box-open fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Remove all ({unselectCount})</span>
                </button>
              ) : null}
              {favouriteCount > 0 ? (
                <button data_id='favourite-all' type='button' className='btn btn-outline-secondary text-nowrap ml-1 mb-1 py-2' onClick={this.onFavouriteAll.bind(this)}>
                  <i className='fas fa-star fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Favourite all ({favouriteCount})</span>
                </button>
              ) : null}
              {unfavouriteCount > 0 ? (
                <button data_id='unfavourite-all' type='button' className='btn btn-outline-secondary text-nowrap ml-1 mb-1 py-2' onClick={this.onUnfavouriteAll.bind(this)}>
                  <i className='far fa-star fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Unfavourite all ({unfavouriteCount})</span>
                </button>
              ) : null}
            </div>
        </div>
      </div>
    </form>
  }
}