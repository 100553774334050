import React from 'react';
import { connect } from 'react-redux';
import {
  reportsTableSelectionCopy
} from '../../../actions/reports';
import { toJS } from '../../../utility/immutable-utility';

export const ClipboardToolbar = connect(
  (state) => ({
    selectionRange: state.getIn(['reports', 'adjustments', 'editSelection', 'range']),
    orientation: state.getIn(['reports', 'results', 'settings', 'orientation'])
  }),
  (dispatch) => ({
    copySelection() {
      dispatch(reportsTableSelectionCopy(false));
    },
    copySelectionWithHeaders() {
      dispatch(reportsTableSelectionCopy(true));
    }
  })
)(({ orientation, selectionRange, copySelection, copySelectionWithHeaders }) => {

  if (orientation === 'Vertical')
    return <></>
    
  const _hasSelection = Object.keys(toJS(selectionRange)).length > 0;

  function onCopy() {
    copySelection();
  }

  function onCopySelectionWithHeaders() {
    copySelectionWithHeaders();
  }

  return <div className="btn-group btn-group-sm">
    <button disabled={!_hasSelection} title='Copy' className='btn btn-sm btn-outline-primary' type='button' onClick={onCopy}>
      <i className='far fa-copy fa-fw' />
    </button>

    <button disabled={!_hasSelection} type="button" className="btn btn-sm btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    </button>

    <div className="dropdown-menu">
      <button title='Copy' className='btn text-nowrap' type='button' onClick={onCopy}>
        <span>Copy</span>
      </button>
      <button className='btn text-nowrap' type='button' onClick={onCopySelectionWithHeaders}>
        <span>Copy with headers</span>
      </button>
    </div>
  </div>
});