import moment from 'moment';
import { fromJS, is } from 'immutable';
import { NATIVE_SCHEMA } from '../utility/analysis-search-utility';

export function buildSearchRequest(criteria) {
  const { page, pageSize = 100 } = criteria;
  const skip = page && page > 1 ? (page * pageSize) - pageSize : 0;

  // Azure search skip limit
  if (skip > 100000) return { error: 'Paging limit reached, please refine your search.' };

  const requestParams = {
    query: criteria.query || '*',
    customFilter: criteria.customFilter,
    onlyFavourites: criteria.enableFavouritesFilter,
    orderBy: `${criteria.orderBy || 'name'} ${criteria.orderByDirection || 'asc'}`,
    top: pageSize,
    skip: skip
  };

  if (criteria.enableDiscontinuedFilter === true && criteria.discontinuedFilterMonths > 0) {
    requestParams.StaleMonthLimit = criteria.discontinuedFilterMonths;
  }

  const { columnFilters = [] } = criteria;
  requestParams.columnFilters = columnFilters.map(filter => {
    const { name, value } = filter;
    if (!isNaN(value) && name !== 'schemaId') 
      return { name, value };

    const dateTime = moment.utc(value, moment.ISO_8601, true);
    if (dateTime && dateTime.isValid())
      return { name, value: dateTime.format() };

    return { name, value: `'${value}'` };
  });

  const { schemas = [] } = criteria;
  requestParams.schemaFilters = schemas
    .filter(s => s.isSelected)
    .map(s => {
      if (s.schemaName === NATIVE_SCHEMA)
        requestParams.legacyFacets = criteria.facets;

      const filters = {};
      if (s.facets) {
        s.facets.forEach(facet => {
          const facetValues = facet.results.filter(r => r.isSelected).map(r => r.value);
          if (facetValues.length > 0)
            filters[facet.key] = facetValues;
        });
      }

      return { name: s.schemaName, filters: filters };
    });

  return requestParams;
}

export function hasRequestChanged(previousRequestParams, requestParams) {
  if (!previousRequestParams)
    return false;

  if (!previousRequestParams.size)
    previousRequestParams = fromJS(previousRequestParams);

  if (previousRequestParams.size === 0)
    return false;

  previousRequestParams = previousRequestParams.remove('skip');

  if (!requestParams.size)
    requestParams = fromJS(requestParams);
  requestParams = requestParams.remove('skip');

  return !is(previousRequestParams, requestParams);
}