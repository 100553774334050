import { fromJS } from 'immutable'
import { toJS } from '../utility/immutable-utility';
import { createKey, addKeyToCollection, moveItemUpInCollection, moveItemUpDownCollection, deleteItemFromCollection } from '../utility/timeseries-edit-utility';
import {
  TIMESERIES_DETAILS_DERIVATION_UPDATE_SEARCH_KEY_TEXT,
  TIMESERIES_DETAILS_DERIVATION_ADD_KEY_COMPLETED,
  TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_STARTED,
  TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_COMPLETE,
  TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_UP,
  TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_DOWN,
  TIMESERIES_DETAILS_DERIVATION_DELETE_KEY,
  TIMESERIES_DETAILS_DERIVATION_ADD_KEYS_COMPLETE,
  TIMESERIES_DETAILS_DERIVATION_MOVE_FROM_BASKET_TO_KEYS_COMPLETE,
  TIMESERIES_DETAILS_DERIVATION_KEYS_SAVE,
  TIMESERIES_DETAILS_DERIVATION_TOGGLE_ALT_SELECTION,
  TIMESERIES_DETAILS_DERIVATION_SELECT_ALT_ALL,
  TIMESERIES_DETAILS_DERIVATION_ADD_ALT_SELECTION
} from '../actions/timeSeriesDetails-derivation';

export const timeSeriesDetailsDerivationReducer = {
  [TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_STARTED](state, action) {
    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys'], fromJS([]))
      .setIn(['timeSeriesEditor', 'derivationSettings', 'altSelectedKeys'], fromJS([]));
  },
  [TIMESERIES_DETAILS_DERIVATION_KEYS_LOAD_COMPLETE](state, action) {
    if (!action.data)
      return state;

    const selectedKeys = action.data.map(inputKey => (createKey({
      key: `${inputKey.key}`,
      name: inputKey.name,
      identityId: inputKey.identityId,
      sourceTimeZoneId: inputKey.sourceTimeZoneId,
      granularity: inputKey.granularity,
      type: inputKey.type
    })));

    const altSelectedKeys = selectedKeys.filter(i => i.type === 'Request');

    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys'], fromJS(selectedKeys))
      .setIn(['timeSeriesEditor', 'derivationSettings', 'altSelectedKeys'], fromJS(altSelectedKeys));
  },
  [TIMESERIES_DETAILS_DERIVATION_KEYS_SAVE](state, action) {
    const selectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys']), []);
    const inputKeysStyle = state.getIn(['timeSeriesEditor', 'inputKeysStyle']) ?? 'default';

    if (inputKeysStyle === 'default') {
      return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'keys'], fromJS(selectedKeys.map(r => `${r.key}`)));
    }

    if (inputKeysStyle === 'on-demand') {
      const onDemandStyleInputKeys = selectedKeys.map(r => {
        const response = {
          key: `${r.key}`,
          type: `${r.type}`
        };

        if (r.key)
          response.dynamicWorkspace = r.dynamicWorkspace

        return response;
      });

      return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'keys'], fromJS(onDemandStyleInputKeys));
    }

    return state;
  },
  [TIMESERIES_DETAILS_DERIVATION_UPDATE_SEARCH_KEY_TEXT](state, action) {
    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'searchKeyText'], action.value);
  },
  [TIMESERIES_DETAILS_DERIVATION_ADD_KEY_COMPLETED](state, action) {
    if (!action.data)
      return state;

    let selectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys']), []);

    selectedKeys = addKeyToCollection({
      key: createKey({
        key: `${action.data.id}`,
        name: action.data.name,
        identityId: `${action.data.id}`,
        sourceTimeZoneId: action.data.sourceTimeZoneId,
        granularity: action.data.granularity,
        type: 'Identity'
      }),
      collection:selectedKeys});

      return state.setIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys'], fromJS(selectedKeys));
  },
  [TIMESERIES_DETAILS_DERIVATION_ADD_KEYS_COMPLETE](state, action) {
    if (!action.data)
      return state;

    let selectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys']), []);
    action.data.forEach(newItem => {
      const newItemKey = newItem.key ? `${newItem.key}` : `${newItem.id}`;
      selectedKeys = addKeyToCollection({
        key: createKey({
          key: newItemKey,
          name: newItem.name,
          identityId: `${newItem.id}`,
          type: `Identity`,
          sourceTimeZoneId: newItem.sourceTimeZoneId,
          granularity: newItem.granularity,
        }),
        collection:selectedKeys});
    });

    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys'], fromJS(selectedKeys));
  },
  [TIMESERIES_DETAILS_DERIVATION_MOVE_FROM_BASKET_TO_KEYS_COMPLETE](state, action) {
    const { basket } = action;
    if (!basket)
      return state;

    let selectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys']), []);
    [...selectedKeys].forEach(basketItem => {
      if (basketItem.type !== `Identity`){
        const existing = selectedKeys.find(x => basketItem.key === x.key && x.type === `Identity`);
        if (existing){
          selectedKeys = selectedKeys.filter(x => !(x.key === existing.key && x.type !== 'Identity'));
        } else {
          basketItem.type = `Identity`;
          const workspaceBasketItem = basket.find(b => b.key === basketItem.key);
          if (workspaceBasketItem && workspaceBasketItem.dynamicWorkspace) {
            basketItem.key = `${workspaceBasketItem.identityId}`;
            basketItem.dynamicWorkspace = workspaceBasketItem.dynamicWorkspace;
          }
        }
      }
    });

    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys'], fromJS(selectedKeys));
  },
  [TIMESERIES_DETAILS_DERIVATION_ADD_ALT_SELECTION](state, action) {
    const altSelectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'altSelectedKeys']), []);
    let selectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys']), []);
    
    altSelectedKeys.forEach(inputKey => {
      selectedKeys = addKeyToCollection({
        key: createKey({
          name: inputKey.name,
          identityId: inputKey.identityId,
          key: inputKey.key,
          type: inputKey.type
        }),
        collection:selectedKeys});
    });

    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys'], fromJS(selectedKeys));
  },
  [TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_UP](state, action) {
    if (!action.key)
      return state;

    let selectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys']), []);
    selectedKeys = moveItemUpInCollection({item: action.key, collection:selectedKeys });

    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys'], fromJS(selectedKeys));
  },
  [TIMESERIES_DETAILS_DERIVATION_MOVE_KEY_DOWN](state, action) {
    if (!action.key)
      return state;

    let selectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys']), []);
    selectedKeys = moveItemUpDownCollection({item: action.key, collection:selectedKeys });

    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys'], fromJS(selectedKeys));
  },
  [TIMESERIES_DETAILS_DERIVATION_DELETE_KEY](state, action) {
    if (!action.key)
      return state;

    let selectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys']), []);
    selectedKeys = deleteItemFromCollection({item:action.key, collection:selectedKeys});
   
    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'selectedKeys'], fromJS(selectedKeys));
  },
  [TIMESERIES_DETAILS_DERIVATION_TOGGLE_ALT_SELECTION](state, action) {
    const inputKey = action.inputKey;
    let altSelectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'altSelectedKeys']), []);
    if (altSelectedKeys.some(i => i.key === inputKey.key)) {
      altSelectedKeys = altSelectedKeys.filter(i => i.key !== inputKey.key);
    } else {
      altSelectedKeys.push(createKey({
        name: inputKey.name,
        identityId: inputKey.identityId,
        key: inputKey.key,
        type: 'Request'
      }));
    }

    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'altSelectedKeys'], fromJS(altSelectedKeys));
  },
  [TIMESERIES_DETAILS_DERIVATION_SELECT_ALT_ALL](state, action) {
    const inputKeys = action.value;
    let altSelectedKeys = toJS(state.getIn(['timeSeriesEditor', 'derivationSettings', 'altSelectedKeys']), []);
    if (inputKeys && inputKeys.length > 0){
      inputKeys.forEach(inputKey => {
        if (altSelectedKeys.some(i => i.key === inputKey.key)) {
            altSelectedKeys = altSelectedKeys.filter(i => i.key !== inputKey.key);
          } else {
            altSelectedKeys.push(createKey({
              name: inputKey.name,
              identityId: inputKey.identityId,
              key: inputKey.key,
              type: 'Request'
            }));
          }
      });
    }else{
      altSelectedKeys = [];
    }

    return state.setIn(['timeSeriesEditor', 'derivationSettings', 'altSelectedKeys'], fromJS(altSelectedKeys));
  }
};