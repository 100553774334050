import React from 'react';
import AdjustmentsToolbar from './AdjustmentsToolbar';
import AdjustmentsTableEditor from './AdjustmentsTableEditor'

export default function AdjustmentsEditor({ referenceData, timeSeries, adjustmentsEditor,
  loadAdjustments, setAdjustmentsFilter, editAdjustmentValues, saveAdjustments,
  applyAdjustmentsRange, isWizard }) {
  const { granularity: timeseriesGranularity, style: timeseriesStyle, derivationData: timeseriesDerivationData = {} } = timeSeries;
  const { derivationType: timeseriesDerivationType } = timeseriesDerivationData;
  const { dataPointsEdit } = adjustmentsEditor;
  const { dataPoints, dataPointsTimezone } = dataPointsEdit;

  return (
    <div className='w-100'>
      <div className='mb-1'>
        <AdjustmentsToolbar
          timeseriesStyle={timeseriesStyle}
          timeseriesDerivationType={timeseriesDerivationType}
          filterFromUtc={adjustmentsEditor.dataPointsEdit.filterFromUtc}
          filterToUtc={adjustmentsEditor.dataPointsEdit.filterToUtc}
          filterTimezone={adjustmentsEditor.dataPointsEdit.filterTimezone}
          dataPointsIsDirty={adjustmentsEditor.dataPointsEdit.dataPointsIsDirty}
          timezones={referenceData.timezones}
          selectedTimeseriesId={timeSeries.id}
          loadAdjustments={loadAdjustments}
          setAdjustmentsFilter={setAdjustmentsFilter}
          saveAdjustments={saveAdjustments}
          applyAdjustmentsRange={applyAdjustmentsRange}
          isWizard={isWizard} />
      </div>
      <div>
        {timeseriesGranularity && (
          <AdjustmentsTableEditor timeseriesGranularity={timeseriesGranularity}
            timeseriesStyle={timeseriesStyle}
            timeseriesDerivationType={timeseriesDerivationType}
            dataPoints={dataPoints}
            onEdited={editAdjustmentValues}
            timezone={dataPointsTimezone}
            isWizard={isWizard} />
        )}
      </div>
    </div>
  );
}