import { createAction } from '../utility/redux-utility';

export const SET_COLLAPSED_STATE = 'SET_COLLAPSED_STATE';
export const setCollapsedState = createAction(SET_COLLAPSED_STATE, 'key', 'value');

export const RESET_COLLAPSED_STATES = 'RESET_COLLAPSED_STATES';
export const resetCollapsedStates = createAction(RESET_COLLAPSED_STATES);

export const SHOW_IDENTITY_MENU = 'SHOW_IDENTITY_MENU';
export const showIdentityMenu = createAction(SHOW_IDENTITY_MENU, 'data');

export const HIDE_IDENTITY_MENU = 'HIDE_IDENTITY_MENU';
export const hideIdentityMenu = createAction(HIDE_IDENTITY_MENU);