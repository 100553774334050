import { FetchError } from '../auth';

export const getMessageParts = message => {
  if (typeof message === 'string') return {
    header: `${message.substring(0, 100)}`,
    body: message,
    source: message
  };

  if (typeof message.text === 'function') {
    const functionText = message.text();

    return {
      header: `${functionText.substring(0, 100)}`,
      body: functionText,
      source: message
    };
  }

  if (message instanceof FetchError) {
    try {
      const obj = JSON.parse(message.body);

      if (obj.errors && obj.title && Array.isArray(obj.errors.text)) {
        if (obj.errors.text.length) return {
          header: obj.title,
          body: obj.errors.text.join('\n'),
          source: message
        };

        return {
          header: obj.title,
          body: obj.detail,
          source: message
        };
      }

      if (obj.error) return {
        header: `${obj.error.substring(0, 100)}`,
        body: obj.error,
        source: message
      };
    }
    catch { }

    const bodyText = message.body;

    return {
      header: `${bodyText.substring(0, 100)}`,
      body: bodyText,
      source: message
    };
  }

  if (message instanceof Error) {
    const errorText = message.message;

    return {
      header: `${errorText.substring(0, 100)}`,
      body: errorText,
      source: message
    };
  }
  return null;
};