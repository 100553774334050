import React from 'react';
import Loading from '../../shared/Loading';
import ErrorBoundary from '../../shared/ErrorBoundary';
import OutagesToolbar from './OutagesToolbar';
import OutagesActionsToolbar from './OutagesActionsToolbar';
import OutagesResults from './OutagesResults';
import OutagesDetails from './OutagesDetails';

export default function Outages({ isLoading, isLoadingSourceList, isLoadingBiddingZoneList, isLoadingProductionTypeList, isLoadingGenerationDataLookupList,
  data, selectedOutage, resultCount, requiresRefresh, sourceList, biddingZoneList, productionTypeList, generationDataLookupList,
  source, biddingZone, productionType, unitEic, eventStart, eventEnd, page, pageSize, fotaStatus, messageVersions, messageStatus, generationData, filters,
  refresh, lookupGenerationData, updateToolbarSource, updateToolbar, updateFilter, updatePage, selectOutage, resetOutages, shareUrl }) {
  return (
    <div className='w-100 p-3' style={{ flexGrow: 1 }}>
      <ErrorBoundary>
        <Loading isLoading={isLoading}>
          <div className='row'>
            <div className='mb-2 col-md-8 col-lg-9'>
              <OutagesToolbar isLoadingSourceList={isLoadingSourceList}
                isLoadingBiddingZoneList={isLoadingBiddingZoneList}
                isLoadingProductionTypeList={isLoadingProductionTypeList}
                isLoadingGenerationDataLookupList={isLoadingGenerationDataLookupList}
                requiresRefresh={requiresRefresh}
                sourceList={sourceList}
                biddingZoneList={biddingZoneList}
                productionTypeList={productionTypeList}
                generationDataLookupList={generationDataLookupList}
                source={source}
                biddingZone={biddingZone}
                productionType={productionType}
                unitEic={unitEic}
                eventStart={eventStart}
                eventEnd={eventEnd}
                fotaStatus={fotaStatus}
                messageVersions={messageVersions}
                messageStatus={messageStatus}
                generationData={generationData}
                resultCount={resultCount}
                refresh={refresh}
                lookupGenerationData={lookupGenerationData}
                updateToolbarSource={updateToolbarSource}
                updateToolbar={updateToolbar}
                shareUrl={shareUrl} />
            </div>
            <div className='mb-2 col-md-4 col-lg-3 d-flex align-items-end'>
              <OutagesActionsToolbar selectedOutage={selectedOutage}
                resetOutages={resetOutages} />
            </div>
          </div>
          <div className='row'>
            <div className='mb-2 col-md-8 col-lg-9'>
              <OutagesResults data={data}
                selectedOutage={selectedOutage}
                page={page}
                pageSize={pageSize}
                filters={filters}
                resultCount={resultCount}
                selectOutage={selectOutage}
                updateFilter={updateFilter}
                updatePage={updatePage} />
            </div>
            <div className='mb-2 col-md-4 col-lg-3'>
              <OutagesDetails data={selectedOutage} />
            </div>
          </div>
        </Loading>
      </ErrorBoundary>
    </div>
  );
};