import React from 'react';
import FormattedDateTime from '../../shared/FormattedDateTime';
import IdentityId from '../../shared/IdentityId2';

export default function OutagesDetailsFotaPanel({ data, labelColumnWidth, valueCellStyles }) {
  const { generationDataId, installedCapacityIdentityId, unavailabilityIdentityId, availabilityForecastIdentityId,
    capturedUtc, ignoredUtc, ignoredReason, validatedUtc, validationFailureReason, fuelType, eic, processedUtc } = data ?? {};

  return (
    <table className='w-100 mt-3'>
      <thead>
        <tr>
          <th width={labelColumnWidth}>Fota</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Generation Data Id</td>
          <td style={valueCellStyles}>{generationDataId}</td>
        </tr>
        <tr>
          <td>Installed Capacity</td>
          <td>{installedCapacityIdentityId && <IdentityId>{installedCapacityIdentityId}</IdentityId>}</td>
        </tr>
        <tr>
          <td>Unavailability</td>
          <td>{unavailabilityIdentityId && <IdentityId>{unavailabilityIdentityId}</IdentityId>}</td>
        </tr>
        <tr>
          <td>Availability Forecast</td>
          <td>{availabilityForecastIdentityId && <IdentityId>{availabilityForecastIdentityId}</IdentityId>}</td>
        </tr>
        <tr>
          <td>Fuel Type</td>
          <td style={valueCellStyles}>{fuelType}</td>
        </tr>
        <tr>
          <td>EIC</td>
          <td style={valueCellStyles}>{eic}</td>
        </tr>
        <tr>
          <td>Captured (UTC)</td>
          <td style={valueCellStyles}><FormattedDateTime>{capturedUtc}</FormattedDateTime></td>
        </tr>
        <tr>
          <td>Ignored (UTC)</td>
          <td style={valueCellStyles}><FormattedDateTime>{ignoredUtc}</FormattedDateTime></td>
        </tr>
        <tr>
          <td>Ignored Reason</td>
          <td style={valueCellStyles}>{ignoredReason}</td>
        </tr>
        <tr>
          <td>Validated (UTC)</td>
          <td style={valueCellStyles}><FormattedDateTime>{validatedUtc}</FormattedDateTime></td>
        </tr>
        <tr>
          <td>Validation Failure Reason</td>
          <td style={valueCellStyles}>{validationFailureReason}</td>
        </tr>
        <tr>
          <td>Processed (UTC)</td>
          <td style={valueCellStyles}><FormattedDateTime>{processedUtc}</FormattedDateTime></td>
        </tr>
      </tbody>
    </table>
  );
}