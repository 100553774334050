import React, { Fragment, cloneElement, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { push } from "redux-first-history";
import qs from 'querystring';
import { copyToClipboard } from '../../utility/text-utility';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import {
  analysisSearch,
  analysisAddWithScenarioOverrides,
  analysisAddWithScenarioOverridesAndOpen
} from '../../actions/analysis';
import {
  newIdentityIdSearchUrlArgs
} from '../../reducer-functions/analysisCrossSchemaSearch';
import { toJS } from '../../utility/immutable-utility';
import { 
  analysisCrossSchemaSearch,
  analysisCrossSchemaSearchAddFavourite,
  analysisCrossSchemaSearchLoadSchemaLookups,
  analysisCrossSchemaSearchRemoveFavourite,
  analysisCrossSchemaSearchSetSearchQueryText,
  analysisCrossSchemaSearchToggleFavouritesFilter,
  analysisCrossSchemaSearchToggleSchema
} from '../../actions/analysis-cross-schema-search';
import { NATIVE_SCHEMA } from '../../utility/analysis-search-utility';

const VIEW_TYPE = {
  ANALYSIS: 0,
  EVENTS: 1,
  REPORTS: 2,
  TIMESERIES: 3
};

const IdentityId = connect(
  (state) => {
      const applicationPath = state.getIn(['application', 'routePath']) ?? [];

      const [view, area] = applicationPath;
      let fromDate = undefined;
      let toDate = undefined;
      let timeZoneId = undefined;
      if (view === 'reports'){
        fromDate = state.getIn(['reports', 'criteria', 'fromDate']);
        toDate = state.getIn(['reports', 'criteria', 'toDate']);
        timeZoneId = state.getIn(['reports', 'criteria', 'timeZoneId']);
      }
      else if (view === 'analysis' && (area === 'chart' || area === 'split-chart' || area === 'chart-data')){
        timeZoneId = state.getIn(['analysis', 'workspace', 'timeZoneId']);
        const fromDateMode = state.getIn(['analysis', 'workspace', 'fromDateMode']);
        if (fromDateMode !== 'rel'){
          fromDate = state.getIn(['analysis', 'workspace', 'fromUtc']);
        }

        const toDateMode = state.getIn(['analysis', 'workspace', 'toDateMode']);
        if (toDateMode !== 'rel'){
          toDate = state.getIn(['analysis', 'workspace', 'toUtc']);
        }
      }

      return {
        windowMode: state.getIn(['userSettings', 'settings', 'identityMenu', 'windowMode']),
        fromDate: fromDate,
        toDate: toDate,
        timeZoneId: timeZoneId,
        searchCriteria: state.getIn(['analysisCrossSchemaSearch', 'criteria']),
    };
  },
  (dispatch, { children: label, id }) => {
    const _id = id || label;

    return {
      addFavourite() {
        dispatch(analysisCrossSchemaSearchAddFavourite('default', _id));
      },
      addToBasket() { 
        dispatch(analysisAddWithScenarioOverrides(_id));
      },
      addToBasketAndOpen() { 
        dispatch(analysisAddWithScenarioOverridesAndOpen(_id));
      },
      removeFavourite() {
        dispatch(analysisCrossSchemaSearchRemoveFavourite('default', _id));
      },
      searchAnalysis(timeSeriesId) {
        if (timeSeriesId) {
          dispatch(analysisCrossSchemaSearchLoadSchemaLookups(false, dispatch => {
            dispatch(analysisCrossSchemaSearchToggleSchema('default', NATIVE_SCHEMA, true));
            dispatch(analysisCrossSchemaSearchToggleFavouritesFilter('default', false));
            dispatch(analysisCrossSchemaSearchSetSearchQueryText('default', `${timeSeriesId}`));
            dispatch(analysisCrossSchemaSearch('default'));
            dispatch(analysisSearch());
          }));
        } else {
          dispatch(analysisSearch());
        }
      },
      openWindow({ pathname, search, windowMode }) {
        switch (windowMode) {
          case 'self': dispatch(push({ pathname, search })); break;
          case 'tab': window.open(search ? `${pathname}?${search}` : pathname, '_blank', 'noopener,noreferrer'); break;
          default: window.open(search ? `${pathname}?${search}` : pathname, '_blank', 'menubar=yes,noopener,noreferrer'); break;
        }
      }
    };
  }
)(({ searchCriteria = {}, groupClassname = '', children: label, id, hideLabel, name, isLeftDrop, noButtonBorders, onClick, isFavourite, isCompactView = false, timeSeriesKey,
  windowMode, addFavourite, addToBasket, addToBasketAndOpen, removeFavourite, searchAnalysis, openWindow, additionalButtons, customMenuButton, fromDate, toDate, timeZoneId }) => {
  const [menuAnchor, setMenuAnchor] = useState({ topMenu: null });

  const openMenu = e => setMenuAnchor({ topMenu: e.currentTarget, subMenu1: null, subMenu2: null, subMenu3: null, subMenu4: null });
  const closeMenu = () => setMenuAnchor({ topMenu: null, subMenu1: null, subMenu2: null, subMenu3: null, subMenu4: null });

  const openSubMenu = (e, subMenu) => setMenuAnchor(menuAnchor => ({ ...menuAnchor, [subMenu]: e.currentTarget }));
  const closeSubMenu = (subMenu) => setMenuAnchor(menuAnchor => ({ ...menuAnchor, [subMenu]: null }));

  const isOpen = !!menuAnchor && !!menuAnchor.topMenu;
  const isSub1Open = isOpen && !!menuAnchor.subMenu1;
  const isSub2Open = isOpen && !!menuAnchor.subMenu2;
  const isSub3Open = isOpen && !!menuAnchor.subMenu3;
  const isSub4Open = isOpen && !!menuAnchor.subMenu4;
  const isSub5Open = isOpen && !!menuAnchor.subMenu5;

  const viewType = useMemo(() => {
    const pathname = window.location.pathname;

    if (pathname.indexOf('/events') >= 0) return VIEW_TYPE.EVENTS;
    if (pathname.indexOf('/reports') >= 0) return VIEW_TYPE.REPORTS;
    if (pathname.indexOf('/timeseries') >= 0) return VIEW_TYPE.TIMESERIES;

    return VIEW_TYPE.ANALYSIS;
  }, []);

  const stopPropagation = e => {
    e.stopPropagation();
    e.preventDefault();
  };
  const onOpenMenu = e => {
    stopPropagation(e);
    openMenu(e);
  };
  const onCloseMenu = e => {
    stopPropagation(e);
    closeMenu();
  };
  const onOpenSubMenu = (e, subMenu) => {
    stopPropagation(e);
    openSubMenu(e, subMenu);
  };
  const onCloseSubMenu = (e, subMenu) => {
    stopPropagation(e);
    closeSubMenu(subMenu);
  };
  const onAddFavourite = e => {
    stopPropagation(e);
    addFavourite();
    closeMenu();
  };
  const onAddToBasket = e => {
    stopPropagation(e);
    addToBasket();
    closeMenu();
  };
  const onAddToBasketAndOpen = e => {
    stopPropagation(e);
    addToBasketAndOpen();
    closeMenu();
  };
  const onRemoveFavourite = e => {
    stopPropagation(e);
    removeFavourite();
    closeMenu();
  };
  const onSearchAnalysis = (e, mode) => {
    const searchWindowMode = mode || windowMode;

    stopPropagation(e);
    const timeSeriesId = id || label;
    let search = newIdentityIdSearchUrlArgs(toJS(searchCriteria), timeSeriesId);

    openWindow({ pathname: `/analysis`, search, windowMode: searchWindowMode });
    closeMenu();

    if (searchWindowMode === 'self')     
      searchAnalysis(timeSeriesId);
  };
  const onViewEvents = (e, mode) => {
    stopPropagation(e);
    openWindow({ pathname: `/events`, search: qs.stringify({ filters: [`identityId|${id || label}`] }), windowMode: mode || windowMode });
    closeMenu();
  };
  const onOpenEditWindow = (e, mode) => {
    stopPropagation(e);

    if (fromDate){
      fromDate = moment.utc(fromDate);
      if (fromDate.isValid())
        fromDate = fromDate.format('YYYY-MM-DD HH:mm:ss');
      else
        fromDate = undefined;
    }

    if (toDate){
      toDate = moment.utc(toDate);
      if (toDate.isValid())
        toDate = toDate.format('YYYY-MM-DD HH:mm:ss');
      else
        toDate = undefined;
    }

    openWindow({ 
      pathname: `/timeseries/${id || label}`, 
      search : qs.stringify({
        fromDate,
        toDate,
        timeZoneId}),
      windowMode: mode || windowMode
     });
    closeMenu();
  };
  const onOpenEditAnnotationsWindow = (e, mode) => {
    stopPropagation(e);
    openWindow({ pathname: `/timeseries/${id || label}/annotations`, windowMode: mode || windowMode });
    closeMenu();
  };
  const onCopyId = e => {
    stopPropagation(e);
    copyToClipboard(id || label);
    closeMenu();
  };

  const onCopyKey = e => {
    stopPropagation(e);
    copyToClipboard(timeSeriesKey);
    closeMenu();
  };  

  return (
    <ClickAwayListener onClickAway={onCloseMenu}>
      <>
        { customMenuButton ? cloneElement(customMenuButton, { onClick: onOpenMenu }) : (
          <div className={`identity-menu-button-group${groupClassname ? ' ' + groupClassname : ''}`}>
            {isLeftDrop && 
            <button data_id='open-identity' type='button' className={`identity-menu-button ${noButtonBorders ? 'identity-menu-button-no-border' : ''}`} onClick={onOpenMenu}>
              <i className='fas fa-caret-down fa-fw' />
            </button>
            }
            {!hideLabel &&
            <button data_id='edit-identity' type='button' className={`my-0 mx-2 identity-menu-button identity-menu-button-no-border`} onClick={onClick || onOpenEditWindow}>
              <span>{ label }</span>
            </button>}
            {!isLeftDrop &&
            <button data_id='open-identity' type='button' className={`identity-menu-button ${noButtonBorders ? 'identity-menu-button-no-border' : ''}`} onClick={onOpenMenu}>
              <i className='fas fa-caret-down fa-fw' />
            </button>}
            
            {additionalButtons}
          </div>
        ) }
        <Menu data_id='identity-menu' anchorEl={menuAnchor.topMenu} open={isOpen} onClose={onCloseMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              TransitionComponent={Fade}>
          {(!isCompactView && viewType === VIEW_TYPE.ANALYSIS && isFavourite !== undefined) && (
            !isFavourite ? (
              <MenuItem data_id='add-favourite' className='py-1 px-2 justify-content-between' onClick={onAddFavourite}>
                <span><i className='fas fa-star fa-fw ml-2 mr-3' /> Add favourite</span>
              </MenuItem>
            ) : (
              <MenuItem data_id='remove-favourite' className='py-1 px-2 justify-content-between' onClick={onRemoveFavourite}>
                <span><i className='far fa-star fa-fw ml-2 mr-3' /> Remove favourite</span>
              </MenuItem>
            )
          )}
          <MenuItem data_id='edit-timeseries' className='py-1 px-2 justify-content-between' onClick={onOpenEditWindow}>
            <span><i className='fas fa-pen fa-fw ml-2 mr-3' /> Edit time series</span>
            <Button className='text-primary active' onClick={e => onOpenSubMenu(e, 'subMenu1')}>
              <i className='fas fa-caret-right fa-fw' />
            </Button>
            <Menu data_id='add-favourite' anchorEl={menuAnchor.subMenu1} open={isSub1Open} onClose={e => onCloseSubMenu(e, 'subMenu1')}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  TransitionComponent={Fade}>
              <MenuItem data_id='current-window' className='py-1 px-2' onClick={e => onOpenEditWindow(e, 'self')}>
                Current window
              </MenuItem>
              <MenuItem data_id='new-window'  className='py-1 px-2' onClick={e => onOpenEditWindow(e, 'modal')}>
                New window
              </MenuItem>
              <MenuItem data_id='new-tab' className='py-1 px-2' onClick={e => onOpenEditWindow(e, 'tab')}>
                New tab
              </MenuItem>
            </Menu>
          </MenuItem>
          <MenuItem data_id='edit-annotations' className='py-1 px-2 justify-content-between' onClick={onOpenEditAnnotationsWindow}>
            <span><i className='fas fa-comment fa-fw ml-2 mr-3' /> Edit annotations</span>
            <Button className='text-primary text-right active' onClick={e => onOpenSubMenu(e, 'subMenu2')}>
              <i className='fas fa-caret-right fa-fw' />
            </Button>
            <Menu anchorEl={menuAnchor.subMenu2} open={isSub2Open} onClose={e => onCloseSubMenu(e, 'subMenu2')}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  TransitionComponent={Fade}>
              <MenuItem data_id='current-window' className='py-1 px-2' onClick={e => onOpenEditAnnotationsWindow(e, 'self')}>
                Current window
              </MenuItem>
              <MenuItem data_id='new-window'  className='py-1 px-2' onClick={e => onOpenEditAnnotationsWindow(e, 'modal')}>
                New window
              </MenuItem>
              <MenuItem data_id='new-tab' className='py-1 px-2' onClick={e => onOpenEditAnnotationsWindow(e, 'tab')}>
                New tab
              </MenuItem>
            </Menu>
          </MenuItem>
          <MenuItem data_id='search-timeseries' className='py-1 px-2 justify-content-between' onClick={onSearchAnalysis}>
            <span><i className='fas fa-search fa-fw ml-2 mr-3' /> Search time series</span>
            <Button data_id='search-timeseries-menu' className='text-primary active' onClick={e => onOpenSubMenu(e, 'subMenu3')}>
              <i className='fas fa-caret-right fa-fw' />
            </Button>
            <Menu data_id='identity-windowing-menu' anchorEl={menuAnchor.subMenu3} open={isSub3Open} onClose={e => onCloseSubMenu(e, 'subMenu3')}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  TransitionComponent={Fade}>
              <MenuItem data_id='current-window' className='py-1 px-2' onClick={e => onSearchAnalysis(e, 'self')}>
                Current window
              </MenuItem>
              <MenuItem data_id='new-window' className='py-1 px-2' onClick={e => onSearchAnalysis(e, 'modal')}>
                New window
              </MenuItem>
              <MenuItem data_id='new-tab' className='py-1 px-2' onClick={e => onSearchAnalysis(e, 'tab')}>
                New tab
              </MenuItem>
            </Menu>
          </MenuItem>
          {viewType !== VIEW_TYPE.ANALYSIS && 
            <MenuItem data_id='add-to-basket' className='py-1 px-2 justify-content-between' onClick={onAddToBasket}>
              <span><i className='fas fa-solid fa-broom fa-fw ml-2 mr-3' /> Send to analysis basket</span>
              <Button className='text-primary active' onClick={e => onOpenSubMenu(e, 'subMenu5')}>
                <i className='fas fa-caret-right fa-fw' />
              </Button>
              <Menu anchorEl={menuAnchor.subMenu5} open={isSub5Open} onClose={e => onCloseSubMenu(e, 'subMenu5')}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  TransitionComponent={Fade}>
                <MenuItem data_id='add-to-basket-and-navigate' className='py-1 px-2' onClick={onAddToBasketAndOpen}>
                  and open analysis
                </MenuItem>              
              </Menu>              
            </MenuItem>}                    
          <MenuItem data_id='view-events' className='py-1 px-2 justify-content-between' onClick={onViewEvents}>
            <span><i className='fas fa-bolt fa-fw ml-2 mr-3' /> View events</span>
            <Button className='text-primary active' onClick={e => onOpenSubMenu(e, 'subMenu4')}>
              <i className='fas fa-caret-right fa-fw' />
            </Button>
            <Menu anchorEl={menuAnchor.subMenu4} open={isSub4Open} onClose={e => onCloseSubMenu(e, 'subMenu4')}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  TransitionComponent={Fade}>
              <MenuItem data_id='current-window' className='py-1 px-2' onClick={e => onViewEvents(e, 'self')}>
                Current window
              </MenuItem>
              <MenuItem data_id='new-window' className='py-1 px-2' onClick={e => onViewEvents(e, 'modal')}>
                New window
              </MenuItem>
              <MenuItem data_id='new-tab' className='py-1 px-2' onClick={e => onViewEvents(e, 'tab')}>
                New tab
              </MenuItem>
            </Menu>
          </MenuItem>
          <MenuItem data_id='copy-id' className='py-1 px-2 justify-content-between' onClick={onCopyId}>
            <span><i className='fas fa-copy fa-fw ml-2 mr-3' /> Copy Id to clipboard</span>
          </MenuItem>
          {timeSeriesKey && <MenuItem data_id='copy-key' className='py-1 px-2 justify-content-between' onClick={onCopyKey}>
            <span><i className='fas fa-key fa-fw ml-2 mr-3' /> Copy Key to clipboard</span>
          </MenuItem>}
        </Menu>
      </>
    </ClickAwayListener>
  );
});

export default IdentityId;