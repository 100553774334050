import { createAction } from '../utility/redux-utility';
import moment from 'moment';
import { authFetch } from '../auth';
import { 
  ANALYSIS_API_ROOT_URL, 
  ANALYSIS_SEARCH_API_ROOT_URL, 
  ANALYSIS_API_URL } from '../config';
import { toJS } from '../utility/immutable-utility';
import { replace } from "redux-first-history";
import {
  logErrorNotification,
  logWarningNotification
} from './log';
import {
  csvExport,
  jsonExport
} from '../utility/file-utility';

import { encodeCriteriaToUrlArgs } from '../utility/analysis-search-utility'; 

import { 
  buildSearchRequest, hasRequestChanged
} from '../mapper/analysisSearchMapper';

export const analysisCrossSchemaSearchLoadSchemaLookups = (forceReload = false, callback) => (dispatch, getState) => {
  const state = getState();
  const schemas = state.getIn(['analysisCrossSchemaSearch', 'initialise', 'schemas']);
  if (!forceReload && schemas && schemas.size > 0) {
    callback?.(dispatch);
    return;
  }

  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/schemas/lookups`)
    .then(response => response.status === 204 ? null : response.json())
    .then(data => {
      dispatch(analysisCrossSchemaSearchSchemaLookupLoadComplete(data));
      callback?.(dispatch);
    })
    .catch(error => {
      dispatch(logWarningNotification(`Error loading schema lookup data: ${error}\n\nPlease try refreshing your browser using Ctrl+F5`));
    });
};

export const ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_SCHEMA_LOOKUP_COMPLETE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_SCHEMA_LOOKUP_COMPLETE';
const analysisCrossSchemaSearchSchemaLookupLoadComplete = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_SCHEMA_LOOKUP_COMPLETE, 'data');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_COLUMN_FILTER = 'ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_COLUMN_FILTER';
export const analysisCrossSchemaSearchSetSearchSetColumnFilter = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_COLUMN_FILTER, 'stateContext', 'name', 'value');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_ORDER_BY = 'ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_ORDER_BY';
export const analysisCrossSchemaSearchSetSearchOrderBy = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_ORDER_BY, 'stateContext', 'name');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_PAGE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_PAGE';
export const analysisCrossSchemaSearchSetSearchSetPage = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_PAGE, 'stateContext', 'page', 'pageSize');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_QUERY_TEXT = 'ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_QUERY_TEXT';
export const analysisCrossSchemaSearchSetSearchQueryText = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_QUERY_TEXT, 'stateContext', 'text');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_CUSTOM_FILTER_TEXT = 'ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_CUSTOM_FILTER_TEXT';
export const analysisCrossSchemaSearchSetSearchCustomFilterText = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_CUSTOM_FILTER_TEXT, 'stateContext', 'text');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SELECTION_MODE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SELECTION_MODE';
export const analysisCrossSchemaSearchSetSearchSelectionMode = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SELECTION_MODE, 'stateContext', 'isMultiSelect');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA';
export const analysisCrossSchemaSearchToggleSchema = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA, 'stateContext', 'schemaName', 'value');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_EXPANSION = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_EXPANSION';
export const analysisCrossSchemaSearchToggleSchemaExpansion = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_EXPANSION, 'stateContext', 'schemaName');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_SELECTION = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_SELECTION';
export const analysisCrossSchemaSearchToggleSchemaSelection = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_SELECTION, 'stateContext', 'schemaName', 'facetKey');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_FACET_VALUE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_FACET_VALUE';
export const analysisCrossSchemaSearchToggleSchemaFacetValue = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_FACET_VALUE, 'stateContext', 'schemaName', 'facetKey', 'value');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_CLEAR_SCHEMA_FACET_FILTERS = 'ANALYSIS_CROSS_SCHEMA_SEARCH_CLEAR_SCHEMA_FACET_FILTERS';
export const analysisCrossSchemaSearchClearSchemaFacetFilters = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_CLEAR_SCHEMA_FACET_FILTERS, 'stateContext');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXCLUDE_DISCONTINUED_FILTER = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXCLUDE_DISCONTINUED_FILTER';
export const analysisCrossSchemaSearchToggleExcludeDiscontinuedFilter = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXCLUDE_DISCONTINUED_FILTER, 'stateContext');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES';
export const analysisCrossSchemaSearchToggleFavouritesFilter = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES, 'stateContext', 'value');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SCHEMA_SELECTOR_SIZE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SCHEMA_SELECTOR_SIZE';
export const analysisCrossSchemaSearchSetSchemaSelectorSize = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SCHEMA_SELECTOR_SIZE, 'stateContext', 'size');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_BEGIN = 'ANALYSIS_CROSS_SCHEMA_SEARCH_BEGIN';
export const analysisCrossSchemaSearchBegin = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_BEGIN, 'stateContext', 'requestParams', 'requestedPage');

export const analysisCrossSchemaSearch = (stateContext) => (dispatch, getState) => {
  const state = getState();
  const criteria = toJS(state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria']));
  const requestParams = buildSearchRequest(criteria);

  if (requestParams.error) return dispatch(logErrorNotification(requestParams.error));

  if (hasRequestChanged(toJS(state.getIn(['analysisCrossSchemaSearch', stateContext, 'requestParams'])), requestParams)) {
    requestParams.skip = 0;
    criteria.page = 1;
  }

  const searchArgs = encodeCriteriaToUrlArgs(criteria, window.location.search);

  dispatch(analysisCrossSchemaSearchBegin(stateContext, requestParams, criteria.page));

  authFetch(`${ANALYSIS_SEARCH_API_ROOT_URL}/v1/schemas/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestParams)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(analysisCrossSchemaSearchComplete(stateContext, data, searchArgs));

      if (data.count === 1) {
        const result = data.results[0];
        dispatch(analysisCrossSchemaSearchToggleExpand(stateContext, result.id));
      }
    })
    .catch(error => {
      dispatch(analysisCrossSchemaSearchComplete(stateContext));
      dispatch(logErrorNotification(error));
    })
    .finally(() =>{
      const location = new URL(window.location);
      if (stateContext === 'default' && location.pathname.replace('/','') === 'analysis') {  
        const state = getState();
        const criteria = toJS(state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria']));
        const searchArgs = encodeCriteriaToUrlArgs(criteria, window.location.search);
        dispatch(replace(window.location.pathname + '?' + searchArgs));
      }
    });
};

export const ANALYSIS_CROSS_SCHEMA_SEARCH_COMPLETE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_COMPLETE';
export const analysisCrossSchemaSearchComplete = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_COMPLETE, 'stateContext', 'data', 'searchArgs');

export const analysisCrossSchemaSearchLoadIdentityCategories = (stateContext, index, id) => (dispatch, getState) => {
  dispatch(analysisCrossSchemaSearchLoadIdentityCategoriesBegin(stateContext, index, id));
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/schemas/categories/${id}`)
    .then(response => response.json())
    .then(data => {
      dispatch(analysisCrossSchemaSearchLoadIdentityCategoriesComplete(stateContext, index, id, data));
      const state = getState();
      const schemas = toJS(state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'schemas'])) ?? [];
      const selectedSchema = schemas.find(s => s.isSelected === true);

      dispatch(analysisCrossSchemaSearchSelectCategories(stateContext, index, id, selectedSchema ? selectedSchema.schemaName : schemas.schemaName));
    })
    .catch(error => {
      dispatch(analysisCrossSchemaSearchLoadIdentityCategoriesComplete(stateContext, index, id));
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_BEGIN = 'ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_BEGIN';
const analysisCrossSchemaSearchLoadIdentityCategoriesBegin = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_BEGIN, 'stateContext', 'index', 'id');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_COMPLETE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_COMPLETE';
const analysisCrossSchemaSearchLoadIdentityCategoriesComplete = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_COMPLETE, 'stateContext', 'index', 'id', 'data');

export const analysisCrossSchemaSearchLoadIdentityInfo = (stateContext, index, id) => (dispatch, getState) => {
  dispatch(analysisCrossSchemaSearchLoadIdentityInfoBegin(stateContext, index, id));
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/schemas/identity/${id}`)
    .then(response => response.json())
    .then(data => {
      dispatch(analysisCrossSchemaSearchLoadIdentityInfoComplete(stateContext, index, id, data));
    })
    .catch(error => {
      dispatch(analysisCrossSchemaSearchLoadIdentityInfoComplete(stateContext, index, id));
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_BEGIN = 'ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_BEGIN';
const analysisCrossSchemaSearchLoadIdentityInfoBegin = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_BEGIN, 'stateContext', 'index', 'id');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_COMPLETE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_COMPLETE';
const analysisCrossSchemaSearchLoadIdentityInfoComplete = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_COMPLETE, 'stateContext', 'index', 'id', 'data');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_SELECT_CATEGORIES_SCHEMA = 'ANALYSIS_CROSS_SCHEMA_SEARCH_SELECT_CATEGORIES_SCHEMA';
export const analysisCrossSchemaSearchSelectCategories = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_SELECT_CATEGORIES_SCHEMA, 'stateContext', 'index', 'id', 'name');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND';
export const analysisCrossSchemaSearchToggleExpand = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND, 'stateContext', 'key');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_ITEM_DETAILS = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_ITEM_DETAILS';
export const analysisCrossSchemaSearchToggleExpandItemDetails = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_ITEM_DETAILS, 'stateContext', 'key');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_FACETS = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_FACETS';
export const analysisCrossSchemaSearchToggleExpandFacets = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_FACETS, 'stateContext');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_DETAILS = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_DETAILS';
export const analysisCrossSchemaSearchToggleExpandDetails = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_DETAILS, 'stateContext');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_UPDATE_CUSTOM_FILTER_HEIGHT = 'ANALYSIS_CROSS_SCHEMA_SEARCH_UPDATE_CUSTOM_FILTER_HEIGHT';
export const analysisCrossSchemaSearchUpdateCustomFilterHeight = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_UPDATE_CUSTOM_FILTER_HEIGHT, 'stateContext', 'value');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES_COMPLETE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES_COMPLETE';
const analysisCrossSchemaSearchToggleFavouritesComplete = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES_COMPLETE, 'stateContext', 'keys', 'value');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITE_COMPLETE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITE_COMPLETE';
const analysisCrossSchemaSearchToggleFavouriteComplete = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITE_COMPLETE, 'stateContext', 'key');

export const analysisCrossSchemaSearchAddFavourite = (stateContext, key) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/favourites/${key}`, {
    method: 'POST'
  })
    .then(() => {
      dispatch(analysisCrossSchemaSearchToggleFavouriteComplete(stateContext, key));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const analysisCrossSchemaSearchRemoveFavourite = (stateContext, key) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/favourites/${key}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch(analysisCrossSchemaSearchToggleFavouriteComplete(stateContext, key));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const analysisCrossSchemaSearchAddFavourites = (stateContext, keys) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/favourites`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      identityIds: keys
    })
  })
    .then(() => {
      dispatch(analysisCrossSchemaSearchToggleFavouritesComplete(stateContext, keys, true));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const analysisCrossSchemaSearchRemoveFavourites = (stateContext, keys) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/favourites`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      identityIds: keys
    })
  })
    .then(() => {
      dispatch(analysisCrossSchemaSearchToggleFavouritesComplete(stateContext, keys, false));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_CROSS_SCHEMA_SEARCH_UPDATE_FACET_STYLE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_UPDATE_FACET_STYLE';
export const analysisCrossSchemaSearchUpdateFacetStyle = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_UPDATE_FACET_STYLE, 'stateContext', 'data');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_EXPORT_BEGIN = 'ANALYSIS_CROSS_SCHEMA_SEARCH_EXPORT_BEGIN';
const analysisCrossSchemaSearchExportBegin = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_EXPORT_BEGIN, 'stateContext');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_EXPORT_COMPLETE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_EXPORT_COMPLETE';
const analysisCrossSchemaSearchExportComplete = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_EXPORT_COMPLETE, 'stateContext');

export const analysisCrossSchemaSearchExport = (stateContext, type) => (dispatch, getState) => {
  dispatch(analysisCrossSchemaSearchExportBegin(stateContext));
  const state = getState();
  const results = state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'data']).toJS();

  if (!results || !results.length) {
    dispatch(logWarningNotification('No results to export'));
    return;
  }

  const fileName = `analysis-search-export_${moment().format('DD-MMM-YYYY-HH-mm-ss')}`,
    columns = [
      'id', 'source', 'sourceId', 'name', 'dataType', 'style', 'granularity', 'granularityFrequency', 'granularityType', 'sourceTimeZoneId',
      'unit', 'currency', 'lastUpdatedUtc'
    ];

  if (type === 'json') jsonExport(fileName, columns, results)
  else csvExport(fileName, columns, results);

  dispatch(analysisCrossSchemaSearchExportComplete(stateContext));
};

export const analysisCrossSchemaSearchLoadStatistics = (stateContext, index, id) => (dispatch, getState) => {
  dispatch(analysisCrossSchemaSearchLoadStatisticsBegin(stateContext, index));
  authFetch(`${ANALYSIS_API_URL}/timeseries-statistics?id=${id}`)
    .then(response => response.json())
    .then((data) => {
      dispatch(analysisCrossSchemaSearchLoadStatisticsComplete(stateContext, index, id, data));
    })
    .catch(error => {
      dispatch(analysisCrossSchemaSearchLoadStatisticsComplete(stateContext, index, id));
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_BEGIN = 'ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_BEGIN';
const analysisCrossSchemaSearchLoadStatisticsBegin = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_BEGIN, 'stateContext', 'index', 'id');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_COMPLETE = 'ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_COMPLETE';
export const analysisCrossSchemaSearchLoadStatisticsComplete = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_COMPLETE, 'stateContext', 'index', 'id', 'data');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_CREATE_SEARCH = 'ANALYSIS_CROSS_SCHEMA_SEARCH_CREATE_SEARCH';
export const analysisCrossSchemaSearchCreateSearch = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_CREATE_SEARCH, 'stateContext', 'displayOptions', 'criteria');

export const ANALYSIS_CROSS_SCHEMA_SEARCH_DELETE_SEARCH = 'ANALYSIS_CROSS_SCHEMA_SEARCH_DELETE_SEARCH';
export const analysisCrossSchemaSearchDeleteSearch = createAction(ANALYSIS_CROSS_SCHEMA_SEARCH_DELETE_SEARCH, 'stateContext');