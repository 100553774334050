import moment from 'moment';
import { mapFromURLSearchParams, mapToURLSearchParams } from '../mapper/absRelDateMapper';
import { filterNames } from '../state/storage-auctions';

export function getFilters(lookups, locations, operators, sites) {
  const matchLocations = lookups.locations.filter(x =>
    (locations.length === 0 || locations.some(y => y === x.name)));

  const matchOperators = lookups.operators.filter(x =>
    (operators.length === 0 || operators.some(y => y === x.name)));

  const matchSites = lookups.sites.filter(x =>
    (sites.length === 0 || sites.some(y => y === x.name)) && matchOperators.some(y => y.id === x.operatorId));

  const lookupLocations = lookups.locations;

  const lookupOperators = lookups.operators.filter(x =>
    (matchLocations.some(y => y.id === x.locationId)));

  const lookupSites = lookups.sites.filter(x =>
    (matchOperators.some(y => y.id === x.operatorId))
    && (matchLocations.some(y => y.id === x.locationId)));

  const lookupNames = lookups.names.filter(x =>
    (matchSites.some(y => y.id === x.siteId))
    && (matchOperators.some(y => y.id === x.operatorId))
    && (matchLocations.some(y => y.id === x.locationId)));

  return {
    locations: lookupLocations.map(x => x.name),
    operators: lookupOperators.map(x => x.name),
    sites: lookupSites.map(x => x.name),
    names: lookupNames.map(x => x.name),
    statuses: lookups.statuses.map(x => x.name),
    storageYears: lookups.storageYears.map(x => x.name),
  }
}

export function mapWorkspaceToSearchRequest(workspace) {
  const request = {
    ...workspace,
    pageIndex: workspace.pageIndex - 1
  };
  delete request.hiddenColumns;
  delete request.hiddenFilters;
  delete request.hidePagination;
  return request;
}

export function mapAuctionSearchResponse(auctions) {
  return auctions.map(a => ({
    id: a.id,
    location: a.location,
    operator: a.operator,
    site: a.site,
    name: a.name,
    status: a.status,
    storageYear: a.storageYear,
    capacityOffered: a.capacityOffered,
    totalRequestedCapacity: a.totalRequestedCapacity,
    totalAllocatedCapacity: a.totalAllocatedCapacity,
    reservePrice: a.reservePrice,
    clearingPrice: a.clearingPrice,
    saleStartUtc: a.saleStartUtc ? moment(a.saleStartUtc) : undefined,
    saleEndUtc: a.saleEndUtc ? moment(a.saleEndUtc) : undefined,
    sourceUrl: a.sourceUrl,
    sourceId: a.sourceId,
    updatedBy: a.updatedBy,
    updatedUtc: moment(a.updatedUtc),
  }));
}

export function mapWorkspaceToURLSearchParamsString(state, stateKey) {
  const urlArgs = new URLSearchParams('');
  urlArgs.set('pageIndex', state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'pageIndex']));
  urlArgs.set('pageSize', state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'pageSize']));
  urlArgs.set('orderBy', state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'orderBy']));
  urlArgs.set('orderByDirection', state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'orderByDirection']));
  filterNames.forEach(filter => {
    state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'filters', filter]).forEach(f => {
      urlArgs.append(filter, f);
    });
  });

  mapToURLSearchParams(urlArgs, 'from', state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'dateFrom']));
  mapToURLSearchParams(urlArgs, 'to', state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'dateTo']));
  return urlArgs.toString();
}

export function mapWorkspaceFromSearchParamsString(urlSearchParams) {
  const response = {
    filters: {}
  };

  const urlArgs = new URLSearchParams(urlSearchParams);
  response.pageIndex = urlArgs.get('pageIndex') ? parseInt(urlArgs.get('pageIndex')) : undefined;
  response.pageSize = urlArgs.get('pageSize') ? parseInt(urlArgs.get('pageSize')) : undefined;
  response.orderBy = urlArgs.get('orderBy');
  response.orderByDirection = urlArgs.get('orderByDirection');
  response.dateFrom = mapFromURLSearchParams(urlArgs, 'from');
  response.dateTo = mapFromURLSearchParams(urlArgs, 'to');

  filterNames.forEach(filter => {
    let values = urlArgs.getAll(filter);
    if (values) {
      response.filters[filter] = values;
    }
  });

  return response;
}

export function setAreAnyFiltersApplied(state, stateKey) {
  let applied = false;
  state.getIn(['tiles', stateKey, 'workspace', 'filters']).forEach(f => {
    if (f.size > 0)
      applied = true;
  });

  return state.setIn(['tiles', stateKey, 'anyFiltersApplied'], applied);
}