import { createReducer } from '../utility/redux-utility';
import {
    TOOLBOX_EXEC_COMPLETE,
    TOOLBOX_EXEC_START,
    TOOLBOX_EXEC_STOP,
    TOOLBOX_TOGGLE_MATERIALISATION,
    TOOLBOX_TOGGLE_VARIANTS,
    TOOLBOX_TOGGLE_TEST_UNSAVED_CHANGES,    
    TOOLBOX_UPDATE_PROPERTY,
} from '../actions/toolbox';
import { fromJS } from 'immutable';

export const toolbox = createReducer(null, {
    [TOOLBOX_EXEC_START](state, action) {
      return state.set('isLoading', true);
    },
    [TOOLBOX_EXEC_STOP](state, action) {
      return state.set('isLoading', false);
    },
    [TOOLBOX_EXEC_COMPLETE](state, action) {
      return state.set('inputs', fromJS(action.data.inputs))
                  .set('steps', fromJS(action.data.steps))
                  .set('result', fromJS(action.data.result));
    },
    [TOOLBOX_TOGGLE_MATERIALISATION](state, action) {
      return state.set('disableMaterialisation', !state.get('disableMaterialisation'));
    },
    [TOOLBOX_TOGGLE_VARIANTS](state, action) {
      return state.set('disableVariants', !state.get('disableVariants'));
    },    
    [TOOLBOX_TOGGLE_TEST_UNSAVED_CHANGES](state, action) {
      return state.set('testUnsavedChanges', !state.get('testUnsavedChanges'));
    },    
    [TOOLBOX_UPDATE_PROPERTY](state, action) {
       return state.set(action.prop, action.value);
    }
});