import React from 'react';
import { connect } from 'react-redux';
import { getSelectionStatistics } from '../../../reducer-functions/reports';

export const ReportSelectionStatsConnected = connect(
  (state) => ({
    selectionRange: state.getIn(['reports', 'adjustments', 'editSelection', 'range']),
    orientation: state.getIn(['reports', 'results', 'settings', 'orientation'])
  }),
  (dispatch) => ({
  })
)(({ selectionRange, orientation, itemClassName = '' }) => {
  var stats = getSelectionStatistics(orientation, selectionRange);

  return <>
    {(stats).map((s, i) => <div key={`k-${i}`} className={itemClassName}>
      {s.name}:&nbsp;{s.value}
    </div>)}
  </>
});