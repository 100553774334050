import { memo } from 'react';
import moment from 'moment';
import numeral from 'numeral';

const acceptedDateFormats = [moment.ISO_8601, ...Object.values(moment.HTML5_FMT)];

function formatNumber({ input, commaSeparated, decimalPlaces }) {
  let isValid = false;
  let output = Number(input);

  if (isNaN(output))
    return { isValid, output: input };

  let options = { useGrouping: commaSeparated };

  if (!isNaN(decimalPlaces)) {
    options.minimumFractionDigits = decimalPlaces;
    options.maximumFractionDigits = decimalPlaces;
  }

  try {
    output = output.toLocaleString(undefined, options);
    isValid = true;
  }
  catch {
    return { isValid, output: input };
  }

  return { isValid, output };
}

function formatNumeral({ input, format }) {
  let isValid = false;

  if (isNaN(Number(input)) || !format)
    return { isValid, output: input };

  let output;

  try {
    output = numeral(input).format(format);
    isValid = true;
  }
  catch {
    return { isValid, output: input };
  }

  if (!isValid)
    return { isValid, output: input };

  return { isValid, output };
}

function formatDate({ input, format }) {
  let isValid = false;

  if (!format)
    return { isValid, output: input };

  let output = moment.utc(input, acceptedDateFormats, true);

  if (!(isValid = output.isValid()))
    return { isValid, output: input };

  return { isValid, output: output.format(format) };
}

export default memo(function FormattedValue({ children, commaSeparated, decimalPlaces, dateFormat, valueFormat }) {
  return formatValue({value:children, commaSeparated, decimalPlaces, dateFormat, valueFormat});
});

export function formatValue({ value, commaSeparated, decimalPlaces, dateFormat, valueFormat }) {
  if (value === null || value === undefined || value === '')
    return value;

  const numeralValue = formatNumeral({ input: value, format: valueFormat });
  if (numeralValue.isValid)
    return numeralValue.output;

  const numberValue = formatNumber({ input: value, commaSeparated, decimalPlaces });
  if (numberValue.isValid)
    return numberValue.output;

  const dateValue = formatDate({ input: value, format: dateFormat });
  if (dateValue.isValid)
    return dateValue.output;

  return value;
}