import { authFetch } from '../auth';
import { ETL_API_URL } from '../config';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const functionsSubmitRequest = (functionRequest) => (dispatch, getState) => {
  authFetch(`${ETL_API_URL}/jobrequest`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(functionRequest)
  })
  .then(() => {
    dispatch(logInfoNotification(`Function '${functionRequest.jobName}' submitted`));
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};