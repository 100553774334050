import React, { useRef, useEffect, useState } from 'react';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import useFetch from '../../../hooks/useFetch';
import { connect } from 'react-redux';
import { EVENTS_API_URL } from '../../../config';
import { authFetch } from '../../../auth';
import { logInfoNotification, logErrorNotification } from '../../../actions/log';
import { replace } from "redux-first-history";
import Loading from '../../shared/Loading';
import Modal from '../../shared/Modal';
import DatasetPicker from '../actions/DatasetPicker';
import AuditInfo from '../../shared/AuditInfo';
import { getValueWithFallback } from '../../../utility/property-utlility';
import { useParams } from 'react-router';
import PathNavLink from '../../shared/PathNavLink';

const eventTypes = [
  { value: "Unset", text: "Any" },
  { value: "Info", text: "Info" },
  { value: "Warning", text: "Warning" },
  { value: "Success", text: "Success" },
  { value: "Error", text: "Error" }
];

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUrl(key) {
      dispatch(replace(key));
    },
    logInfoNotification(message) {
      dispatch(logInfoNotification(message));
    },
    logErrorNotification(message) {
      dispatch(logErrorNotification(message));
    }
  };
};

function Editor({ viewConfiguration, id, setId, logInfoNotification, logErrorNotification, updateUrl, name, actionType, isEnabled, eventName, eventType, source, filter,
  notificationSettings, parameters, eventParameters, datasets, reloadData, auditInfo }) {
  const [isDirty, setIsDirty] = useState(false);
  const [nameText, setNameText] = useState(name);
  const [actionTypeText, setActionTypeText] = useState(actionType);
  const [isEnabledState, setIsEnabledState] = useState(isEnabled);
  const [eventNameText, setEventNameText] = useState(eventName);
  const [eventTypeText, setEventTypeText] = useState(eventType);
  const [sourceText, setSourceText] = useState(source);
  const [datasetOptions, setDatasetOptions] = useState(datasets);
  const [filterType, setFilterType] = useState(source && source.trim().length > 0 ? "source" : "dataset");
  const [filterJson, setFilter] = useState(filter);
  const [notificationSettingsText, setNotificationSettingsText] = useState(notificationSettings);
  const [eventParametersText, setEventParametersText] = useState(eventParameters);
  const [parametersText, setParametersText] = useState(parameters);
  const modalRef = useRef();

  const [currentViewConfig, setCurrentViewConfig] = useState(getValueWithFallback(viewConfiguration, actionTypeText));

  useEffect(() => setCurrentViewConfig(getValueWithFallback(viewConfiguration, actionTypeText)), [actionTypeText, viewConfiguration]);

  useDidUpdateEffect(() => {
    setIsDirty(true);
  }, [nameText, isEnabledState, eventNameText, eventTypeText, actionTypeText, parametersText, eventParametersText, sourceText, notificationSettingsText]);

  function onDelete(e) {
    e.preventDefault();
    modalRef.current.onShow({
      title: 'Delete Triggered action',
      message: 'Are you sure you want to delete this action?',
      onApply: () => {
        authFetch(`${EVENTS_API_URL}/actions/triggered/${id}`, {
          method: 'DELETE'
        })
          .then(() => {
            logInfoNotification(`Triggered action "${nameText}" deleted`);
            updateUrl("../../");
          })
          .catch(error => {
            logErrorNotification(error);
          });
      }
    });
  }

  function onSave(e) {
    e.preventDefault();
    const saveData = {
      id: id,
      name: nameText,
      actionType: actionTypeText,
      isEnabled: isEnabledState,
      eventName: eventNameText,
      eventType: eventTypeText,
      notificationSettings: notificationSettingsText && JSON.parse(notificationSettingsText),
      eventParameters: eventParametersText && JSON.parse(eventParametersText),
      parameters: parametersText && JSON.parse(parametersText),
      filter: filterJson === '' ? null : filterJson && JSON.parse(filterJson),
      source: filterType === 'source' ? sourceText : null,
      datasetIds: filterType === 'dataset' ? datasetOptions.map(d => d.id) : []
    };

    if (saveData.id === undefined) {
      authFetch(`${EVENTS_API_URL}/actions/triggered`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(saveData)
      })
        .then(response => response.json())
        .then(key => {
          logInfoNotification(`Triggered action "${saveData.name}" created`);
          setId(key.actionId);
          updateUrl(`${key.actionId}/edit`);
          setIsDirty(false);
        })
        .catch(error => {
          logErrorNotification(error);
        });
      return;
    }

    authFetch(`${EVENTS_API_URL}/actions/triggered/${saveData.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(saveData)
    })
      .then(response => response.json())
      .then(key => {
        logInfoNotification(`Triggered action "${saveData.name}" saved`);
        setIsDirty(false);
        reloadData();
      })
      .catch(error => {
        logErrorNotification(error);
      });
  };

  return (
    <div className='container-fluid'>
      <form className='my-3' noValidate >
        <div className='form-row'>
          <div className='col-md-4'>
            {id > 0 ? <div>Edit Triggered Action [id:{id}]</div> : <div>New Triggered Action</div>}
          </div>
          <div className='col-md-8 d-flex align-content-center justify-content-end'>
            <div className='btn-toolbar'>
              {/* <button type='button' className={`ml-1 btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={onSave}>
                <i className='fas fa-arrow-left fa-fw' />
                <span className='ml-8'>Close</span>
              </button> */}
            </div>
          </div>
        </div>
      </form>
      <div className='row'>
        <div className='col-md-8 col-lg-5'>
          <div className='form-group'>
            <label className='font-weight-bold'>Name</label>
            <input className='form-control' type='text' value={nameText} onChange={e => setNameText(e.target.value)} />
          </div>

          <div className='form-group'>
            <label className='font-weight-bold'>Action Type</label>
            <select className='form-control'
              value={actionTypeText}
              onChange={e => setActionTypeText(e.target.value)}>
              {Object.keys(viewConfiguration).map(k => <option key={k} value={viewConfiguration[k].actionType}>{viewConfiguration[k].text}</option>)}
            </select>
          </div>

          <div className='form-group'>
            <div className='form-check d-inline-block'>
              <input className='form-check-input' type='checkbox' value='' id='is-enabled' checked={isEnabledState} onChange={e => setIsEnabledState(e.target.checked)} />
              <label className='form-check-label font-weight-bold' htmlFor='is-enabled'>Is Enabled</label>
            </div>
          </div>

          <div className='form-group'>
            <label className='font-weight-bold'>Event Name</label>
            <input className='form-control' type='text' value={eventNameText} onChange={e => setEventNameText(e.target.value)} />
          </div>

          <div className='form-group'>
            <label className='font-weight-bold'>Event Type</label>
            <select className='form-control'
              value={eventTypeText}
              onChange={e => setEventTypeText(e.target.value)}>
              {eventTypes.map(i => <option key={i.value} value={i.value}>{i.text}</option>)}
            </select>
          </div>

          {currentViewConfig.showDatasetsOrSource &&
            <div>
              <label className='font-weight-bold'>Filter by</label>
              <div className='form-check d-inline-block ml-3'>
                <input className='form-check-input' type="radio" checked={filterType === 'source'} name="filter-by" id="filter-by-source" onChange={(e) => setFilterType('source')} />
                <label className='form-check-label' htmlFor='filter-by-source'>Source</label>
              </div>

              <div className='form-check d-inline-block ml-3'>
                <input className='form-check-input' type="radio" checked={filterType === 'dataset'} name="filter-by" id="filter-by-dataset" onChange={(e) => setFilterType('dataset')} />
                <label className='form-check-label' htmlFor='filter-by-dataset'>Dataset</label>
              </div>

              {filterType === 'source' && <div className='form-group'>
                <label className='font-weight-bold'>Source</label>
                <input className='form-control' type='text' value={sourceText} onChange={e => setSourceText(e.target.value)} />
              </div>}

              {filterType === 'dataset' && <div className='form-group'>
                <label className='font-weight-bold'>Datasets</label>
                <DatasetPicker datasetOptions={datasetOptions} setDatasetOptions={setDatasetOptions} setIsDirty={setIsDirty} />
              </div>}
            </div>}
          {!currentViewConfig.showDatasetsOrSource &&
            <div>
              {currentViewConfig.showSource && <div className='form-group'>
                <label className='font-weight-bold'>Source</label>
                <input className='form-control' type='text' value={sourceText} onChange={e => setSourceText(e.target.value)} />
              </div>}

              {currentViewConfig.showDatasets && <div className='form-group'>
                <label className='font-weight-bold'>Datasets</label>
                <DatasetPicker datasetOptions={datasetOptions} setDatasetOptions={setDatasetOptions} setIsDirty={setIsDirty} />
              </div>}
            </div>
          }

          {currentViewConfig.showFilter && <div className='form-group'>
            <label className='font-weight-bold'>Filter Settings</label>
            <button type='button' className='btn btn-sm float-right'
              title="Filter settings"
              onClick={e => { setFilter(JSON.stringify(currentViewConfig.filter, undefined, 2)); }}>
              <i className='fas fa-sort-amount-down fa-fw' />
            </button>
            <textarea className='form-control' rows='5' value={filterJson} onChange={e => setFilter(e.target.value)} />
          </div>}


          {currentViewConfig.showNotificationSettings && <div className='form-group'>
            <label className='font-weight-bold'>Notification Settings</label>
            <button type='button' className='btn btn-sm float-right'
              title="Use default notification settings"
              onClick={e => { setNotificationSettingsText(JSON.stringify(currentViewConfig.notificationSettings, undefined, 2)); }}>
              <i className='fas fa-sort-amount-down fa-fw' />
            </button>
            <textarea className='form-control' rows='5' value={notificationSettingsText} onChange={e => setNotificationSettingsText(e.target.value)} />
          </div>}

          {currentViewConfig.showParameters && <div className='form-group'>
            <label className='font-weight-bold'>Parameters</label>
            <button type='button' className='btn btn-sm float-right'
              title="Use default parameters"
              onClick={e => { setParametersText(JSON.stringify(currentViewConfig.parameters, undefined, 2)); }}>
              <i className='fas fa-sort-amount-down fa-fw' />
            </button>
            <textarea className='form-control' rows='5' value={parametersText} onChange={e => setParametersText(e.target.value)} />
          </div>}

          {currentViewConfig.showEventParameters && <div className='form-group'>
            <label className='font-weight-bold'>Event Parameters</label>
            <button type='button' className='btn btn-sm float-right'
              title="Use default event parameters"
              onClick={e => { setEventParametersText(JSON.stringify(currentViewConfig.eventParameters, undefined, 2)); }}>
              <i className='fas fa-sort-amount-down fa-fw' />
            </button>
            <textarea className='form-control' rows='5' value={eventParametersText} onChange={e => setEventParametersText(e.target.value)} />
          </div>}

          <div className='d-flex flex-row w-100'>
            <div className='flex-fill form-group'>
              <PathNavLink className='btn btn-outline-secondary' to={ `/actions/` }>Return to actions</PathNavLink>
            </div>
            <div className='form-group  float-right'>
              {id === 'new' ? null :
                <button type='button' className='btn btn-secondary' onClick={onDelete}>
                  <i className='fas fa-trash fa-fw' />
                  <span className='ml-8'>Delete</span>
                </button>}
              <button type='button' className={`ml-1 btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={onSave}>
                <i className='fas fa-save fa-fw' />
                <span className='ml-8'>Save</span>
              </button>
            </div>
          </div>

          {auditInfo && <div className='form-group'>
            <AuditInfo owner={auditInfo.owner} createdDateUtc={auditInfo.createdUtc} updatedBy={auditInfo.updatedBy} updatedDateUtc={auditInfo.updatedUtc} />
          </div>}
        </div>
      </div>
      <Modal ref={modalRef} />
    </div>
  );
}

const TriggeredActionEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ logInfoNotification, logErrorNotification, updateUrl }) => {
  const params = useParams();
  const [id, setId] = useState(params.id);
  const [getActionUrl, setActionUrl] = useState('');
  const [getDatasetsUrl, setDatasetsUrl] = useState('');
  const [, actionData, actionDataLoading] = useFetch(getActionUrl);
  const [, datasetsData, datasetsDataLoading] = useFetch(getDatasetsUrl);
  const [, configData, configLoading] = useFetch(`${EVENTS_API_URL}/actions/triggered/config`);

  useEffect(() => {
    if (id !== undefined) {
      setActionUrl(`${EVENTS_API_URL}/actions/triggered/${id}`);
      setDatasetsUrl(`${EVENTS_API_URL}/actions/triggered/${id}/datasets`);
    }
  }, [id]);

  function reloadData() {
    setActionUrl(`${EVENTS_API_URL}/actions/triggered/${id}?${Date.now()}`);
  }

  if (id === undefined) {
    return <Loading isLoading={configLoading}>
      {configData && <Editor
        viewConfiguration={configData}
        id={id}
        setId={setId}
        name={""}
        actionType={'dataPointUpdate'}
        isEnabled={true}
        eventName={"Fota.DataPointUpdate"}
        eventType={eventTypes[0].value}
        source={""}
        filter={""}
        notificationSettings={JSON.stringify(configData['dataPointUpdate'].notificationSettings || {}, undefined, 2)}
        parameters={JSON.stringify(configData['dataPointUpdate'].parameters || {}, undefined, 2)}
        eventParameters={JSON.stringify(configData['dataPointUpdate'].eventParameters || {}, undefined, 2)}
        datasets={[]}
        logInfoNotification={logInfoNotification}
        logErrorNotification={logErrorNotification}
        updateUrl={updateUrl} />
      }
    </Loading>;
  }

  return <Loading isLoading={actionDataLoading || datasetsDataLoading || configLoading}>
    {configData && actionData && datasetsData && <Editor
      viewConfiguration={configData}
      id={id}
      name={actionData.name}
      actionType={actionData.actionType}
      isEnabled={actionData.isEnabled}
      eventName={actionData.eventName}
      eventType={actionData.eventType || eventTypes[0].value}
      source={actionData.source}
      filter={actionData.filter === null ? "" : JSON.stringify(actionData.filter || {}, undefined, 2)}
      notificationSettings={JSON.stringify(actionData.notificationSettings || {}, undefined, 2)}
      parameters={JSON.stringify(actionData.parameters || {}, undefined, 2)}
      eventParameters={JSON.stringify(actionData.eventParameters || {}, undefined, 2)}
      datasets={datasetsData}
      logInfoNotification={logInfoNotification}
      logErrorNotification={logErrorNotification}
      updateUrl={updateUrl}
      reloadData={reloadData}
      auditInfo={{
        createdUtc: actionData.createdUtc,
        owner: actionData.owner,
        updatedBy: actionData.updatedBy,
        updatedUtc: actionData.updatedUtc
      }}
    />}
  </Loading>;
});

export default TriggeredActionEdit;