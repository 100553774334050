import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GridLayout from 'react-grid-layout';
import {
  dashboardTilesLoad,
  dashboardTilesEdit,
  dashboardTilesEditSave,
  dashboardTilesEditCancel,
  dashboardTilesEditLayoutToColumns,

  dashboardTilesUpdateCriteriaProperty,
  dashboardTilesRefreshAll,

  dashboardTileEditMoved,
  dashboardTileEditAddNew,
  dashboardSetValue,
  dashboardSetValues,
} from '../../../actions/dashboard-tiles';
import { toJS } from '../../../utility/immutable-utility';
import { TileConnected } from './TileConnected';

import { ModalDialogButton } from '../../shared/ModalDialogButton';
import { PopoverButton } from '../../shared/PopoverButton';
import JsonEditor from '../../shared/JsonEditor';
import HideOnScreenCapture, { isScreenCapture } from '../../shared/HideOnScreenCapture';

import { createNewTileContent, workspaceTypeToTileTypeMap } from '../../../mapper/dashboardTileMapper';
import { WorkspacePickerConnected } from './WorkspacePickerConnected';
import { DynamicWorkspacePickerConnected } from './DynamicWorkspacePickerConnected';
import { DashboardCriteriaConnected } from './DashboardCriteriaConnected';
import { dashboardTilesWorkspacePickerInitialise } from '../../../actions/dashboard-tiles-workspace-picker';
import { TileBuilderConnected } from './TileBuilderConnected';
import { DynamicWorkspaceDropDownEditorConnected, DynamicWorkspaceDropdownsConnected } from './DynamicWorkspaceDropDowns';
import { AutoRefresh } from '../../shared/AutoRefresh';
import { referenceDataLoadPeriods } from '../../../actions/referenceData';
import { dashboardTilesDynamicWorkspaceInitialise } from '../../../actions/dashboard-tiles-dynamic-workspace';

class DashboardTileContainer extends React.Component {
  static propTypes = {
    criteriaIsPinned: PropTypes.bool,
    autoRefreshSchedule: PropTypes.string,
    dashboardPath: PropTypes.object,
    tilesConfig: PropTypes.object,
    setValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,

    editMode: PropTypes.func.isRequired,
    editSave: PropTypes.func.isRequired,
    editCancel: PropTypes.func.isRequired,
    layoutToColumns: PropTypes.func.isRequired,
    workspacePickerInitialise: PropTypes.func.isRequired,
    refreshAll: PropTypes.func.isRequired,
    beginAddDynamicWorkspace: PropTypes.func.isRequired,
    loadDashboardTilesWorkspace: PropTypes.func.isRequired,
    
    gridRows: PropTypes.any.isRequired,
    layouts: PropTypes.any.isRequired,
    expandedTileKey: PropTypes.string,
    expandedTileState: PropTypes.string,
    dashboardWidth : PropTypes.number.isRequired
  }

  componentDidMount() {
    const {
      dashboardPath,
      loadDashboardTilesWorkspace
    } = this.props;

    if (dashboardPath) {
      const _dashboardPath = toJS(dashboardPath);
      loadDashboardTilesWorkspace({ path: _dashboardPath.path, isEditing: _dashboardPath.isEditing });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  onEdit() {
    this.props.editMode();
  }

  onEditSave() {
    this.props.editSave();
  }

  onEditCancel() {
    this.props.editCancel();
  }

  onApplyColumnsLayout(columns, closePopover) {
    this.props.layoutToColumns(columns);
    closePopover();
  }

  onRefresh() {
    this.props.refreshAll();
  }

  onCloseSelectWorkspaceTileDialog(closePopover) {
    closePopover();
  }

  onWorkspacePickerInitialise() {
    this.props.workspacePickerInitialise();
  }

  onBeginAddDynamicWorkspace() {
    this.props.beginAddDynamicWorkspace();
  }

  onEndAddDynamicWorkspace(closePopover) {
    closePopover();
  }

  onAddTile(dashboardTileType, closePopover) {
    closePopover();
    const { editAddTile } = this.props;

    const intialProps = { dashboardTileType };
    if (createNewTileContent(dashboardTileType, intialProps)) {
      this.props.setValues([
        [['editorState', 'newTilePropertyBag'],intialProps],
        [['editorState', 'newTileType'],dashboardTileType]
      ]);
    } else {
      editAddTile(intialProps);
    }
  }

  onLayoutChange(panels) {
    if (!this.props.isEditing)
      return;
      
    const layouts = this.props.layouts.toJS();
    panels.forEach(panel => {
      const layout = layouts.find(t => t.i === panel.i);
      if (layout) {
        if (layout.x !== panel.x || layout.y !== panel.y || layout.w !== panel.w || layout.h !== panel.h) {
          this.props.editMoveTile({ stateKey: layout.stateKey, x: panel.x, y: panel.y, width: panel.w, height: panel.h  });
        }
      }
    });
  }

  render() {
    const {
      autoRefreshSchedule,
      updateCriteriaProperty,
      isDirty,
      isEditing } = this.props;

    const layouts = this.props.layouts.toJS();
    const gridRows = this.props.gridRows.toJS();
    const expandedTileStyle = isScreenCapture() ? 
    { width: '100%', overflow: 'auto', height: '100vh', zIndex:'50'} : 
    { width: '100%', marginTop: '40px',  overflow: 'auto', height: 'calc(100vh - 82px)', zIndex:'50' };
    const showExpanded = this.props.expandedTileStateKey && this.props.expandedTileDashboardTileType;

    return <main className='d-flex flex-column fota-dashboard' >
      <TileBuilderConnected />
      <div className='d-flex flex-row'>
        <div className='flex-fill d-flex flex-row'>
          <div data_id='dashboard-tiles' className='flex-fill' style={expandedTileStyle}>
            <div style={{position:'relative', display:showExpanded ? 'none' : 'initial'}}>
              {isEditing && <div className='fota-dashboardItem-grid'>
                <table cellSpacing='0' cellPadding='0' style={{width:`${(this.props.dashboardWidth * 28)-5}px`}}>
                  <tbody>
                    {gridRows.map((r, ri) => <tr key={`r-${ri}`}>
                      {r.map((c, ci) => <td key={`c-${ri}-${ci}`}></td>)}
                    </tr>)}
                  </tbody>
                </table>
              </div>}

              <GridLayout
                onLayoutChange={args => this.onLayoutChange(args)}
                layout={layouts}
                draggableHandle='.fota-dashboardItem-drag-target'
                cols={this.props.dashboardWidth}
                rowHeight={20}
                width={this.props.dashboardWidth * 28}
                compactType={null}
                preventCollision={true}
                isDraggable={isEditing}
                isResizable={isEditing}>
                {layouts.map((layout,index) => (
                  <div data_id={`dashboard-tile-${index}`} key={`t-${layout.stateKey}`}>
                    <TileConnected isExpanded={false} dashboardTileType={layout.type} stateKey={layout.stateKey} />
                  </div>
                ))}
              </GridLayout>
            </div>

            {showExpanded && <main className='d-flex flex-column fota-dashboard h-100 w-100' >
              <TileConnected isExpanded={true} dashboardTileType={this.props.expandedTileDashboardTileType} stateKey={this.props.expandedTileStateKey} />
            </main>}
          </div>

          {this.props.criteriaIsPinned && !isScreenCapture() && <div style={{ width:'420px', marginTop: '40px', overflowY: 'auto', height: 'calc(100vh - 70px)', zIndex: '100', backgroundColor:'#fff', padding:'5px', boxShadow: '-5px 0px 5px rgba(0, 0, 0, .1)' }}>
            <div style={{ position: 'relative' }}>
            <div className='d-flex flex-column'>
                <div className='d-flex'>
                  <button type='button' className='btn btn-sm btn-xs btn-outline-primary m-1'><i className='fas fa-thumbtack fa-fw' onClick={() => this.props.setValue(['criteria', 'isPinned'], false)}/></button>
                  <div className='w-100'></div>
                </div>

              <DashboardCriteriaConnected/>
              </div>
            </div>
          </div>}
        </div>
      </div>

      <HideOnScreenCapture>
        <div data_id='toolbar' className={`w-100 d-flex d-flex-row justify-content-between ${isDirty || isEditing ? 'header-edit-mode' : 'header-non-edit-mode'}`} >
          <div className='align-content-center pt-2 pb-1' >
            <AutoRefresh className={'input-group-sm ml-2'} label={<i className='fas fa-clock' ></i>} autoRefreshSchedule={autoRefreshSchedule} setAutoRefreshSchedule={value => updateCriteriaProperty('autoRefreshSchedule', value)} onRefresh={this.props.refreshAll} />
          </div>

          <div className='d-flex pt-2 pb-1'>
            {isDirty && <div className='d-flex align-content-center container-sm'>
              <div className='mt-1'>This dashboard has unsaved changed</div>
              <button data_id='cancel' type='button' className='btn btn-sm btn-light ml-2' onClick={this.onEditCancel.bind(this)}>Discard</button>
              <button data_id='save' type='button' className='btn btn-sm btn-primary ml-2' onClick={this.onEditSave.bind(this)}>Save</button>
            </div>}

            {!isDirty && isEditing && <div className='d-flex align-content-center container-sm'>
              <div className='mt-1'>Add, move, resize and edit your tiles</div>
              <button data_id='cancel' type='button' className='btn btn-sm btn-light ml-2' onClick={this.onEditCancel.bind(this)}>Done customising</button>
              <button data_id='save' type='button' className='btn btn-sm btn-primary ml-4' onClick={this.onEditSave.bind(this)}>Save</button>
            </div>}
          </div>

          <div className='d-flex align-content-center container-sm pt-2 pb-1'>
            <DynamicWorkspaceDropdownsConnected />
            {isEditing && <DynamicWorkspaceDropDownEditorConnected />}

            {isEditing && <PopoverButton data_id='layout' buttonClassName='btn btn-sm btn-secondary mr-3' buttonContent={<><i className="fas fa-th" /></>}>
              {({ closePopover }) => (<div className='fota-dashboard-criteria-property-group'>
                <div className='pb-2'>
                  <label className='font-weight-bold' style={{ fontSize: '.9em' }}>Layout</label>
                  <button data_id='layout-columns-1' className='btn dropdown-item' type='button' onClick={_ => this.onApplyColumnsLayout(1, closePopover)}><i className="fas fa-square-full" />&nbsp;Single column</button>
                  <button data_id='layout-columns-2' className='btn dropdown-item' type='button' onClick={_ => this.onApplyColumnsLayout(2, closePopover)}><i className="fas fa-th-large" />&nbsp;2 columns</button>
                  <button data_id='layout-columns-3' className='btn dropdown-item' type='button' onClick={_ => this.onApplyColumnsLayout(3, closePopover)}><i className="fas fa-th" />&nbsp;3 Columns</button>
                  <button data_id='layout-columns-4' className='btn dropdown-item' type='button' onClick={_ => this.onApplyColumnsLayout(4, closePopover)}><i className="fas fa-grip-vertical" /><i style={{ marginLeft: '1.5px' }} className="fas fa-grip-vertical" />&nbsp;4 Columns</button>
                </div>
              </div>)}
            </PopoverButton>}

            {!this.props.criteriaIsPinned && <PopoverButton data_id='criteria-popup' buttonClassName='btn btn-sm btn-secondary' buttonContent={<><i className="far fa-calendar-alt" /></>}>
              {() => <div style={{width:'350px'}}>
                <div className='d-flex'>
                  <div className='w-100'></div>
                    <button type='button' className='btn btn-sm btn-xs btn-outline-primary m-1'><i className='fas fa-thumbtack fa-fw' onClick={() => this.props.setValue(['criteria', 'isPinned'], true)}/></button>
                  </div>
                  <div className='p-2'>
                    <DashboardCriteriaConnected />
                  </div>
                </div>}
            </PopoverButton>}

            <button type='button' className='btn btn-sm btn-primary ml-2' style={{ whiteSpace: 'nowrap' }} onClick={this.onRefresh.bind(this)}><i className='fas fa-sync-alt fa-fw mr-2' />Refresh</button>
            
            {!isEditing && <button data_id='edit' type='button' className='btn btn-sm btn-secondary ml-2 mr-1' onClick={this.onEdit.bind(this)}>Edit</button>}
            {isEditing && <DashboardInfoConnected />}
            {isEditing && <>
              <PopoverButton buttonClassName='btn btn-sm btn-secondary ml-2 mr-1' buttonContent={<><i className='fas fa-plus fa-fw' /><i className='fas fa-caret-down fa-fw' /></>}>
                {({ closePopover: closeOuterPopover }) => (<div>

                  <ModalDialogButton data_id='add-item' onOpenDialog={() => this.onWorkspacePickerInitialise()} onClose={() => this.onCloseSelectWorkspaceTileDialog(closeOuterPopover)} buttonClassName='btn dropdown-item' buttonContent={'Item'} >
                    {({ closeDialog }) => (
                      <WorkspacePickerConnected
                        workspaceTypeToTileTypeMap={workspaceTypeToTileTypeMap}
                        closeDialog={closeDialog}
                      />
                    )}
                  </ModalDialogButton>

                  <button data_id='add-data-tile' className='btn dropdown-item' type='button' onClick={() => this.onAddTile('AnalysisTable', closeOuterPopover)}>Data Tile</button>
                  <ModalDialogButton data_id='add-dynamic-workspace' onOpenDialog={() => this.onBeginAddDynamicWorkspace()} onClose={() => this.onEndAddDynamicWorkspace(closeOuterPopover)} buttonClassName='btn dropdown-item' buttonContent={'Dynamic Workspace'} >
                    {({ closeDialog }) => (
                      <DynamicWorkspacePickerConnected
                        workspaceTypeToTileTypeMap={workspaceTypeToTileTypeMap}
                        closeDialog={closeDialog} />
                    )}
                  </ModalDialogButton>
                  <button data_id='markdown' className='btn dropdown-item' type='button' onClick={() => this.onAddTile('Markdown', closeOuterPopover)}>Markdown</button>
                </div>)}
              </PopoverButton>
            </>}
          </div>
        </div>
      </HideOnScreenCapture>
    </main >
  }
}

const DashboardInfoConnected = connect(
  (state) => ({
    showDebug: state.getIn(['userSettings', 'settings', 'show-debug']),
    dashboardTiles: state.getIn(['dashboardTiles']),
  }),
  (dispatch) => ({})
)(({ showDebug, dashboardTiles }) => {

  return showDebug && <PopoverButton buttonClassName='btn btn-sm btn-secondary ml-2 mr-1' buttonContent={<><i className='fas fa-code' /></>}>
    {() => {
      let js = dashboardTiles.toJS();
      delete js.editorState;
      delete js.dynamicWorkspaceDropdownsRevertState;
      delete js.tilesConfigRevertState;
      delete js.tilesStateRevertState;
      delete js.tilesState;
      if (js.workspace && js.workspace.data && typeof js.workspace.data === 'string')
        js.workspace.data = JSON.parse(js.workspace.data);

      return <div style={{ width: '800px', height: '800px' }}><JsonEditor mode='code' data={js} /></div>
    }
    }
  </PopoverButton>
});

const DashboardConnected = connect(
  (state) => ({
    dashboardPath: state.getIn(['dashboardTiles', 'dashboardPath']),
    tilesConfig: state.getIn(['dashboardTiles', 'tilesConfig']),
    isEditing: state.getIn(['dashboardTiles', 'isEditing']),
    isDirty: state.getIn(['dashboardTiles', 'isDirty']),
    dashboardTilesWorkspaceLoadStatus: state.getIn(['dashboardTiles', 'dashboardTilesWorkspaceLoadStatus']),
    criteriaIsPinned: state.getIn(['dashboardTiles', 'criteria', 'isPinned']),
    autoRefreshSchedule: state.getIn(['dashboardTiles', 'criteria', 'autoRefreshSchedule']) ?? '0',
    gridRows: state.getIn(['dashboardTiles', 'ui', 'gridRows']),
    layouts: state.getIn(['dashboardTiles', 'ui', 'layouts']),
    expandedTileStateKey: state.getIn(['dashboardTiles', 'ui', 'expandedTile','stateKey']),
    expandedTileDashboardTileType: state.getIn(['dashboardTiles', 'ui', 'expandedTile','dashboardTileType']),
    dashboardWidth: state.getIn(['dashboardTiles', 'options', 'dashboardWidth']),
  }),
  (dispatch) => ({
    loadDashboardTilesWorkspace({ path, isEditing = false }) {
      dispatch(referenceDataLoadPeriods());
      dispatch(dashboardTilesLoad({ path, isEditing }));
    },
    editMode() {
      dispatch(dashboardTilesEdit());
    },
    editSave() {
      dispatch(dashboardTilesEditSave());
    },
    editCancel() {
      dispatch(dashboardTilesEditCancel());
    },
    layoutToColumns(columns) {
      dispatch(dashboardTilesEditLayoutToColumns(columns));
    },
    editMoveTile({ stateKey, x, y, width, height }) {
      dispatch(dashboardTileEditMoved(stateKey, x, y, width, height));
    },
    editAddTile({ dashboardTileType, properties }) {
      dispatch(dashboardTileEditAddNew(dashboardTileType, properties));
    },
    setValue(key, value) {
      dispatch(dashboardSetValue(key, value));
    },
    setValues(list) {
      dispatch(dashboardSetValues(list));
    },
    updateCriteriaProperty(key, value) {
      dispatch(dashboardTilesUpdateCriteriaProperty(key, value));
    },

    refreshAll() {
      dispatch(dashboardTilesRefreshAll());
    },

    workspacePickerInitialise() {
      dispatch(dashboardTilesWorkspacePickerInitialise());
    },

    beginAddDynamicWorkspace() {
      dispatch(dashboardTilesDynamicWorkspaceInitialise());
    }
  })
)((props) => {
  if (props.dashboardTilesWorkspaceLoadStatus === 'no-data')
    return <></>

  return <DashboardTileContainer {...props} />
});

export default DashboardConnected;