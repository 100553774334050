import React from 'react';
import Timezone from './Timezone';
import FormattedDateTime from './FormattedDateTime';

function AuditInfo({ owner, createdBy, createdDateUtc, updatedBy, updatedDateUtc }) {
  return <div>
    <label className='font-weight-bold'>Audit</label>
    <div>
      Created <Timezone />:<FormattedDateTime>{createdDateUtc}</FormattedDateTime>
    </div>
    {createdBy && <div>Created by:{createdBy}</div>}
    <div>
      Owner:{owner}
    </div>
    <div>
      Updated by:{updatedBy}
    </div>
    <div>
      Update <Timezone />:<FormattedDateTime>{updatedDateUtc}</FormattedDateTime>
    </div>
  </div>;
}

export default AuditInfo;