import React from 'react';
import FormattedNumber from '../../shared/FormattedNumber';

export default function OutagesDetailsDetailPanel({ data, labelColumnWidth, valueCellStyles }) {
  const { source, type, reason, productionType, unitName, unitEic, biddingZoneEic, marketParticipant, marketParticipantCode,
    availableCapacity, unavailableCapacity, maxCapacity: installedCapacity } = data ?? {};

  return (
    <table className='w-100'>
      <thead>
        <tr>
          <th width={labelColumnWidth}>Detail</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Source</td>
          <td style={valueCellStyles}>{source}</td>
        </tr>
        <tr>
          <td>Event Type</td>
          <td style={valueCellStyles}>{type}</td>
        </tr>
        <tr>
          <td>Reason</td>
          <td style={{ ...valueCellStyles, wordBreak: 'break-all' }}>{reason}</td>
        </tr>
        <tr>
          <td>Bidding Zone</td>
          <td style={valueCellStyles}>{biddingZoneEic}</td>
        </tr>
        <tr>
          <td>Production Type</td>
          <td style={valueCellStyles}>{productionType}</td>
        </tr>
        <tr>
          <td>Capacity</td>
          <td style={valueCellStyles}>
            <table className='w-100' style={{ maxWidth: '300px' }}>
              <thead>
                <tr>
                  <th className='w-25'>Installed</th>
                  <th className='w-25'>Available</th>
                  <th className='w-25'>Unavailable</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><FormattedNumber minDecimals={2} maxDecimals={2}>{installedCapacity}</FormattedNumber></td>
                  <td><FormattedNumber minDecimals={2} maxDecimals={2}>{availableCapacity}</FormattedNumber></td>
                  <td><FormattedNumber minDecimals={2} maxDecimals={2}>{unavailableCapacity}</FormattedNumber></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>Market Participant</td>
          <td style={valueCellStyles}>
            <table className='w-100' style={{ maxWidth: '300px' }}>
              <thead>
                <tr>
                  <th className='w-50'>Name</th>
                  <th className='w-50'>Code</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{marketParticipant}</td>
                  <td>{marketParticipantCode}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>Unit</td>
          <td style={valueCellStyles}>
            <table className='w-100' style={{ maxWidth: '300px' }}>
              <thead>
                <tr>
                  <th className='w-50'>Name</th>
                  <th className='w-50'>Eic</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{unitName}</td>
                  <td>{unitEic}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}