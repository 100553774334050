import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import {
  ANALYSIS_COMPOSITION_LOAD_STARTED,
  ANALYSIS_COMPOSITION_LOAD_COMPLETED,
  ANALYSIS_COMPOSITION_SET_SELECTED_LEVEL,
  ANALYSIS_COMPOSITION_SET_MAX_DEPTH,
  ANALYSIS_COMPOSITION_REFRESH_SELECTED,
  ANALYSIS_COMPOSITION_TOGGLE_SELECTED,
  ANALYSIS_COMPOSITION_TOGGLE_EXPANDED,
  ANALYSIS_COMPOSITION_TIER_TOGGLE_EXPANDED
} from '../actions/analysis-composition-v2';
import { ANALYSIS_CROSS_SCHEMA_SEARCH_COMPLETE } from '../actions/analysis-cross-schema-search';

function buildDisplayMap({ rows, displayMap = {}, path = [], selectedIds = [], selectedLevel = 0, depth = 0 }) {
  let maxLevel = depth;

  if (rows) for (let i = 0, row; i < rows.length && (row = rows[i]); i++) {
    const rowKey = `${row.id}`;
    const rowPath = [...path, rowKey];
    const displayKey = rowPath.join('-');

    const isExpanded = depth <= selectedLevel;
    const isSelected = selectedIds.includes(rowKey);

    displayMap[displayKey] = { isExpanded, isSelected };

    row.isExpanded = isExpanded;
    row.isSelected = isSelected;

    if (row.items) {
      let result = buildDisplayMap({ rows: row.items, displayMap, path: rowPath, selectedIds, selectedLevel, depth: depth + 1 });

      if (result.maxLevel > maxLevel)
        maxLevel = result.maxLevel;

      displayMap = result.displayMap;
    }
  }

  return { rows: rows || [{}], displayMap, maxLevel };
}

function getSelectedIds(state) {
  let selectedIds = [];

  const basket = state.getIn(['workspace', 'timeseries']);

  if (basket) selectedIds = basket.map(i => `${i.get('identityId')}`);

  return selectedIds;
}

function findKeyPaths(items, id, keyPath, keyPaths = []) {
  const index = items ? items.findIndex(i => `${i.get('id')}` === id) : -1;

  if (index >= 0)
    return [...keyPaths, [...keyPath, index]];

  for (let i = 0, item; items && i < items.size && (item = items.get(i)); i++) {
    const _items = item.get('items');

    if (!_items || !_items.size) continue;

    keyPaths = [
      ...keyPaths,
      ...findKeyPaths(_items, id, [...keyPath, i, 'items'])
    ];
  }

  return keyPaths;
}

export const compositionReducerV2 = {
  [ANALYSIS_COMPOSITION_LOAD_STARTED](state, action) {
    return state.setIn(['composition', 'data', `${action.key}`], fromJS({}))
                .setIn(['composition', `${action.key}`, 'isLoading'], true);
  },
  [ANALYSIS_COMPOSITION_LOAD_COMPLETED](state, action) {
    if (!action.data)
      return state.setIn(['composition', 'data', `${action.key}`], fromJS({}))
                  .setIn(['composition', 'maxLevel'], 0)
                  .setIn(['composition', `${action.key}`, 'isLoading'], false)
                  .setIn(['composition', `${action.key}`, 'isInitialised'], true);

    const selectedLevel = state.getIn(['composition', 'selectedLevel']);

    let selectedIds = getSelectedIds(state);
    let { rows, maxLevel } = action.data
      ? buildDisplayMap({ rows: [action.data], selectedIds, selectedLevel })
      : { rows: [{}], maxLevel: 0 };

    return state.setIn(['composition', 'data', `${action.key}`], fromJS(rows[0]))
                .updateIn(['composition', 'maxLevel'], value => value > maxLevel ? value : maxLevel)
                .setIn(['composition', `${action.key}`, 'isLoading'], false)
                .setIn(['composition', `${action.key}`, 'isInitialised'], true);
  },
  [ANALYSIS_COMPOSITION_REFRESH_SELECTED](state, action) {
    let newState = state;

    const compositions = state.getIn(['composition', 'data']);
    if (!compositions || !compositions.size) return newState;

    const selectedLevel = state.getIn(['composition', 'selectedLevel']);

    let selectedIds = getSelectedIds(state);

    compositions.keySeq().forEach(key => {
      let data = toJS(compositions.get(`${key}`), {});
      let { rows } = buildDisplayMap({ rows: [data], selectedIds, selectedLevel });

      newState = newState.setIn(['composition', 'data', `${key}`], fromJS(rows[0]));
    });

    return newState;
  },
  [ANALYSIS_COMPOSITION_TOGGLE_SELECTED](state, action) {
    let selectedIds = getSelectedIds(state);

    const isSelected = selectedIds.includes(`${action.key}`);
    const compositions = state.getIn(['composition', 'data']);
    const allKeyPaths = compositions.keySeq().reduce((accumulator, key) => {
      const items = state.getIn(['composition', 'data', key, 'items']);
      const keyPaths = findKeyPaths(items, `${action.key}`, ['composition', 'data', key, 'items']);

      return [
        ...accumulator,
        ...keyPaths
      ];
    }, []);

    let newState = state;
    allKeyPaths.forEach(keyPath => newState = newState.updateIn([...keyPath, 'isSelected'], () => isSelected));

    return newState;
  },
  [ANALYSIS_COMPOSITION_TOGGLE_EXPANDED](state, action) {
    const dataKeyPath = action.indexKeyPath
      ? ['composition', 'data', `${action.key}`, ...action.indexKeyPath.split(','), 'isExpanded']
      : ['composition', 'data', `${action.key}`, 'isExpanded'];

    return state.updateIn(dataKeyPath, value => !value);
  },
  [ANALYSIS_COMPOSITION_TIER_TOGGLE_EXPANDED](state, action) {
    let newState = state.setIn(['composition', 'selectedLevel'], action.selectedLevel);

    const compositions = state.getIn(['composition', 'data']);
    if (!compositions || !compositions.size) return newState;

    let selectedIds = getSelectedIds(state);

    compositions.keySeq().forEach(key => {
      let data = toJS(compositions.get(`${key}`), {});
      let { rows } = buildDisplayMap({ rows: [data], selectedIds, selectedLevel: action.selectedLevel });

      newState = newState.setIn(['composition', 'data', `${key}`], fromJS(rows[0]));
    });

    return newState;
  },
  [ANALYSIS_COMPOSITION_SET_SELECTED_LEVEL](state, action) {
    return state.setIn(['composition', 'selectedLevel'], action.selectedLevel);
  },
  [ANALYSIS_COMPOSITION_SET_MAX_DEPTH](state, action) {
    return state.setIn(['composition', 'maxDepth'], action.maxDepth);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_COMPLETE](state, action) {
    const selectedLevel = state.getIn(['composition', 'selectedLevel']);
    const maxDepth = state.getIn(['composition', 'maxDepth']);

    return state.setIn(['composition'], fromJS({ selectedLevel, maxLevel: 0, maxDepth, data: {}, displayMap: {} }));
  },
};