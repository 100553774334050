import React, { cloneElement, useRef, useState, useEffect } from 'react';
import { ResizeableCol } from '../../shared/ColResizer';

function TableColumn({ tableRef, column, filters, orderBy, orderByDirection, onSelectFilter, onSortChange, onResize, isResizeable, dataset, colWidth }) {
  const { className = '', style = {}, key, title, filterable, sortable, leftComponent, rightComponent, noWrap = true } = column || {};
  const thClassName = [
    className,
    filterable ? 'filterable' : '',
    sortable ? 'sortable' : '',
    noWrap ? 'text-nowrap' : ''
  ].filter(i => i).join(' ');

  const thWidth = colWidth !== undefined ? parseInt(colWidth) + 'px' : undefined;
  const thStyle = {
    userSelect: 'none',
    width: thWidth,
    minWidth: thWidth,
    ...style
  };

  const columnProps = removeUnsupportedPropsForDomElement(column);

  return column
    ? <ResizeableCol tableRef={ tableRef } className={ thClassName } style={ thStyle } disableResize={ !isResizeable } dataset={dataset}
                     onClick={ sortable ? () => onSortChange(key) : undefined }
                     onResize={ isResizeable ? value => isResizeable && onResize(key, value) : undefined }>
        <div className='d-flex justify-content-between align-items-center'>
          { leftComponent ? cloneElement(leftComponent, { ...columnProps }) : '' }
          <span>{ title }</span>
          { rightComponent ? cloneElement(rightComponent, { ...columnProps }) : '' }
          { filterable ? <TableFilterIcon columnKey={ key } filters={ filters } onSelectFilter={ onSelectFilter } /> : '' }
          { sortable ? <TableSortIcon columnKey={ key } orderBy={ orderBy } orderByDirection={ orderByDirection } onSortChange={ onSortChange } /> : '' }
        </div>
      </ResizeableCol>
    : <ResizeableCol tableRef={ tableRef } disableResize={ !isResizeable } style={{ width: thStyle.width }} dataset={dataset}
                     onResize={ isResizeable ? value => isResizeable && onResize(column, value) : undefined } />
}

function removeUnsupportedPropsForDomElement(props) {
  return Object.fromEntries(Object.entries(props).filter(([key]) => key !== 'leftComponent' && key !== 'rightComponent'));
}

function TableEmpty({ message, columnsCount }) {
  return (
    <TableBody>
      <tr>
        <td colSpan={ columnsCount }>
          <div className='row no-gutters'>
            <div className='col text-center'>{ message || 'No data' }</div>
          </div>
        </td>
      </tr>
    </TableBody>
  );
}

function TableBody({ children, useTBody = true }) {
  return useTBody ? <tbody className='border-top-0'>{ children }</tbody> : children;
}

function TableFilterIcon({ filters, columnKey, onSelectFilter }) {
  const isActive = filters.some(f => f.name === columnKey && f.value !== undefined && f.value !== null && f.value !== '');

  return isActive
    ? <i className='fas fa-filter fa-fw' onClick={ (e) => onSelectFilter(e, columnKey) } />
    : <i className='fas fa-filter fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} onClick={ (e) => onSelectFilter(e, columnKey) } />;
}

function TableSortIcon({ orderBy, orderByDirection, columnKey }) {
  return orderBy && orderBy === columnKey
    ? orderByDirection === 'desc'
    ? <i className='fas fa-sort-down fa-fw' />
    : <i className='fas fa-sort-up fa-fw' />
    : <i className='fas fa-sort fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} />;
}

function TableFilter({ filter, onChange }) {
  const [isVisible, setVisibility] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const filterRef = useRef();
  const filterInput = useRef();

  useEffect(() => {
    window.$(filterRef.current).modal({
      show: false
    })
    .on('shown.bs.modal', () => filterInput.current.focus())
    .on('hidden.bs.modal', () => onDismiss());
  }, []);

  useEffect(() => {
    if (filter.name) {
      setFilterValue(filter.value || '');
      setVisibility(true);
    }
  }, [filter]);

  useEffect(() => {
    window.$(filterRef.current).modal(isVisible ? 'show' : 'hide');
  }, [isVisible]);

  function onSubmit(e) {
    e.preventDefault();

    onChange(filter.name, filterValue);
    onDismiss();
  }

  function onClear() {
    onChange(filter.name, '');
    onDismiss();
  }

  function onDismiss() {
    setFilterValue('');
    setVisibility(false);
  }

  return (
    <div ref={ filterRef } className='modal fade' tabIndex='-1'>
      <form className='modal-dialog' onSubmit={ onSubmit }>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Filter</h5>
            <button type='button' className='close' data-dismiss='modal'>&times;</button>
          </div>
          <div className='modal-body'>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <label className='input-group-text'>{ filter.name }</label>
              </div>
              <div className='input-group-prepend'>
                <label className='input-group-text'>eq</label>
              </div>
              <input ref={ filterInput } type='text' className='form-control' value={ filterValue } onChange={ (e) => setFilterValue(e.target.value) } />
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-outline-secondary mr-auto' data-dismiss='modal' onClick={ onClear }>Clear</button>
            <button type='button' className='btn btn-secondary' data-dismiss='modal'>Close</button>
            <button type='submit' className='btn btn-primary'>Apply filter</button>
          </div>
        </div>
      </form>
    </div>
  );
}

const caretDownSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 448"><path transform="matrix(1 0 0 -1 0 448)" fill="%23007bff" d="M31.2998 256h257.3c17.8008 0 26.7002 -21.5 14.1006 -34.0996l-128.601 -128.7c-7.7998 -7.7998 -20.5 -7.7998 -28.2998 0l-128.6 128.7c-12.6006 12.5996 -3.7002 34.0996 14.0996 34.0996z" /></svg>';

export default function ReportsTableHorizontal({
  isEditing,
  rootScenario, scenarioWidth, scenarios, scenarioMode, children, columns, data, annotationSections, emptyMessage, useTBody, useStickyHeader = false, isActiveTest,
  filters, onFilterChange, orderBy, orderByDirection, onSortChange = () => {}, onResize, setAllScenarioOverride, doubleClick, mouseDown, mouseMove
}) {
  const [filter, setFilter] = useState('');
  const tableRef = useRef();
  const _rows = data.map(d => {
    let row = {
      id: d.id,
      cellData: {},
      rawData: { ...d }
    };

    columns.forEach(c => c && d[c.key] !== null && d[c.key] !== undefined ? row.cellData[c.key] = c.format ? c.format(d[c.key]) : d[c.key] : '');
    return row;
  });

  function onSelectFilter(e, name) {
    e.stopPropagation();

    const value = filters && filters.length ? (filters.find(f => f.name === name) || {}).value || '' : '';

    setFilter({ name: name, value: value });
  }

  function onDoubleClick(e){
    doubleClick(e);
  }

  function onMouseDown(e){
    if (e.buttons !== 1 || e.button !== 0) return;// Primary mouse button
    mouseDown(e);
  }

  function onMouseMove(e){
    if (e.buttons !== 1 || e.button !== 0) return; // Primary mouse button
    mouseMove(e);
  }

  const [reportRowComponent, annotationRowComponent] = children;

  const scenarioColumn = {
    rightComponent: (
      <div style={{ minWidth: 10 }}>
        <button data_id='scenarios-clear' className='btn btn-xs btn-link p-0 m-0'
                style={{ position: 'absolute', top: 0, right: '6.5px' }}
                onClick={() => setAllScenarioOverride('')}>
          <i className='fas fa-times' />
        </button>
        <select data_id='scenario-top-level-selector' className='form-control form-control-sm p-0 border-0'
          style={{
            height: '15px',
            backgroundColor: 'inherit',
            backgroundImage: 'scenarios'.length > 0 ? `url('data:image/svg+xml;charset=UTF-8,${caretDownSvg}')` : 'none',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right .2em top 50%, 0 0',
            backgroundSize: '.65em auto, 100%',
            appearance: 'none'
          }}
          value={rootScenario} disabled={!scenarios.length}
          onChange={e => setAllScenarioOverride(e.target.value)}>
          <option value='' />
          {scenarios.map((i, ix) => <option key={ix} value={i.scenarioName}>{i.scenarioName}</option>)}
        </select>
      </div>
    )
  };

  return <>
      <table data_id='report-table' ref={ tableRef } tabIndex='0' className={ `table table-sm table-bordered ${(isEditing ? 'report-adjustments-enabled': 'spreadsheet-selection-enabled')} `} onDoubleClick={onDoubleClick} onMouseDown={onMouseDown} onMouseMove={onMouseMove}>
        <thead>
          <tr className={ `bg-light ${useStickyHeader ? 'sticky-header' : ''}` }>
            { scenarioMode === 'Enabled' && (
              <TableColumn tableRef={ tableRef } isResizeable={ true }
                           column={ scenarioColumn }
                           onResize={ onResize }
                           colWidth={ scenarioWidth } />
            )}
            { columns.map((c, cx) => (
              <TableColumn key={ cx } tableRef={ tableRef } column={ c } isResizeable={ cx < 1 }
                           filters={ filters } onSelectFilter={ onSelectFilter }
                           onResize = {() => {}}
                           orderBy={ orderBy } orderByDirection={ orderByDirection } onSortChange={ onSortChange } />
            )) }
          </tr>
        </thead>
        { _rows && _rows.length ? (
          <TableBody useTBody={ useTBody }>
            { _rows.map((r, rx) => {
              const isActive = isActiveTest && isActiveTest(r);
              const key = `${r.id}-${rx}`;

              return cloneElement(reportRowComponent, { key, index: rx, cellData: r.cellData, rawData: r.rawData, isActive })
            }) }
          </TableBody>
        ) : (
          <TableEmpty message={ emptyMessage } columnsCount={ columns.length } />
        ) }
        { annotationSections && annotationSections.filter(s => s.annotations && s.annotations.length).map((s, sx) => (
          <TableBody key={ `as-${sx}` } useTBody={ useTBody }>
            { s.annotations.map((ar, arx) => {
              const isActive = isActiveTest && isActiveTest(ar);

              return cloneElement(annotationRowComponent, {
                key: `ar-${ar.id}-${arx}`,
                index: arx,
                section: s,
                cellData: ar.cellData,
                rawData: ar,
                isActive,
                isFirstChild: arx === 0,
                hasSiblings: s.annotations.length
              });
            }) }
          </TableBody>
        )) }
      </table>
      { !!onFilterChange && <TableFilter filter={ filter } onChange={ onFilterChange } /> }
    </>
};