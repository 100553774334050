import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_URL } from '../config';
import {
  homepageSetDisplayMode,
  homepageSetOrderBy
} from './homepage';
import {
  dashboardTilesUpdateExperimentalSettings
} from './dashboard-tiles';
import {
  analysisInitialiseCriteria,
  analysisUpdateFacets,
  analysisUpdateChartSettings,
  analysisUpdateChartEditSettings,
  analysisApplyChartEditSettings,
  analysisUpdateTableSettings,
  analysisUpdateColourCycleSettings,
  analysisUISetPanelWidth,
  analysisUpdateExperimentalSettings,
  analysisSetExpandFacets,
  analysisSetExpandDetails,
  analysisUpdateAnnotationDefaults
} from './analysis';
import {
  reportsUpdateExperimentalSettings
} from './reports';
import {
  analysisCompositionSetSelectedLevel,
  analysisCompositionSetMaxDepth
} from './analysis-composition-v2';
import {
  eventsInitialiseCriteria
} from './events';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const userSettingsApply = (data) => (dispatch, getState) => {
  const { homepage, analysis, events, experimental } = data ?? {};

  if (experimental) {
    dispatch(dashboardTilesUpdateExperimentalSettings(experimental));
    dispatch(analysisUpdateExperimentalSettings(experimental));
    dispatch(reportsUpdateExperimentalSettings(experimental));

    dispatch(userSettingsExperimentalChanged(experimental));
  }
  
  if (homepage) {
    const { displayMode, orderBy, orderByDirection } = homepage;

    if (displayMode)
      dispatch(homepageSetDisplayMode(displayMode));

    if (orderBy && orderByDirection)
      dispatch(homepageSetOrderBy(orderBy, orderByDirection));
  }

  if (analysis) {
    const { search, chart, ui, chartSettings, tableSettings, colourCycle, adjustments } = analysis;

    if (search) {
      let { facets, criteria, enableExpandFacets, enableExpandDetails, compositionSelectedLevel, compositionMaxDepth } = search;

      if (facets) dispatch(analysisUpdateFacets(facets));
      if (criteria) {
        if (criteria.hasOwnProperty('discontinuedFilterMonths') || criteria.hasOwnProperty('enableDiscontinuedFilter')) {
          if (criteria.enableDiscontinuedFilter && criteria.discontinuedFilterMonths > 0)
            criteria.staleMonthLimit = criteria.discontinuedFilterMonths;

          delete criteria.enableDiscontinuedFilter;
          delete criteria.discontinuedFilterMonths;
        }

        if (criteria.hasOwnProperty('enableFavouritesFilter')) {
          if (criteria.enableFavouritesFilter)
            criteria.onlyFavourites = criteria.enableFavouritesFilter;

          delete criteria.enableFavouritesFilter;
        }

        dispatch(analysisInitialiseCriteria(criteria));
      }

      if (typeof enableExpandFacets === 'boolean')
        dispatch(analysisSetExpandFacets(enableExpandFacets));

      if (typeof enableExpandDetails === 'boolean')
        dispatch(analysisSetExpandDetails(enableExpandDetails));

      if (compositionSelectedLevel > 0)
        dispatch(analysisCompositionSetSelectedLevel(compositionSelectedLevel));

      if (compositionMaxDepth > 0)
        dispatch(analysisCompositionSetMaxDepth(compositionMaxDepth));
    }

    if (chart) {
      const { comparison, lens, operation, timeZoneId, stacking, force24HourDST } = chart;

      if (comparison) dispatch(analysisUpdateChartSettings('comparisonMode', comparison));
      if (lens) dispatch(analysisUpdateChartSettings('lens', lens));
      if (operation) dispatch(analysisUpdateChartSettings('operation', operation));
      if (timeZoneId) dispatch(analysisUpdateChartSettings('timeZoneId', timeZoneId));
      if (stacking) dispatch(analysisUpdateChartSettings('stackingType', stacking));
      if (force24HourDST) dispatch(analysisUpdateChartSettings('force24HourDST', force24HourDST));
    }

    if (ui) {
      const { reportCriteriaPanel, chartConfigurationPanel, seriesPanel } = ui;

      dispatch(analysisUISetPanelWidth('reportSettings', reportCriteriaPanel));
      dispatch(analysisUISetPanelWidth('chartSettings', chartConfigurationPanel));
      dispatch(analysisUISetPanelWidth('seriesSettings', seriesPanel));
    }

    if (chartSettings) {
      dispatch(analysisUpdateChartEditSettings(chartSettings));
      dispatch(analysisApplyChartEditSettings());
    }

    if (tableSettings) {
      dispatch(analysisUpdateTableSettings(tableSettings));
    }

    if (colourCycle) {
      dispatch(analysisUpdateColourCycleSettings(colourCycle));
    }

    if (adjustments){
      dispatch(analysisUpdateAnnotationDefaults(adjustments));
    }

  }

  if (events) {
    const { criteria } = events;

    if (criteria) dispatch(eventsInitialiseCriteria(criteria));
  }
};

export const USER_SETTINGS_LOAD_STARTED = 'USER_SETTINGS_LOAD_STARTED';
export const userSettingsLoadStarted = createAction(USER_SETTINGS_LOAD_STARTED);

export const userSettingsLoad = (applySettings) => (dispatch, getState) => {
  dispatch(userSettingsLoadStarted());

  authFetch(`${ANALYSIS_API_URL}/user-settings`)
    .then(response => response.status === 204 ? null : response.json())
    .then(data => {
      if (window.__APIKEY__ !== undefined)
        delete data['show-debug'];

      dispatch(userSettingsLoadComplete(data));
      if (applySettings) dispatch(userSettingsApply(data));
    })
    .catch(error => {
      dispatch(userSettingsLoadComplete());
      dispatch(logErrorNotification(error));
    });
};

export const USER_SETTINGS_LOAD_COMPLETE = 'USER_SETTINGS_LOAD_COMPLETE';
export const userSettingsLoadComplete = createAction(USER_SETTINGS_LOAD_COMPLETE, 'data');

export const userSettingsSave = (settings, applySettings) => (dispatch, getState) => {
  dispatch(userSettingsLoadStarted());

  authFetch(`${ANALYSIS_API_URL}/user-settings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(settings)
    })
    .then(() => {
      if (window.__APIKEY__ !== undefined)
        delete settings['show-debug'];

      dispatch(userSettingsLoadComplete(settings));

      if (applySettings) dispatch(userSettingsApply(settings));

      dispatch(logInfoNotification('User settings updated'));
    })
    .catch(error => {
      dispatch(userSettingsLoadComplete());
      dispatch(logErrorNotification(error));
    });
};

export const USER_SETTINGS_EXPERIMENTAL_CHANGED = 'USER_SETTINGS_EXPERIMENTAL_CHANGED';
const userSettingsExperimentalChanged = createAction(USER_SETTINGS_EXPERIMENTAL_CHANGED, 'data');