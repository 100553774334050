import React from 'react';
import CollapsePanel from '../../../shared/CollapsePanel';

export default function ResultSeries({ seriesKey, granularity, sourceTimeZoneId, dataPointCount, dataPoints, errors, warnings, infos }) {
  const key = `result-${seriesKey}`;
  const title = `OUTPUT -> ${seriesKey}: ${granularity} ${sourceTimeZoneId} (${dataPointCount} dps)`;
  return (
    <CollapsePanel idKey={key} title={title} headerStyle={{ color: 'white', background: 'forestgreen', cursor: 'pointer' }} collapsed={true}>
      <table className='table table-sm table-striped'>
        <tbody>
          {errors && errors.map((x, i) => 
            <tr key={i}>
              <td><i className='text-danger fas fa-exclamation-triangle fa-fw' /></td>
              <td>{x}</td>
            </tr> 
          )}          
          {warnings && warnings.map((x, i) => 
            <tr key={i}>
              <td><i className='text-warning fas fa-exclamation-triangle fa-fw' /></td>
              <td>{x}</td>
            </tr> 
          )}          
        </tbody>
      </table>
      <table className='table table-sm table-striped'>
        <tbody>
          {dataPoints && dataPoints.map((x, i) => 
            <tr key={i}>
              <td>{x.startDateTime}</td>
              <td>{x.endDateTime}</td>
              <td>{x.value}</td>
            </tr> 
          )}          
        </tbody>
      </table>
    </CollapsePanel>
  );
}