import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Table from '../../shared/Table';
import Loading from '../../shared/Loading';
import Timezone from '../../shared/Timezone';
import useFetch from '../../../hooks/useFetch';
import { Link } from 'react-router-dom';
import { EVENTS_API_URL } from '../../../config';
import { logErrorNotification } from '../../../actions/log';

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logErrorNotification(message) {
      dispatch(logErrorNotification(message));
    }
  };
};

const columns = [{
  key: 'id'
}, {
  key: 'id'
}, {
  key: 'name',
  title: 'Name'
}, {
  key: 'searchFilter',
  title: 'Query'
}, {
  key: 'owner',
  title: 'Owner'
}, {
  key: 'updatedBy',
  title: 'Last Updated By'
}, {
  key: 'updatedUtc',
  title: <span>Last Updated<Timezone/></span>,
  format: value => moment.utc(value).format('DD-MMM-YYYY HH:mm:ss')
}, {

}];

function DatasetDependencyDetailRow({ cellData }) {
  const { actionId, name, type } = cellData;
  const { viewUrl, editUrl, description } = getSpecifics(type);
  return <tr>
    <td className='p-0'>
      <button type='button' className='btn btn-sm btn-link'>
        <Link to={viewUrl}>
          <i className='fas fa-search-plus fa-fw' />
        </Link>
      </button>
    </td>
    <td className='p-0'>
      <button type='button' className='btn btn-sm btn-link'>
        <Link to={editUrl}>
          <i className='fas fa-pen fa-fw' />
        </Link>
      </button>
    </td>
    <td>{description}</td>
    <td>{name}</td>
  </tr>;

  function getSpecifics(type) {
    switch (type) {
      case "scheduled": return { viewUrl: `/actions/scheduled/${actionId}`, editUrl: `/actions/scheduled/${actionId}/edit`, description: 'Scheduled' };
      case "triggered": return { viewUrl: `/actions/triggered/${actionId}`, editUrl: `/actions/triggered/${actionId}/edit`, description: 'Triggered' };
      default: return { viewUrl: `/actions/scheduled/${actionId}`, description: '' };
    }
  }
}

function DatasetRow({ useFetch, cellData, rawData }) {
  const { id, name, searchFilter, owner, updatedBy, updatedUtc } = cellData;
  const [isExpanded, setIsExpanded] = useState(false);
  const [dependenciesUrl, setDependenciesUrl] = useState('');
  const [, dependenciesData] = useFetch(dependenciesUrl);

  useEffect(() => setDependenciesUrl(isExpanded ? `${EVENTS_API_URL}/datasets/${id}/dependencies` : ''), [isExpanded, id])

  function onToggleExpand(e) {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  }

  return <tbody className='border-top-0'>
    <tr>
      <td className='p-0'>
        <button type='button' className='btn btn-sm btn-link' onClick={onToggleExpand}>
          <i className={'fas fa-fw ' + (isExpanded ? 'fa-chevron-down' : 'fa-chevron-right')} />
        </button>
      </td>
      <td className='p-0'>
        <button type='button' className='btn btn-sm btn-link' >
          <Link to={`/datasets/${id}`}>
            <i className='fas fa-pen fa-fw' />
          </Link>
        </button>
      </td>
      <td className='text-nowrap'>{name}</td>
      <td style={{maxWidth:'calc(100vw - 900px)', whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}} title={searchFilter} >{searchFilter}</td>
      <td className='text-nowrap'>{owner}</td>
      <td className='text-nowrap'>{updatedBy}</td>
      <td className='text-nowrap'>{updatedUtc}</td>
      <td className='w-100'></td>
    </tr>
    <tr className={isExpanded ? '' : 'd-none'}>
      <td />
      <td colSpan={columns.length - 1}>
        <div className='d-flex flew-row flex-md-nowrap'>
          {(!dependenciesData || dependenciesData.length === 0) ?
            (<div>No Dependencies</div>) :
            (<div className='w-100'>
              <h6>Dependencies</h6>
              <table className='table table-sm'>
                <thead>
                  <tr className='bg-light'>
                    <td></td>
                    <td></td>
                    <td className='w-25' >
                      <span>Type</span>
                    </td>
                    <td className='w-75' >
                      <span>Name</span>
                    </td>
                  </tr>
                </thead>
                <tbody className={'border-top-0'}>
                  {dependenciesData.map(r => <DatasetDependencyDetailRow key={r.type + r.actionId} cellData={r} />)}
                </tbody>
              </table>
            </div>)}
        </div>
      </td>
      <td className='w-100'></td>
    </tr>
  </tbody>;
}

const DatasetsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ logErrorNotification }) => {
  const [error, dataSetsData, isLoading] = useFetch(`${EVENTS_API_URL}/datasets`);
  useEffect(() => {
    if (error) logErrorNotification(error);
  }, [error, logErrorNotification]);

  return <Loading isLoading={isLoading} message='Loading datasets...'>
    <div className='container-fluid'>
      <form className='my-3' noValidate >
        <div className='form-row'>
          <div className='col-md-9'>
            Datasets
          </div>
          <div className='col-md-3 d-flex align-content-center justify-content-end'>
            <div className='btn-toolbar'>
              <div className='btn-group'>
                <Link to={`/datasets/new`}>
                  <button type='submit' className='btn btn-primary'>
                    <i className='fas fa-plus fa-fw' />
                    <span className='ml-8'>New Dataset</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Table className='datasets-list' columns={columns} data={dataSetsData} useTBody={false}>
        <DatasetRow useFetch={useFetch} />
      </Table>
    </div>
  </Loading>
});

export default DatasetsList;