import { fromJS } from 'immutable';
import {
  DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_BEGIN,
  DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_COMPLETE,
  DASHBOARD_TILES_DYNAMIC_WORKSPACE_DESIGN_SET_VALUE,
  DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_OPTION,
  DASHBOARD_TILES_DYNAMIC_WORKSPACE_ALL_TOGGLE_OPTIONS,
  DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_MULTISELECT_TO_CREATE_SINGLE_TILE,
  DASHBOARD_TILES_DYNAMIC_WORKSPACE_INITIALISE,
  DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_NEXT,
  DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_BACK
} from '../actions/dashboard-tiles-dynamic-workspace'
import { toggleAll } from '../utility/selection-utility';
import { getDynamicFilterCombinations, mergeGroupedDynamicFilters } from '../reducer-functions/analysis-dynamic-workspace';
import { dashboardTiles } from '../state/dashboard-tiles';
import { getFullPath } from '../utility/workspace-utility';

export const dashboardTilesDynamicWorkspaceReducer = {
  [DASHBOARD_TILES_DYNAMIC_WORKSPACE_INITIALISE](state, action) {
    return state.setIn(['dynamicWorkspaceDesign'], fromJS(dashboardTiles.dynamicWorkspaceDesign));
  },
  [DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_NEXT](state, action) {
    let view = state.getIn(['dynamicWorkspaceDesign', 'view']);

    switch (view) {
      case 'selection':
        state = state.setIn(['dynamicWorkspaceDesign', 'view'], 'building')
          .setIn(['dynamicWorkspaceDesign', 'canGoBack'], true)
          .setIn(['dynamicWorkspaceDesign', 'canGoNext'], false);
        break;
      case 'building':
        break;
      default: break;
    }

    return state;
  },
  [DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_BACK](state, action) {
    let view = state.getIn(['dynamicWorkspaceDesign', 'view']);

    switch (view) {
      case 'selection':
        // do nothing
        break;
      case 'building':
        state = state.setIn(['dynamicWorkspaceDesign', 'view'], 'selection')
          .setIn(['dynamicWorkspaceDesign', 'canGoBack'], false)
          .setIn(['dynamicWorkspaceDesign', 'canGoNext'], state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace']).size > 0);
        break;
      default: break;
    }

    return state;
  },
  [DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_BEGIN](state, action) {
    state = state
      .setIn(['dynamicWorkspaceDesign', 'view'], 'selection')
      .setIn(['dynamicWorkspaceDesign', 'workspace'], undefined)
      .setIn(['dynamicWorkspaceDesign', 'workspaceName'], '')
      .setIn(['dynamicWorkspaceDesign', 'workspaceData'], undefined)
      .setIn(['dynamicWorkspaceDesign', 'canGoNext'], false)
      .setIn(['dynamicWorkspaceDesign', 'readingWorkspace'], true)
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace'], fromJS([]))
      .setIn(['dynamicWorkspaceDesign', 'combinations'], fromJS([]));

    return state;
  },
  [DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_COMPLETE](state, action) {
    const { workspace, workspaceData } = action;
    state = state
      .setIn(['dynamicWorkspaceDesign', 'readingWorkspace'], false);

    if (!workspace || !workspaceData)
      return state;

    if (workspaceData.dynamicSettings) {
      workspaceData.dynamicWorkspaces = workspaceData.dynamicSettings;
      delete workspaceData.dynamicSettings;
    }

    if (!workspaceData.dynamicWorkspaces || workspaceData.dynamicWorkspaces.length === 0)
      return state;

    let ws = workspaceData.dynamicWorkspaceGroupingEnabled === true ? mergeGroupedDynamicFilters(workspaceData.dynamicWorkspaces) : workspaceData.dynamicWorkspaces;
    const dynamicWorkspaces = ws.map(ds => (
      {
        key: ds.key,
        associatedFilters: ds.associatedFilters,
        dynamicFilters: ds.dynamicFilters ? ds.dynamicFilters.map(df => (
          {
            dynamicFilterKey: df.key,
            title: (df.alias ?? '').trim().length === 0 ? df.selectedCategory : df.alias,
            isMultiSelect: df.isMultiSelect,
            groupMultiselectOptions: false,
            values: ((df.strict === true ? df.strictValues : df.values) ?? []).map(v => (
              {
                name: v.name,
                isSelected: false
              }
            ))
          }
        )) : []
      }));

    return state
      .setIn(['dynamicWorkspaceDesign', 'workspace'], fromJS(workspace))
      .setIn(['dynamicWorkspaceDesign', 'workspaceName'], getFullPath({ workspace }))
      .setIn(['dynamicWorkspaceDesign', 'workspaceData'], fromJS(workspaceData))
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace'], fromJS(dynamicWorkspaces))
      .setIn(['dynamicWorkspaceDesign', 'combinations'], fromJS([]))
      .setIn(['dynamicWorkspaceDesign', 'canGoNext'], dynamicWorkspaces.length > 0)
  },
  [DASHBOARD_TILES_DYNAMIC_WORKSPACE_DESIGN_SET_VALUE](state, action) {
    const { key, value } = action;
    return state.setIn(['dynamicWorkspaceDesign', ...key], typeof value === 'object' ? fromJS(value) : value);
  },
  [DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_OPTION](state, action) {
    const { dynamicWorkspaceKey, dynamicFilterKey, name, isSelected } = action;
    const dynamicWorkspaces = state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace']).toJS();

    const dynamicWorkspace = dynamicWorkspaces.find(ds => ds.key === dynamicWorkspaceKey);
    const dynamicFilter = dynamicWorkspace.dynamicFilters.find(df => df.dynamicFilterKey === dynamicFilterKey);
    const value = dynamicFilter.values.find(v => v.name === name);
    value.isSelected = isSelected;

    state = state
      .setIn(['dynamicWorkspaceDesign', 'canGoNext'], state.hasIn(['dynamicWorkspaceDesign', 'workspace']) && dynamicWorkspaces.some(ds => ds.dynamicFilters.some(df => df.values.some(v => v.isSelected === true))))
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace'], fromJS(dynamicWorkspaces))

    const combinations = getDynamicFilterCombinations(state);
    return state.setIn(['dynamicWorkspaceDesign', 'combinations'], fromJS(combinations));
  },
  [DASHBOARD_TILES_DYNAMIC_WORKSPACE_ALL_TOGGLE_OPTIONS](state, action) {
    const { dynamicWorkspaceKey, dynamicFilterKey } = action;
    const dynamicWorkspaces = state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace']).toJS();

    const dynamicWorkspace = dynamicWorkspaces.find(ds => ds.key === dynamicWorkspaceKey);
    const dynamicFilter = dynamicWorkspace.dynamicFilters.find(df => df.dynamicFilterKey === dynamicFilterKey);
    toggleAll(dynamicFilter.values, v => v.isSelected, (i, v) => i.isSelected = v);

    state = state
      .setIn(['dynamicWorkspaceDesign', 'canGoNext'], state.hasIn(['dynamicWorkspaceDesign', 'workspace']) && dynamicWorkspaces.some(ds => ds.dynamicFilters.some(df => df.values.some(v => v.isSelected === true))))
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace'], fromJS(dynamicWorkspaces));

    const combinations = getDynamicFilterCombinations(state);
    return state.setIn(['dynamicWorkspaceDesign', 'combinations'], fromJS(combinations));
  },
  [DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_MULTISELECT_TO_CREATE_SINGLE_TILE](state, action) {
    const { dynamicWorkspaceKey, dynamicFilterKey, value } = action;
    const dynamicWorkspaces = state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace']).toJS();

    const dynamicWorkspace = dynamicWorkspaces.find(ds => ds.key === dynamicWorkspaceKey);
    const dynamicFilter = dynamicWorkspace.dynamicFilters.find(df => df.dynamicFilterKey === dynamicFilterKey);
    dynamicFilter.groupMultiselectOptions = value;

    state = state.setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace'], fromJS(dynamicWorkspaces));

    const combinations = getDynamicFilterCombinations(state);
    return state.setIn(['dynamicWorkspaceDesign', 'combinations'], fromJS(combinations));
  }
};