import React, { Fragment, useRef } from 'react';
import IdentityId from '../../shared/IdentityId2';
import { TimeSeriesSearch2 } from '../../shared/TimeSeriesSearch2';
import { ModalDialogButton } from '../../shared/ModalDialogButton';

function ScenarioHeader({ timeSeries }) {
  if (!timeSeries) return null;

  const { id, name, granularity, timeZoneId } = timeSeries ?? {};
  const { granularityType, granularityFrequency } = granularity ?? {};

  return id > 0 && (
    <Fragment>
      <div className='d-flex justify-content-start mt-1'>
        <div>
          <IdentityId>{id}</IdentityId>
        </div>
        <div className='d-flex ml-3 font-weight-bold'>
          <span className='d-inline-block text-nowrap' style={{ maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${name}`}</span>
          <span className='d-inline-block text-nowrap'>{` - ${granularityFrequency} ${granularityType} - ${timeZoneId}`}</span>
        </div>
      </div>
    </Fragment>
  );
}

export default function ScenarioToolbar({ scenarioCsvIds, setScenarioCsvIds, addScenarioByCsvIds, addScenarioByIds, autoAddScenarios, selectedTimeSeries }) {
  function onAddScenarioByCsvIds(e) {
    e.preventDefault();
    addScenarioByCsvIds();
  }

  function onAutoAddScenarios(e) {
    e.preventDefault();
    autoAddScenarios();
  }

  const inputRef = useRef();

  if (!selectedTimeSeries || !selectedTimeSeries.id)
    return null;

  return (
    <form className='m-1' onSubmit={onAddScenarioByCsvIds} style={{ height: '30px' }}>
      <div className='form-row'>
        <div className='form-group col-2 d-flex align-items-end' style={{ marginBottom: '.5em' }}>
          <div className='input-group input-group-sm'>
            <input ref={inputRef} type='text' className='form-control' placeholder='Add scenario by Ids'
                  value={scenarioCsvIds}
                  onChange={e => setScenarioCsvIds(e.target.value)} />
            <div className='input-group-append'>
              <button type='submit' className='btn btn-sm btn-primary text-nowrap'>
                <i className='fas fa-plus fa-fw' />
              </button>
            </div>
          </div>
        </div>
        <div className='form-group col-2 d-flex align-items-end d-none' style={{ marginBottom: '.5em' }}>
          <div className='btn-group btn-group-sm'>
            <ModalDialogButton buttonClassName='btn btn-primary text-nowrap mr-2' buttonContent={<><i className='fas fa-search' /> Search</>}>
              {({closeDialog}) => <TimeSeriesSearch2 title='Add Scenarios' 
                selectionStyle='multiple'
                stateKey='addScenariosSearch'
                intitialSelection={[]}
                actions={[{
                  text: 'Close',
                  className: 'btn-secondary',
                  func: () => closeDialog()
                },{
                  text: 'Add Scenarios',
                  func: ({ selectedIds }) => {
                    if (!selectedIds || !selectedIds.length)
                      return;

                    addScenarioByIds(selectedIds);
                    closeDialog();
                  }
                }]} />
              }
            </ModalDialogButton>

            <button type='button' className='btn btn-primary text-nowrap' onClick={onAutoAddScenarios}>
              Auto Add Scenarios
            </button>
          </div>
        </div>
        <div className='col'>
          <ScenarioHeader timeSeries={selectedTimeSeries} />
        </div>
      </div>
    </form>
  );
}