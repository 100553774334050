import moment from 'moment';
import { generateGradients } from './color-utility';
import { cloneInstance, to2dp } from './property-utlility';

const defaultGradientSteps = 25;

export const specialProperties = ['commaSeparated', 'minDecimals', 'maxDecimals', 'decimalPlaces', 'dateFormat', 'valueFormat'];

function filterUndefinedEntries(item) {
  if (!item)
    return item;

  const a = Object.entries(item);
  const b = a.filter(([_, value]) => value !== undefined);

  return Object.fromEntries(b);
}

export const organiseStyles = (...args) => Object.entries(args.reduce((accumulator, item) => ({ ...accumulator, ...filterUndefinedEntries(item) }), {})).reduce((accumulator, [key, value]) => {
  let styles = {}, specialisedStyles = {};

  if (specialProperties.includes(key))
    specialisedStyles[key] = value
  else
    styles[key] = value;

  return {
    ...accumulator,
    styles: {
      ...accumulator.styles,
      ...styles
    },
    specialisedStyles: {
      ...accumulator.specialisedStyles,
      ...specialisedStyles
    }
  };
}, {});

export const getColumnStyle = ({ columnKey, valueKeys, columnStyles }) => {
  const {
    title, bucket, dateTime, numbers,
    negativeNumbers, positiveNumbers, zeroNumbers, blankNumbers
  } = columnStyles ?? {};

  if (columnKey === 'title')
    return title;

  if (columnKey === 'bucket')
    return bucket;

  if (columnKey === 'dateTime')
    return dateTime;

  if (valueKeys && valueKeys.some(v => v === 'negative'))
    return { ...numbers, ...negativeNumbers };

  if (valueKeys && valueKeys.some(v => v === 'positive'))
    return { ...numbers, ...positiveNumbers };

  if (valueKeys && valueKeys.some(v => v === 'zero'))
    return { ...numbers, ...zeroNumbers };

  if (valueKeys && valueKeys.some(v => v === 'blank'))
    return { ...numbers, ...blankNumbers };

  return numbers;
};

export const getRowStyle = ({ rowIndex, defaultStyle, altStyle }) => altStyle && rowIndex % 2 ? altStyle : defaultStyle;

const ConditionalOperators = {
  'eq': (lValue, rValue) => lValue === rValue,
  'ne': (lValue, rValue) => lValue !== rValue,
  'lt': (lValue, rValue) => lValue < rValue,
  'le': (lValue, rValue) => lValue <= rValue,
  'gt': (lValue, rValue) => lValue > rValue,
  'ge': (lValue, rValue) => lValue >= rValue
}

function precalculateValueStyleProperties(statistics, valueStyles, defaultGradientSteps, isHorizontal) {
  const response = [];
  if (valueStyles)
    valueStyles.forEach(vs => {
      const newValue = cloneInstance(vs);
      response.push(newValue);
      newValue.gradientSteps = newValue.gradientSteps ?? defaultGradientSteps;
      if (isHorizontal)
        newValue.identityKeys = (!newValue.rowKeys || newValue.rowKeys.length === 0) ? Object.keys(statistics) : newValue.rowKeys; 
      else
        newValue.identityKeys = (!newValue.columnKeys || newValue.columnKeys.length === 0) ? Object.keys(statistics) : newValue.columnKeys; 

      newValue.min = Math.min(...newValue.identityKeys.map(k => (statistics[k] ?? {}).min).filter(m =>m));
      newValue.max = Math.max(...newValue.identityKeys.map(k => (statistics[k] ?? {}).max).filter(m =>m));
      newValue.valueStepDp = (newValue.max - newValue.min) / newValue.gradientSteps;
      newValue.gradientColours = generateGradients(newValue.colours, newValue.gradientSteps);
    });

  return response;
}

export const getConditionalStyle = ({ value, columnKey, otherColumnKeys, rowKeys, conditionalStyles, valueStyles, isHorizontal }) => {
  if ((!columnKey && !rowKeys) || !conditionalStyles)
    return;

  const columnKeys = isHorizontal
    ? getPivotedColumnKeys({ columnKey, otherColumnKeys, conditionalStyles })
    : [columnKey];


  let matchingStyles = [];

  if (valueStyles) {
    let lValue = to2dp(value);

    const identityKey = isHorizontal ? rowKeys.filter(r => r)[0] : columnKey;
    if (lValue !== undefined && identityKey !== 'title' && identityKey !== 'dateTime') {
      valueStyles.filter(vs => vs.style && (vs.identityKeys.length === 0 || vs.identityKeys.indexOf(identityKey) >= 0))
        .forEach(vs => {
          let stepToUse = 0;
          for (let rValue = vs.min, step = 0; rValue < vs.max; rValue += vs.valueStepDp, step++) {
            if (lValue >= to2dp(rValue)) {
              stepToUse = parseInt(step);
            }
          }

          var updatedStyle = JSON.stringify(vs.style);
          updatedStyle = updatedStyle.replace('$value$', vs.gradientColours[Math.min(stepToUse, vs.gradientSteps - 1)])
          matchingStyles.push(JSON.parse(updatedStyle));
        });
    }
  }

  const prioritisedStyles = [...conditionalStyles];
  matchingStyles = [...matchingStyles, ...prioritisedStyles.filter(({ columnKey: cKey, rowKey: rKey, condition }) => {
    if (condition) {
      let { operator, value: rValue } = condition;
      let lValue = (!value && value !== 0) ? undefined : value;
      if (!rValue && rValue !== 0) rValue = undefined;

      var opFunc = ConditionalOperators[operator];
      if (opFunc && (columnKey !== 'title' && columnKey !== 'dateTime')) {
        if (cKey)
          return (cKey === columnKey) && opFunc(lValue, rValue);

        if (rKey)
          return rowKeys.some(rowKey => rKey === rowKey) && opFunc(lValue, rValue);

        if (opFunc(lValue, rValue))
          return true;
      }

      return false;
    }

    if (!cKey && !rKey)
      return false;

    if (cKey && rKey) {
      if (columnKeys.some(columnKey => cKey === columnKey) && rowKeys && rowKeys.some(rowKey => rKey === rowKey))
        return true;
    }
    else if (cKey) {
      if (columnKeys.some(columnKey => cKey === columnKey))
        return true;
    }
    else if (rKey) {
      if (rowKeys && rowKeys.some(rowKey => rKey === rowKey))
        return true;
    }

    return false;
  }).map(c => c.style)];  

  let style;
  matchingStyles.forEach(m => style = Object.assign(style ?? {}, m));
  return style;
};

export const getRowKeys = ({ row, conditionalStyles, isHorizontal }) => [
  null,
  isHorizontal ? row.key : row.bucket,
  ...getDateStyleKeys(row),
  ...getRelativeDateStyleKeys(row, conditionalStyles)
];

export const getValueKeys = ({ columnKey, row }) => [
  ...getValueStyleKeys(columnKey, row)
];

const getStyledHeaderWidth = (headerStyles, header, ignoreHiddenState) => {
  if (!ignoreHiddenState && header.key === 'dateTime' && header.styles && header.styles.display === 'none')
    return 0;

  if (!ignoreHiddenState && header.isDisabled === true)
    return 0;

  const key = header.key;
  const { cellStyles } = headerStyles ?? {};
  const { columnStyles, conditionalStyles } = cellStyles ?? {};
  const { bucket, dateTime, numbers } = columnStyles ?? {};

  let width = undefined;

  if (conditionalStyles) {
    const prioritisedStyles = [...conditionalStyles].reverse();
    const { style } = prioritisedStyles.find(i => i.columnKey === key) ?? {};

    if (style && style.hasOwnProperty('width'))
      width = style.width;
  }

  if (!width) {
    const styles = key === 'bucket' ? bucket
      : key === 'dateTime' ? dateTime
        : numbers;

    if (styles && styles.hasOwnProperty('width'))
      width = styles.width;
  }

  return width ?? 150;
};

const getPivotedColumnKeys = ({ columnKey, otherColumnKeys, conditionalStyles }) => {
  if (columnKey === 'title')
    return [columnKey];

  const column = { dateTime: columnKey, ...otherColumnKeys };

  if (!column.bucket && !column.dateTime)
    return [];

  return [
    null,
    column.bucket,
    ...getDateStyleKeys(column),
    ...getRelativeDateStyleKeys(column, conditionalStyles, true)
  ];
};

const getDateStyleKeys = row => {
  if (!row || !row.dateTime || !row.dateTime.value)
    return [];

  const dateTime = moment.utc(row.dateTime.value);
  if (!dateTime.isValid())
    return [];

  const today = moment.utc().startOf('day');
  const yesterday = moment.utc().startOf('day').subtract(1, 'd');
  const tomorrow = moment.utc().startOf('day').add(1, 'd');

  let styles = [];

  if (dateTime.diff(today) === 0)
    styles.push('today');
  else if (dateTime.diff(yesterday) === 0)
    styles.push('yesterday');
  else if (dateTime.diff(tomorrow) === 0)
    styles.push('tomorrow');

  if (dateTime.weekday() >= 1 && dateTime.weekday() <= 5)
    styles.push('weekday');
  else
    styles.push('weekend');

  if (dateTime.month() >= 3 && dateTime.month() <= 8)
    styles.push('summer');
  else
    styles.push('winter');

  if (dateTime.month() === today.month() && dateTime.year() === today.year())
    styles.push('thismonth');

  return styles;
};

const getRelativeDateStyleKeys = (row, definitions, isHorizontal) => {
  if (!row || !row.dateTime || !row.dateTime.value || !definitions)
    return [];

  const dateTime = moment.utc(row.dateTime.value).startOf('day');
  if (!dateTime.isValid())
    return [];

  const today = moment.utc().startOf('day');
  const parse = key => {
    const { groups: result } = key.match(/(?<origin>^today)(?<offset>[-+]?\d+)/) ?? {};
    const { origin, offset } = result ?? {};

    if (origin === 'today' && !!offset)
      if (today.clone().add(Number(offset), 'd').diff(dateTime) === 0)
        return key;

    return null;
  };

  return definitions.map(({ rowKey, columnKey }) => isHorizontal ? columnKey : rowKey)
    .filter(key => key).map(parse)
    .filter(key => key);
};

const getValueStyleKeys = (key, row) => {
  if (!key || !row || !row[key])
    return [];

  const value = row[key].value;
  let styles = [];

  if (value === null || value === undefined || value === '')
    styles.push('blank');
  else if (value === 0)
    styles.push('zero');
  else if (value < 0)
    styles.push('negative');
  else if (value > 0)
    styles.push('positive');

  return styles;
};

export function getTotalVisibleColumnWidths(headers) {
  let totalColumnWidth = 0;
  headers.forEach(h => {
    if (h.styles && h.styles.display !== 'none' && !h.isDisabled) {
      let width = parseFloat(h.styles.width);
      totalColumnWidth += width;
    }
  });

  return `${totalColumnWidth}px`;
}

export function applyStyles(tableSettings = {}, headers, data, statistics) {
  const { disableSorting, displayData, headerStyles, hideDate, dataStyles = {}, dateFormat, decimalPlaces, commaSeparated } = tableSettings;
  const { cellStyles } = headerStyles ?? {};
  const { default: defaultStyle = {}, columnStyles, conditionalStyles } = cellStyles ?? {};

  const topLevelSettings = {
    dateFormat: dateFormat,
    commaSeparated: commaSeparated,
    decimalPlaces: decimalPlaces,
  };

  const isHorizontal = displayData === 'horizontal';
  const isSortable = !isHorizontal && !disableSorting;

  for (let colIndex = 0; colIndex < headers.length; colIndex++) {
    const header = headers[colIndex];
    header.isSortable = isSortable;

    const baseStyle = { textAlign: 'center' };
    const columnStyle = getColumnStyle({ columnKey: header.key, columnStyles });
    const conditionalStyle = getConditionalStyle({ columnKey: header.key, otherColumnKeys: {}, conditionalStyles, isHorizontal });
    const { styles = {}, specialisedStyles } = organiseStyles(baseStyle, topLevelSettings, defaultStyle, columnStyle, conditionalStyle, {
      flexGrow: 0,
      position: 'relative',
      userSelect: 'none',
      cursor: isSortable ? 'pointer' : 'default'
    });

    header.styles = {
      ...styles,
      display: (hideDate && (isHorizontal || (!isHorizontal && header.key === 'dateTime'))) ? 'none' : 'unset'
    };

    header.styles.width = `${getStyledHeaderWidth(tableSettings.headerStyles, header, true)}px`;
    header.specialisedStyles = specialisedStyles;
  }

  const tableRowStyles = [];
  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    const row = data[rowIndex];
    const { rowStyles: dataRowStyles } = dataStyles ?? {};
    const { default: defaultStyle, alt: altStyle, conditionalStyles } = dataRowStyles ?? {};
    const rowKeys = getRowKeys({ row, conditionalStyles, isHorizontal });
    const rowStyle = getRowStyle({ rowIndex, defaultStyle, altStyle });
    const conditionalStyle = getConditionalStyle({ rowKeys, conditionalStyles });
    const { styles } = organiseStyles(rowStyle, conditionalStyle);
    tableRowStyles.push(styles);
  }

  {
    const { cellStyles } = dataStyles ?? {};
    const { default: defaultStyle = {}, columnStyles, conditionalStyles, valueStyles } = cellStyles ?? {};
    const extendedValueStyles = precalculateValueStyleProperties(statistics, valueStyles, defaultGradientSteps, isHorizontal);

    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {      
      const row = data[rowIndex];
      const rowKeys = getRowKeys({ row, conditionalStyles, isHorizontal });

      for (let colIndex = 0; colIndex < headers.length; colIndex++) {
        const key = headers[colIndex].key;
        const baseStyle = {};
        if (key === 'dateTime') {
          baseStyle.textAlign = 'right';
          baseStyle.display = !isHorizontal && key === 'dateTime' && hideDate ? 'none' : 'unset';
        } else if (key === 'title') {
          baseStyle.textAlign = !isHorizontal ? 'right' : 'left';
        } else {
          baseStyle.textAlign = 'right';
          baseStyle.commaSeparated = true;
          baseStyle.decimalPlaces = 2;
        };

        const valueKeys = getValueKeys({ columnKey: key, row });
        const columnStyle = getColumnStyle({ columnKey: key, valueKeys, columnStyles, isHorizontal });
        const conditionalStyle = getConditionalStyle({ value: row[key] ? row[key].value : undefined, valueStyles: extendedValueStyles, columnKey: key, otherColumnKeys: {}, rowKeys, conditionalStyles:conditionalStyles ?? [], isHorizontal });
        const { styles = {}, specialisedStyles } = organiseStyles(baseStyle, topLevelSettings, defaultStyle, columnStyle, conditionalStyle, { flexGrow: 0 });
        styles.width = headers[colIndex].styles.width;

        if (!row[key]) row[key] = {};
        row[key].styles = styles;
        row[key].specialisedStyles = specialisedStyles;
      }
    }
  }

  const totalColumnWidth = headers.reduce((sum, header) => sum + getStyledHeaderWidth(tableSettings.headerStyles, header), 0);
  const tableStyles = {
    width: `${totalColumnWidth}px`
  }

  return { tableStyles, tableRowStyles, headers, data }
}