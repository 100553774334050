import React from 'react';
import OutagesToolbarRow1 from './OutagesToolbarRow1';
import OutagesToolbarRow2 from './OutagesToolbarRow2';

const isValueSelected = (value, selectedValue) => (value || '').toLocaleLowerCase() === (selectedValue || '').toLocaleLowerCase();

function mapStatusToClassName(value, activeValue) {
  const isActive = isValueSelected(value, activeValue);

  switch (value.toLocaleLowerCase()) {
    case 'new': return isActive ? 'btn-primary active' : 'btn-outline-primary';
    case 'ignored': return isActive ? 'btn-light active' : 'btn-outline-secondary bg-light text-dark';
    case 'invalid': return isActive ? 'btn-danger active' : 'btn-outline-danger';
    case 'validated': return isActive ? 'btn-warning active' : 'btn-outline-warning';
    case 'processed': return isActive ? 'btn-success active' : 'btn-outline-success';
    default: return isActive ? 'btn-secondary active' : 'btn-outline-secondary';
  }
}

const fotaStatuses = [
  { name: 'All', value: '' },
  { name: 'New', value: 'New' },
  { name: 'Ignored', value: 'Ignored' },
  { name: 'Invalid', value: 'Invalid' },
  { name: 'Validated', value: 'Validated' },
  { name: 'Processed', value: 'Processed' }
];

const groupStyle = { marginBottom: '.5em' };
const labelStyle = { fontSize: '.9em', marginBottom: 0 };

export default function OutagesToolbar({ requiresRefresh, isLoadingSourceList, isLoadingBiddingZoneList, isLoadingProductionTypeList, isLoadingGenerationDataLookupList,
  sourceList, biddingZoneList, productionTypeList, generationDataLookupList,
  source, biddingZone, productionType, unitEic, eventStart, eventEnd, fotaStatus, messageVersions, messageStatus, generationData,
  resultCount, refresh, lookupGenerationData, updateToolbarSource, updateToolbar, shareUrl }) {
  const refreshButtonClassName = requiresRefresh
    ? 'btn text-nowrap btn-primary'
    : 'btn text-nowrap btn-outline-primary';

  function onSubmit(e) {
    e.preventDefault();
    refresh();
  }

  return (
    <form noValidate onSubmit={onSubmit}>
      <OutagesToolbarRow1 isLoadingSourceList={isLoadingSourceList}
        isLoadingBiddingZoneList={isLoadingBiddingZoneList}
        isLoadingProductionTypeList={isLoadingProductionTypeList}
        sourceList={sourceList}
        biddingZoneList={biddingZoneList}
        productionTypeList={productionTypeList}
        generationDataLookupList={generationDataLookupList}
        source={source}
        biddingZone={biddingZone}
        productionType={productionType}
        unitEic={unitEic}
        eventStart={eventStart}
        eventEnd={eventEnd}
        refreshButtonClassName={refreshButtonClassName}
        resultCount={resultCount}
        updateToolbarSource={updateToolbarSource}
        updateToolbar={updateToolbar}
        shareUrl={shareUrl}
        groupStyle={groupStyle}
        labelStyle={labelStyle} />
      <OutagesToolbarRow2 isLoadingGenerationDataLookupList={isLoadingGenerationDataLookupList}
        generationDataLookupList={generationDataLookupList}
        fotaStatus={fotaStatus}
        messageVersions={messageVersions}
        messageStatus={messageStatus}
        generationData={generationData}
        lookupGenerationData={lookupGenerationData}
        updateToolbar={updateToolbar}
        fotaStatuses={fotaStatuses}
        groupStyle={groupStyle}
        labelStyle={labelStyle}
        isValueSelected={isValueSelected}
        mapStatusToClassName={mapStatusToClassName} />
    </form>
  );
}