import { useState } from 'react';

let timeoutId;
export default function useForceReMountEffect() {
  const [isMounted, setIsMounted] = useState(true);

  const forceReMount = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setIsMounted(false);
    timeoutId = setTimeout(setIsMounted => setIsMounted(true), 0, setIsMounted);
  };

  return [isMounted, forceReMount];
}