import { createAction } from '../utility/redux-utility';
import { FetchError, authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logWarningNotification
} from './log';

export const referenceDataLoadSharedLookups = (forceReload = false) => (dispatch, getState) => {
  const state = getState();
  const referenceData = state.getIn(['referenceData']).toJS();
  const isInitialised = state.getIn(['referenceData', 'isInitialised', 'sharedLookups']);
  if (!forceReload && isInitialised === true)
    return;

  dispatch(referenceDataLoadSharedLookupsStarted());

  authFetch(`${ANALYSIS_API_ROOT_URL}/v3/sharedLookups`)
    .then(response => response.status === 204 ? null : response.json())
    .then(response => {
      dispatch(referenceDataLoadSharedLookupsComplete({
        ...referenceData,
        ...response}));
    })
    .catch(error => {
      dispatch(referenceDataLoadSharedLookupsComplete());
      dispatch(logWarningNotification(`Error loading reference data: ${error instanceof FetchError ? error.body : error}\n\nPlease try refreshing your browser using Ctrl+F5`));
    });
};

export const REFERENCE_DATA_LOAD_SHARED_LOOKUPS_STARTED = 'REFERENCE_DATA_LOAD_SHARED_LOOKUPS_STARTED';
const referenceDataLoadSharedLookupsStarted = createAction(REFERENCE_DATA_LOAD_SHARED_LOOKUPS_STARTED);

export const REFERENCE_DATA_LOAD_SHARED_LOOKUPS_COMPLETE = 'REFERENCE_DATA_LOAD_SHARED_LOOKUPS_COMPLETE';
const referenceDataLoadSharedLookupsComplete = createAction(REFERENCE_DATA_LOAD_SHARED_LOOKUPS_COMPLETE, 'data');

export const referenceDataLoadConversionUnits = (forceReload = false) => (dispatch, getState) => {
  const state = getState();
  const isInitialised = state.getIn(['referenceData', 'isInitialised', 'conversionUnits']);
  if (!forceReload && isInitialised === true)
    return;

  dispatch(referenceDataLoadConversionUnitsStarted());

  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/unitConversions`)
    .then(response => response.status === 204 ? null : response.json())
    .then(response => {
      dispatch(referenceDataLoadConversionUnitsComplete(response ?? []));
    })
    .catch(error => {
      dispatch(referenceDataLoadConversionUnitsComplete([]));
      dispatch(logWarningNotification(`Error loading conversionUnits: ${error instanceof FetchError ? error.body : error}\n\nPlease try refreshing your browser using Ctrl+F5`));
    });
};

export const REFERENCE_DATA_LOAD_CONVERSION_UNITS_STARTED = 'REFERENCE_DATA_LOAD_CONVERSION_UNITS_STARTED';
const referenceDataLoadConversionUnitsStarted = createAction(REFERENCE_DATA_LOAD_CONVERSION_UNITS_STARTED);

export const REFERENCE_DATA_LOAD_CONVERSION_UNITS_COMPLETE = 'REFERENCE_DATA_LOAD_CONVERSION_UNITS_COMPLETE';
const referenceDataLoadConversionUnitsComplete = createAction(REFERENCE_DATA_LOAD_CONVERSION_UNITS_COMPLETE, 'data');

export const referenceDataLoadFunctions = (forceReload = false) => (dispatch, getState) => {
  const state = getState();
  const isInitialised = state.getIn(['referenceData', 'isInitialised', 'functions']);
  if (!forceReload && isInitialised === true)
    return;

  dispatch(referenceDataLoadFunctionsStarted());

  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/functions`)
    .then(response => response.status === 204 ? null : response.json())
    .then(response => {
      dispatch(referenceDataLoadFunctionsComplete(response ?? []));
    })
    .catch(error => {
      dispatch(referenceDataLoadFunctionsComplete([]));
      dispatch(logWarningNotification(`Error loading functions: ${error instanceof FetchError ? error.body : error}\n\nPlease try refreshing your browser using Ctrl+F5`));
    });
};

export const REFERENCE_DATA_LOAD_FUNCTIONS_STARTED = 'REFERENCE_DATA_LOAD_FUNCTIONS_STARTED';
const referenceDataLoadFunctionsStarted = createAction(REFERENCE_DATA_LOAD_FUNCTIONS_STARTED);

export const REFERENCE_DATA_LOAD_FUNCTIONS_COMPLETE = 'REFERENCE_DATA_LOAD_FUNCTIONS_COMPLETE';
const referenceDataLoadFunctionsComplete = createAction(REFERENCE_DATA_LOAD_FUNCTIONS_COMPLETE, 'data');

export const referenceDataLoadCategoriesByDataType = (forceReload = false) => (dispatch, getState) => {
  const state = getState();
  const isInitialised = state.getIn(['referenceData', 'isInitialised', 'categoriesByDataType']);
  if (!forceReload && isInitialised === true)
    return;

  dispatch(referenceDataLoadCategoriesByDataTypeStarted());

  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/categories-by-datatype`)
    .then(response => response.status === 204 ? null : response.json())
    .then(response => {
      dispatch(referenceDataLoadCategoriesByDataTypeComplete(response ?? []));
    })
    .catch(error => {
      dispatch(referenceDataLoadCategoriesByDataTypeComplete([]));
      dispatch(logWarningNotification(`Error loading CategoriesByDataType: ${error instanceof FetchError ? error.body : error}\n\nPlease try refreshing your browser using Ctrl+F5`));
    });
};

export const REFERENCE_DATA_LOAD_CATEGORIESBYDATATYPE_STARTED = 'REFERENCE_DATA_LOAD_CATEGORIESBYDATATYPE_STARTED';
const referenceDataLoadCategoriesByDataTypeStarted = createAction(REFERENCE_DATA_LOAD_CATEGORIESBYDATATYPE_STARTED);

export const REFERENCE_DATA_LOAD_CATEGORIESBYDATATYPE_COMPLETE = 'REFERENCE_DATA_LOAD_CATEGORIESBYDATATYPE_COMPLETE';
const referenceDataLoadCategoriesByDataTypeComplete = createAction(REFERENCE_DATA_LOAD_CATEGORIESBYDATATYPE_COMPLETE, 'data');

export const referenceDataLoadPeriods = (forceReload = false) => (dispatch, getState) => {
  const state = getState();
  const isInitialised = state.getIn(['referenceData', 'isInitialised', 'periods']);
  if (!forceReload && isInitialised === true)
    return;

  dispatch(referenceDataLoadPeriodsStarted());

  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/periods`)
    .then(response => response.status === 204 ? null : response.json())
    .then(periods => {
      dispatch(referenceDataLoadPeriodsComplete(periods));
    })
    .catch(error => {
      const state = getState();
      const periods = state.getIn(['referenceData', 'periods']).toJS();
      dispatch(referenceDataLoadPeriodsComplete(periods));
      dispatch(logWarningNotification(`Error loading Periods: ${error instanceof FetchError ? error.body : error}\n\nPlease try refreshing your browser using Ctrl+F5`));
    });
};

export const REFERENCE_DATA_LOAD_PERIODS_STARTED = 'REFERENCE_DATA_LOAD_PERIODS_STARTED';
const referenceDataLoadPeriodsStarted = createAction(REFERENCE_DATA_LOAD_PERIODS_STARTED);

export const REFERENCE_DATA_LOAD_PERIODS_COMPLETE = 'REFERENCE_DATA_LOAD_PERIODS_COMPLETE';
const referenceDataLoadPeriodsComplete = createAction(REFERENCE_DATA_LOAD_PERIODS_COMPLETE, 'data');