import moment from "moment";

export const storageAuctions = {
  lookups: {
    locations: [],
    operators: [],
    sites: [],
    names: [],
    statuses: [],
    storageYears: []
  },
  workspaceLocation: {
    id: 0,
    name: undefined,
    scope: undefined,
    folderPath: undefined,
    subFolderPath: undefined
  },
  canShareWorkspace: false,
  tiles: {
    'default': {
      isLoading: false,
      auctionsPageCount: 100,
      auctionsTotalCount: 0,
      auctions: [],
      elapsed: '0',
      availableFilters: {
        locations: [],
        operators: [],
        sites: [],
        names: [],
        statuses: [],
        storageYears: []
      },
      showDiagnostics: true,
      anyFiltersApplied: false,
      workspace: {
        hiddenColumns: {},
        hiddenFilters: {},
        hidePagination: false,
        pageIndex: 1,
        pageSize: 50,
        orderBy: 'Name',
        orderByDirection: 'asc',
        dateFrom: {
          mode: 'abs',
          abs: moment.utc().startOf('day').add(-2, 'year').format('YYYY-MM-DD'),
          rel: '-2Y'
        },
        dateTo: {
          mode: 'abs',
          abs: moment.utc().startOf('day').add(3, 'month').format('YYYY-MM-DD'),
          rel: '3M'
        },
        filters: {
          locations: [],
          operators: [],
          sites: [],
          names: [],
          statuses: [],
          storageYears: []
        },
      },
      resultsWorkspace: {
      }
    }
  }
};

export const filterNames = [
  'locations',
  'operators',
  'sites',
  'names',
  'statuses',
  'storageYears'];

export const hideableFilterNames = [
  'Locations',
  'Operators',
  'Sites',
  'Names',
  'Statuses',
  'Storage Years',
  'Date From',
  'Date To'];

export const columnsNames = [
  'Location',
  'Operator',
  'Site',
  'Name',
  'Status',
  'Storage Year',
  'Capacity Offered',
  'Total Requested Capacity',
  'Total Allocated Capacity',
  'Reserve Price',
  'Clearing Price',
  'Sale Start',
  'Sale End',
  'Updated (UTC)',
  'Source'];

export function getDefaultFilter() {
  return Object.fromEntries(filterNames.map(f => [f, []]));
}