import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import moment from 'moment';

import {
  ANALYSIS_COMPARISONMODE_INIT,

  ANALYSIS_COMPARISONMODE_SET_ACTIVE_VIEW,
  ANALYSIS_COMPARISONMODE_SET_PLOT_ORIGIN,
  ANALYSIS_COMPARISONMODE_SET_RANGE,
  ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION,
  ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE,

  // individual dates
  ANALYSIS_COMPARISONMODE_CLEAR_PRESELECTION,
  ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_COLUMN,
  ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_ROW,
  ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION,
  ANALYSIS_COMPARISONMODE_ADD_PRESELECTION_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_SET_DAY_ACTIVE_DATE,
  ANALYSIS_COMPARISONMODE_SET_INDIVIDUAL_RELATIVE_DATE,
  ANALYSIS_COMPARISONMODE_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION,

  // aggregations
  ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_RANGE_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_AGGREGATE_TOGGLE_OPERATION,
  ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_PRESELECTION_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_DATE,
  ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_TO_PRESELECTION,
  ANALYSIS_COMPARISONMODE_REMOVE_AGGREGATE_RELATIVE_FROM_PRESELECTION,
  ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_FROM_DATE,
  ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_TO_DATE,
  ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION,

  // workspace
  ANALYSIS_COMPARISONMODE_APPLY_SELECTION_TO_WORKSPACE,
  ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION_FROM_WORKSPACE,
  ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE_FROM_WORKSPACE,
} from '../actions/analysis-comparisonmode-v2';
import { comparisonModes, dateToComparisonModeName } from '../utility/comparisonmode-utility';
import { parseGasSeasonToDate, parseGasYearToDate, parseQuarterToDate, parseYearToDate, toGasSeasonFormat, toQuarterFormat } from '../utility/date-utility';

export const comparisonModeReducer = {
  [ANALYSIS_COMPARISONMODE_INIT](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const workspaceComparisonSettings = toJS(state.getIn(['workspace', 'comparisonSettings', comparisonMode]), { selection: [] });

    if (workspaceComparisonSettings && workspaceComparisonSettings.windows && workspaceComparisonSettings.windows.length > 0)
      comparisonSettings.selection = workspaceComparisonSettings.windows;

    createCalendar(comparisonMode, comparisonSettings);

    let newState = state
      .setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', comparisonSettings.activeView, 'showAddPreselectionErrorMessage'], false)
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', comparisonSettings.activeView, 'showAddRangePreselectionErrorMessage'], false);
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_APPLY_SELECTION_TO_WORKSPACE](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));

    const workspaceComparisonSettings = {
      mode: comparisonMode,
      windows: comparisonSettings.selection
    };

    let newState = state.setIn(['workspace', 'comparisonSettings', comparisonMode], fromJS(workspaceComparisonSettings));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_SET_ACTIVE_VIEW](state, action) {
    const comparisonMode = action.comparisonMode;
    const view = action.view;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));

    comparisonSettings.activeView = view;
    createCalendar(comparisonMode, comparisonSettings);

    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', comparisonSettings.activeView, 'showAddPreselectionErrorMessage'], false)
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', comparisonSettings.activeView, 'showAddRangePreselectionErrorMessage'], false);
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_SET_RANGE](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));

    comparisonSettings.rangeStart = action.start;
    comparisonSettings.rangeEnd = action.end;
    createCalendar(comparisonMode, comparisonSettings);

    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_SET_PLOT_ORIGIN](state, action) {
    const comparisonMode = action.comparisonMode;
    const plotOrigin = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode, 'plotOrigin']), {});
    if (action.mode)
      plotOrigin.mode = action.mode;

    if (action.absOriginDate)
      plotOrigin.absOriginDate = action.absOriginDate;

    if (action.relOriginDate)
      plotOrigin.relOriginDate = action.relOriginDate;

    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode, 'plotOrigin'], fromJS(plotOrigin));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION_FROM_WORKSPACE](state, action) {
    const comparisonMode = action.comparisonMode;

    let newState = state.setIn(['workspace', 'comparisonSettings', comparisonMode, 'windows'], fromJS([]));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE_FROM_WORKSPACE](state, action) {
    const comparisonMode = action.comparisonMode;
    const value = action.value;

    let selection = toJS(state.getIn(['workspace', 'comparisonSettings', comparisonMode, 'windows'], []));
    selection = selection.filter(s => !(s.name === value.name && s.mode === comparisonMode));

    let newState = state.setIn(['workspace', 'comparisonSettings', comparisonMode, 'windows'], fromJS(selection));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_REMOVE_ALL_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode, 'selection'], fromJS([]));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_REMOVE_SELECTED_DATE](state, action) {
    const comparisonMode = action.comparisonMode;
    const value = action.value;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    comparisonSettings.selection = comparisonSettings.selection.filter(s => !(s.name === value.name && s.mode === comparisonMode));
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode, 'selection'], fromJS(comparisonSettings.selection));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_CLEAR_PRESELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;
    comparisonSettings.view[activeView].preselection = [];
    createCalendar(comparisonMode, comparisonSettings);
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const value = action.value;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;

    if (comparisonSettings.view[activeView].preselection.some(s => s.name === value.name))
      comparisonSettings.view[activeView].preselection = comparisonSettings.view[activeView].preselection.filter(s => s.name !== value.name);
    else
      comparisonSettings.view[activeView].preselection.push(value);

    createCalendar(comparisonMode, comparisonSettings);
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_ADD_PRESELECTION_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;
    const { hasSelection } = canAddPreselection(comparisonMode, comparisonSettings);

    if (hasSelection) {
      comparisonSettings.view[activeView].preselection.forEach(pre => {
        // check for existing items first
        if (comparisonSettings.selection.some(s => s.name === pre.name))
          return;

        comparisonSettings.selection.push({
          ...pre,
          mode:comparisonMode
        });
      });

      // clear the pre-selection list
      comparisonSettings.view[activeView].preselection = [];
      createCalendar(comparisonMode, comparisonSettings);
    }

    return state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
          .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddPreselectionErrorMessage'], !hasSelection);
  },
  [ANALYSIS_COMPARISONMODE_SET_DAY_ACTIVE_DATE](state, action) {
    const comparisonMode = action.comparisonMode;
    const activeDate = action.date;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;

    comparisonSettings.view[activeView].activeDate = activeDate;
    createCalendar(comparisonMode, comparisonSettings);
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_COLUMN](state, action) {
    const comparisonMode = action.comparisonMode;
    if (!comparisonMode || comparisonMode === comparisonModes.none)
      return state;

    const column = action.column;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;
    let options = [];

    switch (comparisonMode){
      case comparisonModes.yearOnYear : 
        options = (comparisonSettings.view[activeView].options.dates || []);
        break;
      case comparisonModes.gasYear: 
        options = (comparisonSettings.view[activeView].options.dates || []);
        break;
      case comparisonModes.quarter:
        comparisonSettings.view[activeView].options.years.forEach(y => options = [...options, ...y.dates]);
        options = options.filter(pre => pre && pre.name.indexOf(column) === 0);
        break;
      case comparisonModes.gasSeason :
        comparisonSettings.view[activeView].options.years.forEach(y => options = [...options, ...y.dates]);
        options = options.filter(pre => pre && pre.name.indexOf(column) === 0);
        break;
      case comparisonModes.monthOnMonth:
        comparisonSettings.view[activeView].options.years.forEach(y => options = [...options, ...y.dates]);
        options = options.filter(pre => moment.utc(pre.window.absFromDate).format('MMM') === column);
        break;
      case comparisonModes.dayOnDay:
        comparisonSettings.view[activeView].options.weeks.forEach(w => options = [...options, ...w.dates]);
        options = options.filter(pre => pre && moment.utc(pre.window.absFromDate).format('ddd') === column);
        break;
      default:
        return state;
    }

    if (options.some(s => !s.isSelected)) {
      options.forEach(s => {
        if (!comparisonSettings.view[activeView].preselection.some(pre => pre && pre.name === s.name)) comparisonSettings.view[activeView].preselection.push(s);
      });
    } else {
      options.forEach(s => {
        comparisonSettings.view[activeView].preselection = comparisonSettings.view[activeView].preselection.filter(pre => pre && pre.name !== s.name);
      });
    }

    createCalendar(comparisonMode, comparisonSettings);
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_TOGGLE_PRESELECTION_ROW](state, action) {
    const comparisonMode = action.comparisonMode;
    const row = action.row;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;

    if (comparisonMode === comparisonModes.monthOnMonth) {
      const monthOptions = comparisonSettings.view[activeView].options.years.filter(y => y.name === `${row}`)[0].dates;
      if (monthOptions.some(s => !s.isSelected)) {
        monthOptions.forEach(s => {
          if (!comparisonSettings.view[activeView].preselection.some(pre => pre && pre.name === s.name)) comparisonSettings.view[activeView].preselection.push(s);
        });
      } else {
        monthOptions.forEach(s => {
          comparisonSettings.view[activeView].preselection = comparisonSettings.view[activeView].preselection.filter(pre => pre && pre.name !== s.name);
        });
      }
    }

    if (comparisonMode === comparisonModes.dayOnDay) {
      const weekOptions = comparisonSettings.view[activeView].options.weeks.filter(w => w.name === `${row}`)[0].dates;
      if (weekOptions.some(s => s && !s.isSelected)) {
        weekOptions.filter(s => s).forEach(s => {
          if (!comparisonSettings.view[activeView].preselection.some(pre => pre && pre.name === s.name)) comparisonSettings.view[activeView].preselection.push(s);
        });
      } else {
        weekOptions.forEach(s => {
          comparisonSettings.view[activeView].preselection = comparisonSettings.view[activeView].preselection.filter(pre => pre && pre.name !== s.name);
        });
      }
    }

    createCalendar(comparisonMode, comparisonSettings);
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_AGGREGATE_TOGGLE_OPERATION](state, action) {
    const comparisonMode = action.comparisonMode;
    const operation = action.operation;
    const isSelected = action.isSelected;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;

    if (isSelected) {
      if (!comparisonSettings.view[activeView].operations.some(a => a === operation)) {
        comparisonSettings.view[activeView].operations.push(operation);
      }
    } else {
      comparisonSettings.view[activeView].operations = comparisonSettings.view[activeView].operations.filter(a => a !== operation);
    }

    createCalendar(comparisonMode, comparisonSettings);
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings));
    return newState
  },
  [ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_PRESELECTION_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;
    const { hasSelection, hasAggregations } = canAddPreselection(comparisonMode, comparisonSettings);

    if (hasSelection && hasAggregations) {
      comparisonSettings.view[activeView].operations.forEach(op => {
        const newWindow = createAggregateIndividualWindow(comparisonSettings.view[activeView].preselection, op);

        // check for existing items first
        if (!comparisonSettings.selection.some(s => s.name === newWindow.name)) {
          comparisonSettings.selection.push({
            ...newWindow,
            mode:comparisonMode
          });
        }
      });

      // clear the pre-selection list
      comparisonSettings.view[activeView].preselection = [];
      createCalendar(comparisonMode, comparisonSettings);
    }

    return state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddPreselectionErrorMessage'], !(hasSelection & hasAggregations));
  },
  [ANALYSIS_COMPARISONMODE_AGGREGATE_ADD_RANGE_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;

    const { hasAggregations } = canAddPreselection(comparisonMode, comparisonSettings);
    if (hasAggregations) {
      comparisonSettings.view[activeView].operations.forEach(op => {
        const newWindow = createAggregateAbsWindowRange(comparisonMode, comparisonSettings.rangeStart, comparisonSettings.rangeEnd, op);

        // check for existing items first
        if (!comparisonSettings.selection.some(s => s.name === newWindow.name)) {
          comparisonSettings.selection.push({
            ...newWindow,
            mode:comparisonMode
          });
        }
      });
    }

    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddRangePreselectionErrorMessage'], !hasAggregations);
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_SET_INDIVIDUAL_RELATIVE_DATE](state, action) {
    const comparisonMode = action.comparisonMode;
    const relativeDate = action.relativeDate;

    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode, 'view', 'individualRel', 'relativeDate'], relativeDate);
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const relativeDate = state.getIn(['ui', 'comparisonSettings', comparisonMode, 'view', 'individualRel', 'relativeDate']);

    const newWindow = createRelativeIndividualWindow(relativeDate);
    if (!comparisonSettings.selection.some(s => s.name === newWindow.name)) {
      comparisonSettings.selection.push({
        ...newWindow,
        mode:comparisonMode
      });
    }

    return state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings));
  },
  [ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_DATE](state, action) {
    const comparisonMode = action.comparisonMode;
    const relativeDate = action.relativeDate;
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode, 'view', 'aggregationRel', 'relativeDate'], relativeDate);
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_TO_PRESELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const relativeDate = comparisonSettings.view.aggregationRel.relativeDate;

    let newState = state;

    if (!comparisonSettings.view.aggregationRel.options.dates.some(o => o.name === relativeDate)) {
      comparisonSettings.view.aggregationRel.options.dates.push({
        name: relativeDate,
        relFromDate: relativeDate,
        relToDate: relativeDate,
        isSelected: true
      });
      newState = newState.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings));
    }

    return newState;
  },
  [ANALYSIS_COMPARISONMODE_REMOVE_AGGREGATE_RELATIVE_FROM_PRESELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const relativeDate = action.relativeDate;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));

    comparisonSettings.view.aggregationRel.options.dates = comparisonSettings.view.aggregationRel.options.dates.filter(r => r.name !== relativeDate.name);
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;

    const { hasSelection, hasAggregations } = canAddPreselection(comparisonMode, comparisonSettings);
    if (hasSelection && hasAggregations) {
      comparisonSettings.view[activeView].operations.forEach(op => {
        const newWindow = createAggregateIndividualWindow(comparisonSettings.view[activeView].options.dates.map(d => ({
          name: d.name,
          isSelected: d.isSelected,
          window: {
            relFromDate: d.relFromDate,
            relToDate: d.relToDate,
          }
        })), op);

        // check for existing items first
        if (!comparisonSettings.selection.some(s => s.name === newWindow.name)) {
          comparisonSettings.selection.push({
            ...newWindow,
            mode:comparisonMode
          });
        }
      });

      // clear the pre-selection list
      comparisonSettings.view[activeView].options.dates = [];
    }

    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddPreselectionErrorMessage'], !(hasSelection & hasAggregations));
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_FROM_DATE](state, action) {
    const comparisonMode = action.comparisonMode;
    const relativeDate = action.relativeDate;
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode, 'view', 'aggregationRel', 'relativeFromDate'], relativeDate);
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_SET_AGGREGATE_RELATIVE_TO_DATE](state, action) {
    const comparisonMode = action.comparisonMode;
    const relativeDate = action.relativeDate;
    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode, 'view', 'aggregationRel', 'relativeToDate'], relativeDate);
    return newState;
  },
  [ANALYSIS_COMPARISONMODE_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;

    const { hasAggregations } = canAddPreselection(comparisonMode, comparisonSettings);
    if (hasAggregations) {
      comparisonSettings.view[activeView].operations.forEach(op => {
        const newWindow = createAggregateRelWindowRange(comparisonSettings.view.aggregationRel.relativeFromDate, comparisonSettings.view.aggregationRel.relativeToDate, op);

        // check for existing items first
        if (!comparisonSettings.selection.some(s => s.name === newWindow.name)) {
          comparisonSettings.selection.push({
            ...newWindow,
            mode:comparisonMode
          });
        }
      });
    }

    let newState = state.setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddRangePreselectionErrorMessage'], !hasAggregations);
    return newState;
  }
};

export function createCalendar(comparisonMode, comparisonSettings) {
  if (comparisonSettings.activeView === 'individualRel' || comparisonSettings.activeView === 'aggregationRel')
    return;

  const view = comparisonSettings.view[comparisonSettings.activeView];
  switch (comparisonMode) {
    case comparisonModes.yearOnYear:
    case comparisonModes.gasYear:
      {
        const years = [];
        let start = moment.utc(comparisonSettings.rangeStart).month(0).date(1);
        let end = moment.utc(comparisonSettings.rangeEnd).month(0).date(1);
        while (start <= end) {
          years.push(createCalendarWindow(comparisonMode, comparisonSettings, start.format('YYYY')));
          start = start.add(1, 'years');
        };

        view.options = {
          dates: years
        };
        break;
      }
    case comparisonModes.gasSeason:
      {
        const years = [];
        {
          let year = moment.utc(comparisonSettings.rangeStart).month(0).date(1);
          let end = moment.utc(comparisonSettings.rangeEnd).month(11).date(31);
          while (year <= end) {
            years.push({
              name: year.format('YYYY'),
              dates: [
                createCalendarWindow(comparisonMode, comparisonSettings, toGasSeasonFormat(year.month(4 - 1))),
                createCalendarWindow(comparisonMode, comparisonSettings, toGasSeasonFormat(year.month(10 - 1)))
               ]
            });
            year = year.add(1, 'years');
          };
        }

        view.options = {
          years: years,
        }
        break;
      }
    case comparisonModes.quarter:
        {
          const years = [];
          {
            let year = moment.utc(comparisonSettings.rangeStart).month(0).date(1);
            let end = moment.utc(comparisonSettings.rangeEnd).month(11).date(31);
            while (year <= end) {
              years.push({
                name: year.format('YYYY'),
                dates: [
                  createCalendarWindow(comparisonMode, comparisonSettings, toQuarterFormat(year.month(1 - 1))),
                  createCalendarWindow(comparisonMode, comparisonSettings, toQuarterFormat(year.month(4 - 1))),
                  createCalendarWindow(comparisonMode, comparisonSettings, toQuarterFormat(year.month(7 - 1))),
                  createCalendarWindow(comparisonMode, comparisonSettings, toQuarterFormat(year.month(10 - 1)))
                ]
              });
              year = year.add(1, 'years');
            };
          }
  
          view.options = {
            years: years,
          }
          break;
        }
    case comparisonModes.monthOnMonth:
      {
        const years = [];
        {
          let year = moment.utc(comparisonSettings.rangeStart).month(0).date(1);
          let end = moment.utc(comparisonSettings.rangeEnd).month(11).date(31);
          while (year <= end) {
            const dates = [];
            for (let month = 0; month < 12; month++) {
              const date = year.month(month);
              dates.push(createCalendarWindow(comparisonMode, comparisonSettings, date.format('MMM-YYYY')));
            }
            years.push({
              name: year.format('YYYY'),
              dates: dates
            });
            year = year.add(1, 'years');
          };
        }

        view.options = {
          years: years,
        }
        break;
      }
    case comparisonModes.dayOnDay:
      {
        const years = [];
        {
          let year = moment.utc(comparisonSettings.rangeStart).month(0).date(1);
          let end = moment.utc(comparisonSettings.rangeEnd).month(11).date(31);
          while (year <= end) {
            const dates = [];
            const yearIndex = year.year();
            for (let month = 0; month < 12; month++) {
              dates.push({
                year: yearIndex,
                month: month,
                name: month,
                isSelected: view.preselection.filter(s => s).some(s => {
                  const m = moment.utc(s.window.absFromDate);
                  return m.year() === yearIndex && m.month() === month;
                })
              });
            }
            years.push({
              name: year.format('YYYY'),
              dates: dates
            });
            year = year.add(1, 'years');
          };
        }

        view.yearView = {
          years: years,
        }

        const activeDate = view.activeDate;
        if (activeDate) {
          let date = moment.utc(activeDate).startOf('day');
          let dateNumber = -date.weekday() + 1;
          const daysInMonth = date.daysInMonth();
          const weeks = [];
          for (let y = 0; dateNumber < daysInMonth; y++) {
            const days = {
              name: `WK${weeks.length}`,
              dates: []
            };
            for (let x = 0; x < 7; x++) {
              if (dateNumber > 0 && dateNumber <= daysInMonth) {
                const dateNumberText = `${dateNumber}`.padStart(2, '0');
                var tempDate = moment.utc(`${date.format('YYYY-MM')}-${dateNumberText}T00:00:00Z`);
                days.dates.push({
                  ...createCalendarWindow(comparisonMode, comparisonSettings, tempDate.format('DD-MMM-YYYY')),
                  dateNumber: tempDate.format('DD')
                });
              }
              else {
                days.dates.push(undefined);
              }
              dateNumber++;
            }

            weeks.push(days);
          }

          view.options = {
            dateText: date.format('MMM-YYYY'),
            daysInMonth: date.daysInMonth(),
            weeks: weeks
          };
        } else {
          view.options = undefined;
        }

        break;
      }
    default:
      return undefined;
  }
}

function createCalendarWindow(comparisonMode, comparisonSettings, value) {
  const activeView = comparisonSettings.activeView;
  switch (comparisonMode) {
    case comparisonModes.yearOnYear:
    case comparisonModes.gasYear:
        {
        const dateText = (comparisonMode === comparisonModes.yearOnYear ? parseYearToDate(value) : parseGasYearToDate(value)).format();
        const name = dateToComparisonModeName(comparisonMode, dateText);
        return {
          windowType: 'simple',
          name: name,
          description: name,
          window: {
            absFromDate: dateText,
            absToDate: dateText
          },
          isSelected: comparisonSettings.view[activeView].preselection.some(s => s.name === name)
        };
      }
    case comparisonModes.gasSeason:
      {
        const date = parseGasSeasonToDate(value);
        const dateText = date.format();
        const name = dateToComparisonModeName(comparisonMode, dateText);
        return {
          windowType: 'simple',
          name: name,
          description: name,
          window: {
            absFromDate: dateText,
            absToDate: dateText
          },
          isSelected: comparisonSettings.view[activeView].preselection.some(s => s.name === name)
        };
      }
    case comparisonModes.quarter:
        {
          const date = parseQuarterToDate(value);
          const dateText = date.format();
          const name = value;
          return {
            windowType: 'simple',
            name: name,
            description: name,
            window: {
              absFromDate: dateText,
              absToDate: dateText
            },
            isSelected: comparisonSettings.view[activeView].preselection.some(s => s.name === name)
          };
        }
    case comparisonModes.monthOnMonth:
      {
        const dateText = moment.utc(`01-${value}`, 'DD-MMM-YYYY').format();
        const name = dateToComparisonModeName(comparisonMode, dateText);
        return {
          windowType: 'simple',
          name: name,
          description: name,
          window: {
            absFromDate: dateText,
            absToDate: dateText
          },
          isSelected: comparisonSettings.view[activeView].preselection.some(s => s.name === name)
        };
      }
    case comparisonModes.dayOnDay:
      {
        const dateText = moment.utc(`${value}`, 'DD-MMM-YYYY').format();
        const name = dateToComparisonModeName(comparisonMode, dateText);
        return {
          windowType: 'simple',
          name: name,
          window: {
            absFromDate: dateText,
            absToDate: dateText
          },
          isSelected: comparisonSettings.view[activeView].preselection.some(s => s && s.name === name)
        };
      }
    default:
      return undefined;
  }
}

export function canAddPreselection(comparisonMode, comparisonSettings) {
  let hasSelection = false;
  let hasAggregations = false;

  const view = comparisonSettings.view[comparisonSettings.activeView];

  switch (comparisonMode) {
    case comparisonModes.yearOnYear:
    case comparisonModes.gasYear:
      hasSelection = view.options.dates.some(s => s.isSelected);
      if (comparisonSettings.activeView === 'aggregationAbs' || comparisonSettings.activeView === 'aggregationRel') {
        hasAggregations = view.operations.length > 0;
      }
      break;
    case comparisonModes.gasSeason:
    case comparisonModes.monthOnMonth:
    case comparisonModes.quarter:
      if (comparisonSettings.activeView === 'aggregationRel') {
        hasSelection = view.options.dates.some(s => s.isSelected);
        hasAggregations = view.operations.length > 0;
      }
      else {
        hasSelection = view.options.years.some(y => y.dates.some(m => m.isSelected));
        if (comparisonSettings.activeView === 'aggregationAbs') {
          hasAggregations = view.operations.length > 0;
        }
      }
      break;
    case comparisonModes.dayOnDay:
      if (comparisonSettings.activeView === 'aggregationRel') {
        hasSelection = view.options.dates.some(s => s.isSelected);
        hasAggregations = view.operations.length > 0;
      }
      else {
        if (view.options) {
          hasSelection = view.options.weeks.some(w => w.dates.some(d => d && d.isSelected));
        }

        if (comparisonSettings.activeView === 'aggregationAbs') {
          hasAggregations = view.operations.length > 0;
        }
      }
      break;
    default:
      break;
  }

  return {
    hasSelection,
    hasAggregations
  }
}

export function createAggregateAbsWindowRange(comparisonMode, fromDate, toDate, operation) {
  switch (comparisonMode) {
    case comparisonModes.yearOnYear :
      fromDate = moment.utc(fromDate).month(0).day(0).format();
      toDate = moment.utc(toDate).month(0).day(0).format();
      break;
    case comparisonModes.gasYear :
      fromDate = moment.utc(fromDate).month(9).day(0).format();
      toDate = moment.utc(toDate).month(9).day(0).format();
      break;
    
    case comparisonModes.gasSeason:
    case comparisonModes.quarter:
    case comparisonModes.monthOnMonth:
      fromDate = moment.utc(fromDate).day(0).format();
      toDate = moment.utc(toDate).day(0).format();
      break;
    
    default:
      break;
  }

  const fromDateText = dateToComparisonModeName(comparisonMode, fromDate);
  const toDateText = dateToComparisonModeName(comparisonMode, toDate);
  return {
    windowType: 'aggregate',
    name: fromDateText === toDateText ? `(${fromDateText} ${operation})` :  `(${fromDateText} -> ${toDateText} ${operation})`,
    description: fromDateText === toDateText ? `(${fromDateText} ${operation})` :  `(${fromDateText} to ${toDateText} ${operation})`,
    aggregated: {
      windowType: 'range',
      operation: operation,
      range: {
        absFromDate: fromDate,
        absToDate: toDate,
      }
    }
  }
}

export function createAggregateRelWindowRange(fromDate, toDate, operation) {
  return {
    windowType: 'aggregate',
    name: (fromDate === toDate) ? `(${fromDate} ${operation})` : `(${fromDate} -> ${toDate} ${operation})`,
    description: (fromDate === toDate) ? `(${fromDate} ${operation})` : `(${fromDate} to ${toDate} ${operation})`,
    aggregated: {
      windowType: 'range',
      operation: operation,
      range: {
        relFromDate: fromDate,
        relToDate: toDate,
      }
    }
  }
}

export function createRelativeIndividualWindow(relativeDate) {
  return {
    windowType: 'simple',
    name: `${relativeDate}`,
    description: `${relativeDate}`,
    window: {
      relFromDate: relativeDate,
      relToDate: relativeDate
    }
  }
}

export function createAggregateIndividualWindow(windows, operation) {
  let name = '';
  if (windows.length === 1)
    name = windows[0].name;
  else
    name = `${windows[0].name}...${windows[windows.length - 1].name}`;

  return {
    windowType: 'aggregate',
    name: `(${name} ${operation})`,
    description: `(${windows.map(w => w.name).join(', ')} ${operation})`,
    aggregated: {
      windowType: 'individual',
      operation: operation,
      dates: windows
    }
  }
}