import React, { useCallback } from 'react';
import FormattedNumber from '../../shared/FormattedNumber';

export function EventsSearchFacet({ data, updateFilter, getActiveValue }) {
  const _data = data.toJS ? data.toJS() : data,
        key = _data.key,
        activeValue = getActiveValue(key);

  let results = _data.results;

  if ((!results || !results.length) && activeValue) results = [{ value: activeValue, count: 0 }];

  return (
    <ul className='facet-group list-group mb-2' style={{ flex: '1 100%', width: '100%', maxHeight: '20rem' }}>
      <li className='list-group-item list-group-header text-nowrap text-center text-uppercase'>
        { key }
      </li>
      <ul className='list-group'>
        { results.filter(i => i.value).map(({ value, count }) => {
          const isActive = activeValue === value;

          return (
            <li key={ value } className={ 'list-group-item list-group-item-action d-flex justify-content-between align-items-center' + (isActive ? ' active' : '') }
                onClick={ () => updateFilter(key, isActive ? null : value) }>
              { value }
              <span className='badge'><FormattedNumber>{ count }</FormattedNumber></span>
            </li>
          );
        }) }
      </ul>
    </ul>
  );
}

export default function EventsSearchFacets({ data, filters, updateFilter }) {
  const getActiveValue = useCallback((key) => {
    const filter = filters.find(f => f.get('name') === key);

    return filter ? filter.get('value') : null;
  }, [filters]);

  return (
    <div className='fota-facets container-fluid d-flex flex-wrap align-content-start justify-content-start pl-2' style={{ width: '18rem' }}>
      { data ? data.map(i => <EventsSearchFacet key={ i.get('key') } data={ i } updateFilter={ updateFilter } getActiveValue={ getActiveValue } />) : null }
    </div>
  );
}