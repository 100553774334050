import React, { useState } from 'react';
import RequiredField from '../../../shared/RequiredField';
import InfoPopup from '../../../shared/InfoPopup';
import CollapsePanel from '../../../shared/CollapsePanel';
import ConfirmButton from '../../../shared/ConfirmButton';
import moment from 'moment';
import Tooltip from '../../../shared/Tooltip';
import { Autocomplete, TextField } from '@mui/material';

const standardManyToManyFunctions = ['Discount','Lens','UnitConversion'];

const labelStyle = {
  fontSize: '11.6px',
  fontWeight: 700,
  margin: 0,
  cursor: 'default'
};

const inputStyle = {
  fontSize: '12px'
};

export default function FunctionSettings({ referenceData, functionSettings, timeSeries, updateTimeSeriesValue,
  derivationSelectedKeys,
  inputFocusId, setInputFocus, setInputHasFocus,
  setFunctionsStepStyleToMultiStep, setFunctionsType, addFunctionsStepParameter, deleteFunctionsStepParameter, updateFunctionParameterValue, addMultiStepFunction, deleteMultiStepFunction, moveMultiStepFunctionUp, moveMultiStepFunctionDown,
  addFunctionKey, deleteFunctionKey, moveFunctionKeyUp, moveFunctionKeyDown, addFunctionOutputCategory, updateFunctionOutputKey, updateFunctionOutputCategoryValue, deleteFunctionOutputCategory, setFunctionDisabled,
 }) {
  const { derivationData } = timeSeries;
  const { isMultiStepFunctions = false, functionData, functions: multiFunctionData, keys: inputKeys = [] } = derivationData || {};
  const { inputLens, outputLens } = functionData || {};
  const {
    lenses: lensOptions,
    functions: functionOptions
  } = referenceData;

  function setIsMultiStepFunctions(e) {
    setFunctionsStepStyleToMultiStep(e.target.value === 'true');
  }

  return (
    <CollapsePanel idKey='timeSeriesEditorFunctionSettings' headerStyle={{ cursor: 'pointer' }}
      title={<><span>Functions</span>
        <a className='fota-small-text' href='https://mercuria-fota.visualstudio.com/timeseries/_wiki/wikis/timeseries.wiki/65/Derived-time-series?anchor=fota-functions' target='_blank' rel='noopener noreferrer'>
          <i className='fas fa-book fa-fw' /> Wiki</a></>} >
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Step Style</label>
          <select className='form-control' style={inputStyle}
            value={isMultiStepFunctions}
            onChange={setIsMultiStepFunctions}>
            <option value={false}>Single Step</option>
            <option value={true}>Multi Step</option>
          </select>
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col-6 mb-1 pr-1'>
          <label style={labelStyle}>Input Lens</label>
          <select className='form-control' style={inputStyle}
            value={inputLens || ''}
            onChange={e => updateTimeSeriesValue(['derivationData', 'functionData', 'inputLens'], e.target.value)}>
            {lensOptions.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
        <div className='col-6 mb-1 pl-1'>
          <label style={labelStyle}>Output Lens</label>
          <select className='form-control' style={inputStyle}
            value={outputLens || ''}
            onChange={e => updateTimeSeriesValue(['derivationData', 'functionData', 'outputLens'], e.target.value)}>
            {lensOptions.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
      </div>
      {!isMultiStepFunctions && <SingleStepFunction
        functionSettings={functionSettings}
        functionData={functionData}
        functionOptions={functionOptions}
        derivationSelectedKeys={derivationSelectedKeys}
        updateTimeSeriesValue={updateTimeSeriesValue}
        setFunctionsType={setFunctionsType}
        addFunctionsStepParameter={addFunctionsStepParameter}
        deleteFunctionsStepParameter={deleteFunctionsStepParameter}
        updateFunctionParameterValue={updateFunctionParameterValue}
        inputFocusId={inputFocusId}
        setInputFocus={setInputFocus}
        setInputHasFocus={setInputHasFocus}
      />}
      {isMultiStepFunctions && <MultiStepFunctionsList
        functionSettings={functionSettings}
        functionsList={multiFunctionData}
        functionOptions={{ ...functionOptions, inputKeys }}
        derivationSelectedKeys={derivationSelectedKeys}
        addMultiStepFunction={addMultiStepFunction}
        deleteMultiStepFunction={deleteMultiStepFunction}
        moveMultiStepFunctionUp={moveMultiStepFunctionUp}
        moveMultiStepFunctionDown={moveMultiStepFunctionDown}
        setFunctionsType={setFunctionsType}
        addFunctionsStepParameter={addFunctionsStepParameter}
        deleteFunctionsStepParameter={deleteFunctionsStepParameter}
        updateFunctionParameterValue={updateFunctionParameterValue}
        addFunctionKey={addFunctionKey}
        deleteFunctionKey={deleteFunctionKey}
        moveFunctionKeyUp={moveFunctionKeyUp}
        moveFunctionKeyDown={moveFunctionKeyDown}
        addFunctionOutputCategory={addFunctionOutputCategory}
        updateFunctionOutputKey={updateFunctionOutputKey}
        updateFunctionOutputCategoryValue={updateFunctionOutputCategoryValue}
        deleteFunctionOutputCategory={deleteFunctionOutputCategory}
        setFunctionDisabled={setFunctionDisabled}
        inputFocusId={inputFocusId}
        setInputFocus={setInputFocus}
        setInputHasFocus={setInputHasFocus}
      />}
    </CollapsePanel>
  );
}

const hasFunctionName = name => !!name && name !== 'None';

function SingleStepFunction({ functionData, functionOptions, setFunctionsType,
  inputFocusId, setInputFocus, setInputHasFocus,
  addFunctionsStepParameter, deleteFunctionsStepParameter, updateFunctionParameterValue }) {
  let { function: functionName, customFunctionName, parameters: functionParameters } = functionData || {};
  const { standardFunctions = {}, customFunctions = {}, functionsList = [] } = functionOptions || {};
  functionName = hasFunctionName(customFunctionName) ? customFunctionName : hasFunctionName(functionName) ? functionName : '';

  let availableFunctionParameters = [];
  if (hasFunctionName(functionName)) {
    availableFunctionParameters = ((standardFunctions[functionName] ?? customFunctions[functionName]) || []).parameters || [];
  }

  function onSetFunction(functionName) {
    if (standardFunctions[functionName]) {
      setFunctionsType({ source: 'standard', functionName, parameters: standardFunctions[functionName].parameters });
    } else {
      if (customFunctions[functionName]) {
        setFunctionsType({ source: 'custom', functionName, parameters: customFunctions[functionName].parameters });
      }
    }
  }

  function onAddFunctionsStepParameter(name, inputFocusPath) {
    addFunctionsStepParameter({ name });
    setInputFocus(`${inputFocusPath}/${name}`);
  }

  function onUpdateParameter(name, value) {
    updateFunctionParameterValue({ name, value });
  }

  function onDeleteParameter(name) {
    deleteFunctionsStepParameter({ name });
  }

  return (
    <>
      <div className='row no-gutters'>
        <div className='col mb-1 mui-default'>
          <label style={labelStyle}>Function</label>
          <Autocomplete
            value={functionName}
            disableClearable={true}
            sx={{ padding: 0 }}
            onChange={(event, newValue) => {
              onSetFunction(newValue.id);
            }}
            options={functionsList.map(f => ({ label: f.name, id: f.name, function:f}))}
            renderOption={(props, option, { selected }) => (
              <div {...props}>                
                {option.label}&emsp;<i>{option.function.shortDescription}</i>
                {option.function.longDescription && <InfoPopup>
                  <p className='lead'>{option.function.longDescription}</p>
                  <p className='small'>Author: {option.function.author}</p>
                  <p className='small'>Last updated on: {moment(option.function.lastUpdatedOn).format('DD-MMM-yyyy HH:mm')}</p>
                  <p className='small'>Last updated by: {option.function.lastUpdatedBy}</p>
                  </InfoPopup>}
              </div>
            )}

            renderInput={(item) => <TextField {...item} />}
          />
        </div>
      </div>

      <div className='row no-gutters'>
        <label style={labelStyle}>Parameters</label>
        <NewParameter
          availableParameters={availableFunctionParameters}
          onAddNewParameter={name => onAddFunctionsStepParameter(name, 'single')}
          focusId='new-single-step'
          inputFocusId={inputFocusId}
          setInputHasFocus={setInputHasFocus} />
        <ParameterList
          parameterList={Object.keys(functionParameters || {}).sort()}
          parameterValues={functionParameters || {}}
          availableParameters={availableFunctionParameters}
          emptyMessage='No parameters have been added'
          onChange={onUpdateParameter}
          onRemove={onDeleteParameter}
          sourceFocusId='new-single-step'
          inputFocusPath='single'
          inputFocusId={inputFocusId}
          setInputFocus={setInputFocus}
          setInputHasFocus={setInputHasFocus} />
      </div>
    </>
  );
}

function MultiStepFunction({ functionData, multiFunctionIndex, functionOptions, derivationSelectedKeys,
  setFunctionsType, addFunctionsStepParameter, deleteFunctionsStepParameter, updateFunctionParameterValue,
  previousInputKeys = [], addFunctionKey, deleteFunctionKey, moveFunctionKeyUp, moveFunctionKeyDown,
  addFunctionOutputCategory, updateFunctionOutputKey, updateFunctionOutputCategoryValue, deleteFunctionOutputCategory,
  setFunctionDisabled,
  inputFocusId, setInputFocus, setInputHasFocus }) {
  let { function: functionName, customFunctionName, parameters: functionParameters, keys: inputKeys = [], disabled: isDisabled, outputKey, outputCategories } = functionData || {};
  const { standardFunctions = {}, customFunctions = {}, functionsList = [] } = functionOptions || {};
  const inputKeysOptions = [...derivationSelectedKeys, ...previousInputKeys.map(i => ({key:`${i}`, name: ''})) ];
  functionName = customFunctionName ?? functionName;

  let availableFunctionParameters = [];
  let isManyToMany = false;
  if (hasFunctionName(functionName)) {
    availableFunctionParameters = ((standardFunctions[functionName] ?? customFunctions[functionName]) || []).parameters || [];
    isManyToMany = standardManyToManyFunctions.includes(functionName) ? true : (customFunctions[functionName] || {}).signature === 'ManyToMany';
    if(isManyToMany && (functionName === 'Clone' || functionName === 'Constant')) {
      isManyToMany = false;
    }
  }

  function onSetFunction(value) {
    if (standardFunctions[value]) {
      setFunctionsType({ source: 'standard', multiFunctionIndex, functionName: value, parameters: standardFunctions[value].parameters });
    } else {
      if (customFunctions[value]) {
        setFunctionsType({ source: 'custom', multiFunctionIndex, functionName: value, parameters: customFunctions[value].parameters });
      }
    }
  }

  function onAddFunctionsStepParameter(name, inputFocusPath) {
    addFunctionsStepParameter({ multiFunctionIndex, name });
    setInputFocus(`${inputFocusPath}/${name}`);
  }

  function onAddInputKey(inputKey) {
    addFunctionKey({ multiFunctionIndex, key: inputKey });
  }

  function onMoveKeyUp(index) {
    moveFunctionKeyUp({ multiFunctionIndex, index });
  }

  function onMoveKeyDown(index) {
    moveFunctionKeyDown({ multiFunctionIndex, index });
  }

  function onDeleteKey(index) {
    deleteFunctionKey({ multiFunctionIndex, index });
  }

  function onSetDisabled(isDisabled) {
    setFunctionDisabled({ multiFunctionIndex, isDisabled });
  }

  function onUpdateParameter(name, value) {
    updateFunctionParameterValue({ multiFunctionIndex, name, value });
  }

  function onDeleteParameter(name) {
    deleteFunctionsStepParameter({ multiFunctionIndex, name });
  }

  function onUpdateOutputKey(value) {
    updateFunctionOutputKey({ multiFunctionIndex, value });
  }

  function onAddOutputCategory(name, inputFocusPath) {
    addFunctionOutputCategory({ multiFunctionIndex, name });
    setInputFocus(`${inputFocusPath}/${name}`);
  }

  function onUpdateOutputCategory(name, value) {
    updateFunctionOutputCategoryValue({ multiFunctionIndex, name, value });
  }

  function onDeleteOutputCategory(name) {
    deleteFunctionOutputCategory({ multiFunctionIndex, name });
  }

  function InputKeyName({inputKey}){
    var item = inputKeysOptions.find(i => `${i.key}` === `${inputKey}`);
    if (item && item.identityId)
      return <>{item.identityId} {item.name}</>;

    return <>{`${inputKey}`}</>;
  }

  function ToolipInputKeyName({inputKey}){
    var item = inputKeysOptions.find(i => `${i.key}` === `${inputKey}`);
    if (item && item.identityId){
      return <> {item.identityId} {item.name}
        {item.granularity && <><br/>Granularity:{item.granularity}</>}
        {item.sourceTimeZoneId && <><br/>TimeZone:{item.sourceTimeZoneId}</>}
        </>
    }

    return <>{`${inputKey}`}</>
  }

  return <div>
    <div className='row no-gutters'>
      <div className='col mb-1'>
        <label style={labelStyle}>Function</label>
        <select className='form-control' value={functionName} onChange={e => onSetFunction(e.target.value)}>
          {functionsList.map((f, fi) => <option key={`${fi}`} value={f.name} title={`${f.longDescription ? f.longDescription : f.shortDescription}`}>{f.name}&emsp;{f.shortDescription && `(${f.shortDescription})`}</option>)}
          {functionName === 'None' && <option disabled value={functionName}></option>}
        </select>
        {/*<DropdownList data={functionsList} */}
        {/*              onChange={value => onSetFunction(value.name)}*/}
        {/*              textField='name' */}
        {/*              value={functionName}*/}
        {/*              renderListItem={({ item }) => (*/}
        {/*                <span>*/}
        {/*                  {item.name}&emsp; */}
        {/*                  <i>{item.shortDescription}</i>*/}
        {/*                  {item.longDescription && <InfoPopup>*/}
        {/*                      <p className='lead'>{item.longDescription}</p>*/}
        {/*                      <p className='small'>Author: {item.author}</p>*/}
        {/*                      <p className='small'>Last updated on: {moment(item.lastUpdatedOn).format('DD-MMM-yyyy HH:mm')}</p>*/}
        {/*                      <p className='small'>Last updated by: {item.lastUpdatedBy}</p>*/}
        {/*                    </InfoPopup>}                          */}
        {/*                </span>*/}
        {/*              )}/>*/}
      </div>
    </div>

    <div className='row no-gutters'>
      <div className='col mb-1'>
        <label style={labelStyle}>Input Keys</label>
        {(inputKeysOptions && inputKeysOptions.length > 0) && <select className='form-control' style={inputStyle}
          value=''
          onChange={e => onAddInputKey(e.target.value)}>
          <option value='' disabled>Select input key</option>
          {inputKeysOptions && inputKeysOptions.map(k => <option key={`inputKey${k.type}-${k.key}`} value={k.key}>{KeySource(k, inputKeysOptions)} {k.identityId} {k.name || k.key}</option>)}
        </select>}

        {(!inputKeysOptions || inputKeysOptions.length === 0) && <div style={inputStyle}>No input keys have been added</div>}
      </div>
    </div>

    {inputKeys && inputKeys.map((k, index) => <div key={`fk${k}`} className='row no-gutters'>
      <div className='col' style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
        <Tooltip placement='right' title={<ToolipInputKeyName inputKey={k}/>}>
          <span><InputKeyName inputKey={k}/></span>
        </Tooltip>
      </div>
      <div className='btn-toolbar'>
        <button type='button' className='btn btn-sm' disabled={index === 0} onClick={e => onMoveKeyUp(index)}>
          <i className='fas fa-arrow-up fa-fw' />
        </button>
        <button type='button' className='btn btn-sm' disabled={index === inputKeys.length - 1} onClick={e => onMoveKeyDown(index)}>
          <i className='fas fa-arrow-down fa-fw' />
        </button>
        <button type='button' className='btn btn-sm' onClick={() => onDeleteKey(index)}>
          <i className='fas fa-trash fa-fw' />
        </button>
      </div>
    </div>)}

    <div className='row no-gutters'>
      <div className='col mb-1'>
        <label style={labelStyle}>Parameters</label>
        <NewParameter
          availableParameters={availableFunctionParameters}
          onAddNewParameter={name => onAddFunctionsStepParameter(name, `multi-step-function-parameter/${multiFunctionIndex}`)}
          inputFocusId={inputFocusId}
          focusId={`new-multi-step-function-parameter/${multiFunctionIndex}`}
          setInputHasFocus={setInputHasFocus} />
        <ParameterList
          parameterList={Object.keys(functionParameters || {})}
          parameterValues={functionParameters || {}}
          availableParameters={availableFunctionParameters}
          emptyMessage='No parameters have been added'
          onChange={onUpdateParameter}
          onRemove={onDeleteParameter}
          sourceFocusId={`new-multi-step-function-parameter/${multiFunctionIndex}`}
          inputFocusPath={`multi-step-function-parameter/${multiFunctionIndex}`}
          inputFocusId={inputFocusId}
          setInputFocus={setInputFocus}
          setInputHasFocus={setInputHasFocus} />
      </div>
    </div>

   <div className='row no-gutters'>
      <div className='col mb-1'>
        <label style={labelStyle}>Output Key{isManyToMany && <InfoPopup>Output keys are only allowed on functions that have a single output, apart from Clone and Constant which are special cases</InfoPopup>}</label>
        <input type='text' className='form-control' style={inputStyle}
          value={outputKey || ''}
          onChange={e => onUpdateOutputKey(e.target.value)} disabled={isManyToMany} />
        </div>
    </div>

    <div className='row no-gutters'>
      <div className='col mb-1'>
        <label style={labelStyle}>Output Categories</label>
        <NewParameter
          onAddNewParameter={name => onAddOutputCategory(name, `multi-step-function-category/${multiFunctionIndex}`)}
          inputFocusId={inputFocusId}
          focusId={`new-multi-step-function-category/${multiFunctionIndex}`}
          setInputHasFocus={setInputHasFocus} />
        <ParameterList
          parameterList={Object.keys(outputCategories || {})}
          parameterValues={outputCategories || {}}
          availableParameters={[]}
          emptyMessage='No categories have been added'
          onChange={onUpdateOutputCategory}
          onRemove={onDeleteOutputCategory}
          sourceFocusId={`new-multi-step-function-category/${multiFunctionIndex}`}
          inputFocusPath={`multi-step-function-category/${multiFunctionIndex}`}
          inputFocusId={inputFocusId}
          setInputFocus={setInputFocus}
          setInputHasFocus={setInputHasFocus} />
      </div>
    </div>

    <div className='row no-gutters'>
      <div className='col mb-1'>
        <input type='checkbox' id={`isDisabled${multiFunctionIndex}`} checked={isDisabled} onChange={e => onSetDisabled(e.target.checked)} />
        <label className='ml-1' htmlFor={`isDisabled${multiFunctionIndex}`} style={labelStyle}>Disabled</label>
      </div>
    </div>

  </div>
}

function KeySource (key, options) {
  if (options.length === 0)
    return '';

  if (options.every(x => !x.type || x.type === 'Request') || options.every(x => !x.type || x.type === 'Identity'))
    return '';

  if (key.type === 'Request')
    return '[Basket]';

    return '[Search]';
}

function MultiStepFunctionsList({ functionSettings, functionsList, functionOptions, derivationSelectedKeys,
  inputFocusId, setInputFocus, setInputHasFocus,
  setFunctionsType, addFunctionsStepParameter, deleteFunctionsStepParameter, updateFunctionParameterValue,
  addMultiStepFunction, deleteMultiStepFunction, moveMultiStepFunctionDown, moveMultiStepFunctionUp,
  addFunctionKey, deleteFunctionKey, moveFunctionKeyUp, moveFunctionKeyDown,
  addFunctionOutputCategory, updateFunctionOutputKey, updateFunctionOutputCategoryValue, deleteFunctionOutputCategory,
  setFunctionDisabled, setCompleteEditParameter }) {
  function onMoveUp(e, index) {
    e.stopPropagation();
    moveMultiStepFunctionUp(index);
  }

  function onMoveDown(e, index) {
    e.stopPropagation();
    moveMultiStepFunctionDown(index);
  }

  function getInputKeys(index) {
    const keys = [];

    functionsList.slice(0, index).forEach(f => {
      if (f.outputKey) {
        keys.push(f.outputKey);
      }
    });

    return keys;
  }

  function getHeaderStyle(func){
    const style = {};
    if (func.disabled === true) {
      style.backgroundColor ='#BBBBBB';
      style.color ='#555555';
      style.textDecoration = 'line-through';
    }

    return style;
  }

  return <div>
    {functionsList && functionsList.map((func, index) => (
      <CollapsePanel collapsed={func.isNew ? undefined : true} headerStyle={getHeaderStyle(func)} key={`df${index}`} idKey={`multifunc-${index}`} title={<div className='row no-gutters' >
        <div className='col mt-1' style={inputStyle}>
          {index + 1}: {func.customFunctionName ?? func.function}
        </div>
        <div className='col-1' >
          <button type='button' className='btn btn-sm' disabled={index === 0} onClick={e => onMoveUp(e, index)}>
            <i className='fas fa-arrow-up fa-fw' />
          </button>
        </div>
        <div className='col-1'>
          <button type='button' className='btn btn-sm' disabled={index === functionsList.length - 1} onClick={e => onMoveDown(e, index)}>
            <i className='fas fa-arrow-down fa-fw' />
          </button>
        </div>
        <div className='col-1'>
          <ConfirmButton type='button' className='btn btn-sm' confirmClassName='btn btn-outline-warning btn-sm' onClick={() => deleteMultiStepFunction(index)}
            content={
              <i className='fas fa-trash fa-fw' />
            }
            confirm={
              <span style={{ whiteSpace: 'nowrap' }}><i className='fas fa-trash fa-fw' />?</span>
            }>
          </ConfirmButton>
        </div>
      </div>}>
        <MultiStepFunction
          functionSettings={functionSettings}
          functionData={func}
          derivationSelectedKeys={derivationSelectedKeys}
          multiFunctionIndex={index}
          functionOptions={functionOptions}
          setFunctionsType={setFunctionsType}
          addFunctionsStepParameter={addFunctionsStepParameter}
          deleteFunctionsStepParameter={deleteFunctionsStepParameter}
          updateFunctionParameterValue={updateFunctionParameterValue}
          addFunctionKey={addFunctionKey}
          deleteFunctionKey={deleteFunctionKey}
          moveFunctionKeyUp={moveFunctionKeyUp}
          moveFunctionKeyDown={moveFunctionKeyDown}
          addFunctionOutputCategory={addFunctionOutputCategory}
          updateFunctionOutputKey={updateFunctionOutputKey}
          updateFunctionOutputCategoryValue={updateFunctionOutputCategoryValue}
          deleteFunctionOutputCategory={deleteFunctionOutputCategory}
          setFunctionDisabled={setFunctionDisabled}
          setCompleteEditParameter={setCompleteEditParameter}
          previousInputKeys={getInputKeys(index)}
          inputFocusId={inputFocusId}
          setInputFocus={setInputFocus}
          setInputHasFocus={setInputHasFocus} />
      </CollapsePanel>
    ))}
    <div className='row no-gutters justify-content-end'>
      <button className="btn btn-secondary" onClick={e => addMultiStepFunction()} >
        <i className='fas fa-plus fa-fw' />Add Step
      </button>
    </div>
  </div>
}

const NewParameter = function ({ availableParameters = [], onAddNewParameter, focusId, inputFocusId, setInputHasFocus }) {
  const [nameText, setNameText] = useState('');
  function onNameChanged(e) {
    setNameText(e.target.value);
  }

  function onNameChangedKeyPress(e) {
    const keyCode = e.charCode || e.keyCode || 0;
    if (keyCode === 13) {
      onAddName();
      setNameText('');
    }
  }

  function onAddName() {
    onAddNewParameter(nameText);
  }

  function onHandleFocus(input) {
    if (!input)
      return;

    if (focusId === inputFocusId) {
      input.focus();
      setInputHasFocus();
    }
  }

  return <div className='input-group'>
    <input ref={onHandleFocus} className='form-control' type='text' style={inputStyle} placeholder='Parameter name' autoComplete='off' list={focusId}
      value={nameText}
      onChange={onNameChanged}
      onKeyPress={onNameChangedKeyPress} />
    <datalist id={focusId}>
      {availableParameters && availableParameters.map(k => <option key={k.name}>{k.name}</option>)}
    </datalist>
    <div className='input-group-append'>
      <button className='btn btn-secondary' style={inputStyle} onClick={e => onAddName()} ><i className='fas fa-plus fa-fw' /></button>
    </div>
  </div>
}

function Parameter({ label, value, exampleValues, mandatory, description, onChange, onRemove, sourceFocusId, focusId, inputFocusId, setInputFocus, setInputHasFocus }) {
  function onValueChangedKeyPress(e) {
    const keyCode = e.charCode || e.keyCode || 0;
    if (keyCode === 13) {
      setInputFocus(sourceFocusId);
    }
  }

  function onHandleFocus(input) {
    if (!input)
      return;

    if (focusId === inputFocusId) {
      input.focus();
      setInputHasFocus();
    }
  }

  return (
    <div className='row no-gutters w-100 mt-1'>
      <div className='col-5 mt-2' style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
        <Tooltip placement='right' title={label}>
          <span>
            {label}
          </span>
        </Tooltip>
        {mandatory && <RequiredField />}
        {description && <InfoPopup>{description}</InfoPopup>}
      </div>
      <div className='col'>
        <input ref={onHandleFocus} type='text' style={inputStyle} value={value} className='form-control' list={focusId}
          onChange={e => onChange(label, e.target.value)} onKeyPress={onValueChangedKeyPress} />
        <datalist id={focusId}>
          {exampleValues && exampleValues.map(k => <option key={k}>{k}</option>)}
    </datalist>
      </div>
      <div className='col-1 ml-2'>
        <button type='button' className='btn btn-sm' onClick={() => onRemove(label)}>
          <i className='fas fa-trash fa-fw' />
        </button>
      </div>
    </div>
  )
};

function ParameterList({ parameterList = [], parameterValues, availableParameters, emptyMessage, onChange, onRemove,
  sourceFocusId, inputFocusId, inputFocusPath, setInputFocus, setInputHasFocus }) {
  function lookupParameter(i) {
    return availableParameters.find(x => i === x.name);
  }

  function lookupValue(i) {
    return parameterValues ? parameterValues[i] : '';
  }

  return (
    <div className='row w-100 no-gutters'>
      {parameterList && parameterList.map(p => {
        const { mandatory, description, exampleValues } = lookupParameter(p) ?? {};
        const value = lookupValue(p);

        return (
          <Parameter key={p}
            mandatory={mandatory}
            description={description}
            label={p}
            value={value}
            exampleValues={exampleValues}
            onChange={onChange}
            onRemove={onRemove}
            sourceFocusId={sourceFocusId}
            inputFocusId={inputFocusId}
            focusId={`${inputFocusPath}/${p}`}
            setInputFocus={setInputFocus}
            setInputHasFocus={setInputHasFocus} />
        );
      })}
      {!parameterList.length && <div className='row no-gutters w-100 mt-1'><div className='col' style={inputStyle}>{emptyMessage || 'No items have been added'}</div></div>}
    </div>
  );
};
