import { fromJS } from 'immutable';
import { getNextUserColour, toHighChartsColour } from './color-utility';
import { guid } from './uid-utility';
import { toJS } from './immutable-utility';
import { createPlotOrigins, dateToComparisonModeName } from './comparisonmode-utility';
import deepmerge from 'deepmerge';
import { assertType } from './type-checking';
import PropTypes from 'prop-types';

export function getTimeSeries({ basket }) {
  const response = [];

  basket.forEach(item => {
    if (!response.find(ts => `${ts.identityId}` === `${item.identityId}`)) {
      response.push(item);
    }
  });

  return response.map(mapToTimeSeries);
}

export function extractTimeSeriesItems({ basket }) {
  const basketItems = basket.map(ts => ({ identityId: ts.identityId, basketItem: ts }))
    .reduce((a, i) => a.some(kv => kv.identityId === i.identityId) ? a : [...a, i], [])
    .map(kv => kv.basketItem);

  const timeSeries = basketItems.map(mapToTimeSeries)
  return timeSeries;
}

export function removeRangeOfTimeSeriesFromBasket({ basket, timesSeries }) {
  let response = [...basket];

  if (!Array.isArray(timesSeries))
    timesSeries = [timesSeries];

  timesSeries.forEach(removeTs => {
    response = response.filter(ts => `${ts.identityId}` !== `${removeTs.id}`);
  });

  return response;
}

// returns a cloned instance with unique colour and a new instance of the derivation data with a new id
export function cloneBasketItem({ basket, key, suppressName, withProperty, withValue }) {
  const index = basket.findIndex(i => `${i.key}` === `${key}`);
  const original = basket[index];

  const clone = enrichBasketItem({
    ...original,
    nameStyle: 'custom',
    customName: suppressName ? original.name : `Clone of ${original.name}`,
    name: `Clone of ${original.name}`,
    key: mapToIdentityKey(original, basket),
    color: getNextUserColour(),
    derivationData: original.derivationData ? {
      ...original.derivationData,
      id: guid(),
      name: `Clone of ${original.derivationData.name}`
    } : undefined,
    createdFrom: key
  });

  if (withProperty) {
    clone[withProperty] = withValue;
  }

  return {
    basket: [...basket.slice(0, index + 1), clone, ...basket.slice(index + 1)],
    newBasketItem: clone
  };
}

// overwrites the existing item with the new properties from the basketItem (matching by Key) and synchronises all instances of derivation data
export function updateBasketItem({ basket, basketItem }) {
  const response = [...basket];

  const editTs = response.find(ts => `${ts.key}` === `${basketItem.key}`);
  if (editTs) {
    Object.assign(editTs, basketItem);

    if (basketItem.derivationData && basketItem.derivationData.id) {
      basket.forEach(other => {
        if (other !== basketItem && other.derivationData && other.derivationData.id === basketItem.derivationData.id) {
          other.derivationData = { ...basketItem.derivationData };
        }
      });
    }
  }

  return response;
}

export function projectForecastsToBasket({ basket, key, asAts = [], adHocAsAts = [], relativeAsAts = [], relativeForecasts = [] }) {
  let index = basket.findIndex(i => `${i.key}` === `${key}`);
  if (index < 0)
    return basket;

  let basketItem = basket[index];

  const useKey = !(basketItem.createdFrom && !basket.find(ts => `${ts.key}` === `${basketItem.createdFrom}`));
  if (!useKey)
    key = basketItem.createdFrom;

  // Build all new forecasts
  const newBasketItems = [];

  asAts.forEach(asAt => {
    newBasketItems.push(createNewProjectedBasketItem({
      basketItem: {
        ...basketItem,
        asAtType: 'asAt',
        asAt: asAt
      },
      createdFrom: key
    }));
  });

  adHocAsAts.forEach(asAt => {
    newBasketItems.push(createNewProjectedBasketItem({
      basketItem: {
        ...basketItem,
        asAtType: 'adHocAsAt',
        asAt: asAt
      },
      createdFrom: key
    }));
  });

  relativeAsAts.forEach(relativeAsAt => {
    newBasketItems.push(createNewProjectedBasketItem({
      basketItem: {
        ...basketItem,
        asAtType: 'relativeAsAt',
        relativeAsAtDate: relativeAsAt
      },
      createdFrom: key
    }));
  });

  relativeForecasts.forEach(relativeForecast => {
    let forecastOffset = relativeForecast.period.replace('D', '');
    let cutoffTime = relativeForecast.cutoffTime;
    newBasketItems.push(createNewProjectedBasketItem({
      basketItem: {
        ...basketItem,
        asAtType: 'forecastOffset',
        forecastOffset: forecastOffset,
        cutoffTime: cutoffTime
      },
      createdFrom: key
    }));
  });

  // Remove all forecasts not specified in asAts, adHocAsAts, relativeAsAts or relativeForecasts
  basket = basket.filter(ts => {
    return ts.identityId !== basketItem.identityId // not targeting the same identity so keep it
      || ts.createdFrom !== key // not created from this key
      || (!ts.asAtType || ts.asAtType === 'none') // no asAt settings so keep it
      || newBasketItems.some(newTs => areForecastsEqual(newTs, ts));
  });

  // Add new unique forecasts
  let response = [...basket];
  newBasketItems.forEach(newTs => {
    if (!basket.some(ts => ts.createdFrom === key && areForecastsEqual(newTs, ts))) {
      const newBasketItem = enrichBasketItem({
        ...newTs,
        key: mapToIdentityKey(newTs, response),
        color: getNextUserColour()
      });

      response = [...response.slice(0, index + 1), newBasketItem, ...response.slice(index + 1)];
      index++;
    }
  });

  return response;
}

export function extractForecastOptionsForBasketItem({ basket, key }) {
  let basketItem = basket.find(ts => ts.key === key);

  const useKey = !(basketItem.createdFrom && !basket.find(ts => `${ts.key}` === `${basketItem.createdFrom}`));
  const basketItems = useKey ?
    basket.filter(ts => ts.createdFrom === `${key}` && `${ts.identityId}` === `${basketItem.identityId}`) :
    basket.filter(ts => ts.createdFrom === `${basketItem.createdFrom}` && `${ts.identityId}` === `${basketItem.identityId}`);

  const forecastBasketItems = basketItems
    .filter(ts => mapToDataKey(ts).indexOf('forecast') !== -1)
    .map(ts => ({ dataKey: mapToForecastHashKey(ts), basketItem: ts }))
    .reduce((a, i) => a.some(kv => kv.dataKey === i.dataKey) ? a : [...a, i], [])
    .map(kv => kv.basketItem);

  return {
    asAts: forecastBasketItems.filter(ts => ts.asAtType === 'asAt').map(ts => ts.asAt),
    adHocAsAts: forecastBasketItems.filter(ts => ts.asAtType === 'adHocAsAt').map(ts => ts.asAt),
    relativeAsAts: forecastBasketItems.filter(ts => ts.asAtType === 'relativeAsAt').map(ts => ({
      period: ts.relativeAsAtDate.period,
      cutoffTime: ts.relativeAsAtDate.cutoffTime
    })),
    relativeForecasts: forecastBasketItems.filter(ts => ts.asAtType === 'forecastOffset').map(ts => ({
      period: `${ts.forecastOffset}D`,
      cutoffTime: ts.cutoffTime
    })),
    timeSeries: basket.find(ts => `${ts.identityId}` === `${basketItem.identityId}`)
  };

  function mapToForecastHashKey(ts) {
    const key = [];
    if (ts.asAtType) {
      key.push(`forecast`);
      if (ts.asAtType === 'asAt') key.push(`a:${ts.asAt}`);
      if (ts.asAtType === 'adHocAsAt') key.push(`aa:${ts.asAt}`);
      if (ts.asAtType === 'relativeAsAt') key.push(`r:${ts.relativeAsAtDate.period}@${ts.relativeAsAtDate.cutoffTime}`);
      if (ts.asAtType === 'forecastOffset') key.push(`f:${ts.forecastOffset}@${ts.cutoffTime}`);
    }
    return key.join('|');
  }
}

export function projectComparisonsToBasket({ basket, key, selection = [], plotOrigins }) {
  let index = basket.findIndex(i => `${i.key}` === `${key}`);
  if (index < 0)
    return basket;

  let basketItem = basket[index];

  const useKey = !(basketItem.createdFrom && !basket.find(ts => `${ts.key}` === `${basketItem.createdFrom}`));
  if (!useKey)
    key = basketItem.createdFrom;

  basket = [...basket];

  // Build all new comparisons
  const newBasketItems = [];

  selection.forEach(comparisonSetting => {
    newBasketItems.push(createNewProjectedBasketItem({ basketItem, comparisonSetting, createdFrom: key }));
  });

  // Remove all comparisons related to the time series, which are not in the selection
  basket = basket.filter(ts => {
    return ts.identityId !== basketItem.identityId // not targeting the same identity so keep it
      || ts.createdFrom !== key // not created from this key
      || !ts.comparisonSettings // no comparison settings so keep it
      || newBasketItems.some(newTs => areComparisonsEqual(newTs, ts));
  });

  // Add new unique comparisons
  let response = [...basket];
  newBasketItems.forEach(newTs => {
    if (!basket.some(ts => ts.createdFrom === key && areComparisonsEqual(newTs, ts))) {
      const newBasketItem = enrichBasketItem({
        ...newTs,
        key: mapToIdentityKey(newTs, response),
        color: getNextUserColour()
      });

      response = [...response.slice(0, index + 1), newBasketItem, ...response.slice(index + 1)];
      index++;
    }
  });

  // Update comparison origins
  response.forEach(ts => {
    if (plotOrigins && ts.comparisonSettings && ts.comparisonSettings.mode !== 'none')
      ts.comparisonSettings.plotOrigin = plotOrigins[ts.comparisonSettings.mode];
  });

  return response;
}

export function extractComparisonOptionsForBasketItem({ topLevelWindow, basket, key, comparisonMode }) {
  const basketItem = basket.find(ts => `${ts.key}` === `${key}`);

  let sourcePlotOrigin = topLevelWindow ? topLevelWindow.absFromDate : undefined;
  if (basketItem.windowType && basketItem.windowType === 'override')
    sourcePlotOrigin = basketItem.window.absFromDate;

  const useKey = !(basketItem.createdFrom && !basket.find(ts => `${ts.key}` === `${basketItem.createdFrom}`));
  const basketItems = useKey ?
    basket.filter(ts => ts.createdFrom === `${key}` && `${ts.identityId}` === `${basketItem.identityId}` && ts.comparisonSettings) :
    basket.filter(ts => ts.createdFrom === `${basketItem.createdFrom}` && `${ts.identityId}` === `${basketItem.identityId}` && ts.comparisonSettings);

  const selection = [];
  const plotOrigins = createPlotOrigins(sourcePlotOrigin);

  basketItems.forEach(ts => {
    comparisonMode = ts.comparisonSettings ? ts.comparisonSettings.mode : comparisonMode;

    const { name, description } = mapToComparisonDisplay(ts.comparisonSettings);
    if (ts.comparisonSettings.plotOrigin) {
      plotOrigins[ts.comparisonSettings.mode] = {
        mode: ts.comparisonSettings.plotOrigin.mode,
        absOriginDate: ts.comparisonSettings.plotOrigin.absOriginDate,
        relOriginDate: ts.comparisonSettings.plotOrigin.relOriginDate
      };
    }

    if (!selection.some(s => s.name === name && s.mode === ts.comparisonSettings.mode)) {
      selection.push({
        name: name,
        mode: ts.comparisonSettings.mode,
        description: description,
        windowType: ts.comparisonSettings.windowType,
        window: ts.comparisonSettings.window,
        aggregated: ts.comparisonSettings.aggregated
      });
    }
  });

  return {
    key: key,
    comparisonMode: comparisonMode,
    timeSeriesName: basketItem.timeSeriesName,
    selection: selection,
    plotOrigins: plotOrigins
  };
}

export function addTimeSeriesProjectionsToBasket(timeSeriesToAdd, defaultNameStyle, suppressExistsCheck, basket, reportCriteriaShapes = [], reportCriteriaComparisons = [], reportCriteriaXAxisId = undefined, selectedSearchSchema) {
  const itemsToProject = [];
  timeSeriesToAdd.forEach(newTs => {
    const add = suppressExistsCheck || (!suppressExistsCheck && !basket.some(ts => `${ts.identityId}` === `${newTs.id}`));
    if (add) {
      itemsToProject.push(enrichBasketItem({
        ...mapToBasketTimeSeries(newTs, defaultNameStyle, selectedSearchSchema, basket),
        key: mapToIdentityKey(newTs, [...itemsToProject, ...basket]),
        color: getNextUserColour()
      }));
    }
  });

  return addProjectionsToBasket(itemsToProject, basket, reportCriteriaShapes, reportCriteriaComparisons, reportCriteriaXAxisId);
}

export function removeTimeSeriesFromBasket(identityId, basket, reportCriteriaXAxisId) {
  identityId = `${identityId}`;
  const isXAxisDeletion = identityId === `${reportCriteriaXAxisId ?? ''}`;
  const updatedBasket = [];

  basket.forEach(basketItem => {
    if (isXAxisDeletion && `${basketItem.xAxisKey}` === identityId) {
      if (basketItem.nonXAxisStyle)
        Object.assign(basketItem, basketItem.nonXAxisStyle);

      delete basketItem.xAxisKey;
      delete basketItem.nonXAxisStyle;
    }

    if (`${basketItem.identityId}` !== identityId) {
      updatedBasket.push(basketItem);
    }
  });

  return updatedBasket;
}

export function addOnDemandProjectionsToBasket(defaults = {}, defaultTimeZoneId, defaultLens, basket, reportCriteriaShapes = [], reportCriteriaComparisons = [], reportCriteriaXAxisId = undefined) {
  const defaultGranularity = defaultLens ? getGranularityFromLens(defaultLens) : undefined;

  let derivationId = -1;
  for (let index = 0; index < basket.length; index++) {
    derivationId = Math.min(basket[index].identityId - 1, derivationId);
  }

  const defaultsDerivationData = defaults.derivationData ?? {};
  const timeSeriesName = `Derived${derivationId}`;

  const itemToProject = {
    ...mapToBasketTimeSeries({
      id:derivationId, 
      key: `Derived${derivationId}`,
      unit: defaults.unit ?? 'MW',
      granularityType: (defaultGranularity && defaultGranularity.granularityType) ?? defaults.granularityType ?? 'Day',
      granularityFrequency: (defaultGranularity && defaultGranularity.granularityFrequency) ?? defaults.granularityFrequency ?? '1',
      sourceTimeZoneId: defaultTimeZoneId ?? defaults.sourceTimeZoneId ?? 'UTC',
      style: defaults.style ?? 'Derived',
      type: defaults.type ?? 'line',
      dashStyle: defaults.dashStyle ?? 'solid',
      stacking: defaults.stacking ?? '',
    }, null, null),
    name: timeSeriesName,
    timeSeriesName: timeSeriesName,
    derivationType: 'On demand',
    derivationData: {
      id: guid(),
      name: timeSeriesName,
      functionData: defaultsDerivationData.functionData ?? {
        customFunctionName: "None",
        function: "None",
        inputLens: "None",
        outputLens: "None",
        parameters: {}
      },
      functions: defaultsDerivationData.functions ?? [],
      keys: defaultsDerivationData.keys ?? [],
      sourceTimeZoneId: defaultTimeZoneId ?? defaults.sourceTimeZoneId ?? 'UTC',
      unit: defaultsDerivationData.unit ?? 'MW',
      granularity: defaultGranularity
        ?? {
        granularityType: 'Day',
        granularityFrequency: '1'
      }
        ?? defaultsDerivationData.granularity ?? {
        granularityType: 'Day',
        granularityFrequency: '1'
      }
    },
    createdFrom: undefined
  };

  return addProjectionsToBasket([itemToProject], basket, reportCriteriaShapes, reportCriteriaComparisons, reportCriteriaXAxisId);
}

function addProjectionsToBasket(itemsToProject, basket, reportCriteriaShapes = [], reportCriteriaComparisons = [], reportCriteriaXAxisId = undefined) {
  if (itemsToProject.length === 0)
    return { updatedBasket: basket, hasChanged: false };

  let newBasketItems = [];

  if (reportCriteriaShapes && reportCriteriaShapes.length > 0) {
    itemsToProject.forEach(basketItem => {
      reportCriteriaShapes.forEach((shape) => {
        newBasketItems.push(createNewProjectedBasketItem({ basketItem, shape, createdFrom: basketItem.createdFrom }));
      });
    });

    itemsToProject = newBasketItems;
  }

  if (reportCriteriaComparisons && reportCriteriaComparisons.length > 0) {
    newBasketItems = [];
    itemsToProject.forEach(basketItem => {
      reportCriteriaComparisons.forEach((comparisonSetting) => {
        newBasketItems.push(createNewProjectedBasketItem({ basketItem, comparisonSetting, createdFrom: basketItem.createdFrom }));
      });
    });

    itemsToProject = newBasketItems;
  }

  if (newBasketItems.length === 0) {
    newBasketItems = [...itemsToProject];
  }

  // xAxis projection
  if (reportCriteriaXAxisId) {
    const xAxisTimeSeriesCollection = basket.filter(ts => ts.identityId === reportCriteriaXAxisId);
    const response = [...basket];

    xAxisTimeSeriesCollection.forEach(xAxisTs => {
      newBasketItems.filter(ts => areComparisonsEqual(ts, xAxisTs)).forEach(ts => {
        response.push(enrichBasketItem({
          ...ts,
          xAxisKey: xAxisTs.key,
          isXAxis: false,
          nonXAxisStyle: ts.nonXAxisStyle ?? getNonXAxisStyles(ts),
          ...getXAxisStyles(ts),         
          key: mapToIdentityKey(ts, response),
        }));
      });
    });

    return { updatedBasket: response, hasChanged: true };
  }
  else {
    const response = [...basket];
    newBasketItems.forEach(newTs => {
      response.push(enrichBasketItem({
        ...newTs,
        key: mapToIdentityKey(newTs, response),
        color: getNextUserColour()
      }));
    });

    return { updatedBasket: response, hasChanged: true };
  }
}

export function projectTopLevelSettingsToBasket({ basket, shapes = [], comparisons = [], xAxisId = undefined }) {
  const projectableBasket = [...basket];
  const basketOrder = basket.map(({ identityId, name, key }) => ({ identityId, name, key }));

  /*
    Create a collection of items to project that have no customisations

    Basket before:
    12345 (Peak)
    12345 (OffPeak)
    67891 (Peak)
    67891 (OffPeak)

    Items to project after:
    12345
    67891
  */
  let itemsToProject = [];
  projectableBasket.forEach(basketItem => {
    // Create a basic basket item as copy of the projectable item
    basketItem = { ...basketItem };

    if (basketItem.nonXAxisStyle)
      Object.assign(basketItem, basketItem.nonXAxisStyle);

    // Remove any customisations such as shapes, comparisons etc
    delete basketItem.nonXAxisStyle;
    delete basketItem.isXAxis;
    delete basketItem.xAxisKey;
    delete basketItem.isArray;
    delete basketItem.shape;
    delete basketItem.comparisonSettings;
    if (basketItem.windowType === 'comparison')
      delete basketItem.windowType;

    // Only add it to the items to project if it is unique
    const dataKey = mapToDataKey(basketItem);
    if (!itemsToProject.some(kv => kv.key === dataKey)) {
      // if the derivation data has no instance id then asign one
      if (basketItem.derivationData && !basketItem.derivationData.id) {
        basketItem.derivationData.id = guid();
      }

      itemsToProject.push({ key: dataKey, value: basketItem })
    }
  });

  itemsToProject = itemsToProject.map(kv => kv.value);

  let newBasketItems = [];
  /*
   Ceate all shapes

   Shapes = Peak, OffPeak

   Basket before:
   12345 (Peak)
   12345 (Weekends)
   67891 (Peak)
   67891 (Weekends)

   Items to project after:
   12345 (Peak)
   12345 (OffPeak)
   67891 (Peak)
   67891 (OffPeak)
 */
  if (shapes.length > 0) {
    itemsToProject.forEach(basketItem => {
      shapes.forEach((shape) => {
        newBasketItems.push(createNewProjectedBasketItem({ basketItem, shape, createdFrom: basketItem.createdFrom }));
      });
    });

    itemsToProject = newBasketItems;
  }

  /*
    Create all comparisons

    Comparisons = 2018,2020

    Basket before:
    12345 (Peak)
    12345 (OffPeak)
    67891 (Peak)
    67891 (OffPeak)

    Items to project after:
    12345 (Peak) 2018
    12345 (Peak) 2020
    12345 (OffPeak) 2018
    12345 (OffPeak) 2020
    67891 (Peak) 2018
    67891 (Peak) 2020
    67891 (OffPeak) 2018
    67891 (OffPeak) 2020
  */
  if (comparisons && comparisons.length > 0) {
    newBasketItems = [];
    itemsToProject.forEach(basketItem => {
      comparisons.forEach((comparisonSetting) => {
        newBasketItems.push(createNewProjectedBasketItem({ basketItem, comparisonSetting, createdFrom: basketItem.createdFrom }));
      });
    });
  }

  if (newBasketItems.length === 0) {
    newBasketItems = [...itemsToProject];
  }

  // xAxis projection
  if (xAxisId) {
    /*
      Associate xAxis items

      xAxis = 67891

      Basket before:
      12345 2018
      12345 2020
      67891 2018
      67891 2020
      3345 2018
      3345 2020

      Items to project after:
      67891 2018
      vs 12345 2018
      vs 3345 2018
      67891 2020
      vs 12345 2020
      vs 3345 2020
    */
    const xAxisTimeSeriesCollection = newBasketItems.filter(ts => ts.identityId === xAxisId);
    const nonXAxisTimeSeriesCollection = newBasketItems.filter(ts => ts.identityId !== xAxisId);
    const xAxisBasketItems = [];

    xAxisTimeSeriesCollection.forEach(xAxisTs => {
      xAxisTs.isXAxis = true;
      xAxisTs.key = mapToIdentityKey(xAxisTs, xAxisBasketItems);
      xAxisBasketItems.push(xAxisTs);

      nonXAxisTimeSeriesCollection.forEach(ts => {
        // Only associate where comparison details match
        if (!areComparisonsEqual(ts, xAxisTs))
          return;

        xAxisBasketItems.push(enrichBasketItem({
          ...ts,
          xAxisKey: xAxisTs.key,
          isXAxis: false,
          nonXAxisStyle: ts.nonXAxisStyle ?? getNonXAxisStyles(ts),
          ...getXAxisStyles(ts),
          key: mapToIdentityKey(ts, xAxisBasketItems)
        }));
      });
    });

    return xAxisBasketItems;
  }
  else // Non xAxis path
  {
    let existingDataKeys = basket.map(ts => ({ key: ts.key, dataKey: mapToDataKey(ts) }));

    // Revert xAxis properties before building keys so that we can uniquely identify them against previous xAxis items
    newBasketItems.forEach(ts => {
      if (ts.isXAxis || ts.xAxisKey) {
        ts.nameStyle = 'default';
        ts.name = ts.timeSeriesName;
        if (ts.nonXAxisStyle) {
          Object.assign(ts, ts.nonXAxisStyle);
          delete ts.nonXAxisStyle;
        }
      }

      delete ts.isXAxis;
      delete ts.xAxisKey;
    });

    const newDataKeys = newBasketItems.map(mapToDataKey);
    existingDataKeys.forEach(e => {
      if (!newDataKeys.some(k => k === e.dataKey))
        basket = basket.filter(ts => ts.key !== e.key);
    });

    // Retain the order of items in the basket
    const response = [...basket].sort(({ key: a }, { key: b }) => {
      return basketOrder.findIndex(i => i.key === a) - basketOrder.findIndex(i => i.key === b);
    });

    // Add new unique projections
    existingDataKeys = basket.map(mapToDataKey);
    newBasketItems.forEach(newTs => {
      const newDataKey = mapToDataKey(newTs);
      if (!existingDataKeys.some(k => k === newDataKey)) {
        response.push(enrichBasketItem({
          ...newTs,
          key: mapToIdentityKey(newTs, response),
          color: getNextUserColour()
        }));
      }
    });

    response.forEach(ts => {
      delete ts.isXAxis;
    });

    return response;
  }
}

export function rebuildDisplayNamesToState(state, stateKey) {
  const paths = stateKey === undefined ?
    {
      basket: ['workspace', 'timeseries'],
      tableSettings: ['workspace', 'tableSettings'],
      chartSeries: ['chart', 'series'],
      chartTimeSeries: ['chart', 'timeSeries'],
      table: ['table']
    } :
    {
      basket: ['tilesState', stateKey, 'workspace', 'timeseries'],
      tableSettings: ['tilesState', stateKey, 'workspace', 'tableSettings'],
      chartSeries: ['tilesState', stateKey, 'chart', 'chartSeries'],
      chartTimeSeries: ['tilesState', stateKey, 'chart', 'timeSeries'],
      table: ['tilesState', stateKey, 'table']
    };

  const basket = toJS(state.getIn(paths.basket));
  const chartSeries = toJS(state.getIn(paths.chartSeries));
  const chartTimeSeries = toJS(state.getIn(paths.chartTimeSeries));
  rebuildBasketItemDisplayNames({ basket, chartSeries, chartTimeSeries });

  state = state
    .setIn(paths.basket, fromJS(basket))
    .setIn(paths.chartSeries, fromJS(chartSeries))
    .setIn(paths.chartTimeSeries, fromJS(chartTimeSeries));

  const tableSettings = toJS(state.getIn(paths.tableSettings, {}));

  const isTableHorizontal = tableSettings.displayData === 'horizontal';
  if (isTableHorizontal) {
    basket.forEach((basketItem, rowIndex) => {
      if (state.hasIn([...paths.table, 'data', rowIndex])) {
        state = state.setIn([...paths.table, 'data', rowIndex, 'title', 'value'], getTableHeaderName(basketItem.identityId, basketItem.name, tableSettings.headerType));
      }
    });
  }
  else {
    basket.forEach((basketItem, index) => {
      const headerIndex = index + 1; // include date column
      if (state.hasIn([...paths.table, 'headers', headerIndex])) {
        state = state.setIn([...paths.table, 'headers', headerIndex, 'title'], getTableHeaderName(basketItem.identityId, basketItem.name, tableSettings.headerType));
      }
    });
  }

  return state;
}

export function getTableHeaderName(identityId, name, headerType) {
  switch (headerType) {
    case 'both': return `${identityId}: ${name}`;
    case 'name': return `${name}`;
    default: return `${identityId}`;
  }
}

function rebuildBasketItemDisplayNames({ basket, chartSeries, chartTimeSeries }) {
  assertType({
    basket : PropTypes.array.isRequired,
    chartSeries : PropTypes.array.isRequired,
    chartTimeSeries : PropTypes.array.isRequired
  }, {basket, chartSeries, chartTimeSeries});

  const isXAxisTimeSeriesSet = basket.some(ts => ts.isXAxis);

  basket.forEach(basketItem => {
    let name = basketItem.timeSeriesName;

    if (basketItem.nameStyle === 'default' || !basketItem.nameStyle) {
      if (isXAxisTimeSeriesSet && !basketItem.isXAxis)
        name = `vs ${name}`;
    }

    if (basketItem.nameStyle === 'custom')
      name = basketItem.customName;

    if (basketItem.nameStyle === 'expression')
      name = basketItem.expressionName;

    basketItem.name = name;

    if (chartSeries) {
      const cs = chartSeries.find(c => `${c.id}` === `${basketItem.key}`);
      if (cs)
        cs.name = name;
    }

    if (chartTimeSeries) {
      const cts = chartTimeSeries.find(c => `${c.key}` === `${basketItem.key}`);
      if (cts)
        cts.name = name;
    }
  });

  if (isXAxisTimeSeriesSet) {
    if (chartSeries) {
      chartSeries.forEach(cs => {
        const basketItem = basket.find(ts => `${ts.key}` === `${cs.id}`);
        if (basketItem && !basketItem.isXAxis) {
          const xTs = basket.find(ts => ts.key === basketItem.xAxisKey);
          if (xTs) {
            cs.name = `${getBasketItemDisplayName(xTs)} vs ${getBasketItemDisplayName(basketItem)}`;
          }
          else {
            cs.name = `[UNKNOWN] vs ${getBasketItemDisplayName(basketItem)}`;
          }
        }
      });
    }

    if (chartTimeSeries) {
      chartTimeSeries.forEach(cs => {
        const basketItem = basket.find(ts => `${ts.key}` === `${cs.key}`);
        if (basketItem && !basketItem.isXAxis) {
          const xTs = basket.find(ts => ts.key === basketItem.xAxisKey);
          if (xTs) {
            cs.name = `${getBasketItemDisplayName(xTs)} vs ${getBasketItemDisplayName(basketItem)}`;
          }
          else {
            cs.name = `[UNKNOWN] vs ${getBasketItemDisplayName(basketItem)}`;
          }
        }
      });
    }
  }
}

function areForecastsEqual(tsA, tsB) {
  if (tsA.asAtType !== tsB.asAtType)
    return false;

  if (tsA.asAtType === 'asAt')
    if (tsA.asAt !== tsB.asAt)
      return false;

  if (tsA.asAtType === 'adHocAsAt')
    if (tsA.asAt !== tsB.asAt)
      return false;

  if (tsA.asAtType === 'relativeAsAt')
    if (tsA.relativeAsAtDate !== tsB.relativeAsAtDate)
      return false;

  if (tsA.asAtType === 'forecastOffset')
    if (tsA.forecastOffset !== tsB.forecastOffset || tsA.cutoffTime !== tsB.cutoffTime)
      return false;

  return true;
}

export function mapToTimeSeries(basketTimeSeries) {
  return {
    id: basketTimeSeries.identityId,
    identityId: basketTimeSeries.identityId,
    name: basketTimeSeries.sourceTimeSeriesName ?? basketTimeSeries.timeSeriesName,
    timeSeriesName: basketTimeSeries.timeSeriesName,
    unit: basketTimeSeries.unit,
    dataType: basketTimeSeries.dataType,
    queryCount: basketTimeSeries.queryCount,
    granularityType: basketTimeSeries.granularityType,
    styleExtended: basketTimeSeries.styleExtended,
    granularity: basketTimeSeries.granularity,
    granularityFrequency: basketTimeSeries.granularityFrequency,
    sourceTimeZoneId: basketTimeSeries.sourceTimeZoneId,
    derivationType: basketTimeSeries.derivationType,
    style: basketTimeSeries.style,
    sourceId: basketTimeSeries.sourceId,
    source: basketTimeSeries.source,
    lastUpdatedUtc: basketTimeSeries.lastUpdatedUtc,
    derivationData: basketTimeSeries.derivationData,
    key: basketTimeSeries.key,
    dynamicWorkspace: basketTimeSeries.dynamicWorkspace
  };
}

export function mapSchemaName(timeSeriesSchemas, selectedSearchSchema) {
  if (!selectedSearchSchema || selectedSearchSchema === '_legacy')
    return undefined;

  if (timeSeriesSchemas && timeSeriesSchemas.indexOf(selectedSearchSchema) >= 0)
    return selectedSearchSchema;

  return undefined;
}

export function mapToBasketTimeSeries(timeSeries, defaultNameStyle, selectedSearchSchema, basket) {
  let ts = {
    id: timeSeries.id,
    identityId: timeSeries.identityId || timeSeries.id,
    key: timeSeries.key ?? mapToIdentityKey(timeSeries, basket),
    sourceTimeSeriesName: timeSeries.name,
    timeSeriesName: timeSeries.name,
    style: timeSeries.style,
    dataType: timeSeries.dataType,
    source: timeSeries.source,
    derivationType: timeSeries.derivationType,
    granularity: timeSeries.granularity,
    granularityFrequency: timeSeries.granularityFrequency,
    sourceTimeZoneId: timeSeries.sourceTimeZoneId,
    queryCount: timeSeries.queryCount,
    unit: timeSeries.unit,
    firstDataPointUtc: timeSeries.firstDataPointUtc,
    lastDataPointUtc: timeSeries.lastDataPointUtc,
    lastUpdatedUtc: timeSeries.lastUpdatedUtc,
    timestampUtc: timeSeries.timestampUtc,
    infoType: timeSeries.infoType || '',
    infoMessage: timeSeries.infoMessage || '',
    derivationData: timeSeries.derivationData,

    // display name
    name: timeSeries.name, // 'name' may change depending on the nameStyle - 'timeSeriesName' never changes
    nameStyle: timeSeries.nameStyle ?? defaultNameStyle ?? 'default',
    customName: timeSeries.customName ?? '',
    expressionName: timeSeries.expressionName ?? '',
    schemaName: mapSchemaName(timeSeries.schemas, selectedSearchSchema),
    scenarioOverrides: timeSeries.scenarioOverrides ?? [],
    complexScenario: timeSeries.complexScenario,

    windowType: timeSeries.windowType,
    window: timeSeries.window,
    // asAt: timeSeries.asAt ?? '',
    // asAtType: timeSeries.asAtType,
    seriesStyle: timeSeries.seriesStyle,

    // visual styling
    type: timeSeries.type || 'line',
    dashStyle: timeSeries.dashStyle || 'solid',
    stacking: timeSeries.stacking || '',
    lineWidth: timeSeries.lineWidth || 1,
    color: timeSeries.color || getNextUserColour(),
    yAxis: timeSeries.yAxis || 0,
    isDisabled: timeSeries.isDisabled || false
  };

  ts.highChartSettings = getHighchartsPropertiesFromTimeSeries(ts);

  if (timeSeries.asAt)
    ts.asAt = timeSeries.asAt;

  if (timeSeries.asAtType)
    ts.asAtType = timeSeries.asAtType;

  return ts;
}

export function mapEditTimeSeriesToBasketTimeSeries(editTimeSeries) {
  let response = { ...editTimeSeries };

  if (response.asAtType === 'forecastOffset') {
    response.forecastOffset = (response.forecastOffset ?? '0D').replace('D', '');

    if (response.cutoffTime !== undefined && response.cutoffTime.length === 2)
      response.cutoffTime += ':00';
  }

  if (response.asAtType === 'relativeAsAt') {
    response.relativeAsAtDate ??= {
      period: '0D',
      cutoffTime:''};
  }

  return response;
}

export function mapToIdentityKey(timeSeries, timeSeriesCollections) {
  const newKey = `${timeSeries.identityId || timeSeries.id}`;

  let matches = [
    ...timeSeriesCollections.filter(t => t.key.startsWith(newKey)).map(t => t.key),
    ...timeSeriesCollections.filter(t => t.createdFrom && t.createdFrom.startsWith(newKey)).map(t => t.createdFrom)];

  matches = matches.map(m => m[0] === '-' ? m.substring(1) : m);
  matches = [...new Set(matches)];

  if (matches.length > 0) {
    matches = matches.filter(m => m.indexOf('-') > 0)
      .map(m => Number(m.split('-')[1]))
      .sort((a, b) => a - b);

    if (matches.length === 0)
      return `${newKey}-${1}`;

    let keyIndex = Number(matches[matches.length - 1])
    keyIndex++;
    return `${newKey}-${keyIndex}`;
  }

  return newKey;
}

function mapToDataKey(ts, excludeIdentity) {
  const key = [];

  if (excludeIdentity === true) {
    key.push(`-`);
  }
  else {
    key.push(`${ts.identityId ?? ts.id ?? ''}`);
    key.push(`[${ts.createdFrom ?? ''}]`);
  }

  if (ts.lens) key.push(`lens:${ts.lens}`);
  if (ts.conversionUnit) key.push(`conversionUnit:${ts.conversionUnit}`);
  if (ts.factor) key.push(`factor:${ts.factor}`);
  if (ts.operation) key.push(`op:${ts.operation}`);

  if (ts.asAtType) {
    key.push(`forecast`);
    if (ts.asAtType === 'asAt') key.push(`a:${ts.asAt}`);
    if (ts.asAtType === 'adHocAsAt') key.push(`aa:${ts.asAt}`);
    if (ts.asAtType === 'relativeAsAt') key.push(`r:${ts.relativeAsAtDate.period}@${ts.relativeAsAtDate.cutoffTime}`);
    if (ts.asAtType === 'forecastOffset') key.push(`f:${ts.forecastOffset}@${ts.cutoffTime}`);
  }

  if (ts.shape) key.push(`shape:${JSON.stringify(ts.shape)}`); // shape name

  if (ts.comparisonSettings) {
    key.push(`comparison`);
    key.push(`cmode:${ts.comparisonSettings.mode}`);
    key.push(`cw:${mapToComparisonDisplay(ts.comparisonSettings).description}`);
  }

  if (ts.xAxisKey !== undefined) key.push(`x:${ts.xAxisKey}`);

  if (ts.dynamicWorkspace) {
    key.push(`dwfskey:${ts.dynamicWorkspace.key}`);
  }

  return key.join('|');
}

function areComparisonsEqual(tsA, tsB) {
  function mapToComparisonHashKey(ts) {
    const key = [];

    if (ts.comparisonSettings) {
      key.push(`comparison`);
      key.push(`cmode:${ts.comparisonSettings.mode}`);
      key.push(`cw:${mapToComparisonDisplay(ts.comparisonSettings).description}`);
    }

    if (ts.xAxisKey !== undefined)
      key.push(`x:${ts.xAxisKey}`);

    return key.join('|');
  }

  return mapToComparisonHashKey(tsA) === mapToComparisonHashKey(tsB);
}

export function mapToComparisonDisplay(comparisonSetting) {
  let name = '';
  let description = '';

  if (comparisonSetting.windowType === 'aggregate' && comparisonSetting.aggregated) {
    const aggregated = comparisonSetting.aggregated;

    if (aggregated.windowType === 'range') {
      const fromDate = aggregated.range.relFromDate ?? dateToComparisonModeName(comparisonSetting.mode, aggregated.range.absFromDate);
      const toDate = aggregated.range.relToDate ?? dateToComparisonModeName(comparisonSetting.mode, aggregated.range.absToDate);
      if (fromDate === toDate) {
        name = `(${fromDate} ${aggregated.operation})`;
        description = `(${fromDate} ${aggregated.operation})`;
      }
      else {
        name = `(${fromDate} -> ${toDate} ${aggregated.operation})`;
        description = `(${fromDate} to ${toDate} ${aggregated.operation})`;
      }
    }

    if (!aggregated.windowType || aggregated.windowType === 'individual') {
      let dates = aggregated.dates.map(d => d.window.relFromDate ?? dateToComparisonModeName(comparisonSetting.mode, d.window.absFromDate));
      if (dates.length === 1) {
        name = `(${dates[0]} ${aggregated.operation})`;
        description = `(${dates[0]} ${aggregated.operation})`;
      }
      else {
        name = `(${dates[0]}..${dates[dates.length - 1]} ${aggregated.operation})`;
        description = `(${dates.join(', ')} ${aggregated.operation})`;
      }
    }
  }

  if (comparisonSetting.windowType === 'simple' && comparisonSetting.window) {
    if (comparisonSetting.window.relFromDate) {
      name = comparisonSetting.window.relFromDate;
      description = comparisonSetting.window.relFromDate;
    }
    else {
      name = dateToComparisonModeName(comparisonSetting.mode, comparisonSetting.window.absFromDate);
      description = dateToComparisonModeName(comparisonSetting.mode, comparisonSetting.window.absFromDate);
    }
  }

  return { name, description };
}

export function mergeTimeSeriesCollectionToBasket({ basket, timeSeriesCollection, timeSeriesExpressionNames, preventNameOverride = false }) {
  return basket.map(basketItem => {
    const timeSeries = timeSeriesCollection.find(ts => ts.key === basketItem.key);

    if (timeSeries) {
      basketItem = { ...basketItem };
      basketItem.timeSeriesName = timeSeries.name;
      basketItem.preventNameOverride = preventNameOverride;
      basketItem.style = timeSeries.style;
      basketItem.source = timeSeries.source;
      basketItem.dataType = timeSeries.dataType;
      basketItem.granularity = timeSeries.granularity;
      basketItem.sourceTimeZoneId = timeSeries.sourceTimeZoneId;
      basketItem.lastUpdatedUtc = timeSeries.updatedDateTime;
      basketItem.unit = timeSeries.unit;
      basketItem.information = timeSeries.information;
      basketItem.warning = timeSeries.warning;
      basketItem.error = timeSeries.error;
      basketItem.responseLens = timeSeries.lens;

      basketItem.infoType = '';
      basketItem.infoMessage = '';
      if (timeSeries.information && timeSeries.information.length) {
        basketItem.infoType = 'information';
        basketItem.infoMessage = timeSeries.information.join('\n');
      }

      if (timeSeries.warning && timeSeries.warning.length) {
        basketItem.infoType = 'warning';
        basketItem.infoMessage = timeSeries.warning.join('\n');
      }

      if (timeSeries.error && timeSeries.error.length) {
        basketItem.infoType = 'error';
        basketItem.infoMessage = timeSeries.error.join('\n');
      }

      if (timeSeriesExpressionNames) {
        const { templatedName } = timeSeriesExpressionNames.find(t => t.key === timeSeries.key) ?? {};

        basketItem.expressionName = templatedName ?? '';
      }
    }

    return basketItem;
  });
}

function createNewProjectedBasketItem({ basketItem, comparisonSetting, shape, createdFrom }) {
  let newBasketItem = {
    ...basketItem,
    createdFrom: createdFrom,
    preventNameOverride: false
  };

  // if the derivation data has no instance id then asign one
  if (newBasketItem.derivationData && !newBasketItem.derivationData.id) {
    newBasketItem.derivationData.id = guid();
  }

  if (comparisonSetting) {
    if (comparisonSetting.windowType === 'simple') {
      newBasketItem.windowType = 'comparison';
      newBasketItem.comparisonSettings = {
        name: `${mapToComparisonDisplay(comparisonSetting).name}`,
        mode: comparisonSetting.mode,
        plotOrigin: comparisonSetting.plotOrigin ? {
          mode: comparisonSetting.plotOrigin.mode,
          absOriginDate: comparisonSetting.plotOrigin.absOriginDate,
          relOriginDate: comparisonSetting.plotOrigin.relOriginDate,
        } : undefined,
        windowType: comparisonSetting.windowType,
        window: comparisonSetting.window ? {
          absFromDate: comparisonSetting.window.absFromDate,
          absToDate: comparisonSetting.window.absToDate,
          relFromDate: comparisonSetting.window.relFromDate,
          relToDate: comparisonSetting.window.relToDate
        } : undefined
      }
    };

    if (comparisonSetting.windowType === 'aggregate') {
      newBasketItem.windowType = 'comparison';
      newBasketItem.comparisonSettings = {
        name: `${mapToComparisonDisplay(comparisonSetting).name}`,
        mode: comparisonSetting.mode,
        plotOrigin: comparisonSetting.plotOrigin ? {
          mode: comparisonSetting.plotOrigin.mode,
          absOriginDate: comparisonSetting.plotOrigin.absOriginDate,
          relOriginDate: comparisonSetting.plotOrigin.relOriginDate,
        } : undefined,
        windowType: comparisonSetting.windowType,
        aggregated: comparisonSetting.aggregated ? {
          windowType: comparisonSetting.aggregated.windowType,
          operation: comparisonSetting.aggregated.operation,
          range: comparisonSetting.aggregated.range ? {
            absFromDate: comparisonSetting.aggregated.range.absFromDate,
            absToDate: comparisonSetting.aggregated.range.absToDate,
            relFromDate: comparisonSetting.aggregated.range.relFromDate,
            relToDate: comparisonSetting.aggregated.range.relToDate,
          } : undefined,
          dates: comparisonSetting.aggregated.dates // array of windows
        } : undefined
      };
    }
  }

  if (shape) {
    newBasketItem.shape = shape;
  }

  newBasketItem = enrichBasketItem(newBasketItem);
  return newBasketItem;
}

function enrichBasketItem(ts){
  ts.highChartSettings = deepmerge.all([
    (ts.highChartSettings ?? {}),
    getHighchartsPropertiesFromTimeSeries(ts)]
  );

  return ts;
}

function getBasketItemDisplayName(basketItem) {
  switch (basketItem.nameStyle ?? 'default') {
    case 'custom':
      return basketItem.customName;
    case 'expression':
      return basketItem.expressionName;
    case 'default':
    default:
      return basketItem.timeSeriesName;
  }
}

export function getTimeSeriesName(timeSeries) {
  switch (timeSeries.nameStyle ?? 'default') {
    case 'custom':
      return timeSeries.customName;
    case 'expression':
      return timeSeries.expressionName;
    case 'default':
    default:
      return timeSeries.timeSeriesName;
  }
}

export const mapSeriesOptions = (s, data, index) => {
  let options = {
    name: s.name,
    tooltip: {
      valueSuffix: s.unit
    },
    id: s.key,
    index: index
  }

  if (data) {
    options.data = data; 
  }

  return updateSeriesOptions(options, s);
}

export const updateSeriesOptions = (options, s) => {
  const highChartSettings = s.highChartSettings ?? {};

  options.name = getTimeSeriesName(s);
  options.type = s.type;
  options.color = s.color;
  options.visible = !s.isDisabled;
  options.stacking = s.stacking ?? '';
  options.yAxis = s.yAxis ?? 0;
  options.lineWidth = s.lineWidth ?? 1;
  options.dashStyle = s.dashStyle;
  options.connectNulls = s.connectNulls === true;

  if (highChartSettings.hasOwnProperty('fillColor') || highChartSettings.hasOwnProperty('fillOpacity')){
    // use the highchart settings
    options.fillColor = highChartSettings.fillColor;
    options.fillOpacity = highChartSettings.fillOpacity;
  } else {
    options.fillColor = {
      linearGradient: {
        x1: 0, y1: 0, x2: 0, y2: 1
      },
      stops: [
        [0, s.color], [1, toHighChartsColour(s.color)]
      ]
    };
  }

  if (s.marker)
    options.marker = s.marker;
    
  // merge the highchart json properties over the options
  Object.assign(options, deepmerge.all([options, highChartSettings]));

  // ensure yAxis is a number if set
  if (options.yAxis !== null && options.yAxis !== undefined)
    options.yAxis = Number(options.yAxis);

  // ensure lineWidth is a number if set
  if (options.lineWidth !== null && options.lineWidth !== undefined)
    options.lineWidth = Number(options.lineWidth);

  // special style of our own so we can hide the line https://api.highcharts.com/class-reference/Highcharts#.DashStyleValue
  if (options.dashStyle === 'none')
    options.lineWidth = 0;

  return options;
}

export function getHighchartsPropertiesFromTimeSeries(s) {
  const response = {};
  response.type = s.type;
  response.color = s.color;
  response.visible = !s.isDisabled;  
  if (s.stacking) response.stacking = s.stacking;
  response.yAxis = s.yAxis;
  response.lineWidth = s.lineWidth;
  response.dashStyle = s.dashStyle;
  response.connectNulls = s.connectNulls === true;
  if (s.marker) response.marker = s.marker;
  return response;
}

export function getTimeSeriesPropertiesFromHighchartsJson(s) {
  const response = {};
  response.type = s.type;
  response.color = s.color;
  response.isDisabled = !s.visible;
  if (s.stacking) response.stacking = s.stacking;
  response.yAxis = s.yAxis;
  response.lineWidth = s.lineWidth;
  response.dashStyle = s.dashStyle;
  response.connectNulls = s.connectNulls === true;
  if (s.marker) response.marker = s.marker;
  return response;
}

function getGranularityFromLens(lens) {
  switch (lens) {
    case 'QuarterHour':
      return { granularityType: 'Minute', granularityFrequency: 15 };
    case 'HalfHour':
      return { granularityType: 'Minute', granularityFrequency: 30 };
    case 'Hour':
      return { granularityType: 'Hour', granularityFrequency: 1 };
    case 'Day':
      return { granularityType: 'Day', granularityFrequency: 1 };
    case 'Week':
      return { granularityType: 'Day', granularityFrequency: 7 };
    case 'Month':
      return { granularityType: 'Month', granularityFrequency: 1 };
    case 'Quarter':
      return { granularityType: 'Month', granularityFrequency: 3 };
    case 'GasSeason':
      return { granularityType: 'Month', granularityFrequency: 6 };
    case 'GasYear':
    case 'Year':
      return { granularityType: 'Month', granularityFrequency: 12 };
    default:
      return undefined;
  }
}

function getNonXAxisStyles(ts){
  const reponse = { 
    ...getHighchartsPropertiesFromTimeSeries(ts), 
    highChartSettings: ts.highChartSettings ?? {}
  };

  if (!reponse.marker) reponse.marker = {enabled:false};
  return reponse;
}

function getXAxisStyles(ts){
  return {
    type: 'scatter',
    dashStyle: 'none',
    marker: deepmerge.all([(ts.marker ?? {}),{ enabled: true }]),
    color: getNextUserColour()
  }
}