import React, { useEffect, useRef, Fragment } from 'react';
import LoadingIcon from '../../../shared/LoadingIcon';
import IdentityId from '../../../shared/IdentityId2';
import Tooltip from '../../../shared/Tooltip';

function VariantsEditorTableEditRow({ variant, lenses, timeZoneIds, operations, shapes, setValue, onSave, onCancel, hasError = false }) {
  const { identityId: variantId, lens, timeZoneId, operation, shapeName, isSaving, errorMessage } = variant;

  const focusRef = useRef();

  useEffect(() => {
    if (focusRef && focusRef.current) focusRef.current.focus();
  }, [focusRef]);

  const isValidForm = !!lens && !!timeZoneId && !!operation;

  const { body: errorBody } = errorMessage || {};

  return (
    <tr>
      { !!hasError && (
        <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>
          <Tooltip placement='right' title={<div style={{ maxWidth: '400px', textAlign: 'left', wordBreak: 'break-word' }}>
              { errorBody }
            </div>}>
            <i className='fas fa-exclamation-triangle fa-fw text-danger' style={{ fontSize: '10px' }} />
          </Tooltip>
        </td>
      ) }
      <td />
      <td style={{ verticalAlign: 'middle' }}>
        { !!variantId && <IdentityId isLeftDrop>{ variantId }</IdentityId> }
      </td>
      <td />
      <td>Variant</td>
      <td>
        <select ref={ focusRef } className='custom-select custom-select-sm'
                value={ lens || '' }
                onChange={ e => setValue('lens', e.target.value) }>
          <option value='' />
          { lenses.map(i => <option key={ i } value={ i }>{ i }</option>) }
        </select>
      </td>
      
      <td>
        <select className='custom-select custom-select-sm'
                value={ timeZoneId || '' }
                onChange={ e => setValue('timeZoneId', e.target.value) }>
          <option value='' />
          { timeZoneIds.map(i => <option key={ i } value={ i }>{ i }</option>) }
        </select>
      </td>

      <td>
        <select className='custom-select custom-select-sm'
                value={ operation || '' }
                onChange={ e => setValue('operation', e.target.value) }>
          <option value='' />
          { operations.map(i => <option key={ i } value={ i }>{ i }</option>) }
        </select>
      </td>

      <td>
        <select className='custom-select custom-select-sm'
                value={ shapeName || '' }
                onChange={ e => setValue('shapeName', e.target.value) }>
          <option value='' />
          { shapes.map(i => <option key={ i.name } value={ i.name }>{ i.name }</option>) }
        </select>
      </td>
      
      <td style={{width: '10em'}}>
        { isSaving ? (
          <LoadingIcon width='1.25em' height='1.25em' stroke='#333' />
        ) : (
          <Fragment>
            <button type='submit' className='btn btn-primary' onClick={ () => onSave(variant) } disabled={ !isValidForm }>Save</button>
            <button type='button' className='btn btn-outline-secondary ml-1' onClick={ () => onCancel(variantId) }>Cancel</button>
          </Fragment>
        ) }
      </td>
    </tr>
  );
}

function VariantsEditorTableRow({ variant, hasError = false }) {
  const { identityId: variantId, name, derivationType, granularity, timeZoneId, operation, shapeName } = variant;
  const { granularityType, granularityFrequency } = granularity || {};

  return (
    <tr>
      { hasError && <td /> }
      <td />
      <td style={{ verticalAlign: 'middle' }}>
        { !!variantId && <IdentityId isLeftDrop name={name}>{ variantId }</IdentityId> }
      </td>
      <td style={{ verticalAlign: 'middle' }}>{ name }</td>
      <td style={{ verticalAlign: 'middle' }}>{ derivationType }</td>
      <td style={{ verticalAlign: 'middle' }}>{ `${granularityFrequency || ''} ${granularityType || ''}` }</td>
      <td style={{ verticalAlign: 'middle' }}>{ timeZoneId }</td>
      <td style={{ verticalAlign: 'middle' }}>{ operation }</td>
      <td style={{ verticalAlign: 'middle' }}>{ shapeName }</td>
      <td />
    </tr>
  );
}

export default function VariantsEditorTable({ referenceData, variants, variantEdit, cancelVariantEdit, setVariantValue, saveVariant }) {
  const {
    lenses,
    timezones: timeZoneIds,
    operations,
    shapes
  } = referenceData;

  const hasError = !!variantEdit.errorMessage;

  return <div className='sticky-table h-100'>
    <table className='table table-sm'>
      <thead>
        <tr className='sticky-header'>
          { hasError && <th style={{ minWidth: '25px' }} /> }
          <th style={{ minWidth: '50px' }} />
          <th style={{ minWidth: '100px' }} className='text-center'>Id</th>
          <th style={{ minWidth: '350px' }}>Name</th>
          <th style={{ minWidth: '150px' }}>Derivation Type</th>
          <th style={{ minWidth: '150px' }}>Granularity</th>
          <th style={{ minWidth: '200px' }}>Timezone</th>
          <th style={{ minWidth: '100px' }}>Operation</th>
          <th style={{ minWidth: '140px' }}>Shape</th>
          <th style={{ width: '100%' }} />
        </tr>
      </thead>
      <tbody>
        { variants.map((v, vx) => (
          <VariantsEditorTableRow key={ v.identityId || v.key || vx }
                                  variant={ v }
                                  hasError={ hasError } />
        )) }
        { variantEdit.key && (
          <VariantsEditorTableEditRow variant={ variantEdit }
                                      lenses={ lenses }
                                      timeZoneIds={ timeZoneIds }
                                      operations={ operations }
                                      shapes={ shapes }
                                      setValue={ setVariantValue }
                                      onSave={ saveVariant }
                                      onCancel={ cancelVariantEdit }
                                      hasError={ hasError } />
        ) }
      </tbody>
    </table>
  </div>
}