import { fromJS } from 'immutable';
import {
  DASHBOARD_TILE_ANALYSIS_TABLE_REFRESH_COMPLETE,
  DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_STARTED,
  DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_COMPLETE
} from '../actions/dashboard-tiles-analysis-table';
import { toJS } from '../utility/immutable-utility';

export const dashboardTilesAnalysisTableReducer = {
  [DASHBOARD_TILE_ANALYSIS_TABLE_REFRESH_COMPLETE](state, action) {
    if (!action.data) {
      return state
      .deleteIn(['tilesState', action.stateKey, 'workspace']);
    }
    
    const dashboardTile = toJS(state.getIn(['tilesConfig', action.stateKey]), {});
    return state.setIn(['tilesConfig', action.stateKey], fromJS(dashboardTile))
                .setIn(['tilesState', action.stateKey, 'styles'], fromJS(action.data.styles))
                .setIn(['tilesState', action.stateKey, 'rows'], fromJS(action.data.rows));
  },
  [DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_STARTED](state, action) {
    return state.deleteIn(['workspaceSharedfolders']);
  },
  [DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_COMPLETE](state, action) {
    return state.setIn(['workspaceSharedfolders'], fromJS(action.data));
  }
};