export class DelayedExecution {
    constructor(delay = 500){
      this._delay = delay;
      this._timerId = undefined;
      this._func = undefined;
    }
  
    execute(func, ...args){
      if (this._timerId || !func){
        clearTimeout(this._timerId);
        this._timerId = undefined;
      }
      
      if (func){
        this._timerId = setTimeout(()=>{
          func(...args);
          this._timerId = undefined;
        }, this._delay);
      }
    }
  }