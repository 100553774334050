import React from 'react';
import Loading from '../../shared/Loading';
import { AutoRefresh } from '../../shared/AutoRefresh';

export default function QueueStatus({ isWizard, isLoading, loaders, subscriptions, refresh, lastRefreshed, replayDlq }) {
  function onReplay(queueType, l) {
    replayDlq(queueType, l.get('topicName'), l.get('subscriptionName'));
  }

  return (
    <Loading isLoading={isLoading} message='Loading...'>
      <div>
        <div className='d-flex p-2'>
          <button type='button' className='btn btn-primary text-nowrap mr-2' onClick={e => refresh()}>
            <i className='fas fa-sync-alt fa-fw' />
            <span className='px-2 d-none d-md-inline-block'>Refresh</span>
          </button>

          <AutoRefresh
            autoRefreshSchedule={2}
            onRefresh={() => refresh()}
            options={[
              { minutes: 1, text: 'Every 1 minute' },
              { minutes: 2, text: 'Every 2 minutes' },
              { minutes: 5, text: 'Every 5 minutes' },
              { minutes: 10, text: 'Every 10 minutes' }
            ]} />

          <div className='d-flex p-2'>
            <div className='text-info'>Last refreshed: {lastRefreshed ? lastRefreshed.format('DD-MMM-YYYY HH:mm:ss') : ''}</div>
          </div>
        </div>

        <label className='font-weight-bold ml-1'>Loaders</label>
        <table className='table table-sm table-striped'>
          <thead>
            <tr>
              <td>Name</td>
              <td>Sources</td>
              <td className='text-nowrap text-right'>Sessions</td>
              <td className='text-nowrap text-right'>Count</td>
              <td className='text-nowrap text-right' style={{width:'5em'}}>DLQs</td>
              <td style={{width:'5em'}}></td>
            </tr>
          </thead>

          <tbody>
            {loaders.map((l, ix) => {
              const deadLetterCount = l.get('deadLetterCount');
              return <tr key={ix} >
                <td>{l.get('name')}</td>
                <td>{l.get('sourceList')}</td>
                <td className='text-nowrap text-right pr-2'>{l.get('sessionCount')}</td>
                <td className='text-nowrap text-right pr-2'>{l.get('activeMessageCount')}</td>
                <td className={`text-nowrap text-right pr-2 ${deadLetterCount > 0 ? 'bg-danger text-white' : ''}`} >{deadLetterCount}</td>
                <td className='text-nowrap text-right p-0'>{isWizard && l.get('canReplay') && <button type='button' disabled={l.get('replaySubmitted')} className='btn btn-sm btn-secondary text-nowrap' onClick={() => { onReplay('loader', l) }}>Replay</button>}</td>
              </tr>
            })}
          </tbody>
        </table>

        <label className='font-weight-bold ml-1'>Subscriptions</label>
        <table className='table table-sm table-striped'>
          <thead>
            <tr>
              <td>Topic Name</td>
              <td>Subscription Name</td>
              <td className='text-nowrap text-right'>Count</td>
              <td className='text-nowrap text-right' style={{width:'5em'}}>DLQs</td>
              <td style={{width:'5em'}}></td>
            </tr>
          </thead>

          <tbody>
            {subscriptions.map((l, ix) => {
              const deadLetterCount = l.get('deadLetterCount');
              return <tr key={ix} >
                <td>{l.get('topicName')}</td>
                <td>{l.get('subscriptionName')}</td>
                <td className='text-nowrap text-right pr-2'>{l.get('activeMessageCount')}</td>
                <td className={`text-nowrap text-right pr-2 ${deadLetterCount > 0 ? 'bg-danger text-white' : ''}`}>{deadLetterCount}</td>
                <td className='text-nowrap text-right p-0'>{isWizard && l.get('canReplay') && <button type='button' disabled={l.get('replaySubmitted')} className='btn btn-sm btn-secondary text-nowrap' onClick={() => { onReplay('subscription', l) }}>Replay</button>}</td>
              </tr>
            })}
          </tbody>
        </table>

      </div>
    </Loading>
  );
}