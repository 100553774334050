import React from 'react';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import SchemasToolbar from './SchemasToolbar';
import Schema from './Schema';
import Loading from '../../../shared/Loading';

export default function Schemas({ allSchemaLookups, availableSchemas, identityId, identitySchemas, isLoading, schemaInEdit, selectedSchema,
                                  addSchema, loadSchemas, deleteIdentitySchema, editSchemaCategory, saveIdentitySchema, setSelectedSchema, startSchemaEdit, stopSchemaEdit,
                                  selectedLookup, setSelectedLookup, lookupValue, setLookupValue, saveLookup,
                                  copyFromIdentityId, copyCategoriesFrom, setCopyFromIdentityId }) {
  useDidMountEffect(() => loadSchemas(identityId));
  return (
    <Loading isLoading={ isLoading } message='Loading...'>
      <div className='w-100 h-100' style={{ overflowY: 'auto', flexGrow: 1 }}>
        <div className='mb-1'>
          <SchemasToolbar addSchema={addSchema}
                          availableSchemas={availableSchemas}
                          selectedSchema={selectedSchema}
                          setSelectedSchema={setSelectedSchema} />
          {identitySchemas.map((x, ix) => <Schema key={ix} allSchemaLookups={allSchemaLookups}
                                            identityId={identityId}
                                            categoryValues={x.searchSchemaCategoryValues}
                                            schema={x.searchSchema}
                                            schemaInEdit={schemaInEdit}
                                            deleteIdentitySchema={deleteIdentitySchema}
                                            editSchemaCategory={editSchemaCategory}
                                            saveIdentitySchema={saveIdentitySchema}
                                            startSchemaEdit={startSchemaEdit}
                                            stopSchemaEdit={stopSchemaEdit}
                                            selectedLookup={selectedLookup}
                                            setSelectedLookup={setSelectedLookup}
                                            lookupValue={lookupValue}
                                            setLookupValue={setLookupValue}
                                            saveLookup={saveLookup}
                                            copyFromIdentityId={copyFromIdentityId}
                                            copyCategoriesFrom={copyCategoriesFrom}
                                            setCopyFromIdentityId={setCopyFromIdentityId} /> )}
        </div>
      </div>
    </Loading>
  );
}