import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_URL } from '../config';
import { toJS } from '../utility/immutable-utility';
import { analysisRefreshTemplateNameExpressions } from './analysis';

export const analysisTimeSeriesVersionsInit = (key, operations) => (dispatch, getState) => {
  dispatch(analysisTimeSeriesVersionsInitBegin(key, operations));
  
  const state = getState(); // analysisTimeSeriesVersionsInitBegin will modify state so we have to get the state after
  const basket = toJS(state.getIn(['analysis', 'workspace', 'timeseries']), []);
  const timeSeries = basket.find(ts => ts.key === key);

  if (timeSeries.identityId <= 0){
    dispatch(analysisTimeSeriesVersionsInitComplete(undefined));
    return;
  }

  const timeseriesVersionSettings = toJS(state.getIn(['analysis', 'ui', 'timeseriesVersionSettings']), {});
  return authFetch(`${ANALYSIS_API_URL}/instances/delta/${timeSeries.identityId}?pageNumber=${timeseriesVersionSettings.asAts.page - 1}&pageSize=${timeseriesVersionSettings.asAts.pageSize | 25}`, {
    method: 'GET'
  })
    .then(response => response.json())
    .then(data => {
        dispatch(analysisTimeSeriesVersionsInitComplete(data));
    })
}

export const ANALYSIS_TIMESERIES_VERSIONS_INIT_BEGIN = 'ANALYSIS_TIMESERIES_VERSIONS_INIT_BEGIN';
export const analysisTimeSeriesVersionsInitBegin = createAction(ANALYSIS_TIMESERIES_VERSIONS_INIT_BEGIN, 'key', 'operations');

export const ANALYSIS_TIMESERIES_VERSIONS_INIT_COMPLETE = 'ANALYSIS_TIMESERIES_VERSIONS_INIT_COMPLETE';
export const analysisTimeSeriesVersionsInitComplete = createAction(ANALYSIS_TIMESERIES_VERSIONS_INIT_COMPLETE, 'data');

export const ANALYSIS_TIMESERIES_VERSIONS_SET_TAB = 'ANALYSIS_TIMESERIES_VERSIONS_SET_TAB';
export const analysisTimeSeriesVersionsSetTab = createAction(ANALYSIS_TIMESERIES_VERSIONS_SET_TAB, 'name');

export const analysisTimeSeriesVersionsSaveSettings = () => (dispatch, getState) => {
  dispatch(analysisTimeSeriesVersionsSaveSettingsExecute());
  dispatch(analysisRefreshTemplateNameExpressions());
}

export const ANALYSIS_TIMESERIES_VERSIONS_SAVE_SETTINGS = 'ANALYSIS_TIMESERIES_VERSIONS_SAVE_SETTINGS';
const analysisTimeSeriesVersionsSaveSettingsExecute = createAction(ANALYSIS_TIMESERIES_VERSIONS_SAVE_SETTINGS);

export const ANALYSIS_VERSION_SELECT_TOGGLE_SHOW_DEFAULT = 'ANALYSIS_VERSION_SELECT_TOGGLE_SHOW_DEFAULT';
export const analysisVersionSelectToggleShowDefault = createAction(ANALYSIS_VERSION_SELECT_TOGGLE_SHOW_DEFAULT);

export const ANALYSIS_VERSION_SELECT_TOGGLE_OPERATION = 'ANALYSIS_VERSION_SELECT_TOGGLE_OPERATION';
export const analysisVersionSelectToggleOperation = createAction(ANALYSIS_VERSION_SELECT_TOGGLE_OPERATION, 'operation');

// Instance AsAt
export const ANALYSIS_VERSION_SELECT_TOGGLE_ASAT = 'ANALYSIS_VERSION_SELECT_TOGGLE_ASAT';
export const analysisVersionSelectToggleAsAt = createAction(ANALYSIS_VERSION_SELECT_TOGGLE_ASAT, 'asAt');

export const analysisVersionSelectAsAtPageChange = (page) => (dispatch, getState) => {
  dispatch(analysisVersionSelectAsAtPageChangeBegin(page));
  const state = getState();

  const timeseriesVersionSettings = toJS(state.getIn(['analysis', 'ui', 'timeseriesVersionSettings']), {});
  return authFetch(`${ANALYSIS_API_URL}/instances/delta/${timeseriesVersionSettings.timeseries.identityId}?pageNumber=${timeseriesVersionSettings.asAts.page - 1}&pageSize=${timeseriesVersionSettings.asAts.pageSize | 25}`, {
    method: 'GET'
  })
    .then(response => response.json())
    .then(data => {
      dispatch(analysisVersionSelectAsAtPageChangeComplete(data));
    })
}

export const ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_BEGIN = 'ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_BEGIN';
export const analysisVersionSelectAsAtPageChangeBegin = createAction(ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_BEGIN, 'page');

export const ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_COMPLETE = 'ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_COMPLETE';
export const analysisVersionSelectAsAtPageChangeComplete = createAction(ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_COMPLETE, 'data');

// AdHoc As-Ats
export const ANALYSIS_VERSION_SELECT_ADD_ADHOC_ASAT = 'ANALYSIS_VERSION_SELECT_ADD_ADHOC_ASAT';
export const analysisVersionSelectAddAdHocAsAt = createAction(ANALYSIS_VERSION_SELECT_ADD_ADHOC_ASAT, 'adHocAsAt');

export const ANALYSIS_VERSION_SELECT_DELETE_ADHOC_ASAT = 'ANALYSIS_VERSION_SELECT_DELETE_ADHOC_ASAT';
export const analysisVersionSelectDeleteAdHocAsAt = createAction(ANALYSIS_VERSION_SELECT_DELETE_ADHOC_ASAT, 'adHocAsAt');

export const ANALYSIS_VERSION_SELECT_DELETE_ALL_ADHOC_ASATS = 'ANALYSIS_VERSION_SELECT_DELETE_ALL_ADHOC_ASATS';
export const analysisVersionSelectDeleteAllAdHocAsAts = createAction(ANALYSIS_VERSION_SELECT_DELETE_ALL_ADHOC_ASATS);

export const ANALYSIS_VERSION_SELECT_EDIT_ADHOC_ASAT_VALUE = 'ANALYSIS_VERSION_SELECT_EDIT_ADHOC_ASAT_VALUE';
export const analysisVersionSelectEditAdHocAsAtValue = createAction(ANALYSIS_VERSION_SELECT_EDIT_ADHOC_ASAT_VALUE, 'value');

// Relative As-Ats
export const ANALYSIS_VERSION_SELECT_ADD_RELATIVE_ASAT = 'ANALYSIS_VERSION_SELECT_ADD_RELATIVE_ASAT';
export const analysisVersionSelectAddRelativeAsAt = createAction(ANALYSIS_VERSION_SELECT_ADD_RELATIVE_ASAT, 'relativeAsAt');

export const ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_ASAT = 'ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_ASAT';
export const analysisVersionSelectDeleteRelativeAsAt = createAction(ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_ASAT, 'relativeAsAt');

export const ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_VALUE = 'ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_VALUE';
export const analysisVersionSelectEditRelativeAsAtValue = createAction(ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_VALUE, 'value');

export const ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_CUTOFF = 'ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_CUTOFF';
export const analysisVersionSelectEditRelativeAsAtCutoff = createAction(ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_CUTOFF, 'value');

// Relative forecast
export const ANALYSIS_VERSION_SELECT_ADD_RELATIVE_FORECAST = 'ANALYSIS_VERSION_SELECT_ADD_RELATIVE_FORECAST';
export const analysisVersionSelectAddRelativeForecast = createAction(ANALYSIS_VERSION_SELECT_ADD_RELATIVE_FORECAST, 'relativeForecast');

export const ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_FORECAST = 'ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_FORECAST';
export const analysisVersionSelectDeleteRelativeForecast = createAction(ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_FORECAST, 'relativeForecast');

export const ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_VALUE = 'ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_VALUE';
export const analysisVersionSelectEditRelativeForecastValue = createAction(ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_VALUE, 'value');

export const ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_CUTOFF = 'ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_CUTOFF';
export const analysisVersionSelectEditRelativeForecastCutoff = createAction(ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_CUTOFF, 'value');