import React from 'react';
import { connect } from 'react-redux';
import WorkspaceSelector from '../dashboard/WorkspaceSelector';
import TileCommonSettings from './TileCommonSettings';
import WorkspaceEditConnected from './WorkspaceEditConnected';
import { getFullPath } from '../../../utility/workspace-utility';
import { toJS } from '../../../utility/immutable-utility';
import { TabContainer } from '../../shared/TabContainer';
import { getGroupedFilterChanges, mergeGroupedDynamicFilters } from '../../../reducer-functions/analysis-dynamic-workspace';
import { MultiselectDropdown } from '../../shared/MultiselectDropdown';

export default function AnalysisChartTileOptions() {
  return {
    View: (args) => View(args),
    Edit: (args) => Edit(args),
    Settings: (args) => Settings(args),
  };
}

function onOpenWorkspace(workspace, propertyBag) {
  const queryArgs = [];
  const _workspace = toJS(workspace, {});
  const { scope, folderPath, subFolderPath, name } = _workspace;
  const path = encodeURIComponent(['/analysis', scope, folderPath, subFolderPath, name].filter(s => s).join('/'));
  queryArgs.push(`workspacePath=${path}`);

  if (propertyBag.overrideDynamicWorkspace) {
    const dynamicFilter = encodeURIComponent(JSON.stringify(propertyBag.overrideDynamicWorkspace));
    queryArgs.push(`dynamicFilter=${dynamicFilter}`);
  }

  const url = `/analysis?${queryArgs.join('&')}`;
  window.open(url);
}

function View({ propertyBag, setProperty, workspace, stateKey }) {
  return <>
    <DynamicWorkspaceFilters propertyBag={propertyBag} setProperty={setProperty} workspace={workspace} stateKey={stateKey} />
    <button type='button' title='Open workspace' className='btn btn-sm' onClick={() => onOpenWorkspace(workspace, propertyBag)}><i className='fas fa-external-link-alt fa-fw' /></button>
    <button type='button' className='btn btn-sm' onClick={() => setProperty({ viewTable: propertyBag.viewTable ? false : true, requiresSave: true })}>
      {!propertyBag.viewTable && <i title='Show data table' className='fas fa-list fa-fw' />}
      {propertyBag.viewTable && <i title='Show chart' className='fas fa-chart-line fa-fw' />}
    </button>
  </>
}

function Edit({ propertyBag, setProperty, workspace, stateKey }) {
  return <>
    <DynamicWorkspaceFilters propertyBag={propertyBag} setProperty={setProperty} workspace={workspace} isEditing={true} stateKey={stateKey} />
    <button type='button' title='Open workspace' className='btn btn-sm' onClick={() => onOpenWorkspace(workspace, propertyBag)}><i className='fas fa-external-link-alt fa-fw' /></button>
    <button type='button' className='btn btn-sm' onClick={() => setProperty({ viewTable: propertyBag.viewTable ? false : true, requiresSave: true })}>
      {!propertyBag.viewTable && <i title='Show data table' className='fas fa-list fa-fw' />}
      {propertyBag.viewTable && <i title='Show chart' className='fas fa-chart-line fa-fw' />}
    </button>
  </>
}

function Settings({ propertyBag, setProperty, settingsCallbacks, workspace: currentWorkspace, dashboardTileType }) {
  currentWorkspace = toJS(currentWorkspace);
  const hasAnyDynamicWorkspaces = currentWorkspace && currentWorkspace.dynamicWorkspaces && currentWorkspace.dynamicWorkspaces.length > 0;

  function onSelectWorkspace(workspace) {
    setProperty({
      workspacePath: getFullPath({
        workspace: workspace,
        includeType: false
      })
    });
  }

  return <TabContainer>
    <div header='Settings'>
      <div className='form-group'>
        <TileCommonSettings propertyBag={propertyBag} setProperty={setProperty} dashboardTileType={dashboardTileType} >
          <div className='form-group'>
            <div className='form-row'>

              <div className='col-md-4'>
                <div className='form-group'>
                  <label className='font-weight-bold'>Chart legend</label>
                  <div>
                    <div className='form-check d-inline-block'>
                      <input className='form-check-input' type='radio' value='none' name='hideChartLegend-false' id='hideChartLegend-false'
                        checked={!propertyBag.hideChartLegend}
                        onChange={() => setProperty({ hideChartLegend: false })} />
                      <label className='form-check-label' htmlFor='hideChartLegend-false'>Visible</label>
                    </div>
                    <div className='form-check d-inline-block ml-3'>
                      <input className='form-check-input' type='radio' value='none' name='hideChartLegend-true' id='hideChartLegend-true'
                        checked={propertyBag.hideChartLegend === true}
                        onChange={() => setProperty({ hideChartLegend: true })} />
                      <label className='form-check-label' htmlFor='hideChartLegend-true'>Hidden</label>
                    </div>
                  </div>
                </div>

                {hasAnyDynamicWorkspaces && <div className='form-group'>
                  <label className='font-weight-bold'>Dynamic workspace selectors behaviour</label>
                  <select className='form-control form-control-sm' value={propertyBag.dynamicWorkspaceToolbarStyle ?? 'Default'}
                          onChange={(e) => setProperty({ dynamicWorkspaceToolbarStyle: e.target.value })}>
                    <option value='default' >Default</option>
                    <option value='readOnly' >Read only</option>
                    <option value='hidden' >Hidden</option>
                  </select>
                </div>}
              </div>          
              
              <div className='col-md-4'>
                <div className='form-group'>
                  <label className='font-weight-bold'>View as</label>
                  <div>
                    <div className='form-check d-inline-block'>
                      <input className='form-check-input' type='radio' value='none' name='viewTable-false' id='viewTable-false'
                        checked={!propertyBag.viewTable}
                        onChange={() => setProperty({ viewTable: false })} />
                      <label className='form-check-label' htmlFor='viewTable-false'>Chart</label>
                    </div>
                    <div className='form-check d-inline-block ml-3'>
                      <input className='form-check-input' type='radio' value='none' name='viewTable-true' id='viewTable-true'
                        checked={propertyBag.viewTable === true}
                        onChange={() => setProperty({ viewTable: true })} />
                      <label className='form-check-label' htmlFor='viewTable-true'>Data table</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TileCommonSettings>
      </div>

      <div className='form-group'>
        <label className='font-weight-bold'>Workspace</label>
        <input type='text' readOnly className='form-control' value={propertyBag.workspacePath ?? ''} onChange={e => setProperty({ workspacePath: e.target.value })} />
        <WorkspaceSelector style={{ marginTop: '1px', border: 'solid 1px #DDDDDD' }} showOnlyItems={['Folder', 'Analysis']} onChange={w => onSelectWorkspace(w)} />
      </div>
    </div>

    <div header='Workspace' style={{ height: '653px' }}>
      <WorkspaceEditConnected workspaceType='Analysis' workspacePath={propertyBag.workspacePath} settingsCallbacks={settingsCallbacks} />
    </div>
  </TabContainer>
}

const DynamicWorkspaceFilters = connect(
  (state) => ({
    enableDynamicWorkspace: state.getIn(['userSettings', 'settings', 'experimental', 'enableDynamicWorkspace']),
    dynamicWorkspaceDropdowns: state.getIn(['dashboardTiles', 'dynamicWorkspaceDropdowns'])
  }),
  (dispatch) => ({
  })
)(({ enableDynamicWorkspace = true, propertyBag, setProperty, workspace, stateKey, isEditing, dynamicWorkspaceDropdowns }) => {
  if (enableDynamicWorkspace !== true || propertyBag.dynamicWorkspaceToolbarStyle === 'hidden' || !propertyBag.overrideDynamicWorkspace)
    return <></>

  const _dynamicWorkspaceDropdowns = dynamicWorkspaceDropdowns ? dynamicWorkspaceDropdowns.toJS() : [];
  const unGroupedDynamicWorkspaces = workspace ? toJS(workspace.getIn(['dynamicWorkspaces'])) ?? [] : [];
  if (isEditing !== true) {
    unGroupedDynamicWorkspaces.forEach(w => {
      if (w.dynamicFilters) {
        w.dynamicFilters.forEach(f => {
          const filterName = f.alias ? f.alias : f.selectedCategory;
          const dropdown = _dynamicWorkspaceDropdowns.find(d => d.filterName === filterName);
          if (dropdown && dropdown.doNotShowInTile && dropdown.tiles.exists(stateKey)) {
            f.isHidden = true;
          }
        })
      }
    });
  }

  const isGroupingEnabled = workspace ? (workspace.getIn(['dynamicWorkspaceGroupingEnabled']) === true) ?? false : false;
  const dynamicWorkspaces = isGroupingEnabled ? mergeGroupedDynamicFilters(unGroupedDynamicWorkspaces) : unGroupedDynamicWorkspaces;

  const { overrideDynamicWorkspace } = propertyBag;

  return <>{dynamicWorkspaces.map((df, i) =>
    <DynamicWorkspaceFilter
      key={`ds${i}`}
      isReadOnly={isEditing ? false : propertyBag.dynamicWorkspaceToolbarStyle === 'readOnly'}
      unGroupedDynamicWorkspaces={unGroupedDynamicWorkspaces}
      dynamicWorkspaces={dynamicWorkspaces}
      dynamicWorkspace={df}
      setProperty={setProperty}
      overrideDynamicWorkspace={overrideDynamicWorkspace}
      isGroupingEnabled={isGroupingEnabled}
      isEditing={isEditing} />)}</>
});

function DynamicWorkspaceFilter({ isReadOnly, unGroupedDynamicWorkspaces, dynamicWorkspaces, dynamicWorkspace, setProperty, overrideDynamicWorkspace, isGroupingEnabled, isEditing }) {
  function setDynamicWorkspaceFilterValue(dynamicWorkspaceKey, dynamicFilterKey, value, values) {
    const dynamicWorkspaceKeyFilterGroupings = getGroupedFilterChanges(dynamicWorkspaceKey, dynamicFilterKey, isGroupingEnabled, unGroupedDynamicWorkspaces);

    for (let index = 0; index < dynamicWorkspaceKeyFilterGroupings.length; index++) {
      const { dynamicWorkspaceKey, dynamicFilterKey } = dynamicWorkspaceKeyFilterGroupings[index];
      applyDynamicWorkspaceFilterValue(overrideDynamicWorkspace, dynamicWorkspaceKey, dynamicFilterKey, value, values);
    }

    setProperty({
      overrideDynamicWorkspace: overrideDynamicWorkspace,
      forceRefresh: true,
      requiresSave: isEditing
    });
  }

  function setDynamicWorkspaceMultipleFilterValues(dynamicWorkspaceKey, dynamicFilterKey, values) {
    setDynamicWorkspaceFilterValue(dynamicWorkspaceKey, dynamicFilterKey, undefined, values);
  }

  function applyDynamicWorkspaceFilterValue(overrideDynamicWorkspace, dynamicWorkspaceKey, dynamicFilterKey, value, values) {
    let dynamicWorkspace = overrideDynamicWorkspace.find(o => o.key === dynamicWorkspaceKey);
    if (!dynamicWorkspace) {
      dynamicWorkspace = {
        key: dynamicWorkspaceKey,
        filters: []
      };
      overrideDynamicWorkspace.push(dynamicWorkspace);
    }

    let filter = dynamicWorkspace.filters.find(df => df.dynamicFilterKey === dynamicFilterKey);
    if (!filter) {
      filter = {
        dynamicFilterKey,
        value,
        values
      };
      dynamicWorkspace.filters.push(filter);
    } else {
      filter.value = value;
      filter.values = values;
    }

    overrideDynamicWorkspace = overrideDynamicWorkspace.filter(o => dynamicWorkspaces.some(ds => ds.key === o.key));
  }

  if (!dynamicWorkspace.dynamicFilters)
    return <></>

  if (dynamicWorkspace.dynamicFilters.every(f => f.isHidden === true))
    return <></>

  const visibleFilters = dynamicWorkspace.dynamicFilters.filter(f => f.isHidden !== true);

  return <div className='d-flex ml-1 mr-0 p-0 pl-0 pr-0' style={{ backgroundColor: isReadOnly ? 'unset' : 'rgba(150, 150, 150, 0.4)', borderRadius: '3px' }}>
    <div className='d-flex'>
      {visibleFilters.map((ds, di) => <DynamicFilter isReadOnly={isReadOnly} setDynamicWorkspaceFilterValue={setDynamicWorkspaceFilterValue} setDynamicWorkspaceMultipleFilterValues={setDynamicWorkspaceMultipleFilterValues} key={`df-${di}`} dynamicWorkspace={dynamicWorkspace} dynamicFilter={ds} overrideDynamicWorkspace={overrideDynamicWorkspace} />)}
    </div>
  </div>
}

function DynamicFilter({ isReadOnly, dynamicWorkspace, dynamicFilter, overrideDynamicWorkspace, setDynamicWorkspaceFilterValue, setDynamicWorkspaceMultipleFilterValues }) {
  overrideDynamicWorkspace = overrideDynamicWorkspace.find(o => dynamicWorkspace.key === o.key);
  if (overrideDynamicWorkspace) {
    let filter = overrideDynamicWorkspace.filters.find(df => df.dynamicFilterKey === dynamicFilter.key);
    if (filter) {
      dynamicFilter.selectedValue = filter.value;
    }
  }

  const dynamicFilterValues = dynamicFilter.strict ? dynamicFilter.strictValues : dynamicFilter.values;

  if (dynamicFilter.isCollapsed || !dynamicFilterValues || dynamicFilterValues.length === 0)
    return <></>

  let title = '';
  if (dynamicFilter.alias !== '') {
    title = dynamicFilter.alias;
  } else if (dynamicFilter.selectedCategory) {
    title = dynamicFilter.selectedCategory;
  } else {
    title = '???';
  }

  let filterOptions = dynamicFilterValues.filter(v => v.isSelected === true).map(v => ({ value: v.value, name: v.name }));
  if (!dynamicFilter.isMultiSelect && !filterOptions.find(v => v.value === dynamicFilter.selectedValue)) {
    filterOptions.push({ value: dynamicFilter.selectedValue, name: '' });
  }

  if (dynamicFilter.isMultiSelect) {
    return <div className='d-flex mr-1'>
      {isReadOnly ? 
        <>
          <span className='pt-1 pl-1 pr-1' style={{ backgroundColor: 'rgba(150, 150, 150, 0.4)', borderRadius: '3px' }} >{title}:{dynamicFilter.multiSelectValues}</span>
        </> : 
        <>
          <span className='mt-1 ml-1 mr-1'>{title}</span>
          <MultiselectDropdown className='multiselect-sm'
            style={{width:'200px', maxHeight:'28px'}}
            data={filterOptions}
            dataKey='name'
            textField='name'
            onChange={values => setDynamicWorkspaceMultipleFilterValues(dynamicWorkspace.key, dynamicFilter.key, values.map(v => v.name))}
            value={dynamicFilter.multiSelectValues} />
        </>}
    </div>
  }
  else {
    return isReadOnly ? 
        <>
          <span className='pt-1 pl-1 pr-1 mr-1' style={{ backgroundColor: 'rgba(150, 150, 150, 0.4)', borderRadius: '3px' }} >{title}:{dynamicFilter.selectedValue}</span>          
        </> :
        <>
          <span className='mt-1 ml-1 mr-1'>{title}</span>
          <select className='form-control form-control-sm'
            style={{ minWidth: '50px', maxWidth: '150px' }}
            title={dynamicFilter.selectedValue}
            value={dynamicFilter.selectedValue}
            onChange={e => { setDynamicWorkspaceFilterValue(dynamicWorkspace.key, dynamicFilter.key, e.target.value) }}>
            {filterOptions.map((v, vi) => <option key={`vi-${vi}`} value={v.value}>{v.name}</option>)}
          </select>
        </>        
  }
}