import React from 'react';
import { connect } from 'react-redux';
import { push } from "redux-first-history";
import { toJS } from '../../../utility/immutable-utility';
import {
  workspaceHitCountLoad
} from '../../../actions/workspaceHitCount';
import {
  workspaceModalClear,
  workspaceModalDelete
} from '../../../actions/workspaceModal';
import {
  homepageAddFavourite,
  homepageDeleteFavourite,
  homepageToggleOnlyFavourites,
  homepageSetDisplayMode,
  homepageSetOrderBy,
  homepageSetSearchText,
  homepageEnableSearchMode,
  homepageClearSearch,
  homepageRefresh
} from '../../../actions/homepage';
import {
  homepageNewItemModalClear,
  homepageNewItemModalSetScope,
  homepageNewItemModalSetPath
} from '../../../actions/homepageNewItemModal';
import {
  analysisWorkspaceLoad
} from '../../../actions/analysis';
import {
  reportsClear,
  reportsResetCriteria
} from '../../../actions/reports';
import '../../../styles/homepage.css';
import './Home2';
import Home2 from './Home2';

const HomeConnected = connect(
  (state) => ({
    isInitialised: state.getIn(['homepage', 'isInitialised']),
    isLoading: state.getIn(['homepage', 'isLoading']),
    isSearchMode: state.getIn(['homepage', 'isSearchMode']),
    displayMode: state.getIn(['homepage', 'displayMode']),
    orderBy: state.getIn(['homepage', 'orderBy']),
    orderByDirection: state.getIn(['homepage', 'orderByDirection']),
    path: state.getIn(['homepage', 'path']),
    scope: state.getIn(['homepage', 'scope']),
    folder: state.getIn(['homepage', 'folder']),
    subFolder: state.getIn(['homepage', 'subFolder']),
    items: state.getIn(['homepage', 'items']),
    searchText: state.getIn(['homepage', 'searchText']),
    displayFavourites: state.getIn(['homepage', 'onlyFavourites'])
  }),
  (dispatch) => ({
    clearWorkspaceModel() {
      dispatch(workspaceModalClear());
    },
    initHomeItemModel(scope, folderPath, subFolderPath) {
      dispatch(homepageNewItemModalClear());
      dispatch(homepageNewItemModalSetScope(scope));
      dispatch(homepageNewItemModalSetPath({ folderPath, subFolderPath }));
    },
    loadAnalysisWorkspace({workspaceId}) {
      dispatch(analysisWorkspaceLoad(workspaceId, '/analysis/chart'));
    },
    loadReportWorkspace({path}) {
      dispatch(reportsClear());
      dispatch(reportsResetCriteria());
      dispatch(push(path));
    },
    loadItem(item) {
      const {type, id} = item;
      if (type === 'StorageAuction'){
        dispatch(push(`/storage-auctions?workspace=${id}`));
      }
    },
    deleteWorkspace(workspaceId) {
      dispatch(workspaceModalDelete(workspaceId, () => {
        dispatch(homepageRefresh());
      }));
    },
    openWorkspaceHitCount(workspaceId) {
      dispatch(workspaceHitCountLoad(workspaceId));
    },
    setDisplayMode(value) {
      dispatch(homepageSetDisplayMode(value));
    },
    setSearchText(value) {
      dispatch(homepageSetSearchText(value));
    },
    clearSearch() {
      dispatch(homepageClearSearch());
      dispatch(homepageRefresh());
    },
    setPath({path, scope}) {
      const fullPath = (scope === 'Private' ? `/home/${scope}/${path}` : `/home/${path}`).replace(/\/+/g, '/').replace(/\/$/, '');

      dispatch(homepageClearSearch());
      dispatch(push(fullPath));
    },
    addFavourite(workspaceId) {
      dispatch(homepageAddFavourite(workspaceId));
    },
    removeFavourite(workspaceId) {
      dispatch(homepageDeleteFavourite(workspaceId));
    },
    toggleDisplayFavourites() {
      dispatch(homepageToggleOnlyFavourites());
      dispatch(homepageRefresh());
    },
    startSearch() {
      dispatch(homepageEnableSearchMode());
      dispatch(homepageRefresh());
    },
    sortBy(name, direction) {
      dispatch(homepageSetOrderBy(name, direction));
      dispatch(homepageRefresh());
    },
    refresh() {
      dispatch(homepageRefresh());
    }
  })
)(({ isInitialised, isLoading, isSearchMode, displayMode, orderBy, orderByDirection, scope, path, folder, subFolder, items, searchText, displayFavourites, setPath, sortBy, setDisplayMode, setSearchText, clearSearch, startSearch, refresh,
  addFavourite, removeFavourite, toggleDisplayFavourites, initHomeItemModel, clearWorkspaceModel, loadAnalysisWorkspace, loadReportWorkspace, loadItem, deleteWorkspace, openWorkspaceHitCount }) => {
  const _displayMode = displayMode ? displayMode : 'tiles';
  const _items = toJS(items, []);
  return <Home2
      isInitialised  = {isInitialised}
      isLoading = {isLoading}
      isSearchMode = {isSearchMode}
      displayMode = {_displayMode}
      orderBy = {orderBy}
      orderByDirection = {orderByDirection}
      scope = {scope}
      path = {path}
      folder = {folder}
      subFolder = {subFolder}
      items = {_items}
      searchText = {searchText}
      displayFavourites = {displayFavourites}
      setPath = {setPath}
      sortBy = {sortBy}
      setDisplayMode = {setDisplayMode}
      setSearchText = {setSearchText}
      clearSearch  = {clearSearch}
      startSearch = {startSearch}
      refresh = {refresh}
      addFavourite  = {addFavourite}
      removeFavourite = {removeFavourite}
      toggleDisplayFavourites = {toggleDisplayFavourites}
      initHomeItemModel = {initHomeItemModel}
      clearWorkspaceModel = {clearWorkspaceModel}
      selectAnalysisWorkspace = {loadAnalysisWorkspace}
      selectReportWorkspace = {loadReportWorkspace}
      deleteWorkspace = {deleteWorkspace}
      selectItem={loadItem}
      openWorkspaceHitCount={openWorkspaceHitCount}
  />
});

export default HomeConnected;