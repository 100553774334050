import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_URL, ANALYSIS_SEARCH_API_URL } from '../config';
import qs from 'querystring';
import {
  logErrorNotification
} from './log';

export const analysisSearchLoadComposition = (key) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_URL}/timeseriescomposition?id=${key}`)
    .then(response => response.json())
    .then(compositionData => {
      let data = {
        ids: [],
        composition: {}
      };

      function mapComposition(item) {
        data.ids.push(item.id);

        return {
          id: item.id,
          title: item.name,
          style: item.style,
          items: item.inputs && item.inputs.length ? item.inputs.map(mapComposition) : null
        };
      }

      data.composition = mapComposition(compositionData);

      return data;
    })
    .then(data => {
      const params = qs.stringify({
        customFilter: `search.in(id, '${data.ids.join(',')}')`
      });

      return new Promise((resolve, reject) => {
        authFetch(`${ANALYSIS_SEARCH_API_URL}/timeseriessearch?${params}&top=1000`)
          .then(response => response.json())
          .then(searchData => {
            function mergeComposition(item) {
              item.data = searchData.results.find(i => i.id === item.id);

              if (item.items && item.items.length) item.items.map(mergeComposition);
            }

            mergeComposition(data.composition);

            resolve(data.composition);
          })
          .catch(error => reject(error));
      });
    })
    .then(data => {
      dispatch(analysisSearchLoadCompositionComplete(key, data));
    })
    .catch(error => {
      dispatch(analysisSearchLoadCompositionComplete(key));
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_SEARCH_LOAD_COMPOSITION_COMPLETE = 'ANALYSIS_SEARCH_LOAD_COMPOSITION_COMPLETE';
export const analysisSearchLoadCompositionComplete = createAction(ANALYSIS_SEARCH_LOAD_COMPOSITION_COMPLETE, 'key', 'data');