import React from 'react';
import AdjustmentRangeEditorConnected from './adjustmentRangeEditor/AdjustmentRangeEditorConnected';
import AdjustmentsEditor from './adjustmentsEditor/AdjustmentsEditor';
import TimeSeriesEditor from './timeSeriesEditor/TimeSeriesEditor';
import TimeSeriesToolBar from './TimeSeriesToolBar';
import AnnotationsEditor from './annotationsEditor/AnnotationsEditor';
import InstanceViewerConnected  from './instanceViewer/InstanceViewerConnected';
import HistoryViewerConnected  from './historyViewer/HistoryViewerConnected';
import SchemasConnected from './schemas/SchemasConnected';
import TimeSeriesComposition from './timeSeriesComposition/TimeSeriesComposition';
import VariantsEditor from './variantsEditor/VariantsEditor';
import TimeSeriesSideBar from './TimeSeriesSideBar';
import PageHeader from '../../shared/PageHeader';
import IdentityId from '../../shared/IdentityId2';
import ToolboxConnected from './toolbox/ToolboxConnected';
import { Allotment } from 'allotment';

export default function TimeSeriesDetails({ features, referenceData, allSchemaNames, isEditorCollapsed, isSideBarCollapsed, currentTimeSeriesId, currentTimeSeriesView, selectedTimeSeriesId,
  setInputFocus, setInputHasFocus,
  updateViewMode, cloneTimeSeries, saveTimeSeries, refreshTimeSeries,
  timeSeriesEditor, annotationsEditor, composition, variantsEditor,
  loadTimeSeries, updateTimeSeriesObject, updateTimeSeriesValue, setTimeSeriesView, toggleEditorCollapse, toggleSideBarCollapse,
  updateTimeSeriesDataType,
  updateDerivationSearchKeyText, addDerivationSearchKeyText, moveDerivationKeyUp, moveDerivationKeyDown, deleteDerivationKey, addDerivationKeys,
  setFunctionsStepStyleToMultiStep, setFunctionsType, addFunctionsStepParameter, deleteFunctionsStepParameter, updateFunctionParameterValue, addMultiStepFunction, deleteMultiStepFunction, moveMultiStepFunctionUp, moveMultiStepFunctionDown, addFunctionKey, deleteFunctionKey, moveFunctionKeyUp, moveFunctionKeyDown, addFunctionOutputCategory, updateFunctionOutputKey, updateFunctionOutputCategoryValue, deleteFunctionOutputCategory, setFunctionDisabled,
  addCategory, updateCategoryValue, deleteCategory, deleteAllcategories, copyCategoriesCopyFrom, getCategoryValues,
  loadAnnotations, setAnnotationsTimeZone, updateAnnotationsFilter, applyAnnotationsFilter, clearAnnotationsFilter, addAnnotation, editAnnotation, deleteAnnotation, saveAnnotation, cancelAnnotationEdit, setAnnotationKey, setAnnotationFromDate, setAnnotationToDate, setAnnotationPriority, setAnnotationText, sortAnnotations,
  materialiseRangeDialogVisible, materialiseRangeFromUtc, materialiseRangeToUtc, requestRematerialise, requestRematerialiseWithRange, setMaterialiseRangeDialogVisible, setMaterialiseRangeDate, setMaterialiseStatusToEnabled,
  suppressArchive, setSuppressArchive,
  loadVariants, addVariant, cancelVariantEdit, setVariantValue, saveVariant,
  adjustmentsEditor, loadAdjustments, setAdjustmentsFilter, editAdjustmentValues, saveAdjustments, applyAdjustmentsRange,
  copyLinkToClipboard, isContributor, isWizard,
  basedOnSettings,
  basedOnAddKey, basedOnDeleteKey, basedOnUpdateSearchKeyText, basedOnUpdateSettings }) {

  const enableRangeBasedAdjustments = features.rangeBasedAdjustments;
  const isNewTimeSeries = !isNaN(currentTimeSeriesId);

  let currentTimeSeriesViewPanel = <div />;
  if (isNewTimeSeries) {
    if (enableRangeBasedAdjustments && (!currentTimeSeriesView || currentTimeSeriesView === 'adjustments')) {
      currentTimeSeriesViewPanel = (
        <AdjustmentRangeEditorConnected />
      );
    }
    else {
      switch (currentTimeSeriesView) {      
        case 'annotations': currentTimeSeriesViewPanel = (
          <AnnotationsEditor currentTimeSeriesId={currentTimeSeriesId}
            referenceData={referenceData}
            annotations={annotationsEditor.annotations}
            annotationEdit={annotationsEditor.annotationUnderEdit}
            selectedTimeZone={annotationsEditor.selectedTimeZone}
            filterType={annotationsEditor.filterType}
            filterText={annotationsEditor.filterText}
            isFilterDirty={annotationsEditor.isFilterDirty}
            timeZone={annotationsEditor.timeZone}
            orderBy={annotationsEditor.appliedOrderBy}
            orderByDirection={annotationsEditor.appliedOrderByDirection}
            loadAnnotations={loadAnnotations}
            setAnnotationsTimeZone={setAnnotationsTimeZone}
            updateAnnotationsFilter={updateAnnotationsFilter}
            applyAnnotationsFilter={applyAnnotationsFilter}
            clearAnnotationsFilter={clearAnnotationsFilter}
            addAnnotation={addAnnotation}
            editAnnotation={editAnnotation}
            deleteAnnotation={deleteAnnotation}
            saveAnnotation={saveAnnotation}
            cancelAnnotationEdit={cancelAnnotationEdit}
            setAnnotationKey={setAnnotationKey}
            setAnnotationFromDate={setAnnotationFromDate}
            setAnnotationToDate={setAnnotationToDate}
            setAnnotationPriority={setAnnotationPriority}
            setAnnotationText={setAnnotationText}
            sortAnnotations={sortAnnotations}
            isContributor={isContributor} />
        ); break;
        case 'composition': currentTimeSeriesViewPanel = (
          <TimeSeriesComposition composition={composition}
            selectTimeseries={loadTimeSeries} />
        ); break;
        case 'schemas': currentTimeSeriesViewPanel = (
          <SchemasConnected />
        ); break;
        case 'instances': currentTimeSeriesViewPanel = (
          <InstanceViewerConnected />
        ); break;
        case 'history': currentTimeSeriesViewPanel = (
          <HistoryViewerConnected />
        ); break;        
        case 'variants': currentTimeSeriesViewPanel = (
          <VariantsEditor currentTimeSeriesId={currentTimeSeriesId}
            referenceData={referenceData}
            variants={variantsEditor.variants}
            variantEdit={variantsEditor.variantEdit}
            loadVariants={loadVariants}
            addVariant={addVariant}
            cancelVariantEdit={cancelVariantEdit}
            setVariantValue={setVariantValue}
            saveVariant={saveVariant}
            materialiseRangeDialogVisible={materialiseRangeDialogVisible}
            materialiseRangeFromUtc={materialiseRangeFromUtc}
            materialiseRangeToUtc={materialiseRangeToUtc}
            requestRematerialise={requestRematerialise}
            requestRematerialiseWithRange={requestRematerialiseWithRange}
            setMaterialiseRangeDialogVisible={setMaterialiseRangeDialogVisible} 
            setMaterialiseRangeDate={setMaterialiseRangeDate}
            suppressArchive={suppressArchive}
            setSuppressArchive={setSuppressArchive}
            isContributor={isContributor} />
        ); break;
        case 'toolbox': currentTimeSeriesViewPanel = (
          <ToolboxConnected />
        ); break;
        case 'adjustments':
        default: currentTimeSeriesViewPanel = (
          <AdjustmentsEditor
            referenceData={referenceData}
            timeSeries={timeSeriesEditor.timeSeries}
            adjustmentsEditor={adjustmentsEditor}
            loadAdjustments={loadAdjustments}
            setAdjustmentsFilter={setAdjustmentsFilter}
            editAdjustmentValues={editAdjustmentValues}
            saveAdjustments={saveAdjustments}
            applyAdjustmentsRange={applyAdjustmentsRange}
            isContributor={isContributor}
            isWizard={isWizard} />
        ); break;
      }
    }
  }

  return <>
    <Allotment vertical={false} proportionalLayout={false} separator={false}>
      <Allotment.Pane visible={!isEditorCollapsed} minSize={390}>
        <div className='d-flex flex-column h-100'>
            <div className='p-2'>
              <TimeSeriesToolBar currentTimeSeriesId={timeSeriesEditor.timeSeries.id}
                                viewMode={timeSeriesEditor.viewMode}
                                updateViewMode={updateViewMode}
                                cloneTimeSeries={cloneTimeSeries}
                                saveTimeSeries={saveTimeSeries}
                                refreshTimeSeries={refreshTimeSeries}
                                isContributor={isContributor} />
            </div>
            <div className='flex-fill' style={{ overflowY: 'auto' }}>
              <TimeSeriesEditor
                currentTimeSeriesId={currentTimeSeriesId}
                selectedTimeSeriesId={selectedTimeSeriesId}
                referenceData={referenceData}
                allSchemaNames={allSchemaNames}
                lookupData={timeSeriesEditor.lookupData}
                timeSeries={timeSeriesEditor.timeSeries}
                viewMode={timeSeriesEditor.viewMode}
                inputFocusId={timeSeriesEditor.inputFocusId}
                setInputFocus={setInputFocus}
                setInputHasFocus={setInputHasFocus}

                derivationSettings={timeSeriesEditor.derivationSettings}
                updateDerivationSearchKeyText={updateDerivationSearchKeyText}
                addDerivationSearchKeyText={addDerivationSearchKeyText}
                addDerivationKeys={addDerivationKeys}

                functionSettings={timeSeriesEditor.functionSettings}
                setFunctionsStepStyleToMultiStep={setFunctionsStepStyleToMultiStep}
                setFunctionsType={setFunctionsType}
                addFunctionsStepParameter={addFunctionsStepParameter}
                deleteFunctionsStepParameter={deleteFunctionsStepParameter}
                updateFunctionParameterValue={updateFunctionParameterValue}
                addMultiStepFunction={addMultiStepFunction}
                deleteMultiStepFunction={deleteMultiStepFunction}
                moveMultiStepFunctionUp={moveMultiStepFunctionUp}
                moveMultiStepFunctionDown={moveMultiStepFunctionDown}
                addFunctionKey={addFunctionKey}
                deleteFunctionKey={deleteFunctionKey}
                moveFunctionKeyUp={moveFunctionKeyUp}
                moveFunctionKeyDown={moveFunctionKeyDown}
                addFunctionOutputCategory={addFunctionOutputCategory}
                updateFunctionOutputKey={updateFunctionOutputKey}
                updateFunctionOutputCategoryValue={updateFunctionOutputCategoryValue}
                deleteFunctionOutputCategory={deleteFunctionOutputCategory}
                setFunctionDisabled={setFunctionDisabled}

                categorySettings={timeSeriesEditor.categorySettings}
                addCategory={addCategory}
                updateCategoryValue={updateCategoryValue}
                deleteCategory={deleteCategory}
                deleteAllcategories={deleteAllcategories}
                copyCategoriesCopyFrom={copyCategoriesCopyFrom}
                getCategoryValues={getCategoryValues}

                updateTimeSeriesObject={updateTimeSeriesObject}
                updateTimeSeriesValue={updateTimeSeriesValue}
                updateTimeSeriesDataType={updateTimeSeriesDataType}
                moveDerivationKeyUp={moveDerivationKeyUp}
                moveDerivationKeyDown={moveDerivationKeyDown}
                deleteDerivationKey={deleteDerivationKey}
                requestRematerialiseWithRange={requestRematerialiseWithRange}
                setMaterialiseStatusToEnabled={setMaterialiseStatusToEnabled}
                isContributor={isContributor}
                
                basedOnSettings={basedOnSettings}
                basedOnAddKey={basedOnAddKey}
                basedOnDeleteKey={basedOnDeleteKey}
                basedOnUpdateSearchKeyText={basedOnUpdateSearchKeyText} 
                basedOnUpdateSettings={basedOnUpdateSettings} />
            </div>
        </div>
      </Allotment.Pane>

      <Allotment.Pane preferredSize={'100%'}>
        <div className='d-flex flex-row w-100 h-100'>
          <div>
            {isNewTimeSeries && <aside style={{width:isSideBarCollapsed ? '46px' : '217px'}} className={`pl-1 pt-0 timeseries-edit-body ${(isSideBarCollapsed ? ' collapsed' : '')}`}>
              <TimeSeriesSideBar
                isWizard={isWizard}
                currentTimeSeriesView={currentTimeSeriesView}
                setTimeSeriesView={setTimeSeriesView}
                isEditorCollapsed={isEditorCollapsed}
                toggleEditorCollapse={toggleEditorCollapse}
                isSideBarCollapsed={isSideBarCollapsed}
                toggleSideBarCollapse={toggleSideBarCollapse}
                copyLinkToClipboard={copyLinkToClipboard} />
            </aside>}
          </div>
          <div className='flex-fill d-flex flex-column'>
            <Allotment vertical={true} proportionalLayout={true} separator={false} >
              <Allotment.Pane minSize={42} maxSize={42}>
                <PageHeader containerClassName='w-100'>
                    {!timeSeriesEditor.timeSeries.id
                      ? <span>New Time Series: {timeSeriesEditor.timeSeries.name}</span>
                      : <div style={{display: 'flex'}}>
                          <div className='px-1'>Edit Time Series: </div>
                          <b className='px-1'>{timeSeriesEditor.timeSeries.id}</b>
                          <div className='px-1'>{timeSeriesEditor.timeSeries.name}</div>
                          <IdentityId id={timeSeriesEditor.timeSeries.id} />
                        </div>
                    }
                </PageHeader>
              </Allotment.Pane>
              <Allotment.Pane>
                <div className='w-100 h-100 pl-1 pt-0'>
                  {currentTimeSeriesViewPanel}
                </div>
              </Allotment.Pane>
            </Allotment>
          </div>
        </div>
      </Allotment.Pane>
    </Allotment>
  </>  
}