import React from 'react';
import AnnotationsToolbar from './AnnotationsToolbar';
import AnnotationsTable from './AnnotationsTable';

export default function AnnotationsEditor({ currentTimeSeriesId, referenceData, annotations, annotationEdit,
  selectedTimeZone, timeZone, filterType, filterText, isFilterDirty,
  orderBy, orderByDirection,
  loadAnnotations, setAnnotationsTimeZone, updateAnnotationsFilter, applyAnnotationsFilter, clearAnnotationsFilter,
  addAnnotation, editAnnotation, deleteAnnotation, saveAnnotation, cancelAnnotationEdit, setAnnotationKey, setAnnotationFromDate, setAnnotationToDate, setAnnotationPriority, setAnnotationText, sortAnnotations, isContributor }) {
  return (
    <div className='w-100 h-100 d-flex flex-column'>
      <div className='mb-1'>
        <AnnotationsToolbar referenceData={referenceData}
          currentTimeSeriesId={currentTimeSeriesId}
          selectedTimeZone={selectedTimeZone}
          filterType={filterType}
          filterText={filterText}
          isFilterDirty={isFilterDirty}
          loadAnnotations={loadAnnotations}
          setAnnotationsTimeZone={setAnnotationsTimeZone}
          updateAnnotationsFilter={updateAnnotationsFilter}
          applyAnnotationsFilter={applyAnnotationsFilter}
          clearAnnotationsFilter={clearAnnotationsFilter} />
      </div>
      <div className='flex-fill' style={{overflowY:'auto'}}>
        <AnnotationsTable referenceData={referenceData}
          annotations={annotations}
          timeZone={timeZone}
          annotationEdit={annotationEdit}
          addAnnotation={addAnnotation}
          editAnnotation={editAnnotation}
          deleteAnnotation={deleteAnnotation}
          saveAnnotation={saveAnnotation}
          cancelAnnotationEdit={cancelAnnotationEdit}
          setAnnotationKey={setAnnotationKey}
          setAnnotationFromDate={setAnnotationFromDate}
          setAnnotationToDate={setAnnotationToDate}
          setAnnotationPriority={setAnnotationPriority}
          setAnnotationText={setAnnotationText}
          sortAnnotations={sortAnnotations}
          isContributor={isContributor}
          orderBy={orderBy}
          orderByDirection={orderByDirection} />
      </div>
    </div>
  );
}