import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ETL_API_URL } from '../config';
import {
  logErrorNotification,
  logInfoNotification
} from './log';

export const ETL_JOB_FILTER_CHANGED = 'ETL_JOB_FILTER_CHANGED';
export const etlJobFilterChanged = createAction(ETL_JOB_FILTER_CHANGED, 'filterType', 'text');

export const ETL_JOB_REFRESH_STARTED = 'ETL_JOB_REFRESH_STARTED';
export const etlJobRefreshStarted = createAction(ETL_JOB_REFRESH_STARTED);

export const ETL_JOB_REFRESH_STOPPED = 'ETL_JOB_REFRESH_STOPPED';
export const etlJobRefreshStopped = createAction(ETL_JOB_REFRESH_STOPPED);

export const ETL_JOB_REFRESH_COMPLETE = 'ETL_JOB_REFRESH_COMPLETE';
export const etlJobRefreshComplete = createAction(ETL_JOB_REFRESH_COMPLETE, 'data');

export const etlJobRefresh = () => (dispatch, getState) => {
  dispatch(etlJobRefreshStarted());
  authFetch(`${ETL_API_URL}/jobdashboard`)
    .then(response => response.json())
    .then(data => {
      dispatch(etlJobRefreshComplete(data));
    })
    .catch(error => {
      dispatch(etlJobRefreshStopped());
      dispatch(logErrorNotification(error));
    });
};

export const ETL_JOB_APPLY_SORT = 'ETL_JOB_APPLY_SORT';
export const etlJobApplySort = createAction(ETL_JOB_APPLY_SORT, 'key');

export const etlJobRequest = (jobId) => (dispatch, getState) => {
  authFetch(`${ETL_API_URL}/job/${jobId}/request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => response.json())
  .then(data => {
    dispatch(logInfoNotification(`${data.jobName} requested`));
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
}

export const ETL_JOB_TOGGLE_SHOW = 'ETL_JOB_TOGGLE_SHOW';
export const etlJobToggleShow = createAction(ETL_JOB_TOGGLE_SHOW, 'property');