import { fromJS, merge } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
  STORAGE_AUCTIONS_INIT_COMPLETE,
  STORAGE_AUCTIONS_LOAD_WORKSPACE_COMPLETE,
  STORAGE_AUCTIONS_WORKSPACE_CREATE_COMPLETE,
  STORAGE_AUCTIONS_CLOSE_WORKSPACE,
  STORAGE_AUCTIONS_REFRESH_STARTED,
  STORAGE_AUCTIONS_REFRESH_STOPPED,
  STORAGE_AUCTIONS_REFRESH_COMPLETE,
  STORAGE_AUCTIONS_SET_PAGE_INDEX,
  STORAGE_AUCTIONS_SET_ORDER_BY,
  STORAGE_AUCTIONS_VALUE,
  STORAGE_AUCTIONS_RESET_FILTERS,
  STORAGE_AUCTIONS_UPDATE_FILTERS
} from '../actions/storageAuctions';
import { LOCATION_CHANGE } from "redux-first-history";
import {
  DASHBOARD_TILE_WORKSPACE_LOAD_COMPLETE,
  DASHBOARD_TILE_EDIT_START,
  DASHBOARD_TILE_EDIT_CANCEL,
  DASHBOARD_TILE_EDIT_COMPLETE,
  DASHBOARD_TILE_CLONE
} from '../actions/dashboard-tile';
import {
  getFilters,
  mapWorkspaceFromSearchParamsString,
  setAreAnyFiltersApplied
} from '../utility/storage-auctions-utility';
import getInitialState from '../state';
import { filterNames } from '../state/storage-auctions';

export const storageAuctions = createReducer(null, {
  [LOCATION_CHANGE](state, action) {
    const { location } = action.payload;
    let { pathname = '' } = location || {};
    pathname = decodeURIComponent(pathname);

    if (pathname !== '/' && pathname.indexOf('/storage-auctions') < 0) return state;

    const { payload } = action;
    const { search = '' } = location;
    const searchCriteria = new URLSearchParams(search);
    if (payload.action === 'POP' || payload.action === 'PUSH') {
      if (searchCriteria.get('workspace'))
        return state;
    }

    if (payload.action === 'POP') {
      const workspace = mapWorkspaceFromSearchParamsString(search);
      if (workspace.pageIndex > 0)
        state = state.setIn(['tiles', 'default', 'workspace', 'pageIndex'], workspace.pageIndex).set('setFromUrl', true);

      if (workspace.pageSize > 0)
        state = state.setIn(['tiles', 'default', 'workspace', 'pageSize'], workspace.pageSize).set('setFromUrl', true);

      if (workspace.orderBy)
        state = state.setIn(['tiles', 'default', 'workspace', 'orderBy'], workspace.orderBy).set('setFromUrl', true);

      if (workspace.orderByDirection)
        state = state.setIn(['tiles', 'default', 'workspace', 'orderByDirection'], workspace.orderByDirection).set('setFromUrl', true);

      if (workspace.dateFrom)
        state = state.setIn(['tiles', 'default', 'workspace', 'dateFrom'], merge(state.getIn(['tiles', 'default', 'workspace', 'dateFrom']), fromJS(workspace.dateFrom)))
          .set('setFromUrl', true);

      if (workspace.dateTo)
        state = state.setIn(['tiles', 'default', 'workspace', 'dateTo'], merge(state.getIn(['tiles', 'default', 'workspace', 'dateTo']), fromJS(workspace.dateTo)))
          .set('setFromUrl', true);

      filterNames.forEach(filter => {
        let values = workspace.filters[filter];
        if (values && values.length > 0) {
          state = state.setIn(['tiles', 'default', 'workspace', 'filters', filter], fromJS(values))
            .set('setFromUrl', true);
        }
      });
    }

    state = setAreAnyFiltersApplied(state, 'default');
    return state;
  },
  [STORAGE_AUCTIONS_INIT_COMPLETE](state, action) {
    const { stateKey, lookups } = action;
    if (lookups) {
      state = state.set('lookups', fromJS(lookups));

      const filters = state.getIn(['tiles', stateKey, 'workspace', 'filters']).toJS();
      const availableFilters = getFilters(
        lookups,
        filters.locations,
        filters.operators,
        filters.sites);
      state = state.setIn(['tiles', stateKey, 'availableFilters'], fromJS(availableFilters))
        .delete('setFromUrl');
    }

    return state;
  },
  [STORAGE_AUCTIONS_RESET_FILTERS](state, action) {
    const { stateKey } = action;
    const lookups = state.get('lookups').toJS();
    const filters = getInitialState().toJS().storageAuctions.tiles.default.workspace.filters;
    const availableFilters = getFilters(
      lookups,
      filters.locations,
      filters.operators,
      filters.sites);

    state = state.setIn(['tiles', stateKey, 'workspace', 'filters'], fromJS(filters))
      .setIn(['tiles', stateKey, 'availableFilters'], fromJS(availableFilters))
      .delete('setFromUrl');

    state = setAreAnyFiltersApplied(state, stateKey);
    return state;
  },
  [STORAGE_AUCTIONS_UPDATE_FILTERS](state, action) {
    const { stateKey } = action;
    const lookups = state.get('lookups').toJS();
    const filters = state.getIn(['tiles', stateKey, 'workspace', 'filters']).toJS();
    const availableFilters = getFilters(
      lookups,
      filters.locations,
      filters.operators,
      filters.sites);

    [...filters.operators].forEach(a => {
      if (availableFilters.operators.indexOf(a) < 0) {
        filters.operators = filters.operators.remove(a);
      }
    });

    [...filters.sites].forEach(a => {
      if (availableFilters.sites.indexOf(a) < 0) {
        filters.sites = filters.sites.remove(a);
      }
    });

    [...filters.names].forEach(a => {
      if (availableFilters.names.indexOf(a) < 0) {
        filters.names = filters.names.remove(a);
      }
    });

    state = state.setIn(['tiles', stateKey, 'workspace', 'filters'], fromJS(filters))
      .setIn(['tiles', stateKey, 'availableFilters'], fromJS(availableFilters));

    state = setAreAnyFiltersApplied(state, stateKey);
    return state;
  },
  [STORAGE_AUCTIONS_LOAD_WORKSPACE_COMPLETE](state, action) {
    const { stateKey, workspace, data } = action;
    if (data) {
      const defaultSettings = getInitialState().toJS().storageAuctions.tiles.default;

      state = state
        .setIn(['workspaceLocation'], fromJS(workspace))
        .setIn(['canShareWorkspace'], workspace.scope === 'Shared')
        .setIn(['tiles', stateKey], fromJS(defaultSettings))
        .setIn(['tiles', stateKey, 'workspace'], fromJS({ ...defaultSettings.workspace, ...data }))
        .delete('setFromUrl');

      state = setAreAnyFiltersApplied(state, stateKey);
    }

    return state;
  },
  [DASHBOARD_TILE_WORKSPACE_LOAD_COMPLETE](state, action) {
    const { stateKey, dashboardTileType, workspace, data, tilesConfig } = action;
    if (dashboardTileType !== 'StorageAuction')
      return state;

    const defaultSettings = getInitialState().toJS().storageAuctions.tiles.default;
    const workspaceData = {
      ...defaultSettings.workspace,
      ...data,
      ...tilesConfig.workspace
    };

    workspaceData.filters = { ...data.filters, ...(tilesConfig.workspace ? tilesConfig.workspace.filters : {}) };
    state = state
      .setIn(['tiles', stateKey], fromJS(defaultSettings))
      .setIn(['tiles', stateKey, 'showDiagnostics'], false)
      .setIn(['tiles', stateKey, 'showPager'], false)
      .setIn(['tiles', stateKey, 'workspaceLocation'], fromJS(workspace))
      .setIn(['tiles', stateKey, 'workspace'], fromJS(workspaceData));

    const lookups = state.get('lookups').toJS();
    const filters = state.getIn(['tiles', stateKey, 'workspace', 'filters']).toJS();
    const availableFilters = getFilters(
      lookups,
      filters.locations,
      filters.operators,
      filters.sites);
    state = state.setIn(['tiles', stateKey, 'availableFilters'], fromJS(availableFilters));

    state = setAreAnyFiltersApplied(state, stateKey);
    return state;
  },
  [DASHBOARD_TILE_EDIT_START](state, action) {
    const { stateKey, dashboardTileType } = action;
    if (dashboardTileType !== 'StorageAuction')
      return state;

    state = state.setIn(['edit-tiles', stateKey], state.getIn(['tiles', stateKey]));
    return state;
  },
  [DASHBOARD_TILE_EDIT_CANCEL](state, action) {
    const { stateKey, dashboardTileType } = action;
    if (dashboardTileType !== 'StorageAuction')
      return state;

    state = state.setIn(['tiles', stateKey], state.getIn(['edit-tiles', stateKey]));
    return state;
  },
  [DASHBOARD_TILE_EDIT_COMPLETE](state, action) {
    const { stateKey, dashboardTileType } = action;
    if (dashboardTileType !== 'StorageAuction')
      return state;

    state = state.deleteIn(['edit-tiles', stateKey]);
    return state;
  },
  [DASHBOARD_TILE_CLONE](state, action) {
    const { dashboardTileType, fromStateKey, toStateKey } = action;
    if (dashboardTileType !== 'StorageAuction')
      return state;

    state = state
      .setIn(['tiles', toStateKey], state.getIn(['tiles', fromStateKey]));

    return state;
  },
  [STORAGE_AUCTIONS_WORKSPACE_CREATE_COMPLETE](state, action) {
    const { id, folderPath, subFolderPath, name, scope } = action;
    state = state.setIn(['canShareWorkspace'], true)
      .setIn(['workspaceLocation'], fromJS({
        id,
        name,
        scope,
        folderPath,
        subFolderPath
      }));

    return state;
  },
  [STORAGE_AUCTIONS_CLOSE_WORKSPACE](state, action) {
    const workspaceLocation = getInitialState().toJS().storageAuctions.workspaceLocation;

    state = state.setIn(['canShareWorkspace'], false)
      .setIn(['workspaceLocation'], fromJS(workspaceLocation));

    return state;
  },
  [STORAGE_AUCTIONS_REFRESH_STARTED](state, action) {
    const { stateKey } = action;
    state = state.setIn(['tiles', stateKey, 'isLoading'], true);
    return state;
  },
  [STORAGE_AUCTIONS_REFRESH_STOPPED](state, action) {
    const { stateKey } = action;
    state = state.setIn(['tiles', stateKey, 'isLoading'], false);
    return state;
  },
  [STORAGE_AUCTIONS_REFRESH_COMPLETE](state, action) {
    const { stateKey, auctions, auctionsTotalCount, diagnostics } = action;
    const pageSize = state.getIn(['tiles', stateKey, 'workspace', 'pageSize']);
    const auctionsPageCount = Math.round(auctionsTotalCount / pageSize);

    state = state.setIn(['tiles', stateKey, 'isLoading'], false)
      .setIn(['tiles', stateKey, 'auctions'], fromJS(auctions))
      .setIn(['tiles', stateKey, 'auctionsPageCount'], auctionsPageCount === 0 ? 1 : auctionsPageCount)
      .setIn(['tiles', stateKey, 'auctionsTotalCount'], auctionsTotalCount)
      .setIn(['tiles', stateKey, 'elapsed'], diagnostics.elapsed)
      .setIn(['tiles', stateKey, 'resultsWorkspace'], state.getIn(['tiles', stateKey, 'workspace']));
    return state;
  },
  [STORAGE_AUCTIONS_SET_PAGE_INDEX](state, action) {
    const { stateKey, pageIndex } = action;
    state = state.setIn(['tiles', stateKey, 'workspace', 'pageIndex'], pageIndex);
    return state;
  },
  [STORAGE_AUCTIONS_SET_ORDER_BY](state, action) {
    const { stateKey, orderBy } = action;
    const previousOrderBy = state.getIn(['tiles', stateKey, 'workspace', 'orderBy']);
    state = state.setIn(['tiles', stateKey, 'workspace', 'orderBy'], orderBy);
    if (previousOrderBy !== orderBy) {
      state = state.setIn(['tiles', stateKey, 'workspace', 'orderByDirection'], 'asc');
    } else {
      const previousOrderByDirection = state.getIn(['tiles', stateKey, 'workspace', 'orderByDirection']);
      state = state.setIn(['tiles', stateKey, 'workspace', 'orderByDirection'], previousOrderByDirection !== 'asc' ? 'asc' : 'desc');
    }

    return state;
  },
  [STORAGE_AUCTIONS_VALUE](state, action) {
    const { stateKey, key, value } = action;
    state = state.setIn(['tiles', stateKey, ...key], fromJS(value));
    state = setAreAnyFiltersApplied(state, stateKey);
    return state;
  },
});