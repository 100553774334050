import React from 'react';
import Loading from '../../shared/Loading';
import ErrorBoundary from '../../shared/ErrorBoundary';
import { HotTable, HotColumn } from "@handsontable/react";

import {
  AutoColumnSize,
  Autofill,
  ColumnSorting,
  CopyPaste,
  DropdownMenu,
  Filters,
  HiddenRows,
  registerPlugin,
} from 'handsontable/plugins';

import {
  registerCellType,
  CheckboxCellType,
  DropdownCellType,
  AutocompleteCellType
} from 'handsontable/cellTypes';

registerPlugin(AutoColumnSize);
registerPlugin(Autofill);
registerPlugin(ColumnSorting);
registerPlugin(CopyPaste);
registerPlugin(DropdownMenu);
registerPlugin(HiddenRows);
registerPlugin(Filters);

registerCellType(AutocompleteCellType);
registerCellType(CheckboxCellType);
registerCellType(DropdownCellType);

const CommentRendererComponent = (props) => {
  return (
    <React.Fragment>
      <div style={{ whiteSpace: 'nowrap' }}>{props.value}</div>
    </React.Fragment>
  );
}

const StatusRendererComponent = (props) => {

  let style = { textAlign: 'center' };  
  if(props.value === 'Cancelled') {
    style.backgroundColor = '#dadada';
  } else 
  if(props.value === 'Pending') {
    style.backgroundColor = '#f5f5ce';
  }

  return (
    <React.Fragment>
      <div style={style}>{props.value}</div>
    </React.Fragment>
  );
}

export default class LngDeals extends React.Component {  

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.initialise();
  }

  handleAfterChange(changes, source) {
    if (source === 'edit') {
      // here we are assuming that an edit will be a row at a time
      let data = this.tableRef.current.hotInstance.getData(); // get visible data
      var row = data[changes[0][0]]; // the id is the 1st col in the 1 change
      // we pass the id (which is position 2 in the data array)
      // plus the changed prop and it's value
      const id = row[2];
      const prop = changes[0][1];
      const value = changes[0][3];
      console.log({ id, prop, value});
      this.props.edited(id, prop, value);
    }
  }

  render() {

   const sortConfig = {
      initialConfig: {
        column: 3,
        sortOrder: 'desc'
      }
    };
    return (
      <div className='w-100' style={{ flexGrow: 1 }}>
        <ErrorBoundary>
          <Loading isLoading={this.props.isLoading}>

            <div className='mb-1'>
              <div className='row ml-1 mt-1'>
                <div className='btn-group'>
                  <button type='button' className='btn btn-primary' onClick={this.props.initialise}>Refresh</button>
                  <button type='button' className='btn btn-secondary' onClick={this.props.add}>Add</button>
                  <button type='button' className='btn btn-secondary' onClick={this.props.save}>Save</button>
                </div>
              </div>
            </div>
            <div>
              <HotTable root='hot' 
                        licenseKey='non-commercial-and-evaluation'
                        ref={this.tableRef} 
                        // renderAllRows={true}
                        data={this.props.deals}
                        // height={800}
                        style={{ height: 'calc(100vh - 126px)', overflowX: 'hidden' }}
                        dropdownMenu={['---------', 'filter_by_condition', 'filter_by_value', 'filter_action_bar']}
                        columnSorting={sortConfig}
                        contextMenu={true}
                        multiColumnSorting={true}          
                        filters={true}
                        rowHeaders={false}                        
                        afterChange={this.handleAfterChange.bind(this)}>
                    <HotColumn title='Mod' data='modified' className='htCenter' type='checkbox' readOnly={true} width={70}/>
                    <HotColumn title='Del' data='delete' className='htCenter' type='checkbox' width={70}/>
                    <HotColumn title='Id' data='id' className='htCenter' readOnly={true} />            
                    <HotColumn title='TenderClosingDate' data='tenderClosingDate' type='date' dateFormat='DD-MMM-YYYY' />
                    <HotColumn title='Status' data='status' type='dropdown' strict={true} source={this.props.status} >
                      <StatusRendererComponent hot-renderer />
                    </HotColumn>
                    <HotColumn title='Seller' data='seller' type='dropdown' source={this.props.entity} strict={true} />
                    <HotColumn title='Buyer' data='buyer' type='dropdown' source={this.props.entity} strict={true} />
                    <HotColumn title='Cargoes' data='cargoes' type='numeric' strict={true} />
                    <HotColumn title='Tbtu' data='tbtu' type='numeric' strict={true} />
                    <HotColumn title='Delivery' data='delivery' type='dropdown' source={this.props.delivery} strict={true} />
                    <HotColumn title='StartDate' data='startDate' type="date" dateFormat='DD-MMM-YYYY' />
                    <HotColumn title='EndDate' data='endDate' type="date" dateFormat='DD-MMM-YYYY' />
                    <HotColumn title='CountryOrigin' data='countryOrigin' type='dropdown' source={this.props.country} strict={true} />
                    <HotColumn title='CountryDestination' data='countryDestination' type='dropdown' source={this.props.country} strict={true} />
                    <HotColumn title='TerminalDestination' data='terminalDestination' />            
                    <HotColumn title='TerminalOrigin' data='terminalOrigin' />            
                    <HotColumn title='FixPrice' data='fixPrice' type='numeric' strict={true} />
                    <HotColumn title='PricingIndex' data='pricingIndex' />
                    <HotColumn title='Comment' data='comment' width={350} >
                      <CommentRendererComponent hot-renderer />
                    </HotColumn>
                    <HotColumn title='LastUpdated' data='lastUpdatedUtc' readOnly={true} />
                    <HotColumn title='LastUpdateBy' data='lastUpdatedBy' readOnly={true} />
              </HotTable>
            </div>
          </Loading>
        </ErrorBoundary>    
      </div>
    );
  }
}
