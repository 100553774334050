import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { toJS } from '../../../utility/immutable-utility';
import ReportsToolbar from './ReportsToolbar';
import ReportsTableConnected from './ReportsTableConnected';
import Loading from '../../shared/Loading';
import {
  reportsInitialiseSettings,
  reportsReload,
  reportsSnapshotsRefresh,
  reportsClear,
  reportsAdjustmentsEndEdit
} from '../../../actions/reports';
import {
  logWarningNotification,
  logErrorNotification
} from '../../../actions/log';
import { useLocation } from 'react-router';
import { ReportSelectionStatsConnected } from './ReportSelectionStatsConnected';

const ReportsWorkspaceLoader = connect(
  (state) => ({
    lastLoadedReportPath: state.getIn(['reports', 'lastLoadedReportPath']),
    reportPath: state.getIn(['reports', 'criteria', 'reportPath'])
  }),
  (dispatch) => ({
    loadReport() {
      dispatch(reportsClear());
      dispatch(reportsInitialiseSettings(() => {
        dispatch(reportsReload(() => {
           dispatch(reportsSnapshotsRefresh());
        }));
      }));
    }
  })
)(({ children, lastLoadedReportPath, reportPath, loadReport }) => {
  const _isCurrent = useMemo(() => lastLoadedReportPath === reportPath, [lastLoadedReportPath, reportPath]);

  useEffect(() => {
    if (!_isCurrent && !!reportPath && reportPath !== '/') loadReport();
  }, [loadReport, reportPath, _isCurrent]);

    return _isCurrent && children;
});

const ReportsViewer = connect(
  (state) => ({
    isLoading: state.getIn(['reports', 'isLoading']),
    elapsed: state.getIn(['reports', 'diagnostics', 'elapsed']),
    warnings: state.getIn(['reports', 'results', 'warnings']),
    errors: state.getIn(['reports', 'results', 'errors']),
    scenarioMode: state.getIn(['reports', 'results', 'settings', 'scenarioMode'])
    }),
  (dispatch) => ({
    endEditMode(){
      dispatch(reportsAdjustmentsEndEdit());
    },
    logWarnings(warnings) {
      Object.values(toJS(warnings, {})).forEach(({ key, name, details }) => dispatch(logWarningNotification(`${name} (${key}): ${details[0]}`)))
    },
    logErrors(errors) {
      Object.values(toJS(errors, {})).forEach(({ key, name, details }) => dispatch(logErrorNotification(`${name} (${key}): ${details[0]}`)))
    }
  })
)(({ isLoading, elapsed, warnings, errors, logWarnings, logErrors, scenarioMode, endEditMode }) => {
  const location = useLocation();
  
  return <ReportsWorkspaceLoader location={ location }>
    <Loading isLoading={ isLoading } message='Loading report...'>
      <div>
        <div className='container-fluid'>
          <ReportsToolbar />
        </div>
      </div>
      <ReportsTableConnected />
      <div className='mt-1 mx-2 d-flex justify-content-between flex-row' onDoubleClick={endEditMode}>
        <div style={{fontSize:'10px', marginLeft:'1em', marginRight:'1em'}}>Report was generated in {moment.duration(elapsed).asSeconds()} seconds
          { !!(warnings && warnings.size) && (
            <>
              <span> with </span>
              <b className='text-warning cursor-pointer' onClick={() => logWarnings(warnings)}>
                { `${warnings.size} warnings` }
              </b>
            </>
          ) }
          { !!(errors && errors.size) && (
            <>
              { (warnings && warnings.size) ? <span> and </span> : <span> with </span> }
              <b className='text-danger cursor-pointer' onClick={() => logErrors(errors)}>
                { `${errors.size} errors` }
              </b>
            </>
          ) }
          </div>
        <div style={{fontSize:'10px', marginLeft:'1em', marginRight:'1em'}} className='d-flex justify-content-between flex-row'>
          <ReportSelectionStatsConnected itemClassName='ml-3'/>
        </div>
      </div>
    </Loading>
  </ReportsWorkspaceLoader>;
});

export default ReportsViewer;