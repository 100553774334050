import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
  DASHBOARD_WORKSPACES_LOAD_STARTED,
  DASHBOARD_WORKSPACES_LOAD_COMPLETE,
  DASHBOARD_WORKSPACE_DELETE_STARTED,
  DASHBOARD_WORKSPACE_DELETE_COMPLETE,
  DASHBOARD_WORKSPACE_SELECT,
  DASHBOARD_WORKSPACES_CLEAR,
  DASHBOARD_WORKSPACES_SORT_UPDATED,
  DASHBOARD_WORKSPACES_FILTER_UPDATED,
} from '../actions/dashboard';
import { toJS } from '../utility/immutable-utility';
import moment from 'moment';

const toLower = (...args) => args.map(i => i.toLowerCase());

function sortStructuredData(data, orderBy, orderByDirection, filterType, filterText) {
  let filteredCount = 0;

  if (filterText && filterText.length > 0)
    filterText = filterText.toLowerCase();

  function recursiveSort(e) {
    if (!Array.isArray(e)) {
      if (e.children) {
        if (!e.children.some(c => c.itemType === 'Folder')) {
          e.children.sort(collectionSortFunc);
          if (filterText && filterText.length > 0) {
            if (filterType === 'name') {
              e.children = e.children.filter(s => s.name && s.name.toLowerCase().indexOf(filterText) >= 0);
            } else if (filterType === 'updatedBy') {
              e.children = e.children.filter(s => s.updatedBy && s.updatedBy.toLowerCase().indexOf(filterText) >= 0);
            }
          }

          filteredCount += e.children.length;
        }
        recursiveSort(e.children);
      }
      return;
    }

    for (let i = 0; i < e.length; i++) {
      if (e[i]) {
        recursiveSort(e[i]);
      }
    }
  }

  function collectionSortFunc(a, b) {
    const aName = `${a.name || ''}`.toLowerCase();
    const bName = `${b.name || ''}`.toLowerCase();

    let response = 0;
    switch (orderBy) {
      case 'updatedBy':
        if (a.updatedBy && b.updatedBy) {
          const aUpdatedBy = `${a.updatedBy || ''}`.toLowerCase();
          const bUpdatedBy = `${b.updatedBy || ''}`.toLowerCase();
          response = aUpdatedBy < bUpdatedBy ? -1 : aUpdatedBy > bUpdatedBy ? 1 : 0;
        }
        break;
      case 'updatedUtc':
        if (a.updatedUtc && b.updatedUtc) {
          const aUpdatedUtc = `${a.updatedUtc || ''}`.toLowerCase();
          const bUpdatedUtc = `${b.updatedUtc || ''}`.toLowerCase();
          response = aUpdatedUtc < bUpdatedUtc ? -1 : aUpdatedUtc > bUpdatedUtc ? 1 : 0;
        }
        break;
      default:
        response = 0;
        break;
    }

    if (response === 0)
      response = aName < bName ? -1 : aName > bName ? 1 : 0;

    if (orderByDirection !== 'asc')
      return 0 - response;

    return response;
  }

  recursiveSort(data);

  return { filteredCount: filteredCount, sorted: data };
}

export const dashboard = createReducer(null, {
  [DASHBOARD_WORKSPACES_LOAD_STARTED](state, action) {
    const { type, scope } = action.data;

    if (!type || !scope) return state;

    const [_type, _scope] = toLower(type, scope);

    return state.setIn(['workspaces', _type, _scope, 'isLoading'], true);
  },
  [DASHBOARD_WORKSPACES_LOAD_COMPLETE](state, action) {
    if (!action.data) return state;
    
    const { area, type, scope, data = [] } = action.data;

    if (!area || !type || !scope) return state;

    const [_area, _type, _scope] = toLower(area, type, scope);

    const orderBy = state.getIn(['workspaces', _area, _type, _scope, 'orderBy']) || 'name';
    const orderByDirection = state.getIn(['workspaces', _area, _type, _scope, 'orderByDirection']) || 'asc';
    const filterType = state.getIn(['workspaces', area, 'filter', 'filterType'], 'name');
    const filterText = state.getIn(['workspaces', area, 'filter', 'filterText'], '');

    const structuredData = data ? data.sort((a, b) => {
      const folderA = `${a.folderPath || ''}${a.name || ''}`.toLowerCase();
      const folderB = `${b.folderPath || ''}${b.name || ''}`.toLowerCase();

      return folderA < folderB ? -1 : folderA > folderB ? 1 : 0;
    }).reduce((accumulator, item) => {
      const pathParts = item.folderPath ? item.folderPath.split('/').filter(i => i) : [];

      let parent = accumulator[0];

      for (let i = 0; i < pathParts.length; i++) {
        let part = pathParts[i];
        let child = parent.children.find(i => i.name === part && i.itemType === 'Folder');

        if (!child) {
          child = { name: part, itemType: 'Folder', folderPath: item.folderPath, children: [], displayChildren: true };

          parent.children.push(child);
        }

        parent = child;
      }

      parent.children.push(item);

      return accumulator;
    }, [{ name: '/', itemType: 'Root', folderPath: '', children: [] }]) : data;

    const { filteredCount, sorted } = sortStructuredData(structuredData, orderBy, orderByDirection, filterType, filterText);

    return state.setIn(['workspaces', _area, _type, _scope, 'structuredData'], fromJS(structuredData))
      .setIn(['workspaces', _area, _type, _scope, 'data'], fromJS(sorted))
      .setIn(['workspaces', _area, _type, _scope, 'metrics-total'], data.length)
      .setIn(['workspaces', _area, _type, _scope, 'metrics-filtered'], filteredCount)
      .setIn(['workspaces', _area, _type, _scope, 'metrics-refreshed'], moment().format('YYYY-MM-DD  HH:mm'))
      .setIn(['workspaces', _area, _type, _scope, 'isLoading'], false);
  },
  [DASHBOARD_WORKSPACE_DELETE_STARTED](state, action) {
    const { id, area, type, scope } = action.data;

    if (!id || !area || !type || !scope) return state;

    const [_area, _type, _scope] = toLower(area, type, scope);

    return state.setIn(['workspaces', _area, _type, _scope, 'isLoading'], true);
  },
  [DASHBOARD_WORKSPACE_DELETE_COMPLETE](state, action) {
    const { id, type, scope } = action.data;

    if (!id || !type || !scope) return state;

    const [_type, _scope] = toLower(type, scope);

    function findKeyPath(items, id, keyPath = []) {
      const index = items ? items.findIndex(i => i.get('id') === id) : -1;

      if (index >= 0) return [...keyPath, index];

      for (let i = 0, item; items && i < items.size && (item = items.get(i)); i++) {
        const children = item.get('children');

        if (!children || !children.size) continue;

        const _keyPath = findKeyPath(children, id, [...keyPath, i, 'children']);

        if (_keyPath) return _keyPath;
      }

      return null;
    }

    const startPaths = [
      ['workspaces', 'homepage', _type, _scope, 'structuredData'],
      ['workspaces', 'reports', _type, _scope, 'structuredData'],
      ['workspaces', 'homepage', _type, _scope, 'data'],
      ['workspaces', 'reports', _type, _scope, 'data']
    ];

    let newState = state;

    startPaths.forEach(startPath => {
      const keyPath = findKeyPath(newState.getIn(startPath), id, startPath);

      newState = keyPath ? newState.deleteIn(keyPath) : newState;
    });

    return newState;
  },
  [DASHBOARD_WORKSPACE_SELECT](state, action) {
    return state.setIn(['workspaces', 'selected'], action.data ? fromJS(action.data) : null);
  },
  [DASHBOARD_WORKSPACES_CLEAR](state, action) {
    const { area, type, scope } = action.data;

    if (!type || !scope) return state;

    const [_area, _type, _scope] = toLower(area || '', type, scope);

    if (_area)
      return state.setIn(['workspaces', _area, _type, _scope, 'structuredData'], fromJS([]))
                  .setIn(['workspaces', _area, _type, _scope, 'data'], fromJS([]));

    return state.setIn(['workspaces', 'homepage', _type, _scope, 'structuredData'], fromJS([]))
                .setIn(['workspaces', 'reports', _type, _scope, 'structuredData'], fromJS([]))
                .setIn(['workspaces', 'homepage', _type, _scope, 'data'], fromJS([]))
                .setIn(['workspaces', 'reports', _type, _scope, 'data'], fromJS([]));
  },
  [DASHBOARD_WORKSPACES_SORT_UPDATED](state, action) {

    const { area, type, scope } = action.data;
    let { orderBy, orderByDirection } = action.data;
    const [_area, _type, _scope] = toLower(area, type, scope);

    if (!orderBy) {
      orderBy = state.getIn(['workspaces', _area, _type, _scope, 'orderBy']);
      orderByDirection = state.getIn(['workspaces', _area, _type, _scope, 'orderByDirection']);
    }

    let filterType = state.getIn(['workspaces', _area, 'filter', 'filterType']);
    let filterText = state.getIn(['workspaces', _area, 'filter', 'filterText']);

    const structuredData = toJS(state.getIn(['workspaces', _area, _type, _scope, 'structuredData']));
    const { filteredCount, sorted } = sortStructuredData(structuredData, orderBy, orderByDirection, filterType, filterText);

    return state.setIn(['workspaces', _area, _type, _scope, 'orderBy'], orderBy)
      .setIn(['workspaces', _area, _type, _scope, 'orderByDirection'], orderByDirection)
      .setIn(['workspaces', _area, _type, _scope, 'data'], fromJS(sorted))
      .setIn(['workspaces', _area, _type, _scope, 'metrics-filtered'], filteredCount);
  },
  [DASHBOARD_WORKSPACES_FILTER_UPDATED](state, action) {
    const { area, filterText, filterType } = action.data;

    (area ==='homepage' ? ['analysis', 'report'] : ['report']).forEach(_type => {
      ['private', 'shared'].forEach(_scope => {
        const structuredData = toJS(state.getIn(['workspaces', area, _type, _scope, 'structuredData']));
        const orderBy = state.getIn(['workspaces', area, _type, _scope, 'orderBy']);
        const orderByDirection = state.getIn(['workspaces', area, _type, _scope, 'orderByDirection']);
        const { filteredCount, sorted } = sortStructuredData(structuredData, orderBy, orderByDirection, filterType, filterText);

        state = state.setIn(['workspaces', area, _type, _scope, 'data'], fromJS(sorted))
          .setIn(['workspaces', area, _type, _scope, 'metrics-filtered'], filteredCount);
      });
    });

    return state.setIn(['workspaces', area, 'filter', 'filterType'], filterType)
      .setIn(['workspaces', area, 'filter', 'filterText'], filterText);
  }
});