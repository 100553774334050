import React from 'react';
import { connect } from 'react-redux';
import {
  analysisCompositionTierToggleExpanded
} from '../../../actions/analysis-composition-v2';

const CompositionTierToggle = connect(
  (state) => ({
    selectedLevel: state.getIn(['analysis', 'composition', 'selectedLevel']),
    maxLevel: state.getIn(['analysis', 'composition', 'maxLevel'])
  }),
  (dispatch) => ({
    toggleExpand(selectedLevel) {
      dispatch(analysisCompositionTierToggleExpanded(selectedLevel));
    }
  })
)(({ selectedLevel, maxLevel, toggleExpand }) => {
  if (maxLevel <= 1)
    return <div />;

  const tiers = new Array(maxLevel).fill(false);

  if (selectedLevel < tiers.length)
    tiers[selectedLevel] = true;
  else if (tiers.length)
    tiers[tiers.length - 1] = true;

  return (
    <div className='text-left' style={{ minWidth: '26em' }}>
      <div className='btn-group'>
        { tiers.map((i, ix) => (
          <button key={ ix } type='button' className={ `btn btn-sm btn${!i ? '-outline' : ''}-primary rounded-lg ml-1 py-0 px-1 font-weight-bold` }
                  onClick={ () => toggleExpand(ix) } style={{ fontSize: '.8rem' }}>
            { ix + 1 }
          </button>
        )) }
      </div>
    </div>
  );
});

export default CompositionTierToggle;