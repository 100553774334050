import moment from 'moment';
import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
  ETL_JOB_HISTORY_FETCH_STARTED,
  ETL_JOB_HISTORY_FETCH_COMPLETE
} from '../actions/etlJob';

export const etlJobHistory = createReducer(null, {
  [ETL_JOB_HISTORY_FETCH_STARTED](state, action) {
    return state.set('isLoading', true);
  },
  [ETL_JOB_HISTORY_FETCH_COMPLETE](state, action) {
    const { history, totalCount, page } = action;
    if (!history)
      return state.set('isLoading', false);

    let mapped = history.map(x => {
      if (x.startedAtUtc && x.stoppedAtUtc)
        x.elapsed = moment.duration(moment.utc(x.stoppedAtUtc).subtract(moment.utc(x.startedAtUtc)));

      if (x.startedAtUtc)
        x.startedAtUtc = moment.utc(x.startedAtUtc);

      if (x.stoppedAtUtc)
        x.stoppedAtUtc = moment.utc(x.stoppedAtUtc);

      return x;
    });

    return state
      .set('isLoading', false)
      .set('items', fromJS(mapped))
      .set('page', page)
      .set('totalCount', totalCount);
  }
});