import React from 'react';
import ShapesBuilder from './ShapesBuilder';

export default function ShapesEditor({
  shapeName, isBusy, isSavingShape, isDeletingShape, isSelectingShape, isExistingShape, availableShapes,
  shapeTemplateMap, setShapeName, selectShape, saveShape, deleteShape,
  shapeTemplateMapIsDirty, toggleShapeCellSelection, toggleShapeAllSelection, toggleShapeBucketSelection, toggleShapeGroupSelection,
  selectedShapes, addShapeErrorMessage, addShapeToSelection, removeSelectedShape, removeAllSelectedShapes, applySelectionToBasket
}) {

  function onApplyShape() {
    applySelectionToBasket();
  }

  function onAddShapeToSelection() {
    addShapeToSelection();
  }

  function onRemoveSelectedShape(shape) {
    removeSelectedShape(shape);
  }

  function onRemoveAllSelection() {
    removeAllSelectedShapes();
  }

  return <div className='modal-content'>
    <div className='modal-header'>
      <h5 className='modal-title'>Shape</h5>
      <button type='button' className='close' data-dismiss='modal'>&times;</button>
    </div>
    <div className='modal-body'>
        <ShapesBuilder shapeName={shapeName} 
                       isBusy={isBusy}
                       isSavingShape={isSavingShape} 
                       isDeletingShape={isDeletingShape}
                       isSelectingShape={isSelectingShape}
                       isExistingShape={isExistingShape} 
                       availableShapes={availableShapes}
                       shapeTemplateMap={shapeTemplateMap}
                       setShapeName={setShapeName}
                       selectShape={selectShape} 
                       saveShape={saveShape} 
                       deleteShape={deleteShape} 
                       shapeTemplateMapIsDirty={shapeTemplateMapIsDirty}
                       toggleShapeCellSelection={toggleShapeCellSelection}
                       toggleShapeAllSelection={toggleShapeAllSelection}
                       toggleShapeBucketSelection={toggleShapeBucketSelection} 
                       toggleShapeGroupSelection={toggleShapeGroupSelection} />

      <div>
        <div className='form-group text-right mt-2'>
          <button type='button' className='btn btn-primary' onClick={() => onAddShapeToSelection()}>Add Shape</button>
        </div>
        {addShapeErrorMessage && <div className="alert alert-warning" >{addShapeErrorMessage}</div>}
      </div>

      <hr />

      <div className='form-group'>
        <label className='font-weight-bold'>Selected Shapes</label>
        <div className='input-group' >
          <div className='form-control' style={{ height: "6em", overflowY: "auto" }}>
            {selectedShapes && selectedShapes.map(s => <div key={s.name} title={s.description} className='btn btn-outline-secondary btn-sm mr-1 mb-1 ml-1 mt-1' onClick={() => onRemoveSelectedShape(s)} >
              {s.name}
              <i className='fas fa-times fa-fw' />
            </div>)}
          </div>
          <div className='input-group-append dropdown'>
            {selectedShapes && selectedShapes.length > 0 && <button type='button' className='btn btn-outline-secondary' onClick={onRemoveAllSelection} >
              <i className='fas fa-times fa-fw' />
            </button>}
          </div>
        </div>
      </div>
    </div>

    <div className='modal-footer'>
      <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
      <button type='button' className='btn btn-primary' data-dismiss='modal' onClick={onApplyShape}>Apply</button>
    </div>
  </div>
}