import React from 'react';
import { connect } from 'react-redux';
import { toolboxExec, 
         toolboxToggleMaterialisation, 
         toolboxToggleVariants, 
         toolboxToggleTestUnsavedChanges,
         toolboxUpdateProperty } from '../../../../actions/toolbox';
import { toJS } from '../../../../utility/immutable-utility';
import Toolbox from './Toolbox';

const ToolboxConnected = connect(
  (state) => ({
    lenses: state.getIn(['referenceData', 'lenses']),
    operations: state.getIn(['referenceData', 'operations']),
    timezones: state.getIn(['referenceData', 'timezones']),
    identityId: state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']),
    isLoading:state.getIn(['toolbox', 'isLoading']), 
    lens: state.getIn(['toolbox', 'lens']),
    operation: state.getIn(['toolbox', 'operation']),
    shapeName: state.getIn(['toolbox', 'shapeName']),
    fromDate: state.getIn(['toolbox', 'fromDate']),
    toDate: state.getIn(['toolbox', 'toDate']),
    timezone: state.getIn(['toolbox', 'timezone']),
    asAtDate: state.getIn(['toolbox', 'asAtDate']),
    take: state.getIn(['toolbox', 'take']),
    dpCount: state.getIn(['toolbox', 'dpCount']),
    disableMaterialisation: state.getIn(['toolbox', 'disableMaterialisation']),
    disableVariants: state.getIn(['toolbox', 'disableVariants']),
    testUnsavedChanges: state.getIn(['toolbox', 'testUnsavedChanges']),
    inputs: state.getIn(['toolbox', 'inputs']),
    steps: state.getIn(['toolbox', 'steps']),
    result: state.getIn(['toolbox', 'result']),    
  }),
  (dispatch) => ({
    execute(identityId) {
      dispatch(toolboxExec(identityId));
    },
    toggleMaterialisation() {
      dispatch(toolboxToggleMaterialisation());
    },
    toggleVariants() {
      dispatch(toolboxToggleVariants());
    },
    toggleTestUnsavedChanges() {
      dispatch(toolboxToggleTestUnsavedChanges());
    },
    updateProperty(prop, value) {
      dispatch(toolboxUpdateProperty(prop, value));
    }
  })
)(({ lenses, operations, timezones, identityId, isLoading, lens, operation, timezone, shapeName, fromDate, toDate, asAtDate, take, dpCount, disableMaterialisation, disableVariants, testUnsavedChanges, inputs, steps, result, 
    updateProperty, toggleMaterialisation, toggleVariants, toggleTestUnsavedChanges, execute }) => {

  const _inputs = toJS(inputs);
  const _steps = toJS(steps);
  const _result = toJS(result);

  return <Toolbox identityId={identityId}
                  isLoading={isLoading}
                  lenses={lenses}
                  operations={operations}
                  timezones={timezones}
                  lens={lens}
                  operation={operation}
                  shapeName={shapeName}
                  fromDate={fromDate}
                  toDate={toDate}
                  timezone={timezone}
                  asAtDate={asAtDate}
                  take={take}
                  disableMaterialisation={disableMaterialisation}
                  disableVariants={disableVariants}
                  testUnsavedChanges={testUnsavedChanges}
                  dpCount={dpCount}
                  inputs={_inputs}
                  steps={_steps}
                  result={_result}
                  updateProperty={updateProperty}
                  toggleMaterialisation={toggleMaterialisation}
                  toggleVariants={toggleVariants}
                  toggleTestUnsavedChanges={toggleTestUnsavedChanges}
                  execute={execute} />;
});

export default ToolboxConnected;