import { memo } from 'react';
import moment from 'moment';

export default memo(function FormattedDateTime({ children, displayFormat = 'DD-MMM-YYYY HH:mm:ss' }) {
  return formatDateTime(children, displayFormat);
});

export const reactWidgetDateTimeFormat = {
  includeTime:true,
  valueFormat: 'DD MMM YYYY HH:mm'
}

export function formatDateTime(value, displayFormat = 'DD-MMM-YYYY HH:mm:ss' ){
  if (value === null || value === undefined || value === '') return '';
  return moment.utc(value).format(displayFormat);
}