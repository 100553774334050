import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logErrorNotification,
} from './log';
import { toJS } from '../utility/immutable-utility';

export const ANALYSIS_SHAPES_INIT = 'ANALYSIS_SHAPES_INIT';
export const analysisShapesInit = createAction(ANALYSIS_SHAPES_INIT, 'shape');

export const ANALYSIS_SHAPES_REMOVE_SELECTED = 'ANALYSIS_SHAPES_REMOVE_SELECTED';
export const analysisShapesRemoveSelected = createAction(ANALYSIS_SHAPES_REMOVE_SELECTED, 'shape');

export const ANALYSIS_SHAPES_REMOVE_ALL_SELECTION = 'ANALYSIS_SHAPES_REMOVE_ALL_SELECTION';
export const analysisShapesRemoveAllSelection = createAction(ANALYSIS_SHAPES_REMOVE_ALL_SELECTION);

export const ANALYSIS_SHAPES_TOGGLE_SHAPE_CELL_SELECTION = 'ANALYSIS_SHAPES_TOGGLE_SHAPE_CELL_SELECTION';
export const analysisShapesToggleShapeCellSelection = createAction(ANALYSIS_SHAPES_TOGGLE_SHAPE_CELL_SELECTION, 'group', 'bucket');

export const ANALYSIS_SHAPES_TOGGLE_SHAPE_ALL_SELECTION = 'ANALYSIS_SHAPES_TOGGLE_SHAPE_ALL_SELECTION';
export const analysisShapesToggleShapeAllSelection = createAction(ANALYSIS_SHAPES_TOGGLE_SHAPE_ALL_SELECTION);

export const ANALYSIS_SHAPES_TOGGLE_SHAPE_BUCKET_SELECTION = 'ANALYSIS_SHAPES_TOGGLE_SHAPE_BUCKET_SELECTION';
export const analysisShapesToggleShapeBucketSelection = createAction(ANALYSIS_SHAPES_TOGGLE_SHAPE_BUCKET_SELECTION, 'bucketName');

export const ANALYSIS_SHAPES_TOGGLE_SHAPE_GROUP_SELECTION = 'ANALYSIS_SHAPES_TOGGLE_SHAPE_GROUP_SELECTION';
export const analysisShapesToggleShapeGroupSelection = createAction(ANALYSIS_SHAPES_TOGGLE_SHAPE_GROUP_SELECTION, 'group');

export const ANALYSIS_TOGGLE_BASKET_MATERIALISATION_MODE = 'ANALYSIS_TOGGLE_BASKET_MATERIALISATION_MODE';
export const analysisToggleBasketMaterialisationMode = createAction(ANALYSIS_TOGGLE_BASKET_MATERIALISATION_MODE);

export const ANALYSIS_TOGGLE_BASKET_VARIANT_MODE = 'ANALYSIS_TOGGLE_BASKET_VARIANT_MODE';
export const analysisToggleBasketVariantMode = createAction(ANALYSIS_TOGGLE_BASKET_VARIANT_MODE);

export const ANALYSIS_SHAPES_SET_SHAPE_NAME = 'ANALYSIS_SHAPES_SET_SHAPE_NAME';
export const analysisShapesSetShapeName = createAction(ANALYSIS_SHAPES_SET_SHAPE_NAME, 'name');

export const ANALYSIS_SHAPES_SELECT_SHAPE = 'ANALYSIS_SHAPES_SELECT_SHAPE';
export const analysisShapesSelectShape = (shapeId, callback) => (dispatch, getState) => {
  dispatch(analysisShapesSelectShapeStarted());
  if (!shapeId) {
    dispatch(analysisShapesSelectShapeComplete({data: {}}));
    if (callback)
      callback();

    return;
  }

  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/shapes/${shapeId}`)
    .then(response => response.json())
    .then(data => {
      if (data && typeof data.data === 'string')
        data.data = JSON.parse(data.data);

      dispatch(analysisShapesSelectShapeComplete(data));
      if (callback)
        callback(data.data);      
    })
    .catch(error => {
      dispatch(analysisShapesSelectShapeComplete());
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_SHAPES_SELECT_SHAPE_STARTED = 'ANALYSIS_SHAPES_SELECT_SHAPE_STARTED';
const analysisShapesSelectShapeStarted = createAction(ANALYSIS_SHAPES_SELECT_SHAPE_STARTED);

export const ANALYSIS_SHAPES_SELECT_SHAPE_COMPLETED = 'ANALYSIS_SHAPES_SELECT_SHAPE_COMPLETED';
const analysisShapesSelectShapeComplete = createAction(ANALYSIS_SHAPES_SELECT_SHAPE_COMPLETED, 'data');

export const ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES = 'ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES';
export const analysisShapesLoadAvailableShapes = (selectedShape) => (dispatch, getState) => {
  dispatch(analysisShapesLoadAvailableShapesStarted());
  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/shapes`)
    .then(response => response.json())
    .then(availableShapes => {
      dispatch(analysisShapesLoadAvailableShapesComplete(availableShapes));
      if (selectedShape){
        dispatch(analysisShapesSelectShapeComplete({
          name:selectedShape.name,
          data:selectedShape}));
      }
    })
    .catch(error => {
      dispatch(analysisShapesLoadAvailableShapesComplete());
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_STARTED = 'ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_STARTED';
const analysisShapesLoadAvailableShapesStarted = createAction(ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_STARTED);

export const ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_COMPLETE = 'ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_COMPLETE';
const analysisShapesLoadAvailableShapesComplete = createAction(ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_COMPLETE, 'data');

export const ANALYSIS_SHAPES_SAVE_SHAPE = 'ANALYSIS_SHAPES_SAVE_SHAPE';
export const analysisShapesSaveShape = (callback) => (dispatch, getState) => {
  const state = getState();
  const lens = state.getIn(['analysis', 'workspace', 'lens']);
  const shapesSettings = toJS(state.getIn(['analysis', 'ui', 'shapesSettings']));
  if (!shapesSettings.shapeName || shapesSettings.shapeName.trim() === '')
    return;

  dispatch(analysisShapesSaveShapesStarted());

  const innerData = {
    onItems: [],
    name: shapesSettings.shapeName,
    groups: shapesSettings.shapeTemplateMap.groups.map(g => ({ name: g.name, index: g.index })),
    buckets: shapesSettings.shapeTemplateMap.buckets,
  };

  shapesSettings.shapeTemplateMap.groups.forEach(group => {
    group.buckets.forEach(bucket => {
      if (bucket.isSelected) {
        innerData.onItems.push({
          group: `${group.index}`,
          bucket: bucket.name
        })
      }
    });
  });

  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/shapes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name: shapesSettings.shapeName,
      lens: lens,
      data: JSON.stringify(innerData)
    })
  })
    .then(response => response.json())
    .then(data => {
      dispatch(analysisShapesSaveShapesComplete(data));
      if (callback){
        callback(innerData)
      }
    })
    .catch(error => {
      dispatch(analysisShapesSaveShapesComplete());
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_SHAPES_SAVE_SHAPE_STARTED = 'ANALYSIS_SHAPES_SAVE_SHAPE_STARTED';
export const analysisShapesSaveShapesStarted = createAction(ANALYSIS_SHAPES_SAVE_SHAPE_STARTED);

export const ANALYSIS_SHAPES_SAVE_SHAPE_COMPLETE = 'ANALYSIS_SHAPES_SAVE_SHAPE_COMPLETE';
export const analysisShapesSaveShapesComplete = createAction(ANALYSIS_SHAPES_SAVE_SHAPE_COMPLETE, 'data');

export const ANALYSIS_SHAPES_DELETE_SHAPE = 'ANALYSIS_SHAPES_DELETE_SHAPE';

export const analysisShapesDeleteShape = () => (dispatch, getState) => {
  const state = getState();
  const shapesSettings = toJS(state.getIn(['analysis', 'ui', 'shapesSettings']));
  const shape = shapesSettings.availableShapes.find(s => s.name === shapesSettings.shapeName);
  if (!shape)
    return;

  dispatch(analysisShapesDeleteShapesStarted());

  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/shapes/${shape.id}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch(analysisShapesDeleteShapesComplete(shape.id));      
    })
    .catch(error => {
      dispatch(analysisShapesDeleteShapesComplete());
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_SHAPES_DELETE_SHAPE_STARTED = 'ANALYSIS_SHAPES_DELETE_SHAPE_STARTED';
export const analysisShapesDeleteShapesStarted = createAction(ANALYSIS_SHAPES_DELETE_SHAPE_STARTED);

export const ANALYSIS_SHAPES_DELETE_SHAPE_COMPLETE = 'ANALYSIS_SHAPES_DELETE_SHAPE_COMPLETE';
export const analysisShapesDeleteShapesComplete = createAction(ANALYSIS_SHAPES_DELETE_SHAPE_COMPLETE, 'data');


export const ANALYSIS_SHAPES_ADD_SHAPE_TO_SELECTION = 'ANALYSIS_SHAPES_ADD_SHAPE_TO_SELECTION';
export const analysisShapesAddShapeToSelection = createAction(ANALYSIS_SHAPES_ADD_SHAPE_TO_SELECTION);

export const ANALYSIS_SHAPES_REMOVE_SELECTED_SHAPE = 'ANALYSIS_SHAPES_REMOVE_SELECTED_SHAPE';
export const analysisShapesRemoveSelectedShape = createAction(ANALYSIS_SHAPES_REMOVE_SELECTED_SHAPE, 'name');

export const ANALYSIS_SHAPES_REMOVE_ALL_SELECTED_SHAPES = 'ANALYSIS_SHAPES_REMOVE_ALL_SELECTED_SHAPES';
export const analysisShapesRemoveAllSelectedShapes = createAction(ANALYSIS_SHAPES_REMOVE_ALL_SELECTED_SHAPES);

export const ANALYSIS_SHAPES_APPLY_SELECTION_TO_WORKSPACE = 'ANALYSIS_SHAPES_APPLY_SELECTION_TO_WORKSPACE';
export const analysisShapesApplySelectionToWorkspace = createAction(ANALYSIS_SHAPES_APPLY_SELECTION_TO_WORKSPACE);

export const ANALYSIS_SHAPES_UPDATE_BASKET_FROM_WORKSPACE = 'ANALYSIS_SHAPES_UPDATE_BASKET_FROM_WORKSPACE';
export const analysisShapesUpdateBasketFromWorkspace = createAction(ANALYSIS_SHAPES_UPDATE_BASKET_FROM_WORKSPACE);