import React from 'react';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import VariantsEditorToolbar from './VariantsEditorToolbar';
import VariantsEditorTable from './VariantsEditorTable';

export default function VariantsEditor({ currentTimeSeriesId, referenceData, variants, variantEdit,
  materialiseRangeDialogVisible, materialiseRangeFromUtc, materialiseRangeToUtc, requestRematerialise, requestRematerialiseWithRange, setMaterialiseRangeDialogVisible, setMaterialiseRangeDate,
  suppressArchive, setSuppressArchive,
  loadVariants, addVariant, cancelVariantEdit, setVariantValue, saveVariant, isContributor }) {
  useDidMountEffect(() => loadVariants(currentTimeSeriesId));

  return (
    <div className='w-100 d-flex flex-column h-100'>
      <div className='mb-1'>
        <VariantsEditorToolbar identityId={ currentTimeSeriesId }
                               referenceData={ referenceData }
                               currentTimeSeriesId={ currentTimeSeriesId }
                               variants={ variants }
                               variantEdit={ variantEdit }
                               loadVariants={ loadVariants }
                               addVariant={ addVariant }
                               cancelVariantEdit={ cancelVariantEdit }
                               setVariantValue={ setVariantValue }
                               saveVariant={ saveVariant }
                               materialiseRangeDialogVisible={materialiseRangeDialogVisible}
                               materialiseRangeFromUtc={materialiseRangeFromUtc}
                               materialiseRangeToUtc={materialiseRangeToUtc}
                               requestRematerialise={ requestRematerialise }
                               requestRematerialiseWithRange={requestRematerialiseWithRange}
                               setMaterialiseRangeDialogVisible={setMaterialiseRangeDialogVisible} 
                               setMaterialiseRangeDate={setMaterialiseRangeDate}
                               suppressArchive={suppressArchive}
                               setSuppressArchive={setSuppressArchive}
                               isContributor={isContributor} />
      </div>
      <div className='flex-fill' style={{ overflowY: 'auto', flexGrow: 1 }}>
        <VariantsEditorTable identityId={ currentTimeSeriesId }
                             referenceData={ referenceData }
                             currentTimeSeriesId={ currentTimeSeriesId }
                             variants={ variants }
                             variantEdit={ variantEdit }
                             loadVariants={ loadVariants }
                             addVariant={ addVariant }
                             cancelVariantEdit={ cancelVariantEdit }
                             setVariantValue={ setVariantValue }
                             saveVariant={ saveVariant }
                             requestRematerialise={ requestRematerialise }
                             isContributor={isContributor} />
      </div>
    </div>
  );
}