import moment from 'moment';

export function mapToAbsRelDate(mode, abs, rel) {
	return {
		mode: mode === 'rel' ? 'rel' : 'abs',
		abs: moment.utc(abs).startOf('day').format('YYYY-MM-DD'),
		rel: rel
	}
}

export function mapToURLSearchParams(urlSearchParams, urlName, absRelDate) {
	if (absRelDate.toJS) {
		if (absRelDate.get('mode') === 'abs')
			urlSearchParams.set(`${urlName}Abs`, absRelDate.get('abs'));
		else
			urlSearchParams.set(`${urlName}Rel`, absRelDate.get('rel'));
	} else {
		if (absRelDate.mode === 'abs')
			urlSearchParams.set(`${urlName}Abs`, absRelDate.abs);
		else
			urlSearchParams.set(`${urlName}Rel`, absRelDate.rel);
	}
}

export function mapFromURLSearchParams(urlSearchParams, urlName) {
	if (urlSearchParams.get(`${urlName}Abs`)) {
		return {
			mode: 'abs',
			abs: urlSearchParams.get(`${urlName}Abs`)
		};
	} else if (urlSearchParams.get(`${urlName}Rel`)) {
		return {
			mode: 'rel',
			rel: urlSearchParams.get(`${urlName}Rel`)
		};
	}
}

export function mapDashboardCriteriaDateFromToAbsRelDate(criteria) {
	return mapToAbsRelDate(criteria.get('fromDateMode'), criteria.get('absFromDate'), criteria.get('relFromDate'));
}

export function mapDashboardCriteriaDateToToAbsRelDate(criteria) {
	return mapToAbsRelDate(criteria.get('toDateMode'), criteria.get('absToDate'), criteria.get('relToDate'));
}