import { fromJS, is } from 'immutable';
import {
  ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_COMPLETE,
  ANALYSIS_DYNAMIC_WORKSPACE_BEGIN_EDIT,
  ANALYSIS_DYNAMIC_WORKSPACE_END_EDIT,
  ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_SAVE_COMPLETE,
  ANALYSIS_DYNAMIC_WORKSPACE_DELETE,
  ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_COMPLETE,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_ADD_DYNAMIC_FILTER,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DELETE_DYNAMIC_FILTER,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_TOGGLE_EXCLUSION_CATEGORY,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_ALIAS,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_COMPLETE,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_COMPLETE,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_COMPLETE,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_MULTISELECT_VALUE_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_COMPLETE,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_COMPLETE,
  ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_RESET_PREVIOUS_DYNAMIC_KEYS,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DESIGN_CHANGED
} from '../actions/analysis-dynamic-workspace-editor';
import { toJS } from '../utility/immutable-utility';
import {
  applyDynamicWorkspaceTimeSeries,
  getPivotCategories,
  updateSubstitionKeyExclusionCategories,
  buildIntersectionOfCategories,
  removeDynamicWorkspaceFromBaselineBasket,
  getTimeSeriesForDynamicWorkspace
} from '../reducer-functions/analysis-dynamic-workspace';
import { guid } from '../utility/uid-utility';
import { mapSchemasToDynamicFilters, mapToDynamicWorkspaceBaseline } from '../mapper/analysisDynamicWorkspaceMapper';
import getInitialState from '../state';

export const dynamicWorkspaceEditorReducer = {
  [ANALYSIS_DYNAMIC_WORKSPACE_BEGIN_EDIT](state, action) {
    const { key, searchCriteria } = action;

    let dynamicWorkspace = undefined;
    let dynamicFilters = undefined;
    if (!key) {
      const defaultState = toJS(getInitialState());
      dynamicWorkspace = defaultState.analysis.dynamicWorkspaceDesign.dynamicWorkspace;
      dynamicFilters = defaultState.analysis.dynamicWorkspaceDesign.dynamicFilters;
      dynamicWorkspace.searchCriteria = searchCriteria;
      mapSchemasToDynamicFilters(dynamicFilters, searchCriteria);
    } else {
      const dynamicWorkspaces = toJS(state.getIn(['workspace', 'dynamicWorkspaces']));
      dynamicWorkspace = dynamicWorkspaces.find(d => d.key === key);
      dynamicFilters = dynamicWorkspace.dynamicFilters;
      dynamicWorkspace.previousDynamicKeys = [];
      if (dynamicWorkspace.dynamicFilters) {
        dynamicWorkspace.dynamicFilters.forEach(d => {
          d.selectedCategory = d.baselineSelectedCategory ?? d.selectedCategory;
          d.selectedValue = d.baselineSelectedValue ?? d.selectedValue;
          d.selectedValues = d.baselineMultiSelectValues ?? d.selectedValues;
        });
      }
    }

    return state
      .setIn(['dynamicWorkspaceDesign', 'showModal'], true)
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace'], fromJS(dynamicWorkspace))
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspaceBaseline'], fromJS(mapToDynamicWorkspaceBaseline(dynamicWorkspace)))
      .setIn(['dynamicWorkspaceDesign', 'previousSearchCriteria'], fromJS(dynamicWorkspace.searchCriteria))
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters'], fromJS(dynamicFilters));
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_END_EDIT](state, action) {
    const { key } = action;
    return state
      .setIn(['dynamicWorkspaceDesign', 'showModal'], false)
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'key'], key);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BEGIN](state, action) {
    return state.setIn(['dynamicWorkspaceDesign', 'isSaving'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_SAVE_COMPLETE](state, action) {
    state = state.setIn(['dynamicWorkspaceDesign', 'isSaving'], false);

    const { dynamicWorkspace, dynamicRows } = action;
    if (!dynamicWorkspace || !dynamicRows)
      return state;

    dynamicWorkspace.dynamicFilters = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters']).toJS();
    delete dynamicWorkspace.previousDynamicKeys;

    // set the baseline to the current value and save this in the baseline
    dynamicWorkspace.dynamicFilters.forEach(d => {
      d.baselineSelectedCategory = d.selectedCategory;
      d.baselineSelectedValue = d.selectedValue;
      d.baselineMultiSelectValues = d.multiSelectValues;
    });

    if (dynamicWorkspace.key === 'new')
      dynamicWorkspace.key = guid();

    const dynamicWorkspaces = toJS(state.getIn(['workspace', 'dynamicWorkspaces']));
    const existing = dynamicWorkspaces.find(d => d.key === dynamicWorkspace.key);
    if (existing) {
      Object.assign(existing, dynamicWorkspace);
    } else {
      dynamicWorkspaces.push(dynamicWorkspace);
    }

    const basket = applyDynamicWorkspaceTimeSeries(
      toJS(state.getIn(['workspace', 'timeseries'])),
      toJS(state.getIn(['workspace', 'baselineBasket'])) ?? [],
      dynamicWorkspace,
      dynamicRows,
      true);

    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'key'], dynamicWorkspace.key)
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], false)
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['workspace', 'baselineBasket'], fromJS(basket))
      .setIn(['workspace', 'dynamicWorkspaces'], fromJS(dynamicWorkspaces))
      .setIn(['workspace', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_DELETE](state, action) {
    const dynamicWorkspace = toJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace']));
    let dynamicWorkspaces = toJS(state.getIn(['workspace', 'dynamicWorkspaces']));
    dynamicWorkspaces = dynamicWorkspaces.filter(d => d.key !== dynamicWorkspace.key);
    const baselineBasket = removeDynamicWorkspaceFromBaselineBasket(fromJS(state.getIn(['workspace', 'baselineBasket'])), dynamicWorkspace);

    let basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    getTimeSeriesForDynamicWorkspace(basket, dynamicWorkspace).forEach(ts => {
      basket = basket.filter(t => t.key !== ts.key);
    });

    return state
      .setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket))
      .setIn(['workspace', 'dynamicWorkspaces'], fromJS(dynamicWorkspaces))
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['workspace', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DESIGN_CHANGED](state, action) {
    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys'], fromJS([]))
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], true)
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_ADD_DYNAMIC_FILTER](state, action) {
    const defaultState = toJS(getInitialState());
    const dynamicFilters = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters']).toJS();
    const dynamicWorkspace = state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace']).toJS();

    const newDynamicFilter = defaultState.analysis.dynamicWorkspaceDesign.dynamicFilters[0];
    if (dynamicFilters.length > 0) {
      newDynamicFilter.isMultiSelect = dynamicFilters[0].isMultiSelect;
      newDynamicFilter.selectedSchemas = dynamicFilters[0].selectedSchemas;
    }

    dynamicFilters.push(newDynamicFilter);
    dynamicWorkspace.dynamicFilters.push(newDynamicFilter);

    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace'], fromJS(dynamicWorkspace))
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters'], fromJS(dynamicFilters))
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DELETE_DYNAMIC_FILTER](state, action) {
    const { index } = action;

    const dynamicWorkspace = state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace']).toJS();
    if (dynamicWorkspace.dynamicFilters.length > 1) {
      dynamicWorkspace.dynamicFilters.splice(index, 1);
      state = state.setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
    }

    const dynamicFilters = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters']).toJS();
    if (dynamicFilters.length > 1) {
      dynamicFilters.splice(index, 1);
      state = state.setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
    }

    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace'], fromJS(dynamicWorkspace))
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters'], fromJS(dynamicFilters));
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_ALIAS](state, action) {
    const { index, value } = action;

    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'alias'], value)
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_BEGIN](state, action) {
    const { index, value: selectedCategory } = action;

    const dynamicFiltersCount = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters']).size;

    const dynamicFilter = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters', index]).toJS();
    const schemaCategories = toJS(state.getIn(['dynamicWorkspaceDesign', 'searchResultSchemaCategories']));
    const values = buildIntersectionOfCategories(schemaCategories, selectedCategory, dynamicFilter.selectedSchemas);

    dynamicFilter.selectedCategory = selectedCategory;
    dynamicFilter.values = values;
    dynamicFilter.selectedValue = !dynamicFilter.isMultiSelect && values.length > 0 ? values[0].name : '';
    dynamicFilter.multiSelectValues = dynamicFilter.isMultiSelect && values.length > 0 ? [values[0].name] : [];
    dynamicFilter.strict = false;
    dynamicFilter.strictSelectedValue = '';
    dynamicFilter.strictOptions = values;

    // only reset the substitutions if we have a single dynamic filter
    if (dynamicFiltersCount === 1) {
      const substitutionKeyExclusionCategories = toJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories']));
      substitutionKeyExclusionCategories.forEach(category => {
        category.isExcluded = false;
      });
      state = state.setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories'], fromJS(substitutionKeyExclusionCategories));
    }

    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys'], fromJS([]))
      
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'dynamicFilters', index], fromJS(dynamicFilter))
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index], fromJS(dynamicFilter))
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], true)
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_COMPLETE](state, action) {
    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys'], fromJS([]))
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], false);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_BEGIN](state, action) {
    const { index, value } = action;
    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'selectedValue'], value)
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'dynamicFilters', index, 'selectedValue'], value)
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], true)
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_COMPLETE](state, action) {
    return state
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], false);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_BEGIN](state, action) {
    const { index, isStrict, value: strictSelectedValue } = action;

    if (!isStrict)
      state = state.setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'selectedValue'], '');

    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys'], fromJS([]))
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'strict'], isStrict)
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'strictSelectedValue'], isStrict ? strictSelectedValue : '')
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], true)
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_COMPLETE](state, action) {
    const { index, data = [] } = action;
    const strictValues = data.map(d => ({
      isSelected: true,
      name: d
    }));

    return state.setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'strictValues'], fromJS(strictValues))
      .setIn(['dynamicWorkspaceDesign', 'isStrictCategoriesLoading'], false)
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], false);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_BEGIN](state, action) {
    return state.setIn(['dynamicWorkspaceDesign', 'isBusy'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_COMPLETE](state, action) {
    const { searchCriteria, data } = action;
    state = state.setIn(['dynamicWorkspaceDesign', 'isBusy'], false);
    if (!searchCriteria || !data)
      return state;

    const searchResultCategories = getPivotCategories(data.schemas);

    const dynamicFiltersCount = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters']).size;
    for (let index = 0; index < dynamicFiltersCount; index++) {
      let dynamicFilter = toJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters', index]));
      // if selected category isnt available in the search results - deselect it and cascade reset
      if (!searchResultCategories.exists(c => c.name === dynamicFilter.selectedCategory)) {
        state = state
          .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'strictOptions'], fromJS([]))
          .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'selectedCategory'], '')
          .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'selectedValue'], '')
          .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'strictSelectedValue'], '')
          .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'strictValues'], fromJS([]))
          .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'multiSelectValues'], fromJS([]));
      }
    }

    const substitutionKeyExclusionCategories = updateSubstitionKeyExclusionCategories(
      toJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories'])),
      data.schemas);

    const previousSearchCriteria = fromJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'searchCriteria']));

    return state
      .setIn(['dynamicWorkspaceDesign', 'searchResultCategories'], fromJS(searchResultCategories))
      .setIn(['dynamicWorkspaceDesign', 'searchResultSchemaCategories'], fromJS(data.schemas))
      .setIn(['dynamicWorkspaceDesign', 'previousSearchCriteria'], fromJS(previousSearchCriteria))
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'searchCriteria'], fromJS(searchCriteria))
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories'], fromJS(substitutionKeyExclusionCategories));
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_TOGGLE_EXCLUSION_CATEGORY](state, action) {
    const { categories } = action;
    const substitutionKeyExclusionCategories = toJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories']));
    const names = categories.map(c => c.name);
    substitutionKeyExclusionCategories.forEach(category => {
      category.isExcluded = names.includes(category.name);
    });

    const dynamicFilters = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters']).toJS();
    dynamicFilters.forEach(dynamicFilter => {
        if (dynamicFilter.strict) {
          dynamicFilter.strictSelectedValue = '';
          dynamicFilter.strictValues = [];      
        }
    });

    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys'], fromJS([]))
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories'], fromJS(substitutionKeyExclusionCategories))
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters'], fromJS(dynamicFilters))
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_RESET_PREVIOUS_DYNAMIC_KEYS](state, action) {
    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys'], fromJS([]));
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_BEGIN](state, action) {
    return state
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_COMPLETE](state, action) {
    state = state.setIn(['dynamicWorkspaceDesign', 'isBusy'], false);

    const { exclusions } = action;
    if (!exclusions)
      return state;

    let substitutionKeyExclusionCategories = toJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories']));

    exclusions.forEach(e => {
      const exclusion = substitutionKeyExclusionCategories.find(s => s.name === e);
      if (exclusion) {
        exclusion.isExcluded = true;
      } else {
        substitutionKeyExclusionCategories.push({
          name: e,
          isExcluded: true
        });
      }
    });

    substitutionKeyExclusionCategories = fromJS(substitutionKeyExclusionCategories)
    if (!is(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories']), substitutionKeyExclusionCategories)) {
      state = state.setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
    }

    const dynamicFilters = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters']).toJS();
    dynamicFilters.forEach(dynamicFilter => {
        if (dynamicFilter.strict) {
          dynamicFilter.strictSelectedValue = '';
          dynamicFilter.strictValues = [];      
        }
    });
    
    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys'], fromJS([]))
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories'], substitutionKeyExclusionCategories)
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters'], fromJS(dynamicFilters));
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_BEGIN](state, action) {
    const { index, value } = action;

    if (index === -1) {
      const dynamicFiltersCount = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters']).size;
      for (let i = 0; i < dynamicFiltersCount; i++) {
        state = state.setIn(['dynamicWorkspaceDesign', 'dynamicFilters', i, 'isMultiSelect'], value);
      }
    } else {
      state = state.setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'isMultiSelect'], value);
    }

    return state
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], true)
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_COMPLETE](state, action) {
    return state
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], false);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_MULTISELECT_VALUE_BEGIN](state, action) {
    const { index, values = [] } = action;
    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index, 'multiSelectValues'], fromJS(values.sort()))
      .setIn(['dynamicWorkspaceDesign', 'isBusy'], false)
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_BEGIN](state, action) {
    return state.setIn(['dynamicWorkspaceDesign', 'isBusy'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_COMPLETE](state, action) {
    state = state.setIn(['dynamicWorkspaceDesign', 'isBusy'], false);
    const { results, exclusions, schemaCategories, searchCriteria } = action;
    if (!results)
      return state;

    state = state.setIn(['dynamicWorkspaceDesign', 'dynamicRows'], fromJS(results));

    const previousDynamicKeys = toJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys']));
    if (!previousDynamicKeys || previousDynamicKeys.length === 0) {
      state = state.setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys'], fromJS(results.map(r => r.dynamicKey)))
    }

    const substitutionKeyExclusionCategories = toJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories']));
    if (exclusions.length > 0) {
      state = state.setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'previousDynamicKeys'], fromJS([]))
    }

    exclusions.forEach(e => {
      const exclusion = substitutionKeyExclusionCategories.find(s => s.name === e);
      if (exclusion) {
        exclusion.isExcluded = true;
      } else {
        substitutionKeyExclusionCategories.push({
          name: e,
          isExcluded: true
        });
      }
    });

    const dynamicFiltersCount = state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters']).size;
    for (let index = 0; index < dynamicFiltersCount; index++) {
      let dynamicFilter = toJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicFilters', index]));
      dynamicFilter.selectedSchemas = schemaCategories
        .map(s => ({
          isSelected: true,
          schemaName: s.schemaName
        }));

      const categoryOptions = buildIntersectionOfCategories(schemaCategories, dynamicFilter.selectedCategory, dynamicFilter.selectedSchemas);
      dynamicFilter.values = categoryOptions;
      dynamicFilter.strictOptions = categoryOptions;
      // deleselect the filter value(s) is it is not available in the results set
      if (dynamicFilter.isMultiSelect) {
        dynamicFilter.selectedValue = '';
        [...dynamicFilter.multiSelectValues].forEach(selectedValue => {
          if (!dynamicFilter.values.exists(v => v.name === selectedValue))
            dynamicFilter.multiSelectValues = dynamicFilter.multiSelectValues.filter(x => x.name !== selectedValue);
        });

      } else {
        dynamicFilter.multiSelectValues = [];
        if (!dynamicFilter.values.exists(v => v.name === dynamicFilter.selectedValue))
          dynamicFilter.selectedValue = '';
      }

      state = state.setIn(['dynamicWorkspaceDesign', 'dynamicFilters', index], fromJS(dynamicFilter))
        .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'dynamicFilters', index], fromJS(dynamicFilter))
    }

    const previousSearchCriteria = fromJS(state.getIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'searchCriteria']));

    return state
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'substitutionKeyExclusionCategories'], fromJS(substitutionKeyExclusionCategories))
      .setIn(['dynamicWorkspaceDesign', 'previousSearchCriteria'], fromJS(previousSearchCriteria))
      .setIn(['dynamicWorkspaceDesign', 'dynamicWorkspace', 'searchCriteria'], fromJS(searchCriteria))
      .setIn(['dynamicWorkspaceDesign', 'isDirty'], false);
  },
};