import { msalInstance } from './msalAuth';
import { SCOPES } from './config';
import { fetchEventStart, fetchEventEnd } from './fetch-watcher';

export class FetchError {
  constructor(message, response) {
    this.response = { url: response.url, status: response.status };
    this.body = message || `${response.url} - ${response.status}`;
  }
}

// if browser is refreshed we need to re-assign the active user
async function setAccount() {
  if (window.__APIKEY__ !== undefined)
    return;

  await msalInstance.initialize(); // safe to call multiple times as msalInstance will not re-initialise
  if (!msalInstance.getActiveAccount()) {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0)
      msalInstance.setActiveAccount(accounts[0]);
    else {
      msalInstance.logoutRedirect();
      return;
    }
  }
}

async function getToken() {
  await msalInstance.initialize(); // safe to call multiple times as msalInstance will not re-initialise
  try {
    return await msalInstance.acquireTokenSilent({ scopes: SCOPES });
  }
  catch (exception) {
    console.log({ exception });
    if (msalInstance.errors === undefined || msalInstance.errors.length < 3) {
      msalInstance.acquireTokenRedirect({ scopes: SCOPES });
      return;
    }
    else {
      throw new FetchError(`${exception.message}`, {
        title: exception.name,
        errors: [exception.message, exception.subError]
      });
    }
  }
}

export const hasRole = async (role) => {
  if (window.__APIKEY__ !== undefined)
    return true;

  await setAccount();

  const tokenResponse = await getToken();
  return (tokenResponse && tokenResponse.idTokenClaims && tokenResponse.idTokenClaims.roles && tokenResponse.idTokenClaims.roles.includes(role));
}

export const authFetch = async (url, options) => {
  window.dispatchEvent(fetchEventStart);

  await setAccount();

  if (!options) options = { method: "GET" }
  if (!options.headers) options.headers = {};

  if (window.__APIKEY__ !== undefined)
    options.headers.apikey = window.__APIKEY__;
  else {
    const accessToken = (await getToken()).accessToken;
    options.headers.authorization = `Bearer ${accessToken}`;
  }

  return fetch(url, options)
    .then(async response => {
      if (response.status >= 400 && response.status <= 500) {
        const message = await response.text();
        throw new FetchError(`${message}`, response);
      };

      return response;
    }).finally(() => {
      window.dispatchEvent(fetchEventEnd);
    });
};

// the application will have been logged in and msalInstance initialised to get use this function
export const signOut = () => {
  msalInstance.logoutRedirect();}

// the application will have previously and successfully called an initialise on the msalInstance to have got this far
export const getCurrentUserName = () => {
  return msalInstance.getActiveAccount().username;
}