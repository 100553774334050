import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import JSONEditor from 'jsoneditor';
import { toJS } from '../../../utility/immutable-utility';
import {
  analysisUpdateTableSettings
} from '../../../actions/analysis';

export const TableJsonEditorPanel = connect(
  (state) => ({
    tableSettings: state.getIn(['analysis', 'workspace', 'tableSettings'])
  }),
  (dispatch) => ({
    updateSettings(data) {
      dispatch(analysisUpdateTableSettings(data));
    }
  })
)(({ updateSettings, tableSettings }) => {
  const [data, setData] = useState(toJS(tableSettings));
  const [isDirty, setIsDirty] = useState(false);
  const jsonEditorRef = useRef();

  function onEdited(d) {
    setData(d);
    setIsDirty(true);
  }

  function onRevert() {
    const previousData = toJS(tableSettings);
    setData(previousData);
    setIsDirty(false);
    jsonEditorRef.current.refresh(previousData);
  }

  function onApply() {
    updateSettings(data);
    setIsDirty(false);
  }

  return <div className='analysis-table-panel'>
    <div className='w-100 d-flex justify-content-between align-items-center pb-2'>
      <div className='btn-group btn-group-sm ' >
        <button title='Revert to last saved state' type='button' className={`btn btn-sm btn-outline-primary text-nowrap`} onClick={onRevert}>
        <i className='fas fa-th' /><i className='fas fa-arrow-right fa-fw' /><i className='far fa-file-code mr-1' />Reset
        </button>
      </div>

      <div className='btn-group btn-group-sm ' >
        <button title='Apply changes the view' type='button' className={`btn btn-sm ${isDirty ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`} onClick={onApply} >
          <i className='fas fa-check fa-fw mr-1' />Apply
        </button>
      </div>
    </div>

    <div className='w-100' style={{ height: 'calc(100% - 35px)', overflowY: 'auto' }}>
      <JsonEditor ref={jsonEditorRef} data={data} onEdited={onEdited} />
    </div>
  </div>
});

class JsonEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editor = null;
  }

  expandAll() {
    if (typeof this.editor.expandAll === 'function') this.editor.expandAll();
  }

  componentDidMount() {
    this.initialiseEditor();
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate = this.props.data !== nextProps.data;
    return shouldUpdate;
  }

  initialiseEditor() {
    if (!this.props.data) return;

    if (!this.editor) {
      let container = document.getElementById('editor');

      let jsonEditor = this.editor = new JSONEditor(container, {
        onChange: this.onChanged.bind(this),
        mode: 'code',
        modes: ['code', 'tree'],
        onModeChange() {
          if (typeof jsonEditor.expandAll === 'function') jsonEditor.expandAll();
        }
      }, this.props.data);

      this.expandAll();
    }
    else {
      this.editor.set(this.props.data);
      this.editor.refresh();
      this.expandAll();
    }
  }

  onChanged() {
    try {
      let data = this.editor.get();
      this.props.onEdited(data);
    }
    catch {
      const text = this.editor.getText();
      if (text === '')
        this.props.onEdited({});
    }
  }

  refresh(data) {
    this.editor.set(data);
    this.editor.refresh();
  }

  render() {
    return (
      <div className='d-flex flex-column h-100'>
        <div id='editor' className='flex-fill' />
      </div>
    );
  }
}