import { createAction } from '../utility/redux-utility';
import { authFetch, getCurrentUserName } from '../auth';
import { EVENTS_API_URL } from '../config';
import { logInfoNotification, logErrorNotification } from './log';
import qs from 'querystring';
import {
  buildAnalysisShareUrl,
  buildDashboardShareUrl,
  buildOutagesShareUrl,
  buildReportsShareUrl
} from '../utility/share-url-utility';

export const screenCaptureUpdateDefaults = () => (dispatch, getState) =>  {
  const state = getState();
  let cronSpec = state.getIn(['userSettings', 'settings', 'screenCapture', 'cronSpec']);
  let recipients = state.getIn(['userSettings', 'settings', 'screenCapture', 'recipients']);

  if (!cronSpec) cronSpec = '0 6 * * *';
  if (!recipients) recipients = getCurrentUserName();

  const pathname = decodeURIComponent(window.location.pathname);
  let shareUrl = undefined;

  if (pathname.startsWith('/home/') || pathname.startsWith('/dashboard/'))
    shareUrl = buildDashboardShareUrl({ state, pathname });
  else if (pathname.startsWith('/analysis'))
    shareUrl = buildAnalysisShareUrl({ state, pathname });
  else if (pathname.startsWith('/reports'))
    shareUrl = buildReportsShareUrl({ state, pathname });
  else if (pathname.startsWith('/power/outages'))
    shareUrl = buildOutagesShareUrl({ state, pathname });

  let subject = '';
  let targetUrl = window.location.href;
  if (shareUrl) {
    subject = shareUrl.name;
    if (!pathname.startsWith('/reports'))
      targetUrl = shareUrl.url;
  }

  dispatch(screenCaptureSetDefaults({ cronSpec, recipients, subject, targetUrl }));
};

export const SCREEN_CAPTURE_SET_DEFAULTS = 'SCREEN_CAPTURE_SET_DEFAULTS';
export const screenCaptureSetDefaults = createAction(SCREEN_CAPTURE_SET_DEFAULTS, 'data');

export const SCREEN_CAPTURE_CLEAR = 'SCREEN_CAPTURE_CLEAR';
export const screenCaptureClear = createAction(SCREEN_CAPTURE_CLEAR);

export const SCREEN_CAPTURE_LOAD_STARTED = 'SCREEN_CAPTURE_LOAD_STARTED';
export const screenCaptureLoadStarted = createAction(SCREEN_CAPTURE_LOAD_STARTED);

export const screenCaptureLoad = () => (dispatch, getState) =>  {
  dispatch(screenCaptureCheckForWarnings());
  dispatch(screenCaptureLoadStarted());

  const targetUrl = window.location.pathname.startsWith('/analysis')
    ? `${window.location.origin}/analysis`
    : window.location.href;
  const params = qs.stringify({ targetUrl });
  return authFetch(`${EVENTS_API_URL}/actions/scheduled/page-captures?${params}`)
    .then(response => response.json())
    .then(data => {
      dispatch(screenCaptureLoadComplete(data));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const SCREEN_CAPTURE_LOAD_STOPPED = 'SCREEN_CAPTURE_LOAD_STOPPED';
export const screenCaptureLoadStopped = createAction(SCREEN_CAPTURE_LOAD_STOPPED);

export const SCREEN_CAPTURE_LOAD_COMPLETE = 'SCREEN_CAPTURE_LOAD_COMPLETE';
export const screenCaptureLoadComplete = createAction(SCREEN_CAPTURE_LOAD_COMPLETE, 'data');

export const SCREEN_CAPTURE_SAVE_EDIT_STARTED = 'SCREEN_CAPTURE_SAVE_EDIT_STARTED';
export const screenCaptureSaveEditStarted = createAction(SCREEN_CAPTURE_SAVE_EDIT_STARTED);

export const screenCaptureSaveEdit = () => (dispatch, getState) =>  {
  const state = getState();
  const { id, isEnabled, cronSpec, recipients, subject, targetUrl } = state.getIn(['screenCapture', 'edit']).toJS();

  dispatch(screenCaptureSaveEditStarted());

  const isNew = id === 0;
  const request = {
    id: isNew ? 'new' : id,
    name: subject,
    cronSpec,
    isEnabled,
    actionType: 'PageCapture',
    notificationSettings: {
      transports: []
    },
    eventParameters: {},
    parameters: {
      pageCaptures: [{ recipients, subject, targetUrl }]
    },
    datasetIds: []
  };

  return authFetch(`${EVENTS_API_URL}/actions/scheduled${isNew ? '' : `/${id}`}`, {
    method: isNew ? 'POST' : 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
  .then(() => {
    dispatch(screenCaptureLoad());
    dispatch(screenCaptureSaveEditComplete());
    dispatch(screenCaptureCloseEdit());
    dispatch(logInfoNotification(`Screen capture saved.\n\nTo: ${recipients}\nSubject: ${subject}\nTarget Url: ${targetUrl}`));
  })
  .catch(error => {
    dispatch(screenCaptureSaveEditStopped());
    dispatch(logErrorNotification(error));
  });
};

export const SCREEN_CAPTURE_SAVE_EDIT_STOPPED = 'SCREEN_CAPTURE_SAVE_EDIT_STOPPED';
export const screenCaptureSaveEditStopped = createAction(SCREEN_CAPTURE_SAVE_EDIT_STOPPED);

export const SCREEN_CAPTURE_SAVE_EDIT_COMPLETE = 'SCREEN_CAPTURE_SAVE_EDIT_COMPLETE';
export const screenCaptureSaveEditComplete = createAction(SCREEN_CAPTURE_SAVE_EDIT_COMPLETE, 'data');

export const screenCaptureDelete = (id) => (dispatch, getState) =>  {
  const state = getState();

  const action = state.getIn(['screenCapture', 'actions']).find(i => i.get('id') === id);
  if (!action) return Promise.resolve();

  const { recipients, subject, targetUrl } = action.toJS();

  dispatch(screenCaptureSaveEditStarted());

  return authFetch(`${EVENTS_API_URL}/actions/scheduled/${id}`, {
    method: 'DELETE'
  })
  .then(() => {
    dispatch(screenCaptureLoad());
    dispatch(screenCaptureSaveEditComplete());
    dispatch(screenCaptureCloseEdit());
    dispatch(logInfoNotification(`Screen capture deleted.\n\nTo: ${recipients}\nSubject: ${subject}\nTarget Url: ${targetUrl}`));
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const screenCaptureSelectedNow = () => (dispatch, getState) =>  {
  const state = getState();

  const selectedId = state.getIn(['screenCapture', 'selectedId']);
  if (!selectedId) return Promise.resolve();

  const action = state.getIn(['screenCapture', 'actions']).find(i => i.get('id') === selectedId);
  if (!action) return Promise.resolve();

  const pageCapture = action.getIn(['parameters', 'pageCaptures', 0]).toJS();
  if (!pageCapture) return Promise.resolve();

  return authFetch(`${EVENTS_API_URL}/actions/requests/page-capture`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(pageCapture)
  })
  .then(() => {
    dispatch(logInfoNotification(`Screen capture request accepted.\n\nTo: ${pageCapture.recipients}\nSubject: ${pageCapture.subject}\nTarget Url: ${pageCapture.targetUrl}`));
    dispatch(screenCaptureSelect(0));
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const SCREEN_CAPTURE_SELECT = 'SCREEN_CAPTURE_SELECT';
export const screenCaptureSelect = createAction(SCREEN_CAPTURE_SELECT, 'id');

export const SCREEN_CAPTURE_OPEN_NEW_EDIT = 'SCREEN_CAPTURE_OPEN_NEW_EDIT';
export const screenCaptureOpenNewEdit = createAction(SCREEN_CAPTURE_OPEN_NEW_EDIT);

export const SCREEN_CAPTURE_OPEN_EDIT = 'SCREEN_CAPTURE_OPEN_EDIT';
export const screenCaptureOpenEdit = createAction(SCREEN_CAPTURE_OPEN_EDIT, 'id');

export const SCREEN_CAPTURE_CLOSE_EDIT = 'SCREEN_CAPTURE_CLOSE_EDIT';
export const screenCaptureCloseEdit = createAction(SCREEN_CAPTURE_CLOSE_EDIT);

export const SCREEN_CAPTURE_EDIT_PROPERTY_CHANGE = 'SCREEN_CAPTURE_EDIT_PROPERTY_CHANGE';
export const screenCaptureEditPropertyChange = createAction(SCREEN_CAPTURE_EDIT_PROPERTY_CHANGE, 'key', 'value');

export const screenCaptureCheckForWarnings = () => (dispatch, getState) =>  {
  const state = getState();

  if (window.location.pathname.startsWith('/analysis')) {
    const isDirty = state.getIn(['analysis', 'workspace', 'isDirty']);
    const isUnsaved = state.getIn(['analysis', 'workspace', 'isUnsaved']);
    const isDiffLocation = state.getIn(['analysis', 'workspace', 'initialPageUrl']) !== state.getIn(['analysis', 'workspace', 'pageUrl']) ;
    if (isDirty || isUnsaved || isDiffLocation)
      dispatch(screenCaptureSetWarning('Your workspace has unsaved changes and a screen capture may not return what you expect'));
  }
};

export const SCREEN_CAPTURE_SET_WARNING = 'SCREEN_CAPTURE_SET_WARNING';
export const screenCaptureSetWarning = createAction(SCREEN_CAPTURE_SET_WARNING, 'message');
