import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import MomentLocalizer from 'react-widgets-moment';
import Localization from 'react-widgets/Localization';
import Loading from './components/shared/Loading';
import Notifications from './components/shared/Notifications';
import AppContent from './components/app-content/AppContent';
import AppTitle from './AppTitle';
import { referenceDataLoadSharedLookups } from './actions/referenceData';
import { userSettingsLoad } from './actions/userSettings';
import { loadRoles } from './actions/roles';
import './styles/app.css';

export const AppStartup = connect(
  (state) => ({
    isLoadingRoles: state.getIn(['roles', 'isLoading']),
    isLoadingUserSettings: state.getIn(['userSettings', 'isLoading']),
    isLoadingReferenceData: state.getIn(['referenceData', 'isLoading'])
  }),
  (dispatch) => ({
    initialise() {
      dispatch(loadRoles());
      dispatch(referenceDataLoadSharedLookups());
      dispatch(userSettingsLoad(true));
    }
  })
)(({ children, initialise, isLoadingRoles, isLoadingUserSettings, isLoadingReferenceData }) => {
  useEffect(() => initialise(), [initialise]);

  const isLoading = isLoadingRoles | isLoadingUserSettings | isLoadingReferenceData;
  return (
    <Loading isLoading={isLoading} message='Loading...'>
      {!isLoading ? children : null}
    </Loading>
  );
});

export default function App() {
  return <>
    <Localization date={new MomentLocalizer(moment)}>
      <AppTitle />
      <AppStartup>
        <AppContent />
        <Notifications />
      </AppStartup>
    </Localization>
  </>;
};