import React from 'react';

export function ConversionUnitFiller({ updateAllSeries, conversionUnits }) {
  let sum = 0;
  conversionUnits.forEach(cu => {
    sum += cu.units.length;
  });

  return <>
    <select className='form-control form-control-sm filler-select' style={{ minWidth: '125px' }} size={sum / 2} onChange={e => updateAllSeries(['conversionUnit'], e.target.value, { requiresRefresh: true, applyToHighcharts: false })}>
      <option key='none' value='' data-close-popover></option>
        {conversionUnits.map(cu => (
          <optgroup key={cu.group} label={cu.group}>
            {cu.units.map(u => <option data-close-popover key={u.name} value={u.name}>{u.name}</option>)}
          </optgroup>
        ))}
    </select>
  </>
}