import React, { Fragment } from 'react';

const dayHeaders = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
/**
 * range = {
 * weeks:[{
 *   name : 'wk1',
 *   dates :[{
 *     isSelected : false,
 *     name}]
 * }]
 */
export default function CalendarDayPicker({ range, topLeftClick, columnClick, rowClick, cellClick, rowHeaderStyle, columnHeaderStyle, cellStyle }) {
  rowHeaderStyle = rowHeaderStyle || '';
  columnHeaderStyle = columnHeaderStyle || '';
  cellStyle = cellStyle || '';

  function onTopLeftClick() {
    if (topLeftClick) topLeftClick();
  }

  function onColumnClick(column) {
    if (columnClick) columnClick(column);
  }

  function onRowClick(row) {
    if (rowClick) rowClick(row);
  }

  function onCellClick(month) {
    if (cellClick) cellClick(month);
  }

  return <Fragment>
    <div className='scrolling-table' style={{ width: '767px' }}>
      <div className='scrolling-tableHead'>
        <div className='scrolling-tableRow'>
          <div className={'scrolling-tableCell spreadsheet-cursor-topLeft spreadsheet-topLeft'} style={{ width: '64px' }} onClick={() => onTopLeftClick()}></div>
          {dayHeaders.map(m => <div className={columnHeaderStyle + ' scrolling-tableCell table-header-cell'} style={{ width: '100px' }} key={`m${m}`} onClick={() => onColumnClick(m)}>{m}</div>)}
        </div>
      </div>
    </div>
    <div className='scrolling-table' style={{ width: '767px' }}>
      <div className='scrolling-table-scroll-area' style={{ height: '100%' }}>
        <div className='scrolling-tableBody '>
          {range && range.weeks && range.weeks.map((week) => (
            <div className='scrolling-tableRow' key={`w${week.name}`}>
              <div className={rowHeaderStyle + ' scrolling-tableCell table-header-cell'} style={{ width: '64px' }} onClick={() => onRowClick(week.name)}></div>
              {week.dates.map((day, dayindex) => day === undefined ? <div key={`c${week.name}-${dayindex}`} className='scrolling-tableCell day-picker' ></div> :
                <div key={`d${dayindex}`} className={cellStyle + ' scrolling-tableCell table-cell day-picker ' + (day.isSelected && ' table-primary table-checked')} style={{ width: '100px', backgroundPositionX: '80%' }} onClick={() => onCellClick(day)}>{day.dateNumber}</div>)}
            </div>))}
        </div>
      </div>
    </div>
  </Fragment>
}