import React, { useCallback, useState, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import deepmerge from 'deepmerge';
import { toJS } from '../../../utility/immutable-utility';
import CollapsePanel from '../../shared/CollapsePanel';
import {
  analysisUpdateChartEditView,
  analysisUpdateChartEditSettings,
  analysisUpdateChartEditSettingsValue,
  analysisShowChartEditUserSettingsModal,
  analysisApplyChartEditSettings
} from '../../../actions/analysis';
import { ModalDialogButton } from '../../shared/ModalDialogButton';
import JsonEditor from '../../shared/JsonEditor';
import InfoPopup from '../../shared/InfoPopup';

function CollapsiblePanel({ idKey, title, children }) {
  return (
    <CollapsePanel idKey={ idKey } title={ <CollapsiblePanelHeader title={ title } /> } disableClasses={ true } collapsed={ false }
                   headerStyle={{ textAlign: 'center', cursor: 'pointer', fontWeight: 'bold', userSelect: 'none', marginTop: '1em' }}>
      { children }
    </CollapsePanel>
  );
}

function CollapsiblePanelHeader(props) {
  const [backgroundColor, setBackgroundColor] = useState('#ccc');

  const _style = useMemo(() => props.style || {}, [props.style]);

  return (
    <div { ...props } style={{ ..._style, backgroundColor }}
         onMouseEnter={ () => setBackgroundColor('#33333333') }
         onMouseLeave={ () => setBackgroundColor('#ccc') }>
      <span>{ props.title }</span>
    </div>
  );
}

const getDefaultYAxis = () => ({ title: { text: '' }, labels: { format: '' }, opposite: false });

function SettingsEditor({ data, onChange, seriesTypes, seriesDashStyles, seriesStacking }) {
  const _data = useMemo(() => toJS(data, {}), [data]);

  const _chartZoomType = useMemo(() => (_data.chart || {}).zoomType || 'xy', [_data]);
  const _chartType = useMemo(() => (_data.chart || {}).type || 'line', [_data]);
  const _plotOptionsDashStyle = useMemo(() => ((_data.plotOptions || {}).series || {}).dashStyle || 'solid', [_data]);
  const _plotOptionsLineWidth = useMemo(() => ((_data.plotOptions || {}).series || {}).lineWidth || 1, [_data]);
  const _plotOptionsStacking = useMemo(() => ((_data.plotOptions || {}).series || {}).stacking || 1, [_data]);
  const _yAxes = useMemo(() => _data.yAxis || [getDefaultYAxis()], [_data]);

  const updateChartZoomType = useCallback(e => onChange(['chart', 'zoomType'], e.target.value), [onChange]);
  const updateChartType = useCallback(e => onChange(['chart', 'type'], e.target.value), [onChange]);

  const updatePlotOptionsDashStyle = useCallback(e => onChange(['plotOptions', 'series', 'dashStyle'], e.target.value), [onChange]);
  const updatePlotOptionsLineWidth = useCallback(e => onChange(['plotOptions', 'series', 'lineWidth'], Number(e.target.value)), [onChange]);
  const updatePlotOptionsStacking = useCallback(e => onChange(['plotOptions', 'series', 'stacking'], e.target.value), [onChange]);

  const updateYAxisProperty = useCallback((ix, value) => {
    const yAxis = deepmerge.all([_yAxes[ix], value], {
      arrayMerge: (_target, source, _options) => source
    });

    onChange(['yAxis'], [..._yAxes.slice(0, ix), yAxis, ..._yAxes.slice(ix + 1)]);
  }, [onChange, _yAxes]);

  const updateYAxisTitle = useCallback((ix, e) => updateYAxisProperty(ix, { title: { text: e.target.value } }), [updateYAxisProperty]);
  const updateYAxisFormat = useCallback((ix, e) => updateYAxisProperty(ix, { labels: { format: e.target.value } }), [updateYAxisProperty]);
  const updateYAxisOpposite = useCallback((ix, e) => updateYAxisProperty(ix, { opposite: e.target.checked }), [updateYAxisProperty]);

  const removeYAxis = useCallback((ix) => onChange(['yAxis'], [..._yAxes.slice(0, ix), ..._yAxes.slice(ix + 1)]), [onChange, _yAxes]);
  const addYAxis = useCallback(() => {
    const hasOpposite = _yAxes.some(i => i.opposite);

    if (hasOpposite) onChange(['yAxis'], [..._yAxes, getDefaultYAxis()]);
    else onChange(['yAxis'], [..._yAxes, { ...getDefaultYAxis(), opposite: true }]);
  }, [onChange, _yAxes]);

  return (
    <div className='py-0 px-2'>
      <CollapsiblePanel idKey='chartBasic' title='Chart'>
        <div className='form-row mt-2'>
          <div className='form-group col m-0'>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Zoom Type</label>
            <select className='form-control form-control-sm' value={ _chartZoomType } onChange={ updateChartZoomType }>
              <option value='xy'>XY</option>
              <option value='x'>X</option>
              <option value='y'>Y</option>
            </select>
          </div>
        </div>
        <div className='form-row mt-2'>
          <div className='form-group col m-0'>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Type</label>
            <select className='form-control form-control-sm' value={ _chartType } onChange={ updateChartType }>
              {seriesTypes.map((s,si) => <option key={`seriesTypes-${si}`} value={s.value}>{s.text}</option> )}  
            </select>
          </div>
        </div>
      </CollapsiblePanel>
      <CollapsiblePanel idKey='chartPlotOptionsLine' title='Plot Options'>
        <a href={`https://api.highcharts.com/highcharts/series`} target='_blank' rel='noopener noreferrer'><i className='fas fa-book mr-1'></i>highcharts series</a>

        <div className='form-row mt-2'>
          <div className='form-group col m-0' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Dash Style</label>
            <select className='form-control form-control-sm' value={ _plotOptionsDashStyle } onChange={ updatePlotOptionsDashStyle }>
              {seriesDashStyles.map((s,si) => <option key={`seriesDashStyles-${si}`} value={s.value}>{s.text}</option> )}
            </select>
          </div>
        </div>
        <div className='form-row mt-2'>
          <div className='form-group col m-0' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Line Width</label>
            <input className='form-control form-control-sm' type='number' step='1' min='1' max='10' value={ _plotOptionsLineWidth } onChange={ updatePlotOptionsLineWidth } />
          </div>
        </div>
        <div className='form-row mt-2'>
          <div className='form-group col m-0' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Stacking</label>
            <select className='form-control form-control-sm' value={ _plotOptionsStacking } onChange={ updatePlotOptionsStacking }>
              {seriesStacking.map((s,si) => <option key={`seriesStacking-${si}`} value={s.value}>{s.text}</option> )}
            </select>
          </div>
        </div>
      </CollapsiblePanel>
      <CollapsiblePanel idKey='chartXAxis' title='X-Axis'>
        <a href={`https://api.highcharts.com/highcharts/xAxis`} target='_blank' rel='noopener noreferrer'><i className='fas fa-book mr-1'></i>highcharts X-Axis</a>

        <div className='form-row mt-2'>
          <div className='form-group col m-0' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Title</label>
            <input className='form-control form-control-sm' type='text' value={data.getIn(['xAxis', 'title', 'text']) ?? ''} onChange={e => onChange(['xAxis', 'title', 'text'], e.target.value)} />
          </div>
        </div>

        <div className='form-row mt-2'>
          <div className='form-group col m-0' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Custom Format <InfoPopup placement='top'>
              <strong>Custom format</strong> <br></br>
              Charts use <a style={{color:'#fff', textDecoration:'underline'}} href='https://momentjs.com/docs/#/displaying/format/' target='_blank' rel='noopener noreferrer'>Moment JS</a> date formatting within curly brackets.<br></br><br></br>
              exmples: <br></br>
              <table border='1' cellspacing='4' cellpadding='5'>
                <thead>
                  <th>Format</th>
                  <th>Expected result</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{`Day {dd}, Hour {HH}`}</td>
                    <td>Day 5, Hour 23</td>
                  </tr>
                  <tr>
                    <td>{`{DD-MMM HH:mm}`}</td>
                    <td>5-JAN 22:59</td>
                  </tr>
                  <tr>
                    <td>{`{MMMM}`}</td>
                    <td>September</td>
                  </tr>
                </tbody>
              </table>              
              </InfoPopup>
            </label>
            <input className='form-control form-control-sm' type='text' value={data.getIn(['xAxis', 'customFormat']) ?? ''} onChange={e => onChange(['xAxis', 'customFormat'], e.target.value)} />
          </div>
        </div>      
      </CollapsiblePanel>

      <CollapsiblePanel idKey='chartYAxis' title='Y-Axis'>
        <a href={`https://api.highcharts.com/highcharts/yAxis`} target='_blank' rel='noopener noreferrer'><i className='fas fa-book mr-1'></i>highcharts Y-Axis</a>

        { _yAxes.map((i, ix) => (
          <Fragment key={ ix }>
            <div className='form-row mt-2'>
              <div className='form-group col m-0' style={{ position: 'relative' }}>
                <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Title</label>
                <div className='input-group input-group-sm'>
                  <input className='form-control form-control-sm' type='text' value={ (i.title || {}).text || '' } onChange={ e => updateYAxisTitle(ix, e) } />
                </div>
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col m-0'>
                <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Format</label>
                <div className='input-group input-group-sm'>
                  <input className='form-control form-control-sm' type='text' value={ (i.labels || {}).format || '' } onChange={ e => updateYAxisFormat(ix, e) } />
                </div>
              </div>
            </div>
            <div className='form-row' style={{ position: 'relative' }}>
              { ix > 0 && (
                <button className='btn btn-sm btn-xs btn-outline-primary m-1' type='button' onClick={ () => removeYAxis(ix) } style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
                  <i className='fas fa-trash fa-fw'></i>
                </button>
              ) }
              <div className='form-group col m-0'>
                <div className='custom-control custom-checkbox'>
                  <input type='checkbox' className='custom-control-input' id={ `y-axis-opposite-${ix}` }
                         checked={ !!i.opposite }
                         onChange={ e => updateYAxisOpposite(ix, e) } />
                  <label className='custom-control-label' htmlFor={ `y-axis-opposite-${ix}` }
                         style={{ userSelect: 'none', cursor: 'pointer' }}>Opposite</label>
                </div>
              </div>
            </div>
            { (ix < _yAxes.length - 1) && <hr className='mt-2' /> }
          </Fragment>
        )) }
        <button className='btn btn-sm btn-primary mt-4 float-right' type='button' onClick={ addYAxis }>
          Add Y-Axis
        </button>
      </CollapsiblePanel>
    </div>
  );
}

const ViewType = { UI: 'UI', JSON: 'JSON' };

const AnalysisHighChartSettings = connect(
  (state) => ({
    currentView: state.getIn(['analysis', 'chartSettingsEdit', 'currentView']),
    userSettings: state.getIn(['userSettings', 'settings', 'analysis', 'chartSettings']),
    chartSettings: state.getIn(['analysis', 'chartSettingsEdit', 'settings']),
    isDirty: state.getIn(['analysis', 'chartSettingsEdit', 'isDirty']),
    seriesTypes: state.getIn(['analysis', 'seriesTypes']),
    seriesDashStyles: state.getIn(['analysis', 'seriesDashStyles']),
    seriesStacking: state.getIn(['analysis', 'seriesStacking']),
  }),
  (dispatch) => ({
    updateChartEditSettings(data) {
      dispatch(analysisUpdateChartEditSettings(data));
    },
    updateChartEditSettingsValue(keyPath, value) {
      dispatch(analysisUpdateChartEditSettingsValue(keyPath, value));
    },
    updateCurrentView(data) {
      dispatch(analysisUpdateChartEditView(data));
    },
    showUserSettingModal() {
      dispatch(analysisShowChartEditUserSettingsModal());
    },
    applyChartEditSettings(includeSeries) {
      dispatch(analysisApplyChartEditSettings(includeSeries));
    }
  })
)(({ currentView, chartSettings, userSettings, isDirty, updateChartEditSettings, updateChartEditSettingsValue, updateCurrentView, toggleIsPinned,
  applyChartEditSettings,
  seriesTypes,
  seriesDashStyles,
  seriesStacking}) => {
  const setUIView = useCallback(() => updateCurrentView(ViewType.UI), [updateCurrentView]);
  const setJsonView = useCallback(() => updateCurrentView(ViewType.JSON), [updateCurrentView]);
  const _seriesTypes = toJS(seriesTypes, {});
  const _seriesDashStyles = toJS(seriesDashStyles, []);
  const _seriesStacking = toJS(seriesStacking, []);

  function onApplyUserSettings() {
    updateChartEditSettings(toJS(userSettings, {}));
    applyChartEditSettings();
  }

  return (
    <div className='d-flex flex-column w-100 h-100'>
      <div className='d-flex justify-content-between align-items-center w-100 p-0'>
        { currentView === ViewType.JSON ? (
          <button className='btn btn-sm btn-xs btn-outline-primary m-1' type='button' onClick={ setUIView } >
            UI
          </button>
        ) : (
          <button className='btn btn-sm btn-xs btn-outline-primary m-1' type='button' onClick={ setJsonView } >
            JSON
          </button>
        ) }
        <div>
          <h6 className='m-0'>Chart Configuration</h6>
        </div>
        
        <div className='d-flex'>
          {userSettings && <ModalDialogButton size='sm'
                              buttonClassName='btn btn-sm btn-xs btn-outline-primary m-1'
                              buttonContent={<i className='fas fa-user-cog fa-fw'></i>}>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Apply User Settings?</h5>
                  <button type='button' className='close' data-dismiss>&times;</button>
                </div>
                <div className='modal-body'>
                  <p>
                    Are you sure you wish to apply your user settings?<br/><br/>
                    This will overwrite your current chart configuration.
                  </p>
                </div>
                <form className='modal-footer'>
                  <button type='button' className='btn btn-secondary' data-dismiss>Cancel</button>
                  <button autoFocus className='btn btn-primary' onClick={ onApplyUserSettings } data-dismiss>Confirm</button>
                </form>
              </div>
            </ModalDialogButton>}
          <button className='btn btn-sm btn-xs btn-outline-primary m-1' type='button' onClick={ toggleIsPinned }>
            <i className='fas fa-thumbtack fa-fw'></i>
          </button>
        </div>
      </div>

      {currentView === ViewType.JSON ? <div className='flex-fill w-100 mt-2' style={{ overflowY: 'hidden' }}>
          <JsonEditor mode='code' data={ chartSettings.toJS() } onEdited={ updateChartEditSettings } isDirty={ isDirty }/>
      </div>
      :
      <div className='flex-fill w-100 mt-2' style={{ overflowY: 'auto' }}>
          <SettingsEditor data={ chartSettings } onChange={ updateChartEditSettingsValue } seriesTypes={_seriesTypes} seriesDashStyles={_seriesDashStyles} seriesStacking={_seriesStacking}/>
      </div>}

      <div className='w-100'>
        <ModalDialogButton size='sm'
                           buttonClassName={ `btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'} float-right m-1` }
                           buttonContent={<><i className='fas fa-sync-alt fa-fw' /><span className='ml-2'>Apply</span></>}>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Apply to Series Settings?</h5>
              <button type='button' className='close' data-dismiss>&times;</button>
            </div>
            <div className='modal-body'>
              <p>
                Do you wish to apply your chart settings to your existing series?
              </p>
            </div>
            <form className='modal-footer' >
              <button type='button' className='btn btn-secondary' data-dismiss>Cancel</button>
              <button type='button' className='btn btn-outline-primary' data-dismiss onClick={() => applyChartEditSettings(false)}>Chart Only</button>
              <button autoFocus type='button' data-dismiss className='btn btn-primary' onClick={() => applyChartEditSettings(true)}>Apply to Series</button>
            </form>
          </div>
        </ModalDialogButton>
      </div>
    </div>
  );
});

export default AnalysisHighChartSettings;