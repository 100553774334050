import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import {
  TIMESERIES_DETAILS_BASIC_SET_DATATYPE,
} from '../actions/timeSeriesDetails-basic';

export const timeSeriesDetailsBasicReducer = {
  [TIMESERIES_DETAILS_BASIC_SET_DATATYPE](state, action) {
    if (!action.data)
      return state;

    const { categoriesByDataType, value } = action.data;

    let newState = state.setIn(['timeSeriesEditor', 'timeSeries', 'dataType'], value);
    const originalCategories = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'categories']), {});
    if (value || value.trim('').length > 0) {
      let categories = {};
      if (categoriesByDataType[value.toLowerCase()]) {
        const categoriesToExclude = ['datatype', 'granularity', 'granularitytype', 'sourcetimezoneid', 'source', 'style', 'unit'];
        categoriesByDataType[value.toLowerCase()].filter(c => categoriesToExclude.indexOf(c.name.toLowerCase()) < 0).forEach(c => {
          categories[c.name] = originalCategories[c.name] || '';
        });
      }

      newState = newState.setIn(['timeSeriesEditor', 'timeSeries', 'categories'], fromJS(categories));
    }

    return newState;
  }
};