import React from 'react';
import { CheckboxInput } from '../../../shared/CheckboxInput';
import AbsRelDate from '../../../shared/AbsRelDate';

export function PlotOriginFiller({ fillerValues, setFillerValue, updateAllSeries }) {
  const { isEnabled, absRel } = fillerValues.get('plotOrigin').toJS();

  function saveValues(e) {
    e.preventDefault();
    updateAllSeries(['plotOriginEnabled'], isEnabled, { requiresRefresh: true, applyToHighcharts: false });
    if (isEnabled) {
      updateAllSeries(['plotOriginEnabled'], isEnabled, { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['absPlotOrigin'], absRel.abs, { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['relPlotOrigin'], absRel.rel, { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['plotOriginMode'], absRel.mode, { requiresRefresh: true, applyToHighcharts: false });
    }
  }

  return <form onSubmit={e => saveValues(e)} >
    <div className='modal-content'>
      <div className='modal-body'>
        <div className='form-inline form-group'>
          <CheckboxInput checked={isEnabled} onChange={(_, checked) => setFillerValue(['plotOrigin', 'isEnabled'], checked)}>Plot Origin</CheckboxInput>
        </div>

        <div className='form-inline form-group' style={{ opacity: isEnabled ? '1' : '0.4' }}>
          <AbsRelDate disabled={!isEnabled} value={absRel} onChange={value => setFillerValue(['plotOrigin', 'absRel'], value)} />
        </div>
      </div>

      <div className='modal-footer'>
        <button data_id='apply' className='btn btn-sm btn-primary' type='submit' data-close-popover>Apply</button>
      </div>
    </div>
  </form>
}