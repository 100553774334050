import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';

export function ModalDialogButton({ onOpenDialog, onClose, children, buttonContent, title, buttonClassName = 'btn', buttonStyles = {}, size = 'lg' }) {
  const [isOpen, setIsOpen] = useState(false);

  function openDialog(e) {
    e.preventDefault();
    if (onOpenDialog)
      onOpenDialog();

    setIsOpen(true);
  }

  function closeDialog() {
    if (onClose)
      onClose();

    setIsOpen(false);
  }

  function onInnerClick(e) {
    if (e) {
      if (e.target.className === 'dropdown-item' || 
          e.target.getAttribute('data-dismiss') ||
          e.target.getAttribute('data-close-popover')){
        closeDialog();
      }
    }
  }

  function ModalContent() {
    if (typeof children === 'function'){
      return children({ openDialog, closeDialog })
    }
    else{
      return children;
    }
  }

  return <>
      <button type='button' onClick={openDialog} title={title} className={buttonClassName} style={buttonStyles}>
        {buttonContent}
      </button>
      <Dialog open={isOpen} onClose={closeDialog} fullWidth={true} maxWidth={size} >
        <div onClick={e => onInnerClick(e)}>
          <ModalContent/>
        </div>
      </Dialog>
    </>
}
