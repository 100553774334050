import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableHeaderCell } from '../../shared/TableHeader';
import { AnalysisSearchColumns, SearchResultRow } from './SearchResultRow';
import {
  analysisSearchToggleInBasket,
} from '../../../actions/analysis';
import {
  analysisCrossSchemaSearch,
  analysisCrossSchemaSearchSetSearchSetColumnFilter,
  analysisCrossSchemaSearchSetSearchOrderBy,
  analysisCrossSchemaSearchToggleExpand,
  analysisCrossSchemaSearchToggleExpandDetails,
  analysisCrossSchemaSearchToggleExpandItemDetails,
  analysisCrossSchemaSearchAddFavourite,
  analysisCrossSchemaSearchRemoveFavourite
} from '../../../actions/analysis-cross-schema-search';
import {
  analysisCompositionToggleSelected
} from '../../../actions/analysis-composition-v2';
import {
  getTimeSeriesSchema
} from '../../../reducer-functions/analysisCrossSchemaSearch';

export const SearchResultsConnected = connect(
  (state, { stateContext = 'default' }) => ({
    data: state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'data']),
    resultsCriteria: state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'criteria']),
    enableExpandFacets: state.getIn(['analysisCrossSchemaSearch', stateContext, 'enableExpandFacets']),
    enableExpandDetails: state.getIn(['analysisCrossSchemaSearch', stateContext, 'enableExpandDetails']),
    columnFilters: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'columnFilters']),
    orderBy: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'orderBy']),
    orderByDirection: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'orderByDirection']),
    compositionMaxLevel: state.getIn(['analysis', 'composition', 'maxLevel']),
    basket: state.getIn(['analysis', 'workspace', 'timeseries']),
    allSchemas: state.getIn(['analysisCrossSchemaSearch', 'initialise', 'schemas'])
  }),
  (dispatch, { stateContext = 'default', searchHandler = undefined }) => ({
    updateColumnFilter(name, value) {
      dispatch(analysisCrossSchemaSearchSetSearchSetColumnFilter(stateContext, name, value));

      if (searchHandler) {
        searchHandler(stateContext);
      } else {
        dispatch(analysisCrossSchemaSearch(stateContext));
      }

    },
    updateSort(key) {
      dispatch(analysisCrossSchemaSearchSetSearchOrderBy(stateContext, key));

      if (searchHandler) {
        searchHandler(stateContext);
      } else {
        dispatch(analysisCrossSchemaSearch(stateContext));
      }
    },
    addFavourite(id) {
      dispatch(analysisCrossSchemaSearchAddFavourite(stateContext, id));
    },
    removeFavourite(id) {
      dispatch(analysisCrossSchemaSearchRemoveFavourite(stateContext, id));
    },
    toggleExpand(key) {
      dispatch(analysisCrossSchemaSearchToggleExpand(stateContext, key));
    },
    toggleSelection(key, data, selectedSchemaName) {
      dispatch(analysisSearchToggleInBasket(key, data, selectedSchemaName));
      dispatch(analysisCompositionToggleSelected(key));
    },
    toggleExpandDetails() {
      dispatch(analysisCrossSchemaSearchToggleExpandDetails(stateContext));
    },
    toggleExpandItemDetails(key) {
      dispatch(analysisCrossSchemaSearchToggleExpandItemDetails(stateContext, key));
    },
  })
)(({
  stateContext,
  data, columnFilters, orderBy, orderByDirection,
  addFavourite, removeFavourite, basket,
  updateColumnFilter, updateSort, toggleExpand, toggleSelection,
  toggleExpandItemDetails,
  allSchemas, resultsCriteria, displayOptions = {}, addtionalResultRowDisplay }) => {

  const { canSelectResults = true } = displayOptions;
  const _allSchemas = toJS(allSchemas);
  const isActiveTest = useCallback((id) => {
    return canSelectResults && toJS(basket, []).some(ts => `${ts.identityId}` === `${id}`)
  }, [canSelectResults, basket]);

  function onToggleSelection(key, timeSeries) {
    if (!canSelectResults)
      return;

    const _resultsCriteria = toJS(resultsCriteria) ?? {};
    const { schemas = [] } = _resultsCriteria;
    const selectedSchema = getTimeSeriesSchema(timeSeries.schemas, schemas);
    toggleSelection(key, timeSeries, selectedSchema);
  }

  const _analysisSearchColumns = AnalysisSearchColumns.map((a, ai) => {
    if (a) {
      if (displayOptions.noFilters === true) {
        a = { ...a, filterable: false };
      }
    } else {
      a = {key: `${ai}`, title: ''};
    }

    return a;
  });

  return <TableContainer sx={{ height: '100%' }}>
    <Table data_id='search-results' stickyHeader className='table-sm'>
      <TableHead>
        <TableRow>
            {_analysisSearchColumns.map(c => <TableHeaderCell key={c.key} columnData={c} style={c.style} filters={ columnFilters } applyFilter={ updateColumnFilter } orderBy={orderBy} orderByDirection={ orderByDirection } applyOrderBy={ updateSort } />)} 
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((cellData, cellDataIndex) => <SearchResultRow data_id={`row-${cellDataIndex}`} key={`${cellDataIndex}`} cellData={cellData}
          stateContext={stateContext} allSchemas={_allSchemas} toggleExpand={toggleExpand} toggleSelection={onToggleSelection}
          addFavourite={addFavourite} removeFavourite={removeFavourite}
          isActiveTest={isActiveTest} isCompactView={false}
          toggleExpandDetails={toggleExpandItemDetails}
          displayOptions={displayOptions}
          addtionalResultRowDisplay={addtionalResultRowDisplay}
        />)}
      </TableBody>
    </Table>
  </TableContainer>
});