import React, { useRef, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

function CardMessage({ header, body, source, createdOn }) {
  const copyRef = useRef();

  function copyMessage() {
    copyRef.current.select();
    window.document.execCommand('copy');
    copyRef.current.blur();
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <button type='button' className='btn btn-sm btn-outline-primary float-right' onClick={ copyMessage }>
          <i className='far fa-copy fa-fw'></i>
        </button>
        <h5 className='card-title fota-ellipsis-text'>{ header }</h5>
        <p className='card-text'>{ body }</p>
        <textarea ref={ copyRef } className='position-absolute' defaultValue={ body }
                  style={{ height: 0, width: 0, border: 0, opacity: 0, resize: 'none' }}></textarea>
         {source && <Fragment><hr/>
            <p className='card-text'>{ source }</p></Fragment>}
      </div>
      <div className='card-footer'>
        <small className='text-muted'>{ moment(createdOn).format('DD MMM YYYY HH:mm:ss ZZ') }</small>
      </div>
    </div>
  );
}

const Log = connect(
  (state) => ({
    messages: state.getIn(['log', 'messages'])
  })
)(({ messages }) => {
  const _messages = useMemo(() => messages && messages.toJS ? messages.toJS() || [] : messages || [], [messages]);

  return (
    <div className='container-fluid'>
      <div className='card-columns mt-3' style={{ columnCount: 4 }}>
        { _messages.map(i => <CardMessage key={ i.id } { ...i } />) }
      </div>
    </div>
  );
});

export default Log;