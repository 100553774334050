import React, { useEffect, useRef } from 'react';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import HomeNewItemContent from './HomeNewItemContent';
import HomeImageUploadContent from './HomeImageUploadContent';
import HomePageContents from './HomePageContents';
import Loading from '../../shared/Loading';
import Modal from '../../shared/Modal';
import { PopoverButton } from '../../shared/PopoverButton';
import '../../../styles/homepage.css';

function HomePageBreadcrumb({ className, style, scope, path, setPath }) {
  const parts = path && path.length ? path.split('/') : [];
  const initTrail = (scope || '').toLocaleLowerCase() !== 'private' ? [{
    name: 'Home',
    path: '',
    isActive: parts.length === 0,
    onHomeClick
  }] : [{
    name: 'Home',
    path: '',
    isActive: false,
    onHomeClick
  }, {
    name: 'Private',
    path: '',
    isActive: parts.length === 0
  }];

  const trail = parts.reduce((accumulator, value, ix) => [
    ...accumulator, {
      name: value,
      path: [...accumulator.slice(initTrail.length).map(i => i.name), value].join('/'),
      isActive: ix === parts.length - 1
    }
  ], initTrail);

  function onHomeClick(e) {
    e.preventDefault();

    setPath({path:'', scope:''});
  }

  function onSetPathClick(e, path) {
    e.preventDefault();

    setPath({path, scope});
  }

  return (
    <nav className={className} style={style}>
      <ol className='breadcrumb bg-white p-0 m-0'>
        { trail.map(({ name, path, isActive, onHomeClick }, ix) => isActive ? (
          <li key={ix} className='breadcrumb-item active'>
            { name }
          </li>
        ) : (
          <li key={ix} className='breadcrumb-item'>
            <a href={`#${path}`} onClick={e => typeof onHomeClick === 'function' ? onHomeClick(e) : onSetPathClick(e, path)}>
              { name }
            </a>
          </li>
        )) }
      </ol>
    </nav>
  );
}

let delayId;

export default function Home2({ isInitialised, isLoading, isSearchMode, displayMode, orderBy, orderByDirection, scope, path, folder, subFolder, items, searchText, displayFavourites, setPath, sortBy, setDisplayMode, setSearchText, clearSearch, startSearch, refresh,
  addFavourite, removeFavourite, toggleDisplayFavourites, initHomeItemModel, clearWorkspaceModel,
  selectAnalysisWorkspace, selectReportWorkspace, selectWebLink, selectDashboardLink, selectAnalysisTableLink, selectItem,
  deleteWorkspace, openWorkspaceHitCount,
  showSetDisplayMode = true, showNewItem = true, showUploadImage = true, showItemMenu = true }){

  const modalRef = useRef();

  useDidMountEffect(() => {
    const onHideMenus = () => {
    };

    window.addEventListener('scroll', onHideMenus, true);
    window.addEventListener('resize', onHideMenus);
    window.addEventListener('click', onHideMenus);

    return () => {
      window.removeEventListener('scroll', onHideMenus, true);
      window.removeEventListener('resize', onHideMenus);
      window.removeEventListener('click', onHideMenus);
    };
  });

  useEffect(() => {
    if (!isInitialised) refresh();
  }, [isInitialised, refresh]);

  function onNewItem() {
    initHomeItemModel(scope || 'Shared', folder, subFolder);
    if (modalRef.current) modalRef.current.onShow({
      content: <HomeNewItemContent mode='New' />
    });
  }

  function onUploadImage() {
    if (modalRef.current) modalRef.current.onShow({
      content: <HomeImageUploadContent />
    });
  }

  function onSearchTextSubmit(e) {
    if (delayId) clearTimeout(delayId);

    e.preventDefault();
    startSearch();
  };

  function onSearchTextKeyDown(e) {
    if (e.keyCode === 13){
      onSearchTextSubmit(e);
    }
  }

  function onSearchTextChange(e) {
    if (delayId) clearTimeout(delayId);

    setSearchText(e.target.value);
    delayId = setTimeout(() => startSearch(), 750);
  };

  function onSortBy(type, direction, closePopover){
    sortBy(type, direction);
    if (closePopover)
      closePopover();
  }

  let nextDisplayMode, displayIcon, homeSection;
  const homeSectionProps = {
    items: items,
    scope,
    path,
    setPath,
    addFavourite,
    removeFavourite,
    initHomeItemModel,
    clearWorkspaceModel,
    selectAnalysisWorkspace,
    selectReportWorkspace,
    selectWebLink,
    selectDashboardLink,
    selectAnalysisTableLink,
    selectItem,
    deleteWorkspace,
    openWorkspaceHitCount,
    modalRef,
    showItemMenu,
    isSearchMode,
    displayMode
  };

  homeSection = <HomePageContents { ...homeSectionProps } />;

  if (isSearchMode) {
    nextDisplayMode = 'tiles';
    displayIcon = 'fas fa-th-list fa-fw';
    if (displayMode !== 'tiles' && displayMode !== 'list-compact')
    {
      homeSectionProps.displayMode = 'tiles';
    }
  }
  else switch (displayMode) {
    case 'contents':
      nextDisplayMode = 'tiles';
      displayIcon = 'fas fa-th-list fa-fw';
      break;
    case 'tiles':
      nextDisplayMode = 'list-compact';
      displayIcon = 'fas fa-th-large fa-fw';
      break;
    default:
      nextDisplayMode = 'contents';
      displayIcon = 'fas fa-list fa-fw';
      break;
  }

  let nextOrderByDirection, orderByDirectionIcon, orderByContent, orderByIconStyle = { top: 10, left: 5 };
  switch (orderByDirection) {
    case 'asc':
      nextOrderByDirection = 'desc';
      orderByDirectionIcon = (
        <>
          <i className='fas fa-sort fa-fw position-absolute' style={{ ...orderByIconStyle, color: '#ccc' }} />
          <i className='fas fa-sort-up fa-fw position-absolute' style={orderByIconStyle} />
        </>
      );
      break;
    case 'desc':
      nextOrderByDirection = 'asc';
      orderByDirectionIcon = (
        <>
          <i className='fas fa-sort fa-fw position-absolute' style={{ ...orderByIconStyle, color: '#ccc' }} />
          <i className='fas fa-sort-down fa-fw position-absolute' style={orderByIconStyle} />
        </>
      );
      break;
    default:
      nextOrderByDirection = 'asc';
      orderByDirectionIcon = (
        <>
          <i className='fas fa-sort fa-fw position-absolute' style={{ ...orderByIconStyle, color: '#ccc' }} />
        </>
      );
      break;
  }

  switch (orderBy) {
    case 'updatedBy': orderByContent = <>Last updated by</>; break;
    case 'updatedUtc': orderByContent = <>Last updated</>; break;
    case 'name': orderByContent = <>Name</>; break;
   default: orderByContent = <>None</>; break;
  }

  return <>
      <div className='d-flex flex-column h-100 w-100'>
        <div>
          <div className='w-100 pt-2 pb-2 d-flex flex-row flex-nowrap align-items-center'>
            <div className='btn-group mx-2'>
              <button type='button' className='btn btn-link border border-primary' onClick={toggleDisplayFavourites}>
                { displayFavourites
                  ? <i className='fas fa-star fa-fw' style={{ color: 'gold' }} />
                  : <i className='far fa-star fa-fw' /> }
              </button>
              <button type='button' className='btn btn-link border border-primary ml-2' onClick={refresh}>
                <i className='fas fa-sync-alt fa-fw' />
              </button>
            </div>
            <HomePageBreadcrumb className='w-100 mx-2' scope={scope} path={path} setPath={setPath} />
            <div className='home-search input-group border border-secondary rounded' >
              <div className='input-group-prepend'>
                <label className='input-group-text bg-white border-0' htmlFor='homepage-search-input'>
                  <i className='fas fa-search fa-fw mt-1' />
                </label>
              </div>
              <input id='homepage-search-input' type='text' className='form-control border-0' placeholder='Search'
                    onChange={onSearchTextChange} onKeyDown={onSearchTextKeyDown}
                    value={searchText} />
              { (!!searchText || isSearchMode) && (
                <div className='input-group-append'>
                  <button className='btn btn-link user-select-none' type='button' onClick={clearSearch} tabIndex='-1'>
                    <i className='fas fa-times fa-fw' />
                  </button>
                </div>
              ) }
              <button type='submit' className='d-none' />
            </div>
            <div className='btn-group mr-2'>
              <div className='btn-group ml-2'>
                <PopoverButton buttonClassName='btn btn-link border border-primary text-decoration-none text-nowrap rounded' buttonContent={<>{ orderByContent } <i className='fas fa-caret-down fa-fw' /></>}  >
                  {({closePopover}) => (<>
                    <button className='dropdown-item' type='button' onClick={() => onSortBy('name', orderByDirection, closePopover)}>
                      Name
                    </button>
                    <button className='dropdown-item' type='button' onClick={() => onSortBy('updatedUtc', orderByDirection, closePopover)}>
                      Last updated
                    </button>
                    <button className='dropdown-item' type='button' onClick={() => onSortBy('updatedBy', orderByDirection, closePopover)}>
                      Last updated by
                    </button>
                  </>)}
                </PopoverButton>
                <button type='button' className='btn btn-link px-3' onClick={() => sortBy(orderBy, nextOrderByDirection)}>
                  { orderByDirectionIcon }
                </button>
              </div>
              {showUploadImage && <button type='button' className='btn btn-link border border-primary ml-2' onClick={onNewItem}>
                <i className='fas fa-plus fa-fw' />
              </button>}
              {showNewItem && <button type='button' className='btn btn-link border border-primary ml-2' onClick={onUploadImage}>
                <i className='fas fa-file-upload fa-fw' />
              </button>}
              {showSetDisplayMode && !isSearchMode && (
                <div className='btn-group ml-2'>
                  <button type='button' className='btn btn-link border border-primary' onClick={() => setDisplayMode(nextDisplayMode)}>
                    <i className={displayIcon} />
                  </button>
                </div>
              ) }
            </div>
          </div>
       </div>

        <div className='flex-fill w-100' style={{ overflowY: 'auto' }}>
          <Loading isLoading={isLoading} message='Loading...'>
            { homeSection }
          </Loading>
        </div>
      </div>
      <Modal ref={modalRef} />
    </>
}