import React from 'react';
import { connect } from 'react-redux';
import ScreenCaptureTableEditRow from './ScreenCaptureTableEditRow';
import {
  screenCaptureEditPropertyChange,
  screenCaptureSaveEdit,
  screenCaptureCloseEdit
} from '../../../actions/screenCapture';

const ScreenCaptureTableEditRowConnected = connect(
  (state) => ({
    isEnabled: state.getIn(['screenCapture', 'edit', 'isEnabled']),
    cronSpec: state.getIn(['screenCapture', 'edit', 'cronSpec']),
    name: state.getIn(['screenCapture', 'edit', 'name']),
    subject: state.getIn(['screenCapture', 'edit', 'subject']),
    recipients: state.getIn(['screenCapture', 'edit', 'recipients']),
    targetUrl: state.getIn(['screenCapture', 'edit', 'targetUrl']),
    isValidForm: state.getIn(['screenCapture', 'edit', 'isValidForm'])
  }),
  (dispatch) => ({
    onPropertyChange(key, value) {
      dispatch(screenCaptureEditPropertyChange(key, value));
    },
    saveEdit() {
      dispatch(screenCaptureSaveEdit());
    },
    closeEdit() {
      dispatch(screenCaptureCloseEdit());
    }
  })
)(({ isEnabled, cronSpec, name, subject, recipients, targetUrl, isValidForm, onPropertyChange, saveEdit, closeEdit }) => {
  return (
      <ScreenCaptureTableEditRow
        isEnabled={isEnabled}
        cronSpec={cronSpec}
        name={name}
        subject={subject}
        recipients={recipients}
        targetUrl={targetUrl}
        isValidForm={isValidForm}
        onPropertyChange={onPropertyChange}
        saveEdit={saveEdit}
        closeEdit={closeEdit} />
  );
});

export default ScreenCaptureTableEditRowConnected;