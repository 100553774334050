import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { toJS } from '../../../utility/immutable-utility';
import RelativeDate from '../../shared/RelativeDate';

import {
  analysisComparisonModeSetActiveView,
  analysisComparisonModeSetPlotOrigin,
  analysisComparisonModeSetRange,
  analysisComparisonModeClearPreselection,
  analysisComparisonModeTogglePreselection,
  analysisComparisonModeTogglePreselectionColumn,
  analysisComparisonModeTogglePreselectionRow,
  analysisComparisonModeSetDayActiveDate,
  analysisComparisonModeAggregateToggleOperation,
  analysisComparisonModeSetIndividualRelativeDate,
  analysisComparisonModeSetAggregateRelativeDate,
  analysisComparisonModeAddAggregateRelativeToPreselection,
  analysisComparisonModeRemoveAggregateRelativeFromPreselection,
  analysisComparisonModeSetAggregateRelativeFromDate,
  analysisComparisonModeSetAggregateRelativeToDate,

  analysisComparisonModeApplyToBasket,
  analysisComparisonModeBasketLevelSetComparisonMode,
  analysisComparisonModeBasketLevelAddIndividualRelativeToSelection,
  analysisComparisonModeBasketLevelAddPreselectionToSelection,
  analysisComparisonModeBasketLevelAggregateAddPreselectionToSelection,
  analysisComparisonModeBasketLevelAddAggregateRelativeRangeToSelection,
  analysisComparisonModeBasketLevelAddAggregateRelativeDatesToSelection,
  analysisComparisonModeBasketLevelAggregateAddRangeToSelection,
  analysisComparisonModeBasketLevelRemoveSelectedDate,
  analysisComparisonModeBasketLevelRemoveAllSelection
} from '../../../actions/analysis-comparisonmode-v2';

import { IndividualAbsoluteDatesSelector } from '../analysis-comparisons/IndividualAbsoluteDatesSelector';
import { IndividualRelativeDatesSelector } from '../analysis-comparisons/IndividualRelativeDatesSelector';
import { AggregateAbsoluteDatesSelector } from '../analysis-comparisons/AggregateAbsoluteDatesSelector';
import { AggregateRelativeDatesSelector } from '../analysis-comparisons/AggregateRelativeDatesSelector';
import LensedDateTime from '../../shared/LensedDateTime';
import { comparisonModeLensedDateOptions, comparisonModeOptions, comparisonModePlotOriginOptions, comparisonModes } from '../../../utility/comparisonmode-utility';

export const ComparisonBuilderComponent = connect(
  (state) => ({
    allComparisonSettings: state.getIn(['analysis', 'ui', 'comparisonSettings']),
  }),
  (dispatch) => ({
    applySelection(comparisonMode) {
      dispatch(analysisComparisonModeApplyToBasket(comparisonMode));
    },
    setActiveComparisonMode(comparisonMode) {
      dispatch(analysisComparisonModeBasketLevelSetComparisonMode(comparisonMode));
    },
    setActiveView(comparisonMode, view) {
      dispatch(analysisComparisonModeSetActiveView(comparisonMode, view));
    },
    removeAllSelection(comparisonMode) {
      dispatch(analysisComparisonModeBasketLevelRemoveAllSelection(comparisonMode));
    },
    removeSelectedDate(comparisonMode, value) {
      dispatch(analysisComparisonModeBasketLevelRemoveSelectedDate(comparisonMode, value));
    },
    setComparisonModeSetPlotOrigin({comparisonMode, mode, absOriginDate, relOriginDate}) {
      dispatch(analysisComparisonModeSetPlotOrigin(comparisonMode, mode, absOriginDate, relOriginDate));
    },
    setComparisonModeSetRange(comparisonMode, start, end) {
      dispatch(analysisComparisonModeSetRange(comparisonMode, start, end));
    },
    togglePreselection(comparisonMode, value) {
      dispatch(analysisComparisonModeTogglePreselection(comparisonMode, value));
    },
    addPreselectionToSelection(comparisonMode) {
      dispatch(analysisComparisonModeBasketLevelAddPreselectionToSelection(comparisonMode));
    },
    toggleColumn(comparisonMode, column) {
      dispatch(analysisComparisonModeTogglePreselectionColumn(comparisonMode, column));
    },
    toggleRow(comparisonMode, row) {
      dispatch(analysisComparisonModeTogglePreselectionRow(comparisonMode, row));
    },
    clearPreselection(comparisonMode) {
      dispatch(analysisComparisonModeClearPreselection(comparisonMode));
    },
    setDayActiveDate(comparisonMode, date) {
      dispatch(analysisComparisonModeSetDayActiveDate(comparisonMode, date));
    },
    addRangeToSelection(comparisonMode) {
      dispatch(analysisComparisonModeBasketLevelAggregateAddRangeToSelection(comparisonMode));
    },
    addAggregatePreselectionToSelection(comparisonMode) {
      dispatch(analysisComparisonModeBasketLevelAggregateAddPreselectionToSelection(comparisonMode));
    },
    toggleAggregationOperation(comparisonMode, operation, isSelected) {
      dispatch(analysisComparisonModeAggregateToggleOperation(comparisonMode, operation, isSelected));
    },
    setIndividualRelativeDateText(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeSetIndividualRelativeDate(comparisonMode, relativeDate));
    },
    addIndividualRelativeDate(comparisonMode) {
      dispatch(analysisComparisonModeBasketLevelAddIndividualRelativeToSelection(comparisonMode));
    },
    setAggregateRelativeDate(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeSetAggregateRelativeDate(comparisonMode, relativeDate));
    },
    addAggregateRelativeDatesToSelection(comparisonMode) {
      dispatch(analysisComparisonModeBasketLevelAddAggregateRelativeDatesToSelection(comparisonMode));
    },
    setAggregateRelativeFromDate(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeSetAggregateRelativeFromDate(comparisonMode, relativeDate));
    },
    setAggregateRelativeToDate(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeSetAggregateRelativeToDate(comparisonMode, relativeDate));
    },
    addAggregateRelativeRangeToSelection(comparisonMode) {
      dispatch(analysisComparisonModeBasketLevelAddAggregateRelativeRangeToSelection(comparisonMode));
    },
    addAggregateRelativeToPreselection(comparisonMode) {
      dispatch(analysisComparisonModeAddAggregateRelativeToPreselection(comparisonMode));
    },
    removeAggregateRelativeFromPreselection(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeRemoveAggregateRelativeFromPreselection(comparisonMode, relativeDate));
    }
  })
)(({
  onComplete, setActiveComparisonMode,
  allComparisonSettings, applySelection, setActiveView, removeAllSelection, removeSelectedDate,
  setComparisonModeSetRange, togglePreselection, addPreselectionToSelection, toggleColumn, toggleRow, clearPreselection, setDayActiveDate,
  addRangeToSelection, toggleAggregationOperation, addAggregatePreselectionToSelection,
  setIndividualRelativeDateText, addIndividualRelativeDate, setComparisonModeSetPlotOrigin,
  setAggregateRelativeDate, addAggregateRelativeDatesToSelection, setAggregateRelativeFromDate, setAggregateRelativeToDate, addAggregateRelativeRangeToSelection, addAggregateRelativeToPreselection, removeAggregateRelativeFromPreselection
}) => {
  const _allComparisonSettings = toJS(allComparisonSettings);
  const _basket = _allComparisonSettings.basket;
  const _comparisonMode = _basket.comparisonMode;
  const _comparisonSettings = _allComparisonSettings[_basket.comparisonMode];
  const _selection =  _basket.selection;

  const _plotOriginAbs = (_comparisonSettings.plotOrigin && _comparisonSettings.plotOrigin.absOriginDate) ? moment.utc(_comparisonSettings.plotOrigin.absOriginDate).format('YYYY-MM-DD') : '';
  const _plotOriginRel = (_comparisonSettings.plotOrigin && _comparisonSettings.plotOrigin.relOriginDate) ? _comparisonSettings.plotOrigin.relOriginDate : '';
  const _plotOriginMode = (_comparisonSettings.plotOrigin && _comparisonSettings.plotOrigin.mode) ? _comparisonSettings.plotOrigin.mode : 'abs';

  function onCancel(e) {
    if (e) e.preventDefault();

    onComplete();
  }

  function onSetComparisonMode(e) {
    setActiveComparisonMode(e.target.value);
  }

  function onSetPlotOriginMode(value) {
    setComparisonModeSetPlotOrigin({comparisonMode: _comparisonMode, mode: value});
  }

  function onSetAbsPlotOrigin(value) {
    setComparisonModeSetPlotOrigin({comparisonMode: _comparisonMode, absOriginDate: value});
  }

  function onSetRelPlotOrigin(value) {
    setComparisonModeSetPlotOrigin({comparisonMode: _comparisonMode, relOriginDate: value});
  }

  function onSetActiveView(currentView) {
    setActiveView(_comparisonMode, currentView);
  }

  function onApply() {
    applySelection(_comparisonMode);
    onComplete();
  }

  const onRemoveSelectedDate = (value) => {
    removeSelectedDate(_comparisonMode, value);
  }

  const onRemoveAllSelection = () => {
    removeAllSelection(_comparisonMode);
  }

  function isActiveView(_comparisonSettings, activeView) {
    return _comparisonSettings.activeView === activeView;
  }

  return <form className='modal-content'>
    <div className='modal-header'>
      <h5 className='modal-title'>Comparisons for: {_basket.timeSeriesName}</h5>
      <button type='button' className='close' onClick={onCancel}>&times;</button>
    </div>
    <div className='modal-body'>
      <div className='row'>
        <div className='col-md-12 col-lg-12'>
          <label className='font-weight-bold'>Comparison mode</label>
          {comparisonModeOptions.filter(c => c.value !== comparisonModes.none).map(c => <div key={c.value} className='form-check d-inline-block ml-3'>
            <input className='form-check-input' type='radio' value={c.value} name='comparison' id={`comparison-${c.value}`}
              checked={_comparisonMode === c.value}
              onChange={onSetComparisonMode} />
            <label className='form-check-label' htmlFor={`comparison-${c.value}`}>
              {c.name}
            </label>
          </div>)}
        </div>
      </div>

      <hr />

      <div className='row'>
        <div className='col-md-12 col-lg-12'>
          <ul className='nav nav-tabs mb-2'>
            <li className='nav-item'>
              <button className={`nav-link ${isActiveView(_comparisonSettings, 'individualAbs') ? 'active' : ''}`} type='button' onClick={() => onSetActiveView('individualAbs')}>Standard</button>
            </li>
            <li className='nav-item ml-1' >
              <button className={`nav-link ${isActiveView(_comparisonSettings, 'individualRel') ? 'active' : ''}`} type='button' onClick={() => onSetActiveView('individualRel')}>Relative</button>
            </li>
            <li className='nav-item ml-1'>
              <button className={`nav-link ${isActiveView(_comparisonSettings, 'aggregationAbs') ? 'active' : ''}`} type='button' onClick={() => onSetActiveView('aggregationAbs')}>Aggregated</button>
            </li>
            <li className='nav-item ml-1'>
              <button className={`nav-link ${isActiveView(_comparisonSettings, 'aggregationRel') ? 'active' : ''}`} type='button' onClick={() => onSetActiveView('aggregationRel')}>Relative Aggregated</button>
            </li>
          </ul>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12 col-lg-12' >
          <div style={{ minHeight: '434px' }}>
            {isActiveView(_comparisonSettings, 'individualAbs') && <IndividualAbsoluteDatesSelector
              comparisonSettings={_comparisonSettings} comparisonMode={_comparisonMode}
              setComparisonModeSetRange={setComparisonModeSetRange} togglePreselection={togglePreselection} addPreselectionToSelection={addPreselectionToSelection} toggleColumn={toggleColumn} toggleRow={toggleRow} clearPreselection={clearPreselection} setDayActiveDate={setDayActiveDate} />}

            {isActiveView(_comparisonSettings, 'individualRel') && <IndividualRelativeDatesSelector
              comparisonSettings={_comparisonSettings} comparisonMode={_comparisonMode}
              setRelativeDateText={setIndividualRelativeDateText} applyRelativeDate={addIndividualRelativeDate} />}

            {isActiveView(_comparisonSettings, 'aggregationAbs') && <AggregateAbsoluteDatesSelector
              comparisonSettings={_comparisonSettings} comparisonMode={_comparisonMode}
              setComparisonModeSetRange={setComparisonModeSetRange} togglePreselection={togglePreselection} addPreselectionToSelection={addPreselectionToSelection} toggleColumn={toggleColumn} toggleRow={toggleRow} clearPreselection={clearPreselection} setDayActiveDate={setDayActiveDate}
              addRangeToSelection={addRangeToSelection} toggleAggregationOperation={toggleAggregationOperation} addAggregatePreselectionToSelection={addAggregatePreselectionToSelection} />}

            {isActiveView(_comparisonSettings, 'aggregationRel') && <AggregateRelativeDatesSelector
              comparisonSettings={_comparisonSettings} comparisonMode={_comparisonMode}
              setAggregateRelativeDate={setAggregateRelativeDate} toggleAggregationOperation={toggleAggregationOperation} addAggregateRelativeDatesToSelection={addAggregateRelativeDatesToSelection} setAggregateRelativeFromDate={setAggregateRelativeFromDate} setAggregateRelativeToDate={setAggregateRelativeToDate} addAggregateRelativeRangeToSelection={addAggregateRelativeRangeToSelection} addAggregateRelativeToPreselection={addAggregateRelativeToPreselection} removeAggregateRelativeFromPreselection={removeAggregateRelativeFromPreselection} />}
          </div>
        </div>
      </div>

      <hr />

      <div className='row'>
        <div className='col-md-6' >
          <div className='form-inline form-group'>
            <div className='d-flex'>
              <label className='fota-input-group-label font-weight-bold'>Plot Origin</label>
              {_plotOriginMode === 'abs' && <LensedDateTime lens={comparisonModeLensedDateOptions[_comparisonMode]} dateTimeClassName='form-control' placeholder='Origin'
                              value={_plotOriginAbs}
                              onChange={onSetAbsPlotOrigin} />}
              {_plotOriginMode === 'rel' && <RelativeDate value={_plotOriginRel} onChange={onSetRelPlotOrigin} options = {comparisonModePlotOriginOptions[_comparisonMode]} />}

              <div className='form-check ml-2'>
                <input className='form-check-input' type='radio' value='abs' id='datemode-mode-abs'
                  checked={_plotOriginMode === 'abs'}
                  onChange={() => onSetPlotOriginMode('abs')} />
                <label className='form-check-label' htmlFor='datemode-mode-abs' >Absolute</label>
              </div>
              <div className='form-check ml-2'>
                <input className='form-check-input' type='radio' value='rel' id='datemode-mode-rel'
                  checked={_plotOriginMode === 'rel'}
                  onChange={() => onSetPlotOriginMode('rel')} />
                <label className='form-check-label' htmlFor='datemode-mode-rel'>Relative</label>
              </div>                              
            </div>
          </div>
        </div>
        <div className='col-md-12' >
          <div className='form-group'>
            <label className='font-weight-bold'>Selected Dates</label>
            <div className='input-group w-100'>
              <div className='form-control' style={{ height: "6em", overflowY: "auto" }}>
                {_selection.map(s => <div key={`${s.mode}-${s.name}`} title={s.description} className='btn btn-outline-secondary btn-sm mr-1 mb-1 ml-1 mt-1 p-0' onClick={() => onRemoveSelectedDate(s)} >
                  <div className='p-2'>
                    {s.mode === comparisonModes.gasYear && <span>GY&nbsp;</span>}
                    <span >{s.name}</span>
                    <i className='fas fa-times fa-fw pl-1' />
                  </div>
                </div>)}
              </div>
              <div className='input-group-append dropdown'>
                {_selection.length > 0 && <button type='button' className='btn btn-outline-secondary' onClick={onRemoveAllSelection} >
                  <i className='fas fa-times fa-fw' />
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' onClick={onCancel}>Cancel</button>
        <button type='button' className='btn btn-primary' onClick={onApply}>Apply</button>
      </div>
    </div>
  </form>
});