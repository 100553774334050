import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import moment from 'moment';
import {
  ANALYSIS_TIMESERIES_VERSIONS_INIT_BEGIN,
  ANALYSIS_TIMESERIES_VERSIONS_INIT_COMPLETE,
  ANALYSIS_TIMESERIES_VERSIONS_SET_TAB,
  ANALYSIS_TIMESERIES_VERSIONS_SAVE_SETTINGS,

  ANALYSIS_VERSION_SELECT_TOGGLE_SHOW_DEFAULT,

  ANALYSIS_VERSION_SELECT_TOGGLE_OPERATION,

  ANALYSIS_VERSION_SELECT_TOGGLE_ASAT,
  ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_BEGIN,
  ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_COMPLETE,

  ANALYSIS_VERSION_SELECT_ADD_ADHOC_ASAT,
  ANALYSIS_VERSION_SELECT_DELETE_ADHOC_ASAT,
  ANALYSIS_VERSION_SELECT_DELETE_ALL_ADHOC_ASATS,
  ANALYSIS_VERSION_SELECT_EDIT_ADHOC_ASAT_VALUE,

  ANALYSIS_VERSION_SELECT_ADD_RELATIVE_ASAT,
  ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_ASAT,
  ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_VALUE,
  ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_CUTOFF,


  ANALYSIS_VERSION_SELECT_ADD_RELATIVE_FORECAST,
  ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_FORECAST,
  ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_VALUE,
  ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_CUTOFF

} from '../actions/analysis-versions';

import {
  projectForecastsToBasket,
  extractForecastOptionsForBasketItem,
  rebuildDisplayNamesToState
} from '../utility/analysis-basket-utility';
import { mergeBasketToBaseline } from '../reducer-functions/analysis-dynamic-workspace';

export const versionsReducer = {
  [ANALYSIS_TIMESERIES_VERSIONS_INIT_BEGIN](state, action) {
    const basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const key = action.key;
    const timeSeriesForecasts = extractForecastOptionsForBasketItem({ basket, key })
    const timeSeries = timeSeriesForecasts.timeSeries;
    const previousSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    const timeseriesVersionSettings = {
      tab: ((timeSeries.style === 'Derived' && timeSeries.derivationType === 'Standard') || timeSeries.style === 'Simple') ? 'AdHocAsAt' : 'AsAt',
      timeseries: timeSeries,
      key: key,
      asAts: {
        isLoading: true,
        page: 1,
        pageSize: 25,
        data: [],
        selection: timeSeriesForecasts.asAts || []
      },
      adHocAsAts: {
        editValue: previousSettings.adHocAsAts && previousSettings.adHocAsAts.editValue ? previousSettings.adHocAsAts.editValue : moment.utc().format('YYYY-MM-DDTHH:mm'),
        selection: timeSeriesForecasts.adHocAsAts || []
      },
      relativeAsAts: {
        editValue: previousSettings.relativeAsAts && previousSettings.relativeAsAts.editValue ? previousSettings.relativeAsAts.editValue : { period: '-1D' },
        selection: timeSeriesForecasts.relativeAsAts || []
      },
      relativeForecasts: {
        editValue: previousSettings.relativeForecasts && previousSettings.relativeForecasts.editValue ? previousSettings.relativeForecasts.editValue : { period: '-1D' },
        selection: timeSeriesForecasts.relativeForecasts || []
      }
    };

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_TIMESERIES_VERSIONS_INIT_COMPLETE](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    timeseriesVersionSettings.asAts.isLoading = false;
    if (action.data) {
      timeseriesVersionSettings.asAts.data = action.data;
    }

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_TOGGLE_SHOW_DEFAULT](state, action) {
    const showDefault = state.getIn(['ui', 'timeseriesVersionSettings', 'showDefault']);
    return state.setIn(['ui', 'timeseriesVersionSettings', 'showDefault'], !showDefault);
  },
  [ANALYSIS_TIMESERIES_VERSIONS_SET_TAB](state, action) {
    return state.setIn(['ui', 'timeseriesVersionSettings', 'tab'], action.name);
  },
  [ANALYSIS_TIMESERIES_VERSIONS_SAVE_SETTINGS](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});

    const basket = projectForecastsToBasket({
      basket :toJS(state.getIn(['workspace', 'timeseries']), []),
      key: timeseriesVersionSettings.key,
      asAts: timeseriesVersionSettings.asAts.selection,
      adHocAsAts: timeseriesVersionSettings.adHocAsAts.selection,
      relativeAsAts: timeseriesVersionSettings.relativeAsAts.selection,
      relativeForecasts: timeseriesVersionSettings.relativeForecasts.selection
    });

    const baselineBasket = mergeBasketToBaseline(
        toJS(state.getIn(['workspace', 'baselineBasket'])),
        basket);   

    state = state
      .setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket))
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['workspace', 'isDirty'], true);

    state = rebuildDisplayNamesToState(state);
    return state;
  },
  [ANALYSIS_VERSION_SELECT_TOGGLE_OPERATION](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    const operation = timeseriesVersionSettings.operations.find(op => op.name === action.operation);
    operation.isSelected = !operation.isSelected;
    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_TOGGLE_ASAT](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    if (timeseriesVersionSettings.asAts.selection.find(a => a === action.asAt.timestampUtc)) {
      timeseriesVersionSettings.asAts.selection = timeseriesVersionSettings.asAts.selection.filter(a => a !== action.asAt.timestampUtc);
    } else {
      timeseriesVersionSettings.asAts.selection.push(action.asAt.timestampUtc);
    }

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_BEGIN](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    timeseriesVersionSettings.asAts.isLoading = true;
    timeseriesVersionSettings.asAts.page = action.page;
    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_ASAT_PAGE_CHANGE_COMPLETE](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    timeseriesVersionSettings.asAts.isLoading = false;
    if (action.data) {
      timeseriesVersionSettings.asAts.data = action.data;
    }

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_ADD_ADHOC_ASAT](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    timeseriesVersionSettings.adHocAsAts.editValue = action.adHocAsAt;
    if (action.adHocAsAt && !timeseriesVersionSettings.adHocAsAts.selection.some(a => a === action.adHocAsAt)) {
      timeseriesVersionSettings.adHocAsAts.selection.push(action.adHocAsAt);
    }

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_EDIT_ADHOC_ASAT_VALUE](state, action) {
    return state.setIn(['ui', 'timeseriesVersionSettings', 'adHocAsAts', 'editValue'], action.value);
  },
  [ANALYSIS_VERSION_SELECT_DELETE_ADHOC_ASAT](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    timeseriesVersionSettings.adHocAsAts.selection = timeseriesVersionSettings.adHocAsAts.selection.filter(a => a !== action.adHocAsAt);

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_DELETE_ALL_ADHOC_ASATS](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    timeseriesVersionSettings.adHocAsAts.selection = [];

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_ADD_RELATIVE_ASAT](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});

    let cutoffTime = action.relativeAsAt.cutoffTime;

    if (cutoffTime !== undefined && cutoffTime.length === 2)
      cutoffTime = cutoffTime += ':00';

    if (action.relativeAsAt && !timeseriesVersionSettings.relativeAsAts.selection.some(a => a.period === action.relativeAsAt.period && a.cutoffTime === cutoffTime)) {
      timeseriesVersionSettings.relativeAsAts.selection.push({ period: action.relativeAsAt.period, cutoffTime });
    }

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_VALUE](state, action) {
    return state.setIn(['ui', 'timeseriesVersionSettings', 'relativeAsAts', 'editValue', 'period'], action.value);
  },
  [ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_ASAT_CUTOFF](state, action) {
    return state.setIn(['ui', 'timeseriesVersionSettings', 'relativeAsAts', 'editValue', 'cutoffTime'], action.value);
  },
  [ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_ASAT](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    timeseriesVersionSettings.relativeAsAts.selection = timeseriesVersionSettings.relativeAsAts.selection.filter(a => a.period !== action.relativeAsAt.period || a.cutoffTime !== action.relativeAsAt.cutoffTime);

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_ADD_RELATIVE_FORECAST](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});

    let cutoffTime = action.relativeForecast.cutoffTime;

    if (cutoffTime !== undefined && cutoffTime.length === 2)
      cutoffTime = cutoffTime += ':00';

    if (action.relativeForecast && !timeseriesVersionSettings.relativeForecasts.selection.some(a => a.period === action.relativeForecast.period && a.cutoffTime === cutoffTime)) {
      timeseriesVersionSettings.relativeForecasts.selection.push({ period: action.relativeForecast.period, cutoffTime });
    }

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_DELETE_RELATIVE_FORECAST](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    timeseriesVersionSettings.relativeForecasts.selection = timeseriesVersionSettings.relativeForecasts.selection.filter(a => a.period !== action.relativeForecast.period || a.cutoffTime !== action.relativeForecast.cutoffTime);

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings));
  },
  [ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_VALUE](state, action) {
    return state.setIn(['ui', 'timeseriesVersionSettings', 'relativeForecasts', 'editValue', 'period'], action.value);
  },
  [ANALYSIS_VERSION_SELECT_EDIT_RELATIVE_FORECAST_CUTOFF](state, action) {
    return state.setIn(['ui', 'timeseriesVersionSettings', 'relativeForecasts', 'editValue', 'cutoffTime'], action.value);
  },
};