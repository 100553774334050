export function getFullPath({ workspace, includeType = true, includeScope = true, includeFolder = true }) {
  const path = [
    includeType ? workspace.type : null,
    includeScope ? workspace.scope : null,
    includeFolder ? workspace.parentFolderPath : null,
    workspace.name].filter(s => s).join('/');
  return path;
}

export function getShortcutPath(workspace) {
  return getFullPath({ workspace, includeType: true });
}