import React, { Fragment } from 'react';
import CollapsePanel from '../../../shared/CollapsePanel';
import ConfirmButton from '../../../shared/ConfirmButton';
import { Autocomplete, TextField } from '@mui/material';

function SchemaTableEdit({ allSchemaLookups, schemaName, identityId, categoryValues,
  editSchemaCategory, saveIdentitySchema, stopSchemaEdit,
  selectedLookup, setSelectedLookup, lookupValue, setLookupValue, saveLookup,
  copyFromIdentityId, copyCategoriesFrom, setCopyFromIdentityId }) {

  const lookups = [''].concat([...new Set(categoryValues.filter(x => x.valuesLookup).map(x => x.valuesLookup))]).sort();
  const schemaLookups = allSchemaLookups[schemaName.toLowerCase()] || {};

  return (
    <Fragment>
      <div className='row no-gutters mb-2'>
        <div className='btn-group'>
          <button className='btn btn-primary' onClick={e => saveIdentitySchema(schemaName, identityId)}>
            <i className='fas fa-save' />
          </button>
          <button className='btn btn-secondary' onClick={e => stopSchemaEdit()}>
            <i className='fas fa-times' />
          </button>
        </div>

        <input className='ml-4' type='number' value={copyFromIdentityId}  onChange={e => setCopyFromIdentityId(e.target.value)} />
        <button className='btn btn-default' title='Copy category values from another series' onClick={e => copyCategoriesFrom()}>
          <i className='fas fa-search' />
        </button>
        
        <select className='ml-4' value={selectedLookup} onChange={e => setSelectedLookup(e.target.value)} >            
            {lookups.map(x => <option key={x}>{x}</option>)}
        </select>
        <input className='ml-2' value={lookupValue} onChange={e => setLookupValue(e.target.value)} />
        <button className='btn btn-default' title='Save a new value to lookups' onClick={e => saveLookup()}>
          <i className='fas fa-plus' />
        </button>

      </div>
      <table className='col-12 table table-sm table-striped table-bordered'>
        <tbody>
          {categoryValues.map(x => {
            if(x.isGenerated) {
              return (
              <tr key={x.category}>
                <td className='col-3'>{x.category}</td>
                <td className='col-6'>
                  <div>{x.value}</div>
                </td>
                <td className='col-3'>{x.defaultValue}</td>              
              </tr>
              );
            }
            else {
              const lookups = x.valuesLookup && schemaLookups[x.valuesLookup.toLowerCase()] ? schemaLookups[x.valuesLookup.toLowerCase()] : null;
              if (lookups) {
                return (
                  <tr key={x.category}>
                    <td className='col-3'>{x.category}</td>
                    <td className='col-6 mui-default'>
                      <Autocomplete
                        value={x.value}
                        disableClearable={true}
                        sx={{ padding: 0 }}
                        onChange={(event, newValue) => {
                          editSchemaCategory(schemaName, x.category, newValue.id);
                        }}
                        options={lookups.map(f => ({ label: f.value, id: f.value}))}
                        renderOption={(props, option) => (
                          <span {...props}>
                            {option.label}&nbsp;
                          </span>
                        )}

                        renderInput={(item) => <TextField {...item} />}
                      />
                    </td>
                    <td className='col-3'>{x.defaultValue}</td>              
                  </tr>);
              } else {
                return (
                  <tr key={x.category}>
                    <td className='col-3'>{x.category}</td>
                    <td className='col-6'>
                      <input className='form-control' value={x.value} onChange={e => editSchemaCategory(schemaName, x.category, e.target.value)} />
                    </td>
                    <td className='col-3'>{x.defaultValue}</td>              
                  </tr>);
              }
            }
          })}
        </tbody>
      </table>
    </Fragment>
  );
}

function SchemaTableView({ schemaName, identityId, categoryValues,
  deleteIdentitySchema, startSchemaEdit }) {
  return (
    <Fragment>
      <div className='row no-gutters mb-2'>
        <div className='btn-group'>
          <button className='btn btn-primary' onClick={e => startSchemaEdit(schemaName)}>
            <i className='fas fa-edit' />
          </button>
          <ConfirmButton type='button' className='btn btn-secondary' confirmClassName='btn btn-outline-warning' onClick={e => deleteIdentitySchema(schemaName, identityId)}
            content={
              <span style={{ whiteSpace: 'nowrap', fontSize: '12px' }}><i className='fas fa-trash fa-fw' /></span>
            }
            confirm={
              <span style={{ whiteSpace: 'nowrap', fontSize: '12px' }}><i className='fas fa-trash fa-fw' />&nbsp;Delete?</span>
            }>
          </ConfirmButton>
        </div>
      </div>
      <table className='col-12 table table-sm table-striped table-bordered'>
        <tbody>
          {categoryValues.map(x =>
            <tr key={x.category}>
              <td className='col-3'>{x.category}</td>
              <td className='col-6'>
                <div>{x.value}</div>
              </td>
              <td className='col-3'>{x.defaultValue}</td>              
            </tr>)}
        </tbody>
      </table>
    </Fragment>
  );
}

export default function Schema({ allSchemaLookups, schema, identityId, categoryValues, schemaInEdit,
  deleteIdentitySchema, editSchemaCategory, saveIdentitySchema, startSchemaEdit, stopSchemaEdit,   
  selectedLookup, setSelectedLookup, lookupValue, setLookupValue, saveLookup,
  copyFromIdentityId, copyCategoriesFrom, setCopyFromIdentityId }) {
  return (
    <CollapsePanel idKey={schema.id} title={schema.name} headerStyle={{ cursor: 'pointer', backgroundColor: schema.colour, color: 'White' }}>
      {schemaInEdit === schema.name
        ? <SchemaTableEdit allSchemaLookups={allSchemaLookups} 
                           schemaName={schema.name} 
                           identityId={identityId} 
                           categoryValues={categoryValues} 
                           editSchemaCategory={editSchemaCategory} 
                           saveIdentitySchema={saveIdentitySchema} 
                           stopSchemaEdit={stopSchemaEdit} 
                           selectedLookup={selectedLookup}
                           setSelectedLookup={setSelectedLookup}
                           lookupValue={lookupValue}
                           setLookupValue={setLookupValue}
                           saveLookup={saveLookup}
                           copyFromIdentityId={copyFromIdentityId}
                           copyCategoriesFrom={copyCategoriesFrom}
                           setCopyFromIdentityId={setCopyFromIdentityId} />
        : <SchemaTableView schemaName={schema.name} identityId={identityId} categoryValues={categoryValues} startSchemaEdit={startSchemaEdit} deleteIdentitySchema={deleteIdentitySchema} />}
    </CollapsePanel>
  );
}