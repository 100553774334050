import React, { useEffect, useState, useCallback } from 'react';

export function PaginationPage({ suffix = '', prefix = '', pageNumber, updatePage, isActiveTest }) {
  const isActive = isActiveTest && isActiveTest(pageNumber);

  return (
    <li key={ pageNumber } className={ `page-item ${isActive ? 'active' : ''}` }>
      <a {...(isActive ? {data_id:'page-index'} : {})} className='page-link' href={ pageNumber } onClick={ updatePage }>{ `${prefix}${pageNumber.toLocaleString()}${suffix}` }</a>
    </li>
  );
}

export default function Pagination({ currentPage, steps, pageSize, itemCount, onPageChange, hidePageSize, hideJumpTo, showTotalPageCount = true }) {
  const [pagination, setPagination] = useState([]);
  const [jumpTo, setJumpTo] = useState('');

  const isActiveTest = useCallback(page => page === currentPage, [currentPage]),
        pageCount = Math.ceil(itemCount / pageSize),
        previousPage = currentPage - 1,
        nextPage = currentPage + 1;

  function getPages(i, length) {
    let pages = [];

    for (i; i <= length; i++) pages.push({
      pageNumber: i
    });

    return pages;
  }

  useEffect(() => {
    let pages;

    if (pageCount < steps * 2) {
      pages = [...getPages(1, pageCount)];
    }
    else if (currentPage - 1 <= steps) {
      pages = [...getPages(1, steps * 2), {
        pageNumber: pageCount,
        prefix: '...'
      }];
    }
    else if (currentPage >= (pageCount - steps)) {
      pages = [{
        pageNumber: 1,
        suffix: '...'
      }, ...getPages(pageCount - (steps * 2) + 1, pageCount)];
    }
    else {
      pages = [{
        pageNumber: 1,
        suffix: '...'
      }, ...getPages(currentPage - steps + 1, currentPage + steps - 1), {
        pageNumber: pageCount,
        prefix: '...'
      }];
    }

    if (!showTotalPageCount) {
      pages = pages.filter(p => p.prefix !== '...');
    }

    if (pages) setPagination(pages);
  }, [currentPage, steps, pageSize, pageCount, showTotalPageCount])

  function isPageInRange(page) {
    return page > 0 && page <= pageCount;
  }

  function updatePage(e) {
    e.preventDefault();

    const page = Number(e.target.getAttribute('href'));

    if (isPageInRange(page)) onPageChange(page);
  }

  function updatePageSize(e) {
    const page = 1,
          pageSize = e.target.value;

    onPageChange(page, pageSize);
  }

  function jumpToPage(e) {
    e.preventDefault();

    const page = Math.floor(jumpTo);

    if (isPageInRange(page) && page !== currentPage) {
      onPageChange(page);
      setJumpTo('');
    }
  }

  return (
    <div className='d-flex justify-content-center' style={{ visibility: pageCount > 0 ? 'visible' : 'hidden' }}>
      <div className={ `input-group ${hidePageSize ? 'd-none' : ''}` } style={{ maxWidth: 125 }}>
        <div className='input-group-prepend'>
          <label className='input-group-text'>Show</label>
        </div>
        <select data_id='page-size' className='custom-select' value={ pageSize } onChange={ updatePageSize }>
          <option value='25'>25</option>
          <option value='50'>50</option>
          <option value='100'>100</option>
          <option value='500'>500</option>
          <option value='1000'>1000</option>
        </select>
      </div>
      <nav>
        <ul className='pagination mb-0 mx-3'>
          <li className={ 'page-item' + (isPageInRange(previousPage) ? '' : ' disabled') }>
            <a className='page-link' href={ previousPage } onClick={ updatePage }>&laquo;</a>
          </li>
          { pagination.map((i, ix) => <PaginationPage key={ ix } { ...i } updatePage={ updatePage } isActiveTest={ isActiveTest } />) }
          <li className={ 'page-item' + (isPageInRange(nextPage) ? '' : ' disabled') }>
            <a className='page-link' href={ nextPage } onClick={ updatePage }>&raquo;</a>
          </li>
        </ul>
      </nav>
      <form className={ `input-group ${hideJumpTo ? 'd-none' : ''}` } style={{ maxWidth: 125 }} onSubmit={ jumpToPage } noValidate>
        <input className='form-control' type='number' step='1' min='1' max={ pageCount }
               value={ jumpTo }
               onChange={ (e) => setJumpTo(e.target.value) } />
        <div className='input-group-append'>
          <button className='btn btn-outline-primary' type='submit'>Go</button>
        </div>
      </form>
    </div>
  );
}