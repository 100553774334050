import { createReducer } from '../utility/redux-utility';
import {
  HISTORY_LOAD_COMPLETE,
  HISTORY_LOADING,
  HISTORY_TOGGLE_SELECTION
} from '../actions/historyViewer';
import { fromJS } from 'immutable';

export const historyViewer = createReducer(null, {
  [HISTORY_LOAD_COMPLETE](state, action) {
    action.data.forEach(x=> {
      x.selected = false;
    });
    return state.set('rows', fromJS(action.data));
  },
  [HISTORY_LOADING](state, action) {
    return state.set('isLoading', action.isLoading);
  },
  [HISTORY_TOGGLE_SELECTION](state, action) {
    state = state.set('selectedRow', action.row)
                 .update('rows', rows => rows.map(r => { 
                  const matching = r.getIn(['audit','validFrom']) === (action.row && action.row.getIn(['audit','validFrom']));
                  const selected = matching && !r.get('selected') ? true : false;
                  return r.set('selected', selected);
              }));
    
    state = state.set('selectedAudit', null)
                 .set('selectedIdentityVersion', null)
                 .set('isItemSelected', false);

    const rows = state.get('rows').toJS();
    for(let i=0; i< rows.length; i++) {
      if(rows[i].selected === true)
        state = state.set('selectedAudit', fromJS(rows[i].audit))
                     .set('selectedIdentityVersion', fromJS(rows[i].identity))
                     .set('isItemSelected', true);
    }

    return state;
  }
});