import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CheckboxInput } from '../../../shared/CheckboxInput';
import AbsRelDate from '../../../shared/AbsRelDate';
import { analysisReportCriteriaUpdatePeriodAbs } from '../../../../actions/analysis';
import Loading from '../../../shared/Loading';

export const WindowOverrideFiller = connect(
  (state) => ({
    periodsAbs: state.getIn(['analysis', 'ui', 'periodsAbs']),
    isPeriodsAbsLoading: state.getIn(['analysis', 'ui', 'isPeriodsAbsLoading']),
  }),
  (dispatch) => ({
    initialise() {
      dispatch(analysisReportCriteriaUpdatePeriodAbs());
    }
  })
)(({ initialise, fillerValues, setFillerValue, updateAllSeries, periodsAbs, isPeriodsAbsLoading }) => {
  const { isEnabled, from, to, period } = fillerValues.get('windowOverride').toJS();
  const periods = periodsAbs.toJS();

  useEffect(() => {
    initialise();
  }, [initialise]);

  function saveValues(e) {
    e.preventDefault();

    if (!isEnabled) {
      updateAllSeries(['windowType'], 'none', { requiresRefresh: true, applyToHighcharts: false });
      updateAllSeries(['period'], '', { requiresRefresh: true, applyToHighcharts: false });
    }
    else {
      if (period) {
        updateAllSeries(['windowType'], 'none', { requiresRefresh: true, applyToHighcharts: false });
        updateAllSeries(['period'], period, { requiresRefresh: true, applyToHighcharts: false });
        updateAllSeries(['window'],
          {
            fromDateMod: 'abs',
            fromUtc: from.abs,
            toDateMode: 'abs',
            toUtc: to.abs
          },
          { requiresRefresh: true, applyToHighcharts: false });
      } else {
        updateAllSeries(['windowType'], 'override', { requiresRefresh: true, applyToHighcharts: false });
        updateAllSeries(['period'], '', { requiresRefresh: true, applyToHighcharts: false });
        updateAllSeries(['window'],
          {
            fromDateMod: 'abs',
            fromUtc: from.abs,
            toDateMode: 'abs',
            toUtc: to.abs
          },
          { requiresRefresh: true, applyToHighcharts: false });
      }
    }
  }

  function setPeriodFillerValue(value) {
    setFillerValue(['windowOverride', 'period'], value);

    const period = periods.find(p => p.name === value);
    if (period) {
      setFillerValue(['windowOverride', 'from'], {
        mode: 'abs',
        abs: period.absFromDate
      });
      setFillerValue(['windowOverride', 'to'], {
        mode: 'abs',
        abs: period.absToDate
      });
    }
  }

  function setOverrideFillerValue(key, value) {
    setFillerValue(['windowOverride', 'period'], '');
    setFillerValue(key, value)
  }

  return <form onSubmit={e => saveValues(e)} >
    <Loading isLoading={isPeriodsAbsLoading}>
      <div className='modal-content'>
        <div className='modal-body'>
          <div className='form-inline form-group'>
            <CheckboxInput checked={isEnabled} onChange={(_, checked) => setFillerValue(['windowOverride', 'isEnabled'], checked)}>Override Window</CheckboxInput>
          </div>

          <div className='form-row pb-1' style={{ opacity: isEnabled ? '1' : '0.4' }}>
            <label className='font-weight-bold col-sm-2 mt-2'>Period</label>
            <div className='col-sm-10'>
              <select className='form-control form-control-sm'
                disabled={!isEnabled}
                value={period ?? ''}
                onChange={e => setPeriodFillerValue(e.target.value)}>
                {period === '' && <option disabled key='-top-option-' value=''></option>}
                {periods.map(period => <option key={period.name} value={period.name}>{period.name}</option>)}
              </select>
            </div>
          </div>

          <div className='form-row pb-1' style={{ opacity: isEnabled ? '1' : '0.4' }}>
            <label className='font-weight-bold col-sm-2 mt-2'>From</label>
            <div className='col-sm-10'>
              <AbsRelDate lens={'None'} disabled={!isEnabled} value={from} onChange={value => setOverrideFillerValue(['windowOverride', 'from'], value)} />
            </div>
          </div>

          <div className='form-row pb-1' style={{ opacity: isEnabled ? '1' : '0.4' }}>
            <label className='font-weight-bold col-sm-2 mt-2'>To</label>
            <div className='col-sm-10'>
              <AbsRelDate lens={'None'} disabled={!isEnabled} value={to} onChange={value => setOverrideFillerValue(['windowOverride', 'to'], value)} />
            </div>
          </div>
        </div>

        <div className='modal-footer'>
          <button data_id='apply' className='btn btn-sm btn-primary' type='submit' data-close-popover>Apply</button>
        </div>
      </div>
    </Loading>
  </form>

})

export default WindowOverrideFiller;