import React from 'react';
import LoadingIcon from '../../../shared/LoadingIcon';
import MaterialiseRangeDialog from './MaterialiseRangeDialog';
import Tooltip from '../../../shared/Tooltip';

export default function VariantsEditorToolbar({ isLoading, loadVariants, addVariant, 
  materialiseRangeDialogVisible, materialiseRangeFromUtc, materialiseRangeToUtc, 
  requestRematerialise, requestRematerialiseWithRange, setMaterialiseRangeDialogVisible, setMaterialiseRangeDate,
  suppressArchive, setSuppressArchive,
  isContributor }) {

  return <>
      <div className='form fota-compact-form px-2 m-0'>
        <div className='form-row'>
          <div className='form-group col d-flex align-items-end mb-0'>
            <div className='btn-group'>
              {isContributor && <button data_id='add-variant' type='button' className='btn btn-outline-primary' onClick={ addVariant }>
                <i className='fas fa-plus fa-fw' /> Add Variant
              </button>}
              <button data_id='refresh-variants' type='button' className='btn btn-outline-primary text-nowrap d-flex align-items-center'
                      onClick={ loadVariants } disabled={ isLoading }>
                { isLoading
                  ? <LoadingIcon width='1.25em' height='1.25em' stroke='#fff' />
                  : <i className='fas fa-sync-alt fa-fw' /> }
                <span className='px-2 d-none d-md-inline-block'>Refresh</span>
              </button>
              {isContributor && <><Tooltip placement='bottom' title='Will rematerialise any dependant materialised or variant timeseries'>
                <button data_id='rematerialise' type='button'
                        className='btn btn-outline-info'  
                        onClick={ requestRematerialise }>
                Rematerialise
                </button></Tooltip>
                <div className="btn-group btn-group-sm">
                  <button type="button" className="btn btn-outline-info dropdown-toggle" id='mat-drop-down' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  </button>
                  <div className="dropdown-menu" aria-labelledby='mat-drop-down'>
                    <button data_id='rematerialise-supply-date-range' type='button' className='btn  btn-outline-info' onClick={() => setMaterialiseRangeDialogVisible(true)}>Rematerialise - supply range</button>
                  </div>
              </div>
              </>}
            </div>
          </div>
        </div>
      </div>
      <MaterialiseRangeDialog dialogVisible={materialiseRangeDialogVisible}
                              fromUtc={materialiseRangeFromUtc} 
                              toUtc={materialiseRangeToUtc}
                              suppressArchive={suppressArchive}
                              setSuppressArchive={setSuppressArchive}
                              materialiseWithRangeFunction={requestRematerialiseWithRange}
                              setDateFunction={setMaterialiseRangeDate} 
                              setDialogVisible={setMaterialiseRangeDialogVisible} />
    </>
}