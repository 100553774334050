export function createKey({ key, name, identityId, type, sourceTimeZoneId, granularity }) {
  return {
    key,
    name,
    identityId,
    type,
    sourceTimeZoneId,
    granularity
  }
}

export function addKeyToCollection({ key, collection }) {
  if (!collection.some(i => i.type === key.type && i.key === key.key)) {
    const requestKeys = collection.filter(i => i.type === 'Request');
    const identityKeys = collection.filter(i => i.type === 'Identity');
    if (key.type === 'Request')
      requestKeys.push(key);
    else
      identityKeys.push(key);

      return [...requestKeys,...identityKeys];
  }

  return collection;
}

export function moveItemUpInCollection({ item, collection }) {
  const itemToMove = collection.find(i => `${i.key}` === `${item.key}` && `${i.type}` === `${item.type}`);
  if (!itemToMove)
    return collection;

  const index = collection.indexOf(itemToMove);
  if (index < 1)
    return collection;

  let newCollection = [...collection];
  newCollection.splice(index, 1);
  newCollection.splice(index - 1, 0, itemToMove);

  newCollection = [...newCollection.filter(i => i.type === 'Request'), ...newCollection.filter(i => i.type === 'Identity')];
  return newCollection;
}

export function moveItemUpDownCollection({ item, collection }) {
  const itemToMove = collection.find(i => `${i.key}` === `${item.key}` && `${i.type}` === `${item.type}`);
  if (!itemToMove)
    return collection;

  const index = collection.indexOf(itemToMove);
  if (index > collection.length - 1)
    return collection;

  let newCollection = [...collection];
  newCollection.splice(index, 1);
  newCollection.splice(index + 1, 0, itemToMove);

  newCollection = [...newCollection.filter(i => i.type === 'Request'), ...newCollection.filter(i => i.type === 'Identity')];
  return newCollection;
}

export function deleteItemFromCollection({item, collection}) {
  const itemToDelete = collection.find(i => `${i.key}` === `${item.key}` && `${i.type}` === `${item.type}`);
  if (!itemToDelete)
    return collection;

  const index = collection.indexOf(itemToDelete);

  let newCollection = [...collection];
  newCollection.splice(index, 1);
  return newCollection;
}