import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import { shallowEqual } from 'react-redux';
import { createReducer } from '../utility/redux-utility';
import {
  OUTAGES_LOAD_SOURCE_LIST_STARTED,
  OUTAGES_LOAD_SOURCE_LIST_COMPLETE,
  OUTAGES_LOAD_BIDDING_ZONE_LIST_STARTED,
  OUTAGES_LOAD_BIDDING_ZONE_LIST_COMPLETE,
  OUTAGES_LOAD_PRODUCTION_TYPE_LIST_STARTED,
  OUTAGES_LOAD_PRODUCTION_TYPE_LIST_COMPLETE,
  OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_STARTED,
  OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_COMPLETE,
  OUTAGES_REFRESH_STARTED,
  OUTAGES_REFRESH_COMPLETE,
  OUTAGES_REFRESH_STOPPED,
  OUTAGES_SELECT_OUTAGE,
  OUTAGES_INITIALISE_TOOLBAR,
  OUTAGES_UPDATE_TOOLBAR,
  OUTAGES_UPDATE_TOOLBAR_FILTER,
  OUTAGES_UPDATE_TOOLBAR_PAGE,
  OUTAGES_UPDATE_CRITERIA,
  OUTAGES_UPDATE_CRITERIA_FILTER,
  OUTAGES_UPDATE_CRITERIA_PAGE,
  OUTAGES_SEARCH_RESET_PAGE,
} from '../actions/outages';

function getFilter(kvp) {
  const [name, value] = kvp.split('|');
  return { name, value };
}

export const outages = createReducer(null, {
  [OUTAGES_LOAD_SOURCE_LIST_STARTED](state, action) {
    return state.setIn(['toolbar', 'sourceList', 'isLoading'], true)
                .setIn(['toolbar', 'sourceList', 'data'], fromJS([]));
  },
  [OUTAGES_LOAD_SOURCE_LIST_COMPLETE](state, action) {
    let data = action.data ?? [];

    return state.setIn(['toolbar', 'sourceList', 'isLoading'], false)
                .setIn(['toolbar', 'sourceList', 'data'], fromJS(data));
  },
  [OUTAGES_LOAD_BIDDING_ZONE_LIST_STARTED](state, action) {
    return state.setIn(['toolbar', 'biddingZoneList', 'isLoading'], true)
                .setIn(['toolbar', 'biddingZoneList', 'data'], fromJS([]));
  },
  [OUTAGES_LOAD_BIDDING_ZONE_LIST_COMPLETE](state, action) {
    let data = action.data ?? [];

    return state.setIn(['toolbar', 'biddingZoneList', 'isLoading'], false)
                .setIn(['toolbar', 'biddingZoneList', 'data'], fromJS(data));
  },
  [OUTAGES_LOAD_PRODUCTION_TYPE_LIST_STARTED](state, action) {
    return state.setIn(['toolbar', 'productionTypeList', 'isLoading'], true)
                .setIn(['toolbar', 'productionTypeList', 'data'], fromJS([]));
  },
  [OUTAGES_LOAD_PRODUCTION_TYPE_LIST_COMPLETE](state, action) {
    let data = action.data ?? [];

    return state.setIn(['toolbar', 'productionTypeList', 'isLoading'], false)
                .setIn(['toolbar', 'productionTypeList', 'data'], fromJS(data));
  },
  [OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_STARTED](state, action) {
    return state.setIn(['toolbar', 'generationDataLookupList', 'isLoading'], true)
                .setIn(['toolbar', 'generationDataLookupList', 'data'], fromJS([]));
  },
  [OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_COMPLETE](state, action) {
    let data = action.data ?? [];

    return state.setIn(['toolbar', 'generationDataLookupList', 'isLoading'], false)
                .setIn(['toolbar', 'generationDataLookupList', 'data'], fromJS(data));
  },
  [OUTAGES_REFRESH_STARTED](state, action) {
    return state.setIn(['isLoading'], true)
                .setIn(['data'], fromJS([]))
                .setIn(['resultCount'], 0)
                .deleteIn(['selectedOutage']);
  },
  [OUTAGES_REFRESH_COMPLETE](state, action) {
    let data = action.data ?? {};
    let result = data.result ?? [];
    let resultCount = data.resultCount ?? 0;

    result.forEach(i => {
      i.generationData = i.fuelType && i.eic ? `${i.fuelType}-${i.eic}` : '';
    });

    const requiresRefresh = action.updateRefreshRequired === false
      ? state.getIn(['requiresRefresh'])
      : false;

    return state.setIn(['isInitialised'], true)
                .setIn(['isLoading'], false)
                .setIn(['data'], fromJS(result))
                .setIn(['resultCount'], resultCount)
                .setIn(['requiresRefresh'], requiresRefresh);
  },
  [OUTAGES_REFRESH_STOPPED](state, action) {
    return state.setIn(['isLoading'], false);
  },
  [OUTAGES_SELECT_OUTAGE](state, action) {
    let item = action.item;

    return state.setIn(['selectedOutage'], fromJS(item));
  },
  [OUTAGES_INITIALISE_TOOLBAR](state, action) {
    const criteria = action.data ?? {};

    let requiresRefresh = false;
    let newState = state;
    ['source', 'biddingZone', 'productionType', 'unitEic', 'eventStart', 'eventEnd',
    'fotaStatus', 'messageVersions', 'messageStatus', 'generationData'].forEach(i => {
      if (!criteria.hasOwnProperty(i)) return;
      newState = newState.setIn(['toolbar', i], criteria[i]);
      requiresRefresh = true;
    });
    ['page', 'pageSize'].forEach(i => {
      if (!criteria.hasOwnProperty(i)) return;
      newState = newState.setIn(['toolbar', i], Number(criteria[i]));
      requiresRefresh = true;
    });

    if (criteria.hasOwnProperty('filters')) {
      const filters = (Array.isArray(criteria.filters) ? criteria.filters : [criteria.filters]).map(getFilter);
      newState = newState.setIn(['toolbar', 'filters'], fromJS(filters));
      requiresRefresh = true;
    }

    return newState.setIn(['requiresRefresh'], requiresRefresh);
  },
  [OUTAGES_UPDATE_TOOLBAR](state, action) {
    let newState = state.setIn(['toolbar', action.key], action.value);

    const currentToolbar = {
      source: newState.getIn(['toolbar', 'source']),
      biddingZone: newState.getIn(['toolbar', 'biddingZone']),
      productionType: newState.getIn(['toolbar', 'productionType']),
      unitEic: newState.getIn(['toolbar', 'unitEic']),
      eventStart: newState.getIn(['toolbar', 'eventStart']),
      eventEnd: newState.getIn(['toolbar', 'eventEnd']),
      fotaStatus: newState.getIn(['toolbar', 'fotaStatus']),
      messageVersions: newState.getIn(['toolbar', 'messageVersions']),
      messageStatus: newState.getIn(['toolbar', 'messageStatus']),
      generationData: newState.getIn(['toolbar', 'generationData'])
    };

    const currentCriteria = {
      source: newState.getIn(['criteria', 'source']),
      biddingZone: newState.getIn(['criteria', 'biddingZone']),
      productionType: newState.getIn(['criteria', 'productionType']),
      unitEic: newState.getIn(['criteria', 'unitEic']),
      eventStart: newState.getIn(['criteria', 'eventStart']),
      eventEnd: newState.getIn(['criteria', 'eventEnd']),
      fotaStatus: newState.getIn(['criteria', 'fotaStatus']),
      messageVersions: newState.getIn(['criteria', 'messageVersions']),
      messageStatus: newState.getIn(['criteria', 'messageStatus']),
      generationData: newState.getIn(['criteria', 'generationData'])
    };

    const requiresRefresh = !shallowEqual(currentToolbar, currentCriteria);

    return newState.setIn(['requiresRefresh'], requiresRefresh);
  },
  [OUTAGES_UPDATE_TOOLBAR_FILTER](state, action) {
    let filters = toJS(state.getIn(['toolbar', 'filters']), []);
    const index = filters.findIndex(i => i.name === action.key);

    if (index >= 0)
      filters[index] = { name: action.key, value: action.value };
    else
      filters = [...filters, { name: action.key, value: action.value }]

    return state.setIn(['toolbar', 'filters'], fromJS(filters));
  },
  [OUTAGES_UPDATE_TOOLBAR_PAGE](state, action) {
    let newState = state.setIn(['toolbar', 'page'], action.page);
    if (action.pageSize)
      newState = newState.setIn(['toolbar', 'pageSize'], action.pageSize);

    return newState;
  },
  [OUTAGES_UPDATE_CRITERIA](state, action) {
    const source = state.getIn(['toolbar', 'source']);
    const biddingZone = state.getIn(['toolbar', 'biddingZone']);
    const productionType = state.getIn(['toolbar', 'productionType']);
    const unitEic = state.getIn(['toolbar', 'unitEic']);
    const eventStart = state.getIn(['toolbar', 'eventStart']);
    const eventEnd = state.getIn(['toolbar', 'eventEnd']);
    const fotaStatus = state.getIn(['toolbar', 'fotaStatus']);
    const messageVersions = state.getIn(['toolbar', 'messageVersions']);
    const messageStatus = state.getIn(['toolbar', 'messageStatus']);
    const generationData = state.getIn(['toolbar', 'generationData']);
    const page = state.getIn(['toolbar', 'page']);
    const pageSize = state.getIn(['toolbar', 'pageSize']);

    let criteria = {
      source,
      biddingZone,
      productionType,
      unitEic,
      eventStart,
      eventEnd,
      fotaStatus,
      messageVersions,
      messageStatus,
      generationData,
      skip: page > 1 && pageSize > 1 ? (page * pageSize) - pageSize : 0,
      take: pageSize ?? undefined
    };

    toJS(state.getIn(['toolbar', 'filters']), []).forEach(({ name, value }) => {
      criteria[name] = value;
    });

    return state.setIn(['criteria'], fromJS(criteria));
  },
  [OUTAGES_UPDATE_CRITERIA_FILTER](state, action) {
    let newState = state;

    toJS(state.getIn(['toolbar', 'filters']), []).forEach(({ name, value }) => {
      newState = newState.setIn(['criteria', name], value);
    });

    return newState;
  },
  [OUTAGES_UPDATE_CRITERIA_PAGE](state, action) {
    const page = state.getIn(['toolbar', 'page']);
    const pageSize = state.getIn(['toolbar', 'pageSize']);

    const criteria = {
      skip: page > 1 && pageSize > 1 ? (page * pageSize) - pageSize : 0,
      take: pageSize ?? undefined
    };

    return state.setIn(['criteria', 'skip'], criteria.skip)
                .setIn(['criteria', 'take'], criteria.take);
  },
  [OUTAGES_SEARCH_RESET_PAGE](state, action) {
    const requiresRefresh = state.getIn(['requiresRefresh']);
    return requiresRefresh
      ? state.setIn(['toolbar', 'page'], 1)
      : state;
  }
});