import React from 'react';
import ConfirmButton from '../../shared/ConfirmButton';

const groupStyle = { marginBottom: '.5em' };

export default function OutagesActionsToolbar({ selectedOutage, resetOutages }) {
  const { fotaStatus } = selectedOutage ?? {};
  const isIgnored = (fotaStatus ?? '').toLocaleLowerCase() === 'ignored';
  return (
    <form noValidate>
      <div className='form-row'>
        <div className='form-group ml-1 d-flex align-items-end' style={groupStyle}>
          <div className='btn-group btn-group-sm'>
            {isIgnored ? (
              <ConfirmButton type='button'
                className='btn text-nowrap btn-primary'
                confirmClassName='btn text-nowrap btn-warning'
                onClick={resetOutages}
                content={<span className='px-2 d-none d-md-inline-block'>Reset Outages</span>}
                confirm={<span className='px-2 d-none d-md-inline-block'>Confirm?</span>} />
            ) : undefined}
          </div>
        </div>
      </div>
    </form>
  );
}