import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Converter } from 'showdown';
import Loading from '../../shared/Loading';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableHeaderCell } from '../../shared/TableHeader';
import Timezone from '../../shared/Timezone';
import FormattedDateTime from '../../shared/FormattedDateTime';
import Pagination from '../../shared/Pagination';
import IdentityId from '../../shared/IdentityId2';
import EventsSearchToolbar from './EventsSearchToolbar';
import EventsSearchFacets from './EventsSearchFacets';
import EventsSearchMessage from './EventsSearchMessage';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import {
  eventsSearch,
  eventsClearFilters,
  eventsUpdateQuery,
  eventsUpdateFilter,
  eventsUpdateSort,
  eventsUpdatePage,
  eventsUpdateTimeRangeFilter,
  eventsSearchExport,
  eventsSearchToggleExpand,
  eventsSearchLoadMessage,
  eventsSourceFileDownload
} from '../../../actions/events';

const columns = [{
  key: 'id'
}, {
  key: 'eventName',
  title: 'Event Name',
  filterable: true
}, {
  key: 'eventType',
  title: 'Event Type',
  filterable: true
}, {
  key: 'source',
  title: 'Source',
  filterable: true
}, {
  key: 'identityId',
  title: 'Identity Id',
  filterable: true
}, {
  key: 'reason',
  title: 'Reason'
}, {
  key: 'originator',
  title: 'Originator',
  filterable: true
}, {
  key: 'raisedUtc',
  title: <span>Raised On <Timezone/></span>,
  sortable: true
}];

function EventsSearchRow({ toggleExpand, loadMessage, convertMessage, downloadSourceFile, navigate, eventData }) {
  const { id, eventName, eventType, source, identityId, reason, originator, raisedUtc, isExpanded = false, message } = eventData.toJS();

  return (
    <>
      <TableRow>
        <TableCell className='p-0'>
          <button type='button' className='btn btn-sm btn-link' onClick={ (e) => e.stopPropagation() || toggleExpand(id) }>
            <i className={ 'fas fa-fw ' + (isExpanded ? 'fa-chevron-down' : 'fa-chevron-right') } />
          </button>
        </TableCell>
        <TableCell className='text-nowrap' title={`Event id: ${id}`}>{ eventName }</TableCell>
        <TableCell className='text-nowrap'>{ eventType }</TableCell>
        <TableCell className='text-nowrap'>{ source }</TableCell>
        <TableCell className='text-nowrap'>
          <IdentityId isLeftDrop>{ identityId }</IdentityId>
        </TableCell>
        <TableCell className='text-nowrap'>{ reason }</TableCell>
        <TableCell className='text-nowrap'>{ originator }</TableCell>
        <TableCell className='text-nowrap'>{ <FormattedDateTime>{raisedUtc}</FormattedDateTime> }</TableCell>
        <TableCell className='w-100'></TableCell>
      </TableRow>
      <TableRow className={ isExpanded ? '' : 'd-none' }>
        <TableCell />
        <TableCell colSpan={ columns.length }> {/* length includes a right padding */}
          <div className='d-flex flew-row flex-md-nowrap'>
            <EventsSearchMessage id={ id } 
                                 message={ message }
                                 loadMessage={ loadMessage }
                                 convertMessage={ convertMessage }
                                 downloadSourceFile={ downloadSourceFile }
                                 navigate={ navigate }
                                 isVisible={ isExpanded } />
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

const EventsSearch = connect(
  (state) => ({
    locationSearch: state.getIn(['events', 'location', 'history', '/events']),
    isSearching: state.getIn(['events', 'search', 'isSearching']),
    facets: state.getIn(['events', 'search', 'results', 'facets']),
    data: state.getIn(['events', 'search', 'results', 'data']),
    count: state.getIn(['events', 'search', 'results', 'count']),
    query: state.getIn(['events', 'search', 'criteria', 'query']),
    customFilter: state.getIn(['events', 'search', 'criteria', 'customFilter']),
    filters: state.getIn(['events', 'search', 'criteria', 'filters']),
    orderBy: state.getIn(['events', 'search', 'criteria', 'orderBy']),
    orderByDirection: state.getIn(['events', 'search', 'criteria', 'orderByDirection']),
    page: state.getIn(['events', 'search', 'criteria', 'page']),
    pageSize: state.getIn(['events', 'search', 'criteria', 'pageSize']),
    timeRangeFilter: state.getIn(['events', 'search', 'criteria', 'timeRangeFilter'])
  }),
  (dispatch) => ({
    search() {
      dispatch(eventsSearch());
    },
    updateQuery(query, customFilter) {
      dispatch(eventsUpdateQuery(query, customFilter));
      dispatch(eventsUpdatePage(1));
    },
    updateFilter(key, value) {
      dispatch(eventsUpdateFilter(key, value));
      dispatch(eventsUpdatePage(1));
    },
    updateTimeRangeFilter(value) {
      dispatch(eventsUpdateTimeRangeFilter(value));
      dispatch(eventsUpdatePage(1));
    },
    clearTimeRangeFilter() {
      dispatch(eventsUpdateTimeRangeFilter(''));
      dispatch(eventsUpdatePage(1));
    },
    updateSort(key) {
      dispatch(eventsUpdateSort(key));
    },
    updatePage(page, pageSize) {
      dispatch(eventsUpdatePage(page, pageSize));
    },
    clearFilters() {
      dispatch(eventsClearFilters());
    },
    exportData() {
      dispatch(eventsSearchExport());
    },
    toggleExpand(key) {
      dispatch(eventsSearchToggleExpand(key));
    },
    loadMessage(key) {
      dispatch(eventsSearchLoadMessage(key));
    },
    downloadSourceFile(path) {
      dispatch(eventsSourceFileDownload(path));
    },
    navigate(path) {
      window.open(path, '_blank', 'menubar=yes');
    }
  })
)(({ locationSearch, isSearching, facets, data, count, query, customFilter, timeRangeFilter, filters, orderBy, orderByDirection, page = 1, pageSize = 25,
  search, updateQuery, updateFilter, updateSort, updatePage, updateTimeRangeFilter, clearTimeRangeFilter, clearFilters, exportData, toggleExpand, loadMessage, downloadSourceFile, navigate }) => {
  const [converter, setConverter] = useState(null);

  const convertMessage = useCallback(message => message ? converter.makeHtml(message) : 'No message found', [converter])

  useEffect(() => search(), [search]);

  useEffect(() => {
    const converter = new Converter({
      openLinksInNewWindow: true,
      simpleLineBreaks: true,
      tables: true
    });

    setConverter(converter);
  }, [])

  useDidUpdateEffect(() => search(), [locationSearch]);

  return <main className='d-flex flex-column'>
    <Loading isLoading={ isSearching } message='Searching...'>
      <div className='d-flex flex-column h-100'>
        <div>
          <div className='container-fluid p-0'>
            <EventsSearchToolbar query={ query } customFilter={ customFilter } updateQuery={ updateQuery }
                                refreshData={ search } exportData={ exportData } clearFilters={ clearFilters }
                                displayCount={ data.size } totalCount={ count } filterCount={ filters.size }
                                timeRangeFilter={ timeRangeFilter }
                                updateTimeRangeFilter={ updateTimeRangeFilter }
                                clearTimeRangeFilter={ clearTimeRangeFilter } />
          </div>
        </div>
        <div className='d-flex flex-fill h-100' >
          <div style={{ minWidth:'265px', width:'265px', overflowX:'hidden', overflowY:'auto', maxHeight: 'calc(100vh - 130px)'}}>
            <EventsSearchFacets data={ facets } filters={ filters } updateFilter={ updateFilter } />
          </div>
          <div className='flex-fill'>
            <div className='d-flex flex-column'>
              <div className='flex-fill' style={{ overflowY:'auto', height: 'calc(100vh - 180px)' }}>
                <Table stickyHeader className='table table-sm'>
                  <TableHead>
                    <TableRow>
                        {columns.map(c => <TableHeaderCell key={c.key} columnData={c} filters={ filters } applyFilter={ updateFilter } orderBy={orderBy} orderByDirection={ orderByDirection } applyOrderBy={ updateSort } />)} 
                        <TableCell className='w-100'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>  
                    {data.map(eventData => <EventsSearchRow key={`ei-${eventData.get('id')}`} eventData={eventData} toggleExpand={ toggleExpand } loadMessage={ loadMessage } convertMessage={ convertMessage } downloadSourceFile={ downloadSourceFile } navigate={ navigate } />)}
                  </TableBody>
                </Table>              
              </div>
              <div className='pt-2 pb-2'>
                <Pagination currentPage={ page } steps={ 5 } pageSize={ pageSize } itemCount={ count } onPageChange={ updatePage } />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Loading>
  </main>
});

export default EventsSearch;