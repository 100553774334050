import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import WorkspaceSelector from '../dashboard/WorkspaceSelector';
import TileCommonSettings from './TileCommonSettings';
import WorkspaceEditConnected from './WorkspaceEditConnected';
import { getFullPath } from '../../../utility/workspace-utility';
import { dashboardTileAnalysisTableSharedFoldersRefresh } from '../../../actions/dashboard-tiles-analysis-table';
import { toJS } from '../../../utility/immutable-utility';
import { dashboardWorkspacesSaveForEdit } from '../../../actions/dashboard-tiles';

export default function AnalysisTableTileOptions() {
  return {
    Create: (args) => <Create {...args} />,
    View: (args) => View(args),
    Edit: (args) => Edit(args),
    Settings: (args) => Settings(args),
  };
}

const Create = connect(
  (state) => ({
  }),
  (dispatch) => ({
    createNewWorkspace(workspace) {
      dispatch(dashboardWorkspacesSaveForEdit(workspace));
    },
  })
)(({ propertyBag, setProperty, executeCreate, cancelCreate, createNewWorkspace, dashboardTileType }) => {
  const _isShared = !propertyBag.workspaceScope || propertyBag.workspaceScope === "Shared";

  function onCreateNewTile(e) {
    e.preventDefault();

    if (_isShared && !propertyBag.workspaceFolder)
      return;

    createNewWorkspace({
      type: 'AnalysisTable',
      scope: _isShared ? 'Shared' : 'Private',
      name: propertyBag.workspaceName,
      folderPath: propertyBag.workspaceFolder ?? "",
      subFolderPath: propertyBag.workspaceSubFolder ?? "",
      data: propertyBag.workspaceConfig ?? "",
      version: 2
    });

    propertyBag.workspacePath = [
      _isShared ? 'Shared' : 'Private', 
      propertyBag.workspaceFolder, 
      propertyBag.workspaceSubFolder, 
      propertyBag.workspaceName].filter(x => x).join('/');
      
    executeCreate();
  }

  function onCancelCreateNewTile() {
    cancelCreate();
  }

  const _workspaceFullPath = [propertyBag.workspaceFolder, propertyBag.workspaceSubFolder, propertyBag.workspaceName].filter(x => x).join('/');

  return <div className='modal-content' style={{ minWidth: '50em' }}>
    <div className='modal-header'>
      <h5 className='modal-title'>New tile</h5>
    </div>
    <div className='modal-body'>
      <div className='form-group'>
        <TileCommonSettings propertyBag={propertyBag} setProperty={setProperty} dashboardTileType={dashboardTileType}/>
      </div>

      <div className='form-group'>
        <label className='font-weight-bold'>Scope</label>
        <div className='mb-2'>
          <div className='form-check d-inline-block'>
            <input className='form-check-input' type='radio' value='none' name='workspaceScope-Private' id='workspaceScope-Private'
              checked={!_isShared}
              onChange={() => setProperty({ workspaceScope: 'Private' })} />
            <label className='form-check-label' htmlFor='workspaceScope-Private'>Private</label>
          </div>

          <div className='form-check d-inline-block ml-3'>
            <input className='form-check-input' type='radio' value='none' name='workspaceScope-Shared' id='workspaceScope-Shared'
              checked={_isShared}
              onChange={() => setProperty({ workspaceScope: 'Shared' })} />
            <label className='form-check-label' htmlFor='workspaceScope-Shared'>Shared</label>
          </div>
        </div>

        <div className='input-group'>
          <div className='input-group-prepend'>
            <label className='input-group-text'>Name</label>
          </div>
          <input type='text' className='form-control' value={propertyBag.workspaceName} maxLength='80'
            onChange={e => setProperty({ workspaceName: e.target.value })} />
        </div>
        <div className='input-group input-group-sm mt-2'>
          <div className='input-group-prepend'>
            <label className='input-group-text'>Folder Path</label>
          </div>
          {!_isShared ? (
            <input type='text' className='form-control' value={propertyBag.workspaceFolder} maxLength='250'
              onChange={e => setProperty({ workspaceFolder: e.target.value })} />
          ) : (
            <SharedFolderConnected onChange={e => setProperty({ workspaceFolder: e })} value={propertyBag.workspaceFolder} />            
          )}
        </div>
        <div className='input-group input-group-sm mt-2'>
          <div className='input-group-prepend'>
            <label className='input-group-text'>Sub-folder Path</label>
          </div>
          <input type='text' className='form-control' value={propertyBag.workspaceSubFolder} maxLength='250'
            onChange={e => setProperty({ workspaceSubFolder: e.target.value })} />
        </div>

        <div className='text-muted text-italic mt-2'>
          <strong>Full Path:</strong> {_workspaceFullPath}
        </div>
      </div>

      <div className='form-group'>
        <div className='form-row'>
          <label className='font-weight-bold'>Workspace config</label>
          <textarea rows="20" cols="50" className='form-control form-control-sm' value={propertyBag.workspaceConfig} onChange={e => setProperty({ workspaceConfig: e.target.value })} >
          </textarea>
        </div>
      </div>
      <div className='modal-footer'>
        <button className='btn btn-sml btn-secondary' onClick={() => onCancelCreateNewTile()}>Cancel</button>
        <button className='btn btn-sml btn-primary' onClick={(e) => onCreateNewTile(e)}>Create</button>
      </div>
    </div>
  </div>  
});

function View({ propertyBag, setProperty, workspace }) {
  return <>
  </>
}

function Edit({ propertyBag, setProperty, workspace }) {
  return <>
  </>
}

function Settings({ propertyBag, setProperty, settingsCallbacks, dashboardTileType }) {
  const [activeTab, setActiveTab] = useState('settings');

  function onSelectWorkspace(workspace) {
    setProperty({
      workspacePath: getFullPath({
        workspace: workspace,
        includeType: false
      })
    });
  }

  function onSetTab(tab) {
    setActiveTab(tab);
  }

  return <>
    <ul className='nav nav-tabs mb-2'>
      <li className='nav-item'>
        <button className={`nav-link ${activeTab === 'settings' ? 'active' : ''}`} type='button' onClick={() => onSetTab('settings')}>Settings</button>
      </li>
      <li className='nav-item'>
        <button className={`nav-link ${activeTab === 'workspace' ? 'active' : ''}`} type='button' onClick={() => onSetTab('workspace')}>Workspace</button>
      </li>
    </ul>

    {activeTab === 'settings' && (
      <>
        <div className='form-group'>
          <TileCommonSettings propertyBag={propertyBag} setProperty={setProperty} dashboardTileType={dashboardTileType}/>
        </div>

        <div className='form-group'>
          <label className='font-weight-bold'>Workspace</label>
          <input type='text' readOnly className='form-control' value={propertyBag.workspacePath} onChange={e => setProperty({ workspacePath: e.target.value })} />
          <WorkspaceSelector style={{ marginTop: '1px', border: 'solid 1px #DDDDDD' }} showOnlyItems={['Folder', 'AnalysisTable']} onChange={w => onSelectWorkspace(w)} />
        </div>
      </>
    )}

    {activeTab === 'workspace' && (
      <div style={{ height: '653px' }}>
        <WorkspaceEditConnected workspaceType='AnalysisTable' workspacePath={propertyBag.workspacePath} settingsCallbacks={settingsCallbacks}/>
      </div>
    )}

  </>
}

const SharedFolderConnected = connect(
  (state) => ({
    sharedFolders: state.getIn(['dashboardTiles', 'workspaceSharedfolders'])
  }),
  (dispatch) => ({
    initialise() {
      dispatch(dashboardTileAnalysisTableSharedFoldersRefresh());
    },
  })
)(({ initialise, sharedFolders, onChange, value }) => {
  useEffect(() => {
    initialise();
  }, [initialise]);

  const _sharedFolders = toJS(sharedFolders, []);

  return <select id='homepage-modal-content-image' className='custom-select'
    onChange={e => onChange(e.target.value)} value={value}>
    <option />
    {_sharedFolders.map(({ folderPath }) => <option key={folderPath} value={folderPath}>{folderPath}</option>)}
  </select>
});
