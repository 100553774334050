import React, { useRef, useCallback } from 'react';
import DatePicker from 'react-widgets/DatePicker';
import DataPointsAddRangeModal from './DataPointsAddRangeModal';
import DataPointsSaveModal from './DataPointsSaveModal';
import { reactWidgetDateTimeFormat } from '../../../shared/FormattedDateTime';
import Modal from '../../../shared/Modal';

export default function AdjustmentsToolbar({ filterFromUtc, filterToUtc, filterTimezone, 
  loadAdjustments, setAdjustmentsFilter, applyAdjustmentsRange, timezones,
  saveAdjustments, dataPointsIsDirty, isWizard }) {

  const modalRef = useRef();
  function onAddRange(e) {
    e.preventDefault();

    modalRef.current.onShow({
      title: 'Add range of data points',
      content: (<DataPointsAddRangeModal/>),
      isLarge: true,
      onApply() {
        applyAdjustmentsRange();
      }
    });
  }

  const onFromFilterChange = useCallback((date) => {
    // if there is no initial date and the new supplied date is not the half hour then use 00:00
    if (!filterFromUtc && (date.getMinutes() % 30 !== 0)) date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    setAdjustmentsFilter(['filterFromUtc'], date);
  }, [filterFromUtc, setAdjustmentsFilter]);

  const onToFilterChange = useCallback((date) => {
    // if there is no initial date and the new supplied date is not the half hour then use 00:00
    if (!filterToUtc && (date.getMinutes() % 30 !== 0)) date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    setAdjustmentsFilter(['filterToUtc'], date);
  }, [filterToUtc, setAdjustmentsFilter]);

  function onRefresh(e) {
    e.preventDefault();
    loadAdjustments();
  }

  function onSave(e) {
    e.preventDefault();
    if (dataPointsIsDirty) {
      modalRef.current.onShow({
        title: 'Save data points',
        content: (<DataPointsSaveModal/>),
        onApply() {
          saveAdjustments();
        }
      });
    } else {
      saveAdjustments();
    }
  }

  return <><form className='form w-100'>
    <div className='form-row'>
      <div className='form-group col-3 mb-0' style={{ maxWidth: '230px' }}>      
        <DatePicker {...reactWidgetDateTimeFormat}
          onChange={date => onFromFilterChange(date)}
          value={filterFromUtc}
           />
      </div>
      <div className='form-group col-3 mb-0' style={{ maxWidth: '230px' }}>
        <DatePicker {...reactWidgetDateTimeFormat}
          onChange={date => onToFilterChange(date)}
          value={filterToUtc} />
      </div>
      <div className='form-group col-3 mb-0' style={{ maxWidth: '230px' }}>
        <select className='form-control'
          value={filterTimezone}
          onChange={e => setAdjustmentsFilter(['filterTimezone'], e.target.value)}>
          {timezones.map(timezone => <option key={timezone} value={timezone}>{timezone}</option>)}
        </select>
      </div>
      <div className='form-group col mb-0'>
        <div className='btn-toolbar'>
          {isWizard && (
            <div className='btn-group mr-2'>
              <button type='button' className='btn btn-primary' onClick={onAddRange}>Add Range</button>
            </div>
          )}
          <div className='btn-group mr-2'>
            <button type='button' className='btn btn-primary' onClick={onRefresh}>Refresh</button>
          </div>
          {isWizard && (
            <div className='btn-group'>
              <button type='button' className='btn btn-primary' onClick={onSave}>Save</button>
            </div>
          )}
        </div>
      </div>
    </div>
  </form>
  <Modal ref={modalRef} />
  </>
}