import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function PathNavLink(props) {
  if (props.exact !== undefined) props = {...props, exact: `${props.exact}`};

  const { exact, to, search = '' } = props;
  const location = useLocation();

  const isActive = exact === 'true' ? to === location.pathname : location.pathname.startsWith(to);

  function onClick(e) {
    if (props.disabled)
      e.preventDefault();
  }

  const style = { ...props.style };
  if (props.disabled === true) {
    style.opacity = 0.6;
    style.cursor = 'default';
  }

  const link = [to, search].map(f => f ? f.replace(/^\?+|\?+$/gm,'') : '').filter(x => x).join('?');

  return <>
    <Link {...props}
      className={(props.className || '') + (isActive ? ' active' : '')}
      style={style}
      to={`${link}`}
      onClick={onClick}>
      {typeof props.children === 'function' ? props.children({ isActive }) : props.children}
    </Link>
  </>
}