import { createReducer } from '../utility/redux-utility';
import { isNotEmpty } from '../utility/text-utility';
import {
  TIME_SERIES_INITIALISE,
  TIME_SERIES_CLEAR
} from '../actions/time-series';

const timeSeriesReducer = {
  [TIME_SERIES_INITIALISE](state, action) {
    const { id, granularity, features } = action.data;

    const timeSeriesId = isNotEmpty(id) && !isNaN(id) ? Number(id) : null;
    const granularityType = (granularity ?? {}).granularityType ?? null;
    const rangeBasedAdjustments = (features ?? {}).rangeBasedAdjustments ?? false;

    return state.setIn(['id'], timeSeriesId)
      .setIn(['granularityType'], granularityType)
      .setIn(['features', 'rangeBasedAdjustments'], rangeBasedAdjustments);
  },
  [TIME_SERIES_CLEAR](state, action) {
    return state.setIn(['id'], null)
      .setIn(['granularityType'], null)
      .setIn(['features', 'rangeBasedAdjustments'], false);
  }
};

export const timeSeries = createReducer(null, {
  ...timeSeriesReducer
});