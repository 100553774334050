import React from 'react';
import InfoPopup from '../../../shared/InfoPopup';

export default function ToolboxToolbar({ lenses, operations, timezones, identityId, lens, operation, shapeName, fromDate, toDate, timezone, asAtDate, take, dpCount, 
                                         disableMaterialisation, disableVariants, testUnsavedChanges, updateProperty, toggleMaterialisation, toggleVariants, toggleTestUnsavedChanges, execute }) {
  return (
    <div>
        <div className='row ml-1'>
       
          <select className='form-control'
                  style={{ maxWidth:'85px' }}
                  value={lens}
                  onChange={e => updateProperty('lens', e.target.value)}>
              {lenses.map(x => <option key={x} value={x}>{x}</option>)}
          </select>

          <select className='form-control ml-1'  
                  style={{ maxWidth:'85px' }}
                  value={operation}
                  onChange={e => updateProperty('operation', e.target.value)}>
              {operations.map(x => <option key={x} value={x}>{x}</option>)}
          </select>

          <input className='form-control ml-1'  
                 style={{ maxWidth:'130px' }}
                 type={ 'date' }                  
                 value={ fromDate }
                 onChange={ e => updateProperty('fromDate', e.target.value) } />
          <input className='form-control ml-1' 
                 style={{ maxWidth:'130px' }}
                 type={ 'date' }                  
                 value={ toDate }
                 onChange={ e => updateProperty('toDate', e.target.value) } />
          <select className='form-control ml-1'  
                  style={{ maxWidth:'130px' }}
                  value={timezone}
                  onChange={e => updateProperty('timezone', e.target.value)}>
              {timezones.map(x => <option key={x} value={x}>{x}</option>)}
          </select>
          <input className='form-control ml-1' 
                 style={{ maxWidth:'165px' }}
                 type={ 'datetime-local' }                  
                 value={ asAtDate }
                 onChange={ e => updateProperty('asAtDate', e.target.value) } >
          </input>                 
          <InfoPopup>A master build style of Instance Only will always be used</InfoPopup>

          <button type='button' className='btn btn-primary ml-2' onClick={() => execute(identityId)}>Execute</button>     
        </div>

        <div className='row mt-1 ml-1'>
          <input className='form-control' 
                 style={{ maxWidth:'100px' }}
                 placeholder='shape'
                 type={ 'text' }                  
                 value={ shapeName }
                 onChange={ e => updateProperty('shapeName', e.target.value) } >
          </input>                 

          <select className='form-control' 
                  style={{ maxWidth:'85px' }}
                  value={take}
                  onChange={e => updateProperty('take', e.target.value)}>
              <option value={'First'}>First</option>
              <option value={'Last'}>Last</option>
          </select>                 

          <input className='form-control ml-1' 
                 style={{ maxWidth:'85px' }}
                 type={ 'number' }                  
                 value={dpCount}
                 onChange={ e => updateProperty('dpCount', e.target.value) } />                 

          <div className="form-check form-switch ml-2 mt-2">
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckMaterialisationMode" checked={disableMaterialisation} onChange={toggleMaterialisation} />
              <label className="form-check-label" htmlFor="flexSwitchCheckMaterialisationMode">Disable materialisation</label>
          </div>                 

          <div className="form-check form-switch ml-2 mt-2">
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckVariantMode" checked={disableVariants} onChange={toggleVariants} />
              <label className="form-check-label" htmlFor="flexSwitchCheckVariantMode">Disable variants</label>
          </div>

          <div className="form-check form-switch ml-2 mt-2">
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchTestUnsaved" checked={testUnsavedChanges} onChange={toggleTestUnsavedChanges} />
              <label className="form-check-label" htmlFor="flexSwitchTestUnsaved">Test unsaved changes</label>
          </div>

        </div>
    </div>
  );
}