import React, { useMemo, useCallback, createRef } from 'react';
import PropTypes from 'prop-types';
import { getSafeClassName } from '../../../utility/dom-utility';
import { mapCSVToArray } from '../../../utility/text-utility';
import ReportsTableHorizontal from './ReportsTableHorizontal';
import { formatNumber, formatNumeral } from '../../shared/FormattedNumber';
import IdentityId from '../../shared/IdentityId2';
import { TimeSeriesRowLabel } from './TimeSeriesRowLabel';
import { ReportsTableVertical } from './ReportsTableVertical';
import { isOnDemand, getAnnotationDetails, linkHorizontalViewCells, mapHorizontalCellStyles } from '../../../utility/reportstable-utility';
import { AdjustmentsPanel } from './AdjustmentsPanel';
import { AdjustmentsSidebar } from './AdjustmentsSidebar';
import { is } from 'immutable';
import Tooltip from '../../shared/Tooltip';
//import { DebugReports } from './DebugReports';

const caretDownSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 448"><path transform="matrix(1 0 0 -1 0 448)" fill="%23007bff" d="M31.2998 256h257.3c17.8008 0 26.7002 -21.5 14.1006 -34.0996l-128.601 -128.7c-7.7998 -7.7998 -20.5 -7.7998 -28.2998 0l-128.6 128.7c-12.6006 12.5996 -3.7002 34.0996 14.0996 34.0996z" /></svg>';

class AnnotationHighlighter extends React.Component {
  shouldComponentUpdate(nextProps, nextState){
    return (nextProps || nextProps.className !== this.props.className);
  }

  render(){
    const className = this.props.className;
    return <style>
    {`.${className} > div:first-of-type { background-color: rgba(255, 255, 51, .75) !important; color: #333 !important; }`}
  </style>
  }
}

function getAnnotationClassName(value) {
  let annotation = value.replace(/\s[-0-9,.]+/, ''); // Remove the numeric value from the annotation

  return getSafeClassName(annotation);
}

export function AnnotationRow({ toggleExpand, displayMap, rawData, section, scenarioMode, getColumnStyleNames, getStyle, isFirstChild = false, hasSiblings = false, setSelectedAnnotation, index: rowIndex }) {
  const { values = [], details = [], cellStyles } = rawData;
  const { title: sectionTitle, styles = [] } = section;

  const _sectionKey = useMemo(() => `${sectionTitle}`.toLocaleLowerCase().trim(), [sectionTitle]);
  const _displayMode = useMemo(() => displayMap[_sectionKey] || '', [displayMap, _sectionKey]);

  const hasValues = !!(values && values.length > 1);
  const displayValues = isFirstChild || (hasValues && (_displayMode === 'Open' || _displayMode === 'AlwaysOpen'));
  const canToggleExpandCollapse = isFirstChild && hasSiblings && (_displayMode === 'Closed' || _displayMode === 'Open');

  const getCellStyleNames = useCallback(ix => cellStyles ? Object.entries(cellStyles).filter(([, value]) => value.includes(ix)).map(([key]) => key) : [], [cellStyles]);

  const _rowStyle = useMemo(() => styles.reduce((accumulator, item) => ({ ...accumulator, ...getStyle(item) }), {}), [styles, getStyle]);

  let iconClassName;
  switch (_displayMode) {
    case 'No':
    case 'Closed': iconClassName = 'icon icon-chevron-right'; break;
    case 'AlwaysOpen':
    case 'Open': iconClassName = 'icon icon-chevron-down'; break;
    default: iconClassName = ''; break;
  }

  const onToggle = useCallback(e => {
    e.stopPropagation();

    toggleExpand(_sectionKey);
  }, [_sectionKey, toggleExpand]);

  let sectionStyle = _displayMode === 'Closed' ? { background: 'lightgray' } : {}

  return displayValues && (
    <tr style={_rowStyle}>
      {scenarioMode === 'Enabled' && <td />}
      <td className='sticky-cell py-0 pl-0 text-nowrap' style={sectionStyle}>
        <button type='button' className='btn btn-sm btn-link p-0 mr-2' style={{ lineHeight: 0, visibility: canToggleExpandCollapse ? 'visible' : 'hidden' }}
          onClick={onToggle} disabled={!canToggleExpandCollapse}>
          <i className={`fa-fw ${iconClassName}`} style={{
            fontSize: '.75em',
            fontWeight: 'bolder',
            visibility: canToggleExpandCollapse ? 'visible' : 'hidden'
          }} />
        </button>
        {isFirstChild && sectionTitle}
      </td>
      {values.map((annotation, ix) => {
        const styleNames = [
          ...getColumnStyleNames(ix + 1),
          ...getCellStyleNames(ix)
        ];

        const cellStyle = {
          verticalAlign: 'middle',
          ...styleNames.reduce((accumulator, item) => ({ ...accumulator, ...getStyle(item) }), {}),
          maxWidth: '50px',
          padding: '1px'
        };

        return <AnnotationCell key={`${_sectionKey}-${ix}`} {...{ annotation, ix, cellStyle, getCellStyleNames, getColumnStyleNames, getStyle, displayMode: _displayMode, sectionKey: _sectionKey, rowIndex, details, setSelectedAnnotation }} />
      })}      
    </tr>
  );
}

class AnnotationCell extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      annotationDetails:nextAnnotationDetails = [],
    } = nextState;
    let {
      annotation:nextAnnotation,
      displayMode:nextDisplayMode,
      cellStyle: nextCellStyle
    } = nextProps;

    const {
      annotationDetails:currentAnnotationDetails = [],
    } = this.state;
    let {
      annotation:currentAnnotation = {},
      displayMode:currentDisplayMode,
      cellStyle: currentCellStyle
    } = this.props;

    if (nextAnnotationDetails.join('|') !== currentAnnotationDetails.join('|')) {
      return true;
    }

    if (nextDisplayMode !== currentDisplayMode) {
      return true;
    }

    nextAnnotation = nextAnnotation ?? {};
    currentAnnotation = currentAnnotation ?? {};
    if (nextAnnotation.annotationId !== currentAnnotation.annotationId ||
      nextAnnotation.identityId !== currentAnnotation.identityId ||
      nextAnnotation.value !== currentAnnotation.value ) {
      return true;
    }

    if (JSON.stringify(nextCellStyle ?? {}) !== JSON.stringify(currentCellStyle ?? {})){
      return true;
    }
    
    return false;
  }

  mouseEnter() {
    const { annotation, ix, details } = this.props;
    const annotationClassName = annotation && annotation.value ? getAnnotationClassName(annotation.value) : '';
    this.props.setSelectedAnnotation(annotationClassName);
    this.setState({...this.state, annotationDetails: getAnnotationDetails(details, ix, annotation.value) });
  }

  mouseLeave() {
    this.props.setSelectedAnnotation('');
    this.setState({...this.state, annotationDetails: undefined});
  }

  render() {
    const { annotation, ix, cellStyle, displayMode, sectionKey } = this.props;
    if (displayMode === 'Closed' || !annotation)
      return <td key={`${sectionKey}-${ix}`} style={cellStyle} />;

    const annotationDetails = this.state.annotationDetails;
    const annotationClassName = annotation && annotation.value ? getAnnotationClassName(annotation.value) : '';

    return <IdentityId key={`ident-${ix}`} id={annotation.identityId} customMenuButton={(
      <Tooltip placement='bottom' title={(annotationDetails && annotationDetails.map((i, adx) => <div key={adx}>{i}</div>))}>
        <td style={cellStyle} className={annotationClassName} /*className used to find all matching annotation types for hoverover highlighting*/
            onMouseLeave={this.mouseLeave.bind(this)} onMouseEnter={this.mouseEnter.bind(this)}>
            <div style={{ width: '100%', maxHeight: '21px', overflow: 'hidden', fontSize: '.72em', cursor: 'default' }} >
              {annotation.value}
            </div>
        </td>
          </Tooltip>
      )}>
      </IdentityId>
  }
}

class ReportRow extends React.Component {
  static propTypes = {
    rawData: PropTypes.shape({
      id: PropTypes.number.isRequired,
      rowKey: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      timeSeries: PropTypes.object.isRequired,
      parentIds: PropTypes.array,
      parentId: PropTypes.number,
      overridenId: PropTypes.number.isRequired,
      displayChildrenMode: PropTypes.string.isRequired,
      isLastChild: PropTypes.bool.isRequired,
    }),
    isEditing: PropTypes.bool,
    setScenarioOverride: PropTypes.func,
    toggleOnDemand: PropTypes.func,
    toggleExpand: PropTypes.func,
    openChart: PropTypes.func,
    getColumnStyleNames: PropTypes.func,
    getStyle: PropTypes.func,
    getSpecialStyle: PropTypes.func,
    setAdjustmentValue: PropTypes.func.isRequired,
    setSelectionAdjustmentValue: PropTypes.func.isRequired,
    navigateCell: PropTypes.func.isRequired,
    removeSelectionAdjustments: PropTypes.func.isRequired,
    copySelection: PropTypes.func.isRequired,
    pasteToSelection: PropTypes.func.isRequired,
    undoSelectionAdjustments: PropTypes.func.isRequired
  }

  onOpenChart(e) {
    e.stopPropagation();

    const { openChart, rawData } = this.props;
    const { key, id, children, parentIds = [], displayChildrenMode } = rawData;
    const displayChildren = displayChildrenMode === 'Open';

    openChart(id, [...parentIds, key], (displayChildren && children) || []);
  };

  onToggleExpand(e) {
    e.stopPropagation();

    const { toggleOnDemand, toggleExpand, rawData } = this.props;
    const { overridenId, level, displayChildrenMode, baseKey } = rawData;

    if (isOnDemand(displayChildrenMode))
      toggleOnDemand(overridenId, level + 1, baseKey);
    else if (displayChildrenMode !== 'Loading')
      toggleExpand(overridenId, level + 1, baseKey);
    else e.preventDefault();
  };

  onSetScenarioOverride(e) {
    const { setScenarioOverride, rawData } = this.props;
    const { overridenId, parentId = -1 } = rawData;
    const nodeKey = `${parentId}|${overridenId}`;
    setScenarioOverride(nodeKey, e.target.value);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate =
      this.props.isEditing !== nextProps.isEditing ||
      this.props.rawData.id !== nextProps.rawData.id ||
      this.props.rawData.name !== nextProps.rawData.name ||
      this.props.rawData.rowKey !== nextProps.rawData.rowKey ||
      this.props.rawData.level !== nextProps.rawData.level ||
      this.props.rawData.parentId !== nextProps.rawData.parentId ||
      this.props.rawData.overridenId !== nextProps.rawData.overridenId ||
      this.props.rawData.displayChildrenMode !== nextProps.rawData.displayChildrenMode ||
      this.props.rawData.scenarioOverride !== nextProps.rawData.scenarioOverride ||
      !is(this.props.rawData.values, nextProps.rawData.values);
    return shouldUpdate;
  }

  render() {
    const { isEditing, scenarioMode, getColumnStyleNames, getStyle, getSpecialStyle, rawData, columns, index } = this.props;
    let { rowKey, id, name, values = [], styles = [], cellStyles, isPriceFormat = false,
      level, isLastChild = false, scenarios, scenarioOverride,
      rowStyle, scenarioHeader, rowHeaderstyle = {}, timeSeries } = rawData;

    const { commaSeparated, decimalPlaces, valueFormat } = styles.reduce((accumulator, item) => ({ ...accumulator, ...getSpecialStyle(item) }), {});
    const rowSpecialisedStyles = { commaSeparated, decimalPlaces, valueFormat };
    cellStyles = mapHorizontalCellStyles(cellStyles, rowSpecialisedStyles, getColumnStyleNames, getStyle, getSpecialStyle);

    if (rowStyle) {
      if (rowStyle.background)
        rowHeaderstyle.background = rowStyle.background;

      if (rowStyle.backgroundColor)
        rowHeaderstyle.backgroundColor = rowStyle.backgroundColor;
    }

    return <tr style={rowStyle} data_id={`row-${index}`}>
      {scenarioMode === 'Enabled' && (
        <td className='p-0 px-1' style={scenarioHeader}>
          <select data_id='scenario-selector' className='form-control form-control-sm p-0 border-0'
            style={{
              height: '15px',
              backgroundColor: 'inherit',
              backgroundImage: (scenarios && scenarios.length > 0) ? `url('data:image/svg+xml;charset=UTF-8,${caretDownSvg}')` : 'none',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right .2em top 50%, 0 0',
              backgroundSize: '.65em auto, 100%',
              appearance: 'none'
            }}
            value={scenarioOverride} disabled={!scenarios || scenarios.length === 0}
            onChange={this.onSetScenarioOverride.bind(this)}>
            <option value='' />
            {scenarios && scenarios.map((i, ix) => <option key={ix} value={i.scenarioName}>{i.scenarioName}</option>)}
          </select>
        </td>
      )}
      <td className='sticky-cell p-0'  data-label={`${id}: ${name}`}>
        <div style={rowHeaderstyle}>
          {level > 1 && (
            <div className='d-inline-block'>
              <i className='fas fa-fw text-dark'>{isLastChild ? '└' : '├'}</i>
            </div>
          )}
          <TimeSeriesRowLabel timeSeries={timeSeries} orientation="Horizontal" rowKey={rowKey}
            onOpenChart={this.onOpenChart.bind(this)}
            onToggleExpand={this.onToggleExpand.bind(this)} ></TimeSeriesRowLabel>
        </div>
      </td>
      {values.map((cellData, index) => <ReportCell 
          key={columns[index + 1].key}
          isTableEditing={isEditing}
          cellData={cellData}
          isPriceFormat={isPriceFormat}
          commaSeparated={commaSeparated}
          decimalPlaces={decimalPlaces}
          valueFormat={valueFormat}
          cellStyles={cellStyles}
          rowKey={rowKey}
          colKey={columns[index + 1].key}
          colIndex={index}
          onSetValue={this.props.setAdjustmentValue}
          onSetSelectionValue={this.props.setSelectionAdjustmentValue}
          onNavigateCell={this.props.navigateCell}
          onRemoveSelectionAdjustments={this.props.removeSelectionAdjustments}
          copySelection={this.props.copySelection}
          pasteToSelection={this.props.pasteToSelection}
          undoSelectionAdjustments={this.props.undoSelectionAdjustments}
        />)}
    </tr>
  }
}

class ReportCell extends React.Component {
  static propTypes = {
    isTableEditing: PropTypes.bool,
    value: PropTypes.number,
    isSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    hasCursor: PropTypes.bool,
    adjustment: PropTypes.any,
    adjustmentIsDirty: PropTypes.bool,
    hasExistingAdjustment: PropTypes.bool,
    hasExistingInlineAdjustment: PropTypes.bool,
    cellStyles: PropTypes.array.isRequired,
    rowKey: PropTypes.string.isRequired,
    colKey: PropTypes.string.isRequired,
    colIndex: PropTypes.number.isRequired,
    isPriceFormat: PropTypes.bool,
    commaSeparated: PropTypes.bool,
    decimalPlaces: PropTypes.number,
    valueFormat: PropTypes.string,
    onSetValue: PropTypes.func.isRequired,
    onSetSelectionValue: PropTypes.func.isRequired,
    onNavigateCell: PropTypes.func.isRequired,
    onRemoveSelectionAdjustments: PropTypes.func.isRequired,
    copySelection: PropTypes.func.isRequired,
    pasteToSelection: PropTypes.func.isRequired,
    undoSelectionAdjustments: PropTypes.func.isRequired
  }

  static shouldUpdate(propsA, propsB) {
    const {cellData:cellDataA} = propsA;
    const {cellData:cellDataB} = propsB;
    
    if (
      propsA.isTableEditing !== propsB.isTableEditing ||
      propsA.rowKey !== propsB.rowKey ||
      propsA.colKey !== propsB.colKey ||
      propsA.colIndex !== propsB.colIndex ||
      propsA.isPriceFormat !== propsB.isPriceFormat ||
      propsA.commaSeparated !== propsB.commaSeparated ||
      propsA.decimalPlaces !== propsB.decimalPlaces ||
      propsA.valueFormat !== propsB.valueFormat ||
      !is(cellDataA, cellDataB)){
        return true;
    }

    return false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return ReportCell.shouldUpdate(this.props, nextProps);
  }

  render() {
    let { cellData, isTableEditing, rowKey, colKey, colIndex, cellStyles, isPriceFormat, valueFormat } = this.props;
    let isSelected = cellData.get('isSelected') ?? false;
    let value = cellData.get('value');
    let isEditing = cellData.get('isEditing') ?? false;
    let hasCursor = cellData.get('hasCursor') ?? false;
    let hasExistingAdjustment = cellData.get('hasExistingAdjustment') ?? false;
    let hasExistingInlineAdjustment = cellData.get('hasExistingInlineAdjustment') ?? false;
    let adjustment = cellData.get('adjustment');
    let adjustmentIsDirty = cellData.get('adjustmentIsDirty') ??  false;
    let {cssStyles, specialisedStyles} = cellStyles[colIndex];

    if (isPriceFormat) {
      specialisedStyles.decimalPlaces = 2;
      specialisedStyles.commaSeparated = true;
    }

    let cssClass = isSelected ? ' is-selected ' : '';
    if (hasCursor) cssClass += ' has-cursor ';
    if (hasExistingAdjustment) cssClass += ' existing-adjustment ';
    if (hasExistingInlineAdjustment) cssClass += ' existing-inline-adjustment ';
    if (adjustmentIsDirty) cssClass += ' is-dirty ';
    if (isEditing) cssClass += ' is-editing ';

    if (!isTableEditing || !isEditing) {
      const adjustmentMessage = {};
      if (adjustment !== undefined && !adjustmentIsDirty && `${adjustment ? adjustment : ''}` !== `${value ? value : ''}`) {
        cssClass += ' adjustment-override-differs ';
        adjustmentMessage.title = `Override is ${adjustment ? adjustment : '-'}`;
      }

      let displayValue = undefined;
      if (adjustmentIsDirty) {
        displayValue = adjustment;
      } else {
        displayValue = value;
      }

      if (displayValue === undefined && displayValue === null) {
        displayValue = '';
      } else {
        displayValue = !valueFormat
          ? formatNumber({ value: displayValue, useGrouping: specialisedStyles.commaSeparated, minDecimals: specialisedStyles.decimalPlaces, maxDecimals: specialisedStyles.decimalPlaces })
          : formatNumeral(displayValue, valueFormat);
      }

      cssClass += ' text-nowrap text-right py-0 pr-1';
      return <td className={cssClass} style={cssStyles} data-rowkey={`${rowKey}`} data-colkey={`${colKey}`} data-colindex={`${colIndex}`} {...adjustmentMessage}>
        <div className={`${adjustmentIsDirty ? 'asterisk' : ''}`}>
          {displayValue || <>&nbsp;</>}
        </div>
      </td>
    }
    else {
      let adjustedValue = undefined;
      if (adjustment === undefined) {
        if (hasExistingAdjustment) value = adjustment;
        if (hasExistingInlineAdjustment) value = adjustment;
        adjustedValue = !valueFormat
          ? formatNumber({ value, useGrouping: false, minDecimals: specialisedStyles.decimalPlaces, maxDecimals: specialisedStyles.decimalPlaces })
          : formatNumeral(value, valueFormat);
      } else {
        adjustedValue = adjustment ?? '';
      }

      cssClass += ' position-relative py-0 pr-1';
      return <td className={cssClass} style={cssStyles} data-rowkey={`${rowKey}`} data-colkey={`${colKey}`} data-colindex={`${colIndex}`}>
        <ReportCellInput value={`${adjustedValue ?? ''}`}
          storedValue={value}
          rowKey={this.props.rowKey}
          colKey={this.props.colKey}
          colIndex={this.props.colIndex}
          onSetValue={this.props.onSetValue}
          onSetSelectionValue={this.props.onSetSelectionValue}
          onNavigateCell={this.props.onNavigateCell}
          onRemoveSelectionAdjustments={this.props.onRemoveSelectionAdjustments}
          copySelection={this.props.copySelection}
          pasteToSelection={this.props.pasteToSelection}
          undoSelectionAdjustments={this.props.undoSelectionAdjustments} />
      </td>
    }
  }
}

class ReportCellInput extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    storedValue: PropTypes.any,
    rowKey: PropTypes.string.isRequired,
    colKey: PropTypes.string.isRequired,
    colIndex: PropTypes.number.isRequired,
    onSetValue: PropTypes.func.isRequired,
    onSetSelectionValue: PropTypes.func.isRequired,
    onNavigateCell: PropTypes.func.isRequired,
    onRemoveSelectionAdjustments: PropTypes.func.isRequired,
    copySelection: PropTypes.func.isRequired,
    pasteToSelection: PropTypes.func.isRequired,
    undoSelectionAdjustments: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = { isDirty: false, value: undefined, activationValue: props.value, storedValue: props.storedValue };
  }

  componentWillUnmount() {
    const shouldSetValue = this.state.isDirty;
    if (shouldSetValue)
      this.props.onSetValue({ value: this.state.value, rowKey: this.props.rowKey, colKey: this.props.colKey, colIndex: this.props.colIndex });
  }

  onBlur() {
    if (!this.state.isDirty)
      return;

    const args = { value: this.state.value, rowKey: this.props.rowKey, colKey: this.props.colKey, colIndex: this.props.colIndex };
    this.props.onSetValue(args);
    this.setState({ ...this.state, isDirty: false });
  }

  onKeyDown(e) {
    const shouldSetValue = !e.shiftKey && this.state.isDirty;
    const args = {
      value: this.state.value,
      rowKey: this.props.rowKey,
      colKey: this.props.colKey,
      colIndex: this.props.colIndex
    };

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        if (shouldSetValue) this.props.onSetValue(args);
        this.props.onNavigateCell({ ...args, direction: 'up', continueSelection: e.shiftKey });
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (shouldSetValue) this.props.onSetValue(args);
        this.props.onNavigateCell({ ...args, direction: 'down', continueSelection: e.shiftKey });
        break;
      case 'ArrowLeft':
        if (e.target.selectionStart === 0) {
          e.preventDefault();
          if (shouldSetValue) this.props.onSetValue(args);
          this.props.onNavigateCell({ ...args, direction: 'left', continueSelection: e.shiftKey });
        }
        break;
      case 'ArrowRight':
        if (e.target.selectionStart === e.target.value.length || (e.shiftKey && e.target.selectionEnd === e.target.value.length)) {
          e.preventDefault();
          if (shouldSetValue) this.props.onSetValue(args);
          this.props.onNavigateCell({ ...args, direction: 'right', continueSelection: e.shiftKey });
        }
        break;
      case 'Tab':
        e.preventDefault();
        if (shouldSetValue) this.props.onSetValue(args);
        this.props.onNavigateCell({ ...args, direction: e.shiftKey ? 'left' : 'right' });
        break;
      case 'Enter':
        e.preventDefault();
        if ((e.shiftKey && e.ctrlKey) || e.ctrlKey) {
          this.props.onSetValue({ ...args, value: !this.state.isDirty ? this.state.activationValue : this.state.value, isDeferredValue: true });
          this.props.onSetSelectionValue(args);
        } else {
          if (shouldSetValue) this.props.onSetValue(args);
          this.props.onNavigateCell({ ...args, direction: 'enter' });
        }
        break;
      case 'Delete':
        if (e.shiftKey && e.ctrlKey) {
          e.preventDefault();
          this.props.onRemoveSelectionAdjustments();
        }
        else if (e.ctrlKey) {
          e.preventDefault();
          delete args.value;
          this.props.onSetValue(args);
        }
        break;
      case 'z':
        if (e.ctrlKey) {
          e.preventDefault();
          this.setState({ ...this.state, isDirty: false, value: this.state.storedValue });
          this.props.undoSelectionAdjustments();
        }
        break;
      case 'Escape':
        e.preventDefault();
        this.setState({ ...this.state, isDirty: false, value: this.state.activationValue });
        break;
      default:
        break;
    }
  }

  onCopy(e) {
    e.preventDefault();
    this.props.copySelection();
  }

  onPaste(e) {
    const text = e.clipboardData.getData('text/plain');
    const { success, table } = mapCSVToArray(text);
    if (success && (table.length > 1 || (table.length === 1 && table[0].length > 1))) {
      e.preventDefault();
      this.props.pasteToSelection(text);
    }
  }

  onChange(e) {
    this.setState({ ...this.state, isDirty: true, value: e.target.value });
  }

  render() {
    const value = this.state.value ?? this.props.value;

    function updateWidth(e) {
      if (e && e.parentElement) {
        const { width: parentWidth } = window.getComputedStyle(e.parentElement);
        e.style.width = `${parseFloat(parentWidth)}px`;
        e.style.display = 'unset';
      }
    }

    function onRef(e) {
      updateWidth(e);

      if (this.state.hasFocus)
        return;

      this.setState({ hasFocus: true })
      setTimeout(() => {
        if (e) {
          e.focus();
          e.setSelectionRange(0, e.value.length);
          updateWidth(e);
        }
      }, 10);
    }

    return <input className='report-scenario-input' ref={onRef.bind(this)} style={{ display: 'none' }} value={value ?? ''}
      onBlur={this.onBlur.bind(this)}
      onKeyDown={this.onKeyDown.bind(this)}
      onCopy={this.onCopy.bind(this)}
      onPaste={this.onPaste.bind(this)}
      onChange={this.onChange.bind(this)} />
  }
}

export class ReportsTable extends React.Component {
  static propTypes = {
    isEditing: PropTypes.bool,
    rootScenario: PropTypes.any,
    scenarioWidth: PropTypes.any,
    fromDate: PropTypes.any,
    toDate: PropTypes.any,
    displayMap: PropTypes.any,
    annotationsDisplayMap: PropTypes.any,
    columns: PropTypes.any,
    rows: PropTypes.any,
    annotationSections: PropTypes.any,
    errors: PropTypes.any,
    scenarios: PropTypes.any,
    scenarioMode: PropTypes.any,
    scenarioOverrideMap: PropTypes.object,
    warnings: PropTypes.any,
    setAllScenarioOverride: PropTypes.any,
    setScenarioOverride: PropTypes.any,
    orientation: PropTypes.string,
    toggleOnDemand: PropTypes.func,
    toggleExpand: PropTypes.func,
    toggleAnnotationExpand: PropTypes.func,
    openChart: PropTypes.func,
    getColumnStyleNames: PropTypes.func,
    getSpecialStyle: PropTypes.func,
    getStyle: PropTypes.func,
    htmlStyles: PropTypes.any,
    copySelection: PropTypes.func,
    pasteToSelection: PropTypes.func,
    undoSelectionAdjustments: PropTypes.func,
    setSaveConfirmationVisibility: PropTypes.func,
    onResize: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { menuId: 0, menuPosition: { top: 0, left: 0 }, selectedAnnotation: '', lastCellCoordinate: '' };
    this.reportRef = createRef();
  }

  componentDidMount() {
    this.bind_onKeyDown = this.onKeyDown.bind(this);
    window.addEventListener('keydown', this.bind_onKeyDown);
    this.bind_onMouseUp = this.onMouseUp.bind(this);
    window.addEventListener('mouseup', this.bind_onMouseUp);
    this.bind_onFocus = this.onFocus.bind(this);
    window.addEventListener('focusin', this.bind_onFocus);
  }

  componentWillUnmount() {
    delete window.isInTable;

    window.removeEventListener('keydown', this.bind_onKeyDown);
    window.removeEventListener('mouseup', this.bind_onMouseUp);
    window.removeEventListener('focusin', this.bind_onFocus);
  }

  setSelectedAnnotation(annotation) {
    this.setState({ ...this.state, selectedAnnotation: annotation });
  }

  setLastCellCoordinate(coords) {
    this.setState({ ...this.state, lastCellCoordinate: coords });
  }

  onMouseUp(e) {
    window.isInTable = this.isElementInTable(e.target);
  }

  onFocus(e) {
    window.isInTable = this.isElementInTable(e.target);
  }

  isElementInTable(e) {
    let maxNavigateCount = 10;
    while (e && maxNavigateCount > 0) {
      if (e === this.reportRef.current) return true;

      maxNavigateCount--;
      e = e.parentNode;
    }
  }

  onDoubleClick(e) {
    const { isEditing, beginEditMode } = this.props;
    const { rowKey, colKey, colIndex } = getCoord(e.target);
    if (!rowKey || !colKey)
      return;

    e.preventDefault();
    if (!isEditing)
      beginEditMode({ rowKey, colKey, colIndex });
  }

  onMouseDown(e) {
    const { setSelectionStart } = this.props;
    const { rowKey, colKey, colIndex } = getCoord(e.target);
    if (!rowKey || !colKey)
      return;

    if (this.state.lastCellCoordinate === `${rowKey}|${colKey}|${colIndex}`)
      return;

    this.setLastCellCoordinate(`${rowKey}|${colKey}|${colIndex}`);
    e.preventDefault();
    setSelectionStart({ rowKey, colKey, colIndex, ctrlKey: e.ctrlKey === true, shiftKey: e.shiftKey === true });
  }

  onMouseMove(e) {
    const { setSelectionEnd } = this.props;
    const { rowKey, colKey, colIndex } = getCoord(e.target);
    if (!rowKey || !colKey)
      return;

    if (this.state.lastCellCoordinate === `${rowKey}|${colKey}|${colIndex}`)
      return;

    this.setLastCellCoordinate(`${rowKey}|${colKey}|${colIndex}`);
    setSelectionEnd({ rowKey, colKey, colIndex, ctrlKey: e.ctrlKey === true, shiftKey: e.shiftKey === true });
  }

  onKeyDown(e) {
    const { selectLine = () => { }, navigateCell, undoSelectionAdjustments, copySelection, pasteToSelection } = this.props;

    switch (e.key) {
      case 's':
        if (e.ctrlKey) {
          e.preventDefault();
          this.showSaveAdjustments();
          return;
        }
        break;
      default:
        break;
    }

    if (!window.isInTable || !selectLine || !navigateCell || !undoSelectionAdjustments || !copySelection || !pasteToSelection)
      return;

    const isCellInput = e.target.nodeName === 'INPUT'
    switch (e.key) {
      case 'ArrowUp':
        if (isCellInput) return;

        e.preventDefault();
        if (e.shiftKey && e.ctrlKey) {
          selectLine({ isRowSelection: false, direction: 'fromStart' });
        } else {
          navigateCell({ direction: 'up', continueSelection: e.shiftKey });
        }
        break;
      case 'ArrowDown':
        if (isCellInput) return;

        e.preventDefault();
        if (e.shiftKey && e.ctrlKey) {
          selectLine({ isRowSelection: false, direction: 'toEnd' });
        } else {
          navigateCell({ direction: 'down', continueSelection: e.shiftKey });
        }
        break;
      case 'ArrowLeft':
        if (isCellInput) return;

        e.preventDefault();
        if (e.shiftKey && e.ctrlKey) {
          selectLine({ isRowSelection: true, direction: 'fromStart' });
        } else {
          navigateCell({ direction: 'left', continueSelection: e.shiftKey });
        }
        break;
      case 'ArrowRight':
        if (isCellInput) return;

        e.preventDefault();
        if (e.shiftKey && e.ctrlKey) {
          selectLine({ isRowSelection: true, direction: 'toEnd' });
        } else {
          navigateCell({ direction: 'right', continueSelection: e.shiftKey });
        }
        break;
      case 'Tab':
        if (isCellInput) return;

        e.preventDefault();
        navigateCell({ direction: e.shiftKey ? 'left' : 'right' });
        break;
      case 'c':
        if (e.ctrlKey) {
          if (isCellInput) return;

          e.preventDefault();
          copySelection();
        }
        break;
      case 'v':
        if (e.ctrlKey) {
          if (isCellInput) return;

          e.preventDefault();
          pasteToSelection();
        }
        break;
      case 'z':
        if (e.ctrlKey) {
          e.preventDefault();
          undoSelectionAdjustments();
        }
        break;
      default:
        break;
    }
  }

  onSetAdjustmentValue(args) {
    const { isEditing, setAdjustmentValue } = this.props;
    if (isEditing)
      setAdjustmentValue(args);
  }

  onSetSelectionAdjustmentValue(args) {
    const { isEditing, setSelectionAdjustmentValue } = this.props;
    if (isEditing)
      setSelectionAdjustmentValue(args);
  }

  onNavigateCell(args) {
    const { isEditing, navigateCell } = this.props;
    if (isEditing)
      navigateCell(args);
  }

  onCopy(e) {
    e.preventDefault();
    this.props.copySelection();
  }

  onPaste(e) {
    const text = e.clipboardData.getData('text/plain');
    const { success, table } = mapCSVToArray(text);
    if (success && (table.length > 1 || (table.length === 1 && table[0].length > 1))) {
      e.preventDefault();
      this.props.pasteToSelection(text);
    }
  }

  onRemoveSelectionAdjustments(args) {
    const { isEditing, removeSelectionAdjustments } = this.props;
    if (isEditing)
      removeSelectionAdjustments(args);
  }

  showSaveAdjustments() {
    this.props.setSaveConfirmationVisibility(true);
  }

  render() {
    const {
      isEditing,
      rootScenario, displayMap, annotationsDisplayMap, columns, rows, annotationSections, errors, scenarios, scenarioMode, scenarioOverrideMap, warnings,
      setAllScenarioOverride, setScenarioOverride, orientation, toggleOnDemand, toggleExpand, toggleAnnotationExpand, openChart, getColumnStyleNames, getSpecialStyle, getStyle, htmlStyles, horizontalView } = this.props;

    if (orientation !== 'Vertical')
      linkHorizontalViewCells(rows, horizontalView, scenarioOverrideMap);

    const { selectedAnnotation } = this.state;
    return <div className='d-flex flex-row w-100 h-100' style={{ overflow: 'auto'}}>
      <div className='d-flex flex-fill w-100 h-100'>
          {orientation === 'Vertical' ?
            <div ref={this.reportRef} className='fota-report table-responsive sticky-table sticky-ltr-cells' style={{ fontSize: '11px', ...htmlStyles }}>
              <ReportsTableVertical
                rows={rows}
                columns={columns}
                displayMap={displayMap}
                toggleExpand={toggleExpand}
                toggleOnDemand={toggleOnDemand}
                openChart={openChart}
                getStyle={getStyle}
                getSpecialStyle={getSpecialStyle}
                warnings={warnings}
                errors={errors}>
              </ReportsTableVertical>
            </div>
            :
            <div className='d-flex w-100 '>
              <div ref={this.reportRef} className='fota-report table-responsive sticky-table sticky-ltr-cells' style={{ fontSize: '11px', ...htmlStyles }}>
                <ReportsTableHorizontal
                  isEditing={isEditing}
                  columns={columns}
                  data={horizontalView}
                  rootScenario={rootScenario}
                  scenarioWidth={this.props.scenarioWidth}
                  scenarios={scenarios}
                  scenarioMode={scenarioMode}
                  setAllScenarioOverride={setAllScenarioOverride}
                  annotationSections={annotationSections}
                  useTBody={true}
                  useStickyHeader={true}
                  doubleClick={this.onDoubleClick.bind(this)}
                  mouseDown={this.onMouseDown.bind(this)}
                  mouseMove={this.onMouseMove.bind(this)}
                  onResize={this.props.onResize}>
                  <ReportRow toggleOnDemand={toggleOnDemand}
                    isEditing={isEditing}
                    columns={columns}
                    toggleExpand={toggleExpand}
                    scenarioMode={scenarioMode}
                    scenarioOverrideMap={scenarioOverrideMap}
                    openChart={openChart}
                    getColumnStyleNames={getColumnStyleNames}
                    getStyle={getStyle}
                    getSpecialStyle={getSpecialStyle}
                    setScenarioOverride={setScenarioOverride}
                    setAdjustmentValue={this.onSetAdjustmentValue.bind(this)}
                    setSelectionAdjustmentValue={this.onSetSelectionAdjustmentValue.bind(this)}
                    navigateCell={this.onNavigateCell.bind(this)}
                    removeSelectionAdjustments={this.onRemoveSelectionAdjustments.bind(this)}
                    copySelection={this.props.copySelection}
                    pasteToSelection={this.props.pasteToSelection}
                    undoSelectionAdjustments={this.props.undoSelectionAdjustments} />
                  <AnnotationRow toggleExpand={toggleAnnotationExpand}
                    displayMap={annotationsDisplayMap}
                    getColumnStyleNames={getColumnStyleNames}
                    getStyle={getStyle}
                    scenarioMode={scenarioMode}
                    setSelectedAnnotation={args => { this.setSelectedAnnotation(args); }} />
                </ReportsTableHorizontal>
                <AnnotationHighlighter className={selectedAnnotation} />
              </div>
              <div>
                <AdjustmentsPanel />
              </div>
              <div>
                <AdjustmentsSidebar />
              </div>
            </div>}        
      </div>
    </div>
  }
}

function getCoord(e) {
  while (e) {
    const dataset = e.dataset;
    if (dataset) {
      const rowKey = dataset.rowkey;
      const colKey = dataset.colkey;
      const colIndex = Number(dataset.colindex);
      if (rowKey && colKey)
        return { rowKey, colKey, colIndex };
    }

    e = e.parentNode;
  }

  return {};
}