import React from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} arrow/>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '50vw',
    fontSize: '12px'
  },
});

export default Tooltip;