import * as ColourPalette from '@mui/material/colors';

const sortGreyLast = ([keyA], [keyB]) => keyA === 'grey' ? 1 : keyB === 'grey' ? -1 : 0;
export const colours = Object.entries(ColourPalette).filter(([key]) => key !== 'common').sort(sortGreyLast);
export const colourShades = [100, 300, 500, 700, 900];
const allColours = [];

export const defaultColourCycle = [
  '#5B9BD5',
  '#ED7D31',
  '#CC006C',
  '#FFC000',
  '#70AD47',
  '#255E91',
  '#D32F2F',
  '#827717',
  '#50AD00',
  '#9E480E',
  '#BA68C8',
  '#303F9F',
  '#CCBE00',
  '#00A0A0',
  '#9C27B0',
  '#8DC6C1'
];

(function buildColors() {
  const darkestColourShades = [...colourShades];
  darkestColourShades.reverse();
  darkestColourShades.forEach(shade => {
    colours.forEach(color => {
      allColours.push(color[1][shade]);
    })
  });
}());

export function knownColour(index, userColours) {
  if (userColours) {
    return userColours[index % userColours.length];
  }

  return allColours[index % allColours.length];
}

export function knownColourToIndex(color, userColours) {
  if (userColours) {
    return userColours.indexOf(color);
  }

  return allColours.indexOf(color);
}

export function toHighChartsColour(colour) {
  if (window.Highcharts)
    return window.Highcharts.Color(colour).setOpacity(0.5).get('rgba');

  return 'rgba(200,200,200,0.5)';
}

let _userColours = [...defaultColourCycle];
let _userColourIndex = -1;
export function setUserColours(userColours) {
  _userColours = userColours || defaultColourCycle;
}

export function getNextUserColour() {
  _userColourIndex++;
  _userColourIndex = _userColourIndex % _userColours.length;
  return _userColours[_userColourIndex];
}

export function getHLS(hex, dark = '#000000', light = '#FFFFFF') {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result || result.length !== 4){
    return {
      hue: 0,
      luminance: 1,
      saturation: 0,
      isLight: true,
      contrast: dark,
      isValid: false
    }
  }

  const r = parseInt(result[1], 16) / 255;
  const g = parseInt(result[2], 16) / 255;
  const b = parseInt(result[3], 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
      default:
        break;
    }
    h /= 6;
  }

  return {
    hue: h,
    luminance: l,
    saturation: s,
    isLight: l > 0.5,
    contrast: l > 0.5 ? dark : light,
    isValid: true
  };
}

export function generateGradients(stops, granularity = 50.0){
  if (stops.length === 1)
    stops.push(stops[0]);

  var colours = stops.map(s => window.Highcharts.color(s));

  const spread = [];

  for (let c = 0; c < colours.length - 1; c++) {
    for (let index = 0; index < 1; index += 0.1) {
      spread.push(colours[c].tweenTo(colours[c + 1], index))
    }
  }

  var response = [];
  var stepSize = spread.length / granularity
  for (let step = 0; step < spread.length; step += stepSize) {
    response.push(spread[parseInt(step)]);
  }

  return response;
}