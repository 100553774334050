import React, { useEffect, useCallback, useState } from 'react';
import { toJS } from '../../../utility/immutable-utility';
import { connect } from 'react-redux';
import {
  dashboardTileDefinedReportFromWorkspace,
  dashboardTileDefinedReportSetScenarioOverride,
  dashboardTileDefinedReportsSetAllScenarioOverride,

  dashboardTileDefinedReportToggleOnDemand,
  dashboardTileDefinedReportToggleExpand,
  dashboardTileDefinedReportToggleAnnotationExpand,

  dashboardTileDefinedReportRefreshChart,

  dashboardTileDefinedReportToggleExpandTier,
  dashboardTileDefinedReportReloadOnDemandParams
} from '../../../actions/dashboard-tile-definedReport';
import Loading from '../../shared/Loading';
import { ReportsTable } from '../reports/ReportsTable';
import { ReportsTierToggle } from '../reports/ReportsTierToggle';
import Dialog from '@mui/material/Dialog';
import { getScenarios } from '../../../utility/reportstable-utility';
import { comparisonModes } from '../../../utility/comparisonmode-utility';
import AnalysisChart from '../analysis/AnalysisChart';

const DefinedReportTile = connect(
  (state, { stateKey }) => {
    const statePath = ['dashboardTiles', 'tilesState', stateKey];
    const configPath = ['dashboardTiles', 'tilesConfig', stateKey];
    const refreshRequired = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'refreshRequired']);
    const isBusy = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'isBusy']);

    const workspacePath = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'workspacePath']);
    const chartData = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'chart']);

    return {

      // layoutChanged,
      refreshRequired,
      isBusy,
      workspacePath,
      chartData,

      lenses: state.getIn([...statePath, 'results', 'settings', 'lenses']),
      orientation: state.getIn([...statePath, 'results', 'settings', 'orientation']),
      displayMap: state.getIn([...statePath, 'results', 'displayMap']),
      annotationsDisplayMap: state.getIn([...statePath, 'results', 'annotationsDisplayMap']),
      scenarioOverrideMap: state.getIn([...configPath, 'results', 'scenarioOverrideMap']),
      rootScenario: state.getIn([...statePath, 'criteria', 'rootScenario']),
      styles: state.getIn([...statePath, 'results', 'styles']),
      specialisedStyles: state.getIn([...statePath, 'results', 'specialisedStyles']),
      columns: state.getIn([...statePath, 'results', 'columns']),
      rows: state.getIn([...statePath, 'results', 'rows']),
      horizontalView: state.getIn([...statePath, 'results', 'horizontalView']),
      selectedLevel: state.getIn([...statePath, 'results', 'selectedLevel']),
      tierToggleMap: state.getIn([...statePath, 'results', 'tierToggleMap']),
      annotationSections: state.getIn([...statePath, 'results', 'annotationSections']),
      errors: state.getIn([...statePath, 'results', 'errors']),
      warnings: state.getIn([...statePath, 'results', 'warnings']),
      scenarioMode: state.getIn([...statePath, 'results', 'settings', 'scenarioMode']),

      reportCriteriaLens: state.getIn([...statePath, 'criteria', 'lens'])
    };
  },
  (dispatch, { stateKey }) => ({
    refresh(workspacePath) {
      dispatch(dashboardTileDefinedReportFromWorkspace(stateKey, workspacePath));
    },
    setScenarioOverride(key, value) {
      dispatch(dashboardTileDefinedReportSetScenarioOverride(stateKey, key, value));
    },
    toggleOnDemand(key, level, displayKey) {
      dispatch(dashboardTileDefinedReportToggleOnDemand(stateKey, key, level, displayKey));
    },
    toggleExpand(key, level, displayKey) {
      dispatch(dashboardTileDefinedReportToggleExpand(stateKey, key, level, displayKey));
    },
    toggleAnnotationExpand(sectionKey) {
      dispatch(dashboardTileDefinedReportToggleAnnotationExpand(stateKey, sectionKey));
    },
    toggleExpandTier(tier, value) {
      dispatch(dashboardTileDefinedReportToggleExpandTier(stateKey, tier, value));
      dispatch(dashboardTileDefinedReportReloadOnDemandParams(stateKey));
    },
    refreshChartData(ids, parentKeys, options) {
      dispatch(dashboardTileDefinedReportRefreshChart(stateKey, ids, parentKeys, options));
    },
    setAllScenarioOverride(value) {
      dispatch(dashboardTileDefinedReportsSetAllScenarioOverride(stateKey, value));
    },
  })
)(({ lenses, orientation,
  isBusy, refresh, refreshRequired, workspacePath, //layoutChanged,
  displayMap, annotationsDisplayMap, styles, specialisedStyles, columns, rows, annotationSections, errors, scenarioMode, scenarioOverrideMap, warnings,
  reportCriteriaLens,
  rootScenario, setAllScenarioOverride, setScenarioOverride, toggleOnDemand, toggleExpand, toggleAnnotationExpand,
  refreshChartData, chartData,
  selectedLevel, tierToggleMap, toggleExpandTier,
  horizontalView
}) => {
  useEffect(() => {
    if (refreshRequired)
      refresh(workspacePath);
  }, [refresh, refreshRequired, workspacePath]);

  const _lenses = toJS(lenses, []);
  const _horizontalView = orientation === 'Vertical' ? [] : toJS(horizontalView);
  const _displayMap = orientation === 'Vertical' ? toJS(displayMap, {}) : displayMap;
  const _rows = orientation === 'Vertical' ? toJS(rows, {}) : rows;
  const _annotationsDisplayMap = toJS(annotationsDisplayMap, {});
  const _annotationSections = toJS(annotationSections, []);
  const _scenarioOverrideMap = toJS(scenarioOverrideMap, {});
  const _specialisedStyles = toJS(specialisedStyles, {});
  const _styles = toJS(styles, {});
  const _errors = toJS(errors, {});
  const _warnings = toJS(warnings, {});
  const getSpecialStyle = useCallback(key => _specialisedStyles[key] || '', [_specialisedStyles]);
  const getStyle = useCallback(key => _styles[key] || {}, [_styles]);
  const scenarios = rows ? getScenarios(rows) : [];
  const [isChartDialogOpen, setIsChartDialogOpen] = useState(false);
  const [chartLens, setChartLens] = useState(reportCriteriaLens);
  const [chartTimeSeriesId, setChartTimeSeriesId] = useState();
  const _chartData = toJS(chartData);
  const _tierToggleMap = toJS(tierToggleMap);
  const _columns = [{
      key: 'id',
      title: 'Name',
      className: 'sticky-cell',
      rightComponent: <ReportsTierToggle selectedLevel={selectedLevel} tierToggleMap={_tierToggleMap} toggleExpandTier={toggleExpandTier} />,
      style: {
        padding: '.1rem',
        minWidth: '29em'
      }
    }, ...(columns && columns.toJS ? columns.toJS() || [] : columns || []).map(i => ({
      ...i,
      noWrap: false,
      style: {
        whiteSpace: 'pre',
        padding: '.1rem',
        ...(i ? (i.className || '').split(' ') : []).reduce((accumulator, item) => ({ ...accumulator, ...getStyle(item) }), {})
      }
    }))
  ];

  function getColumnStyleNames(ix) {
    return _columns && _columns.length > ix ? _columns[ix].className.split(' ') : [];
  }

  function openChart(id, parentKeys, children = []) {
    const timeSeriesIds = [id, ...children].filter(i => i !== 0);
    refreshChartData(timeSeriesIds, parentKeys);
    setChartTimeSeriesId({ timeSeriesIds, parentKeys });
    setIsChartDialogOpen(true);
  }

  function onSetChartLens(lens) {
    setChartLens(lens);
    refreshChartData(chartTimeSeriesId.timeSeriesIds, chartTimeSeriesId.parentKeys, { lens: lens });
  }

  function onRefreshChart(e) {
    e.preventDefault();
    refreshChartData(chartTimeSeriesId.timeSeriesIds, chartTimeSeriesId.parentKeys, { lens: chartLens });
  }

  function closeChartDialog() {
    setIsChartDialogOpen(false);
  }
  
  const chartSeries = (isChartDialogOpen && _chartData && _chartData.series) ? (Object.values(_chartData.series) || []).map(c => {
    c.data = _chartData.seriesData[c.id];
    return c;
  }) : [];

  const hasSyntheticTimeSeries = isChartDialogOpen && chartSeries.some(f => f.isSynthetic);

  return <Loading isLoading={isBusy} message='Loading...'>
    {rows &&
      <div style={{ overflowX: 'auto', backgroundColor: '#FFFFFF', height: '100%', paddingBottom: '10px' }}>
        <ReportsTable
          orientation={orientation}
          displayMap={_displayMap}
          annotationsDisplayMap={_annotationsDisplayMap}
          styles={_styles}
          specialisedStyles={specialisedStyles}
          columns={_columns}
          rows={_rows}
          annotationSections={_annotationSections}
          errors={_errors}
          rootScenario={rootScenario}
          scenarios={scenarios}
          scenarioMode={scenarioMode}
          scenarioOverrideMap={_scenarioOverrideMap}
          setAllScenarioOverride={setAllScenarioOverride}
          warnings={_warnings}
          setScenarioOverride={setScenarioOverride}
          toggleOnDemand={toggleOnDemand}
          toggleExpand={toggleExpand}
          toggleAnnotationExpand={toggleAnnotationExpand}
          openChart={openChart}
          getColumnStyleNames={getColumnStyleNames}
          getSpecialStyle={getSpecialStyle}
          getStyle={getStyle}
          htmlStyles={{ height: '100%' }}
          isEditing={false}
          horizontalView={_horizontalView}
          onResize={noop}
          beginEditMode={noop}
          endEditMode={noop}
          setSelectionStart={noop}
          setSelectionEnd={noop}
          setAdjustmentValue={noop}
          setSelectionAdjustmentValue={noop}
          navigateCell={noop}
          removeSelectionAdjustments={noop}
          copySelection={noop}
          pasteToSelection={noop}
          undoSelectionAdjustments={noop}
        />
      </div>}
    <Dialog open={isChartDialogOpen} onClose={closeChartDialog} fullWidth={true} maxWidth='lg' >
      {isChartDialogOpen &&_chartData && (
        <div className='modal-content' style={{ height: '80vh', top: 0, left: 0, right: 0, bottom: 0 }}>
          <div className='modal-body'>

            <div className='position-absolute overflow-hidden bg-white shadow rounded m-auto' style={{ width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0 }}>
              {!hasSyntheticTimeSeries && <div className='position-absolute my-3' style={{ left: 50, zIndex: 1, width: '350px' }}>
                <div className='form-row'>
                  <div className='col form-group'>
                    <div className='input-group input-group-sm'>
                      <div className='input-group-prepend'>
                        <div className='input-group-text'>Lens</div>
                      </div>
                      <select className='form-control' name='lens' defaultValue={reportCriteriaLens} onChange={e => onSetChartLens(e.target.value)}>
                        {_lenses.map(l => <option key={`lens-${l.lens}`} value={l.lens}>{l.lens}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className='col form-group'>
                    <button onClick={onRefreshChart} className='btn btn-sm btn-outline-primary'>
                      <i className='fas fa-sync-alt fa-fw' />
                    </button>
                  </div>
                </div>
              </div>}
              <Loading isLoading={_chartData.isLoading} message='Loading...'>
                <AnalysisChart 
                  chartSeries={ chartSeries } 
                  title='Reports Chart' 
                  lens={chartLens} 
                  comparisonMode={comparisonModes.none} 
                  hasXAxis={false}/>
              </Loading>
            </div>
          </div>

        </div>
      )}
    </Dialog>
  </Loading >
});

export default DefinedReportTile;

function noop() {
}
