import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import OutagesLoaderConnected from './OutagesLoaderConnected';
import Outages from './Outages';
import {
  outagesInitialise,
  outagesLookupGenerationDataLookupList,
  outagesUpdateToolbarSource,
  outagesUpdateToolbar,
  outagesSelectOutage,
  outagesUpdateFilter,
  outagesUpdatePage,
  outagesSearch,
  outagesResetValidation,
  outagesCopyPath
} from '../../../actions/outages';
import '../../../styles/power.css';

const OutagesConnected = connect(
  (state) => ({
    isInitialised: state.getIn(['outages', 'isInitialised']),
    isLoading: state.getIn(['outages', 'isLoading']),
    data: state.getIn(['outages', 'data']),
    selectedOutage: state.getIn(['outages', 'selectedOutage']),
    resultCount: state.getIn(['outages', 'resultCount']),
    requiresRefresh: state.getIn(['outages', 'requiresRefresh']),

    isLoadingSourceList: state.getIn(['outages', 'sourceList', 'isLoading']),
    sourceList: state.getIn(['outages', 'toolbar', 'sourceList', 'data']),

    isLoadingBiddingZoneList: state.getIn(['outages', 'toolbar', 'biddingZoneList', 'isLoading']),
    biddingZoneList: state.getIn(['outages', 'toolbar', 'biddingZoneList', 'data']),

    isLoadingProductionTypeList: state.getIn(['outages', 'toolbar', 'productionTypeList', 'isLoading']),
    productionTypeList: state.getIn(['outages', 'toolbar', 'productionTypeList', 'data']),

    isLoadingGenerationDataLookupList: state.getIn(['outages', 'toolbar', 'generationDataLookupList', 'isLoading']),
    generationDataLookupList: state.getIn(['outages', 'toolbar', 'generationDataLookupList', 'data']),

    source: state.getIn(['outages', 'toolbar', 'source']),
    biddingZone: state.getIn(['outages', 'toolbar', 'biddingZone']),
    productionType: state.getIn(['outages', 'toolbar', 'productionType']),
    unitEic: state.getIn(['outages', 'toolbar', 'unitEic']),
    eventStart: state.getIn(['outages', 'toolbar', 'eventStart']),
    eventEnd: state.getIn(['outages', 'toolbar', 'eventEnd']),
    page: state.getIn(['outages', 'toolbar', 'page']),
    pageSize: state.getIn(['outages', 'toolbar', 'pageSize']),
    fotaStatus: state.getIn(['outages', 'toolbar', 'fotaStatus']),
    messageVersions: state.getIn(['outages', 'toolbar', 'messageVersions']),
    messageStatus: state.getIn(['outages', 'toolbar', 'messageStatus']),
    generationData: state.getIn(['outages', 'toolbar', 'generationData']),
    filters: state.getIn(['outages', 'toolbar', 'filters'])
  }),
  (dispatch) => ({
    initialise() {
      dispatch(outagesInitialise());
    },
    refresh() {
      dispatch(outagesSearch());
    },
    lookupGenerationData() {
      dispatch(outagesLookupGenerationDataLookupList());
    },
    updateToolbarSource(value) {
      dispatch(outagesUpdateToolbarSource(value));
    },
    updateToolbar(key, value) {
      dispatch(outagesUpdateToolbar(key, value));
    },
    updateFilter(key, value) {
      dispatch(outagesUpdateFilter(key, value));
    },
    updatePage(page, pageSize) {
      dispatch(outagesUpdatePage(page, pageSize));
    },
    selectOutage(item) {
      dispatch(outagesSelectOutage(item));
    },
    resetOutages() {
      dispatch(outagesResetValidation());
    },
    shareUrl() {
      dispatch(outagesCopyPath());
    }
  })
)(({ location, isInitialised, isLoading, isLoadingSourceList, isLoadingBiddingZoneList, isLoadingProductionTypeList, isLoadingGenerationDataLookupList,
  data, selectedOutage, resultCount, requiresRefresh, sourceList, biddingZoneList, productionTypeList, generationDataLookupList,
  source, biddingZone, productionType, unitEic, eventStart, eventEnd, page, pageSize, fotaStatus, messageVersions, messageStatus, generationData, filters,
  initialise, refresh, lookupGenerationData, updateToolbarSource, updateToolbar, updateFilter, updatePage, selectOutage, resetOutages, shareUrl }) => {
  const _sourceList = toJS(sourceList, []);
  const _biddingZoneList = toJS(biddingZoneList, []);
  const _productionTypeList = toJS(productionTypeList, []);
  const _generationDataLookupList = toJS(generationDataLookupList, []);
  const _data = toJS(data, []);
  const _selectedOutage = toJS(selectedOutage, null);

  useLayoutEffect(() => {
    if (!isInitialised) initialise();
  }, [isInitialised, initialise]);

  return (
    <OutagesLoaderConnected location={location}>
      <Outages isLoading={isLoading}
        isLoadingSources={isLoadingSourceList}
        isLoadingBiddingZoneList={isLoadingBiddingZoneList}
        isLoadingProductionTypeList={isLoadingProductionTypeList}
        isLoadingGenerationDataLookupList={isLoadingGenerationDataLookupList}
        requiresRefresh={requiresRefresh}
        sourceList={_sourceList}
        biddingZoneList={_biddingZoneList}
        productionTypeList={_productionTypeList}
        generationDataLookupList={_generationDataLookupList}
        data={_data}
        resultCount={resultCount}
        source={source}
        biddingZone={biddingZone}
        productionType={productionType}
        unitEic={unitEic}
        eventStart={eventStart}
        eventEnd={eventEnd}
        page={page}
        pageSize={pageSize}
        fotaStatus={fotaStatus}
        messageVersions={messageVersions}
        messageStatus={messageStatus}
        generationData={generationData}
        filters={filters}
        selectedOutage={_selectedOutage}
        refresh={refresh}
        lookupGenerationData={lookupGenerationData}
        updateToolbarSource={updateToolbarSource}
        updateToolbar={updateToolbar}
        updateFilter={updateFilter}
        updatePage={updatePage}
        selectOutage={selectOutage}
        resetOutages={resetOutages}
        shareUrl={shareUrl} />
    </OutagesLoaderConnected>
  );
});

export default OutagesConnected;