import React from 'react';

import AnalysisChartTile from '../components/app-content/dashboard-tiles/AnalysisChartTile';
import AnalysisChartTileOptions from '../components/app-content/dashboard-tiles/AnalysisChartTileOptions';

import DefinedReportTile from '../components/app-content/dashboard-tiles/DefinedReportTile';
import DefinedReportTileOptions from '../components/app-content/dashboard-tiles/DefinedReportTileOptions';

import MarkdownTile from '../components/app-content/dashboard-tiles/MarkdownTile';
import MarkdownTileOptions from '../components/app-content/dashboard-tiles/MarkdownTileOptions';

import WebLinkTile from '../components/app-content/dashboard-tiles/WebLinkTile';
import WebLinkTileOptions from '../components/app-content/dashboard-tiles/WebLinkTileOptions';

import ShortcutTile from '../components/app-content/dashboard-tiles/ShortcutTile';
import ShortcutTileOptions from '../components/app-content/dashboard-tiles/ShortcutTileOptions';

import AnalysisTableTile from '../components/app-content/dashboard-tiles/AnalysisTableTile';
import AnalysisTableTileOptions from '../components/app-content/dashboard-tiles/AnalysisTableTileOptions';

// avoid adding new tile types using this, use RegisterDashboardItem (e.g. storageActions)
export const TileTypes = {
  AnalysisChart: 'AnalysisChart',
  DefinedReport: 'DefinedReport',
  Markdown: 'Markdown',
  WebLink: 'WebLink',
  Shortcut: 'Shortcut',
  AnalysisTable: 'AnalysisTable'
};

export function upgradeDashboardTileKeyToStateKey(data) {
  if (data.tilesConfig) {
    Object.keys(data.tilesConfig).forEach(stateKey => {
      const tileConfig = data.tilesConfig[stateKey];
      if (!tileConfig.stateKey && tileConfig.dashboardTileKey) {
        tileConfig.stateKey = stateKey;
        delete tileConfig.dashboardTileKey;
      }
    });
  }
}

export function prepareDashboardForPersistence(dashboardTiles, periods = []) {
  const {dynamicWorkspaceDropdowns} = dashboardTiles;
  if (dynamicWorkspaceDropdowns) {
    dynamicWorkspaceDropdowns.forEach(d => {
      d.options = [];
    });
  }

  const {criteria} = dashboardTiles;
  if (criteria){
    const periodLookups = Object.fromEntries(periods.map(p => [p.name, p]));
    const {periodSettings = {}} = criteria;
    const newPeriodSettings = {};

    Object.keys(periodSettings).forEach(name => {
      const periodSetting = periodSettings[name];
      const period = periodLookups[name] ?? {isEditable : false};
      newPeriodSettings[name] = {
        isHidden: periodSetting.isHidden === true,
        overriddenByPeriod: !period.isEditable ? periodSetting.overriddenByPeriod : undefined,
        overriddenByUser : !period.isEditable ? periodSetting.overriddenByUser === true : false
      };

      if (periodSetting.overriddenByUser === true) {
        newPeriodSettings[name].from = periodSetting.from;
        newPeriodSettings[name].to = periodSetting.to;
      } else {
        if (!periodSetting.overriddenByPeriod) {
          if (period.isEditable) {
            newPeriodSettings[name].from = periodSetting.from;
            newPeriodSettings[name].to = periodSetting.to;
          }
        }
      }
    });

    criteria.periodSettings = newPeriodSettings;
  }
}

export function mapApiTilesConfig(data) {
  // data is incomming from the API so can be null, tilesConfig will also be null for a newly created workspace
  const { tilesConfig = {} } = data ?? {};

  Object.keys(tilesConfig).forEach(stateKey => {
    const tileConfig = tilesConfig[stateKey];
    tileConfig.stateKey = stateKey;
    if (tileConfig.overrideDynamicSetting) {
      tileConfig.overrideDynamicWorkspace = tileConfig.overrideDynamicSetting.map(o => ({
        key: o.filterSetKey,
        filters: o.filters ? o.filters.map(f => ({
          dynamicFilterKey: f.filterKey,
          value: f.value
        })) : []
      }));

      delete tileConfig.overrideDynamicSetting;
    };
  });

  return tilesConfig;
}

export function mapTileConfig(tile) {
  const config = {
    type: tile.type,
    color: tile.color ?? '#F5F5F5',
    borderStyle: tile.borderStyle ?? 'default',
    stateKey: tile.stateKey,
    title: tile.title,
    hideToolbar: tile.hideToolbar,
    width: tile.width ?? 9,
    height: tile.height ?? 5,
    viewTable: tile.viewTable,
    x: tile.x ?? 0,
    y: tile.y ?? 0,
  };

  switch (tile.type) {
    case TileTypes.AnalysisChart:
      config.workspacePath = tile.workspacePath;
      config.viewTable = tile.viewTable;
      break;
    case TileTypes.DefinedReport:
      config.workspacePath = tile.workspacePath;
      break;
    case TileTypes.Markdown:
      config.markdown = tile.markdown;
      break;
    case TileTypes.Shortcut:
      config.workspacePath = tile.workspacePath;
      config.stretchImage = tile.stretchImage;
      config.hideWorkspaceName = tile.hideWorkspaceName;
      break;
    case TileTypes.WebLink:
      config.workspacePath = tile.workspacePath;
      config.stretchImage = tile.stretchImage;
      config.hideWorkspaceName = tile.hideWorkspaceName;
      break;
    case TileTypes.AnalysisTable:
      config.workspacePath = tile.workspacePath;
      break;
    default:
      config.workspacePath = tile.workspacePath;
      break;
  }

  return config;
}

export const workspaceTypeToTileTypeMap = {
  'Analysis': TileTypes.AnalysisChart,
  'Report': TileTypes.DefinedReport,
  'WebLink': TileTypes.WebLink,
  'AnalysisTable': TileTypes.AnalysisTable
}

export function createNewTileContent(dashboardTileType, props) {
  switch (dashboardTileType) {
    case TileTypes.Markdown:
      return MarkdownTileOptions(props)
    case TileTypes.AnalysisTable:
      return AnalysisTableTileOptions(props)
    case TileTypes.AnalysisChart:
    case TileTypes.DefinedReport:
    case TileTypes.WebLink:
    case TileTypes.Shortcut:
    default:
      return undefined;
  }
}

export function tileComponent({ dashboardTileType, stateKey }) {
  switch (dashboardTileType) {
    case TileTypes.AnalysisChart:
      return <AnalysisChartTile dashboardTileType={dashboardTileType} stateKey={stateKey} />
    case TileTypes.DefinedReport:
      return <DefinedReportTile dashboardTileType={dashboardTileType} stateKey={stateKey} />
    case TileTypes.Markdown:
      return <MarkdownTile dashboardTileType={dashboardTileType} stateKey={stateKey} />
    case TileTypes.WebLink:
      return <WebLinkTile dashboardTileType={dashboardTileType} stateKey={stateKey} />
    case TileTypes.Shortcut:
      return <ShortcutTile dashboardTileType={dashboardTileType} stateKey={stateKey} />
    case TileTypes.AnalysisTable:
      return <AnalysisTableTile dashboardTileType={dashboardTileType} stateKey={stateKey} />
    default:
      return undefined;
  }
}

export function tileOptions(props) {
  const { dashboardTileType } = props;
  switch (dashboardTileType) {
    case TileTypes.AnalysisChart:
      return AnalysisChartTileOptions(props);
    case TileTypes.DefinedReport:
      return DefinedReportTileOptions(props);
    case TileTypes.Markdown:
      return MarkdownTileOptions(props);
    case TileTypes.WebLink:
      return WebLinkTileOptions(props);
    case TileTypes.Shortcut:
      return ShortcutTileOptions(props);
    case TileTypes.AnalysisTable:
      return AnalysisTableTileOptions(props);
    default:
      return undefined;
  }
}

const tileConfig = {};

export function RegisterDashboardItem({
  type, 
  refreshAction,
  component, 
  viewToolbar = () => <></>, 
  editToolbar = () => <></>,
  settingsView = () => <></>,
  includeInAddWorkspacePicker, 
  onOpenWorkspace, 
  dashboardCriteriaOptions,
  dashboardFilterOptions
  }) {
  tileConfig[type] = {
    component,
    viewToolbar,
    editToolbar,
    settingsView,
    refreshAction,
    onOpenWorkspace,
    dashboardCriteriaOptions,
    dashboardFilterOptions
  }

  if (includeInAddWorkspacePicker)
    workspaceTypeToTileTypeMap[type] = type;
}

export function GetRegisteredDashboardItem(dashboardTileType) {
  return tileConfig[dashboardTileType];
}