import React from 'react';
import { connect } from 'react-redux';
import { CheckboxInput } from '../../shared/CheckboxInput';
import {
  columnsNames,
  hideableFilterNames
} from '../../../state/storage-auctions';
import { dashboardTileSetProperty } from '../../../actions/dashboard-tile';
import { storageAuctionsSetValue } from '../../../actions/storageAuctions';

export const StorageAuctionsTileSettingsConnected = connect(
  (state, { stateKey }) => ({
    stateKey,
    hiddenColumns: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'hiddenColumns']),
    hiddenFilters: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'hiddenFilters']),
    hidePagination: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'hidePagination'])
  }),
  (dispatch, { stateKey }) => ({
    setValue(key, value) {
      dispatch(dashboardTileSetProperty(stateKey, key, value));
      dispatch(storageAuctionsSetValue(stateKey, key, value));
    }
  })
)(({ stateKey, setValue, hiddenFilters, hiddenColumns, hidePagination }) => {
  function OnChangeShowPaginations() {
    setValue(['workspace', 'hidePagination'], !hidePagination);
  }

  function onChangeFilterVisibility(value) {
    let map = hiddenFilters.toJS();
    if (map[value] === true)
      delete map[value];
    else
      map[value] = true;

    setValue(['workspace', 'hiddenFilters'], map);
  }

  function isFilterHidden(c) {
    return !hiddenFilters.get(c) === true;
  }

  function onChangeColumnVisibility(value) {
    let map = hiddenColumns.toJS();
    if (map[value] === true)
      delete map[value];
    else
      map[value] = true;
    setValue(['workspace', 'hiddenColumns'], map);
  }

  function isColumnHidden(c) {
    return !hiddenColumns.get(c) === true;
  }

  return <>
    <div className='form-group'>
      <label className='font-weight-bold'>View</label>
      <CheckboxInput checked={!hidePagination} onChange={OnChangeShowPaginations} >Show Pagination</CheckboxInput>
    </div>
    <div className='form-group'>
      <label className='font-weight-bold'>Visible Filters</label>
      <div style={{ maxWidth: '620px' }}>
        {hideableFilterNames.map(c => <div key={c} style={{ display: 'inline-block', width: '200px' }}>
          <CheckboxInput checked={isFilterHidden(c)} onChange={() => onChangeFilterVisibility(c)} >{c}</CheckboxInput>
        </div>
        )}
      </div>
    </div>
    <div className='form-group'>
      <label className='font-weight-bold'>Visible Columns</label>
      <div style={{ maxWidth: '620px' }}>
        {columnsNames.map(c => <div key={c} style={{ display: 'inline-block', width: '200px' }}>
          <CheckboxInput checked={isColumnHidden(c)} onChange={() => onChangeColumnVisibility(c)} >{c}</CheckboxInput>
        </div>
        )}
      </div>
    </div>
  </>
})