import React from 'react';
import moment from 'moment';
import { CUTOFF_INFO, RELATIVE_ASAT_INFO, RELATIVE_OFFSET_INFO, WINDOW_INFO } from '../../../help';
import InfoPopup from '../../shared/InfoPopup';
import FormattedDateTime from '../../shared/FormattedDateTime';
import RelativeDate from '../../shared/RelativeDate';
import Loading from '../../shared/Loading';
import Table from '../../shared/Table';
import Pagination from '../../shared/Pagination';
import Timezone from '../../shared/Timezone';
import { mapToComparisonDisplay } from '../../../utility/analysis-basket-utility';
import ShapesBuilder from '../analysis-shapes/ShapesBuilder'
import LensedDateTime from '../../shared/LensedDateTime';
import { comparisonModeLensedDateOptions, comparisonModeName, comparisonModePlotOriginOptions, comparisonModes } from '../../../utility/comparisonmode-utility';
import { TabContainer } from '../../shared/TabContainer';
import { CheckboxInput } from '../../shared/CheckboxInput';
import AbsRelDate from '../../shared/AbsRelDate';

function TimeSeriesWindowEditor({ windowPropertyName, window, updateValue }) {
  return <>
  {/* from */}
  <label className='fota-input-group-label font-weight-bold' style={{ fontSize: '.9em' }}>From</label>
  <div className='form-inline form-group'>
    <AbsRelDate value={{mode:window.fromDateMode, abs: window.absFromDate, rel:window.relFromDate}} relativeDateOptions={['D', 'W', 'M', 'Y']} onChange={v => updateValue([windowPropertyName], {...window, fromDateMode:v.mode, absFromDate:v.abs, relFromDate:v.rel} )}/>
  </div>

  {/* to */}
  <label className='fota-input-group-label font-weight-bold' style={{ fontSize: '.9em' }}>To</label>
  <div className='form-inline form-group'>
    <AbsRelDate value={{mode:window.toDateMode, abs: window.absToDate, rel:window.relToDate}} relativeDateOptions={['D', 'W', 'M', 'Y']} onChange={v => updateValue([windowPropertyName], {...window, toDateMode:v.mode, absToDate:v.abs, relToDate:v.rel} )}/>
  </div>
</>
}

function PlotOrigin({timeSeries, workspace, updateValue}){
  function onToggleEnabledState(isEnabled){
    updateValue(['plotOriginEnabled'],isEnabled);
    if (isEnabled && !timeSeries.plotOriginMode){
      updateValue(['plotOriginMode'], workspace.fromDateMode);
      updateValue(['absPlotOrigin'], moment(workspace.fromUtc).format('YYYY-MM-DD'));
      updateValue(['relPlotOrigin'], workspace.relFrom);
    }
  }
  
  function onSetPlotOrigin(value) {
      updateValue(['plotOriginMode'], value.mode);
      updateValue(['absPlotOrigin'], value.abs);
      updateValue(['relPlotOrigin'], value.rel);
  }

  return <>
    <div className='form-inline form-group'>
      <CheckboxInput checked={timeSeries.plotOriginEnabled} onChange={(_,checked) => onToggleEnabledState(checked)}>Plot Origin</CheckboxInput>
    </div>
        
    <div className='form-inline form-group' style={{ opacity: timeSeries.plotOriginEnabled ? '1' : '0.4' }}>
      <AbsRelDate disabled={!timeSeries.plotOriginEnabled} value={{ mode: timeSeries.plotOriginMode ?? 'abs', abs: timeSeries.absPlotOrigin, rel: timeSeries.relPlotOrigin }} onChange={value => onSetPlotOrigin(value)} />
    </div>
  </>
}

function ComparisonWindow({ timeSeries, updateValue }) {
  if (!timeSeries.comparisonSettings || timeSeries.comparisonSettings.mode === 'none')
    return <></>

  let modeText = !timeSeries.comparisonSettings.mode || timeSeries.comparisonSettings.mode === comparisonModes.none ? '' : comparisonModeName[timeSeries.comparisonSettings.mode];

  const text = `${modeText} ${mapToComparisonDisplay(timeSeries.comparisonSettings).description}`

  const _plotOriginAbs = (timeSeries.comparisonSettings.plotOrigin && timeSeries.comparisonSettings.plotOrigin.absOriginDate) ? moment.utc(timeSeries.comparisonSettings.plotOrigin.absOriginDate).format('YYYY-MM-DD') : '';
  const _plotOriginRel = (timeSeries.comparisonSettings.plotOrigin && timeSeries.comparisonSettings.plotOrigin.relOriginDate) ? timeSeries.comparisonSettings.plotOrigin.relOriginDate : '';
  const _plotOriginMode = (timeSeries.comparisonSettings.plotOrigin && timeSeries.comparisonSettings.plotOrigin.mode) ? timeSeries.comparisonSettings.plotOrigin.mode : 'abs';

  function onSetPlotOriginMode(value) {
    updateValue(['comparisonSettings', 'plotOrigin', 'mode'], value);
  }

  function onSetAbsPlotOrigin(value) {
    updateValue(['comparisonSettings', 'plotOrigin', 'absOriginDate'], value);
  }

  function onSetRelPlotOrigin(value) {
    updateValue(['comparisonSettings', 'plotOrigin', 'relOriginDate'], value);
  }

  return (
    <div>
      {text}
      {timeSeries.comparisonSettings.plotOrigin && <>
        <div className='form-inline form-group'>
          <div className='d-flex'>
            <label className='fota-input-group-label font-weight-bold'>Plot Origin</label>
            {_plotOriginMode === 'abs' && <LensedDateTime lens={comparisonModeLensedDateOptions[timeSeries.comparisonSettings.mode]} dateTimeClassName='form-control' placeholder='Origin'
              value={_plotOriginAbs}
              onChange={onSetAbsPlotOrigin} />}
            {_plotOriginMode === 'rel' && <RelativeDate value={_plotOriginRel} onChange={onSetRelPlotOrigin} options={comparisonModePlotOriginOptions[timeSeries.comparisonSettings.mode]} />}

            <div className='form-check ml-2'>
              <input className='form-check-input' type='radio' value='abs' id='datemode-mode-abs'
                checked={_plotOriginMode === 'abs'}
                onChange={() => onSetPlotOriginMode('abs')} />
              <label className='form-check-label' htmlFor='datemode-mode-abs' >Absolute</label>
            </div>
            <div className='form-check ml-2'>
              <input className='form-check-input' type='radio' value='rel' id='datemode-mode-rel'
                checked={_plotOriginMode === 'rel'}
                onChange={() => onSetPlotOriginMode('rel')} />
              <label className='form-check-label' htmlFor='datemode-mode-rel'>Relative</label>
            </div>
          </div>
        </div>
      </>}

      <p className='text-muted mt-2'>To change a comparison window use ... -&gt; select comparisons</p>
    </div>
  );
}

function OverrideWindow({ timeSeries, updateValue }) {
  return   <TimeSeriesWindowEditor windowPropertyName={'window'}
                                   window={timeSeries.window}
                                   updateValue={updateValue} />
}

function AsAtWindow({ timeSeries, updateValue }) {
  return <>
    <div className='form-inline form-group'>
      <div className='form-check mr-2'>
        <input type='checkbox' className='form-check-input' id='relasat-toggle' checked={timeSeries.dataWindowRelativeToAsAt} onChange={e => updateValue('dataWindowRelativeToAsAt', e.target.checked)} />
        <label className='form-check-label' htmlFor='relasat-toggle'>Data relative to asAt</label>
      </div>
      <InfoPopup>
        When selected any relative from or to dates will be relative to each asAt returned
      </InfoPopup>
    </div>
    <TimeSeriesWindowEditor windowPropertyName={'asAtWindow'}
                            window={timeSeries.asAtWindow}
                            updateValue={updateValue} />
  </>
}

function WorkspaceWindow({ workspace }) {
  return <div style={{ opacity: '0.4' }}>
    {/* from */}
    <label className='fota-input-group-label font-weight-bold' style={{ fontSize: '.9em' }}>From</label>
    <div className='form-inline form-group'>
      <AbsRelDate disabled={true} value={{mode:workspace.fromDateMode, abs:workspace.fromUtc, rel:workspace.relFrom}} relativeDateOptions={['D', 'W', 'M', 'Y']}/>
    </div>

    {/* to */}
    <label className='fota-input-group-label font-weight-bold' style={{ fontSize: '.9em' }}>To</label>
    <div className='form-inline form-group'>
      <AbsRelDate disabled={true} value={{mode:workspace.toDateMode, abs:workspace.toUtc, rel:workspace.relTo}} relativeDateOptions={['D', 'W', 'M', 'Y']}/>
    </div>
  </div>
}

const asAtPickerColumns = [{
  key: 'identityId',
  title: 'Id'
}, {
  key: 'timestampUtc',
  title: <span>Timestamp <Timezone /></span>
}, {
  key: 'updatedUtc',
  title: <span>Last Updated <Timezone /></span>
}, {
  key: 'firstDataPointUtc',
  title: <span>First data point <Timezone /></span>
}, {
  key: 'lastDataPointUtc',
  title: <span>Last data point <Timezone /></span>
}];

function AsAtRow({ rawData, toggleAsAt, isSelected, isSelectedElsewhere }) {
  const { identityId, timestampUtc, updatedUtc, firstDataPointUtc, lastDataPointUtc } = rawData;

  const _isSelected = isSelected(rawData);
  const _isSelectedElsewhere = isSelectedElsewhere(rawData);

  const className = `${_isSelected ? 'table-primary' : _isSelectedElsewhere ? 'table-secondary' : ''}`

  function onClick() {
    if (!_isSelectedElsewhere)
      toggleAsAt(rawData);
  }

  return (
    <tr className={className} style={{ userSelect: 'none', cursor: 'pointer' }}
      onClick={onClick}>
      <td className='text-nowrap'>{identityId}</td>
      <td className='text-nowrap'>
        <FormattedDateTime>{timestampUtc}</FormattedDateTime>
      </td>
      <td className='text-nowrap'>
        <FormattedDateTime>{updatedUtc}</FormattedDateTime>
      </td>
      <td className='text-nowrap'>
        <FormattedDateTime>{firstDataPointUtc}</FormattedDateTime>
      </td>
      <td className='text-nowrap'>
        <FormattedDateTime>{lastDataPointUtc}</FormattedDateTime>
      </td>
    </tr>
  );
}

function AsAtPicker({ timeSeries, timeSeriesAsAts, toggleAsAt, pageChange }) {
  const { isLoading, data, selection, page, pageSize, error } = timeSeriesAsAts;

  const isSelected = ({ timestampUtc }) => timeSeries.asAt === timestampUtc;
  const isSelectedElsewhere = ({ timestampUtc }) => timeSeries.asAt !== timestampUtc && selection.some(i => i === timestampUtc);

  function onToggleAsAt({ timestampUtc }) {
    toggleAsAt(timestampUtc);
  }

  return (
    <Loading isLoading={isLoading} message='Loading other versions...'>
      <>
        <div className='table-responsive mb-3' style={{ maxHeight: '30vh', fontSize: '.9em' }}>
          <Table className='table-hover' columns={asAtPickerColumns} data={data && data.results} useTBody={true}
            emptyMessage={error || 'No other versions available'}>
            <AsAtRow isSelected={isSelected} isSelectedElsewhere={isSelectedElsewhere} toggleAsAt={onToggleAsAt} />
          </Table>
        </div>
        <div>
          <Pagination steps={5}
            currentPage={page}
            pageSize={pageSize}
            itemCount={data && data.count}
            onPageChange={pageChange}
            hidePageSize={true}
            hideJumpTo={true} />
        </div>
      </>
    </Loading>
  );
}

function AdHocAsAtPicker({ timeSeries, updateValue }) {
  const { asAt } = timeSeries ?? {};

  function onChange(e) {
    updateValue(['asAt'], e.target.value);
  }

  return (
    <div className='form-group' style={{ maxWidth: 225 }}>
      <input type='datetime-local' className='form-control' min='0001-01-01T00:00:00' max='9999-12-31T23:59:59' step='01'
        value={asAt}
        onChange={onChange} />
    </div>
  );
}

function RelativeAsAtPicker({ timeSeries, updateValue }) {
  const { relativeAsAtDate } = timeSeries ?? {};
  const { period, cutoffTime } = relativeAsAtDate ?? {};

  function onPeriodChange(value) {
    updateValue(['relativeAsAtDate', 'period'], value);
  }

  function onCutOffChange(e) {
    updateValue(['relativeAsAtDate', 'cutoffTime'], e.target.value);
  }

  return (
    <>
      <div className='form-group' style={{ maxWidth: 225 }}>
        <RelativeDate value={period} onChange={onPeriodChange} isPastOnly={false} />
      </div>
      <div className='form-group' style={{ maxWidth: 225 }}>
        <label style={{ fontWeight: 'bold' }}>Cutoff time</label>
        <InfoPopup>
            {CUTOFF_INFO}
        </InfoPopup>
        <input className='form-control' onChange={onCutOffChange} value={cutoffTime} />
      </div>
    </>
  );
}

function ForecastOffsetPicker({ timeSeries, updateValue }) {
  const { forecastOffset: period, cutoffTime } = timeSeries ?? {};

  function onPeriodChange(value) {
    updateValue(['forecastOffset'], value);
  }

  function onCutOffChange(e) {
    updateValue(['cutoffTime'], e.target.value);
  }

  return (
    <>
      <div className='form-group' style={{ maxWidth: 225 }}>
        <RelativeDate value={period} onChange={onPeriodChange} isPastOnly={false} options={['D', 'M']} />
      </div>
      <div className='form-group' style={{ maxWidth: 225 }}>
        <label style={{ fontWeight: 'bold' }}>Cutoff time</label>
        <InfoPopup>
            {CUTOFF_INFO}
        </InfoPopup>
        <input className='form-control' onChange={onCutOffChange} value={cutoffTime} />
      </div>
    </>
  );
}

function ForecastWindow({ operations, timeSeries, updateValue }) {
  const asAtOps = ['Close','Open'];
  const simpleChoice = ['true','false'];

  return (
    <>
        <TimeSeriesWindowEditor windowPropertyName={'forecastWindow'}
                                window={timeSeries.forecastWindow}
                                updateValue={updateValue} />

        <div className='form-group'>
            <label className='font-weight-bold'>AsAt selection operation</label>
            <select className='form-control form-control-sm' value={timeSeries.forecastWindowAsAtOperation} onChange={e => updateValue(['forecastWindowAsAtOperation'], e.target.value)}>
              <option key='' style={{ color: '#AAAAAA' }} value=''></option>
              {asAtOps.map(i => <option key={i} value={i}>{i}</option>)}
            </select>
        </div>
        <div className='form-group'>
            <label className='font-weight-bold'>AsAt window relative to data points</label>
            <select className='form-control form-control-sm' value={timeSeries.forecastWindowAsAtWindowRelativeToDataPoint} onChange={e => updateValue(['forecastWindowAsAtWindowRelativeToDataPoint'], e.target.value)}>
              {simpleChoice.map(i => <option key={i} value={i}>{i}</option>)}
            </select>
        </div>
        <div className='form-group'>
            <label className='font-weight-bold'>Data point operation</label>
            <select className='form-control form-control-sm' value={timeSeries.forecastWindowDataPointOperation} onChange={e => updateValue(['forecastWindowDataPointOperation'], e.target.value)}>
              {operations.map(i => <option key={i} value={i}>{i}</option>)}
            </select>
        </div>
        <div className='form-group'>
            <label className='font-weight-bold'>Strict count for data point operation</label>
            <select className='form-control form-control-sm' value={timeSeries.forecastWindowStrictCountForOperation} onChange={e => updateValue(['forecastWindowStrictCountForOperation'], e.target.value)}>
              {simpleChoice.map(i => <option key={i} value={i}>{i}</option>)}
            </select>
        </div>
        <div className='form-group'>
            <label className='font-weight-bold'>Fill forward asAt</label>
            <select className='form-control form-control-sm' value={timeSeries.forecastWindowFillForwardAsAt} onChange={e => updateValue(['forecastWindowFillForwardAsAt'], e.target.value)}>
              {simpleChoice.map(i => <option key={i} value={i}>{i}</option>)}
            </select>
        </div>
    </>
  );
}

export const TimeSeriesEditComponent = (({
  focusPath,
  workspace, timeSeries, timeSeriesAsAts, operations, lenses, toggleAsAt, asAtPageChange, updateValue, onSave, onCancel,
  shapesSettings,
  setShapeName, selectShape, saveShape, deleteShape,
  toggleShapeCellSelection, toggleShapeAllSelection, toggleShapeBucketSelection, toggleShapeGroupSelection, toggleBasketMaterialisationMode, toggleBasketVariantMode }) => {

  const [focusTab, focusElement] = (focusPath ?? '').split('|');
  const focusTabIndex = focusTab ? ['General', 'Forecast', 'Shape'].indexOf(focusTab) : undefined;

  const canSave = !shapesSettings.shapeTemplateMapIsDirty;
  const { asAtType, seriesStyle, materialisationModeDisabled, variantModeDisabled } = timeSeries ?? {};
  const shapesSettingsAvailableShapes = [
    { id: undefined, name: '' },
    ...shapesSettings.availableShapes];

  let _windowType = timeSeries.windowType;
  if (!_windowType) {
    _windowType = 'none';
    if (timeSeries.window) _windowType = 'override';
    if (timeSeries.comparisonSettings) _windowType = 'comparison';
  }

  function onSetWindow(windowType) {
    updateValue(['windowType'], windowType);
    switch (windowType) {
      case 'override':
        updateValue(['period'], '');
        if (!timeSeries.window) {
          updateValue(['window'], {
            fromDateMode: workspace.fromDateMode,
            absFromDate: workspace.fromUtc,
            relFromDate: workspace.relFrom,
            toDateMode: workspace.toDateMode,
            absToDate: workspace.toUtc,
            relToDate: workspace.relTo
          });
        }
        break;

      case 'comparison':
        // build a default comparison setting object as required
        break;

      case 'none':
      default:
        break;
    }
  }

  function onSetAsAtWindow(enabled) {
    updateValue(['asAtWindowEnabled'], enabled);
    if (!timeSeries.asAtWindow) {
      updateValue(['asAtWindow'], {
        fromDateMode: 'abs',
        absFromDate: moment.utc().startOf('day').add(-14, 'day').format('YYYY-MM-DDTHH:mm'),
        relFromDate: '-1M',
        toDateMode: 'abs',
        absToDate: moment.utc().startOf('day').add(1, 'day').format('YYYY-MM-DDTHH:mm'),
        relToDate: '0D'
      });
    }
  }

  function onSaveShape() {
    saveShape((response) => {
      updateValue(['shape'],
        response ? {
          id: response.id,
          name: response.name,
          onItems: response.onItems
        } : undefined);
    });
  }

  function onDeleteShape() {
    deleteShape();
    selectShape(undefined, () => updateValue(['shape'], undefined));
  }

  function onSelectShape(id) {
    selectShape(id, (response) => {
      updateValue(['shape'],
        response ? {
          id: response.id,
          name: response.name,
          onItems: response.onItems
        } : undefined);
    });
  }

  function onSubmit(e) {
    e.preventDefault();
    if (canSave)
      onSave();
  }

  return (
    <form className='modal-content' onSubmit={onSubmit} style={{ flex: 1 }}>
      <div className='modal-header'>
        <h5 className='modal-title'>{timeSeries.name}</h5>
        <button type='button' className='close' onClick={onCancel}>&times;</button>
      </div>
      <div className='modal-body' style={{minHeight:'550px'}}>
        <TabContainer initialSelectedTabIndex={focusTabIndex} >
          <div header='General'>
            <>
              <div className='form-group'>
                <label className='font-weight-bold'>Lens</label>
                <select className='form-control form-control-sm' value={timeSeries.lens} onChange={e => updateValue(['lens'], e.target.value)}>
                  <option key='' style={{ color: '#AAAAAA' }} value=''></option>
                  {lenses.map(i => <option key={i} value={i}>{i}</option>)}
                </select>
              </div>
              <div className='form-group'>
                <label className='font-weight-bold'>Operation</label>
                <select className='form-control form-control-sm' value={timeSeries.operation} onChange={e => updateValue(['operation'], e.target.value)}>
                  <option key='' style={{ color: '#AAAAAA' }} value=''></option>
                  {operations.map(i => <option key={i} value={i}>{i}</option>)}
                </select>
              </div>
              <div className='form-group'>
                <label className='font-weight-bold'>Scenario</label>
                <InfoPopup>
                  Allows addition of a free text scenario override, ie. PurePrice, SNT+2
                </InfoPopup>
                <input autoFocus={focusElement === 'scenario'} className='form-control form-control-sm' value={timeSeries.scenario} onChange={e => updateValue(['scenario'], e.target.value)} />

                <label className='font-weight-bold'>Complex scenario</label>
                <InfoPopup>
                  When a complex set of scenario overrides have been applied from reports this will be populated, initially with all scenarios used.
                  Any edits will be used purely for series labelling, ie. complexScenario as an expression
                </InfoPopup>
                <input className='form-control form-control-sm' value={timeSeries.complexScenario} onChange={e => updateValue(['complexScenario'], e.target.value)} />
              </div>

              <div className='form-row'>
                <div className='col'>
                  <div className='form-group mb-0'>
                    {_windowType === 'comparison'
                      ? <label className='font-weight-bold'>Comparison Window</label>
                      : <label className='font-weight-bold'>Window</label>}
                    {_windowType !== 'comparison' && (
                      <div className='form-check form-switch'>
                        <input id='window-override' type='checkbox' className='form-check-input'
                          autoFocus={focusElement === 'window'}
                          checked={_windowType === 'override'}
                          onChange={e => onSetWindow(e.target.checked ? 'override' : 'none')} />
                        <label className='form-check-label' htmlFor='window-override'
                          style={{ userSelect: 'none', cursor: 'pointer' }}>Override</label>
                      </div>
                    )}
                  </div>
                  {_windowType === 'none' && <WorkspaceWindow workspace={workspace} />}
                  {_windowType === 'override' && <OverrideWindow timeSeries={timeSeries} updateValue={updateValue} />}
                  {_windowType === 'comparison' && <ComparisonWindow timeSeries={timeSeries} updateValue={updateValue} />}
                  {_windowType !== 'comparison' && <>
                    <PlotOrigin workspace={workspace} timeSeries={timeSeries} updateValue={updateValue}/>
                  </>}
                </div>
                <div className='col'>
                  <div className='form-group mb-0'>
                    <label className='font-weight-bold'>AsAt Window</label>
                    <InfoPopup>
                      Set AsAtWindow in order to view the evolution of the data points
                    </InfoPopup>
                    <div className='form-check form-switch'>
                      <input id='window-enable-asat' type='checkbox' className='form-check-input'
                        autoFocus={focusElement === 'asAtWindow'}
                        checked={timeSeries.asAtWindowEnabled}
                        onChange={e => onSetAsAtWindow(e.target.checked)} />
                      <label className='form-check-label' htmlFor='window-enable-asat'
                        style={{ userSelect: 'none', cursor: 'pointer' }}>Enable</label>
                    </div>
                    {timeSeries.asAtWindowEnabled && <AsAtWindow timeSeries={timeSeries} updateValue={updateValue} />}
                  </div>
                </div>
              </div>

              <div className='form-row mt-2 mb-2'>
                <div className="form-check form-switch ml-2">
                  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckBasketMaterialisationMode"
                    checked={materialisationModeDisabled}
                    onChange={e => updateValue(['materialisationModeDisabled'], !materialisationModeDisabled)} />
                  <label className="form-check-label" htmlFor="flexSwitchCheckBasketMaterialisationMode">Disable materialisation mode</label>
                </div>
                <br />
                <div className="form-check form-switch ml-2">
                  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckBasketVariantMode"
                    checked={variantModeDisabled}
                    onChange={e => updateValue(['variantModeDisabled'], !variantModeDisabled)} />
                  <label className="form-check-label" htmlFor="flexSwitchCheckBasketVariantMode">Disable variant mode</label>
                </div>
              </div>
            </>
          </div>
          <div header='Forecast'>
            <>
              <div style={{ fontWeight: 'bold' }}>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio' id='timeseries-edit-forecast-none' name='forecast-type'
                    value='' checked={!asAtType}
                    onChange={e => updateValue(['asAtType'], e.target.value)} />
                  <label className='form-check-label' htmlFor='timeseries-edit-forecast-none'>None</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio' id='timeseries-edit-forecast-asat' name='forecast-type'
                    value='asAt' checked={asAtType === 'asAt'}
                    onChange={e => updateValue(['asAtType'], e.target.value)} />
                  <label className='form-check-label' htmlFor='timeseries-edit-forecast-asat'>AsAt</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio' id='timeseries-edit-forecast-adhoc' name='forecast-type'
                    value='adHocAsAt' checked={asAtType === 'adHocAsAt'}
                    onChange={e => updateValue(['asAtType'], e.target.value)} />
                  <label className='form-check-label' htmlFor='timeseries-edit-forecast-adhoc'>AdHoc AsAt</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio' id='timeseries-edit-forecast-relative' name='forecast-type'
                    value='relativeAsAt' checked={asAtType === 'relativeAsAt'}
                    onChange={e => updateValue(['asAtType'], e.target.value)} />
                  <label className='form-check-label' htmlFor='timeseries-edit-forecast-relative'>Relative AsAt</label>
                  <InfoPopup>
                    {RELATIVE_ASAT_INFO}
                  </InfoPopup>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio' id='timeseries-edit-forecast-offset' name='forecast-type'
                    value='forecastOffset' checked={asAtType === 'forecastOffset'}
                    onChange={e => updateValue(['asAtType'], e.target.value)} />
                  <label className='form-check-label' htmlFor='timeseries-edit-forecast-offset'>Relative forecast offset</label>
                  <InfoPopup>
                    {RELATIVE_OFFSET_INFO}
                  </InfoPopup>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio' id='timeseries-edit-forecast-window' name='forecast-type'
                    value='window' checked={asAtType === 'window'}
                    onChange={e => updateValue(['asAtType'], e.target.value)} />
                  <label className='form-check-label' htmlFor='timeseries-edit-forecast-offset'>Window</label>
                  <InfoPopup>
                    {WINDOW_INFO}
                  </InfoPopup>
                </div>                

                <a className='fota-small-text' href={`https://dev.azure.com/mercuria-fota/timeseries/_wiki/wikis/timeseries.wiki/335/Configure-basket`} target='_blank' rel='noopener noreferrer'>
                  <i className='fas fa-book fa-fw' /> Wiki</a>
              </div>

              {asAtType && asAtType !== 'window' && (
                <div className='form-row' style={{ fontWeight: 'bold' }}>
                  <div className='col-sm-2'>Series style</div>
                  <div className='form-check form-check-inline'>
                    <input className='form-check-input' type='radio' id='timeseries-series-style-default' name='series-build-style'
                      value='masterBuild' checked={!seriesStyle || seriesStyle === 'masterBuild'}
                      onChange={e => updateValue(['seriesStyle'], e.target.value)} />
                    <label className='form-check-label' htmlFor='timeseries-series-style-default'>Default</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input className='form-check-input' type='radio' id='timeseries-series-style-instance' name='series-build-style'
                      value='justInstance' checked={seriesStyle === 'justInstance'}
                      onChange={e => updateValue(['seriesStyle'], e.target.value)} />
                    <label className='form-check-label' htmlFor='timeseries-series-style-instance'>JustInstance</label>
                  </div>
                  <InfoPopup>
                    Default: data is a blend of master or instances<br />
                    JustInstance: data is for the select instance only
                  </InfoPopup>
                </div>)}

              <div className='mt-3'>
                {asAtType === 'asAt' && <AsAtPicker timeSeries={timeSeries} timeSeriesAsAts={timeSeriesAsAts} toggleAsAt={toggleAsAt} pageChange={asAtPageChange} />}
                {asAtType === 'adHocAsAt' && <AdHocAsAtPicker timeSeries={timeSeries} updateValue={updateValue} />}
                {asAtType === 'relativeAsAt' && <RelativeAsAtPicker timeSeries={timeSeries} updateValue={updateValue} />}
                {asAtType === 'forecastOffset' && <ForecastOffsetPicker timeSeries={timeSeries} updateValue={updateValue} />}
                {asAtType === 'window' && <ForecastWindow operations={operations} timeSeries={timeSeries} updateValue={updateValue} />}
              </div>
            </>
          </div>
          <div header='Shape'>
            <>
              <ShapesBuilder canSelectNothing={true}
                selectionListTitle='Selected shape'
                shapeName={shapesSettings.shapeName}
                isBusy={shapesSettings.isBusy}
                isSavingShape={shapesSettings.isSavingShape}
                isDeletingShape={shapesSettings.isDeletingShape}
                isSelectingShape={shapesSettings.isSelectingShape}
                isExistingShape={shapesSettings.isExistingShape}
                availableShapes={shapesSettingsAvailableShapes}
                shapeTemplateMap={shapesSettings.shapeTemplateMap}
                shapeTemplateMapIsDirty={shapesSettings.shapeTemplateMapIsDirty}
                setShapeName={setShapeName}
                selectShape={onSelectShape}
                saveShape={onSaveShape}
                deleteShape={onDeleteShape}
                toggleShapeCellSelection={toggleShapeCellSelection}
                toggleShapeAllSelection={toggleShapeAllSelection}
                toggleShapeBucketSelection={toggleShapeBucketSelection}
                toggleShapeGroupSelection={toggleShapeGroupSelection} />
            </>
          </div>
          {/* <div header='Evolution'>            
          </div> */}
        </TabContainer>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' onClick={onCancel}>Cancel</button>
        <button type='submit' className='btn btn-primary' disabled={!canSave} >Save</button>
      </div>
    </form>
  );
});