import React from 'react';
import ShapesEditorConnectComponent from './ShapesEditorConnectComponent';
import { ModalDialogButton } from '../../shared/ModalDialogButton';

export default function ShapesPicker({ workspaceSelection, shapesInit, loadAvailableShapes, removeSelectedFromWorkspace, removeAllShapesFromWorkspace }) {
  const onShowShapesPicker = () => {
    shapesInit();
    loadAvailableShapes();
  }

  const onRemoveSelectedShape = (shape) => {
    removeSelectedFromWorkspace(shape);
  }

  const onRemoveAllSelection = () => {
    removeAllShapesFromWorkspace();
  }

  return <div className='input-group' >
    <div className='form-control' style={{ height: "auto", paddingLeft: "0px", paddingTop: "1px", paddingBottom: "1px" }}>
      <div style={{ maxHeight: "10em", overflowY: "auto" }}>
        {workspaceSelection.map(s => <div key={s.name}
          className='btn btn-outline-secondary btn-sm mr-1 ml-1 mb-1 mt-1'
          style={{ paddingLeft: "6px", paddingRight: "6px", paddingTop: "2px", paddingBottom: "2px" }} onClick={() => onRemoveSelectedShape(s)} >
          {s.name}
          <i className='fas fa-times fa-fw' />
        </div>)}
      </div>
    </div>
    <div className='input-group-append dropdown' style={{ height: "2.7em" }}>
      {workspaceSelection.length > 0 && <button type='button' className='btn btn-sm btn-outline-secondary' onClick={onRemoveAllSelection} >
        <i className='fas fa-times fa-fw' />
      </button>}
      <ModalDialogButton buttonContent={<i className='fas fa-plus fa-fw' />} buttonClassName='btn btn-sm btn-secondary' size='md' onOpenDialog={onShowShapesPicker}>
          <ShapesEditorConnectComponent />
      </ModalDialogButton>
    </div>
  </div>
}