import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
  USER_SETTINGS_LOAD_STARTED,
  USER_SETTINGS_LOAD_COMPLETE
} from '../actions/userSettings';

export const userSettings = createReducer(null, {
  [USER_SETTINGS_LOAD_STARTED](state, action) {
    return state.set('isLoading', true);
  },
  [USER_SETTINGS_LOAD_COMPLETE](state, action) {
    if (!action.data) return state.set('isLoading', false);

    return state.set('settings', fromJS(action.data || {}))
                .set('isLoading', false);
  }
});