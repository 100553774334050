import React from 'react';
import { connect } from 'react-redux';
import {
  timeSeriesDetailsAdjustmentsSetSaveProperty
} from '../../../../actions/timeSeriesDetails-adjustments';

const DataPointsSaveModal = connect(
  (state) => {
    return {
      reason: state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsSaveModal', 'reason']),
      comment: state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsSaveModal', 'commentMarkdown']),
      adjustmentType: state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsSaveModal', 'adjustmentType'])
    }
  },
  (dispatch) => {
    return {
      updateProperty(properties, value) {
        dispatch(timeSeriesDetailsAdjustmentsSetSaveProperty(properties, value));
      }
    }
  }
)(({ reason, comment, adjustmentType, updateProperty, title, onSubmit }) => {

  function onSave(e) {
    e.preventDefault();
    onSubmit(e);
  }

  return (
    <form className='modal-content' onSubmit={onSave}>
      <div className='modal-header'>
        <h5 className='modal-title'>{title}</h5>
        <button type='button' className='close' data-dismiss='modal'>&times;</button>
      </div>
      <div className='modal-body'>
        <div className='form-group'>
          <label htmlFor='save-datapoints-reason'>Reason</label>
          <input className='form-control' id='save-datapoints-reason'
            type='text' placeholder='Enter an optional reason for the change' maxLength='80'
            onChange={e => updateProperty(['reason'], e.target.value)}
            value={reason} />
        </div>
        <div className='form-group'>
          <label htmlFor='save-datapoints-comment'>Comment</label>
          <textarea className='form-control' id='save-datapoints-comment'
            placeholder='Enter an optional comment for the change' size='400' maxLength='400' rows='5'
            onChange={e => updateProperty(['commentMarkdown'], e.target.value)}
            value={comment} />
        </div>
        <div className='form-row mt-2 ml-4'>
          <label className='form-check-label' htmlFor='save-datapoints-adjustmentType'>Make temporary</label>
          <input className='form-check-input' id='save-datapoints-adjustmentType' type="checkbox" checked={adjustmentType === 'Temporary'} onChange={e => updateProperty(['adjustmentType'], e.target.checked ? 'Temporary' : 'Standard')} />
        </div>        
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
        <button type='submit' className='btn btn-primary' >Save</button>
      </div>
    </form>
  );
});

export default DataPointsSaveModal;