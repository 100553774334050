import { push } from "redux-first-history";
import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import { authFetch } from '../auth';
import { WORKSPACES_API_URL } from '../config';
import {
  logInfoNotification,
  logErrorNotification
} from './log';
import {
  timeSeriesDetailsDerivationKeysLoad,
  timeSeriesDetailsDerivationKeysSave
} from './timeSeriesDetails-derivation';
import {
  timeSeriesDetailsBasedOnLoad
} from './timeSeriesDetails-based-on';
import {
  timeSeriesDetailsAnnotationsLoad
} from './timeSeriesDetails-annotations';
import {
  timeSeriesDetailsAdjustmentsLoad
} from './timeSeriesDetails-adjustments';
import {
  timeSeriesInitialise,
  timeSeriesClear
} from './time-series';

export const TIMESERIES_DETAILS_INITIALISE = 'TIMESERIES_DETAILS_INITIALISE';
export const timeSeriesDetailsInitialise = createAction(TIMESERIES_DETAILS_INITIALISE, 'view');

export const TIMESERIES_DETAILS_INITIALISE_INPUTKEY_STYLE = 'TIMESERIES_DETAILS_INITIALISE_INPUTKEY_STYLE';
export const timeSeriesDetailsInitialiseInputKeyStyle = createAction(TIMESERIES_DETAILS_INITIALISE_INPUTKEY_STYLE, 'style');

export const timeSeriesDetailsInitialiseTimeSeries = ({ timeSeriesId, timeSeriesObject, inputKeysStyle = 'default' }) => (dispatch, getState) => {
  dispatch(timeSeriesDetailsLoadLookupData());
  dispatch(timeSeriesDetailsInitialiseInputKeyStyle(inputKeysStyle));

  // Clone time series
  if (timeSeriesObject) {
    timeSeriesObject = initialiseTimeSeries(timeSeriesObject);
    dispatch(timeSeriesDetailsNewTimeSeries('new', timeSeriesObject));
    dispatch(timeSeriesDetailsDerivationKeysLoad());
    dispatch(timeSeriesDetailsBasedOnLoad());
    /*
      TEMP: Pass time series data into shared area of state.
      Time series should be loaded into state and shared among components.
    */
    dispatch(timeSeriesClear());
    /*
      END TEMP
    */
    return;
  }

  // New time series
  if (isNaN(timeSeriesId)) {
    const state = getState();
    const userSettingTimeSeries = toJS(state.getIn(['userSettings', 'settings', 'timeSeries']), {});
    const data = createEmptyTimeseries(userSettingTimeSeries);
    dispatch(timeSeriesDetailsNewTimeSeries(`${timeSeriesId}`.toLowerCase(), data));
    dispatch(timeSeriesDetailsCategoriesLoad());
    /*
      TEMP: Pass time series data into shared area of state.
      Time series should be loaded into state and shared among components.
    */
    dispatch(timeSeriesClear());
    /*
      END TEMP
    */
    return;
  }

  // Load time series
  dispatch(timeSeriesDetailsSetCurrentId(timeSeriesId));
  dispatch(timeSeriesDetailsLoad({ timeSeriesId }));
};

export const TIMESERIES_DETAILS_NEW_TIMESERIES = 'TIMESERIES_DETAILS_NEW_TIMESERIES';
export const timeSeriesDetailsNewTimeSeries = createAction(TIMESERIES_DETAILS_NEW_TIMESERIES, 'id', 'data');

export const TIMESERIES_DETAILS_CLONE_TIMESERIES_COMPLETE = 'TIMESERIES_DETAILS_CLONE_TIMESERIES_COMPLETE';
export const timeSeriesDetailsCloneTimeSeriesComplete = createAction(TIMESERIES_DETAILS_CLONE_TIMESERIES_COMPLETE, 'data');


export const TIMESERIES_DETAILS_LOAD_STARTED = 'TIMESERIES_DETAILS_LOAD_STARTED';
export const timeSeriesDetailsLoadStarted = createAction(TIMESERIES_DETAILS_LOAD_STARTED);

export const timeSeriesDetailsLoad = ({ timeSeriesId, doNotLoadComposition = false }) => (dispatch, getState) => {
  if (!timeSeriesId) return;

  dispatch(timeSeriesDetailsLoadStarted());

  return authFetch(`${WORKSPACES_API_URL}/timeseries?id=${timeSeriesId}`)
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => initialiseTimeSeries(data))
    .then(data => {
      /*
        TEMP: Pass time series data into shared area of state.
        Time series should be loaded into state and shared among components.
      */
      dispatch(timeSeriesInitialise(data));
      /*
        END TEMP
      */
      dispatch(timeSeriesDetailsLoadComplete(data));
      dispatch(timeSeriesDetailsDerivationKeysLoad());
      dispatch(timeSeriesDetailsBasedOnLoad());
      dispatch(timeSeriesDetailsCategoriesLoad());
      dispatch(timeSeriesDetailsAnnotationsLoad());

      /*
        TEMP: To be removed once range based adjustments are enabled everywhere
      */
      if (!(data && data.features && data.features.rangeBasedAdjustments))
        dispatch(timeSeriesDetailsAdjustmentsLoad());
      /*
        END TEMP
      */

      if (!doNotLoadComposition && data)
        dispatch(timeSeriesDetailsCompositionLoad(data.id));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_LOAD_COMPLETE = 'TIMESERIES_DETAILS_LOAD_COMPLETE';
export const timeSeriesDetailsLoadComplete = createAction(TIMESERIES_DETAILS_LOAD_COMPLETE, 'data');

export const TIMESERIES_DETAILS_COMPOSITION_LOAD_STARTED = 'TIMESERIES_DETAILS_COMPOSITION_LOAD_STARTED';
export const timeSeriesDetailsCompositionLoadStarted = createAction(TIMESERIES_DETAILS_COMPOSITION_LOAD_STARTED);

export const timeSeriesDetailsCompositionLoad = (key) => (dispatch, getState) => {
  if (!key) return;

  return authFetch(`${WORKSPACES_API_URL}/timeseriescomposition/full?id=${key}`)
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      dispatch(timeSeriesDetailsCompositionLoadComplete(data));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_COMPOSITION_LOAD_COMPLETE = 'TIMESERIES_DETAILS_COMPOSITION_LOAD_COMPLETE';
export const timeSeriesDetailsCompositionLoadComplete = createAction(TIMESERIES_DETAILS_COMPOSITION_LOAD_COMPLETE, 'data');

export const TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_STARTED = 'TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_STARTED';
export const timeSeriesDetailsLoadLookupDataStarted = createAction(TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_STARTED);

export const timeSeriesDetailsLoadLookupData = () => (dispatch, getState) => {
  dispatch(timeSeriesDetailsLoadLookupDataStarted());

  return authFetch(`${WORKSPACES_API_URL}/searchfacets/values?facets=source&facets=unit`)
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      dispatch(timeSeriesDetailsLoadLookupDataComplete(data));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_CATEGORIES_LOAD_STARTED = 'TIMESERIES_DETAILS_CATEGORIES_LOAD_STARTED';
export const timeSeriesDetailsCategoriesLoadStarted = createAction(TIMESERIES_DETAILS_CATEGORIES_LOAD_STARTED);

export const timeSeriesDetailsCategoriesLoad = () => (dispatch, getState) => {
  dispatch(timeSeriesDetailsCategoriesLoadStarted());

  return authFetch(`${WORKSPACES_API_URL}/searchfacets/all`)
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      const categories = data.map(c => { return { key: c.toLowerCase(), mandatory: false }; });
      dispatch(timeSeriesDetailsCategoriesLoadComplete(categories));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_CATEGORIES_LOAD_COMPLETE = 'TIMESERIES_DETAILS_CATEGORIES_LOAD_COMPLETE';
export const timeSeriesDetailsCategoriesLoadComplete = createAction(TIMESERIES_DETAILS_CATEGORIES_LOAD_COMPLETE, 'data');

export const TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_COMPLETE = 'TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_COMPLETE';
export const timeSeriesDetailsLoadLookupDataComplete = createAction(TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_COMPLETE, 'data');

export const TIMESERIES_DETAILS_UPDATE_VALUE = 'TIMESERIES_DETAILS_UPDATE_VALUE';
export const timeSeriesDetailsUpdateValue = createAction(TIMESERIES_DETAILS_UPDATE_VALUE, 'keyPath', 'value');

export const TIMESERIES_DETAILS_UPDATE_OBJECT = 'TIMESERIES_DETAILS_UPDATE_OBJECT';
export const timeSeriesDetailsUpdateObject = createAction(TIMESERIES_DETAILS_UPDATE_OBJECT, 'data');

export const timeSeriesDetailsSave = () => (dispatch, getState) => {
  dispatch(timeSeriesDetailsDerivationKeysSave());

  const state = getState();
  const timeSeries = toJS(state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries']), {});
  const currentTimeSeriesId = state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']);

  // if derivation is single step clear the functions collection
  if (timeSeries.derivationData && timeSeries.derivationData.isMultiStepFunctions === false)
    timeSeries.derivationData.functions = [];

  // remove isNew flag on functions
  if (timeSeries.derivationData.functions) {
    timeSeries.derivationData.functions.forEach(f => {
      delete f.isNew;
    });
  }

  // only save categories with a value
  if (timeSeries.categories) Object.keys(timeSeries.categories).forEach(k => {
    const categoryValue = timeSeries.categories[k];
    if (!categoryValue) delete timeSeries.categories[k];
  });

  return authFetch(`${WORKSPACES_API_URL}/timeseries`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(timeSeries, (key, value) => value !== null ? value : undefined)
  })
    .then(response => response.json())
    .then(data => initialiseTimeSeries(data))
    .then(data => {
      /*
        TEMP: Pass updated time series data into shared area of state.
        Time series should be loaded into state and shared among components.
      */
      dispatch(timeSeriesInitialise(data));
      /*
        END TEMP
      */
      dispatch(logInfoNotification('Time series saved'));
      dispatch(timeSeriesDetailsSaveComplete(data));

      if (currentTimeSeriesId === 0 || currentTimeSeriesId === 'new' || /* completely new */ timeSeries.id === 0 /* or a clone */) {
        let url = `/timeseries/${data.id}`;
        const pathname = window.location.pathname;
        if (pathname.indexOf('/annotations') >= 0) url += '/annotations';
        else if (pathname.indexOf('/composition') >= 0) url += '/composition';
        dispatch(push(url));
      }
      else {
        dispatch(timeSeriesDetailsLoadComplete(data));
        dispatch(timeSeriesDetailsDerivationKeysLoad());
        dispatch(timeSeriesDetailsBasedOnLoad());
        dispatch(timeSeriesDetailsCategoriesLoad());
        dispatch(timeSeriesDetailsAnnotationsLoad());
        /*
          TEMP: To be removed once range based adjustments are enabled everywhere
        */
        if (!(data && data.features && data.features.rangeBasedAdjustments))
          dispatch(timeSeriesDetailsAdjustmentsLoad());
        /*
          END TEMP
        */
        dispatch(timeSeriesDetailsCompositionLoad(currentTimeSeriesId));
      }
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_SAVE_COMPLETE = 'TIMESERIES_DETAILS_SAVE_COMPLETE';
export const timeSeriesDetailsSaveComplete = createAction(TIMESERIES_DETAILS_SAVE_COMPLETE, 'data');

export const TIMESERIES_DETAILS_SET_CURRENT_ID = 'TIMESERIES_DETAILS_SET_CURRENT_ID';
export const timeSeriesDetailsSetCurrentId = createAction(TIMESERIES_DETAILS_SET_CURRENT_ID, 'key');

export const TIMESERIES_DETAILS_SET_VIEW = 'TIMESERIES_DETAILS_SET_VIEW';
export const timeSeriesDetailsSetView = createAction(TIMESERIES_DETAILS_SET_VIEW, 'view');

export const TIMESERIES_DETAILS_TOGGLE_EDITOR_COLLAPSE = 'TIMESERIES_DETAILS_TOGGLE_EDITOR_COLLAPSE';
export const timeSeriesDetailsToggleEditorCollapse = createAction(TIMESERIES_DETAILS_TOGGLE_EDITOR_COLLAPSE);

export const TIMESERIES_DETAILS_TOGGLE_SIDEBAR_COLLAPSE = 'TIMESERIES_DETAILS_TOGGLE_SIDEBAR_COLLAPSE';
export const timeSeriesDetailsToggleSideBarCollapse = createAction(TIMESERIES_DETAILS_TOGGLE_SIDEBAR_COLLAPSE);

export const TIMESERIES_DETAILS_SET_FOCUS = 'TIMESERIES_DETAILS_SET_FOCUS';
export const timeSeriesDetailsSetFocus = createAction(TIMESERIES_DETAILS_SET_FOCUS, 'id');

export const TIMESERIES_DETAILS_SET_HAS_FOCUSED = 'TIMESERIES_DETAILS_SET_HAS_FOCUSED';
export const timeSeriesDetailsSetHasFocus = createAction(TIMESERIES_DETAILS_SET_HAS_FOCUSED);

export const timeSeriesDetailsCloneTimeSeries = () => (dispatch, getState) => {
  const state = getState();
  const data = toJS(state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries']), {});

  const clone = {
    ...data,
    id: 0,
    businessKey: null,
    name: `Clone of ${data.name}`,
    sourceId: ''
  };
  dispatch(timeSeriesDetailsCloneTimeSeriesComplete(clone));
  /*
    TEMP: Pass time series data into shared area of state.
    Time series should be loaded into state and shared among components.
  */
  dispatch(timeSeriesClear());
  /*
    END TEMP
  */
};

export const TIMESERIES_DETAILS_COPY_LINK_TO_CLIPBOARD = 'TIMESERIES_DETAILS_COPY_LINK_TO_CLIPBOARD';
export const timeSeriesDetailsCopyLinkToClipboard = createAction(TIMESERIES_DETAILS_COPY_LINK_TO_CLIPBOARD);

export const timeSeriesDetailsSaveOnDemand = (key) => (dispatch, getState) => {
  const state = getState();
  const data = toJS(state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries']), {});
  if (data.derivationData && !data.derivationData.isMultiStepFunctions){
    data.derivationData.functions = [];
    delete data.derivationData.isMultiStepFunctions;
  }
  dispatch(timeSeriesDetailsSaveOnDemandComplete(key, data))
};

export const TIMESERIES_DETAILS_SAVE_ON_DEMAND_DERIVED_COMPLETE = 'TIMESERIES_DETAILS_SAVE_ON_DEMAND_DERIVED_COMPLETE';
export const timeSeriesDetailsSaveOnDemandComplete = createAction(TIMESERIES_DETAILS_SAVE_ON_DEMAND_DERIVED_COMPLETE, 'key', 'data');

export const timeSeriesDetailsCopyOnDemandToNewTimeSeries = () => (dispatch, getState) => {
  const state = getState();

  const userSettingTimeSeries = toJS(state.getIn(['userSettings', 'settings', 'timeSeries']), {});
  const newTimeSeries = createEmptyTimeseries(userSettingTimeSeries);
  const derivationData = toJS(state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'derivationData'])) ?? createEmptyDerivationData(userSettingTimeSeries);

  // convert identity input keys to an array of input keys
  if (derivationData.keys)
    derivationData.keys = derivationData.keys.filter(k => k.type === 'Identity').map(k => k.key);

  newTimeSeries.style = 'Derived';
  newTimeSeries.name = `${derivationData.name}`;
  newTimeSeries.unit = derivationData.unit;
  newTimeSeries.sourceTimeZoneId = derivationData.sourceTimeZoneId;
  newTimeSeries.granularity = derivationData.granularity;
  newTimeSeries.derivationData = derivationData;

  delete newTimeSeries.derivationData.id;
  delete newTimeSeries.derivationData.name;
  delete newTimeSeries.derivationData.unit;
  delete newTimeSeries.derivationData.sourceTimeZoneId;
  delete newTimeSeries.derivationData.granularity;

  dispatch(timeSeriesDetailsInitialiseInputKeyStyle('default'));
  dispatch(timeSeriesDetailsCloneTimeSeriesComplete(newTimeSeries));
};

function createEmptyForecastData() {
  return {
    baseIdentityId: -1,
    asAtType: 'None',
    asAt: {
      asAt: null,
      forecastOffsetLens: 'Day',
      forecastOffset: null,
      relativeAsAtDate: null,
      cutOffTime: null,
      forecastWindowData: {
        asAtSelectionOperation: 'Close',
        asAtWindowRelativeToDataPoint: true,
        asAtWindow: {
          relFromDate: '-14D',
          relToDate: '1D',
        },
        strictCountForOperation: true,
        fillForwardAsAt: true,
        dataPointOperation: 'Avg' 
      }
    }
  };
}

function createEmptyEvolutionData() {
  return {
    baseIdentityId: -1,
    asAtSelectionOperation: 'Close',
    dataPointOperation: 'Avg',
    dataPointWindowRelativeToDataPoint: true,
    dataPointWindow: {
      relFromDate: '-14D',
      relToDate: '1D',
    }    
  };
}

function createEmptyDerivationData(userDefaults = {}) {
  let derivationData = {
    keys: [],
    searchRequest: {},
    functionData: {
      parameters: {}
    },
    evolutionSeriesData: createEmptyEvolutionData(),
    forecastSeriesData: createEmptyForecastData(),         
    functions: [],
    materialise: false,
    dataQueryStart: null,
    dataQueryEnd: null,
    isMultiStepFunctions: false
  };

  if (userDefaults) {
    if (userDefaults.derivationData) {
      if (userDefaults.derivationData.functionData) {
        if (userDefaults.derivationData.functionData.function) derivationData.functionData.function = userDefaults.derivationData.functionData.function;
        if (userDefaults.derivationData.functionData.inputLens) derivationData.functionData.inputLens = userDefaults.derivationData.functionData.inputLens;
        if (userDefaults.derivationData.functionData.outputLens) derivationData.functionData.outputLens = userDefaults.derivationData.functionData.outputLens;
        if (userDefaults.derivationData.functionData.parameters) derivationData.functionData.parameters = userDefaults.derivationData.functionData.parameters;
      }
    }
  }

  return derivationData;
}

function createEmptyTimeseries(userDefaults = {}) {
  let data = {
    isDirty: false,
    id: 0,
    source: '',
    sourceTimeZoneId: 'UTC',
    style: 'Forecast',
    instanceStyle: 'Default',
    derivationData: createEmptyDerivationData(userDefaults),
    granularity: {
      granularityType: 'Day',
      granularityFrequency: 1
    },
    categories: {}
  };

  if (userDefaults) {
    if (userDefaults.source) data.source = userDefaults.source;
    if (userDefaults.sourceTimeZoneId) data.sourceTimeZoneId = userDefaults.sourceTimeZoneId;
    if (userDefaults.style) data.style = userDefaults.style;
    if (userDefaults.dataType) data.dataType = userDefaults.dataType;
    if (userDefaults.unit) data.unit = userDefaults.unit;
    if (userDefaults.granularity) {
      if (userDefaults.granularity.granularityType) data.granularity.granularityType = userDefaults.granularity.granularityType;
      if (userDefaults.granularity.granularityFrequency) data.granularity.granularityFrequency = userDefaults.granularity.granularityFrequency;
    }
    if (userDefaults.categories) data.categories = userDefaults.categories;
  }

  return data;
}

function initialiseTimeSeries(data) {
  if (data) {
    data.derivationData = Object.assign(createEmptyDerivationData(), data.derivationData || {});
    data.derivationData.isMultiStepFunctions = data.derivationData && data.derivationData.functions && data.derivationData.functions.length > 0;
  }

  return data;
}