import React from 'react';

export default function SchemasToolbar({ availableSchemas, selectedSchema, addSchema, setSelectedSchema }) {
  return (
    <div className='row no-gutters fota-compact-form'>
      <div className='col-3 mb-0 mr-2'>
        <div className='input-group w-100'>
          <select className='form-control'
            value={selectedSchema}
            onChange={e => setSelectedSchema(e.target.value)}>
            {availableSchemas.map(x => <option key={x.id ?? '_blank'} value={x.name}>{x.name}</option>)}
          </select>
          <div className='input-group-append'>
            <button type='button' className='btn btn-primary' onClick={() => addSchema()}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}