import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { formatTitle } from '../../../utility/analysis-search-utility';
import { SchemaFacetItem } from './SchemaFacetItem';
import { getToggleAllCheckStyle } from '../../../utility/selection-utility';

export class SchemaFacetItems extends Component {
  static propTypes = {
    isMultiSelect: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    schemaName: PropTypes.string.isRequired,
    schemaColour: PropTypes.string.isRequired,
    facet: PropTypes.shape({
      results: PropTypes.array.isRequired,
      key: PropTypes.string.isRequired
    }).isRequired,

    toggleSchemaFacetValue: PropTypes.func.isRequired,
    toggleSchemaSelection: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  onToggleSchemaFacetValue(e) {
    e.preventDefault();
    const { isMultiSelect, schemaName, toggleSchemaFacetValue } = this.props;
    const { dataset } = e.target;
    if (dataset.facetkey && dataset.value !== undefined) {
      toggleSchemaFacetValue(schemaName, dataset.facetkey, dataset.value, isMultiSelect);
    }
  }

  render() {
    const { isMultiSelect, isExpanded, facet, toggleSchemaExpansion, toggleSchemaSelection, schemaName, schemaColour } = this.props;

    let { selectedCount, selectionIndicator } = getToggleAllCheckStyle(facet.results, r => r.isSelected);
    if (selectedCount === 0 && !isExpanded) selectionIndicator = '';

    return <ul data_id={this.props.data_id} className='facet-group list-group mr-2' title={facet.key}>
      {!isMultiSelect && <li onClick={() => toggleSchemaExpansion(schemaName)} className='list-group-item list-group-header text-nowrap d-flex justify-content-between align-items-center' style={{ backgroundColor: schemaColour, color: 'White' }}>
        <span data_id='facet-name' style={{ opacity: 0.3 }} ><i className={`fas ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'} fa-fw`} /></span>
        {formatTitle(facet.key)}
        <span></span>
      </li>}

      {isMultiSelect && <li className='list-group-item list-group-header text-nowrap d-flex justify-content-between align-items-center' style={{ backgroundColor: schemaColour, color: 'White' }}>
        <div className='w-100 d-flex justify-content-between align-items-center' style={{ cursor: 'default' }} onClick={() => toggleSchemaExpansion(schemaName)}>
          <span style={{ opacity: 0.3 }} ><i className={`fas ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'} fa-fw`} /></span>
          <span data_id='name'>{formatTitle(facet.key)}</span>
          <span></span>
        </div>
        <span style={{ cursor: 'pointer', width: '15px' }} onClick={() => toggleSchemaSelection(schemaName, facet.key)}><i className={`${selectionIndicator} fa-lg`}
          title={selectedCount > 0 ? `${selectedCount} facet(s) selected` : 'No facets selected'} /></span>
      </li>}

      {isExpanded && <ul className='list-group' style={{ display: '' }} onClick={e => this.onToggleSchemaFacetValue(e)}>
        {facet.results.map((result, i) => <SchemaFacetItem data_id={`facet-item-${i}`} key={`f${i}`} result={result}
          isMultiSelect={isMultiSelect}
          schemaColour={schemaColour} facetKey={facet.key} ></SchemaFacetItem>)}
      </ul>}
    </ul>
  }
}
