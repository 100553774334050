import moment from 'moment';
import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
  ETL_JOB_FETCH_COMPLETE,
  ETL_JOB_FETCH_STARTED,
  ETL_JOB_FETCH_STOPPED,

  ETL_JOB_RUN_FETCH_STARTED,
  ETL_JOB_RUN_FETCH_COMPLETE
} from '../actions/etlJob';

function byTimestamp(a,b){
  const dateA = moment.utc(a.timestamp).toDate();
  const dateB = moment.utc(b.timestamp).toDate();

  return dateA - dateB;
}
export const etlJob = createReducer(null, {
  [ETL_JOB_FETCH_STARTED](state, action) {
    return state.set('isLoading', true)
      .set('job', fromJS({}))
      .set('lastRun', fromJS({}))
      .set('lastRunInformation', fromJS([]))
      .set('lastRunWarning', fromJS([]))
      .set('lastRunError', fromJS([]))
      .set('nextRun', fromJS({}));
  },
  [ETL_JOB_FETCH_STOPPED](state) {
    return state.set('isLoading', false);
  },
  [ETL_JOB_FETCH_COMPLETE](state, action) {

    let lastRun = action.data.lastRun;
    let nextRun = action.data.nextRun;

    if (lastRun) {
      if (lastRun.startedAtUtc) {
        lastRun.startedAtUtc = moment.utc(lastRun.startedAtUtc);
      }
      if (lastRun.stoppedAtUtc) {
        lastRun.stoppedAtUtc = moment.utc(lastRun.stoppedAtUtc);
      }
    } else {
      lastRun = {};
    }

    let lastRunDataItems = lastRun && lastRun.runData ? lastRun.runData.items : [];

    if (nextRun) {
      if (nextRun.nextRunUtc) {
        nextRun.nextRunUtc = nextRun.nextRunUtc = moment.utc(nextRun.nextRunUtc);
      }
    } else {
      nextRun = {}
    }

    let lastSuccessAtUtc = null;
    if(action.data.lastSuccessAtUtc) {
      lastSuccessAtUtc = moment.utc(action.data.lastSuccessAtUtc);
    }

    return state.set('isLoading', false)
      .set('lastRefreshed', moment())
      .set('job', fromJS(action.data.job))
      .set('lastRun', fromJS(lastRun))
      .set('lastRunInformation', fromJS(lastRunDataItems.filter(x => x.status === 'Info').sort(byTimestamp)))
      .set('lastRunWarning', fromJS(lastRunDataItems.filter(x => x.status === 'Warning').sort(byTimestamp)))
      .set('lastRunError', fromJS(lastRunDataItems.filter(x => x.status === 'Error').sort(byTimestamp)))
      .set('nextRun', fromJS(nextRun))
      .set('lastSuccessAtUtc', lastSuccessAtUtc);
  },
  [ETL_JOB_RUN_FETCH_STARTED](state, action) {
    return state.set('isLoadingJobRun', true);
  },
  [ETL_JOB_RUN_FETCH_COMPLETE](state, action) {
    let jobRun = action.data ?? {};
    if (jobRun) {
      if (jobRun.startedAtUtc)
        jobRun.startedAtUtc = moment.utc(jobRun.startedAtUtc);
  
        if (jobRun.stoppedAtUtc)
        jobRun.stoppedAtUtc = moment.utc(jobRun.stoppedAtUtc);
    }

    const { items = [] } = jobRun.runData ?? {};
    return state.set('isLoadingJobRun', false)
      .set('lastRun', fromJS(jobRun))
      .set('lastRunInformation', fromJS(items.filter(x => x.status === 'Info').sort(byTimestamp)))
      .set('lastRunWarning', fromJS(items.filter(x => x.status === 'Warning').sort(byTimestamp)))
      .set('lastRunError', fromJS(items.filter(x => x.status === 'Error').sort(byTimestamp)))
  }
});