import React, { useMemo, useCallback, useRef, useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import moment from 'moment';
import qs from 'querystring';
import CollapsePanel from '../../shared/CollapsePanel';
import LoadingIcon from '../../shared/LoadingIcon';
import PathNavLink from '../../shared/PathNavLink';
import RelativeDate from '../../shared/RelativeDate';
import Multiselect from 'react-widgets/Multiselect';
import {
  reportsFilterEnabled,
  reportsFilterValueEnabled,
  reportsReload,
  reportsSnapshotsRefresh,
  reportsExcelExport,
  reportsUpdateCriteriaProperty,
  reportsUpdateMode,
  reportsSnapshotsCreate
} from '../../../actions/reports';
import { ClipboardToolbar } from './ClipboardToolbar';
import { AdjustmentsToolbar } from './AdjustmentsToolbar';

function ReportCheckbox({ parentIndex, index, filter, onValueChanged }) {
  const _elementId = useMemo(() => `is-enabled-${parentIndex}-${index}`, [parentIndex, index]);

  const onChange = useCallback(() => onValueChanged(parentIndex, index, !filter.enabled), [onValueChanged, parentIndex, index, filter.enabled]);

  return (
    <div className='custom-control custom-checkbox'>
      <input id={ _elementId } type='checkbox'
             className='custom-control-input'
             checked={ !!filter.enabled }
             onChange={ onChange } />
      <label className='custom-control-label user-select-none' htmlFor={ _elementId }>{ filter.value }</label>
    </div>
  );
}

function ReportFilter({ index, filter, onEnabledChanged, onValueChanged }) {
  const _elementId = useMemo(() => `is-enabled-${index}`, [index]);

  const onChange = useCallback(() => onEnabledChanged(index, !filter.enabled), [onEnabledChanged, index, filter.enabled]);

  return (
    <div className='custom-control custom-checkbox mr-3'>
      <input id={ _elementId } type='checkbox'
             className='custom-control-input'
             checked={ !!filter.enabled }
             onChange={ onChange } />
      <label className='custom-control-label font-weight-bold user-select-none' htmlFor={ _elementId }>{ filter.label }</label>
      <div className='d-flex flex-column mt-1'>
        { filter.values && filter.values.map((v, i) => <ReportCheckbox key={ `${index}-${i}` } parentIndex={index} index={i} filter={v} onValueChanged={onValueChanged} />) }
      </div>
    </div>
  );
}

function CollapsePanelHeader(props) {
  const [backgroundColor, setBackgroundColor] = useState('#fff');

  const _style = useMemo(() => props.style || {}, [props.style]);
  const _groupingsLabel = useMemo(() => props.groupings.length ? `Groupings (${props.groupings.length})` : '', [props.groupings]);
  const _filtersLabel = useMemo(() => {
    const enabledFilters = props.filters.reduce((accumulator, item) => {
      if (!item.enabled || !item.values) return accumulator;

      return [
        ...accumulator, ...item.values.filter(i => i.enabled)
      ];
    }, []);

    return enabledFilters.length ? `Filters (${enabledFilters.length})` : ''
  }, [props.filters]);

  return (
    <div { ...props } style={{ ..._style, backgroundColor }}
         onMouseEnter={ () => setBackgroundColor('#33333333') }
         onMouseLeave={ () => setBackgroundColor('#fff') }>
         { _filtersLabel && <span className='font-weight-normal float-right mr-1'>{ _filtersLabel }</span> }
         { _groupingsLabel && <span className='font-weight-normal float-right mr-1'>{ _groupingsLabel }</span> }
         <span>{props.groupings.length ? 'Grouping and Filters' : 'Filters'}</span>
    </div>
  )
}

const ReportsToolbar = connect(
  (state) => ({
    isContributor: state.getIn(['roles', 'isContributor']),
    isExporting: state.getIn(['reports', 'isExporting']),
    lenses: state.getIn(['reports', 'settings', 'lenses']),
    timeZones: state.getIn(['reports', 'settings', 'timeZones']),    
    reportPath: state.getIn(['reports', 'criteria', 'reportPath']),
    reportScope: state.getIn(['reports', 'criteria', 'reportScope']),
    lens: state.getIn(['reports', 'criteria', 'lens']),
    fromDate: state.getIn(['reports', 'criteria', 'fromDate']),
    toDate: state.getIn(['reports', 'criteria', 'toDate']),
    timeZoneId: state.getIn(['reports', 'criteria', 'timeZoneId']),
    asAt: state.getIn(['reports', 'criteria', 'asAt']),
    mode: state.getIn(['reports', 'criteria', 'mode']),
    isDirty: state.getIn(['reports', 'criteria', 'isDirty']),
    relativeAsAt: state.getIn(['reports', 'criteria', 'relativeAsAt']),
    snapshotAsAt: state.getIn(['reports', 'criteria', 'snapshotAsAt']),
    snapshotRelativeDate: state.getIn(['reports', 'criteria', 'snapshotRelativeDate']),
    snapshotLookupDate: state.getIn(['reports', 'criteria', 'snapshotLookupDate']),
    snapshotLookupCount: state.getIn(['reports', 'criteria', 'snapshotLookupCount']),
    availableGroupings: state.getIn(['reports', 'criteria', 'availableGroupings']),
    reportingModes: state.getIn(['reports', 'settings', 'reportingModes']),
    groupings: state.getIn(['reports', 'criteria', 'groupings']),
    filters: state.getIn(['reports', 'criteria', 'filters']),
    isSnapshotsCreating: state.getIn(['reports', 'snapshots', 'isCreating']),
    isSnapshotsLoading: state.getIn(['reports', 'snapshots', 'isLoading']),
    snapshots: state.getIn(['reports', 'snapshots', 'data']),
    scenarioMode: state.getIn(['reports', 'results', 'settings', 'scenarioMode']),
    shapeName: state.getIn(['reports', 'criteria', 'shapeName']),
    conversionUnit: state.getIn(['reports', 'criteria', 'conversionUnit']),
    displayShapeDropdown: state.getIn(['reports', 'results', 'settings', 'shapeOptions', 'displayShapeDropdown']),
    availableShapes: state.getIn(['reports', 'results', 'settings', 'shapeOptions', 'availableShapes']),
    displayUnitDropdown: state.getIn(['reports', 'results', 'settings', 'unitOptions', 'displayUnitDropdown']),
    availableUnits: state.getIn(['reports', 'results', 'settings', 'unitOptions', 'availableUnits']),
  }),
  (dispatch) => ({
    filterChanged(index, enabled) {
      dispatch(reportsFilterEnabled(index, enabled));
    },
    filterValueChanged(parentIndex, index, enabled) {
      dispatch(reportsFilterValueEnabled(parentIndex, index, enabled));
    },
    updateMode(value) {
      dispatch(reportsUpdateMode(value));
    },
    updateProperty(key, value) {
      dispatch(reportsUpdateCriteriaProperty(key, value));
    },
    updateSnapshotImpactingProperty(key, value) {
      dispatch(reportsUpdateCriteriaProperty(key, value));

      dispatch(reportsUpdateCriteriaProperty('snapshotAsAt', ''));
      dispatch(reportsSnapshotsRefresh());
    },
    refreshSnapshots() {
      dispatch(reportsSnapshotsRefresh());
    },
    refresh() {
      dispatch(reportsReload());
    },
    excelExport() {
      dispatch(reportsExcelExport());
    },
    createSnapshot() {
      dispatch(reportsSnapshotsCreate());
    }    
  })
)(({ isContributor, isDirty, lenses, timeZones, reportPath, reportScope, lens, fromDate, toDate, timeZoneId, asAt, mode, relativeAsAt, snapshotAsAt, snapshotRelativeDate, snapshotLookupDate, snapshotLookupCount, reportingModes,
  availableGroupings, groupings, filters, scenarioMode, refresh, refreshSnapshots, excelExport, createSnapshot, isExporting, updateProperty, updateMode, updateSnapshotImpactingProperty, filterChanged,
  filterValueChanged, isSnapshotsCreating, isSnapshotsLoading, snapshots,
  shapeName, displayShapeDropdown, availableShapes, conversionUnit, displayUnitDropdown, availableUnits
 }) => {
    const [shareClicked, setShareClicked] = useState(false);

    const snapshotLookupDateRef = useRef();

    const _availableGroupings = useMemo(() => toJS(availableGroupings, []), [availableGroupings]);
    const _groupings = useMemo(() => toJS(groupings, []), [groupings]);
    const _filters = useMemo(() => toJS(filters, []), [filters]);
    const _snapshots = useMemo(() => toJS(snapshots, []), [snapshots]);
    const _lenses = useMemo(() => toJS(lenses, []), [lenses]);

    const _shareUrl = useMemo(() => {
      if (reportScope !== 'Shared')
        return;

      let criteria = {};
      let filters = _filters.filter(f => f.enabled)
                            .map(f => `in(${f.category},'${f.values.filter(y => y.enabled)
                                                                   .map(f => f.value)
                                                                   .join(',')}')`);

      Object.entries({ lens, fromDate, toDate, timeZoneId, mode, asAt, groupings: _groupings, filters }).forEach(([key, value]) => {
        if (value && !(key === 'mode' && value.includes('Snapshot'))) criteria[key] = value;
      });

      return `${window.location.origin}/reports/${reportPath.replace(/\s/g, '%20')}?${qs.stringify(criteria)}`;
    }, [reportPath, reportScope, lens, mode, fromDate, toDate, timeZoneId, asAt, _groupings, _filters]);

    const share = useCallback(() => {
      if (!_shareUrl) return;

      let element = document.createElement('textarea');

      element.value = _shareUrl;
      element.setAttribute('readonly', '');
      element.style.position = 'absolute';
      element.style.left = '-9999px';
      document.body.appendChild(element);
      element.select();
      document.execCommand('copy');
      document.body.removeChild(element);

      setShareClicked(true);
      setTimeout(() => setShareClicked(false), 750);
    }, [_shareUrl]);

  const _isDateTimePicker = useMemo(() => lens === 'QuarterHour' || lens === 'HalfHour' || lens === 'Hour', [lens]);

  const getDateTime = useCallback(value => {
    const dateTime = moment.utc(value);

    return dateTime.isValid() ? dateTime.format(_isDateTimePicker ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD') : value;
  }, [_isDateTimePicker]);

  const _fromDate = useMemo(() => getDateTime(fromDate), [fromDate, getDateTime]);
  const _toDate = useMemo(() => getDateTime(toDate), [toDate, getDateTime]);

  const showRelativeAsAt = mode === 'AsAtDeltaRelative';
  const showSnapshot = mode === 'SnapshotDelta' || mode === 'Snapshot';
  const showSnaphotRelativeDate = mode === 'SnapshotDeltaRelative';
  
  const showScenarioComposition = scenarioMode === 'Enabled';

  function onSubmit(e) {
    e.preventDefault();
    refresh();
  }

  const [adjustSnapshotLookup, setAdjustSnapshotLookup] = useState(false);

  const modeLookup = {
    'AsAt': 'As at',
    'AsAtDelta': 'As at delta',
    'AsAtDeltaRelative': 'As at delta (relative)',
    'Snapshot' : 'Snapshot',
    'SnapshotDelta' : 'Delta from snapshot',
    'SnapshotDeltaRelative' : 'Delta vs. date'
  };

  const showMode = mode && mode !== 'Default' && mode !== '';
  
  function SnapshotLabel() {
    if (!adjustSnapshotLookup)
      return 'Snapshots';

    const lookupCount = snapshotLookupCount || 50;
    const lookupDate = snapshotLookupDate
      ? moment.utc(snapshotLookupDate).format('DD MMM YYYY')
      : 'Today';

    return `Last ${lookupCount} snapshots before ${lookupDate}`;
  }

  function onAdjustSnapshotToggle() {
    if (adjustSnapshotLookup) {
      updateProperty('snapshotLookupDate', '');
      updateProperty('snapshotLookupCount', 50);
      return;
    }

    setAdjustSnapshotLookup(true);
  }

  function onAdjustSnapshotConfirm(e) {
    if (e.hasOwnProperty('keyCode') && e.keyCode !== 13)
      return;
    else
      e.preventDefault();

    setAdjustSnapshotLookup(false);
    refreshSnapshots();
  }

  useLayoutEffect(() => {
    if (adjustSnapshotLookup && snapshotLookupDateRef.current)
      snapshotLookupDateRef.current.focus();
  }, [adjustSnapshotLookup])

  return <>
    <form name='reportToolbar' className='mt-1' noValidate onSubmit={ onSubmit }>
      <div className='form-row'>
        <div className='form-group' style={{ marginBottom: '.5em' }}>
          <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Lens</label>
          <select name='lens'
                  className='form-control form-control-sm'
                  value={ lens || '' }
                  onChange={ e => updateSnapshotImpactingProperty('lens', e.target.value) }>
            { _lenses.map(l => <option key={ `key-${l.lens}` } value={ l.lens }>{ l.lens }</option>) }
          </select>
        </div>

        <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
          <label className='font-weight-bold text-nowrap' style={{ fontSize: '.9em', marginBottom: 0 }}>From date</label>
          <input name='fromDate'
                 type={ _isDateTimePicker ? 'datetime-local' : 'date' } 
                 className='form-control form-control-sm rounded-right'
                 min={ _isDateTimePicker ? '0001-01-01T00:00' : ''}
                 max={ _isDateTimePicker ? '9999-12-31T23:59' : '9999-12-31'}
                 value={ _fromDate }
                 onChange={ e => updateProperty('fromDate', e.target.value) } />
        </div>

        <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
          <label className='font-weight-bold text-nowrap' style={{ fontSize: '.9em', marginBottom: 0 }}>To date</label>
          <input name='toDate'
                 type={ _isDateTimePicker ? 'datetime-local' : 'date' } 
                 className='form-control form-control-sm rounded-right'
                 min={ _isDateTimePicker ? '0001-01-01T00:00' : ''}
                 max={ _isDateTimePicker ? '9999-12-31T23:59' : '9999-12-31'}
                 value={ _toDate }
                 onChange={ e => updateProperty('toDate', e.target.value) } />
        </div>

        <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
          <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Timezone</label>
          <select name='timeZoneId'
                  className='form-control form-control-sm'
                  value={ timeZoneId || '' }
                  onChange={ e => updateSnapshotImpactingProperty('timeZoneId', e.target.value) }>
            { timeZones.map(tz => <option key={ tz } value={ tz }>{ tz }</option>) }
          </select>
        </div>

        { displayShapeDropdown && (
          <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Shape</label>
            <select name='shapeName'
                    className='form-control form-control-sm'
                    value={ shapeName }
                    onChange={ e => updateProperty('shapeName', e.target.value) }>
                      {availableShapes.map(s => <option key={ s } value={ s }>{s}</option>) }
            </select>
          </div>          
        )}

        { displayUnitDropdown && (
          <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Unit</label>
            <select name='conversionUnit'
                    className='form-control form-control-sm'
                    value={ conversionUnit }
                    onChange={ e => updateProperty('conversionUnit', e.target.value) }>
                      {availableUnits.map(s => <option key={ s } value={ s }>{s}</option>) }
            </select>
          </div>          
        )}

        { showMode && (
          <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Mode</label>
            <select name='reportingMode'
                    className='form-control form-control-sm'
                    value={ mode }
                    onChange={ e => updateMode(e.target.value) }>
                      {reportingModes.map(m => <option key={ m } value={ m }>{ modeLookup[m] }</option>) }
            </select>
          </div>
        )}
        
        { showSnapshot ?  (
          <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold text-nowrap' style={{ fontSize: '.9em', marginBottom: 0, width: '100%' }}>
              <SnapshotLabel />
            </label>
            <div className='custom-control pl-0'>
              <div className='input-group input-group-sm'>
                <div className='input-group-prepend'>
                  <button type='button' className={ `btn ${snapshotLookupDate ? 'btn-secondary' : 'btn-outline-secondary'} py-1 px-2` } title={ adjustSnapshotLookup ? 'Clear' : 'Adjust snapshots lookup' }
                          style={{ height: 'calc(1.5em + .5rem + 2px)' }}
                          onClick={ onAdjustSnapshotToggle }>
                    { adjustSnapshotLookup
                      ? <i className='fas fa-times fa-fw' />
                      : <i className='fas fa-filter fa-fw' /> }
                  </button>
                </div>
                { adjustSnapshotLookup ? (
                  <>
                    <input ref={ snapshotLookupDateRef } type='date' className='form-control form-control-sm' max='9999-12-31'
                          value={ snapshotLookupDate }
                          onChange={ e => updateProperty('snapshotLookupDate', e.target.value) }
                          onKeyDown={ onAdjustSnapshotConfirm }
                          style={{ minWidth: '180px', maxWidth: '180px' }} />
                    <input type='number' className='form-control form-control-sm' min='1' max='999'
                          value={ snapshotLookupCount }
                          onChange={ e => updateProperty('snapshotLookupCount', e.target.value) }
                          onKeyDown={ onAdjustSnapshotConfirm }
                          style={{ minWidth: '52px', maxWidth: '52px' }} />
                    <div className='input-group-append'>
                      <button type='button' className='btn btn-outline-primary' title='Confirm'
                              style={{ height: 'calc(1.5em + .5rem + 2px)' }}
                              onClick={ onAdjustSnapshotConfirm }>
                        <i className='fas fa-check fa-fw' />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    { isSnapshotsLoading ? (
                      <div className='py-1 px-2' style={{ minWidth: '180px', maxWidth: '180px' }}>
                        <LoadingIcon width='1.25em' height='1.25em' stroke='#333' /> Loading snapshots...
                      </div>
                    ) : (
                      <select name='snapshotAsAt'
                              className='form-control form-control-sm'
                              value={ snapshotAsAt || '' }
                              onChange={ e => updateProperty('snapshotAsAt', e.target.value) }
                              style={{ minWidth: '180px', maxWidth: '180px' }}>
                        <option value=''>None</option>
                        { _snapshots && _snapshots.map(({ label, value }) => <option key={ value } value={ value }>{ label }</option>) }
                      </select>
                    ) }
                    <div className='input-group-append'>
                      <button type='button' className='btn btn-outline-primary' title='Refresh snapshots'
                              style={{ height: 'calc(1.5em + .5rem + 2px)' }}
                              onClick={ refreshSnapshots } disabled={ isSnapshotsCreating }>
                        <i className='fas fa-sync fa-fw' />
                      </button>
                      <button type='button' className='btn btn-outline-primary text-nowrap' title='Create new snapshot'
                              style={{ height: 'calc(1.5em + .5rem + 2px)' }}
                              onClick={ createSnapshot } disabled={ isSnapshotsCreating }>
                        <i className='fas fa-camera fa-fw' />
                        <i className='fas fa-plus fa-fw' />
                      </button>
                    </div>
                  </>
                ) }
              </div>
            </div>
          </div>
        ) : showRelativeAsAt ? (
          <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold text-nowrap' style={{ fontSize: '.9em', marginBottom: 0 }}>Date</label>
            <RelativeDate name='relativeAsAt'
                          controlClassName='form-control form-control-sm'
                          value={relativeAsAt}
                          onChange={value => updateProperty('relativeAsAt', value)} />
          </div>
        ) : showSnaphotRelativeDate ? (
          <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
            <label className='font-weight-bold text-nowrap' style={{ fontSize: '.9em', marginBottom: 0 }}>Date</label>
            <RelativeDate name='snapshotRelativeDate'
                          controlClassName='form-control form-control-sm'
                          value={snapshotRelativeDate}
                          onChange={value => updateProperty('snapshotRelativeDate', value)} />
          </div>
        ) : showMode ? (
        <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
          <label className='font-weight-bold text-nowrap' style={{ fontSize: '.9em', marginBottom: 0 }}>As at</label>
          <input name='asAt'
                 className='form-control form-control-sm' 
                 type='datetime-local' 
                 min='0001-01-01T00:00' 
                 max='9999-12-31T23:59'
                 value={asAt}
                 onChange={e => updateProperty('asAt', e.target.value)} />
        </div>
        ) : <div/>}

        <div className='form-group ml-1 d-flex align-items-end' style={{ marginBottom: '.5em' }}>
          <div className='btn-group btn-group-sm'>
            <button name='refresh' data_id='refresh-report' className={`btn text-nowrap ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} type='submit'>
              <i className='fas fa-sync-alt fa-fw' />
              <span className='px-2 d-none d-md-inline-block'>Refresh</span>
            </button>
            <button title='Export to Excel' className='btn btn-outline-primary text-nowrap' type='button' onClick={ excelExport } disabled={ isExporting }>
              <i className='fas fa-file-excel fa-fw' />
              <span className='px-2 d-none d-md-inline-block'>Export</span>
              { isExporting && (
                <LoadingIcon width='1.25em' height='1.25em' stroke='#fff' style={{ marginTop: '-.2em' }} />
              ) }
            </button>
            { _shareUrl && (
              <button title='Share' className={ 'btn text-nowrap ' + (shareClicked ? 'btn-outline-secondary' : 'btn-outline-primary') } type='button' onClick={ share }>
                <i className='fas fa-share fa-fw' /> 
              </button>
            ) }

            <div className='btn-group btn-group-sm'>
              <button type='button' className='btn btn-outline-primary dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
              </button>
              { (isContributor || showScenarioComposition) &&<div className='dropdown-menu'>
                  {isContributor && <PathNavLink className='btn text-nowrap' to={ `/reports/${reportPath}/edit` }>
                    <span className='px-2 d-none d-md-inline-block'>Edit Report</span>
                  </PathNavLink>}

                  { showScenarioComposition && <PathNavLink className='btn text-nowrap' to={ `/reports/${reportPath}/comp` }>
                    <span className='px-2 d-none d-md-inline-block'>Edit Composition</span>
                  </PathNavLink>}
              </div>}
            </div>
          </div>
        </div>
     
        <div className='form-group ml-1 d-flex align-items-end' style={{ marginBottom: '.5em' }}>
          <ClipboardToolbar/>
        </div>
        <div className='form-group ml-1' style={{ marginBottom: '.5em' }}>
          <AdjustmentsToolbar/>
        </div>
        
        {/* <DebugReports /> */}
      </div>
    
      { (_filters.length > 0 || availableGroupings.length > 0) && (
        <>
          <div className='form-row' style={{ maxWidth: '1200px' }}>
            <div className='form-group col mb-2'>
              <CollapsePanel idKey='reportFilters' title={ <CollapsePanelHeader filters={ _filters } groupings={ _groupings } /> } disableClasses={ true } collapsed={ true }
                             headerStyle={{ textAlign: 'center', border: '1px solid #ccc', cursor: 'pointer', fontWeight: 'bold', userSelect: 'none' }}
                             bodyStyle={{ border: '1px solid #ccc', borderTopWidth: '0', padding: '.25em .5em', maxHeight: '20em', overflow: 'auto' }}>
                <div className='row no-gutters'>
                {_availableGroupings.length > 0 && < div className='col'>
                    <Multiselect className='user-select-none' data={ _availableGroupings } onChange={ value => updateProperty('groupings', value) } value={ _groupings } />
                  </div>}
                {_filters.length > 0 && <div className='col ml-md-2'>
                    <div className='d-flex'>
                      { _filters.map((f, i) => <ReportFilter key={ `${f.category}-${i}` } index={i} filter={ f } onEnabledChanged={filterChanged} onValueChanged={filterValueChanged} />) }
                    </div>
                  </div>} 
                </div>
              </CollapsePanel>
            </div>
          </div>
        </>
      ) }
    </form>
  </>
});

export default ReportsToolbar;
