import React from 'react';
import { parseTextToRelativeDate, relativeDateNames, lensedOptionsLookUp } from '../../utility/date-utility';

export function displayFormat(item) {
  const { type, value } = parseTextToRelativeDate(item.period);
  const suffix = item.cutoffTime === undefined || item.cutoffTime.length === 0 ? '' : `@${item.cutoffTime}`;
  switch (type) {
    case "H": return `H${value}${suffix}`;
    case "D": return `D${value}${suffix}`;
    case "W": return `W${value}${suffix}`;
    case "M": return `M${value}${suffix}`;
    case "Y": return `Y${value}${suffix}`;
    default: break;
  }
}

function toApiFormat(type, unit) {
  return `${unit}${type.substring(0, 1)}`;
}

const defaultOptions = ['H', 'D', 'W', 'M', 'Y'];

class RelativeDate extends React.Component {
  constructor(props) {
    super(props);
    const parsed = parseTextToRelativeDate(props.value ?? '0D');
    const lens = props.lens;
    this.state = {
      type: parsed.type?.substring(0, 1) ?? 'D',
      unit: parsed.value,
      options: ((lens && lens !== 'none') ? (lensedOptionsLookUp[lens] ?? lensedOptionsLookUp['Day']) : (this.options ?? defaultOptions))?.map(o => relativeDateNames[o]) ?? []
    }
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.value !== this.props.value) {
    //   const parsed = parseTextToRelativeDate(this.props.value);
    //   const lens = this.props.lens;
    //   this.state = {
    //     type: parsed.type?.substring(0, 1) ?? 'D',
    //     unit: parsed.value,
    //     options: ((lens && lens !== 'none') ? (lensedOptionsLookUp[lens] ?? lensedOptionsLookUp['Day']) : (this.options ?? defaultOptions))?.map(o => relativeDateNames[o]) ?? []
    //   }
    // }

    if (prevProps.lens !== this.props.lens) {
      const lens = this.props.lens;
      const options = ((lens && lens !== 'none') ? (lensedOptionsLookUp[lens] ?? lensedOptionsLookUp['Day']) : (this.options ?? defaultOptions))?.map(o => relativeDateNames[o]) ?? [];

      if (!this.state.options.find(o => o.short === this.state.type)) {
        let type = options[0].short;
        this.setState({ ...this.state, options: options, type: type });
        const newValue = toApiFormat(type, this.state.unit);
        if (newValue) this.props?.onChange(newValue);
      } else {
        this.setState({ ...this.state, options: options });
      }
    }
  }

  onChangeUnit(unit) {
    this.setState({ ...this.state, unit: unit });
    const newValue = toApiFormat(this.state.type, unit);
    if (newValue) this.props?.onChange(newValue);
  }

  onChangeType(type) {
    this.setState({ ...this.state, type: type });
    const newValue = toApiFormat(type, this.state.unit);
    if (newValue) this.props?.onChange(newValue);
  }

  render() {
    const { data_id, style = { display: 'flex' }, readOnly, className, controlClassName = 'form-control', isPastOnly = false } = this.props;

    return <div className={className} style={style}>
      <div className='input-group'>
        <input data_id={`${data_id}-value`} disabled={readOnly} className={`${controlClassName}`} style={{ textAlign: 'right', maxWidth: '5em' }} type='number' max={isPastOnly ? '0' : ''} placeholder='' autoComplete='off'
          value={this.state.unit} onChange={e => this.onChangeUnit(e.target.value)} />
        <select data_id={`${data_id}-type`} className={`${controlClassName} input-group-append`} disabled={readOnly || this.state.options.length === 1} value={this.state.type} onChange={e => this.onChangeType(e.target.value)}>
          {this.state.options.map(o => <option key={o.short} value={o.short}>{o.desc}</option>)}
        </select>
      </div>
    </div>
  }
}

export default RelativeDate;