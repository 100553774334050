import React from 'react';
import { connect } from 'react-redux';
import {
  analysisChartDataExport,
  analysisToggleTableDesignPanelVisibility,
  analysisToggleTableJsonEditorPanelVisibility,
  analysisAdjustmentsBeginEdit,
  analysisAdjustmentsEndEdit
} from '../../../actions/analysis';

export const TableToolbar = connect(
  (state) => ({
    isTableDesignPanelVisible: state.getIn(['analysis', 'ui', 'isTableDesignPanelVisible']),
    isTableJsonEditorPanelVisible: state.getIn(['analysis', 'ui', 'isTableJsonEditorPanelVisible']),
    tableSettings: state.getIn(['analysis', 'workspace', 'tableSettings']),
    isInlineAdjustmentsEditor: state.getIn(['roles', 'isInlineAdjustmentsEditor']),
    isEditing: state.getIn(['analysis', 'adjustments', 'isEditing']),
  }),
  (dispatch) => ({
    toggleTableDesignPanelVisibility(){
      dispatch(analysisToggleTableDesignPanelVisibility());
    },
    toggleTableJsonEditorPanelVisibility(){
      dispatch(analysisToggleTableJsonEditorPanelVisibility());
    },
    exportData() {
      dispatch(analysisChartDataExport());
    },
    beginEditMode(){
      dispatch(analysisAdjustmentsBeginEdit());     
    },
    endEditMode(){
      dispatch(analysisAdjustmentsEndEdit());
    },
  })
  )(({ toggleTableDesignPanelVisibility, isTableDesignPanelVisible, 
    toggleTableJsonEditorPanelVisibility, isTableJsonEditorPanelVisible,
    isInlineAdjustmentsEditor, isEditing, beginEditMode, endEditMode,
    exportData }) => {

  function onToggleDesignPanel() {
    if (isEditing){
      endEditMode();
    }

    toggleTableDesignPanelVisibility();
  }

  function onToggleJsonEditorPanel() {
    if (isEditing){
      endEditMode();
    }

    toggleTableJsonEditorPanelVisibility();
  }

  function onToggleAdjustmentMode() {
    if (!isEditing) {
      beginEditMode();
    } else {
      endEditMode();
    }
  }

  return (
    <div className='list-group'>

      <button title='Design' className={`no-focus list-group-item list-group-item-action ${isTableDesignPanelVisible ? 'bg-primary text-white' : ''}`} type='button' onClick={onToggleDesignPanel}>
        <i className='fas fa-pen fa-fw' />
      </button>

      <button title='Design' className={`no-focus list-group-item list-group-item-action ${isTableJsonEditorPanelVisible ? 'bg-primary text-white' : ''}`} type='button' onClick={onToggleJsonEditorPanel}>
        <i className='fas fa-file-code' />
      </button>

      {/* <button title='Export' className='no-focus list-group-item list-group-item-action' type='button' onClick={exportData}>
        <i className='fas fa-download fa-fw' />
      </button> */}

      {isInlineAdjustmentsEditor && <button title='Adjustments' className={`no-focus list-group-item list-group-item-action ${isEditing ? 'bg-primary text-white' : ''}`} type='button' onClick={onToggleAdjustmentMode}>
        <i className='fas fa-edit fa-fw' />
      </button>}

    </div>
  );
});