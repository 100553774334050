import React, { useEffect } from 'react';
import { toJS } from '../../../utility/immutable-utility';
import { connect } from 'react-redux';
import {
  dashboardTileAnalysisTableFromWorkspace
} from '../../../actions/dashboard-tiles-analysis-table';
import Loading from '../../shared/Loading';

const AnalysisTableTile = connect(
  (state, { stateKey }) => {
    const refreshRequired = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'refreshRequired']);
    const isBusy = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'isBusy']);
    // todo not sure what this is used for revisit
    // const layoutChanged = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'layoutChanged']);

    const workspacePath = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'workspacePath']);
    const rows = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'rows']);
    const styles = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'styles']);

    return {
      //layoutChanged,
      refreshRequired,
      isBusy,
      workspacePath,
      rows,
      styles
    };
  },
  (dispatch, { stateKey }) => ({
    refresh(workspacePath) {
      dispatch(dashboardTileAnalysisTableFromWorkspace({ stateKey, workspacePath }));
    }
  })
)(({ isBusy, refresh, refreshRequired, workspacePath, rows, styles }) => {
  useEffect(() => {
    if (refreshRequired)
      refresh(workspacePath);
  }, [refresh, refreshRequired, workspacePath]);

  const _rows = toJS(rows);
  const _styles = toJS(styles);

  return <Loading isLoading={isBusy} message='Loading...'>
   <table className='table table-xs'>
    <tbody>
      {_rows && _rows.map((x,xi) => {
        return <tr key={`r-${xi}`} style={_styles[x.style]}>{
          x.cells.map((y, yi) => <td key={`r-${yi}`} style={_styles[y.style]}>{y.value}</td>)
        }</tr>;
      })}
    </tbody>
   </table>   
  </Loading>
});

export default AnalysisTableTile;