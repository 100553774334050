import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import { toJS } from '../utility/immutable-utility';
import moment from 'moment';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const TIMESERIES_DETAILS_ADJUSTMENTS_LOAD_COMPLETE = 'TIMESERIES_DETAILS_ADJUSTMENTS_LOAD_COMPLETE';
export const timeSeriesDetailsAdjustmentsLoadComplete = createAction(TIMESERIES_DETAILS_ADJUSTMENTS_LOAD_COMPLETE, 'data');

export const timeSeriesDetailsAdjustmentsLoad = (identityId) => (dispatch, getState) => {
  const state = getState();

  if (!identityId)
    identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);

  if (!identityId)
    return;

  const uri = [`${ANALYSIS_API_ROOT_URL}/v3/timeseries-adjustments/${identityId}?take=500`];
  const dataPointsEdit = toJS(state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsEdit']), {});
  const { filterFromUtc, filterToUtc, filterTimezone } = dataPointsEdit;

  if (filterFromUtc !== null) uri.push(`from=${moment(filterFromUtc).format('YYYY-MM-DD HH:mm:ss')}`);
  if (filterToUtc !== null) uri.push(`to=${moment(filterToUtc).format('YYYY-MM-DD HH:mm:ss')}`);
  if (filterTimezone) uri.push(`timeZoneId=${filterTimezone}`);

  authFetch(uri.join('&'))
    .then(response => response.json())
    .then(data => {
      dispatch(timeSeriesDetailsAdjustmentsLoadComplete(mapToLocal(data)));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_ADJUSTMENTS_SET_FILTER_PROPERTY = 'TIMESERIES_DETAILS_ADJUSTMENTS_SET_FILTER_PROPERTY';
export const timeSeriesDetailsAdjustmentsSetFilterProperty = createAction(TIMESERIES_DETAILS_ADJUSTMENTS_SET_FILTER_PROPERTY, 'properties', 'value');

export const TIMESERIES_DETAILS_ADJUSTMENTS_EDITED = 'TIMESERIES_DETAILS_ADJUSTMENTS_EDITED';
export const timeSeriesDetailsAdjustmentsEdited = createAction(TIMESERIES_DETAILS_ADJUSTMENTS_EDITED, 'data');

export const TIMESERIES_DETAILS_ADJUSTMENTS_SET_RANGE_EDITOR_PROPERTY = 'TIMESERIES_DETAILS_ADJUSTMENTS_SET_RANGE_EDITOR_PROPERTY';
export const timeSeriesDetailsAdjustmentsSetRangeEditorProperty = createAction(TIMESERIES_DETAILS_ADJUSTMENTS_SET_RANGE_EDITOR_PROPERTY, 'properties', 'value');

export const TIMESERIES_DETAILS_ADJUSTMENTS_SET_SAVE_PROPERTY = 'TIMESERIES_DETAILS_ADJUSTMENTS_SET_SAVE_PROPERTY';
export const timeSeriesDetailsAdjustmentsSetSaveProperty = createAction(TIMESERIES_DETAILS_ADJUSTMENTS_SET_SAVE_PROPERTY, 'properties', 'value');

export const timeSeriesDetailsAdjustmentsSave = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);
  if (!identityId)
    return;

  const dataPoints = toJS(state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsEdit', 'dataPoints']), []);
  if (!dataPoints || !dataPoints.length)
    return;

  const dataPointsSaveModal = toJS(state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsSaveModal']), {});
  const { reason, commentMarkdown, adjustmentType } = dataPointsSaveModal;
  const data = {
    reason,
    adjustmentType,
    commentMarkdown,
    dataPoints
  };

  return authFetch(`${ANALYSIS_API_ROOT_URL}/v3/timeseries-adjustments/${identityId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data, (_, value) => value !== null ? value : undefined)
  })
    .then(() => {
      dispatch(timeSeriesDetailsAdjustmentsSaved());
      dispatch(timeSeriesDetailsAdjustmentsLoad(identityId));
      dispatch(logInfoNotification('Data point adjustments saved'));
      
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_ADJUSTMENTS_SAVED = 'TIMESERIES_DETAILS_ADJUSTMENTS_SAVED';
export const timeSeriesDetailsAdjustmentsSaved = createAction(TIMESERIES_DETAILS_ADJUSTMENTS_SAVED);

export const TIMESERIES_DETAILS_ADJUSTMENTS_RANGE_APPLY_COMPLETE = 'TIMESERIES_DETAILS_ADJUSTMENTS_RANGE_APPLY_COMPLETE';
export const timeSeriesDetailsAdjustmentsRangeApplyComplete = createAction(TIMESERIES_DETAILS_ADJUSTMENTS_RANGE_APPLY_COMPLETE, 'data');

export const timeSeriesDetailsAdjustmentsRangeApply = () => (dispatch, getState) => {
  const state = getState();

  const identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);
  if (!identityId)
    return;

  const dataPointsEdit = toJS(state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsEdit']), {});
  const { filterTimezone } = dataPointsEdit;

  const dataPointsRangeEdit = toJS(state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsRangeEdit']), {});
  const { fromUtc, toUtc, value } = dataPointsRangeEdit;

  const uri = [`${ANALYSIS_API_ROOT_URL}/v3/timeseries-adjustments/${identityId}/generate-points?from=${moment(fromUtc).format('YYYY-MM-DD HH:mm:ss')}&to=${moment(toUtc).format('YYYY-MM-DD HH:mm:ss')}`];
  if (value !== null) uri.push(`adjustedValue=${value}`);
  if (filterTimezone) uri.push(`timeZoneId=${filterTimezone}`);

  authFetch(uri.join('&'))
    .then(response => response.json())
    .then(mapAdjustments)
    .then(data => {
      const { dataPoints } = dataPointsEdit;
      const adjustments = dataPoints && dataPoints.length
        ? mergeAdjustments(data, dataPoints)
        : data;

      dispatch(timeSeriesDetailsAdjustmentsRangeApplyComplete(adjustments.map(mapDataPoint)));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

const mapAdjustments = (data) => data.map(i => ({
  periodStartUtc: i.periodStartUtc ? moment.utc(i.periodStartUtc).toDate() : null,
  periodEndUtc: i.periodEndUtc ? moment.utc(i.periodEndUtc).toDate() : null,
  value: i.value,
  adjustedValue: i.adjustedValue,
  periodStart: i.periodStart,
  periodEnd: i.periodEnd,
  isAdjustmentDeletion: !!i.isAdjustmentDeletion
}));

const mergeAdjustments = (data, existing) => ([
  ...existing.filter(a => !data.some(b => !(a.periodStartUtc - b.periodStartUtc))),
  ...data
].sort((a, b) => a.periodStartUtc - b.periodStartUtc));

export const mapDataPoint = (dataPoint) => {
  const dateFormat = 'DD-MMM-YYYY HH:mm';

  const periodStartUtc = dataPoint.periodStartUtc ? moment.utc(dataPoint.periodStartUtc).toDate() : null;
  const periodEndUtc = dataPoint.periodEndUtc ? moment.utc(dataPoint.periodEndUtc).toDate() : null;

  const formattedStartUtc = dataPoint.periodStart ? moment.utc(dataPoint.periodStart).format(dateFormat) : null;
  const formattedEndUtc = dataPoint.periodEnd ? moment.utc(dataPoint.periodEnd).format(dateFormat) : null;
    
  const isTemporary = dataPoint.adjustmentType && dataPoint.adjustmentType === 'Temporary';

  return {
    periodStartUtc: periodStartUtc,
    periodEndUtc: periodEndUtc,
    periodStart: dataPoint.periodStart,
    periodEnd: dataPoint.periodEnd,
    formattedStartUtc: formattedStartUtc,
    formattedEndUtc: formattedEndUtc,
    value: dataPoint.value,
    adjustedValue: dataPoint.adjustedValue,
    isAdjustmentDeletion: !!dataPoint.isAdjustmentDeletion,
    isTemporary: isTemporary
  };
}

const mapToLocal = (data) => ({
  timeseriesId: data.identityId,
  instanceId: data.instanceId,
  timezone: data.timeZone,
  dataPoints: data.dataPoints.map(mapDataPoint)
});