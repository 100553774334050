import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../../utility/immutable-utility';
import {
  analysisDynamicWorkspaceEditNew,
  analysisDynamicWorkspaceEditExisting,
  analysisDynamicWorkspaceEndEdit
} from '../../../../actions/analysis-dynamic-workspace-editor';
import {
  analysisDynamicWorkspaceSetToolbarDynamicFilter,
  analysisDynamicWorkspaceToggleCategoryGrouping
} from '../../../../actions/analysis-dynamic-workspace';
import Loading from '../../../shared/Loading';
import { DynamicWorkspaceToolbar } from './DynamicWorkspaceToolbar';
import { canGroupDynamicFilters, mergeGroupedDynamicFilters } from '../../../../reducer-functions/analysis-dynamic-workspace';

export const DynamicWorkspaceToolbarConnected = connect(
  (state) => ({
    enableDynamicWorkspace: state.getIn(['userSettings', 'settings', 'experimental', 'enableDynamicWorkspace']),
    schemas: state.getIn(['analysisCrossSchemaSearch', 'initialise', 'schemas']),
    showModal: state.getIn(['analysis', 'dynamicWorkspaceDesign', 'showModal']),
    isDynamicWorkspaceFilterLoading: state.getIn(['analysis', 'isDynamicWorkspaceFilterLoading']),
    isDynamicWorkspaceDesignSaving: state.getIn(['analysis', 'dynamicWorkspaceDesign', 'isSaving']),
    searchStateContext: state.getIn(['analysis', 'dynamicWorkspaceDesign', 'searchStateContext']),
    dynamicWorkspaces: state.getIn(['analysis', 'workspace', 'dynamicWorkspaces']),
    isGroupingEnabled: state.getIn(['analysis', 'workspace', 'dynamicWorkspaceGroupingEnabled'])
  }),
  (dispatch) => ({
    editNew() {
      dispatch(analysisDynamicWorkspaceEditNew());
    },
    editExisting(dynamicFilterKey) {
      dispatch(analysisDynamicWorkspaceEditExisting(dynamicFilterKey));
    },
    setDynamicWorkspaceFilterValue(dynamicWorkspaceKey, dynamicFilterKey, value) {
      dispatch(analysisDynamicWorkspaceSetToolbarDynamicFilter(dynamicWorkspaceKey, dynamicFilterKey, value, undefined));
    },
    setDynamicWorkspaceMultipleFilterValues(dynamicWorkspaceKey, dynamicFilterKey, values) {
      dispatch(analysisDynamicWorkspaceSetToolbarDynamicFilter(dynamicWorkspaceKey, dynamicFilterKey, undefined, values));
    },
    endEditing() {
      dispatch(analysisDynamicWorkspaceEndEdit());
    },
    toggleCategoryGrouping() {
      dispatch(analysisDynamicWorkspaceToggleCategoryGrouping('dynamicWorkspaceToolbar'));
    }
  })
)(({ isCompact = false,
  enableDynamicWorkspace = true, schemas, isDynamicWorkspaceFilterLoading = false, isDynamicWorkspaceDesignSaving = false,
  showModal, searchStateContext, editNew, editExisting, endEditing,
  isGroupingEnabled, toggleCategoryGrouping,
  setDynamicWorkspaceFilterValue, setDynamicWorkspaceMultipleFilterValues,
  dynamicWorkspaces }) => {
  if (enableDynamicWorkspace !== true)
    return <></>

  const isDynamicWorskpaceDialogIsOpen = showModal;
  let _dynamicWorkspaces = toJS(dynamicWorkspaces) ?? [];
  const canGroupFilters = canGroupDynamicFilters(_dynamicWorkspaces);

  const isInitialised = schemas && schemas.size > 0;

  if (isGroupingEnabled === true) {
    _dynamicWorkspaces = mergeGroupedDynamicFilters(_dynamicWorkspaces);
  }

  return <Loading message='' isLoading={isDynamicWorkspaceFilterLoading || isDynamicWorkspaceDesignSaving}>
    <DynamicWorkspaceToolbar
        isInitialised={isInitialised}
        isCompact={isCompact}
        searchStateContext={searchStateContext}
        editNew={editNew}
        editExisting={editExisting}
        endEditing={endEditing}
        setDynamicWorkspaceFilterValue={setDynamicWorkspaceFilterValue}
        setDynamicWorkspaceMultipleFilterValues={setDynamicWorkspaceMultipleFilterValues}
        isDynamicWorskpaceDialogIsOpen={isDynamicWorskpaceDialogIsOpen}
        dynamicWorkspaces={_dynamicWorkspaces}
        canGroupFilters={canGroupFilters}
        isGroupingEnabled={isGroupingEnabled}
        toggleCategoryGrouping={toggleCategoryGrouping} />
    </Loading>
});