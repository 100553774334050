import { createAction } from '../utility/redux-utility';
import { getMessageParts } from '../utility/log-utility';
import { toJS } from '../utility/immutable-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const TIMESERIES_DETAILS_VARIANTS_CLEAR = 'TIMESERIES_DETAILS_VARIANTS_CLEAR';
export const timeSeriesDetailsVariantsClear = createAction(TIMESERIES_DETAILS_VARIANTS_CLEAR);

export const TIMESERIES_DETAILS_VARIANTS_LOAD_STARTED = 'TIMESERIES_DETAILS_VARIANTS_LOAD_STARTED';
export const timeSeriesDetailsVariantsLoadStarted = createAction(TIMESERIES_DETAILS_VARIANTS_LOAD_STARTED);

export const timeSeriesDetailsVariantsLoad = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);
  if (!identityId || identityId === 0)
    return;
    
  dispatch(timeSeriesDetailsVariantsLoadStarted());

  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/identities/${identityId}/dependants`)
    .then(response => response.json())
    .then(data => {
      dispatch(timeSeriesDetailsVariantsLoadComplete(data));
    })
    .catch(error => {
      dispatch(timeSeriesDetailsVariantsLoadComplete());
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_VARIANTS_LOAD_COMPLETE = 'TIMESERIES_DETAILS_VARIANTS_LOAD_COMPLETE';
export const timeSeriesDetailsVariantsLoadComplete = createAction(TIMESERIES_DETAILS_VARIANTS_LOAD_COMPLETE, 'data');

export const TIMESERIES_DETAILS_VARIANTS_EDIT = 'TIMESERIES_DETAILS_VARIANTS_EDIT';
export const timeSeriesDetailsVariantsEdit = createAction(TIMESERIES_DETAILS_VARIANTS_EDIT);

export const TIMESERIES_DETAILS_VARIANTS_CANCEL_EDIT = 'TIMESERIES_DETAILS_VARIANTS_CANCEL_EDIT';
export const timeSeriesDetailsVariantsCancelEdit = createAction(TIMESERIES_DETAILS_VARIANTS_CANCEL_EDIT);

export const TIMESERIES_DETAILS_VARIANTS_UPDATE_EDIT = 'TIMESERIES_DETAILS_VARIANTS_UPDATE_EDIT';
export const timeSeriesDetailsVariantsUpdateEdit = createAction(TIMESERIES_DETAILS_VARIANTS_UPDATE_EDIT, 'keyPath', 'value');

export const TIMESERIES_DETAILS_VARIANTS_SAVE_STARTED = 'TIMESERIES_DETAILS_VARIANTS_SAVE_STARTED';
export const timeSeriesDetailsVariantsSaveStarted = createAction(TIMESERIES_DETAILS_VARIANTS_SAVE_STARTED, 'key', 'data');

export const timeSeriesDetailsVariantsSave = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);
  const variant = toJS(state.getIn(['timeSeriesDetails', 'variantsEditor', 'variantEdit']), null);

  if (!variant) return;

  const { lens, operation, timeZoneId, shapeName } = variant;

  dispatch(timeSeriesDetailsVariantsSaveStarted());

  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/identities/${identityId}/variants`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ lens, operation, timeZoneId, shapeName })
  })
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      dispatch(timeSeriesDetailsVariantsSaveComplete(data));
      dispatch(logInfoNotification('Variant added'));
    })
    .catch(error => {
      dispatch(timeSeriesDetailsVariantsActionFailed(getMessageParts(error)));
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_DETAILS_VARIANTS_SAVE_COMPLETE = 'TIMESERIES_DETAILS_VARIANTS_SAVE_COMPLETE';
export const timeSeriesDetailsVariantsSaveComplete = createAction(TIMESERIES_DETAILS_VARIANTS_SAVE_COMPLETE, 'data');

export const TIMESERIES_DETAILS_VARIANTS_ACTION_FAILED = 'TIMESERIES_DETAILS_VARIANTS_ACTION_FAILED';
export const timeSeriesDetailsVariantsActionFailed = createAction(TIMESERIES_DETAILS_VARIANTS_ACTION_FAILED, 'errorMessage');
