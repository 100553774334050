import React from 'react';

export default function TimeSeriesToolBar({ currentTimeSeriesId, viewMode, updateViewMode, cloneTimeSeries, saveTimeSeries, refreshTimeSeries, isContributor }) {
  const showJsonView = viewMode === 'json';

  return <div className='row'>
    <div className='col d-flex pr-0'>
      <div className='btn-group'>
        {!showJsonView && (
          <button type='button' className='btn btn-outline-primary' onClick={() => updateViewMode('json')}>
            <i className='fas fa-file-code' /> View Json
          </button>
        )}
        {showJsonView && (
          <button type='button' className='btn btn-outline-primary' onClick={() => updateViewMode('default')}>
            <i className='fas fa-file-alt' /> View Editor
          </button>
        )}
      </div>
      <a className='nav-link fota-small-text' href='https://mercuria-fota.visualstudio.com/timeseries/_wiki/wikis/timeseries.wiki/157/Time-series-fields' target='_blank' rel='noopener noreferrer'>
        <i className='fas fa-book fa-fw' /> Wiki
      </a>
    </div>
    <div className='col d-flex justify-content-end pl-0'>
      <div className='btn-group'>
        {isContributor && currentTimeSeriesId > 0 && (
          <button type='button' className='btn btn-secondary' onClick={cloneTimeSeries}>Clone</button>
        )}
        {currentTimeSeriesId > 0 && (
          <button type='button' className='btn btn-secondary' onClick={() => refreshTimeSeries(currentTimeSeriesId)}>Refresh</button>
        )}
        { isContributor && (
          <button type='button' className='btn btn-primary' onClick={saveTimeSeries}>Save</button>
        )}
      </div>
    </div>
  </div>
}