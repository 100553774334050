import React from 'react';

export function MarkersFiller({ updateAllSeries }) {
  const options = [
    { name: 'On', value: 'true' },
    { name: 'Off', value: 'false' }
  ];

  return <select data_id='connect-nulls' autoFocus className='form-control form-control-sm filler-select' style={{ overflowY: 'hidden', minWidth: '125px' }} size={options.length} onChange={e => updateAllSeries(['marker'], { enabled: e.target.value === 'true' })}>
    {options.map((o, oi) => <option key={oi} data-close-popover value={o.value} >{o.name}</option>)}
  </select>
}