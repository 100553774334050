import { push } from "redux-first-history";
import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import { copyToClipboard } from '../utility/text-utility';
import { parse as qsParse } from 'querystring';
import { authFetch } from '../auth';
import { ETL_API_URL } from '../config';
import { stringify } from 'querystring';
import {
  logErrorNotification
} from './log';

export const outagesPathLoad = (path, url) => (dispatch, getState) => {
  const qsIndex = path.indexOf('?');
  if (qsIndex < 0) return;

  const criteria = qsParse(path.substring(qsIndex + 1));
  dispatch(outagesInitialiseToolbar(criteria));

  if (url) dispatch(push(url));
};

export const outagesCopyPath = () => (dispatch, getState) => {
  const state = getState();

  let criteria = {
    source: state.getIn(['outages', 'criteria', 'source']),
    biddingZone: state.getIn(['outages', 'criteria', 'biddingZone']),
    productionType: state.getIn(['outages', 'criteria', 'productionType']),
    unitEic: state.getIn(['outages', 'criteria', 'unitEic']),
    eventStart: state.getIn(['outages', 'criteria', 'eventStart']),
    eventEnd: state.getIn(['outages', 'criteria', 'eventEnd']),
    fotaStatus: state.getIn(['outages', 'criteria', 'fotaStatus']),
    messageVersions: state.getIn(['outages', 'criteria', 'messageVersions']),
    messageStatus: state.getIn(['outages', 'criteria', 'messageStatus']),
    generationData: state.getIn(['outages', 'criteria', 'generationData'])
  };

  const messageId = state.getIn(['outages', 'criteria', 'messageId']);
  if (messageId) criteria.filters = [`messageId|${messageId}`];

  const skip = state.getIn(['outages', 'criteria', 'skip']);
  const take = state.getIn(['outages', 'criteria', 'take']);
  if (skip > 0) criteria.page = (skip / take) + 1;
  if (take > 0) criteria.pageSize = take;

  for (let i in criteria)
    if (criteria[i] === undefined || criteria[i] === null || criteria[i] === '')
      delete criteria[i];

  const outagesPath = encodeURIComponent(`/power/outages?${stringify(criteria)}`);
  const shareUrl = `${window.location.origin}/power/outages?outagesPath=${outagesPath}`;

  copyToClipboard(shareUrl);
};

export const OUTAGES_LOAD_SOURCE_LIST_STARTED = 'OUTAGES_LOAD_SOURCE_LIST_STARTED';
export const outagesLoadSourceListStarted = createAction(OUTAGES_LOAD_SOURCE_LIST_STARTED);

export const outagesLoadSourceList = () => (dispatch, getState) => {
  dispatch(outagesLoadSourceListStarted());

  return authFetch(`${ETL_API_URL}/outagedashboard/sources`)
    .then(response => response.json())
    .then(data => {
      dispatch(outagesLoadSourceListComplete(data));
    })
    .catch(error => {
      dispatch(outagesLoadSourceListComplete());
      dispatch(logErrorNotification(error));
    });
};

export const OUTAGES_LOAD_SOURCE_LIST_COMPLETE = 'OUTAGES_LOAD_SOURCE_LIST_COMPLETE';
export const outagesLoadSourceListComplete = createAction(OUTAGES_LOAD_SOURCE_LIST_COMPLETE, 'data');

export const OUTAGES_LOAD_BIDDING_ZONE_LIST_STARTED = 'OUTAGES_LOAD_BIDDING_ZONE_LIST_STARTED';
export const outagesLoadBiddingZoneListStarted = createAction(OUTAGES_LOAD_BIDDING_ZONE_LIST_STARTED);

export const outagesLoadBiddingZoneList = () => (dispatch, getState) => {
  const state = getState();

  const source = state.getIn(['outages', 'toolbar', 'source']);
  const criteria = { source };

  dispatch(outagesLoadBiddingZoneListStarted());

  return authFetch(`${ETL_API_URL}/outagedashboard/biddingzones?${stringify(criteria)}`)
    .then(response => response.json())
    .then(data => {
      dispatch(outagesLoadBiddingZoneListComplete(data));
    })
    .catch(error => {
      dispatch(outagesLoadBiddingZoneListComplete());
      dispatch(logErrorNotification(error));
    });
};

export const OUTAGES_LOAD_BIDDING_ZONE_LIST_COMPLETE = 'OUTAGES_LOAD_BIDDING_ZONE_LIST_COMPLETE';
export const outagesLoadBiddingZoneListComplete = createAction(OUTAGES_LOAD_BIDDING_ZONE_LIST_COMPLETE, 'data');

export const OUTAGES_LOAD_PRODUCTION_TYPE_LIST_STARTED = 'OUTAGES_LOAD_PRODUCTION_TYPE_LIST_STARTED';
export const outagesLoadProductionTypeListStarted = createAction(OUTAGES_LOAD_PRODUCTION_TYPE_LIST_STARTED);

export const outagesLoadProductionTypeList = () => (dispatch, getState) => {
  const state = getState();

  const source = state.getIn(['outages', 'toolbar', 'source']);
  const criteria = { source };

  dispatch(outagesLoadProductionTypeListStarted());

  return authFetch(`${ETL_API_URL}/outagedashboard/productiontypes?${stringify(criteria)}`)
    .then(response => response.json())
    .then(data => {
      dispatch(outagesLoadProductionTypeListComplete(data));
    })
    .catch(error => {
      dispatch(outagesLoadProductionTypeListComplete());
      dispatch(logErrorNotification(error));
    });
};

export const OUTAGES_LOAD_PRODUCTION_TYPE_LIST_COMPLETE = 'OUTAGES_LOAD_PRODUCTION_TYPE_LIST_COMPLETE';
export const outagesLoadProductionTypeListComplete = createAction(OUTAGES_LOAD_PRODUCTION_TYPE_LIST_COMPLETE, 'data');

export const OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_STARTED = 'OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_STARTED';
export const outagesLookupGenerationDataLookupListStarted = createAction(OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_STARTED);

export const outagesLookupGenerationDataLookupList = () => (dispatch, getState) => {
  const state = getState();

  const generationData = state.getIn(['outages', 'toolbar', 'generationData']);
  const criteria = { lookup: generationData };

  dispatch(outagesLookupGenerationDataLookupListStarted());

  return authFetch(`${ETL_API_URL}/outagedashboard/generationdatalookups?${stringify(criteria)}`)
    .then(response => response.json())
    .then(data => {
      dispatch(outagesLookupGenerationDataLookupListComplete(data));
    })
    .catch(error => {
      dispatch(outagesLookupGenerationDataLookupListComplete());
      dispatch(logErrorNotification(error));
    });
};

export const OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_COMPLETE = 'OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_COMPLETE';
export const outagesLookupGenerationDataLookupListComplete = createAction(OUTAGES_LOOKUP_GENERATION_DATA_LOOKUP_LIST_COMPLETE, 'data');

export const OUTAGES_REFRESH_STARTED = 'OUTAGES_REFRESH_STARTED';
export const outagesRefreshStarted = createAction(OUTAGES_REFRESH_STARTED);

export const outagesRefresh = (updateRefreshRequired = true) => (dispatch, getState) => {
  const state = getState();

  let criteria = toJS(state.getIn(['outages', 'criteria']), {});

  for (let i in criteria) if (criteria[i] === undefined) delete criteria[i];

  dispatch(outagesRefreshStarted());

  return authFetch(`${ETL_API_URL}/outagedashboard?${stringify(criteria)}`)
    .then(response => response.json())
    .then(data => {
      dispatch(outagesRefreshComplete(data, updateRefreshRequired));
    })
    .catch(error => {
      dispatch(outagesRefreshStopped());
      dispatch(logErrorNotification(error));
    });
};

export const OUTAGES_REFRESH_COMPLETE = 'OUTAGES_REFRESH_COMPLETE';
export const outagesRefreshComplete = createAction(OUTAGES_REFRESH_COMPLETE, 'data', 'updateRefreshRequired');

export const OUTAGES_REFRESH_STOPPED = 'OUTAGES_REFRESH_STOPPED';
export const outagesRefreshStopped = createAction(OUTAGES_REFRESH_STOPPED);

export const OUTAGES_SELECT_OUTAGE = 'OUTAGES_SELECT_OUTAGE';
export const outagesSelectOutage = createAction(OUTAGES_SELECT_OUTAGE, 'item');

export const outagesResetValidation = () => (dispatch, getState) => {
  const state = getState();

  const outage = toJS(state.getIn(['outages', 'selectedOutage']), {});
  if (!outage.id) return;

  dispatch(outagesRefreshStarted());

  return authFetch(`${ETL_API_URL}/outage/new`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify([outage.id])
    })
    .then(() => {
      dispatch(outagesRefresh(false));
    })
    .catch(error => {
      dispatch(outagesRefreshStopped());
      dispatch(logErrorNotification(error));
    });
};

export const OUTAGES_INITIALISE_TOOLBAR = 'OUTAGES_INITIALISE_TOOLBAR';
export const outagesInitialiseToolbar = createAction(OUTAGES_INITIALISE_TOOLBAR, 'data');

export const outagesUpdateToolbarSource = (value) => (dispatch, getState) => {
  dispatch(outagesUpdateToolbar('source', value));
  dispatch(outagesUpdateToolbar('biddingZone', ''));
  dispatch(outagesUpdateToolbar('productionType', ''));
  dispatch(outagesLoadBiddingZoneList());
  dispatch(outagesLoadProductionTypeList());
};

export const OUTAGES_UPDATE_TOOLBAR = 'OUTAGES_UPDATE_TOOLBAR';
export const outagesUpdateToolbar = createAction(OUTAGES_UPDATE_TOOLBAR, 'key', 'value');

export const OUTAGES_UPDATE_TOOLBAR_FILTER = 'OUTAGES_UPDATE_TOOLBAR_FILTER';
export const outagesUpdateToolbarFilter = createAction(OUTAGES_UPDATE_TOOLBAR_FILTER, 'key', 'value');

export const OUTAGES_UPDATE_TOOLBAR_PAGE = 'OUTAGES_UPDATE_TOOLBAR_PAGE';
export const outagesUpdateToolbarPage = createAction(OUTAGES_UPDATE_TOOLBAR_PAGE, 'page', 'pageSize');

export const OUTAGES_UPDATE_CRITERIA = 'OUTAGES_UPDATE_CRITERIA';
export const outagesUpdateCriteria = createAction(OUTAGES_UPDATE_CRITERIA);

export const OUTAGES_UPDATE_CRITERIA_FILTER = 'OUTAGES_UPDATE_CRITERIA_FILTER';
export const outagesUpdateCriteriaFilter = createAction(OUTAGES_UPDATE_CRITERIA_FILTER);

export const OUTAGES_UPDATE_CRITERIA_PAGE = 'OUTAGES_UPDATE_CRITERIA_PAGE';
export const outagesUpdateCriteriaPage = createAction(OUTAGES_UPDATE_CRITERIA_PAGE);

export const OUTAGES_SEARCH_RESET_PAGE = 'OUTAGES_SEARCH_RESET_PAGE';
export const outagesSearchResetPage = createAction(OUTAGES_SEARCH_RESET_PAGE);

export const outagesUpdateFilter = (key, value) => (dispatch, getState) => {
  dispatch(outagesUpdateToolbarFilter(key, value));
  dispatch(outagesUpdateCriteriaFilter(key, value));
  dispatch(outagesUpdateToolbarPage(1));
  dispatch(outagesUpdateCriteriaPage());
  dispatch(outagesRefresh(false));
};

export const outagesUpdatePage = (page, pageSize) => (dispatch, getState) => {
  dispatch(outagesUpdateToolbarPage(page, pageSize));
  dispatch(outagesUpdateCriteriaPage());
  dispatch(outagesRefresh(false));
};

export const outagesSearch = () => (dispatch, getState) => {
  dispatch(outagesSearchResetPage());
  dispatch(outagesUpdateCriteria());
  dispatch(outagesRefresh());
};

export const outagesInitialise = () => (dispatch, getState) => {
  dispatch(outagesLoadSourceList());
  dispatch(outagesLoadBiddingZoneList());
  dispatch(outagesLoadProductionTypeList());
  dispatch(outagesLookupGenerationDataLookupList());
  dispatch(outagesUpdateCriteria());
  dispatch(outagesRefresh());
};