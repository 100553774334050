import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
  UPDATE_SEARCH_PARAM2,
  TIMESERIES_SEARCH_STARTED2,
  TIMESERIES_SEARCH_COMPLETE2,
} from '../actions/timeseriesSearch2';

export const timeseriesSearch2 = createReducer(null, {
  [UPDATE_SEARCH_PARAM2](state, action) {
    const convertEmptyToNull = action.convertEmptyToNull || false;
    let value = convertEmptyToNull ? (action.value === '' ? null : action.value) : action.value;
    return state.setIn(action.stateKey, typeof value === 'object' ? fromJS(value) : value);
  },
  [TIMESERIES_SEARCH_STARTED2](state, action) {
    return state.setIn([action.stateKey, 'isSearching'], true);
  },
  [TIMESERIES_SEARCH_COMPLETE2](state, action) {
    const results = (action && action.data && {
      count: action.data.count || 0,
      facets: action.data.facets || [],
      data: action.data.results || []
    }) || { count: 0, facets: [], data: [] };

    return state.setIn([action.stateKey, 'results', 'count'], results.count)
      .setIn([action.stateKey, 'results', 'facets'], fromJS(results.facets))
      .setIn([action.stateKey, 'results', 'data'], fromJS(results.data))
      .setIn([action.stateKey, 'isSearching'], false);
  }
});