import React, { Component } from 'react';
import PropTypes from 'prop-types'
import LoadingIcon from '../../shared/LoadingIcon';
import FormattedNumber from '../../shared/FormattedNumber';
import FormattedDateTime from '../../shared/FormattedDateTime';
import Timezone from '../../shared/FormattedDateTime';

export class SearchResultStatistics extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    loadStatistics: PropTypes.func.isRequired,
    statistics: PropTypes.array,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.statistics) {
      this.props.loadStatistics();
    }
  }

  render() {
    const { isLoading, statistics } = this.props;
    if (isLoading === true)
      return <div>
        <LoadingIcon width='1.25em' /><span className='pl-2'>Loading statistics...</span>
      </div>

    if (!statistics || statistics.length === 0)
      return <span>No statistics</span>

    return <table className='table table-sm table-borderless ml-4'>
      <tbody>
        {/* <tr><td><b>Percentage loaded</b></td><td><FormattedNumber displayIfNaN='-' maxDecimals='1' minDecimals='1'>{ statistics.sourcePercentageLoaded }</FormattedNumber></td  ></tr> */}
        <tr><td><b>Average Value</b></td><td><FormattedNumber>{statistics.averageValue}</FormattedNumber></td></tr>
        <tr><td><b>Min Value</b></td><td><FormattedNumber>{statistics.minValue}</FormattedNumber></td></tr>
        <tr><td><b>Max Value</b></td><td><FormattedNumber>{statistics.maxValue}</FormattedNumber></td></tr>
        <tr><td><b>Instances</b></td><td><FormattedNumber>{statistics.instanceCount}</FormattedNumber></td></tr>
        <tr><td><b>Data Points</b></td><td><FormattedNumber>{statistics.dataPointCount}</FormattedNumber></td></tr>
        <tr><td><b>First Data Point <Timezone /></b></td><td>{statistics.firstDataPointUtc ? <FormattedDateTime>{statistics.firstDataPointUtc}</FormattedDateTime> : '-'}</td></tr>
        <tr><td><b>Last Data Point <Timezone /></b></td><td>{statistics.lastDataPointUtc ? <FormattedDateTime>{statistics.lastDataPointUtc}</FormattedDateTime> : '-'}</td></tr>
        <tr><td><b>Last Updated <Timezone /></b></td><td>{statistics.lastUpdatedUtc ? <FormattedDateTime>{statistics.lastUpdatedUtc}</FormattedDateTime> : '-'}</td></tr>
        <tr><td><b>Query Count</b></td><td>{statistics.queryCount ? statistics.queryCount : '-'}</td></tr>
        <tr><td><b>Last Queried <Timezone /></b></td><td>{statistics.lastQueriedUtc ? <FormattedDateTime>{statistics.lastQueriedUtc}</FormattedDateTime> : '-'}</td></tr>
        <tr><td><b>Last Queried By</b></td><td>{statistics.lastQueriedBy ? statistics.lastQueriedBy : '-'}</td></tr>
      </tbody>
    </table>
  }
}