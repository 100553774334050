import { createReducer } from '../utility/redux-utility';
import { areEmpty } from '../utility/text-utility';
import { fromJS } from 'immutable';
import {
  WORKSPACE_MODAL_CLEAR,
  WORKSPACE_MODAL_LOAD_STARTED,
  WORKSPACE_MODAL_LOAD_STOPPED,
  WORKSPACE_MODAL_LOAD_COMPLETE,
  WORKSPACE_MODAL_SAVE_STARTED,
  WORKSPACE_MODAL_SAVE_STOPPED,
  WORKSPACE_MODAL_SAVE_COMPLETE,
  WORKSPACE_MODAL_SET_MODE,
  WORKSPACE_MODAL_SET_TYPE,
  WORKSPACE_MODAL_SET_SCOPE,
  WORKSPACE_MODAL_SET_SELECTED_WORKSPACE,
  WORKSPACE_MODAL_SET_PATH
} from '../actions/workspaceModal';

const getFullPath = (folderPath, subFolderPath, name) => `${folderPath || ''}/${subFolderPath || ''}/${name || ''}`.replace(/\/+/g, '/');

export const workspaceModal = createReducer(null, {
  [WORKSPACE_MODAL_CLEAR](state, action) {
    return state.set('isInitialised', false)
                .set('isLoading', false)
                .set('private', fromJS([]))
                .set('shared', fromJS([]))
                .set('filteredShared', fromJS([]))
                .set('sharedFolders', fromJS([]))
                .set('mode', '')
                .set('type', '')
                .set('scope', '')
                .set('folderPath', '')
                .set('subFolderPath', '')
                .set('name', '')
                .set('fullPath', '')
                .set('selectedWorkspaceId', '')
                .set('selectedWorkspacePath', '')
                .set('selectedWorkspace', fromJS({}));
  },
  [WORKSPACE_MODAL_LOAD_STARTED](state, action) {
    return state.set('isLoading', true)
                .set('private', fromJS([]))
                .set('shared', fromJS([]))
                .set('filteredShared', fromJS([]))
                .set('sharedFolders', fromJS([]))
  },
  [WORKSPACE_MODAL_LOAD_STOPPED](state, action) {
    return state.set('isLoading', false);
  },
  [WORKSPACE_MODAL_LOAD_COMPLETE](state, action) {
    let newState = state.set('isInitialised', true)
                        .set('isLoading', false);

    action.data.forEach(({ status, value }) => {
      if (status !== 'fulfilled') return;

      const { property, data } = value || {};

      newState = newState.set(property, fromJS(data));
    });

    const folderPath = newState.get('folderPath');
    const filteredShared = newState.get('shared').filter(i => i.get('folderPath') === folderPath || areEmpty(i.get('folderPath'), folderPath));

    return newState.set('filteredShared', filteredShared);
  },
  [WORKSPACE_MODAL_SAVE_STARTED](state, action) {
    return state.set('isSaving', true);
  },
  [WORKSPACE_MODAL_SAVE_STOPPED](state, action) {
    return state.set('isSaving', false);
  },
  [WORKSPACE_MODAL_SAVE_COMPLETE](state, action) {
    return state.set('isSaving', false);
  },
  [WORKSPACE_MODAL_SET_MODE](state, action) {
    return state.set('mode', action.value);
  },
  [WORKSPACE_MODAL_SET_TYPE](state, action) {
    return state.set('type', action.value);
  },
  [WORKSPACE_MODAL_SET_SCOPE](state, action) {
    return state.set('scope', action.value);
  },
  [WORKSPACE_MODAL_SET_PATH](state, action) {
    let newState = state;

    Object.entries(action.data).forEach(([key, value]) => newState = newState.set(key, value));

    const folderPath = newState.get('folderPath');
    const subFolderPath = newState.get('subFolderPath');
    const name = newState.get('name');
    const filteredShared = newState.get('shared').filter(i => i.get('folderPath') === folderPath || areEmpty(i.get('folderPath'), folderPath));

    const fullPath = getFullPath(folderPath, subFolderPath, name);

    return newState.set('filteredShared', filteredShared)
                   .set('fullPath', fullPath);
  },
  [WORKSPACE_MODAL_SET_SELECTED_WORKSPACE](state, action) {
    const { id, type, scope, folderPath, subFolderPath, name } = action.data;
    const fullPath = getFullPath(folderPath, subFolderPath, name);

    return state.set('selectedWorkspace', fromJS(action.data))
                .set('selectedWorkspaceId', id)
                .set('selectedWorkspacePath', fullPath)
                .set('type', type)
                .set('scope', scope)
                .set('folderPath', folderPath)
                .set('subFolderPath', subFolderPath)
                .set('name', name)
                .set('fullPath', fullPath);
  }
});