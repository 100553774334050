import React from 'react';
import Loading from '../../shared/Loading';
import ErrorBoundary from '../../shared/ErrorBoundary';
import PropTypes from 'prop-types';
import { MenuItem, Pagination } from '@mui/material';
import { PopoverButton } from '../../shared/PopoverButton';
import AbsRelDate from '../../shared/AbsRelDate';
import { immutableType } from '../../../utility/type-checking';
import { ModalDialogButton } from '../../shared/ModalDialogButton';
import { StorageAuctionSaveAsModal } from '../../shared/WorkspaceSaveAsModal';
import PageHeader from '../../shared/PageHeader';
import FormattedNumber, { formatNumber } from '../../shared/FormattedNumber';
import moment from 'moment';
import { fromJS } from 'immutable';
import { hideableFilterNames } from '../../../state/storage-auctions';
import { mapDashboardCriteriaDateFromToAbsRelDate, mapDashboardCriteriaDateToToAbsRelDate } from '../../../mapper/absRelDateMapper';

export class StorageAuctions extends React.Component {
  static propTypes = {
    stateKey: PropTypes.string.isRequired,
    showDiagnostics: PropTypes.bool.isRequired,
    hidePagination: PropTypes.bool,
    hiddenColumns: PropTypes.object,
    hiddenFilters: PropTypes.object,

    init: PropTypes.func.isRequired,
    closeWorkspace: PropTypes.func.isRequired,
    workspaceName: PropTypes.string,
    canShareWorkspace: PropTypes.bool.isRequired,
    shareWorkspace: PropTypes.func.isRequired,
    refreshRequired: PropTypes.bool.isRequired,
    refresh: PropTypes.func.isRequired,
    setPageIndex: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    orderByDirection: PropTypes.string.isRequired,
    setOrderBy: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    setFilterValue: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    dateFrom: immutableType.isRequired,
    dateTo: immutableType.isRequired,
    availableFilters: immutableType.isRequired,
    filters: immutableType.isRequired,
    elapsed: PropTypes.string,
    auctions: immutableType.isRequired,
    auctionsTotalCount: PropTypes.number.isRequired
  }

  componentDidMount() {
    this.props.init();
    this.props.refresh();
  }

  onRefresh(e) {
    if (e) e.preventDefault();
    this.props.refresh();
  }

  onCloseWorkspace() {
    this.props.closeWorkspace();
  }

  filterVisibility(name) {
    return (this.props.hiddenFilters && this.props.hiddenFilters.get(name) === true) ? 'd-none' : '';
  }

  allFilterVisibility(style) {
    return (this.props.hiddenFilters && this.props.hiddenFilters.size === hideableFilterNames.length) ? 'd-none' : style;
  }

  columnVisibility(name) {
    return (this.props.hiddenColumns && this.props.hiddenColumns.get(name) === true) ? 'd-none' : '';
  }

  render() {
    return <main className='d-flex flex-column h-100' >
      <ErrorBoundary>
        <Loading isLoading={this.props.isLoading}>
          <div className='container-fluid p-0'>
            {this.props.workspaceName && <>
              <div className='d-flex justify-content-center'>
                <PageHeader>
                  <button type='button' className='btn btn-sm float-left p-0 mr-2' onClick={() => this.onCloseWorkspace()}>
                    <i className='fas fa-times fa-fw'></i>
                  </button>
                  {this.props.workspaceName}
                </PageHeader>
              </div>
            </>}
            <div className={`${this.allFilterVisibility('d-flex')} m-1 p-1 `}>
              <div className='flex-fill'>
                <form className='form-row' noValidate onSubmit={e => this.onRefresh(e)}>
                  <div className={`form-group m-0 mr-1 ${this.filterVisibility('Locations')}`}>
                    <label className='font-weight-bold mb-0'>Locations</label>
                    <FilterInput property='locations' setFilterValue={this.props.setFilterValue} updateFilters={this.props.updateFilters} filters={this.props.filters} availableFilters={this.props.availableFilters} />
                  </div>

                  <div className={`form-group m-0 mr-1 ${this.filterVisibility('Operators')}`}>
                    <label className='font-weight-bold mb-0'>Operators</label>
                    <FilterInput property='operators' setFilterValue={this.props.setFilterValue} updateFilters={this.props.updateFilters} filters={this.props.filters} availableFilters={this.props.availableFilters} />
                  </div>

                  <div className={`form-group m-0 mr-1 ${this.filterVisibility('Sites')}`}>
                    <label className='font-weight-bold mb-0'>Sites</label>
                    <FilterInput property='sites' setFilterValue={this.props.setFilterValue} updateFilters={this.props.updateFilters} filters={this.props.filters} availableFilters={this.props.availableFilters} />
                  </div>

                  <div className={`form-group m-0 mr-1 ${this.filterVisibility('Names')}`}>
                    <label className='font-weight-bold mb-0'>Names</label>
                    <FilterInput property='names' setFilterValue={this.props.setFilterValue} updateFilters={this.props.updateFilters} filters={this.props.filters} availableFilters={this.props.availableFilters} />
                  </div>

                  <div className={`form-group m-0 mr-1 ${this.filterVisibility('Statuses')}`}>
                    <label className='font-weight-bold mb-0'>Statuses</label>
                    <FilterInput property='statuses' setFilterValue={this.props.setFilterValue} updateFilters={this.props.updateFilters} filters={this.props.filters} availableFilters={this.props.availableFilters} />
                  </div>

                  <div className={`form-group m-0 mr-1 ${this.filterVisibility('Storage Years')}`}>
                    <label className='font-weight-bold mb-0'>Storage Years</label>
                    <FilterInput property='storageYears' setFilterValue={this.props.setFilterValue} updateFilters={this.props.updateFilters} filters={this.props.filters} availableFilters={this.props.availableFilters} />
                  </div>

                  <div className={`form-group m-0 mr-1 ${this.filterVisibility('Date From')}`}>
                    <label className='font-weight-bold mb-0'>From</label>
                    {this.props.useDashboardDates === true ? <AbsRelDate disabled={true} id='dateFrom' value={fromJS(mapDashboardCriteriaDateFromToAbsRelDate(this.props.dashboardCriteria))} /> : <AbsRelDate id='dateFrom' value={this.props.dateFrom} onChange={value => this.props.setValue(['workspace', 'dateFrom'], value)} />}
                  </div>

                  <div className={`form-group m-0 mr-1 ${this.filterVisibility('Date To')}`}>
                    <label className='font-weight-bold mb-0'>To</label>
                    {this.props.useDashboardDates === true ? <AbsRelDate disabled={true} id='dateTo' value={fromJS(mapDashboardCriteriaDateToToAbsRelDate(this.props.dashboardCriteria))} /> : <AbsRelDate id='dateTo' value={this.props.dateTo} onChange={value => this.props.setValue(['workspace', 'dateTo'], value)} />}
                  </div>

                  {this.props.stateKey === 'default' && <div className='form-group m-0 m-0 mr-1 d-flex pt-3'>
                    <button type='submit' id='refresh' className={` btn ${this.props.refreshRequired ? 'btn-primary' : 'btn-outline-primary'}`}><i className='fas fa-sync-alt fa-fw mr-1' />Refresh</button>
                    {this.props.auctionsTotalCount > 0 && <div className='ml-1 mt-2'><FormattedNumber>{this.props.auctionsTotalCount}</FormattedNumber> results</div>}
                  </div>}
                </form>
              </div>

              <div className='m-0 pt-3 d-flex flex-nowrap' style={{ maxHeight: '50px' }}>
                {this.props.stateKey === 'default' && <ModalDialogButton onOpenDialog={() => { }} buttonClassName='ml-1 btn btn-primary' buttonContent={<i className='fas fa-save fa-fw' title='Save Workspace' />} >
                  {({ closeDialog }) => (
                    <StorageAuctionSaveAsModal modalTitle='Save Auctions Workspace' closeDialog={() => closeDialog()} />
                  )}
                </ModalDialogButton>}
                {this.props.stateKey === 'default' && this.props.canShareWorkspace && <button type='button' id='share' className='ml-1 btn btn-primary text-nowrap' onClick={() => this.props.shareWorkspace()}><i className='fas fa-share fa-fw mr-1' />Share</button>}
                {this.props.anyFiltersApplied && <button type='button' id='clear-filter' className='btn btn-secondary ml-2 mr-2 text-nowrap' onClick={() => this.props.resetFilters()}>Clear Filters</button>}
              </div>
            </div>
          </div>
          <div className='d-flex flex-row w-100 h-100 ' style={{ overflow: 'auto' }}>
            <div className='sticky-table w-100'>
              <table id='auctions-table' className='table table-sm table-striped w-100 test'>
                <thead>
                  <tr className={`sticky-header {}`}>
                    <th className={this.columnVisibility('Location')} ><SortTableHeader text='Location' title='Location' columnKey='location' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Operator')}><SortTableHeader text='Operator' title='Operator' columnKey='operator' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Site')}><SortTableHeader text='Site' title='Site' columnKey='site' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={`w-100 ${this.columnVisibility('Name')}`}><SortTableHeader text='Name' title='Name' columnKey='name' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Status')}><SortTableHeader text='Status' title='Status' columnKey='status' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Storage Year')}><SortTableHeader text='Storage Year' title='Storage Year' columnKey='storageYear' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Capacity Offered')} align="right"><SortTableHeader text='Offered (MWh)' title='Capacity Offered (MWh)' columnKey='capacityOffered' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Total Requested Capacity')} align="right"><SortTableHeader text='Requested (MWh)' title='Total Requested Capacity(MWh)' columnKey='totalRequestedCapacity' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Total Allocated Capacity')} align="right"><SortTableHeader text='Allocated (MWh)' title='Total Allocated Capacity(MWh)' columnKey='totalAllocatedCapacity' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Reserve Price')} align="right"><SortTableHeader text='Reserve Price' title='Reserve Price' columnKey='reservePrice' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Clearing Price')} align="right"><SortTableHeader text='Clearing Price' title='Clearing Price' columnKey='clearingPrice' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Sale Start')}><SortTableHeader text='Start' title='Sale Start' columnKey='saleStartUtc' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Sale End')}><SortTableHeader text='End' title='Sale End' columnKey='saleEndUtc' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Updated (UTC)')}><SortTableHeader text='Updated (UTC)' title='Updated (UTC)' columnKey='updatedUtc' orderBy={this.props.orderBy} orderByDirection={this.props.orderByDirection} setOrderBy={this.props.setOrderBy} /></th>
                    <th className={this.columnVisibility('Source Url')}></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.auctions.size === 0 && <tr><td colSpan='14'>No auctions found</td></tr>}
                  {this.props.auctions.map((row) => (
                    <tr key={row.get('id')} className='fota-analysis-table-row-hover text-nowrap'>
                      <td className={this.columnVisibility('Location')} >{row.get('location')}</td>
                      <td className={this.columnVisibility('Operator')} >{row.get('operator')}</td>
                      <td className={this.columnVisibility('Site')} >{row.get('site')}</td>
                      <td className={this.columnVisibility('Name')} >{row.get('name')}</td>
                      <td className={this.columnVisibility('Status')} >{row.get('status')}</td>
                      <td className={this.columnVisibility('Storage Year')} >{row.get('storageYear')}</td>
                      <td className={this.columnVisibility('Capacity Offered')} align="right">{formatNumber({ value: row.get('capacityOffered'), useGrouping: true })}</td>
                      <td className={this.columnVisibility('Total Requested Capacity')} align="right">{formatNumber({ value: row.get('totalRequestedCapacity'), useGrouping: true })}</td>
                      <td className={this.columnVisibility('Total Allocated Capacity')} align="right">{formatNumber({ value: row.get('totalAllocatedCapacity'), useGrouping: true })}</td>
                      <td className={this.columnVisibility('Reserve Price')} align="right">{formatNumber({ value: row.get('reservePrice'), useGrouping: true, minDecimals: 2, maxDecimals: 2, displayIfZero: '0.00' })}</td>
                      <td className={this.columnVisibility('Clearing Price')} align="right">{formatNumber({ value: row.get('clearingPrice'), useGrouping: true, minDecimals: 2, maxDecimals: 2, displayIfZero: '0.00' })}</td>
                      <td className={this.columnVisibility('Sale Start')} >{row.get('saleStartUtc') ? row.get('saleStartUtc').format('YYYY-MM-DD HH:mm') : ''}</td>
                      <td className={this.columnVisibility('Sale End')} >{row.get('saleEndUtc') ? row.get('saleEndUtc').format('YYYY-MM-DD HH:mm') : ''}</td>
                      <td className={this.columnVisibility('Updated (UTC)')}>{row.get('updatedUtc') ? row.get('updatedUtc').format('YYYY-MM-DD HH:mm') : ''}</td>
                      <td className={this.columnVisibility('Source Url')}>
                        {row.get('sourceUrl') && <a href={row.get('sourceUrl')} rel='noreferrer' target='_blank' title={`Open ${row.get('sourceUrl')}`}>
                          <i className='text-secondary fas fa-external-link-alt fa-fw' />
                        </a>}
                      </td>
                    </tr>))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='d-flex justify-content-between p-0 pt-1 mx-2'>
            <div>
              {this.props.showDiagnostics && <>
                {this.props.elapsed ? <div className='mt-2'>Generated in {moment.duration(this.props.elapsed).asSeconds()} seconds</div> : <>Generated in n/a</>}
              </>}
            </div>
            <div>
              {!this.props.hidePagination && <Pagination shape='rounded' page={this.props.pageIndex} count={this.props.pageCount} onChange={(e, v) => this.props.setPageIndex(v)}></Pagination>}
            </div>
            <div></div>
          </div>
        </Loading>
      </ErrorBoundary>
    </main>
  }
}

function SortTableHeader({ text, title, columnKey, orderBy, orderByDirection, setOrderBy }) {
  return <div id={`column-header-${columnKey}`} title={title} className='d-flex justify-content-between cursor-pointer text-nowrap' onClick={_ => setOrderBy(columnKey)}>
    <span>{text}</span>
    <div className='btn btn-xs btn-link text text-secondary' >
      {orderBy && orderBy === columnKey
        ? orderByDirection === 'desc'
          ? <i className='mt-1 fas fa-sort-down fa-fw' />
          : <i className='mt-1 fas fa-sort-up fa-fw' />
        : <i className='mt-1 fas fa-sort fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} />}
    </div>
  </div>
}

function FilterInput({ property, width = '200px', setFilterValue, updateFilters, filters, availableFilters }) {
  function onToggleValue(value) {
    if (filters.get(property).indexOf(value) >= 0) {
      setFilterValue([property], fromJS(filters.get(property).filter(x => x !== value)));
    }
    else {
      setFilterValue([property], fromJS([...filters.get(property), value]));
    }
  }

  function onClear(e) {
    e.preventDefault();
    e.stopPropagation();
    setFilterValue([property], fromJS([]));
  }

  function onClose() {
    updateFilters(property);
  }

  return <div style={{ width: width }} >
    <PopoverButton onClosePopover={onClose} buttonClassName='form-control form-control-sm p-0 px-1' buttonContent={
      <div className='d-flex flex-row' title={filters.get(property).join(', ')}>
        <div className='flex-fill' style={{ fontSize: '11px', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
          {filters.get(property).map((v, i) => <div key={`${v}-${i}`} style={{ display: 'inline-block', backgroundColor: 'lightgray', padding: '3px', borderRadius: '3px', marginRight: '2px', xmarginBottom: '1px' }}>{v}</div>)}
        </div>
        {filters.get(property).size > 0 && <div key='clear-filters' style={{ padding: '2px', cursor: 'pointer' }} onClick={onClear}><i className='fa fa-times' /></div>}
        <div><i className='fas fa-caret-down' /></div>
      </div>
    }>
      {() => (<>
        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {availableFilters.get(property).size === 0 && <MenuItem key='no-options' disabled>No Options</MenuItem>}
          {availableFilters.get(property).map((a, i) => <MenuItem key={`${a}-${i}`} selected={filters.get(property).indexOf(a) >= 0} onClick={() => onToggleValue(a)}>
            {a}
          </MenuItem>)}
        </div>
      </>)}
    </PopoverButton>
  </div>
}