import React from 'react';

export function ReportsTierToggle({ selectedLevel, tierToggleMap, toggleExpandTier }) {
  return (
    <div className='text-right pl-1 pr-1'>
      <div className='btn-group'>
        { tierToggleMap && tierToggleMap.map(t => (
          <button key={ `tier-${t + 1}` } type='button' className={ `btn btn-sm btn${t + 1 !== selectedLevel ? '-outline' : ''}-primary rounded-lg ml-1 py-0 px-1 font-weight-bold` }
                  onClick={ () => toggleExpandTier(t + 1, t + 1 === selectedLevel) } style={{ fontSize: '.8rem' }}>
            { `${t + 1}` }
          </button>
        )) }
      </div>
    </div>
  );
}