import { useCallback, useState, useEffect } from 'react';
import { authFetch } from '../auth';

export default function useFetch(url, options) {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (!url) return;

    try {
      setError(null);
      setIsLoading(true);

      const response = await authFetch(url, options);

      if (response.status >= 400 && response.status <= 500) throw response;

      const data = await response.json();

      setData(data);
      setIsLoading(false);
    }
    catch (error) {
      setData([]);
      setError(error.message);
      setIsLoading(false);
    }
  }, [options, url]);

  const refreshData = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [error, data, isLoading, refreshData];
}