import React from 'react';
import { withStyles } from 'tss-react/mui';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const StyledSelect = withStyles(Select, ({ palette }) => ({
  root: {
    width: '100%',
    minWidth: '400px',
    height: '25px',
    fontSize: '12px',
    margin: '.1em',
    padding: '0',
    border: '0',
    color: ({ colour }) => palette.getContrastText(colour || '#fff'),
    backgroundColor: ({ colour }) => colour || '#fff'
  }
}));

const StyledMenuItem = withStyles(MenuItem, ({ palette }) => ({
  root: {
    height: '25px',
    fontSize: '12px',
    margin: '.1em',
    color: ({ colour }) => palette.getContrastText(colour || '#fff'),
    backgroundColor: ({ colour }) => colour || '#fff'
  }
}));

const StyledChip = withStyles(Chip, ({ palette }) => ({
  root: {
    height: '25px',
    fontSize: '12px',
    borderRadius: 4,
    margin: '.1em',
    backgroundColor: ({ colour }) => colour || '#ddd'
  }
}));

export default function CompositionToolbar({
  lens, lenses, timeZoneId, timeZones, scenarioAlternatesSummary, showOnlyScenarios, filteredScenarios,
  applyOverrides, refresh, updateProperty, toggleShowOnlyScenarios, selectFilteredScenarios, excludedScenarioCategories
}) {
  const _filteredScenarios = scenarioAlternatesSummary.filter(s => filteredScenarios.some(fs => fs.scenarioName === s.scenarioName));

  return (
    <div className='m-1'>
      <div className='form-row'>
        <div className='form-group col' style={{ maxWidth: '150px', marginBottom: '.5em' }}>
          <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Lens</label>
          <select className='form-control form-control-sm'
            value={lens || ''}
            onChange={e => updateProperty('lens', e.target.value)}>
            {lenses.map(lens => <option key={lens} value={lens}>{lens}</option>)}
          </select>
        </div>
        <div className='form-group col' style={{ maxWidth: '240px', marginBottom: '.5em' }}>
          <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Timezone</label>
          <select className='form-control form-control-sm'
            value={timeZoneId || ''}
            onChange={e => updateProperty('timeZoneId', e.target.value)}>
            {timeZones.map(timeZone => <option key={timeZone} value={timeZone}>{timeZone}</option>)}
          </select>
        </div>

        <div className='form-group col' style={{ maxWidth: '240px', marginBottom: '.5em' }}>
          <label className='d-block' style={{ fontSize: '.9em', marginBottom: 0 }}>&nbsp;</label>
          <div className='btn-group btn-group-sm'>
            <button className='btn btn-primary text-nowrap' onClick={_ => refresh()}>
              <i className='fas fa-sync-alt fa-fw' />
              <span className='px-2 d-none d-md-inline-block'>Refresh</span>
            </button>
            <button className='ml-1 btn btn-primary text-nowrap' onClick={_ => applyOverrides()}>
              <i className='fas fa-sync-alt fa-fw' />
              <span className='px-2 d-none d-md-inline-block'>Apply overrides</span>
            </button>
          </div>
        </div>

        <div className='form-group col' style={{ maxWidth: '240px', marginBottom: '.5em' }}>
          <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>&nbsp;</label>
          <div className='custom-control custom-switch user-select-none'>
            <input type='checkbox' className='custom-control-input' id='show-only-scenarios-toggle'
              checked={showOnlyScenarios}
              onChange={toggleShowOnlyScenarios} />
            <label className='custom-control-label' htmlFor='show-only-scenarios-toggle'>Show Only Scenarios</label>
          </div>
        </div>

        <div className='form-group col' style={{ marginBottom: '.5em' }}>
          <label className='d-block font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Scenarios</label>
          <StyledSelect multiple value={_filteredScenarios}
            onChange={e => selectFilteredScenarios(e.target.value)}
            renderValue={selected => (
              <div className='d-flex w-100'>
                {selected.map((i, ix) => <StyledChip key={ix} label={i.label} />)}
              </div>
            )}>
            {scenarioAlternatesSummary.map((i, ix) => (
              <StyledMenuItem key={ix} value={i}>
                {i.label}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </div>

        <div className='form-group col' style={{ marginBottom: '.5em' }}>
          <label className='d-block font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Excluded Scenario Categories</label>
          <div>{ excludedScenarioCategories.join(', ') }</div>
        </div>
      </div>
    </div>
  );
}