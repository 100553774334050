import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import {
  analysisComparisonModeInit,
  analysisUpdateBasketFromComparisonModeSettings2,
  analysisComparisonModeRemoveAllSelectionFromWorkspace,
  analysisComparisonModeRemoveSelectedDateFromWorkspace
} from '../../../actions/analysis-comparisonmode-v2';

import {
  analysisRefreshTemplateNameExpressions
} from '../../../actions/analysis';

import { ComparisonsDatesPicker } from './ComparisonsDatesPicker';

export const ComparisonsDatesPickerConnectComponent = connect(
  (state) => ({
    comparisonMode: state.getIn(['analysis', 'workspace', 'comparisonMode']),
    allWorkspaceComparisonSettings: state.getIn(['analysis', 'workspace', 'comparisonSettings'])
  }),
  (dispatch) => ({
    removeAllSelection(comparisonMode) {
      dispatch(analysisComparisonModeRemoveAllSelectionFromWorkspace(comparisonMode));
      dispatch(analysisUpdateBasketFromComparisonModeSettings2(comparisonMode));
      dispatch(analysisRefreshTemplateNameExpressions());
    },
    removeSelectedDate(comparisonMode, value) {
      dispatch(analysisComparisonModeRemoveSelectedDateFromWorkspace(comparisonMode, value));
      dispatch(analysisUpdateBasketFromComparisonModeSettings2(comparisonMode));
      dispatch(analysisRefreshTemplateNameExpressions());
    },
    comparisonModeInit(comparisonMode) {
      dispatch(analysisComparisonModeInit(comparisonMode));
    },
  })
)(({ comparisonModeInit, comparisonMode, allWorkspaceComparisonSettings, removeAllSelection, removeSelectedDate }) => {
  const workspaceComparisonSettings = toJS(allWorkspaceComparisonSettings, [])[comparisonMode];
  const workspaceSelection = (workspaceComparisonSettings && workspaceComparisonSettings.windows) || [];
  return <ComparisonsDatesPicker comparisonModeInit={comparisonModeInit} comparisonMode={comparisonMode} workspaceSelection={workspaceSelection} removeSelectedDate={removeSelectedDate} removeAllSelection={removeAllSelection} />
});