import React, { useRef, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import FormattedDateTime from './FormattedDateTime';
import {
  logDismissMessage
} from '../../actions/log';

const containerStyle = {
  position: 'fixed',
  top: '3rem',
  right: '0',
  maxHeight: 'calc(100% - 3rem)',
  overflowY: 'auto',
  zIndex: 9999
};

const toastStyle = {
  backgroundColor: '#fff'
};

const ToastInfoIcon = () => (
  <div className='text-info mr-1' style={{ fontSize: '1.5em' }}>
    <i className='fas fa-info-circle fa-fw'></i>
  </div>
);

const ToastWarningIcon = () => (
  <div className='text-warning mr-1' style={{ fontSize: '1.5em' }}>
    <i className='fas fa-exclamation-circle fa-fw'></i>
  </div>
);

const ToastErrorIcon = () => (
  <div className='text-danger mr-1' style={{ fontSize: '1.5em' }}>
    <i className='fas fa-exclamation-triangle fa-fw'></i>
  </div>
);

function ToastMessage({ id, type, header, body, createdOn, isDismissed, dismissMessage, data_id }) {
  const toastRef = useRef();

  useEffect(() => {
    if (!isDismissed) {
      const options = type === 'info'
        ? { autohide: true, delay: 5000 }
        : { autohide: false }

      window.$(toastRef.current).toast(options).on('hidden.bs.toast', () => dismissMessage(id)).toast('show');
    }
  }, [id, type, isDismissed, dismissMessage]);

  return (
    <div data_id={data_id} ref={ toastRef } className={ `toast fade mb-1 hide` } style={ toastStyle }>
      <div className='toast-header px-2'>
        { (!type || type === 'error') && <ToastErrorIcon /> }
        { type === 'warning' && <ToastWarningIcon /> }
        { type === 'info' && <ToastInfoIcon /> }
        
        <strong data_id='title' className='mr-auto fota-ellipsis-text'>{ header }</strong>
        <button type='button' className='btn btn-sm ml-2 mb-1' data-dismiss='toast'>
          <i className='fas fa-times fa-fw'></i>
        </button>        
      </div>
      <div className='toast-body'>
        <pre data_id='content' style={{ whiteSpace: 'pre-wrap' }} className='p-0 m-0'>{ body }</pre>
        <div className='text-muted text-right pr-0 py-0' style={{fontSize:'8px'}}>
          <FormattedDateTime>{ createdOn }</FormattedDateTime>
        </div>
      </div>
      
    </div>
  );
}

const Notifications = connect(
  (state) => ({
    messages: state.getIn(['log', 'messages'])
  }),
  (dispatch) => ({
    dismissMessage(id) {
      dispatch(logDismissMessage(id));
    }
  })
)(({ messages, dismissMessage }) => {
  const _messages = useMemo(() => messages && messages.toJS
    ? messages.filter(i => !i.get('isDismissed')).toJS() || []
    : messages.filter(i => !i.isDismissed) || [], [messages]);

  function onCloseAll(){
    _messages.forEach(i => dismissMessage(i.id));
  }

  return (
    <div data_id='toast-messages' className='m-1' style={ containerStyle }>     
      { _messages.length > 1 && <div className='d-flex align-content-end justify-content-end'>
        <button type='button' className='btn btn-sm btn-light text-nowrap text-center' data-dismiss='toast' onClick={onCloseAll}>
          Dismiss all <i className='fas fa-times fa-fw'></i>
        </button>        
      </div>}
      { _messages.map((m,i) => <ToastMessage data_id={`toast-message-${i}`} key={ m.id } { ...m } dismissMessage={ dismissMessage } />) }
    </div>
  );
});

export default Notifications;