import React from 'react';
import { connect } from 'react-redux';
import Pagination from '../../shared/Pagination';
import {
  analysisCrossSchemaSearchSetSearchSetPage,
  analysisCrossSchemaSearch
} from '../../../actions/analysis-cross-schema-search';

export const PaginationConnected = connect(
  (state, { stateContext = 'default' }) => ({
    page: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'page']),
    pageSize: state.getIn(['analysisCrossSchemaSearch', stateContext, 'criteria', 'pageSize']),
  }),
  (dispatch, { stateContext = 'default', searchHandler }) => ({
    updatePage(page, pageSize) {
      dispatch(analysisCrossSchemaSearchSetSearchSetPage(stateContext, page, pageSize));
      if (searchHandler) {
        searchHandler(stateContext);
      } else {
        dispatch(analysisCrossSchemaSearch(stateContext));
      }
    }
  })
)(({ page = 1, pageSize = 100, updatePage }) => {
  return <Pagination showTotalPageCount={false} currentPage={page} steps={5} pageSize={pageSize} itemCount={Number.MAX_SAFE_INTEGER} onPageChange={updatePage} />
});