import React from 'react';
import WorkspaceSelector from '../dashboard/WorkspaceSelector';
import { getFullPath } from '../../../utility/workspace-utility';
import TileCommonSettings from './TileCommonSettings';

export default function WebLinkTileOptions({ propertyBag, setProperty }) {
  return {
    View:  (args) => View(args),
    Edit: (args) => Edit(args),
    Settings: (args) => Settings(args),
  };
}

function View({ propertyBag, setProperty }) {
  return <></>
}

function Edit({ propertyBag, setProperty }) {
  return <></>
}

function Settings({ propertyBag, setProperty, dashboardTileType }) {
  function onSelectWorkspace(workspace) {
    setProperty({
      workspacePath: getFullPath({
        workspace: workspace,
        includeType: false
      })
    });
  }

  return <>
    <div className='form-group'>
      <TileCommonSettings propertyBag={propertyBag} setProperty={setProperty} dashboardTileType={dashboardTileType} >
        <div className='form-group'>
          <label className='font-weight-bold'>Image size</label>
          <div className='form-check '>
            <input type='checkbox' className='form-check-input' id='stretchImage' checked={propertyBag.stretchImage} onChange={e => setProperty({ stretchImage: e.target.checked })} />
            <label className='form-check-label' htmlFor='stretchImage'>Fill entire tile area</label>
          </div>
        </div>

        <div className='form-group'>
          <label className='font-weight-bold'>Link address label</label>
          <div className='form-check '>
            <input type='checkbox' className='form-check-input' id='hideLinkAddress' checked={propertyBag.hideLinkAddress} onChange={e => setProperty({ hideLinkAddress: e.target.checked })} />
            <label className='form-check-label' htmlFor='hideLinkAddress'>Hide address label</label>
          </div>
        </div>
      </TileCommonSettings>
    </div>

    <div className='form-group'>
      <label className='font-weight-bold'>Workspace</label>
      <input type='text' readOnly className='form-control' value={propertyBag.workspacePath} onChange={e => setProperty({ workspacePath: e.target.value })} />
      <WorkspaceSelector style={{ marginTop: '1px', border: 'solid 1px #DDDDDD' }} showOnlyItems={['Folder', 'WebLink']} onChange={w => onSelectWorkspace(w)} />
    </div>
  </>
}