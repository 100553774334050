import React from 'react';
import { connect } from 'react-redux';
import {
  analysisTableSelectionCopy,
  analysisTablePasteToSelection,
} from '../../../actions/analysis';
import { toJS } from '../../../utility/immutable-utility';

export const TableClipboardToolbar = connect(
  (state) => ({
    selectionRange: state.getIn(['analysis', 'adjustments', 'editSelection', 'range']),
    canPaste: state.getIn(['analysis', 'adjustments', 'isEditing'])
  }),
  (dispatch) => ({
    copySelection() {
      dispatch(analysisTableSelectionCopy(false));
    },
    copySelectionWithHeaders() {
      dispatch(analysisTableSelectionCopy(true));
    },
    pasteToSelection() {
      dispatch(analysisTablePasteToSelection());
    }
  })
)(({ selectionRange, canPaste, copySelection, copySelectionWithHeaders, pasteToSelection }) => {
  const _hasSelection = Object.keys(toJS(selectionRange)).length > 0;

  function onCopy() {
    copySelection();
  }

  function onCopySelectionWithHeaders() {
    copySelectionWithHeaders();
  }

  function onPaste() {
    pasteToSelection();
  }

  return <>
      <button disabled={!_hasSelection} title='Copy' className='btn btn-sm btn-outline-primary' type='button' onClick={onCopy}>
          <i className='far fa-copy fa-fw' />
      </button>

      <button disabled={!_hasSelection} type="button" className="btn btn-sm btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      </button>
      
      <div className="dropdown-menu">
        <button title='Copy' className='btn text-nowrap' type='button' onClick={onCopy}>
          <span>Copy</span>
        </button>
        <button className='btn text-nowrap' type='button' onClick={onCopySelectionWithHeaders}>
          <span>Copy with headers</span>
        </button>
      </div>

    {canPaste && <button disabled={!_hasSelection} title='Paste' className='btn btn-sm btn-outline-primary text-nowrap' type='button' onClick={onPaste}>
      <i className='fas fa-paste fa-fw' />
    </button>}
  </>
});