import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-widgets/DatePicker';
import { parseDate } from '../../utility/date-utility';

const dateOptions = {
  includeTime: false,
  valueFormat: 'DD MMM YYYY'
};

const dateTimeOptions = {
  includeTime: true,
  valueFormat: 'DD MMM YYYY HH:mm'
};

function DateTimeInput({ focusRef, className, style, isDateOnly, value, onChange, min = '', max = '9999-12-31T00:00' }) {
  const widgetOptions = isDateOnly ? {
    ...dateOptions, onChange: value => onChange(moment(value).startOf('day'))
  } : {
    ...dateTimeOptions, onChange: value => onChange(moment(value))
  };
  const dateValue = value ? parseDate(value) : null;
  return (
    <DatePicker {...widgetOptions}
      containerClassName='fota-rw-widget-picker'
      inputProps={{ style: { padding: '0 .5em' } }}
      className={className}
      style={style}
      min={min ? parseDate(min) : undefined}
      max={max ? parseDate(max) : undefined}
      value={dateValue}
      ref={focusRef} />
  );
};

DateTimeInput.propTypes = {
  focusRef: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  isDateOnly: PropTypes.bool,
  value: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  min: PropTypes.string,
  max: PropTypes.string
};

export default DateTimeInput;