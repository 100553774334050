import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import { mapToTimeSeriesReportRequest, mapToTemplatedNameExpression } from '../utility/analysisReportRequest-utility';
import { fetchPeriodsAbs, fetchTimeSeriesNamesAsync, fetchTimeSeriesReportAsync } from './analysis';
import {
  dashboardTileRefreshBegin,
  dashboardTileRefreshComplete,
  dashboardTileRefreshError,
  fetchWorkspaceAsync,
  fetchPeriodsAsync
} from './dashboard-tiles';
import { mapWorkspaceFromApiModel } from '../mapper/workspaceMapper';
import { applyDynamicFilterOverridesToBasketAsync } from './analysis-dynamic-workspace';
import { dashboardTilesDynamicWorkspaceDropdownsRebuildOptions } from './dashboard-tiles-dynamic-workspace';
import { mapUrlSearchArgsToDynamicFilterOverrides } from '../mapper/analysisDynamicWorkspaceMapper';

export const DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_VALUES = 'DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_VALUES';
export const dashboardTileAnalysisUpdateTableSettingsValues = createAction(DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_VALUES, 'stateKey', 'data');

export const DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH = 'DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH';
export const dashboardTileAnalysisUpdateTableSettingsHeaderWidth = createAction(DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH, 'stateKey', 'key', 'value');

export const dashboardTileAnalysisFromWorkspace = ({ stateKey, workspacePath }) => async (dispatch, getState) => {
  dispatch(dashboardTileRefreshBegin(stateKey));
  try {
    if (!workspacePath || workspacePath.trim().length === 0)
      return;

    const state = getState();
    let workspace = await fetchWorkspaceAsync(`Analysis/${workspacePath}`, dispatch, state, stateKey);
    if (!workspace) {
      dispatch(dashboardTileRefreshError(stateKey, { type: 'error', message: 'no content' }));
      return;
    }

    workspace = mapWorkspaceFromApiModel(`${workspace.id}`, workspace);
    let overrideDynamicWorkspace = toJS(state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'overrideDynamicWorkspace']));

    const urlSearchArgs = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'initUrlSearch']);
    if (urlSearchArgs) {
      const dynamicWorkspaceDropdowns = state.getIn(['dashboardTiles', 'dynamicWorkspaceDropdowns']).toJS();
      overrideDynamicWorkspace = mapUrlSearchArgsToDynamicFilterOverrides(urlSearchArgs, dynamicWorkspaceDropdowns, stateKey, workspace.dynamicWorkspaces);
    }

    if (overrideDynamicWorkspace) {
      const { basket, errors } = await applyDynamicFilterOverridesToBasketAsync(workspace.dynamicWorkspaces, overrideDynamicWorkspace, workspace.timeseries, workspace.baselineBasket);
      if (!basket || basket.length === 0) {
        dispatch(dashboardTileRefreshError(stateKey, { type: 'warning', message: `Timeseries not found for the dynamic filters` }));
        dispatch(dashboardTileAnalysisRefreshComplete(stateKey, { workspace, overrideDynamicWorkspace }));
        return;
      }

      workspace.timeseries = basket;
      if (errors) {
        errors.forEach(message => {
          dispatch(dashboardTileRefreshError(stateKey, { type: 'warning', message }));
        });
      }

      await loadDataAsync(getState());
    } else {
      await loadDataAsync(getState());
    }

    async function loadDataAsync(state) {
      const useDashboardDates = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardDates']) ?? false;
      const useDashboardLens = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardLens']) ?? false;
      const useDashboardTimezone = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardTimezone']) ?? false;
      const criteria = toJS(state.getIn(['dashboardTiles', 'criteria']), {
        periods:[]
      });

      let periodsAbs = [];
      if (useDashboardDates) {
        workspace.fromDateMode = criteria.fromDateMode;
        workspace.fromUtc = criteria.absFromDate;
        workspace.toUtc = criteria.absToDate;
        workspace.toDateMode = criteria.toDateMode;
        workspace.relFrom = criteria.relFromDate;
        workspace.relTo = criteria.relToDate;
        periodsAbs = await fetchPeriodsAsync(dispatch, state);
      }
      else {
        if (workspace.periods && workspace.periods.length > 0)
          periodsAbs = await fetchPeriodsAbs(workspace.periods);
      }

      if (useDashboardLens) {
        workspace.lens = criteria.lens;
      }

      if (useDashboardTimezone) {
        workspace.timeZoneId = criteria.timezoneId;
      }        

      const reportRequest = mapToTimeSeriesReportRequest(workspace, periodsAbs);

      const {timeSeriesReport, errors, warnings} = await fetchTimeSeriesReportAsync(reportRequest);
      errors.forEach(e => dispatch(dashboardTileRefreshError(stateKey, { type: 'error', message: e.message, error: e})));
      warnings.forEach(w => dispatch(dashboardTileRefreshError(stateKey, { type: 'warn', message: w.message})));
      if (timeSeriesReport === null) {
        dispatch(dashboardTileAnalysisRefreshComplete(stateKey, {workspace}));
        return;
      }

      const expressionRequest = mapToTemplatedNameExpression(workspace, periodsAbs);
      const timeSeriesExpressionNames = await fetchTimeSeriesNamesAsync(expressionRequest);

      dispatch(dashboardTileAnalysisSaveBaseline(stateKey, workspace.baselineBasket));
      dispatch(dashboardTileAnalysisRefreshComplete(stateKey, { workspace, timeSeriesReport, baselineBasket: workspace.baselineBasket, timeSeriesExpressionNames, overrideDynamicWorkspace }));
    }
  }
  catch (error) {
    dispatch(dashboardTileRefreshError(stateKey, { type: 'error', message: error }));
  }
  finally {
    dispatch(dashboardTileRefreshComplete(stateKey));
    dispatch(dashboardTilesDynamicWorkspaceDropdownsRebuildOptions());
  }
};

export const DASHBOARD_TILE_ANALYSIS_REFRESH_COMPLETE = 'DASHBOARD_TILE_ANALYSIS_REFRESH_COMPLETE';
const dashboardTileAnalysisRefreshComplete = createAction(DASHBOARD_TILE_ANALYSIS_REFRESH_COMPLETE, 'stateKey', 'data');

export const DASHBOARD_TILE_ANALYSIS_SAVE_BASELINE = 'DASHBOARD_TILE_ANALYSIS_SAVE_BASELINE';
const dashboardTileAnalysisSaveBaseline = createAction(DASHBOARD_TILE_ANALYSIS_SAVE_BASELINE, 'stateKey', 'baseline');
