import { createReducer } from '../utility/redux-utility';
import { fromJS } from 'immutable';
import {
  WORKSPACE_HIT_COUNT_CLEAR,
  WORKSPACE_HIT_COUNT_LOAD_STARTED,
  WORKSPACE_HIT_COUNT_LOAD_STOPPED,
  WORKSPACE_HIT_COUNT_LOAD_COMPLETE
} from '../actions/workspaceHitCount';

export const workspaceHitCount = createReducer(null, {
  [WORKSPACE_HIT_COUNT_CLEAR](state, action) {
    return state.set('isVisible', false)
                .set('isLoading', false)
                .set('hits', fromJS([]));
  },
  [WORKSPACE_HIT_COUNT_LOAD_STARTED](state, action) {
    return state.set('isVisible', true)
                .set('isLoading', true);
  },
  [WORKSPACE_HIT_COUNT_LOAD_STOPPED](state, action) {
    return state.set('isVisible', false)
                .set('isLoading', false);
  },
  [WORKSPACE_HIT_COUNT_LOAD_COMPLETE](state, action) {
    return state.set('isLoading', false)
                .set('hits', fromJS(action.data));
  }
});