import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { WORKSPACES_API_URL } from '../../config';
import useFetch from '../../hooks/useFetch';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import Table from './Table';
import Loading from './Loading';
import Timezone from './Timezone';
import FormattedDateTime from './FormattedDateTime';
import { toJS } from '../../utility/immutable-utility';
import {
  analysisWorkspaceSave
} from '../../actions/analysis';
import {
  dashboardWorkspaceSelect,
  dashboardWorkspaceCopy,
  dashboardWorkspacesLoad,
  dashboardWorkspacesClear
} from '../../actions/dashboard';
import {
  reportsWorkspaceCreate
} from '../../actions/reports';
import {
  storageAuctionsWorkspaceCreate
} from '../../actions/storageAuctions';

const folderColumns = [{
  key: 'folderPath',
  title: 'Folder'
}];

const columns = [{
  key: 'name',
  title: 'Name'
}, {
  key: 'subFolderPath',
  title: 'Sub-folder Path'
}, {
  key: 'owner',
  title: 'Owner'
}, {
  key: 'updatedUtc',
  title: <span>Last Updated <Timezone/></span>
}];

function WorkspaceConfirmationModalContent({ className, isVisible, overwriteName, setShowConfirmation }) {
  const confirmButtonRef = useRef();

  useEffect(() => {
    if (isVisible) confirmButtonRef.current.focus();
  }, [isVisible]);

  return (
    <div className={ `modal-content ${className}` }>
      <div className='modal-header'>
        <h5 className='modal-title'>Overwrite Workspace?</h5>
        <button type='button' className='close' data-dismiss='modal'>&times;</button>
      </div>
      <div className='modal-body'>
        Are you sure you wish to overwrite '{ overwriteName }'?
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' onClick={ () => setShowConfirmation(false) }>Cancel</button>
        <button ref={ confirmButtonRef } type='submit' className='btn btn-primary'>Confirm</button>
      </div>
    </div>
  );
}

function FolderRow({ cellData, rawData, setActiveName }) {
  const { folderPath } = cellData,
        { isActive } = rawData;

  return (
    <tr className={ isActive ? 'table-primary' : '' }
        style={{ userSelect: 'none', cursor: 'pointer' }} onClick={ () => setActiveName(folderPath) }>
      <td className='w-50 text-nowrap'>
        <i className='fas fa-folder fa-fw'></i> { folderPath || (
          <span className='text-muted'>/</span>
        ) }
      </td>
    </tr>
  );
}

function WorkspaceRow({ cellData, rawData, setActiveSubFolderPathAndName }) {
  const { name, subFolderPath, owner, updatedUtc } = cellData,
        { isActive } = rawData;

  const workspaceRowRef = useRef();

  const onSelected = useCallback(() => setActiveSubFolderPathAndName(subFolderPath, name), [name, subFolderPath, setActiveSubFolderPathAndName]);

  useEffect(() => {
    if (isActive) workspaceRowRef.current.scrollIntoView({ block: 'center' });
  }, [isActive]);

  return (
    <tr ref={ workspaceRowRef } className={ isActive ? 'table-primary' : '' }
        style={{ userSelect: 'none', cursor: 'pointer' }} onClick={ onSelected }>
      <td className='w-50 text-nowrap'>{ name }</td>
      <td className='w-50 text-nowrap'>{ subFolderPath }</td>
      <td className='w-25 text-nowrap'>{ owner }</td>
      <td className='text-nowrap'><FormattedDateTime>{ updatedUtc }</FormattedDateTime></td>
    </tr>
  );
}

const WorkspaceSaveAsModalContent = connect(
  (state, ownProps) => {
    let { area = 'homepage', workspace = {} } = ownProps;
    let { type, scope = 'private' } = workspace;

    if (area) area = area.toLowerCase();
    if (type) type = type.toLowerCase();
    if (scope) scope = scope.toLowerCase();

    return {
      data: state.getIn(['dashboard', 'workspaces', area, type, scope, 'structuredData'])
    };
  },
  (dispatch, ownProps) => {
    let { area = 'homepage', workspace = {} } = ownProps;
    let { type, scope = 'private' } = workspace;

    if (area) area = area.toLowerCase();
    if (type) type = type.toLowerCase();
    if (scope) scope = scope.toLowerCase();

    return {
      refreshWorkspaces() {
        dispatch(dashboardWorkspacesLoad({ area, type, scope }));
      }
    }
  }
)(({ isLoading, data, className, modalTitle, nameInputRef, workspace, refreshWorkspaces, showScopeSelector, showFolderSelector, isShared,
  setWorkspaceScope, setWorkspaceFolderPath, setWorkspaceSubFolderPath, setWorkspaceName, setWorkspaceSubFolderPathAndName }) => {
  useEffect(() => {
    if (!data || !data.size) refreshWorkspaces();
  }, [data, refreshWorkspaces]);

  const [, sharedFolders, isLoadingSharedFolders] = useFetch(`${WORKSPACES_API_URL}/workspaces/shared-folders`);

  const _data = useMemo(() => toJS(data, []), [data]);

  const [_folders, _workspaces] = useMemo(() => {
    function flatten(items, folders = [], workspaces = []) {
      for (let i = 0, item; i < items.length && (item = items[i]); i++) {
        const { itemType, folderPath, subFolderPath, name, children } = item;

        if (itemType === 'Root' || itemType === 'Folder') {
          if ((!isShared || itemType !== 'Root') && folders.findIndex(i => i.folderPath === folderPath) < 0)
            if (!isShared /* TEMP: Only show shared folders from Api */)
              folders.push({ ...item, folderPath, isActive: (!workspace.folderPath && !folderPath) || workspace.folderPath === folderPath, children: undefined });

          if (children) flatten(children, folders, workspaces);
        }
        else workspaces.push({ ...item, isActive: ((!workspace.subFolderPath && !subFolderPath) || workspace.subFolderPath === subFolderPath) && workspace.name === name });
      }

      return [folders, workspaces];
    }

    return flatten(_data, isShared && sharedFolders ? sharedFolders.map(sf => ({
      itemType: 'Folder',
      folderPath: sf,
      name: sf,
      isActive: (!workspace.folderPath && !sf) || workspace.folderPath === sf
    })) : []);
  }, [_data, isShared, sharedFolders, workspace]);

  const _folderWorkspaces = useMemo(() => workspace.scope !== 'Private'
    ? _workspaces.filter(i => (!workspace.folderPath && !i.folderPath) || workspace.folderPath === i.folderPath)
    : _workspaces, [_workspaces, workspace.folderPath, workspace.scope]);

  const onSetWorkspaceScope = useCallback(e => setWorkspaceScope(e.target.dataset.value, ''), [setWorkspaceScope]);

  const _canSave = useMemo(() => !isShared || workspace.folderPath, [isShared, workspace.folderPath]);

  const onChangeWorkspaceName = useCallback(e => {
    if (e.shiftKey) switch (e.keyCode) {
      case 56: // *
      case 186: // :
      case 188: // <
      case 190: // >
      case 191: // ?
      case 220: // |
        e.preventDefault();
        break;
      default: break;
    }
    else switch (e.keyCode) {
      case 191: // /
      case 220: // \
        e.preventDefault();
        break;
      default: break;
    }

    if (!_canSave && e.keyCode === 13) e.preventDefault();
  }, [_canSave]);

  const _fullPath = useMemo(() => `${workspace.folderPath || ''}/${workspace.subFolderPath || ''}/${workspace.name}`.replace(/\/+/g, '/'), [workspace.folderPath, workspace.subFolderPath, workspace.name]);

  return (
    <div className={ `modal-content ${className}` }>
      <div className='modal-header'>
        <h5 className='modal-title'>{ modalTitle }</h5>
        <button type='button' className='close' data-dismiss='modal'>&times;</button>
      </div>
      <div className='modal-body'>
        { showScopeSelector && (
          <ul className='nav nav-tabs mb-2'>
            <li className='nav-item'>
              <button className={ `nav-link ${!isShared ? 'active' : ''}` } type='button' data-value='Private' onClick={ onSetWorkspaceScope }>Private</button>
            </li>
            <li className='nav-item ml-1'>
              <button className={ `nav-link ${isShared ? 'active' : ''}` } type='button' data-value='Shared' onClick={ onSetWorkspaceScope }>Shared</button>
            </li>
          </ul>
        ) }
        <div className='d-flex' style={{ position: 'relative' }}>
            { (isShared && showFolderSelector) && (
              <Loading isLoading={ isLoadingSharedFolders } message='Loading folders...'>
                <div className='table-responsive mb-3 mr-3' style={{ maxHeight: '20vh', maxWidth: '10vw', fontSize: '.9em' }}>
                  <Table className='table-hover' columns={ folderColumns } data={ _folders } useTBody={ true } emptyMessage='No existing folders'>
                    <FolderRow setActiveName={ setWorkspaceFolderPath } />
                  </Table>
                </div>
              </Loading>
            ) }
          <Loading isLoading={ isLoading } message='Loading existing workspaces...'>
            <div className='table-responsive mb-3' style={{ height: '20vh', fontSize: '.9em' }}>
              <Table className='table-hover' columns={ columns } data={ _folderWorkspaces } useTBody={ true } emptyMessage='No existing workspaces'>
                <WorkspaceRow setActiveSubFolderPathAndName={ setWorkspaceSubFolderPathAndName } />
              </Table>
            </div>
          </Loading>
        </div>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <label className='input-group-text'>Name</label>
          </div>
          <input ref={ nameInputRef } type='text' className='form-control' value={ workspace.name || '' } maxLength='80'
                 onKeyDown={ onChangeWorkspaceName }
                 onChange={ e => setWorkspaceName(e.target.value) } />
        </div>
        <div className='input-group input-group-sm mt-2'>
          <div className='input-group-prepend'>
            <label className='input-group-text'>Sub-folder Path</label>
          </div>
          <input type='text' className='form-control' value={ workspace.subFolderPath || '' } maxLength='250'
                 onChange={ e => setWorkspaceSubFolderPath(e.target.value) } />
        </div>
        <div className='text-muted text-italic mt-2'>
          <strong>Full Path:</strong> { _fullPath }
        </div>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
        <button type='submit' className='btn btn-primary' disabled={ !_canSave }>Save</button>
      </div>
    </div>
  );
});

function WorkspaceSaveModal({ modalTitle = 'Save Workspace', selectedWorkspace, saveWorkspace, visibility, isVisible, setVisibility, area,
  useConfirmation = true, showScopeSelector = true, showFolderSelector = true }) {
  const [workspace, setWorkspace] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);

  const _selectedWorkspace = useMemo(() => toJS(selectedWorkspace, {}), [selectedWorkspace]);

  useEffect(() => setWorkspace({ ..._selectedWorkspace }), [_selectedWorkspace]);

  const modalRef = useRef()
  const nameInputRef = useRef();

  const setWorkspaceScope = useCallback((scope, folderPath) => setWorkspace({ ...workspace, scope, folderPath }), [setWorkspace, workspace]);
  const setWorkspaceFolderPath = useCallback(folderPath => setWorkspace({ ...workspace, folderPath }), [setWorkspace, workspace]);
  const setWorkspaceSubFolderPath = useCallback(subFolderPath => setWorkspace({ ...workspace, subFolderPath: (subFolderPath || '').replace(/\/+/g, '/') }), [setWorkspace, workspace]);
  const setWorkspaceName = useCallback(name => setWorkspace({ ...workspace, name }), [setWorkspace, workspace]);
  const setWorkspaceSubFolderPathAndName = useCallback((subFolderPath, name) => setWorkspace({ ...workspace, name, subFolderPath: (subFolderPath || '').replace(/\/+/g, '/') }), [setWorkspace, workspace]);

  const isShared = useMemo(() => workspace.scope === 'Shared', [workspace.scope]);

  useDidMountEffect(() => {
    const bootstrapModal = window.$(modalRef.current).modal({
      show: false,
      backdrop: 'static'
    })
    .on('shown.bs.modal', () => nameInputRef.current && nameInputRef.current.focus())
    .on('hidden.bs.modal', () => onDismiss());

    return () => bootstrapModal.modal('hide');
  });

  useEffect(() => {
    if(visibility !== 'external')
      window.$(modalRef.current).modal(isVisible ? 'show' : 'hide');
  }, [isVisible, visibility]);

  const onDismiss = useCallback(() => {
    setShowConfirmation(false);
    setVisibility(false);
  }, [setVisibility]);

  const onConfirm = useCallback(e => {
    e.preventDefault();

    saveWorkspace(workspace);
    onDismiss();
  }, [onDismiss, saveWorkspace, workspace]);

  const onSubmit = useCallback(e => {
    e.preventDefault();

    if (useConfirmation && workspace.id) setShowConfirmation(true);
    else onConfirm(e);
  }, [onConfirm, useConfirmation, workspace.id]);

  if (visibility === 'external')
  return <form onSubmit={ showConfirmation ? onConfirm : onSubmit }>
      <WorkspaceConfirmationModalContent className={ showConfirmation ? '' : 'd-none' }
                                        isVisible={ showConfirmation }
                                        overwriteName={ workspace.name }
                                        setShowConfirmation={ setShowConfirmation } />
      <WorkspaceSaveAsModalContent className={ showConfirmation ? 'd-none' : '' }
                                  modalTitle={ modalTitle }
                                  nameInputRef={ nameInputRef }
                                  workspace={ workspace }
                                  setWorkspaceScope={ setWorkspaceScope }
                                  setWorkspaceFolderPath={ setWorkspaceFolderPath }
                                  setWorkspaceSubFolderPath={ setWorkspaceSubFolderPath }
                                  setWorkspaceSubFolderPathAndName={ setWorkspaceSubFolderPathAndName }
                                  setWorkspaceName={ setWorkspaceName }
                                  showScopeSelector={ showScopeSelector }
                                  showFolderSelector={ showFolderSelector }
                                  isShared={ isShared }
                                  area={ area } />
    </form>

  return (
    <div ref={ modalRef } className='modal fade' tabIndex='-1'>
      <form className={ ('modal-dialog modal-dialog-centered' + (isShared && showFolderSelector ? ' modal-lg' : ' modal-lg')) } onSubmit={ showConfirmation ? onConfirm : onSubmit }>
        <WorkspaceConfirmationModalContent className={ showConfirmation ? '' : 'd-none' }
                                           isVisible={ showConfirmation }
                                           overwriteName={ workspace.name }
                                           setShowConfirmation={ setShowConfirmation } />
        <WorkspaceSaveAsModalContent className={ showConfirmation ? 'd-none' : '' }
                                     modalTitle={ modalTitle }
                                     nameInputRef={ nameInputRef }
                                     workspace={ workspace }
                                     setWorkspaceScope={ setWorkspaceScope }
                                     setWorkspaceFolderPath={ setWorkspaceFolderPath }
                                     setWorkspaceSubFolderPath={ setWorkspaceSubFolderPath }
                                     setWorkspaceSubFolderPathAndName={ setWorkspaceSubFolderPathAndName }
                                     setWorkspaceName={ setWorkspaceName }
                                     showScopeSelector={ showScopeSelector }
                                     showFolderSelector={ showFolderSelector }
                                     isShared={ isShared }
                                     area={ area } />
      </form>
    </div>
  );
}

export const AnalysisWorkspaceSaveAsModal = connect(
  (state) => ({
    selectedWorkspace: state.getIn(['analysis', 'workspace'])
  }),
  (dispatch) => ({
    saveWorkspace(data) {
      dispatch(analysisWorkspaceSave(data));
      dispatch(dashboardWorkspacesClear(data));
    }
  })
)(props => {
  return (
    <WorkspaceSaveModal { ...props } area='homepage' type='analysis' scope='private' />
  );
});

export const ReportWorkspaceSaveAsModal = connect(
  null, (dispatch) => ({
    saveWorkspace(data = {}) {
      const { scope, folderPath, subFolderPath, name } = data;

      dispatch(reportsWorkspaceCreate(null, `${scope || 'private'}/${folderPath || ''}/${subFolderPath || ''}/${name}`.replace(/\/+/g, '/')));
      dispatch(dashboardWorkspacesClear(data));
    }
  })
)(props => {
  const selectedWorkspace = {
    id: 0,
    name: '',
    folderPath: '',
    subFolderPath: '',
    type: 'Report',
    scope: 'Private',
    version: 2
  };

  return (
    <WorkspaceSaveModal { ...props } selectedWorkspace={ selectedWorkspace }
                        showScopeSelector={ false }
                        showFolderSelector={ false }
                        area='homepage' type='report' scope='private' />
  );
});

export const WorkspaceCopyToModal = connect(
  (state) => ({
    selectedWorkspace: state.getIn(['dashboard', 'workspaces', 'selected'])
  }),
  (dispatch) => ({
    saveWorkspace(data) {
      dispatch(dashboardWorkspaceCopy(data));
    },
    dismissSelectedWorkspace() {
      dispatch(dashboardWorkspaceSelect());
    }
  })
)(props => {
  const { selectedWorkspace, dismissSelectedWorkspace } = props;

  return (
    <WorkspaceSaveModal { ...props } setVisibility={ dismissSelectedWorkspace } isVisible={ !!selectedWorkspace } />
  );
});

export const StorageAuctionSaveAsModal = connect(
  (state) => ({
    id: state.getIn(['storageAuctions', 'workspaceLocation', 'id']),
    name: state.getIn(['storageAuctions', 'workspaceLocation', 'name']),
    scope: state.getIn(['storageAuctions', 'workspaceLocation', 'scope']),
    folderPath: state.getIn(['storageAuctions', 'workspaceLocation', 'folderPath']),
    subFolderPath: state.getIn(['storageAuctions', 'workspaceLocation', 'subFolderPath'])
  }), (dispatch) => ({
    saveWorkspace(data = {}) {
      dispatch(storageAuctionsWorkspaceCreate(data));
      dispatch(dashboardWorkspacesClear(data));
    }
  })
)(props => {
  const selectedWorkspace = {
    id: props.id,
    name: props.name ?? '',
    folderPath: props.folderPath ?? '',
    subFolderPath: props.subFolderPath ?? '',
    type: 'StorageAuction',
    scope: props.scope ?? 'Private',
    version: 1,
    data : props.workspace
  };

  return (
    <WorkspaceSaveModal { ...props } visibility='external' setVisibility={props.closeDialog} isFoldersVisible={ true } showScopeSelector={ true } 
     selectedWorkspace={ selectedWorkspace }
                        area='homepage' type='storageAuction' scope='private' />
  );
});
