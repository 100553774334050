import React from 'react';
import { isNotEmpty } from '../../../utility/text-utility';
import ConfirmButton from '../../shared/ConfirmButton';
import UserNameLink from '../../shared/UserNameLink';
import Tooltip from '../../shared/Tooltip';

export default function ScreenCaptureTableReadRow({ isEditing, action, selectedId, openEdit, selectAction, deleteAction, goToAction }) {
  function onSelect(e) {
    if (isEditing) return;
    e.preventDefault();
    e.stopPropagation();
    selectAction(action.get('id'));
  }
  function onEdit(e) {
    if (action.get('isInvalid') || isEditing) return;
    e.preventDefault();
    e.stopPropagation();
    openEdit(action.get('id'));
  }
  function onGoToAction(e) {
    e.preventDefault();
    e.stopPropagation();
    goToAction(action.get('id'));
  }
  const dataStyle = { verticalAlign: 'middle', opacity: action.get('isEnabled') ? '1' : '.6' };
  return (
    <tr onClick={onSelect}>
      <td className='text-primary text-center' style={{ verticalAlign: 'middle' }} onClick={onEdit}>
        { action.get('isInvalid') ? (
          <Tooltip placement='right' title={<div>
                Multiple action parameters are not supported in this view.<br />
                Can only be edited within the actions page.
              </div>}>
            <i className='fas fa-exclamation-triangle fa-fw text-warning mx-1' style={{ lineHeight: '1.5' }} />
          </Tooltip>
        ) : (
          <button className='btn btn-link p-0 mx-1 border-0' type='button' style={{ fontSize: '.9rem' }} onClick={onEdit} title='Edit page capture' disabled={isEditing}>
            <i className='fas fa-edit fa-fw' />
          </button>
        ) }
      </td>
      <td style={dataStyle}>
        <div className='custom-control custom-checkbox text-center'>
          <input className='form-check-input' type='checkbox' checked={action.get('isEnabled')} readOnly={true} />
        </div>
      </td>
      <td className='text-center' style={dataStyle}>{ action.get('cronSpec') }</td>
      <td style={dataStyle}>{ action.get('subject') }</td>
      <td style={dataStyle}>
        { action.get('recipients').split(';').filter(isNotEmpty).map((i, ix) => ix === 0
          ? <UserNameLink key={ix}>{ i }</UserNameLink>
          : <><span>,</span> <UserNameLink key={ix}>{ i }</UserNameLink></>
        ) }
      </td>
      <td style={dataStyle}>
        <a target='_blank' href={ action.get('targetUrl') } style={{ wordBreak: 'break-all' }} rel='noreferrer'>{ action.get('targetUrl') }</a>
      </td>
      <td className='text-primary text-center' style={{ verticalAlign: 'middle', cursor: 'pointer' }}>
        <div className='d-flex' style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          <button className='btn btn-link p-0 mx-1 border-0' type='button' style={{ fontSize: '.9rem' }} onClick={onGoToAction} title='Open page capture action edit page'>
            <i className='fas fa-external-link-alt fa-fw' />
          </button>
          <ConfirmButton type='button'
            className='btn btn-link py-0 px-1'
            confirmClassName='btn btn-link py-0 px-1 btn-warning'
            onClick={() => deleteAction(action.get('id'))}
            content={<span className='d-inline-block text-nowrap'><i className='fas fa-trash fa-fw' /><span className='invisible'>?</span></span>}
            confirm={<span className='d-inline-block text-nowrap'><i className='fas fa-trash fa-fw' /><span>?</span></span>}
            disabled={isEditing} />
        </div>
      </td>
      <td className='text-primary text-center p-0' style={{ verticalAlign: 'middle', width: '25px' }}>
        { (action.get('id') === selectedId) && <i className='fas fa-check-circle fa-fw' style={{ lineHeight: '1.5', fontSize: '1.2rem' }} /> }
      </td>
    </tr>
  );
}