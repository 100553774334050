import React from 'react';

export default function PageHeader({ containerClassName, children }) {
  return children ? (
    <div className={ `d-flex justify-content-center ${containerClassName || ''}` }>
      <div className='shadow-sm py-1 px-3 mb-1 bg-white text-center rounded'>
        <div className='font-weight-normal my-1' style={{ fontSize: '1em' }}>{ children }</div>
      </div>
    </div>
  ) : null;
}