import React, { Fragment } from 'react';
import ConfirmButton from '../../../shared/ConfirmButton';
const groupStyle = { marginBottom: '.5em' };

export default function HistoryToolbar({ isItemSelected, refreshFunction, revertFunction }) {
  return (
      <div className='ml-0 form-row'>
        <div className='form-group ml-1 d-flex align-items-end' style={groupStyle}>
          <div className='btn-group btn-group-sm'>
            <button className='btn text-nowrap btn-secondary' onClick={refreshFunction}>
              <i className='fas fa-sync-alt fa-fw' />
              <span className='px-2 d-none d-md-inline-block'>Refresh</span>
            </button>
            {
              isItemSelected && (
                <ConfirmButton type='button'
                               className='btn text-nowrap btn-primary' 
                               confirmClassName='btn btn-sm btn-link btn-warning fota-btn-toolbar fota-color-black' 
                               onClick={revertFunction}
                               content={
                                 <Fragment>
                                   <span>Revert</span>
                                   <i className='fas fa-trash fa-fw' />
                                 </Fragment>
                               }
                               confirm={
                                 <Fragment>
                                   <span>Revert?</span>
                                   <i className='fas fa-trash fa-fw' />
                                 </Fragment>
                               } />            
              ) 
            }
            
          </div>
        </div>
      </div>

  );
}