import React from 'react';
import { connect } from 'react-redux';
import {
  dashboardSetValue,
  dashboardSetValues,
  dashboardSetPeriodFrom,
  dashboardSetPeriodTo,
  dashboardSetPeriodOverride
} from '../../../actions/dashboard-tiles';
import AbsRelDate from '../../shared/AbsRelDate';
import { fromJS } from 'immutable';
import { mergePeriodCollectionLists } from '../../../utility/period-utility';

const labelSize = { minWidth: '20%', textWrap:'nowrap'};
const propertySize = { flexGrow:'1' };

export const DashboardCriteriaConnected = connect(
  (state) => ({
    isEditing: state.getIn(['dashboardTiles', 'isEditing']),
    dashboardOptions: state.getIn(['dashboardTiles', 'ui', 'dashboardOptions']),

    lenses: state.getIn(['referenceData', 'lenses']),
    timezones: state.getIn(['referenceData', 'timezones']),

    lensIsHidden: state.getIn(['dashboardTiles', 'criteria', 'lensIsHidden']),
    lens: state.getIn(['dashboardTiles', 'criteria', 'lens']),

    datesIsHidden: state.getIn(['dashboardTiles', 'criteria', 'datesIsHidden']),
    fromDateMode: state.getIn(['dashboardTiles', 'criteria', 'fromDateMode']),
    absFromDate: state.getIn(['dashboardTiles', 'criteria', 'absFromDate']),
    relFromDate: state.getIn(['dashboardTiles', 'criteria', 'relFromDate']),
    toDateMode: state.getIn(['dashboardTiles', 'criteria', 'toDateMode']),
    absToDate: state.getIn(['dashboardTiles', 'criteria', 'absToDate']),
    relToDate: state.getIn(['dashboardTiles', 'criteria', 'relToDate']),

    timezoneIdIsHidden: state.getIn(['dashboardTiles', 'criteria', 'timezoneIdIsHidden']),
    timezoneId: state.getIn(['dashboardTiles', 'criteria', 'timezoneId']),

    periodsReferenceData: state.getIn(['dashboardTiles', 'ui', 'periodsReferenceData']),
    periodsWorkspaces: state.getIn(['dashboardTiles', 'ui', 'periodsWorkspaces']),
    periodSettings: state.getIn(['dashboardTiles', 'criteria', 'periodSettings'])
  }),
  (dispatch) => ({
    setValue(key, value) {
      dispatch(dashboardSetValue(key, value));
    },
    setValues(list) {
      dispatch(dashboardSetValues(list));
    },
    setPeriodOverride(name, value){
      dispatch(dashboardSetPeriodOverride(name,value));
    },
    setPeriodFrom(period, value) {
      dispatch(dashboardSetPeriodFrom(period, value));
    },
    setPeriodTo(name, value) {
      dispatch(dashboardSetPeriodTo(name, value));
    }
  })
)(({ isEditing, dashboardOptions, lenses, timezones,
  lensIsHidden = false, lens,
  datesIsHidden = false, fromDateMode = 'abs', absFromDate, relFromDate,
  toDateMode = 'abs', absToDate, relToDate,
  timezoneIdIsHidden = false, timezoneId,
  setValue, setValues,
  setPeriodOverride, setPeriodFrom, setPeriodTo,
  periodsReferenceData, periodsWorkspaces, periodSettings }) => {

  const _periodSettings = periodSettings.toJS();

  // workspace
  function onSetFromDate(value) {
    setValues([
      [['criteria', 'fromDateMode'], value.get('mode')],
      [['criteria', 'absFromDate'], value.get('abs')],
      [['criteria', 'relFromDate'], value.get('rel')]
    ]);
  }

  function onSetToDate(value) {
    setValues([
      [['criteria', 'toDateMode'], value.get('mode')],
      [['criteria', 'absToDate'], value.get('abs')],
      [['criteria', 'relToDate'], value.get('rel')]
    ]);
  }

  // periods
  function onSetPeriodSetting(name, key, value) {
    setValue(['criteria', 'periodSettings', name, key], value);
  }

  if (!timezones || !lenses)
    return <></>

  const _selectablePeriods = mergePeriodCollectionLists(periodsReferenceData.toJS(), periodsWorkspaces.toJS());

  //Only overriden tile settings appear here
  return <div style={{ minWidth: '300px' }}>
    {!dashboardOptions.get('useDashboardLens') && 
      !dashboardOptions.get('useDashboardTimezone') && 
      !dashboardOptions.get('useDashboardDates') && 
      !dashboardOptions.get('useDashboardPeriods') && <>      
      <span>Only overriden tile settings appear here</span>      
    </>}

    {(dashboardOptions.get('useDashboardLens') && (isEditing || !lensIsHidden)) && <div className='fota-dashboard-criteria-property'>
      <div className='d-flex flex-row mb-1'>
        <div style={labelSize}>
          {isEditing && <button type='button' className='btn btn-xs btn-light mr-1' onClick={() => setValue(['criteria', 'lensIsHidden'], !lensIsHidden)}>
            <i className={lensIsHidden ? 'fas fa-eye-slash fa-fw' : 'fas fa-eye fa-fw'}></i>
          </button>}
          <label className='font-weight-bold mt-1' style={{ fontSize: '.9em' }}>Lens</label>
        </div>
        <div style={propertySize}>
          <select className='form-control form-control-sm'
            value={lens || ''}
            onChange={e => setValue(['criteria', 'lens'], e.target.value)}>
            {lenses.map(lens => <option key={lens} value={lens}>{lens}</option>)}
          </select>
        </div>
      </div>
    </div>}

    {(dashboardOptions.get('useDashboardDates') && (isEditing || !datesIsHidden)) && <div className='fota-dashboard-criteria-property'>
      {isEditing && <button type='button' className='btn btn-xs btn-light mr-1' onClick={() => setValue(['criteria', 'datesIsHidden'], !datesIsHidden)}>
        <i className={datesIsHidden ? 'fas fa-eye-slash fa-fw' : 'fas fa-eye fa-fw'}></i>
      </button>}

      <div className='d-flex flex-row mb-1'>
        <div style={labelSize}>
          <label className='font-weight-bold mt-1' style={{ fontSize: '.9em' }}>From</label>
        </div>
        <div style={propertySize}>
          <AbsRelDate value={fromJS({ mode: fromDateMode, abs: absFromDate, rel: relFromDate })} onChange={value => onSetFromDate(value)} />
        </div>
      </div>

      <div className='d-flex flex-row mb-1'>
        <div style={labelSize}>
          <label className='font-weight-bold mt-1' style={{ fontSize: '.9em' }}>To</label>
        </div>
        <div style={propertySize}>
          <AbsRelDate value={fromJS({ mode: toDateMode, abs: absToDate, rel: relToDate })} onChange={value => onSetToDate(value)}  />
        </div>
      </div>
    </div>}

    {periodsWorkspaces.map(p => {
      const name = p.get('name');
      const isUsed = p.get('isUsed') === true;
      const isEditable = p.get('isEditable') === true;
      const hideAbsRelToggle = p.get('hideAbsRelToggle') === true;
      const settings = _periodSettings[name] ?? {
        isHidden: false,
        overriddenByPeriod: undefined,
        from: undefined,
        to: undefined
      };

      const selectedValue = (!settings.overriddenByPeriod && (settings.from || settings.to)) ? '' : (settings.overriddenByPeriod ?? p.get('selectedPeriod'));

      if (!isUsed || (!isEditing && settings.isHidden))
        return <></>

      return <div className='fota-dashboard-criteria-property' key={`period-${name}`}>

        <div className='d-flex'>
          {isEditing && <button type='button' className='btn btn-xs btn-light mr-1' onClick={() => onSetPeriodSetting(name, 'isHidden', !settings.isHidden)} title={`Toggle visibility of '${name}'`}>
            <i className={settings.isHidden ? 'fas fa-eye-slash fa-fw' : 'fas fa-eye fa-fw'}></i>
          </button>}
          {isEditable ?
            <>
              <label>{name}</label>
            </> :
            <>
              <select className={`form-control form-control-sm mb-1`} value={selectedValue} onChange={e => setPeriodOverride(name, e.target.value)}>
                {_selectablePeriods.map(sp => <option key={sp.name} value={sp.name}>{sp.name}</option>)}
                {!selectedValue && <option key={'blank'} value={''}></option>}
              </select>
            </>}
        </div>

        <div className='d-flex flex-row mb-1'>
          <div style={labelSize}>
            <label className='font-weight-bold mt-1' style={{ fontSize: '.9em' }}>From</label>
          </div>
          <div style={propertySize}>
            <AbsRelDate value={settings.from ?? p.get('from')} onChange={value => setPeriodFrom(name, value)} isModeSelectorVisible={!hideAbsRelToggle} />
          </div>
        </div>

        <div className='d-flex flex-row mb-1'>
          <div style={labelSize}>
            <label className='font-weight-bold mt-1' style={{ fontSize: '.9em' }}>To</label>
          </div>
          <div style={propertySize}>
            <AbsRelDate value={settings.to ?? p.get('to')} onChange={value => setPeriodTo(name, value)} isModeSelectorVisible={!hideAbsRelToggle} />
          </div>
        </div>

      </div>
    })}

    {(dashboardOptions.get('useDashboardTimezone') && (isEditing || !timezoneIdIsHidden)) && <div className='fota-dashboard-criteria-property'>
      <div className='d-flex flex-row mb-1'>
        <div style={labelSize}>
          {isEditing && <button type='button' className='btn btn-xs btn-light mr-1' onClick={() => setValue(['criteria', 'timezoneIdIsHidden'], !timezoneIdIsHidden)}>
            <i className={timezoneIdIsHidden ? 'fas fa-eye-slash fa-fw' : 'fas fa-eye fa-fw'}></i>
          </button>}
          <label className='font-weight-bold mt-1 mr-1' style={{ fontSize: '.9em' }}>Timezone</label>
        </div>
        <div style={propertySize}>
          <select className='form-control form-control-sm'
            value={timezoneId || ''}
            onChange={e => setValue(['criteria', 'timezoneId'], e.target.value)}>
            {timezones.map(timezone => <option key={timezone} value={timezone}>{timezone}</option>)}
          </select>
        </div>
      </div>
    </div>}
  </div>
});