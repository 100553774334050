import React from 'react';

const monthHeaders = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 * range = {
 * years:[{
 *   name : '2020',
 *   dates :[{
 *     isSelected : false,
 *     name}]
 * }]
 */
export default function CalendarMonthPicker({ range, topLeftClick, columnClick, rowClick, cellClick, rowHeaderStyle, columnHeaderStyle, cellStyle, headers = monthHeaders }) {
  rowHeaderStyle = rowHeaderStyle || '';
  columnHeaderStyle = columnHeaderStyle || '';
  cellStyle = cellStyle || '';

  function onTopLeftClick() {
    if (topLeftClick) topLeftClick();
  }

  function onColumnClick(column) {
    if (columnClick) columnClick(column);
  }

  function onRowClick(row) {
    if (rowClick) rowClick(row);
  }

  function onCellClick(month) {
    if (cellClick) cellClick(month);
  }

  return <>
    <div className='scrolling-table' style={{ width: '767px' }}>
      <div className='scrolling-tableHead'>
        <div className='scrolling-tableRow'>
          <div className={'scrolling-tableCell spreadsheet-cursor-topLeft spreadsheet-topLeft'} style={{ width: '64px' }} onClick={() => onTopLeftClick()}></div>
          {headers.map(m => <div className={columnHeaderStyle + ' scrolling-tableCell table-header-cell'} style={{ width: '57px' }} key={`m${m}`} onClick={() => onColumnClick(m)}>{m}</div>)}
        </div>
      </div>
    </div>
    <div className='scrolling-table' style={{ width: '767px' }}>
      <div className='scrolling-table-scroll-area'>
        <div className='scrolling-tableBody '>
          {range && range.years && range.years.map(year => (
            <div className='scrolling-tableRow' key={`y${year.name}`}>
              <div className={rowHeaderStyle + ' scrolling-tableCell table-header-cell'} style={{ width: '64px' }} onClick={() => onRowClick(year.name)}>{year.name}</div>
              {year.dates.map((m) => <div className={cellStyle + ' scrolling-tableCell table-cell' + (m.isSelected ? ' table-primary table-checked' : '')} style={{ width: '57px' }} key={`m${m.name}`} onClick={() => onCellClick(m)}></div>)}
            </div>))}
        </div>
      </div>
    </div>
  </>
}