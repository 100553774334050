import React from 'react';
import IdentityId from '../../shared/IdentityId2';
import InfoPopup from '../../shared/InfoPopup';
import { DebugPopup } from '../../shared/DebugPopup';
import { getInfoDisplayProps } from '../../../utility/reportstable-utility'

function getIconClassName(timeSeries) {
  if (timeSeries) {
    switch (timeSeries.expandMode) {
      case 'OnDemand': return 'icon icon-chevron-right-closed';
      case 'OnDemand-Open': return 'icon icon-chevron-down-closed';
      case 'OnDemand-Closed': return 'icon icon-chevron-right-closed';
      case 'Open': return 'icon icon-chevron-down';
      case 'Closed': return 'icon icon-chevron-right';
      case 'Loading': return 'fas fa-spinner fa-spin';
      default: return '';
    }
  }

  return '';
}

export class TimeSeriesRowLabel extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.rowKey !== this.props.rowKey)
      return true;

    if (getIconClassName(nextProps.timeSeries) !== getIconClassName(this.props.timeSeries))
      return true;

    return false;
  }

  render() {
    const { timeSeries, onToggleExpand, onOpenChart, rowKey } = this.props;
    let iconClassName = getIconClassName(timeSeries);
    let { infoClassName, infoMessage } = getInfoDisplayProps(timeSeries);

    return <>
      <button data_id='toggle-expand' type='button' className='btn btn-sm btn-link p-0 mr-2' style={{ lineHeight: 0, visibility: timeSeries.hasChildren ? 'visible' : 'hidden' }} disabled={!timeSeries.hasChildren} onClick={onToggleExpand}>
        <i className={`fa-fw ${iconClassName}`} style={{ fontSize: '.75em', fontWeight: 'bolder' }} />
      </button>

      <span data_id='text'>
        <DebugPopup linkClassName='btn btn-xs' linkContent={<i className='link-hover' style={{color:'green', fontWeight:'bold', textDecoration:'italic'}}>{timeSeries.id}</i>}>
          {() => <>
            <div className='modal-body'>
              id:{timeSeries.id}<br />
              key:{timeSeries.key}<br />
              rowKey:{rowKey}<br />
              <hr />
              <pre>{JSON.stringify(timeSeries, null, 4)}</pre>
            </div>
          </>}
        </DebugPopup>
        {timeSeries.name}
        {!!infoClassName && <InfoPopup {...infoClassName}>
          {infoMessage}
        </InfoPopup>}
        {timeSeries.id !== 0 && <IdentityId hideLabel={true} noButtonBorders={true} additionalButtons={<button type='button' className={`identity-menu-button identity-menu-button-no-border`} onClick={onOpenChart}>
          <i className='fas fa-chart-line fa-fw' />
        </button>}>{timeSeries.id}</IdentityId>}
        {!!timeSeries.scenarioInfo.className && <InfoPopup {...timeSeries.scenarioInfo.className}>
          {timeSeries.scenarioInfo.message}
        </InfoPopup>}
        
      </span>
    </>
  }
}