import { fromJS } from 'immutable';
import moment from 'moment';
import {
  TIMESERIES_DETAILS_MATERIALISE_SET_RANGE_DIALOG_VISIBLE,
  TIMESERIES_DETAILS_MATERIALISE_SET_DATE,
  TIMESERIES_DETAILS_MATERIALISE_SET_SUPPRESS_ARCHIVE,
  TIMESERIES_DETAILS_MATERIALISE_COMPLETE
} from '../actions/timeSeriesDetails-materialise';

export const timeSeriesDetailsMaterialiseReducer = {
  [TIMESERIES_DETAILS_MATERIALISE_SET_RANGE_DIALOG_VISIBLE](state, action) {
    let settings = {
      rangeDialogVisible: action.value,
      suppressArchive: true,
      fromUtc: moment.utc().startOf('year').add(-10, 'year').format('YYYY-MM-DD'),
      toUtc: moment.utc().startOf('year').add(10, 'year').format('YYYY-MM-DD')
    };

    return state.set('materialise', fromJS(settings));
  },
  [TIMESERIES_DETAILS_MATERIALISE_SET_DATE](state, action) {
    return state.setIn(['materialise', action.field], action.value);
  },
  [TIMESERIES_DETAILS_MATERIALISE_SET_SUPPRESS_ARCHIVE](state, action) {
    return state.setIn(['materialise', 'suppressArchive'], action.value);
  },
  [TIMESERIES_DETAILS_MATERIALISE_COMPLETE](state, action) {
    return state.set('materialise', fromJS({
        rangeDialogVisible: false,
        suppressArchive: true,
        fromUtc: moment.utc().startOf('year').add(-10, 'year').format('YYYY-MM-DD'),
        toUtc: moment.utc().startOf('year').add(5, 'year').format('YYYY-MM-DD')
    }));
  }
};