import { useLayoutEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { parse as qsParse } from 'querystring';
import {
  outagesPathLoad
} from '../../../actions/outages';
import { useLocation } from 'react-router';

const OutagesLoaderConnected = connect(
  null, (dispatch) => ({
    loadWorkspace(path) {
      dispatch(outagesPathLoad(path, '/power/outages'));
    }
  })
)(({ children, loadWorkspace }) => {
  const location = useLocation();
  const _outagesPath = useMemo(() => {
    const criteria = qsParse(location.search.startsWith('?') ? location.search.substring(1) : location.search);
    return decodeURIComponent(criteria.outagesPath ?? '');
  }, [location.search]);

  useLayoutEffect(() => {
    if (_outagesPath) loadWorkspace(_outagesPath);
  }, [loadWorkspace, _outagesPath]);

  return children;
});

export default OutagesLoaderConnected;