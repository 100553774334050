import {
  parseNumber
} from '../components/shared/FormattedNumber';

export const allToLocaleLowerCase = (...args) => args.map(i => i.toLocaleLowerCase());

export const copyToClipboard = (value) => {
  if (value === undefined || value == null) return;

  return navigator.clipboard.writeText(value).catch(() => copyToClipboardFallback(value));
}

const copyToClipboardFallback = (value) => {
  let element = document.createElement('textarea');
  element.value = value;
  element.setAttribute('readonly', '');
  element.style.position = 'absolute';
  element.style.left = '-9999px';
  document.body.appendChild(element);
  element.select();
  document.execCommand('copy');
  document.body.removeChild(element);
}

export const isEmpty = value => value === undefined || value === null || value === '';

export const areEmpty = (...values) => values.every(isEmpty);

export const isNotEmpty = value => !isEmpty(value);

export const areNotEmpty = (...values) => values.every(isNotEmpty);

export async function getClipboardTextAsync() {
  const permissions = await navigator.permissions.query({ name: "clipboard-read" });
  if (permissions.state !== "granted" && permissions.state !== "prompt") {
    return { success: false, message: 'No access to clipboard' };
  } else {
    const text = await navigator.clipboard.readText();
    return { success: true, text };
  }
}

export function mapCSVToArray(text) {
  if (text === undefined || text === null)
    return { success: false, message: `No table data in the clipboard` };

  let leftHeaderRemoved = false;
  let topHeaderRemoved = false;

  let lines = text.split('\r\n');

  // if the last row is empty
  if (lines.length > 1 && lines[lines.length - 1].length === 0) {
    lines = lines.slice(0, lines.length - 1);
  }

  let table = lines.map(r => r.split('\t').map(parceCell));

  // if we have non numerical in the first column - exclude it
  if (table.length > 1) {
    const firstColumn = table.map(r => r[0]);
    if (firstColumn.every((val,i) => val === undefined || (i === 0 && val === null))) {
      leftHeaderRemoved = true;
      for (let index = 0; index < table.length; index++) {
        table[index] = table[index].filter((_, i) => i > 0);
      }
    }
  }

  // if we have non numerical in the row exclude it
  if (table.length > 1) {
    if (table[0].every(val => val === undefined)) {
      topHeaderRemoved = true;
      table = table.slice(1);
    }
  }

  // validate csv shape
  const cellsPerRow = [...new Set(table.map(row => row.length))];
  if (cellsPerRow.length !== 1) {
    return { success: false, message: `Not possible to convert an irregular shape` };
  }

  /// validate numeric values in cells
  let isValidValues = true;
  table.forEach(row => {
    row.forEach(cell => {
      if (cell === undefined)
        isValidValues = false;
    });
  });
  if (!isValidValues) return { success: false, message: `Not possible to convert all data to values` };

  return { success: true, table, leftHeaderRemoved, topHeaderRemoved };
}

function parceCell(val) {
  const trimmed = val.replace(/["]/g, "");
  if (trimmed === '') return null;

  return parseNumber(trimmed);
}