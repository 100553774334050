import { fromJS } from 'immutable';
import {
  DASHBOARD_TILE_WEBLINK_REFRESH_COMPLETE
} from '../actions/dashboard-tiles-webLink';

export const dashboardTilesWebLinkReducer = {
  [DASHBOARD_TILE_WEBLINK_REFRESH_COMPLETE](state, action) {
    if (!action.workspace) {
      return state.deleteIn(['tilesState', action.stateKey, 'workspace']);
    }
    
    const workspace = action.workspace;
    return state.setIn(['tilesState', action.stateKey, 'workspace'], fromJS(workspace));
  }
};