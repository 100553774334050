import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { ETL_API_URL } from '../../../config';
import Loading from '../../shared/Loading';
import useFetch from '../../../hooks/useFetch';
import {
  functionsSubmitRequest
} from '../../../actions/functions';

const Functions = connect(
  (state) => ({
    isWizard: state.getIn(['roles', 'isWizard'])
  }),
  (dispatch) => ({
    submitRequest(data) {
      dispatch(functionsSubmitRequest(data));
    }
  })
)(({ isWizard, submitRequest }) => {
  const [error, functionData, isLoading] = useFetch(`${ETL_API_URL}/jobrequests`);
  const [functionParameters, setFunctionParameters] = useState({});
  const [functionIndex, setFunctionIndex] = useState(0);

  useEffect(() => {
    if (functionData && functionData.length >= functionIndex) {
      setFunctionParameters(functionData[functionIndex].parameters || {});
    }
  }, [functionData, functionIndex]);

  const onParameterChange = useCallback((key, value) => {
    setFunctionParameters({
      ...functionParameters,
      [key]: value
    });
  }, [functionParameters]);

  const onSubmit = useCallback(e => {
    if (!isWizard)
      return;

    e.preventDefault();

    if (functionData && functionData.length) submitRequest({
      ...functionData[functionIndex],
      parameters: functionParameters
    });
  }, [isWizard, functionData, functionIndex, functionParameters, submitRequest]);

  return (
    <Loading isLoading={ isLoading } message='Loading jobs...'>
      <div className='container-fluid pt-3'>
        { error && (
          <div className='alert alert-warning alert-dismissible fade show'>
            { error }
            <button type='button' className='close' data-dismiss='alert'>
              <span>&times;</span>
            </button>
          </div>
        ) }
        { (!functionData || !functionData.length) ? (
          <div className='alert alert-info'>
            No job loaded
          </div>
        ) : (
          <div className='row'>
            <div className='col-md-5'>
              <form onSubmit={ onSubmit }>
                <div className='form-group'>
                  <label className='font-weight-bold' htmlFor='select-function-name'>Job name</label>
                  <select className='custom-select' id='select-function-name'
                          onChange={ e => setFunctionIndex(e.target.value) }
                          value={ functionIndex }>
                    { functionData.map((i, ix) => <option key={ ix } value={ ix }>{ i.jobName }</option>) }
                  </select>
                </div>
                { Object.keys(functionParameters).map((i, ix) => (
                  <div key={ i } className='form-group'>
                    <label className='font-weight-bold' htmlFor={ `function-parameter-${ix}` }>{ i }</label>
                    <input type='text' className='form-control' id={ `function-parameter-${ix}` }
                           onChange={ e => onParameterChange(i, e.target.value) }
                           value={ functionParameters[i] } />
                  </div>
                )) }
                <button type='submit' disabled={!isWizard} className='btn btn-primary float-right'>Submit</button>
              </form>
            </div>
          </div>
        ) }
      </div>
    </Loading>
  );
});

export default Functions;