import React from 'react';
import Table from '../../shared/Table';
import Pagination from '../../shared/Pagination';
import OutagesResultsRow from './OutagesResultsRow';

export const OutageColumns = [{
  key: 'source',
  title: 'Source'
}, {
  key: 'biddingZoneEic',
  title: 'Bidding Zone'
}, {
  key: 'productionType',
  title: 'Production Type'
}, {
  key: 'unitEic',
  title: 'Unit Eic'
}, {
  key: 'messageId',
  title: 'Message Id',
  filterable: true
}, {
  key: 'version',
  title: 'Ver.'
}, {
  key: 'status',
  title: 'Status'
}, {
  key: 'unavailableCapacity',
  title: 'Unavail. Cap.'
}, {
  key: 'eventStartUtc',
  title: 'Event Start (UTC)'
}, {
  key: 'eventEndUtc',
  title: 'Event End (UTC)'
}, {
  key: 'fotaStatus',
  title: 'Fota Status'
}, {
  key: 'generationData',
  title: 'Generation Data'
}, {
  key: 'processedUtc',
  title: 'Processed (UTC)'
}];

function mapStatusToClassName(value) {
  switch (value.toLocaleLowerCase()) {
    case 'new': return 'text-white bg-primary';
    case 'ignored': return 'text-dark bg-light outage-ignored';
    case 'invalid': return 'text-white bg-danger';
    case 'validated': return 'bg-warning';
    case 'processed': return 'text-white bg-success';
    default: return '';
  }
}

export default function OutagesResults({ data, selectedOutage, page, pageSize, filters, resultCount, selectOutage, updateFilter, updatePage }) {
  return (
    <>
      <div className='mb-2 table-responsive sticky-table'>
        <Table className='table-hover table-striped' columns={OutageColumns} data={data}
          filters={filters} onFilterChange={updateFilter}
          useTBody={true} useStickyHeader={true}>
          <OutagesResultsRow selectedOutage={selectedOutage} selectOutage={selectOutage} mapStatusToClassName={mapStatusToClassName} />
        </Table>
      </div>
      <div>
        <Pagination steps={5} currentPage={page} pageSize={pageSize} itemCount={resultCount}
          onPageChange={updatePage} />
      </div>
    </>
  );
};