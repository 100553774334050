import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import LngDeals from './LngDeals';
import {
  lngDealsAdd,
  lngDealsEdited,
  lngDealsRefresh,
  lngDealsSave  
} from '../../../actions/lngDeals';

const LngDealsConnected = connect(
  (state) => ({
    isInitialised: state.getIn(['lngDeals', 'isInitialised']),
    isLoading: state.getIn(['lngDeals', 'isLoading']),
    country: state.getIn(['lngDeals', 'country']),
    delivery: state.getIn(['lngDeals', 'delivery']),
    entity: state.getIn(['lngDeals', 'entity']),
    status: state.getIn(['lngDeals', 'status']),
    deals: state.getIn(['lngDeals', 'deals']),
  }),
  (dispatch) => ({
    initialise() {
      dispatch(lngDealsRefresh());
    },
    add() {
      dispatch(lngDealsAdd());
    },
    edited(data, property, value) {
      dispatch(lngDealsEdited(data, property, value))
    },
    save() {
      dispatch(lngDealsSave())
    },
  })
)(({ isLoading, country, delivery, entity, status, deals, initialise, add, edited, save }) => {

  const _country = toJS(country);
  const _delivery = toJS(delivery);
  const _entity = toJS(entity);
  const _status = toJS(status);
  const _deals = toJS(deals);

  return (
    <LngDeals initialise={initialise} 
              isLoading={isLoading} 
              country={_country} 
              delivery={_delivery} 
              entity={_entity} 
              status={_status} 
              deals={_deals} 
              add={add}
              edited={edited} 
              save={save} />
  );
});

export default LngDealsConnected;