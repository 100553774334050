import React from 'react';
import FormattedDateTime from '../../shared/FormattedDateTime';
import FormattedNumber from '../../shared/FormattedNumber';

export default function OutagesResultsRow({ rawData, selectedOutage, selectOutage, mapStatusToClassName }) {
  let { source, messageId, version, status, productionType, unitEic, biddingZoneEic, unavailableCapacity, eventStartUtc, eventEndUtc,
    fotaStatus, generationData, processedUtc } = rawData;
  const { messageId: selectedMessageId, version: selectedVersion } = selectedOutage ?? {};
  const isSelected = selectedMessageId === messageId && selectedVersion === version;

  const rowStyle = { cursor: 'pointer' };
  const badgeStyle = { padding: '.5em 1em' };
  const rowClassName = [(isSelected ? 'outage-selected' : ''), (isSelected ? 'outage-ignored' : '')].filter(i => i).join(' ');

  return (
    <tr className={rowClassName} style={rowStyle} onClick={() => selectOutage(rawData)}>
      <td className='text-nowrap'>{source}</td>
      <td className='text-nowrap'>{biddingZoneEic}</td>
      <td className='text-nowrap'>{productionType}</td>
      <td className='text-nowrap'>{unitEic}</td>
      <td className='text-nowrap'>{messageId}</td>
      <td className='text-nowrap'>{version}</td>
      <td className='text-nowrap'>{status}</td>
      <td className='text-nowrap text-right'>
        <FormattedNumber minDecimals={2} maxDecimals={2}>{unavailableCapacity}</FormattedNumber>
      </td>
      <td className='text-nowrap text-right'>{eventStartUtc}</td>
      <td className='text-nowrap text-right'>{eventEndUtc}</td>
      <td className='text-nowrap text-center'>
        <div className={`badge badge-pill ${mapStatusToClassName(fotaStatus)}`} style={badgeStyle}>
          {fotaStatus}
        </div>
      </td>
      <td className='text-nowrap'>{generationData}</td>
      <td className='text-nowrap'>
        <FormattedDateTime>{processedUtc}</FormattedDateTime>
      </td>
      <td className='text-primary' style={{ minWidth: 30 }}>
        <i className={'fas fa-check-circle fa-lg fa-fw ' + (isSelected ? 'visible' : 'invisible')} />
      </td>
    </tr>
  );
}