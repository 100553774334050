import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ETL_API_URL } from '../config';
import {
  logErrorNotification,
  logInfoNotification
} from './log';
import { replace } from "redux-first-history";
import {
  mapWorkspaceToSearchRequest,
  mapAuctionSearchResponse,
  mapWorkspaceToURLSearchParamsString
} from '../utility/storage-auctions-utility';
import { WORKSPACES_API_URL_ROOT } from '../config';
import { copyToClipboard } from '../utility/text-utility';
import { mapToAbsRelDate } from '../mapper/absRelDateMapper';
import { filterNames } from '../state/storage-auctions';
import { dashboardTileRefreshComplete } from './dashboard-tiles';

export const storageAuctionsInit = (stateKey) => (dispatch, getState) => {
  const urlArgs = new URLSearchParams(window.location.search);
  const workspaceId = urlArgs.get('workspace');

  dispatch(storageAuctionsInitStarted(stateKey));
  authFetch(`${ETL_API_URL}/storage-auctions/lookups`)
    .then(response => response.json())
    .then(lookups => {
      dispatch(storageAuctionsInitComplete(stateKey, lookups));
      if (stateKey === 'default' && workspaceId)
        dispatch(storageAuctionsLoadWorkspace(stateKey, workspaceId));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(storageAuctionsInitStopped(stateKey));
    });
};

export const STORAGE_AUCTIONS_RESET_FILTERS = 'STORAGE_AUCTIONS_RESET_FILTERS';
export const storageAuctionsResetFilters = createAction(STORAGE_AUCTIONS_RESET_FILTERS, 'stateKey');

export const STORAGE_AUCTIONS_UPDATE_FILTERS = 'STORAGE_AUCTIONS_UPDATE_FILTERS';
export const storageAuctionsUpdateFilters = createAction(STORAGE_AUCTIONS_UPDATE_FILTERS, 'stateKey');

export const STORAGE_AUCTIONS_INIT_STARTED = 'STORAGE_AUCTIONS_INIT_STARTED';
const storageAuctionsInitStarted = createAction(STORAGE_AUCTIONS_INIT_STARTED, 'stateKey');

export const STORAGE_AUCTIONS_INIT_COMPLETE = 'STORAGE_AUCTIONS_INIT_COMPLETE';
const storageAuctionsInitComplete = createAction(STORAGE_AUCTIONS_INIT_COMPLETE, 'stateKey', 'lookups');

export const STORAGE_AUCTIONS_INIT_STOPPED = 'STORAGE_AUCTIONS_INIT_STOPPED';
const storageAuctionsInitStopped = createAction(STORAGE_AUCTIONS_INIT_STOPPED, 'stateKey');

export const STORAGE_AUCTIONS_SET_PAGE_INDEX = 'STORAGE_AUCTIONS_SET_PAGE_INDEX';
export const storageAuctionsSetPageIndex = createAction(STORAGE_AUCTIONS_SET_PAGE_INDEX, 'stateKey', 'pageIndex');

export const STORAGE_AUCTIONS_SET_ORDER_BY = 'STORAGE_AUCTIONS_SET_ORDER_BY';
export const storageAuctionsSetOrderBy = createAction(STORAGE_AUCTIONS_SET_ORDER_BY, 'stateKey', 'orderBy');

export const STORAGE_AUCTIONS_VALUE = 'STORAGE_AUCTIONS_VALUE';
export const storageAuctionsSetValue = createAction(STORAGE_AUCTIONS_VALUE, 'stateKey', 'key', 'value');

export const storageAuctionsLoadWorkspace = (stateKey, workspaceId) => (dispatch, getState) => {
  dispatch(storageAuctionsLoadWorkspaceBegin(stateKey, workspaceId));

  authFetch(`${WORKSPACES_API_URL_ROOT}/v3/workspaces/${workspaceId}`)
    .then(response => response.json())
    .then(workspace => {
      if (workspace.type !== 'StorageAuction') {
        dispatch(logErrorNotification('Invalid workspace'));
        return;
      }

      const data = JSON.parse(workspace.data);
      const workspaceLocation = {
        id: workspace.id,
        name: workspace.name,
        scope: workspace.scope,
        folderPath: workspace.folderPath,
        subFolderPath: workspace.subFolderPath
      };

      dispatch(storageAuctionsLoadWorkspaceComplete(stateKey, workspaceLocation, data));
      const urlArgs = new URLSearchParams(window.location.search);
      urlArgs.delete('workspace');
      dispatch(replace(window.location.pathname + '?' + urlArgs.toString()));
      dispatch(storageAuctionsRefresh(stateKey));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(storageAuctionsLoadWorkspaceStopped(stateKey));
    })
}

export const STORAGE_AUCTIONS_LOAD_WORKSPACE_BEGIN = 'STORAGE_AUCTIONS_LOAD_WORKSPACE_BEGIN';
const storageAuctionsLoadWorkspaceBegin = createAction(STORAGE_AUCTIONS_LOAD_WORKSPACE_BEGIN, 'stateKey', 'workspaceId');

export const STORAGE_AUCTIONS_LOAD_WORKSPACE_COMPLETE = 'STORAGE_AUCTIONS_LOAD_WORKSPACE_COMPLETE';
const storageAuctionsLoadWorkspaceComplete = createAction(STORAGE_AUCTIONS_LOAD_WORKSPACE_COMPLETE, 'stateKey', 'workspace', 'data');

export const STORAGE_AUCTIONS_LOAD_WORKSPACE_STOPPED = 'STORAGE_AUCTIONS_LOAD_WORKSPACE_STOPPED';
const storageAuctionsLoadWorkspaceStopped = createAction(STORAGE_AUCTIONS_LOAD_WORKSPACE_COMPLETE, 'stateKey');

export const STORAGE_AUCTIONS_CLOSE_WORKSPACE = 'STORAGE_AUCTIONS_CLOSE_WORKSPACE';
export const storageAuctionsCloseWorkspace = createAction(STORAGE_AUCTIONS_CLOSE_WORKSPACE);

export const storageAuctionsRefresh = (stateKey) => (dispatch, getState) => {
  const state = getState();
  const workspace = state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace']).toJS();

  dispatch(storageAuctionsRefreshStarted(stateKey));

  const filters = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'filters']);
  if (filters) {
    const dateFrom = filters.get('dateFrom');
    if (dateFrom) workspace.dateFrom = dateFrom.toJS();

    const dateTo = filters.get('dateTo');
    if (dateTo) workspace.dateTo = dateTo.toJS();

    filterNames.forEach(f => {
      const value = filters.get(f);
      if (value) workspace.filters[f] = value.toJS();
    });
  }

  const useDashboardDates = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardDates']) ?? false;
  const criteria = state.getIn(['dashboardTiles', 'criteria']).toJS();
  if (useDashboardDates) {
    workspace.dateFrom = mapToAbsRelDate(criteria.fromDateMode, criteria.absFromDate, criteria.relFromDate);
    workspace.dateTo = mapToAbsRelDate(criteria.toDateMode, criteria.absToDate, criteria.relToDate);
  }

  const searchRequest = mapWorkspaceToSearchRequest(workspace);
  authFetch(`${ETL_API_URL}/storage-auctions/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(searchRequest)
  })
    .then(response => response.json())
    .then(data => {
      const { diagnostics = {
        elapsed: '',
      } } = data;

      dispatch(storageAuctionsRefreshComplete(stateKey, mapAuctionSearchResponse(data.auctions), data.totalCount, diagnostics));
      dispatch(dashboardTileRefreshComplete(stateKey));
      if (stateKey === 'default')
        dispatch(storageAuctionsUpdateUrl(stateKey));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(storageAuctionsRefreshStopped(stateKey));
    });
};

export const STORAGE_AUCTIONS_REFRESH_STARTED = 'STORAGE_AUCTIONS_REFRESH_STARTED';
const storageAuctionsRefreshStarted = createAction(STORAGE_AUCTIONS_REFRESH_STARTED, 'stateKey');

export const STORAGE_AUCTIONS_REFRESH_STOPPED = 'STORAGE_AUCTIONS_REFRESH_STOPPED';
const storageAuctionsRefreshStopped = createAction(STORAGE_AUCTIONS_REFRESH_STOPPED, 'stateKey');

export const STORAGE_AUCTIONS_REFRESH_COMPLETE = 'STORAGE_AUCTIONS_REFRESH_COMPLETE';
const storageAuctionsRefreshComplete = createAction(STORAGE_AUCTIONS_REFRESH_COMPLETE, 'stateKey', 'auctions', 'auctionsTotalCount', 'diagnostics');

export const storageAuctionsUpdateUrl = (stateKey) => (dispatch, getState) => {
  const state = getState();
  const searchArgs = mapWorkspaceToURLSearchParamsString(state, stateKey);
  dispatch(replace(window.location.pathname + '?' + searchArgs));
}

export const STORAGE_AUCTIONS_WORKSPACE_CREATE_STARTED = 'STORAGE_AUCTIONS_WORKSPACE_CREATE_STARTED';
export const storageAuctionsWorkspaceCreateStarted = createAction(STORAGE_AUCTIONS_WORKSPACE_CREATE_STARTED);

export const storageAuctionsWorkspaceCreate = ({ id, folderPath, subFolderPath, name, type, scope }) => (dispatch, getState) => {
  const state = getState();

  if (!folderPath && !!subFolderPath) {
    const parts = subFolderPath.split('/').filter(i => !!i);

    if (parts.length === 1) {
      folderPath = parts[0];
      subFolderPath = '';
    }
    else {
      folderPath = parts[0];
      subFolderPath = parts.slice(1).join('/');
    }
  }

  const data = state.getIn(['storageAuctions', 'tiles', 'default', 'workspace']).toJS();
  const params = {
    id, type, scope, folderPath, subFolderPath, name,
    data: JSON.stringify(data, undefined, 2)
  };

  dispatch(storageAuctionsWorkspaceCreateStarted());

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v1/workspaces`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })
    .then(response => response.json())
    .then(workspace => {
      const { workspaceId } = workspace;
      dispatch(storageAuctionsWorkspaceCreateComplete(workspaceId, scope, folderPath, subFolderPath, name));
      dispatch(logInfoNotification('Workspace saved'));
    })
    .catch(error => {
      dispatch(storageAuctionsWorkspaceCreateComplete());
      dispatch(logErrorNotification(error));
    });
};

export const STORAGE_AUCTIONS_WORKSPACE_CREATE_COMPLETE = 'STORAGE_AUCTIONS_WORKSPACE_CREATE_COMPLETE';
export const storageAuctionsWorkspaceCreateComplete = createAction(STORAGE_AUCTIONS_WORKSPACE_CREATE_COMPLETE, 'id', 'scope', 'folderPath', 'subFolderPath', 'name');

export const storageAuctionsCopyWorkspaceUrlToClipboard = () => (dispatch, getState) => {
  const state = getState();
  const workspaceLocation = state.getIn(['storageAuctions', 'workspaceLocation']).toJS();
  const { id, scope } = workspaceLocation;

  if (!id) return;
  if (scope !== 'Shared') {
    dispatch(logInfoNotification('Only shared workspaces can be shared'))
    return;
  }

  const shareUrl = `${window.location.origin}/storage-auctions?workspace=${id}`;
  copyToClipboard(shareUrl);
  dispatch(logInfoNotification('Workspace Url copied'))
};