import React from 'react';
import Tooltip from './Tooltip';

function RequiredField({ children }) {
  const content = (children && children) || 'Required Field';
  return <Tooltip placement='right' title={<>{content}</>}>
    <span style={{ color: '#F00', padding: '3px' }}>*</span>
  </Tooltip>
}

export default RequiredField;