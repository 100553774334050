import React, { useRef, useEffect, useState } from 'react';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import useFetch from '../../../hooks/useFetch';
import { connect } from 'react-redux';
import { EVENTS_API_URL } from '../../../config';
import { authFetch } from '../../../auth';
import { logInfoNotification, logErrorNotification } from '../../../actions/log';
import { replace } from "redux-first-history";
import Loading from '../../shared/Loading';
import Modal from '../../shared/Modal';
import DatasetPicker from '../actions/DatasetPicker';
import AuditInfo from '../../shared/AuditInfo';
import { getValueWithFallback } from '../../../utility/property-utlility';
import { useParams } from 'react-router';
import { actionsRunScheduledAction } from '../../../actions/actionsEditor';
import { toJS } from '../../../utility/immutable-utility';
import { ModalDialogButton } from '../../shared/ModalDialogButton';
import JsonEditor from '../../shared/JsonEditor';
import LoadingIcon from '../../shared/LoadingIcon';
import PathNavLink from '../../shared/PathNavLink';

function Editor({ viewConfiguration, id, setId, logInfoNotification, logErrorNotification, updateUrl, name, cronSpec, isEnabled,
  actionType, parameters, notificationSettings, eventParameters, datasets, reloadData, auditInfo, scheduledRun, runScheduledAction }) {
  const [isDirty, setIsDirty] = useState(false);
  const [nameText, setNameText] = useState(name);
  const [cronSpecText, setCronSpecText] = useState(cronSpec);
  const [isEnabledState, setIsEnabledState] = useState(isEnabled);
  const [actionTypeText, setActionTypeText] = useState(actionType);
  const [notificationSettingsText, setNotificationSettingsText] = useState(notificationSettings);
  const [parametersText, setParametersText] = useState(parameters);
  const [eventParametersText, setEventParametersText] = useState(eventParameters);
  const [datasetOptions, setDatasetOptions] = useState(datasets);
  const modalRef = useRef();

  const [currentViewConfig, setCurrentViewConfig] = useState(getValueWithFallback(viewConfiguration, actionTypeText));

  useEffect(() => setCurrentViewConfig(getValueWithFallback(viewConfiguration, actionTypeText)), [actionTypeText, viewConfiguration]);

  useDidUpdateEffect(() => {
    setIsDirty(true);
  }, [nameText, isEnabledState, cronSpecText, actionTypeText, parametersText]);

  function onDelete(e) {
    e.preventDefault();
    modalRef.current.onShow({
      title: 'Delete Scheduled action',
      message: 'Are you sure you want to delete this action?',
      onApply: () => {
        authFetch(`${EVENTS_API_URL}/actions/scheduled/${id}`, {
          method: 'DELETE'
        })
          .then(() => {
            logInfoNotification(`Scheduled action "${nameText}" deleted`);
            updateUrl("../../");
          })
          .catch(error => {
            logErrorNotification(error);
          });
      }
    });
  }

  function onSave(e) {
    e.preventDefault();
    const saveData = {
      id: id,
      name: nameText,
      cronSpec: cronSpecText,
      isEnabled: isEnabledState,
      actionType: actionTypeText,
      notificationSettings: notificationSettingsText && JSON.parse(notificationSettingsText),
      eventParameters: eventParametersText && JSON.parse(eventParametersText),
      parameters: parametersText && JSON.parse(parametersText),
      datasetIds: datasetOptions.map(d => d.id)
    };

    if (saveData.id === undefined) {
      authFetch(`${EVENTS_API_URL}/actions/scheduled`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(saveData)
      })
        .then(response => response.json())
        .then(key => {
          logInfoNotification(`Scheduled action "${saveData.name}" created`);
          setId(key.actionId);
          updateUrl(`${key.actionId}/edit`);
          setIsDirty(false);
        })
        .catch(error => {
          logErrorNotification(error);
        });
      return;
    }

    authFetch(`${EVENTS_API_URL}/actions/scheduled/${saveData.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(saveData)
    })
      .then(response => response.json())
      .then(key => {
        logInfoNotification(`Scheduled action "${saveData.name}" saved`);
        setIsDirty(false);
        reloadData();
      })
      .catch(error => {
        logErrorNotification(error);
      });
  };

  function onRunScheduledAction(){
    runScheduledAction({id, name:nameText});
  }

  const isRunning = (scheduledRun && scheduledRun.getIn(['isRunning']) === true);

  const runResponse = scheduledRun ? {
        data: toJS(scheduledRun.getIn(['data'])),
        error: toJS(scheduledRun.getIn(['error']))
     } : undefined;

  return (
    <div className='container-fluid'>
      <form className='my-3' noValidate >
        <div className='form-row'>
          <div className='col-md-4'>
            {id > 0 ? <div>Edit Scheduled Action [id:{id}]</div> : <div>New Scheduled Action</div>}
          </div>
          <div className='btn-toolbar'>
            {/* <button type='button' className={`ml-1 btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={onSave}>
                <i className='fas fa-arrow-left fa-fw' />
                <span className='ml-8'>Close</span>
              </button> */}
          </div>
        </div>
      </form>
      <div className='row'>
        <div className='col-md-8 col-lg-5'>
          <div className='form-group'>
            <label className='font-weight-bold'>Name</label>
            <input className='form-control' type='text' value={nameText} onChange={e => setNameText(e.target.value)} />
          </div>

          <div className='form-group'>
            <label className='font-weight-bold'>Action Type</label>
            <select className='form-control'
              value={actionTypeText}
              onChange={e => setActionTypeText(e.target.value)}>
              {Object.keys(viewConfiguration).map(k => <option key={k} value={viewConfiguration[k].actionType}>{viewConfiguration[k].text}</option>)}
            </select>
          </div>

          <div className='form-group'>
            <div className='form-check d-inline-block'>
              <input className='form-check-input' type='checkbox' value='' id='is-enabled' checked={isEnabledState} onChange={e => setIsEnabledState(e.target.checked)} />
              <label className='form-check-label font-weight-bold' htmlFor='is-enabled'>Is Enabled</label>
            </div>
          </div>

          <div className='form-group'>
            <label className='font-weight-bold'>Cron (schedule)</label>
            <input className='form-control' type='text' value={cronSpecText} onChange={e => setCronSpecText(e.target.value)} />
            <a target='_blank' href={`https://crontab.guru/#${cronSpecText}`} rel='noopener noreferrer'>Cron helper</a>
          </div>

          {currentViewConfig.showDatasets && <div className='form-group'>
            <label className='font-weight-bold'>Datasets</label>
            <DatasetPicker datasetOptions={datasetOptions} setDatasetOptions={setDatasetOptions} setIsDirty={setIsDirty} />
          </div>}

          <div className='form-group'>
            <label className='font-weight-bold'>Notification Settings</label>
            <button type='button' className='btn btn-sm float-right'
              title="Use defaut notification settings"
              onClick={e => { setNotificationSettingsText(JSON.stringify(currentViewConfig.notificationSettings, undefined, 2)); }}>
              <i className='fas fa-sort-amount-down fa-fw' />
            </button>
            <textarea className='form-control' rows='5' value={notificationSettingsText} onChange={e => setNotificationSettingsText(e.target.value)} />
          </div>

          {currentViewConfig.showParameters && <div className='form-group'>
            <label className='font-weight-bold'>Parameters</label>
            <button type='button' className='btn btn-sm float-right'
              title="Use default parameters"
              onClick={e => { setParametersText(JSON.stringify(currentViewConfig.parameters, undefined, 2)); }}>
              <i className='fas fa-sort-amount-down fa-fw' />
            </button>
            <textarea className='form-control' rows='5' value={parametersText} onChange={e => setParametersText(e.target.value)} />
          </div>}

          {currentViewConfig.showEventParameters && <div className='form-group'>
            <label className='font-weight-bold'>Event Parameters</label>
            <button type='button' className='btn btn-sm float-right'
              title="Use default event parameters"
              onClick={e => { setEventParametersText(JSON.stringify(currentViewConfig.eventParameters, undefined, 2)); }}>
              <i className='fas fa-sort-amount-down fa-fw' />
            </button>
            <textarea className='form-control' rows='5' value={eventParametersText} onChange={e => setEventParametersText(e.target.value)} />
          </div>}

          <div className='d-flex flex-row w-100'>
            <div className='flex-fill form-group'>
              <PathNavLink className='btn btn-outline-secondary' to={ `/actions/` }>Return to actions</PathNavLink>

              <button type='button' disabled={id === undefined || isDirty || isRunning} className={`ml-3 btn ${isRunning ? 'btn-warning' : 'btn-secondary'}`} onClick={onRunScheduledAction}>Run</button>
              {runResponse && <ModalDialogButton buttonClassName='btn btn-outline-secondary ml-1' buttonContent={<><i className='fas fa-eye'></i></>}>
                {runResponse && <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>{nameText}</h5>
                    <button type='button' className='close' data-dismiss>&times;</button>
                  </div>
                  <div className='modal-body'>
                    {isRunning && <LoadingIcon width='2.5em' height='2.5em' />}
                    {runResponse.error && <>
                      <div className='alert alert-warning'>
                        <label className='font-weight-bold'>Error</label>
                      </div>
                      <div style={{ height: '60vh', overflowY: 'auto' }}>
                        <JsonEditor data={runResponse.error} mode='code'/>
                      </div>
                    </>}
                    {runResponse.data && <>
                      <div className='alert alert-info'>
                        <label className='font-weight-bold'>Info</label>
                      </div>
                      <div style={{ height: '60vh', overflowY: 'auto' }}>
                        <JsonEditor data={runResponse.data} mode='code'/>
                      </div>
                    </>}
                  </div>
                  <form className='modal-footer' >
                    <button type='button' className='btn btn-secondary' data-dismiss>Close</button>
                  </form>
                </div>}
              </ModalDialogButton>}
            </div>
            <div className='form-group'>
              {id === undefined ? null :
                <button type='button' className='btn btn-secondary' onClick={onDelete}>
                  <i className='fas fa-trash fa-fw' />
                  <span className='ml-8'>Delete</span>
                </button>}
              <button type='button' className={`ml-1 btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={onSave}>
                <i className='fas fa-save fa-fw' />
                <span className='ml-8'>Save</span>
              </button>
            </div>
          </div>        

          {auditInfo && <div className='form-group mt-3'>
            <AuditInfo owner={auditInfo.owner} createdDateUtc={auditInfo.createdUtc} updatedBy={auditInfo.updatedBy} updatedDateUtc={auditInfo.updatedUtc} />
          </div>}
        </div>
      </div>
      <Modal ref={modalRef} />
    </div>
  );
}

const ScheduledActionEdit = connect(
  (state) => ({
      scheduledRuns: state.getIn(['actionsEditor', 'scheduledRuns'])
  }),
  (dispatch) => ({
      updateUrl(key) {
        dispatch(replace(key));
      },
      logInfoNotification(message) {
        dispatch(logInfoNotification(message));
      },
      logErrorNotification(message) {
        dispatch(logErrorNotification(message));
      },
      runScheduledAction(action) {
        dispatch(actionsRunScheduledAction(action));
      }
  })
)(({ logInfoNotification, logErrorNotification, updateUrl, scheduledRuns, runScheduledAction }) => {
  const params = useParams();
  const [id, setId] = useState(params.id);
  const [getActionUrl, setActionUrl] = useState('');
  const [getDatasetsUrl, setDatasetsUrl] = useState('');
  const [, actionData, actionDataLoading] = useFetch(getActionUrl);
  const [, datasetsData, datasetsDataLoading] = useFetch(getDatasetsUrl);
  const [, configData, configLoading] = useFetch(`${EVENTS_API_URL}/actions/scheduled/config`);

  useEffect(() => {
    if (id !== undefined) {
      setActionUrl(`${EVENTS_API_URL}/actions/scheduled/${id}`);
      setDatasetsUrl(`${EVENTS_API_URL}/actions/scheduled/${id}/datasets`);
    }
  }, [id]);

  function reloadData() {
    setActionUrl(`${EVENTS_API_URL}/actions/scheduled/${id}?${Date.now()}`);
  }

  const scheduledRun = id === undefined ? undefined : scheduledRuns.getIn([id]);

  if (id === undefined) {
    return <Loading isLoading={configLoading || actionDataLoading || datasetsDataLoading}>
      {configData && <Editor
        viewConfiguration={configData}
        id={id}
        setId={setId}
        name={""}
        cronSpec={"0 9 * * *"}
        isEnabled={true}
        actionType={"healthCheck"}
        notificationSettings={JSON.stringify(configData['healthCheck'].notificationSettings || {}, undefined, 2)}
        eventParameters={JSON.stringify(configData['healthCheck'].eventParameters || {}, undefined, 2)}
        parameters={JSON.stringify(configData['healthCheck'].parameters || {}, undefined, 2)}
        datasets={[]}
        logInfoNotification={logInfoNotification}
        logErrorNotification={logErrorNotification}
        updateUrl={updateUrl} />}
    </Loading>;
  }

  return <Loading isLoading={configLoading || actionDataLoading || datasetsDataLoading}>
    {configData && actionData && datasetsData && <Editor
      viewConfiguration={configData}
      runScheduledAction={runScheduledAction}
      scheduledRun={scheduledRun}
      id={id}
      name={actionData.name}
      cronSpec={actionData.cronSpec}
      isEnabled={actionData.isEnabled}
      actionType={actionData.actionType}
      notificationSettings={JSON.stringify(actionData.notificationSettings || { notificationSettings: [] }, undefined, 2)}
      parameters={JSON.stringify(actionData.parameters || {}, undefined, 2)}
      eventParameters={JSON.stringify(actionData.eventParameters || {}, undefined, 2)}
      datasets={datasetsData}
      logInfoNotification={logInfoNotification}
      logErrorNotification={logErrorNotification}
      updateUrl={updateUrl}
      reloadData={reloadData}
      auditInfo={{
        createdUtc: actionData.createdUtc,
        owner: actionData.owner,
        updatedBy: actionData.updatedBy,
        updatedUtc: actionData.updatedUtc
      }}
    />}
  </Loading>;
});

export default ScheduledActionEdit;