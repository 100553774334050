import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import ReportsComposition from './ReportsComposition';
import {
  reportsCompositionApplyOverrides,
  reportsCompositionItemSelected,
  reportsCompositionRefresh,
  reportsCompositionSetScenarioOverride,
  reportsCompositionToggleExpand,
  reportsCompositionUpdateRequestProperty,
  reportsCompositionSetScenarioCsvIds,
  reportsCompositionAddScenarioByCsvIds,
  reportsCompositionRemoveScenarioById,
  reportsCompositionRemoveAllScenarios,
  reportsCompositionAddScenarioByIds,
  reportsCompositionAutoAddScenarios,
  reportsCompositionAddScenarios,
  reportsCompositionResizePanels,
  reportsCompositionToggleShowOnlyScenarios,
  reportsCompositionSelectFilteredScenarios
} from '../../../actions/reports';

const ReportsCompositionConnected = connect(
  (state) => ({
    lens: state.getIn(['reports', 'composition', 'criteria', 'lens']),
    lenses: state.getIn(['referenceData', 'lenses']),
    localReportPath: state.getIn(['reports', 'composition', 'criteria', 'reportPath']),
    displayMap: state.getIn(['reports', 'composition', 'results', 'displayMap']),
    isLoading: state.getIn(['reports', 'composition', 'isLoading']),
    isOverridenLoading: state.getIn(['reports', 'composition', 'isOverridenLoading']),
    showOnlyScenarios: state.getIn(['reports', 'composition', 'showOnlyScenarios']),
    filteredScenarios: state.getIn(['reports', 'composition', 'filteredScenarios']),
    reportPath: state.getIn(['reports', 'criteria', 'reportPath']),
    rows: state.getIn(['reports', 'composition', 'results', 'rows']),
    rowsWithOverrides: state.getIn(['reports', 'composition', 'results', 'rowsWithOverrides']),
    scenarioOverrideMap: state.getIn(['reports', 'composition', 'results', 'scenarioOverrideMap']),
    scenarioRows: state.getIn(['reports', 'composition', 'results', 'scenarioRows']),
    scenarioAlternates: state.getIn(['reports', 'composition', 'results', 'scenarioAlternates']),
    excludedScenarioCategories: state.getIn(['reports', 'composition', 'results', 'excludedScenarioCategories']),
    selectedTimeSeries: state.getIn(['reports', 'composition', 'results', 'selectedTimeSeries']),
    selectedTimeSeriesId: state.getIn(['reports', 'composition', 'results', 'selectedTimeSeriesId']),
    timeZoneId: state.getIn(['reports', 'composition', 'criteria', 'timeZoneId']),
    timeZones: state.getIn(['referenceData', 'timezones']),
    isLoadingScenarios: state.getIn(['reports', 'composition', 'toolbar', 'isLoading']),
    scenarioCsvIds: state.getIn(['reports', 'composition', 'toolbar', 'scenarioCsvIds']),
    panelGroup1: state.getIn(['reports', 'composition', 'panels', 'panelGroup1']),
    panelGroup2: state.getIn(['reports', 'composition', 'panels', 'panelGroup2'])
  }),
  (dispatch) => ({
    applyOverrides() {
      dispatch(reportsCompositionApplyOverrides());
    },
    itemSelected(timeSeriesId) {
      dispatch(reportsCompositionItemSelected(timeSeriesId));
    },
    refresh(reportPath) {
      dispatch(reportsCompositionRefresh(reportPath));
    },
    setScenarioOverride(key, value) {
      dispatch(reportsCompositionSetScenarioOverride(key, value));
    },
    toggleExpand(key) {
      dispatch(reportsCompositionToggleExpand(key));
    },
    updateRequestProperty(name, value) {
      dispatch(reportsCompositionUpdateRequestProperty(name, value));
    },
    setScenarioCsvIds(value) {
      dispatch(reportsCompositionSetScenarioCsvIds(value));
    },
    addScenarioByCsvIds() {
      dispatch(reportsCompositionAddScenarioByCsvIds());
    },
    removeScenarioById(timeSeriesId) {
      dispatch(reportsCompositionRemoveScenarioById(timeSeriesId));
    },
    removeAllScenarios() {
      dispatch(reportsCompositionRemoveAllScenarios());
    },
    addScenarioByIds(timeSeriesIds) {
      dispatch(reportsCompositionAddScenarioByIds(timeSeriesIds));
    },
    autoAddScenarios() {
      dispatch(reportsCompositionAutoAddScenarios());
    },
    addScenarios(data) {
      dispatch(reportsCompositionAddScenarios(data));
    },
    resizePanels(panelGroupName, panels) {
      dispatch(reportsCompositionResizePanels(panelGroupName, panels));
    },
    toggleShowOnlyScenarios() {
      dispatch(reportsCompositionToggleShowOnlyScenarios());
    },
    selectFilteredScenarios(values) {
      dispatch(reportsCompositionSelectFilteredScenarios(values));
    }
  })
)(({
  displayMap, lens, lenses, localReportPath, isLoading, isOverridenLoading, reportPath, rows, rowsWithOverrides, scenarioOverrideMap, scenarioRows, scenarioAlternates, excludedScenarioCategories,
  selectedTimeSeries, selectedTimeSeriesId, timeZoneId, timeZones, applyOverrides, itemSelected, refresh, setScenarioOverride, toggleExpand, updateRequestProperty,
  panelGroup1, panelGroup2, resizePanels, isLoadingScenarioById, scenarioCsvIds, setScenarioCsvIds, addScenarioByCsvIds, removeScenarioById, removeAllScenarios,
  addScenarioByIds, autoAddScenarios, addScenarios, isLoadingScenarios, filteredScenarios, selectFilteredScenarios, showOnlyScenarios, toggleShowOnlyScenarios,
}) => {
  useEffect(() => {
    if (reportPath !== localReportPath) refresh(reportPath);
  }, [refresh, reportPath, localReportPath]);

  const _displayMap = toJS(displayMap, {});
  const _rows = toJS(rows, []);
  const _rowsWithOverrides = toJS(rowsWithOverrides, []);
  const _scenarioOverrideMap = toJS(scenarioOverrideMap, []);
  const _scenarioAlternates = toJS(scenarioAlternates, []);
  const _excludedScenarioCategories = toJS(excludedScenarioCategories, []);
  const _scenarioRows = toJS(scenarioRows, []);
  const _panelGroup1 = toJS(panelGroup1, []);
  const _panelGroup2 = toJS(panelGroup2, []);
  const _filteredScenarios = toJS(filteredScenarios, []);
  const _selectedTimeSeries = toJS(selectedTimeSeries, null);

  return <ReportsComposition applyOverrides={applyOverrides}
                             displayMap={_displayMap}
                             lens={lens}
                             lenses={lenses}
                             isLoading={isLoading}
                             isOverridenLoading={isOverridenLoading}
                             itemSelected={itemSelected}
                             refresh={refresh}
                             reportPath={reportPath}
                             rows={_rows}
                             rowsWithOverrides={_rowsWithOverrides}
                             scenarioOverrideMap={_scenarioOverrideMap}
                             scenarioAlternates={_scenarioAlternates}
                             excludedScenarioCategories={_excludedScenarioCategories}
                             scenarioRows={_scenarioRows}
                             selectedTimeSeries={_selectedTimeSeries}
                             selectedTimeSeriesId={selectedTimeSeriesId}
                             setScenarioOverride={setScenarioOverride}
                             timeZoneId={timeZoneId}
                             timeZones={timeZones}
                             panelGroup1={_panelGroup1}
                             panelGroup2={_panelGroup2}
                             resizePanels={resizePanels}
                             toggleExpand={toggleExpand}
                             updateRequestProperty={updateRequestProperty}
                             isLoadingScenarioById={isLoadingScenarioById}
                             scenarioCsvIds={scenarioCsvIds}
                             setScenarioCsvIds={setScenarioCsvIds}
                             addScenarioByCsvIds={addScenarioByCsvIds}
                             removeScenarioById={removeScenarioById}
                             removeAllScenarios={removeAllScenarios}
                             addScenarioByIds={addScenarioByIds}
                             autoAddScenarios={autoAddScenarios}
                             addScenarios={addScenarios}
                             filteredScenarios={_filteredScenarios}
                             selectFilteredScenarios={selectFilteredScenarios}
                             showOnlyScenarios={showOnlyScenarios}
                             toggleShowOnlyScenarios={toggleShowOnlyScenarios}
                             isLoadingScenarios={isLoadingScenarios} />;
});

export default ReportsCompositionConnected;