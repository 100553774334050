import React from 'react';
import { Routes, Route, useLocation } from 'react-router';
import ReportsCompositionConnected from '../reportComposition/ReportsCompositionConnected';
import ReportsEditor from './ReportsEditor';
import ReportsViewer from './ReportsViewer';
import DashboardWorkspaces from '../dashboard/DashboardWorkspaces';
import DashboardFilter from '../dashboard/DashboardFilter';
import { WorkspaceCopyToModal } from '../../shared/WorkspaceSaveAsModal';
import { Allotment } from "allotment";
import { RegisterDashboardItem } from '../../../mapper/dashboardTileMapper';

export default function Reports() {
  const location = useLocation();

  const isReportEdit = location.pathname.endsWith('/edit');
  const isCompositionEdit = location.pathname.endsWith('/comp');
  const isReportViewer = !isReportEdit && !isCompositionEdit;

  return (
    <main className='d-flex flex-column'>
      <Routes>
        {isCompositionEdit && <Route path='/*' element={ <ReportsCompositionConnected/> } />}
        {isReportEdit && <Route path='/*' element={ <ReportsEditor/> } />}
        {isReportViewer && <Route path='/*' element={ <ReportsViewer/> } />}
        <Route exact path='/' element={ <ReportPicker/> } />
      </Routes>
    </main>
  );
}

RegisterDashboardItem({
  type :'DefinedReport',
  dashboardFilterOptions : ['Lens', 'Dates', 'Timezone'],
  dashboardCriteriaOptions : (workspace, tileConfig) => {
    const dashboardOption = {
      useDashboardLens : tileConfig.useDashboardLens ?? false,
      useDashboardTimezone : tileConfig.useDashboardTimezone ?? false,
      useDashboardDates : tileConfig.useDashboardDates ?? false,
      workspacePeriods: []
    }

    return dashboardOption;
  }
});

function ReportPicker(){
  return <>
    <div className='w-100 d-flex flex-column h-100'>
      <div className='p-2' style={{ maxWidth: '600px' }}>
        <DashboardFilter area='reports'/>
      </div>
      <div className='flex-fill'>
        <Allotment vertical={false} >
          <Allotment.Pane>
            <DashboardWorkspaces title='My Reports' area='reports' type='report' scope='private' />
          </Allotment.Pane>
          <Allotment.Pane>
            <DashboardWorkspaces title='Shared Reports' area='reports' type='report' scope='shared' />
          </Allotment.Pane>        
        </Allotment>
      </div>
    </div>
    <WorkspaceCopyToModal area='reports' modalTitle='Copy Workspace To...' isFoldersVisible={true} useConfirmation={false} showScopeSelector={true} />
  </>
}