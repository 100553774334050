import { createAction } from '../utility/redux-utility';

export const TIMESERIES_DETAILS_SET_FUNCTIONS_STEP_STYLE = 'TIMESERIES_DETAILS_SET_FUNCTIONS_STEP_STYLE';
export const timeSeriesDetailssetFunctionsStepStyleToMultiStep = createAction(TIMESERIES_DETAILS_SET_FUNCTIONS_STEP_STYLE, 'style');

export const TIMESERIES_DETAILS_SET_FUNCTION_TYPE = 'TIMESERIES_DETAILS_SET_FUNCTION_TYPE';
export const timeSeriesDetailsSetFunctionsType = createAction(TIMESERIES_DETAILS_SET_FUNCTION_TYPE, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_ADD_PARAMETER = 'TIMESERIES_DETAILS_FUNCTIONS_ADD_PARAMETER';
export const timeSeriesDetailsFunctionsAddParameter = createAction(TIMESERIES_DETAILS_FUNCTIONS_ADD_PARAMETER, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_DELETE_PARAMETER = 'TIMESERIES_DETAILS_FUNCTIONS_DELETE_PARAMETER';
export const timeSeriesDetailsFunctionsDeleteParameter = createAction(TIMESERIES_DETAILS_FUNCTIONS_DELETE_PARAMETER, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_UPDATE_PARAMETER_VALUE = 'TIMESERIES_DETAILS_FUNCTIONS_UPDATE_PARAMETER_VALUE';
export const timeSeriesDetailsFunctionsUpdateParameterValue = createAction(TIMESERIES_DETAILS_FUNCTIONS_UPDATE_PARAMETER_VALUE, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION = 'TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION';
export const timeSeriesDetailsFunctionsAddFunction = createAction(TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION);

export const TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION = 'TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION';
export const timeSeriesDetailsFunctionsDeleteFunction = createAction(TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION, 'index');

export const TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_UP = 'TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_UP';
export const timeSeriesDetailsFunctionsMoveFunctionUp = createAction(TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_UP, 'index');

export const TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_DOWN = 'TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_DOWN';
export const timeSeriesDetailsFunctionsMoveFunctionDown = createAction(TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_DOWN, 'index');

export const TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_KEY = 'TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_KEY';
export const timeSeriesDetailsFunctionsAddFunctionKey = createAction(TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_KEY, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_KEY = 'TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_KEY';
export const timeSeriesDetailsFunctionsDeleteFunctionKey = createAction(TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_KEY, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_UP = 'TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_UP';
export const timeSeriesDetailsFunctionsMoveFunctionKeyUp = createAction(TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_UP, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_DOWN = 'TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_DOWN';
export const timeSeriesDetailsFunctionsMoveFunctionKeyDown = createAction(TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_DOWN, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_KEY = 'TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_KEY';
export const timeSeriesDetailsFunctionsUpdateFunctionOutputKey = createAction(TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_KEY, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_OUTPUT_CATEGORY = 'TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_OUTPUT_CATEGORY';
export const timeSeriesDetailsFunctionsAddFunctionOutputCategory = createAction(TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_OUTPUT_CATEGORY, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_CATEGORY_VALUE = 'TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_CATEGORY_VALUE';
export const timeSeriesDetailsFunctionsUpdateFunctionOutputCategoryValue = createAction(TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_CATEGORY_VALUE, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_OUTPUT_CATEGORY = 'TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_OUTPUT_CATEGORY';
export const timeSeriesDetailsFunctionsDeleteFunctionOutputCategory = createAction(TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_OUTPUT_CATEGORY, 'data');

export const TIMESERIES_DETAILS_FUNCTIONS_SET_FUNCTION_DISABLED_STATE = 'TIMESERIES_DETAILS_FUNCTIONS_SET_FUNCTION_DISABLED_STATE';
export const timeSeriesDetailsFunctionsSetFunctionDisabledState = createAction(TIMESERIES_DETAILS_FUNCTIONS_SET_FUNCTION_DISABLED_STATE, 'data');