import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import { setUserColours } from '../utility/color-utility';
import { toJS, applyStateChanges } from '../utility/immutable-utility';
import { applyStyles, getTotalVisibleColumnWidths } from '../utility/style-utility';
import {
} from '../utility/analysis-basket-utility';
import {
  addTimeSeriesProjectionsToBasket,
  addOnDemandProjectionsToBasket,
  removeTimeSeriesFromBasket,

  rebuildDisplayNamesToState,
  removeRangeOfTimeSeriesFromBasket,
  updateBasketItem,
  projectTopLevelSettingsToBasket
} from '../utility/analysis-basket-utility';
import {
  mapReportToTable,
  sortTableData
} from '../reducer-functions/analysis-table-mapper';
import {
  clearSelection,
  fromAddress,
  toAddress,
  selectCell,
  selectGridRange,
  selectLine,
  setEditCell,
  setCellAdjustmentValue,
  navigateToNextCell,
  setSelectionAdjustmentValue,
  removeSelectionAdjustments,
  revertSelectionAdjustments,
  updateTimeSeriesMeta,
  getIsHorizontal,
  getTableAddressColumnsLookups,
  getTableAddressRowsLookups,
  getTableAddressDatesLookups
} from '../reducer-functions/analysis';
import {
  ANALYSIS_TOGGLE_EVOLUTION,
  ANALYSIS_TOGGLE_MATERIALISATION_MODE,
  ANALYSIS_TOGGLE_VARIANT_MODE,
  ANALYSIS_SHOW_OVERLAY,
  ANALYSIS_HIDE_OVERLAY,
  ANALYSIS_UI_TOGGLE_COLLAPSIBLE_SIDEBAR,
  ANALYSIS_UI_TOGGLE_CREATE_REPORT_MODAL,
  ANALYSIS_UI_SET_PANEL_WIDTH,
  ANALYSIS_UI_TOGGLE_PANEL_VISIBILITY,
  ANALYSIS_UI_RESIZE_PANELS,
  ANALYSIS_UI_UPDATE_REFLOW_SWITCH,
  ANALYSIS_SEARCH_STARTED,
  ANALYSIS_SEARCH_COMPLETE,
  ANALYSIS_SEARCH_LOAD_CATEGORIES_COMPLETE,
  ANALYSIS_SEARCH_LOAD_STATISTICS_COMPLETE,
  ANALYSIS_UPDATE_CUSTOM_FILTER_HEIGHT,
  ANALYSIS_SEARCH_TOGGLE_EXPAND,
  ANALYSIS_SEARCH_TOGGLE_IN_BASKET,
  ANALYSIS_SEARCH_ADD_RANGE_TO_BASKET,
  ANALYSIS_SEARCH_REMOVE_RANGE_FROM_BASKET,
  ANALYSIS_SELECTION_ADD_ON_DEMAND_DERIVED_SELECTION,
  ANALYSIS_TOGGLE_EXPAND_FACETS,
  ANALYSIS_TOGGLE_EXPAND_DETAILS,
  ANALYSIS_TOGGLE_EXPAND_ITEM_DETAILS,
  ANALYSIS_SET_EXPAND_FACETS,
  ANALYSIS_SET_EXPAND_DETAILS,
  ANALYSIS_SET_SELECTED_SEARCH_SCHEMA,
  ANALYSIS_WORKSPACE_LOAD_COMPLETE,
  ANALYSIS_WORKSPACE_UPDATE,
  ANALYSIS_UPDATE_FACETS,
  ANALYSIS_UPDATE_HIGHCHART_SETTINGS,
  ANALYSIS_UPDATE_TABLE_SETTINGS,
  ANALYSIS_UPDATE_TABLE_SETTINGS_HIDE_DATE,
  ANALYSIS_UPDATE_TABLE_SETTINGS_TITLE,
  ANALYSIS_UPDATE_TABLE_SETTINGS_DATE_FORMAT,
  ANALYSIS_UPDATE_TABLE_SETTINGS_DECIMAL_PLACES,
  ANALYSIS_UPDATE_TABLE_SETTINGS_FONT_SIZE,
  ANALYSIS_UPDATE_TABLE_SETTINGS_ROW_SIZE,
  ANALYSIS_UPDATE_TABLE_SETTINGS_VALUE,
  ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH,
  ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTHS,
  ANALYSIS_UPDATE_TABLE_SETTINGS_ORDER_BY,
  ANALYSIS_UPDATE_CHART_EDIT_SETTINGS,
  ANALYSIS_UPDATE_CHART_EDIT_SETTINGS_VALUE,
  ANALYSIS_APPLY_CHART_EDIT_SETTINGS,
  ANALYSIS_UPDATE_CHART_EDIT_VIEW,
  ANALYSIS_UPDATE_CHART_SETTINGS,
  ANALYSIS_UPDATE_UI_VALUES,
  ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_COMPLETED,
  ANALYSIS_REPORT_CRITERIA_UPDATE_CUSTOM_PERIOD,
  ANALYSIS_SHOW_CHART_EDIT_USER_SETTINGS_MODAL,
  ANALYSIS_HIDE_CHART_EDIT_USER_SETTINGS_MODAL,
  ANALYSIS_SHOW_CHART_EDIT_SERIES_SETTINGS_MODAL,
  ANALYSIS_HIDE_CHART_EDIT_SERIES_SETTINGS_MODAL,
  ANALYSIS_REFRESH_STARTED,
  ANALYSIS_UPDATE_FACET_STYLE,
  ANALYSIS_UPDATE_COLOUR_CYCLE_SETTINGS,
  ANALYSIS_UPDATE_EXPERIMENTAL_SETTINGS,
  ANALYSIS_TOGGLE_TABLE_DESIGN_PANEL_VISIBILITY,
  ANALYSIS_TOGGLE_TABLE_JSON_EDITOR_PANEL_VISIBILITY,
  ANALYSIS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY,
  ANALYSIS_ADJUSTMENTS_BEGIN_EDIT,
  ANALYSIS_ADJUSTMENTS_END_EDIT,
  ANALYSIS_ADJUSTMENTS_SET_SELECTION_START,
  ANALYSIS_ADJUSTMENTS_SET_SELECTION_END,
  ANALYSIS_ADJUSTMENTS_SELECT_LINE,
  ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_CELL,
  ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE,
  ANALYSIS_ADJUSTMENTS_TABLE_PASTE_TO_SELECTION_COMPLETE,
  ANALYSIS_ADJUSTMENTS_VALIDATE_BEGIN,
  ANALYSIS_ADJUSTMENTS_VALIDATE_COMPLETE,
  ANALYSIS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE,
  ANALYSIS_ADJUSTMENTS_REVERT_SELECTION_ADJUSTMENTS,
  ANALYSIS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS,
  ANALYSIS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS,
  ANALYSIS_ADJUSTMENTS_NAVIGATE_CELL,
  ANALYSIS_ADJUSTMENTS_SAVE_CONFIRMATION_REQUIRED,
  ANALYSIS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY,
  ANALYSIS_ADJUSTMENTS_SAVE_BEGIN,
  ANALYSIS_ADJUSTMENTS_SAVE_COMPLETE,
  ANALYSIS_ADJUSTMENTS_RESET_PRE_SAVE_WARNINGS,
  ANALYSIS_ADJUSTMENTS_UPDATE_DEFAULTS,
  ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_STARTED,
  ANALYSIS_WORKSPACE_UPDATE_PERIODS_FROM_USER_SETTINGS
} from '../actions/analysis';
import {
  ANALYSIS_SHAPES_UPDATE_BASKET_FROM_WORKSPACE
} from '../actions/analysis-shapes-v2';
import {
  TIMESERIES_DETAILS_SAVE_ON_DEMAND_DERIVED_COMPLETE
} from '../actions/timeSeriesDetails';
import {
  ANALYSIS_UPDATE_BASKET_FROM_COMPARISONMODE_SETTINGSV2,
} from '../actions/analysis-comparisonmode-v2';
import { compositionReducerV1 } from './analysis-composition-v1';
import { compositionReducerV2 } from './analysis-composition-v2';
import { comparisonModeReducer } from './analysis-comparisonmode-v2';
import { comparisonModeBasketLevelReducer } from './analysis-comparisonmode-basket-level-v2';
import { shapesReducerV2 } from './analysis-shapes-v2';
import { basketReducerV2 } from './analysis-basket-v2';
import { versionsReducer } from './analysis-versions';
import { dynamicWorkspaceReducerV2 } from './analysis-dynamic-workspace';
import { analysisWorkspaceReducer } from './analysis-workspace';
import { LOCATION_CHANGE } from 'redux-first-history';
import { dynamicWorkspaceEditorReducer } from './analysis-dynamic-workspace-editor';
import { mergeBasketToBaseline } from '../reducer-functions/analysis-dynamic-workspace';
import { REFERENCE_DATA_LOAD_PERIODS_COMPLETE, REFERENCE_DATA_LOAD_SHARED_LOOKUPS_COMPLETE } from '../actions/referenceData';
import { USER_SETTINGS_LOAD_COMPLETE } from '../actions/userSettings';
import { areAnyPeriodsChanged, mergePeriodCollectionLists, ofPeriodTypes } from '../utility/period-utility';

const analysisReducer = {
  [ANALYSIS_TOGGLE_EVOLUTION](state, action) {
    return state.setIn(['workspace', 'isEvolution'], !state.getIn(['workspace', 'isEvolution']));
  },
  [ANALYSIS_TOGGLE_MATERIALISATION_MODE](state, action) {
    const mode = state.getIn(['workspace', 'materialisationMode'])
    return state.setIn(['workspace', 'materialisationMode'], mode === 'Disabled' ? 'Enabled' : 'Disabled');
  },
  [ANALYSIS_TOGGLE_VARIANT_MODE](state, action) {
    const mode = state.getIn(['workspace', 'variantMode'])
    return state.setIn(['workspace', 'variantMode'], mode === 'Disabled' ? 'Enabled' : 'Disabled');
  },
  [ANALYSIS_SHOW_OVERLAY](state, action) {
    return state.setIn(['overlay', 'isVisible'], true)
      .setIn(['overlay', 'panelName'], action.value);
  },
  [ANALYSIS_HIDE_OVERLAY](state, action) {
    return state.setIn(['overlay', 'isVisible'], false)
      .setIn(['overlay', 'panelName'], '');
  },
  [ANALYSIS_UI_TOGGLE_COLLAPSIBLE_SIDEBAR](state, action) {
    const isCollapsed = state.getIn(['ui', 'isSideBarCollapsed']);

    return state.setIn(['ui', 'isSideBarCollapsed'], !isCollapsed)
                .setIn(['ui', 'reflowSwitch'], state.getIn(['ui', 'reflowSwitch']) + 1);
  },
  [ANALYSIS_UI_TOGGLE_CREATE_REPORT_MODAL](state, action) {
    return state.setIn(['ui', 'isCreateReportModalVisible'], action.isVisible);
  },
  [ANALYSIS_UI_SET_PANEL_WIDTH](state, action) {
    if (!action.panelWidth) return;

    let newState = state;

    Object.keys(action.panelWidth).forEach(i => {
      newState = newState.setIn(['ui', 'panelWidths', action.key, i], action.panelWidth[i]);
    });

    return newState;
  },
  [ANALYSIS_UI_TOGGLE_PANEL_VISIBILITY](state, action) {
    const value = state.getIn(['ui', 'panelWidths', action.key, 'isHidden']);
    const newValue = !value;
    if (!newValue) {
      state = state.setIn(['overlay', 'isVisible'], false)
                   .setIn(['overlay', 'panelName'], '')
    }

    return state.setIn(['ui', 'panelWidths', action.key, 'isHidden'], newValue)
                .setIn(['ui', 'reflowSwitch'], state.getIn(['ui', 'reflowSwitch']) + 1);
  },
  [ANALYSIS_UI_RESIZE_PANELS](state, action) {
    action.panels.forEach((i, ix) => {
      const key = action.keys[ix];

      state = state.setIn(['ui', 'panelWidths', key, 'size'], i.size);
    });

    return state.setIn(['ui', 'reflowSwitch'], state.getIn(['ui', 'reflowSwitch']) + 1);
  },
  [ANALYSIS_UI_UPDATE_REFLOW_SWITCH](state, action) {
    return state.setIn(['ui', 'reflowSwitch'], state.getIn(['ui', 'reflowSwitch']) + 1);
  },
  [ANALYSIS_SEARCH_STARTED](state, action) {
    return state.setIn(['search', 'isSearching'], true);
  },
  [ANALYSIS_SEARCH_COMPLETE](state, action) {
    if (!action.data) return state.setIn(['search', 'isSearching'], false);

    const filters = state.getIn(['search', 'criteria', 'filters']);

    const facets = action.data.facets.reduce((accumulator, item) => {
      const activeFilter = filters.find(f => f.get('name') === item.key);

      let results = (item.results || []).filter(i => i.value || i.value === 0 || i.value === false).map(i => ({
        ...i, isActive: !!activeFilter && activeFilter.get('value') === i.value
      }));

      if (!results.length && activeFilter)
        results.push({ value: activeFilter.get('value'), count: 0, isActive: true });

      return results.length ? [...accumulator, { key: item.key, results }] : accumulator;
    }, []);

    const isDetailsExpanded = state.getIn(['search', 'enableExpandDetails']);
    const resultsData = (action.data.results || []).map(i => ({ ...i, isDetailsExpanded, displaySchemas: [...(i.schemas ?? [])] }));

    const selectedLevel = state.getIn(['composition', 'selectedLevel']);
    const maxDepth = state.getIn(['composition', 'maxDepth']);

    return state.setIn(['search', 'results', 'data'], fromJS(resultsData))
      .setIn(['search', 'results', 'facets'], fromJS(facets))
      .setIn(['search', 'results', 'count'], action.data.count)
      .setIn(['search', 'isSearching'], false)
      .setIn(['composition'], fromJS({ selectedLevel, maxLevel: 0, maxDepth, data: {}, displayMap: {} }));
  },
  [ANALYSIS_SEARCH_LOAD_CATEGORIES_COMPLETE](state, action) {
    if (!action.data) return state;

    const data = state.getIn(['search', 'results', 'data']),
      index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    return state
      .setIn(['search', 'results', 'data', index, 'categories'], fromJS(action.data.categories))
      .setIn(['search', 'results', 'data', index, 'sourceCategories'], fromJS(action.data.sourceCategories));
  },
  [ANALYSIS_SEARCH_LOAD_STATISTICS_COMPLETE](state, action) {
    if (!action.data) return state;

    const data = state.getIn(['search', 'results', 'data']),
    index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    return state
      .setIn(['search', 'results', 'data', index, 'statistics'], fromJS(action.data));
  },
  [ANALYSIS_UPDATE_CUSTOM_FILTER_HEIGHT](state, action) {
    return state.setIn(['search', 'customFilterHeight'], action.value);
  },
  [ANALYSIS_SET_EXPAND_FACETS](state, action) {
    return state.setIn(['search', 'enableExpandFacets'], action.value);
  },
  [ANALYSIS_TOGGLE_EXPAND_FACETS](state, action) {
    const isEnabled = state.getIn(['search', 'enableExpandFacets']);

    return state.setIn(['search', 'enableExpandFacets'], !isEnabled);
  },
  [ANALYSIS_SET_EXPAND_DETAILS](state, action) {
    return state.setIn(['search', 'enableExpandDetails'], action.value);
  },
  [ANALYSIS_SET_SELECTED_SEARCH_SCHEMA](state, action) {
    return state.setIn(['search', 'selectedSearchSchema'], action.value);
  },
  [ANALYSIS_TOGGLE_EXPAND_DETAILS](state, action) {
    const isEnabled = state.getIn(['search', 'enableExpandDetails']);

    let newState = state.setIn(['search', 'enableExpandDetails'], !isEnabled);

    state.getIn(['search', 'results', 'data']).forEach((_i, ix) => {
      newState = newState.setIn(['search', 'results', 'data', ix, 'isDetailsExpanded'], !isEnabled);
    });

    return newState;
  },
  [ANALYSIS_TOGGLE_EXPAND_ITEM_DETAILS](state, action) {
    const data = state.getIn(['search', 'results', 'data']);
    const index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    const isEnabled = state.getIn(['search', 'results', 'data', index, 'isDetailsExpanded']);

    return state.setIn(['search', 'results', 'data', index, 'isDetailsExpanded'], !isEnabled);
  },
  [ANALYSIS_SEARCH_TOGGLE_EXPAND](state, action) {
    const data = state.getIn(['search', 'results', 'data']);
    const index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    const isExpanded = data.getIn([index, 'isExpanded']);

    return state.setIn(['search', 'results', 'data', index, 'isExpanded'], !isExpanded);
  },
  [ANALYSIS_SEARCH_TOGGLE_IN_BASKET](state, action) {
    const timesSeries = action.data;

    let basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    if (basket.some(ts => `${ts.identityId}` === `${timesSeries.id}`)) {
      // remove from basket
      const updatedBasket = removeTimeSeriesFromBasket(
        `${timesSeries.id}`,
        toJS(state.getIn(['workspace', 'timeseries']), []),
        toJS(state.getIn(['workspace', 'xAxisTimeSeries', 'identityId'])));

      state = state.setIn(['workspace', 'timeseries'], fromJS(updatedBasket))
                   .setIn(['chart', 'refreshRequired'], true);
    }else{
      // add to basket
      const comparisonMode = state.getIn(['workspace', 'comparisonMode']) ?? 'none';
      const { updatedBasket,hasChanged } = addTimeSeriesProjectionsToBasket(
        [timesSeries],
        state.getIn(['workspace', 'defaultNameStyle']),
        false,
        toJS(state.getIn(['workspace', 'timeseries']), []),
        toJS(state.getIn(['workspace', 'shapesSettings', 'selection']), []),
        toJS(state.getIn(['workspace', 'comparisonSettings', comparisonMode]), {}).windows ?? [],
        toJS(state.getIn(['workspace', 'xAxisTimeSeries', 'identityId'])),
        action.selectedSchemaName ?? state.getIn(['search', 'selectedSearchSchema'])
      );

      if (hasChanged)
        state = state.setIn(['workspace', 'timeseries'], fromJS(updatedBasket))
                     .setIn(['chart', 'refreshRequired'], true);
    }

    state = rebuildDisplayNamesToState(state);
    return state;
  },
  [ANALYSIS_SEARCH_ADD_RANGE_TO_BASKET](state, action) {
    if (!action.keys || !action.keys.length || !action.data) return state;

    const comparisonMode = state.getIn(['workspace', 'comparisonMode']) ?? 'none';
    const { updatedBasket, hasChanged } = addTimeSeriesProjectionsToBasket(
      action.data,
      state.getIn(['workspace', 'defaultNameStyle']),
      action.suppressExistsCheck,
      toJS(state.getIn(['workspace', 'timeseries']), []),
      toJS(state.getIn(['workspace', 'shapesSettings', 'selection']), []),
      toJS(state.getIn(['workspace', 'comparisonSettings', comparisonMode]), {}).windows ?? [],
      toJS(state.getIn(['workspace', 'xAxisTimeSeries', 'identityId']))
    );

    if (hasChanged) {
      state = state
        .setIn(['workspace', 'timeseries'], fromJS(updatedBasket))
        .setIn(['workspace', 'isDirty'], true);

      state = rebuildDisplayNamesToState(state);
    }

    return state;
  },
  [ANALYSIS_SEARCH_REMOVE_RANGE_FROM_BASKET](state, action) {
    const basket = removeRangeOfTimeSeriesFromBasket({
      basket: toJS(state.getIn(['workspace', 'timeseries']), []),
      timesSeries: action.keys.map(k => ({ id: k }))
    });

    return state
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['chart', 'refreshRequired'], true);
  },
  [ANALYSIS_SELECTION_ADD_ON_DEMAND_DERIVED_SELECTION](state, action) {
    const comparisonMode = state.getIn(['workspace', 'comparisonMode']) ?? 'none';
    const { updatedBasket } = addOnDemandProjectionsToBasket(
      action.userTimeSeriesSettings,
      state.getIn(['workspace', 'timeZoneId']),
      state.getIn(['workspace', 'lens']),
      toJS(state.getIn(['workspace', 'timeseries']), []),
      toJS(state.getIn(['workspace', 'shapesSettings', 'selection']), []),
      toJS(state.getIn(['workspace', 'comparisonSettings', comparisonMode]), {}).windows ?? [],
      toJS(state.getIn(['workspace', 'xAxisTimeSeries', 'identityId']))
    );

    state = state
      .setIn(['workspace', 'timeseries'], fromJS(updatedBasket))
      .setIn(['workspace', 'isDirty'], true);

    state = rebuildDisplayNamesToState(state);
    return state;
  },
  [TIMESERIES_DETAILS_SAVE_ON_DEMAND_DERIVED_COMPLETE](state, action) {
    const derivationData = action.data.derivationData;
    const basket = updateBasketItem({
      basket: toJS(state.getIn(['workspace', 'timeseries']), []),
      basketItem: {
        key: action.key,
        derivationData: derivationData,
        name: derivationData.name,
        unit: derivationData.unit,
        granularity: derivationData.granularity.granularityType,
        granularityFrequency: derivationData.granularity.granularityFrequency,
        sourceTimeZoneId: derivationData.sourceTimeZoneId
      }
    });

    return state
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['chart', 'refreshRequired'], true);
  },
  [ANALYSIS_WORKSPACE_LOAD_COMPLETE](state, action) {
    state = state.setIn(['workspacePath'], undefined);
    
    const { workspace } = action;
    if (!workspace)
      return state;

    const periods = state.getIn(['ui', 'periodsUserSettings']);
    const periodsUnsyncronised = areAnyPeriodsChanged(workspace.periods ? workspace.periods : [], periods ? periods.toJS() : [] );

    return state.setIn(['chart', 'refreshRequired'], true)
      .setIn(['adjustments', 'isEditing'], false)
      .setIn(['workspace'], fromJS(workspace))
      .setIn(['ui', 'isTableDesignPanelVisible'], true)
      .setIn(['ui', 'isTableJsonEditorPanelVisible'], false)
      .setIn(['table', 'data'], [])
      .setIn(['table', 'headers'], [])
      .setIn(['adjustments', 'dirtyCellsMap'], {})
      .setIn(['workspace', 'isUnsaved'], false)
      .setIn(['workspace', 'initialPageUrl'], workspace.pageUrl)
      .setIn(['ui', 'periodsUnsyncronised'], periodsUnsyncronised);
  },
  [ANALYSIS_WORKSPACE_UPDATE](state, action) {
    const { scope, folderPath, subFolderPath, name } = action.data || {};
    const pageUrl = state.getIn(['workspace', 'pageUrl']);

    return state.setIn(['workspace', 'id'], action.key)
      .setIn(['workspace', 'scope'], scope)
      .setIn(['workspace', 'folderPath'], folderPath)
      .setIn(['workspace', 'subFolderPath'], subFolderPath)
      .setIn(['workspace', 'name'], name)
      .setIn(['workspace', 'isUnsaved'], false)
      .setIn(['workspace', 'initialPageUrl'], pageUrl);
  }, 
  [ANALYSIS_UPDATE_FACETS](state, action) {
    return state.setIn(['search', 'criteria', 'facets'], fromJS(action.data));
  },
  [ANALYSIS_UPDATE_HIGHCHART_SETTINGS](state, action) {
    return state.setIn(['workspace', 'chartSettings'], fromJS(action.data));
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS](state, action) {
    const tableSettings = action.data;
    const source = toJS(state.getIn(['source'])) ?? {};
    const basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const startDate = state.getIn(['chart', 'startDate']);
    const endDate = state.getIn(['chart', 'endDate']);

    const { tableStyles, tableRowStyles, tableData, tableHeaders, addressLookup, statistics } = mapReportToTable(tableSettings, basket, source, startDate, endDate);
    state = state.setIn(['table', 'styles'], fromJS(tableStyles))
      .setIn(['table', 'rowStyles'], fromJS(tableRowStyles))
      .setIn(['table', 'headers'], fromJS(tableHeaders))
      .setIn(['table', 'data'], fromJS(tableData))
      .setIn(['table', 'addressLookup'], fromJS(addressLookup))
      .setIn(['data', 'statistics'], fromJS(statistics))
      .setIn(['workspace', 'tableSettings'], fromJS(action.data));

    return state;
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_HIDE_DATE](state, action) {
    var propertyValue = action.value;
    state = state.setIn(['workspace', 'tableSettings', 'hideDate'], !!propertyValue);

    const isHorizontal = getIsHorizontal(state);
    if (!isHorizontal) {
      const data = state.getIn(['table', 'data']);
      const headers = toJS(state.getIn(['table', 'headers']));
      if (headers && headers.length > 0) {
        const header = headers.find(h => h.key === 'dateTime');
        const displayValue = propertyValue ? 'none' : 'unset';
        header.styles.display = displayValue;
        for (var rowIndex = 0; rowIndex < data.size; rowIndex++) {
          state = state.setIn(['table', 'data', rowIndex, 'dateTime', 'styles', 'display'], displayValue);
        }

        const tableWidth = getTotalVisibleColumnWidths(headers);
        state = state.setIn(['table', 'styles', 'width'], tableWidth)
          .setIn(['table', 'headers'], fromJS(headers));
      }
    }
    else {
      const headers = toJS(state.getIn(['table', 'headers']));
      headers.forEach(h => {
        h.styles.display = propertyValue ? 'none' : 'unset';
      });
      state = state.setIn(['table', 'headers'], fromJS(headers));
    }

    return state;
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_TITLE](state, action) {
    var propertyValue = action.value;
    state = state.setIn(['workspace', 'tableSettings', 'headerType'], propertyValue);

    const basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const isHorizontal = getIsHorizontal(state);
    if (!isHorizontal) {
      const headers = toJS(state.getIn(['table', 'headers']));
      basket.forEach(s => {
        const header = headers.find(h => h.key === s.key);
        switch (propertyValue) {
          case 'both': header.title = `${s.identityId}: ${s.name}`; break;
          case 'name': header.title = `${s.name}`; break;
          default: header.title = `${s.identityId}`; break;
        }
      });
      state = state.setIn(['table', 'headers'], fromJS(headers));
    }
    else {
      basket.forEach((s, rowIndex) => {
        let title = '';
        switch (propertyValue) {
          case 'both': title = `${s.identityId}: ${s.name}`; break;
          case 'name': title = `${s.name}`; break;
          default: title = `${s.identityId}`; break;
        }

        state = state.setIn(['table', 'data', rowIndex, 'title', 'value'], title);
      });
    }

    return state;
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_DATE_FORMAT](state, action) {
    var propertyValue = action.value;
    state = state.setIn(['workspace', 'tableSettings', 'dateFormat'], propertyValue);

    const headers = toJS(state.getIn(['table', 'headers']));
    const data = state.getIn(['table', 'data']);
    headers.forEach(h => {
      h.specialisedStyles.dateFormat = propertyValue;
      for (var rowIndex = 0; rowIndex < data.size; rowIndex++) {
        state = state.setIn(['table', 'data', rowIndex, h.key, 'specialisedStyles', 'dateFormat'], propertyValue);
      }
    });

    return state.setIn(['table', 'headers'], fromJS(headers));
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_DECIMAL_PLACES](state, action) {
    var propertyValue = action.value;
    state = state.setIn(['workspace', 'tableSettings', 'decimalPlaces'], propertyValue);

    const headers = toJS(state.getIn(['table', 'headers']));
    const data = state.getIn(['table', 'data']);
    headers.forEach(h => {
      for (var rowIndex = 0; rowIndex < data.size; rowIndex++) {
        state = state.setIn(['table', 'data', rowIndex, h.key, 'specialisedStyles', 'decimalPlaces'], propertyValue);
      }
    });

    return state;
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_FONT_SIZE](state, action) {
    var propertyValue = action.value;
    state = state.setIn(['workspace', 'tableSettings', 'fontSize'], propertyValue);

    const headers = toJS(state.getIn(['table', 'headers']));
    const data = state.getIn(['table', 'data']);
    headers.forEach(h => {
      h.styles.fontSize = propertyValue;
      for (var rowIndex = 0; rowIndex < data.size; rowIndex++) {
        state = state.setIn(['table', 'data', rowIndex, h.key, 'styles', 'fontSize'], propertyValue);
      }
    });

    return state.setIn(['table', 'headers'], fromJS(headers));
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_ROW_SIZE](state, action) {
    var propertyValue = action.value;
    state = state.setIn(['workspace', 'tableSettings', 'rowSize'], propertyValue);

    const tableRowStyles = toJS(state.getIn(['table', 'rowStyles']));
    if (tableRowStyles && tableRowStyles.length > 0) {
      tableRowStyles.forEach(r => {
        if (r)
          r.height = `${propertyValue}px`;
      });
    }

    state = state.setIn(['table', 'rowStyles'], fromJS(tableRowStyles));

    return state;
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_VALUE](state, action) {
    var propertyName = action.key;
    var propertyValue = action.value;

    state = propertyValue === undefined ?
      state.deleteIn(['workspace', 'tableSettings', ...(Array.isArray(propertyName) ? propertyName : [propertyName])]) :
      state.setIn(['workspace', 'tableSettings', ...(Array.isArray(propertyName) ? propertyName : [propertyName])], propertyValue);

    const tableSettings = toJS(state.getIn(['workspace', 'tableSettings'], fromJS(action.data)));
    const source = toJS(state.getIn(['source'])) ?? {};
    const basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const startDate = state.getIn(['chart', 'startDate']);
    const endDate = state.getIn(['chart', 'endDate']);

    const { tableStyles, tableRowStyles, tableData, tableHeaders, isHorizontal, statistics } = mapReportToTable(tableSettings, basket, source, startDate, endDate);
    const addressLookup = {
      rows: getTableAddressRowsLookups(isHorizontal, tableData),
      columns: getTableAddressColumnsLookups(isHorizontal, tableHeaders),
      dates: getTableAddressDatesLookups(isHorizontal, tableData, tableHeaders)
    };

    const selection = {
      editCell: {},
      cursorCell: {},
      range: {},
      startRowKey: undefined,
      startColKey: undefined,
      endRowKey: undefined,
      endColKey: undefined,
      deferredValue: undefined,
      timeSeriesMeta: []
    };

    state = state.setIn(['table', 'styles'], fromJS(tableStyles))
      .setIn(['table', 'rowStyles'], fromJS(tableRowStyles))
      .setIn(['table', 'headers'], fromJS(tableHeaders))
      .setIn(['table', 'data'], fromJS(tableData))
      .setIn(['table', 'addressLookup'], fromJS(addressLookup))
      .setIn(['data', 'statistics'], fromJS(statistics))
      .setIn(['adjustments', 'editSelection'], fromJS(selection));

    return state;
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH](state, action) {
    const tableSettings = toJS(state.getIn(['workspace', 'tableSettings']));
    const headers = toJS(state.getIn(['table', 'headers']));
    const data = toJS(state.getIn(['table', 'data']));
    if (!tableSettings.headerStyles) tableSettings.headerStyles = {};
    if (!tableSettings.headerStyles.cellStyles) tableSettings.headerStyles.cellStyles = {};
    if (!tableSettings.headerStyles.cellStyles.conditionalStyles) tableSettings.headerStyles.cellStyles.conditionalStyles = [];

    const columnKey = `${action.key}`;
    const columnWidthNumber = action.value;
    const columnWidth = `${columnWidthNumber}px`;

    let column = tableSettings.headerStyles.cellStyles.conditionalStyles.find(i => i.columnKey === columnKey);

    if (!column) {
      column = { columnKey: columnKey };
      tableSettings.headerStyles.cellStyles.conditionalStyles.push(column);
    }

    if (!column.style) column.style = {};
    column.style.width = columnWidthNumber;

    const header = headers.find(h => h.key === columnKey);
    if (header)
      header.styles.width = columnWidth;

    const tableWidth = getTotalVisibleColumnWidths(headers);
    state = state.setIn(['table', 'styles', 'width'], tableWidth)
      .setIn(['table', 'headers'], fromJS(headers))
      .setIn(['workspace', 'tableSettings'], fromJS(tableSettings));

    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      state = state.setIn(['table', 'data', rowIndex, columnKey, 'styles', 'width'], columnWidth);
    }

    return state;
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTHS](state, action) {
    const tableSettings = toJS(state.getIn(['workspace', 'tableSettings']));
    const headers = toJS(state.getIn(['table', 'headers']));
    const data = toJS(state.getIn(['table', 'data']));
    const statistics = toJS(state.getIn(['data', 'statistics']));
    if (!tableSettings.headerStyles) tableSettings.headerStyles = {};
    if (!tableSettings.headerStyles.cellStyles) tableSettings.headerStyles.cellStyles = {};
    if (!tableSettings.headerStyles.cellStyles.conditionalStyles) tableSettings.headerStyles.cellStyles.conditionalStyles = [];

    headers.forEach(header => {
      let column = tableSettings.headerStyles.cellStyles.conditionalStyles.find(i => i.columnKey === `${header.key}`);
      if (!column) {
        column = { columnKey: `${header.key}` };
        tableSettings.headerStyles.cellStyles.conditionalStyles.push(column);
      }

      if (!column.style) column.style = {};
      column.style.width = action.value;
    });

    const { tableStyles, tableRowStyles } = applyStyles(tableSettings, headers, data, statistics);
    return state.setIn(['table', 'styles'], fromJS(tableStyles))
      .setIn(['table', 'rowStyles'], fromJS(tableRowStyles))
      .setIn(['table', 'headers'], fromJS(headers))
      .setIn(['table', 'data'], fromJS(data))
      .setIn(['workspace', 'tableSettings'], fromJS(tableSettings));
  },
  [ANALYSIS_UPDATE_TABLE_SETTINGS_ORDER_BY](state, action) {
    let orderBy = state.getIn(['workspace', 'tableSettings', 'orderBy']);
    let orderByDirection = state.getIn(['workspace', 'tableSettings', 'orderByDirection']);

    if (action.key === orderBy && orderByDirection === 'asc') {
      orderBy = '';
      orderByDirection = '';
    }
    else if (action.key === orderBy)
      orderByDirection = 'asc';
    else {
      orderBy = action.key;
      orderByDirection = 'desc';
    }

    let tableData = toJS(state.getIn(['table', 'data']));
    let tableHeaders = toJS(state.getIn(['table', 'headers']));
    let newState = state.setIn(['workspace', 'tableSettings', 'orderBy'], orderBy)
      .setIn(['workspace', 'tableSettings', 'orderByDirection'], orderByDirection);

    const isHorizontal = getIsHorizontal(state);
    tableData = sortTableData(isHorizontal, tableData, orderBy, orderByDirection);
    const addressLookup = {
      rows: getTableAddressRowsLookups(isHorizontal, tableData),
      columns: getTableAddressColumnsLookups(isHorizontal, tableHeaders),
      dates: getTableAddressDatesLookups(isHorizontal, tableData, tableHeaders)
    };

    return newState.setIn(['table', 'data'], fromJS(tableData))
      .setIn(['table', 'addressLookup'], fromJS(addressLookup));
  },
  [ANALYSIS_UPDATE_COLOUR_CYCLE_SETTINGS](state, action) {
    setUserColours(action.data);
    return state.setIn(['colourCycle'], action.data);
  },
  [ANALYSIS_UPDATE_CHART_EDIT_SETTINGS](state, action) {
    return state.setIn(['chartSettingsEdit', 'settings'], fromJS(action.data))
      .setIn(['chartSettingsEdit', 'isDirty'], true);
  },
  [ANALYSIS_UPDATE_CHART_EDIT_SETTINGS_VALUE](state, action) {
    if (!action.keyPath) return;

    const fullKeyPath = ['chartSettingsEdit', 'settings', ...(Array.isArray(action.keyPath) ? action.keyPath : [action.keyPath])];

    if (Array.isArray(action.value) && !action.value.length)
      return state.deleteIn(fullKeyPath, fromJS(action.value))
        .setIn(['chartSettingsEdit', 'isDirty'], true);

    return state.setIn(fullKeyPath, typeof action.value === 'object' ? fromJS(action.value) : action.value)
      .setIn(['chartSettingsEdit', 'isDirty'], true);
  },
  [ANALYSIS_APPLY_CHART_EDIT_SETTINGS](state, action) {
    const data = state.getIn(['chartSettingsEdit', 'settings']);

    let newState = state.setIn(['workspace', 'chartSettings'], data)
      .setIn(['chartSettingsEdit', 'isDirty'], false);

    const series = newState.getIn(['workspace', 'timeseries']);

    if (series) {
      if (action.includeSeries) {
        const chartType = newState.getIn(['workspace', 'chartSettings', 'chart', 'type']) || 'line';
        const plotOptionsDashStyle = newState.getIn(['workspace', 'chartSettings', 'plotOptions', 'series', 'dashStyle']) || 'solid';
        const plotOptionsStacking = newState.getIn(['workspace', 'chartSettings', 'plotOptions', 'series', 'stacking']) || '';
        const plotOptionsLineWidth = newState.getIn(['workspace', 'chartSettings', 'plotOptions', 'series', 'lineWidth']) || 1;

        series.forEach((i, ix) => {
          newState = newState.setIn(['workspace', 'timeseries', ix, 'type'], chartType)
          .setIn(['workspace', 'timeseries', ix, 'dashStyle'], plotOptionsDashStyle)
          .setIn(['workspace', 'timeseries', ix, 'stacking'], plotOptionsStacking)
          .setIn(['workspace', 'timeseries', ix, 'lineWidth'], plotOptionsLineWidth);

          if (newState.getIn(['workspace', 'timeseries', ix, 'highChartSettings'])){
            newState = newState
              .setIn(['workspace', 'timeseries', ix, 'highChartSettings', 'type'], chartType)
              .setIn(['workspace', 'timeseries', ix, 'highChartSettings', 'dashStyle'], plotOptionsDashStyle)
              .setIn(['workspace', 'timeseries', ix, 'highChartSettings', 'stacking'], plotOptionsStacking)
              .setIn(['workspace', 'timeseries', ix, 'highChartSettings','lineWidth'], plotOptionsLineWidth);
          }

          let chartSeriesIndex = newState.getIn(['chart', 'series']).findIndex(x => `${x.get('id')}` === `${i.get('key')}`);
          if (chartSeriesIndex >= 0)
            newState = newState.setIn(['chart', 'series', chartSeriesIndex, 'type'], chartType)
              .setIn(['chart', 'series', chartSeriesIndex, 'dashStyle'], plotOptionsDashStyle)
              .setIn(['chart', 'series', chartSeriesIndex, 'stacking'], plotOptionsStacking)
              .setIn(['chart', 'series', chartSeriesIndex, 'lineWidth'], plotOptionsLineWidth);

          const baselineBasket = mergeBasketToBaseline(
            toJS(state.getIn(['workspace', 'baselineBasket'])),
            newState.getIn(['workspace', 'timeseries']).toJS());   
      
          newState = newState.setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket));
        });
      }

      const prevYAxis = (state.getIn(['workspace', 'chartSettings', 'yAxis']) || {}).size || 0;
      const nextYAxis = (newState.getIn(['workspace', 'chartSettings', 'yAxis']) || {}).size || 0;

      if (nextYAxis < prevYAxis) series.forEach((i, ix) => {
        const prevSeriesYAxis = Number(i.get('yAxis'));

        if (isNaN(prevSeriesYAxis) || prevSeriesYAxis > nextYAxis - 1) {
          newState = newState.deleteIn(['workspace', 'timeseries', ix, 'yAxis']);

          let chartSeriesIndex = newState.getIn(['chart', 'series']).findIndex(x => `${x.get('id')}` === `${i.get('key')}`);
          if (chartSeriesIndex >= 0)
            newState = newState.deleteIn(['chart', 'series', chartSeriesIndex, 'yAxis']);
        }
      });
    }

    return newState;
  },
  [ANALYSIS_UPDATE_CHART_EDIT_VIEW](state, action) {
    return state.setIn(['chartSettingsEdit', 'currentView'], action.value);
  },
  [ANALYSIS_UPDATE_UI_VALUES](state, action) {
    const { list } = action;
    if (list)  {
      list.forEach(l => {
        const [key, value] = l;
        if (value === undefined)
          state = state.deleteIn(['ui', ...key]);
        else
          state = state.setIn(['ui', ...key], value);
      });
    }

    return state;
  },
  [ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_STARTED](state, action) {
    return state.setIn(['ui', 'isPeriodsAbsLoading'], true);
  },
  [ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_COMPLETED](state, action) {
    const { periodsAbs } = action;
    state = state.setIn(['ui', 'periodsAbs'], fromJS(periodsAbs))
                 .setIn(['ui', 'isPeriodsAbsLoading'], false)

    const periodsAbsLookups = Object.fromEntries(periodsAbs.map(p => [p.name, p]));
    const workspacePeriod = state.getIn(['workspace', 'period']);
    if (workspacePeriod) {
      const periodAbs = periodsAbsLookups[workspacePeriod] ?? periodsAbsLookups['Default'];
      if (periodAbs) {
        state = state.setIn(['workspace', 'fromDateMode'], 'abs')
                     .setIn(['workspace', 'fromUtc'], periodAbs.absFromDate)
                     .setIn(['workspace', 'toDateMode'], 'abs')
                     .setIn(['workspace', 'toUtc'], periodAbs.absToDate);
      }
    }

    const timeseries = toJS(state.getIn(['workspace', 'timeseries']), []);
    timeseries.forEach(ts => {
      if (ts.period) {
        const periodAbs = periodsAbsLookups[ts.period];
        if (periodAbs) {
          ts.window ??= {};
          ts.window.fromDateMode = 'abs';
          ts.window.fromUtc = periodAbs.absFromDate;
          ts.window.toDateMode = 'abs';
          ts.window.toUtc = periodAbs.absToDate;
        }
      }
    });

    const baselineBasket = mergeBasketToBaseline(toJS(state.getIn(['workspace', 'baselineBasket'])), timeseries);

    return state.setIn(['workspace', 'isDirty'], true)
                .setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket))
                .setIn(['workspace', 'timeseries'], fromJS(timeseries));
  },
  [ANALYSIS_REPORT_CRITERIA_UPDATE_CUSTOM_PERIOD](state, action) {
    const {key, value} = action;
    const keyPath = ['workspace', ...(Array.isArray(key) ? key : [key])];

    return state.setIn(keyPath, typeof value === 'object' ? fromJS(value) : value)
                 .setIn(['workspace', 'period'], '')
                 .setIn(['workspace', 'isDirty'], true);
  },
  [ANALYSIS_SHOW_CHART_EDIT_USER_SETTINGS_MODAL](state, action) {
    return state.setIn(['ui', 'isChartUserSettingsModalVisible'], true);
  },
  [ANALYSIS_HIDE_CHART_EDIT_USER_SETTINGS_MODAL](state, action) {
    return state.setIn(['ui', 'isChartUserSettingsModalVisible'], false);
  },
  [ANALYSIS_SHOW_CHART_EDIT_SERIES_SETTINGS_MODAL](state, action) {
    return state.setIn(['ui', 'isChartSeriesSettingsModalVisible'], true);
  },
  [ANALYSIS_HIDE_CHART_EDIT_SERIES_SETTINGS_MODAL](state, action) {
    return state.setIn(['ui', 'isChartSeriesSettingsModalVisible'], false);
  },
  [ANALYSIS_UPDATE_CHART_SETTINGS](state, action) {
    const keyPath = ['workspace', ...(Array.isArray(action.key) ? action.key : [action.key])];

    const current = state.getIn(keyPath);
    const isDirty = state.getIn(['workspace', 'isDirty']);

    let newState = state.setIn(keyPath, typeof action.value === 'object' ? fromJS(action.value) : action.value)
      .setIn(['workspace', 'isDirty'], isDirty || current !== action.value);

    return newState;
  },
  [ANALYSIS_REFRESH_STARTED](state, action) {
    return state.setIn(['chart', 'isLoading'], true);
  },
  [ANALYSIS_UPDATE_FACET_STYLE](state, action) {
    return state.setIn(['search', 'criteria', 'facetStyle'], action.data);
  },
  [ANALYSIS_SHAPES_UPDATE_BASKET_FROM_WORKSPACE](state, action) {
    const comparisonMode = state.getIn(['workspace', 'comparisonMode']);
    const basket = projectTopLevelSettingsToBasket({
      basket: toJS(state.getIn(['workspace', 'timeseries']), []),
      shapes: toJS(state.getIn(['workspace', 'shapesSettings', 'selection']), []),
      comparisons: (comparisonMode && comparisonMode !== 'none') ? toJS(state.getIn(['workspace', 'comparisonSettings', comparisonMode]), {}).windows : [],
      xAxisId: toJS(state.getIn(['workspace', 'xAxisTimeSeries', 'identityId']))
    });

    state = state
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['workspace', 'isDirty'], true);

    state = rebuildDisplayNamesToState(state);
    return state;
  },
  [ANALYSIS_UPDATE_BASKET_FROM_COMPARISONMODE_SETTINGSV2](state, action) {
    const comparisonMode = state.getIn(['workspace', 'comparisonMode']);
    const basket = projectTopLevelSettingsToBasket({
      basket: toJS(state.getIn(['workspace', 'timeseries']), []),
      shapes: toJS(state.getIn(['workspace', 'shapesSettings', 'selection']), []),
      comparisons: (comparisonMode && comparisonMode !== 'none') ? toJS(state.getIn(['workspace', 'comparisonSettings', comparisonMode]), {}).windows : [],
      xAxisId: toJS(state.getIn(['workspace', 'xAxisTimeSeries', 'identityId']))
    });

    state = state
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['workspace', 'isDirty'], true);

    state = rebuildDisplayNamesToState(state);
    return state;
  },  
  [ANALYSIS_UPDATE_EXPERIMENTAL_SETTINGS](state, action) {
    return state.setIn(['experimental'], toJS(action.data));
  },
  [ANALYSIS_TOGGLE_TABLE_DESIGN_PANEL_VISIBILITY](state, action) {
    const isTableDesignPanelVisible = state.getIn(['ui', 'isTableDesignPanelVisible']);
    return state
      .setIn(['ui', 'isTableDesignPanelVisible'], !isTableDesignPanelVisible)
      .setIn(['ui', 'isTableJsonEditorPanelVisible'], false);
  },
  [ANALYSIS_TOGGLE_TABLE_JSON_EDITOR_PANEL_VISIBILITY](state, action) {
    const isTableJsonEditorPanelVisible = state.getIn(['ui', 'isTableJsonEditorPanelVisible']);
    return state
      .setIn(['ui', 'isTableDesignPanelVisible'], false)
      .setIn(['ui', 'isTableJsonEditorPanelVisible'], !isTableJsonEditorPanelVisible);
  },
  [ANALYSIS_ADJUSTMENTS_BEGIN_EDIT](state, action) {
    if (state.getIn(['source', 'type']) === 'analysis-evo-report')
      return state;

    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    if (selection.cursorCell) {
      state = state.setIn(['table', 'data', selection.cursorCell.rowKey, selection.cursorCell.colKey, 'isEditing'], true);
    }

    return state.setIn(['adjustments', 'isEditing'], true)
      .setIn(['ui', 'isTableDesignPanelVisible'], false)
      .setIn(['ui', 'isTableJsonEditorPanelVisible'], false)
      .setIn(['adjustments', 'dirtyCellsMap'], {})
      .setIn(['adjustments', 'editSelection', 'editCell'], fromJS(selection.cursorCell)) // align the edit cell with the cursor cell
      .setIn(['adjustments', 'editSelection', 'cursorCell'], fromJS(selection.cursorCell))
      .setIn(['adjustments', 'editSelection', 'deferredValue'], undefined);
  },
  [ANALYSIS_ADJUSTMENTS_END_EDIT](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    if (selection.editCell) {
      state = state.setIn(['table', 'data', selection.editCell.rowKey, selection.editCell.colKey, 'isEditing'], false);
    }

    const dirtyCellsMap = toJS(state.getIn(['adjustments', 'dirtyCellsMap']));
    if (dirtyCellsMap) {
      Object.keys(dirtyCellsMap).forEach(key => {
        const address = fromAddress(key);
        state = state.setIn(['table', 'data', address.rowKey, address.colKey, 'adjustmentIsDirty'], false);
      });
    }

    return state
      .setIn(['adjustments', 'isEditing'], false)
      .setIn(['adjustments', 'dirtyCellsMap'], {})
      .setIn(['adjustments', 'editSelection', 'editCell'], fromJS({}));
  },
  [ANALYSIS_ADJUSTMENTS_SET_SELECTION_START](state, action) {
    const { rowKey, colKey, ctrlKey = false, shiftKey = false } = action.value;
    if (rowKey === undefined || colKey === undefined)
      return state;

    const data = state.getIn(['table', 'data']);
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    let stateChanges = [];
    if (!ctrlKey)
      stateChanges = [...clearSelection(selection, data)];

    if (shiftKey) {
      selection.endRowKey = rowKey;
      selection.endColKey = colKey;
      const addressLookup = toJS(state.getIn(['table', 'addressLookup']));
      stateChanges = [...stateChanges, ...selectGridRange(selection, addressLookup)];
      state = applyStateChanges(state, stateChanges);
      updateTimeSeriesMeta(selection, getIsHorizontal(state), toJS(state.getIn(['workspace', 'timeseries']), []));
      return state
        .setIn(['adjustments', 'editSelection'], fromJS(selection));
    }

    stateChanges = [...stateChanges, ...selectCell(selection, colKey, rowKey, data)];
    state = applyStateChanges(state, stateChanges);
    updateTimeSeriesMeta(selection, getIsHorizontal(state), toJS(state.getIn(['workspace', 'timeseries']), []));
    return state
      .setIn(['adjustments', 'editSelection'], fromJS(selection));
  },
  [ANALYSIS_ADJUSTMENTS_SET_SELECTION_END](state, action) {
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const { rowKey, colKey, ctrlKey = false } = action.value;
    if (rowKey === undefined || colKey === undefined || (selection.endRowKey === rowKey && selection.endColKey === colKey))
      return state;

    const data = state.getIn(['table', 'data']);
    let stateChanges = [];
    if (!ctrlKey)
      stateChanges = [...clearSelection(selection, data)];

    selection.endRowKey = rowKey;
    selection.endColKey = colKey;
    const addressLookup = toJS(state.getIn(['table', 'addressLookup']));
    stateChanges = [...stateChanges, ...selectGridRange(selection, addressLookup)];
    state = applyStateChanges(state, stateChanges);
    updateTimeSeriesMeta(selection, getIsHorizontal(state), toJS(state.getIn(['workspace', 'timeseries']), []));
    return state
      .setIn(['adjustments', 'editSelection'], fromJS(selection));
  },
  [ANALYSIS_ADJUSTMENTS_SELECT_LINE](state, action) {
    const { isRowSelection, ctrlKey = false, direction, fullLine = false, keyOverride } = action.value;

    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const data = state.getIn(['table', 'data']);
    const addressLookup = toJS(state.getIn(['table', 'addressLookup']));
    let stateChanges = [];
    if (!ctrlKey)
      stateChanges = [...clearSelection(selection, data)];

    stateChanges = [...stateChanges, ...selectLine(selection, addressLookup, data, isRowSelection, direction, fullLine, keyOverride)];
    state = applyStateChanges(state, stateChanges);
    return state
      .setIn(['adjustments', 'editSelection'], fromJS(selection));
  },
  [ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_CELL](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const { rowKey, colKey } = action.value;
    if (!rowKey || !colKey === undefined)
      return state;

    const data = state.getIn(['table', 'data']);
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));

    const stateChanges = setEditCell(selection, data, rowKey, colKey);
    state = applyStateChanges(state, stateChanges);
    updateTimeSeriesMeta(selection, getIsHorizontal(state), toJS(state.getIn(['workspace', 'timeseries']), []));
    return state
      .setIn(['adjustments', 'editSelection'], fromJS(selection));
  },
  [ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const { rowKey, colKey, value, isDeferredValue } = action.value;
    if (rowKey === undefined || colKey === undefined)
      return state;

    if (isDeferredValue)
      return state.setIn(['adjustments', 'editSelection', 'deferredValue'], value);

    const stateChanges = setCellAdjustmentValue(rowKey, colKey, value);
    state = applyStateChanges(state, stateChanges);
    return state;
  },
  [ANALYSIS_ADJUSTMENTS_TABLE_PASTE_TO_SELECTION_COMPLETE](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const table = action.table;
    const originAddress = action.originAddress;

    const addressLookup = toJS(state.getIn(['table', 'addressLookup']));
    const originOffset = {
      row: addressLookup.rows.indexOf(originAddress.rowKey),
      col: addressLookup.columns.indexOf(originAddress.colKey)
    };

    table.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        if (rowIndex + originOffset.row < addressLookup.rows.length && colIndex + originOffset.col < addressLookup.columns.length) {
          const rowKey = addressLookup.rows[rowIndex + originOffset.row];
          const colKey = addressLookup.columns[colIndex + originOffset.col];
          state = state.setIn(['table', 'data', rowKey, colKey, 'adjustment'], cell !== undefined ? cell : null);
          state = state.setIn(['table', 'data', rowKey, colKey, 'adjustmentIsDirty'], true);
          state = state.setIn(['adjustments', 'dirtyCellsMap', toAddress(rowKey, colKey)], true);
        }
      });
    });

    return state;
  },
  [ANALYSIS_ADJUSTMENTS_VALIDATE_BEGIN](state, action) {
    return state.setIn(['adjustments', 'validation', 'isLoading'], true)
      .setIn(['adjustments', 'validation', 'messages'], fromJS([]));
  },
  [ANALYSIS_ADJUSTMENTS_VALIDATE_COMPLETE](state, action) {
    if (!action.success)
      return state.setIn(['adjustments', 'validation', 'isLoading'], false);

    const messages = action.messages;
    const timeSeries = toJS(state.getIn(['workspace', 'timeseries']));
    const timeSeriesMessages = [];
    timeSeries.forEach(ts => {
      const errorMessages = messages.filter(m => m.key === ts.key && m.messageType === 'Error');
      const warningMessages = messages.filter(m => m.key === ts.key && m.messageType === 'Warning');
      const informationMessages = messages.filter(m => m.key === ts.key && m.messageType === 'Information');
      if (errorMessages.length > 0 || warningMessages.length > 0 || informationMessages.length > 0) {
        timeSeriesMessages.push({
          name: ts.name,
          identityId: ts.identityId,
          errors: errorMessages.map(m => m.text),
          warnings: warningMessages.map(m => m.text),
          informations: informationMessages.map(m => m.text)
        });
      }
    });

    return state.setIn(['adjustments', 'validation', 'isLoading'], false)
      .setIn(['adjustments', 'validation', 'messages'], fromJS(timeSeriesMessages));
  },
  [ANALYSIS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const stateChanges = setSelectionAdjustmentValue(selection);
    state = applyStateChanges(state, stateChanges);
    return state;
  },
  [ANALYSIS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const data = state.getIn(['table', 'data']);
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const dirtyCellsMap = toJS(state.getIn(['adjustments', 'dirtyCellsMap']));
    const addressLookup = toJS(state.getIn(['table', 'addressLookup']));
    const existingAdjustments = toJS(state.getIn(['adjustments', 'existingAdjustmentsMap']));

    const isHorizontal = getIsHorizontal(state);
    selection.range = {};
    existingAdjustments.forEach(adjustment => {
      if (isHorizontal) {
        const rowKey = addressLookup.rows[adjustment.rowIndex];
        if (rowKey !== undefined) {
          selection.range[`${rowKey}|${adjustment.dateTime}`] = true;
        }
      } else {
        const rowKey = addressLookup.dates[adjustment.dateTime];
        if (rowKey !== undefined) {
          selection.range[`${rowKey}|${adjustment.key}`] = true;
        }
      }
    });

    Object.keys(dirtyCellsMap).forEach(address => {
      selection.range[address] = true;
    });

    const stateChanges = removeSelectionAdjustments(selection, data);
    state = applyStateChanges(state, stateChanges);
    updateTimeSeriesMeta(selection, getIsHorizontal(state), toJS(state.getIn(['workspace', 'timeseries']), []));
    return state;
  },
  [ANALYSIS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const data = state.getIn(['table', 'data']);
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const stateChanges = removeSelectionAdjustments(selection, data);
    state = applyStateChanges(state, stateChanges);
    updateTimeSeriesMeta(selection, getIsHorizontal(state), toJS(state.getIn(['workspace', 'timeseries']), []));
    return state;
  },
  [ANALYSIS_ADJUSTMENTS_REVERT_SELECTION_ADJUSTMENTS](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const stateChanges = revertSelectionAdjustments(selection);
    state = applyStateChanges(state, stateChanges);
    updateTimeSeriesMeta(selection, getIsHorizontal(state), toJS(state.getIn(['workspace', 'timeseries']), []));
    return state;
  },
  [ANALYSIS_ADJUSTMENTS_NAVIGATE_CELL](state, action) {
    const { direction, continueSelection = false } = action.value;
    if (direction === undefined)
      return state;

    const data = state.getIn(['table', 'data']);
    const addressLookup = toJS(state.getIn(['table', 'addressLookup']));
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const stateChanges = navigateToNextCell(selection, addressLookup, data, direction, continueSelection);
    state = applyStateChanges(state, stateChanges);

    updateTimeSeriesMeta(selection, getIsHorizontal(state), toJS(state.getIn(['workspace', 'timeseries']), []));
    return state.setIn(['adjustments', 'editSelection'], fromJS(selection));
  },
  [ANALYSIS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    if (!action.tsKey) {
      const timeSeriesMeta = toJS(state.getIn(['adjustments', 'timeSeriesMeta']));
      timeSeriesMeta[action.propertyName] = action.value;
      return state.setIn(['adjustments', 'timeSeriesMeta'], fromJS(timeSeriesMeta));
    } else {
      const timeSeriesMetas = toJS(state.getIn(['adjustments', 'editSelection', 'timeSeriesMeta']));
      const timeSeriesMeta = timeSeriesMetas.find(t => t.key === action.tsKey);
      timeSeriesMeta[action.propertyName] = action.value;
      return state.setIn(['adjustments', 'editSelection', 'timeSeriesMeta'], fromJS(timeSeriesMetas));
    }
  },
  [ANALYSIS_ADJUSTMENTS_SAVE_CONFIRMATION_REQUIRED](state, action) {
    const preSaveWarnings = action.preSaveWarnings;
    return state.setIn(['adjustments', 'preSaveWarnings'], fromJS(preSaveWarnings));
  },
  [ANALYSIS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    return state.setIn(['adjustments', 'isSaveConfirmationVisible'], action.isVisible);
  },
  [ANALYSIS_ADJUSTMENTS_SAVE_BEGIN](state, action) {
    return state.setIn(['chart', 'isLoading'], true);
  },
  [ANALYSIS_ADJUSTMENTS_SAVE_COMPLETE](state, action) {
    if (!action.success)
      return state.setIn(['chart', 'isLoading'], false);

    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    selection.editCell = {};
    selection.cursorCell = {};
    selection.range = {};

    const data = state.getIn(['table', 'data']);
    let stateChanges = [];
    selection.timeSeriesMeta = [];
    stateChanges = [...clearSelection(selection, data, true)];
    state = state.setIn(['adjustments', 'dirtyCellsMap'], {});
    state = applyStateChanges(state, stateChanges);

    return state.setIn(['adjustments', 'editSelection'], fromJS(selection))
      .setIn(['adjustments', 'preSaveWarnings'], fromJS([]))
      .setIn(['chart', 'isLoading'], false);
  },
  [ANALYSIS_ADJUSTMENTS_RESET_PRE_SAVE_WARNINGS](state, action) {
    return state.setIn(['adjustments', 'preSaveWarnings'], fromJS([]));
  },
  [ANALYSIS_ADJUSTMENTS_UPDATE_DEFAULTS](state, action) {
    const { annotation = '' } = (action.defaults ?? {});
    return state.setIn(['adjustments', 'timeSeriesMeta', 'annotation'], annotation);
  },
  [REFERENCE_DATA_LOAD_SHARED_LOOKUPS_COMPLETE](state, action) {
    let {data: {periods} = {}} = action;
    if (Array.isArray(periods))
      state = state.setIn(['ui', 'periodsReferenceData'], fromJS(
        mergePeriodCollectionLists(state.getIn(['ui', 'periodsReferenceData']).toJS(),
        ofPeriodTypes(periods))));

    return state;
  },
  [ANALYSIS_WORKSPACE_UPDATE_PERIODS_FROM_USER_SETTINGS](state, action) {
    const {update} = action;

    if (update === true) {
      let workspacePeriods = state.getIn(['workspace', 'periods']).toJS();
      let userPeriods = state.getIn(['ui', 'periodsUserSettings']).toJS();

      const updated = [];
        workspacePeriods.forEach(p => {
        const userPeriod = userPeriods.find(_ => _.name === p.name);
        if (userPeriod)
          updated.push(userPeriod);
        else
          updated.push(p);
      });

      state = state.setIn(['workspace', 'periods'], fromJS(updated))
                   .setIn(['workspace', 'isDirty'], true)
    }

    return state.setIn(['ui', 'periodsUnsyncronised'], false);
  },
  [REFERENCE_DATA_LOAD_PERIODS_COMPLETE](state, action) {
    let {data:periods} = action;
    if (Array.isArray(periods) && periods.length > 0)
      state = state.setIn(['ui', 'periodsReferenceData'], fromJS(
        mergePeriodCollectionLists(state.getIn(['ui', 'periodsReferenceData']).toJS(),
        ofPeriodTypes(periods))));

    return state;
  },
  [USER_SETTINGS_LOAD_COMPLETE](state, action) {
    if (!action.data) 
      return state;
    
    let {data: {periods} = {}} = action;

    if (Array.isArray(periods)){
      let workspacePeriods = state.getIn(['workspace', 'periods']);
      if (!workspacePeriods) workspacePeriods = workspacePeriods.toJS();

      state = state.setIn(['ui', 'periodsUserSettings'], fromJS(periods));

      const periodsUnsyncronised = areAnyPeriodsChanged(workspacePeriods ? workspacePeriods.toJS() : [], periods);
      state = state.setIn(['ui', 'periodsUnsyncronised'], periodsUnsyncronised);
    }

    return state;
  },
  [LOCATION_CHANGE](state, action) {
    const { payload } = action;
    const { location } = payload;
    let { pathname = '' } = location || {};
    pathname = decodeURIComponent(pathname);
    if (pathname.indexOf('/analysis') < 0) return state;

    // update the workspace so that we can save it and return to the same page
    state = state.setIn(['workspace', 'pageUrl'], pathname);

    const { search = '' } = location;
    const urlArgs = new URLSearchParams(search);
    if (payload.action === 'POP' && urlArgs.has("workspacePath"))
    {
      state = state.setIn(['workspacePath'], search);
    }

    return state;
  }
};

export const analysis = createReducer(null, {
  ...analysisReducer,
  ...comparisonModeReducer,
  ...comparisonModeBasketLevelReducer,
  ...shapesReducerV2,
  ...compositionReducerV1,
  ...compositionReducerV2,
  ...basketReducerV2,
  ...versionsReducer,
  ...dynamicWorkspaceReducerV2,
  ...dynamicWorkspaceEditorReducer,
  ...analysisWorkspaceReducer
});