import moment from "moment";
import { toGasSeasonFormat, toQuarterFormat } from "./date-utility";

export const isComparisonModeDateOnDate = (comparisonMode) => {
  return !(comparisonMode === undefined || comparisonMode === comparisonModes.none);
};

export const comparisonModes = {
  none: 'none',
  yearOnYear: 'yearOnYear',
  gasYear: 'gasYear',
  gasSeason: 'gasSeason',
  quarter: 'quarter',
  monthOnMonth: 'monthOnMonth',
  dayOnDay: 'dayOnDay'
}

export const comparisonModeName = {
  none: 'None',
  yearOnYear: 'Year',
  gasYear: 'GasYear',
  gasSeason: 'GasSeason',
  quarter: 'Quarter',
  monthOnMonth: 'Month',
  dayOnDay: 'Day'
}

export const comparisonModeOptions = [
  { value: comparisonModes.none, name: comparisonModeName[comparisonModes.none] },
  { value: comparisonModes.yearOnYear, name: comparisonModeName[comparisonModes.yearOnYear] },
  { value: comparisonModes.gasYear, name: comparisonModeName[comparisonModes.gasYear] },
  { value: comparisonModes.gasSeason, name: comparisonModeName[comparisonModes.gasSeason] },
  { value: comparisonModes.quarter, name: comparisonModeName[comparisonModes.quarter] },
  { value: comparisonModes.monthOnMonth, name: comparisonModeName[comparisonModes.monthOnMonth] },
  { value: comparisonModes.dayOnDay, name: comparisonModeName[comparisonModes.dayOnDay] }
];

export function dateToComparisonModeName(comparisonMode, date) {
  date = moment(date);
  switch (comparisonMode) {
    case comparisonModes.yearOnYear:
    case comparisonModes.gasYear:
      return date.format('YYYY');
    case comparisonModes.gasSeason:
      return toGasSeasonFormat(date);
    case comparisonModes.quarter:
      return toQuarterFormat(date);
    case comparisonModes.monthOnMonth:
    return date.format('MMM-YYYY');
    case comparisonModes.dayOnDay:
      return date.format('DD-MMM-YYYY');
    default:
      return undefined;
  }
}

export const comparisonModeRelativeDateOptions = {
  yearOnYear: ['Y'],
  gasYear: ['Y'],
  gasSeason: ['Y'],
  quarter: ['Y'],
  monthOnMonth: ['M', 'Y'],
  dayOnDay: ['H', 'D', 'W', 'M', 'Y'],
  none: ['H', 'D', 'W', 'M', 'Y'],
  // eslint-disable-next-line
  ['']: ['H', 'D', 'W', 'M', 'Y']
}

export const comparisonModePlotOriginOptions = {
  yearOnYear: ['Y'],
  gasYear: ['Y'],
  gasSeason: ['Y'],
  quarter: ['Y'],
  monthOnMonth: ['M', 'Y'],
  dayOnDay: ['H', 'D', 'W', 'M', 'Y'],
  none: [],
  // eslint-disable-next-line
  ['']: []
}

export const comparisonModeLensedDateOptions = {
  yearOnYear: 'Year',
  gasYear: 'Year',
  gasSeason: 'GasSeason',
  quarter: 'Quarter',
  monthOnMonth: 'Month',
  dayOnDay: 'Day',
  none: ''
}

export function createPlotOrigins(plotOrigin) {
  const response = {};
  Object.keys(comparisonModes).forEach(c => {
    if (c !== comparisonModes.none) {
      response[c] = {
        absOriginDate: plotOrigin,
        relOriginDate: undefined
      }
    }
  });

  return response;
}

export function initialisePlotOrigins(target, source) {
  Object.keys(comparisonModes).forEach(c => {
    if (c !== comparisonModes.none) {
      if (!target[c].plotOrigin.absOriginDate) target[c].plotOrigin.absOriginDate = source[c].absOriginDate;
    }
  });
}

export function getPlotOrigins(state) {
  const response = {};
  Object.keys(comparisonModes).forEach(c => {
    if (c !== comparisonModes.none) {
      response[c] = state.getIn(['ui', 'comparisonSettings', c, 'plotOrigin']).toJS();
    }
  });

  return response;
}