import { fromJS } from 'immutable'
import moment from 'moment';
import {
  TIMESERIES_DETAILS_ANNOTATIONS_LOAD_COMPLETE,
  TIMESERIES_DETAILS_ANNOTATION_SAVED,
  TIMESERIES_DETAILS_ANNOTATION_ADD,
  TIMESERIES_DETAILS_ANNOTATION_EDIT,
  TIMESERIES_DETAILS_ANNOTATION_EDIT_CANCEL,
  TIMESERIES_DETAILS_ANNOTATION_DELETED,
  TIMESERIES_DETAILS_ANNOTATIONS_SET_TIMEZONE,
  TIMESERIES_DETAILS_ANNOTATIONS_FILTER_UPDATED,
  TIMESERIES_DETAILS_ANNOTATIONS_APPLY_FILTER,
  TIMESERIES_DETAILS_ANNOTATIONS_APPLY_SORT,
  TIMESERIES_DETAILS_ANNOTATION_SET_KEY,
  TIMESERIES_DETAILS_ANNOTATION_SET_FROMDATE,
  TIMESERIES_DETAILS_ANNOTATION_SET_PRIORITY,
  TIMESERIES_DETAILS_ANNOTATION_SET_TODATE,
  TIMESERIES_DETAILS_ANNOTATION_SET_TEXT
} from '../actions/timeSeriesDetails-annotations';

const mapAnnotation = annotation => {
  const dateFormat = 'DD-MMM-YYYY HH:mm';

  const { periodStart, periodEnd } = annotation;

  const formattedStart = periodStart ? moment.utc(periodStart).format(dateFormat) : null;
  const formattedEnd = periodEnd ? moment.utc(periodEnd).format(dateFormat) : null;

  return { ...annotation, formattedStart, formattedEnd };
};

export const timeSeriesDetailsAnnotationsReducer = {
  [TIMESERIES_DETAILS_ANNOTATIONS_LOAD_COMPLETE](state, action) {
    if (!action.data) return;

    let annotations = action.data.annotations.map(mapAnnotation);

    const filterType = state.getIn(['annotationsEditor', 'appliedFilterType']);
    const filterText = state.getIn(['annotationsEditor', 'appliedFilterText']);

    if (!!filterText) annotations = annotations.filter(i => {
      const value = (i[filterType || 'value'] || '').trim().toLocaleLowerCase();
      const text = (filterText || '').trim().toLocaleLowerCase();

      return value.includes(text);
    });

    const timeZone = action.data.timeZone;

    return state.setIn(['annotationsEditor', 'annotations'], fromJS(annotations))
                .setIn(['annotationsEditor', 'timeZone'], timeZone)
                .setIn(['annotationsEditor', 'selectedTimeZone'], timeZone);
  },
  [TIMESERIES_DETAILS_ANNOTATION_SAVED](state, action) {
    if (!action.data) return;

    const annotation = mapAnnotation(action.data);
    const index = state.getIn(['annotationsEditor', 'annotations']).findIndex(i => i.get('id') === annotation.id);

    const newState = index >= 0
      ? state.setIn(['annotationsEditor', 'annotations', index], fromJS(annotation))
      : state.updateIn(['annotationsEditor', 'annotations'], i => i.unshift(fromJS(annotation)));
    return newState.setIn(['annotationsEditor', 'annotationUnderEdit'], null);
  },
  [TIMESERIES_DETAILS_ANNOTATION_ADD](state, action) {
    const timeZone = state.getIn(['annotationsEditor', 'annotationsEdit', 'timeZone']);
    const annotation = mapAnnotation({
      id: 0,
      periodStart: moment().format('YYYY-MM-DDT00:00'),
      periodEnd: moment().add(1, 'day').format('YYYY-MM-DDT00:00'),
      timeZone: timeZone,
      value: ''
    });

    return state.setIn(['annotationsEditor', 'annotationUnderEdit'], annotation);
  },
  [TIMESERIES_DETAILS_ANNOTATION_EDIT](state, action) {
    const annotationUnderEdit = state.getIn(['annotationsEditor', 'annotations']).find(i => i.get('id') === action.id);
    return state.setIn(['annotationsEditor', 'annotationUnderEdit'], annotationUnderEdit || null);
  },
  [TIMESERIES_DETAILS_ANNOTATION_EDIT_CANCEL](state, action) {
    return state.setIn(['annotationsEditor', 'annotationUnderEdit'], null);
  },
  [TIMESERIES_DETAILS_ANNOTATION_DELETED](state, action) {
    return state.updateIn(['annotationsEditor', 'annotations'], i => i.filter(i => i.get('id') !== action.id));
  },
  [TIMESERIES_DETAILS_ANNOTATIONS_SET_TIMEZONE](state, action) {
    return state.setIn(['annotationsEditor', 'selectedTimeZone'], action.data);
  },
  [TIMESERIES_DETAILS_ANNOTATIONS_FILTER_UPDATED](state, action) {
    const { filterText, filterType } = action.data;
    const appliedFilterType = state.getIn(['annotationsEditor', 'appliedFilterType']);
    const appliedFilterText = state.getIn(['annotationsEditor', 'appliedFilterText']);
    return state.setIn(['annotationsEditor', 'filterType'], filterType)
                .setIn(['annotationsEditor', 'filterText'], filterText)
                .setIn(['annotationsEditor', 'isFilterDirty'], filterType !== appliedFilterType || filterText !== appliedFilterText);
  },
  [TIMESERIES_DETAILS_ANNOTATIONS_APPLY_FILTER](state, action) {
    const filterType = state.getIn(['annotationsEditor', 'filterType']);
    const filterText = state.getIn(['annotationsEditor', 'filterText']);

    return state.setIn(['annotationsEditor', 'appliedFilterType'], filterType)
                .setIn(['annotationsEditor', 'appliedFilterText'], filterText)
                .setIn(['annotationsEditor', 'isFilterDirty'], false);
  },
  [TIMESERIES_DETAILS_ANNOTATIONS_APPLY_SORT](state, action) {
    if (!action.key) return state;

    let orderBy = state.getIn(['annotationsEditor', 'appliedOrderBy']);
    let orderByDirection = state.getIn(['annotationsEditor', 'appliedOrderByDirection']);

    if (orderBy === action.key)
      orderByDirection = orderByDirection === 'desc' ? 'asc' : 'desc';
    else {
      orderBy = action.key;
      orderByDirection = 'desc';
    }

    return state.setIn(['annotationsEditor', 'appliedOrderBy'], orderBy)
                .setIn(['annotationsEditor', 'appliedOrderByDirection'], orderByDirection);
  },
  [TIMESERIES_DETAILS_ANNOTATION_SET_KEY](state, action) {
    return state.setIn(['annotationsEditor', 'annotationUnderEdit', 'annotationKey'], action.data);
  },
  [TIMESERIES_DETAILS_ANNOTATION_SET_FROMDATE](state, action) {
    return state.setIn(['annotationsEditor', 'annotationUnderEdit', 'periodStart'], action.data);
  },
  [TIMESERIES_DETAILS_ANNOTATION_SET_PRIORITY](state, action) {
    return state.setIn(['annotationsEditor', 'annotationUnderEdit', 'priority'], action.data);
  },
  [TIMESERIES_DETAILS_ANNOTATION_SET_TODATE](state, action) {
    return state.setIn(['annotationsEditor', 'annotationUnderEdit', 'periodEnd'], action.data);
  },
  [TIMESERIES_DETAILS_ANNOTATION_SET_TEXT](state, action) {
    return state.setIn(['annotationsEditor', 'annotationUnderEdit', 'value'], action.data);
  }
};