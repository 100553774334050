import React from 'react';
import { connect } from 'react-redux';
import Loading from '../../shared/Loading';
import {
  reportsHideOverlay,
  reportsChartSetLens,
  reportsChartRefresh,
  reportLaunchAnalysis,
  reportsChartSetSelectedScenarios
} from '../../../actions/reports';

import { withStyles } from 'tss-react/mui';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Dialog } from '@mui/material';
import AnalysisChart from '../analysis/AnalysisChart';
import { comparisonModes } from '../../../utility/comparisonmode-utility';

const StyledSelect = withStyles(Select, ({ palette }) => ({
  root: {
    width: '100%',
    minWidth: '400px',
    height: '25px',
    fontSize: '12px',
    margin: '.1em',
    padding: '0',
    border: '0',
    color: ({ colour }) => palette.getContrastText(colour || '#fff'),
    backgroundColor: ({ colour }) => colour || '#fff'
  }
}));

const StyledMenuItem = withStyles(MenuItem, ({ palette }) => ({
  root: {
    height: '25px',
    fontSize: '12px',
    margin: '.1em',
    color: ({ colour }) => palette.getContrastText(colour || '#fff'),
    backgroundColor: ({ colour }) => colour || '#fff'
  }
}));

const StyledChip = withStyles(Chip, ({ palette }) => ({
  root: {
    height: '25px',
    fontSize: '12px',
    borderRadius: 4,
    margin: '.1em',
    backgroundColor: ({ colour }) => colour || '#ddd'
  }
}));

export const ReportChartView = connect(
  (state) => ({
    availableScenarios: state.getIn(['reports', 'chart', 'availableScenarios']),
    selectedScenarios: state.getIn(['reports', 'chart', 'selectedScenarios']),
    isOverlayVisible: state.getIn(['reports', 'overlay', 'isVisible']),
    isLoading: state.getIn(['reports', 'chart', 'isLoading']),
    series: state.getIn(['reports', 'chart', 'series']),
    seriesData: state.getIn(['reports', 'chart', 'seriesData']),
    lenses: state.getIn(['referenceData', 'lenses']),
    lens: state.getIn(['reports', 'chart', 'lens'])
  }),
  (dispatch) => ({
    hideOverlay() {
      dispatch(reportsHideOverlay());
    },
    refresh(ids, options) {
      dispatch(reportsChartRefresh({ timeSeriesIds: ids, options }));
    },
    launchAnalysis() {
      dispatch(reportLaunchAnalysis())
    },
    setLens(lens) {
      dispatch(reportsChartSetLens(lens));
    },
    setSelectedScenarios(selectedScenarios) {
      dispatch(reportsChartSetSelectedScenarios(selectedScenarios));
    }
  })
)(({ availableScenarios, isLoading, isOverlayVisible, selectedScenarios, series, seriesData, lenses, lens, hideOverlay, refresh, launchAnalysis, setLens, setSelectedScenarios }) => {
  if (!isOverlayVisible)
    return <></>
    
  const _seriesData = seriesData.toJS();
  const chartSeries = series ? (Object.values(series.toJS()) || []).map(c => {
    c.data = _seriesData[c.id];
    return c;
  }) : [];

  function onRefresh(e){
    let lens = e.target.value;
    const timeSeriesIds = chartSeries.map(v => v.identityId).filter(id => id > 0) || [];

    if (e && e.target.nodeName === 'FORM') {
      e.preventDefault();

      lens = e.target.lens.value;
    }

    setLens(lens);
    refresh(timeSeriesIds, { lens });
  }

  function onLaunchAnalysis(e) {
    e.preventDefault();
    launchAnalysis();
  }

  const _availableScenarios = availableScenarios.toJS();
  const _selectedScenarios = selectedScenarios.toJS();
  const hasSyntheticTimeSeries = chartSeries.some(f => f.isSynthetic);

  function onClose(){
    hideOverlay();
  }

  return (
    <Dialog open={isOverlayVisible} onClose={onClose} fullWidth={true} maxWidth={'slg'}>
        {isOverlayVisible && <Loading isLoading={ isLoading }>
          <div className='modal-content overflow-hidden shadow rounded m-auto' style={{  height: '80vh', top: 0, left: 0, right: 0, bottom: 0 }}>

            <div className='position-absolute' style={{ left: 50, zIndex: 1 }}>
              <form className='my-3' noValidate onSubmit={ onRefresh } style={{ width: '550px' }}>
              {!hasSyntheticTimeSeries && <div className='form-row'>
                <div className='col form-group'>
                    <div className='input-group input-group-sm'>
                      <div className='input-group-prepend'>
                        <div className='input-group-text'>Lens</div>
                      </div>
                      <select className='form-control' name='lens' defaultValue={ lens } onChange={ onRefresh }>
                        { lenses.map(lens => <option key={ lens } value={ lens }>{ lens }</option>) }
                      </select>
                    </div>
                  </div>
                
                <div className='col form-group'>
                    <button type='submit' className='btn btn-sm btn-outline-primary'>
                      <i className='fas fa-sync-alt fa-fw' />
                    </button>
                    <button className='btn btn-sm btn-outline-secondary' onClick={ onLaunchAnalysis }>
                      <i className='fas fa-external-link-alt fa-fw' />
                    </button> 
                  </div>
                  
                  <div className='col form-group'>
                    <div className='input-group input-group-sm'>
                      <div className='input-group-prepend'>
                        <div className='input-group-text'>Scenarios</div>
                        <StyledSelect multiple value={_selectedScenarios}
                          onChange={e => setSelectedScenarios(e.target.value)}
                          renderValue={selected => (
                            <div className='d-flex w-100'>
                              {selected.map((i, ix) => <StyledChip key={ix} label={i} />)}
                            </div>
                          )}>
                          {_availableScenarios.map((i, ix) => (
                            <StyledMenuItem key={ix} value={i}>
                              {i}
                            </StyledMenuItem>
                          ))}
                        </StyledSelect>                                     
                      </div>
                    </div>
                  </div>
                </div>
              }
              </form>
            </div>

            <AnalysisChart 
              chartSeries={ chartSeries } 
              title='Reports Chart' 
              titleHeight={hasSyntheticTimeSeries ? 0 : 80} 
              lens={lens}
              comparisonMode={comparisonModes.none}
              hasXAxis={false}/>
          </div>
        </Loading>}
    </Dialog>
  );
});