import { fromJS } from 'immutable';
import moment from 'moment';
import { createReducer } from '../utility/redux-utility';
import { getMessageParts } from '../utility/log-utility';
import {
  LOG_MESSAGE,
  LOG_DISMISS_MESSAGE
} from '../actions/log';

const messageLimit = 100;
let messageCount = 0;

function logMessage({ message, type = 'info' }) {
  const { source, header, body } = getMessageParts(message);

  if (!header && !body) return;

  return {
    id: ++messageCount,
    type: type,
    header: header,
    body: body,
    source: JSON.stringify(source),
    createdOn: moment().format(),
    isDismissed: false
  };
}

export const log = createReducer(null, {
  [LOG_MESSAGE](state, action) {
    const { message, type } = action.data || {};

    const log = logMessage({ message, type });

    if (log) return state.update('messages', m => m.unshift(fromJS(log)).splice(messageLimit));

    return state;
  },
  [LOG_DISMISS_MESSAGE](state, action) {
    const index = state.get('messages').findIndex(i => i.get('id') === action.id);

    return index >= 0
      ? state.setIn(['messages', index, 'isDismissed'], true)
      : state;
  }
});