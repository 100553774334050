import React, { useState } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';

export const DebugPopup = connect(
  (state) => ({
    showDebug: state.getIn(['userSettings', 'settings', 'show-debug'])
  }),
  (dispatch) => ({
  })
)(({ showDebug, onOpenDialog, onClose, children, linkContent, linkClassName = '', linkStyles, tooltipText, size = 'lg' }) => {
  if (!showDebug)
    return <></>

  const [isOpen, setIsOpen] = useState(false);

  function openDialog(e) {
    e.preventDefault();
    if (onOpenDialog)
      onOpenDialog();

    setIsOpen(true);
  }

  function closeDialog() {
    if (onClose)
      onClose();

    setIsOpen(false);
  }

  function onInnerClick(e) {
    if (e) {
      if (e.target.className === 'dropdown-item' || 
          e.target.getAttribute('data-dismiss') ||
          e.target.getAttribute('data-close-popover')){
        closeDialog();
      }
    }
  }
  
  return <>
      <span onClick={openDialog} className={linkClassName} style={{ cursor: 'pointer', ...linkStyles }} title={tooltipText}>
        {linkContent ? linkContent : <i className="fas fa-bug fw" />}
      </span>
      <Dialog open={isOpen} onClose={closeDialog} fullWidth={true} maxWidth={size} >
        {isOpen && <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Debug</h5>
            <button type='button' className='close' onClick={closeDialog}>&times;</button>
          </div>
          <div className='modal-body' onClick={e => onInnerClick(e)}>
            {children()}
          </div>
        </div>}
      </Dialog>
    </>
});

export const DebugContainer = connect(
  (state) => ({
    showDebug: state.getIn(['userSettings', 'settings', 'show-debug'])
  }),
  (dispatch) => ({
  })
)(({ showDebug, children }) => {
  if (!showDebug)
    return <></>

  return children;
});