import React from 'react';
import { connect } from 'react-redux';
import {
  analysisCrossSchemaSearchLoadIdentityCategories,
  analysisCrossSchemaSearchSelectCategories
} from '../../../actions/analysis-cross-schema-search';
import { toJS } from '../../../utility/immutable-utility';
import { SearchResultCategories } from './SearchResultCategories';

export const SearchResultCategoriesConnected = connect(
  (state, { index, stateContext = 'default' }) => ({
    isLoading: state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'data', index, 'schemaCategories', 'isLoading']),
    schemaCategories: state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'data', index, 'schemaCategories', 'data']),
    selectedSchemaValues: state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'data', index, 'schemaCategories', 'selectedSchemaValues']),
  }),
  (dispatch, { index, id, stateContext = 'default' }) => ({
    loadCategories() {
      dispatch(analysisCrossSchemaSearchLoadIdentityCategories(stateContext, index, id));
    },
    selectSchema(name) {
      dispatch(analysisCrossSchemaSearchSelectCategories(stateContext, index, id, name));
    }
  })
)(({ loadCategories, isLoading, schemaCategories, selectedSchemaValues, selectSchema = [] }) => {
  const _schemaCategories = schemaCategories ? toJS(schemaCategories) : undefined;
  const _selectedSchemaValues = selectedSchemaValues ? toJS(selectedSchemaValues) : [];
  return <SearchResultCategories isLoading={isLoading} loadCategories={loadCategories} schemaCategories={_schemaCategories} selectedSchemaValues={_selectedSchemaValues} selectSchema={selectSchema} />
});