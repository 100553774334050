import { toJS } from '../utility/immutable-utility';
import {
  forEachInRowHierarchy
} from './reports';

export function mapSelectionToGrid(displayMap, reportRows) {
  let gridResult = [];
  let rowIndex = 0;
  const selectedItems = [];

  forEachInRowHierarchy(
    displayMap, 
    reportRows,
    ({row, key, rowKey}) => {
      rowIndex++;
      const rowValues = row.get('values');
      if (rowValues) {
        const selectedCells = toJS(rowValues.map((cell, index) => ({ cell, index })).filter(c => c.cell.get('isSelected')));
        if (selectedCells.length > 0) {
          selectedItems[rowIndex] = selectedCells.map(c => c.index);
          gridResult.push({
            name: row.get('name'),
            id: row.get('id'),
            rowKey: rowKey,
            key: key,
            cells: selectedCells.map(c => ({ ...c.cell, colIndex: c.index }))
          });
      }}
    }
  );

  if (selectedItems.length === 0)
    return { success: false, message: `Nothing selected` };

  // check the selection is rectangular
  let shapeValidation = undefined;
  let cellsInRow = undefined;
  let previousRowIndex = undefined;
  selectedItems.forEach((row, rowIndex) => {
    if (previousRowIndex !== undefined && rowIndex - previousRowIndex > 1)
      shapeValidation = { success: false, message: `This action won't work on multiple selections` };

    previousRowIndex = rowIndex;

    if (cellsInRow === undefined) {
      cellsInRow = JSON.stringify(row);
    } else {
      if (cellsInRow !== JSON.stringify(row)) {
        shapeValidation = { success: false, message: `This action won't work on multiple selections` };
      } else {
        for (let index = 0; index < row.length - 1; index++) {
          if (row[index + 1] - row[index] > 1) {
            shapeValidation = { success: false, message: `This action won't work on multiple selections` };
            break;
          }
        }
      }
    }
  });

  if (shapeValidation !== undefined)
    return shapeValidation;

  return { success: true, gridResult };
}

export function mapTableSelectionToCSV(displayMap, reportColumns, reportRows, includeHeader) {
  if (!reportColumns || !reportRows)
    return { success: false };

  const { success, gridResult, message } = mapSelectionToGrid(displayMap, reportRows);
  if (!success) {
    return { success: success, message: message };
  }

  const doc = [];
  const columnCount = gridResult[0].cells.length;

  if (includeHeader) {
    let docRow = [];
    docRow.push('');
    reportColumns.forEach((c, index) => {
      if (index < columnCount) {
        let title = c.title;
        title = title.replace('\n', ' ');
        docRow.push(title);
      }
    });

    doc.push(docRow.join('\t'));
  }

  gridResult.forEach(row => {
    let docRow = [];
    if (includeHeader) {
      docRow.push(row.name);
    }

    row.cells.forEach(cell => {
      const val = cell.adjustment !== undefined ? cell.adjustment : cell.value;
      docRow.push((val === null || val === undefined) ? '' : `${val}`);
    });

    doc.push(docRow.join('\t'));
  })

  return { success: true, doc: doc.join('\r\n') };
}