import React from 'react';
import { connect } from 'react-redux';
import PageHeader from '../../shared/PageHeader';
import {
  analysisWorkspaceClose
} from '../../../actions/analysis';
import { DynamicWorkspaceToolbarConnected } from '../analysis-baskets/dynamic-workspace/DynamicWorkspaceToolbarConnected';

const AnalysisPageHeader = connect(
  (state) => ({
    workspaceId: state.getIn(['analysis', 'workspace', 'id']),
    workspaceName: state.getIn(['analysis', 'workspace', 'name']),
    overlayPanelName: state.getIn(['analysis', 'overlay', 'panelName']),
    seriesSettingsPanelIsHidden: state.getIn(['analysis', 'ui', 'panelWidths', 'seriesSettings', 'isHidden'])
  }),
  (dispatch) => ({
    closeWorkspace() {
      dispatch(analysisWorkspaceClose());
    }
  })
)(({ workspaceId, workspaceName, closeWorkspace, canShowDynamicWorkspaces = true, overlayPanelName, seriesSettingsPanelIsHidden }) => {
  const _showDynamicWorkspaceOptions = canShowDynamicWorkspaces && ((overlayPanelName === 'SeriesSettingsPanel' ^ seriesSettingsPanelIsHidden === true) === 1);
  const _isWorkspaceDetailsVisible = !!workspaceId && !!workspaceName;
  const _isVisible = _showDynamicWorkspaceOptions || _isWorkspaceDetailsVisible;

  if (!_isVisible)
    return <></>

  return <div className='w-100 form-row' style={{ height: '37px' }}>
    <div className='col-md-4'>
      {_showDynamicWorkspaceOptions && <DynamicWorkspaceToolbarConnected isCompact={true} />}
    </div>
    <div className='col-md-4'>
      {_isWorkspaceDetailsVisible && <PageHeader>
        <button type='button' className='btn btn-sm float-left p-0 mr-2' onClick={closeWorkspace}>
          <i className='fas fa-times fa-fw'></i>
        </button>
        {workspaceName}
      </PageHeader>}
    </div>
    <div className='col-md-4'>
      <div></div>
    </div>
  </div>
});

export default AnalysisPageHeader;