import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import {
  dashboardWorkspacesInitialiseEdit,
  dashboardWorkspacesLoadForEdit,
  dashboardWorkspacesSaveForEdit,
} from '../../../actions/dashboard-tiles';

export const WorkspaceEditConnected = connect(
  (state, workspaceType, workspacePath) => ({
    workspacePath,
    workspaceType,
    workspaceData: state.getIn(['dashboardTiles', 'editWorkspace'])
  }),
  (dispatch, { workspaceType, workspacePath }) => ({
    initialiseWorkspace() {
      dispatch(dashboardWorkspacesInitialiseEdit());
    },
    loadWorkspace() {
      dispatch(dashboardWorkspacesLoadForEdit({ workspaceType, workspacePath }));
    },
    saveWorkspace(workspace) {
      dispatch(dashboardWorkspacesSaveForEdit(workspace));
    }
  })
)(({ workspaceData, initialiseWorkspace, loadWorkspace, saveWorkspace, settingsCallbacks }) => {
  const [workspaceConfig, setWorkspaceConfig] = useState("");
  const [isLoaded, setIsloaded] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      initialiseWorkspace();
    }
  }, [isLoaded, initialiseWorkspace]);

  useEffect(() => {
    if (!workspaceData) {
      loadWorkspace();
      setWorkspaceConfig("");
    } else {
      setIsloaded(true);
      const _workspaceData = toJS(workspaceData, {});
      const rawJson = _workspaceData.data;
      try {
        const jsonObject = JSON.parse(rawJson);
        const formatted = JSON.stringify(jsonObject, undefined, 2);
        setWorkspaceConfig(formatted);
      }
      catch {
        setWorkspaceConfig(_workspaceData.data);
      }
    }
  }, [workspaceData, setWorkspaceConfig, setIsloaded, loadWorkspace]);

  function onSetWorkspaceConfig(text) {
    const _workspaceData = toJS(workspaceData, {});
    const rawJson = _workspaceData.data;
    let formatted = rawJson;
    try {
      const jsonObject = JSON.parse(rawJson);
      formatted = JSON.stringify(jsonObject, undefined, 2);
    }
    catch{      
    }
    
    if (formatted !== text) {
      setIsDirty(true);
    }
    else {
      setIsDirty(false);
    }

    setWorkspaceConfig(text);
  }

  settingsCallbacks.onSaving = () => {
    if (isDirty){
      const _workspaceData = toJS(workspaceData, {});
      _workspaceData.data = workspaceConfig;
      saveWorkspace(_workspaceData);
      setIsDirty(false);
    }
  }

  return <div >
    <textarea cols="50" style={{height:'540px'}} className='form-control' value={workspaceConfig} onChange={e => onSetWorkspaceConfig(e.target.value)} >
    </textarea>
  </div>
});

export default WorkspaceEditConnected;