import { push } from "redux-first-history";
import { createAction } from '../utility/redux-utility';
import { csvExport, csvExportText, jsonExport, saveAs } from '../utility/file-utility';
import { toJS } from '../utility/immutable-utility';
import { copyToClipboard, getClipboardTextAsync, mapCSVToArray } from '../utility/text-utility';
import { mapScenarioOverrides, hasLensChanged, reduceScenarios } from '../mapper/reportMapper';
import { mapToTimeSeriesReportRequest, mapToTimeSeriesTemplateNameRequest, mapToTemplatedNameExpression } from '../utility/analysisReportRequest-utility';
import { authFetch } from '../auth';
import { saveAnalysisAdjustmentsAsync, validateAnalysisAdjustmentsAsync } from './adjustments';
import { 
  ANALYSIS_API_ROOT_URL,
  ANALYSIS_API_URL, 
  ANALYSIS_REPORT_API_ROOT_URL, 
  ANALYSIS_SEARCH_API_ROOT_URL, 
  ANALYSIS_SEARCH_API_URL, 
  WORKSPACES_API_URL, 
  WORKSPACES_API_URL_ROOT } from '../config';
import moment from 'moment';
import { formatDateToIso } from '../utility/date-utility';
import qs from 'querystring';
import {
  logInfoNotification,
  logErrorNotification,
  logWarningNotification
} from './log';
import {
  analysisRefreshComplete,
  analysisChartingBasketCloneTimeSeries,
  analysisChartingBasketSetValue,
  analysisUpdateAllSeriesNameStyle
} from './analysis-basket-v2';
import {
  getAdjustmentsToSave
} from '../reducer-functions/analysis';
import {
  mapTableSelectionToCSV,
  mapSelectionToGrid  
} from '../reducer-functions/analysis-clipboard-mapper';
import { 
  workspaceUpdateHitCount
} from './workspace';
import { mapWorkspaceFromApiModel, mapAnalysisWorkspaceToApiModel } from '../mapper/workspaceMapper';
import { 
  analysisDynamicWorkspaceSaveBaseline, 
  applyDynamicFilterOverridesToBasketAsync 
} from './analysis-dynamic-workspace';
import { getPeriods } from "../utility/period-utility";

export const ANALYSIS_TOGGLE_EVOLUTION = 'ANALYSIS_TOGGLE_EVOLUTION';
export const analysisToggleEvolution = createAction(ANALYSIS_TOGGLE_EVOLUTION);

export const ANALYSIS_TOGGLE_MATERIALISATION_MODE = 'ANALYSIS_TOGGLE_MATERIALISATION_MODE';
export const analysisToggleMaterialisationMode = createAction(ANALYSIS_TOGGLE_MATERIALISATION_MODE);

export const ANALYSIS_TOGGLE_VARIANT_MODE = 'ANALYSIS_TOGGLE_VARIANT_MODE';
export const analysisToggleVariantMode = createAction(ANALYSIS_TOGGLE_VARIANT_MODE);

export const ANALYSIS_SHOW_OVERLAY = 'ANALYSIS_SHOW_OVERLAY';
export const analysisShowOverlay = createAction(ANALYSIS_SHOW_OVERLAY, 'value');

export const ANALYSIS_HIDE_OVERLAY = 'ANALYSIS_HIDE_OVERLAY';
export const analysisHideOverlay = createAction(ANALYSIS_HIDE_OVERLAY);

export const ANALYSIS_UI_TOGGLE_COLLAPSIBLE_SIDEBAR = 'ANALYSIS_UI_TOGGLE_COLLAPSIBLE_SIDEBAR';
export const analysisUIToggleCollapsibleSideBar = createAction(ANALYSIS_UI_TOGGLE_COLLAPSIBLE_SIDEBAR);

export const ANALYSIS_UI_TOGGLE_CREATE_REPORT_MODAL = 'ANALYSIS_UI_TOGGLE_CREATE_REPORT_MODAL';
export const analysisUIToggleCreateReportModal = createAction(ANALYSIS_UI_TOGGLE_CREATE_REPORT_MODAL, 'isVisible');

export const ANALYSIS_UI_SET_PANEL_WIDTH = 'ANALYSIS_UI_SET_PANEL_WIDTH';
export const analysisUISetPanelWidth = createAction(ANALYSIS_UI_SET_PANEL_WIDTH, 'key', 'panelWidth');

export const ANALYSIS_UI_TOGGLE_PANEL_VISIBILITY = 'ANALYSIS_UI_TOGGLE_PANEL_VISIBILITY';
export const analysisUITogglePanelVisibility = createAction(ANALYSIS_UI_TOGGLE_PANEL_VISIBILITY, 'key');

export const ANALYSIS_UI_RESIZE_PANELS = 'ANALYSIS_UI_RESIZE_PANELS';
export const analysisUIResizePanels = createAction(ANALYSIS_UI_RESIZE_PANELS, 'keys', 'panels');

export const ANALYSIS_UI_UPDATE_REFLOW_SWITCH = 'ANALYSIS_UI_UPDATE_REFLOW_SWITCH';
export const analysisUIUpdateReflowSwitch = createAction(ANALYSIS_UI_UPDATE_REFLOW_SWITCH);

export const ANALYSIS_SEARCH_STARTED = 'ANALYSIS_SEARCH_STARTED';
const analysisSearchStarted = createAction(ANALYSIS_SEARCH_STARTED);

export const analysisSearch = () => (dispatch, getState) => {
  const state = getState();
  let criteria = state.getIn(['analysis', 'search', 'criteria']),
    query = criteria.get('query'),
    customFilter = criteria.get('customFilter'),
    orderBy = criteria.get('orderBy'),
    orderByDirection = criteria.get('orderByDirection'),
    page = criteria.get('page'),
    pageSize = criteria.get('pageSize');

  const top = pageSize || 100;
  const skip = page && page > 1 ? (page * top) - top : 0;
  
  const filters = criteria.get('filters').map(f => {
    const name = f.get('name');
    const value = f.get('value');

    const isNumber = !isNaN(value);
    if (isNumber && name !== 'schemaId') return `${name} eq ${value}`;

    const dateTime = moment.utc(value, moment.ISO_8601, true);
    if (dateTime && dateTime.isValid()) return `${name} eq ${dateTime.format()}`;

    return `${name} eq '${value}'`;
  }).toArray();

  // Azure search skip limit
  if (skip > 100000) return dispatch(logInfoNotification('Paging limit reached, please refine your search.'));
  
  const facets = criteria.get('facets').toArray();
   
  let search = window.location.search;

  if (search.startsWith('?')) search = search.substring(1);

  let { staleMonthLimit, onlyFavourites } = qs.parse(search);

  if (staleMonthLimit <= 0) staleMonthLimit = criteria.get('enableDiscontinuedFilter') ? criteria.get('discontinuedFilterMonths') : undefined;
  if (!onlyFavourites) onlyFavourites = criteria.get('enableFavouritesFilter') ? criteria.get('enableFavouritesFilter') : undefined;

  const searchSchema = state.getIn(['analysis', 'search', 'selectedSearchSchema']);
  if(searchSchema) {

    let params = {
      query: query || '*',
      customFilter: customFilter,
      filters: filters,
      onlyFavourites: onlyFavourites,
      top: top,
      skip: skip
    };

    for (let i in params) if (params[i] === undefined) delete params[i];

    dispatch(analysisSearchStarted());

    authFetch(`${ANALYSIS_SEARCH_API_ROOT_URL}/v1/schemas/search/${searchSchema}?${qs.stringify(params)}`)
      .then(response => response.json())
      .then(data => {
        dispatch(analysisSearchComplete(data));

        if (data.count === 1) {
          const result = data.results[0];

          dispatch(analysisSearchLoadCategories(result.id));
          dispatch(analysisSearchToggleExpand(result.id));
        }
      })
      .catch(error => {
        dispatch(analysisSearchComplete());
        dispatch(logErrorNotification(error));
      });
  } else {


    let params = {
      query: query || '*',
      customFilter: customFilter,
      staleMonthLimit: staleMonthLimit,
      onlyFavourites: onlyFavourites,
      filters: filters,
      facets: facets,
      orderBy: `${orderBy || 'name'} ${orderByDirection || 'asc'}`,
      top: top,
      skip: skip
    };

    for (let i in params) if (params[i] === undefined) delete params[i];

    dispatch(analysisSearchStarted());

    authFetch(`${ANALYSIS_SEARCH_API_URL}/timeseriessearch?${qs.stringify(params)}`)
      .then(response => response.json())
      .then(data => {
        dispatch(analysisSearchComplete(data));

        if (data.count === 1) {
          const result = data.results[0];

          dispatch(analysisSearchLoadCategories(result.id));
          dispatch(analysisSearchToggleExpand(result.id));
        }
      })
      .catch(error => {
        dispatch(analysisSearchComplete());
        dispatch(logErrorNotification(error));
      });
  }
};

export const ANALYSIS_SEARCH_COMPLETE = 'ANALYSIS_SEARCH_COMPLETE';
const analysisSearchComplete = createAction(ANALYSIS_SEARCH_COMPLETE, 'data');

export const ANALYSIS_UPDATE_FACET_STYLE = 'ANALYSIS_UPDATE_FACET_STYLE';
export const analysisUpdateFacetStyle = createAction(ANALYSIS_UPDATE_FACET_STYLE, 'data');

export const analysisSearchExport = (type) => (dispatch, getState) => {
  const state = getState();

  const results = state.getIn(['analysis', 'search', 'results', 'data']).toJS();

  if (!results || !results.length) return;

  const fileName = `analysis-search-export_${moment().format('DD-MMM-YYYY-HH-mm-ss')}`,
    columns = [
      'id', 'source', 'sourceId', 'name', 'dataType', 'style', 'granularity', 'granularityFrequency', 'granularityType', 'sourceTimeZoneId',
      'unit', 'currency', 'lastUpdatedUtc'
    ];

  if (type === 'json') jsonExport(fileName, columns, results)
  else csvExport(fileName, columns, results);
};

export const analysisSearchLoadCategories = (key) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_URL}/timeseries?id=${key}`)
    .then(response => response.json())
    .then(({ categories, sourceCategories }) => {
      dispatch(analysisSearchLoadCategoriesComplete(key, { categories, sourceCategories }));
    })
    .catch(error => {
      dispatch(analysisSearchLoadCategoriesComplete(key));
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_SEARCH_LOAD_CATEGORIES_COMPLETE = 'ANALYSIS_SEARCH_LOAD_CATEGORIES_COMPLETE';
const analysisSearchLoadCategoriesComplete = createAction(ANALYSIS_SEARCH_LOAD_CATEGORIES_COMPLETE, 'key', 'data');

export const analysisSearchLoadStatistics = (key) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_URL}/timeseries-statistics?id=${key}`)
    .then(response => response.json())
    .then((data) => {
      dispatch(analysisSearchLoadStatisticsComplete(key, data));
    })
    .catch(error => {
      dispatch(analysisSearchLoadStatisticsComplete(key));
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_SEARCH_LOAD_STATISTICS_COMPLETE = 'ANALYSIS_SEARCH_LOAD_STATISTICS_COMPLETE';
const analysisSearchLoadStatisticsComplete = createAction(ANALYSIS_SEARCH_LOAD_STATISTICS_COMPLETE, 'key', 'data');

export const ANALYSIS_TOGGLE_EXPAND_FACETS = 'ANALYSIS_TOGGLE_EXPAND_FACETS';
export const analysisToggleExpandFacets = createAction(ANALYSIS_TOGGLE_EXPAND_FACETS);

export const ANALYSIS_TOGGLE_EXPAND_DETAILS = 'ANALYSIS_TOGGLE_EXPAND_DETAILS';
export const analysisToggleExpandDetails = createAction(ANALYSIS_TOGGLE_EXPAND_DETAILS);

export const ANALYSIS_SET_EXPAND_FACETS = 'ANALYSIS_SET_EXPAND_FACETS';
export const analysisSetExpandFacets = createAction(ANALYSIS_SET_EXPAND_FACETS, 'value');

export const ANALYSIS_SET_EXPAND_DETAILS = 'ANALYSIS_SET_EXPAND_DETAILS';
export const analysisSetExpandDetails = createAction(ANALYSIS_SET_EXPAND_DETAILS, 'value');

export const ANALYSIS_SET_SELECTED_SEARCH_SCHEMA = 'ANALYSIS_SET_SELECTED_SEARCH_SCHEMA';
export const analysisSearchSetSelectedSearchSchema = createAction(ANALYSIS_SET_SELECTED_SEARCH_SCHEMA, 'value');

export const ANALYSIS_TOGGLE_EXPAND_ITEM_DETAILS = 'ANALYSIS_TOGGLE_EXPAND_ITEM_DETAILS';
export const analysisToggleExpandItemDetails = createAction(ANALYSIS_TOGGLE_EXPAND_ITEM_DETAILS, 'key');

export const ANALYSIS_SEARCH_TOGGLE_EXPAND = 'ANALYSIS_SEARCH_TOGGLE_EXPAND';
export const analysisSearchToggleExpand = createAction(ANALYSIS_SEARCH_TOGGLE_EXPAND, 'key');

export const analysisSearchToggleInBasket = (key, data, selectedSchemaName) => (dispatch) => {
  dispatch(analysisSearchToggleInBasketComplete(key, data, selectedSchemaName));
  dispatch(analysisRefreshTemplateNameExpressions());
};

export const ANALYSIS_SEARCH_TOGGLE_IN_BASKET = 'ANALYSIS_SEARCH_TOGGLE_IN_BASKET';
const analysisSearchToggleInBasketComplete = createAction(ANALYSIS_SEARCH_TOGGLE_IN_BASKET, 'key', 'data', 'selectedSchemaName');

export const analysisSearchAddRangeToBasket = (key, data, suppressExistsCheck) => (dispatch) => {
  dispatch(analysisSearchAddRangeToBasketComplete(key, data, suppressExistsCheck));
  dispatch(analysisRefreshTemplateNameExpressions());
};

export const ANALYSIS_SEARCH_ADD_RANGE_TO_BASKET = 'ANALYSIS_SEARCH_ADD_RANGE_TO_BASKET';
const analysisSearchAddRangeToBasketComplete = createAction(ANALYSIS_SEARCH_ADD_RANGE_TO_BASKET, 'keys', 'data', 'suppressExistsCheck');

export const ANALYSIS_SEARCH_REMOVE_RANGE_FROM_BASKET = 'ANALYSIS_SEARCH_REMOVE_RANGE_FROM_BASKET';
export const analysisSearchRemoveRangeFromBasket = createAction(ANALYSIS_SEARCH_REMOVE_RANGE_FROM_BASKET, 'keys');

export const analysisAddInstance = (id) => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']);
  const asAtUtc =  state.getIn(['instanceViewer', 'selectedInstance', 'asAtUtc']);
  const firstDpUtc =  state.getIn(['instanceViewer', 'selectedInstance', 'firstDataPointUtc']);
  const lastDpUtc =  state.getIn(['instanceViewer', 'selectedInstance', 'lastDataPointUtc']);
  const params = {
    query: '*',
    customFilter: `search.in(id, '${identityId}')`
  };

  return authFetch(`${ANALYSIS_SEARCH_API_URL}/timeseriessearch?${qs.stringify(params)}`)
  .then(response => response.json())
  .then(data => {
    if (!data && !data.results)
      return;

    const keys = data.results.map(i => i.id);
    data.results.forEach(x => {
      x.seriesStyle = 'justInstance';
      x.asAtType = 'asAt';
      x.asAt = asAtUtc;
      x.windowType = 'override'
      x.window = {
        fromDateMode: 'abs',
        absFromDate: formatDateToIso(firstDpUtc),
        toDateMode: 'abs',        
        absToDate: formatDateToIso(lastDpUtc)
      }
    });
    
    dispatch(analysisSearchAddRangeToBasket(keys, data.results, true));
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
}

export const analysisAddWithScenarioOverridesAndOpen = (id) => (dispatch) => {
  dispatch(push('/analysis/chart'));
  dispatch(analysisAddWithScenarioOverrides(id)).then(() => {    
    dispatch(analysisRefresh());
  });
};

export const analysisAddWithScenarioOverrides = (id) => (dispatch, getState) => {
  const state = getState();

  const lens = state.getIn(['reports', 'criteria', 'lens']);
  const lastCriteriaLens = state.getIn(['reports', 'lastCriteria', 'lens']);    
  const scenarioOverridesMap = reduceScenarios(state.getIn(['reports', 'results', 'scenarioOverrideMap']).toJS());
  const displayMap = state.getIn(['reports', 'results', 'displayMap']).toJS();
  const rows = state.getIn(['reports', 'results', 'rows']).toJS();
  const columns = state.getIn(['reports', 'results', 'columns']).toJS();
  const scenarioOverrides = mapScenarioOverrides(scenarioOverridesMap, displayMap, rows, columns, !hasLensChanged(lastCriteriaLens, lens));
  const complexScenario = [...new Set(scenarioOverrides.filter(x => x.scenario).map(x => x.scenario))].join(',');
  const params = {
    query: '*',
    customFilter: `search.in(id, '${id}')`
  };

  return authFetch(`${ANALYSIS_SEARCH_API_URL}/timeseriessearch?${qs.stringify(params)}`)
    .then(response => response.json())
    .then(data => {
      if (!data && !data.results)
        return;

      const keys = data.results.map(i => i.id);
      data.results.forEach(x => {
        x.complexScenario = complexScenario;
        x.scenarioOverrides = scenarioOverrides;
      });
      
      dispatch(analysisSearchAddRangeToBasket(keys, data.results, true));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const analysisAddRangeSelectionByCsvIds = (csvIdentityIds) => (dispatch, getState) => {
  const identityIds = `${csvIdentityIds}`.split(',').filter(i => i);

  dispatch(analysisAddRangeSelectionByIds(identityIds));
};

const analysisAddRangeSelectionByIds = (identityIds) => (dispatch, getState) => {
  if (!identityIds || !identityIds.length)
    return;

  identityIds = identityIds.filter(i => i.trim());
  const params = {
    query: '*',
    customFilter: `search.in(id, '${identityIds.join(',')}')`
  };

  return authFetch(`${ANALYSIS_SEARCH_API_URL}/timeseriessearch?${qs.stringify(params)}`)
    .then(response => response.json())
    .then(data => {
      if (!data && !data.results)
        return;

      identityIds.forEach(i => {
        if (!data.results.some(r => `${r.id}`.trim() === `${i}`.trim()))
          dispatch(logWarningNotification(`Failed to find ${i}`));
      });

      const keys = data.results.map(i => i.id);
      dispatch(analysisSearchAddRangeToBasket(keys, data.results, true));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const analysisSelectionAddOnDemandDerivedSelection = (userTimeSeriesSettings) => (dispatch, getState) => {
  dispatch(analysisSelectionAddOnDemandDerivedSelectionExecute(userTimeSeriesSettings));
  dispatch(analysisRefreshTemplateNameExpressions());
}

export const ANALYSIS_SELECTION_ADD_ON_DEMAND_DERIVED_SELECTION = 'ANALYSIS_SELECTION_ADD_ON_DEMAND_DERIVED_SELECTION';
const analysisSelectionAddOnDemandDerivedSelectionExecute = createAction(ANALYSIS_SELECTION_ADD_ON_DEMAND_DERIVED_SELECTION, 'userTimeSeriesSettings');

export const analysisWorkspaceLoad = (workspaceId, url) => async (dispatch, getState) => {
  return authFetch(`${WORKSPACES_API_URL_ROOT}/v3/workspaces/${workspaceId}`)
    .then(response => response.json())
    .then(data => {
      const workspace = mapWorkspaceFromApiModel(`${workspaceId}`, data);
      if (workspace.chartSettings) {
        dispatch(analysisUpdateChartEditSettings(workspace.chartSettings));
        dispatch(analysisApplyChartEditSettings());
      }
      
      dispatch(workspaceUpdateHitCount(workspaceId, 'homepage'));
      dispatch(analysisDynamicWorkspaceSaveBaseline(workspace.baselineBasket));
      dispatch(analysisWorkspaceLoadComplete(workspaceId, workspace));

      const pageUrl = workspace.pageUrl || url;

      if (pageUrl) dispatch(push(pageUrl));
    })
    .catch(error => {
      dispatch(analysisDynamicWorkspaceSaveBaseline([]));
      dispatch(analysisWorkspaceLoadComplete(workspaceId));
      dispatch(logErrorNotification(error));
    });
};

export const analysisWorkspacePathNew = (workspaceParams, url) => (dispatch, getState) => {

  let ids = [];
  let idMap = {};
  for(let i=0; i < workspaceParams.key.length; i++) {
    const parts = workspaceParams.key[i].split('|');
    const id = parts[0];
    if(!idMap[id]) {
      idMap[id] = [];
      ids.push(id);
    }

    if(parts.length > 1) {
      idMap[id].push(parts[1]);
    } else {
      idMap[id].push('');
    }
  }

  dispatch(analysisAddRangeSelectionByIds(ids)).then(() => {

    Object.keys(idMap).forEach(k => {
      const scenarios = idMap[k];
      scenarios.forEach((s, i) => {
        if(s !== '') {
          if(i === 0) {
            dispatch(analysisChartingBasketSetValue(`${k}`, 'scenario', s));
          } else  {
            dispatch(analysisChartingBasketCloneTimeSeries(`${k}`, 'default', true, 'scenario', s));
          }
         }
      });
    });

    dispatch(analysisUpdateAllSeriesNameStyle('default'));

    if (workspaceParams.fromUtc) {
      workspaceParams.fromUtc = moment(workspaceParams.fromUtc).format('YYYY-MM-DDTHH:mm');
      workspaceParams.fromDateMode = 'abs';
      workspaceParams.period = '';
    }

    if (workspaceParams.toUtc) {
      workspaceParams.toUtc = moment(workspaceParams.toUtc).format('YYYY-MM-DDTHH:mm');
      workspaceParams.toDateMode = 'abs';
      workspaceParams.period = '';
    }

    Object.keys(workspaceParams).forEach(p => {
      dispatch(analysisUpdateChartSettings(`${p}`, workspaceParams[p]));
    });
   
    dispatch(push(url));
    dispatch(analysisRefresh());
  });
}

export const analysisWorkspacePathLoad = (path, url, dynamicFilterOverrides) => (dispatch, getState) => {
  if (!path) return;

  let workspacePath = path.startsWith('/') ? path : `/${path}`;

  if (!workspacePath.toLowerCase().startsWith('/analysis')) workspacePath = `/analysis${workspacePath}`;

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v3/workspaces?dataVersion=3&path=${encodeURIComponent(workspacePath)}`)
    .then(response => {
      if (response.status !== 200) {
        dispatch(logErrorNotification(`${response.status} - Failed to load "${workspacePath}"`));
        return new Promise((resolve) => resolve(undefined));
      }

      return response.json();
    })
    .then(async data => {
      if (data) {
        const workspace = mapWorkspaceFromApiModel(`${data.id}`, data);
        if (dynamicFilterOverrides) {
          const { basket, errors } = await applyDynamicFilterOverridesToBasketAsync(workspace.dynamicWorkspaces, dynamicFilterOverrides, workspace.timeseries, workspace.baselineBasket);
          workspace.timeseries = basket;
          if (errors) {
            errors.forEach(message => {
              dispatch(logWarningNotification(message));
            });
          }
        }

        if (workspace.chartSettings) {
          dispatch(analysisUpdateChartEditSettings(workspace.chartSettings));
          dispatch(analysisApplyChartEditSettings());
        } 
      
        dispatch(workspaceUpdateHitCount(data.id, 'homepage'));
        dispatch(analysisDynamicWorkspaceSaveBaseline(workspace.baselineBasket));
        dispatch(analysisWorkspaceLoadComplete(data.id, workspace));
        const pageUrl = workspace.pageUrl || url;

        if (pageUrl) dispatch(push(pageUrl));
      }
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(analysisWorkspaceLoadComplete());
    });
};

export const ANALYSIS_WORKSPACE_LOAD_COMPLETE = 'ANALYSIS_WORKSPACE_LOAD_COMPLETE';
const analysisWorkspaceLoadComplete = createAction(ANALYSIS_WORKSPACE_LOAD_COMPLETE, 'key', 'workspace');

export const ANALYSIS_WORKSPACE_UPDATE = 'ANALYSIS_WORKSPACE_UPDATE';
const analysisWorkspaceUpdate = createAction(ANALYSIS_WORKSPACE_UPDATE, 'key', 'data');

export const ANALYSIS_EDIT_WORKSPACE_BEGIN = 'ANALYSIS_EDIT_WORKSPACE_BEGIN';
export const analysisEditWorkspaceBegin = createAction(ANALYSIS_EDIT_WORKSPACE_BEGIN);

export const ANALYSIS_EDIT_WORKSPACE_END = 'ANALYSIS_EDIT_WORKSPACE_END';
export const analysisEditWorkspaceEnd = createAction(ANALYSIS_EDIT_WORKSPACE_END);

export const ANALYSIS_EDIT_WORKSPACE_UPDATE = 'ANALYSIS_EDIT_WORKSPACE_UPDATE';
export const analysisEditWorkspaceUpdate = createAction(ANALYSIS_EDIT_WORKSPACE_UPDATE, 'data');

export const ANALYSIS_EDIT_WORKSPACE_APPLY_CHANGES = 'ANALYSIS_EDIT_WORKSPACE_APPLY_CHANGES';
export const analysisEditWorkspaceApplyChanges = createAction(ANALYSIS_EDIT_WORKSPACE_APPLY_CHANGES);

export const analysisWorkspaceSave = ({ id, folderPath, subFolderPath, name, type, scope, version, thumbImageId }) => (dispatch, getState) => {
  const state = getState();

  const data = mapAnalysisWorkspaceToApiModel(
    state.getIn(['analysis', 'workspace']).toJS(),
    getPeriods(state));

  if (!version) version = 3;

  if (!folderPath && !!subFolderPath) {
    const parts = subFolderPath.split('/').filter(i => !!i);

    if (parts.length === 1) {
      folderPath = parts[0];
      subFolderPath = '';
    }
    else {
      folderPath = parts[0];
      subFolderPath = parts.slice(1).join('/');
    }
  }

  authFetch(`${WORKSPACES_API_URL}/workspaces`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id, type, scope, folderPath, subFolderPath, name, version, thumbImageId,
      data: data && JSON.stringify(data)
    })
  })
    .then(response => response.json())
    .then(data => {
      dispatch(analysisWorkspaceUpdate(data.workspaceId, { type, scope, folderPath, subFolderPath, name }));
      dispatch(logInfoNotification(`Workspace '${name}' saved`));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const analysisWorkspaceCopy = (id, path, scope, callback) => (dispatch, getState) => {
  authFetch(`${WORKSPACES_API_URL}/workspaces/${id}/copy?path=${path}${scope ? `&scope=${scope}` : ''}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(data => {
      dispatch(logInfoNotification(`Workspace '${path}' saved`));

      if (typeof callback === 'function') callback(data);
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const analysisWorkspaceCloseIfOpen = (key) => (dispatch, getState) => {
  const state = getState();

  if (state.getIn(['analysis', 'workspace', 'id']) === key) dispatch(analysisWorkspaceUpdate(0, ''));
};

export const analysisWorkspaceClose = () => (dispatch, getState) => {
  dispatch(analysisWorkspaceUpdate(0, {
    type: '',
    scope: 'Private',
    folderPath: '',
    subFolderPath: '',
    name: ''
  }));
};

export const ANALYSIS_REFRESH_STARTED = 'ANALYSIS_REFRESH_STARTED';
const analysisRefreshStarted = createAction(ANALYSIS_REFRESH_STARTED);

export const analysisRefresh = () => async (dispatch, getState) =>  {
  dispatch(analysisRefreshStarted());
  const state = getState();

  const workspace = toJS(state.getIn(['analysis', 'workspace']));
  const periodsAbs = await fetchPeriodsAbs(getPeriods(state));

  dispatch(analysisReportCriteriaUpdatePeriodAbsCompleted(periodsAbs));

  const reportRequest = mapToTimeSeriesReportRequest(workspace, periodsAbs);
  const {timeSeriesReport, errors, warnings} = await fetchTimeSeriesReportAsync(reportRequest);
  errors.forEach(e => dispatch(logErrorNotification(e)));
  warnings.forEach(w => dispatch(logWarningNotification(w)));

  if (timeSeriesReport == null)
  {
    dispatch(analysisRefreshComplete());
    return;
  }

  const expressionRequest = mapToTemplatedNameExpression(workspace, periodsAbs);
  const timeSeriesExpressionNames = await fetchTimeSeriesNamesAsync(expressionRequest);

  dispatch(analysisRefreshComplete(timeSeriesReport, timeSeriesExpressionNames, reportRequest, expressionRequest));
  dispatch(analysisAdjustmentsValidate());
};

export const analysisExport = () => async (dispatch, getState) =>  {
  const state = getState();
  const workspace = toJS(state.getIn(['analysis', 'workspace']));
  const periodsAbs = await fetchPeriodsAbs(getPeriods(state));

  dispatch(analysisReportCriteriaUpdatePeriodAbsCompleted(periodsAbs));

  const reportCriteria = mapToTimeSeriesReportRequest(workspace, periodsAbs);

  return authFetch(`${ANALYSIS_REPORT_API_ROOT_URL}/v5/timeseries-report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    body: JSON.stringify(reportCriteria)
  })
  .then(response => {
    let filename = `analysis-export-${new Date().getTime()}`;
    if (response.headers && response.headers.has('content-disposition')) {
      const contentDisposition = response.headers.get('content-disposition');
      const matches = /filename="?([^";]+)/.exec(contentDisposition);

      if (matches.length > 1) filename = matches[1];
    }

    return response.blob().then(blob => saveAs(filename, blob));
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export async function fetchPeriodsAbs(periods){  
  return authFetch(`${ANALYSIS_API_ROOT_URL}/v1/periods/as-absolute`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      relativeTo: moment().format(),
      periods: periods
    })
  })
  .then(response => response.json())
  .then(data => {
    return data.periods;
  });
}

export async function fetchTimeSeriesReportAsync(criteria) {
  return authFetch(`${ANALYSIS_REPORT_API_ROOT_URL}/v6/timeseries-report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(criteria)
  })
  .then(response => response.json())
  .then(data => {
    const { timeSeries = [] } = data;

    const errors = [];    
    timeSeries.filter(i => i.error && i.error.length).forEach(i => {
      i.error.forEach(message => errors.push(`${i.key}: ${message}`));
    });

    const warnings = [];
    timeSeries.filter(i => i.warning && i.warning.length).forEach(i => {
      i.warning.forEach(message => warnings.push(`${i.key}: ${message}`));
    });

    return {timeSeriesReport:data, warnings, errors:[]};
  })
  .catch(error => {
    return {timeSeriesReport:null, warnings:[], errors:[error]};
  });
}

export async function fetchTimeSeriesNamesAsync({timeseries, templateNameExpression, comparisonMode, operation, valueType, window, asAt, shape, timeZoneId, periods }){
  const request = mapToTimeSeriesTemplateNameRequest(timeseries, templateNameExpression, comparisonMode, operation, valueType, window, asAt, shape, timeZoneId, periods);
  return authFetch(`${ANALYSIS_REPORT_API_ROOT_URL}/v3/timeseries/templated-names`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
  .then(response => response.json());
}

export const analysisRefreshTemplateNameExpressions = (stateKey) => async (dispatch, getState) =>  {
  const state = getState();
  const workspaceStatePath = stateKey ? ['dashboardTiles', 'tilesState', stateKey, 'workspace'] : ['analysis', 'workspace'];
  const workspace = toJS(state.getIn(workspaceStatePath), {});
  const periodsAbs = await fetchPeriodsAbs(getPeriods(state));
  dispatch(analysisReportCriteriaUpdatePeriodAbsCompleted(periodsAbs));
  const expressionRequest = mapToTemplatedNameExpression(workspace, periodsAbs);
  return await applyTemplateNameExpressionAsync(dispatch, stateKey, expressionRequest, workspace);
}

export const analysisUpdateTemplateNameExpression = (newExpression, stateKey) => async (dispatch, getState) =>  {
  const state = getState();
  const workspaceStatePath = stateKey ? ['dashboardTiles', 'tilesState', stateKey, 'workspace'] : ['analysis', 'workspace'];
  const workspace = toJS(state.getIn(workspaceStatePath), {});
  const periodsAbs = await fetchPeriodsAbs(getPeriods(state));
  dispatch(analysisReportCriteriaUpdatePeriodAbsCompleted(periodsAbs));
  const expressionRequest = mapToTemplatedNameExpression(workspace, periodsAbs);
  if (expressionRequest.templateNameExpression === newExpression)
    return;
  
  expressionRequest.templateNameExpression = newExpression;
  return await applyTemplateNameExpressionAsync(dispatch, stateKey, expressionRequest, workspace);
}

async function applyTemplateNameExpressionAsync(dispatch, stateKey, expressionRequest, workspace) {
  let {timeseries = []} = workspace;
  if (timeseries.length === 0)
    return;
  
  dispatch(analysisRefreshTemplateNameExpressionsBegin(expressionRequest.templateNameExpression));

  try {
    const timeSeriesExpressionNames = await fetchTimeSeriesNamesAsync(expressionRequest);
    timeseries.forEach(wkTs => {
      const timeSeriesExpressionName = timeSeriesExpressionNames.find(t => t.key === wkTs.key);
      if (timeSeriesExpressionName) {
        // if the asAt updated name has previously come back from the getreport API we dont want to override it
        if (!wkTs.preventNameOverride)
          wkTs.timeSeriesName = timeSeriesExpressionName.name;

        wkTs.expressionName = timeSeriesExpressionName.templatedName;
        if (wkTs.expressionName.length === 0 && wkTs.nameStyle === 'expression')
          wkTs.nameStyle = 'default';
      }
    });

    dispatch(analysisRefreshTemplateNameExpressionsComplete(stateKey, timeSeriesExpressionNames, timeseries));
  }
  catch(error) {
    dispatch(analysisRefreshTemplateNameExpressionsComplete(stateKey, undefined, undefined));
    dispatch(logErrorNotification(error));
  }
}

export const ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_BEGIN = 'ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_BEGIN';
const analysisRefreshTemplateNameExpressionsBegin = createAction(ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_BEGIN, 'expression');

export const ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_COMPLETE = 'ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_COMPLETE';
const analysisRefreshTemplateNameExpressionsComplete = createAction(ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_COMPLETE, 'stateKey', 'templateNameExpressions', 'timeseries');

export const ANALYSIS_UPDATE_TABLE_SETTINGS = 'ANALYSIS_UPDATE_TABLE_SETTINGS';
export const analysisUpdateTableSettings = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS, 'data');

export const ANALYSIS_UPDATE_COLOUR_CYCLE_SETTINGS = 'ANALYSIS_UPDATE_COLOUR_CYCLE_SETTINGS';
export const analysisUpdateColourCycleSettings = createAction(ANALYSIS_UPDATE_COLOUR_CYCLE_SETTINGS, 'data');

export const ANALYSIS_UPDATE_EXPERIMENTAL_SETTINGS = 'ANALYSIS_UPDATE_EXPERIMENTAL_SETTINGS';
export const analysisUpdateExperimentalSettings = createAction(ANALYSIS_UPDATE_EXPERIMENTAL_SETTINGS, 'data');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_HIDE_DATE = 'ANALYSIS_UPDATE_TABLE_SETTINGS_HIDE_DATE';
export const analysisUpdateTableSettingHideDate = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_HIDE_DATE, 'value');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_TITLE = 'ANALYSIS_UPDATE_TABLE_SETTINGS_TITLE';
export const analysisUpdateTableSettingTitle = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_TITLE, 'value');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_FONT_SIZE = 'ANALYSIS_UPDATE_TABLE_SETTINGS_FONT_SIZE';
export const analysisUpdateTableSettingFontSize = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_FONT_SIZE, 'value');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_ROW_SIZE = 'ANALYSIS_UPDATE_TABLE_SETTINGS_ROW_SIZE';
export const analysisUpdateTableSettingRowSize = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_ROW_SIZE, 'value');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_DATE_FORMAT = 'ANALYSIS_UPDATE_TABLE_SETTINGS_DATE_FORMAT';
export const analysisUpdateTableSettingDateFormat = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_DATE_FORMAT, 'value');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_DECIMAL_PLACES = 'ANALYSIS_UPDATE_TABLE_SETTINGS_DECIMAL_PLACES';
export const analysisUpdateTableSettingDecimalPlaces = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_DECIMAL_PLACES, 'value');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_VALUE = 'ANALYSIS_UPDATE_TABLE_SETTINGS_VALUE';
export const analysisUpdateTableSettingsValue = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_VALUE, 'key', 'value');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH = 'ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH';
export const analysisUpdateTableSettingsHeaderWidth = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH, 'key', 'value');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTHS = 'ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTHS';
export const analysisUpdateTableSettingsHeaderWidths = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTHS, 'value');

export const ANALYSIS_UPDATE_TABLE_SETTINGS_ORDER_BY = 'ANALYSIS_UPDATE_TABLE_SETTINGS_ORDER_BY';
export const analysisUpdateTableSettingsOrderBy = createAction(ANALYSIS_UPDATE_TABLE_SETTINGS_ORDER_BY, 'key');

export const ANALYSIS_UPDATE_CHART_SETTINGS = 'ANALYSIS_UPDATE_CHART_SETTINGS';
export const analysisUpdateChartSettings = createAction(ANALYSIS_UPDATE_CHART_SETTINGS, 'key', 'value');

export const ANALYSIS_UPDATE_CHART_EDIT_SETTINGS = 'ANALYSIS_UPDATE_CHART_EDIT_SETTINGS';
export const analysisUpdateChartEditSettings = createAction(ANALYSIS_UPDATE_CHART_EDIT_SETTINGS, 'data');

export const ANALYSIS_UPDATE_CHART_EDIT_SETTINGS_VALUE = 'ANALYSIS_UPDATE_CHART_EDIT_SETTINGS_VALUE';
export const analysisUpdateChartEditSettingsValue = createAction(ANALYSIS_UPDATE_CHART_EDIT_SETTINGS_VALUE, 'keyPath', 'value');

export const ANALYSIS_APPLY_CHART_EDIT_SETTINGS = 'ANALYSIS_APPLY_CHART_EDIT_SETTINGS';
export const analysisApplyChartEditSettings = createAction(ANALYSIS_APPLY_CHART_EDIT_SETTINGS, 'includeSeries');

export const ANALYSIS_UPDATE_UI_VALUES = 'ANALYSIS_UPDATE_UI_VALUES';
export const analysisUpdateUiValues = createAction(ANALYSIS_UPDATE_UI_VALUES, 'list');

export const analysisReportCriteriaUpdatePeriodAbs = () => async (dispatch, getState) => {
  dispatch(analysisReportCriteriaUpdatePeriodAbsStarted());

  const state = getState();  
  return fetchPeriodsAbs(getPeriods(state))
  .then(periodsAbs => {
    dispatch(analysisReportCriteriaUpdatePeriodAbsCompleted(periodsAbs));
  })
  .catch(error => {
    dispatch(analysisReportCriteriaUpdatePeriodAbsCompleted());
    dispatch(logErrorNotification(error));
  });
}

export const ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_STARTED = 'ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_STARTED';
const analysisReportCriteriaUpdatePeriodAbsStarted = createAction(ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_STARTED);

export const ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_COMPLETED = 'ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_COMPLETED';
const analysisReportCriteriaUpdatePeriodAbsCompleted = createAction(ANALYSIS_REPORT_CRITERIA_UPDATE_PERIOD_ABS_COMPLETED, 'periodsAbs');

export const ANALYSIS_REPORT_CRITERIA_UPDATE_CUSTOM_PERIOD = 'ANALYSIS_REPORT_CRITERIA_UPDATE_CUSTOM_PERIOD';
export const analysisReportCriteriaUpdateCustomPeriod = createAction(ANALYSIS_REPORT_CRITERIA_UPDATE_CUSTOM_PERIOD, 'key', 'value');

export const ANALYSIS_SHOW_CHART_EDIT_USER_SETTINGS_MODAL = 'ANALYSIS_SHOW_CHART_EDIT_USER_SETTINGS_MODAL';
export const analysisShowChartEditUserSettingsModal = createAction(ANALYSIS_SHOW_CHART_EDIT_USER_SETTINGS_MODAL);

export const ANALYSIS_HIDE_CHART_EDIT_USER_SETTINGS_MODAL = 'ANALYSIS_HIDE_CHART_EDIT_USER_SETTINGS_MODAL';
export const analysisHideChartEditUserSettingsModal = createAction(ANALYSIS_HIDE_CHART_EDIT_USER_SETTINGS_MODAL);

export const ANALYSIS_SHOW_CHART_EDIT_SERIES_SETTINGS_MODAL = 'ANALYSIS_SHOW_CHART_EDIT_SERIES_SETTINGS_MODAL';
export const analysisShowChartEditSeriesSettingsModal = createAction(ANALYSIS_SHOW_CHART_EDIT_SERIES_SETTINGS_MODAL);

export const ANALYSIS_HIDE_CHART_EDIT_SERIES_SETTINGS_MODAL = 'ANALYSIS_HIDE_CHART_EDIT_SERIES_SETTINGS_MODAL';
export const analysisHideChartEditSeriesSettingsModal = createAction(ANALYSIS_HIDE_CHART_EDIT_SERIES_SETTINGS_MODAL);

export const ANALYSIS_UPDATE_CHART_EDIT_VIEW = 'ANALYSIS_UPDATE_CHART_EDIT_VIEW';
export const analysisUpdateChartEditView = createAction(ANALYSIS_UPDATE_CHART_EDIT_VIEW, 'value');

export const ANALYSIS_UPDATE_HIGHCHART_SETTINGS = 'ANALYSIS_UPDATE_HIGHCHART_SETTINGS';
export const analysisUpdateHighChartSettings = createAction(ANALYSIS_UPDATE_HIGHCHART_SETTINGS, 'data');

export const analysisChartDataExport = () => (dispatch, getState) => {
  const state = getState();
  const fileName = `analysis-data-export_${moment().format('DD-MMM-YYYY-HH-mm-ss')}`;
  const series = toJS(state.getIn(['analysis', 'chart', 'timeSeries']), []);
  const rows = toJS(state.getIn(['analysis', 'table', 'data']), []);
  const headerType = state.getIn(['analysis', 'workspace', 'tableSettings', 'headerType']);
  const isHorizontal = state.getIn(['analysis', 'workspace', 'tableSettings', 'displayData']) === 'horizontal';
  if (isHorizontal){
    dispatch(logWarningNotification('Horizontal export not supported'));
  }
  else{
    const {columns, lines} = prepareCsv(headerType, rows, series);
    csvExportText(fileName, lines.join('\r\n'), columns.map(c => c.name), null);
  }
};

export const analysisCopyWorkspaceUrlToClipboard = () =>  (dispatch, getState) => {
  const state = getState();
  const workspace = toJS(state.getIn(['analysis', 'workspace']));
  const { id, type, scope, folderPath, subFolderPath, name } = toJS(workspace, {});

  if (!id) return;
  if (scope !== 'Shared') {
    dispatch(logInfoNotification('Only shared workspaces can be shared'))
    return;
  }

  const workspacePath = `/${type}/${scope}/${folderPath || ''}/${subFolderPath || ''}/${name || ''}`.replace(/\/+/g, '/');
  const shareUrl = `${window.location.origin}/analysis?${qs.stringify({ workspacePath })}`;
  copyToClipboard(shareUrl);
  dispatch(logInfoNotification('Workspace Url copied'))
};

function prepareCsv(headerType, rows, timeSeries){
  let getHeader;
  switch (headerType) {
    case 'both': getHeader = s => `${s.identityId}: ${s.name}`; break;
    case 'name': getHeader = s => `${s.name}`; break;
    default: getHeader = (s, i) => `${s.identityId}`; break;
  }

  let columns = [{ name: 'dateTime' }];
  if (timeSeries.length)
    columns.push(...timeSeries.map((s, i) => ({ name: getHeader(s, i), index: i, key: s.key })));

  const lines = [];
  lines.push(buildLine(columns.map(c => c.name)));
  rows.forEach(r => {
    const line = [];
    columns.forEach(({ name: colName, key }) => {
      switch (colName) {
        case 'dateTime': r[colName] ? line.push(moment.utc(r[colName].value).format('DD-MMM-YYYY HH:mm')) : line.push('Missing dateTime');
          break;
        default:
          if (r[key] !== null && r[key].value !== undefined && r[key].value !== null)
            line.push(`${r[key].value}`);
          else
            line.push('');
          break;
      }
    });
    lines.push(buildLine(line));
  });

  return {columns, lines};

  function buildLine(arrayOfText) {
    return arrayOfText.map(text => {
      text = `${text}`;
      if (text.indexOf(',') > -1) {
        return '"' + text + '"';
      }

      return text;
    }).join(',');
  }
}

export const ANALYSIS_UPDATE_CUSTOM_FILTER_HEIGHT = 'ANALYSIS_UPDATE_CUSTOM_FILTER_HEIGHT';
export const analysisUpdateCustomFilterHeight = createAction(ANALYSIS_UPDATE_CUSTOM_FILTER_HEIGHT, 'value');

export const analysisClearFilters = () => (dispatch, getState) => {
  dispatch(analysisUpdateCriteria({
    filters: []
  }));
};

export const ANALYSIS_UPDATE_FACETS = 'ANALYSIS_UPDATE_FACETS';
export const analysisUpdateFacets = createAction(ANALYSIS_UPDATE_FACETS, 'data');

export const analysisUpdateQuery = (query, customFilter) => (dispatch, getState) => {
  dispatch(analysisUpdateCriteria({
    query: query,
    customFilter: customFilter
  }));
};

export const analysisUpdateFilter = (key, value) => (dispatch, getState) => {
  const state = getState();

  let filters = state.getIn(['analysis', 'search', 'criteria', 'filters']).toJS(),
    filterIndex = filters.findIndex(f => f.name === key);

  if (filterIndex < 0 && (value === undefined || value === null || value === '')) return;

  if (filterIndex >= 0) {
    if (value === undefined || value === null || value === '') filters.splice(filterIndex, 1);
    else filters[filterIndex].value = value;
  }
  else if (value || value === 0) filters.push({
    name: key,
    value: value
  });

  dispatch(analysisUpdateCriteria({
    filters: filters
  }));
};

export const analysisToggleDiscontinuedFilter = () => (dispatch, getState) => {
  const state = getState();

  const isActive = !state.getIn(['analysis', 'search', 'criteria', 'enableDiscontinuedFilter']);
  const discontinuedFilterMonths = isActive
    ? state.getIn(['analysis', 'search', 'criteria', 'discontinuedFilterMonths'])
    : 0;

  dispatch(analysisUpdateCriteria({
    staleMonthLimit: discontinuedFilterMonths
  }));
};

export const analysisToggleFavouritesFilter = () => (dispatch, getState) => {
  const state = getState();

  const isActive = !state.getIn(['analysis', 'search', 'criteria', 'enableFavouritesFilter']);

  dispatch(analysisUpdateCriteria({
    onlyFavourites: isActive
  }));
};

export const analysisUpdateSort = (key) => (dispatch, getState) => {
  const state = getState();

  const orderByDirection = state.getIn(['analysis', 'search', 'criteria', 'orderBy']) === key
    ? state.getIn(['analysis', 'search', 'criteria', 'orderByDirection']) === 'desc' ? 'asc' : 'desc'
    : 'desc';

  dispatch(analysisUpdateCriteria({
    orderBy: key,
    orderByDirection: orderByDirection
  }));
};

export const analysisUpdatePage = (page, pageSize) => (dispatch, getState) => {
  const state = getState();

  dispatch(analysisUpdateCriteria({
    page: page,
    pageSize: pageSize || state.getIn(['analysis', 'search', 'criteria', 'pageSize'])
  }));
};

export const analysisUpdateCriteria = (payload) => (dispatch, getState) => {
  const state = getState();

  let search = state.getIn(['router', 'location', 'search']) || window.location.search;

  if (search.startsWith('?')) search = search.substring(1);

  let criteria = qs.parse(search);

  if (payload.filters) {
    payload.filters = Array.isArray(payload.filters) ? payload.filters : [payload.filters];
    payload.filters = payload.filters.filter(f => f.name && (f.value || f.value === 0 || f.value === false))
      .map(f => `${f.name}|${f.value}`);
  }

  Object.keys(payload).forEach(key => {
    if (payload.hasOwnProperty(key)) {
      if (payload[key] || payload[key] === 0 || payload[key] === false) criteria[key] = payload[key];
      else delete criteria[key];
    }
  });

  if (criteria.filters && !criteria.filters.length) delete criteria.filters;
  if (criteria.staleMonthLimit <= 0) delete criteria.staleMonthLimit;
  if (!criteria.onlyFavourites) delete criteria.onlyFavourites;

  for (let i in criteria) if (criteria[i] === undefined) delete criteria[i];

  dispatch(push({ search: qs.stringify(criteria) }));
};

export const analysisInitialiseCriteria = (data) => (dispatch, getState) => {
  if (!data) return;

  let criteria = { ...data };

  if (criteria.filters && !criteria.filters.length) delete criteria.filters;
  if (criteria.staleMonthLimit <= 0) delete criteria.staleMonthLimit;

  Object.keys(criteria).forEach(key => {
    if (criteria.hasOwnProperty(key)) {
      if (!criteria[key] && criteria[key] !== 0 && criteria[key] !== false) delete criteria[key];
    }
  });

  if (Object.keys(data).length) dispatch(analysisInitialiseUserSettings(criteria));
};

export const ANALYSIS_INITIALISE_USER_SETTINGS = 'ANALYSIS_INITIALISE_USER_SETTINGS';
export const analysisInitialiseUserSettings = createAction(ANALYSIS_INITIALISE_USER_SETTINGS, 'data');

export const ANALYSIS_TOGGLE_TABLE_DESIGN_PANEL_VISIBILITY = 'ANALYSIS_TOGGLE_TABLE_DESIGN_PANEL_VISIBILITY';
export const analysisToggleTableDesignPanelVisibility = createAction(ANALYSIS_TOGGLE_TABLE_DESIGN_PANEL_VISIBILITY);

export const ANALYSIS_TOGGLE_TABLE_JSON_EDITOR_PANEL_VISIBILITY = 'ANALYSIS_TOGGLE_TABLE_JSON_EDITOR_PANEL_VISIBILITY';
export const analysisToggleTableJsonEditorPanelVisibility = createAction(ANALYSIS_TOGGLE_TABLE_JSON_EDITOR_PANEL_VISIBILITY);

export const ANALYSIS_ADJUSTMENTS_BEGIN_EDIT = 'ANALYSIS_ADJUSTMENTS_BEGIN_EDIT';
export const analysisAdjustmentsBeginEdit = createAction(ANALYSIS_ADJUSTMENTS_BEGIN_EDIT);

export const ANALYSIS_ADJUSTMENTS_END_EDIT = 'ANALYSIS_ADJUSTMENTS_END_EDIT';
export const analysisAdjustmentsEndEdit = createAction(ANALYSIS_ADJUSTMENTS_END_EDIT);

export const ANALYSIS_ADJUSTMENTS_SET_SELECTION_START = 'ANALYSIS_ADJUSTMENTS_SET_SELECTION_START';
export const analysisAdjustmentsSetSelectionStart = createAction(ANALYSIS_ADJUSTMENTS_SET_SELECTION_START, 'value');

export const ANALYSIS_ADJUSTMENTS_SET_SELECTION_END = 'ANALYSIS_ADJUSTMENTS_SET_SELECTION_END';
export const analysisAdjustmentsSetSelectionEnd = createAction(ANALYSIS_ADJUSTMENTS_SET_SELECTION_END, 'value');

export const ANALYSIS_ADJUSTMENTS_SELECT_LINE = 'ANALYSIS_ADJUSTMENTS_SELECT_LINE';
export const analysisAdjustmentsSelectLine = createAction(ANALYSIS_ADJUSTMENTS_SELECT_LINE, 'value');

export const ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_CELL = 'ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_CELL';
export const analysisAdjustmentsSetAdjustmentCell = createAction(ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_CELL, 'value');

export const ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE = 'ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE';
export const analysisAdjustmentsSetAdjustmentValue = createAction(ANALYSIS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE, 'value');

export const analysisAdjustmentsValidate = () => async (dispatch, getState) => {
  const state = getState();
  const dirtyCellsMap = toJS(state.getIn(['analysis', 'adjustments', 'dirtyCellsMap'])) ?? {};
  if (Object.keys(dirtyCellsMap).length === 0){
    dispatch(analysisAdjustmentsValidateComplete(true, []));
    return;
  }

  const reportRequest = toJS(state.getIn(['analysis', 'source', 'reportRequest']));  
  const defaultTimeSeriesMeta = toJS(state.getIn(['analysis', 'adjustments', 'timeSeriesMeta']));
  const timeSeriesMetas = toJS(state.getIn(['analysis', 'adjustments', 'editSelection', 'timeSeriesMeta']));
  const data = toJS(state.getIn(['analysis', 'table', 'data']));  
  const addressLookup = toJS(state.getIn(['analysis', 'table', 'addressLookup']));  
  const isHorizontal = state.getIn(['analysis','workspace', 'tableSettings', 'displayData']) === 'horizontal';
  const adjustmentsRequest = getAdjustmentsToSave(defaultTimeSeriesMeta, timeSeriesMetas, reportRequest, isHorizontal, data, addressLookup, dirtyCellsMap);
  dispatch(analysisAdjustmentsValidateBegin());
  if (adjustmentsRequest.length === 0){
    dispatch(analysisAdjustmentsValidateComplete(true, []));
    return;
  }

  const validateResponse = await validateAnalysisAdjustmentsAsync({
    timeSeriesRequest : reportRequest,
    timeSeriesAdjustments : adjustmentsRequest
  }, dispatch);
  
  if (!validateResponse) {
    dispatch(analysisAdjustmentsValidateComplete(false));
    return;
  }

  const {messages} = validateResponse;
  dispatch(analysisAdjustmentsValidateComplete(true, messages));
};

export const ANALYSIS_ADJUSTMENTS_VALIDATE_BEGIN = 'ANALYSIS_ADJUSTMENTS_VALIDATE_BEGIN';
const analysisAdjustmentsValidateBegin = createAction(ANALYSIS_ADJUSTMENTS_VALIDATE_BEGIN);

export const ANALYSIS_ADJUSTMENTS_VALIDATE_COMPLETE = 'ANALYSIS_ADJUSTMENTS_VALIDATE_COMPLETE';
const analysisAdjustmentsValidateComplete = createAction(ANALYSIS_ADJUSTMENTS_VALIDATE_COMPLETE, 'success', 'messages');

export const ANALYSIS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE = 'ANALYSIS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE';
export const analysisAdjustmentsSetSelectionAdjustmentValue = createAction(ANALYSIS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE, 'value');

export const ANALYSIS_ADJUSTMENTS_REVERT_SELECTION_ADJUSTMENTS = 'ANALYSIS_ADJUSTMENTS_REVERT_SELECTION_ADJUSTMENTS';
export const analysisAdjustmentsRevertSelectionAdjustments = createAction(ANALYSIS_ADJUSTMENTS_REVERT_SELECTION_ADJUSTMENTS);

export const ANALYSIS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS = 'ANALYSIS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS';
export const analysisAdjustmentsRemoveAllAdjustments = createAction(ANALYSIS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS);

export const ANALYSIS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS = 'ANALYSIS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS';
export const analysisAdjustmentsRemoveSelectedAdjustments = createAction(ANALYSIS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS);

export const ANALYSIS_ADJUSTMENTS_NAVIGATE_CELL = 'ANALYSIS_ADJUSTMENTS_NAVIGATE_CELL';
export const analysisAdjustmentsNavigateCell = createAction(ANALYSIS_ADJUSTMENTS_NAVIGATE_CELL, 'value');

export const ANALYSIS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY = 'ANALYSIS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY';
export const analysisAdjustmentsUpdateTimeSeriesMetaProperty = createAction(ANALYSIS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY, 'tsKey','propertyName','value');

export const ANALYSIS_ADJUSTMENTS_RESET_PRE_SAVE_WARNINGS = 'ANALYSIS_ADJUSTMENTS_RESET_PRE_SAVE_WARNINGS';
export const analysisAdjustmentsResetPreSaveWarnings = createAction(ANALYSIS_ADJUSTMENTS_RESET_PRE_SAVE_WARNINGS);

export const analysisAdjustmentsSave = (ignorePreSaveWarnings = false) => async (dispatch, getState) => {
  const state = getState();

  const sourceType = toJS(state.getIn(['analysis', 'source', 'sourceType']));  
  if (sourceType === 'analysis-evo-report')
    return;
  
  const dirtyCellsMap = toJS(state.getIn(['analysis', 'adjustments', 'dirtyCellsMap'])) ?? {};
  if (Object.keys(dirtyCellsMap).length === 0)
    return;

  const expressionRequest = toJS(state.getIn(['analysis', 'source', 'expressionRequest'])); 
  const reportRequest = toJS(state.getIn(['analysis', 'source', 'reportRequest']));  
  const defaultTimeSeriesMeta = toJS(state.getIn(['analysis', 'adjustments', 'timeSeriesMeta']));
  const timeSeriesMetas = toJS(state.getIn(['analysis', 'adjustments', 'editSelection', 'timeSeriesMeta']));
  const data = toJS(state.getIn(['analysis', 'table', 'data']));  
  const addressLookup = toJS(state.getIn(['analysis', 'table', 'addressLookup']));  
  const isHorizontal = state.getIn(['analysis', 'workspace', 'tableSettings', 'displayData']) === 'horizontal';
  const timeSeriesWindows = toJS(state.getIn(['analysis', 'timeSeriesWindows']));
  const adjustmentsRequest = getAdjustmentsToSave(defaultTimeSeriesMeta, timeSeriesMetas, reportRequest, isHorizontal, data, addressLookup, dirtyCellsMap);
  
  if (adjustmentsRequest.length === 0)
    return;

    if (!ignorePreSaveWarnings){
      let identityClashConflicts = {};
      adjustmentsRequest.forEach(adj => {
        adjustmentsRequest.forEach(other => {
        if (other.key !== adj.key && other.identityId === adj.identityId){
          identityClashConflicts[adj.identityId] = `Underlying time series for '${adj.name}' is displayed more than once and there are adjustments in more than one version of this series.`;
        }
      })
    })
    
    const preSaveWarnings = Object.keys(identityClashConflicts).map(k => identityClashConflicts[k]);
    if (preSaveWarnings.length > 0){
      dispatch(analysisAdjustmentsSaveConfirmationRequired(preSaveWarnings));
      return;
    }
  }

  dispatch(analysisAdjustmentsSaveBegin());

  const saveResponse = await saveAnalysisAdjustmentsAsync({
    timeSeriesRequest : reportRequest,
    timeSeriesAdjustments : adjustmentsRequest
  }, dispatch);
  
  if (!saveResponse){
    dispatch(analysisAdjustmentsSaveComplete(false));
    return;
  }

  const {success, result: timeSeriesReport} = saveResponse;
  timeSeriesReport.timeSeriesWindows = timeSeriesWindows;
  const timeSeriesExpressionNames = await fetchTimeSeriesNamesAsync(expressionRequest);
  dispatch(analysisRefreshComplete(timeSeriesReport, timeSeriesExpressionNames, reportRequest, expressionRequest, !success));

  dispatch(analysisAdjustmentsSaveComplete(success, timeSeriesReport, data));
  if (!success) {
    dispatch(logErrorNotification('Failed to save adjustments'));
  }else{
    const hasWarnings = timeSeriesReport.timeSeries.some(ts => (ts.warning && ts.warning.length > 0));
    if (hasWarnings){
      dispatch(logWarningNotification(`Adjustments saved with warnings`));
    } else {
      dispatch(logInfoNotification(`Adjustments saved`));
    }
  }
};

export const ANALYSIS_ADJUSTMENTS_SAVE_BEGIN = 'ANALYSIS_ADJUSTMENTS_SAVE_BEGIN';
const analysisAdjustmentsSaveBegin = createAction(ANALYSIS_ADJUSTMENTS_SAVE_BEGIN);

export const ANALYSIS_ADJUSTMENTS_SAVE_CONFIRMATION_REQUIRED = 'ANALYSIS_ADJUSTMENTS_SAVE_CONFIRMATION_REQUIRED';
const analysisAdjustmentsSaveConfirmationRequired = createAction(ANALYSIS_ADJUSTMENTS_SAVE_CONFIRMATION_REQUIRED, 'preSaveWarnings');

export const ANALYSIS_ADJUSTMENTS_SAVE_COMPLETE = 'ANALYSIS_ADJUSTMENTS_SAVE_COMPLETE';
const analysisAdjustmentsSaveComplete = createAction(ANALYSIS_ADJUSTMENTS_SAVE_COMPLETE, 'success', 'timeSeriesReport', 'tableData');

export const ANALYSIS_ADJUSTMENTS_UPDATE_DEFAULTS = 'ANALYSIS_ADJUSTMENTS_UPDATE_DEFAULTS';
export const analysisUpdateAnnotationDefaults = createAction(ANALYSIS_ADJUSTMENTS_UPDATE_DEFAULTS, 'defaults');

export const analysisTableSelectionCopy = (includeHeader) => (dispatch, getState) => {
  const state = getState(); 
  const tableData = toJS(state.getIn(['analysis', 'table', 'data']));  
  const selectionRange = toJS(state.getIn(['analysis', 'adjustments', 'editSelection', 'range']));  
  const timeseries = toJS(state.getIn(['analysis', 'workspace', 'timeseries']));
  const headerType = state.getIn(['analysis', 'workspace', 'tableSettings', 'headerType']);
  const isHorizontal = state.getIn(['analysis','workspace', 'tableSettings', 'displayData']) === 'horizontal';

  const {success, message, doc} = mapTableSelectionToCSV(isHorizontal, timeseries, tableData, selectionRange, headerType, includeHeader);
  if (!success){
    if (message) dispatch(logWarningNotification(message));
  }else{
    copyToClipboard(doc);
    dispatch(analysisTableCopySelectionComplete());
  }
};


export const ANALYSIS_ADJUSTMENTS_TABLE_COPY_SELECTION_COMPLETE = 'ANALYSIS_ADJUSTMENTS_TABLE_COPY_SELECTION_COMPLETE';
const analysisTableCopySelectionComplete = createAction(ANALYSIS_ADJUSTMENTS_TABLE_COPY_SELECTION_COMPLETE);

export const analysisTablePasteToSelection = (text) => async (dispatch, getState) => {
  const state = getState(); 
  const isEditing = state.getIn(['analysis', 'adjustments', 'isEditing']);  
  if (!isEditing)
    return;

  const tableData = toJS(state.getIn(['analysis', 'table', 'data']));  
  const selectionRange = toJS(state.getIn(['analysis', 'adjustments', 'editSelection', 'range']));  
  const timeseries = toJS(state.getIn(['analysis', 'workspace', 'timeseries']));
  const isHorizontal = state.getIn(['analysis','workspace', 'tableSettings', 'displayData']) === 'horizontal';
  const selectionGrid = mapSelectionToGrid(isHorizontal, timeseries, tableData, selectionRange);
  if (!selectionGrid.success){
    if (selectionGrid.message) dispatch(logWarningNotification(selectionGrid.message));
    return;
  }
  
  if (text === undefined) {
    const clipboardResult = await getClipboardTextAsync();
    if (!clipboardResult.success){
      dispatch(logWarningNotification(clipboardResult.message));
      return;
    } else {
      text = clipboardResult.text;
    }
  }

  const {success, message, table, leftHeaderRemoved} = mapCSVToArray(text);
  if (!success){
    if (message) dispatch(logWarningNotification(message));
  }else{
    if (selectionGrid.columns[0].colKey === 'dateTime' || selectionGrid.columns[0].colKey === 'title'){
      if (leftHeaderRemoved && selectionGrid.columns.length > 1){
        selectionGrid.columns = selectionGrid.columns.slice(1);
      }else{
        dispatch(logWarningNotification('Paste is not allowed in read only cells (such as date time columns)'));
        return;
      }
    }

    if ((selectionGrid.rows.length === 1 && selectionGrid.columns.length === 1) || 
        (table.length === selectionGrid.rows.length && table[0].length === selectionGrid.columns.length)){
      dispatch(analysisTablePasteToSelectionComplete(table, {rowKey:selectionGrid.rows[0], colKey:selectionGrid.columns[0].colKey} ));
      dispatch(analysisAdjustmentsValidate());
    }else {
      dispatch(logWarningNotification(`The data you're pasting isn't the same shape as your selection`));
    }
  }
};

export const ANALYSIS_ADJUSTMENTS_TABLE_PASTE_TO_SELECTION_COMPLETE = 'ANALYSIS_ADJUSTMENTS_TABLE_PASTE_TO_SELECTION_COMPLETE';
export const analysisTablePasteToSelectionComplete = createAction(ANALYSIS_ADJUSTMENTS_TABLE_PASTE_TO_SELECTION_COMPLETE, 'table', 'originAddress');

export const ANALYSIS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY = 'ANALYSIS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY';
export const analysisAdjustmentsSetSaveConfirmationVisibility = createAction(ANALYSIS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY, 'isVisible');

export const ANALYSIS_WORKSPACE_UPDATE_PERIODS_FROM_USER_SETTINGS = 'ANALYSIS_WORKSPACE_UPDATE_PERIODS_FROM_USER_SETTINGS';
export const analysisWorkspaceUpdatePeriodsFromUserSettings = createAction(ANALYSIS_WORKSPACE_UPDATE_PERIODS_FROM_USER_SETTINGS, 'update');
