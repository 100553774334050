import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import DateTimeInput from '../../../shared/DateTimeInput';

export default function InstancePromoteDialog({ dialogVisible, promotedAsAtUtc, closeDialogFunction, setAsAtUtcFunction, saveFunction }) {
  return (
    <Dialog open={dialogVisible} onClose={closeDialogFunction}>
        <DialogTitle>Enter an AsAt (UTC) for promoted instance</DialogTitle>
        <DialogContent>
          <div className='form-row' style={{ display:'flex', flexDirection: 'column', minHeight: '365px' }}>
            <label className='mr-2'>AsAt (UTC):</label>
            <DateTimeInput className='mr-2' 
                           value={promotedAsAtUtc}
                           onChange={value => setAsAtUtcFunction(value)} />
          </div>
        </DialogContent>
        <DialogActions>
          <button className='btn btn-sm btn-primary rounded' onClick={() => saveFunction()} >
              <span className='px-2 d-none d-md-inline-block'>Save</span>
          </button>
          <button className='btn btn-sm btn-secondary rounded' onClick={() => closeDialogFunction()} >
              <span className='px-2 d-none d-md-inline-block'>Cancel</span>
          </button>
        </DialogActions>
    </Dialog>
  );
}