import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
  REFERENCE_DATA_LOAD_SHARED_LOOKUPS_STARTED,
  REFERENCE_DATA_LOAD_SHARED_LOOKUPS_COMPLETE,
  REFERENCE_DATA_LOAD_CONVERSION_UNITS_COMPLETE,
  REFERENCE_DATA_LOAD_FUNCTIONS_COMPLETE,
  REFERENCE_DATA_LOAD_CATEGORIESBYDATATYPE_COMPLETE,
  REFERENCE_DATA_LOAD_PERIODS_COMPLETE
} from '../actions/referenceData';
import { 
  ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_COMPLETE 
} from '../actions/analysis-shapes-v2';

export const referenceData = createReducer(null, {
  [REFERENCE_DATA_LOAD_SHARED_LOOKUPS_STARTED](state, action) {
    return state.set('isLoading', true);
  },
  [REFERENCE_DATA_LOAD_SHARED_LOOKUPS_COMPLETE](state, action) {
    const { data } = action;
    if (!data) return state.set('isLoading', false);

    if (data.comparisonLenses)
      state = state.set('comparisonLenses', fromJS(data.comparisonLenses));

    if (data.lenses)
      state = state.set('lenses', fromJS(data.lenses));

    if (data.operations)
      state = state.set('operations', fromJS(data.operations));

    if (data.timezones)
      state = state.set('timezones', fromJS(data.timezones));

    if (data.granularities)
      state = state.set('granularities', fromJS(data.granularities));

    if (data.timeSeriesStyles)
      state = state.set('timeSeriesStyles', fromJS(data.timeSeriesStyles));

    if (data.timeSeriesDataTypes)
      state = state.set('timeSeriesDataTypes', fromJS(data.timeSeriesDataTypes));

    if (data.timeSeriesInstanceStyles)
      state = state.set('timeSeriesInstanceStyles', fromJS(data.timeSeriesInstanceStyles));

    return state.set('isLoading', false)
      .setIn(['isInitialised', 'sharedLookups'], true);
  },
  [REFERENCE_DATA_LOAD_CONVERSION_UNITS_COMPLETE](state, action) {
    const { data } = action;
    return state.setIn(['isInitialised', 'conversionUnits'], true)
      .set('conversionUnits', fromJS(data));
  },
  [REFERENCE_DATA_LOAD_FUNCTIONS_COMPLETE](state, action) {
    const { data } = action;

    state = state.setIn(['isInitialised', 'functions'], true);

    let standardFunctions = {};
    let customFunctions = {};

    for (let i = 0; i < data.length; i++) {
      var func = data[i];
      if (func.source === 'Standard') {
        standardFunctions[func.name] = func;
      } else {
        customFunctions[func.name] = func;
      }
    }

    const functions = {
      standardFunctions: standardFunctions,
      customFunctions: customFunctions,
      functionsList: data
    };

    return state.set('functions', fromJS(functions));
  },
  [REFERENCE_DATA_LOAD_CATEGORIESBYDATATYPE_COMPLETE](state, action) {
    const { data } = action;
    return state.setIn(['isInitialised', 'categoriesByDataType'], true)
      .set('categoriesByDataType', fromJS(data));
  },
  [REFERENCE_DATA_LOAD_PERIODS_COMPLETE](state, action) {
    const { data } = action;
    if (data && data.length > 0) {
      state = state.set('periods', fromJS(data));
    }
    
    return state.setIn(['isInitialised', 'periods'], true);
  },
  [ANALYSIS_SHAPES_LOAD_AVAILABLE_SHAPES_COMPLETE](state, action) {
    const { data } = action;
    if (!data) return state.setIn(['shapes'], fromJS([]));

    return state.setIn(['shapes'], fromJS(data));
  }
});