import { fromJS } from 'immutable'
import {
  TIMESERIES_DETAILS_SET_FUNCTIONS_STEP_STYLE,
  TIMESERIES_DETAILS_SET_FUNCTION_TYPE,
  TIMESERIES_DETAILS_FUNCTIONS_ADD_PARAMETER,
  TIMESERIES_DETAILS_FUNCTIONS_DELETE_PARAMETER,
  TIMESERIES_DETAILS_FUNCTIONS_UPDATE_PARAMETER_VALUE,
  TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION,
  TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION,
  TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_UP,
  TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_DOWN,
  TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_KEY,
  TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_UP,
  TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_DOWN,
  TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_KEY,
  TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_KEY,
  TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_OUTPUT_CATEGORY,
  TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_CATEGORY_VALUE,
  TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_OUTPUT_CATEGORY,
  TIMESERIES_DETAILS_FUNCTIONS_SET_FUNCTION_DISABLED_STATE
} from '../actions/timeSeriesDetails-functions';
import { toJS } from '../utility/immutable-utility';

export const timeSeriesDetailsFunctionsReducer = {
  [TIMESERIES_DETAILS_SET_FUNCTIONS_STEP_STYLE](state, action) {
    const isMultiStep = action.style;
    const functions = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], []));
    if (isMultiStep && functions.length === 0) {
      const singleStepFunction = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functionData'], {}));
      functions.push({
        "isNew": true,
        "keys": [],
        "customFunctionName": singleStepFunction.customFunctionName || null,
        "function": singleStepFunction.function || "None",
        "parameters": singleStepFunction.parameters,
        "outputKey": null,
        "outputCategories": {},
        "disabled": false
      });
      state = state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], fromJS(functions));
    }

    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'isMultiStepFunctions'], isMultiStep);
  },
  [TIMESERIES_DETAILS_SET_FUNCTION_TYPE](state, action) {
    if (!action.data)
      return state;

    const { source, multiFunctionIndex, functionName, parameters } = action.data;

    let dataKey = [];
    if (multiFunctionIndex === undefined) {
      dataKey = ['timeSeriesEditor', 'timeSeries', 'derivationData', 'functionData'];
    }
    else {
      dataKey = ['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex];
    }

    function mapParameters(parameters) {
      const response = {};
      for(let i=0; i < parameters.length; i++) {
        // access by key-name or index as the args could either be an object or an array
        const arg = parameters[i];
        response[arg.name] = arg.defaultValue;
      };
      return response;
    };

    if (source === 'standard') {
      return state
        .setIn([...dataKey, 'function'], functionName)
        .setIn([...dataKey, 'customFunctionName'], null)
        .setIn([...dataKey, 'parameters'], fromJS(mapParameters(parameters)));
    } else if (source === 'custom') {
      return state
        .setIn([...dataKey, 'function'], 'None')
        .setIn([...dataKey, 'customFunctionName'], functionName)
        .setIn([...dataKey, 'parameters'], fromJS(mapParameters(parameters)));
    }

    return state;
  },
  [TIMESERIES_DETAILS_FUNCTIONS_ADD_PARAMETER](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, name } = action.data;

    if (!name || name.trim() === '')
      return state;

    let dataKey = [];
    if (multiFunctionIndex === undefined){
      dataKey = ['timeSeriesEditor', 'timeSeries', 'derivationData', 'functionData', 'parameters'];
    }
    else{
      dataKey = ['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'parameters'];
    }

    const functionParameters = toJS(state.getIn(dataKey), {});
    if (functionParameters[name])
      return state;

    functionParameters[name] = '';
    return state.setIn(dataKey, fromJS(functionParameters));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_DELETE_PARAMETER](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, name } = action.data;
    let dataKey = [];
    if (multiFunctionIndex === undefined)
      dataKey = ['timeSeriesEditor', 'timeSeries', 'derivationData', 'functionData', 'parameters'];
    else
      dataKey = ['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'parameters'];

    return state.deleteIn([...dataKey, name]);
  },
  [TIMESERIES_DETAILS_FUNCTIONS_UPDATE_PARAMETER_VALUE](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, name, value } = action.data;

    let dataKey = [];
    if (multiFunctionIndex === undefined) {
      dataKey = ['timeSeriesEditor', 'timeSeries', 'derivationData', 'functionData', 'parameters', name];
    }
    else {
      dataKey = ['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'parameters', name];
    }

    return state.setIn(dataKey, value);
  },
  [TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION](state, action) {
    const functions = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], []));
    functions.push({
      "isNew": true,
      "keys": [],
      "customFunctionName": null,
      "function": "None",
      "parameters": {},
      "outputKey": null,
      "outputCategories": {},
      "disabled": false
    });
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], fromJS(functions));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION](state, action) {
    if (action.index === null || action.index === undefined)
      return state;

    const index = action.index;
    const functions = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], []));
    functions.splice(index, 1);
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], fromJS(functions));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_UP](state, action) {
    if (action.index === null || action.index === undefined || action.index <= 0)
      return state;

    const index = action.index;
    const functions = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], []));
    const functionToMove = functions[index];
    functions.splice(index, 1);
    functions.splice(index - 1, 0, functionToMove);

    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], fromJS(functions));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_DOWN](state, action) {
    if (action.index === null || action.index === undefined)
      return state;

    const index = action.index;
    const functions = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], []));
    if (index >= functions.length)
      return state;

    const functionToMove = functions[index];
    functions.splice(index, 1);
    functions.splice(index + 1, 0, functionToMove);
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions'], fromJS(functions));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_KEY](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, key } = action.data;
    const keys = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'keys'], []));
    if (keys.indexOf(key) >= 0)
      return state;

    keys.push(key);
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'keys'], fromJS(keys));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_UP](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, index } = action.data;
    const keys = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'keys'], []));
    const keyToMove = keys[index];
    keys.splice(index, 1);
    keys.splice(index - 1, 0, keyToMove);
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'keys'], fromJS(keys));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_MOVE_FUNCTION_KEY_DOWN](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, index } = action.data;
    const keys = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'keys'], []));
    const keyToMove = keys[index];
    keys.splice(index, 1);
    keys.splice(index + 1, 0, keyToMove);
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'keys'], fromJS(keys));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_KEY](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, index } = action.data;
    const keys = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'keys'], []));
    keys.splice(index, 1);
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'keys'], fromJS(keys));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_KEY](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, value } = action.data;
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'outputKey'], value);
  },
  [TIMESERIES_DETAILS_FUNCTIONS_ADD_FUNCTION_OUTPUT_CATEGORY](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, name } = action.data;
    if (!name || name.trim() === '')
      return state;

    const outputCategories = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'outputCategories'], {}));
    outputCategories[name] = '';
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'outputCategories'], fromJS(outputCategories));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_UPDATE_FUNCTION_OUTPUT_CATEGORY_VALUE](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, name, value } = action.data;
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'outputCategories', name], value);
  },
  [TIMESERIES_DETAILS_FUNCTIONS_DELETE_FUNCTION_OUTPUT_CATEGORY](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, name } = action.data;
    const outputCategories = toJS(state.getIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'outputCategories'], {}));
    delete outputCategories[name];
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'outputCategories'], fromJS(outputCategories));
  },
  [TIMESERIES_DETAILS_FUNCTIONS_SET_FUNCTION_DISABLED_STATE](state, action) {
    if (!action.data)
      return state;

    const { multiFunctionIndex, isDisabled } = action.data;
    return state.setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'functions', multiFunctionIndex, 'disabled'], isDisabled);
  },
};