import React, {Fragment} from 'react';
import IdentityId from '../../shared/IdentityId2';
import InfoPopup from '../../shared/InfoPopup';
import {getInfoDisplayProps} from '../../../utility/reportstable-utility'

export function TimeSeriesColumnLabel({timeSeries, onToggleExpand, onOpenChart, orientation}) {

  let iconClassName;
    switch (timeSeries.expandMode) {
      case 'OnDemand': iconClassName = 'icon icon-chevron-right-closed'; break;
      case 'OnDemand-Open': iconClassName = 'icon icon-chevron-down-closed'; break;
      case 'OnDemand-Closed': iconClassName = 'icon icon-chevron-right-closed'; break;
      case 'Open': iconClassName = 'far fa-minus-square'; break;
      case 'Closed': iconClassName = 'far fa-plus-square'; break;
      case 'Loading': iconClassName = 'fas fa-spinner fa-spin'; break;
      default: break;   
    }

  let {infoClassName, infoMessage} = getInfoDisplayProps(timeSeries);

  return (
    <Fragment>
    { timeSeries.hasChildren && <div className="float-left">
    <button type='button' className='btn btn-sm btn-link p-0 mr-2' style={{ lineHeight: 0, visibility: timeSeries.hasChildren ? 'visible' : 'hidden' }} disabled={!timeSeries.hasChildren} onClick={onToggleExpand}>
      <i className={`fa-fw ${iconClassName}`} style={{ fontSize: '.75em', fontWeight: 'bolder' }} />
    </button>
    </div>
    }
    <div className="float-right">
      {timeSeries.name}
      {!!infoClassName && <InfoPopup {...infoClassName}>
        {infoMessage}
      </InfoPopup>}
      {timeSeries.id !== 0 && <IdentityId hideLabel={true} noButtonBorders={true} additionalButtons={<button type='button' className={`identity-menu-button identity-menu-button-no-border`} onClick={onOpenChart}>
        <i className='fas fa-chart-line fa-fw' />
      </button>}>{timeSeries.id}</IdentityId>}
      {!!timeSeries.scenarioInfo.className && <InfoPopup {...timeSeries.scenarioInfo.className}>
        {timeSeries.scenarioInfo.message}
      </InfoPopup>}
      </div>
    </Fragment>
  );
}
