import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from "redux-first-history";
import { createReducer } from '../utility/redux-utility';
import qs from 'querystring';
import {
  EVENTS_SEARCH_STARTED,
  EVENTS_SEARCH_COMPLETE,
  EVENTS_SEARCH_TOGGLE_EXPAND,
  EVENTS_SEARCH_LOAD_MESSAGE_COMPLETE,
  EVENTS_INITIALISE_USER_SETTINGS
} from '../actions/events';

export const events = createReducer(null, {
  [EVENTS_SEARCH_STARTED](state, action) {
    return state.setIn(['search', 'isSearching'], true);
  },
  [EVENTS_SEARCH_COMPLETE](state, action) {
    if (!action.data) return state.setIn(['search', 'isSearching'], false);

    const { results = [], facets = [], count = 0 } = action.data;

    return state.setIn(['search', 'results', 'data'], fromJS(results))
                .setIn(['search', 'results', 'facets'], fromJS(facets))
                .setIn(['search', 'results', 'count'], count)
                .setIn(['search', 'isSearching'], false);
  },
  [EVENTS_SEARCH_TOGGLE_EXPAND](state, action) {
    const data = state.getIn(['search', 'results', 'data']),
          index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    const isExpanded = data.getIn([index, 'isExpanded']);

    return state.setIn(['search', 'results', 'data', index, 'isExpanded'], !isExpanded);
  },
  [EVENTS_SEARCH_LOAD_MESSAGE_COMPLETE](state, action) {
    const data = state.getIn(['search', 'results', 'data']),
          index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    return state.setIn(['search', 'results', 'data', index, 'message'], action.message || 'No message found');
  },
  [EVENTS_INITIALISE_USER_SETTINGS](state, action) {
    let locationPath = window.location.pathname || '',
      locationSearch = window.location.search || '',
      locationState = { ...action.data },
      search;

    if (/\/events\/?(\?|$)/.test(locationPath)) {
      if (locationSearch.startsWith('?')) locationSearch = locationSearch.substring(1);

      locationState = { ...locationState, ...qs.parse(locationSearch) };

      window.history.replaceState(locationState, '', search = `?${qs.stringify(locationState)}`);
    }
    else search = `?${qs.stringify(locationState)}`;

    let { query, customFilter, filters = [], orderBy = '', orderByDirection = '', page, pageSize, timeRangeFilter } = locationState;

    function getFilter(kvp) {
      let [name, value] = kvp.split('|');

      return { name, value };
    }

    if (filters) {
      filters = Array.isArray(filters) ? filters.map(getFilter) : [getFilter(filters)];
      filters = filters.filter(f => f.name && (f.value || f.value === 0 || f.value === false));
    }

    if (page >= 0) page = Number(page);
    if (pageSize >= 0) pageSize = Number(pageSize);

    let newState = state.setIn(['location', 'pathname'], '/events')
      .setIn(['location', 'history', '/events'], search)
      .setIn(['location', 'search'], search)
      .setIn(['search', 'criteria', 'query'], query)
      .setIn(['search', 'criteria', 'customFilter'], customFilter)
      .setIn(['search', 'criteria', 'filters'], fromJS(filters))
      .setIn(['search', 'criteria', 'orderBy'], orderBy)
      .setIn(['search', 'criteria', 'orderByDirection'], orderByDirection)
      .setIn(['search', 'criteria', 'page'], page)
      .setIn(['search', 'criteria', 'pageSize'], pageSize)
      .setIn(['search', 'criteria', 'timeRangeFilter'], timeRangeFilter);

    return newState;
  },
  [LOCATION_CHANGE](state, action) {
    const { location } = action.payload;
    let { pathname = '', search = '' } = location;
    pathname = decodeURIComponent(pathname);

    if (pathname.indexOf('/events') < 0) return state;

    if (search.startsWith('?')) search = search.substring(1);

    let { query, customFilter, filters = [], orderBy = '', orderByDirection = '', page, pageSize, timeRangeFilter } = qs.parse(search);

    function getFilter(kvp) {
      let [name, value] = kvp.split('|');

      return { name, value };
    }

    if (filters) {
      filters = Array.isArray(filters) ? filters.map(getFilter) : [getFilter(filters)];
      filters = filters.filter(f => f.name && (f.value || f.value === 0 || f.value === false));
    }

    if (page >= 0) page = Number(page);
    if (pageSize >= 0) pageSize = Number(pageSize);

    return state.setIn(['location', 'pathname'], pathname)
                .setIn(['location', 'search'], location.search)
                .setIn(['location', 'history', pathname], location.search)
                .setIn(['search', 'criteria', 'query'], query)
                .setIn(['search', 'criteria', 'customFilter'], customFilter)
                .setIn(['search', 'criteria', 'filters'], fromJS(filters))
                .setIn(['search', 'criteria', 'orderBy'], orderBy)
                .setIn(['search', 'criteria', 'orderByDirection'], orderByDirection)
                .setIn(['search', 'criteria', 'page'], page)
                .setIn(['search', 'criteria', 'pageSize'], pageSize)
                .setIn(['search', 'criteria', 'timeRangeFilter'], timeRangeFilter);
  }
});