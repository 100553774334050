import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../../shared/Loading';
import {
  etlJobApplySort,
  etlJobFilterChanged,
  etlJobRefresh,
  etlJobToggleShow,
  etlJobRequest,

} from '../../../actions/etl';
import { toJS } from '../../../utility/immutable-utility';
import { AutoRefresh } from '../../shared/AutoRefresh';
import moment from 'moment';
import { PopoverButton } from '../../shared/PopoverButton';

function SortIcon({ orderBy, orderByDirection, columnKey }) {
  return orderBy && orderBy === columnKey
    ? orderByDirection === 'desc'
      ? <i className='fas fa-sort-down fa-fw' />
      : <i className='fas fa-sort-up fa-fw' />
    : <i className='fas fa-sort fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} />;
}

function TableHeader({ header, orderBy, orderByDirection, applySort }) {
  const { key, title, sortable, prefix, suffix } = header || {};
  const onSortChange = useCallback(e => {
    e.stopPropagation();

    applySort(key);
  }, [applySort, key]);

  return (
    <th className={`${sortable ? 'sortable' : ''}`}>
      <div className='d-flex flex-row' >
        {prefix ?? <>{prefix}</>}
        
        <div className='flex-fill' onClick={onSortChange}>
          {title || ''}
          {sortable ? <SortIcon columnKey={key} orderBy={orderBy} orderByDirection={orderByDirection} /> : ''}
        </div>
      
        {suffix && <>{suffix}</>}
      </div>
    </th>
  );
}

function mapStatusToClass(value) {
  if (value === 'Failed' || value === 'FinishedWithErrors')
    return 'text-center bg-danger text-white';
  if (value === 'Succeeded')
    return 'text-center bg-success text-white';
  if (value === 'Running' || value === 'FinishedWithWarnings')
    return 'text-center bg-warning';
  return 'text-center bg-default';
}

function mapStatusToDesc(value) {
  if (value === 'FinishedWithWarnings')
    return 'Warnings';
  if (value === 'FinishedWithErrors')
    return 'Error';
  return value;
}

const EtlJobs = connect(
  (state) => ({
    initialised: state.getIn(['etl', 'initialised']),
    isLoading: state.getIn(['etl', 'isLoading']),
    filteredRows: toJS(state.getIn(['etl', 'filteredRows'])),
    filter: toJS(state.getIn(['etl', 'filter'])),
    lastRefreshed: state.getIn(['etl', 'lastRefreshed']),
    orderBy: state.getIn(['etl', 'orderBy']),
    orderByDirection: state.getIn(['etl', 'orderByDirection']),
    showSuccess: state.getIn(['etl', 'showSuccess']),
    showRunning: state.getIn(['etl', 'showRunning']),
    showFinishedWithWarnings: state.getIn(['etl', 'showFinishedWithWarnings']),
    showFinishedWithErrors: state.getIn(['etl', 'showFinishedWithErrors']),
    showFail: state.getIn(['etl', 'showFail']),
    showNotRun: state.getIn(['etl', 'showNotRun']),
    showAll: state.getIn(['etl', 'showAll']),
    showLastSuccessAgo: state.getIn(['etl', 'showLastSuccessAgo']),
    succeededCount: state.getIn(['etl', 'succeededCount']),
    runningCount: state.getIn(['etl', 'runningCount']),
    finishedWithWarningsCount: state.getIn(['etl', 'finishedWithWarningsCount']),
    finishedWithErrorsCount: state.getIn(['etl', 'finishedWithErrorsCount']),
    failedCount: state.getIn(['etl', 'failedCount']),
    notRunCount: state.getIn(['etl', 'notRunCount']),
    totalCount: state.getIn(['etl', 'totalCount'])
  }),
  (dispatch) => ({
    applySort(key) {
      dispatch(etlJobApplySort(key));
    },
    filterChanged(type, text) {
      dispatch(etlJobFilterChanged(type, text));
    },
    refreshData() {
      dispatch(etlJobRefresh());
    },
    runJob(jobId) {
      dispatch(etlJobRequest(jobId));
    },
    toggleShow(property) {
      dispatch(etlJobToggleShow(property));
    },
    viewJob(jobId) {
      window.open(`/etl-job/${jobId}`); //, '_blank', 'menubar=yes');
    },
  })
)(({ initialised, isLoading, filteredRows, filter, lastRefreshed, orderBy, orderByDirection,
  showSuccess, showRunning, showFinishedWithWarnings, showFinishedWithErrors, showFail, showNotRun, showAll, showLastSuccessAgo,
  succeededCount, runningCount, finishedWithWarningsCount, finishedWithErrorsCount, failedCount, notRunCount, totalCount,
  refreshData, applySort, runJob, toggleShow, viewJob, filterChanged}) => {

  const {text:filterText} = filter;
  useEffect(() => {
    if (initialised === null)
      refreshData();
  });

  function onRunJob(row) {
    runJob(row.jobId)
  }

  const dashboardHeaders = [
    { key: '', title: '', sortable: false },
    { key: '', title: '', sortable: false },
    // { key: 'jobId', title: `Job ID`, sortable: true },
    { key: 'jobName', title: `Job Name`, sortable: true },
    // { key: 'lastRunId', title: `Last Run ID`, sortable: true },
    { key: 'lastRunStatus', title: 'Status', sortable: true },
    { key: 'lastSuccessAtUtc', title: 'Last Success (UTC)', sortable: true, 
      prefix: <i onClick={e => toggleShow('showLastSuccessAgo')} style={{ color: 'rgba(0, 0, 0, .2)' }} className={showLastSuccessAgo ? 'fas fa-history mr-1 mt-1' : 'fas fa-clock mr-1 mt-1'}></i> },
    { key: 'nextRunUtc', title: 'Next Run (UTC)', sortable: true },

    { key: 'lastRunOriginator', title: `Last Originator`, sortable: true, 
      suffix: <PopoverButton  buttonClassName='btn btn-xs p-0 m-0' buttonContent={<i className='fas fa-filter fa-fw' style={{ color: 'rgba(0, 0, 0, .2)' }} />}>
              {() => <FilterByDialog initialFilterText={filter.lastOriginator} type='lastOriginator' title='Last Originator' applyFilter={filterChanged}/>}
              </PopoverButton>
    },
    { key: 'lastRunStartedAtUtc', title: 'Last Started (UTC)', sortable: true },
    { key: 'lastRunStoppedAtUtc', title: 'Last Stopped (UTC)', sortable: true },
  ];

  return (
    <Loading isLoading={isLoading} message='Loading...'>
      <main className='d-flex flex-column h-100'>
        <div className='d-flex p-2'>
          <button type='button' className='btn btn-primary text-nowrap' onClick={e => refreshData()}>
            <i className='fas fa-sync-alt fa-fw' />
            <span className='px-2 d-none d-md-inline-block'>Refresh</span>
          </button>
          <div className='pt-0 pl-2'>
            <AutoRefresh label={<i className='fas fa-clock' ></i>} onRefresh={() => refreshData()}
              options={[
                { minutes: 1, text: 'Every 1 minute' },
                { minutes: 2, text: 'Every 2 minutes' },
                { minutes: 5, text: 'Every 5 minutes' },
                { minutes: 10, text: 'Every 10 minutes' }
              ]} />
          </div>
          <div className='ml-2 mr-5'>
            <button type="button"
              className={showSuccess ? 'btn btn-success text-white mr-2' : 'btn btn-outline-success mr-2'}
              onClick={e => toggleShow('showSuccess')} >
              {succeededCount} Succeeded
            </button>
            <button type="button"
              className={showRunning ? 'btn btn-warning text-white mr-2' : 'btn btn-outline-warning mr-2'}
              onClick={e => toggleShow('showRunning')} >
              {runningCount} Running
            </button>
            <button type="button"
              className={showFinishedWithWarnings ? 'btn btn-warning text-white mr-2' : 'btn btn-outline-warning mr-2'}
              onClick={e => toggleShow('showFinishedWithWarnings')} >
              {finishedWithWarningsCount} Finished with warnings
            </button>
            <button type="button"
              className={showFinishedWithErrors ? 'btn btn-danger text-white mr-2' : 'btn btn-outline-danger mr-2'}
              onClick={e => toggleShow('showFinishedWithErrors')} >
              {finishedWithErrorsCount} Finished with errors
            </button>
            <button type="button"
              className={showFail ? 'btn btn-danger text-white mr-2' : 'btn btn-outline-danger mr-2'}
              onClick={e => toggleShow('showFail')} >
              {failedCount} Failed
            </button>
            <button type="button"
              className={showNotRun ? 'btn btn-secondary text-white mr-2' : 'btn btn-outline-secondary mr-2'}
              onClick={e => toggleShow('showNotRun')} >
              {notRunCount} Not run
            </button>
            <button type="button"
              className={showAll ? 'btn btn-primary text-white' : 'btn btn-outline-primary'}
              onClick={e => toggleShow('showAll')} >
              {totalCount} Show all
            </button>
          </div>
          <div>
            <input type='text' placeholder='Job filter' className='form-control'
              onChange={e => filterChanged('text', e.target.value)}
              value={filterText} />
          </div>
        </div>
        <div className='sticky-table h-100'>
          <table className='table table-sm table-striped'>
            <thead>
              <tr className='sticky-header text-nowrap'>
                {dashboardHeaders.map((i, ix) => <TableHeader key={ix} header={i} applySort={applySort} orderBy={orderBy} orderByDirection={orderByDirection} />)}
                <td className='w-100'></td>
              </tr>
            </thead>
            <tbody>
              {filteredRows.map((row, ix) => {

                return <tr key={row.jobId}>
                  <td align="center">
                    <button type="button" className="btn btn-sm btn-secondary" onClick={_ => onRunJob(row)}>Run</button>
                  </td>
                  <td align="center">
                    <button type="button" className="btn btn-sm btn-info" onClick={e => viewJob(row.jobId)}>View</button>
                  </td>

                  {/* <td className='text-right'>{row.jobId}</td> */}
                  <td>{row.jobName}</td>
                  {/* <td className='text-centre'>{row.lastRunId}</td> */}
                  <td className={mapStatusToClass(row.lastRunStatus)}>{mapStatusToDesc(row.lastRunStatus)}</td>
                  <td className={showLastSuccessAgo ? 'text-right text-nowrap' : 'text-centre text-nowrap'} >{!row.lastSuccessAtUtc ? '' : (showLastSuccessAgo ? moment.duration(moment.utc(row.lastSuccessAtUtc).diff(moment.utc())).humanize({ d: 7, w: 4, s: 60 }) + ' ago' : row.lastSuccessAtUtc.format('DD-MMM-YYYY HH:mm:ss'))}</td>
                  <td className={`text-centre text-nowrap${row.nextRunUtc && row.isNextRunLate ? ' bg-danger text-white' : ''}`}>{row.nextRunUtc ? row.nextRunUtc.format('DD-MMM-YYYY HH:mm:ss') : ''}</td>

                  <td className='text-centre'>{row.lastRunOriginator}</td>
                  <td className='text-centre text-nowrap'>{row.lastRunStartedAtUtc ? row.lastRunStartedAtUtc.format('DD-MMM-YYYY HH:mm:ss') : ''}</td>
                  <td className='text-centre text-nowrap'>{row.lastRunStoppedAtUtc ? row.lastRunStoppedAtUtc.format('DD-MMM-YYYY HH:mm:ss') : ''}</td>
                  <td></td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        <div className='p-2'>
          <div className='text-info'>Last refreshed: {lastRefreshed ? lastRefreshed.format('DD-MMM-YYYY HH:mm:ss') : ''}</div>
        </div>
      </main>
    </Loading>
  );
});

function FilterByDialog({ type, title, initialFilterText, applyFilter }) {
  const [filterText, setFilterText] = useState(initialFilterText);

  function onApplyFilter(e, filterText) {
    e.preventDefault();
    e.stopPropagation();
    applyFilter(type, filterText);
  }

  return <form onSubmit={e => onApplyFilter(e, filterText)}>
    <div className='modal-content'>
      <div className='modal-header'>
        <h5 className='modal-title'>{title}</h5>
        <button type='button' className='close' data-close-popover>&times;</button>
      </div>
      <div className='modal-body'>
          <input type='text' autoFocus className='form-control' value={filterText ?? ''} onChange={(e) => setFilterText(e.target.value)} />
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' data-close-popover>Close</button>
        <button type='submit' className='btn btn-primary' data-close-popover>Apply filter</button>
      </div>
    </div>
  </form>
}


export default EtlJobs;