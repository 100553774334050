import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../../shared/Loading';
import { analysisShapesLoadAvailableShapes, analysisShapesSelectShape } from '../../../../actions/analysis-shapes-v2';

export const ShapesFiller = connect(
  (state) => ({
    shapes: state.getIn(['analysis', 'ui', 'shapesSettings', 'availableShapes']),
    onItems: state.getIn(['analysis', 'ui', 'shapesSettings', 'onItems']),
    isShapedLoading: state.getIn(['analysis', 'ui', 'shapesSettings', 'isLoadingShapes']),
  }),
  (dispatch) => ({
    initialise() {
      dispatch(analysisShapesLoadAvailableShapes());
    },
    selectShape(shapeId) {
      dispatch(analysisShapesSelectShape(shapeId));
    }
  })
)(({ initialise, fillerValues, setFillerValue, updateAllSeries, shapes, selectShape, isShapedLoading, onItems }) => {
  const { shape } = fillerValues.get('windowOverride').toJS();
  const _shapes = shapes.toJS();

  console.log(onItems?.toJS())
  useEffect(() => {
    initialise();
  }, [initialise]);

  function saveValues(e) {
    e.preventDefault();

    if (shape && onItems) {
      updateAllSeries(['shape'], {
        name: shape,
        onItems: onItems?.toJS()
      });
    } else {
      updateAllSeries(['shape', undefined]);
    }
  }

  function setShape(value) {
    setFillerValue(['windowOverride', 'shape'], value);
    const _shape = _shapes.find(s => s.name === value);
    selectShape(_shape.id);
  }

  return <form onSubmit={e => saveValues(e)} >
    <Loading isLoading={isShapedLoading}>
      <div className='modal-content'>
        <div className='modal-body'>
          <div className='form-row pb-1'>
            <label className='font-weight-bold col-sm-3 mt-2'>Shape</label>
            <div className='col-sm-9'>
              <select className='form-control form-control-sm' style={{ minWidth: '200px' }}
                value={shape ?? ''}
                onChange={e => setShape(e.target.value)}>
                <option disabled key='-top-option-' value=''></option>
                {_shapes.map(s => <option key={s.name} value={s.name}>{s.name}</option>)}
              </select>
            </div>
          </div>
        </div>

        <div className='modal-footer'>
          <button data_id='apply' className='btn btn-sm btn-primary' type='submit' data-close-popover>Apply</button>
        </div>
      </div>
    </Loading>
  </form>

})

export default ShapesFiller;