import React from 'react';
import Dialog from '@mui/material/Dialog';
import LoadingIcon from '../../shared/LoadingIcon';
import ScreenCaptureTable from './ScreenCaptureTable';

export default function ScreenCaptureModal({ hasWarning, warningMessage, isVisible, isLoading, isSaving, isEditing, selectedId, editId, actions,
  openNewEdit, openEdit, closeEdit, selectAction, deleteAction, goToAction, closeModal, captureNow }) {
  const hasSelected = selectedId > 0;
  return (
    <Dialog open={isVisible} fullWidth={true} maxWidth='xl' onClose={() => closeModal()}>
      <div className='modal-content'>
        <div className='modal-header border-0' style={{ justifyContent: 'flex-start' }}>
          <h5 className='modal-title'>Screen Capture</h5>
          { hasWarning && (
            <div className='alert alert-warning py-0 pl-1 pr-3 my-0 mx-2'>
              <h5 className='modal-title ml-2'>
                <i className='fas fa-exclamation-triangle fa-fw' /> Warning
                <h6 className='d-inline-block ml-1'> - { warningMessage }</h6>
              </h5>
            </div>
          ) }
        </div>
        <div className='modal-body p-0'>
          <div className='d-flex' style={{ height: '500px' }}>
            { (isLoading || isSaving) ? (
              <div className='h-100 w-100' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1.25em' }}>
                <LoadingIcon width='2em' height='2em' /> <span className='pl-2'>Loading captures...</span>
              </div>
            ) : (
              <ScreenCaptureTable
                actions={actions}
                isEditing={isEditing}
                selectedId={selectedId}
                editId={editId}
                openNewEdit={openNewEdit}
                openEdit={openEdit}
                selectAction={selectAction}
                deleteAction={deleteAction}
                goToAction={goToAction} />
            ) }
          </div>
        </div>
        <div className='modal-footer border-0 d-flex' style={{ justifyContent: 'space-between' }}>
          <div>
            { isEditing
              ? <button className='btn btn-secondary ml-1' type='button' onClick={() => closeEdit()}>Cancel Edit</button>
              : <button className='btn btn-primary' type='button' onClick={() => openNewEdit()} disabled={isEditing || isLoading || isSaving}>New Capture</button> }
          </div>
          <div>
            <button className='btn btn-secondary' type='button' onClick={() => closeModal()}>Close</button>
            { hasSelected && <button className='btn btn-primary ml-1' type='button' onClick={() => captureNow()}>Capture Now</button> }
          </div>
        </div>
      </div>
    </Dialog>
  );
}