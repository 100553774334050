import React from 'react';
import { connect } from 'react-redux'
import DatePicker from 'react-widgets/DatePicker';
import {
  timeSeriesDetailsAdjustmentsSetRangeEditorProperty
} from '../../../../actions/timeSeriesDetails-adjustments';
import { reactWidgetDateTimeFormat } from '../../../shared/FormattedDateTime';

const DataPointsAddRangeModal = connect(
  (state) => {
    return {
      fromUtc: state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsRangeEdit', 'fromUtc']),
      toUtc: state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsRangeEdit', 'toUtc']),
      filterTimezone: state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsEdit', 'filterTimezone']),
      value: state.getIn(['timeSeriesDetails', 'adjustmentsEditor', 'dataPointsRangeEdit', 'value'])
    }
  },
  (dispatch) => {
    return {
      updateProperty(properties, value) {
        dispatch(timeSeriesDetailsAdjustmentsSetRangeEditorProperty(properties, value));
      }
    }
  },
)(({ value, updateProperty, fromUtc, toUtc, filterTimezone, title, onSubmit }) => {
  function onSave(e) {
    e.preventDefault();
    if (value)
      onSubmit(e);
  }

  function onFromChanged(date) {
    // if there is no initial date and the new supplied date is not the half hour then use 00:00
    if (!fromUtc && (date.getMinutes() % 30 !== 0)) date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    updateProperty(['fromUtc'], date);
  }

  function onToChanged(date) {
    // if there is no initial date and the new supplied date is not the half hour then use 00:00
    if (!toUtc && (date.getMinutes() % 30 !== 0)) date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    updateProperty(['toUtc'], date);
  }

  function onValueChanged(value) {
    updateProperty(['value'], value);
  }

  return (
    <form className='modal-content' onSubmit={onSave}>
      <div className='modal-header'>
        <h5 className='modal-title'>{title}</h5>
        <button type='button' className='close' data-dismiss='modal'>&times;</button>
      </div>
      <div className='modal-body'>
        <div className='form-row'>
          <div className='col'>
            <label htmlFor='addrange-datapoints-fromutc_input'>From date ({filterTimezone})</label>
            <DatePicker {...reactWidgetDateTimeFormat}
            onChange={date => onFromChanged(date)} id='addrange-datapoints-fromutc'
              value={fromUtc} />
          </div>
          <div className='col'>
            <label htmlFor='addrange-datapoints-toutc_input'>To date ({filterTimezone})</label>
            <DatePicker {...reactWidgetDateTimeFormat}
              onChange={date => onToChanged(date)} id='addrange-datapoints-toutc'
              value={toUtc} />
          </div>
          <div className='col'>
            <label htmlFor='addrange-datapoints-value'>Value</label>
            <input className='form-control' type='number' id='addrange-datapoints-value'
              onChange={e => onValueChanged(e.target.value)}
              value={value} />
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
        <button type='submit' className='btn btn-primary' disabled={!value}>OK</button>
      </div>
    </form>
  );
});

export default DataPointsAddRangeModal;