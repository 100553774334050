import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import {
  mapReportToTable,
  mapReportTimeSeriesCollectionDataToChartData,
  sortTableData
} from '../reducer-functions/analysis-table-mapper';
import { applyStyles } from '../utility/style-utility';
import {
  DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_VALUES,
  DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH,
  DASHBOARD_TILE_ANALYSIS_REFRESH_COMPLETE,
  DASHBOARD_TILE_ANALYSIS_SAVE_BASELINE
} from '../actions/dashboard-tiles-analysis';
import {
  ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_BEGIN,
  ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_COMPLETE,
} from '../actions/analysis';
import {
  rebuildDisplayNamesToState,
  mergeTimeSeriesCollectionToBasket,
  mapSeriesOptions  
} from '../utility/analysis-basket-utility';

export const dashboardTilesAnalysisReducer = {  
  [DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_VALUES](state, action) {
    if (!action.stateKey)
      return state;

    const tableSettings = toJS(state.getIn(['tilesState', action.stateKey, 'workspace', 'tableSettings']));
    Object.assign(tableSettings, action.data);

    const source = toJS(state.getIn(['tilesState', action.stateKey, 'source'])) ?? {};
    const basket = toJS(state.getIn(['tilesState', action.stateKey, 'workspace', 'timeseries']), []);
    const startDate = state.getIn(['tilesState', action.stateKey, 'chart', 'startDate']);
    const endDate = state.getIn(['tilesState', action.stateKey, 'chart', 'endDate']);

    let { tableStyles, tableRowStyles, tableData, tableHeaders, isHorizontal, statistics } = mapReportToTable(tableSettings, basket, source, startDate, endDate);
    tableData = sortTableData(isHorizontal, tableData, tableSettings.orderBy, tableSettings.orderByDirection);

    state = state.setIn(['tilesState', action.stateKey, 'table', 'styles'], fromJS(tableStyles))
      .setIn(['tilesState', action.stateKey, 'table', 'rowStyles'], fromJS(tableRowStyles))
      .setIn(['tilesState', action.stateKey, 'table', 'headers'], fromJS(tableHeaders))
      .setIn(['tilesState', action.stateKey, 'table', 'data'], fromJS(tableData))
      .setIn(['tilesState', action.stateKey, 'data', 'statistics'], fromJS(statistics))
      .setIn(['tilesState', action.stateKey, 'workspace', 'tableSettings'], fromJS(tableSettings));
    return state;
  },
  [DASHBOARD_TILE_ANALYSIS_UPDATE_TABLE_SETTINGS_HEADER_WIDTH](state, action) {
    if (!action.stateKey)
      return state;

    const tableSettings = toJS(state.getIn(['tilesState', action.stateKey, 'workspace', 'tableSettings']));
    const headers = toJS(state.getIn(['tilesState', action.stateKey, 'table', 'headers']));
    const data = toJS(state.getIn(['tilesState', action.stateKey, 'table', 'data']));
    const statistics = toJS(state.getIn(['tilesState', action.stateKey, 'data', 'statistics']));
    if (!tableSettings.headerStyles) tableSettings.headerStyles = {};
    if (!tableSettings.headerStyles.cellStyles) tableSettings.headerStyles.cellStyles = {};
    if (!tableSettings.headerStyles.cellStyles.conditionalStyles) tableSettings.headerStyles.cellStyles.conditionalStyles = [];
    let column = tableSettings.headerStyles.cellStyles.conditionalStyles.find(i => i.columnKey === `${action.key}`);
    if (!column) {
      column = { columnKey: `${action.key}` };
      tableSettings.headerStyles.cellStyles.conditionalStyles.push(column);
    }

    if (!column.style) column.style = {};
    column.style.width = action.value;

    const { tableStyles, tableRowStyles } = applyStyles(tableSettings, headers, data, statistics);
    return state.setIn(['tilesState', action.stateKey, 'table', 'styles'], fromJS(tableStyles))
      .setIn(['tilesState', action.stateKey, 'table', 'rowStyles'], fromJS(tableRowStyles))
      .setIn(['tilesState', action.stateKey, 'table', 'headers'], fromJS(headers))
      .setIn(['tilesState', action.stateKey, 'table', 'data'], fromJS(data))
      .setIn(['tilesState', action.stateKey, 'workspace', 'tableSettings'], fromJS(tableSettings));
  },
  [DASHBOARD_TILE_ANALYSIS_REFRESH_COMPLETE](state, action) {
    if (!action.stateKey)
      return state;

    state = state.deleteIn(['tilesState', action.stateKey, 'initUrlSearch']);

    if (!action.data)
      return state.deleteIn(['tilesState', action.stateKey, 'chart']);

    const { workspace, timeSeriesReport, timeSeriesExpressionNames, overrideDynamicWorkspace = []} = action.data;
    if (workspace)
      state = state.setIn(['tilesState', action.stateKey, 'workspace'], fromJS(workspace));

    if (!workspace || !timeSeriesExpressionNames || !timeSeriesReport)
      return state.deleteIn(['tilesState', action.stateKey, 'chart']);

    const dashboardTile = {
      ...toJS(state.getIn(['tilesConfig', action.stateKey]), {}), 
      overrideDynamicWorkspace:overrideDynamicWorkspace};

    const { tableSettings = {} } = workspace;
    let { timeSeries: timeSeriesCollection = [], data = [], timeSeriesWindows = [] } = timeSeriesReport ?? {};

    const newBasket = mergeTimeSeriesCollectionToBasket({ basket:workspace.timeseries, timeSeriesCollection, timeSeriesExpressionNames, preventNameOverride: true });
    const chart = {
      refreshRequired: false,
      isLoading: false,
      lens: workspace.lens,
      hasXAxis: newBasket.some(i => i.isXAxis),
      comparisonMode: workspace.comparisonMode,
      stackingType: workspace.stackingType,
      chartSettings: workspace.chartSettings
    };

    chart.seriesData = mapReportTimeSeriesCollectionDataToChartData({ basket: newBasket, data, comparisonMode: workspace.comparisonMode, timeSeriesWindows });
    chart.timeSeries = newBasket.filter(i => !i.isXAxis && !i.isDisabled).map(i => ({ ...i }));
    chart.chartSeries = chart.timeSeries.map((i, index) => mapSeriesOptions(i, chart.seriesData[i.key], index));

    const source = {
      type: 'analysis-report',
      data
    };

    let { tableStyles, tableRowStyles, tableData, tableHeaders, isHorizontal, statistics } = mapReportToTable(tableSettings, newBasket, source, timeSeriesWindows);
    tableData = sortTableData(isHorizontal, tableData, tableSettings.orderBy, tableSettings.orderByDirection);

    state = state.setIn(['tilesConfig', action.stateKey], fromJS(dashboardTile))
      .setIn(['tilesState', action.stateKey, 'chart'], fromJS(chart))
      .setIn(['tilesState', action.stateKey, 'source'], fromJS(source))
      .setIn(['tilesState', action.stateKey, 'workspace', 'timeseries'], fromJS(newBasket))
      .setIn(['tilesState', action.stateKey, 'table', 'styles'], fromJS(tableStyles))
      .setIn(['tilesState', action.stateKey, 'table', 'rowStyles'], fromJS(tableRowStyles))
      .setIn(['tilesState', action.stateKey, 'table', 'headers'], fromJS(tableHeaders))
      .setIn(['tilesState', action.stateKey, 'table', 'data'], fromJS(tableData))
      .setIn(['tilesState', action.stateKey, 'data', 'statistics'], fromJS(statistics))

    state = rebuildDisplayNamesToState(state, action.stateKey);
    return state;
  },
  [DASHBOARD_TILE_ANALYSIS_SAVE_BASELINE](state, action) {
    if (!action.baselineBasket)
      return state;

    const { baselineBasket } = action;
    return state.setIn(['tilesState', action.stateKey, 'baselineBasket'], fromJS(baselineBasket));
  },
  [ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_BEGIN](state, action) {
    if (!action.stateKey)
      return state;

    return state
      .setIn(['tilesState', action.stateKey, 'workspace', 'isLoadingNameExpression'], true)
      .setIn(['tilesState', action.stateKey, 'workspace', 'templateNameExpression'], action.expression);
  },
  [ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_COMPLETE](state, action) {
    if (!action.stateKey)
      return state;

    const {timeseries = []} = action;
    state = state
      .deleteIn(['tilesState', action.stateKey, 'workspace', 'isLoadingNameExpression'])
      .setIn(['tilesState', action.stateKey, 'workspace', 'timeseries'], fromJS(timeseries));
    state = rebuildDisplayNamesToState(state, action.stateKey);
    return state;
  }
};