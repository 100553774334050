import defaultImageUrls from '../assets/homepage-dataurls.json';
import { WORKSPACES_IMAGE_URL } from '../config';

export function getWorkspaceImageUrl({type, imageId}){
  if (imageId){
    return `${WORKSPACES_IMAGE_URL}/${imageId}`;
  }
  else{
    return defaultImageUrls[type];
  }
}