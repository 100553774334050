import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_URL } from '../config';
import qs from 'querystring';
import {
  logErrorNotification
} from './log';

function mapComposition(item) {
  let i = {
    id: item.id,
    title: item.name,
    style: item.style,
    data: {
      id: item.id,
      title: item.name,
      name: item.name,
      source: item.source,
      dataType: item.dataType,
      schemas: item.schemas,
      style: item.style,
      derivationType: '',
      granularity: '',
      unit: item.unit,
      sourceTimeZoneId: item.sourceTimeZoneId,
      lastUpdatedUtc: item.lastUpdatedUtc,
      queryCount: item.queryCount
    },
    items: item.inputs && item.inputs.length ? item.inputs.map(mapComposition) : null,
  };

  let { granularityFrequency, granularityType } = item.granularity || {};
  if (granularityFrequency && granularityType)
    i.data.granularity = `${granularityFrequency} ${granularityType}`;

  let { derivationType } = item.derivationData || {};
  if (derivationType)
    i.data.derivationType = `${derivationType}`;

  return i;
}

export const analysisCompositionLoad = (key) => (dispatch, getState) => {
  const state = getState();
  const maxDepth = state.getIn(['analysis', 'composition', 'maxDepth']);

  const params = qs.stringify({ id: key, maxDepth: maxDepth > 0 ? maxDepth : 100 });

  dispatch(analysisCompositionLoadStarted(key));

  return authFetch(`${ANALYSIS_API_URL}/timeseriessearchcomposition?${params}`)
    .then(response => response.json())
    .then(data => mapComposition(data))
    .then(data => {
      dispatch(analysisCompositionLoadCompleted(key, data));
    })
    .catch(error => {
      dispatch(analysisCompositionLoadCompleted(key));
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_COMPOSITION_LOAD_STARTED = 'ANALYSIS_COMPOSITION_LOAD_STARTED';
export const analysisCompositionLoadStarted = createAction(ANALYSIS_COMPOSITION_LOAD_STARTED, 'key');

export const ANALYSIS_COMPOSITION_LOAD_COMPLETED = 'ANALYSIS_COMPOSITION_LOAD_COMPLETED';
export const analysisCompositionLoadCompleted = createAction(ANALYSIS_COMPOSITION_LOAD_COMPLETED, 'key', 'data');

export const ANALYSIS_COMPOSITION_TOGGLE_EXPANDED = 'ANALYSIS_COMPOSITION_TOGGLE_EXPANDED';
export const analysisCompositionToggleExpanded = createAction(ANALYSIS_COMPOSITION_TOGGLE_EXPANDED, 'key', 'displayKey', 'indexKeyPath');

export const ANALYSIS_COMPOSITION_TIER_TOGGLE_EXPANDED = 'ANALYSIS_COMPOSITION_TIER_TOGGLE_EXPANDED';
export const analysisCompositionTierToggleExpanded = createAction(ANALYSIS_COMPOSITION_TIER_TOGGLE_EXPANDED, 'selectedLevel');

export const ANALYSIS_COMPOSITION_TOGGLE_SELECTED = 'ANALYSIS_COMPOSITION_TOGGLE_SELECTED';
export const analysisCompositionToggleSelected = createAction(ANALYSIS_COMPOSITION_TOGGLE_SELECTED, 'key');

export const ANALYSIS_COMPOSITION_REFRESH_SELECTED = 'ANALYSIS_COMPOSITION_REFRESH_SELECTED';
export const analysisCompositionRefreshSelected = createAction(ANALYSIS_COMPOSITION_REFRESH_SELECTED);

export const ANALYSIS_COMPOSITION_SET_SELECTED_LEVEL = 'ANALYSIS_COMPOSITION_SET_SELECTED_LEVEL';
export const analysisCompositionSetSelectedLevel = createAction(ANALYSIS_COMPOSITION_SET_SELECTED_LEVEL, 'selectedLevel');

export const ANALYSIS_COMPOSITION_SET_MAX_DEPTH = 'ANALYSIS_COMPOSITION_SET_MAX_DEPTH';
export const analysisCompositionSetMaxDepth = createAction(ANALYSIS_COMPOSITION_SET_MAX_DEPTH, 'maxDepth');