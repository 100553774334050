import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL, REPORTING_API_ROOT_URL } from '../config';
import {
  logErrorNotification,
  logWarningNotification
} from './log';

export async function saveAnalysisAdjustmentsAsync(adjustmentsRequest, dispatch) {
  return authFetch(`${ANALYSIS_API_ROOT_URL}/v1/inline-adjustments`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(adjustmentsRequest)
  })
    .then(response => response.json())
    .then(data => {
      const { timeSeries = [] } = data;

      timeSeries.filter(i => i.error && i.error.length).forEach(i => {
        i.error.forEach(message => dispatch(logErrorNotification(`${i.key}: ${message}`)));
      });

      timeSeries.filter(i => i.warning && i.warning.length).forEach(i => {
        i.warning.forEach(message => dispatch(logWarningNotification(`${i.key}: ${message}`)));
      });

      return data;
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      return null;
    });
}

export async function validateAnalysisAdjustmentsAsync(adjustmentsRequest, dispatch) {
  return authFetch(`${ANALYSIS_API_ROOT_URL}/v1/inline-adjustments/validate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(adjustmentsRequest)
  })
    .then(response => response.json())
    .then(data => {
      const { timeSeries = [] } = data;

      timeSeries.filter(i => i.error && i.error.length).forEach(i => {
        i.error.forEach(message => dispatch(logErrorNotification(`${i.key}: ${message}`)));
      });

      timeSeries.filter(i => i.warning && i.warning.length).forEach(i => {
        i.warning.forEach(message => dispatch(logWarningNotification(`${i.key}: ${message}`)));
      });

      return data;
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      return null;
    });
}

export async function saveDefinedReportsAdjustmentsAsync(adjustmentsRequest, dispatch) {
  return authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports/inline-adjustments`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(adjustmentsRequest)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      return null;
    });
}

export async function validateDefinedReportsAdjustmentsAsync(adjustmentsRequest, dispatch) {
  return authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports/inline-adjustments/validate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(adjustmentsRequest)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      return null;
    });
}