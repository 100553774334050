import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import {
  timeSeriesDetailsDerivationKeysLoad,
  timeSeriesDetailsDerivationKeysSave,
} from './timeSeriesDetails-derivation';
import {
  timeSeriesDetailsBasedOnLoad
} from './timeSeriesDetails-based-on'

export const timeSeriesDetailsToolbarUpdateViewMode = (mode) => (dispatch, getState) => {
  dispatch(timeSeriesDetailsToolbarSetViewMode(mode));
  if (mode === 'json') {
    dispatch(timeSeriesDetailsDerivationKeysSave());
    return;
  }

  // switching back to default view so update derivation settings
  const state = getState();
  const derivationKeys = toJS(state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'derivationData', 'keys']), []);
  dispatch(timeSeriesDetailsDerivationKeysLoad(derivationKeys));
  dispatch(timeSeriesDetailsBasedOnLoad());
}

export const TIMESERIES_DETAILS_TOOLBAR_SET_VIEWMODE = 'TIMESERIES_DETAILS_TOOLBAR_SET_VIEWMODE';
export const timeSeriesDetailsToolbarSetViewMode = createAction(TIMESERIES_DETAILS_TOOLBAR_SET_VIEWMODE, 'mode');

