import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import {
  DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_INITIALISE,
  DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_SHOW_IN_TILE,
  DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_TILE,
  DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_TOGGLE_FILTER_TILES,
  DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SAVE_CHANGES,
  DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_REBUILD_OPTIONS,
  DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_SELECT_OPTION_COMPLETE
} from '../actions/dashboard-tiles-dynamic-workspace'
import { toggleAll } from '../utility/selection-utility';


function rebuildOptions(state) {
  const dynamicWorkspaceDropdowns = toJS(state.get('dynamicWorkspaceDropdowns')) ?? [];
  const tilesState = state.get('tilesState').toJS();
  const tilesConfig = state.get('tilesConfig').toJS();

  dynamicWorkspaceDropdowns.forEach(dropdown => {
    dropdown.isMultiSelect = false;
    dropdown.options = [];
    dropdown.tiles.forEach(tileKey => {
      const tileState = tilesState[tileKey];
      const tileConfig = tilesConfig[tileKey];
      if (tileConfig && tileState && tileState.workspace && tileState.workspace.dynamicWorkspaces) {
        tileState.workspace.dynamicWorkspaces.forEach(dynamicWorkspace => {
          const dynamicFilter = dynamicWorkspace.dynamicFilters.find(df => dropdown.filterName === (df.alias ? df.alias : df.selectedCategory));
          if (dynamicFilter) {
            if (dynamicFilter.isMultiSelect) dropdown.isMultiSelect = true;
            if (dynamicFilter.strict) {
              dropdown.options.push(...dynamicFilter.strictValues.filter(v => v.isSelected === true).map(v => v.name));
            }
            else {
              dropdown.options.push(...dynamicFilter.values.filter(v => v.isSelected === true).map(v => v.name));
            }
          }
        });
      }
    })

    // fitler to a distinct set
    dropdown.options = [...new Set(dropdown.options)].sort();
  });

  state = state.set('dynamicWorkspaceDropdowns', fromJS(dynamicWorkspaceDropdowns));
  return state;
}

export const dashboardTilesDynamicWorkspaceDropdownsReducer = {
  [DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_INITIALISE](state, action) {
    const dynamicWorkspaceDropdownDesign = {
      isDirty: false,
      dropdowns: [],
    };

    const existingDropdowns = toJS(state.get('dynamicWorkspaceDropdowns')) ?? [];
    const tilesConfig = state.get('tilesConfig').toJS();
    const tileKeys = Object.keys(tilesConfig);
    const tilesState = state.get('tilesState').toJS();

    tileKeys.forEach(tileKey => {
      const tile = tilesConfig[tileKey];
      if (tile.type === 'AnalysisChart') {
        const tileState = tilesState[tileKey];
        if (tileState.workspace && tileState.workspace.dynamicWorkspaces) {
          tileState.workspace.dynamicWorkspaces.forEach(dynamicWorkspace => {
            dynamicWorkspace.dynamicFilters.forEach(dynamicFilter => {
              const filterName = dynamicFilter.alias ? dynamicFilter.alias : dynamicFilter.selectedCategory;
              let filter = dynamicWorkspaceDropdownDesign.dropdowns.find(f => f.filterName === filterName);
              if (!filter) {
                const existingDropdown = existingDropdowns.find(d => d.filterName === filterName);
                filter = {
                  filterName: filterName,
                  doNotShowInTile: existingDropdown ? existingDropdown.doNotShowInTile : true,
                  tiles: []
                };
                dynamicWorkspaceDropdownDesign.dropdowns.push(filter);
              }

              if (!filter.tiles.exists(t => t.tileKey === tileKey)) {
                filter.tiles.push({
                  tileKey: tileKey,
                  isSelected: existingDropdowns.exists(d => d.filterName === filterName && d.tiles.exists(tileKey)),
                  tileName: tile.title ? tile.title : tileState.workspace.name
                });
              }
            })
          });
        }
      }
    });

    state = state.set('dynamicWorkspaceDropdownDesign', fromJS(dynamicWorkspaceDropdownDesign));
    return state;
  },
  [DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_SHOW_IN_TILE](state, action) {
    const dynamicWorkspaceDropdownDesign = state.get('dynamicWorkspaceDropdownDesign').toJS();
    const dropdown = dynamicWorkspaceDropdownDesign.dropdowns.find(f => f.filterName === action.filterName);
    dropdown.doNotShowInTile = action.value;
    dynamicWorkspaceDropdownDesign.isDirty = true;

    state = state.set('dynamicWorkspaceDropdownDesign', fromJS(dynamicWorkspaceDropdownDesign));
    return state;
  },
  [DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_TILE](state, action) {
    const dynamicWorkspaceDropdownDesign = state.get('dynamicWorkspaceDropdownDesign').toJS();
    const dropdown = dynamicWorkspaceDropdownDesign.dropdowns.find(f => f.filterName === action.filterName);
    const tile = dropdown.tiles.find(t => t.tileKey === action.tileKey);
    tile.isSelected = action.value;
    dynamicWorkspaceDropdownDesign.isDirty = true;

    state = state.set('dynamicWorkspaceDropdownDesign', fromJS(dynamicWorkspaceDropdownDesign));
    return state;
  },
  [DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_TOGGLE_FILTER_TILES](state, action) {
    const dynamicWorkspaceDropdownDesign = state.get('dynamicWorkspaceDropdownDesign').toJS();
    const dropdown = dynamicWorkspaceDropdownDesign.dropdowns.find(f => f.filterName === action.filterName);
    
    toggleAll(dropdown.tiles, t => t.isSelected, (i, v) => i.isSelected = v);
    dynamicWorkspaceDropdownDesign.isDirty = true;

    state = state.set('dynamicWorkspaceDropdownDesign', fromJS(dynamicWorkspaceDropdownDesign));
    return state;
  },
  [DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SAVE_CHANGES](state, action) {
    const dynamicWorkspaceDropdownDesign = state.get('dynamicWorkspaceDropdownDesign').toJS();
    let dropdowns = [];

    dynamicWorkspaceDropdownDesign.dropdowns.forEach(d => {
      dropdowns.push({
        filterName: d.filterName,
        isMultiSelect: d.isMultiSelect,
        options: [],
        selectedValue: '',
        multiSelectValues : [],
        doNotShowInTile: d.doNotShowInTile,
        tiles: d.tiles.filter(t => t.isSelected).map(t => t.tileKey)
      })
    });

    dropdowns = dropdowns.filter(d => d.tiles.length > 0);
    const existingDropdowns = toJS(state.get('dynamicWorkspaceDropdowns')) ?? [];
    dropdowns.forEach(dropdowns=> {
      const ex = existingDropdowns.find(e => e.filterName === dropdowns.filterName);
      if (ex) {
        dropdowns.selectedValue = ex.selectedValue ?? '';
        dropdowns.multiSelectValues = ex.selectedValue && ex.selectedValue.trim() !== '' ? [ex.selectedValue] : [];
      }
    });
    state = state.set('dynamicWorkspaceDropdowns', fromJS(dropdowns));

    dynamicWorkspaceDropdownDesign.isDirty = false;
    dynamicWorkspaceDropdownDesign.dropdowns = [];
    state = state.set('dynamicWorkspaceDropdownDesign', fromJS(dynamicWorkspaceDropdownDesign))
      .set('isDirty', true);
    state = rebuildOptions(state);
    return state;
  },
  [DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_REBUILD_OPTIONS](state, action) {
    return rebuildOptions(state);
  },
  [DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_SELECT_OPTION_COMPLETE](state, action) {
    const { filterName, value, multiSelectValues } = action;
    const tilesState = state.get('tilesState').toJS();
    const dropdowns = toJS(state.get('dynamicWorkspaceDropdowns')) ?? [];
    const dropdown = dropdowns.find(d => d.filterName === filterName);
    if (dropdown) {
      dropdown.multiSelectValues = multiSelectValues ?? [];
      dropdown.selectedValue = dropdown.isMultiSelect ? (dropdown.multiSelectValues.length > 0 ? dropdown.multiSelectValues[0] : '') : value;
      dropdown.tiles.forEach(tileKey => {
        const tileState = tilesState[tileKey];
        const newOverridesMap = {};
        tileState.workspace.dynamicWorkspaces.forEach(dynamicWorkspace => {
          dynamicWorkspace.dynamicFilters.forEach(dynamicFilter => {
            if (dropdown.filterName === (dynamicFilter.alias ? dynamicFilter.alias : dynamicFilter.selectedCategory)) {
              if (!newOverridesMap[dynamicWorkspace.key]) newOverridesMap[dynamicWorkspace.key] = {};
              newOverridesMap[dynamicWorkspace.key][dynamicFilter.key] = {
                selectedValue: dropdown.selectedValue,
                multiSelectValues: dropdown.multiSelectValues
              };
            }
          })
        });

        const newDynamicWorkspaceKeys = Object.keys(newOverridesMap)
        if (newDynamicWorkspaceKeys.length > 0) {
          const properties = toJS(state.getIn(['tilesConfig', tileKey])) ?? {};
          if (!properties.overrideDynamicWorkspace) properties.overrideDynamicWorkspace = [];

          let allOverridesMap = {};
          properties.overrideDynamicWorkspace.forEach(o => {
            allOverridesMap[o.key] = Object.fromEntries(o.filters.map(_ => [_.dynamicFilterKey, _.value])) ?? {};
          });

          newDynamicWorkspaceKeys.forEach(k => {
            allOverridesMap[k] = { ...(allOverridesMap[k] ?? {}), ...(newOverridesMap[k] ?? {}) };
          });

          properties.overrideDynamicWorkspace = Object.keys(allOverridesMap).map(k => {
            const filters = allOverridesMap[k];
            return {
              key: k,
              filters: Object.keys(filters).map(f => ({
                dynamicFilterKey: f,
                value: filters[f].selectedValue,
                values: filters[f].multiSelectValues,
              }))
            }
          });

          state = state.setIn(['tilesConfig', tileKey], fromJS(properties))
                       .setIn(['tilesState', tileKey, 'refreshRequired'], true);
        }
      });
    }

    const isEditing = state.getIn(['isEditing']);
    if (isEditing)
      state = state.setIn(['isDirty'], true);

    state = state.set('dynamicWorkspaceDropdowns', fromJS(dropdowns));
    return state;
  }
};
