import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import CollapsePanel from '../../shared/CollapsePanel';
import Dialog from '@mui/material/Dialog';
import { TableClipboardToolbar } from './TableClipboardToolbar';
import {
  analysisAdjustmentsUpdateTimeSeriesMetaProperty,
  analysisAdjustmentsEndEdit,
  analysisAdjustmentsSetSelectionAdjustmentValue,
  analysisAdjustmentsRemoveAllAdjustments,
  analysisAdjustmentsRemoveSelectedAdjustments,
  analysisAdjustmentsSave,
  analysisAdjustmentsValidate,
  analysisAdjustmentsResetPreSaveWarnings,
  analysisAdjustmentsSetSaveConfirmationVisibility
} from '../../../actions/analysis';

export const AdjustmentsPanel = connect(
  (state) => ({
    defaultTimeSeriesMetas: state.getIn(['analysis', 'adjustments', 'timeSeriesMeta']),
    selectedTimeSeriesMetas: state.getIn(['analysis', 'adjustments', 'editSelection', 'timeSeriesMeta']),
    dirtyCellsMap: state.getIn(['analysis', 'adjustments', 'dirtyCellsMap']),
    preSaveWarnings: state.getIn(['analysis', 'adjustments', 'preSaveWarnings']),
    validationMessages: state.getIn(['analysis', 'adjustments', 'validation', 'messages']),
    isSaveConfirmationVisible: state.getIn(['analysis', 'adjustments', 'isSaveConfirmationVisible']),
    defaultAnnotationText: state.getIn(['userSettings', 'settings', 'analysis', 'adjustments', 'annotation'])
  }),
  (dispatch) => ({
    updateAdjustmentTimeSeriesMetaProperty(tskey, propertyName, value) {
      dispatch(analysisAdjustmentsUpdateTimeSeriesMetaProperty(tskey, propertyName, value));
    },
    endEditMode() {
      dispatch(analysisAdjustmentsEndEdit());
    },
    setSelectionAdjustmentValue() {
      dispatch(analysisAdjustmentsSetSelectionAdjustmentValue());
      dispatch(analysisAdjustmentsValidate());
    },
    removeAllAdjustments() {
      dispatch(analysisAdjustmentsRemoveAllAdjustments());
      dispatch(analysisAdjustmentsValidate());
    },
    removeSelectedAdjustments() {
      dispatch(analysisAdjustmentsRemoveSelectedAdjustments());
      dispatch(analysisAdjustmentsValidate());
    },
    save(ignorePreSaveWarnings) {
      dispatch(analysisAdjustmentsSave(ignorePreSaveWarnings));
    },
    resetSavePreSaveWarnings() {
      dispatch(analysisAdjustmentsResetPreSaveWarnings());
    },
    setSaveConfirmationVisibility(isVisible) {
      dispatch(analysisAdjustmentsSetSaveConfirmationVisibility(isVisible));
    },
  })
)(({ updateAdjustmentTimeSeriesMetaProperty, defaultTimeSeriesMetas, defaultAnnotationText, selectedTimeSeriesMetas,
  setSelectionAdjustmentValue, removeAllAdjustments, removeSelectedAdjustments, dirtyCellsMap,
  validationMessages, preSaveWarnings, save, resetSavePreSaveWarnings, isSaveConfirmationVisible, setSaveConfirmationVisibility }) => {
  const _defaultTimeSeriesMetas = toJS(defaultTimeSeriesMetas, { annotation: defaultAnnotationText });
  const _selectedTimeSeriesMetas = toJS(selectedTimeSeriesMetas, []);
  const _validationMessages = toJS(validationMessages);
  const _preSaveWarnings = toJS(preSaveWarnings, []);
  const selectedTimeSeriesMeta = _selectedTimeSeriesMetas.filter(m => m.isSelected);
  const hasSelection = _selectedTimeSeriesMetas.length !== 0;
  const [isOpen, setIsOpen] = useState(_preSaveWarnings.length > 0);
  const _isDirty = dirtyCellsMap && Object.keys(dirtyCellsMap).length > 0;

  useEffect(() => {
    setIsOpen(preSaveWarnings.size > 0);
  }, [setIsOpen, preSaveWarnings]);

  function closeDialog() {
    setIsOpen(false);
    resetSavePreSaveWarnings();
  }

  function onUpdateAnnotation(key, value) {
    updateAdjustmentTimeSeriesMetaProperty(key, 'annotation', value);
  }

  function onUpdateAdjustmentType(key, checked) {
    updateAdjustmentTimeSeriesMetaProperty(key, 'adjustmentType', checked ? 'Temporary' : 'Standard');
  }

  function onSetSelectionAdjustmentValue() {
    setSelectionAdjustmentValue();
  }

  function onRemoveAllAdjustments() {
    removeAllAdjustments();
  }

  function onRemoveSelectedAdjustments() {
    removeSelectedAdjustments();
  }

  function onConfirmSave(e) {
    e.preventDefault();
    setSaveConfirmationVisibility(false);
    save(true);
  }

  return <div className='analysis-table-panel'>
    <div className='w-100 d-flex justify-content-between align-items-center pb-2'>
      <div className='btn-group btn-group-sm' >
        <TableClipboardToolbar />
      </div>

      <div className='btn-group btn-group-sm' >
        <button title='Remove all adjustments' type='button' className={`btn btn-sm btn-outline-primary text-nowrap`} onClick={onRemoveAllAdjustments}>
          <i className='fas fa-trash fa-fw' style={{ position: 'relative', top: '2px', fontSize: '10px' }} />
          <i className='fas fa-trash fa-fw' style={{ position: 'relative', top: '-1px', left: '-3px', fontSize: '10px' }} />
        </button>

        <button disabled={!hasSelection} title='Remove selected adjustments' type='button' className={`btn btn-sm btn-outline-primary text-nowrap`} onClick={onRemoveSelectedAdjustments} >
          <i className='fas fa-trash fa-fw' />
        </button>

        <button disabled={!hasSelection} title='Apply adjustment to selected cells' type='button' className={`btn btn-sm btn-outline-primary text-nowrap`} onClick={onSetSelectionAdjustmentValue}>
          <i className='fas fa-check fa-fw' />
        </button>
      </div>

      <button title='Save adjustments' type='button' className={`btn btn-sm ${_isDirty ? 'btn-primary' : 'btn-outline-primary'} text-nowrap`} onClick={() => setSaveConfirmationVisibility(true)}>
        Save
      </button>
    </div>

    <div className='w-100' style={{ height: 'calc(100% - 35px)', overflowY: 'auto', overflowX:'hidden' }}>
      <div className='adjustments-panel-section'>
        <CollapsePanel idKey='adj-basic-details' title='Default' headerStyle={{ cursor: 'pointer' }}>
          <AdjustmentsSettings
            title='Default'
            tsKey=''
            annotation={_defaultTimeSeriesMetas.annotation}
            updateAnnotation={onUpdateAnnotation}
            adjustmentType={_defaultTimeSeriesMetas.adjustmentType}
            updateAdjustmentType={onUpdateAdjustmentType} />
        </CollapsePanel>
      </div>

      {selectedTimeSeriesMeta.length === 1 && <div className='adjustments-panel-section'>
        <CollapsePanel idKey='adj-ts-details'
          title={<div style={{ overflowX: 'hidden', width: 'calc(100%-30px)' }} title={selectedTimeSeriesMeta[0].name}>
            {<span className='text-nowrap' >{selectedTimeSeriesMeta[0].name}</span>}
          </div>} headerStyle={{ cursor: 'pointer' }}>
          <AdjustmentsSettings
            title={selectedTimeSeriesMeta[0].name}
            tsKey={selectedTimeSeriesMeta[0].key}
            annotation={selectedTimeSeriesMeta[0].annotation}
            updateAnnotation={onUpdateAnnotation}
            adjustmentType={selectedTimeSeriesMeta[0].adjustmentType}
            updateAdjustmentType={onUpdateAdjustmentType}
          />
        </CollapsePanel>
      </div>}

      <div className='adjustments-panel-section'>
        <CollapsePanel idkey='adj-validation-messages' title='Validation' isOpen={true} canCollapse={false}>
          {_validationMessages && _validationMessages.map((tsMessages, vi) => <div key={`vi-${vi}}`} className='adjustments-validation-group'>
            <label className='adjustments-validation-group' title={tsMessages.name}>
              {tsMessages.name}
            </label>
            {tsMessages.errors.length > 0 && <div className='adjustments-validation-group'>
              <div className='adjustments-validation-group-title'><i className='text-danger fas fa-exclamation-triangle fa-fw' />Errors</div>
              {tsMessages.errors.map((m, i) => <div key={`m-${i}`} className='adjustments-validation-item'>{m}</div>)}
            </div>}
            {tsMessages.warnings.length > 0 && <div className='adjustments-validation-group'>
              <div className='adjustments-validation-group-title'><i className='text-warning fas fa-exclamation-triangle fa-fw' />Warnings</div>
              {tsMessages.warnings.map((m, i) => <div key={`m-${i}`} className='adjustments-validation-item'>{m}</div>)}
            </div>}
            {tsMessages.informations.length > 0 && <div className='adjustments-validation-group'>
              <div className='adjustments-validation-group-title'><i className='text-info fas fa-info-circle fa-fw' />Information</div>
              {tsMessages.informations.map((m, i) => <div key={`m-${i}`} className='adjustments-validation-item'>{m}</div>)}
            </div>}
          </div>)}
        </CollapsePanel>
      </div>
    </div>

    <Dialog open={isOpen} onClose={closeDialog} fullWidth={true} maxWidth={'sm'} >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Apply Adjustments?</h5>
          <button type='button' className='close' onClick={closeDialog}>&times;</button>
        </div>
        <div className='modal-body'>
          <ul>
            {_preSaveWarnings.map((message, index) => <li key={`m${index}`}>{message}</li>)}
          </ul>
          <br />
          Please confirm whether you could like to continue?
        </div>
        <form className='modal-footer'>
          <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={closeDialog}>Cancel</button>
          <button type='button' className='btn btn-primary' onClick={onConfirmSave}>Save</button>
        </form>
      </div>
    </Dialog>

    <Dialog open={isSaveConfirmationVisible === true} fullWidth={true} maxWidth={'sm'} onClose={() => setSaveConfirmationVisibility(false)}>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Save Adjustments?</h5>
        </div>
        <div className='modal-body'>
          Are you sure you wish to save the adjustments?
        </div>
        <div className='modal-footer'>
          <form noValidate onSubmit={onConfirmSave}>
            <button className='btn btn-sml btn-secondary' type='button' onClick={() => setSaveConfirmationVisibility(false)}>Cancel</button>
            <button autoFocus className='btn btn-sml btn-primary' type='submit' >Confirm</button>
          </form>
        </div>
      </div>
    </Dialog>
  </div>
});

function AdjustmentsSettings({ tsKey, updateAnnotation, annotation, updateAdjustmentType, adjustmentType }) {
  const tempId = `${tsKey}-is-temp`;
  return <>
    <div className='form-row mb-2'>

      <label className='col-md-12 font-weight-bold'>Reason</label>
      <div className="col-md-12">
        <textarea className='form-control form-control-sm' rows={4} value={annotation ?? ''} onChange={e => updateAnnotation(tsKey, e.target.value)}></textarea>
      </div>

    </div>

    <div className='form-row mb-2'>
      <div className='col-md-12 ml-3'>
        <input type='checkbox' id={tempId} className='form-check-input' checked={adjustmentType === 'Temporary'} onChange={e => updateAdjustmentType(tsKey, e.target.checked)} />
        <label htmlFor={tempId} className='form-check-label'>Make temporary</label>
      </div>
    </div>

  </>
}