import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import ShapesEditor from './ShapesEditor'
import {
  analysisShapesToggleShapeCellSelection,
  analysisShapesToggleShapeAllSelection,
  analysisShapesToggleShapeBucketSelection,
  analysisShapesToggleShapeGroupSelection,
  analysisShapesSetShapeName,
  analysisShapesSelectShape,
  analysisShapesSaveShape,
  analysisShapesDeleteShape,

  analysisShapesAddShapeToSelection,
  analysisShapesRemoveSelectedShape,
  analysisShapesRemoveAllSelectedShapes,
  analysisShapesApplySelectionToWorkspace,
  analysisShapesUpdateBasketFromWorkspace
} from '../../../actions/analysis-shapes-v2';

import {
  analysisRefreshTemplateNameExpressions
} from '../../../actions/analysis';

const ShapesEditorConnectComponent = connect(
  (state) => ({
    shapesSettings: state.getIn(['analysis', 'ui', 'shapesSettings'])
  }),
  (dispatch) => ({
    toggleShapeCellSelection(group, bucket) {
      dispatch(analysisShapesToggleShapeCellSelection(group, bucket));
    },
    toggleShapeAllSelection() {
      dispatch(analysisShapesToggleShapeAllSelection());
    },
    toggleShapeBucketSelection(bucketName) {
      dispatch(analysisShapesToggleShapeBucketSelection(bucketName));
    },
    toggleShapeGroupSelection(group) {
      dispatch(analysisShapesToggleShapeGroupSelection(group));
    },
    setShapeName(name) {
      dispatch(analysisShapesSetShapeName(name));
    },
    selectShape(shapeId) {
      dispatch(analysisShapesSelectShape(shapeId));
    },
    saveShape() {
      dispatch(analysisShapesSaveShape());
    },
    deleteShape() {
      dispatch(analysisShapesDeleteShape());
    },
    addShapeToSelection() {
      dispatch(analysisShapesAddShapeToSelection());
    },
    removeSelectedShape(name) {
      dispatch(analysisShapesRemoveSelectedShape(name));
    },
    removeAllSelectedShapes() {
      dispatch(analysisShapesRemoveAllSelectedShapes());
    },
    applySelectionToBasket() {
      dispatch(analysisShapesApplySelectionToWorkspace());
      dispatch(analysisShapesUpdateBasketFromWorkspace());     
      dispatch(analysisRefreshTemplateNameExpressions()); 
    }
  })
)(({ shapesSettings, setShapeName, selectShape, saveShape, deleteShape,
  toggleShapeCellSelection, toggleShapeAllSelection, toggleShapeBucketSelection, toggleShapeGroupSelection,
  addShapeToSelection, removeSelectedShape, removeAllSelectedShapes, applySelectionToBasket }) => {
  shapesSettings = toJS(shapesSettings);
  const shapeName = shapesSettings.shapeName;
  const availableShapes = shapesSettings.availableShapes;
  const isBusy = shapesSettings.isBusy;
  const isSavingShape = shapesSettings.isSavingShape;
  const isDeletingShape = shapesSettings.isDeletingShape;
  const isSelectingShape = shapesSettings.isSelectingShape;
  const isExistingShape = shapesSettings.isExistingShape;
  const addShapeErrorMessage = shapesSettings.addShapeErrorMessage;
  const selectedShapes = shapesSettings.selectedShapes;

  return <ShapesEditor
    shapeName={shapeName}
    isBusy={isBusy}
    isSavingShape={isSavingShape}
    isDeletingShape={isDeletingShape}
    isSelectingShape={isSelectingShape}
    availableShapes={availableShapes}
    isExistingShape={isExistingShape}

    setShapeName={setShapeName}
    selectShape={selectShape}
    saveShape={saveShape}
    deleteShape={deleteShape}
    shapeTemplateMap={shapesSettings.shapeTemplateMap}
    shapeTemplateMapIsDirty={shapesSettings.shapeTemplateMapIsDirty}
    toggleShapeCellSelection={toggleShapeCellSelection}
    toggleShapeAllSelection={toggleShapeAllSelection}
    toggleShapeBucketSelection={toggleShapeBucketSelection}
    toggleShapeGroupSelection={toggleShapeGroupSelection}

    selectedShapes={selectedShapes}
    addShapeErrorMessage={addShapeErrorMessage}
    addShapeToSelection={addShapeToSelection}
    removeSelectedShape={removeSelectedShape}
    removeAllSelectedShapes={removeAllSelectedShapes}
    applySelectionToBasket={applySelectionToBasket} />
});
//
export default ShapesEditorConnectComponent;