import { createAction } from '../utility/redux-utility';
import {
  dashboardTileRefreshBegin,
  dashboardTileRefreshComplete,
  dashboardTileRefreshError,

  fetchWorkspaceAsync
} from './dashboard-tiles';

export const dashboardTileWeblinkFromWorkspace = ({ stateKey, workspacePath }) => async (dispatch, getState) => {
  dispatch(dashboardTileRefreshBegin(stateKey));
  try {
    if (!workspacePath || workspacePath.trim().length === 0)
      return;

    const workspace = await fetchWorkspaceAsync(`/WebLink/${workspacePath}`, dispatch, getState(), stateKey);
    if (!workspace){
      dispatch(dashboardTileRefreshError(stateKey, {type:'error', message:'no content'}));
      return;
    }

    dispatch(dashboardTileWebLinkRefreshComplete(stateKey, workspace));
  }
  catch (error) {
    dispatch(dashboardTileRefreshError(stateKey, {type:'error', message: error}));
  }
  finally {
    dispatch(dashboardTileRefreshComplete(stateKey));
  }
};

export const DASHBOARD_TILE_WEBLINK_REFRESH_COMPLETE = 'DASHBOARD_TILE_WEBLINK_REFRESH_COMPLETE';
const dashboardTileWebLinkRefreshComplete = createAction(DASHBOARD_TILE_WEBLINK_REFRESH_COMPLETE, 'stateKey', 'workspace');
