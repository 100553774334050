import React, { Fragment } from 'react';

export default function CalendarYearPicker({ dates, topLeftClick, columnClick, cellClick }) {
  function onTopLeftClick() {
    if (topLeftClick) topLeftClick();
  }

  function onColumnClick() {
    if (columnClick) columnClick();
  }

  function onCellClick(year) {
    if (cellClick) cellClick(year);
  }

  return <Fragment>
    <div className='scrolling-table' style={{ width: '767px' }}>
      <div className='scrolling-tableHead'>
        <div className='scrolling-tableRow'>
          <div className={'scrolling-tableCell spreadsheet-cursor-topLeft spreadsheet-topLeft'} style={{ width: '64px' }} onClick={() => onTopLeftClick()}></div>
          <div className='scrolling-tableCell table-header-cell spreadsheet-cursor-top' style={{ width: '64px' }} onClick={() => onColumnClick()}>Year</div>
        </div>
      </div>
    </div>
    <div className='scrolling-table' style={{ width: '767px' }}>
      <div className='scrolling-table-scroll-area'>
        <div className='scrolling-tableBody '>
          {dates && dates.map(y => <div className='scrolling-tableRow' key={`y${y.name}`}>
            <div className={'scrolling-tableCell table-header-cell'} style={{ width: '64px' }} >{y.name}</div>
            <div className={'scrolling-tableCell table-cell' + (y.isSelected ? ' table-primary table-checked' : '')} style={{ width: '64px' }} onClick={() => onCellClick(y)}></div>
          </div>)}
        </div>
      </div>
    </div>
  </Fragment>
}