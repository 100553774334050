import React, { Fragment } from 'react';
import BasicSettings from './BasicSettings';
import CategorySettings from './CategorySettings';
import { DerivationSettings } from './DerivationSettings';
import JsonEditor from './JsonEditor';
import FunctionSettings from './FunctionSettings';
import { AnalysisSeriesSettings } from './AnalysisSeriesSettings';
import { EvolutionSeriesSettings } from './EvolutionSeriesSettings';

export default function TimeSeriesEditor({ selectedTimeSeriesId, viewMode, referenceData, allSchemaNames, lookupData, timeSeries, updateTimeSeriesObject, updateTimeSeriesValue, updateTimeSeriesDataType,
  inputFocusId, setInputFocus, setInputHasFocus,
  derivationSettings, updateDerivationSearchKeyText, addDerivationSearchKeyText, moveDerivationKeyUp, moveDerivationKeyDown, deleteDerivationKey, addDerivationKeys,
  functionSettings, setFunctionsStepStyleToMultiStep, setFunctionsType, addFunctionsStepParameter, deleteFunctionsStepParameter, updateFunctionParameterValue, addMultiStepFunction, deleteMultiStepFunction, 
  moveMultiStepFunctionUp, moveMultiStepFunctionDown, addFunctionKey, deleteFunctionKey, moveFunctionKeyUp, moveFunctionKeyDown, addFunctionOutputCategory, updateFunctionOutputKey, updateFunctionOutputCategoryValue, 
  deleteFunctionOutputCategory, setFunctionDisabled,
  categorySettings, addCategory, updateCategoryValue, deleteCategory, deleteAllcategories, copyCategoriesCopyFrom, getCategoryValues, requestRematerialiseWithRange, setMaterialiseStatusToEnabled, isContributor,
  basedOnSettings,
  basedOnAddKey, basedOnDeleteKey, basedOnUpdateSearchKeyText, basedOnUpdateSettings 
}) {
  const isDerived = timeSeries.style === 'Derived';
  const showJsonView = viewMode === 'json';

  function jsonTimeSeriesEdited(jsonTimeSeries) {
    updateTimeSeriesObject(jsonTimeSeries);
  }

  return showJsonView ? <JsonEditor data={timeSeries}
    selectedTimeSeriesId={selectedTimeSeriesId}
    onEdited={jsonTimeSeriesEdited} /> :
    <Fragment>
      <BasicSettings referenceData={referenceData}
                     lookupData={lookupData}
                     timeSeries={timeSeries}
                     updateTimeSeriesValue={updateTimeSeriesValue}
                     updateTimeSeriesDataType={updateTimeSeriesDataType} />
      <AnalysisSeriesSettings isContributor={isContributor}
                              timeSeries={timeSeries} 
                              basedOnSettings={basedOnSettings}                              
                              basedOnAddKey={basedOnAddKey}
                              basedOnDeleteKey={basedOnDeleteKey}
                              basedOnUpdateSearchKeyText={basedOnUpdateSearchKeyText} 
                              basedOnUpdateSettings={basedOnUpdateSettings}
                              requestRematerialiseWithRange={requestRematerialiseWithRange}
                              setMaterialiseStatusToEnabled={setMaterialiseStatusToEnabled}
                              updateTimeSeriesValue={updateTimeSeriesValue} />
      <EvolutionSeriesSettings isContributor={isContributor}
                               timeSeries={timeSeries}                                
                               basedOnSettings={basedOnSettings}                              
                               basedOnAddKey={basedOnAddKey}
                               basedOnDeleteKey={basedOnDeleteKey}
                               basedOnUpdateSearchKeyText={basedOnUpdateSearchKeyText} 
                               basedOnUpdateSettings={basedOnUpdateSettings}
                               requestRematerialiseWithRange={requestRematerialiseWithRange}
                               setMaterialiseStatusToEnabled={setMaterialiseStatusToEnabled}
                               updateTimeSeriesValue={updateTimeSeriesValue} />                              
      <DerivationSettings referenceData={referenceData}
                          allSchemaNames={allSchemaNames}
                          lookupData={lookupData}
                          timeSeries={timeSeries}
                          updateTimeSeriesValue={updateTimeSeriesValue}
                          searchKeyText={derivationSettings.searchKeyText}
                          selectedKeys={derivationSettings.selectedKeys}
                          updateDerivationSearchKeyText={updateDerivationSearchKeyText}
                          addDerivationSearchKeyText={addDerivationSearchKeyText}
                          moveDerivationKeyUp={moveDerivationKeyUp}
                          moveDerivationKeyDown={moveDerivationKeyDown}
                          deleteDerivationKey={deleteDerivationKey}
                          addDerivationKeys={addDerivationKeys}
                          requestRematerialiseWithRange={requestRematerialiseWithRange}
                          setMaterialiseStatusToEnabled={setMaterialiseStatusToEnabled}
                          isContributor={isContributor} />
      {isDerived && <FunctionSettings referenceData={referenceData}
        derivationSelectedKeys={derivationSettings.selectedKeys}
        inputFocusId={inputFocusId}
        setInputFocus={setInputFocus}
        setInputHasFocus={setInputHasFocus}
        lookupData={lookupData}
        functionSettings={functionSettings}
        timeSeries={timeSeries}
        updateTimeSeriesValue={updateTimeSeriesValue}
        setFunctionsStepStyleToMultiStep={setFunctionsStepStyleToMultiStep}
        setFunctionsType={setFunctionsType}
        addFunctionsStepParameter={addFunctionsStepParameter}
        deleteFunctionsStepParameter={deleteFunctionsStepParameter}
        updateFunctionParameterValue={updateFunctionParameterValue}
        addMultiStepFunction={addMultiStepFunction}
        deleteMultiStepFunction={deleteMultiStepFunction}
        moveMultiStepFunctionUp={moveMultiStepFunctionUp}
        moveMultiStepFunctionDown={moveMultiStepFunctionDown}
        addFunctionKey={addFunctionKey}
        deleteFunctionKey={deleteFunctionKey}
        moveFunctionKeyUp={moveFunctionKeyUp}
        moveFunctionKeyDown={moveFunctionKeyDown}
        addFunctionOutputCategory={addFunctionOutputCategory}
        updateFunctionOutputKey={updateFunctionOutputKey}
        updateFunctionOutputCategoryValue={updateFunctionOutputCategoryValue}
        deleteFunctionOutputCategory={deleteFunctionOutputCategory}
                                      setFunctionDisabled={setFunctionDisabled} />}
      <CategorySettings referenceData={referenceData}
        inputFocusId={inputFocusId}
        setInputFocus={setInputFocus}
        setInputHasFocus={setInputHasFocus}
        lookupData={lookupData}
        categorySettings={categorySettings}
        timeSeries={timeSeries}
        updateTimeSeriesValue={updateTimeSeriesValue}
        addCategory={addCategory}
        updateCategoryValue={updateCategoryValue}
        deleteCategory={deleteCategory}
        deleteAllcategories={deleteAllcategories}
        copyCategoriesCopyFrom={copyCategoriesCopyFrom}
                        getCategoryValues={getCategoryValues} />
    </Fragment>
}