import React from 'react';
import ErrorBoundary from '../../../shared/ErrorBoundary';
import Loading from '../../../shared/Loading';
import ToolboxResults from './ToolboxResults';
import ToolboxToolbar from './ToolboxToolbar';

export default function Toolbox({ lenses, operations, timezones, identityId, isLoading, fromDate, toDate, timezone, lens, operation, shapeName, asAtDate, take, dpCount, 
                                  disableMaterialisation, disableVariants, testUnsavedChanges, inputs, steps, result, updateProperty, toggleMaterialisation, toggleVariants, toggleTestUnsavedChanges, execute }) {
  return (
    <div className='p-3' style={{ height:'100%', overflowY: 'auto', flexGrow: 1 }}>
      <ErrorBoundary>
        <Loading isLoading={isLoading}>
          <div className='row'>
            <div className='mb-2 col-md-12'>
              <ToolboxToolbar identityId={identityId}
                              isLoading={isLoading}
                              lenses={lenses}
                              operations={operations}
                              timezones={timezones}
                              lens={lens}
                              operation={operation}
                              shapeName={shapeName}
                              fromDate={fromDate}
                              toDate={toDate}
                              timezone={timezone}
                              asAtDate={asAtDate}
                              take={take}
                              dpCount={dpCount}
                              disableMaterialisation={disableMaterialisation}
                              disableVariants={disableVariants}
                              testUnsavedChanges={testUnsavedChanges}
                              updateProperty={updateProperty}
                              toggleMaterialisation={toggleMaterialisation}
                              toggleVariants={toggleVariants}
                              toggleTestUnsavedChanges={toggleTestUnsavedChanges}
                              execute={execute} />
            </div>
          </div>
          <div className='row'>
            <div className='mb-2 col-md-12'>
              <ToolboxResults inputs={inputs} 
                              steps={steps} 
                              result={result} />
            </div>
          </div>
        </Loading>
      </ErrorBoundary>
    </div>
  );
}