import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import {
  analysisComparisonModeSetActiveView,
  analysisComparisonModeRemoveAllSelection,
  analysisComparisonModeRemoveSelectedDate,
  analysisComparisonModeApplySelectionToWorkspace,
  analysisUpdateBasketFromComparisonModeSettings2,
  analysisComparisonModeSetRange,
  analysisComparisonModeClearPreselection,
  analysisComparisonModeTogglePreselection,
  analysisComparisonModeAddPreselectionToSelection,
  analysisComparisonModeTogglePreselectionColumn,
  analysisComparisonModeTogglePreselectionRow,
  analysisComparisonModeSetDayActiveDate,
  analysisComparisonModeAggregateAddRangeToSelection,
  analysisComparisonModeAggregateAddPreselectionToSelection,
  analysisComparisonModeAggregateToggleOperation,
  analysisComparisonModeSetIndividualRelativeDate,
  analysisComparisonModeAddIndividualRelativeToSelection,
  analysisComparisonModeSetAggregateRelativeDate,
  analysisComparisonModeAddAggregateRelativeToPreselection,
  analysisComparisonModeRemoveAggregateRelativeFromPreselection,
  analysisComparisonModeAddAggregateRelativeDatesToSelection,
  analysisComparisonModeSetAggregateRelativeFromDate,
  analysisComparisonModeSetAggregateRelativeToDate,
  analysisComparisonModeAddAggregateRelativeRangeToSelection
} from '../../../actions/analysis-comparisonmode-v2';

import {
  analysisRefreshTemplateNameExpressions
} from '../../../actions/analysis';

import { ComparisonsEditor } from './ComparisonsEditor';

export const ComparisonsEditorConnectComponent = connect(
  (state) => ({
    comparisonMode: state.getIn(['analysis', 'workspace', 'comparisonMode']),
    allComparisonSettings: state.getIn(['analysis', 'ui', 'comparisonSettings']),
  }),
  (dispatch) => ({
    applySelection(comparisonMode) {
      dispatch(analysisComparisonModeApplySelectionToWorkspace(comparisonMode));
      dispatch(analysisUpdateBasketFromComparisonModeSettings2());
      dispatch(analysisRefreshTemplateNameExpressions());
    },
    setActiveView(comparisonMode, view) {
      dispatch(analysisComparisonModeSetActiveView(comparisonMode, view));
    },
    removeAllSelection(comparisonMode) {
      dispatch(analysisComparisonModeRemoveAllSelection(comparisonMode));
    },
    removeSelectedDate(comparisonMode, value) {
      dispatch(analysisComparisonModeRemoveSelectedDate(comparisonMode, value));
    },
    setComparisonModeSetRange(comparisonMode, start, end) {
      dispatch(analysisComparisonModeSetRange(comparisonMode, start, end));
    },
    togglePreselection(comparisonMode, value) {
      dispatch(analysisComparisonModeTogglePreselection(comparisonMode, value));
    },
    addPreselectionToSelection(comparisonMode) {
      dispatch(analysisComparisonModeAddPreselectionToSelection(comparisonMode));
    },
    toggleColumn(comparisonMode, column) {
      dispatch(analysisComparisonModeTogglePreselectionColumn(comparisonMode, column));
    },
    toggleRow(comparisonMode, row) {
      dispatch(analysisComparisonModeTogglePreselectionRow(comparisonMode, row));
    },
    clearPreselection(comparisonMode) {
      dispatch(analysisComparisonModeClearPreselection(comparisonMode));
    },
    setDayActiveDate(comparisonMode, date) {
      dispatch(analysisComparisonModeSetDayActiveDate(comparisonMode, date));
    },
    addRangeToSelection(comparisonMode) {
      dispatch(analysisComparisonModeAggregateAddRangeToSelection(comparisonMode));
    },
    addAggregatePreselectionToSelection(comparisonMode) {
      dispatch(analysisComparisonModeAggregateAddPreselectionToSelection(comparisonMode));
    },
    toggleAggregationOperation(comparisonMode, operation, isSelected) {
      dispatch(analysisComparisonModeAggregateToggleOperation(comparisonMode, operation, isSelected));
    },
    setIndividualRelativeDateText(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeSetIndividualRelativeDate(comparisonMode, relativeDate));
    },
    addIndividualRelativeDate(comparisonMode) {
      dispatch(analysisComparisonModeAddIndividualRelativeToSelection(comparisonMode));
    },
    setAggregateRelativeDate(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeSetAggregateRelativeDate(comparisonMode, relativeDate));
    },
    addAggregateRelativeDatesToSelection(comparisonMode) {
      dispatch(analysisComparisonModeAddAggregateRelativeDatesToSelection(comparisonMode));
    },
    setAggregateRelativeFromDate(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeSetAggregateRelativeFromDate(comparisonMode, relativeDate));
    },
    setAggregateRelativeToDate(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeSetAggregateRelativeToDate(comparisonMode, relativeDate));
    },
    addAggregateRelativeRangeToSelection(comparisonMode) {
      dispatch(analysisComparisonModeAddAggregateRelativeRangeToSelection(comparisonMode));
    },
    addAggregateRelativeToPreselection(comparisonMode) {
      dispatch(analysisComparisonModeAddAggregateRelativeToPreselection(comparisonMode));
    },
    removeAggregateRelativeFromPreselection(comparisonMode, relativeDate) {
      dispatch(analysisComparisonModeRemoveAggregateRelativeFromPreselection(comparisonMode, relativeDate));
    }  
  })
)(({ comparisonMode, allComparisonSettings, applySelection, setActiveView, removeAllSelection, removeSelectedDate,
  setComparisonModeSetRange, togglePreselection, addPreselectionToSelection, toggleColumn, toggleRow, clearPreselection, setDayActiveDate,
  addRangeToSelection, toggleAggregationOperation, addAggregatePreselectionToSelection,
  setIndividualRelativeDateText, addIndividualRelativeDate,
  setAggregateRelativeDate, addAggregateRelativeDatesToSelection, setAggregateRelativeFromDate, setAggregateRelativeToDate, addAggregateRelativeRangeToSelection, addAggregateRelativeToPreselection, removeAggregateRelativeFromPreselection}) => {
  const comparisonSettings = toJS(allComparisonSettings)[comparisonMode];
  return (comparisonSettings ? <ComparisonsEditor
    comparisonMode={comparisonMode} comparisonSettings={comparisonSettings}
    applySelection={applySelection}
    removeAllSelection={removeAllSelection} removeSelectedDate={removeSelectedDate}
    setActiveView={setActiveView}
    setComparisonModeSetRange={setComparisonModeSetRange} togglePreselection={togglePreselection} addPreselectionToSelection={addPreselectionToSelection} toggleColumn={toggleColumn} toggleRow={toggleRow} clearPreselection={clearPreselection} setDayActiveDate={setDayActiveDate}
    addRangeToSelection={addRangeToSelection} toggleAggregationOperation={toggleAggregationOperation} addAggregatePreselectionToSelection={addAggregatePreselectionToSelection}
    setIndividualRelativeDateText={setIndividualRelativeDateText} addIndividualRelativeDate={addIndividualRelativeDate}
    setAggregateRelativeDate={setAggregateRelativeDate} addAggregateRelativeDatesToSelection={addAggregateRelativeDatesToSelection} setAggregateRelativeFromDate={setAggregateRelativeFromDate} setAggregateRelativeToDate={setAggregateRelativeToDate} addAggregateRelativeRangeToSelection={addAggregateRelativeRangeToSelection} addAggregateRelativeToPreselection={addAggregateRelativeToPreselection} removeAggregateRelativeFromPreselection={removeAggregateRelativeFromPreselection}
  /> : <Fragment />)
});