import { fromJS } from 'immutable'
import { createKey } from '../utility/timeseries-edit-utility';
import {
  TIMESERIES_DETAILS_BASED_ON_UPDATE_SEARCH_KEY_TEXT,
  TIMESERIES_DETAILS_BASED_ON_ADD_KEY_COMPLETED,
  TIMESERIES_DETAILS_BASED_ON_DELETE_KEY,
  TIMESERIES_DETAILS_BASED_ON_UPDATE_SETTINGS
} from '../actions/timeSeriesDetails-based-on';

export const timeSeriesDetailsBasedOnReducer = {
  [TIMESERIES_DETAILS_BASED_ON_UPDATE_SEARCH_KEY_TEXT](state, action) {
    return state.setIn(['timeSeriesEditor', 'basedOnSettings', 'searchKeyText'], action.value);
  },
  [TIMESERIES_DETAILS_BASED_ON_ADD_KEY_COMPLETED](state, action) {
    if (!action.data)
      return state;

    const selectedIdentity = createKey({
        key: `${action.data.id}`,
        name: action.data.name,
        identityId: `${action.data.id}`,
        sourceTimeZoneId: action.data.sourceTimeZoneId,
        granularity: action.data.granularity,
        type: 'Identity'
      });

      return state.setIn(['timeSeriesEditor', 'basedOnSettings', 'selectedIdentity'], fromJS(selectedIdentity))
                  .setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'evolutionSeriesData', 'baseIdentityId'], selectedIdentity.identityId)
                  .setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'forecastSeriesData', 'baseIdentityId'], selectedIdentity.identityId);
  },
  [TIMESERIES_DETAILS_BASED_ON_DELETE_KEY](state, action) {
      return state.setIn(['timeSeriesEditor', 'basedOnSettings', 'selectedIdentity'], fromJS({}))
                  .setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'evolutionSeriesData', 'baseIdentityId'], -1)
                  .setIn(['timeSeriesEditor', 'timeSeries', 'derivationData', 'forecastSeriesData', 'baseIdentityId'], -1);
  },
  [TIMESERIES_DETAILS_BASED_ON_UPDATE_SETTINGS](state, action) {
    // api uses abs if provided, so be sure to init abs dates if rel  
    if(action.property === 'fromDateMode' && action.value === 'rel') {
      return state.setIn(['timeSeriesEditor', 'basedOnSettings', action.property], action.value)
                  .setIn(['timeSeriesEditor', 'timeSeries', 'derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'asAtWindow', 'absFromDate'], null)
                  .setIn(['timeSeriesEditor', 'timeSeries', 'derivationData','evolutionSeriesData', 'dataPointWindow', 'absFromDate'], null);  
    }
    
    if(action.property === 'toDateMode' && action.value === 'rel') {
      return state.setIn(['timeSeriesEditor', 'basedOnSettings', action.property], action.value)
                  .setIn(['timeSeriesEditor', 'timeSeries', 'derivationData','forecastSeriesData', 'asAt','forecastWindowData', 'asAtWindow', 'absToDate'], null)
                  .setIn(['timeSeriesEditor', 'timeSeries', 'derivationData','evolutionSeriesData', 'dataPointWindow', 'absToDate'], null);   
    }    
    
    return state.setIn(['timeSeriesEditor', 'basedOnSettings', action.property], action.value);
  }
};