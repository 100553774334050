import React from 'react';

export default function AnnotationsToolbar({ referenceData, selectedTimeZone, filterType, filterText, isFilterDirty,
  loadAnnotations, setAnnotationsTimeZone, updateAnnotationsFilter, applyAnnotationsFilter, clearAnnotationsFilter }) {
  const { timezones } = referenceData;

  function onChangeFilterText(e) {
    updateAnnotationsFilter({ filterType, filterText: e.target.value });
  }

  function onChangeFilterType(e){
     updateAnnotationsFilter({ filterType: e.target.value, filterText });
  }

  function onApplyFilter(e){
    e.preventDefault();
    applyAnnotationsFilter();
  }

  return (
    <div className='row no-gutters fota-compact-form'>
      <div className='col-3 mb-0 mr-2'>
        <div className='input-group w-100'>
          <select className='custom-select' value={selectedTimeZone} onChange={e => setAnnotationsTimeZone(e.target.value)}>
            {timezones.map(timezone => <option key={timezone} value={timezone}>{timezone}</option>)}
          </select>
          <div className='input-group-append'>
            <button type='button' className='btn btn-primary' onClick={() => loadAnnotations()}>
              <i className='fas fa-sync-alt fa-fw' /> Refresh
            </button>
          </div>
        </div>
      </div>
      <form className='col-6 mb-0' onSubmit={onApplyFilter}>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <select className='custom-select' onChange={onChangeFilterType} value={filterType}>
              <option value='value'>Text</option>
              <option value='updatedBy'>Updated By</option>
            </select>
          </div>
          <input type='text' placeholder='Filter text' className='form-control'
            onChange={onChangeFilterText}
            value={filterText} />
          <div className='input-group-append'>
            <button className={`btn ${isFilterDirty ? 'btn-primary' : 'btn-outline-primary'}`} type='submit'>
              <i className='fas fa-sync-alt fa-fw' /> Apply Filter
            </button>
            {(!!filterText) && (
              <button className='btn btn-outline-primary' type='button' onClick={clearAnnotationsFilter}>
                Clear Filter
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}