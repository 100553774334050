import React, { Component } from 'react';
import PropTypes from 'prop-types'
import LoadingIcon from '../../shared/LoadingIcon';

export class SearchResultInfo extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    loadInfo: PropTypes.func.isRequired,
    info: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.info) {
      this.props.loadInfo();
    }
  }

  render() {
    const { isLoading, info } = this.props;
    if (isLoading === true)
      return <div>
        <LoadingIcon width='1.25em' /><span className='pl-2'>Loading info...</span>
      </div>

    if (!info) 
      return <span>No information</span>

    const { id,
      sourceId,
      dataType,
      style,
      styleExtended,
      instanceStyle,
      derivationType,
      materialisationStatus,
      granularity,
      unit,
      currency,
      sourceTimeZoneId,
      schemaId,
      standardFunctions,
      customFunctions
    } = info;

    return <table className='table table-sm table-borderless ml-4'>
      <tbody>
        <tr><td><b>Id</b></td><td>{id}</td></tr>
        <tr><td><b>Source Id</b></td><td>{sourceId}</td></tr>
        <tr><td><b>Type</b></td><td>{dataType}</td></tr>
        <tr><td><b>Style</b></td><td>{style}</td></tr>
        <tr><td><b>Style Extended</b></td><td>{styleExtended}</td></tr>
        <tr><td><b>Instance Style</b></td><td>{instanceStyle}</td></tr>
        <tr><td><b>Derivation Type</b></td><td>{derivationType}</td></tr>
        <tr><td><b>Materialisation Status</b></td><td>{materialisationStatus || '-'}</td></tr>
        <tr><td><b>Granularity</b></td><td>{granularity}</td></tr>
        <tr><td><b>Unit</b></td><td>{unit}</td></tr>
        <tr><td><b>Currency</b></td><td>{currency || '-'}</td></tr>
        <tr><td className='text-nowrap'><b>Source Timezone</b></td><td>{sourceTimeZoneId || '-'}</td></tr>
        <tr><td><b>Schema Id</b></td><td>{schemaId}</td></tr>
        {!!(standardFunctions && standardFunctions.length) && (
          <tr>
            <td><b>Standard Functions</b></td>
            <td>
              <ul className='pl-0' style={{ listStyle: 'circle', listStylePosition: 'inside' }}>
                {standardFunctions.map(i => <li key={`${i}`}>{i}</li>)}
              </ul>
            </td>
          </tr>
        )}
        {!!(customFunctions && customFunctions.length) && (
          <tr>
            <td><b>Custom Functions</b></td>
            <td>
              <ul className='pl-0' style={{ listStyle: 'circle', listStylePosition: 'inside' }}>
                {customFunctions.map((i,index) => <li key={`i-${index}`}>{i}</li>)}
              </ul>
            </td>
          </tr>
        )}
      </tbody>
    </table>

  }
}