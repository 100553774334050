import React from 'react';
import Loading from '../../shared/Loading';
import CompositionToolbar from './CompositionToolbar';
import CompositionWithOverridesTable from './CompositionWithOverridesTable';
import CompositionOverridenTable from './CompositionOverridenTable';
import ScenarioToolbar from './ScenarioToolbar';
import ScenarioTable from './ScenarioTable';
import { Allotment } from 'allotment';

export default function ReportsComposition({
  displayMap, lens, lenses, isLoading, isOverridenLoading, rows, rowsWithOverrides, scenarioOverrideMap, scenarioRows, scenarioAlternates, selectedTimeSeries, selectedTimeSeriesId,
  timeZoneId, timeZones, applyOverrides, itemSelected, refresh, setScenarioOverride, toggleExpand, updateRequestProperty, panelGroup1, panelGroup2, resizePanels,
  scenarioCsvIds, setScenarioCsvIds, addScenarioByIds, autoAddScenarios, addScenarioByCsvIds, removeAllScenarios, removeScenarioById, addScenarios, isLoadingScenarios,
  showOnlyScenarios, toggleShowOnlyScenarios, filteredScenarios, selectFilteredScenarios, excludedScenarioCategories
}) {

  const scenarioAlternatesSummary = !!rows && rows.length > 0
    ? ((rows[0] || {}).request || {}).scenarioAlternatesSummary || []
    : [];

  function onResizePanels(name, panels){
    return resizePanels(name, panels.map(s => ({size:s})));
  }

  function mapPanels(panels){
    return panels.map(p => p.size ?? '')
  }

  return <div className='d-flex flex-column h-100' style={{ width: 'calc(100% - 2px)' }}>
      <div>
        <CompositionToolbar applyOverrides={applyOverrides}
                lenses={lenses}
                lens={lens}
                refresh={refresh}
                timeZoneId={timeZoneId}
                timeZones={timeZones}
                scenarioAlternatesSummary={scenarioAlternatesSummary}
                updateProperty={updateRequestProperty}
                filteredScenarios={filteredScenarios}
                selectFilteredScenarios={selectFilteredScenarios}
                showOnlyScenarios={showOnlyScenarios}
                toggleShowOnlyScenarios={toggleShowOnlyScenarios}
                excludedScenarioCategories={excludedScenarioCategories} />
      </div>
      <div className='flex-fill h-100'>
        <Allotment vertical={true} defaultSizes={mapPanels(panelGroup1)} onDragEnd={panels => onResizePanels('panelGroup1', panels)}>
          <Allotment.Pane>
            <Allotment vertical={false} defaultSizes={mapPanels(panelGroup2)} onDragEnd={panels => onResizePanels('panelGroup2', panels)}>
              <Allotment.Pane>
                <div className='px-1 h-100' style={{ overflowY: 'auto', overflowX: 'auto'}}>
                  <CompositionWithOverridesTable displayMap={displayMap}
                    isLoading={isLoading}
                    rows={rows}
                    selectedTimeSeriesId={selectedTimeSeriesId}
                    scenarioOverrideMap={scenarioOverrideMap}
                    setScenarioOverride={setScenarioOverride}
                    toggleExpand={toggleExpand}
                    itemSelected={itemSelected}
                    showOnlyScenarios={showOnlyScenarios}
                    filteredScenarios={filteredScenarios}
                    scenarioAlternates={scenarioAlternates} />
                </div>
              </Allotment.Pane>
              <Allotment.Pane>
                <div className='px-1 h-100' style={{ overflowY: 'auto', overflowX: 'auto' }}>
                  <CompositionOverridenTable displayMap={displayMap}
                    isLoading={isOverridenLoading}
                    rows={rowsWithOverrides}
                    toggleExpand={toggleExpand}
                    showOnlyScenarios={showOnlyScenarios}
                    filteredScenarios={filteredScenarios} />
                </div>
              </Allotment.Pane>
            </Allotment>  
          </Allotment.Pane>
          <Allotment.Pane>
            <div className='h-100 w-100 px-1 position-relative' style={{ borderTop: '3px solid #ccc' }}>
              <Loading isLoading={isLoadingScenarios} message='Loading...'>
                <>
                  <ScenarioToolbar
                    scenarioCsvIds={scenarioCsvIds}
                    setScenarioCsvIds={setScenarioCsvIds}
                    addScenarioByCsvIds={addScenarioByCsvIds}
                    addScenarioByIds={addScenarioByIds}
                    autoAddScenarios={autoAddScenarios}
                    addScenarios={addScenarios}
                    selectedTimeSeries={selectedTimeSeries} />
                  <ScenarioTable rows={scenarioRows}
                    selectedTimeSeriesId={selectedTimeSeriesId}
                    removeScenarioById={removeScenarioById}
                    removeAllScenarios={removeAllScenarios} />
                </>
              </Loading>
            </div>          
          </Allotment.Pane>
      </Allotment>
      </div>
    </div>
}