import { createReducer } from '../utility/redux-utility';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from "redux-first-history";
import {
  HOMEPAGE_INITIALISED,
  HOMEPAGE_LOAD_STARTED,
  HOMEPAGE_LOAD_STOPPED,
  HOMEPAGE_LOAD_COMPLETE,
  HOMEPAGE_LOAD_IMAGES_STARTED,
  HOMEPAGE_LOAD_IMAGES_STOPPED,
  HOMEPAGE_LOAD_IMAGES_COMPLETE,
  HOMEPAGE_UPLOAD_IMAGE_STARTED,
  HOMEPAGE_UPLOAD_IMAGE_STOPPED,
  HOMEPAGE_UPLOAD_IMAGE_COMPLETE,
  HOMEPAGE_SET_DISPLAY_MODE,
  HOMEPAGE_SET_ORDER_BY,
  HOMEPAGE_SET_SELECTED_IMAGE,
  HOMEPAGE_SET_ITEM_FAVOURITE,
  HOMEPAGE_TOGGLE_ONLY_FAVOURITES,
  HOMEPAGE_SET_SEARCH_TEXT,
  HOMEPAGE_ENABLE_SEARCH_MODE,
  HOMEPAGE_CLEAR_SEARCH,
  HOMEPAGE_SET_LOCATION
} from '../actions/homepage';
import defaultImageUrls from '../assets/homepage-dataurls.json';
import { WORKSPACES_IMAGE_URL } from '../config';
import { USER_SETTINGS_LOAD_COMPLETE } from '../actions/userSettings';

const mapImageUrls = (items = []) => items.map(i => ({ ...i, imageUrl: i.imageId ? `${WORKSPACES_IMAGE_URL}/${i.imageId}` : defaultImageUrls[i.type] }));
const mapFavourites = (items = [], favourites = []) => items.map(i => ({ ...i, isFavourite: favourites.includes(i.id) }));
const sortItems = (items = []) => [
  ...items.filter(i => i.type === 'Folder'),
  ...items.filter(i => i.type !== 'Folder')
];

export const homepage = createReducer(null, {
  [HOMEPAGE_INITIALISED](state, action) {
    return state.set('isInitialised', true);
  },
  [HOMEPAGE_LOAD_STARTED](state, action) {
    return state.set('isLoading', true)
                .set('folder', '')
                .set('subFolder', '')
                .set('items', fromJS([]));
  },
  [HOMEPAGE_LOAD_STOPPED](state, action) {
    const { folder, subFolder } = action.data || {};

    return state.set('isLoading', false)
                .set('isInitialised', true)
                .set('folder', folder)
                .set('subFolder', subFolder)
                .set('items', fromJS([]));
  },
  [HOMEPAGE_LOAD_COMPLETE](state, action) {
    if (!action.data)
      return state.set('isLoading', false)
                  .set('isInitialised', true);

    let { favourites, items, scope, folder, subFolder } = action.data || {};

    if (items) {
      items = mapImageUrls(items);
      items = mapFavourites(items, favourites);
      items = sortItems(items);

      if (!scope)
        items = items.map(i => i.type === 'Folder' && i.name === 'Private' ? {
          ...i, path: 'Private'
        } : i);
    }

    return state.set('isLoading', false)
                .set('isInitialised', true)
                .set('folder', folder)
                .set('subFolder', subFolder)
                .set('items', fromJS(items));
  },
  [HOMEPAGE_LOAD_IMAGES_STARTED](state, action) {
    return state.set('isLoadingImages', true)
                .set('images', fromJS([]));
  },
  [HOMEPAGE_LOAD_IMAGES_STOPPED](state, action) {
    return state.set('isLoadingImages', false);
  },
  [HOMEPAGE_LOAD_IMAGES_COMPLETE](state, action) {
    return state.set('isLoadingImages', false)
                .set('images', fromJS(action.data));
  },
  [HOMEPAGE_UPLOAD_IMAGE_STARTED](state, action) {
    return state.set('isUploadingImage', true);
  },
  [HOMEPAGE_UPLOAD_IMAGE_STOPPED](state, action) {
    return state.set('isUploadingImage', false);
  },
  [HOMEPAGE_UPLOAD_IMAGE_COMPLETE](state, action) {
    return state.set('isUploadingImage', false);
  },
  [HOMEPAGE_SET_SELECTED_IMAGE](state, action) {
    const image = state.get('images').find(i => `${i.get('id')}` === `${action.id}`);

    return image
      ? state.set('selectedImage', image)
      : state.set('selectedImage', fromJS({
        id: 0,
        name: '',
        dataUrl: ''
      }));
  },
  [HOMEPAGE_SET_ITEM_FAVOURITE](state, action) {
    const items = state.get('items');
    const index = items.findIndex(i => i.get('id') === action.workspaceId);

    return state.setIn(['items', index, 'isFavourite'], action.isFavourite);
  },
  [HOMEPAGE_TOGGLE_ONLY_FAVOURITES](state, action) {
    const onlyFavourites = state.get('onlyFavourites');

    return state.set('onlyFavourites', !onlyFavourites);
  },
  [HOMEPAGE_SET_DISPLAY_MODE](state, action) {
    return state.set('displayMode', action.value);
  },
  [HOMEPAGE_SET_ORDER_BY](state, action) {
    return state.set('orderBy', action.name)
                .set('orderByDirection', action.direction);
  },
  [HOMEPAGE_SET_SEARCH_TEXT](state, action) {
    return state.set('searchText', action.value);
  },
  [HOMEPAGE_ENABLE_SEARCH_MODE](state, action) {
    const isSearchMode = state.get('isSearchMode');

    if (isSearchMode) return state;

    const displayMode = state.get('displayMode');

    return state.set('preSearchDisplayMode', displayMode)
                .set('displayMode', 'contents')
                .set('isSearchMode', true);
  },
  [HOMEPAGE_CLEAR_SEARCH](state, action) {
    const isSearchMode = state.get('isSearchMode');

    if (!isSearchMode) return state;

    const preSearchDisplayMode = state.get('preSearchDisplayMode');

    return state.set('displayMode', preSearchDisplayMode)
                .set('preSearchDisplayMode', '')
                .set('isSearchMode', false)
                .set('searchText', '');
  },
  [HOMEPAGE_SET_LOCATION](state, action) {
    let path = action.path;
    let scope = action.scope;
    return state.set('scope', scope)
                .set('path', path)
                .set('isInitialised', false)
  },
  [USER_SETTINGS_LOAD_COMPLETE](state, action) {
    if (!action.data) 
      return state;

    const scope = state.get('scope') ?? '';
    const path = state.get('path') ?? '';
    if (scope === '' && path === '') {
      const { data : { homePage } = {} } = action;
      if (homePage) {
        if (homePage.onlyFavourites !== undefined)
          state = state.set('onlyFavourites', homePage.onlyFavourites);

        if (homePage.displayMode !== undefined)
          state = state.set('displayMode', homePage.displayMode);
      }
    }

    return state;
  },
  [LOCATION_CHANGE](state, action) {
    const { location } = action.payload;
    let { pathname = '' } = location || {};
    pathname = decodeURIComponent(pathname);

    if (pathname !== '/' && pathname.indexOf('/home') < 0) return state;

    const { groups: result } = { ...pathname.match(/(?:\/?home\/?)(?<scope>private\/?)?(?<path>.*)/i) };
    let { scope = '', path = '' } = result || {};

    scope = scope.replace(/^\/+|\/+$/g, '');
    path = path.replace(/^\/+|\/+$/g, '');

    return state.set('scope', scope)
                 .set('path', path)
                 .set('isInitialised', false);
  }
});
