import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Loading from '../../../shared/Loading';
import AdjustmentRangeEditorToolbar from './AdjustmentRangeEditorToolbar';
import AdjustmentRangeEditorTable from './AdjustmentRangeEditorTable';
import AdjustmentRangeEditorDataPointTable from './AdjustmentRangeEditorDataPointTable';

export default class AdjustmentRangeEditor extends Component {
  static propTypes = {
    timeSeriesId: PropTypes.number.isRequired,
    timeSeriesGranularityType: PropTypes.string.isRequired,
    isWizard: PropTypes.bool.isRequired,
    isInitialised: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isGenerating: PropTypes.bool.isRequired,
    operations: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired,
    isDateOnly: PropTypes.bool.isRequired,
    periodStart: PropTypes.instanceOf(moment),
    periodEnd: PropTypes.instanceOf(moment),
    dataPoints: PropTypes.array.isRequired,
    comments: PropTypes.array.isRequired,
    ranges: PropTypes.array.isRequired,
    rangeEdit: PropTypes.object.isRequired,
    firstSelectedRow: PropTypes.number,
    initialise: PropTypes.func.isRequired,
    uninitialise: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    updateToolbarProperty: PropTypes.func.isRequired,
    addRange: PropTypes.func.isRequired,
    cancelRange: PropTypes.func.isRequired,
    saveRange: PropTypes.func.isRequired,
    deleteRange: PropTypes.func.isRequired,
    selectRange: PropTypes.func.isRequired,
    editRangeProperty: PropTypes.func.isRequired,
    editRangeReason: PropTypes.func.isRequired,
    editRangeItems: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    if (!this.props.timeSeriesId)
      this.props.uninitialise();
    else if (prevProps.timeSeriesId !== this.props.timeSeriesId
      || prevProps.timeSeriesGranularityType !== this.props.timeSeriesGranularityType)
      this.props.initialise(this.props.timeSeriesId, this.props.timeSeriesGranularityType);
  }

  componentDidMount() {
    this.props.initialise(this.props.timeSeriesId, this.props.timeSeriesGranularityType);
  }

  render() {
    const isLoading = this.props.isLoading || this.props.isSaving || this.props.isGenerating;

    return (
      <Loading isLoading={isLoading}>
        <div className='w-100 h-100 p-1' style={{ overflowX: 'auto', flexGrow: 1 }}>
          <div className='w-100'>
            <AdjustmentRangeEditorToolbar
              isDateOnly={this.props.isDateOnly}
              periodStart={this.props.periodStart}
              periodEnd={this.props.periodEnd}
              refresh={this.props.refresh}
              updateToolbarProperty={this.props.updateToolbarProperty}
              addRange={this.props.addRange}
              isWizard={this.props.isWizard} />
          </div>
          <div className='w-100 mt-0' style={{ display: 'flex', flexFlow: 'row nowrap' }}>
            <div style={{ flex: this.props.isDateOnly ? '0 0 804px' :  '0 0 864px' }}>
              <h5 className='text-center border-top p-1 m-0'>Ranges</h5>
              <div className='sticky-table' style={{ height: 'calc(100vh - 205px)', overflowY: 'auto', overflowX: 'hidden' }}>
                <AdjustmentRangeEditorTable
                  operations={this.props.operations}
                  types={this.props.types}
                  isDateOnly={this.props.isDateOnly}
                  ranges={this.props.ranges}
                  rangeEdit={this.props.rangeEdit}
                  cancelRange={this.props.cancelRange}
                  saveRange={this.props.saveRange}
                  deleteRange={this.props.deleteRange}
                  selectRange={this.props.selectRange}
                  editRangeProperty={this.props.editRangeProperty}
                  editRangeReason={this.props.editRangeReason}
                  isWizard={this.props.isWizard} />
              </div>
            </div>
            <div style={{ flex: this.props.isDateOnly ? '0 0 572px' :  '0 0 612px' }}>
              <h5 className='text-center border-top p-1 m-0'>Data Points</h5>
              <div style={{ height: 'calc(100vh - 205px)', overflow: 'hidden' }}>
                <AdjustmentRangeEditorDataPointTable
                  firstSelectedRow={this.props.firstSelectedRow}
                  operations={this.props.operations}
                  types={this.props.types}
                  isDateOnly={this.props.isDateOnly}
                  dataPoints={this.props.dataPoints}
                  comments={this.props.comments}
                  editRangeItems={this.props.editRangeItems}
                  isWizard={this.props.isWizard} />
              </div>
            </div>
          </div>
        </div>
      </Loading>
    );
  }
}