import { fromJS } from 'immutable'
import { createReducer } from '../utility/redux-utility';
import {
  SET_COLLAPSED_STATE,
  RESET_COLLAPSED_STATES,
  SHOW_IDENTITY_MENU,
  HIDE_IDENTITY_MENU
} from '../actions/sharedComponents';

export const sharedComponents = createReducer(null, {
  [SET_COLLAPSED_STATE](state, action) {
    const value = action.value;

    return state.setIn(Array.isArray(action.key)
      ? ['collapsePanel', ...action.key]
      : ['collapsePanel', action.key], typeof value === 'object' ? fromJS(value) : value);
  },
  [RESET_COLLAPSED_STATES](state, action) {
    return state.setIn(['collapsePanel'], {});
  },
  [SHOW_IDENTITY_MENU](state, action) {
    const { id, position } = action.data;

    return state.setIn(['identityMenu', 'id'], id)
                .setIn(['identityMenu', 'position'], fromJS(position))
                .setIn(['identityMenu', 'isVisible'], true);
  },
  [HIDE_IDENTITY_MENU](state, action) {
    return state.setIn(['identityMenu', 'id'], 0)
                .setIn(['identityMenu', 'position'], fromJS({}))
                .setIn(['identityMenu', 'isVisible'], false);
  }
});