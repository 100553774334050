import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../shared/Loading';
import FormattedDateTime from '../../shared/FormattedDateTime';
import {
  actionsEditorRefresh,
  actionsRunScheduledAction,
  actionsSetFilter,
  actionsEditorSetOnlyShowMyActions,
  actionsEditorSetSelectedTab
} from '../../../actions/actionsEditor';
import { toJS } from '../../../utility/immutable-utility';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { TabContainer } from '../../shared/TabContainer';
import { ModalDialogButton } from '../../shared/ModalDialogButton';
import JsonEditor from '../../shared/JsonEditor';
import LoadingIcon from '../../shared/LoadingIcon';
import PathNavLink from '../../shared/PathNavLink';

class ActionsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.refreshActionsList();
  }

  onRunScheduledAction(action) {
    this.props.runScheduledAction(action);
  }

  isScheduledActionRunning(id) {
    const isRunning = this.props.scheduledRuns.getIn([id, 'isRunning']);
    return isRunning === true;
  }

  getRunResponse(id) {
    if (this.props.scheduledRuns.getIn([id])) {
      return {
        data: toJS(this.props.scheduledRuns.getIn([id, 'data'])),
        error: toJS(this.props.scheduledRuns.getIn([id, 'error']))
      }
    }
  }

  render() {
    return <Loading isLoading={this.props.isLoading}>
      <main className='d-flex flex-column h-100'>
        <div className='d-flex flex-row justify-content-between align-items-center p-2 '>
          <div className='d-flex'>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <div className='input-group-text'>Filter</div>
              </div>
              <input placeholder='Action filter' type='text' className='form-control' onChange={e => this.props.setFilter(e.target.value)} value={this.props.filter} />
            </div>

            <div className='form-check d-inline-block ml-3 mt-2'>
              <input className='form-check-input' type='checkbox' value='none' name='show-my-actions' id='show-my-actions'
                checked={this.props.onlyShowMyActions}
                onChange={e => this.props.setOnlyShowMyActions(e.target.checked)} />
              <label className='form-check-label text-nowrap ' htmlFor='show-my-actions'>
                Show just my actions
              </label>
            </div>

            <button type='button' className='btn btn-primary text-nowrap ml-2' onClick={this.props.refreshActionsList}>
              <i className='fas fa-sync-alt fa-fw' />
              <span className='ml-2'>Refresh</span>
            </button>
          </div>
        </div>
        <div className='flex-fill h-100'>
          <TabContainer selectedTabIndex={this.props.selectedTabIndex} onSelectedTabIndexChanged={this.props.selectedTabIndexChanged}>
            <div header='Inline Actions'>
              <div className='m-2'>
                <PathNavLink className='btn btn-primary' to={`/actions/inline/new`}>
                  <span className='text-nowrap'>New Inline Action</span>
                </PathNavLink>
              </div>
              <Table stickyHeader className='table table-sm' >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Operation</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell className='text-nowrap'>Last Updated By</TableCell>
                    <TableCell className='text-nowrap'>Last Updated (UTC)</TableCell>
                    <TableCell className='w-100'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.inlineDataPointsActions.map(row => <TableRow key={`idp-${row.id}`}>
                    <TableCell>
                      <PathNavLink className='btn btn-sm pb-0 m-0 btn-info' to={`/actions/inline/${row.id}/edit`}>Edit</PathNavLink>
                    </TableCell>
                    <TableCell className='text-nowrap text-center'><input type='checkbox' className='m-1' checked={!!row.isEnabled} readOnly='readOnly' /></TableCell>
                    <TableCell className='text-nowrap'>{row.name}</TableCell>
                    <TableCell className='text-nowrap'>{row.operationType}</TableCell>
                    <TableCell className='text-nowrap'>{row.owner}</TableCell>
                    <TableCell className='text-nowrap'>{row.updatedBy}</TableCell>
                    <TableCell className='text-nowrap'><FormattedDateTime>{row.updatedUtc}</FormattedDateTime></TableCell>
                    <TableCell className='w-100'></TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </div>
            <div header='Scheduled Actions'>
              <div className='m-2'>
                <PathNavLink className='btn btn-primary' to={`/actions/scheduled/new`}>
                  <span className='text-nowrap'>New Scheduled Action</span>
                </PathNavLink>
              </div>
              <Table stickyHeader className='table table-sm' >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ minWidth: '40px' }}></TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>CronSpec</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell className='text-nowrap'>Last Updated By</TableCell>
                    <TableCell className='text-nowrap'>Last Updated (UTC)</TableCell>
                    <TableCell className='w-100'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.scheduledActions.map(row => {
                    const isRunning = this.isScheduledActionRunning(row.id);
                    const runResponse = this.getRunResponse(row.id);
                    return <TableRow key={`idp-${row.id}`}>
                      <TableCell>
                        <PathNavLink className='btn btn-sm pb-0 m-0 btn-info' to={`/actions/scheduled/${row.id}/edit`}>Edit</PathNavLink>
                      </TableCell>
                      <TableCell className='text-nowrap'><button type='button' disabled={isRunning} className={`btn btn-sm ${isRunning ? 'btn-warning' : 'btn-secondary'} pb-0 m-0`} onClick={() => this.onRunScheduledAction(row)}>Run</button></TableCell>
                      <TableCell className='text-nowrap'>{runResponse && <ModalDialogButton buttonClassName='btn btn-sm btn-outline-secondary pb-0 m-0' buttonContent={<><i className='fas fa-eye'></i></>}>
                        {runResponse && <div className='modal-content'>
                          <div className='modal-header'>
                            <h5 className='modal-title'>{row.name}</h5>
                            <button type='button' className='close' data-dismiss>&times;</button>
                          </div>
                          <div className='modal-body'>
                            {isRunning && <LoadingIcon width='2.5em' height='2.5em' />}
                            {runResponse.error && <>
                              <div className='alert alert-warning'>
                                <label className='font-weight-bold'>Error</label>
                              </div>
                              <div style={{ height: '60vh', overflowY: 'auto' }}>
                                <JsonEditor data={runResponse.error} mode='code' />
                              </div>
                            </>}
                            {runResponse.data && <>
                              <div className='alert alert-info'>
                                <label className='font-weight-bold'>Info</label>
                              </div>
                              <div style={{ height: '60vh', overflowY: 'auto' }}>
                                <JsonEditor data={runResponse.data} mode='code' />
                              </div>
                            </>}
                          </div>
                          <form className='modal-footer' >
                            <button type='button' className='btn btn-secondary' data-dismiss>Close</button>
                          </form>
                        </div>}
                      </ModalDialogButton>}</TableCell>
                      <TableCell className='text-nowrap text-center'><input type='checkbox' className='m-1' checked={!!row.isEnabled} readOnly='readOnly' /></TableCell>
                      <TableCell className='text-nowrap'>{row.name}</TableCell>
                      <TableCell className='text-nowrap'>{row.actionType}</TableCell>
                      <TableCell className='text-nowrap'>{row.cronSpec}</TableCell>
                      <TableCell className='text-nowrap'>{row.owner}</TableCell>
                      <TableCell className='text-nowrap'>{row.updatedBy}</TableCell>
                      <TableCell className='text-nowrap'><FormattedDateTime>{row.updatedUtc}</FormattedDateTime></TableCell>
                      <TableCell className='w-100'></TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </div>
            <div header='Triggered Actions'>
              <div className='m-2'>
                <PathNavLink className='btn btn-primary' to={`/actions/triggered/new`}>
                  <span className='text-nowrap'>New Triggered Action</span>
                </PathNavLink>
              </div>
              <Table stickyHeader className='table table-sm' >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell className='text-nowrap'>Last Updated By</TableCell>
                    <TableCell className='text-nowrap'>Last Updated (UTC)</TableCell>
                    <TableCell className='w-100'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.triggeredActions.map(row => <TableRow key={`idp-${row.id}`}>
                    <TableCell>
                      <PathNavLink className='btn btn-sm pb-0 m-0 btn-info' to={`/actions/triggered/${row.id}/edit`}>Edit</PathNavLink>
                    </TableCell>
                    <TableCell className='text-nowrap text-center'><input type='checkbox' className='m-1' checked={!!row.isEnabled} readOnly='readOnly' /></TableCell>
                    <TableCell className='text-nowrap'>{row.name}</TableCell>
                    <TableCell className='text-nowrap'>{`${row.eventName} ${row.eventType ? (row.eventType === 'Unset' ? 'Any' : row.eventType) : ''} ${row.source ? row.source : ''}`}</TableCell>
                    <TableCell className='text-nowrap'>{row.actionType}</TableCell>
                    <TableCell className='text-nowrap'>{row.owner}</TableCell>
                    <TableCell className='text-nowrap'>{row.updatedBy}</TableCell>
                    <TableCell className='text-nowrap'><FormattedDateTime>{row.updatedUtc}</FormattedDateTime></TableCell>
                    <TableCell className='w-100'></TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </div>
          </TabContainer>
        </div>
      </main>
    </Loading>
  }
}

export const ActionsEditorConnected = connect(
  (state) => ({
    isLoading: state.getIn(['actionsEditor', 'isLoading']),
    inlineDataPointsActions: state.getIn(['actionsEditor', 'collections', 'inlineDataPointsActions']),
    scheduledActions: state.getIn(['actionsEditor', 'collections', 'scheduledActions']),
    triggeredActions: state.getIn(['actionsEditor', 'collections', 'triggeredActions']),
    scheduledRuns: state.getIn(['actionsEditor', 'scheduledRuns']),
    filter: state.getIn(['actionsEditor', 'filter']),
    onlyShowMyActions: state.getIn(['actionsEditor', 'onlyShowMyActions']),
    selectedTabIndex: state.getIn(['actionsEditor', 'selectedTabIndex'])
  }),
  (dispatch) => ({
    refreshActionsList() {
      dispatch(actionsEditorRefresh());
    },
    runScheduledAction(action) {
      dispatch(actionsRunScheduledAction(action));
    },
    setFilter(text) {
      dispatch(actionsSetFilter(text));
    },
    selectedTabIndexChanged(index) {
      dispatch(actionsEditorSetSelectedTab(index));
    },
    setOnlyShowMyActions(show) {
      dispatch(actionsEditorSetOnlyShowMyActions(show));
    }
  })
)(({ isLoading, refreshActionsList,
  setOnlyShowMyActions, onlyShowMyActions,
  filter, setFilter,
  selectedTabIndex, selectedTabIndexChanged,
  inlineDataPointsActions, scheduledActions, triggeredActions, scheduledRuns, runScheduledAction }) => {
  const _inlineDataPointsActions = toJS(inlineDataPointsActions) ?? [];
  const _scheduledActions = toJS(scheduledActions) ?? [];
  const _triggeredActions = toJS(triggeredActions) ?? [];
  return <ActionsEditor
    isLoading={isLoading}
    refreshActionsList={refreshActionsList}
    setOnlyShowMyActions={setOnlyShowMyActions}
    onlyShowMyActions={onlyShowMyActions}
    selectedTabIndex={selectedTabIndex}
    selectedTabIndexChanged={selectedTabIndexChanged}
    filter={filter}
    setFilter={setFilter}
    runScheduledAction={runScheduledAction}
    inlineDataPointsActions={_inlineDataPointsActions}
    scheduledActions={_scheduledActions}
    scheduledRuns={scheduledRuns}
    triggeredActions={_triggeredActions} />
});