import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  analysisDynamicWorkspaceCategoriesSearch,
  analysisDynamicWorkspaceDynamicRowsSearch,
  analysisDynamicWorkspaceEditorToggleExclusionCategory,
  analysisDynamicWorkspaceSave,
  analysisDynamicWorkspaceDelete,
  analysisDynamicWorkspaceEditorSetAlias,
  analysisDynamicWorkspaceEditorSetCategory,
  analysisDynamicWorkspaceEditorSetStrictValue,
  analysisDynamicWorkspaceEditorSetFilterValue,
  analysisDynamicWorkspaceEditorRebuildExclusions,
  analysisDynamicWorkspaceEditorSetIsMultiSelect,
  analysisDynamicWorkspaceEditorSetMultiSelectValue,
  analysisDynamicWorkspaceEditorDeleteDynamicFilter,
  analysisDynamicWorkspaceEditorAddDynamicFilter,
  analysisDynamicWorkspaceEditorDynamicWorkspaceDesignChanged,
  analysisDynamicWorkspaceEditorResetPreviousDynamicKeys
} from '../../../../actions/analysis-dynamic-workspace-editor';
import { DynamicWorkspaceEditor } from './DynamicWorkspaceEditor';

export const DynamicWorkspaceEditorConnected = connect(
  (state) => ({
    isSearchDirty: state.getIn(['analysisCrossSchemaSearch', 'dynamicWorkspaceDesign', 'criteria', 'isDirty']),
    results: state.getIn(['analysisCrossSchemaSearch', 'dynamicWorkspaceDesign', 'results', 'data']),
    dynamicWorkspaceDesign: state.getIn(['analysis', 'dynamicWorkspaceDesign']),
  }),
  (dispatch) => ({
    categoriesSearch() {
      dispatch(analysisDynamicWorkspaceCategoriesSearch(false));
    },
    dynamicRowsSearch() {
      dispatch(analysisDynamicWorkspaceDynamicRowsSearch());
    },
    saveDynamicWorkspace() {
      dispatch(analysisDynamicWorkspaceSave());
    },
    deleteDynamicWorkspace() {
      dispatch(analysisDynamicWorkspaceDelete());
    },
    toggleExclusionCategory(categories) {
      dispatch(analysisDynamicWorkspaceEditorToggleExclusionCategory(categories));
    },
    rebuildExclusions() {
      dispatch(analysisDynamicWorkspaceEditorRebuildExclusions());
    },
    addDynamicFilter(index) {
      dispatch(analysisDynamicWorkspaceEditorAddDynamicFilter());
    },
    deleteDynamicFilter(index) {
      dispatch(analysisDynamicWorkspaceEditorDeleteDynamicFilter(index));
    },
    setAlias(index, value) {
      dispatch(analysisDynamicWorkspaceEditorSetAlias(index, value));
    },
    setCategory(index, value) {
      dispatch(analysisDynamicWorkspaceEditorSetCategory(index, value));
    },
    setSelectedValue(index, value) {
      dispatch(analysisDynamicWorkspaceEditorSetFilterValue(index, value));
    },
    setIsStrictValue(index, value) {
      dispatch(analysisDynamicWorkspaceEditorSetStrictValue(index, value, undefined));    
    },
    setStrictValue(index, value) {
      dispatch(analysisDynamicWorkspaceEditorSetStrictValue(index, true, value));    
    },
    setIsMultiSelect(index, value) {
      dispatch(analysisDynamicWorkspaceEditorSetIsMultiSelect(index, value));
    },
    setMultiSelectValue(index, value) {
      dispatch(analysisDynamicWorkspaceEditorSetMultiSelectValue(index, value));
    },
    refreshRows(){
      dispatch(analysisDynamicWorkspaceEditorResetPreviousDynamicKeys());
      dispatch(analysisDynamicWorkspaceDynamicRowsSearch(false));
    },
    dynamicWorkspaceDesignChanged(){
      dispatch(analysisDynamicWorkspaceEditorDynamicWorkspaceDesignChanged());
    }
  })
)(({ onComplete, dynamicWorkspaceDesignChanged, isSearchDirty, categoriesSearch, dynamicRowsSearch, saveDynamicWorkspace, deleteDynamicWorkspace, dynamicWorkspaceDesign,
  addDynamicFilter, deleteDynamicFilter,
  toggleExclusionCategory,
  setAlias, setCategory, setSelectedValue, setStrictValue, setIsStrictValue,
  rebuildExclusions,
  setIsMultiSelect, setMultiSelectValue, refreshRows, results
}) => {
  useEffect(() => {
    if (isSearchDirty)
      dynamicWorkspaceDesignChanged();

  }, [isSearchDirty, dynamicWorkspaceDesignChanged]);

  function onCancel(e) {
    onComplete();
  }

  function onSave(e) {
    saveDynamicWorkspace();
    onComplete();
  }

  function onDelete() {
    deleteDynamicWorkspace();
    onComplete();
  }

  const _dynamicWorkspaceDesign = dynamicWorkspaceDesign ? dynamicWorkspaceDesign.toJS() : {};
  const {
    dynamicWorkspace,
    dynamicFilters = [],
    searchResultCategories = [],
    isBusy = false
  } = _dynamicWorkspaceDesign;

  const {
    previousDynamicKeys = [],
    substitutionKeyExclusionCategories = []
  } = dynamicWorkspace;

  return <DynamicWorkspaceEditor
    dynamicWorkspaceKey={dynamicWorkspace.key}
    onSave={onSave}
    onCancel={onCancel}
    onDelete={onDelete}
    addDynamicFilter={addDynamicFilter}
    deleteDynamicFilter={deleteDynamicFilter}
    isBusy={isBusy}
    isDirty={_dynamicWorkspaceDesign.isDirty}
    categoriesSearch={categoriesSearch}
    dynamicRowsSearch={dynamicRowsSearch}
    toggleExclusionCategory={toggleExclusionCategory}
    substitutionKeyExclusionCategories={substitutionKeyExclusionCategories}
    previousDynamicKeys={previousDynamicKeys}
    setAlias={setAlias}
    dynamicFilters={dynamicFilters}
    setCategory={setCategory}
    categoryOptions={searchResultCategories}
    setSelectedValue={setSelectedValue}
    setIsStrictValue={setIsStrictValue}
    setStrictValue={setStrictValue}
    rebuildExclusions={rebuildExclusions}
    setIsMultiSelect={setIsMultiSelect}
    setMultiSelectValue={setMultiSelectValue}
    refreshRows={refreshRows}
    previewResultsCount={results ? results.size : 0}
  />
});