import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_SEARCH_API_URL } from '../config';
import moment from 'moment';
import qs from 'querystring';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const TIMESERIES_SEARCH_STARTED2 = 'TIMESERIES_SEARCH_STARTED2';
export const timeseriesSearchStarted2 = createAction(TIMESERIES_SEARCH_STARTED2, 'stateKey');

export const timeseriesSearch2 = (stateKey) => (dispatch, getState) => {
  const state = getState();
  const criteria = state.getIn(['timeseriesSearch2', stateKey, 'criteria']);
  const query = criteria.get('query');
  const customFilter = criteria.get('customFilter');
  const orderBy = criteria.get('orderBy');
  const orderByDirection = criteria.get('orderByDirection');
  const page = criteria.get('page');
  const pageSize = criteria.get('pageSize');
  const facets = criteria.get('facets') && criteria.get('facets').toArray();
  const filters = criteria.get('filters') && criteria.get('filters').map(f => {
    const name = f.get('name'),
      value = f.get('value'),
      isNumber = !isNaN(value),
      dateTime = !isNumber ? moment.utc(value, moment.ISO_8601, true) : null;

    if (dateTime && dateTime.isValid()) return `${name} eq ${dateTime.format()}`;
    if (isNumber) return `${name} eq ${value}`;

    return `${name} eq '${value}'`;
  }).toArray();

  const top = pageSize || 100;
  const skip = page && page > 1 ? (page * top) - top : 0;

  // Azure search skip limit
  if (skip > 100000) return dispatch(logInfoNotification('Paging limit reached, please refine your search.'));

  let params = {
    query: query || '*',
    customFilter: customFilter,
    //staleMonthLimit: staleMonthLimit,
    //onlyFavourites: onlyFavourites,
    filters: filters,
    facets: facets,
    orderBy: `${orderBy || 'name'} ${orderByDirection || 'asc'}`,
    top: top,
    skip: skip
  };

  for (let i in params) if (params[i] === undefined) delete params[i];

  dispatch(timeseriesSearchStarted2(stateKey));

  authFetch(`${ANALYSIS_SEARCH_API_URL}/timeseriessearch?${qs.stringify(params)}`)
    .then(response => response.json())
    .then(data => {
      dispatch(timeseriesSearchComplete2(stateKey, data));
    })
    .catch(error => {
      dispatch(timeseriesSearchComplete2(stateKey));
      dispatch(logErrorNotification(error));
    });
};

export const TIMESERIES_SEARCH_COMPLETE2 = 'TIMESERIES_SEARCH_COMPLETE2';
export const timeseriesSearchComplete2 = createAction(TIMESERIES_SEARCH_COMPLETE2, 'stateKey', 'data');

export const UPDATE_SEARCH_PARAM2 = 'UPDATE_SEARCH_PARAM2';
export const updateSearchParam2 = createAction(UPDATE_SEARCH_PARAM2, 'stateKey', 'value', 'convertEmptyToNull');