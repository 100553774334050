import moment from 'moment';
import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import {
  dashboardTileRefreshBegin,
  dashboardTileRefreshComplete,
  dashboardTileRefreshError,
  fetchWorkspaceAsync,
  isWorkspaceLoaded
} from './dashboard-tiles';
import { buildGetReportsNodesRequest, displayKeyParseRoot, mapScenarioOverrides } from '../mapper/reportMapper';
import {
  ANALYSIS_REPORT_API_ROOT_URL, REPORTING_API_ROOT_URL,
} from '../config';
import {
  logErrorNotification
} from './log';
import { toJS } from '../utility/immutable-utility';
import { getOnDemandDisplayMapKeys } from '../utility/reportstable-utility';
import { mapWorkspaceFromApiModel } from '../mapper/workspaceMapper';

export const dashboardTileDefinedReportFromWorkspace = (stateKey, workspacePath) => async (dispatch, getState) => {
  try {
    dispatch(dashboardTileRefreshBegin(stateKey));

    if (!workspacePath || workspacePath.trim().length === 0)
      return;

    const state = getState();

    const isFirstLoad = !isWorkspaceLoaded(`/Report/${workspacePath}`, state, stateKey);

    let workspace = await fetchWorkspaceAsync(`/Report/${workspacePath}`, dispatch, state, stateKey);
    if (!workspace) {
      dispatch(dashboardTileRefreshError(stateKey, { type: 'error', message: 'no content' }));
      return;
    }

    workspace = mapWorkspaceFromApiModel(`${workspace.id}`, workspace);
    workspace.reportPath = workspacePath;

    const initialiseRequest = {
      reportPath: workspace.reportPath,
      lens: state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'reports', 'criteria', 'lens']),
      fromDate: state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'reports', 'criteria', 'fromDate']),
      toDate: state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'reports', 'criteria', 'toDate']),
      timeZoneId: state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'reports', 'criteria', 'timeZoneId'])
    };

    const initialiseResponse = await fetchInitialiseReportAsync(initialiseRequest);
    const reportOptions = getInitialReportOptions(initialiseResponse);
    reportOptions.scenarioOverrideMap = isFirstLoad ? getInitialScenarioOverrides(initialiseResponse) : toJS(state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'results', 'scenarioOverrideMap']), {});
    Object.assign(workspace.reportOptions, reportOptions);

    const useDashboardDates = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardDates']) ?? false;
    const useDashboardLens = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardLens']) ?? false;
    const useDashboardTimezone = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardTimezone']) ?? false;
    const criteria = toJS(state.getIn(['dashboardTiles', 'criteria']), {});
    
    if (useDashboardLens) {
      workspace.reportOptions.lens = criteria.lens;
    }

    if (useDashboardDates) {
      if (criteria.fromDateMode === 'rel')
        workspace.reportOptions.relFromDate = criteria.relFromDate;
      else
        workspace.reportOptions.fromDate = criteria.absFromDate;

      if (criteria.toDateMode === 'rel')
        workspace.reportOptions.relToDate = criteria.relToDate;
      else
        workspace.reportOptions.toDate = criteria.absToDate;
    }

    if (useDashboardTimezone) {
      workspace.reportOptions.timeZoneId = criteria.timezoneId;
    }

    const filters = workspace.reportOptions.filters;
    const filter = filters.filter(x => x.enabled)
      .map(x => `in(${x.category},'${x.values.filter(y => y.enabled)
        .map(y => y.value)
        .join(',')}')`);

    const reportRequest = {
      reportPath: workspace.reportPath,
      lens: workspace.reportOptions.lens,
      fromDate: workspace.reportOptions.fromDate,
      toDate: workspace.reportOptions.toDate,
      relFromDate: workspace.reportOptions.relFromDate,
      relToDate: workspace.reportOptions.relToDate,
      timeZoneId: workspace.reportOptions.timeZoneId,
      mode: workspace.reportOptions.mode,
      scenarioOverrides: mapScenarioOverrides(reportOptions.scenarioOverrideMap),
      grouping: workspace.reportOptions.groupings ?? [],
      filter: filter,
      shapeName: workspace.reportOptions.shapeName,
      conversionUnit: workspace.reportOptions.conversionUnit
    };

    switch (workspace.reportOptions.mode) {
      case 'AsAt': 
      case 'AsAtDelta': reportRequest.asAt = workspace.reportOptions.asAt; break;
      case 'AsAtDeltaRelative': reportRequest.relativeAsAt = workspace.reportOptions.relativeAsAt; break;
      case 'Snapshot':
      case 'SnapshotDelta': reportRequest.snapshotAsAt = workspace.reportOptions.snapshotAsAt; break;
      case 'SnapshotDeltaRelative': reportRequest.snapshotRelativeDate = workspace.reportOptions.snapshotRelativeDate; break;
      default: break;
    }

    const definedReport = await fetchDefinedReportAsync(reportRequest);
    dispatch(dashboardTileDefinedReportRefreshComplete(stateKey, { workspace, definedReport, reportOptions }));
  }
  catch (error) {
    dispatch(dashboardTileRefreshError(stateKey, { type: 'error', message: error }));
  }
  finally {
    dispatch(dashboardTileRefreshComplete(stateKey));
  }
};

export const DASHBOARD_TILE_DEFINED_REPORT_REFRESH_COMPLETE = 'DASHBOARD_TILE_DEFINED_REPORT_REFRESH_COMPLETE';
const dashboardTileDefinedReportRefreshComplete = createAction(DASHBOARD_TILE_DEFINED_REPORT_REFRESH_COMPLETE, 'stateKey', 'data');

function getInitialReportOptions(initialiseResponse){
  const { criteria = {}, settings = {} } = initialiseResponse;
  const { lens, fromDate, toDate, timeZoneId, selectedGroupings, mode, relativeAsAt, shapeName } = criteria;
  const {
    availableGroupings = [],
    filters = [],
    hasAdaptiveLensDates,
    lenses = [],
    timeZones = [],
    reportingModes = [],
    shapeOptions = {
      defaultShape: '',
      displayShapeDropdown: false,
      availableShapes: [],
    },
    unitOptions = {
      displayUnitDropdown: false,
      availableUnits: [],
    }
  } = settings;

  return {
    lens,
    fromDate,
    toDate,
    timeZoneId,
    isPivot: settings.reportType === 'Pivot',
    selectedGroupings,
    mode,
    relativeAsAt,
    shapeName,
    availableGroupings,
    filters,
    hasAdaptiveLensDates,
    lenses,
    timeZones,
    reportingModes,
    shapeOptions,
    unitOptions
  };
}

function getInitialScenarioOverrides(initialiseResponse) {
  const { criteria = {} } = initialiseResponse;

  const scenarioOverrides = {};
  if (criteria.scenarioOverrides) {
    criteria.scenarioOverrides.forEach(x => {
      const nodeKey = `${x.dependentId}|${x.id}`;
      scenarioOverrides[nodeKey] = x.scenario;
    });
  }

  return scenarioOverrides;
}

const fetchInitialiseReportAsync = async request => {
  const response = await authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports/initialise`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });
  return await response.json();
};

const fetchDefinedReportAsync = async request => {
  const response = await authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });
  return await response.json();
};

export const DASHBOARD_TILE_DEFINED_REPORTS_SET_SCENARIO_OVERRIDE = 'DASHBOARD_TILE_DEFINED_REPORTS_SET_SCENARIO_OVERRIDE';
export const dashboardTileDefinedReportSetScenarioOverride = createAction(DASHBOARD_TILE_DEFINED_REPORTS_SET_SCENARIO_OVERRIDE, 'stateKey', 'key', 'value');

export const DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ANNOTATION_EXPAND = 'DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ANNOTATION_EXPAND';
export const dashboardTileDefinedReportToggleAnnotationExpand = createAction(DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ANNOTATION_EXPAND, 'stateKey', 'sectionKey');

export const DASHBOARD_TILE_DEFINED_REPORTS_SET_ALL_SCENARIO_OVERRIDE = 'DASHBOARD_TILE_DEFINED_REPORTS_SET_ALL_SCENARIO_OVERRIDE';
export const dashboardTileDefinedReportsSetAllScenarioOverride = createAction(DASHBOARD_TILE_DEFINED_REPORTS_SET_ALL_SCENARIO_OVERRIDE, 'stateKey', 'value');

export const dashboardTileDefinedReportToggleOnDemand = (stateKey, key, nodeLevel, displayKey, callback) => (dispatch, getState) => {
  const state = getState();
  const dashboardTileState = toJS(state.getIn(['dashboardTiles', 'tilesState', stateKey]), { criteria: {}, results: {} });
  const { reportPath, lens, fromDate, toDate, timeZoneId, mode, snapshotAsAt, grouping = [], filters = [], asAt, relativeAsAt, snapshotRelativeDate } = dashboardTileState.criteria;

  const { scenarioOverrides = {} } = dashboardTileState.results;
  const scenarioOverridesMapped = mapScenarioOverrides(scenarioOverrides);

  const request = buildGetReportsNodesRequest(key, displayKeyParseRoot(displayKey), reportPath, lens, fromDate, toDate, timeZoneId, asAt, mode, relativeAsAt, snapshotAsAt, snapshotRelativeDate, grouping, filters, nodeLevel, scenarioOverridesMapped); 

  dispatch(dashboardTileDefinedReportsSetDisplayMode(stateKey, key, nodeLevel, displayKey, 'Loading'));

  authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports/nodes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(dashboardTileDefinedReportsToggleOnDemandComplete(stateKey, key, data, displayKey));
      dispatch(dashboardTileDefinedReportsSetDisplayMode(stateKey, key, nodeLevel, displayKey, 'Open'));

      if (typeof callback === 'function') callback();
    })
    .catch(error => {
      dispatch(dashboardTileDefinedReportsSetDisplayMode(stateKey, key, nodeLevel, displayKey, 'OnDemand'));
      dispatch(logErrorNotification(error));
    });
};

export const DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ONDEMAND_COMPLETE = 'DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ONDEMAND_COMPLETE';
export const dashboardTileDefinedReportsToggleOnDemandComplete = createAction(DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ONDEMAND_COMPLETE, 'stateKey', 'key', 'data', 'displayKey');

export const DASHBOARD_TILE_DEFINED_REPORTS_SET_DISPLAY_MODE = 'DASHBOARD_TILE_DEFINED_REPORTS_SET_DISPLAY_MODE';
export const dashboardTileDefinedReportsSetDisplayMode = createAction(DASHBOARD_TILE_DEFINED_REPORTS_SET_DISPLAY_MODE, 'stateKey', 'key', 'depth', 'displayKey', 'displayMode');

export const DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND = 'DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND';
export const dashboardTileDefinedReportToggleExpand = createAction(DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND, 'stateKey', 'key', 'depth', 'displayKey');


export const DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_STARTED = 'DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_STARTED';
export const dashboardTileDefinedReportRefreshChartStarted = createAction(DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_STARTED, 'stateKey');

export const dashboardTileDefinedReportRefreshChart = (stateKey, timeSeriesIds, parentKeys = [], options = {}) => (dispatch, getState) => {
  dispatch(dashboardTileDefinedReportRefreshChartStarted(stateKey));

  const state = getState();
  const reportCriteria = toJS(state.getIn(['dashboardTiles', 'tilesState', stateKey, 'criteria']), {});
  const { lens, fromDate, toDate, timeZoneId } = reportCriteria;

  const request = {
    timeSeries: timeSeriesIds.filter(id => id > 0).map(id => ({id:id, key:`${id}`})),
    window: {
      absFromDate: moment.utc(fromDate).format(),
      absToDate: moment.utc(toDate).format()
    },
    timeZoneId: timeZoneId,
    lens: lens,
    includeBuckets: false,
    includeTimeseries: true
  }

  const useDashboardDates = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardDates']) ?? false;
  const useDashboardLens = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardLens']) ?? false;
  const useDashboardTimezone = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardTimezone']) ?? false;
  const criteria = toJS(state.getIn(['dashboardTiles', 'criteria']), {
    periods:[]
  });

  if (useDashboardLens) {
    request.lens = criteria.lens;
  }

  if (useDashboardDates) {
    request.window = {};
    if (criteria.fromDateMode === 'rel')
      request.window.relFromDate = criteria.relFromDate;
    else
      request.window.absFromDate = moment.utc(criteria.absFromDate).format();

    if (criteria.toDateMode === 'rel')
      request.window.relToDate = criteria.relToDate;
    else
      request.window.absToDate = moment.utc(criteria.absToDate).format();
  }

  if (useDashboardTimezone) {
    request.timeZoneId = criteria.timezoneId;
  }

  const useLocalData = !options.lens || options.lens === request.lens;
  if (useLocalData) {
    dispatch(dashboardTileDefinedReportRefreshChartComplete(stateKey, {
      timeSeries: [],
      rows: [],
      parentKeys,
      lens: request.lens
    }));
    return;
  }

  if (options)
    Object.assign(request, options);

  authFetch(`${ANALYSIS_REPORT_API_ROOT_URL}/v5/timeseries-report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => response.json())
    .then(data => {
      const { timeSeries = [] } = data;

      timeSeries.filter(i => i.error && i.error.length).forEach(i => {
        i.error.forEach(error => dispatch(logErrorNotification(`${i.key}: ${error}`)));
      });

      return { ...data, parentKeys, lens };
    })
    .then(data => {
      dispatch(dashboardTileDefinedReportRefreshChartComplete(stateKey, data));
    })
    .catch(error => {
      dispatch(dashboardTileDefinedReportRefreshChartComplete(stateKey));
      dispatch(logErrorNotification(error));
    });
};

export const DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_COMPLETE = 'DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_COMPLETE';
export const dashboardTileDefinedReportRefreshChartComplete = createAction(DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_COMPLETE, 'stateKey', 'data');

export const DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND_TIER = 'DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND_TIER';
export const dashboardTileDefinedReportToggleExpandTier = createAction(DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND_TIER, 'stateKey', 'tier', 'value');

export const dashboardTileDefinedReportReloadOnDemandParams = (stateKey) => (dispatch, getState) => {
  const state = getState();

  const reportPath = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'criteria', 'reportPath']),
    lens = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'criteria', 'lens']),
    fromDate = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'criteria', 'fromDate']),
    toDate = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'criteria', 'toDate']),
    timeZoneId = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'criteria', 'timeZoneId']),
    mode = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'criteria', 'mode']),
    snapshotAsAt = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'criteria', 'snapshotAsAt']);

  const groupings = toJS(state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'criteria', 'selectedGroupings']), []);
  const filters = toJS(state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'settings', 'filters']), []);

  let filter = filters.filter(x => x.enabled)
    .map(x => `in(${x.category},'${x.values.filter(y => y.enabled)
      .map(y => y.value)
      .join(',')}')`);

  let grouping = groupings;
  let params = {
    reportPath,
    lens,
    fromDate,
    toDate,
    timeZoneId,
    mode: (mode === '' ? undefined : mode),
    snapshotAsAt,
    grouping,
    filter,
    useVariants: true };

  for (let i in params) if (params[i] === undefined) delete params[i];

  dispatch(dashboardTileDefinedReportReloadOnDemand(stateKey));
};

const dashboardTileDefinedReportReloadOnDemand = (stateKey) => (dispatch, getState) => {
  const state = getState();
  const before = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'criteria']).toJS();
  const { lens, reportPath, fromDate, toDate, timeZoneId, mode, relativeAsAt, asAt, snapshotAsAt, snapshotRelativeDate, groupings, filters } = before;
  const scenarioOverrides = toJS(state.getIn(['dashboardTiles', 'tilesState', stateKey, 'results', 'scenarioOverrideMap']), {});
  const scenarioOverridesMapped = mapScenarioOverrides(scenarioOverrides);
  const displayMap = toJS(state.getIn(['dashboardTiles', 'tilesState', stateKey, 'results', 'displayMap']));
  const keys = getOnDemandDisplayMapKeys(displayMap);

  if (!keys || !keys.length) return;

  for (let i = 0; i < keys.length; i++) {
    const { key, displayKey, nodeLevel } = keys[i];

    const request = buildGetReportsNodesRequest(key, displayKeyParseRoot(displayKey), reportPath, lens, fromDate, toDate, timeZoneId, asAt, mode, relativeAsAt, snapshotAsAt, snapshotRelativeDate, groupings, filters, nodeLevel, scenarioOverridesMapped);
    dispatch(dashboardTileDefinedReportsSetDisplayMode(stateKey, key, nodeLevel, displayKey, 'Loading'));

    authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports/nodes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    })
      .then(response => response.json())
      .then(data => {
        /*
          Protect against a race condition where the criteria could change by the time we finish loading the on-demand nodes
        */
        const { isValid, after } = validateOnDemand(stateKey, getState, before);

        if (isValid) {
          dispatch(dashboardTileDefinedReportsToggleOnDemandComplete(stateKey, key, data, displayKey));
          dispatch(dashboardTileDefinedReportsSetDisplayMode(stateKey, key, nodeLevel, displayKey, 'Open'));
        }
        else {
          console.log('On-Demand criteria difference detected', { key, before, after });
          dispatch(dashboardTileDefinedReportsSetDisplayMode(stateKey, key, nodeLevel, displayKey, 'OnDemand'));
        }
      })
      .catch(error => {
        dispatch(dashboardTileDefinedReportsSetDisplayMode(stateKey, key, nodeLevel, displayKey, 'OnDemand'));
        dispatch(logErrorNotification(error));
      });
  }
};

function validateOnDemand(stateKey, getState, before) {
  const state = getState();
  const mode = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'criteria', 'mode']);
  const after = {
    reportPath: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'criteria', 'reportPath']),
    lens: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'criteria', 'lens']),
    fromDate: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'criteria', 'fromDate']),
    toDate: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'criteria', 'toDate']),
    timeZoneId: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'criteria', 'timeZoneId']),
    mode: (mode === '' ? undefined : mode),
    snapshotAsAt: state.getIn(['dashboardTiles', 'tilesState', stateKey, 'criteria', 'snapshotAsAt'])
  };

  let isValid = before.reportPath === after.reportPath
    && before.lens === after.lens
    && before.fromDate === after.fromDate
    && before.toDate === after.toDate
    && before.timeZoneId === after.timeZoneId
    && before.mode === after.mode
    && before.snapshotAsAt === after.snapshotAsAt;

  return { isValid, after };
}