import React from 'react';
import CollapsePanel from '../../../shared/CollapsePanel';
import StepOutputSeries from './StepOutputSeries';
import Tooltip from '../../../shared/Tooltip';
const labelStyle = {
  fontSize: '11.6px',
  fontWeight: 700,
  margin: 0,
  cursor: 'default'
};

function getTooltip(id, inputs) {
  if(inputs) {
    const match = inputs.filter(x => x.key === id);
    if(match && match.length === 1)
      return { name: match[0].name, granularity: match[0].granularity, sourceTimeZoneId: match[0].sourceTimeZoneId };
  }
  return null;
}

function StackItem({index, id, inputs}) {
  const tooltip = getTooltip(id, inputs);
  return (
    <li key={index} className="list-group-item">
      <Tooltip placement='right' title={tooltip && <> 
        {tooltip.name}
        {tooltip.granularity && <><br/>Granularity: {tooltip.granularity}</>}
        {tooltip.sourceTimeZoneId && <><br/>TimeZone: {tooltip.sourceTimeZoneId}</>}</>}>
        <span>{id}</span>
      </Tooltip>
    </li>);
}

export default function Step({ step, functionName, signature, inputs, inputStack, functionKeys, functionParameters, output }) {
  const key = `step-${step}: ${functionName}`;
  const title = `${step}: ${functionName} (${signature})`;
  return (
    <CollapsePanel idKey={key} title={title} headerStyle={{ color: 'white', background: 'steelblue', cursor: 'pointer' }} collapsed={true}>
      <div>
        <div className='row no-gutters'>
          <label className='col-sm-2' style={labelStyle}>
            Full input stack
          </label>
          <label className='col-sm-2' style={labelStyle}>
            Function keys
          </label>
          <label className='col-sm-2' style={labelStyle}>
            Parameters
          </label>          
          <label className='col-sm-6' style={labelStyle}>
            Output series
          </label>
        </div>
        <div className='row no-gutters'>
          <div className='col-sm-2'>
            <ul className="list-group mr-1">
              {inputStack && inputStack.map((x, i) => <StackItem index={i} id={x} inputs={inputs} />)}
            </ul>            
          </div>
          <div className='col-sm-2'>
            <ul className="list-group mr-1">
              {functionKeys && functionKeys.map((x, i) => <StackItem index={i} id={x} inputs={inputs} /> )}
            </ul>            
          </div>          
          <div className='col-sm-2'>
            <ul className="list-group mr-1">
              {functionParameters && functionParameters.map((x, i) => <li key={i} className="list-group-item">{x.key}: {x.value}</li>)}              
            </ul>            
          </div>                    
          <div className='col-sm-6'>
            {output && output.map((x, i) => 
              <StepOutputSeries key={i} 
                                step={step}
                                seriesKey={x.key}
                                granularity={x.granularity}
                                sourceTimeZoneId={x.sourceTimeZoneId}
                                dataPointCount={x.dataPointCount}
                                dataPoints={x.dataPoints} /> )}
          </div>
        </div>
      </div>
    </CollapsePanel>
  );
}