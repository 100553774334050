import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  dashboardTileShortcutFromWorkspace
} from '../../../actions/dashboard-tiles-shortcut';
import { toJS } from '../../../utility/immutable-utility';
import { getWorkspaceImageUrl } from '../../../utility/image-utility';

const ShortcutTile = connect(
  (state, { stateKey }) => {
    const refreshRequired = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'refreshRequired']);
    const layoutChanged = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'layoutChanged']);
    const workspacePath = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'workspacePath']);
    const stretchImage = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'stretchImage']) ?? false;
    const hideWorkspaceName = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'hideWorkspaceName']) ?? false;
    const workspace = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'workspace']);
    return {
      layoutChanged,
      refreshRequired,
      workspacePath,
      workspace,
      stretchImage,
      hideWorkspaceName
    };
  },
  (dispatch, { stateKey }) => ({
    refresh(workspacePath) {
      dispatch(dashboardTileShortcutFromWorkspace({ stateKey, workspacePath }));
    }
  })
)(({ refresh, refreshRequired, workspacePath, layoutChanged, workspace, stretchImage, hideWorkspaceName }) => {
  useEffect(() => {
    if (refreshRequired)
      refresh(workspacePath);
  }, [refresh, refreshRequired, workspacePath]);

  if (workspace) {
    const _workspace = toJS(workspace);
    const _imageUrl = getWorkspaceImageUrl({ imageId: _workspace.thumbImageId, type: _workspace.type });

    let url = '';
    switch (_workspace.type) {
      case 'Analysis':
        url = `analysis?workspacePath=${encodeURIComponent(workspacePath)}`;
        break;
      case 'Report':
        if (workspacePath.indexOf('Report/') === 0)
          url = 'reports/' + workspacePath.substr('Report/'.length);        
        break;
      default:
        url = workspacePath;
        break;
    }

    return <a href={`/${url}`} target='_blank' rel='noopener noreferrer'
      style={{ display: 'block', height: '100%', width: '100%' }}>
      <div style={{
        display: 'flex', height: '100%', alignItems: 'flex-end', justifyContent: 'center',
        backgroundImage: `url('${_imageUrl}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: stretchImage ? 'cover' : 'contain'
      }}>
        {!hideWorkspaceName && _workspace.name}
      </div>
    </a>
  }
  return <div></div>
});

export default ShortcutTile;