import React from 'react';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import HistoryTable from './HistoryTable';
import HistoryToolbar from './HistoryToolbar';
import Loading from '../../../shared/Loading';
import HistoryJsonEditor from './HistoryJsonEditor';

export default function HistoryViewer({ 
    identityId, isItemSelected, isLoading, rows, selectedIdentityVersion, 
    loadHistoryFunction, refreshFunction, revertFunction, toggleSelectionFunction }) {

  useDidMountEffect(() => loadHistoryFunction(identityId));

  return (
    <Loading isLoading={ isLoading } message='Loading...'>
      <div style={{ display:'flex', flexDirection:'row' }} >
        <HistoryToolbar isItemSelected={isItemSelected} 
                        refreshFunction={refreshFunction} 
                        revertFunction={revertFunction} />
      </div>
      <div style={{ display:'flex', flexDirection:'row', height:'100%'}} >
        <div style={{ display:'flex', flexDirection:'column', minHeight:'100%', overflowY: 'auto'}} >
            <HistoryTable rows={rows} 
                          toggleSelectionFunction={toggleSelectionFunction} />
        </div>
        <div style={{ display:'flex', flexDirection:'column', marginLeft: '2px', width: '500px', minWidth: '500px'}}>
          {isItemSelected && (
            <HistoryJsonEditor data={selectedIdentityVersion}
                        mode={'code'}
                        selectedTimeSeriesId={identityId} />
          )}            
        </div>
      </div>
    </Loading>
  );
}