import { createAction } from '../utility/redux-utility';
import {
  dashboardTileRefreshBegin,
  dashboardTileRefreshComplete,
  dashboardTileRefreshError,
  fetchWorkspaceAsync
} from './dashboard-tiles';
import { logErrorNotification } from './log';
import { authFetch } from '../auth';
import { 
  ANALYSIS_REPORT_API_ROOT_URL,
  WORKSPACES_API_URL 
} from '../config';

export const dashboardTileAnalysisTableFromWorkspace = ({ stateKey, workspacePath }) => async (dispatch, getState) => {
  dispatch(dashboardTileRefreshBegin(stateKey));
  try {
    if (!workspacePath || workspacePath.trim().length === 0)
      return;

    let workspace = await fetchWorkspaceAsync(`/AnalysisTable/${workspacePath}`, dispatch, getState(), stateKey);
    if (!workspace) {
      dispatch(dashboardTileRefreshError(stateKey, { type: 'error', message: 'no content' }));
      return;
    }

    const tableQuery = JSON.parse(workspace.data);
    const analysisTable = await fetchAnalysisTableAsync(tableQuery);
    dispatch(dashboardTileAnalysisTableRefreshComplete(stateKey, analysisTable));
  }
  catch (error) {
    dispatch(dashboardTileRefreshError(stateKey, { type: 'error', message: error }));
    dispatch(logErrorNotification(error));
  }
  finally {
    dispatch(dashboardTileRefreshComplete(stateKey));
  }
};

export const DASHBOARD_TILE_ANALYSIS_TABLE_REFRESH_COMPLETE = 'DASHBOARD_TILE_ANALYSIS_TABLE_REFRESH_COMPLETE';
const dashboardTileAnalysisTableRefreshComplete = createAction(DASHBOARD_TILE_ANALYSIS_TABLE_REFRESH_COMPLETE, 'stateKey', 'data');

export const dashboardTileAnalysisTableSharedFoldersRefresh = () => (dispatch, getState) => {
  dispatch(dashboardTileAnalysisTableSharedFoldersRefreshStarted());

  return authFetch(`${WORKSPACES_API_URL}/workspaces/shared-folders`)
    .then(response => response.json())
    .then(data => (data || []).map(i => ({ folderPath: i })))
    .then(data => {
      dispatch(dashboardTileAnalysisTableSharedFoldersRefreshComplete(data));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_STARTED = 'DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_STARTED';
const dashboardTileAnalysisTableSharedFoldersRefreshStarted = createAction(DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_STARTED);

export const DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_COMPLETE = 'DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_COMPLETE';
const dashboardTileAnalysisTableSharedFoldersRefreshComplete = createAction(DASHBOARD_TILE_ANALYSIS_TABLE_SHARED_FOLDER_REFRESH_COMPLETE, 'data');

const fetchAnalysisTableAsync = tableQuery => {
    return authFetch(`${ANALYSIS_REPORT_API_ROOT_URL}/v1/timeseriesquerytable`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tableQuery)
    })
    .then(response => response.json());
};