import moment from 'moment';
import { comparisonModes } from './comparisonmode-utility';

export class HighchartsAxisConverter {
  constructor({ comparisonMode, lens, chartStartDate, chartEndDate, xAxisKey = undefined }) {
    this._bucketToX = row => moment.utc(row.dateTime).valueOf();
    this._axisStyle = 'datetime';
    this._categoriesFunc = () => undefined;
    this._min = chartStartDate ? moment.utc(chartStartDate).valueOf() : null;
    this._max = chartEndDate ? moment.utc(chartEndDate).valueOf() : null;

    switch (comparisonMode) {
      case comparisonModes.yearOnYear:
      case comparisonModes.gasYear:
        this.initYearLens(lens);
        break;
      case comparisonModes.gasSeason:
      case comparisonModes.monthOnMonth:
      case comparisonModes.quarter:
        this.initMonthLens(lens);
        break;
      case comparisonModes.dayOnDay:
        this.initDayLens(lens);
        break;
      default:
        break;
    }

    if (xAxisKey && xAxisKey !== 'None') {
      this._categoriesFunc = series => [...new Set(series.map(this._bucketToX))].sort();
      this._bucketToX = row => row[`${xAxisKey}`];
      this._axisStyle = 'category';
      this._min = null;
      this._max = null;
    }
    else {
      this._bucketToX = row => moment.utc(row.dateTime).valueOf();
      this._categoriesFunc = () => undefined;
      this._axisStyle = 'datetime';
    }
  }

  getX(row) {
    return this._bucketToX(row);
  }

  initYearLens(lens) {
    switch (lens) {
      case 'Year': // year
      case 'Quarter': // q1,q2,q3,q4
      case 'Month': // january, february ... december
      case 'Week': // wk1 wk2 wk3 ... wk53 (leap year)
        this._axisStyle = 'category';
        this._bucketToX = (row) => row.bucket;
        this._categoriesFunc = (series) => series.map(this._bucketToX);
        break;
      case 'Day': // 01-Jan, 02-Jan, 31-Dec
        this._axisStyle = 'datetime';
        this._toolTipDateFormat = '%d-%B';
        let previousXDay = null;
        let yearDayOffset = 2020;
        let checkYear = true;
        this._bucketToX = (row) => {
          let x = moment.utc(`${row.bucket}-${yearDayOffset}`, 'DD-MMM-YYYY');
          if (checkYear) {
            checkYear = false;
            if (x.month() > 0) {
              yearDayOffset--;
              x = moment.utc(`${row.bucket}-${yearDayOffset}`, 'DD-MMM-YYYY');
            }
          }

          if (previousXDay && x.isBefore(previousXDay)) {
            yearDayOffset++;
            x = moment.utc(`${row.bucket}-${yearDayOffset}`, 'DD-MMM-YYYY');
          }

          previousXDay = x;
          return x.valueOf();
        };
        this._categoriesFunc = (series) => undefined;
        break;
      case 'Hour': // 01-Jan 00:00, 01-Jan 01:00, 01-Jan 02:00
      case 'HalfHour': // 01-Jan 00:00, 01-Jan 01:00, 01-Jan 02:00
      case 'QuarterHour': // 01-Jan 00:00, 01-Jan 01:00, 01-Jan 02:00
        this._axisStyle = 'datetime';
        this._toolTipDateFormat = '%d-%B %H:%M';
        let previousXHour = null;
        let yearHourOffset = 2020;
        let checkHourYear = true;
        this._bucketToX = (row) => {
          let x = moment.utc(`${row.bucket}-${yearHourOffset}`, 'DD-MMM HH:mm-YYYY');
          if (checkHourYear) {
            checkHourYear = false;
            if (x.month() > 0) {
              yearDayOffset--;
              x = moment.utc(`${row.bucket}-${yearDayOffset}`, 'DD-MMM HH:mm-YYYY');
            }
          }

          if (previousXHour && x.isBefore(previousXHour)) {
            yearHourOffset++;
            x = moment.utc(`${row.bucket}-${yearHourOffset}`, 'DD-MMM HH:mm-YYYY');
          }

          previousXHour = x;
          return x.valueOf();
        };
        this._categoriesFunc = (series) => undefined;
        break;
      default:
        break;
    }
  }

  initMonthLens(lens) {
    switch (lens) {
      case 'Quarter': // q1,q2,q3,q4
      case 'Month': // Month
      case 'Week': // wk1 wk2 wk3 ... wk53 (leap year)
        this._axisStyle = 'category';
        this._bucketToX = (row) => row.bucket;
        this._categoriesFunc = (series) => series.map(this._bucketToX);
        break;
      case 'Day': // Day-01, Day-02
        this._axisStyle = 'datetime';
        this._toolTipDateFormat = 'Day-%d';
        let previousXDay = null;
        let yearOffset = 2020;
        this._bucketToX = (row) => {
          let bucket = row.bucket;
          let x = moment.utc(`${bucket}-${yearOffset}`, 'DD-MMM-YYYY');
          if (previousXDay && x.isBefore(previousXDay)) {
            yearOffset++;
            x = moment.utc(`${bucket}-${yearOffset}`, 'DD-MMM-YYYY');
          }

          previousXDay = x;
          return x.valueOf();
        };
        this._categoriesFunc = (series) => undefined;
        break;
      case 'Hour': // Day-01 00:00, Day-02 01:00
      case 'HalfHour': // Day-01 00:00, Day-02 01:00
      case 'QuarterHour': // Day-01 00:00, Day-02 01:00
        this._axisStyle = 'datetime';
        this._toolTipDateFormat = 'Day-%d %H:%M';
        let previousXHour = null;
        let monthOffset = 1;
        this._bucketToX = (row) => {
          let bucket = row.bucket.replace('Day-', '');
          let x = moment.utc(`${bucket} ${monthOffset}-2020`, 'DD HH:mm M-YYYY');
          if (previousXHour && x.isBefore(previousXHour)) {
            monthOffset++;
            x = moment.utc(`${bucket} ${monthOffset}-2020`, 'DD HH:mm M-YYYY');
          }

          previousXHour = x;
          return x.valueOf();
        };
        this._categoriesFunc = (series) => undefined;
        break;
      default:
        break;
    }
  }

  initDayLens(lens) {
    switch (lens) {
      case 'Day': // Day
      case 'Hour': // 00:00, 01:00
      case 'HalfHour': // 00:00, 00:30
      case 'QuarterHour': // 00:00, 00:15
        this._axisStyle = 'category';
        this._toolTipDateFormat = '%H:%M';
        this._bucketToX = (row) => row.bucket;
        this._categoriesFunc = (series) => series.map(this._bucketToX);
        break;
      default:
        break;
    }
  }
}

export class HighchartsInfoProvider {
  constructor({ comparisonMode, lens, chartStartDate, chartEndDate, hasXAxis, customFormat }) {
    this._axisFormatter = undefined;
    this._axisStyle = 'datetime';
    this._categoriesFunc = () => undefined;
    this._min = chartStartDate ? moment.utc(chartStartDate).valueOf() : null;
    this._max = chartEndDate ? moment.utc(chartEndDate).valueOf() : null;

    if (hasXAxis === true) {
      this._axisFormatter = x => `${x}`;
      this._axisStyle = 'category';
      this._min = null;
      this._max = null;
    }
    else {
      this._categoriesFunc = () => undefined;
      this._axisStyle = 'datetime';
      this._axisFormatter = customFormat ? getCustomFormatterFunction(customFormat) : getComparisonLensFormatterFunction(comparisonMode, lens);
    }    
  }

  getMin() {
    return this._min;
  }

  getMax() {
    return this._max;
  }

  getAxisStyle() {
    return this._axisStyle;
  }

  getCategories(series) {
    return this._categoriesFunc(series);
  }

  getTooltipDateFormatter() {
    return this._toolTipDateFormat;
  }

  getAxisFormatterFunction() {
    return this._axisFormatter;
  }

  getUniqueAxisFormatter() {
    const valueFormatter = this.getAxisFormatterFunction();

    if (valueFormatter) return function () {
      const formattedValue = valueFormatter(this.value);
      return formattedValue;
    };

    return undefined;
  }  
}

function getComparisonLensFormatterFunction(comparisonMode, lens) {
  switch (comparisonMode) {
    case comparisonModes.yearOnYear:
    case comparisonModes.gasYear:
      if (lens === 'Year')
        return () => 'Year';
      else if (lens === 'Quarter')
        return x => `Q${moment.utc(x).quarter()}`;
      else if (lens === 'Month')
        return x => moment.utc(x).format('MMMM');
      else if (lens === 'Week')
        return x => `WK${moment.utc(x).isoWeek()}`;
      else if (lens === 'Day')
        return (x) => moment.utc(x).format('DD-MMM');
      else
        return (x) => moment.utc(x).format('DD-MMM HH:mm');
    case comparisonModes.gasSeason:
      if (lens === 'GasSeason')
        return () => 'GasSeason';
      else if (lens === 'Quarter')
        return x => `Q${moment.utc(x).quarter()}`;
      else if (lens === 'Month')
        return x => moment.utc(x).format('MMMM');
      else if (lens === 'Week')
        return x => `WK${moment.utc(x).isoWeek()}`;
      else if (lens === 'Day')
        return (x) => moment.utc(x).format('DD-MMM');
      else
        return (x) => moment.utc(x).format('DD HH:mm');
    case comparisonModes.quarter:
      if (lens === 'Quarter')
        return () => 'Quarter';
      else if (lens === 'Month')
        return x => moment.utc(x).format('MMMM');
      else if (lens === 'Week')
        return x => `WK${moment.utc(x).isoWeek()}`;
      else if (lens === 'Day')
        return (x) => moment.utc(x).format('DD-MMM');
      else
        return (x) => moment.utc(x).format('DD-MMM HH:mm');
    case comparisonModes.monthOnMonth:
      if (lens === 'Month')
        return () => 'Month';
      else if (lens === 'Week')
        return x => `WK${moment.utc(x).isoWeek()}`;
      else if (lens === 'Day')
        return (x) => `Day-${moment.utc(x).format('DD')}`;
      else
        return (x) => `Day-${moment.utc(x).format('DD HH:mm')}`;
    case comparisonModes.dayOnDay:
      if (lens === 'Day')
        return () => 'Day';
      else
        return x => moment.utc(x).format('HH:mm');
    default:
      break;
  }
}

function getCustomFormatterFunction(customFormat){
  return x => {
    let response = `${customFormat}`;
    const m = moment.utc(x);
    const matches = response.match( /\{(.*?)\}/gm );
    if (matches)
      matches.forEach(match => {
        const formatText = match.replace('{','').replace('}','')
        response = response.replace(match, m.format(formatText))
      });

    return response;
  };
}