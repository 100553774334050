import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import TimeSeriesDetails from './TimeSeriesDetails';
import {
  timeSeriesDetailsInitialise,
  timeSeriesDetailsInitialiseTimeSeries,
  timeSeriesDetailsLoad,
  timeSeriesDetailsUpdateObject,
  timeSeriesDetailsUpdateValue,
  timeSeriesDetailsSave,
  timeSeriesDetailsSetView,
  timeSeriesDetailsCloneTimeSeries,
  timeSeriesDetailsToggleEditorCollapse,
  timeSeriesDetailsToggleSideBarCollapse,
  timeSeriesDetailsSetFocus,
  timeSeriesDetailsSetHasFocus,
  timeSeriesDetailsCopyLinkToClipboard
} from '../../../actions/timeSeriesDetails';
import {
  timeSeriesDetailsAdjustmentsSetFilterProperty,
  timeSeriesDetailsAdjustmentsEdited,
  timeSeriesDetailsAdjustmentsSave,
  timeSeriesDetailsAdjustmentsRangeApply,
  timeSeriesDetailsAdjustmentsLoad
} from '../../../actions/timeSeriesDetails-adjustments';
import {
  timeSeriesDetailsAnnotationsLoad,
  timeSeriesDetailsAnnotationsSetTimeZone,
  timeSeriesDetailsAnnotationsFilterUpdated,
  timeSeriesDetailsAnnotationsApplyFilter,
  timeSeriesDetailsAnnotationAdd,
  timeSeriesDetailsAnnotationEdit,
  timeSeriesDetailsAnnotationEditCancel,
  timeSeriesDetailsAnnotationSave,
  timeSeriesDetailsAnnotationDelete,
  timeSeriesDetailsAnnotationSetKey,
  timeSeriesDetailsAnnotationSetFromDate,
  timeSeriesDetailsAnnotationSetPriority,
  timeSeriesDetailsAnnotationSetToDate,
  timeSeriesDetailsAnnotationSetText,
  timeSeriesDetailsAnnotationsApplySort
} from '../../../actions/timeSeriesDetails-annotations';
import {
  timeSeriesDetailsMaterialiseSetRangeDialogVisible,
  timeSeriesDetailsMaterialiseSetDate,
  timeSeriesDetailsRematerialise,
  timeSeriesDetailsRematerialiseWithRange,
  timeSeriesDetailsMaterialiseSetSuppressArchive,
  timeSeriesDetailsSetMaterialiseStatusToEnabled
} from '../../../actions/timeSeriesDetails-materialise';
import {
  timeSeriesDetailsVariantsLoad,
  timeSeriesDetailsVariantsEdit,
  timeSeriesDetailsVariantsCancelEdit,
  timeSeriesDetailsVariantsUpdateEdit,
  timeSeriesDetailsVariantsSave
} from '../../../actions/timeSeriesDetails-variants';
import {
  timeSeriesDetailsBasicSetDataType
} from '../../../actions/timeSeriesDetails-basic';
import {
  timeSeriesDetailsToolbarUpdateViewMode
} from '../../../actions/timeSeriesDetails-toolbar';
import {
  timeSeriesDetailDerivationUpdateSearchKeyText,
  timeSeriesDerivationAddKey,
  timeSeriesDetailsDerivationMoveKeyUp,
  timeSeriesDetailsDerivationMoveKeyDown,
  timeSeriesDetailsDerivationDeleteKey,
  timeSeriesDetailsDerivationAddKeys
} from '../../../actions/timeSeriesDetails-derivation';
import {
  timeSeriesDetailssetFunctionsStepStyleToMultiStep,
  timeSeriesDetailsSetFunctionsType,
  timeSeriesDetailsFunctionsAddParameter,
  timeSeriesDetailsFunctionsDeleteParameter,
  timeSeriesDetailsFunctionsUpdateParameterValue,
  timeSeriesDetailsFunctionsAddFunction,
  timeSeriesDetailsFunctionsDeleteFunction,
  timeSeriesDetailsFunctionsMoveFunctionUp,
  timeSeriesDetailsFunctionsMoveFunctionDown,
  timeSeriesDetailsFunctionsAddFunctionKey,
  timeSeriesDetailsFunctionsDeleteFunctionKey,
  timeSeriesDetailsFunctionsMoveFunctionKeyUp,
  timeSeriesDetailsFunctionsMoveFunctionKeyDown,
  timeSeriesDetailsFunctionsUpdateFunctionOutputKey,
  timeSeriesDetailsFunctionsAddFunctionOutputCategory,
  timeSeriesDetailsFunctionsUpdateFunctionOutputCategoryValue,
  timeSeriesDetailsFunctionsDeleteFunctionOutputCategory,
  timeSeriesDetailsFunctionsSetFunctionDisabledState
} from '../../../actions/timeSeriesDetails-functions';
import {
  timeSeriesDetailsCategoriesAdd,
  timeSeriesDetailsCategoriesUpdate,
  timeSeriesDetailsCategoriesDelete,
  timeSeriesDetailsCategoriesDeleteAll,
  timeSeriesDetailsCategoriesCopyFrom,
  timeSeriesDetailsCategoriesGetCategoryValues
} from '../../../actions/timeSeriesDetails-categories';
import {
  timeSeriesDetailsBasedOnUpdateSearchKeyText,
  timeSeriesDetailsBasedOnAddKey,
  timeSeriesDetailsBasedOnDeleteKey,
  timeSeriesDetailsBasedOnUpdateSettings
} from '../../../actions/timeSeriesDetails-based-on'

import {
  resetCollapsedStates
} from '../../../actions/sharedComponents';
import { useParams } from 'react-router';
import {
  referenceDataLoadFunctions,
  referenceDataLoadCategoriesByDataType
} from '../../../actions/referenceData';
import { loadSchemaLookups } from '../../../actions/schemas';
import { analysisShapesLoadAvailableShapes } from '../../../actions/analysis-shapes-v2';

const TimeSeriesDetailsConnected = connect(
  (state) => ({
    features: state.getIn(['timeSeries', 'features']),
    timeSeriesDetails: state.get('timeSeriesDetails'),
    referenceData: state.getIn(['referenceData']),
    allSchemaNames: state.getIn(['schemas', 'allSchemaNames']),
    isContributor: state.getIn(['roles', 'isContributor']),
    isWizard: state.getIn(['roles', 'isWizard']),
    materialiseRangeDialogVisible: state.getIn(['timeSeriesDetails', 'materialise', 'rangeDialogVisible']),
    materialiseRangeFromUtc: state.getIn(['timeSeriesDetails', 'materialise', 'fromUtc']),
    materialiseRangeToUtc: state.getIn(['timeSeriesDetails', 'materialise', 'toUtc']),
    suppressArchive: state.getIn(['timeSeriesDetails', 'materialise', 'suppressArchive']),
    basedOnSettings: state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'basedOnSettings'])
  }),
  (dispatch, { location }) => ({
    initialise(view) {
      dispatch(loadSchemaLookups());
      dispatch(analysisShapesLoadAvailableShapes());
      dispatch(timeSeriesDetailsInitialise(view));
    },
    initialiseTimeSeries(timeSeriesId) {
      dispatch(referenceDataLoadFunctions());
      dispatch(referenceDataLoadCategoriesByDataType());
      dispatch(timeSeriesDetailsInitialiseTimeSeries({ timeSeriesId }));
    },
    loadTimeSeries({ timeSeriesId, doNotLoadComposition = false }) {
      dispatch(timeSeriesDetailsLoad({ timeSeriesId, doNotLoadComposition }));
    },
    updateTimeSeriesObject(data) {
      dispatch(timeSeriesDetailsUpdateObject(data));
    },
    updateTimeSeriesValue(keyPath, value) {
      dispatch(timeSeriesDetailsUpdateValue(keyPath, value));
    },
    saveTimeSeries(timeSeriesId, data) {
      dispatch(timeSeriesDetailsSave(timeSeriesId, data));
    },
    setTimeSeriesView(view) {
      dispatch(timeSeriesDetailsSetView(view));
    },
    toggleEditorCollapse() {
      dispatch(timeSeriesDetailsToggleEditorCollapse());
    },
    toggleSideBarCollapse() {
      dispatch(timeSeriesDetailsToggleSideBarCollapse());
    },
    setInputFocus(id) {
      dispatch(timeSeriesDetailsSetFocus(id));
    },
    setInputHasFocus() {
      dispatch(timeSeriesDetailsSetHasFocus());
    },

    // Toolbar
    updateViewMode(mode) {
      dispatch(timeSeriesDetailsToolbarUpdateViewMode(mode));
    },
    cloneTimeSeries() {
      dispatch(timeSeriesDetailsCloneTimeSeries());
    },
    refreshTimeSeries(timeSeriesId) {
      dispatch(timeSeriesDetailsLoad({ timeSeriesId }));
    },

    // Basic
    updateTimeSeriesDataType(data) {
      dispatch(timeSeriesDetailsBasicSetDataType(data));
    },

    // Derivation
    updateDerivationSearchKeyText(value) {
      dispatch(timeSeriesDetailDerivationUpdateSearchKeyText(value));
    },
    addDerivationSearchKeyText(value) {
      dispatch(timeSeriesDerivationAddKey());
    },
    moveDerivationKeyUp(value) {
      dispatch(timeSeriesDetailsDerivationMoveKeyUp(value));
    },
    moveDerivationKeyDown(value) {
      dispatch(timeSeriesDetailsDerivationMoveKeyDown(value));
    },
    deleteDerivationKey(value) {
      dispatch(timeSeriesDetailsDerivationDeleteKey(value));
    },
    addDerivationKeys(keys) {
      dispatch(timeSeriesDetailsDerivationAddKeys());
    },

    // Functions
    setFunctionsStepStyleToMultiStep(style) {
      dispatch(timeSeriesDetailssetFunctionsStepStyleToMultiStep(style));
    },
    setFunctionsType(data) {
      dispatch(timeSeriesDetailsSetFunctionsType(data));
    },
    addFunctionsStepParameter(data) {
      dispatch(timeSeriesDetailsFunctionsAddParameter(data));
    },
    deleteFunctionsStepParameter(data) {
      dispatch(timeSeriesDetailsFunctionsDeleteParameter(data));
    },
    updateFunctionParameterValue(data) {
      dispatch(timeSeriesDetailsFunctionsUpdateParameterValue(data));
    },
    addMultiStepFunction() {
      dispatch(timeSeriesDetailsFunctionsAddFunction());
    },
    deleteMultiStepFunction(index) {
      dispatch(timeSeriesDetailsFunctionsDeleteFunction(index));
    },
    moveMultiStepFunctionUp(index) {
      dispatch(timeSeriesDetailsFunctionsMoveFunctionUp(index));
    },
    moveMultiStepFunctionDown(index) {
      dispatch(timeSeriesDetailsFunctionsMoveFunctionDown(index));
    },
    addFunctionKey(data) {
      dispatch(timeSeriesDetailsFunctionsAddFunctionKey(data));
    },
    deleteFunctionKey(data) {
      dispatch(timeSeriesDetailsFunctionsDeleteFunctionKey(data));
    },
    moveFunctionKeyUp(data) {
      dispatch(timeSeriesDetailsFunctionsMoveFunctionKeyUp(data));
    },
    moveFunctionKeyDown(data) {
      dispatch(timeSeriesDetailsFunctionsMoveFunctionKeyDown(data));
    },
    addFunctionOutputCategory(data) {
      dispatch(timeSeriesDetailsFunctionsAddFunctionOutputCategory(data));
    },
    updateFunctionOutputKey(data) {
      dispatch(timeSeriesDetailsFunctionsUpdateFunctionOutputKey(data));
    },
    updateFunctionOutputCategoryValue(data) {
      dispatch(timeSeriesDetailsFunctionsUpdateFunctionOutputCategoryValue(data));
    },
    deleteFunctionOutputCategory(data) {
      dispatch(timeSeriesDetailsFunctionsDeleteFunctionOutputCategory(data));
    },
    setFunctionDisabled(data) {
      dispatch(timeSeriesDetailsFunctionsSetFunctionDisabledState(data));
    },

    // categories
    addCategory(data) {
      dispatch(timeSeriesDetailsCategoriesAdd(data));
    },
    updateCategoryValue(data) {
      dispatch(timeSeriesDetailsCategoriesUpdate(data));
    },
    deleteCategory(data) {
      dispatch(timeSeriesDetailsCategoriesDelete(data));
    },
    deleteAllcategories(data) {
      dispatch(timeSeriesDetailsCategoriesDeleteAll(data));
    },
    copyCategoriesCopyFrom(data) {
      dispatch(timeSeriesDetailsCategoriesCopyFrom(data));
    },
    getCategoryValues(name) {
      dispatch(timeSeriesDetailsCategoriesGetCategoryValues(name));
    },

    // Annotations
    loadAnnotations() {
      dispatch(timeSeriesDetailsAnnotationsLoad());
    },
    setAnnotationsTimeZone(timeZone) {
      dispatch(timeSeriesDetailsAnnotationsSetTimeZone(timeZone));
      dispatch(timeSeriesDetailsAnnotationsLoad());
    },
    updateAnnotationsFilter({ filterType, filterText }) {
      dispatch(timeSeriesDetailsAnnotationsFilterUpdated({ filterType, filterText }));
    },
    applyAnnotationsFilter() {
      dispatch(timeSeriesDetailsAnnotationsApplyFilter());
      dispatch(timeSeriesDetailsAnnotationsLoad());
    },
    clearAnnotationsFilter() {
      dispatch(timeSeriesDetailsAnnotationsFilterUpdated({ filterType: '', filterText: '' }));
      dispatch(timeSeriesDetailsAnnotationsApplyFilter());
      dispatch(timeSeriesDetailsAnnotationsLoad());
    },
    addAnnotation() {
      dispatch(timeSeriesDetailsAnnotationAdd());
    },
    editAnnotation(annotationId) {
      dispatch(timeSeriesDetailsAnnotationEdit(annotationId));
    },
    deleteAnnotation(annotationId) {
      dispatch(timeSeriesDetailsAnnotationDelete(annotationId));
    },
    saveAnnotation() {
      dispatch(timeSeriesDetailsAnnotationSave());
    },
    cancelAnnotationEdit() {
      dispatch(timeSeriesDetailsAnnotationEditCancel());
    },
    setAnnotationKey(annotationKey) {
      dispatch(timeSeriesDetailsAnnotationSetKey(annotationKey));
    },
    setAnnotationFromDate(fromDate) {
      dispatch(timeSeriesDetailsAnnotationSetFromDate(fromDate));
    },
    setAnnotationToDate(toDate) {
      dispatch(timeSeriesDetailsAnnotationSetToDate(toDate));
    },
    setAnnotationPriority(priority) {
      dispatch(timeSeriesDetailsAnnotationSetPriority(priority));
    },
    setAnnotationText(text) {
      dispatch(timeSeriesDetailsAnnotationSetText(text));
    },
    sortAnnotations(key) {
      dispatch(timeSeriesDetailsAnnotationsApplySort(key));
    },

    // Variants
    loadVariants(timeSeriesId) {
      dispatch(timeSeriesDetailsVariantsLoad(timeSeriesId));
    },
    addVariant() {
      dispatch(timeSeriesDetailsVariantsEdit());
    },
    cancelVariantEdit() {
      dispatch(timeSeriesDetailsVariantsCancelEdit());
    },
    setVariantValue(keyPath, value) {
      dispatch(timeSeriesDetailsVariantsUpdateEdit(keyPath, value));
    },
    saveVariant() {
      dispatch(timeSeriesDetailsVariantsSave());
    },
    // Materialise    
    requestRematerialise() {
      dispatch(timeSeriesDetailsRematerialise(timeSeriesId => {
        dispatch(timeSeriesDetailsLoad({ timeSeriesId }));
      }));
    },
    requestRematerialiseWithRange() {
      dispatch(timeSeriesDetailsRematerialiseWithRange(timeSeriesId => {
        dispatch(timeSeriesDetailsLoad({ timeSeriesId }));
      }));      
    },
    setSuppressArchive(value) {
      dispatch(timeSeriesDetailsMaterialiseSetSuppressArchive(value));
    },
    setMaterialiseRangeDialogVisible(value) {
      dispatch(timeSeriesDetailsMaterialiseSetRangeDialogVisible(value));
    },
    setMaterialiseRangeDate(field, value) {
      dispatch(timeSeriesDetailsMaterialiseSetDate(field, value));
    },
    setMaterialiseStatusToEnabled() {
      dispatch(timeSeriesDetailsSetMaterialiseStatusToEnabled());
    },
    // adjustments
    loadAdjustments(identityId) {
      dispatch(timeSeriesDetailsAdjustmentsLoad(identityId));
    },
    setAdjustmentsFilter(property, value) {
      dispatch(timeSeriesDetailsAdjustmentsSetFilterProperty(property, value));
    },
    editAdjustmentValues(data) {
      dispatch(timeSeriesDetailsAdjustmentsEdited(data));
    },
    saveAdjustments() {
      dispatch(timeSeriesDetailsAdjustmentsSave());
    },
    applyAdjustmentsRange() {
      dispatch(timeSeriesDetailsAdjustmentsRangeApply());
    },

    // ui behaviour
    resetCollapsedPanels() {
      dispatch(resetCollapsedStates());
    },

    copyLinkToClipboard() {
      dispatch(timeSeriesDetailsCopyLinkToClipboard())
    },

    // base on
    basedOnUpdateSearchKeyText(value) {
        dispatch(timeSeriesDetailsBasedOnUpdateSearchKeyText(value));
    },
    basedOnAddKey() {
        dispatch(timeSeriesDetailsBasedOnAddKey());
    },
    basedOnDeleteKey() {
        dispatch(timeSeriesDetailsBasedOnDeleteKey());
    },
    basedOnUpdateSettings(property, value) {
        dispatch(timeSeriesDetailsBasedOnUpdateSettings(property, value));
    }
  })
)(({ features, referenceData, allSchemaNames, timeSeriesDetails, initialise, initialiseTimeSeries, loadTimeSeries, updateTimeSeriesObject, updateTimeSeriesValue, saveTimeSeries, setTimeSeriesView, toggleEditorCollapse, toggleSideBarCollapse,
  setInputFocus, setInputHasFocus,
  updateViewMode, cloneTimeSeries, refreshTimeSeries,
  updateTimeSeriesDataType,
  updateDerivationSearchKeyText, addDerivationSearchKeyText, addDerivationKeys, moveDerivationKeyUp, moveDerivationKeyDown, deleteDerivationKey,
  setFunctionsStepStyleToMultiStep, setFunctionsType, addFunctionsStepParameter, deleteFunctionsStepParameter, updateFunctionParameterValue, addMultiStepFunction, deleteMultiStepFunction, moveMultiStepFunctionUp, moveMultiStepFunctionDown, addFunctionKey, deleteFunctionKey, moveFunctionKeyUp, moveFunctionKeyDown, addFunctionOutputCategory, updateFunctionOutputKey, updateFunctionOutputCategoryValue, deleteFunctionOutputCategory, setFunctionDisabled,
  addCategory, updateCategoryValue, deleteCategory, deleteAllcategories, copyCategoriesCopyFrom, getCategoryValues,
  loadAnnotations, setAnnotationsTimeZone, updateAnnotationsFilter, applyAnnotationsFilter, clearAnnotationsFilter, addAnnotation, editAnnotation, deleteAnnotation, saveAnnotation, cancelAnnotationEdit, setAnnotationKey, setAnnotationFromDate, setAnnotationToDate, setAnnotationPriority, setAnnotationText, sortAnnotations,
  materialiseRangeDialogVisible, materialiseRangeFromUtc, materialiseRangeToUtc, requestRematerialise, requestRematerialiseWithRange, setMaterialiseRangeDialogVisible, setMaterialiseRangeDate, setMaterialiseStatusToEnabled,
  suppressArchive, setSuppressArchive,
  loadVariants, addVariant, cancelVariantEdit, setVariantValue, saveVariant, 
  loadAdjustments, setAdjustmentsFilter, editAdjustmentValues, saveAdjustments, applyAdjustmentsRange,
  resetCollapsedPanels, copyLinkToClipboard, isContributor, isWizard,
  basedOnSettings,
  basedOnAddKey, basedOnDeleteKey, basedOnUpdateSearchKeyText, basedOnUpdateSettings }) => {
  const _features = toJS(features, {});
  const _timeSeriesDetails = toJS(timeSeriesDetails, {});
  const _referenceData = toJS(referenceData, {});
  const _allSchemaNames = toJS(allSchemaNames);

  const params = useParams();
  const {
    currentTimeSeriesId,
    currentTimeSeriesView,
    selectedTimeSeriesId,
    isEditorCollapsed,
    isSideBarCollapsed,
    timeSeriesEditor,
    annotationsEditor,
    adjustmentsEditor,
    variantsEditor
  } = _timeSeriesDetails;

  // compostion ui is expecting immutables
  const composition = timeSeriesDetails.get('composition');

  useEffect(() => {
    if (currentTimeSeriesId !== params.id) {
      initialise(params.view);
      initialiseTimeSeries(params.id);
      resetCollapsedPanels();
    }
  }, [initialise, initialiseTimeSeries, params.id, params.view, currentTimeSeriesId, resetCollapsedPanels]);

  return <TimeSeriesDetails
    features={_features}
    currentTimeSeriesId={currentTimeSeriesId}
    selectedTimeSeriesId={selectedTimeSeriesId}
    currentTimeSeriesView={currentTimeSeriesView}
    referenceData={_referenceData}
    allSchemaNames={ _allSchemaNames }
    isEditorCollapsed={isEditorCollapsed}
    isSideBarCollapsed={isSideBarCollapsed}
    timeSeriesEditor={timeSeriesEditor}
    composition={composition}
    variantsEditor={variantsEditor}
    loadTimeSeries={loadTimeSeries}
    updateTimeSeriesObject={updateTimeSeriesObject}
    updateTimeSeriesValue={updateTimeSeriesValue}
    saveTimeSeries={saveTimeSeries}
    setTimeSeriesView={setTimeSeriesView}
    toggleEditorCollapse={toggleEditorCollapse}
    toggleSideBarCollapse={toggleSideBarCollapse}
    setInputFocus={setInputFocus}
    setInputHasFocus={setInputHasFocus}
    updateViewMode={updateViewMode}
    cloneTimeSeries={cloneTimeSeries}
    refreshTimeSeries={refreshTimeSeries}

    updateTimeSeriesDataType={updateTimeSeriesDataType}

    updateDerivationSearchKeyText={updateDerivationSearchKeyText}
    addDerivationSearchKeyText={addDerivationSearchKeyText}
    moveDerivationKeyUp={moveDerivationKeyUp}
    moveDerivationKeyDown={moveDerivationKeyDown}
    deleteDerivationKey={deleteDerivationKey}
    addDerivationKeys={addDerivationKeys}

    setFunctionsStepStyleToMultiStep={setFunctionsStepStyleToMultiStep}
    setFunctionsType={setFunctionsType}
    addFunctionsStepParameter={addFunctionsStepParameter}
    deleteFunctionsStepParameter={deleteFunctionsStepParameter}
    updateFunctionParameterValue={updateFunctionParameterValue}
    addMultiStepFunction={addMultiStepFunction}
    deleteMultiStepFunction={deleteMultiStepFunction}
    moveMultiStepFunctionUp={moveMultiStepFunctionUp}
    moveMultiStepFunctionDown={moveMultiStepFunctionDown}
    addFunctionKey={addFunctionKey}
    deleteFunctionKey={deleteFunctionKey}
    moveFunctionKeyUp={moveFunctionKeyUp}
    moveFunctionKeyDown={moveFunctionKeyDown}
    addFunctionOutputCategory={addFunctionOutputCategory}
    updateFunctionOutputKey={updateFunctionOutputKey}
    updateFunctionOutputCategoryValue={updateFunctionOutputCategoryValue}
    deleteFunctionOutputCategory={deleteFunctionOutputCategory}
    setFunctionDisabled={setFunctionDisabled}

    addCategory={addCategory}
    updateCategoryValue={updateCategoryValue}
    deleteCategory={deleteCategory}
    deleteAllcategories={deleteAllcategories}
    copyCategoriesCopyFrom={copyCategoriesCopyFrom}
    getCategoryValues={getCategoryValues}

    annotationsEditor={annotationsEditor}
    loadAnnotations={loadAnnotations}
    setAnnotationsTimeZone={setAnnotationsTimeZone}
    updateAnnotationsFilter={updateAnnotationsFilter}
    applyAnnotationsFilter={applyAnnotationsFilter}
    clearAnnotationsFilter={clearAnnotationsFilter}
    addAnnotation={addAnnotation}
    editAnnotation={editAnnotation}
    deleteAnnotation={deleteAnnotation}
    saveAnnotation={saveAnnotation}
    cancelAnnotationEdit={cancelAnnotationEdit}
    setAnnotationKey={setAnnotationKey}
    setAnnotationFromDate={setAnnotationFromDate}
    setAnnotationToDate={setAnnotationToDate}
    setAnnotationPriority={setAnnotationPriority}
    setAnnotationText={setAnnotationText}
    sortAnnotations={sortAnnotations}

    materialiseRangeDialogVisible={materialiseRangeDialogVisible}
    materialiseRangeFromUtc={materialiseRangeFromUtc}
    materialiseRangeToUtc={materialiseRangeToUtc}
    requestRematerialise={requestRematerialise}
    requestRematerialiseWithRange={requestRematerialiseWithRange}
    setMaterialiseRangeDialogVisible={setMaterialiseRangeDialogVisible}
    setMaterialiseRangeDate={setMaterialiseRangeDate}
    suppressArchive={suppressArchive}
    setSuppressArchive={setSuppressArchive}
    setMaterialiseStatusToEnabled={setMaterialiseStatusToEnabled}

    loadVariants={loadVariants}
    addVariant={addVariant}
    cancelVariantEdit={cancelVariantEdit}
    setVariantValue={setVariantValue}
    saveVariant={saveVariant}
    
    adjustmentsEditor={adjustmentsEditor}
    loadAdjustments={loadAdjustments}
    setAdjustmentsFilter={setAdjustmentsFilter}
    editAdjustmentValues={editAdjustmentValues}
    saveAdjustments={saveAdjustments}
    applyAdjustmentsRange={applyAdjustmentsRange}

    copyLinkToClipboard={copyLinkToClipboard}
    isContributor={isContributor}
    isWizard={isWizard}

    basedOnSettings={basedOnSettings}
    basedOnAddKey={basedOnAddKey}
    basedOnDeleteKey={basedOnDeleteKey}
    basedOnUpdateSearchKeyText={basedOnUpdateSearchKeyText}
    basedOnUpdateSettings={basedOnUpdateSettings}
  />;
});

export default TimeSeriesDetailsConnected;