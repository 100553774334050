import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Converter } from 'showdown';
import {
  dashboardTileMarkdownRefresh
} from '../../../actions/dashboard-tiles-markdown';

const MarkdownTile = connect(
  (state, { stateKey }) => {
    const refreshRequired = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'refreshRequired']);
    const layoutChanged = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'layoutChanged']);
    const markdown = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'markdown']);
    const clipContents = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'clipContents']);
    return {
      refreshRequired,
      layoutChanged,
      markdown,
      clipContents
    };
  },
  (dispatch, { stateKey }) => ({
    refresh(){
      dispatch(dashboardTileMarkdownRefresh({ stateKey }));
    }
  })
)(({ refreshRequired, markdown, clipContents, refresh }) => {
  useEffect(() => {
    if (refreshRequired)
      refresh();
  }, [refreshRequired, refresh]);

  const converter = new Converter({
    openLinksInNewWindow: true,
    simpleLineBreaks: true,
    tables: true
  });

  const html = converter.makeHtml(markdown);
  const overflowValue = clipContents ? 'hidden' : 'auto';
  return <div style={{height:'100%', overflowX:overflowValue, overflowY:overflowValue}} dangerouslySetInnerHTML={{ __html: html }}></div>
});

export default MarkdownTile;