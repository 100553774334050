import React from 'react';
import { Routes, Route } from 'react-router';
import { SelectionBasketComponent } from './SelectionBasketComponent';
import { ChartBasketConnected } from './ChartBasketConnected';

export const BasketConnectComponent = ({ toggleIsPinned }) => {
  return (
    <Routes>
        <Route path='/chart/*' element={<ChartBasketConnected toggleIsPinned={toggleIsPinned} />} />
        <Route path='/split-chart/*' element={<ChartBasketConnected toggleIsPinned={toggleIsPinned} />} />
        <Route path='/chart-data/*' element={<ChartBasketConnected toggleIsPinned={toggleIsPinned} />} />
        <Route exact path='/*' element={<SelectionBasketComponent toggleIsPinned={toggleIsPinned} />} />
    </Routes>
  );
};