import React from 'react';
import qs from 'querystring';
import { copyToClipboard } from '../../../utility/text-utility';
import HomeImageSelectContent from './HomeImageSelectContent';
import WorkspaceModalContent from './WorkspaceModalContent';
import WorkspaceHitCountModalConnected from './WorkspaceHitCountModalConnected';
import HomeNewItemContent from './HomeNewItemContent';
import FormattedDateTime from '../../shared/FormattedDateTime';

import '../../../styles/homepage.css';

const onShareUrl = data => {
  const { type = '', path, scope } = data;

  let url = '';
  switch (type.toLowerCase()) {
    case 'analysis': url = `${window.location.origin}/analysis?${qs.stringify({ workspacePath: path })}`; break;
    case 'report': url = `${window.location.origin}/${path.replace(/\s/g, '%20')}`.replace(/\/+/g, '/'); break;
    case 'folder': url = `${window.location.origin}/home/${path.replace(/\s/g, '%20')}`.replace(/\/+/g, '/'); break;
    case 'dashboard': url = `${window.location.origin}/dashboard/${scope}/${path.replace(/\s/g, '%20')}`.replace(/\/+/g, '/'); break;
    case 'storageauction': url = `${window.location.origin}/storage-auctions/${scope}/${path.replace(/\s/g, '%20')}`.replace(/\/+/g, '/'); break;
    default: url = path; break;
  }

  if (url) copyToClipboard(url);
};

function FolderContextMenu({ modalRef, item, showItemMenu }) {
  function onOpenImageModal() {
    if (modalRef.current) modalRef.current.onShow({
      content: <HomeImageSelectContent item={item} />
    });
  }

  return showItemMenu && (
    <div className='dropdown folder-context-menu'>
      <button className='btn btn-light bg-transparent p-0 px-1' type='button' data-toggle='dropdown'>
        <i className='fas fa-ellipsis-h fa-fw' />
      </button>
      <div className='dropdown-menu' onClick={e => e.stopPropagation()}>
        <button className='dropdown-item' type='button' onClick={() => onShareUrl(item)}>
          <i className='fas fa-copy fa-fw mr-1' /> Copy url to clipboard
        </button>
        <button className='dropdown-item' type='button' onClick={() => onOpenImageModal()}>
          <i className='fas fa-image fa-fw mr-1' /> Set image
        </button>
      </div>
    </div>
  );
}

function FavouriteToggle({ item, addFavourite, removeFavourite }) {
  const { id, isFavourite } = item;

  function onToggle(e) {
    e.stopPropagation();
    e.preventDefault();

    if (isFavourite) removeFavourite(id);
    else addFavourite(id);
  }

  return (
    <div className='home-item-favourite' onClick={onToggle}>
      <button className='btn btn-link py-0 px-1 rounded' type='button'>
        {isFavourite
          ? <i className='fas fa-star fa-fw' style={{ color: 'gold' }} />
          : <i className='far fa-star fa-fw text-dark' />}
      </button>
    </div>
  );
}

function ContextMenu({ modalRef, item, initHomeItemModel, clearWorkspaceModel, deleteWorkspace, showItemMenu, openWorkspaceHitCount }) {
  const { id, type } = item;

  function onOpenImageModal() {
    if (modalRef.current) modalRef.current.onShow({
      content: <HomeImageSelectContent item={item} />
    });
  }

  function onOpenWorkspaceModal(mode) {
    clearWorkspaceModel();

    if (modalRef.current) modalRef.current.onShow({
      isLarge: true,
      content: <WorkspaceModalContent mode={mode} workspaceId={id} />
    });
  }

  function onOpenHomeItemModal(mode) {
    initHomeItemModel();

    if (modalRef.current) modalRef.current.onShow({
      content: <HomeNewItemContent mode={mode} workspaceId={id} />
    });
  }

  const onEdit = type === 'WebLink' || type === 'NetworkFile'
    ? () => onOpenHomeItemModal('Edit')
    : () => onOpenWorkspaceModal('Edit');

  const canOpenWorkspaceHitCount = typeof openWorkspaceHitCount === 'function' && !!id;

  return showItemMenu && (
    <div className='dropdown default-context-menu'>
      <button className='btn btn-light bg-transparent p-0 px-1' type='button' data-toggle='dropdown'>
        <i className='fas fa-ellipsis-h fa-fw' />
      </button>
      <div className='dropdown-menu' onClick={e => e.preventDefault()}>
        <button className='dropdown-item' type='button' onClick={onEdit}>
          <i className='fas fa-edit fa-fw mr-1' /> Edit
        </button>
        <button className='dropdown-item' type='button' onClick={() => onOpenWorkspaceModal('SaveAs')}>
          <i className='fas fa-save fa-fw mr-1' /> Save as
        </button>
        <button className='dropdown-item' type='button' onClick={() => onShareUrl(item)}>
          <i className='fas fa-copy fa-fw mr-1' /> Copy url to clipboard
        </button>
        <button className='dropdown-item' type='button' onClick={() => onOpenImageModal()}>
          <i className='fas fa-image fa-fw mr-1' /> Set image
        </button>
        { canOpenWorkspaceHitCount && (
          <button className='dropdown-item' type='button' onClick={() => openWorkspaceHitCount(item.id)}>
            <i className='far fa-list-alt fa-fw mr-1' /> Hit Count
          </button>
        ) }
        <button className='dropdown-item' type='button' onClick={() => deleteWorkspace(item.id)}>
          <i className='fas fa-trash fa-fw mr-1' /> Delete
        </button>
      </div>
    </div>
  );
}

function Statistics({ item, displayMode, openWorkspaceHitCount }){
  const { id, loadedCount } = item;
  if (!loadedCount)
    return <></>

  function onOpenWorkspaceStatistics(e) {
    if (typeof openWorkspaceHitCount !== 'function' || !id)
      return;

    e.stopPropagation();
    e.preventDefault();
    openWorkspaceHitCount(id);
  }

  if (displayMode === 'list-compact')
    return <div className='home-item-loaded-count text-nowrap' onClick={onOpenWorkspaceStatistics}>Hit count: {(loadedCount).toLocaleString(undefined, { useGrouping: true })}</div>

  const loadedCountText = loadedCount > 99 ? '99+' : `${loadedCount}`;
  return <div className='home-item-loaded-count badge badge-primary' title={loadedCount} onClick={onOpenWorkspaceStatistics}>{loadedCountText}</div>
}

function Folder({ modalRef, item, scope, setPath, showItemMenu, displayMode }) {
  const { type, name, path, imageUrl } = item;

  function onSetPath(e){
    setPath({ path, scope, item });
  }

  switch (displayMode) {
    case 'tiles':
      return (
        <div className='home-item-container'>
          <div className='card home-folder home-item' onClick={onSetPath}>
            <Statistics item={item} displayMode={displayMode} />
            <img className='home-item-image card-img' src={imageUrl} alt='' />
          </div>
          <FolderContextMenu modalRef={modalRef} item={item} showItemMenu={showItemMenu} />
          <div className='text-center' style={{ wordBreak: 'break-word' }}>
            {name}
          </div>
        </div>);

    default:
      return (
        <div className='list-group-item list-group-item-action cursor-pointer p-0' >
          <div className='home-item' onClick={onSetPath}>
            <div>
              <img src={imageUrl} alt='' />
              <Statistics item={item} displayMode={displayMode} />
            </div>
            <div>
              <div>
                <div>{scope}</div>
                <div>{type}</div>
              </div>
              <div>
                <div>{name}</div>
              </div>
            </div>
          </div>
          <FolderContextMenu modalRef={modalRef} item={item} showItemMenu={showItemMenu} />
        </div>
        );
  }
}

function Analysis({ modalRef, item, setPath, addFavourite, removeFavourite, clearWorkspaceModel, selectWorkspace, deleteWorkspace, openWorkspaceHitCount, showItemMenu, displayMode }) {
  const { type, scope, id, name, parentFolderPath, imageUrl, updatedBy, updatedUtc } = item;

  switch (displayMode) {
    case 'tiles':
      return <div className='home-item-container'>
        <div className='home-item card' onClick={() => selectWorkspace({ workspaceId: id, item })}>
          <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
          <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount}/>
          <img className='home-item-image card-img' src={imageUrl} alt='' />
        </div>
        <ContextMenu modalRef={modalRef} item={item} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} openWorkspaceHitCount={openWorkspaceHitCount} />
        <div className='text-center' style={{ wordBreak: 'break-word' }}>
          {name}
        </div>
      </div>;

    default:
      return <div className='home-item-container list-group-item list-group-item-action cursor-pointer p-0' >
          <div className='home-item' onClick={() => selectWorkspace({ workspaceId: id, item })}>
            <div>
              <img src={imageUrl} alt='' />
              <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
              <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount}/>
            </div>
            <div>
              <div>
                <div>{scope}</div>
                <div>{type}</div>
              </div>
              <div>
                <div>{name}</div>
                <div>
                  <button className='btn btn-sm btn-outline-primary py-0 px-1' type='button' onClick={e => setPath({ e, path: parentFolderPath, scope })}>
                    <i className='fas fa-folder fa-fw' /> {parentFolderPath || '/'}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                {updatedBy}
              </div>
              <div>
                <FormattedDateTime>{updatedUtc}</FormattedDateTime>
              </div>
            </div>
          </div>
          <ContextMenu modalRef={modalRef} item={item} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} openWorkspaceHitCount={openWorkspaceHitCount} />
        </div>;
  }
}

function Report({ modalRef, item, setPath, addFavourite, removeFavourite, clearWorkspaceModel, selectWorkspace, deleteWorkspace, openWorkspaceHitCount, showItemMenu, displayMode }) {
  const { type, scope, name, parentFolderPath, path, imageUrl, updatedBy, updatedUtc } = item;

  switch (displayMode) {
    case 'tiles':
      return <div className='home-item-container'>
        <div className='card home-item' onClick={() => selectWorkspace({ path, item })}>
          <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
          <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount}/>
          <img className='home-item-image card-img' src={imageUrl} alt='' />
        </div>
        <ContextMenu modalRef={modalRef} item={item} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} openWorkspaceHitCount={openWorkspaceHitCount} />
        <div className='text-center' style={{ wordBreak: 'break-word' }}>
          {name}
        </div>
      </div>;

    default:
      return (
        <div className='home-item-container list-group-item list-group-item-action cursor-pointer p-0' >
          <div className='home-item' onClick={() => selectWorkspace({ path, item })}>
            <div>
              <img src={imageUrl} alt='' />
              <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
             <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount}/>
            </div>
            <div>
              <div>
                <div>{scope}</div>
                <div>{type}</div>
              </div>
              <div>
                <div>{name}</div>
                <div>
                  <button className='btn btn-sm btn-outline-primary py-0 px-1' type='button' onClick={e => setPath({ e, path: parentFolderPath, scope })}>
                    <i className='fas fa-folder fa-fw' /> {parentFolderPath || '/'}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                {updatedBy}
              </div>
              <div>
                <FormattedDateTime>{updatedUtc}</FormattedDateTime>
              </div>
            </div>
          </div>
          <ContextMenu modalRef={modalRef} item={item} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} openWorkspaceHitCount={openWorkspaceHitCount} />
        </div>);
  }
}

function WebLink({ modalRef, item, setPath, addFavourite, removeFavourite, initHomeItemModel, clearWorkspaceModel, selectWebLink, deleteWorkspace, showItemMenu, displayMode }) {
  const { type, scope, name, parentFolderPath, path, imageUrl, updatedBy, updatedUtc } = item;

  switch (displayMode) {
    case 'tiles':
      return <div className='home-item-container'>
        <a className='card home-item' onClick={e => selectWebLink && selectWebLink({e, item})} href={path} target='_blank' rel='noopener noreferrer'>
          <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
          <img className='home-item-image card-img' src={imageUrl} alt='' />
        </a>
        <ContextMenu modalRef={modalRef} item={item} initHomeItemModel={initHomeItemModel} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} />
        <div className='text-center' style={{ wordBreak: 'break-word' }}>
          {name}
        </div>
      </div>;

    default:
      return (
        <a className='home-item-container list-group-item list-group-item-action cursor-pointer p-0' onClick={e => selectWebLink && selectWebLink({e, item})} href={path} target='_blank' rel='noopener noreferrer'>
          <div className='home-item' >
            <div>
              <img className='home-item-image' src={imageUrl} alt='' />
              <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
              <ContextMenu modalRef={modalRef} item={item} initHomeItemModel={initHomeItemModel} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} />
            </div>
            <div>
              <div>
                <div>{scope}</div>
                <div>{type}</div>
              </div>
              <div>
                <div>{name}</div>
                <div>
                  <button className='btn btn-sm btn-outline-primary py-0 px-1' type='button' onClick={e => setPath({ e, path: parentFolderPath, scope })}>
                    <i className='fas fa-folder fa-fw' /> {parentFolderPath || '/'}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                {updatedBy}
              </div>
              <div>
                <FormattedDateTime>{updatedUtc}</FormattedDateTime>
              </div>
            </div>
          </div>
        </a>);
  }
}

function NetworkFile(props) {
  return <WebLink {...props} />
}

function Dashboard({ modalRef, item, setPath, addFavourite, removeFavourite, initHomeItemModel, clearWorkspaceModel, selectDashboardLink, deleteWorkspace, showItemMenu, displayMode, openWorkspaceHitCount }) {
  const { type, scope, name, parentFolderPath, path, imageUrl, updatedBy, updatedUtc } = item;

  switch (displayMode) {
    case 'tiles':
      return <div className='home-item-container'>
        <a className='card home-item' onClick={e => selectDashboardLink && selectDashboardLink({e, item})} href={`/dashboard/${scope}/${path}`} >
          <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
          <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount} />
          <img className='home-item-image card-img' src={imageUrl} alt='' />
        </a>
        <ContextMenu modalRef={modalRef} item={item} initHomeItemModel={initHomeItemModel} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} />
        <div className='text-center' style={{ wordBreak: 'break-word' }}>
          {name}
        </div>
      </div>;

    default:
      return (
        <div className='home-item-container list-group-item list-group-item-action cursor-pointer p-0' >
          <a className='home-item' onClick={e => selectDashboardLink && selectDashboardLink({e, item})} href={`/dashboard/${scope}/${path}`}>
            <div>
              <img className='home-item-image' src={imageUrl} alt='' />
              <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
              <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount} />
            </div>
            <div>
              <div>
                <div>{scope}</div>
                <div>{type}</div>
              </div>
              <div>
                <div>{name}</div>
                <div>
                  <button className='btn btn-sm btn-outline-primary py-0 px-1' type='button' onClick={e => setPath({ e, path: parentFolderPath, scope })}>
                    <i className='fas fa-folder fa-fw' /> {parentFolderPath || '/'}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                {updatedBy}
              </div>
              <div>
                <FormattedDateTime>{updatedUtc}</FormattedDateTime>
              </div>
            </div>
          </a>
          <ContextMenu modalRef={modalRef} item={item} initHomeItemModel={initHomeItemModel} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} />
        </div>);
  }
}

function AnalysisTable({ modalRef, item, setPath, addFavourite, removeFavourite, initHomeItemModel, clearWorkspaceModel, selectAnalysisTableLink, deleteWorkspace, showItemMenu, displayMode, openWorkspaceHitCount }) {
  const { type, scope, name, parentFolderPath, path, imageUrl, updatedBy, updatedUtc } = item;

  switch (displayMode) {
    case 'tiles':
      return <div className='home-item-container'>
        <div className='card home-item' onClick={e => selectAnalysisTableLink && selectAnalysisTableLink({e, item})}>
          <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
          <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount} />
          <img className='home-item-image card-img' src={imageUrl} alt='' />
        </div>
        <ContextMenu modalRef={modalRef} item={item} initHomeItemModel={initHomeItemModel} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} openWorkspaceHitCount={openWorkspaceHitCount} />
        <div className='text-center' style={{ wordBreak: 'break-word' }}>
          {name}
        </div>
      </div>;

    default:
      return (
        <div className='home-item-container list-group-item list-group-item-action cursor-pointer p-0' >
          <div className='home-item' onClick={e => selectAnalysisTableLink && selectAnalysisTableLink({e, item})} href={`/dashboard/${scope}/${path}`}>
            <div>
              <img className='home-item-image' src={imageUrl} alt='' />
              <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
              <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount} />
            </div>
            <div>
              <div>
                <div>{scope}</div>
                <div>{type}</div>
              </div>
              <div>
                <div>{name}</div>
                <div>
                  <button className='btn btn-sm btn-outline-primary py-0 px-1' type='button' onClick={e => setPath({ e, path: parentFolderPath, scope })}>
                    <i className='fas fa-folder fa-fw' /> {parentFolderPath || '/'}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                {updatedBy}
              </div>
              <div>
                <FormattedDateTime>{updatedUtc}</FormattedDateTime>
              </div>
            </div>
          </div>
          <ContextMenu modalRef={modalRef} item={item} initHomeItemModel={initHomeItemModel} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} openWorkspaceHitCount={openWorkspaceHitCount} />
        </div>);
  }
}

function ItemPanel({ modalRef, item, setPath, addFavourite, removeFavourite, initHomeItemModel, clearWorkspaceModel, selectItem, deleteWorkspace, showItemMenu, displayMode, openWorkspaceHitCount }) {
  const { type, scope, name, parentFolderPath, imageUrl, updatedBy, updatedUtc } = item;

  switch (displayMode) {
    case 'tiles':
      return <div className='home-item-container'>
        <div className='card home-item' onClick={e => selectItem && selectItem(item)}>
          <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
          <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount} />
          <img className='home-item-image card-img' src={imageUrl} alt='' />
        </div>
        <ContextMenu modalRef={modalRef} item={item} initHomeItemModel={initHomeItemModel} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} openWorkspaceHitCount={openWorkspaceHitCount} />
        <div className='text-center' style={{ wordBreak: 'break-word' }}>
          {name}
        </div>
      </div>;

    default:
      return (
        <div className='home-item-container list-group-item list-group-item-action cursor-pointer p-0' >
          <div className='home-item' onClick={e => selectItem && selectItem(item)} >
            <div>
              <img className='home-item-image' src={imageUrl} alt='' />
              <FavouriteToggle item={item} addFavourite={addFavourite} removeFavourite={removeFavourite} />
              <Statistics item={item} displayMode={displayMode} openWorkspaceHitCount={openWorkspaceHitCount} />
            </div>
            <div>
              <div>
                <div>{scope}</div>
                <div>{type}</div>
              </div>
              <div>
                <div>{name}</div>
                <div>
                  <button className='btn btn-sm btn-outline-primary py-0 px-1' type='button' onClick={e => setPath({ e, path: parentFolderPath, scope })}>
                    <i className='fas fa-folder fa-fw' /> {parentFolderPath || '/'}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                {updatedBy}
              </div>
              <div>
                <FormattedDateTime>{updatedUtc}</FormattedDateTime>
              </div>
            </div>
          </div>
          <ContextMenu modalRef={modalRef} item={item} initHomeItemModel={initHomeItemModel} clearWorkspaceModel={clearWorkspaceModel} deleteWorkspace={deleteWorkspace} showItemMenu={showItemMenu} openWorkspaceHitCount={openWorkspaceHitCount} />
        </div>);
  }
}

function HomePageContentItem({ modalRef, item, scope, setPath, addFavourite, removeFavourite, initHomeItemModel, clearWorkspaceModel,
  selectAnalysisWorkspace, selectReportWorkspace, selectWebLink, selectDashboardLink, selectAnalysisTableLink, selectItem,
  deleteWorkspace, openWorkspaceHitCount, showItemMenu, displayMode }) {
  let component;

  function onSetParentPath({ e, path, scope }) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    setPath({ path, scope });
  }

  switch (item.type) {
    case 'Folder':
      component = <Folder modalRef={modalRef}
        item={item}
        scope={scope}
        setPath={setPath}
        showItemMenu={showItemMenu}
        displayMode={displayMode} />;
      break;
    case 'Analysis':
      component = <Analysis modalRef={modalRef}
        item={item}
        setPath={onSetParentPath}
        addFavourite={addFavourite}
        removeFavourite={removeFavourite}
        clearWorkspaceModel={clearWorkspaceModel}
        selectWorkspace={selectAnalysisWorkspace}
        deleteWorkspace={deleteWorkspace}
        openWorkspaceHitCount={openWorkspaceHitCount}
        showItemMenu={showItemMenu}
        displayMode={displayMode} />;
      break;
    case 'Report':
      component = <Report modalRef={modalRef}
        item={item}
        setPath={onSetParentPath}
        addFavourite={addFavourite}
        removeFavourite={removeFavourite}
        clearWorkspaceModel={clearWorkspaceModel}
        selectWorkspace={selectReportWorkspace}
        deleteWorkspace={deleteWorkspace}
        openWorkspaceHitCount={openWorkspaceHitCount}
        showItemMenu={showItemMenu}
        displayMode={displayMode} />;
      break;
    case 'NetworkFile':
      component = <NetworkFile modalRef={modalRef}
        item={item}
        setPath={onSetParentPath}
        addFavourite={addFavourite}
        removeFavourite={removeFavourite}
        initHomeItemModel={initHomeItemModel}
        clearWorkspaceModel={clearWorkspaceModel}
        selectWebLink={selectWebLink}
        deleteWorkspace={deleteWorkspace}
        showItemMenu={showItemMenu}
        displayMode={displayMode} />;
      break;
    case 'WebLink':
      component = <WebLink modalRef={modalRef}
        item={item}
        setPath={onSetParentPath}
        addFavourite={addFavourite}
        removeFavourite={removeFavourite}
        initHomeItemModel={initHomeItemModel}
        clearWorkspaceModel={clearWorkspaceModel}
        selectWebLink={selectWebLink}
        deleteWorkspace={deleteWorkspace}
        showItemMenu={showItemMenu}
        displayMode={displayMode} />;
      break;
    case 'Dashboard':
      component = <Dashboard modalRef={modalRef}
        item={item}
        setPath={onSetParentPath}
        addFavourite={addFavourite}
        removeFavourite={removeFavourite}
        initHomeItemModel={initHomeItemModel}
        clearWorkspaceModel={clearWorkspaceModel}
        selectDashboardLink={selectDashboardLink}
        deleteWorkspace={deleteWorkspace}
        openWorkspaceHitCount={openWorkspaceHitCount}
        showItemMenu={showItemMenu}
        displayMode={displayMode} />;
      break;
    case 'AnalysisTable':
      component = <AnalysisTable modalRef={modalRef}
        item={item}
        setPath={onSetParentPath}
        addFavourite={addFavourite}
        removeFavourite={removeFavourite}
        initHomeItemModel={initHomeItemModel}
        clearWorkspaceModel={clearWorkspaceModel}
        selectAnalysisTableLink={selectAnalysisTableLink}
        deleteWorkspace={deleteWorkspace}
        openWorkspaceHitCount={openWorkspaceHitCount}
        showItemMenu={showItemMenu}
        displayMode={displayMode} />;
      break;
      case 'StorageAuction':        
        component = <ItemPanel modalRef={modalRef}
        item={item}
        setPath={onSetParentPath}
        addFavourite={addFavourite}
        removeFavourite={removeFavourite}
        initHomeItemModel={initHomeItemModel}
        clearWorkspaceModel={clearWorkspaceModel}
        selectItem={selectItem}
        deleteWorkspace={deleteWorkspace}
        openWorkspaceHitCount={openWorkspaceHitCount}
        showItemMenu={showItemMenu}
        displayMode={displayMode} />
        break;
    default:
      component = <div />;
  }

  return component;
}

function HomePageContents({ modalRef, scope, items, setPath, addFavourite, removeFavourite, initHomeItemModel, clearWorkspaceModel,
  selectAnalysisWorkspace, selectReportWorkspace, selectWebLink, selectDashboardLink, selectAnalysisTableLink, selectItem,
  deleteWorkspace, openWorkspaceHitCount, showItemMenu, displayMode }) {
  let contentClassName = '';
  switch (displayMode) {
    case 'tiles':
      contentClassName = 'home-content-tiles d-flex flex-wrap'; break;
    case 'list-compact':
      contentClassName = 'home-content-list-compact list-group m-2'; break;
    case 'contents':
    default:
      contentClassName = 'home-content-list list-group m-2'; break;
  }

  return (
    <div className={contentClassName}>
      <WorkspaceHitCountModalConnected />
      { items.map((i, ix) => (
        <HomePageContentItem key={ix}
          modalRef={modalRef}
          item={i}
          scope={scope}
          setPath={setPath}
          addFavourite={addFavourite}
          removeFavourite={removeFavourite}
          initHomeItemModel={initHomeItemModel}
          clearWorkspaceModel={clearWorkspaceModel}
          selectAnalysisWorkspace={selectAnalysisWorkspace}
          selectReportWorkspace={selectReportWorkspace}
          selectWebLink={selectWebLink}
          selectDashboardLink={selectDashboardLink}
          selectAnalysisTableLink={selectAnalysisTableLink}
          selectItem={selectItem}
          deleteWorkspace={deleteWorkspace}
          openWorkspaceHitCount={openWorkspaceHitCount}
          showItemMenu={showItemMenu}
          displayMode={displayMode} />
      ))}
    </div>
  );
}

export default HomePageContents;