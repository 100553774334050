import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { formatTitle } from '../../../utility/analysis-search-utility';
import FormattedNumber from '../../shared/FormattedNumber';

export class SchemaSelector extends Component {
  static propTypes = {
    schemas: PropTypes.arrayOf(PropTypes.shape({
      schemaName: PropTypes.string.isRequired,
      colour: PropTypes.string.isRequired,
      resultsCount: PropTypes.number,
      isSelected: PropTypes.bool,
      errorMessage: PropTypes.string
    })).isRequired,
    toggleSchema: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { toggleSchema, schemas } = this.props;

    return <div className='fota-facets pl-0 pr-0' >
      <ul className='facet-group list-group' style={{ maxHeight: '100%' }}>
        <li className='list-group-item list-group-header text-nowrap text-center text-uppercase' >
          Schemas
        </li>

        <ul data_id='schema-selector' className='list-group'>
          {schemas.map((schema, index) => {
            const hasErrors = schema.errorMessage && schema.errorMessage.length > 0;
            return <li data_id={`schema-${index}`} key={`k-${index}`} onClick={() => toggleSchema(schema.schemaName)} className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'>
              <div data_id='schema-name' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} title={formatTitle(schema.schemaName)}>
                {formatTitle(schema.schemaName)}
              </div>
              <div className='text-nowrap'>
                {schema.isSelected && <>
                  {!hasErrors && schema.resultsCount !== undefined && <span className='badge'><FormattedNumber>{schema.resultsCount}</FormattedNumber><div data_id='is-selected' style={{display:'none'}}>true</div></span>}
                  {hasErrors && <span title={`${schema.errorMessage}`} className="text-danger mr-1" ><i className="fas fa-exclamation-triangle fa-fw"></i><div data_id='is-selected' style={{display:'none'}}>true</div></span>}
                </>}
                <span style={{ color: schema.colour }}><i className={schema.isSelected ? 'fas fa-check-square fa-lg fa-fw' : 'far fa-square fa-lg fa-fw'} /></span>
              </div>
            </li>
          })}
        </ul>
      </ul>
    </div>
  }
}