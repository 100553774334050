import React, { useRef, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { REPORTING_API_ROOT_URL } from '../../../config';
import JSONEditor from 'jsoneditor';
import useFetch from '../../../hooks/useFetch';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import Loading from '../../shared/Loading';
import PathNavLink from '../../shared/PathNavLink';
import {
  reportsUpdate
} from '../../../actions/reports';

const ReportsEditor = connect(
  (state) => ({
    reportPath: state.getIn(['reports', 'criteria', 'reportPath']),
    isContributor: state.getIn(['roles', 'isContributor']),
  }),
  (dispatch) => ({
    save(data) {
      dispatch(reportsUpdate(data));
    }
  })
)(({ reportPath, save, isContributor }) => {
  const [editor, setEditor] = useState(null);

  const [editorDataUrl] = useState(`${REPORTING_API_ROOT_URL}/v1/timeseries-defined-reports/definition?reportPath=${reportPath}`);

  const [, editorData, isEditorDataLoading] = useFetch(editorDataUrl);

  const editorRef = useRef();

  const createEditors = useCallback(() => {
    if (editor) return [editor];

    return [
      new JSONEditor(editorRef.current, {
        mode: 'code',
        modes: ['code', 'tree']
      }, editorData)
    ];
  }, [editor, editorData]);

  useDidMountEffect(() => {
    let [editor] = createEditors();

    setEditor(editor);

    return () => {
      editor.destroy();
    };
  });

  useDidUpdateEffect(() => {
    if (editor) editor.set(editorData);
  }, [editor, editorData]);

  const onSubmit = useCallback(e => {
    e.preventDefault();

    const data = editor.get();

    save(data);
  }, [save, editor]);

  const onRefreshEditor = useCallback(e => {
    e.preventDefault();

    editor.set(editorData);
  }, [editor, editorData]);

  return (
    <main className='d-flex flex-column'>
      <Loading isLoading={ isEditorDataLoading } message='Loading...'>
        <div className='d-flex flex-row h-100'>
          <div className='h-100 w-100'>
            <div ref={ editorRef } className='h-100'></div>
          </div>
        </div>
        <div className='d-flex flex-row'>
          <div className='py-2 px-2 w-100'>
            <form className='d-flex justify-content-center form-inline' onSubmit={ onSubmit }>
              <div className='form-group'>
                <PathNavLink className='btn btn-outline-secondary' to={ `/reports/${reportPath}` }>Return to report</PathNavLink>
              </div>
              <div className='form-group ml-2'>
                <button type='button' className='btn btn-secondary' onClick={ onRefreshEditor }>Reset</button>
              </div>
              <div className='form-group ml-2'>
                {isContributor && <button type='submit' className='btn btn-primary'>Save</button>}
              </div>
            </form>
          </div>
        </div>
      </Loading>
    </main>
  );
});

export default ReportsEditor;