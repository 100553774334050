import React from 'react';
import RelativeDate from '../../shared/RelativeDate';
import InfoPopup from '../../shared/InfoPopup';
import { comparisonModeRelativeDateOptions } from '../../../utility/comparisonmode-utility';

export function AggregateRelativeDatesSelector({ comparisonMode, comparisonSettings, toggleAggregationOperation, setAggregateRelativeDate, addAggregateRelativeDatesToSelection, setAggregateRelativeFromDate, setAggregateRelativeToDate, addAggregateRelativeRangeToSelection, addAggregateRelativeToPreselection, removeAggregateRelativeFromPreselection }) {
  const onSetRelativeDateText = (value) => {
    setAggregateRelativeDate(comparisonMode, value);
  }

  const onAddAggregateRelativeToPreselection = (e) => {
    addAggregateRelativeToPreselection(comparisonMode);
  }

  const onAddRelativeDatesToSelection = (e) => {
    addAggregateRelativeDatesToSelection(comparisonMode);
  }

  const onToggleAggregationOperation = (e) => {
    toggleAggregationOperation(comparisonMode, e.target.value, e.target.checked === true);
  }

  const onRemoveAggregateRelativeFromPreselection = (e) => {
    removeAggregateRelativeFromPreselection(comparisonMode, e);
  }

  const onSetAggregateRelativeFromDate = (e) => {
    setAggregateRelativeFromDate(comparisonMode, e);
  }

  const onSetAggregateRelativeToDate = (e) => {
    setAggregateRelativeToDate(comparisonMode, e);
  }

  const onAddAggregateRelativeRangeToSelection = () => {
    addAggregateRelativeRangeToSelection(comparisonMode);
  }

  const isOperationSelected = (value) => {
    return comparisonSettings.view.aggregationRel.operations.some(a => a === value);
  }

  return <div>
    <div className='form-group'>
      <label className='font-weight-bold mr-3'>Operation</label>
      <div className='form-check d-inline-block ml-3'>
        <input className='form-check-input' type='checkbox' id='grouping-avg' value='Avg' checked={isOperationSelected('Avg')} onChange={onToggleAggregationOperation} />
        <label className='form-check-label' htmlFor="grouping-avg">Avg</label>
      </div>

      <div className='form-check d-inline-block ml-3'>
        <input className='form-check-input' type='checkbox' id='grouping-sum' value='Sum' checked={isOperationSelected('Sum')} onChange={onToggleAggregationOperation} />
        <label className='form-check-label' htmlFor="grouping-sum">Sum</label>
      </div>

      <div className='form-check d-inline-block ml-3'>
        <input className='form-check-input' type='checkbox' id='grouping-min' value='Min' checked={isOperationSelected('Min')} onChange={onToggleAggregationOperation} />
        <label className='form-check-label' htmlFor="grouping-min">Min</label>
      </div>

      <div className='form-check d-inline-block ml-3'>
        <input className='form-check-input' type='checkbox' id='grouping-max' value='Max' checked={isOperationSelected('Max')} onChange={onToggleAggregationOperation} />
        <label className='form-check-label' htmlFor="grouping-max">Max</label>
      </div>
    </div>

    <div className='form-group'>
      <div className='input-group' >
        <label className='fota-input-group-label font-weight-bold'>Range</label>
        <RelativeDate value={comparisonSettings.view.aggregationRel.relativeFromDate} onChange={e => onSetAggregateRelativeFromDate(e)} options={comparisonModeRelativeDateOptions[comparisonMode]} />
        <RelativeDate className='ml-2' value={comparisonSettings.view.aggregationRel.relativeToDate} onChange={e => onSetAggregateRelativeToDate(e)} options={comparisonModeRelativeDateOptions[comparisonMode]} />
        <button type='button' className='btn btn-primary ml-2' onClick={() => onAddAggregateRelativeRangeToSelection()}>Add Range</button>
        <InfoPopup>
          Add a contiguous range of dates to aggregate into a time series view
          <br />
          <sub>For today use &quot;0  Days&quot;</sub>
        </InfoPopup>
      </div>
      {comparisonSettings.view.aggregationRel.showAddRangePreselectionErrorMessage && <div className="alert alert-warning mt-2" >Please select 1 or more operations</div>}
    </div>

    <div className='form-group'>
      <div className='input-group' >
        <label className='fota-input-group-label font-weight-bold'>Dates</label>
        <RelativeDate value={comparisonSettings.view.aggregationRel.relativeDate} onChange={e => onSetRelativeDateText(e)} options={comparisonModeRelativeDateOptions[comparisonMode]} />
        <button type='button' className='btn btn-primary ml-2' onClick={() => onAddAggregateRelativeToPreselection()}><i className='fas fa-plus fa-fw' /></button>
      </div>
    </div>

    <div className='form-group'>
      <div className='input-group' >
        <div className='form-control ml-2' style={{ padding: "0", height: "3em", overflowY: "auto" }}>
          {comparisonSettings.view.aggregationRel.options.dates.map(s => <div key={`${s.mode}-${s.name}`} className='btn btn-outline-secondary btn-sm mr-1 mb-1 ml-1 mt-1' onClick={() => onRemoveAggregateRelativeFromPreselection(s)} >
            {s.name}
            <i className='fas fa-times fa-fw' />
          </div>)}
        </div>

        <button type='button' className='btn btn-primary ml-2' onClick={() => onAddRelativeDatesToSelection()}>Add Relative Dates</button>
        <InfoPopup>
          Add a collection of dates to aggregate into a time series view
          <br/>
          <sub>For today use &quot;0  Days&quot;</sub>
        </InfoPopup>
      </div>
      {comparisonSettings.view.aggregationRel.showAddPreselectionErrorMessage && <div className="alert alert-warning mt-2" >Please select 1 or more operations and dates</div>}
    </div>
  </div>
}