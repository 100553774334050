import React from 'react';
import OutagesDetailsDetailPanel from './OutagesDetailsDetailPanel';
import OutagesDetailsEventPanel from './OutagesDetailsEventPanel';
import OutagesDetailsFotaPanel from './OutagesDetailsFotaPanel';
import OutagesDetailsSourcePanel from './OutagesDetailsSourcePanel';

const labelColumnWidth = '120px';
const valueCellStyles = { paddingLeft: '12px' };

export default function OutagesDetails({ data }) {
  const { messageId, version } = data ?? {};

  if (!data) return (
    <div className='border shadow-sm p-3'>
      <div className='text-center'>
        <strong>No outage selected</strong>
      </div>
    </div>
  );

  const title = `${messageId}_${version.toString().padStart(3, '0')}`;

  return (
    <div className='border shadow-sm p-3 overflow-hidden'>
      <div className='text-center'>
        <strong>{title}</strong>
      </div>
      <OutagesDetailsDetailPanel data={data} labelColumnWidth={labelColumnWidth} valueCellStyles={valueCellStyles} />
      <OutagesDetailsEventPanel data={data} labelColumnWidth={labelColumnWidth} valueCellStyles={valueCellStyles} />
      <OutagesDetailsFotaPanel data={data} labelColumnWidth={labelColumnWidth} valueCellStyles={valueCellStyles} />
      <OutagesDetailsSourcePanel data={data} />
    </div>
  );
}