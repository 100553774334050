import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { setCollapsedState } from '../../actions/sharedComponents';
import { toJS } from '../../utility/immutable-utility';

const CollapsePanel = connect(
  (state) => ({
    collapsePanelState: state.getIn(['sharedComponents', 'collapsePanel'])
  }),
  (dispatch) => ({
    onSetCollapsedState(key, value) {
      dispatch(setCollapsedState([key], value));
    }
  })
)(({ children, idKey, title, disableClasses, panelStyle = {}, headerStyle = {}, bodyStyle = {}, collapsePanelState, onSetCollapsedState, collapsed = false, canCollapse = true }) => {
  const _collapsePanelState = toJS(collapsePanelState);
  let isCollapsed = idKey && _collapsePanelState[idKey] !== undefined ? _collapsePanelState[idKey] : collapsed;

  function onToggleView() {
    isCollapsed = !isCollapsed;
    if (idKey) onSetCollapsedState(idKey, isCollapsed);
  }

  const _panelStyle = useMemo(() => panelStyle || {}, [panelStyle]);
  const _headerStyle = useMemo(() => headerStyle || {}, [headerStyle]);
  const _bodyStyle = useMemo(() => {
    const style = bodyStyle || {};

    return isCollapsed ? { ...style, display: 'none' } : style;
  }, [isCollapsed, bodyStyle]);

  return (
    <div className={disableClasses ? '' : 'fota-collapse-panel'} style={_panelStyle}>
      <div key='header' className={disableClasses ? 'row no-gutters' : 'row no-gutters fota-collapse-panel-header'} style={_headerStyle} onClick={onToggleView}>
        <div className='col title user-select-none'>
          {canCollapse && <span className='float-right'>
              {isCollapsed
                ? <i className='fas fa-chevron-down fa-fw' />
                : <i className='fas fa-chevron-up fa-fw' />}
            </span>}
          {title}
        </div>
      </div>
      <div key='body' className={disableClasses ? '' : 'fota-collapse-panel-body'} style={_bodyStyle}>
        {children}
      </div>
    </div>
  );
});

export default CollapsePanel;