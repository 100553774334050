import React, { Fragment, useCallback } from 'react';
import Timezone from '../../shared/Timezone';
import IdentityId from '../../shared/IdentityId2';
import FormattedDateTime from '../../shared/FormattedDateTime';
import FormattedNumber from '../../shared/FormattedNumber';
import AnalysisSearchCategories from './AnalysisSearchCategories';
import AnalysisCompositionTreeConnected from './AnalysisCompositionTree';
import AnalysisSearchMisc from './AnalysisSearchMisc';
import AnalysisSearchStats from './AnalysisSearchStats';
import { getSchemaColour } from '../../../utility/analysis-search-utility';

export const AnalysisSearchColumns = [null, {
    key: 'id',
    title: 'Id'
  }, {
    key: 'source',
    title: 'Source'
  }, {
    key: 'name',
    title: 'Name',
    sortable: true
  }, {

  },{
    key: 'dataType',
    title: 'Type',
    filterable: true,
    style: {
      minWidth: 85
    }
  }, {
    key: 'style',
    title: 'Style',
    filterable: true,
    style: {
      minWidth: 60
    }
  }, {
    key: 'derivationType',
    title: 'Derivation Type',
    filterable: true,
    style: {
      minWidth: 120
    }
  }, {
    key: 'granularity',
    title: 'Granularity',
    style: {
      minWidth: 80
    }
  }, {
    key: 'sourceTimeZoneId',
    title: 'Source Timezone',
    style: {
      minWidth: 115
    }
  }, {
    key: 'unit',
    title: 'Unit',
    style: {
      minWidth: 40
    }
  }, {
    key: 'lastUpdatedUtc',
    title: <span>Last Updated <Timezone /></span>,
    sortable: true,
    style: {
      minWidth: 140
    }
  }, {
    key: 'queryCount',
    title: 'Queries',
    filterable: true,
    sortable: true,
    style: {
      minWidth: 85
    }
  }, null, null];

export const AnalysisSearchColumnsCompact = [{
      key: 'id',
      title: 'Id'
    }, {
      key: 'source',
      title: 'Source'
    }, {
      key: 'name',
      title: 'Name',
      sortable: true
    }, {
      key: 'dataType',
      title: 'Type',
      style: {
        minWidth: 85
      }
    }, {
      key: 'style',
      title: 'Style',
      style: {
        minWidth: 60
      }
    }, {
      key: 'granularity',
      title: 'Granularity',
      style: {
        minWidth: 80
      }
    }, {
      key: 'sourceTimeZoneId',
      title: 'Source Timezone',
      style: {
        minWidth: 115
      }
    }, {
      key: 'unit',
      title: 'Unit',
      style: {
        minWidth: 40
      }
    }, {
      key: 'lastUpdatedUtc',
      title: <span>Last Updated <Timezone /></span>,
      sortable: true,
      style: {
        minWidth: 140
      }
    }, {
      key: 'queryCount',
      title: 'Queries',
      sortable: true,
      style: {
        minWidth: 85
      }
    }, null, null];

export const AnalysisSearchRow2 = function ({ allSchemas, toggleExpand, toggleExpandDetails, toggleSelection,
  loadCategories, loadStatistics, cellData, rawData, isActiveTest, addFavourite, removeFavourite, isCompactView }) {
  const { id, source, name, dataType, style, granularity, unit, sourceTimeZoneId, derivationType, lastUpdatedUtc, queryCount } = cellData;
  const { categories, sourceCategories, statistics, isFavourite = false, isExpanded = false, isDetailsExpanded = false, displaySchemas = [] } = rawData;
  const isActive = isActiveTest && isActiveTest(id);
  const canToggleFavourites = !isCompactView && !!addFavourite && !!removeFavourite;
  const onToggleFavourite = useCallback(e => {
    e.stopPropagation();

    isFavourite ? removeFavourite(id) : addFavourite(id);
  }, [addFavourite, removeFavourite, id, isFavourite]);

  const identityClassName = `fota-identity-${style || 'none'}`.toLocaleLowerCase();
  const columnStyles = Object.fromEntries(AnalysisSearchColumns.filter(i => i).map(({ key, style }) => [key, style]));

  return (
    <Fragment>
      <tbody className='border-top-0'>
        <tr className={ identityClassName } onClick={() => toggleSelection(id, rawData)}>
          {!isCompactView && <td className='p-0'>
            <button type='button' className='btn btn-sm btn-link' onClick={e => e.stopPropagation() || toggleExpand(id)}>
              <i className={'fas fa-fw ' + (isExpanded ? 'fa-chevron-down' : 'fa-chevron-right')} />
            </button>
          </td>}
          <td className='text-right'>
            <IdentityId isFavourite={isFavourite} isCompactView={isCompactView}>{id}</IdentityId>
          </td>
          <td className='text-nowrap'>{source}</td>
          <td className='w-100'>{name}</td>
          <td className='text-nowrap'>
              {
                displaySchemas.map((x,xi) => <div key={`s${xi}`} style={getSchemaColour(x, allSchemas)}><span>{x}</span></div>)
              }
          </td>
          <td style={ columnStyles.dataType }>{dataType}</td>
          <td style={ columnStyles.style }>{style}</td>
          <td className='text-nowrap' style={ columnStyles.derivationType }>{derivationType}</td>
          <td className='text-nowrap' style={ columnStyles.granularity }>{granularity}</td>
          <td className='text-nowrap' style={ columnStyles.sourceTimeZoneId }>{sourceTimeZoneId}</td>
          <td className='text-nowrap' style={ columnStyles.unit }>{unit}</td>
          <td className='text-nowrap text-right' style={ columnStyles.lastUpdatedUtc }>
            <FormattedDateTime>{lastUpdatedUtc}</FormattedDateTime>
          </td>
          <td className='text-right' style={ columnStyles.queryCount }>
            <FormattedNumber>{queryCount}</FormattedNumber>
          </td>
          { canToggleFavourites ? (
            <td className='text-primary user-select-none cursor-pointer' style={{minWidth: 30}} onClick={onToggleFavourite}>
              <i className='fas fa-star fa-lg fa-fw' style={{ color: isFavourite ? 'gold' : 'rgba(0, 0, 0, .1)' }} />
            </td>
          ) : <td className='text-primary' style={{minWidth: 30}} /> }
          <td className='text-primary' style={{minWidth: 30}}>
            <i className={'fas fa-check-circle fa-lg fa-fw ' + (isActive ? 'visible' : 'invisible')} />
          </td>
        </tr>
        { isExpanded && (
          <tr>
            <td className='border-bottom-0' />
            <td className='border-bottom-0 p-0 text-left' colSpan={AnalysisSearchColumns.length - 1}>
              <button type='button' className='btn btn-sm btn-link w-100 text-left text-decoration-none text-dark' onClick={e => e.stopPropagation() || toggleExpandDetails(id)}>
                <i className={'fas fa-fw ' + (isDetailsExpanded ? 'fa-chevron-down' : 'fa-chevron-right')} /> <strong>Time series details</strong>
              </button>
            </td>
          </tr>
        ) }
        { (isExpanded && isDetailsExpanded) && (
          <tr>
            <td className='border-0' colSpan='2' />
            <td className='border-0' colSpan={AnalysisSearchColumns.length - 2}>
              <div className='d-flex flew-row flex-md-nowrap'>
                <AnalysisSearchCategories id={id} categories={categories} sourceCategories={sourceCategories} loadCategories={loadCategories} />
                <AnalysisSearchStats id={id} statistics={statistics} loadStatistics={loadStatistics} />
                <AnalysisSearchMisc data={rawData} />
              </div>
            </td>
          </tr>
        ) }
      { (isExpanded) && <AnalysisCompositionTreeConnected id={ id } /> }
      </tbody>
    </Fragment>
  );
}