import { createReducer } from '../utility/redux-utility';
import {
  ROLES_LOADED
} from '../actions/roles';

export const roles = createReducer(null, {
  [ROLES_LOADED](state, action) {
    const roles = action.data;
    Object.keys(roles).forEach(key =>{
      state = state.set(key, roles[key]);
    });

    return state.set('isLoading', false);
  }
});