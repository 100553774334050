import React from 'react';

export default function LoadingIcon({ className, style, width = '1.25em', height = '1.25em', fill = 'none', stroke = '#007bff' }) {
  return (
    <svg className={ className } style={ style } width={ width } height={ height } viewBox='0 0 100 100' preserveAspectRatio='xMidYMid' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='50' cy='50' fill={ fill } stroke={ stroke } strokeWidth='10' r='35' strokeDasharray='175'>
        <animateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 50;360 50 50' keyTimes='0;1' dur='1s' begin='0s' repeatCount='indefinite' />
      </circle>
    </svg>
  );
}