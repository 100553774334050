export const getArrayFromState = (state, keyPath) => {
  const value = Array.isArray(keyPath) ? state.getIn(keyPath) : state.get(keyPath);

  return toJS(value, []);
};

export const toJS = (value, defaultValue) => (value && value.toJS ? value.toJS() : value) || (defaultValue || value);

// stateChanges is an array of {path:[...keyPath], isDeletion: boolean, value:any}
export const applyStateChanges = (state, stateChanges) => {
  stateChanges.forEach(change => {
    if (change.isDeletion) {
      state = state.deleteIn([...change.path]);
    } else {
      state = state.setIn([...change.path], change.value);
    }
  });

  return state;
}