import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AnalysisSearchToolbar2 from '../app-content/analysis/AnalysisSearchToolbar2';
import { AnalysisSearchColumnsCompact, AnalysisSearchRow2 } from '../app-content/analysis/AnalysisSearchRow2';
import Table from './Table';
import { toJS } from '../../utility/immutable-utility';

import {
  updateSearchParam2,
  timeseriesSearch2
} from '../../actions/timeseriesSearch2';
import Pagination from './Pagination';
import Loading from './Loading';

export const TimeSeriesSearch2 = connect(
  (state, { stateKey }) => ({
    query: state.getIn(['timeseriesSearch2', stateKey, 'criteria', 'query']),
    customFilter: state.getIn(['timeseriesSearch2', stateKey, 'criteria', 'customFilter']),
    isSearching: state.getIn(['timeseriesSearch2', stateKey, 'isSearching']),
    page: state.getIn(['timeseriesSearch2', stateKey, 'criteria', 'page']),
    pageSize: state.getIn(['timeseriesSearch2', stateKey, 'criteria', 'pageSize']),
    orderBy: state.getIn(['timeseriesSearch2', stateKey, 'criteria', 'orderBy']),
    orderByDirection: state.getIn(['timeseriesSearch2', stateKey, 'criteria', 'orderByDirection']),
    count: state.getIn(['timeseriesSearch2', stateKey, 'results', 'count']),
    data: state.getIn(['timeseriesSearch2', stateKey, 'results', 'data']),
    selectedTimeSeries: state.getIn(['timeseriesSearch2', stateKey, 'selectedTimeSeries']),
    allSchemas: state.getIn(['schemas', 'allSchemas'])
  }),
  (dispatch, { stateKey }) => ({
    search() {
      dispatch(timeseriesSearch2(stateKey));
    },
    updateSearchParam(key, value, convertEmptyToNull) {
      dispatch(updateSearchParam2([stateKey, ...key], value, convertEmptyToNull));
    }
  })
)(({ stateKey, title, search,
  updateSearchParam, intitialSelection = [], actions, selectionStyle = 'multiple',
  isSearching, page = 1, pageSize = 100, orderBy, orderByDirection, count, data, selectedTimeSeries, query, customFilter, closeDialog, allSchemas }) => {

  const _data = toJS(data, []);
  const _selectedTimeSeries = toJS(selectedTimeSeries, []);
  const _selectedIdsInCurrentPage = _data.filter(r => _selectedTimeSeries.find(i => `${i.id}` === `${r.id}`)).map(i => i.id);
  const _selectPageCount = _data.length - _selectedIdsInCurrentPage.length;
  const _unselectCount = _selectedIdsInCurrentPage.length;
  const _isSingleSectionMode = selectionStyle === 'single';
  const _allSchemas = toJS(allSchemas);

  useEffect(() => {
    updateSearchParam(['selectedTimeSeries'], intitialSelection);
  }, [intitialSelection, updateSearchParam]);

  function onResultRowToggleSelection(id, data) {
    let newSelectedTimeSeries = [];
    if (!_isSingleSectionMode) {
      const index = _selectedTimeSeries.find(i => `${i.id}` === `${id}`);
      if (index) {
        newSelectedTimeSeries = [..._selectedTimeSeries.filter(i => `${i.id}` !== `${id}`)];
      }
      else
        newSelectedTimeSeries = [..._selectedTimeSeries, {
          id: data.id,
          name: data.name,
          granularity: data.granularity,
          sourceTimeZoneId: data.sourceTimeZoneId
        }];
    }
    
    if (_isSingleSectionMode) {
      newSelectedTimeSeries = [{
        id: data.id,
        name: data.name,
        granularity: data.granularity,
        sourceTimeZoneId: data.sourceTimeZoneId
      }];
    }

    updateSearchParam(['selectedTimeSeries'], newSelectedTimeSeries);
  }

  function isActiveTest(id) {
    const exists = _selectedTimeSeries.some(i => `${i.id}` === `${id}`);
    return exists;
  }

  function onUpdateQuery(query, customFilter) {
    updateSearchParam(['criteria', 'query'], query);
    updateSearchParam(['criteria', 'customFilter'], customFilter);
    search();
  }

  function onUpdatePage(page, pageSize) {
    if (page) updateSearchParam(['criteria', 'page'], page);
    if (pageSize) updateSearchParam(['criteria', 'pageSize'], pageSize);
    search();
  }

  function onCommandClick(e, action) {
    action.func({
      selectedIds: _selectedTimeSeries.map(i => Number(i.id)),
      selectedTimeSeries: _selectedTimeSeries
    }, e);
  }

  function onSelectAll() {
    if (_isSingleSectionMode)
      return;

    const newSelection = [..._selectedTimeSeries];
    _data.forEach(i => {
      if (!newSelection.find(id => `${i.id}` === `${id}`))
        newSelection.push({ 
          id: i.id, 
          name: i.name, 
          granularity: i.granularity,
          sourceTimeZoneId: i.sourceTimeZoneId
         });
    });
    updateSearchParam(['selectedTimeSeries'], newSelection);
  }

  function onUnselectAll() {
    const newSelection = _selectedTimeSeries.filter(i => !_data.find(t => t.id === i.id));
    updateSearchParam(['selectedTimeSeries'], newSelection);
  }

  function onUpdateSort(newOrderBy) {
    const previoudOrderBy =orderBy;
    const previoudOrderByDirection = orderByDirection;
    if (previoudOrderBy && previoudOrderBy === orderBy) {
      if (previoudOrderByDirection === 'desc') {
        updateSearchParam(['criteria', 'orderByDirection'], 'asc');
      } else {
        updateSearchParam(['criteria', 'orderByDirection'], 'desc');
      }
    }
    else {
      updateSearchParam(['criteria', 'orderBy'], newOrderBy);
      updateSearchParam(['criteria', 'orderByDirection'], 'asc');
    };

    search();
  }

  function onCloseDialog(e){
    if (closeDialog)
      closeDialog();
  }

  return <div className='modal-content'>
    <div className='modal-header'>
      <h5 className='modal-title'>{title}</h5>
      <button type='button' className='close' data-dismiss='modal' onClick={onCloseDialog}>&times;</button>
    </div>
    <Loading isLoading={isSearching} message='Searching...'>
      <div className='modal-body' key={stateKey} >
        <div>
          <AnalysisSearchToolbar2 query={query} customFilter={customFilter} updateQuery={onUpdateQuery}
            refreshData={search}
            selectAll={_isSingleSectionMode ? undefined : onSelectAll} 
            unselectAll={onUnselectAll}
            selectCount={_selectPageCount}
            unselectCount={_unselectCount}
            displayCount={_data.length}
            totalCount={count}
            isCompactView={true}
            updateCustomFilterHeight={() => {}} />
        </div>
        <div style={{ height: '400px' }}>
          <div className='table-responsive sticky-table h-100'>
            <Table columns={AnalysisSearchColumnsCompact} data={_data} useTBody={false} useStickyHeader={true}
              orderBy={orderBy} orderByDirection={orderByDirection} onSortChange={onUpdateSort}>
              <AnalysisSearchRow2
                allSchemas={_allSchemas}
                toggleSelection={onResultRowToggleSelection}
                isActiveTest={isActiveTest}
                isCompactView={true} />
            </Table>
          </div>
          <div className='mt-2'>
            <Pagination currentPage={page} steps={5} pageSize={pageSize} itemCount={count} onPageChange={onUpdatePage} />
          </div>
        </div>
      </div>

      <div className='form-footer mt-3' >
        {actions && actions.map(a => <button key={a.text} className={a.className ? `btn ${a.className} ml-2` : `btn btn-primary ml-2`} type='button' onClick={e => onCommandClick(e, a)} >{a.text}</button>)}
      </div>
    </Loading>
  </div>;
});