import {
  encodeCriteriaToUrlArgs,
  NATIVE_SCHEMA,
  SOURCE_CATEGORY_SCHEMA
} from "../utility/analysis-search-utility";
import { cloneInstance } from "../utility/property-utlility";

export function filterSchemasForResultsRow(schemas) {
  return schemas.filter(s => s !== NATIVE_SCHEMA && s !== SOURCE_CATEGORY_SCHEMA);
}

export function applyUserSchemasOrder(schemas, userSchemas) {
  let sortOrder = [];
  if (schemas.length > 0 && userSchemas && userSchemas.length > 0) {
    mapDisplaySchemas(userSchemas);

    sortOrder = [...userSchemas.map(s => s.schemaName)];
    if (sortOrder.indexOf(NATIVE_SCHEMA) < 0) sortOrder.push(NATIVE_SCHEMA);
    if (sortOrder.indexOf(SOURCE_CATEGORY_SCHEMA) < 0) sortOrder.push(SOURCE_CATEGORY_SCHEMA);
  }
  else {
    sortOrder = [NATIVE_SCHEMA, SOURCE_CATEGORY_SCHEMA, ...schemas.map(s => s.schemaName).sort().filter(s => s !== NATIVE_SCHEMA && s !== SOURCE_CATEGORY_SCHEMA)];
  }

  schemas.sort(function (a, b) {
    return sortOrder.indexOf(a.schemaName) - sortOrder.indexOf(b.schemaName);
  });

  return schemas;
}

export function applyUserSchemasSelection(schemas, userSchemas) {
  if (schemas.length > 0 && userSchemas && userSchemas.length > 0) {
    mapDisplaySchemas(userSchemas);

    schemas = schemas.filter(s => !userSchemas.find(u => u.schemaName === s.schemaName));

    schemas.forEach(s => {
      const userSchema = userSchemas.find(us => s.schemaName === us.schemaName);
      if (userSchema) {
        if (userSchema.isSelected !== undefined) {
          s.isSelected = userSchema.isSelected;
          if (userSchema.isExpanded !== undefined) {
            s.isExpanded = userSchema.isExpanded;
          }
        }
      }
    });
  }

  return schemas;
}

export function sortLegacyFacets(schemas, legacyFacets = []) {
  let response = [...schemas];

  const legacy = schemas.find(f => f.schemaName === NATIVE_SCHEMA);
  // ensure the legacy facets are in the order specified by the user
  if (legacy) {
    if (!legacy.facets)
      legacy.facets = [];

    legacy.facets.sort(function (a, b) {
      return legacyFacets.indexOf(a.key) - legacyFacets.indexOf(b.key);
    });
  }
  
  return response;
}

export function synchroniseToResultFacets(selectorSchemas, resultSchemaFacets) {
  var filtersCount = 0;

  const hasExpandedSchema = selectorSchemas.some(s => s.isSelected === true && s.isExpanded !== undefined);
  if (!hasExpandedSchema) {
    const firstSelectedSchema = selectorSchemas.find(s => s.isSelected === true);
    if (firstSelectedSchema)
      firstSelectedSchema.isExpanded = true;
  }

  selectorSchemas.forEach(selectorSchema => {
    selectorSchema.resultsCount = undefined;
    selectorSchema.errorMessage = undefined;
    const resultFacetGroup = resultSchemaFacets.find(f => f.schemaName === selectorSchema.schemaName);
    if (resultFacetGroup) {
      resultFacetGroup.isExpanded = selectorSchema.isExpanded;
      resultFacetGroup.colour = selectorSchema.colour;
      selectorSchema.resultsCount = resultFacetGroup.resultsCount;
      selectorSchema.errorMessage = resultFacetGroup.errorMessage;
      if (selectorSchema.facets && selectorSchema.facets.length > 0) {
        selectorSchema.facets.forEach(criteriaFacet => {
          const resultFacet = resultFacetGroup.facets.find(f => f.key === criteriaFacet.key);

          if (resultFacet) {
            criteriaFacet.results.forEach(criteriaFacetResult => {
              const result = resultFacet.results.find(r => r.value === criteriaFacetResult.value);

              if (result) {
                result.isSelected = criteriaFacetResult.isSelected;
                if (criteriaFacetResult.isSelected) {
                  filtersCount++;
                }
              } else {
                if (criteriaFacetResult.isSelected) {
                  filtersCount++;
                  resultFacet.results.push({
                    count: 0,
                    value: criteriaFacetResult.value,
                    isSelected: true
                  });
                }
              }
            })
          }
        })
      }
    }
  });

  return filtersCount;
}

export function toggleSelection(isMultiSelect, schemas, schemaName, facetKey, value, forceSelectionOverride) {
  const selectedSchema = schemas.find(s => s.schemaName === schemaName);
  if (selectedSchema) {
    let facet = selectedSchema.facets.find(f => f.key === facetKey);
    if (!facet) {
      facet = {
        key: facetKey,
        results: []
      };
      selectedSchema.facets.push(facet);
    }

    if (!isMultiSelect)
      facet.results = facet.results.filter(v => v.value === value);

    let facetValue = facet.results.find(v => v.value === value);
    if (!facetValue) {
      facetValue = {
        value: value,
        isSelected: false
      }

      facet.results.push(facetValue);
    }

    if (forceSelectionOverride !== undefined) {
      facetValue.isSelected = forceSelectionOverride;
    } else {
      facetValue.isSelected = facetValue.isSelected ? false : true;
    }
  }
}

export function clearSelection(schemas) {
  schemas.forEach(schemaFacets => {
    schemaFacets.facets.forEach(facet => {
      facet.results.forEach(r => {
        delete r.isSelected;
      });
    })
  });
}

export function newIdentityIdSearchCriteria(criteria, identityId) {
  criteria = cloneInstance(criteria);

  if (criteria.schemas && criteria.schemas.length > 0) {
    const legacy = criteria.schemas.find(s => s.schemaName === NATIVE_SCHEMA);
    if (legacy) {
      legacy.isSelected = true;
    } else {
      criteria.schemas = [{
        schemaName: NATIVE_SCHEMA,
        isSelected: true,
      }, ...criteria.schemas];
    }
  } else {
    criteria.schemas = [{
      schemaName: NATIVE_SCHEMA,
      isSelected: true,
    }]
  }

  criteria.query = `${identityId ?? ''}`;
  criteria.customFilter = '';
  criteria.filters = [];
  criteria.enableFavouritesFilter = false;
  criteria.page = 1;
  return criteria;
}

export function newIdentityIdSearchUrlArgs(criteria, identityId) {
  return encodeCriteriaToUrlArgs(newIdentityIdSearchCriteria(criteria, identityId));
}

export function mapUserSettings(criteriaState, settings) {
  const response = {
    criteria: cloneInstance(criteriaState ?? {}),
    ui: {
      enableExpandFacets: settings.enableExpandFacets !== false,
      enableExpandDetails: settings.enableExpandDetails !== false,
      schemaSelectorSize: (settings.ui && settings.ui.schemaSelector) ? settings.ui.schemaSelector.size : undefined
    }
  };

  const { criteria = {} } = settings;
  if (criteria.enableFavouritesFilter) response.criteria.enableFavouritesFilter = criteria.enableFavouritesFilter;
  if (criteria.enableDiscontinuedFilter) response.criteria.enableDiscontinuedFilter = criteria.enableDiscontinuedFilter;
  if (criteria.query) response.criteria.query = criteria.query;
  if (criteria.customFilter) response.criteria.customFilter = criteria.customFilter;
  if (criteria.pageSize) response.criteria.pageSize = criteria.pageSize;
  if (criteria.orderByDirection) response.criteria.orderByDirection = criteria.orderByDirection;
  if (criteria.order) response.criteria.order = criteria.order;
  if (criteria.searchMode)
    response.criteria.isMultiSelect = criteria.searchMode.toLocaleLowerCase() === 'filters';
  else
    response.criteria.isMultiSelect = false;

  const { facets } = settings;
  if (facets) {
    response.criteria.facets = facets;
  }

  return response;
}

export function getTimeSeriesSchema(timeSeriesSchemas, schemas) {
  const selectedSchemas = (schemas ?? []).filter(s => s.isSelected && s.schemaName !== NATIVE_SCHEMA).map(s => s.schemaName);

  let firstSelectedSchema = undefined;

  if (timeSeriesSchemas) {
    timeSeriesSchemas.forEach(n => {
      if (!firstSelectedSchema) {
        firstSelectedSchema = selectedSchemas.find(s => s === n);
      }
    });
  }

  return firstSelectedSchema;
}

// this allows the user to name the schemas in the user settings the name they see in the UI
function mapDisplaySchemas(userSchemas) {
  userSchemas.forEach(us => {
    switch (us.schemaName) {
      case 'Native':
        us.schemaName = NATIVE_SCHEMA;
        break;
      case 'Source':
        us.schemaName = SOURCE_CATEGORY_SCHEMA;
        break;
      default:
        break;
    }
  });
}