import React from 'react';
import { connect } from 'react-redux';
import WorkspaceHitCountModal from './WorkspaceHitCountModal';
import {
  workspaceHitCountClear
} from '../../../actions/workspaceHitCount';

const WorkspaceHitCountModalConnected = connect(
  (state) => ({
    isVisible: state.getIn(['workspaceHitCount', 'isVisible']),
    isLoading: state.getIn(['workspaceHitCount', 'isLoading']),
    hits: state.getIn(['workspaceHitCount', 'hits']),
  }),
  (dispatch) => ({
    closeWorkspaceHitCount() {
      dispatch(workspaceHitCountClear());
    }
  })
)(({ isVisible, isLoading, hits, closeWorkspaceHitCount }) => {
  return (
      <WorkspaceHitCountModal
        isLoading={isLoading}
        isVisible={isVisible}
        hits={hits}
        closeWorkspaceHitCount={closeWorkspaceHitCount} />
  );
});

export default WorkspaceHitCountModalConnected;