import React, { cloneElement, forwardRef, useEffect, useState, useRef, useImperativeHandle } from 'react';

const Modal = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(null),
        [isLarge, setIsLarge] = useState(false),
        [isExtraLarge, setIsExtraLarge] = useState(false),
        [title, setTitle] = useState(''),
        [message, setMessage] = useState(''),
        [content, setContent] = useState(null),
        [callbacks, setCallbacks] = useState(null);

  const modalRef = useRef(),
        confirmButtonRef = useRef();

  useImperativeHandle(ref, () => ({
    onShow({ title, message, content, isLarge, isExtraLarge, onApply, onDismiss }) {
      setTitle(title);
      setMessage(message);
      setContent(content);
      setIsLarge(!!isLarge && !isExtraLarge);
      setIsExtraLarge(!!isExtraLarge && !isLarge);
      setCallbacks({
        onApply: onApply,
        onDismiss: onDismiss
      });
      setIsVisible(true);
    },
    onDismiss() {
      setIsVisible(false);
    }
  }));

  useEffect(() => {
    const bootstrapModal = window.$(modalRef.current).modal({
      show: false
    })
    .on('shown.bs.modal', () => confirmButtonRef.current && confirmButtonRef.current.focus())
    .on('hidden.bs.modal', () => setIsVisible(false));

    return () => bootstrapModal.modal('hide');
  }, []);

  useEffect(() => {
    if (isVisible === true) {
      window.$(modalRef.current).modal('show');
      setIsVisible(null);
    }
    else if (isVisible === false) {
      if (callbacks && callbacks.onDismiss) callbacks.onDismiss();
      window.$(modalRef.current).modal('hide');
      setIsVisible(null);
    }
  }, [isVisible, callbacks]);

  function onSubmit(e) {
    e.preventDefault();

    setIsVisible(false);
    if (callbacks && callbacks.onApply) callbacks.onApply();
    setCallbacks(null);
  }

  const shouldShowContent = !!content;
  const shouldShowMessage = message && message !== '' && title && title !== '';

  return (
    <div ref={ modalRef } className='modal fade' tabIndex='-1'>
      <div className={ `modal-dialog modal-dialog-centered ${isLarge ? 'modal-lg' : (isExtraLarge ? 'modal-xlg' : '')}` }>
        {shouldShowContent && cloneElement(content, { title, isVisible, onSubmit }) }
        {shouldShowMessage && (
          <form className='modal-content' onSubmit={ onSubmit }>
            <div className='modal-header'>
              <h5 className='modal-title'>{ title }</h5>
              <button type='button' className='close' data-dismiss='modal'>&times;</button>
            </div>
            <div className='modal-body'>
              { message }
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
              <button ref={ confirmButtonRef } type='submit' className='btn btn-primary'>OK</button>
            </div>
          </form>
        ) }
      </div>
    </div>
  );
});

export default Modal;