import React from 'react';

export function TimeSeriesNameExpressionFiller({ updateAllSeriesNameStyle }) {
  const options = [
    { name: 'Default', value: 'default' },
    { name: 'Custom', value: 'custom' },
    { name: 'Expression', value: 'expression' },
  ];

  return <select data_id='connect-nulls' autoFocus className='form-control form-control-sm filler-select' style={{ overflowY: 'hidden', minWidth: '125px' }} size={options.length} onChange={e => updateAllSeriesNameStyle(e.target.value)}>
    {options.map((o, oi) => <option key={oi} data-close-popover value={o.value} >{o.name}</option>)}
  </select>
}