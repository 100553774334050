import React from 'react';

export class AutoRefresh extends React.Component {
  constructor(props) {
    super(props);
    this.state = { autoRefreshSchedule: props ? props.autoRefreshSchedule : undefined };
    this.refreshTimerHandle = undefined;
  }

  createRefreshSchedule(schedule = '0', onRefresh) {
    let _scheduleMs = Number(schedule) * 1000 * 60;
    if (_scheduleMs === 0) {
      if (this.refreshTimerHandle) {
        clearInterval(this.refreshTimerHandle);
        this.refreshTimerHandle = undefined;
      }
    }
    else {
      if (this.refreshTimerHandle) {
        clearInterval(this.refreshTimerHandle);
      }

      this.refreshTimerHandle = setInterval(() => {
        onRefresh();
      }, _scheduleMs);
    }
  }

  destroyRefreshSchedule() {
    if (this.refreshTimerHandle) {
      clearInterval(this.refreshTimerHandle);
    }
  }

  componentWillUnmount() {
    this.destroyRefreshSchedule();
  }

  componentDidMount() {
    const autoRefreshSchedule = this.getAutoRefreshSchedule();
    if (autoRefreshSchedule !== null && autoRefreshSchedule !== undefined) {
      this.createRefreshSchedule(autoRefreshSchedule, this.props.onRefresh);
    }
  }

  componentDidUpdate(prevProps = {}, prevState = {}, snapshot) {
    if (this.props.autoRefreshSchedule !== prevProps.autoRefreshSchedule) {
      this.createRefreshSchedule(this.props.autoRefreshSchedule, this.props.onRefresh);
    }

    if (this.state.autoRefreshSchedule !== prevState.autoRefreshSchedule) {
      this.createRefreshSchedule(this.state.autoRefreshSchedule, this.props.onRefresh);
    }
  }

  getAutoRefreshSchedule() {
    if (this.state.autoRefreshSchedule) {
      return this.state.autoRefreshSchedule;
    } else {
      return this.props.autoRefreshSchedule;
    }
  }

  onSetAutoRefreshSchedule(value) {
    if (this.props.setAutoRefreshSchedule) {
      this.props.setAutoRefreshSchedule(value);
    }

    this.setState({
      autoRefreshSchedule: value
    });
  }

  getLabel(){
    if (this.props.label !== undefined)
      return this.props.label;

    return <><i className='fas fa-clock mr-2' />Refresh</>
  }

  render() {
    return <div>
      <div className={`input-group ${this.props.className ? this.props.className : ''}`}>
        <div className='input-group-prepend no-wrap'>
          <label className='input-group-text' >{this.getLabel()}</label>
        </div>
        <select data_id='auto-refresh' className='form-select form-control' style={{ maxWidth: '11em' }} value={this.getAutoRefreshSchedule()} onChange={e => this.onSetAutoRefreshSchedule(e.target.value)} >
          <option value='0'>Off</option>
          {this.props.options ? this.props.options.map((o, oi) => <option key={`ar-${oi}`} value={o.minutes}>{o.text}</option>)
            : <>
              <option value='5'>Every 5 minutes</option>
              <option value='10'>Every 10 minutes</option>
              <option value='15'>Every 15 minutes</option>
              <option value='30'>Every 30 minutes</option>
              <option value='60'>Every hour</option>
            </>}
        </select>
      </div>
    </div>
  }
}