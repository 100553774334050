import React, { Component } from 'react';

export class CheckboxInput extends Component {
  checkChanged(e){
    if (this.props.onChange)
      this.props.onChange(e, e.target.checked);
  }

  render() {
    const {
      checked = false,
      type='checkbox', 
      className = 'form-check', 
      checkClassName = 'form-check-input', 
      labelClassName = 'form-check-label',
      disabled = false,
      title,
      data_id = ''} = this.props;

      return <div className={className} title={title}> 
        <label className={labelClassName} style={{ opacity: disabled ? '0.5' : '1', cursor:'pointer'}}>
          <input style={{ cursor:'pointer'}} data_id={data_id} className={checkClassName} type={type} disabled={disabled} checked={checked} onChange={e => this.checkChanged.call(this, e)} />{this.props.children}
        </label>
      </div>
  }
}