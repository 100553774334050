import React from 'react';
import { connect } from 'react-redux';
import FormattedDateTime from '../../shared/FormattedDateTime';
import FormattedNumber from '../../shared/FormattedNumber';
import LoadingIcon from '../../shared/LoadingIcon';
import IdentityId from '../../shared/IdentityId2';
import { AnalysisSearchColumns } from './AnalysisSearchRow2';
import { toJS } from '../../../utility/immutable-utility';
import { useClickOrDoubleClick } from '../../../hooks/useClickOrDoubleClick';
import '../../../styles/composition-tree.css';
import {
  analysisCompositionLoad,
  analysisCompositionToggleExpanded,
  analysisCompositionToggleSelected
} from '../../../actions/analysis-composition-v2';
import {
  analysisSearchToggleInBasket
} from '../../../actions/analysis';
import { getSchemaColour } from '../../../utility/analysis-search-utility';

const AnalysisCompositionTreeConnected = connect(
  (state, { id: rootId }) => ({
    isInitialised: state.getIn(['analysis', 'composition', `${rootId}`, 'isInitialised']),
    isLoading: state.getIn(['analysis', 'composition', `${rootId}`, 'isLoading']),
    composition: state.getIn(['analysis', 'composition', 'data', `${rootId}`]),
    allSchemas: state.getIn(['schemas', 'allSchemas'])
  }),
  (dispatch, { id: rootId }) => ({
    initialise() {
      dispatch(analysisCompositionLoad(rootId));
    },
    toggleExpand({ displayKey, indexKeyPath }) {
      dispatch(analysisCompositionToggleExpanded(rootId, displayKey, indexKeyPath));
    },
    toggleSelect({ id, data }) {
      dispatch(analysisSearchToggleInBasket(id, toJS(data, {})));
      dispatch(analysisCompositionToggleSelected(id));
    }
  })
)(({ isInitialised, isLoading, id, composition, allSchemas, initialise, toggleExpand, toggleSelect }) => {
  const data = composition && composition.get('data');
  const items = composition && composition.get('items');
  const _allSchemas = allSchemas.toJS();
  
  return <AnalysisCompositionTree 
            data={data} 
            items={items} 
            isInitialised={isInitialised}
            isLoading={isLoading}
            id={id}
            composition={composition}
            allSchemas={_allSchemas}
            initialise={initialise}
            toggleExpand={toggleExpand}
            toggleSelect={toggleSelect}/>
});

export default AnalysisCompositionTreeConnected;

class AnalysisCompositionTree extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  componentDidMount(){
    if (!this.props.isInitialised) {
      this.props.initialise();
    }
  }

  render(){
    return <tr>
      <td className='pl-5' colSpan={ AnalysisSearchColumns.length }>
        { (this.props.isLoading) ? (
          <div className='d-flex align-items-center pl-2'>
            <LoadingIcon width='1.25em' />
            <span className='pl-2'>Loading composition...</span>
          </div>
        ) : !this.props.data ? (
          <div className='d-flex align-items-center pl-2'>
            <i className='fas fa-exclamation-triangle' />
            <span className='pl-2'>No composition data found.</span>
          </div>
        ) : (
          <CompositionGroup className='composition-tree'
                            items={ this.props.items }
                            allSchemas={ this.props.allSchemas }
                            displayKey={ `${this.props.id}` } indexKeyPath=''
                            toggleSelect={ this.props.toggleSelect }
                            toggleExpand={ this.props.toggleExpand } />
        ) }
      </td>
    </tr>
  }
}

function CompositionGroup({ allSchemas, className, items, displayKey, indexKeyPath, toggleSelect, toggleExpand }) {
  return (
    <ul className={ className }>
      { items && items.map((i, ix) => {
        const id = i.get('id');
        const data = i.get('data');
        const items = i.get('items');
        const isSelected = i.get('isSelected');
        const isExpanded = i.get('isExpanded');

        return (
          <CompositionItem key={ ix } 
                           allSchemas={ allSchemas }
                           id={ id } 
                           data={ data } 
                           items={ items }
                           displayKey={ displayKey ? `${displayKey}-${id}` : `${id}` }
                           indexKeyPath={ indexKeyPath ? `${indexKeyPath},items,${ix}` : `items,${ix}` }
                           toggleSelect={ toggleSelect } toggleExpand={ toggleExpand }
                           isSelected={ isSelected } isExpanded={ isExpanded } />
        );
      }) }
    </ul>
  );
}

function CompositionItem({ allSchemas, id, data, items, displayKey, indexKeyPath, toggleSelect, toggleExpand, isSelected, isExpanded }) {
  const hasChildren = !!(items && items.size);

  const [onSelect, onToggle] = useClickOrDoubleClick({
    onClick() {
      toggleSelect({ id, data });
    },
    onDoubleClick() {
      toggleExpand({ displayKey, indexKeyPath });
    },
    onEvent(e) {
      e.preventDefault();
      e.stopPropagation();
    }
  });

  const name = data.get('name');
  const source = data.get('source');
  const dataType = data.get('dataType');
  const schemas = data.get('schemas');
  const style = data.get('style');
  const derivationType = data.get('derivationType');
  const granularity = data.get('granularity');
  const unit = data.get('unit');
  const sourceTimeZoneId = data.get('sourceTimeZoneId');
  const lastUpdatedUtc = data.get('lastUpdatedUtc');
  const queryCount = data.get('queryCount');

  return (
    <li className={ hasChildren ? 'has-children' : '' } onClick={ onSelect } onDoubleClick={ onToggle }>
      <div>
        <CompositionItemContent allSchemas={allSchemas} 
                                id={ id }
                                name={ name } 
                                source={ source } 
                                dataType={ dataType }
                                schemas={ schemas }
                                style={ style } 
                                derivationType={ derivationType }
                                granularity={ granularity } 
                                unit={ unit }
                                sourceTimeZoneId={ sourceTimeZoneId }
                                lastUpdatedUtc={ lastUpdatedUtc } 
                                queryCount={ queryCount }
                                hasChildren={ hasChildren }
                                displayKey={ displayKey } 
                                indexKeyPath={ indexKeyPath }
                                toggleExpand={ toggleExpand } 
                                isExpanded={ isExpanded }
                                isSelected={ isSelected } />
      </div>
      { (hasChildren && isExpanded) && (
        <CompositionGroup allSchemas={allSchemas} 
                          items={ items } 
                          displayKey={ displayKey } 
                          indexKeyPath={ indexKeyPath }
                          toggleSelect={ toggleSelect } 
                          toggleExpand={ toggleExpand } />
      ) }
    </li>
  );
}

function CompositionItemContent({ allSchemas, id, name, source, dataType, schemas, style, derivationType, granularity, unit, sourceTimeZoneId, lastUpdatedUtc, queryCount,
  hasChildren, displayKey, indexKeyPath, isSelected, isExpanded, toggleExpand }) {
  function onToggleClick(e) {
    e.preventDefault();
    e.stopPropagation();

    toggleExpand({ displayKey, indexKeyPath });
  }

  function preventDoubleClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  const identityClassName = `fota-identity-${style || 'none'}`.toLocaleLowerCase();
  const columnStyles = Object.fromEntries(AnalysisSearchColumns.filter(i => i).map(({ key, style }) => [key, style]));

  return (
    <div className={ `d-flex ${identityClassName}` }>
      <div className='flex-grow-1 d-flex align-items-center'>
        { hasChildren && (
          <i className={ `far ${isExpanded ? 'fa-minus-square' : 'fa-plus-square'} p-1 user-select-none` }
             style={{ fontSize: '1.25em' }}
             onClick={ onToggleClick }
             onDoubleClick={ preventDoubleClick } />
        ) }
        <IdentityId>{ id }</IdentityId>
        <span className='ml-2'>{ source }</span>
        <span className='ml-2 mr-2'>{ name }</span>
      </div>
      <div className='d-flex flex-row-nowrap'>
        <div className='text-nowrap'>
          {
            schemas.map((x, xi) => <div key={`s${xi}`} style={getSchemaColour(x, allSchemas)}><span>{x}</span></div>)
          }
        </div>        
        <div className='d-inline-block' style={ columnStyles.dataType }>{ dataType }</div>
        <div className='d-inline-block' style={ columnStyles.style }>{ style }</div>
        <div className='d-inline-block' style={ columnStyles.derivationType }>{ derivationType }</div>
        <div className='d-inline-block' style={ columnStyles.granularity }>{ granularity }</div>
        <div className='d-inline-block' style={ columnStyles.sourceTimeZoneId }>{ sourceTimeZoneId }</div>
        <div className='d-inline-block' style={ columnStyles.unit }>{ unit }</div>
        <div className='d-inline-block text-nowrap text-right' style={ columnStyles.lastUpdatedUtc }>
          <FormattedDateTime>{ lastUpdatedUtc }</FormattedDateTime>
        </div>
        <div className='d-inline-block text-right' style={columnStyles.queryCount}>
          <FormattedNumber>{ queryCount }</FormattedNumber>
        </div>
        <div className='d-inline-block text-primary text-right' style={{ minWidth: 60 }}>
          <i className={ `fas fa-check-circle fa-lg fa-fw ${isSelected ? 'visible' : 'invisible'}` } />
        </div>
      </div>
    </div>
  );
}