import React from 'react';
import RelativeDate from './RelativeDate';
import { assertType } from '../../utility/type-checking';
import PropTypes from 'prop-types';
import LensedDateTime from './LensedDateTime';

export default function AbsRelDate({ id = '', className = '', controlClassName = 'form-control-sm', relativeDateOptions = ['D', 'W', 'M', 'Y'], value, onChange, disabled = false, lens = 'none', isModeSelectorVisible = true, data_id = '' }) {
  const isImmutable = value && typeof value.toJS === 'function';

  assertType({
    value: PropTypes.any,
    onChange: PropTypes.array
  }, { value });

  function getValue(key) {
    if (isImmutable)
      return value.get(key);

    return value[key];
  }

  function onUpdateValue(key, KeyValue) {
    if (onChange)
      if (isImmutable)
        onChange(value.set(key, KeyValue));
      else
        onChange({ ...value, [key]: KeyValue });
  }

  return <div id={id} className={className} >
    <div className='d-flex flex-row' >
      <div className='flex-fill'>
        {getValue('mode') === 'abs' ?
          <LensedDateTime data_id={data_id + '-abs'} disabled={disabled} lens={lens} style={{ minWidth: '162px' }} dateTimeClassName={`form-control ${controlClassName}`} value={getValue('abs')} onChange={v => onUpdateValue('abs', v)} />
          :
          <RelativeDate data_id={data_id + '-rel'} readOnly={disabled} lens={lens} style={{ minWidth: '162px' }} controlClassName={`form-control ${controlClassName}`} id={`${id}-rel`} options={relativeDateOptions} value={getValue('rel')} onChange={v => onUpdateValue('rel', v)} />
        }
      </div>

      {isModeSelectorVisible === true && <>
        <div className='form-check ml-1 mt-1'>
          <label className={`form-check-label p-0`}>
            <input data_id={data_id + '-select-abs'} disabled={disabled} className={`form-check-input p-0`} type='radio' value='none' id={`${id}-abs-check`}
              checked={getValue('mode') === 'abs'}
              onChange={() => onChange && onUpdateValue('mode', 'abs')} />
            Abs</label>
        </div>
        <div className='form-check ml-1 mt-1'>
          <label className={`form-check-label p-0`}>
            <input data_id={data_id + '-select-rel'} disabled={disabled} className={`form-check-input p-0`} type='radio' value='none' id={`${id}-rel-check`}
              checked={getValue('mode') === 'rel'}
              onChange={() => onChange && onUpdateValue('mode', 'rel')} />
            Rel</label>
        </div>
      </>}
    </div>
  </div>
}