import React from 'react';
import RelativeDate from '../../shared/RelativeDate';
import InfoPopup from '../../shared/InfoPopup';
import { comparisonModeRelativeDateOptions } from '../../../utility/comparisonmode-utility';

export function IndividualRelativeDatesSelector({ comparisonMode, comparisonSettings, setRelativeDateText, applyRelativeDate }) {
  const onSetRelativeDateText = (value) => {
    setRelativeDateText(comparisonMode, value);
  }

  const onApplyRelativeDate = (e) => {
    applyRelativeDate(comparisonMode);
  }

  return <div>
    <div className='form-group'>
      <div className='input-group' >
        <label className='fota-input-group-label font-weight-bold'>Relative Date</label>
        <RelativeDate value={comparisonSettings.view.individualRel.relativeDate} onChange={e => onSetRelativeDateText(e)} options={comparisonModeRelativeDateOptions[comparisonMode]} />
        <button type='button' className='btn btn-primary ml-2' onClick={() => onApplyRelativeDate()}>Add Relative Date</button>
        <InfoPopup>
          Add a relative date to aggregate into a time series view
          <br />
          <sub>For today use &quot;0  Days&quot;</sub>
        </InfoPopup>
      </div>
    </div>
  </div>
}