import React from 'react';
import Dialog from '@mui/material/Dialog';
import FormattedDateTime from '../../shared/FormattedDateTime';
import LoadingIcon from '../../shared/LoadingIcon';

export default function WorkspaceHitCountModal({ isVisible, isLoading, hits, closeWorkspaceHitCount }) {
  return (
    <Dialog open={isVisible} fullWidth={true} maxWidth='xs' onClose={() => closeWorkspaceHitCount()}>
      <div className='modal-content'>
        <div className='modal-header border-0'>
          <h5 className='modal-title'>Workspace Hit Count</h5>
        </div>
        <div className='modal-body p-0'>
          <div className='sticky-table w-100' style={{ height: '50vh' }} >
            { isLoading ? (
                <div className='h-100' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1.25em' }}>
                  <LoadingIcon width='2em' height='2em' /> <span className='pl-2'>Loading hits...</span>
                </div>
              ) : (
                <table className='table table-sm'>
                  <thead>
                    <tr className='sticky-header'>
                      <th className='pl-3'>Date (UTC)</th>
                      <th>User</th>
                    </tr>
                  </thead>
                  <tbody>
                    { hits.map((i, ix) => (
                      <tr key={ix}>
                        <td className='pl-3'>
                          <FormattedDateTime>{ i.get('dateTimeUtc') }</FormattedDateTime>
                        </td>
                        <td>{ i.get('user') }</td>
                      </tr>
                    )) }
                  </tbody>
                </table>
              ) }
          </div>
        </div>
        <div className='modal-footer border-0'>
          <button className='btn btn-sml btn-secondary' type='button' onClick={() => closeWorkspaceHitCount()}>Close</button>
        </div>
      </div>
    </Dialog>
  );
}