import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_SEARCH_API_ROOT_URL } from '../config';
import {
  logErrorNotification,
  logInfoNotification,
  logWarningNotification
} from './log';

export const addSchema = () => (dispatch, getState) => {
  const state = getState();
  const schemaName = state.getIn(['schemas','selectedSchema']);
  if(!schemaName) return;
  
  authFetch(`${ANALYSIS_SEARCH_API_ROOT_URL}/v1/schemas/${schemaName}/new`)
    .then(response => response.json())
    .then(data => {
      dispatch(addSchemasComplete(schemaName, data));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const ADD_SCHEMAS_COMPLETE = 'ADD_SCHEMAS_COMPLETE';
export const addSchemasComplete = createAction(ADD_SCHEMAS_COMPLETE, 'schemaName', 'data');

export const COPY_SCHEMAS_COMPLETE = 'COPY_SCHEMAS_COMPLETE';
export const copySchemasComplete = createAction(COPY_SCHEMAS_COMPLETE, 'schemaName', 'data');

export const EDIT_SCHEMA_CATEGORY = 'EDIT_SCHEMA_CATEGORY';
export const editSchemaCategory = createAction(EDIT_SCHEMA_CATEGORY, 'schemaName', 'category', 'value');

export const copyCategoriesFrom = () => (dispatch, getState) => {
  const state = getState();
  const schemaName = state.getIn(['schemas','schemaInEdit']);
  const identityId = state.getIn(['schemas','copyFromIdentityId']);

  if(!schemaName || !identityId) return;
  dispatch(schemasLoading(true));
  authFetch(`${ANALYSIS_SEARCH_API_ROOT_URL}/v1/schemas/${schemaName}/${identityId}`)
    .then(response => response.json())
    .then(data => {
      dispatch(copySchemasComplete(schemaName, data));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
}

export const loadSchemaLookups = (forceRefresh = false) => (dispatch, getState) => {
  const state = getState();
  const allSchemasInitialised = state.getIn(['schemas', 'allSchemasInitialised']);
  if (!forceRefresh && allSchemasInitialised === true)
    return;

  authFetch(`${ANALYSIS_SEARCH_API_ROOT_URL}/v1/schemas/lookups`)
    .then(response => response.status === 204 ? null : response.json())
    .then(data => {
      dispatch(schemaLookupLoadComplete(data));
    })
    .catch(error => {
      dispatch(logWarningNotification(`Error loading schema lookup data: ${error}\n\nPlease try refreshing your browser using Ctrl+F5`));
    });  
};

export const LOAD_SCHEMA_LOOKUP_COMPLETE = 'LOAD_SCHEMA_LOOKUP_COMPLETE';
export const schemaLookupLoadComplete = createAction(LOAD_SCHEMA_LOOKUP_COMPLETE, 'data');

export const loadSchemas = (identityId) => (dispatch, getState) => {
  dispatch(schemasLoading(true));
  authFetch(`${ANALYSIS_SEARCH_API_ROOT_URL}/v1/schemas/${identityId}`)
    .then(response => response.json())
    .then(data => {
      dispatch(loadSchemasComplete(data));
      dispatch(schemasLoading(false));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const LOAD_SCHEMAS_COMPLETE = 'LOAD_SCHEMAS_COMPLETE';
export const loadSchemasComplete = createAction(LOAD_SCHEMAS_COMPLETE, 'data');

export const deleteIdentitySchema = (schemaName, identityId) => (dispatch, _) => {
  dispatch(schemasLoading(true));
  authFetch(`${ANALYSIS_SEARCH_API_ROOT_URL}/v1/schemas/${schemaName}/${identityId}`, { method: 'DELETE' })
    .then(() => {
      dispatch(loadSchemas(identityId));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const saveIdentitySchema = (schemaName, identityId) => (dispatch, getState) => {
  const state = getState();
  let identitySchemas = state.getIn(['schemas','identitySchemas']).toJS();
  let match = identitySchemas.find(x => x.searchSchema.name === schemaName);
  if(!match || !match.searchSchemaCategoryValues) return;
  const cats = Object.assign({}, ...match.searchSchemaCategoryValues.map((x) => ({[x.category]: x.value})));
  
  authFetch(`${ANALYSIS_SEARCH_API_ROOT_URL}/v1/schemas/${schemaName}/${identityId}`, { 
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(cats)
  })
    .then(() => {
      dispatch(stopSchemaEdit());
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const saveLookup = () => (dispatch, getState) => {
  const state = getState();
  let selectedLookup = state.getIn(['schemas','selectedLookup']);
  let lookupValue = state.getIn(['schemas','lookupValue']);
  if(!selectedLookup || !lookupValue) return;

  let lvp = {
    lookup: selectedLookup,
    value: lookupValue
  }; 

  authFetch(`${ANALYSIS_SEARCH_API_ROOT_URL}/v1/schemas/lookup`, { 
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(lvp)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(saveLookupComplete(data));
      dispatch(loadSchemaLookups(true));
      dispatch(logInfoNotification(data));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const SAVE_LOOKUP_COMPLETE = 'SAVE_LOOKUP_COMPLETE';
export const saveLookupComplete = createAction(SAVE_LOOKUP_COMPLETE, 'data');

export const SCHEMAS_LOADING = 'SCHEMAS_LOADING';
export const schemasLoading = createAction(SCHEMAS_LOADING, 'isLoading');

export const SET_COPY_FROM_IDENTITY_ID = 'SET_COPY_FROM_IDENTITY_ID';
export const setCopyFromIdentityId = createAction(SET_COPY_FROM_IDENTITY_ID, 'id');

export const SET_LOOKUP_VALUE = 'SET_LOOKUP_VALUE';
export const setLookupValue = createAction(SET_LOOKUP_VALUE, 'lookupValue');

export const SET_SELECTED_LOOKUP = 'SET_SELECTED_LOOKUP';
export const setSelectedLookup = createAction(SET_SELECTED_LOOKUP, 'selectedLookup');

export const SET_SELECTED_SCHEMA = 'SET_SELECTED_SCHEMA';
export const setSelectedSchema = createAction(SET_SELECTED_SCHEMA, 'schemaName');

export const START_SCHEMA_EDIT = 'START_SCHEMA_EDIT';
export const startSchemaEdit = createAction(START_SCHEMA_EDIT, 'schemaName');

export const STOP_SCHEMA_EDIT = 'STOP_SCHEMA_EDIT';
export const stopSchemaEdit = createAction(STOP_SCHEMA_EDIT);
