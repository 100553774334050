import React from 'react';
import CollapsePanel from '../../../shared/CollapsePanel';

export default function StepOutputSeries({ step, seriesKey, granularity, sourceTimeZoneId, dataPointCount, dataPoints }) {
  const key = `stepoutput-${step}-${seriesKey}`;
  const title = `${seriesKey}: ${granularity} ${sourceTimeZoneId} (${dataPointCount} dps)`;
  return (
    <CollapsePanel idKey={key} title={title} headerStyle={{ background: 'yellowgreen', cursor: 'pointer' }} collapsed={true}>
      <table className='table table-sm table-striped'>
        <tbody>
          {dataPoints && dataPoints.map((x, i) => 
            <tr key={i}>
              <td>{x.startDateTime}</td>
              <td>{x.endDateTime}</td>
              <td>{x.value}</td>
            </tr> 
          )}          
        </tbody>
      </table>
    </CollapsePanel>
  );
}