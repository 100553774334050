import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import {
  analysisSelectionBasketRemoveAll,
  analysisChartingBasketRemoveItem,
  analysisUpdateSeries2,
  analysisToggleSeriesVisibiliy2,
  analysisToggleAllSeriesVisibiliy2,
  analysisUpdateAllSeries,
  analysisToggleSeriesNameStyle,
  analysisUpdateAllSeriesNameStyle,
  analysisUpdateSeriesCustomName,
  analysisSelectionMoveTimeSeries,
  analysisChartingBasketCloneTimeSeries,
  analysisChartingBasketBeginEdit,
  analysisChartingBasketSaveEdit,
  analysisChartingBasketCancelEdit,
  analysisChartingBasketEditValue,
  analysisSetBasketView,
  analysisEditHighchartsJson,
  analysisUpdateHighchartsJson,
  analysisCancelHighchartsJson,
  analysisPreviewHighchartsJson,
  analysisApplyHighchartsJson,
  analysisSetFillerValue
} from '../../../actions/analysis-basket-v2';
import { analysisDynamicWorkspaceDisassociateFromDynamicFilter } from '../../../actions/analysis-dynamic-workspace';

import {
  analysisShapesInit,
  analysisShapesLoadAvailableShapes,
} from '../../../actions/analysis-shapes-v2';

import {
  analysisRefreshTemplateNameExpressions,
  analysisUpdateTemplateNameExpression,
  analysisSelectionAddOnDemandDerivedSelection,
  analysisAddRangeSelectionByCsvIds,
  analysisReportCriteriaUpdatePeriodAbs
} from '../../../actions/analysis';

import {
  analysisTimeSeriesVersionsInit,
} from '../../../actions/analysis-versions';

import {
  analysisComparisonModeInitFromBasket,
} from '../../../actions/analysis-comparisonmode-v2';

import {
  analysisCompositionToggleSelected,
  analysisCompositionRefreshSelected
} from '../../../actions/analysis-composition-v2';

import ChartBasketNext from './ChartBasketNext';
import { mergePeriodCollectionLists } from '../../../utility/period-utility';

export const ChartBasketConnected = connect(
  (state) => ({
    templateNameExpression: state.getIn(['analysis', 'workspace', 'templateNameExpression']),
    timeseries: state.getIn(['analysis', 'workspace', 'timeseries']),
    chartSettings: state.getIn(['analysis', 'workspace', 'chartSettings']),
    timeSeriesNameSettings: state.getIn(['analysis', 'ui', 'timeSeriesNameSettings']),
    fillerValues: state.getIn(['analysis', 'ui', 'fillerValues']),
    conversionUnits: state.getIn(['referenceData', 'conversionUnits']),
    operations: state.getIn(['referenceData', 'operations']),
    userTimeSeriesSettings: state.getIn(['userSettings', 'settings', 'timeSeries']),
    comparisonLenses: state.getIn(['referenceData', 'comparisonLenses']),
    isLoading: state.getIn(['analysis', 'chart', 'isLoading']),
    seriesTypes: state.getIn(['analysis', 'seriesTypes']),
    seriesDashStyles: state.getIn(['analysis', 'seriesDashStyles']),
    seriesStacking: state.getIn(['analysis', 'seriesStacking']),
    basketView: state.getIn(['analysis', 'basketSettings', 'basketView']),
    highchartsJson: state.getIn(['analysis', 'basketSettings', 'highchartsJson']),
    userSettingPeriods: state.getIn(['analysis', 'ui', 'periodsUserSettings']),
    referenceDataPeriods: state.getIn(['analysis', 'ui', 'periodsReferenceData']),
    workspacePeriods: state.getIn(['analysis', 'workspace', 'periods']),
    reportCriteria : {
      lens : state.getIn(['analysis', 'workspace', 'lens']),
      operation : state.getIn(['analysis', 'workspace', 'operation']),
      conversionUnit : state.getIn(['analysis', 'workspace', 'conversionUnit']),
      conversionFactor : state.getIn(['analysis', 'workspace', 'conversionFactor']),
      comparisonMode: state.getIn(['analysis', 'workspace', 'comparisonMode']),
      asAt: state.getIn(['analysis', 'workspace', 'asAtUtc']),
      shape: state.getIn(['analysis', 'workspace', 'shape']),
      evo : state.getIn(['analysis', 'workspace', 'isEvolution']) === true ? {
        toDateMode: state.getIn(['analysis', 'workspace', 'toAsAtDateMode']),
        absToDate: state.getIn(['analysis', 'workspace', 'asAtToUtc']),
        relToDate: state.getIn(['analysis', 'workspace', 'relAsAtTo']),
        fromDateMode: state.getIn(['analysis', 'workspace', 'fromAsAtDateMode']),
        relFromDate: state.getIn(['analysis', 'workspace', 'relAsAtFrom']),
        absFromDate: state.getIn(['analysis', 'workspace', 'asAtFromUtc'])
      } : undefined,
      window : {
        period: state.getIn(['analysis', 'workspace', 'period']),
        toDateMode: state.getIn(['analysis', 'workspace', 'toDateMode']),
        absToDate: state.getIn(['analysis', 'workspace', 'toUtc']),
        relToDate: state.getIn(['analysis', 'workspace', 'relTo']),
        fromDateMode: state.getIn(['analysis', 'workspace', 'fromDateMode']),
        relFromDate: state.getIn(['analysis', 'workspace', 'relFrom']),
        absFromDate: state.getIn(['analysis', 'workspace', 'fromUtc'])
      }
    }
  }),
  (dispatch) => ({
    addSelectionByCsv(value) {
      dispatch(analysisAddRangeSelectionByCsvIds(value));
    },
    removeFromBasket(key) {
      dispatch(analysisChartingBasketRemoveItem(key));
      dispatch(analysisCompositionToggleSelected(key));
    },
    removeAllFromBasket() {
      dispatch(analysisSelectionBasketRemoveAll());
      dispatch(analysisCompositionRefreshSelected());
    },
    updateTemplateNameExpression(expression) {
      dispatch(analysisUpdateTemplateNameExpression(expression));
    },
    setFillerValue(key,value){
      dispatch(analysisSetFillerValue(key, value));      
    },
    updateSeriesProperty(key, property, value, requiresApiRefresh) {
      dispatch(analysisUpdateSeries2(key, { [property]: value }, requiresApiRefresh));
    },
    updateChartProperty(key, property, value, requiresApiRefresh) {
      dispatch(analysisUpdateSeries2(key, { [property]: value }, requiresApiRefresh));
    },
    updateSeriesWindowPeriod(key, period){
      dispatch(analysisUpdateSeries2(key, { period }, true));
      dispatch(analysisReportCriteriaUpdatePeriodAbs());
    },
    updateSeriesCustomName(key, customName) {
      dispatch(analysisUpdateSeriesCustomName(key, customName));
    },
    toggleNameStyle(key) {
      dispatch(analysisToggleSeriesNameStyle(key));
      dispatch(analysisRefreshTemplateNameExpressions());
    },
    updateAllSeries(key, value, options) {
      dispatch(analysisUpdateAllSeries(key, value, options));
    },
    updateAllSeriesNameStyle(nameStyle) {
      dispatch(analysisUpdateAllSeriesNameStyle(nameStyle));
      dispatch(analysisRefreshTemplateNameExpressions());
    },
    toggleVisibility(key) {
      dispatch(analysisToggleSeriesVisibiliy2(key));
    },
    toggleAllVisibility(value) {
      dispatch(analysisToggleAllSeriesVisibiliy2(value));
    },
    disassociateFromDynamicFilter(value) {
      dispatch(analysisDynamicWorkspaceDisassociateFromDynamicFilter(value));
    },
    addOnDemandDerivedSelection(userTimeSeriesSettings) {
      dispatch(analysisSelectionAddOnDemandDerivedSelection(userTimeSeriesSettings));
      dispatch(analysisCompositionRefreshSelected());
    },
    cloneTimeSeries(key) {
      dispatch(analysisChartingBasketCloneTimeSeries(key));
    },
    moveTimeSeries(key, direction) {
      dispatch(analysisSelectionMoveTimeSeries(key, null, direction));
    },
    beginEditTimeSeries(key) {
      dispatch(analysisChartingBasketBeginEdit(key));
    },
    saveEditTimeSeries() {
      dispatch(analysisChartingBasketSaveEdit());
      dispatch(analysisRefreshTemplateNameExpressions());
    },
    cancelEditTimeSeries() {
      dispatch(analysisChartingBasketCancelEdit());
    },
    updateEditTimeSeriesValue(keyPath, value) {
      dispatch(analysisChartingBasketEditValue(keyPath, value));
    },
    initialiseForecast(timeSeriesKey, operations) {
      dispatch(analysisTimeSeriesVersionsInit(timeSeriesKey, operations));
    },
    initialiseComparisons(timeSeriesKey) {
      dispatch(analysisComparisonModeInitFromBasket(timeSeriesKey));
    },
    initialiseShapes(shape){
      dispatch(analysisShapesInit(shape));
      dispatch(analysisShapesLoadAvailableShapes(shape));
    },
    setBasketView(basketView) {
      dispatch(analysisSetBasketView(basketView));
    },
    editHighchartsJson(key){
      dispatch(analysisEditHighchartsJson(key));
    },
    updateHighchartsJson(key, data){
      dispatch(analysisUpdateHighchartsJson(key, data));
    },
    cancelHighchartsJson(key){
      dispatch(analysisCancelHighchartsJson(key));
    },
    previewHighchartsJson(key){
      dispatch(analysisPreviewHighchartsJson(key));
    },
    applyHighchartsJson(key){
      dispatch(analysisApplyHighchartsJson(key));
    }
  })
)(({ 
  basketView,
  conversionUnits,
  toggleIsPinned,
  timeseries,
  chartSettings,
  templateNameExpression,
  updateTemplateNameExpression,
  updateSeriesProperty,
  updateSeriesWindowPeriod,
  updateChartProperty,
  updateSeriesCustomName,
  toggleNameStyle,
  setFillerValue,
  fillerValues,
  updateAllSeries,
  updateAllSeriesNameStyle,
  toggleVisibility,
  toggleAllVisibility,
  addSelectionByCsv,
  removeFromBasket,
  removeAllFromBasket,
  timeSeriesNameSettings,
  userTimeSeriesSettings,
  addOnDemandDerivedSelection,
  moveTimeSeries,
  operations,
  cloneTimeSeries,
  comparisonLenses,

  reportCriteria,
  referenceDataPeriods, userSettingPeriods, workspacePeriods,

  disassociateFromDynamicFilter,

  beginEditTimeSeries,
  saveEditTimeSeries,
  cancelEditTimeSeries,
  updateEditTimeSeriesValue,

  initialiseForecast,
  initialiseComparisons,

  initialiseShapes,

  seriesTypes,
  seriesDashStyles,
  seriesStacking,
  
  setBasketView,

  editHighchartsJson,
  highchartsJson,
  updateHighchartsJson,
  cancelHighchartsJson,
  previewHighchartsJson,
  applyHighchartsJson
}) => {
  const _timeseries = useMemo(() => {
    const ts = toJS(timeseries);
    return Array.isArray(ts) ? ts : []
  }, [timeseries]);

  const _highchartsJson = useMemo(() => toJS(highchartsJson, {}), [highchartsJson]);
  const _chartSettings = useMemo(() => toJS(chartSettings, {}), [chartSettings]);
  const _timeSeriesNameSettings = toJS(timeSeriesNameSettings, {});
  const _conversionUnits = toJS(conversionUnits, []);
  const _lenses = toJS(comparisonLenses, {})[reportCriteria.comparisonMode];

  const _seriesTypes = toJS(seriesTypes, {});
  const _seriesDashStyles = toJS(seriesDashStyles, []);
  const _seriesStacking = toJS(seriesStacking, []);

  const periods = mergePeriodCollectionLists(
    referenceDataPeriods ? referenceDataPeriods.toJS() : undefined,
    userSettingPeriods ? userSettingPeriods.toJS() : undefined,
    workspacePeriods ? workspacePeriods.toJS() : undefined,
  );

  return <ChartBasketNext 
    basketView={basketView} 
    reportCriteria={reportCriteria}
    periods={periods}
    templateNameExpression={templateNameExpression}
    timeseries={_timeseries}
    chartSettings={_chartSettings}
    timeSeriesNameSettings={_timeSeriesNameSettings}
    conversionUnits={_conversionUnits}
    operations={operations}
    userTimeSeriesSettings={userTimeSeriesSettings}
    lenses={_lenses}
    seriesTypes={_seriesTypes}
    seriesDashStyles={_seriesDashStyles}
    seriesStacking={_seriesStacking}
    addSelectionByCsv={addSelectionByCsv}
    removeFromBasket={removeFromBasket}
    removeAllFromBasket={removeAllFromBasket}
    toggleIsPinned={toggleIsPinned}
    updateTemplateNameExpression={updateTemplateNameExpression}
    updateSeriesProperty={updateSeriesProperty}
    updateSeriesWindowPeriod={updateSeriesWindowPeriod}
    updateChartProperty={updateChartProperty}
    updateSeriesCustomName={updateSeriesCustomName}
    toggleNameStyle={toggleNameStyle}
    setFillerValue={setFillerValue}
    fillerValues={fillerValues}
    updateAllSeries={updateAllSeries}
    updateAllSeriesNameStyle={updateAllSeriesNameStyle}                           
    toggleVisibility={toggleVisibility}
    toggleAllVisibility={toggleAllVisibility}
    disassociateFromDynamicFilter={disassociateFromDynamicFilter}
    addOnDemandDerivedSelection={addOnDemandDerivedSelection}
    cloneTimeSeries={cloneTimeSeries}
    moveTimeSeries={moveTimeSeries}
    beginEditTimeSeries={beginEditTimeSeries}
    saveEditTimeSeries={saveEditTimeSeries}
    cancelEditTimeSeries={cancelEditTimeSeries}
    updateEditTimeSeriesValue={updateEditTimeSeriesValue}
    initialiseForecast={initialiseForecast}
    initialiseComparisons={initialiseComparisons}
    initialiseShapes={initialiseShapes} 
    setBasketView={setBasketView}
    editHighchartsJson={editHighchartsJson}
    highchartsJson={_highchartsJson}
    updateHighchartsJson={updateHighchartsJson}
    cancelHighchartsJson={cancelHighchartsJson}
    previewHighchartsJson={previewHighchartsJson}
    applyHighchartsJson={applyHighchartsJson}/>
});