import { useEffect, useRef } from 'react';
import { noop, requestTimeout } from '../utility/request-timeout';

/*
  https://medium.com/trabe/preventing-click-events-on-double-click-with-react-the-performant-way-1416ab03b835
*/

const useCancelableScheduledWork = () => {
  const cancelCallback = useRef(noop);
  const registerCancel = fn => (cancelCallback.current = fn);
  const cancelScheduledWork = () => cancelCallback.current();

  // Cancels the current sheduled work before the "unmount"
  useEffect(() => {
    return cancelScheduledWork;
  }, []);

  return [registerCancel, cancelScheduledWork];
};

export const useClickOrDoubleClick = ({ onClick, onDoubleClick, delay = 300, onEvent }) => {
  const [registerCancel, cancelScheduledRaf] = useCancelableScheduledWork();

  const handleEvent = e => {
    if (!e) return;
    if (typeof onEvent === 'function') onEvent(e);
    e.persist();
  }

  const handleClick = e => {
    handleEvent(e);
    cancelScheduledRaf();
    requestTimeout(() => onClick(e), delay, registerCancel);
  };

  const handleDoubleClick = e => {
    handleEvent(e);
    cancelScheduledRaf();
    onDoubleClick(e);
  };

  return [handleClick, handleDoubleClick];
};