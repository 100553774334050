import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import {
    logErrorNotification,
    logInfoNotification
  } from './log';
import { EVENTS_API_URL } from '../config';

export const actionsEditorRefresh = () => async (dispatch, getState) => {
  dispatch(actionsEditorRefreshStarted());

  try {
    const dataTypeCollection = await Promise.allSettled([
      getActionsAsync('inlineDataPointsActions', 'inline-datapoints'),
      getActionsAsync('scheduledActions', 'scheduled'),
      getActionsAsync('triggeredActions', 'triggered'),
    ]);
    const data = {};
    for (let index = 0; index < dataTypeCollection.length; index++) {
      const dataCollection = dataTypeCollection[index];
      data[dataCollection.value.type] = await dataCollection.value.data;
    }

    dispatch(actionsEditorRefreshComplete(data));
  } catch (error) {
    dispatch(logErrorNotification(error));
    dispatch(actionsEditorRefreshComplete());
  }
};

export const ACTIONS_EDITOR_REFRESH_STARTED = 'ACTIONS_EDITOR_REFRESH_STARTED';
const actionsEditorRefreshStarted = createAction(ACTIONS_EDITOR_REFRESH_STARTED);

export const ACTIONS_EDITOR_REFRESH_COMPLETE = 'ACTIONS_EDITOR_REFRESH_COMPLETE';
const actionsEditorRefreshComplete = createAction(ACTIONS_EDITOR_REFRESH_COMPLETE, 'data');

async function getActionsAsync(type, path){
  return authFetch(`${EVENTS_API_URL}/actions/${path}`)
    .then(response => ({type, data: response.json()}));
}

export const ACTIONS_EDITOR_SET_SELECTED_TAB = 'ACTIONS_EDITOR_SET_SELECTED_TAB';
export const actionsEditorSetSelectedTab = createAction(ACTIONS_EDITOR_SET_SELECTED_TAB, 'index');

export const ACTIONS_EDITOR_SET_ONLY_SHOW_MY_ACTIONS = 'ACTIONS_EDITOR_SET_ONLY_SHOW_MY_ACTIONS';
export const actionsEditorSetOnlyShowMyActions = createAction(ACTIONS_EDITOR_SET_ONLY_SHOW_MY_ACTIONS, 'show');

export const actionsRunScheduledAction = (action) => async (dispatch, getState) => {
  dispatch(actionsRunScheduledActionStarted(action.id, action.name));
  dispatch(logInfoNotification(`Running '${action.name}'`));

  return authFetch(`${EVENTS_API_URL}/scheduled-action-execution/${action.id}`)
    .then(response => response.json())
    .then(data => {
      dispatch(actionsRunScheduledActionComplete(action.id, action.name, data));
    })
    .catch(error => {
      dispatch(actionsRunScheduledActionComplete(action.id, action.name, undefined, error.message));
      dispatch(logErrorNotification(error));
    });
};

export const ACTIONS_RUN_SCHEDULED_ACTION_STARTED = 'ACTIONS_RUN_SCHEDULED_ACTION_STARTED';
const actionsRunScheduledActionStarted = createAction(ACTIONS_RUN_SCHEDULED_ACTION_STARTED, 'id', 'name');

export const ACTIONS_RUN_SCHEDULED_ACTION_COMPLETE = 'ACTIONS_RUN_SCHEDULED_ACTION_COMPLETE';
const actionsRunScheduledActionComplete = createAction(ACTIONS_RUN_SCHEDULED_ACTION_COMPLETE, 'id', 'name', 'data', 'error');

export const ACTIONS_SET_FILTER = 'ACTIONS_SET_FILTER';
export const actionsSetFilter = createAction(ACTIONS_SET_FILTER, 'text');
