import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import { TimeSeriesEditComponent } from './TimeSeriesEditComponent';
import {
  analysisChartingBasketEditAsAt
} from '../../../actions/analysis-basket-v2';
import {
  analysisShapesToggleShapeCellSelection,
  analysisShapesToggleShapeAllSelection,
  analysisShapesToggleShapeBucketSelection,
  analysisShapesToggleShapeGroupSelection,
  analysisToggleBasketMaterialisationMode,
  analysisToggleBasketVariantMode,
  analysisShapesSetShapeName,
  analysisShapesSelectShape,
  analysisShapesSaveShape,
  analysisShapesDeleteShape
} from '../../../actions/analysis-shapes-v2';

import {
  analysisVersionSelectAsAtPageChange
} from '../../../actions/analysis-versions';

export const TimeSeriesEditComponentConnected = connect(
  (state) => ({
    operations: state.getIn(['referenceData', 'operations']),
    comparisonLenses: state.getIn(['referenceData', 'comparisonLenses']),
    timeSeries: state.getIn(['analysis', 'ui', 'editTimeSeries']),
    timeSeriesAsAts: state.getIn(['analysis', 'ui', 'timeseriesVersionSettings', 'asAts']),
    workspace: state.getIn(['analysis', 'workspace']),
    shapesSettings: state.getIn(['analysis', 'ui', 'shapesSettings'])
  }),
  (dispatch) => ({
    toggleAsAt(value) {
      dispatch(analysisChartingBasketEditAsAt(value));
    },
    asAtPageChange(page) {
      dispatch(analysisVersionSelectAsAtPageChange(page));
    },
    toggleShapeCellSelection(group, bucket) {
      dispatch(analysisShapesToggleShapeCellSelection(group, bucket));
    },
    toggleShapeAllSelection() {
      dispatch(analysisShapesToggleShapeAllSelection());
    },
    toggleShapeBucketSelection(bucketName) {
      dispatch(analysisShapesToggleShapeBucketSelection(bucketName));
    },
    toggleShapeGroupSelection(group) {
      dispatch(analysisShapesToggleShapeGroupSelection(group));
    },
    toggleBasketMaterialisationMode() {
      dispatch(analysisToggleBasketMaterialisationMode());
    },
    toggleBasketVariantMode() {
      dispatch(analysisToggleBasketVariantMode());
    },
    setShapeName(name) {
      dispatch(analysisShapesSetShapeName(name));
    },
    selectShape(shapeId, callback) {
      dispatch(analysisShapesSelectShape(shapeId, callback));
    },
    saveShape(callback) {
      dispatch(analysisShapesSaveShape(callback));
    },
    deleteShape() {
      dispatch(analysisShapesDeleteShape());
    }
  })
)(({ focusPath, setFocusPath, comparisonLenses, operations, timeSeries, timeSeriesAsAts, workspace, toggleAsAt, asAtPageChange, onSave, onCancel, updateValue, 
  shapesSettings, setShapeName, selectShape, saveShape, deleteShape,
  toggleShapeCellSelection, toggleShapeAllSelection, toggleShapeBucketSelection, toggleShapeGroupSelection,
  toggleBasketMaterialisationMode, toggleBasketVariantMode
 }) => {
  if (!timeSeries) return <></>;

  const _timeSeries = useMemo(() => toJS(timeSeries, {}), [timeSeries]);
  const _timeSeriesAsAts = useMemo(() => toJS(timeSeriesAsAts, {}), [timeSeriesAsAts]);
  const _comparisonLenses = useMemo(() => toJS(comparisonLenses, {}), [comparisonLenses]);
  const _operations = useMemo(() => toJS(operations, []), [operations]);
  const _workspace = useMemo(() => toJS(workspace, []), [workspace]);
  const _shapesSettings = toJS(shapesSettings);
  const _lenses = _comparisonLenses[_workspace.comparisonMode];

  return (
    <TimeSeriesEditComponent timeSeries={ _timeSeries }
                             timeSeriesAsAts={ _timeSeriesAsAts }
                             operations={ _operations }
                             lenses={ _lenses }
                             workspace={ _workspace }
                             toggleAsAt={ toggleAsAt }
                             asAtPageChange={ asAtPageChange }
                             updateValue={ updateValue }
                             onSave={ onSave }
                             onCancel={ onCancel }
                             shapesSettings= { _shapesSettings }
                             setShapeName={ setShapeName }
                             selectShape = {selectShape}
                             saveShape = {saveShape}
                             deleteShape = {deleteShape}
                             toggleShapeCellSelection = {toggleShapeCellSelection}
                             toggleShapeAllSelection = {toggleShapeAllSelection} 
                             toggleShapeBucketSelection = {toggleShapeBucketSelection}
                             toggleShapeGroupSelection = {toggleShapeGroupSelection}
                             toggleBasketMaterialisationMode={toggleBasketMaterialisationMode}
                             toggleBasketVariantMode={toggleBasketVariantMode}
                             focusPath={focusPath}
                             setFocusPath={setFocusPath}
                             />
  );
});