import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import AnalysisChartData from './AnalysisChartData';
import {
  analysisUpdateTableSettingsValue,
  analysisUpdateTableSettingDateFormat,
  analysisUpdateTableSettingDecimalPlaces,
  analysisUpdateTableSettingFontSize,
  analysisUpdateTableSettingHideDate,
  analysisUpdateTableSettingRowSize,
  analysisUpdateTableSettingTitle,
  analysisUpdateTableSettingsHeaderWidth,
  analysisUpdateTableSettingsHeaderWidths,
  analysisUpdateTableSettingsOrderBy,
  analysisChartDataExport,
  analysisAdjustmentsUpdateTimeSeriesMetaProperty,
  analysisAdjustmentsBeginEdit,
  analysisAdjustmentsEndEdit,
  analysisAdjustmentsSetSelectionStart,
  analysisAdjustmentsSetSelectionEnd,
  analysisAdjustmentsSelectLine,
  analysisAdjustmentsSetAdjustmentCell,
  analysisAdjustmentsSetAdjustmentValue,
  analysisAdjustmentsSetSelectionAdjustmentValue,
  analysisAdjustmentsNavigateCell,
  analysisAdjustmentsRemoveSelectedAdjustments,
  analysisAdjustmentsRevertSelectionAdjustments,
  analysisAdjustmentsValidate,
  analysisTableSelectionCopy,
  analysisTablePasteToSelection,
  analysisAdjustmentsSetSaveConfirmationVisibility
} from '../../../actions/analysis';

const AnalysisChartDataConnected = connect(
  (state) => ({
    isTableDesignPanelVisible: state.getIn(['analysis', 'ui', 'isTableDesignPanelVisible']),
    isTableJsonEditorPanelVisible: state.getIn(['analysis', 'ui', 'isTableJsonEditorPanelVisible']),
    comparisonMode: state.getIn(['analysis', 'chart', 'comparisonMode']),
    tableSettings: state.getIn(['analysis', 'workspace', 'tableSettings']),
    tableStyles: state.getIn(['analysis', 'table', 'styles']),
    tableRowStyles: state.getIn(['analysis', 'table', 'rowStyles']),
    tableHeaders: state.getIn(['analysis', 'table', 'headers']),
    tableData: state.getIn(['analysis', 'table', 'data']),
    isInlineAdjustmentsEditor: state.getIn(['roles', 'isInlineAdjustmentsEditor']),
    isEditing: state.getIn(['analysis', 'adjustments', 'isEditing']),
  }),
  (dispatch) => ({
    updateProperty(key, value) {
      dispatch(analysisUpdateTableSettingsValue(key, value));
    },
    updateFontSize(value) {
      dispatch(analysisUpdateTableSettingFontSize(value));
    },
    updateHideDate(value) {
      dispatch(analysisUpdateTableSettingHideDate(value));
    },
    updateRowSize(value) {
      dispatch(analysisUpdateTableSettingRowSize(value));
    },
    updateTitle(value) {
      dispatch(analysisUpdateTableSettingTitle(value));
    },
    updateDateFormat(value) {
      dispatch(analysisUpdateTableSettingDateFormat(value));
    },
    updateDecimalPlaces(value) {
      dispatch(analysisUpdateTableSettingDecimalPlaces(value));
    },
    updateHeaderWidth(key, value) {
      dispatch(analysisUpdateTableSettingsHeaderWidth(key, value));
    },
    updateHeaderWidths(value) {
      dispatch(analysisUpdateTableSettingsHeaderWidths(value));
    },
    updateSort(key, orderBy, orderByDirection) {
      dispatch(analysisUpdateTableSettingsOrderBy(key, orderBy, orderByDirection));
    },
    exportData() {
      dispatch(analysisChartDataExport());
    },
    updateAdjustmentTimeSeriesMetaProperty(tskey, propertyName, value){
      dispatch(analysisAdjustmentsUpdateTimeSeriesMetaProperty(tskey, propertyName, value));
    },
    beginEditMode(args){
      dispatch(analysisAdjustmentsBeginEdit());
      if (args){
        dispatch(analysisAdjustmentsSetAdjustmentCell(args));
        dispatch(analysisAdjustmentsSetSelectionStart(args));
      }      
    },
    endEditMode(){
      dispatch(analysisAdjustmentsEndEdit());
    },
    setSelectionStart(args){
      dispatch(analysisAdjustmentsSetAdjustmentCell(args));
      dispatch(analysisAdjustmentsSetSelectionStart(args));
    },
    setSelectionEnd(args){
      dispatch(analysisAdjustmentsSetSelectionEnd(args));
    },
    selectLine(args){
      dispatch(analysisAdjustmentsSelectLine(args));
    },
    setAdjustmentValue(args){
      dispatch(analysisAdjustmentsSetAdjustmentValue(args));
      if (args && !args.isDeferredValue){
        dispatch(analysisAdjustmentsValidate());
      }
    },
    setSelectionAdjustmentValue(args){
      dispatch(analysisAdjustmentsSetSelectionAdjustmentValue(args));
      dispatch(analysisAdjustmentsValidate());
    },
    navigateCell(args){
      dispatch(analysisAdjustmentsNavigateCell(args));
    },
    removeSelectionAdjustments(){
      dispatch(analysisAdjustmentsRemoveSelectedAdjustments());
      dispatch(analysisAdjustmentsValidate());
    },
    revertSelectionAdjustments() {
      dispatch(analysisAdjustmentsRevertSelectionAdjustments());
      dispatch(analysisAdjustmentsValidate());
    },
    copySelection() {
      dispatch(analysisTableSelectionCopy(false));
    },
    pasteToSelection(text) {
      dispatch(analysisTablePasteToSelection(text));
    },
    showSaveAdjustments() {
      dispatch(analysisAdjustmentsSetSaveConfirmationVisibility(true));
    }
  })
)(({ reflowSwitch, comparisonMode,
  updateSort, updateProperty, updateHeaderWidth, updateHeaderWidths, exportData,
  updateFontSize, updateHideDate, updateRowSize, updateTitle, updateDateFormat, updateDecimalPlaces,
  tableSettings, tableStyles, tableRowStyles, tableHeaders, tableData,
  isTableDesignPanelVisible, isTableJsonEditorPanelVisible,
  isInlineAdjustmentsEditor = false, isEditing, beginEditMode, endEditMode, setSelectionStart, setSelectionEnd, selectLine, navigateCell, 
  setAdjustmentValue, setSelectionAdjustmentValue, removeSelectionAdjustments, revertSelectionAdjustments,
  copySelection, pasteToSelection, showSaveAdjustments}) => {
  const _tableSettings = toJS(tableSettings, {});
  const _tableStyles = toJS(tableStyles);
  const _tableRowStyles = toJS(tableRowStyles);
  const [resizeSwitch, setResizeSwitch] = useState(0);

  let _tableHeaders = toJS(tableHeaders);
  let _tableData = toJS(tableData);
  
  useLayoutEffect(() => {
    let resizeId;
    const onResize = () => {
      clearTimeout(resizeId);
      resizeId = setTimeout(() => setResizeSwitch(v => v + 1), 500);
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return <AnalysisChartData reflowSwitch={reflowSwitch}
                           resizeSwitch={resizeSwitch}
                           comparisonMode={comparisonMode}
                           tableSettings={_tableSettings}
                           updateProperty={updateProperty}
                           updateDateFormat={updateDateFormat}
                           updateDecimalPlaces={updateDecimalPlaces}
                           updateFontSize={updateFontSize}
                           updateHideDate={updateHideDate}
                           updateRowSize={updateRowSize}
                           updateTitle={updateTitle}
                           updateHeaderWidth={updateHeaderWidth}
                           updateHeaderWidths={updateHeaderWidths}
                           updateSort={updateSort}
                           exportData={exportData}
                           tableHeaders={_tableHeaders}
                           tableData={_tableData}
                           tableStyles={_tableStyles}
                           tableRowStyles={_tableRowStyles} 
                           isToolbarVisible={true}
                           isTableDesignPanelVisible={isTableDesignPanelVisible}
                           isTableJsonEditorPanelVisible={isTableJsonEditorPanelVisible}
                           isInlineAdjustmentsEditor={isInlineAdjustmentsEditor}
                           isEditing={isEditing}
                           beginEditMode={beginEditMode}
                           endEditMode={endEditMode}
                           setSelectionStart={setSelectionStart}
                           setSelectionEnd={setSelectionEnd}
                           selectLine={selectLine}
                           setAdjustmentValue={setAdjustmentValue}
                           setSelectionAdjustmentValue={setSelectionAdjustmentValue}
                           removeSelectionAdjustments={removeSelectionAdjustments}
                           revertSelectionAdjustments={revertSelectionAdjustments}
                           navigateCell={navigateCell}
                           copySelection={copySelection}
                           pasteToSelection={pasteToSelection}
                           showSaveAdjustments={showSaveAdjustments}
                           />
});

export default AnalysisChartDataConnected;