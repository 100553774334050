import { createReducer } from '../utility/redux-utility';
import { fromJS } from 'immutable';
import {
  HOMEPAGE_NEW_ITEM_MODAL_CLEAR,
  HOMEPAGE_NEW_ITEM_MODAL_LOAD_STARTED,
  HOMEPAGE_NEW_ITEM_MODAL_LOAD_STOPPED,
  HOMEPAGE_NEW_ITEM_MODAL_LOAD_COMPLETE,
  HOMEPAGE_NEW_ITEM_MODAL_SAVE_STARTED,
  HOMEPAGE_NEW_ITEM_MODAL_SAVE_STOPPED,
  HOMEPAGE_NEW_ITEM_MODAL_SAVE_COMPLETE,
  HOMEPAGE_NEW_ITEM_MODAL_SET_MODE,
  HOMEPAGE_NEW_ITEM_MODAL_SET_TYPE,
  HOMEPAGE_NEW_ITEM_MODAL_SET_SCOPE,
  HOMEPAGE_NEW_ITEM_MODAL_SET_DATA,
  HOMEPAGE_NEW_ITEM_MODAL_SET_PATH,
  HOMEPAGE_NEW_ITEM_MODAL_SET_SELECTED_WORKSPACE
} from '../actions/homepageNewItemModal';

const getFullPath = (folderPath, subFolderPath, name) => `${folderPath || ''}/${subFolderPath || ''}/${name || ''}`.replace(/\/+/g, '/');

export const homepageNewItemModal = createReducer(null, {
  [HOMEPAGE_NEW_ITEM_MODAL_CLEAR](state, action) {
    return state.set('isInitialised', false)
                .set('isLoading', false)
                .set('sharedFolders', fromJS([]))
                .set('mode', 'New')
                .set('type', 'WebLink')
                .set('scope', 'Private')
                .set('folderPath', '')
                .set('subFolderPath', '')
                .set('name', '')
                .set('fullPath', '')
                .set('data', '')
                .set('selectedWorkspaceId', '')
                .set('selectedWorkspacePath', '')
                .set('selectedWorkspace', fromJS({}));
  },
  [HOMEPAGE_NEW_ITEM_MODAL_LOAD_STARTED](state, action) {
    return state.set('isLoading', true)
                .set('sharedFolders', fromJS([]))
  },
  [HOMEPAGE_NEW_ITEM_MODAL_LOAD_STOPPED](state, action) {
    return state.set('isLoading', false);
  },
  [HOMEPAGE_NEW_ITEM_MODAL_LOAD_COMPLETE](state, action) {
    return state.set('isInitialised', true)
                .set('isLoading', false)
                .set('sharedFolders', fromJS(action.data));
  },
  [HOMEPAGE_NEW_ITEM_MODAL_SAVE_STARTED](state, action) {
    return state.set('isSaving', true);
  },
  [HOMEPAGE_NEW_ITEM_MODAL_SAVE_STOPPED](state, action) {
    return state.set('isSaving', false);
  },
  [HOMEPAGE_NEW_ITEM_MODAL_SAVE_COMPLETE](state, action) {
    return state.set('isSaving', false);
  },
  [HOMEPAGE_NEW_ITEM_MODAL_SET_MODE](state, action) {
    return state.set('mode', action.value);
  },
  [HOMEPAGE_NEW_ITEM_MODAL_SET_TYPE](state, action) {
    return state.set('type', action.value);
  },
  [HOMEPAGE_NEW_ITEM_MODAL_SET_SCOPE](state, action) {
    return state.set('scope', action.value)
                .set('folderPath', '');
  },
  [HOMEPAGE_NEW_ITEM_MODAL_SET_DATA](state, action) {
    return state.set('data', action.value);
  },
  [HOMEPAGE_NEW_ITEM_MODAL_SET_PATH](state, action) {
    let newState = state;

    Object.entries(action.data).forEach(([key, value]) => newState = newState.set(key, value));

    const folderPath = newState.get('folderPath');
    const subFolderPath = newState.get('subFolderPath');
    const name = newState.get('name');

    const fullPath = getFullPath(folderPath, subFolderPath, name);

    return newState.set('fullPath', fullPath);
  },
  [HOMEPAGE_NEW_ITEM_MODAL_SET_SELECTED_WORKSPACE](state, action) {
    const { id, type, scope, folderPath, subFolderPath, name, data } = action.data;
    const fullPath = getFullPath(folderPath, subFolderPath, name);

    return state.set('selectedWorkspace', fromJS(action.data))
                .set('selectedWorkspaceId', id)
                .set('selectedWorkspacePath', fullPath)
                .set('type', type)
                .set('scope', scope)
                .set('folderPath', folderPath)
                .set('subFolderPath', subFolderPath)
                .set('name', name)
                .set('data', data)
                .set('fullPath', fullPath);
  }
});