import React from 'react';
import { DynamicWorkspaceEditorConnected } from './DynamicWorkspaceEditorConnected';
import Dialog from '@mui/material/Dialog';
import { MultiselectDropdown } from '../../../shared/MultiselectDropdown';

export function DynamicWorkspaceToolbar({
  isCompact, isInitialised,
  searchStateContext, editNew, editExisting, endEditing,
  canGroupFilters, isGroupingEnabled, toggleCategoryGrouping,
  setDynamicWorkspaceFilterValue, setDynamicWorkspaceMultipleFilterValues, isDynamicWorskpaceDialogIsOpen = false, dynamicWorkspaces }) {
  return <div className='d-flex flex-row dynamic-filter-toolbar'>
    <div className='flex-fill d-flex flex-row'>
      {!isCompact && <button className='btn btn-sm btn-secondary text-nowrap ml-1' disabled={!isInitialised} type='button' onClick={editNew} >
        <i className='fa fa-list fa-fw mr-1' />Add Dynamic
      </button>}
      {!isCompact && <Dialog open={isDynamicWorskpaceDialogIsOpen} fullWidth={true} maxWidth='xl'>
        {isDynamicWorskpaceDialogIsOpen && <DynamicWorkspaceEditorConnected searchStateContext={searchStateContext} onComplete={endEditing} />}
      </Dialog>}
      {dynamicWorkspaces.map((ds, di) => <DynamicWorkspace key={`df-${di}`} isCompact={isCompact} editExisting={editExisting} setDynamicWorkspaceFilterValue={setDynamicWorkspaceFilterValue} setDynamicWorkspaceMultipleFilterValues={setDynamicWorkspaceMultipleFilterValues} dynamicWorkspace={ds} />)}
    </div>

    <div className='d-flex flex-row'>
      {!isCompact && canGroupFilters && <div className="form-check form-switch ml-2 mt-1">
        <input className="form-check-input" type="checkbox" id='groupFilters' checked={isGroupingEnabled} onChange={toggleCategoryGrouping} />
        <label className="form-check-label" htmlFor="groupFilters">Group like filters</label>
      </div>}
    </div>
  </div>
}

function DynamicWorkspace({ dynamicWorkspace, isCompact, editExisting, setDynamicWorkspaceFilterValue, setDynamicWorkspaceMultipleFilterValues }) {
  if (isCompact && (!dynamicWorkspace.dynamicFilters || dynamicWorkspace.dynamicFilters.length === 0))
    return <></>

  return <div className='d-flex ml-1 mr-0 pr-0 dynamic-filter-toolbar-item' >
    {/* {dynamicWorkspace.colour && <span style={{backgroundColor:dynamicWorkspace.colour, width:'3px', height:'18px', marginRight:'1px', marginTop:'5px'}}>&nbsp;</span>}     */}
    {dynamicWorkspace.dynamicFilters && dynamicWorkspace.dynamicFilters.map(dynamicFilter =>
      <DynamicFilter key={`${dynamicFilter.key}`} dynamicWorkspace={dynamicWorkspace} dynamicFilter={dynamicFilter}
        setDynamicWorkspaceFilterValue={setDynamicWorkspaceFilterValue}
        setDynamicWorkspaceMultipleFilterValues={setDynamicWorkspaceMultipleFilterValues} />)}
    {!isCompact && <button className='btn btn-sm' type='button' onClick={() => editExisting(dynamicWorkspace.key)} >
      <i className='fa fa-edit fa-fw' />
    </button>}
  </div>
}

function DynamicFilter({ dynamicWorkspace, dynamicFilter, setDynamicWorkspaceFilterValue, setDynamicWorkspaceMultipleFilterValues }) {
  if (dynamicFilter.isCollapsed)
    return <div></div>

  let title = '';
  if (dynamicFilter.alias !== '') {
    title = dynamicFilter.alias;
  } else if (dynamicFilter.selectedCategory) {
    title = dynamicFilter.selectedCategory;
  } else {
    title = '???';
  }

  const dynamicFilterValues = (dynamicFilter.strict ? dynamicFilter.strictValues : dynamicFilter.values) ?? [];
  let filterOptions = dynamicFilterValues.filter(v => v.isSelected === true).map(v => ({ value: v.name, name: v.name, isAllowed: true }));
  if (!dynamicFilter.isMultiSelect && !filterOptions.find(o => o.name === dynamicFilter.selectedValue)) {
    filterOptions = [{ name: '', value: dynamicFilter.selectedValue, isAllowed: false }, ...filterOptions];
  }

  if (dynamicFilter.isMultiSelect) {
    return <div className='d-flex mr-1'>
      <span className='mt-1 ml-1 mr-1'>{title}</span>
      <MultiselectDropdown className='multiselect-sm'
        style={{minWidth:'200px', maxHeight:'28px'}}
        data={filterOptions}
        dataKey='name'
        textField='name'
        onChange={values => setDynamicWorkspaceMultipleFilterValues(dynamicWorkspace.key, dynamicFilter.key, values.map(v => v.name))}
        value={dynamicFilter.multiSelectValues} />
    </div>
  } else {
    return <div key={`${dynamicFilter.key}`} className='d-flex mr-1'>
      <span className='mt-1 ml-1 mr-1'>{title}</span>
      <select className='form-control form-control-sm ml-1'
        style={{ maxWidth: '150px' }}
        title={dynamicFilter.selectedValue}
        value={dynamicFilter.selectedValue}
        onChange={e => { setDynamicWorkspaceFilterValue(dynamicWorkspace.key, dynamicFilter.key, e.target.value) }}>
        {filterOptions.map((v, vi) => <option key={`vi-${vi}`} value={v.value}>{v.name}</option>)}
      </select>
    </div>
  }
}