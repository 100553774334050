import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import Dialog from '@mui/material/Dialog';
import {
  reportsAdjustmentsRemoveAllAdjustments,
  reportsAdjustmentsRemoveSelectedAdjustments,
  reportsAdjustmentsSetSelectionAdjustmentValue,
  reportsAdjustmentsBeginEdit,
  reportsAdjustmentsEndEdit,
  reportsTablePasteToSelection,
  reportsSaveAdjustments,
  reportAdjustmentsValidate,
  reportsAdjustmentsSetSaveConfirmationVisibility
} from '../../../actions/reports';

export const AdjustmentsToolbar = connect(
  (state) => ({
    isWizard: state.getIn(['roles', 'isWizard']),
    isEditing: state.getIn(['reports', 'adjustments', 'isEditing']),
    adjustments: state.getIn(['reports', 'results', 'adjustments']),
    inlineAdjustments: state.getIn(['reports', 'results', 'inlineAdjustments']),
    dirtyCellsMap: state.getIn(['reports', 'adjustments', 'dirtyCellsMap']),
    selectionRange: state.getIn(['reports', 'adjustments', 'editSelection', 'range']),
    isSaveConfirmationVisible: state.getIn(['reports', 'adjustments', 'isSaveConfirmationVisible'])
  }),
  (dispatch) => ({
    removeAllAdjustments() {
      dispatch(reportsAdjustmentsRemoveAllAdjustments());
      dispatch(reportAdjustmentsValidate());
    },
    removeSelectedAdjustments() {
      dispatch(reportsAdjustmentsRemoveSelectedAdjustments());
      dispatch(reportAdjustmentsValidate());
    },
    setSelectionAdjustmentValue() {
      dispatch(reportsAdjustmentsSetSelectionAdjustmentValue());
      dispatch(reportAdjustmentsValidate());
    },
    beginEdit() {
      dispatch(reportsAdjustmentsBeginEdit());
    },
    endEdit() {
      dispatch(reportsAdjustmentsEndEdit());
    },
    pasteToSelection() {
      dispatch(reportsTablePasteToSelection());
    },
    setSaveConfirmationVisibility(isVisible) {
      dispatch(reportsAdjustmentsSetSaveConfirmationVisibility(isVisible));
    },
    saveAdjustments() {
      dispatch(reportsSaveAdjustments());
    }
  })
)(({ isEditing, isWizard,
  selectionRange, removeAllAdjustments, removeSelectedAdjustments, setSelectionAdjustmentValue, dirtyCellsMap, adjustments, inlineAdjustments,
  pasteToSelection, setSaveConfirmationVisibility, isSaveConfirmationVisible, saveAdjustments, endEdit }) => {
  if (!isEditing) return <></>

  function onSaveAdjustments(e){
    e.preventDefault();
    setSaveConfirmationVisibility(false);
    saveAdjustments();    
  }

  const _adjustments = toJS(adjustments) ?? {};
  const _inlineAdjustments = toJS(inlineAdjustments) ?? {};
  const _selectionRange = toJS(selectionRange) ?? {};
  const _hasSelection = Object.keys(_selectionRange).length > 0;
  const _hasAdjustments = Object.keys(_adjustments).length > 0 || Object.keys(_inlineAdjustments).length > 0 || Object.keys(toJS(dirtyCellsMap)).length > 0;
  const _hasChangesToSave = Object.keys(toJS(dirtyCellsMap)).length > 0 || Object.keys(_inlineAdjustments).length > 0;

  return <>
    <label className='font-weight-bold' style={{ fontSize: '.9em', marginBottom: 0 }}>Adjustments</label>
    <div>
      <button disabled={!_hasSelection} title='Paste' className='btn btn-sm btn-outline-primary text-nowrap' type='button' onClick={pasteToSelection}>
        <i className='fas fa-paste fa-fw' />
      </button>

      <div className='btn-group btn-group-sm ml-1 mr-1' >
        <button title='Remove all adjustments' type='button' className={`btn btn-sm btn-outline-primary text-nowrap`} disabled={!_hasAdjustments} onClick={removeAllAdjustments} >
          <i className='fas fa-trash fa-fw' style={{ position: 'relative', top: '2px', fontSize: '10px' }} />
          <i className='fas fa-trash fa-fw' style={{ position: 'relative', top: '-1px', left: '-3px', fontSize: '10px' }} />
          <span className='d-none d-md-inline-block'></span>
        </button>

        <button title='Remove selected adjustments' type='button' className={`btn btn-sm btn-outline-primary text-nowrap`} disabled={!(_hasAdjustments && _hasSelection)} onClick={removeSelectedAdjustments}>
          <i className='fas fa-trash fa-fw' />
        </button>
      </div>

      <button title='Apply adjustment to selected cells' type='button' className={`btn btn-sm btn-outline-primary text-nowrap`} disabled={!_hasSelection} onClick={setSelectionAdjustmentValue}>
        <i className='fas fa-check fa-fw' />
      </button>

      {isWizard && <>
        <button title='Save adjustments' type='button' disabled={!_hasChangesToSave} className={`btn btn-sm ${(_hasChangesToSave ? 'btn-primary' : 'btn-outline-primary')} text-nowrap ml-1`} onClick={() => setSaveConfirmationVisibility(true)}>
          Save
        </button>
      </>}

      <button title='Exit adjustment mode' type='button' className={`btn btn-sm btn-outline-primary text-nowrap ml-1`} onClick={endEdit}>
        <i className='fas fa-times fa-fw' />
      </button>
    </div>

    <Dialog open={isSaveConfirmationVisible === true} fullWidth={true} maxWidth={'sm'} onClose={() => setSaveConfirmationVisibility(false)}>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Save Adjustments?</h5>
        </div>
        <div className='modal-body'>
          Are you sure you wish to save the adjustments?
        </div>
        <div className='modal-footer'>
          <form noValidate onSubmit={e => onSaveAdjustments(e)}>
            <button className='btn btn-sml btn-secondary' type='button' onClick={() => setSaveConfirmationVisibility(false)}>Cancel</button>
            <button autoFocus className='btn btn-sml btn-primary' type='submit' >Confirm</button>
          </form>
        </div>
      </div>
    </Dialog>
  </>
});