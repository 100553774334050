import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
    QUEUE_STATUS_REFRESH_STARTED,
    QUEUE_STATUS_REFRESH_STOPPED,
    QUEUE_STATUS_FETCH_COMPLETE,
    QUEUE_STATUS_REPLAY_DLQ_STARTED,
    QUEUE_STATUS_REPLAY_DLQ_STOPPED
} from '../actions/queueStatus';
import moment from 'moment';

export const queueStatus = createReducer(null, {
    [QUEUE_STATUS_REFRESH_STARTED](state, action) {
      return state.set('isLoading', true);
    },
    [QUEUE_STATUS_REFRESH_STOPPED](state, action) {
      return state.set('isLoading', false)
                  .set('initialised', true)
                  .set('lastRefreshed', moment())
                  .set('loaders', fromJS([]))
                  .set('subscriptions', fromJS([]));
    },
    [QUEUE_STATUS_FETCH_COMPLETE](state, action) {
      action.data.loaders.forEach(s => {
        s.replaySubmitted = false;
        s.canReplay = s.deadLetterCount > 0;
      });

      action.data.subscriptions.forEach(s => {
        s.replaySubmitted = false;
        s.canReplay = s.deadLetterCount > 0;
      });

       return state.set('initialised', true)
                   .set('isLoading', false)
                   .set('lastRefreshed', moment())
                   .set('loaders', fromJS(action.data.loaders))
                   .set('subscriptions', fromJS(action.data.subscriptions));
    },
    [QUEUE_STATUS_REPLAY_DLQ_STARTED](state, action) {
      const {queueType, topicName, subscriptionName} = action;

      if (queueType === 'loader'){
        const loaders = state.get('loaders').toJS();
        const loader = loaders.find(s => s.topicName === topicName && s.subscriptionName === subscriptionName);
        loader.replaySubmitted = true;
        return state.set('loaders', fromJS(loaders));
      }

      const subscriptions = state.get('subscriptions').toJS();
      const subscription = subscriptions.find(s => s.topicName === topicName && s.subscriptionName === subscriptionName);
      subscription.replaySubmitted = true;
      return state.set('subscriptions', fromJS(subscriptions));
    },
    [QUEUE_STATUS_REPLAY_DLQ_STOPPED](state, action) {
      const {queueType, topicName, subscriptionName} = action;
      
      if (queueType === 'loader'){
        const loaders = state.get('loaders').toJS();
        const loader = loaders.find(s => s.topicName === topicName && s.subscriptionName === subscriptionName);
        if (loader) {
          loader.replaySubmitted = false;
          loader.canReplay = false;
        }

        return state.set('loaders', fromJS(loaders));
      }

      const subscriptions = state.get('subscriptions').toJS();
      const subscription = subscriptions.find(s => s.topicName === topicName && s.subscriptionName === subscriptionName);
      if (subscription) {
        subscription.replaySubmitted = false;
        subscription.canReplay = false;
      }

      return state.set('subscriptions', fromJS(subscriptions));
    }
});