import React from 'react';

import $ from 'jquery';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import 'jsoneditor/dist/jsoneditor.min.css';

import 'handsontable/dist/handsontable.full.css';
import 'react-widgets/styles.css';
import 'allotment/dist/style.css';

import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as ConnectedRouter } from "redux-first-history/rr6";
import * as serviceWorker from './serviceWorker';
import { history, getStore } from './store';
import App from './App';

// MSAL imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { msalInstance } from "./msalAuth";

import './external/jquery-sticky-table.css';
import './external/jquery-sticky-table';
import './utility/array-utility';

window.jQuery = window.$ = $;

const apiKey = new URLSearchParams(window.location.search).get("_ak");
if (apiKey !== null) window.__APIKEY__ = apiKey; 

const auto = new URLSearchParams(window.location.search).get("_no_screen_capture");
window.__IS_SCREEN_CAPTURE_ALLOWED = !auto; 

const store = getStore();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(RenderApp(App));
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;

    ReactDOM.render(
      RenderApp(NextApp), document.getElementById('root')
    );
  });

  window.store = store;
}

/*
  If you want your app to work offline and load faster, you can change unregister() to register() below.
  NB: This comes with some pitfalls: http://bit.ly/CRA-PWA
*/
serviceWorker.unregister();

function RenderApp(NextApp) {
  return window.__APIKEY__ === undefined ? (
      <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <NextApp instance={msalInstance} history={history} />
          </ConnectedRouter>
        </Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>)
    : (<Provider store={store}>
      <ConnectedRouter history={history}>
        <NextApp history={history} />
      </ConnectedRouter>
    </Provider>
  );
}