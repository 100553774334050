import React, { useCallback, useState, useEffect, useRef } from 'react';
import FormattedNumber from '../../shared/FormattedNumber';

const CATEGORY_VIEW_TYPE = {
  STANDARD: '',
  SOURCE: 'sourceCategories'
};

export default function AnalysisSearchToolbar2({ query = '', customFilter = '', totalCount, filterCount, updateQuery, facetStyle = '',
  discontinuedFilterMonths, enableDiscontinuedFilter, toggleDiscontinuedFilter, enableFavouritesFilter, toggleFavouritesFilter, displayCount,
  updateFacetStyle, clearFilters, refreshData, exportData, selectAll, unselectAll, selectCount, unselectCount, isCompactView, customFilterHeight, updateCustomFilterHeight,
  favouriteAll, unfavouriteAll, favouriteCount, unfavouriteCount, enableExpandFacets, enableExpandDetails, toggleExpandFacets, toggleExpandDetails,
  allSchemaNames, selectedSearchSchema, setSelectedSearchSchema }) {

  const [searchText, setSearchText] = useState(query);
  const [customFilterText, setCustomFilterText] = useState(customFilter);
  const [facetStyleText, setFacetStyle] = useState(facetStyle);
  const searchInputRef = useRef();

  useEffect(() => {
    setSearchText(query);
    setCustomFilterText(customFilter);
    searchInputRef.current.focus();
  }, [query, customFilter]);

  const onChangeView = useCallback(e => {
    e.preventDefault();

    const style = e.target.dataset.category;
    setFacetStyle(style);
    updateFacetStyle(style);
  }, [updateFacetStyle]);

  function onSearch(e) {
    e.preventDefault();

    if (query !== searchText || customFilter !== customFilterText) {
      setSearchText(searchText);
      setCustomFilterText(customFilterText);
      updateQuery(searchText, customFilterText);
    }
    else refreshData();
  }

  function onClearQuery() {
    setSearchText('');
    updateQuery('', customFilterText);
  }

  function onClearCustomFilter() {
    setCustomFilterText('');
    updateQuery(searchText, '');
  }

  function onCustomFilterKeyDown(e) {
    if (e.keyCode === 13)
      onSearch(e);
  }

  function onCustomFilterMouseUp(e) {
    if (e.target.offsetHeight !== customFilterHeight)
      updateCustomFilterHeight(e.target.offsetHeight);
  }

  return (
    <form noValidate onSubmit={onSearch}>
      <div className='form-row'>
        <div className='col-md-3'>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <div className='input-group-text'>Search</div>
            </div>
            <input data_id='search-text' ref={searchInputRef} type='text' className={'form-control' + (searchText.length ? '' : ' rounded-right')} placeholder='Search text'
                   value={searchText}
                   onChange={e => setSearchText(e.target.value)} />
            <div className='input-group-append'>
              <button type='button' className={'btn btn-outline-secondary' + (searchText.length ? '' : ' d-none')}
                      onClick={ onClearQuery }>
                <i className='fas fa-times' />
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <div className='input-group-text'>Filter</div>
            </div>
            <textarea data_id='filter-text' className={'form-control' + (customFilterText.length ? '' : ' rounded-right')} placeholder='Filter text'
                      style={{ minHeight: 'calc(1.5em + .75rem + 2px)', height: customFilterHeight || 'calc(1.5em + .75rem + 2px)' }}
                      value={customFilterText}
                      onChange={e => setCustomFilterText(e.target.value)}
                      onKeyDown={ onCustomFilterKeyDown }
                      onMouseUp={ onCustomFilterMouseUp } />
            <div className='input-group-append'>
              <button data_id='clear-filter-text' type='button' className={'btn btn-outline-secondary' + (customFilterText.length ? '' : ' d-none')}
                      onClick={ onClearCustomFilter }>
                <i className='fas fa-times' />
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-3 d-flex align-content-center'>
          <button type='submit' className='btn btn-primary mr-2'>
            <i className='fas fa-search' />
          </button>
          <small className='col-form-label text-muted'>
            <FormattedNumber>{displayCount}</FormattedNumber> of <FormattedNumber>{totalCount}</FormattedNumber> results
          </small>
        </div>
        <div className='col-md-3 d-flex align-content-center justify-content-end'>
          <div className='btn-toolbar'>
            <div className='btn-group btn-group-sm'>
              {!isCompactView && <button data_id='export-data' type='button' className='btn btn-outline-secondary text-nowrap mr-1' onClick={exportData}>
                <i className='fas fa-download fa-fw' />
                <span className='px-2 d-none d-md-inline-block'>Export</span>
              </button>}
              {!isCompactView && clearFilters && (<button data_id='clear-filters' type='button' className={`btn btn-outline-secondary text-nowrap mr-1 ${filterCount ? '' : 'd-none'}`} onClick={clearFilters}>
                <i className='fas fa-filter fa-fw' />
                <span className='px-2 d-none d-md-inline-block'>Clear filters ({filterCount})</span>
              </button>)}
              {selectCount > 0 ? (
                <button data_id='add-all' type='button' disabled={selectAll === undefined} className='btn btn-outline-secondary text-nowrap' onClick={selectAll}>
                  <i className='fas fa-box-open fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Add all ({selectCount})</span>
                </button>
              ) : null}
              {unselectCount > 0 ? (
                <button data_id='remove-all' type='button' className='btn btn-outline-secondary text-nowrap' onClick={unselectAll}>
                  <i className='fas fa-box-open fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Remove all ({unselectCount})</span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className='form-row mt-2'>
        <div className='col-6 d-flex justify-content-between'>
          { (!isCompactView && enableExpandFacets) ? (
            <div className='btn-group btn-group-sm btn-group-toggle'>
              <button type='button' className={ 'btn btn-outline-secondary text-nowrap' + (facetStyleText === CATEGORY_VIEW_TYPE.STANDARD ? ' active' : '') }
                      data-category={ CATEGORY_VIEW_TYPE.STANDARD }
                      onClick={ onChangeView }>Categories
              </button>
              <button type='button' className={ 'btn btn-outline-secondary text-nowrap' + (facetStyleText === CATEGORY_VIEW_TYPE.SOURCE ? ' active' : '') }
                      data-category={ CATEGORY_VIEW_TYPE.SOURCE }
                      onClick={ onChangeView }>Source Categories
              </button>
              <div className='ml-2' >
                <select className='form-control' 
                        value={selectedSearchSchema}
                        onChange={e => setSelectedSearchSchema(e.target.value)}>
                  {allSchemaNames.map(x => <option key={x} value={x}>{x}</option>)}
                </select>
              </div>
            </div>
          ) : <div /> }

          <div className='btn-group btn-group-sm'>
            {!isCompactView && <div className='custom-control custom-switch user-select-none ml-3'>
              <input data_id='expand-facets' type='checkbox' className='custom-control-input' id='expand-facets-toggle'
                     checked={ enableExpandFacets }
                     onChange={ toggleExpandFacets } />
              <label className='custom-control-label' htmlFor='expand-facets-toggle'>Expand Facets</label>
            </div>}
            {!isCompactView && <div className='custom-control custom-switch user-select-none ml-3'>
              <input data_id='expand-details' type='checkbox' className='custom-control-input' id='expand-details-toggle'
                     checked={ enableExpandDetails }
                     onChange={ toggleExpandDetails } />
              <label className='custom-control-label' htmlFor='expand-details-toggle'>Expand Details</label>
            </div>}
            {!isCompactView && <div className='custom-control custom-switch user-select-none ml-3'>
              <input data_id='exclude-discontinued' type='checkbox' className='custom-control-input' id='discontinued-filter-toggle'
                    checked={ enableDiscontinuedFilter } disabled={ discontinuedFilterMonths <= 0 }
                    onChange={ toggleDiscontinuedFilter } />
              <label className='custom-control-label' htmlFor='discontinued-filter-toggle'>Exclude Discontinued</label>
            </div>}
            {!isCompactView && <div className='custom-control custom-switch user-select-none ml-3'>
              <input tdata_id='only-favorites' ype='checkbox' className='custom-control-input' id='favourites-filter-toggle'
                     checked={ enableFavouritesFilter }
                     onChange={ toggleFavouritesFilter } />
              <label className='custom-control-label' htmlFor='favourites-filter-toggle'>Show Only Favourites</label>
            </div>}
          </div>
        </div>
        {!isCompactView && <div className='col-md-6 d-flex align-content-center justify-content-end'>
          <div className='btn-toolbar'>
            <div className='btn-group btn-group-sm'>
              { favouriteCount > 0 ? (
                <button data_id='favorites-all' type='button' className='btn btn-outline-secondary text-nowrap' onClick={ favouriteAll }>
                  <i className='fas fa-star fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Favourite all ({ favouriteCount })</span>
                </button>
              ) : null }
              { unfavouriteCount > 0 ? (
                <button data_id='unfavorite-all' type='button' className='btn btn-outline-secondary text-nowrap' onClick={ unfavouriteAll }>
                  <i className='far fa-star fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Unfavourite all ({ unfavouriteCount })</span>
                </button>
              ) : null }
            </div>
          </div>
        </div>}
      </div>
    </form>
  );
}