import moment from 'moment';
import { fromJS } from 'immutable';
import {
  buildTierToggleMap,
  buildHorizontalView
} from '../utility/reportstable-utility';
import {
  DASHBOARD_TILE_DEFINED_REPORT_REFRESH_COMPLETE,
  DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ANNOTATION_EXPAND,
  DASHBOARD_TILE_DEFINED_REPORTS_SET_SCENARIO_OVERRIDE,
  DASHBOARD_TILE_DEFINED_REPORTS_SET_ALL_SCENARIO_OVERRIDE,
  DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ONDEMAND_COMPLETE,
  DASHBOARD_TILE_DEFINED_REPORTS_SET_DISPLAY_MODE,
  DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND,

  DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_STARTED,
  DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_COMPLETE,

  DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND_TIER
} from '../actions/dashboard-tile-definedReport';
import { toJS } from '../utility/immutable-utility';
import {
  flattenReportRows,
  createDisplayMapItem,
  buildReportDisplayMap
} from '../mapper/reportMapper';
import {
  getDisplayMapKeys,
  getDisplayMode,
  enrichReportRows,
  mergeNewRows,
  mergeDisplayMap,
  buildColumns,
  selectScenarioOverride
} from '../reducer-functions/reports';
import {
  buildAnnotationDisplayMap
} from './reports';

export const dashboardTilesDefinedReportsReducer = {
  [DASHBOARD_TILE_DEFINED_REPORT_REFRESH_COMPLETE](state, action) {
    const workspace = action.data.workspace;
    const definedReport = action.data.definedReport;
    const reportOptions = action.data.reportOptions;

    const { criteria = {}, settings = {}, headers = [], annotationSections = [], status = {} } = definedReport;
    const { lens, fromDate, toDate, timeZoneId, selectedGroupings, mode } = criteria;
    const { isPivot, availableGroupings = [], filters = [], lenses = [], timeZones = [], reportingModes = [], orientation } = settings;

    const rows = (definedReport.rows ?? []).map(row => enrichReportRows(row, {}, {}));
    const columns = buildColumns(headers, lens, orientation);
    const flatRows = flattenReportRows(-1, rows);
    
    state = state.setIn(['tilesState', action.stateKey, 'workspace'], fromJS(workspace));
    
    state = state.setIn(['tilesState', action.stateKey, 'results', 'settings'], fromJS(settings))
                 .setIn(['tilesState', action.stateKey, 'results', 'columns'], fromJS(columns))
                 .setIn(['tilesState', action.stateKey, 'results', 'flatRows'], fromJS(flatRows))
                 .setIn(['tilesState', action.stateKey, 'results', 'rows'], fromJS(rows))
                 .setIn(['tilesState', action.stateKey, 'results', 'headers'], fromJS(headers))
                 .setIn(['tilesState', action.stateKey, 'results', 'annotationSections'], fromJS(annotationSections))
                 .setIn(['tilesState', action.stateKey, 'results', 'errors'], fromJS(status.errors))
                 .setIn(['tilesState', action.stateKey, 'results', 'warnings'], fromJS(status.warnings))
                 .setIn(['tilesState', action.stateKey, 'results', 'criteria'], fromJS(criteria))
                 .setIn(['tilesState', action.stateKey, 'results', 'tierToggleMap'], fromJS(buildTierToggleMap(rows, settings.maxLevel)));
    
    const { styles, specialisedStyles } = MergeStyles(definedReport.styles);
    state = state.setIn(['tilesState', action.stateKey, 'results', 'styles'], fromJS(styles))
                 .setIn(['tilesState', action.stateKey, 'results', 'specialisedStyles'], fromJS(specialisedStyles));
    
    const serverDisplayMap = buildReportDisplayMap(rows);
    const serverAnnotationsDisplayMap = buildAnnotationDisplayMap(annotationSections);
    state = state.setIn(['tilesState', action.stateKey, 'results', 'displayMap'], fromJS(serverDisplayMap))
                 .setIn(['tilesState', action.stateKey, 'results', 'annotationsDisplayMap'], fromJS(serverAnnotationsDisplayMap))
                 .setIn(['tilesState', action.stateKey, 'results', 'selectedLevel'], settings.selectedLevel);

    let rootScenario = state.getIn(['tilesState', action.stateKey, 'criteria', 'rootScenario']);
    let scenarioOverrideMap = reportOptions.scenarioOverrideMap;
    const kvps = Object.entries(scenarioOverrideMap).map(([key, value]) => {
      return { key, value };
    });

    for(let i=0; i < kvps.length; i++) {
      let result = selectScenarioOverride(
        kvps[i].key, 
        kvps[i].value, 
        flatRows,
        scenarioOverrideMap,
        rootScenario);
  
      scenarioOverrideMap = result.scenarioOverrideMap;
      rootScenario = result.rootScenario;  
    };

    state = state.setIn(['tilesState', action.stateKey, 'criteria', 'lens'], lens)
                 .setIn(['tilesState', action.stateKey, 'criteria', 'mode'], mode)
                 .setIn(['tilesState', action.stateKey, 'criteria', 'fromDate'], fromDate)
                 .setIn(['tilesState', action.stateKey, 'criteria', 'toDate'], toDate)
                 .setIn(['tilesState', action.stateKey, 'criteria', 'timeZoneId'], timeZoneId)
                 .setIn(['tilesState', action.stateKey, 'criteria', 'isPivot'], isPivot)
                 .setIn(['tilesState', action.stateKey, 'criteria', 'availableGroupings'], fromJS(availableGroupings))
                 .setIn(['tilesState', action.stateKey, 'criteria', 'groupings'], fromJS(selectedGroupings))
                 .setIn(['tilesState', action.stateKey, 'criteria', 'filters'], fromJS(filters))
                 .setIn(['tilesState', action.stateKey, 'criteria', 'isDirty'], false)
                 .setIn(['tilesState', action.stateKey, 'criteria', 'reportPath'], workspace.reportPath)
                 .setIn(['tilesState', action.stateKey, 'criteria', 'rootScenario'], rootScenario);

    state = state.setIn(['tilesConfig', action.stateKey, 'results', 'scenarioOverrideMap'], fromJS(scenarioOverrideMap));

    state = state.setIn(['tilesState', action.stateKey, 'settings', 'lenses'], fromJS(lenses))
                 .setIn(['tilesState', action.stateKey, 'settings', 'timeZones'], fromJS(timeZones))
                 .setIn(['tilesState', action.stateKey, 'settings', 'reportingModes'], fromJS(reportingModes))

    state = buildHorizontalView(state, ['tilesState', action.stateKey, 'results']);
    return state;
  },
  [DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ANNOTATION_EXPAND](state, action) {
    const nodePath = ['tilesState', action.stateKey, 'results', 'annotationsDisplayMap', action.sectionKey];
    if (!state.hasIn(nodePath)) return state;

    const displayMode = getDisplayMode(state.getIn(nodePath));
    if (displayMode === 'AlwaysOpen') return state;

    return state.setIn(nodePath, createDisplayMapItem(displayMode === 'Open' ? 'Closed' : 'Open'));
  },
  [DASHBOARD_TILE_DEFINED_REPORTS_SET_SCENARIO_OVERRIDE](state, action) {
    const {scenarioOverrideMap, rootScenario} = selectScenarioOverride(
      action.key, 
      action.value, 
      toJS(state.getIn(['tilesState', action.stateKey, 'results', 'flatRows']), []),
      toJS(state.getIn(['tilesConfig', action.stateKey, 'results', 'scenarioOverrideMap'], {})),
      state.getIn(['tilesState', action.stateKey, 'criteria', 'rootScenario']));
      
    state = state.setIn(['tilesConfig', action.stateKey, 'results', 'scenarioOverrideMap'], fromJS(scenarioOverrideMap))
                .setIn(['tilesState', action.stateKey, 'criteria', 'rootScenario'], rootScenario);
    state = buildHorizontalView(state, ['tilesState', action.stateKey, 'results']);
    return state;
  },
  [DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_ONDEMAND_COMPLETE](state, action) {
    if (!action.data) return state;

    const { rows: newRows } = action.data;
    if (!newRows || !newRows.length) return state;

    let rows = toJS(state.getIn(['tilesState', action.stateKey, 'results', 'rows']));

    mergeNewRows(rows, {}, {}, action.displayKey, newRows[0]);

    const serverDisplayMap = buildReportDisplayMap(rows);
    const clientDisplayMap = toJS(state.getIn(['tilesState', action.stateKey, 'results', 'displayMap']));

    mergeDisplayMap(serverDisplayMap, clientDisplayMap);

    state = state.setIn(['tilesState', action.stateKey, 'results', 'rows'], fromJS(rows))
      .setIn(['tilesState', action.stateKey, 'results', 'displayMap'], fromJS(clientDisplayMap));
    state = buildHorizontalView(state, ['tilesState', action.stateKey, 'results']);
    return state;
  },
  [DASHBOARD_TILE_DEFINED_REPORTS_SET_DISPLAY_MODE](state, action) {
    const nodePath = ['tilesState', action.stateKey, 'results', 'displayMap', action.displayKey];
    if (!state.hasIn(nodePath)) return state;

    const displayMapItem = toJS(state.getIn([...nodePath]));
    displayMapItem.displayMode = action.displayMode;
    state = state.setIn([...nodePath], fromJS(displayMapItem));
    state = buildHorizontalView(state, ['tilesState', action.stateKey, 'results']);
    return state;
  },
  [DASHBOARD_TILE_DEFINED_REPORTS_SET_ALL_SCENARIO_OVERRIDE](state, action) {
    let flatRows = toJS(state.getIn(['tilesState', action.stateKey, 'results', 'flatRows']), []);
    let scenarioOverrideMap = toJS(state.getIn(['tilesConfig', action.stateKey, 'results', 'scenarioOverrideMap'], {}));
    let baseScenarioOverrideMap = Object.fromEntries(flatRows.map(({ parentId, id }) => [`${parentId}|${id}`, '']));
    let fullScenarioOverrideMap = { ...baseScenarioOverrideMap, ...scenarioOverrideMap };

    Object.keys(fullScenarioOverrideMap).forEach(key => {
      const row = flatRows.find(i => `${i.parentId}|${i.id}` === key);

      fullScenarioOverrideMap[key] = (row && row.scenarioAlternatesSummary.some(sas => sas.scenarioName === action.value)) ? action.value : '';
    });

    return state.setIn(['tilesConfig', action.stateKey, 'results', 'scenarioOverrideMap'], fromJS(fullScenarioOverrideMap))
                .setIn(['tilesState', action.stateKey, 'criteria', 'rootScenario'], action.value);;
  },
  [DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND](state, action) {
    const nodePath = ['tilesState', action.stateKey, 'results', 'displayMap', action.displayKey];
    if (!state.hasIn(nodePath)) return state;

    const displayChildrenMode = getDisplayMode(state.getIn(nodePath));

    state = state.setIn(nodePath, createDisplayMapItem(displayChildrenMode === 'Open' ? 'Closed' : 'Open'));
    state = buildHorizontalView(state, ['tilesState', action.stateKey, 'results']);
    return state;
  },
  [DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_STARTED](state, action) {
    return state.setIn(['tilesState', action.stateKey, 'chart', 'isLoading'], true);
  },
  [DASHBOARD_TILE_DEFINED_REPORTS_REFRESH_CHART_COMPLETE](state, action) {
    if (!action.data) return state.setIn(['tilesState', action.stateKey, 'chart', 'isLoading'], false);

    const timeZoneId = state.getIn(['tilesState', action.stateKey, 'criteria', 'timeZoneId']);
    const { timeSeries = [], rows = [], parentKeys } = action.data;

    let series = {}, seriesData = {};

    function findKeyPath(items, keys, keyPath = []) {
      const key = keys.shift();
      const index = items.findIndex(i => i.get('key') === key);

      if (index < 0) return null;

      return keys.length
        ? findKeyPath(items.getIn([index, 'children']), keys, [...keyPath, index, 'children'])
        : [...keyPath, index];
    }

    let keyPathKeys = [...parentKeys];
    const keyPath = parentKeys && parentKeys.length
      ? findKeyPath(state.getIn(['tilesState', action.stateKey, 'results', 'rows']), keyPathKeys, ['tilesState', action.stateKey, 'results', 'rows'])
      : state.getIn(['tilesState', action.stateKey, 'chart', 'parentKeyPath']);

    if(timeSeries.length > 0) {
      timeSeries.forEach(s => {
        series[s.key] = mapChartSeries(s.key, s);
        seriesData[s.key] = rows.map(r => [moment.utc(r.dateTime).valueOf(), r[s.key]]);
      });
    } 
    else if (keyPath) {
      const localHeaders = toJS(state.getIn(['tilesState', action.stateKey, 'results', 'headers']));
      const localParentRow = toJS(state.getIn(keyPath));
      const localParentRowDisplayChildren = toJS(state.getIn(['tilesState', action.stateKey, 'results', 'displayMap', parentKeys.join('-')]));
      const localParentRowDisplayChildrenDisplayMode = getDisplayMode(localParentRowDisplayChildren);
      const localChildRows = localParentRowDisplayChildrenDisplayMode === 'Open' ? toJS(state.getIn([...keyPath, 'children'])) : [];

      [localParentRow, ...localChildRows].forEach(r => {
        const key = r.id > 0 ? `${r.id}` : `${r.key}`;
        const s = {
          key: r.key,
          id: key,
          identityId: r.id,
          name: r.name,
          sourceTimeZoneId: timeZoneId
        };

        series[key] = mapChartSeries(key, s);
        seriesData[key] = r.values.map((v, vx) => [moment.utc(localHeaders[vx].dateTime).valueOf(), v.value]);
      });
    }   

    return state.setIn(['tilesState', action.stateKey, 'chart', 'parentKeyPath'], keyPath)
      .setIn(['tilesState', action.stateKey, 'chart', 'series'], fromJS(series))
      .setIn(['tilesState', action.stateKey, 'chart', 'seriesData'], fromJS(seriesData))
      .setIn(['tilesState', action.stateKey, 'chart', 'isLoading'], false);
  },
  [DASHBOARD_TILE_DEFINED_REPORTS_TOGGLE_EXPAND_TIER](state, action) {
    let displayMap = toJS(state.getIn(['tilesState', action.stateKey, 'results', 'displayMap']));

    const keys = getDisplayMapKeys(displayMap);

    state = state.setIn(['tilesState', action.stateKey, 'results', 'selectedLevel'], action.tier);

    keys.forEach(({ displayKey, isOnDemand, nodeLevel }) => {
      state = state.setIn(['tilesState', action.stateKey, 'results', 'displayMap', displayKey, 'displayMode'], action.tier >
        nodeLevel ? isOnDemand ? 'OnDemand-Open' : 'Open'
        : isOnDemand ? 'OnDemand' : 'Closed');
    });

    state = buildHorizontalView(state, ['tilesState', action.stateKey, 'results']);
    return state;
  },
};

const mapChartSeries = (key, data) => ({
  key: key,
  id: data.id,
  identityId: data.identityId || data.id,
  name: data.name,
  style: data.style,
  granularity: data.granularity,
  sourceTimeZoneId: data.sourceTimeZoneId,
  unit: data.unit,
  firstDataPointUtc: data.firstDataPointUtc,
  lastDataPointUtc: data.lastDataPointUtc,
  lastUpdatedUtc: data.lastUpdatedUtc,
  timestampUtc: data.timestampUtc,
  type: data.type || 'line',
  color: data.color || '#000000'.replace(/0/g, () => Math.floor(Math.random() * 0xf).toString(16)),
  lineWidth: data.lineWidth || 1,
  isDisabled: data.isDisabled || false
});

function MergeStyles(existingStyles) {
  if (existingStyles) {
    const specialProperties = ['commaSeparated', 'decimalPlaces', 'valueFormat'];
    const { styles, specialisedStyles } = Object.entries(existingStyles).reduce((accumulator, [key, value]) => {
      let styles = {}, specialisedStyles = {};

      Object.entries(value).forEach(([property, value]) => {
        let o = specialProperties.includes(property) ? specialisedStyles : styles;
        o[key] = o[key] || {};
        o[key][property] = value;
      });

      return {
        ...accumulator,
        styles: {
          ...accumulator.styles,
          ...styles
        },
        specialisedStyles: {
          ...accumulator.specialisedStyles,
          ...specialisedStyles
        }
      };
    }, {});

    return {
      styles,
      specialisedStyles
    };
  }

  return {
    styles: {},
    specialisedStyles: {}
  }
}