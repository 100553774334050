import { fromJS } from 'immutable';
import moment from 'moment';
import { LOCATION_CHANGE } from "redux-first-history";
import { parse as qsParse } from 'querystring';
import { createReducer } from '../utility/redux-utility';
import { parseDate } from '../utility/date-utility';
import { isNotEmpty } from '../utility/text-utility';
import { toJS } from '../utility/immutable-utility';
import {
  mapRangeReasons,
  mergeGeneratedDataPointsIntoEditedDataPoints,
  overlayEditedDataPointsOntoOriginalDataPoints,
  mergeAdjustmentsIntoRange,
  convertAdjustmentsToRange,
  validateRangeForm,
  mapComments
 } from '../reducer-functions/adjustmentRangeEditor';
import {
  ADJUSTMENT_RANGE_EDITOR_UNINITIALISE,
  ADJUSTMENT_RANGE_EDITOR_SET_TIMESERIES_ID,
  ADJUSTMENT_RANGE_EDITOR_SET_IS_DATE_ONLY,
  ADJUSTMENT_RANGE_EDITOR_LOAD_STARTED,
  ADJUSTMENT_RANGE_EDITOR_LOAD_STOPPED,
  ADJUSTMENT_RANGE_EDITOR_LOAD_COMPLETE,
  ADJUSTMENT_RANGE_EDITOR_ADD_RANGE,
  ADJUSTMENT_RANGE_EDITOR_GENERATE_STARTED,
  ADJUSTMENT_RANGE_EDITOR_GENERATE_STOPPED,
  ADJUSTMENT_RANGE_EDITOR_GENERATE_COMPLETE,
  ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_COMPLETE,
  ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_STARTED,
  ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_COMPLETE,
  ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_STARTED,
  ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_COMPLETE,
  ADJUSTMENT_RANGE_EDITOR_UPDATE_TOOLBAR_PROPERTY,
  ADJUSTMENT_RANGE_EDITOR_SELECT_RANGE,
  ADJUSTMENT_RANGE_EDITOR_CLEAR_EDIT_RANGE,
  ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_PROPERTY,
  ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_ITEMS
} from '../actions/adjustment-range-editor';

const adjustmentRangeEditorReducer = {
  [ADJUSTMENT_RANGE_EDITOR_UNINITIALISE](state, action) {
    return state.setIn(['isInitialised'], false)
      .setIn(['timeSeriesId'], null)
      .setIn(['selectedRangeId'], '')
      .setIn(['firstSelectedRow'], -1)
      .setIn(['toolbar', 'periodStart'], null)
      .setIn(['toolbar', 'periodEnd'], null)
      .setIn(['rangeEdit', 'periodStart'], null)
      .setIn(['rangeEdit', 'periodEnd'], null)
      .setIn(['rangeEdit', 'operation'], '')
      .setIn(['rangeEdit', 'adjustedValue'], '')
      .setIn(['rangeEdit', 'type'], '')
      .setIn(['rangeEdit', 'reason'], '')
      .setIn(['rangeEdit', 'isInclusive'], false)
      .setIn(['rangeEdit', 'isEditing'], false)
      .setIn(['rangeEdit', 'hasChanges'], false)
      .setIn(['ranges'], fromJS([]))
      .setIn(['originalDataPoints'], fromJS([]))
      .setIn(['editedDataPoints'], null)
      .setIn(['dataPoints'], fromJS([]))
      .setIn(['comments'], fromJS([]));
  },
  [ADJUSTMENT_RANGE_EDITOR_SET_TIMESERIES_ID](state, action) {
    const timeSeriesId = action.id ? `${action.id}` : '';

    return state.setIn(['isInitialised'], false)
      .setIn(['timeSeriesId'], timeSeriesId);
  },
  [ADJUSTMENT_RANGE_EDITOR_SET_IS_DATE_ONLY](state, action) {
    const isDateOnly = action.granularityType === 'Day' || action.granularityType === 'Month';

    return state.setIn(['isDateOnly'], isDateOnly);
  },
  [ADJUSTMENT_RANGE_EDITOR_LOAD_STARTED](state, action) {
    return state.setIn(['isLoading'], true);
  },
  [ADJUSTMENT_RANGE_EDITOR_LOAD_STOPPED](state, action) {
    return state.setIn(['isLoading'], false);
  },
  [ADJUSTMENT_RANGE_EDITOR_LOAD_COMPLETE](state, action) {
    const selectedRangeId = state.getIn(['selectedRangeId']);
    const { filter = {}, ranges = [], items = [] } = action.data ?? {};
    const reasons = mapRangeReasons(ranges);
    const originalRanges = ranges.map(i => ({
      ...i,
      operation: i.operation === 'Unset' ? '-' : i.operation,
      type: i.type === 'None' ? '-' : i.type,
      isHighlighted: `${i.id}` === `${selectedRangeId}`,
      periodStart: moment.utc(i.periodStart),
      periodEnd: moment.utc(i.periodEnd),
      updatedUtc: moment.utc(i.updatedUtc)
    })).sort((a, b) => b.updatedUtc - a.updatedUtc);
    const originalDataPoints = items.map(i => ({
      ...i,
      periodStartUtc: moment.utc(i.periodStartUtc),
      periodStart: moment.utc(i.periodStart),
      operation: i.operation !== 'Unset' && i.type !== 'None' ? i.operation : undefined,
      type: i.type !== 'None' ? i.type : undefined,
      reason: reasons[i.adjustmentId],
      isDimmed: isNotEmpty(selectedRangeId) && `${i.adjustmentId}` !== `${selectedRangeId}`,
      isOffGran: i.isOffGran
    }));

    let filterPeriodStart = moment.utc(filter.periodStart);
    if (filterPeriodStart && filterPeriodStart.year() <= 1901)
      filterPeriodStart = null;

    let filterPeriodEnd = moment.utc(filter.periodEnd);
    if (filterPeriodEnd && filterPeriodEnd.year() === 9999)
      filterPeriodEnd = null;

    return state.setIn(['isInitialised'], true)
      .setIn(['isLoading'], false)
      .setIn(['originalDataPoints'], fromJS(originalDataPoints))
      .setIn(['dataPoints'], fromJS(originalDataPoints))
      .setIn(['ranges'], fromJS(originalRanges))
      .setIn(['toolbar', 'periodStart'], filterPeriodStart)
      .setIn(['toolbar', 'periodEnd'], filterPeriodEnd);
  },
  [ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_STARTED](state, action) {
    return state.setIn(['isSaving'], true);
  },
  [ADJUSTMENT_RANGE_EDITOR_SAVE_RANGE_COMPLETE](state, action) {
    return state.setIn(['isSaving'], false);
  },
  [ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_STARTED](state, action) {
    return state.setIn(['isDeleting'], true);
  },
  [ADJUSTMENT_RANGE_EDITOR_DELETE_RANGE_COMPLETE](state, action) {
    return state.setIn(['isDeleting'], false)
      .setIn(['selectedRangeId'], '')
      .updateIn(['ranges'], r => r.map(i => i.set('isHighlighted', false)));
  },
  [ADJUSTMENT_RANGE_EDITOR_UPDATE_TOOLBAR_PROPERTY](state, action) {
    return state.setIn(['toolbar', action.key], action.value);
  },
  [ADJUSTMENT_RANGE_EDITOR_SELECT_RANGE](state, action) {
    const selectedRangeId = state.get('selectedRangeId');
    let rangeId = action.id ? `${action.id}` : '';

    if (!!selectedRangeId && `${selectedRangeId}` === rangeId)
      rangeId = '';

    const dataPoints = state.getIn(['originalDataPoints']);
    let newState = state.updateIn(['ranges'], r => r.map(i => i.set('isHighlighted', `${i.get('id')}` === rangeId)))
      .setIn(['selectedRangeId'], rangeId)
      .setIn(['rangeEdit', 'periodStart'], null)
      .setIn(['rangeEdit', 'periodEnd'], null)
      .setIn(['rangeEdit', 'operation'], '')
      .setIn(['rangeEdit', 'adjustedValue'], '')
      .setIn(['rangeEdit', 'type'], '')
      .setIn(['rangeEdit', 'reason'], '')
      .setIn(['rangeEdit', 'isInclusive'], false)
      .setIn(['rangeEdit', 'isEditing'], false)
      .setIn(['rangeEdit', 'hasChanges'], false)
      .setIn(['editedDataPoints'], null)
      .setIn(['dataPoints'], dataPoints);

    const firstSelectedRow = !!rangeId
      ? state.getIn(['dataPoints']).findIndex(i => `${i.get('adjustmentId')}` === rangeId)
      : -1;
    return newState.setIn(['firstSelectedRow'], firstSelectedRow)
      .updateIn(['dataPoints'], dp => dp.map(i => i.set('isDimmed', !!rangeId && `${i.get('adjustmentId')}` !== rangeId)));
  },
  [ADJUSTMENT_RANGE_EDITOR_CLEAR_EDIT_RANGE](state, action) {
    const dataPoints = state.getIn(['originalDataPoints']);
    return state.updateIn(['ranges'], r => r.map(i => i.set('isHighlighted', false)))
      .setIn(['selectedRangeId'], '')
      .setIn(['firstSelectedRow'], -1)
      .setIn(['rangeEdit', 'periodStart'], null)
      .setIn(['rangeEdit', 'periodEnd'], null)
      .setIn(['rangeEdit', 'operation'], '')
      .setIn(['rangeEdit', 'adjustedValue'], '')
      .setIn(['rangeEdit', 'type'], '')
      .setIn(['rangeEdit', 'reason'], '')
      .setIn(['rangeEdit', 'isInclusive'], false)
      .setIn(['rangeEdit', 'isEditing'], false)
      .setIn(['rangeEdit', 'hasChanges'], false)
      .setIn(['editedDataPoints'], null)
      .setIn(['dataPoints'], dataPoints)
      .setIn(['comments'], fromJS([]));
  },
  [ADJUSTMENT_RANGE_EDITOR_ADD_RANGE](state, action) {
    const {
      periodStart = null,
      periodEnd = null,
      adjustedValue = '',
      operation = 'Overwrite',
      type = 'Standard',
      reason = ''
    } = action.range ?? {};
    const hasChanges = !!action.range && (periodStart || periodEnd);

    let newState = state
      .updateIn(['ranges'], r => r.map(i => i.set('isHighlighted', false)))
      .setIn(['selectedRangeId'], '')
      .setIn(['firstSelectedRow'], -1)
      .setIn(['rangeEdit', 'periodStart'], periodStart)
      .setIn(['rangeEdit', 'periodEnd'], periodEnd)
      .setIn(['rangeEdit', 'operation'], operation)
      .setIn(['rangeEdit', 'adjustedValue'], adjustedValue)
      .setIn(['rangeEdit', 'type'], type)
      .setIn(['rangeEdit', 'reason'], reason)
      .setIn(['rangeEdit', 'isInclusive'], false)
      .setIn(['rangeEdit', 'isEditing'], true)
      .setIn(['rangeEdit', 'hasChanges'], hasChanges)
      .setIn(['editedDataPoints'], fromJS([]));

    const rangeEdit = toJS(newState.getIn(['rangeEdit']), {});
    const comments = toJS(newState.getIn(['comments']), []);
    const isValidForm = validateRangeForm(rangeEdit, comments);

    return newState.setIn(['rangeEdit', 'isValidForm'], isValidForm);
  },
  [ADJUSTMENT_RANGE_EDITOR_GENERATE_STARTED](state, action) {
    return state.setIn(['isGenerating'], true);
  },
  [ADJUSTMENT_RANGE_EDITOR_GENERATE_STOPPED](state, action) {
    return state.setIn(['isGenerating'], false);
  },
  [ADJUSTMENT_RANGE_EDITOR_GENERATE_COMPLETE](state, action) {
    const { ranges, items } = action.data;
    const [range] = ranges;
    const { periodStart, periodEnd } = range;

    if (!items || !items.length)
      return state;

    const rangePeriodStart = moment.utc(periodStart);
    const rangePeriodEnd = moment.utc(periodEnd);
    const generatedDataPoints = items.map(i => ({
      ...i,
      periodStartUtc: moment.utc(i.periodStartUtc),
      periodStart: moment.utc(i.periodStart)
    }));

    const editedDataPoints = toJS(state.getIn(['editedDataPoints']), []);
    const rangeEdit = toJS(state.getIn(['rangeEdit']), {});
    const newEditedDataPoints = mergeGeneratedDataPointsIntoEditedDataPoints(editedDataPoints, generatedDataPoints, rangeEdit, action.property);

    const originalDataPoints = toJS(state.getIn(['originalDataPoints']), []);
    const newDataPoints = overlayEditedDataPointsOntoOriginalDataPoints(originalDataPoints, newEditedDataPoints);
    const comments = mapComments(newDataPoints);
    const firstSelectedRow = newDataPoints.findIndex(i => i.isDimmed === false);
    const isValidForm = validateRangeForm(rangeEdit, comments);

    return state.setIn(['editedDataPoints'], fromJS(newEditedDataPoints))
      .setIn(['dataPoints'], fromJS(newDataPoints))
      .setIn(['comments'], fromJS(comments))
      .setIn(['rangeEdit', 'periodStart'], rangePeriodStart)
      .setIn(['rangeEdit', 'periodEnd'], rangePeriodEnd)
      .setIn(['rangeEdit', 'isInclusive'], false)
      .setIn(['rangeEdit', 'isValidForm'], isValidForm)
      .setIn(['isGenerating'], false)
      .setIn(['firstSelectedRow'], firstSelectedRow);
  },
  [ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_PROPERTY](state, action) {
    let newState = state.setIn(['rangeEdit', action.key], action.value)
      .setIn(['rangeEdit', 'hasChanges'], true);

    if (action.key === 'operation' && (action.value === 'Allow' || action.value === 'Block'))
      newState = newState.setIn(['rangeEdit', 'adjustedValue'], '');

    const rangeEdit = toJS(newState.getIn(['rangeEdit']), {});
    const comments = toJS(newState.getIn(['comments']), []);
    const isValidForm = validateRangeForm(rangeEdit, comments);

    return newState.setIn(['rangeEdit', 'isValidForm'], isValidForm);
  },
  [ADJUSTMENT_RANGE_EDITOR_EDIT_RANGE_ITEMS](state, action) {
    const editedDataPoints = toJS(state.getIn(['editedDataPoints']), []);
    const newEditedDataPoints = mergeAdjustmentsIntoRange(editedDataPoints, action.items);

    const originalDataPoints = toJS(state.getIn(['originalDataPoints']), []);
    const newDataPoints = overlayEditedDataPointsOntoOriginalDataPoints(originalDataPoints, newEditedDataPoints);
    const comments = mapComments(newDataPoints);

    const range = convertAdjustmentsToRange(newEditedDataPoints);
    const isValidForm = validateRangeForm(range, comments);
    const { periodStart, periodEnd, adjustedValue, operation, type, reason } = range;

    let newState = state.updateIn(['ranges'], r => r.map(i => i.set('isHighlighted', false)))
      .setIn(['selectedRangeId'], '')
      .setIn(['firstSelectedRow'], -1)
      .setIn(['rangeEdit', 'periodStart'], periodStart)
      .setIn(['rangeEdit', 'operation'], operation)
      .setIn(['rangeEdit', 'adjustedValue'], adjustedValue)
      .setIn(['rangeEdit', 'type'], type)
      .setIn(['rangeEdit', 'reason'], reason)
      .setIn(['rangeEdit', 'isEditing'], true)
      .setIn(['rangeEdit', 'hasChanges'], true)
      .setIn(['rangeEdit', 'isValidForm'], isValidForm)
      .setIn(['editedDataPoints'], fromJS(newEditedDataPoints))
      .setIn(['dataPoints'], fromJS(newDataPoints))
      .setIn(['comments'], fromJS(comments));

    const { periodStart: lastItemPeriodStart } = action.items[action.items.length - 1];
    const { periodStart: lastDataPointPeriodStart } = newEditedDataPoints[newEditedDataPoints.length - 1];
    const isInclusive = lastItemPeriodStart - lastDataPointPeriodStart === 0;
    if (isInclusive)
      newState = newState.setIn(['rangeEdit', 'periodEnd'], periodEnd)
        .setIn(['rangeEdit', 'isInclusive'], isInclusive);

    return newState;
  },
  [ADJUSTMENT_RANGE_EDITOR_GENERATE_PERIOD_END_COMPLETE](state, action) {
    return state.setIn(['rangeEdit', 'periodEnd'], moment.utc(action.periodEnd))
      .setIn(['rangeEdit', 'isInclusive'], false);
  },
  [LOCATION_CHANGE](state, action) {
    const { location } = action.payload;
    if (location.pathname.indexOf('/timeseries') < 0) return state;

    let periodStart = null;
    let periodEnd = null;

    const { search } = location;
    if (search && search.startsWith('?')) {
      const qsParams = qsParse(search.substring(1));
      periodStart = qsParams.fromDate ? parseDate(qsParams.fromDate) : null;
      periodEnd = qsParams.toDate ? parseDate(qsParams.toDate) : null;

      if (periodStart || periodEnd)
        return state
          .setIn(['toolbar', 'periodStart'], periodStart)
          .setIn(['toolbar', 'periodEnd'], periodEnd)
          .setIn(['isInitialised'], false);
    }

    return state;
  }
};

export const adjustmentRangeEditor = createReducer(null, {
  ...adjustmentRangeEditorReducer
});