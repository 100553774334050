import moment from 'moment';
import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import {
  cloneBasketItem,
  mapEditTimeSeriesToBasketTimeSeries,
  mapToTimeSeries,
  projectTopLevelSettingsToBasket,
  mergeTimeSeriesCollectionToBasket,
  rebuildDisplayNamesToState,
  getTimeSeriesName,
  mapSeriesOptions,
  updateSeriesOptions,
  getHighchartsPropertiesFromTimeSeries,
  getTimeSeriesPropertiesFromHighchartsJson
} from '../utility/analysis-basket-utility';

import {
  ANALYSIS_CHARTING_BASKET_REMOVE_ITEM,
  ANALYSIS_SELECTION_BASKET_REMOVE_ALL,
  ANALYSIS_CHARTING_BASKET_CLONE_TIMESERIES,
  ANALYSIS_CHARTING_BASKET_SET_VALUE,
  ANALYSIS_CHARTING_BASKET_BEGIN_EDIT,
  ANALYSIS_CHARTING_BASKET_SAVE_EDIT,
  ANALYSIS_CHARTING_BASKET_CANCEL_EDIT,
  ANALYSIS_CHARTING_BASKET_EDIT_VALUE,
  ANALYSIS_CHARTING_BASKET_EDIT_ASAT,
  ANALYSIS_EVO_REFRESH_COMPLETE,
  ANALYSIS_REFRESH_COMPLETE,
  ANALYSIS_TOGGLE_ALL_SERIES_VISIBILITY2,
  ANALYSIS_TOGGLE_SERIES_VISIBILITY2,
  ANALYSIS_TOGGLE_CHART_SERIES_VISIBILITY,
  ANALYSIS_UPDATE_SERIES2,
  ANALYSIS_SET_XAXIS_SERIES,
  ANALYSIS_UPDATE_ALL_SERIES,
  ANALYSIS_TOGGLE_SERIES_NAME_STYLE,
  ANALYSIS_UPDATE_ALL_SERIES_NAME_STYLE,
  ANALYSIS_UPDATE_SERIES_CUSTOM_NAME,
  ANALYSIS_SELECTION_MOVE_TIME_SERIES,
  ANALYSIS_SET_BASKET_VIEW,
  ANALYSIS_EDIT_HIGHCHARTS_JSON,
  ANALYSIS_UPDATE_HIGHCHARTS_JSON,
  ANALYSIS_CANCEL_HIGHCHARTS_JSON,
  ANALYSIS_PREVIEW_HIGHCHARTS_JSON,
  ANALYSIS_APPLY_HIGHCHARTS_JSON,
  ANALYSIS_SET_FILTER_VALUE
} from '../actions/analysis-basket-v2';
import {
  ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_BEGIN,
  ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_COMPLETE
} from '../actions/analysis';
import {
  getIsHorizontal,
  applyExistingAdjustments,
  getTableAddressColumnsLookups,
  getTableAddressRowsLookups,
  getTableAddressDatesLookups,
} from '../reducer-functions/analysis';
import {
  mapReportToTable,
  mapReportTimeSeriesCollectionDataToChartData,
  sortTableData
} from '../reducer-functions/analysis-table-mapper';
import {
  getTotalVisibleColumnWidths
} from '../utility/style-utility';
import {
  addToBaseline,
  removeFromBaseline,
  updateBaseline
} from '../reducer-functions/analysis-dynamic-workspace';
import deepmerge from 'deepmerge';

export const basketReducerV2 = {
  [ANALYSIS_SET_BASKET_VIEW](state, action) {
    return state.setIn(['basketSettings','basketView'], action.basketView);
  },
  [ANALYSIS_CHARTING_BASKET_REMOVE_ITEM](state, action) {
    const basketItemKey = action.key;
    let basket = toJS(state.getIn(['workspace', 'timeseries']), []);

    basket = basket.filter(t => t.key !== basketItemKey);

    state = state
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['workspace', 'isDirty'], true);

    state = rebuildDisplayNamesToState(state);

    const baselineBasket = removeFromBaseline(
      toJS(state.getIn(['workspace', 'baselineBasket'])),
      basket,
      basketItemKey);

    return state
      .setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket));
  },
  [ANALYSIS_SELECTION_BASKET_REMOVE_ALL](state, action) {
    return state
      .setIn(['workspace', 'timeseries'], fromJS([]))
      .setIn(['workspace', 'baselineBasket'], fromJS([]))
      .setIn(['workspace', 'isDirty'], true);
  },
  [ANALYSIS_CHARTING_BASKET_CLONE_TIMESERIES](state, action) {
    const {basket, newBasketItem} = cloneBasketItem({
      basket: toJS(state.getIn(['workspace', 'timeseries']), []),
      key: action.key,
      nameStyle: action.nameStyle,
      suppressName: action.suppressName,
      withProperty: action.withProperty,
      withValue: action.withValue
    });

    const baselineBasket = addToBaseline(
      toJS(state.getIn(['workspace', 'baselineBasket'])),
      basket,
      newBasketItem);

    return state
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket))
      .setIn(['workspace', 'isDirty'], true);
  },
  [ANALYSIS_CHARTING_BASKET_SET_VALUE](state, action) {
    const basket = state.getIn(['workspace', 'timeseries']);
    const index = basket.findIndex(i => i.get('key') === `${action.key}`)
    if(index !== -1) {
      return state.setIn(['workspace', 'timeseries', index, action.property], action.value);
    }
    return state;
  },
  [ANALYSIS_CHARTING_BASKET_BEGIN_EDIT](state, action) {
    let timeseries = toJS(state.getIn(['workspace', 'timeseries']), []);
    var edit = timeseries.find(ts => ts.key === action.key);
    if (edit)
    {
      if(!edit.forecastWindow) {
        edit.forecastWindow = {
          fromDateMode: 'rel',
          absFromDate: moment.utc().startOf('day').add(-14, 'day').format('YYYY-MM-DDTHH:mm'),
          relFromDate: '1D',
          toDateMode: 'rel',
          absToDate: moment.utc().startOf('day').add(1, 'day').format('YYYY-MM-DDTHH:mm'),
          relToDate: '-14D'
        };
        edit.forecastWindowAsAtOperation = 'Close';
        edit.forecastWindowAsAtWindowRelativeToDataPoint = 'true';
        edit.forecastWindowDataPointOperation = 'Avg';
        edit.forecastWindowStrictCountForOperation = 'true';
        edit.forecastWindowFillForwardAsAt = 'true';
      }
      return state.setIn(['ui', 'editTimeSeries'], fromJS(edit));
    }

    return state;
  },
  [ANALYSIS_CHARTING_BASKET_SAVE_EDIT](state, action) {
    let timeseries = toJS(state.getIn(['workspace', 'timeseries']), []);
    let editTimeSeries = toJS(state.getIn(['ui', 'editTimeSeries']), {});
    editTimeSeries.preventNameOverride = false;
    let replace = timeseries.find(ts => ts.key === editTimeSeries.key);
    if (replace) {
      Object.assign(replace, mapEditTimeSeriesToBasketTimeSeries(editTimeSeries));
      if (replace.windowType === 'none')
        delete replace.window;

      if (replace.asAtWindowEnabled === false)
        delete replace.asAtWindow;
    }

    return state
      .setIn(['ui', 'editTimeSeries'], undefined)
      .setIn(['workspace', 'timeseries'], fromJS(timeseries))
      .setIn(['workspace', 'isDirty'], true);
  },
  [ANALYSIS_CHARTING_BASKET_CANCEL_EDIT](state, action) {
    return state.setIn(['ui', 'editTimeSeries'], undefined);
  },
  [ANALYSIS_CHARTING_BASKET_EDIT_VALUE](state, action) {
    if (!action.keyPath) return;

    const fullKeyPath = ['ui', 'editTimeSeries', ...(Array.isArray(action.keyPath) ? action.keyPath : [action.keyPath])];
    const {value} = action;

    if (Array.isArray(value) && !value.length)
      return state.deleteIn(fullKeyPath, fromJS(action.value));
   
    if (typeof value === 'object' && !action.toJS)
      return state.setIn(fullKeyPath, fromJS(value));

    return state.setIn(fullKeyPath, value);
  },
  [ANALYSIS_CHARTING_BASKET_EDIT_ASAT](state, action) {
    const timeseriesVersionSettings = toJS(state.getIn(['ui', 'timeseriesVersionSettings']), {});
    const prevAsAt = state.getIn(['ui', 'editTimeSeries', 'asAt']);

    if (prevAsAt)
      timeseriesVersionSettings.asAts.selection = timeseriesVersionSettings.asAts.selection.filter(i => i !== prevAsAt);

    if (action.value)
      timeseriesVersionSettings.asAts.selection.push(action.value);

    return state.setIn(['ui', 'timeseriesVersionSettings'], fromJS(timeseriesVersionSettings))
                .setIn(['ui', 'editTimeSeries', 'asAt'], action.value);
  },
  [ANALYSIS_REFRESH_COMPLETE](state, action) {
    state = state.setIn(['chart', 'isLoading'], false);
    if (action.timeSeriesReport === undefined)
      return state;
      
    const previousTableData = toJS(state.getIn(['table', 'data']), []);
    const previousTableHeaders = state.getIn(['table', 'headers']);
    const lens = state.getIn(['workspace', 'lens']);
    const comparisonMode = state.getIn(['workspace', 'comparisonMode']);
    const stackingType = state.getIn(['workspace', 'stackingType']);
    const basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const tableSettings = toJS(state.getIn(['workspace', 'tableSettings']), {});
    let { timeSeriesReport = {}, timeSeriesExpressionNames = [], reportRequest = {}, expressionRequest = {}, reapplyPreviousAdjustments = true } = action;
    let { timeSeries: timeSeriesCollection = [], data = [], timeSeriesWindows} = timeSeriesReport ?? {};

    const hasXAxis = basket.some(i => i.isXAxis);
    const newBasket = mergeTimeSeriesCollectionToBasket({ basket, timeSeriesCollection, timeSeriesExpressionNames, preventNameOverride: true });
    const seriesData = mapReportTimeSeriesCollectionDataToChartData({ basket:newBasket, data, comparisonMode, timeSeriesWindows });
    const chartTimeSeries = newBasket.filter(i => !i.isXAxis && !i.isDisabled).map(i => ({ ...i }));
    const chartSeries = chartTimeSeries.map((i, index) => mapSeriesOptions(i, seriesData[i.key], index));

    const source = {
      reportRequest : reportRequest,
      expressionRequest : expressionRequest,
      type: 'analysis-report',
      data
    };

    let {tableStyles, tableRowStyles, tableData, tableHeaders, adjustments, isHorizontal, statistics} = mapReportToTable(tableSettings, newBasket, source, timeSeriesWindows);
    tableData = sortTableData(isHorizontal, tableData, tableSettings.orderBy, tableSettings.orderByDirection);
    const addressLookup = {
      rows :getTableAddressRowsLookups(isHorizontal, tableData),
      columns : getTableAddressColumnsLookups(isHorizontal, tableHeaders),
      dates: getTableAddressDatesLookups(isHorizontal, tableData, tableHeaders)
    };

    if (reapplyPreviousAdjustments)
      applyExistingAdjustments(previousTableHeaders, previousTableData, tableData);

    const isDirty = state.getIn(['workspace', 'isDirty']);
    state = state.setIn(['table', 'styles'], fromJS(tableStyles))
                .setIn(['table', 'rowStyles'], fromJS(tableRowStyles))
                .setIn(['table', 'headers'], fromJS(tableHeaders))
                .setIn(['table', 'data'], fromJS(tableData))
                .setIn(['table', 'addressLookup'], fromJS(addressLookup))
                .setIn(['data', 'statistics'], fromJS(statistics))
                .setIn(['workspace', 'timeseries'], fromJS(newBasket))
                .setIn(['workspace', 'isDirty'], false)
                .setIn(['workspace', 'isUnsaved'], isDirty)
                .setIn(['source'], fromJS(source))
                .setIn(['chart', 'seriesData'], fromJS(seriesData))
                .setIn(['chart', 'timeSeries'], fromJS(chartTimeSeries))
                .setIn(['chart', 'series'], fromJS(chartSeries))
                .setIn(['chart', 'lens'], lens)
                .setIn(['chart', 'comparisonMode'], comparisonMode)
                .setIn(['chart', 'stackingType'], stackingType)
                .setIn(['chart', 'refreshRequired'], false)
                .setIn(['chart', 'hasXAxis'], hasXAxis)
                .setIn(['timeSeriesWindows' ], fromJS(timeSeriesWindows))
                .setIn(['adjustments', 'existingAdjustmentsMap'], fromJS(adjustments))
                .setIn(['adjustments', 'editSelection', 'editCell'], fromJS({}))
                .setIn(['adjustments', 'editSelection', 'cursorCell'], fromJS({}))
                .setIn(['adjustments', 'editSelection', 'range'], fromJS({}));

    state = rebuildDisplayNamesToState(state);
    return state;
  },
  [ANALYSIS_EVO_REFRESH_COMPLETE](state, action) {
    state = state.setIn(['chart', 'isLoading'], false);
    if (!action.data.rows)
      return state;
      
    let data = action.data.rows;
    let chartTimeSeries = [];
    let seriesData = {};
    let basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const tableSettings = toJS(state.getIn(['workspace', 'tableSettings']));

    basket.forEach(s => {
      let xAxisConverter = row => moment.utc(row.dateTime).valueOf();
      seriesData[s.key] = data.filter(x => x[s.key]).map(r => [xAxisConverter(r), r[s.key]]);

      let selectedTimeseries = basket.find(t => t.identityId === s.id);
      if (selectedTimeseries) {
        selectedTimeseries.style = s.style;
        selectedTimeseries.source = s.source;
        selectedTimeseries.dataType = s.dataType;
        selectedTimeseries.granularity = s.granularity;
        selectedTimeseries.responseLens = s.responseLens;
        selectedTimeseries.sourceTimeZoneId = s.sourceTimeZoneId;
        selectedTimeseries.lastUpdatedUtc = s.updatedDateTime;
        selectedTimeseries.unit = s.unit;
      }

      chartTimeSeries.push(s);
    });

    const chartSeries = chartTimeSeries.map((s, index) => mapSeriesOptions(s, seriesData[s.key], index));
    const source = {
      type: 'analysis-evo-report',
      data
    };

    const {tableStyles, tableRowStyles, tableData, tableHeaders, isHorizontal, statistics} = mapReportToTable(tableSettings, basket, source);
    const addressLookup = {
      rows :getTableAddressRowsLookups(isHorizontal, tableData),
      columns : getTableAddressColumnsLookups(isHorizontal, tableHeaders),
      dates: getTableAddressDatesLookups(isHorizontal, tableData, tableHeaders)
    };

    const isDirty = state.getIn(['workspace', 'isDirty']);
    state = state.setIn(['table', 'styles'], fromJS(tableStyles))
                 .setIn(['table', 'rowStyles'], fromJS(tableRowStyles))
                 .setIn(['table', 'headers'], fromJS(tableHeaders))
                 .setIn(['table', 'data'], fromJS(tableData))
                 .setIn(['table', 'addressLookup'], fromJS(addressLookup))
                 .setIn(['data', 'statistics'], fromJS(statistics))
                 .setIn(['workspace', 'isDirty'], false)
                 .setIn(['workspace', 'isUnsaved'], isDirty)
                 .setIn(['source'], fromJS(source))
                 .setIn(['chart', 'timeSeries'], fromJS(chartTimeSeries))
                 .setIn(['chart', 'series'], fromJS(chartSeries))
                 .setIn(['chart', 'refreshRequired'], false);

    return state;
  },
  [ANALYSIS_TOGGLE_ALL_SERIES_VISIBILITY2](state, action) {
    const basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const chartSeries = toJS(state.getIn(['chart', 'series']), []);
    const isHorizontal = getIsHorizontal(state);

    if (isHorizontal){
      const tableData = toJS(state.getIn(['table', 'data']));
      tableData.forEach((row, rIndex) => {
        row.title.isDisabled = action.value;
        state = state.setIn(['table', 'data', rIndex, 'title', 'isDisabled'], row.title.isDisabled);
      });

      const rows = getTableAddressRowsLookups(isHorizontal, tableData);
      state = state.setIn(['table', 'addressLookup', 'rows'], fromJS(rows));
    }
    else {
      const tableHeaders = toJS(state.getIn(['table', 'headers']));
      tableHeaders.forEach((th, thIndex) => {
        if (thIndex > 0) { // skip the date-time column
          th.isDisabled = action.value;
          state = state.setIn(['table', 'headers', thIndex, 'isDisabled'], th.isDisabled);
        }
      });

      const tableWidth = getTotalVisibleColumnWidths(tableHeaders);
      state = state.setIn(['table', 'styles', 'width'], tableWidth);
      const columns = getTableAddressColumnsLookups(isHorizontal, tableHeaders);
      state = state.setIn(['table', 'addressLookup', 'columns'], fromJS(columns));
    }

    basket.forEach((ts,tsIndex) => {
      ts.isDisabled = action.value;
      state = state.setIn(['workspace', 'timeseries', tsIndex, 'isDisabled'], ts.isDisabled);
        chartSeries.forEach((cs, csIndex) => {
          if (cs.id === ts.key){
            cs.visible = !ts.isDisabled;
            state = state.setIn(['chart', 'series', csIndex, 'visible'], cs.visible);
          }
        });
    });

    return state;
  },
  [ANALYSIS_TOGGLE_CHART_SERIES_VISIBILITY](state, action) {
    const basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const chartSeries = toJS(state.getIn(['chart', 'series']), []);
    basket.forEach((ts,) => {
      if (ts.key === action.key){
        chartSeries.forEach((cs, csIndex) => {
          if (cs.id === ts.key){
            cs.visible = !cs.visible;
            state = state.setIn(['chart', 'series', csIndex, 'visible'], cs.visible);
          }
        });
      }
    });

    return state;
  },
  [ANALYSIS_TOGGLE_SERIES_VISIBILITY2](state, action) {
    const basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const chartSeries = toJS(state.getIn(['chart', 'series']), []);
    const isHorizontal = getIsHorizontal(state);

    if (isHorizontal){
      const tableData = toJS(state.getIn(['table', 'data']));
      tableData.forEach((row, rIndex) => {
        if (row.key === action.key){
          row.title.isDisabled = !row.title.isDisabled;
          state = state.setIn(['table', 'data', rIndex, 'title', 'isDisabled'], row.title.isDisabled);
        }
      });

      const rows = getTableAddressRowsLookups(isHorizontal, tableData);
      state = state.setIn(['table', 'addressLookup', 'rows'], fromJS(rows));
    }
    else {
      const tableHeaders = toJS(state.getIn(['table', 'headers']));
      tableHeaders.forEach((th, thIndex) => {
        if (th.key === action.key){
          th.isDisabled = !th.isDisabled;
          state = state.setIn(['table', 'headers', thIndex, 'isDisabled'], th.isDisabled);
        }
      });

      const tableWidth = getTotalVisibleColumnWidths(tableHeaders);
      state = state.setIn(['table', 'styles', 'width'], tableWidth);

      const columns = getTableAddressColumnsLookups(isHorizontal, tableHeaders);
      state = state.setIn(['table', 'addressLookup', 'columns'], fromJS(columns));
    }

    basket.forEach((ts, tsIndex) => {
      if (ts.key === action.key){
        ts.isDisabled = !ts.isDisabled;

        state = state.setIn(['workspace', 'timeseries', tsIndex, 'isDisabled'], ts.isDisabled);

        const baselineBasket = updateBaseline(
          toJS(state.getIn(['workspace', 'baselineBasket'])),
          basket,
          ts);

        state = state.setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket));

        chartSeries.forEach((cs, csIndex) => {
          if (cs.id === ts.key){
            cs.visible = !ts.isDisabled;
            state = state.setIn(['chart', 'series', csIndex, 'visible'], cs.visible);
          }
        });
      }
    });


    return state;
  },
  [ANALYSIS_UPDATE_SERIES2](state, action) {
    const timeseries = toJS(state.getIn(['workspace', 'timeseries']), []);
    const ts = timeseries.find(t => t.key === action.key);
    if (!ts)
      return state;
      
    Object.assign(ts, deepmerge.all([ts, ts.highChartSettings ??= {}, action.data]));
    ts.highChartSettings = deepmerge.all([ts.highChartSettings ?? {}, action.data]);
 
    if (action.requiresApiRefresh === true){
      state = state.setIn(['workspace', 'isDirty'], true)
                   .setIn(['workspace', 'timeseries'], fromJS(timeseries));
    }
    else{
      const chartSeries = toJS(state.getIn(['chart', 'series']), []);
      const cs = chartSeries.find(c => c.id === ts.key);
      if (cs)
        updateSeriesOptions(cs, ts);

      state = state
        .setIn(['workspace', 'timeseries'], fromJS(timeseries))
        .setIn(['chart', 'series'], fromJS(chartSeries));
    }

    state = rebuildDisplayNamesToState(state);

    const baselineBasket = updateBaseline(
      toJS(state.getIn(['workspace', 'baselineBasket'])),
      timeseries,
      ts);

    return state.setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket))
  },
  [ANALYSIS_SET_FILTER_VALUE](state, action) {
    const {key,value} = action;

    return state.setIn(['ui', 'fillerValues', ...key], fromJS(value));
  },
  [ANALYSIS_UPDATE_ALL_SERIES](state, action) {
    const {key, value, options = {
      requiresRefresh: false, 
      applyToHighcharts: true
    }} = action;

    const timeseriesCount = state.getIn(['workspace', 'timeseries'])?.size ?? 0;
    const baselineBasketCount = state.getIn(['workspace', 'baselineBasket'])?.size ?? 0;

    // update basket
    for (let index = 0; index < timeseriesCount; index++) {
      state = state.setIn(['workspace', 'timeseries', index, ...key], fromJS(value));
      if (options.applyToHighcharts) {
        state = state.setIn(['workspace', 'timeseries', index, 'highChartSettings', ...key], fromJS(value))
      }
    }

    // update baseline
    for (let index = 0; index < baselineBasketCount; index++) {
      state = state.setIn(['workspace', 'baselineBasket', index, ...key], fromJS(value));
      if (options.applyToHighcharts) {
        state = state.setIn(['workspace', 'baselineBasket', index, 'highChartSettings', ...key], fromJS(value))
      }
    }

    // update chart
    const timeseries = state.getIn(['workspace', 'timeseries'])?.toJS() ?? [];
    const chartseries = state.getIn(['chart', 'series'])?.toJS() ?? [];
    timeseries.forEach(ts => {
      const cs = chartseries.find(c => c.id === ts.key);
      if (cs)
        updateSeriesOptions(cs, ts);
    });

    state = state.setIn(['chart', 'series'], fromJS(chartseries))

    // set dirty flag as required
    if (options.requiresRefresh === true)
      state = state.setIn(['workspace', 'isDirty'], true);

    return state;
  },
  [ANALYSIS_UPDATE_SERIES_CUSTOM_NAME](state, action) {
    const timeseries = toJS(state.getIn(['workspace', 'timeseries']), []);
    const ts = timeseries.find(t => t.key === action.key);
    const newName = (action.value ?? '').trim();
    if (ts.customName === newName)
      return state;

    ts.customName = newName;
    ts.nameStyle = (ts.customName.length > 0) ? 'custom' : 'default';
    ts.name = getTimeSeriesName(ts);

    state = state.setIn(['workspace', 'timeseries'], fromJS(timeseries));
    state = rebuildDisplayNamesToState(state);

    const baselineBasket = updateBaseline(
      toJS(state.getIn(['workspace', 'baselineBasket'])),
      timeseries,
      ts);

    state = state.setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket));
    return state;
  },
  [ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_BEGIN](state, action) {
    if (action.stateKey)
      return state;

    return state
      .setIn(['workspace', 'isLoadingNameExpression'], true)
      .setIn(['workspace', 'templateNameExpression'], action.expression);
  },
  [ANALYSIS_REFRESH_TEMPLATE_NAME_EXPRESSIONS_COMPLETE](state, action) {
    if (action.stateKey)
     return state;

    const {timeseries = []} = action;
    state = state
      .deleteIn(['workspace', 'isLoadingNameExpression'], false)
      .setIn(['workspace', 'timeseries'], fromJS(timeseries));

    state = rebuildDisplayNamesToState(state);

    let baselineBasket = toJS(state.getIn(['workspace', 'baselineBasket']));
    timeseries.forEach(ts => {
      baselineBasket = updateBaseline(baselineBasket, timeseries, ts);
    });

    state = state.setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket));
    return state;
  },
  [ANALYSIS_TOGGLE_SERIES_NAME_STYLE](state, action) {
    const timeseries = toJS(state.getIn(['workspace', 'timeseries']), []);
    const ts = timeseries.find(t => t.key === action.key);
    switch (ts.nameStyle ?? 'default') {
      case 'custom':
        ts.nameStyle = ts.expressionName ? 'expression' : 'default';
        break;
      case 'expression':
        ts.nameStyle = 'default';
        break;
      case 'default':
      default:
        ts.nameStyle = ts.customName ? 'custom' : (ts.expressionName ? 'expression' : 'default');
        break;
    }

    state = state.setIn(['workspace', 'timeseries'], fromJS(timeseries));
    state = rebuildDisplayNamesToState(state);

    const baselineBasket = updateBaseline(
      toJS(state.getIn(['workspace', 'baselineBasket'])),
      timeseries,
      ts);

    state = state.setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket));
    return state;
  },
  [ANALYSIS_UPDATE_ALL_SERIES_NAME_STYLE](state, action) {
    const timeseries = toJS(state.getIn(['workspace', 'timeseries']), []);
    const nameStyle = action.nameStyle ?? 'default';
    timeseries.forEach(ts => {
      switch (nameStyle) {
        case 'custom':
          ts.nameStyle = ts.customName ? 'custom' : ts.nameStyle;
          break;
        case 'expression':
          ts.nameStyle = ts.expressionName ? 'expression' : ts.nameStyle;
          break;
        case 'default':
        default:
          ts.nameStyle = 'default';
          break;
      }
    });

    state = state.setIn(['workspace', 'timeseries'], fromJS(timeseries));
    state = rebuildDisplayNamesToState(state);
    state = state.setIn(['workspace', 'defaultNameStyle'], nameStyle);
    return state;
  },
  [ANALYSIS_SET_XAXIS_SERIES](state, action) {
    let basket = toJS(state.getIn(['workspace', 'timeseries']), []);
    const identityId = action.key;
    if (identityId) {
      let xAxisTimeSeries = basket.find(ts => ts.identityId === identityId);
      xAxisTimeSeries = mapToTimeSeries(xAxisTimeSeries);
      state = state.setIn(['workspace', 'xAxisTimeSeries'], fromJS(xAxisTimeSeries));
    } else {
      state = state.deleteIn(['workspace', 'xAxisTimeSeries']);
    }

    const comparisonMode = state.getIn(['workspace', 'comparisonMode']);
    basket = projectTopLevelSettingsToBasket({
      basket: basket,
      shapes: toJS(state.getIn(['workspace', 'shapesSettings', 'selection']), []),
      comparisons: (comparisonMode && comparisonMode !== 'none') ? toJS(state.getIn(['workspace', 'comparisonSettings', comparisonMode]), {}).windows : [],
      xAxisId: toJS(state.getIn(['workspace', 'xAxisTimeSeries', 'identityId'])),
    });

    state = state.setIn(['workspace', 'timeseries'], fromJS(basket));
    state = rebuildDisplayNamesToState(state);
    return state.setIn(['workspace', 'isDirty'], true);
  }, 
  [ANALYSIS_SELECTION_MOVE_TIME_SERIES](state, action) {
    const timeSeries = toJS(state.getIn(['workspace', 'timeseries']), []);
    const chartSeries = toJS(state.getIn(['chart', 'series']), []);
    const chartTimeSeries = toJS(state.getIn(['chart', 'timeSeries'], []));

    let timeSeriesIndex = timeSeries.findIndex(i => i.key === action.key);
    const chartSeriesIndex = chartSeries.findIndex(i => i.key === action.key);
    const chartTimeSeriesIndex = chartTimeSeries.findIndex(i => i.key === action.key);

    if (timeSeriesIndex >= 0) {
      const [item] = timeSeries.splice(timeSeriesIndex, 1);
      timeSeries.splice(action.direction === 'UP' ? (--timeSeriesIndex) : (++timeSeriesIndex), 0, item);
      timeSeries.forEach((i, ix) => {
        i.index = ix;
      });
    }

    if (chartSeriesIndex >= 0) {
      const [item] = chartSeries.splice(chartSeriesIndex, 1);
      chartSeries.splice(action.direction === 'UP' ? chartSeriesIndex - 1 : chartSeriesIndex + 1, 0, item);
    }

    if (chartTimeSeriesIndex >= 0) {
      const [item] = chartTimeSeries.splice(chartTimeSeriesIndex, 1);
      chartTimeSeries.splice(action.direction === 'UP' ? chartTimeSeriesIndex - 1 : chartTimeSeriesIndex + 1, 0, item);
    }

    return state.setIn(['workspace', 'timeseries'], fromJS(timeSeries))
                .setIn(['chart', 'series'], fromJS(chartSeries))
                .setIn(['chart', 'timeSeries'], fromJS(chartTimeSeries))
                .setIn(['workspace', 'isDirty'], true);
  },
  [ANALYSIS_EDIT_HIGHCHARTS_JSON](state,action){
    const {key} = action;
    const timeSeries = toJS(state.getIn(['workspace', 'timeseries']), []);
    const ts = timeSeries.find(t => t.key === key);

    const h1 = ts.highChartSettings ?? {};
    const h2 = getHighchartsPropertiesFromTimeSeries(ts);

    // merge any row edits over the highcharts json
    const highchartsJson = deepmerge.all([h1,h2]);

    state = state.setIn(['basketSettings','highchartsJson'], fromJS(highchartsJson));
    return state;
  },
  [ANALYSIS_UPDATE_HIGHCHARTS_JSON](state,action){
    const {data} = action;
    state = state.setIn(['basketSettings','highchartsJson'], fromJS(data));
    return state;
  },
  [ANALYSIS_CANCEL_HIGHCHARTS_JSON](state,action){
    const {key} = action;
    const timeSeries = toJS(state.getIn(['workspace', 'timeseries']), []);
    const ts = timeSeries.find(t => t.key === key);
    const chartSeries = toJS(state.getIn(['chart', 'series']), []);
    const cs = chartSeries.find(c => c.id === key);
    if (cs) // rebuild the chart series from the original basket item
      Object.assign(cs, mapSeriesOptions(ts, cs.data, cs.index));

    return state.setIn(['chart', 'series'], fromJS(chartSeries));
  },
  [ANALYSIS_PREVIEW_HIGHCHARTS_JSON](state,action){
    const {key} = action;
    const timeSeries = toJS(state.getIn(['workspace', 'timeseries']), []);
    const ts = timeSeries.find(t => t.key === key);
    const highchartsJson = state.getIn(['basketSettings','highchartsJson']).toJS();
    const tsProperties = getTimeSeriesPropertiesFromHighchartsJson(highchartsJson);
    Object.assign(ts, tsProperties); // not a deep merge as we want to take the values provided
    ts.highChartSettings = highchartsJson;

    const chartSeries = toJS(state.getIn(['chart', 'series']), []);
    const cs = chartSeries.find(c => c.id === key);
    if (cs) // rebuild the chart series using the new settings
      Object.assign(cs, mapSeriesOptions(ts, cs.data, cs.index));

    return state.setIn(['chart', 'series'], fromJS(chartSeries));
  },
  [ANALYSIS_APPLY_HIGHCHARTS_JSON](state,action){
    const {key} = action;
    const timeseries = toJS(state.getIn(['workspace', 'timeseries']), []);
    const ts = timeseries.find(t => t.key === key);
    const highchartsJson = state.getIn(['basketSettings','highchartsJson']).toJS();
    const tsProperties = getTimeSeriesPropertiesFromHighchartsJson(highchartsJson);
    Object.assign(ts, tsProperties); // not a deep merge as we want to take the values provided
    ts.highChartSettings = highchartsJson;

    const chartSeries = toJS(state.getIn(['chart', 'series']), []);
    const cs = chartSeries.find(c => c.id === key);
    if (cs) // rebuild the chart series using the new settings
      Object.assign(cs, mapSeriesOptions(ts, cs.data, cs.index));

    const baselineBasket = updateBaseline(
      toJS(state.getIn(['workspace', 'baselineBasket'])),
      timeseries,
      ts);
  
    return state.setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket))
                .setIn(['workspace', 'timeseries'], fromJS(timeseries))
                .setIn(['chart', 'series'], fromJS(chartSeries));
  }
};