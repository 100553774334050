
import React, { useState } from 'react';

export function TabContainer({ children, initialSelectedTabIndex = undefined, selectedTabIndex = undefined, onSelectedTabIndexChanging, onSelectedTabIndexChanged}) {
    const [selectedTab, setSelectedTab] = useState(
      initialSelectedTabIndex !== undefined ? initialSelectedTabIndex :
      selectedTabIndex !== undefined ? selectedTabIndex : 0);
      
    const _children = React.Children.toArray(children);
    const _headers = _children.map((child, index) => ({name:child.props.header, dataId:child.props['data_id'] ?? `${index}`}))
                              .filter(h => h.name);
  
    function onSetSelectedTab(index) {
      const canChange = onSelectedTabIndexChanging ? onSelectedTabIndexChanging({from:selectedTab, to:index}) : true;
      if (canChange) {
        if (onSelectedTabIndexChanged) {
          onSelectedTabIndexChanged(index);
        }

        setSelectedTab(index);
      }
    }

    const _selectedTabIndex = selectedTabIndex !== undefined ? selectedTabIndex : selectedTab;

    if (_selectedTabIndex >= _headers.length)
      return <></>
      
    return <div data_id='tab-container' className='d-flex flex-column'>
      <div className=''>
        <ul className='nav nav-tabs mb-2'>
          {_headers.map((h, hIndex) => <li key={`th-${hIndex}`} className='nav-item mr-1'>
            <button data_id={`${h.dataId}-button`} className={`nav-link ${_selectedTabIndex === hIndex ? 'active' : ''}`} style={{outline: 'none'}} type='button' onClick={() => onSetSelectedTab(hIndex)}>{h.name}</button>
          </li>)}
        </ul>
      </div>
  
      <div className='flex-fill' id={`${_headers[_selectedTabIndex].dataId}-container`}>
          {_children && _children[_selectedTabIndex]}
      </div>
    </div>
  }