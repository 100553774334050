import moment from 'moment';
import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';
import {
  ETL_JOB_APPLY_SORT,
  ETL_JOB_FILTER_CHANGED,
  ETL_JOB_REFRESH_STARTED,
  ETL_JOB_REFRESH_STOPPED,
  ETL_JOB_REFRESH_COMPLETE,
  ETL_JOB_TOGGLE_SHOW
} from '../actions/etl';

const countRows = (rows) => {
  let succeeded = 0;
  let running = 0;
  let finishedWithWarnings = 0;
  let finishedWithErrors = 0;
  let failed = 0;
  let notRun = 0;

  for (let i=0; i<rows.length; i++) {
    let row = rows[i];
    if(row.lastRunStatus === 'Succeeded') succeeded++;
    else if(row.lastRunStatus === 'Running') running++;
    else if(row.lastRunStatus === 'FinishedWithWarnings') finishedWithWarnings++;
    else if(row.lastRunStatus === 'FinishedWithErrors') finishedWithErrors++;
    else if(row.lastRunStatus === 'Running') running++;
    else if(row.lastRunStatus === 'Failed') failed++;
    else if(row.lastRunStatus === '') notRun++;
  }

  return {
    succeeded: succeeded,
    running: running,
    finishedWithWarnings: finishedWithWarnings,
    finishedWithErrors: finishedWithErrors,
    failed: failed,
    notRun: notRun
  };
}

const filterRows = (rows, filters, showSucceeded, showRunning, showFinishedWithWarnings, showFinishedWithErrors, showFailed, showNotRun) => {
  const filterText = filters.text.toLocaleLowerCase();
  const filterLastOriginator = filters.lastOriginator.toLocaleLowerCase();
  let filtered = []
  for (let i=0; i<rows.length; i++) {
    let row = rows[i];
    var isFilteredByText = !filterText || (filterText && row.jobName.toLocaleLowerCase().indexOf(filterText) !== -1);
    var isFilteredByFilterLastOriginator = !filterLastOriginator || (filterLastOriginator && (row.lastRunOriginator ?? '').toLocaleLowerCase().indexOf(filterLastOriginator) !== -1);

    if (isFilteredByText && isFilteredByFilterLastOriginator) {
      if (showSucceeded && row.lastRunStatus === 'Succeeded') filtered.push(row);
      if (showRunning && row.lastRunStatus === 'Running') filtered.push(row);
      if (showFinishedWithWarnings && row.lastRunStatus === 'FinishedWithWarnings') filtered.push(row);
      if (showFinishedWithErrors && row.lastRunStatus === 'FinishedWithErrors') filtered.push(row);
      if (showFailed && row.lastRunStatus === 'Failed') filtered.push(row);
      if (showNotRun && row.lastRunStatus === '') filtered.push(row);
    }
  }
  return filtered;
}

const mapRow = (x) => {
  let row = {
    jobId: x.job.id,
    jobName: x.job.name
  };

  if(x.lastRun) {
    row.lastRunId = x.lastRun.id;
    row.lastRunStatus = x.lastRun.status;
    row.lastRunOriginator = x.lastRun.originator;
    row.lastRunInformationCount = x.lastRun.informationCount;
    row.lastRunWarningCount = x.lastRun.warningCount;
    row.lastRunErrorCount = x.lastRun.errorCount;
    if(x.lastRun.startedAtUtc) {
      row.lastRunStartedAtUtc = moment.utc(x.lastRun.startedAtUtc);
    }
    if(x.lastRun.stoppedAtUtc) {
      row.lastRunStoppedAtUtc = moment.utc(x.lastRun.stoppedAtUtc);
    }
  }
  else {
    row.lastRunStatus = '';
  }

  if(x.nextRun) {
    row.nextRunUtc = x.nextRun.nextRunUtc = moment.utc(x.nextRun.nextRunUtc);
    row.isNextRunLate = x.nextRun.isNextRunLate;
  }

  if(x.lastSuccessAtUtc) {
    row.lastSuccessAtUtc = moment.utc(x.lastSuccessAtUtc);
  }

  return row;
}

const sortRows = (rows, orderBy, orderByDirection) => {
  switch(orderBy) {
    case 'jobName':
    case 'lastRunOriginator':
    case 'lastRunStatus':
      return rows.sort((a, b) => {
        const valueA = (a[orderBy] || '').toLocaleLowerCase();
        const valueB = (b[orderBy] || '').toLocaleLowerCase();

        if (valueA < valueB) return orderByDirection === 'desc' ? 1 : -1;
        if (valueA > valueB) return orderByDirection === 'desc' ? -1 : 1;
        return 0;
      });
    case 'lastRunStartedAtUtc':
    case 'lastRunStoppedAtUtc':
    case 'nextRunUtc':
      return rows.sort((a, b) => {
        const dateA = moment.utc(a[orderBy]).toDate();
        const dateB = moment.utc(b[orderBy]).toDate();
        return orderByDirection === 'desc' ? dateB - dateA : dateA - dateB;
      });
    default:
      return rows.sort((a, b) => {
        const valueA = a[orderBy];
        const valueB = b[orderBy];
        return orderByDirection === 'desc' ? valueB - valueA : valueA - valueB;
      });
    };
};

export const etl = createReducer(null, {
  [ETL_JOB_APPLY_SORT](state, action) {
    if (!action.key) return state;

    let orderBy = state.get('orderBy');
    let orderByDirection = state.get('orderByDirection');

    if (orderBy === action.key) {
      orderByDirection = orderByDirection === 'desc' ? 'asc' : 'desc';
    }
    else {
      orderBy = action.key;
      orderByDirection = 'desc';
    }

    if(orderBy && orderByDirection) {
      let rows = state.get('filteredRows').toJS();
      let sorted = sortRows(rows, orderBy, orderByDirection);
      return state.set('filteredRows', fromJS(sorted))
                  .set('orderBy', orderBy)
                  .set('orderByDirection', orderByDirection);
    }

    return state.set('orderBy', orderBy)
                .set('orderByDirection', orderByDirection);
  },
  [ETL_JOB_FILTER_CHANGED](state, action) {
    const {filterType, text} = action;

    let interim = state.setIn(['filter', filterType], text);
    let filtered = filterRows(interim.get('mappedRows').toJS(), interim.get('filter').toJS(), interim.get('showSuccess'), interim.get('showRunning'), interim.get('showFinishedWithWarnings'), interim.get('showFinishedWithErrors'), interim.get('showFail'), interim.get('showNotRun'))
    let orderBy = state.get('orderBy');
    let orderByDirection = state.get('orderByDirection');
    if(orderBy && orderByDirection) {
      filtered = sortRows(filtered, orderBy, orderByDirection);
    }
    return interim.set('filteredRows', fromJS(filtered));
  },
  [ETL_JOB_REFRESH_STARTED](state, action) {
    return state.set('isLoading', true)
                .set('filteredRows', fromJS([]))
                .set('mappedRows', fromJS([]));
  },
  [ETL_JOB_REFRESH_STOPPED](state) {
    return state.set('isLoading', false)
                .set('initialised', false);
  },
  [ETL_JOB_REFRESH_COMPLETE](state, action) {
    let mapped = action.data.map(x => mapRow(x));
    let counts = countRows(mapped);
    let filtered = filterRows(mapped, state.get('filter').toJS(), state.get('showSuccess'), state.get('showRunning'), state.get('showFinishedWithWarnings'), state.get('showFinishedWithErrors'), state.get('showFail'), state.get('showNotRun'))

    let orderBy = state.get('orderBy');
    let orderByDirection = state.get('orderByDirection');
    if(orderBy && orderByDirection) {
      filtered = sortRows(filtered, orderBy, orderByDirection);
    }

    return state.set('initialised', true)
                .set('isLoading', false)
                .set('lastRefreshed', moment())
                .set('mappedRows', fromJS(mapped))
                .set('filteredRows', fromJS(filtered))
                .set('succeededCount', counts.succeeded)
                .set('runningCount', counts.running)
                .set('finishedWithWarningsCount', counts.finishedWithWarnings)
                .set('finishedWithErrorsCount', counts.finishedWithErrors)
                .set('failedCount', counts.failed)
                .set('notRunCount', counts.notRun)
                .set('totalCount', mapped.length);
  },
  [ETL_JOB_TOGGLE_SHOW](state, action) {
    let interim = state.set(action.property, !state.get(action.property));
    if(action.property === 'showAll') {
      interim = interim.set('showSuccess',interim.get('showAll'))
                       .set('showRunning',interim.get('showAll'))
                       .set('showFinishedWithWarnings',interim.get('showAll'))
                       .set('showFinishedWithErrors',interim.get('showAll'))
                       .set('showFail',interim.get('showAll'))
                       .set('showNotRun',interim.get('showAll'));
    }

    let filtered = filterRows(interim.get('mappedRows').toJS(), state.get('filter').toJS(), interim.get('showSuccess'), interim.get('showRunning'), interim.get('showFinishedWithWarnings'), interim.get('showFinishedWithErrors'), interim.get('showFail'), interim.get('showNotRun'))
    let orderBy = state.get('orderBy');
    let orderByDirection = state.get('orderByDirection');
    if(orderBy && orderByDirection) {
      filtered = sortRows(filtered, orderBy, orderByDirection);
    }
    return interim.set('filteredRows', fromJS(filtered));
  }
});