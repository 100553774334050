import React from 'react';
import { getFullPath, getShortcutPath } from '../../../utility/workspace-utility';
import WorkspaceSelector from '../dashboard/WorkspaceSelector';
import { connect } from 'react-redux';
import { 
  dashboardTileEditAddNew
} from '../../../actions/dashboard-tiles';
import { 
  dashboardTilesWorkspacePickerSelectWorkspace, 
  dashboardTilesWorkspacePickerSetTitle 
} from '../../../actions/dashboard-tiles-workspace-picker';

export const WorkspacePickerConnected = connect(
  (state) => ({
    title: state.getIn(['dashboardTiles', 'workspacePicker', 'title']),
    selectedWorkspace: state.getIn(['dashboardTiles', 'workspacePicker', 'selectedWorkspace'])
  }),
  (dispatch) => ({
    setTitle(value){
      dispatch(dashboardTilesWorkspacePickerSetTitle(value))
    },
    selectWorkspace(value){
      dispatch(dashboardTilesWorkspacePickerSelectWorkspace(value))
    },
    addItem(dashboardTileType, properties){
      dispatch(dashboardTileEditAddNew(dashboardTileType, properties));
    }
  })
)(({ closeDialog, workspaceTypeToTileTypeMap, 
  title, setTitle, 
  selectedWorkspace, selectWorkspace,
  addItem
 }) => {  

  function onAddWorkspaceShortcutTile(){
    addItem(
      'Shortcut', 
      {
        title: title,
        hideToolbar: true,
        borderStyle: 'flat',
        workspacePath: getShortcutPath(selectedWorkspace)
      });
    closeDialog();
  }

  function onAddWorkspaceTile(){
    addItem(
      workspaceTypeToTileTypeMap[selectedWorkspace.type], 
      {
        title: title,
        isTitleVisible : true,
        useDashboardDates : false, 
        useDashboardLens : false, 
        useDashboardTimezone : false, 
        workspacePath: getFullPath({ workspace:selectedWorkspace, includeType: false })
      });
    closeDialog();
  }

  return <div className='modal-content' style={{ minWidth: '50em' }}>
    <div className='modal-header'>
      <h5 className='modal-title'>Add tile</h5>
    </div>
    <div className='modal-body'>
      <div className='form-group'>
        <label className='font-weight-bold'>Title</label>
        <input type='text' className='form-control form-control-sm' value={title} onChange={e => setTitle(e.target.value)} />
      </div>
      <div className='form-group'>
        <label className='font-weight-bold'>Workspace</label>
        <input type='text' readOnly className='form-control' value={selectedWorkspace ? `${getFullPath({ workspace: selectedWorkspace })}` : ''} />
        <WorkspaceSelector style={{ marginTop: '1px', border: 'solid 1px #DDDDDD' }} showOnlyItems={['Folder', ...Object.keys(workspaceTypeToTileTypeMap), 'Dashboard']} onChange={w => selectWorkspace(w)} />
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sml btn-secondary' onClick={() => closeDialog()}>Cancel</button>
      <button className='btn btn-sml btn-secondary' disabled={!selectedWorkspace || (selectedWorkspace && selectedWorkspace.type === 'WebLink')} onClick={() => onAddWorkspaceShortcutTile()}>Add as shortcut</button>
      <button className='btn btn-sml btn-primary' disabled={!selectedWorkspace || (selectedWorkspace && selectedWorkspace.type === 'Dashboard')} onClick={() => onAddWorkspaceTile()}>Add</button>
    </div>
  </div>
});