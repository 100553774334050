import React, { useState } from 'react';
import { toJS } from '../../../utility/immutable-utility';
import ConfirmButton from '../../shared/ConfirmButton';
import { DynamicWorkspaceToolbarConnected } from './dynamic-workspace/DynamicWorkspaceToolbarConnected';
import { ForecastsBuilderComponent } from './ForecastsBuilderComponent';
import { ComparisonBuilderComponent } from './ComparisonBuilderComponent';
import { IdentityQuickInput } from './IdentityQuickInput';
import { TimeSeriesEditComponentConnected } from './TimeSeriesEditComponentConnected';
import Dialog from '@mui/material/Dialog';
import TimeSeriesNameExpression from './fillers/TimeSeriesNameExpression';
import { ChartBasketRowNext } from './ChartBasketRowNext';
import { PopoverButton } from '../../shared/PopoverButton';
import { ChartTypeFiller } from './fillers/ChartTypeFiller';
import { DashStyleFiller } from './fillers/DashStyleFiller';
import { StackingFiller } from './fillers/StackingFiller';
import { ConnectNullsFiller } from './fillers/ConnectNullsFiller';
import { MarkersFiller } from './fillers/MarkersFiller';
import { LineWidthFiller } from './fillers/LineWidthFiller';
import { YAxisFiller } from './fillers/YAxisFiller';
import { ConversionUnitFiller } from './fillers/ConversionUnitFiller';
import { ConversionFactorFiller } from './fillers/ConversionFactorFiller';
import { LensFiller } from './fillers/LensFiller';
import { OperationFiller } from './fillers/OperationFiller';
import { PlotOriginFiller } from './fillers/PlotOriginFiller';
import { WindowOverrideFiller } from './fillers/WindowOverrideFiller';
import { ScenarioFiller } from './fillers/ScenarioFiller';
import { TimeSeriesNameExpressionFiller } from './fillers/TimeSeriesNameExpressionFiller';
import { ShapesFiller } from './fillers/ShapesFiller';
import { AsAtWindowFiller } from './fillers/AsAtWindowFiller';

export default function ChartBasketNext({
  basketView,
  reportCriteria,
  periods,
  templateNameExpression,
  timeseries,
  chartSettings,
  timeSeriesNameSettings,
  conversionUnits,
  userTimeSeriesSettings,
  lenses,
  operations,
  seriesTypes,
  seriesDashStyles,
  seriesStacking,
  addSelectionByCsv,
  removeFromBasket,
  removeAllFromBasket,
  toggleIsPinned,
  updateTemplateNameExpression,
  updateSeriesProperty,
  updateChartProperty,
  updateSeriesWindowPeriod,
  updateSeriesCustomName,
  toggleNameStyle,
  setFillerValue,
  fillerValues,
  updateAllSeries,
  updateAllSeriesNameStyle,
  toggleVisibility,
  toggleAllVisibility,
  disassociateFromDynamicFilter,
  addOnDemandDerivedSelection,
  cloneTimeSeries,
  moveTimeSeries,
  beginEditTimeSeries,
  saveEditTimeSeries,
  cancelEditTimeSeries,
  updateEditTimeSeriesValue,
  initialiseForecast,
  initialiseComparisons,
  initialiseShapes,
  setBasketView,
  editHighchartsJson,
  highchartsJson,
  updateHighchartsJson,
  cancelHighchartsJson,
  previewHighchartsJson,
  applyHighchartsJson
}) {
  const [focusPath, setFocusPath] = useState();

  function onAddOnDemandDerived() {
    addOnDemandDerivedSelection(toJS(userTimeSeriesSettings, {}));
  }

  function onOpenEditDialog({ key, shape }) {
    beginEditTimeSeries(key);
    initialiseForecast(key, toJS(operations));
    initialiseShapes(shape ?? {});
    setEditDialogIsOpen(true);
  }

  function onSaveEdit() {
    saveEditTimeSeries();
    setEditDialogIsOpen(false);
  }

  function onCloseEditDialog() {
    cancelEditTimeSeries();
    setEditDialogIsOpen(false);
  }

  function onOpenForecastsProjections(key) {
    initialiseForecast(key, toJS(operations));
    setForecastDialogIsOpen(true);
  }

  function onCloseForecastProjections() {
    setForecastDialogIsOpen(false);
  }

  function onOpenComparisonProjections(key) {
    initialiseComparisons(key);
    setComparisonDialogIsOpen(true);
  }

  function onCloseComparisonProjections() {
    setComparisonDialogIsOpen(false);
  }

  function getBasketViewStyle(actual, expected) {
    return actual === expected ? 'btn btn-sm btn-secondary' : 'btn btn-sm btn-light';
  }

  function getColumns(basketView) {
    switch (basketView) {
      case 'request':
        return [
          {},
          {},
          {
            title: 'Name',
            centerComponent: <TimeSeriesNameExpression templateNameExpression={templateNameExpression} updateTemplateNameExpression={updateTemplateNameExpression} fillerValues={fillerValues} setFillerValue={setFillerValue} />,
            rightComponent: <TimeSeriesNameExpressionFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeriesNameStyle={updateAllSeriesNameStyle} />
          },
          { title: 'Evo' },
          { 
            title: 'Window',
            rightComponent: <WindowOverrideFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeries={updateAllSeries} />
          },
          { 
            title: 'Plot Origin',
            rightComponent: <PlotOriginFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeries={updateAllSeries} />
          },          
          { 
            title: 'As At Window',
            rightComponent: <AsAtWindowFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeries={updateAllSeries} />
           },
          { 
            title: 'Lens',
            rightComponent: <LensFiller updateAllSeries={updateAllSeries} lenses={lenses} />
          },
          { 
            title: 'Operation',            
            rightComponent: <OperationFiller updateAllSeries={updateAllSeries} operations={operations} />
          },
          { 
            title: 'Unit',
            rightComponent: <ConversionUnitFiller updateAllSeries={updateAllSeries} conversionUnits={conversionUnits} /> 
          },
          { 
            title: 'Factor',
            rightComponent: <ConversionFactorFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeries={updateAllSeries} /> 
          },
          { title: 'Forecast' },
          { 
            title: 'Scenario',
            rightComponent: <ScenarioFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeries={updateAllSeries} /> 
          },
          {
            title: 'Shape',
            rightComponent: <ShapesFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeries={updateAllSeries} />             
           },
          {}
        ];
      case 'chart':
        return [
          {},
          {},
          {
            title: 'Name',
            centerComponent: <TimeSeriesNameExpression templateNameExpression={templateNameExpression} updateTemplateNameExpression={updateTemplateNameExpression} fillerValues={fillerValues} setFillerValue={setFillerValue} />,
            rightComponent: <TimeSeriesNameExpressionFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeriesNameStyle={updateAllSeriesNameStyle} />
          },
          { title: 'Colour' },
          { 
            title: 'Y-Axis',
            rightComponent: <YAxisFiller updateAllSeries={updateAllSeries}  yAxes={chartSettings.yAxis}/>
           },
          {
            title: 'Type',
            rightComponent: <ChartTypeFiller updateAllSeries={updateAllSeries} seriesTypes={seriesTypes}/>
          },
          {
            title: 'Dash Style',
            rightComponent: <DashStyleFiller updateAllSeries={updateAllSeries} seriesDashStyles={seriesDashStyles}/>
          },
          {
            title: 'Stacking',
            rightComponent: <StackingFiller updateAllSeries={updateAllSeries} seriesStacking={seriesStacking}/>
          },
          {
            title: 'Line Width',
            rightComponent: <LineWidthFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeries={updateAllSeries} />
          },
          {
            title: 'Markers',
            rightComponent: <MarkersFiller updateAllSeries={updateAllSeries} />
          },
          {
            title: 'Connect Nulls',
            rightComponent: <ConnectNullsFiller updateAllSeries={updateAllSeries} />
          },
          {},
          {}
        ];
      default:
        return [
          {},
          {},
          {
            title: 'Name',            
            centerComponent: <TimeSeriesNameExpression templateNameExpression={templateNameExpression} updateTemplateNameExpression={updateTemplateNameExpression} fillerValues={fillerValues} setFillerValue={setFillerValue} />,
            rightComponent: <TimeSeriesNameExpressionFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeriesNameStyle={updateAllSeriesNameStyle} />
          },
          { title: 'Style' },
          { title: 'Granularity' },
          { title: 'Last Updated' },
          { 
            title: 'Lens',            
            rightComponent: <LensFiller updateAllSeries={updateAllSeries} lenses={lenses} /> 
           },
          { 
            title: 'Unit',
            rightComponent: <ConversionUnitFiller updateAllSeries={updateAllSeries} conversionUnits={conversionUnits} /> 
          },
          { 
            title: 'Factor',
            rightComponent: <ConversionFactorFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeries={updateAllSeries} /> 
          },
            { title: 'Colour' },
          { 
            title: 'Y-Axis',
            rightComponent: <YAxisFiller updateAllSeries={updateAllSeries} yAxes={chartSettings.yAxis} /> 
          },
          {
            title: 'Type',
            rightComponent: <ChartTypeFiller updateAllSeries={updateAllSeries} seriesTypes={seriesTypes} />
          },
          {
            title: 'Dash Style',
            rightComponent: <DashStyleFiller updateAllSeries={updateAllSeries} seriesDashStyles={seriesDashStyles}/>
          },
          {
            title: 'Stacking',
            rightComponent: <StackingFiller updateAllSeries={updateAllSeries} seriesStacking={seriesStacking}/>
          },
          { 
            title: 'Line Width',
            rightComponent: <LineWidthFiller fillerValues={fillerValues} setFillerValue={setFillerValue} updateAllSeries={updateAllSeries} />
           },
          {}
        ];
    }
  }

  const isAllDisabled = timeseries.length && timeseries.every(i => i.isDisabled);
  const [isEditDialogOpen, setEditDialogIsOpen] = useState(false);
  const [isForecastDialogOpen, setForecastDialogIsOpen] = useState(false);
  const [isComparisonDialogIsOpen, setComparisonDialogIsOpen] = useState(false);

  const columns = getColumns(basketView);

  return <>
    <div className='w-100 d-flex justify-content-between p-1'>
      <div className='d-flex'>
        <div className='mr-2'>
          <button className='btn btn-sm btn-outline-primary' type='button' onClick={toggleIsPinned}>
            <i className='fas fa-thumbtack fa-fw'></i>
          </button>
        </div>

        <div className='mr-2'>
          <IdentityQuickInput addSelectionByCsv={addSelectionByCsv} />
        </div>

        <div className='mr-2'>
          <button className='btn btn-sm btn-secondary' type='button' onClick={onAddOnDemandDerived} >
            <i className='fas fa-sitemap fa-fw mr-1' />Add Derived
          </button>
        </div>

        <DynamicWorkspaceToolbarConnected />
      </div>

      <div className='d-flex'>
        <div className='btn-group btn-group-toggle' style={{ marginRight: '15px' }}>
          <label className={getBasketViewStyle(basketView, 'request')}>
            <input type='radio' id='basket-view-request' value='request' checked={basketView === 'request'} onChange={e => setBasketView(e.target.value)} /> API
          </label>
          <label className={getBasketViewStyle(basketView, 'chart')}>
            <input type="radio" id='basket-view-chart' value="chart" checked={basketView === 'chart'} onChange={e => setBasketView(e.target.value)} /> Chart
          </label>
          <label className={getBasketViewStyle(basketView, 'classic')}>
            <input type="radio" id='basket-view-classic' value="classic" checked={basketView === 'classic'} onChange={e => setBasketView(e.target.value)} /> Classic
          </label>
        </div>

        <button type='button' className='btn btn-sm btn-link fota-btn-toolbar' style={{ marginRight: '15px' }} onClick={() => toggleAllVisibility(!isAllDisabled)}>
          <i className={`fas ${isAllDisabled ? 'fa-eye-slash' : 'fa-eye'} fa-fw`} style={{ color: 'rgba(0, 0, 0, .5)' }} />
        </button>

        <ConfirmButton type='button' className='btn btn-sm btn-link fota-btn-toolbar' confirmClassName='btn btn-sm btn-link btn-warning fota-btn-toolbar fota-color-black' onClick={removeAllFromBasket}
          content={<i style={{ color: 'rgba(0, 0, 0, .5)' }} className='fas fa-trash fa-fw' />}
          confirm={<><i style={{ color: 'rgba(0, 0, 0, .5)' }} className='fas fa-trash fa-fw' /><span>?</span></>} />
      </div>
    </div>

    <div className='sticky-table w-100' style={{ height: 'calc(100% - 36px)' }}>
      <table className={`table table-sm table-striped`}>
        <thead>
          <tr className={'bg-light sticky-header basket-view-table-header'}>
            {columns.map((c, cx) => (
              <th style={{ userSelect: 'none' }} className={'text-nowrap ' + c.className ?? ''} key={`col-${cx}`}>
                <div className='d-flex justify-content-between align-items-end' >
                  {c.title}
                  {c.centerComponent ? <>{c.centerComponent}</> : <></>}
                  {c.rightComponent ? <PopoverButton data_id={`filler-button-${c.title.toLocaleLowerCase()}`} buttonClassName='btn btn-xs xbtn-light' buttonContent={<i className='fas fa-level-down-alt fa-fw' />} buttonStyles={{ color: 'rgba(0, 0, 0, .2)' }}>{() => <div data_id={`filler-${c.title.toLocaleLowerCase()}`}>{c.rightComponent}</div>}</PopoverButton> : <></>}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {timeseries.map(t => {
            return <ChartBasketRowNext
              key={t.key}
              reportCriteria={reportCriteria}
              periods={periods}
              setFocusPath={setFocusPath}
              series={t}
              basketView={basketView}
              lenses={lenses}
              operations={operations}
              conversionUnits={conversionUnits}
              yAxes={chartSettings.yAxis}
              updateSeriesProperty={updateSeriesProperty}
              updateChartProperty={updateChartProperty}
              updateSeriesWindowPeriod={updateSeriesWindowPeriod}
              toggleVisibility={toggleVisibility}
              removeFromBasket={removeFromBasket}
              updateSeriesCustomName={updateSeriesCustomName}
              toggleNameStyle={toggleNameStyle}
              disassociateFromDynamicFilter={disassociateFromDynamicFilter}
              timeSeriesNameSettings={timeSeriesNameSettings}
              basketTimeseries={timeseries}
              moveTimeSeries={moveTimeSeries}
              cloneTimeSeries={cloneTimeSeries}
              updateEditTimeSeriesValue={updateEditTimeSeriesValue}
              openEditDialog={onOpenEditDialog}
              openForecastsProjections={onOpenForecastsProjections}
              openComparisonProjections={onOpenComparisonProjections}
              seriesTypes={seriesTypes}
              seriesDashStyles={seriesDashStyles}
              seriesStacking={seriesStacking} 
              editHighchartsJson={editHighchartsJson}
              highchartsJson={highchartsJson}
              updateHighchartsJson={updateHighchartsJson}
              cancelHighchartsJson={cancelHighchartsJson}
              previewHighchartsJson={previewHighchartsJson}
              applyHighchartsJson={applyHighchartsJson}
              />
          })}
        </tbody>
      </table>
    </div>

    <Dialog open={isEditDialogOpen} onClose={onCloseEditDialog} fullWidth={true} maxWidth={'md'} >
      <TimeSeriesEditComponentConnected focusPath={focusPath} onSave={onSaveEdit} onCancel={onCloseEditDialog} updateValue={updateEditTimeSeriesValue} />
    </Dialog>

    <Dialog open={isForecastDialogOpen} onClose={onCloseForecastProjections} fullWidth={true} maxWidth={'md'} >
      <ForecastsBuilderComponent onComplete={onCloseForecastProjections} />
    </Dialog>

    <Dialog open={isComparisonDialogIsOpen} onClose={onCloseComparisonProjections} fullWidth={true} maxWidth={'md'} >
      <ComparisonBuilderComponent onComplete={onCloseComparisonProjections} />
    </Dialog>
  </>
};