import { checkPropTypes } from 'prop-types';
import PropTypes from 'prop-types';

export const immutableType = PropTypes.shape({
  toJS: PropTypes.func.isRequired
});

PropTypes.immutable = immutableType;

export const timeSeriesType = PropTypes.shape({
  id: PropTypes.any,
  identityId: PropTypes.any,
  key: PropTypes.string,
  sourceTimeSeriesName: PropTypes.string,

  timeSeriesName: PropTypes.string,  
  style: PropTypes.string,
  dataType: PropTypes.string,
  source: PropTypes.string,
  derivationType: PropTypes.string,
  derivationData: PropTypes.object,
  granularity: PropTypes.string,
  sourceTimeZoneId: PropTypes.string,
  queryCount: PropTypes.number,
  unit: PropTypes.string,
  firstDataPointUtc: PropTypes.any,
  lastDataPointUtc: PropTypes.any,
  lastUpdatedUtc: PropTypes.any,
  timestampUtc: PropTypes.any,
  infoType: PropTypes.string,
  infoMessage: PropTypes.string,

  // display name
  name: PropTypes.string, // 'name' may change depending on the nameStyle - 'timeSeriesName' never changes
  nameStyle: PropTypes.string,
  customName: PropTypes.string,
  expressionName: PropTypes.string,
  schemaName: PropTypes.string,
  scenarioOverrides: PropTypes.array,
  complexScenario: PropTypes.any,

  windowType: PropTypes.string,
  window: PropTypes.object,
  // asAt: timeSeries.asAt ?? '',
  // asAtType: timeSeries.asAtType,
  seriesStyle: PropTypes.string,

  // visual styling
  type: PropTypes.string,
  dashStyle: PropTypes.string,
  stacking: PropTypes.string,
  lineWidth: PropTypes.any,
  color: PropTypes.string,
  yAxis: PropTypes.any, // this should be a number but can be 1 or "1"
  isDisabled: PropTypes.bool,
  asAt:PropTypes.any,
  asAtType:PropTypes.string
});

PropTypes.timeSeries = timeSeriesType;

export const workspaceType = PropTypes.shape({
  timeseries: PropTypes.arrayOf(PropTypes.timeSeries),
  templateNameExpression: PropTypes.string,
  comparisonMode: PropTypes.string,
  asAtUtc: PropTypes.string,
  operation: PropTypes.string,
  timeZoneId: PropTypes.string,
  window :PropTypes.shape({
    fromUtc: PropTypes.string,
    relFrom: PropTypes.string, 
    toUtc: PropTypes.string,
    relTo: PropTypes.string
  }),
  lens: PropTypes.string,
  conversionUnit: PropTypes.string, 
  conversionFactor: PropTypes.string, 
  conversionUnitStrictErrors: PropTypes.string, 
  force24HourDST: PropTypes.string,
  alignTimeZones: PropTypes.string, 
  materialisationMode: PropTypes.string, 
  variantMode: PropTypes.string, 
  seriesStyle: PropTypes.string, 
  isEvolution: PropTypes.string, 
  dataWindowRelativeToAsAt: PropTypes.string
});

PropTypes.workspace = workspaceType;

export const absRelDateType = PropTypes.shape({
  mode: PropTypes.string.isRequired,
  abs: PropTypes.any,
  rel: PropTypes.string
});

PropTypes.absRelDate = absRelDateType;

export function assertType(props, args) {
  if (process.env.NODE_ENV !== 'production') {
    const err = [];
    const console_error = console.error;
    console.error = (x) => { err.push(x); }

    try {
      checkPropTypes(props, args);
    } finally {
      console.error = console_error;
    }

    if (err.length > 0) {
      const message = err.join('\n');
      if (process.env.NODE_ENV !== 'test')
        console.error(message);

      throw new Error(message);
    }
  }
}

export function isType(expectedShape, instance) {
  const err = [];
  const console_error = console.error;
  console.error = (x) => { err.push(x); }

  try {
    checkPropTypes({ instance: expectedShape }, { instance });
  } finally {
    console.error = console_error;
  }

  return err.length === 0;
}