import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import { createReducer } from '../utility/redux-utility';
import { LOCATION_CHANGE } from "redux-first-history";
import {
  ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_SCHEMA_LOOKUP_COMPLETE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_QUERY_TEXT,
  ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_CUSTOM_FILTER_TEXT,
  ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SELECTION_MODE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_COLUMN_FILTER,
  ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_PAGE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_ORDER_BY,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_EXPANSION,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_SELECTION,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_FACET_VALUE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_BEGIN,
  ANALYSIS_CROSS_SCHEMA_SEARCH_COMPLETE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_CLEAR_SCHEMA_FACET_FILTERS,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXCLUDE_DISCONTINUED_FILTER,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND,
  ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_BEGIN,
  ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_COMPLETE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_SELECT_CATEGORIES_SCHEMA,
  ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_BEGIN,
  ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_COMPLETE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_BEGIN,
  ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_COMPLETE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_FACETS,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_DETAILS,
  ANALYSIS_CROSS_SCHEMA_SEARCH_UPDATE_CUSTOM_FILTER_HEIGHT,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITE_COMPLETE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES_COMPLETE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_ITEM_DETAILS,
  ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SCHEMA_SELECTOR_SIZE,
  ANALYSIS_CROSS_SCHEMA_SEARCH_CREATE_SEARCH
} from '../actions/analysis-cross-schema-search';
import {
  filterSchemasForResultsRow,
  sortLegacyFacets,
  synchroniseToResultFacets,
  toggleSelection,
  clearSelection,
  mapUserSettings,
  applyUserSchemasOrder
} from '../reducer-functions/analysisCrossSchemaSearch';

import { toggleAll } from '../utility/selection-utility';
import { USER_SETTINGS_EXPERIMENTAL_CHANGED, USER_SETTINGS_LOAD_COMPLETE } from '../actions/userSettings';
import { assignCriteria, decodeUrlArgsToCriteria } from '../utility/analysis-search-utility';

const searchReducer = {
  [USER_SETTINGS_LOAD_COMPLETE](state, action) {
    if (!action.data)
      return state;

    const { analysis : {search: userSettings} = {}} = action.data ?? {};
    state = state.setIn(['initialise', 'userSettings'], fromJS(userSettings));
    return state;
  },
  [USER_SETTINGS_EXPERIMENTAL_CHANGED](state, action) {
    if (!action.data)
      return state;

    return state.setIn(['experimental'], toJS(action.data));
  },
  [LOCATION_CHANGE](state, action) {
    const { location, action: payloadAction } = action.payload;
    if (location.pathname.indexOf('/analysis') < 0)
      return state;

    let { search = '' } = location;
    const urlArgs = new URLSearchParams(search);
    if ((payloadAction === 'POP' || payloadAction === 'PUSH') && !urlArgs.has("workspacePath"))
    {
      state = state.setIn(['initialise', 'searchArgs'], search);
    }

    return state;
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_SCHEMA_LOOKUP_COMPLETE](state, action) {
    const { stateContext = 'default' } = action;
    const schemas = action.data;
    const schemaNames = action.data.map(x => x.name);
    const userSettings = state.getIn(['initialise', 'userSettings'])?.toJS() ?? {};
    const searchArgs = state.getIn(['initialise', 'searchArgs']);
    const {ui:{enableExpandFacets, enableExpandDetails}, criteria} = mapUserSettings(toJS(state.getIn([stateContext, 'criteria'])), userSettings);
    criteria.schemas = schemas.map(schema => (
    {
      schemaName: schema.name,
      colour: schema.colour ?? '#333',
      isSelected: false,
      isExpanded: undefined, // if the user toggles the expansion then isExpanded is set to true/false. Otherwise the app selects its expansion if it is the first selected schema
      resultsCount: 0,
      facets: []
    }));

    if (searchArgs && searchArgs.trim().length > 0) {
      const criteriaOverrides = decodeUrlArgsToCriteria(searchArgs);
      assignCriteria(criteria, criteriaOverrides);
    }

    criteria.schemas = sortLegacyFacets(criteria.schemas, criteria.legacyFacets);
    criteria.schemas = applyUserSchemasOrder(criteria.schemas, userSettings.schemas ? userSettings.schemas.map(s => ({schemaName:s})): undefined);
    if (criteria.schemas.length > 0 && !criteria.schemas.some(s => s.isSelected)) {
      criteria.schemas[0].isSelected = true;
      criteria.schemas[0].isExpanded = true;
    }
    
    return state
      .setIn(['initialise', 'schemas'], fromJS(schemas))
      .setIn(['initialise', 'schemaNames'], fromJS(schemaNames))
      .setIn([stateContext, 'refreshRequired'], true)
      .setIn([stateContext, 'criteria'], fromJS(criteria))
      .setIn([stateContext, 'enableExpandFacets'], enableExpandFacets)
      .setIn([stateContext, 'enableExpandDetails'], enableExpandDetails);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_COLUMN_FILTER](state, action) {
    const { stateContext, name, value } = action;
    const filters = (toJS(state.getIn([stateContext, 'criteria', 'columnFilters'])) ?? []).filter(f => f.name !== name);
    if (value) {
      filters.push({ name, value });
    }

    return state.setIn([stateContext, 'criteria', 'columnFilters'], fromJS(filters));
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_ORDER_BY](state, action) {
    const { stateContext, name } = action;
    let orderBy = state.getIn([stateContext, 'criteria', 'orderBy']);
    let orderByDirection = state.getIn([stateContext, 'criteria', 'orderByDirection']);

    if (orderBy === name) {
      orderByDirection = orderByDirection === 'desc' ? 'asc' : 'desc';
    } else {
      orderBy = name;
      orderByDirection = 'asc';
    }

    return state
      .setIn([stateContext, 'criteria', 'orderBy'], orderBy)
      .setIn([stateContext, 'criteria', 'isDirty'], true)
      .setIn([stateContext, 'criteria', 'orderByDirection'], orderByDirection);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SET_PAGE](state, action) {
    const { stateContext, page, pageSize } = action;
    if (page !== undefined)
      state = state.setIn([stateContext, 'criteria', 'page'], page);

    if (pageSize !== undefined)
      state = state.setIn([stateContext, 'criteria', 'pageSize'], pageSize);

    return state
      .setIn([stateContext, 'criteria', 'isDirty'], true);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_QUERY_TEXT](state, action) {
    const { stateContext, text } = action;
    return state
      .setIn([stateContext, 'criteria', 'query'], text)
      .setIn([stateContext, 'criteria', 'isDirty'], true);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_CUSTOM_FILTER_TEXT](state, action) {
    const { stateContext, text } = action;
    return state
      .setIn([stateContext, 'criteria', 'customFilter'], text)
      .setIn([stateContext, 'criteria', 'isDirty'], true)
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SEARCH_SELECTION_MODE](state, action) {
    const { stateContext, isMultiSelect = false } = action;
    const schemas = toJS(state.getIn([stateContext, 'criteria', 'schemas'])) ?? [];

    if (!isMultiSelect) {
      schemas.forEach(selectedSchema => {
        selectedSchema.facets.forEach(facet => {
          facet.results.forEach(value => {
            value.isSelected = false;
          })
        });
      });
    }

    return state.setIn([stateContext, 'criteria', 'isMultiSelect'], isMultiSelect)
      .setIn([stateContext, 'criteria', 'isDirty'], true)
      .setIn([stateContext, 'criteria', 'schemas'], fromJS(schemas));
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA](state, action) {
    const { stateContext, schemaName, value } = action;

    const schemas = toJS(state.getIn([stateContext, 'criteria', 'schemas'])) ?? [];
    const selectedSchema = schemas.find(s => s.schemaName === schemaName);
    if (selectedSchema.isSelected && schemas.filter(s => s.isSelected).length === 1) //  must have 1 schema selected
      return state;

    selectedSchema.isSelected = (value === false || value === true) ? value : !selectedSchema.isSelected;

    return state
      .setIn([stateContext, 'criteria', 'schemas'], fromJS(schemas))
      .setIn([stateContext, 'criteria', 'isDirty'], true)
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND](state, action) {
    const { stateContext } = action;
    const data = state.getIn([stateContext, 'results', 'data']);
    const index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    const isExpanded = data.getIn([index, 'isExpanded']) === true;

    return state.setIn([stateContext, 'results', 'data', index, 'isExpanded'], !isExpanded);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_BEGIN](state, action) {
    const { stateContext, index } = action;
    return state.setIn([stateContext, 'results', 'data', index, 'schemaCategories', 'isLoading'], true);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_CATEGORIES_COMPLETE](state, action) {
    const { stateContext, index, data } = action;
    if (!action.data) return state.setIn([stateContext, 'results', 'data', index, 'schemaCategories', 'isLoading'], false);

    // convert to from an object to and array of name/value pairs as order is important
    // and can be lost in immutable.js
    data.forEach(d => {
      d.values = Object.keys(d.values).map(k => ({ name: k, value: d.values[k] }));
    });

    return state
      .setIn([stateContext, 'results', 'data', index, 'schemaCategories', 'data'], fromJS(data))
      .setIn([stateContext, 'results', 'data', index, 'schemaCategories', 'isLoading'], false);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_BEGIN](state, action) {
    const { stateContext, index } = action;
    return state.setIn([stateContext, 'results', 'data', index, 'info', 'isLoading'], true);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_IDENTITY_INFO_COMPLETE](state, action) {
    const { stateContext, index, data } = action;
    if (!action.data) return state.setIn([stateContext, 'results', 'data', index, 'info', 'isLoading'], false);

    return state
      .setIn([stateContext, 'results', 'data', index, 'info', 'data'], fromJS(data))
      .setIn([stateContext, 'results', 'data', index, 'info', 'isLoading'], false);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_BEGIN](state, action) {
    const { stateContext, index } = action;
    return state.setIn([stateContext, 'results', 'data', index, 'statistics', 'isLoading'], true);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_LOAD_STATISTICS_COMPLETE](state, action) {
    const { stateContext, index, data } = action;
    if (!action.data) return state.setIn(['results', 'data', index, 'statistics', 'isLoading'], false);

    return state
      .setIn([stateContext, 'results', 'data', index, 'statistics', 'data'], fromJS(data))
      .setIn([stateContext, 'results', 'data', index, 'statistics', 'isLoading'], false);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_SELECT_CATEGORIES_SCHEMA](state, action) {
    let { stateContext, index, name } = action;
    const selectedSchemas = toJS(state.getIn([stateContext, 'results', 'data', index, 'schemas']));

    const data = toJS(state.getIn([stateContext, 'results', 'data', index, 'schemaCategories', 'data']));
    let schemaValues = undefined;
    if (!data.find(s => s.name === name)) {
      name = selectedSchemas[0];
    }

    data.forEach(s => {
      if (s.name === name) {
        s.isSelected = true;
        schemaValues = s.values;
      }
      else {
        s.isSelected = false;
      }
    });

    return state
      .setIn([stateContext, 'results', 'data', index, 'schemaCategories', 'data'], fromJS(data))
      .setIn([stateContext, 'results', 'data', index, 'schemaCategories', 'selectedSchemaValues'], fromJS(schemaValues));
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_EXPANSION](state, action) {
    const { stateContext, schemaName } = action;
    const schemas = toJS(state.getIn([stateContext, 'criteria', 'schemas'])) ?? [];
    const schemaFacets = toJS(state.getIn([stateContext, 'results', 'schemaFacets']));
    const selectedSchema = schemas.find(s => s.schemaName === schemaName);
    selectedSchema.isExpanded = !(selectedSchema.isExpanded === true);
    const resultSchemaFacet = schemaFacets.find(s => s.schemaName === schemaName);
    resultSchemaFacet.isExpanded = selectedSchema.isExpanded;
    return state
      .setIn([stateContext, 'results', 'schemaFacets'], fromJS(schemaFacets))
      .setIn([stateContext, 'criteria', 'schemas'], fromJS(schemas));
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_SELECTION](state, action) {
    const { stateContext, schemaName, facetKey } = action;
    const schemas = toJS(state.getIn([stateContext, 'criteria', 'schemas'])) ?? [];
    const resultSchemaFacets = toJS(state.getIn([stateContext, 'results', 'schemaFacets']));
    const resultSchemaFacet = resultSchemaFacets.find(s => s.schemaName === schemaName).facets.find(f => f.key === facetKey);
    toggleAll(resultSchemaFacet.results, r => r.isSelected, (r,v) => {
      toggleSelection(true, schemas, schemaName, facetKey, r.value, v);
    });

    const filtersCount = synchroniseToResultFacets(schemas, resultSchemaFacets);

    return state
      .setIn([stateContext, 'results', 'schemaFacets'], fromJS(resultSchemaFacets))
      .setIn([stateContext, 'criteria', 'filtersCount'], filtersCount)
      .setIn([stateContext, 'criteria', 'schemas'], fromJS(schemas))
      .setIn([stateContext, 'criteria', 'isDirty'], true)
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_SCHEMA_FACET_VALUE](state, action) {
    const { stateContext, schemaName, facetKey, value } = action;
    const isMultiSelect = state.getIn([stateContext, 'criteria', 'isMultiSelect']);
    const schemas = toJS(state.getIn([stateContext, 'criteria', 'schemas'])) ?? [];
    const resultSchemaFacets = toJS(state.getIn([stateContext, 'results', 'schemaFacets']));

    toggleSelection(isMultiSelect, schemas, schemaName, facetKey, value);
    const filtersCount = synchroniseToResultFacets(schemas, resultSchemaFacets);

    return state
      .setIn([stateContext, 'results', 'schemaFacets'], fromJS(resultSchemaFacets))
      .setIn([stateContext, 'criteria', 'filtersCount'], filtersCount)
      .setIn([stateContext, 'criteria', 'schemas'], fromJS(schemas))
      .setIn([stateContext, 'criteria', 'isDirty'], true);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES](state, action) {
    const { stateContext, value } = action;
    if (value === true || value === false)
      return state.setIn([stateContext, 'criteria', 'enableFavouritesFilter'], value);

    const enableFavouritesFilter = state.getIn([stateContext, 'criteria', 'enableFavouritesFilter']) === true;
    return state.setIn([stateContext, 'criteria', 'enableFavouritesFilter'], !enableFavouritesFilter)
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXCLUDE_DISCONTINUED_FILTER](state, action) {
    const { stateContext } = action;
    const enableDiscontinuedFilter = state.getIn([stateContext, 'criteria', 'enableDiscontinuedFilter']) === true;
    return state.setIn([stateContext, 'criteria', 'enableDiscontinuedFilter'], !enableDiscontinuedFilter)
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_BEGIN](state, action) {
    const { stateContext, requestParams, requestedPage } = action;
    return state
      .setIn([stateContext, 'criteria', 'page'], requestedPage)
      .setIn([stateContext, 'requestParams'], fromJS(requestParams))
      .setIn([stateContext, 'refreshRequired'], false)
      .setIn([stateContext, 'isSearching'], true);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_COMPLETE](state, action) {
    const { stateContext, data, searchArgs } = action;

    if (!data)
      return state.setIn([stateContext, 'isSearching'], false);

    const criteria = toJS(state.getIn([stateContext, 'criteria']));
    if (searchArgs !== undefined && stateContext === 'default') {
      state = state
        .setIn(['location', 'pathname'], '/analysis')
        .setIn(['location', 'history', '/analysis'], searchArgs)
        .setIn(['location', 'search'], searchArgs);
    }

    const { schemas } = data;
    if (schemas !== undefined) {
      const { facets: legacyFacets } = criteria;
      const { schemas: criteriaSchemas = [] } = criteria;
      const resultSchemaFacets = sortLegacyFacets(schemas, legacyFacets);
      const filtersCount = synchroniseToResultFacets(criteriaSchemas, resultSchemaFacets);
      state = state.setIn([stateContext, 'results', 'schemaFacets'], fromJS(resultSchemaFacets))
        .setIn([stateContext, 'criteria', 'schemas'], fromJS(criteriaSchemas))
        .setIn([stateContext, 'criteria', 'filtersCount'], filtersCount)
    }

    const { results } = data;
    if (results !== undefined) {
      const isDetailsExpanded = state.getIn([stateContext, 'enableExpandDetails']);
      const resultsData = results.map((i, index) => (
        {
          index,
          ...i,
          isDetailsExpanded,
          displaySchemas: filterSchemasForResultsRow(i.schemas)
        }));

      state = state.setIn([stateContext, 'results', 'data'], fromJS(resultsData))
    }

    return state
      .setIn([stateContext, 'results', 'criteria'], fromJS(criteria))
      .setIn([stateContext, 'criteria', 'isDirty'], false)
      .setIn([stateContext, 'isSearching'], false);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_CLEAR_SCHEMA_FACET_FILTERS](state, action) {
    const { stateContext } = action;
    const schemas = toJS(state.getIn([stateContext, 'criteria', 'schemas'])) ?? [];
    const resultSchemaFacets = toJS(state.getIn([stateContext, 'results', 'schemaFacets']));

    clearSelection(schemas);
    const filtersCount = synchroniseToResultFacets(schemas, resultSchemaFacets);

    return state
      .setIn([stateContext, 'results', 'schemaFacets'], fromJS(resultSchemaFacets))
      .setIn([stateContext, 'criteria', 'filtersCount'], filtersCount)
      .setIn([stateContext, 'criteria', 'schemas'], fromJS(schemas));
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_FACETS](state, action) {
    const { stateContext } = action;
    const isEnabled = state.getIn([stateContext, 'enableExpandFacets']);
    return state.setIn([stateContext, 'enableExpandFacets'], !isEnabled);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_DETAILS](state, action) {
    const { stateContext } = action;
    const isEnabled = state.getIn([stateContext, 'enableExpandDetails']);

    let newState = state.setIn([stateContext, 'enableExpandDetails'], !isEnabled);

    state.getIn([stateContext, 'results', 'data']).forEach((_i, ix) => {
      newState = newState.setIn([stateContext, 'results', 'data', ix, 'isDetailsExpanded'], !isEnabled);
    });

    return newState;
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_UPDATE_CUSTOM_FILTER_HEIGHT](state, action) {
    const { stateContext } = action;
    return state.setIn([stateContext, 'customFilterHeight'], action.value);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITES_COMPLETE](state, action) {
    const { stateContext } = action;
    const data = state.getIn([stateContext, 'results', 'data']);

    if (!action.keys || !action.keys.length) return state;

    const indexes = action.keys.map(key => data.findIndex(i => i.get('id') === key));

    let newState = state;

    indexes.forEach(index => {
      newState = newState.setIn([stateContext, 'results', 'data', index, 'isFavourite'], !!action.value);
    });

    return newState;
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_FAVOURITE_COMPLETE](state, action) {
    const { stateContext } = action;
    const data = state.getIn([stateContext, 'results', 'data']),
      index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    const isFavourite = data.getIn([index, 'isFavourite']);

    return state.setIn([stateContext, 'results', 'data', index, 'isFavourite'], !isFavourite);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_TOGGLE_EXPAND_ITEM_DETAILS](state, action) {
    const { stateContext } = action;
    const data = state.getIn([stateContext, 'results', 'data']);
    const index = data.findIndex(i => i.get('id') === action.key);

    if (index < 0) return state;

    const isEnabled = state.getIn([stateContext, 'results', 'data', index, 'isDetailsExpanded']);

    return state.setIn([stateContext, 'results', 'data', index, 'isDetailsExpanded'], !isEnabled);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_SET_SCHEMA_SELECTOR_SIZE](state, action) {
    const { stateContext, size } = action;
    return state
      .setIn([stateContext, 'schemaSelectorSize'], size)
      .setIn([stateContext, 'enableExpandFacets'], size !== 0);
  },
  [ANALYSIS_CROSS_SCHEMA_SEARCH_CREATE_SEARCH](state, action) {
    const { stateContext, displayOptions = {}, criteria } = action;
    if (stateContext === 'default')
      throw new Error('Cannot create a context "default"');

    let searchState = toJS(state.getIn(['default']));
    searchState.shouldLoadWhenVisible = false;

    const previousSearchState = toJS(state.getIn([stateContext])) ?? {};
    if (previousSearchState) {
      searchState.customFilterHeight = previousSearchState.customFilterHeight;
    }

    if (displayOptions) {
      Object.keys(searchState.displayOptions).forEach(key => {
        searchState.displayOptions[key] = displayOptions[key] === true;
      });
    }

    if (criteria) {
      searchState.criteria = criteria;
    }

    if (searchState.results) {
      searchState.results.data = [];
      if (!searchState.results.schemaFacets)
        searchState.results.schemaFacets = [];

      searchState.results.schemaFacets.forEach(sf => {
        sf.facets = [];
        sf.resultsCount = 0;
      });
    }

    searchState.refreshRequired = true;
    return state.setIn([stateContext], fromJS(searchState))
  }
};

export const analysisCrossSchemaSearch = createReducer(null, {
  ...searchReducer
});