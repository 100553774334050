import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { APP_TITLE, APP_THEME } from './../../config';
import PathNavLink from '../shared/PathNavLink';
import logo from '../../assets/logo.svg';
import { signOut } from '../../auth';
import {
  screenCaptureUpdateDefaults,
  screenCaptureLoad,
  screenCaptureOpenNewEdit
} from '../../actions/screenCapture';

const NavBar = connect(
  (state) => ({
      isWizard: state.getIn(['roles', 'isWizard']),
      analysisPathname: state.getIn(['analysisCrossSchemaSearch', 'location', 'pathname']),
      analysisSearch: state.getIn(['analysisCrossSchemaSearch', 'location', 'search']),
      eventsPathname: state.getIn(['events', 'location', 'pathname']),
      eventsSearch: state.getIn(['events', 'location', 'search']),
      reportsPathname: state.getIn(['reports', 'location', 'pathname']),
      reportsSearch: state.getIn(['reports', 'location', 'search']),
      showLog: state.getIn(['log', 'messages']).size > 0
  }),
  (dispatch) => ({
    openScreenCapture(e, isNew) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(screenCaptureUpdateDefaults());
      dispatch(screenCaptureLoad());
      if (isNew) dispatch(screenCaptureOpenNewEdit());
    }
})
)(({ isWizard, analysisPathname, analysisSearch, eventsPathname, eventsSearch, reportsPathname, reportsSearch, showLog, openScreenCapture, openNewScreenCapture }) => {
  const location = useLocation();
  if (reportsPathname === location.pathname){
    reportsPathname = 'reports';
  };

  const [isVisible, setIsVisible] = useState(false);

  return (
    <nav className={`navbar ${APP_THEME} fixed-top flex-md-nowrap p-0 shadow`} style={{zIndex:'999'}}>
      <Link className='navbar-brand px-md-3 d-flex align-items-center' to='/'>
        <img src={logo} className='fota-logo' alt='logo' />
        <h5 className='mb-0'>{APP_TITLE}</h5>
      </Link>
      <button className='navbar-toggler d-md-none mr-2' type='button' onClick={() => setIsVisible(!isVisible)}>
        <span className='navbar-toggler-icon'></span>
      </button>
      <div data_id='app-top-nav' className={`collapse navbar-collapse ${isVisible ? 'd-flex show' : 'hide'} d-md-flex align-items-start`}>
        <ul className='navbar-nav ml-sm-3 flex-fill flex-md-row justify-content-start'>
          <li className='nav-item text-nowrap px-md-3'>
            <PathNavLink data_id='home' exact className='nav-link' to='/'>Home</PathNavLink>
          </li>
          <li className='nav-item text-nowrap px-md-3'>
            <PathNavLink data_id='analysis' className='nav-link' to={analysisPathname} search={analysisSearch}>Analysis</PathNavLink>
          </li>
          <li className='nav-item text-nowrap px-md-3'>
            <PathNavLink data_id='reports' className='nav-link' to={reportsPathname} search={reportsSearch}>Reports</PathNavLink>
          </li>
          <li className='nav-item text-nowrap px-md-3'>
            <PathNavLink data_id='events' className='nav-link' to={eventsPathname} search={eventsSearch}>Events</PathNavLink>
          </li>
          <li className='nav-item text-nowrap px-md-3'>
            <PathNavLink data_id='actions' className='nav-link' to='/actions'>Actions</PathNavLink>
          </li>
          <li className='nav-item text-nowrap px-md-3'>
            <PathNavLink data_id='datasets' className='nav-link' to='/datasets'>Datasets</PathNavLink>
          </li>
          <li className='nav-item text-nowrap px-md-3'>
            <div className='dropdown'>
              <button data_id='etl' className='nav-link fota-link-button' data-toggle='dropdown'>ETL</button>
              <div className='dropdown-menu' style={{ position: 'absolute' }}>
                {isWizard && <PathNavLink data_id='jobs' className='dropdown-item' to='/functions'>Jobs</PathNavLink>}
                <PathNavLink data_id='dashboard' className='dropdown-item' to='/etl-jobs'>Dashboard</PathNavLink>
                <PathNavLink data_id='queue-status' className='dropdown-item' to='/queue-status'>Queue status</PathNavLink>
              </div>
            </div>
          </li>
          <li className='nav-item text-nowrap px-md-3'>
            <div className='dropdown'>
              <button data_id='power' className='nav-link fota-link-button' data-toggle='dropdown'>Power</button>
              <div className='dropdown-menu' style={{ position: 'absolute' }}>
                <PathNavLink data_id='outages' className='dropdown-item' to='/power/outages'>Outages</PathNavLink>
              </div>
            </div>
          </li>
          <li className='nav-item text-nowrap px-md-3'>
            <div className='dropdown'>
              <button data_id='gas' className='nav-link fota-link-button' data-toggle='dropdown'>Gas</button>
              <div className='dropdown-menu' style={{ position: 'absolute' }}>
                <PathNavLink data_id='lng-deals' className='dropdown-item' to='/lng-deals'>LNG deals</PathNavLink>
                <PathNavLink data_id='storage-auctions' className='dropdown-item' to='/storage-auctions'>Storage Auctions</PathNavLink>
              </div>
            </div>
          </li>
        </ul>
        <ul className='navbar-nav px-3 flex-fill flex-md-row justify-content-end text-sm-right'>
          <li className='nav-item text-nowrap pl-md-3 d-flex' style={{ position: 'relative' }}>
            <button className='btn btn-sm nav-link' onClick={e => openScreenCapture(e, true)}>
              <i className='fas fa-print fa-fw' /> New Capture
            </button>
            <div className='dropdown' style={{ position: 'static' }}>
              <button className='btn btn-sm nav-link' data-toggle='dropdown'>
                <i className='fas fa-caret-down fa-fw' />
              </button>
              <div className='dropdown-menu' style={{ position: 'absolute' }}>
                <button className='btn btn-link dropdown-item' onClick={e => openScreenCapture(e)}>
                  Manage Captures
                </button>
              </div>
            </div>
          </li>
          <li className='nav-item text-nowrap pl-md-3'>
            <div className='dropdown'>
              <button className='btn btn-sm nav-link' data-toggle='dropdown'>
                <i className='fas fa-plus fa-fw' /> New
              </button>
              <div className='dropdown-menu' style={{ position: 'absolute' }}>
                <NavLink className='dropdown-item' to='/timeseries/new'>Time Series</NavLink>
              </div>
            </div>
          </li>
          <li className='nav-item text-nowrap pl-md-3'>
            <NavLink className='nav-link' to='/user-settings'>
              <i className='fas fa-user-cog fa-fw' /> Settings
            </NavLink>
          </li>
          <li className='nav-item text-nowrap pl-md-3'>
            <a className='nav-link' href='https://mercuria-fota.visualstudio.com/timeseries/_wiki/wikis/timeseries.wiki/1/FOTA-Home' target='_blank' rel='noopener noreferrer'>
              <i className='fas fa-book fa-fw' /> Wiki
            </a>
          </li>
          <li className={'nav-item text-nowrap pl-md-3' + (showLog ? '' : ' d-none')}>
            <NavLink className='nav-link' to='/log'>
              <i className='fas fa-exclamation-triangle fa-fw' /> Logs
            </NavLink>
          </li>
          <li className='nav-item text-nowrap pl-md-3'>
            <span className='nav-link cursor-pointer' onClick={signOut}>Sign out</span>
          </li>
        </ul>
      </div>
    </nav>
  );
});

export default NavBar;