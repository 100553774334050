import React, { useState } from 'react';
import JsonEditor from './JsonEditor';
import BasicSettings from './BasicSettings';
import { OnDemandInputSettings } from '../timeSeriesDetails/timeSeriesEditor/DerivationSettings';
import FunctionSettings from '../timeSeriesDetails/timeSeriesEditor/FunctionSettings';
import InfoPopup from '../../shared/InfoPopup';

export function OnDemandDerivedDetailsEditor({ closeDialog, timeSeriesKey, basketTimeseries,
  referenceData, timeSeriesEditor, saveSettings, cancelSave,
  updateTimeSeriesObject, updateTimeSeriesValue,
  updateDerivationSearchKeyText, addDerivationSearchKeyText, moveFromBasketToKeys,
  moveDerivationKeyUp, moveDerivationKeyDown, deleteDerivationKey, addDerivationKeys, saveDerivationDetails, loadDerivationDetails, addAltSelection, toggleAltSelection, selectAltAll,
  inputFocusId, setInputFocus, setInputHasFocus, functionSettings, setFunctionsStepStyleToMultiStep, setFunctionsType, addFunctionsStepParameter, deleteFunctionsStepParameter, updateFunctionParameterValue, addMultiStepFunction, deleteMultiStepFunction, moveMultiStepFunctionUp, moveMultiStepFunctionDown, addFunctionKey, deleteFunctionKey, moveFunctionKeyUp, moveFunctionKeyDown, addFunctionOutputCategory, updateFunctionOutputKey, updateFunctionOutputCategoryValue, deleteFunctionOutputCategory, setFunctionDisabled,
  convertToTimeSeries
}) {
  const derivationSettings = timeSeriesEditor.derivationSettings;
  const timeSeries = timeSeriesEditor.timeSeries;
  const lookupData = timeSeriesEditor.lookupData;
  const [activeTab, setActiveTab] = useState('default');
  const otherTimerSeries = basketTimeseries.filter(t => t.key !== timeSeriesKey && t.identityId > 0);
  const inputsContainBasketItems = derivationSettings.selectedKeys && derivationSettings.selectedKeys.some(k => k.type === 'Request');
  
  function onConvertToTimeSeries(e) {
    e.preventDefault();

    saveDerivationDetails();
    convertToTimeSeries();
  }

  function onSaveSettings(e) {
    e.preventDefault();
    saveDerivationDetails();
    saveSettings();
  }

  function onCancelSettings(e) {
    e.preventDefault();
    cancelSave();
  }

  function onJsonDerivationDataEdited(data) {
    updateTimeSeriesObject({
      ...timeSeries,
      derivationData: data
    });
  }

  function onSetTab(tab) {
    if (tab === 'json') {
      saveDerivationDetails();
    } else {
      loadDerivationDetails();
    }

    setActiveTab(tab);
  }

  return <>
    <div className='modal-content' style={{ minWidth: '50em' }}>
      <div className='modal-header'>
        <h5 className='modal-title'>On demand derived settings</h5>
        <button type='button' className='close' onClick={closeDialog}>&times;</button>
      </div>
      <div className='modal-body' >
        <ul className='nav nav-tabs mb-2'>
          <li className='nav-item'>
            <button className={`nav-link ${activeTab === 'default' ? 'active' : ''}`} type='button' onClick={() => onSetTab('default')}>Editor</button>
          </li>
          <li className='nav-item'>
            <button className={`nav-link ${activeTab === 'json' ? 'active' : ''}`} type='button' onClick={() => onSetTab('json')}>JSON</button>
          </li>
        </ul>

        {activeTab === 'default' && (
          <div style={{overflowY:'auto', height:'70vh'}}>
            <BasicSettings referenceData={referenceData}
              lookupData={lookupData}
              timeSeries={timeSeries}
              updateTimeSeriesValue={updateTimeSeriesValue} />

            <OnDemandInputSettings referenceData={referenceData}
              lookupData={lookupData}
              timeSeries={timeSeries}
              updateTimeSeriesValue={updateTimeSeriesValue}
              searchKeyText={derivationSettings.searchKeyText}
              selectedKeys={derivationSettings.selectedKeys}
              updateDerivationSearchKeyText={updateDerivationSearchKeyText}
              addDerivationSearchKeyText={addDerivationSearchKeyText}
              moveFromBasketToKeys={moveFromBasketToKeys}
              moveDerivationKeyUp={moveDerivationKeyUp}
              moveDerivationKeyDown={moveDerivationKeyDown}
              deleteDerivationKey={deleteDerivationKey}
              addDerivationKeys={addDerivationKeys}
              altSelectableTimeSeries={otherTimerSeries}
              altSelectedKeys={derivationSettings.altSelectedKeys}
              addAltSelection={addAltSelection}
              toggleAltSelection={toggleAltSelection}
              selectAltAll={selectAltAll} />

            <FunctionSettings referenceData={referenceData}
              lookupData={lookupData}
              timeSeries={timeSeries}
              derivationSelectedKeys={derivationSettings.selectedKeys}
              updateTimeSeriesValue={updateTimeSeriesValue}
              inputFocusId={inputFocusId}
              setInputFocus={setInputFocus}
              setInputHasFocus={setInputHasFocus}
              functionSettings={functionSettings}
              setFunctionsStepStyleToMultiStep={setFunctionsStepStyleToMultiStep}
              setFunctionsType={setFunctionsType}
              addFunctionsStepParameter={addFunctionsStepParameter}
              deleteFunctionsStepParameter={deleteFunctionsStepParameter}
              updateFunctionParameterValue={updateFunctionParameterValue}
              addMultiStepFunction={addMultiStepFunction}
              deleteMultiStepFunction={deleteMultiStepFunction}
              moveMultiStepFunctionUp={moveMultiStepFunctionUp}
              moveMultiStepFunctionDown={moveMultiStepFunctionDown}
              addFunctionKey={addFunctionKey}
              deleteFunctionKey={deleteFunctionKey}
              moveFunctionKeyUp={moveFunctionKeyUp}
              moveFunctionKeyDown={moveFunctionKeyDown}
              addFunctionOutputCategory={addFunctionOutputCategory}
              updateFunctionOutputKey={updateFunctionOutputKey}
              updateFunctionOutputCategoryValue={updateFunctionOutputCategoryValue}
              deleteFunctionOutputCategory={deleteFunctionOutputCategory}
              setFunctionDisabled={setFunctionDisabled} />
          </div>)}

        {activeTab === 'json' && (
          <div style={{ height:'70vh' }}>
            <JsonEditor data={timeSeries.derivationData} onEdited={onJsonDerivationDataEdited} />
          </div>)}
      </div>

      <div className='modal-footer'>
        <div className='btn-group'>
          <button className='btn btn-sml btn-secondary' onClick={e => onCancelSettings(e)}>Cancel</button>
          <button disabled={inputsContainBasketItems} className={`btn btn-sml ${(inputsContainBasketItems ? 'btn-secondary disabled' : 'btn-secondary')} ml-1`} onClick={(e) => onConvertToTimeSeries(e)}>Save as Derived</button>
          {inputsContainBasketItems === true && <InfoPopup>Basket inputs are not compatible with deriveds</InfoPopup>}
          <button className='btn btn-sml btn-primary ml-1' onClick={(e) => onSaveSettings(e)}>Save</button>
        </div>
      </div>
    </div>
  </>
}