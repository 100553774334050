
import { assertType } from '../utility/type-checking';
import PropTypes from 'prop-types';
import { cloneInstance } from '../utility/property-utlility';

export function mapWorkspaceFromApiModel(key, apiResponse) {
  assertType({
    key: PropTypes.string.isRequired,
    apiResponse: PropTypes.object.isRequired
  }, { key, apiResponse });

  const workspace = JSON.parse(apiResponse.data);
  const { type = 'Analysis' } = apiResponse.type;

  switch (type) {
    case 'Analysis':
      loadAnalysisWorkspace(workspace);
      break;
    default:
      break;
  }

  workspace.id = key;
  workspace.type = type;
  workspace.name = apiResponse.name;
  workspace.scope = apiResponse.scope;
  workspace.folderPath = apiResponse.folderPath;
  workspace.subFolderPath = apiResponse.subFolderPath;
  workspace.version = apiResponse.version;
  workspace.thumbImageId = apiResponse.thumbImageId;
  if (!workspace.period)
    workspace.period = '';

  if (!workspace.periods)
    workspace.periods = [];
  
  return workspace;
}

export function mapAnalysisWorkspaceToApiModel(workspace, periods) {
  // save the periods in the workspace so that we can share the workspace if needed
  const referencedPeriods = [...workspace.timeseries.filter(ts => ts.period).map(ts => ts.period)];
  referencedPeriods.push(workspace.period);

  workspace.periods = [...new Set(referencedPeriods.map(name => periods.find(p => p.name === name)).filter(p => p))];
  return workspace;
}

function loadAnalysisWorkspace(ws) {
  if (!ws.chartSettings) ws.chartSettings = {};
  if (!ws.comparisonMode) ws.comparisonMode = 'none';
  if (!ws.relFrom) ws.relFrom = '';
  if (!ws.relTo) ws.relTo = '';
  if (!ws.fromDateMode) ws.fromDateMode = 'abs';
  if (!ws.toDateMode) ws.toDateMode = 'abs';
  if (!ws.fromAsAtDateMode) ws.fromAsAtDateMode = 'abs';
  if (!ws.toAsAtDateMode) ws.toAsAtDateMode = 'abs';

  if (!ws.timeseries) {
    if (ws.timeSeries) {
      ws.timeseries = ws.timeSeries;
      delete ws.timeSeries;
    } else {
      ws.timeseries = [];
    }
  }

  if (ws.dynamicSettings) {
    ws.dynamicWorkspaces = ws.dynamicSettings;
    delete ws.dynamicSettings;
  }

  if (ws.dynamicWorkspaces) {
    ws.dynamicWorkspaces.forEach(dynamicWorkspace => {
      //   dynamicWorkspace.color ??= getNextUserColour();

      if (dynamicWorkspace.dynamicFilters) {
        dynamicWorkspace.dynamicFilters.forEach(dynamicFilter => {
          dynamicFilter.baselineSelectedCategory ??= dynamicFilter.selectedCategory;
          dynamicFilter.baselineSelectedValue ??= dynamicFilter.selectedValue;
          dynamicFilter.baselineMultiSelectValues ??= (dynamicFilter.multiSelectValues ?? []);
        });
      }
    });
  } else {
    ws.dynamicWorkspaces = [];
  }

  ws.dynamicWorkspaces.forEach(ws => {
    ws.dynamicFilters ??= ws.dynamicFilters = [];

    ws.dynamicFilters.forEach(dynamicFilter => {
      dynamicFilter.isMultiSelect ??= false;
      dynamicFilter.multiSelectValues ??= [];
    })
  });

  if (ws.dynamicWorkspaceGroupingEnabled === undefined) {
    ws.dynamicWorkspaceGroupingEnabled = false;    
  }

  ws.timeseries.forEach(ts => {
    if (ts.dynamicWorkspace && ts.dynamicWorkspace.filterSetKey) {
      ts.dynamicWorkspace.key = ts.dynamicWorkspace.filterSetKey;
      delete ts.dynamicWorkspace.filterSetKey;
    }
  });

  if (!ws.baselineBasket || ws.baselineBasket.length === 0)
    ws.baselineBasket = cloneInstance(ws.timeseries);

  ws.baselineBasket.forEach(b => {
    if (b.dynamicWorkspace && b.dynamicWorkspace.filterSetKey) {
      b.dynamicWorkspace.key = b.dynamicWorkspace.filterSetKey;
      delete b.dynamicWorkspace.filterSetKey;
    }
    
    if (!b.baselineId)
      b.baselineId = b.id ?? b.identityId;
  });

  ws.timeseries.forEach(ts => {
    if (ts.dynamicWorkspace && !ts.baselineId) {
      const baselineTs = ws.baselineBasket.find(b => b.key === ts.key) ?? ts;
      ts.baselineId = baselineTs.id ?? baselineTs.identityId;
    }
  });
}