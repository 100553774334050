import React from 'react';
import { connect } from 'react-redux';
import Schemas from './Schemas';
import {
  addSchema,
  copyCategoriesFrom,
  deleteIdentitySchema,
  editSchemaCategory,
  loadSchemas,
  saveIdentitySchema,
  saveLookup,
  setCopyFromIdentityId,
  setLookupValue,
  setSelectedLookup,
  setSelectedSchema,
  startSchemaEdit,
  stopSchemaEdit
} from '../../../../actions/schemas';
import { loadSchemaLookups } from '../../../../actions/schemas';
const SchemasConnected = connect(
  (state) => ({
    allSchemaLookups: state.getIn(['schemas', 'allSchemaLookups']),
    availableSchemas: state.getIn(['schemas', 'availableSchemas']),
    identityId: state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']),
    identitySchemas: state.getIn(['schemas', 'identitySchemas']),
    isLoading: state.getIn(['schemas', 'isLoading']),
    schemaInEdit: state.getIn(['schemas', 'schemaInEdit']),
    selectedSchema: state.getIn(['schemas', 'selectedSchema']),
    selectedLookup: state.getIn(['schemas', 'selectedLookup']),
    lookupValue: state.getIn(['schemas', 'lookupValue']),
    copyFromIdentityId: state.getIn(['schemas', 'copyFromIdentityId'])
  }),
  (dispatch) => ({
    addSchema() {
      dispatch(addSchema());
    },
    copyCategoriesFrom() {
      dispatch(copyCategoriesFrom());
    },
    deleteIdentitySchema(schemaName, identityId) {
      dispatch(deleteIdentitySchema(schemaName, identityId));
    },
    editSchemaCategory(schemaName, category, value) {
      dispatch(editSchemaCategory(schemaName, category, value));
    },
    loadSchemas(identityId) {
      dispatch(loadSchemaLookups());
      dispatch(loadSchemas(identityId));
    },
    saveIdentitySchema(schemaName, identityId) {
      dispatch(saveIdentitySchema(schemaName, identityId));
    },
    saveLookup() {
      dispatch(saveLookup());
    },
    setCopyFromIdentityId(id) {
      dispatch(setCopyFromIdentityId(id));
    },
    setLookupValue(lookupValue) {
      dispatch(setLookupValue(lookupValue));
    },
    setSelectedLookup(selectedLookup) {
      dispatch(setSelectedLookup(selectedLookup));
    },
    setSelectedSchema(schemaName) {
      dispatch(setSelectedSchema(schemaName));
    },
    startSchemaEdit(schemaName) {
      dispatch(startSchemaEdit(schemaName));
    },
    stopSchemaEdit() {
      dispatch(stopSchemaEdit());
    }
  })
)(({ allSchemaLookups, availableSchemas, identityId, identitySchemas, isLoading, schemaInEdit, selectedSchema,
  addSchema, loadSchemas, deleteIdentitySchema, editSchemaCategory, saveIdentitySchema, setSelectedSchema, startSchemaEdit, stopSchemaEdit,
  selectedLookup, setSelectedLookup, lookupValue, setLookupValue, saveLookup,
  copyFromIdentityId, copyCategoriesFrom, setCopyFromIdentityId
}) => {

  const _allSchemaLookups = allSchemaLookups.toJS();
  const _availableSchemas = availableSchemas.toJS();
  const _identitySchemas = identitySchemas.toJS();

  return <Schemas addSchema={addSchema}
                  allSchemaLookups={_allSchemaLookups}
                  availableSchemas={_availableSchemas}
                  identityId={identityId}
                  identitySchemas={_identitySchemas}
                  isLoading={isLoading}
                  loadSchemas={loadSchemas}
                  schemaInEdit={schemaInEdit}
                  deleteIdentitySchema={deleteIdentitySchema}
                  editSchemaCategory={editSchemaCategory}
                  saveIdentitySchema={saveIdentitySchema}
                  selectedSchema={selectedSchema}
                  setSelectedSchema={setSelectedSchema}
                  startSchemaEdit={startSchemaEdit}
                  stopSchemaEdit={stopSchemaEdit} 
                  selectedLookup={selectedLookup}
                  setSelectedLookup={setSelectedLookup}
                  lookupValue={lookupValue}
                  setLookupValue={setLookupValue}
                  saveLookup={saveLookup}                 
                  copyFromIdentityId={copyFromIdentityId}
                  copyCategoriesFrom={copyCategoriesFrom}
                  setCopyFromIdentityId={setCopyFromIdentityId} />;
});

export default SchemasConnected;