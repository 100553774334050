import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../../utility/immutable-utility';
import AdjustmentRangeEditor from './AdjustmentRangeEditor';
import {
  adjustmentRangeEditorInitialise,
  adjustmentRangeEditorUninitialise,
  adjustmentRangeEditorLoad,
  adjustmentRangeEditorUpdateToolbarProperty,
  adjustmentRangeEditorAddRange,
  adjustmentRangeEditorGenerate,
  adjustmentRangeEditorGeneratePeriodEnd,
  adjustmentRangeEditorClearEditRange,
  adjustmentRangeEditorEditRangeProperty,
  adjustmentRangeEditorEditRangeItems,
  adjustmentRangeEditorSaveRange,
  adjustmentRangeEditorDeleteRange,
  adjustmentRangeEditorSelectRange
} from '../../../../actions/adjustment-range-editor';

const AdjustmentRangeEditorConnected = connect(
  (state) => ({
    timeSeriesId: state.getIn(['timeSeries', 'id']),
    timeSeriesGranularityType: state.getIn(['timeSeries', 'granularityType']),
    isInitialised: state.getIn(['adjustmentRangeEditor', 'isInitialised']),
    isLoading: state.getIn(['adjustmentRangeEditor', 'isLoading']),
    isSaving: state.getIn(['adjustmentRangeEditor', 'isSaving']),
    isGenerating: state.getIn(['adjustmentRangeEditor', 'isGenerating']),
    isDateOnly: state.getIn(['adjustmentRangeEditor', 'isDateOnly']),
    operations: state.getIn(['adjustmentRangeEditor', 'operations']),
    types: state.getIn(['adjustmentRangeEditor', 'types']),
    periodStart: state.getIn(['adjustmentRangeEditor', 'toolbar', 'periodStart']),
    periodEnd: state.getIn(['adjustmentRangeEditor', 'toolbar', 'periodEnd']),
    dataPoints: state.getIn(['adjustmentRangeEditor', 'dataPoints']),
    comments: state.getIn(['adjustmentRangeEditor', 'comments']),
    ranges: state.getIn(['adjustmentRangeEditor', 'ranges']),
    rangeEdit: state.getIn(['adjustmentRangeEditor', 'rangeEdit']),
    firstSelectedRow: state.getIn(['adjustmentRangeEditor', 'firstSelectedRow']),
    isWizard: state.getIn(['roles', 'isWizard'])
  }),
  (dispatch) => ({
    initialise(timeSeriesId, timeSeriesGranularityType) {
      dispatch(adjustmentRangeEditorInitialise(timeSeriesId, timeSeriesGranularityType));
    },
    uninitialise() {
      dispatch(adjustmentRangeEditorUninitialise());
    },
    refresh() {
      dispatch(adjustmentRangeEditorClearEditRange());
      dispatch(adjustmentRangeEditorLoad());
    },
    updateToolbarProperty(key, value) {
      dispatch(adjustmentRangeEditorUpdateToolbarProperty(key, value));
    },
    addRange(data) {
      dispatch(adjustmentRangeEditorAddRange(data));
      dispatch(adjustmentRangeEditorGenerate());
    },
    cancelRange() {
      dispatch(adjustmentRangeEditorClearEditRange());
      dispatch(adjustmentRangeEditorLoad());
    },
    saveRange() {
      dispatch(adjustmentRangeEditorSaveRange());
    },
    deleteRange(id) {
      dispatch(adjustmentRangeEditorDeleteRange(id));
    },
    selectRange(id) {
      dispatch(adjustmentRangeEditorSelectRange(id));
    },
    editRangeProperty(key, value) {
      dispatch(adjustmentRangeEditorEditRangeProperty(key, value));
      dispatch(adjustmentRangeEditorGenerate(key));
    },
    editRangeReason(value) {
      dispatch(adjustmentRangeEditorEditRangeProperty('reason', value));
    },
    editRangeItems(items) {
      dispatch(adjustmentRangeEditorEditRangeItems(items));
      dispatch(adjustmentRangeEditorGeneratePeriodEnd());
    }
  })
)(({ timeSeriesId, timeSeriesGranularityType, isWizard, isInitialised, isLoading, isSaving, isGenerating, isDateOnly,
  operations, types, periodStart, periodEnd, dataPoints, comments, ranges, rangeEdit, firstSelectedRow,
  initialise, uninitialise, refresh, updateToolbarProperty, addRange, cancelRange, saveRange, deleteRange, selectRange,
  editRangeProperty, editRangeReason, editRangeItems }) => {
  const _dataPoints = toJS(dataPoints, []);
  const _comments = toJS(comments, []);
  const _ranges = toJS(ranges, []);
  const _rangeEdit = toJS(rangeEdit, {});
  const _operations = toJS(operations, {});
  const _types = toJS(types, {});

  return (
    <AdjustmentRangeEditor
      timeSeriesId={timeSeriesId}
      timeSeriesGranularityType={timeSeriesGranularityType}
      isWizard={isWizard}
      isInitialised={isInitialised}
      isLoading={isLoading}
      isSaving={isSaving}
      isGenerating={isGenerating}
      operations={_operations}
      types={_types}
      isDateOnly={isDateOnly}
      periodStart={periodStart}
      periodEnd={periodEnd}
      dataPoints={_dataPoints}
      comments={_comments}
      ranges={_ranges}
      rangeEdit={_rangeEdit}
      firstSelectedRow={firstSelectedRow}
      initialise={initialise}
      uninitialise={uninitialise}
      refresh={refresh}
      updateToolbarProperty={updateToolbarProperty}
      addRange={addRange}
      cancelRange={cancelRange}
      saveRange={saveRange}
      deleteRange={deleteRange}
      selectRange={selectRange}
      editRangeProperty={editRangeProperty}
      editRangeReason={editRangeReason}
      editRangeItems={editRangeItems} />
  );
});

export default AdjustmentRangeEditorConnected;