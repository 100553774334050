import React from 'react';
import ColourPicker from '../../shared/ColourPicker';
import { CheckboxInput } from '../../shared/CheckboxInput';
import { GetRegisteredDashboardItem } from '../../../mapper/dashboardTileMapper';

export default function TileCommonSettings({ propertyBag, setProperty, dashboardTileType, children }) {
  const { dashboardFilterOptions } = GetRegisteredDashboardItem(dashboardTileType) ?? { dashboardFilterOptions: [] };
  const useDashboardOptions = dashboardFilterOptions.map(k => ({ name: k, value: propertyBag[`useDashboard${k}`] }));

  return <>
    <div className='form-row'>
      <div className='col-md-8'>
        <label className='font-weight-bold'>Title</label>
        <div className='d-flex text-nowrap'>
          <div className='mr-2 mt-1'>
            <CheckboxInput checked={propertyBag.isTitleVisible === true} onChange={(_,checked)=> setProperty({ isTitleVisible: checked })} >Is Visible</CheckboxInput>
          </div>
          <input type='text' disabled={propertyBag.isTitleVisible !== true} autoFocus className='form-control form-control-sm' value={propertyBag.title ?? ''} onChange={e => setProperty({ title: e.target.value })} />
        </div>
      </div>
      <div className='col-md-4'>
        <div className='form-row'>
          <div className='col'>
            <label className='font-weight-bold'>Colour</label>
              <ColourPicker colour={propertyBag.color} buttonClassName={'form-control form-control'} onColourChange={color => setProperty({ color: color.hex })} />
          </div>
          <div className='col'>
            <label className='font-weight-bold'>Toolbar</label>
            <select className='form-control form-control-sm' value={propertyBag.hideToolbar ? 'hidden' : 'visible'}
              onChange={e => setProperty({ hideToolbar: e.target.value === 'hidden' })} >
              <option value='visible'>Visible</option>
              <option value='hidden'>Hidden</option>
            </select>
          </div>
          <div className='col'>
            <label className='font-weight-bold'>Borders</label>
            <select className='form-control form-control-sm' value={propertyBag.borderStyle ?? 'default'}
              onChange={e => setProperty({ borderStyle: e.target.value })} >
              <option value='default'>Shadow</option>
              <option value='flat'>Flat</option>
              <option value='none'>None</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className='d-flex'>
        <div>
          {useDashboardOptions && useDashboardOptions.length > 0 && <>
            <label className='mt-3 font-weight-bold'>Settings Overrides</label>
            <div className=''>
              {useDashboardOptions.map(o => <div key={`opt-${o.name}`} className='pb-3 pr-3 mr-3'>
                <CheckboxInput checked={o.value} onChange={() => setProperty({ [`useDashboard${o.name}`]: !o.value})} >{`Use dashboard ${o.name}`}</CheckboxInput>
              </div>)}
            </div>
          </>}
        </div>

        <div className='flex-fill mt-3'>
          {children}
        </div>
      </div>
    </div>
  </>
}