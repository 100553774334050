import React, { Fragment } from 'react';
import LoadingIcon from './LoadingIcon';

export default function Loading({ children, className, style, isLoading, message = 'Loading...' }) {
  return (
    <Fragment>
      <div className={ `fota-loading ${className || ''} ${isLoading ? '' : 'd-none'}` } style={ style }>
        <LoadingIcon width='2.5em' height='2.5em' />
        <h5 className='mb-0 ml-2'>{ message }</h5>
      </div>
      { children }
    </Fragment>
  );
}