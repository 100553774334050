import React from 'react';
import RequiredField from '../../shared/RequiredField';
import CollapsePanel from '../../shared/CollapsePanel';
import ComboBox from '../../shared/ComboBox';

const labelStyle = {
  fontSize: '11.6px',
  fontWeight: 700,
  margin: 0,
  cursor: 'default'
};

const inputStyle = {
  fontSize: '12px'
};

export default function BasicSettings({ referenceData, lookupData, timeSeries, updateTimeSeriesValue }) {
  const { name, unit, granularity, sourceTimeZoneId } = timeSeries.derivationData;
  const { granularityType, granularityFrequency } = granularity || {};

  const {
    unit: unitLookupData
  } = (lookupData ?? {});
  
  const {
    granularities: granularityOptions,
    timezones
  } = referenceData;

  const hasGranularityFrequency = granularityOptions.some(i => i.name === granularityType && i.hasFrequency);

  return (
    <CollapsePanel idKey='timeSeriesEditorBasicSettings' title='Basics' headerStyle={{ cursor: 'pointer' }}>
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Name <RequiredField /></label>
          <input type='text' className='form-control' style={inputStyle}
            value={name || ''}
            title={name || ''}
            onChange={e => updateTimeSeriesValue(['derivationData','name'], e.target.value)} />
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col mb-1'>
          <label style={labelStyle}>Unit <RequiredField /></label>
          <ComboBox value={unit || ''}
            title={unit || ''}
            style={inputStyle}
            onChange={value => updateTimeSeriesValue(['derivationData','unit'], value)}
            onGetData={callback => callback.success(unitLookupData)} />
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col-3 mb-1 pr-1'>
          <label style={labelStyle}>Granularity <RequiredField /></label>
          <select className='form-control' style={inputStyle}
            value={granularityType || ''}
            onChange={e => updateTimeSeriesValue(['derivationData','granularity', 'granularityType'], e.target.value)}>
            {granularityOptions.map(i => <option key={i.name} value={i.name}>{i.name}</option>)}
          </select>
        </div>
        <div className='col-3 mb-1 pr-1 pl-1'>
          <label style={labelStyle}>Frequency {hasGranularityFrequency && <RequiredField />}</label>
          <input className='form-control' style={inputStyle} type='text'
            value={granularityFrequency || ''}
            disabled={!hasGranularityFrequency}
            onChange={e => updateTimeSeriesValue(['derivationData','granularity', 'granularityFrequency'], e.target.value)} />
        </div>
        <div className='col-6 mb-1 pl-1'>
          <label style={labelStyle}>Timezone <RequiredField /></label>
          <select className='form-control' style={inputStyle}
            value={sourceTimeZoneId || ''}
            onChange={e => updateTimeSeriesValue(['derivationData','sourceTimeZoneId'], e.target.value)} >
            {timezones.map(t => <option key={t} value={t}>{t}</option>)}
          </select>
        </div>
      </div>     
    </CollapsePanel>
  );
}