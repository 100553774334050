import { toJS } from '../utility/immutable-utility';
import moment from 'moment';

export function updateTimeSeriesMeta(selection, isHorizontal, timeSeries) {
  const timeSeriesKeys = {};
  Object.keys(selection.range).forEach(address => {
    let { rowKey, colKey } = fromAddress(address);
    const tsKey = isHorizontal ? rowKey : colKey;
    timeSeriesKeys[tsKey] = {};
  });

  const selectionTimeSeriesMetaMap = Object.fromEntries(selection.timeSeriesMeta.map(ts => [ts.key, ts]));
  // add new select to list as required
  Object.keys(timeSeriesKeys).forEach(tsKey => {
    let timeSeriesMeta = selectionTimeSeriesMetaMap[tsKey];
    if (!timeSeriesMeta) {
      const ts = isHorizontal ? timeSeries[tsKey] : timeSeries.find(ts => ts.key === tsKey);
      if (ts) {
        selectionTimeSeriesMetaMap[tsKey] = {
          key: ts.key,
          identityId: ts.identityId,
          name: ts.name,
          isSelected: true,
          operation: '',
          timeZoneTreatment: '',
          comment: ''
        };
      }
    }
  });

  const metaKeys = Object.keys(selectionTimeSeriesMetaMap);
  metaKeys.forEach(tsKey => {
    let timeSeriesMeta = timeSeriesKeys[tsKey];
    if (!timeSeriesMeta) {
      selectionTimeSeriesMetaMap[tsKey].isSelected = false;
    } else {
      selectionTimeSeriesMetaMap[tsKey].isSelected = true;
    }
  });

  selection.timeSeriesMeta = Object.keys(selectionTimeSeriesMetaMap).map(k => selectionTimeSeriesMetaMap[k]);
}

export function clearSelection(selection, data, removeIsEditingFlag = false) {
  const stateChanges = [];
  if (selection && selection.range) {
    Object.keys(selection.range).forEach(address => {
      delete selection.range[address];
      const { rowKey, colKey } = fromAddress(address);
      const cell = toJS(data.getIn([rowKey, colKey]));
      if (cell) {
        if (cell.isSelected) {
          stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isSelected'], value: false });
          stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'hasCursor'], value: false });
        }

        if (removeIsEditingFlag && cell.isEditing) {
          stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isEditing'], value: false });
          delete selection.editCell;
          delete selection.cursorCell;
        }
      }
    });
  }

  return stateChanges;
}

export function selectCell(selection, colKey, rowKey, data) {
  let stateChanges = [];

  if (selection.cursorCell && selection.cursorCell.rowKey && selection.cursorCell.colKey) {
    stateChanges.push({ path: ['table', 'data', selection.cursorCell.rowKey, selection.cursorCell.colKey, 'hasCursor'], value: false });
  }

  selection.range[toAddress(rowKey, colKey)] = true;
  const previousSelection = data.getIn(['table', 'data', rowKey, colKey, 'isSelected']) === true;
  if (!previousSelection) {
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isSelected'], value: true });
    stateChanges.push({ path: ['adjustments', 'editSelection', 'range', toAddress(rowKey, colKey)], value: true });
  } else {
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isSelected'], value: false });
    stateChanges.push({ path: ['adjustments', 'editSelection', 'range', toAddress(rowKey, colKey)], isDeletion: true });
  }

  stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'hasCursor'], value: true });

  selection.startRowKey = rowKey;
  selection.startColKey = colKey;
  selection.cursorCell = {
    rowKey: rowKey,
    colKey: colKey
  };

  return stateChanges;
}

export function selectGridRange(selection, addressLookup) {
  let stateChanges = [];
  const startRowIndex = Math.min(Number(selection.startRowKey), Number(selection.endRowKey));
  const endRowIndex = Math.max(Number(selection.startRowKey), Number(selection.endRowKey));
  const columns = reduceColumnsRange(addressLookup, selection.startColKey, selection.endColKey);
  const rowLookups = Object.fromEntries(addressLookup.rows.map((r, i) => [r, i]));
  const columnLookups = Object.fromEntries(addressLookup.columns.map(c => [c, c]));
  for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
    if (rowLookups[rowIndex] !== undefined) {
      columns.forEach(colKey => {
        if (columnLookups[colKey] !== undefined) {
          selection.range[toAddress(rowIndex, colKey)] = true;
          stateChanges.push({ path: ['table', 'data', rowIndex, colKey, 'isSelected'], value: true });
        }
      });
    }
  }

  if (selection.cursorCell && selection.cursorCell.rowKey && selection.cursorCell.colKey) {
    stateChanges.push({ path: ['table', 'data', selection.cursorCell.rowKey, selection.cursorCell.colKey, 'hasCursor'], value: false });
  }

  selection.cursorCell = {
    rowKey: selection.endRowKey,
    colKey: selection.endColKey
  };

  stateChanges.push({ path: ['table', 'data', selection.endRowKey, selection.endColKey, 'hasCursor'], value: true });

  return stateChanges;
}

export function selectLine(selection, addressLookup, data, isRowSelection, direction, fullLine, keyOverride) {
  let stateChanges = [];

  if (isRowSelection) {
    const rowKey = addressLookup.rows[selection.cursorCell.rowKey];
    const from = selection.cursorCell.colKey;
    let isSelecting = fullLine || direction === 'fromStart';
    addressLookup.columns.forEach(colKey => {
      if (direction === 'toEnd' && colKey === from) isSelecting = true;

      if (isSelecting) {
        selection.range[toAddress(rowKey, colKey)] = true;
        stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isSelected'], value: true });
      }

      if (direction === 'fromStart' && colKey === from) isSelecting = false;
    });
  } else {
    const key = keyOverride ?? selection.cursorCell.colKey;
    const from = selection.cursorCell.rowKey;
    if (key !== 'dateTime' && key !== 'title' && key !== 'valueOf' && key !== 'rowIndex') {
      let isSelecting = fullLine || direction === 'fromStart';
      for (let index = 0; index < data.size; index++) {
        if (direction === 'toEnd' && index === from) isSelecting = true;

        if (isSelecting) {
          selection.range[toAddress(index, key)] = true;
          stateChanges.push({ path: ['table', 'data', index, key, 'isSelected'], value: true });
        }

        if (direction === 'fromStart' && index === from) isSelecting = false;
      }
    }
  }

  return stateChanges;
}

export function setEditCell(selection, data, rowKey, colKey) {
  let stateChanges = [];
  if (selection.editCell && rowKey === selection.editCell.rowKey && colKey === selection.editCell.colKey)
    return stateChanges;

  // remove the previous edit cell
  if (selection.editCell && selection.editCell.rowKey !== undefined && selection.editCell.colKey !== undefined) {
    stateChanges.push({ path: ['table', 'data', selection.editCell.rowKey, selection.editCell.colKey, 'isEditing'], value: false });
    delete selection.editCell;
  }

  if (selection.cursorCell && selection.cursorCell.rowKey !== undefined && selection.cursorCell.colKey !== undefined) {
    stateChanges.push({ path: ['table', 'data', selection.cursorCell.rowKey, selection.cursorCell.colKey, 'hasCursor'], value: false });
  }

  // set the next edit cell
  const cell = toJS(data.getIn([rowKey, colKey]));
  stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isEditing'], value: true });
  stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'hasCursor'], value: true });
  selection.deferredValue = cell.adjustment !== undefined ? cell.adjustment : cell.value;
  selection.editCell = {
    rowKey,
    colKey
  };

  return stateChanges;
}

export function navigateToNextCell(selection, addressLookup, data, direction, continueSelection) {
  let stateChanges = [];
  if (!selection.cursorCell) {
    stateChanges.push({ path: ['table', 'data', selection.cursorCell.rowKey, selection.cursorCell.colKey, 'hasCursor'], value: false });
    selection.cursorCell = { ...selection.editCell }
  }

  if (!continueSelection) {
    stateChanges = [...stateChanges, ...clearSelection(selection, data)];
  }

  let nextRowIndex = addressLookup.rows.indexOf(Number(selection.cursorCell.rowKey));
  if (direction === 'up') nextRowIndex--;
  if (direction === 'down' || direction === 'enter') nextRowIndex++;
  if (nextRowIndex < 0) nextRowIndex = 0;
  if (nextRowIndex >= addressLookup.rows.length) nextRowIndex = addressLookup.rows.length - 1;
  const nextRowKey = addressLookup.rows[nextRowIndex];

  let columnKeyIndex = addressLookup.columns.indexOf(selection.cursorCell.colKey);
  if (direction === 'left') columnKeyIndex--;
  if (direction === 'right') columnKeyIndex++;
  if (columnKeyIndex < 0) columnKeyIndex = 0;
  if (columnKeyIndex >= addressLookup.columns.length) columnKeyIndex = addressLookup.columns.length - 1;
  const nextColumnKey = addressLookup.columns[columnKeyIndex];

  if (selection.cursorCell && selection.cursorCell.rowKey !== undefined && selection.cursorCell.colKey !== undefined)
    stateChanges.push({ path: ['table', 'data', selection.cursorCell.rowKey, selection.cursorCell.colKey, 'hasCursor'], value: false });

  if (continueSelection) {
    if (selection.startRowKey === undefined) selection.startRowKey = nextRowKey;
    if (selection.startColKey === undefined) selection.startColKey = nextColumnKey;

    selection.endRowKey = nextRowKey;
    selection.endColKey = nextColumnKey;
    stateChanges = [...stateChanges, ...selectGridRange(selection, addressLookup)];
  }
  else{
    selection.range[toAddress(nextRowKey, nextColumnKey)] = true;
  }

  selection.cursorCell = {
    rowKey: nextRowKey,
    colKey: nextColumnKey
  };

  if (!continueSelection) {
    selection.cursorCell = {
      rowKey: nextRowKey,
      colKey: nextColumnKey
    };
    stateChanges = [...stateChanges, ...setEditCell(selection, data, nextRowKey, nextColumnKey)];
  }

  stateChanges.push({ path: ['table', 'data', nextRowKey, nextColumnKey, 'hasCursor'], value: true });
  stateChanges.push({ path: ['table', 'data', nextRowKey, nextColumnKey, 'isSelected'], value: true });
  return stateChanges;
}

export function setCellAdjustmentValue(rowKey, colKey, value) {
  if (value !== '' && value !== undefined && isNaN(value))
    return [];

  let stateChanges = [];
  stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'adjustment'], value: value });
  stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'adjustmentIsDirty'], value: true });
  stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(rowKey, colKey)], value: true });
  return stateChanges;
}

export function setSelectionAdjustmentValue(selection) {
  const value = selection.deferredValue;
  if (value !== '' && value !== undefined && isNaN(value))
    return [];

  let stateChanges = [];

  const addresses = Object.keys(selection.range).map(fromAddress);
  addresses.forEach(address => {
    const rowKey = address.rowKey;
    const colKey = address.colKey;
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'adjustment'], value: value });
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'adjustmentIsDirty'], value: true });
    stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(rowKey, colKey)], value: true });
  });

  return stateChanges;
}

export function removeSelectionAdjustments(selection, data) {
  let stateChanges = [];

  const addresses = Object.keys(selection.range).map(fromAddress);
  addresses.forEach(address => {
    const rowKey = address.rowKey;
    const colKey = address.colKey;
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'hasCursor'], value: false });
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isSelected'], value: false });
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isEditing'], value: false });
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'adjustment'], value: undefined });

    const cell = toJS(data.getIn([rowKey, colKey]));
    if (cell.hasExistingAdjustment) {
      stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'adjustmentIsDirty'], value: true });
      stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(rowKey, colKey)], value: true });
    } else {
      stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'adjustmentIsDirty'], value: false });
      stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(rowKey, colKey)], isDeletion: true });
    }
  });

  selection.editCell = {};
  selection.range = {};

  return stateChanges;
}

export function revertSelectionAdjustments(selection) {
  let stateChanges = [];

  const addresses = Object.keys(selection.range).map(fromAddress);
  addresses.forEach(address => {
    const rowKey = address.rowKey;
    const colKey = address.colKey;
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'hasCursor'], value: false });
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isSelected'], value: false });
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'isEditing'], value: false });
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'adjustment'], value: undefined });
    stateChanges.push({ path: ['table', 'data', rowKey, colKey, 'adjustmentIsDirty'], value: false });
    stateChanges.push({ path: ['adjustments', 'dirtyCellsMap', toAddress(rowKey, colKey)], isDeletion: true });
  });

  selection.editCell = {};
  selection.range = {};
  return stateChanges;
}

export function getAdjustmentsToSave(defaultTimeSeriesMeta, timeSeriesMetas, reportRequest, isHorizontal, tableData, addressLookup, dirtyCellsMap) {
  reportRequest.timeSeries.forEach(ts => {
    ts.adjustments = [];
  });

  if (isHorizontal) {
    Object.keys(dirtyCellsMap).forEach(key => {
      const address = fromAddress(key);
      const ts = reportRequest.timeSeries[address.rowKey];
      const cell = tableData[addressLookup.rows[address.rowKey]][address.colKey];
      if (cell && cell.adjustmentIsDirty) {
        const startDateTime = moment.utc(address.colKey).format();
        ts.adjustments.push({
          startDateTime,
          value: cell.adjustment,
          isDeletion: cell.adjustment === undefined
        })
      }
    });
  }
  else {
    Object.keys(dirtyCellsMap).forEach(key => {
      const address = fromAddress(key);
      const ts = reportRequest.timeSeries.find(t => t.key === address.colKey);
      const cell = tableData[addressLookup.rows[address.rowKey]][address.colKey];
      if (cell && cell.adjustmentIsDirty) {
        const startDateTime = moment.utc(tableData[addressLookup.rows[address.rowKey]].dateTime.value).format();
        ts.adjustments.push({
          startDateTime,
          value: cell.adjustment,
          isDeletion: cell.adjustment === undefined
        })
      }
    });
  }

  const adjustmentsRequest = reportRequest.timeSeries.filter(a => a.adjustments && a.adjustments.length > 0).map(ts => {
    const meta = timeSeriesMetas.find(m => m.key === ts.key);
    return {
      identityId: ts.id,
      key: ts.key,
      lens: ts.lens ?? reportRequest.lens,
      operation: ts.operation ?? reportRequest.operation,
      conversionUnit: ts.conversionUnit ?? reportRequest.conversionUnit,
      conversionFactor: ts.conversionFactor ?? reportRequest.conversionFactor,
      adjustments: ts.adjustments,
      annotation: (meta && meta.annotation) ? meta.annotation : defaultTimeSeriesMeta.annotation,
      adjustmentType: (meta && meta.adjustmentType) ? meta.adjustmentType : defaultTimeSeriesMeta.adjustmentType
    };
  }).filter(ts => ts);

  return adjustmentsRequest;
}

export function fromAddress(address) {
  const [rowKey, colKey] = address.split('|');
  return { rowKey, colKey };
}

export function toAddress(rowKey, colKey) {
  return `${rowKey}|${colKey}`;
}

export function getIsHorizontal(state) {
  return state.getIn(['workspace', 'tableSettings', 'displayData']) === 'horizontal';
}

export function getTableAddressRowsLookups(isHorizontal, data) {
  const rows = [];

  if (isHorizontal) {
    data.forEach((row, index) => {
      if (!row.title.isDisabled) {
        rows.push(index);
      }
    });
  } else {
    data.forEach((row, index) => {
      rows.push(index);
    });
  }

  return rows;
}

export function getTableAddressColumnsLookups(isHorizontal, headers) {
  const columns = [];

  if (isHorizontal) {
    headers.forEach((h) => {
      const key = h.key;
      if (key !== 'valueOf') {
        columns.push(key);
      }
    });
  } else {
    headers.forEach(h => {
      const key = h.key;
      const isDisabled = h.isDisabled;
      if (key !== 'valueOf' && !isDisabled) {
        columns.push(key);
      }
    });
  }

  return columns;
}

export function getTableAddressDatesLookups(isHorizontal, data, headers) {
  const dates = {};

  if (isHorizontal) {
    headers.forEach((h, index) => {
      const key = h.key;
      if (key !== 'valueOf') {
        dates[key] = index;
      }
    });
  } else {
    data.forEach((row, index) => {
      dates[row.dateTime.value] = index;
    });
  }

  return dates;
}

export function applyExistingAdjustments(headers, previousTableData, tableData) {
  const columnKeys = getColumnKeys(headers);
  previousTableData.forEach(previousRow => {
    const row = tableData.find(r => (r.dateTime && r.dateTime.key === previousRow.dateTime.key) || (r.key && r.key === previousRow.key));
    if (row) {
      columnKeys.forEach(colKey => {
        const previousCell = previousRow[colKey];
        const cell = row[colKey];
        if (previousCell && cell) {
          if (previousCell.adjustment !== undefined)
            cell.adjustment = previousCell.adjustment;

          if (previousCell.adjustmentDeletion !== undefined)
            cell.adjustmentDeletion = previousCell.adjustmentDeletion;

          if (previousCell.adjustmentIsDirty !== undefined)
            cell.adjustmentIsDirty = previousCell.adjustmentIsDirty;
        }
      });
    }
  });
}

function getColumnKeys(headers, fromKey, toKey) {
  let columnKeys = [];
  headers.forEach(h => {
    const key = h.get('key');
    const isDisabled = h.get('isDisabled');
    if (key !== 'valueOf' && !isDisabled) {
      columnKeys.push(key);
    }
  });

  if (fromKey && toKey) {
    let start = columnKeys.indexOf(fromKey);
    let end = columnKeys.indexOf(toKey);
    if (start > end) [start, end] = [end, start];
    columnKeys = columnKeys.slice(start, end + 1);
  }

  return columnKeys;
}

function reduceColumnsRange(addressLookup, fromKey, toKey) {
  const columns = [];
  let isInRange = false;
  addressLookup.columns.forEach(col => {
    if (!isInRange) {
      if (col === fromKey || col === toKey) {
        isInRange = true;
        columns.push(col);
      }
    } else {
      if (fromKey === toKey) {
        isInRange = false;
      }
      else if (col === fromKey || col === toKey) {
        columns.push(col);
        isInRange = false;
      } else {
        columns.push(col);
      }
    }
  });

  return columns;
}
