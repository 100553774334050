import { applyMiddleware, createStore } from 'redux';
import { combineReducers } from 'redux-immutable';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import getInitialState from './state';
import { sharedComponents } from './reducers/sharedComponents';
import { analysis } from './reducers/analysis';
import { analysisCrossSchemaSearch } from './reducers/analysisCrossSchemaSearch';
import { application } from './reducers/application';
import { outages } from './reducers/outages';
import { timeseriesSearch2 } from './reducers/timeseriesSearch2';
import { reports } from './reducers/reports';
import { dashboard } from './reducers/dashboard';
import { homepage } from './reducers/homepage';
import { events } from './reducers/events';
import { log } from './reducers/log';
import { userSettings } from './reducers/userSettings';
import { referenceData } from './reducers/referenceData';
import { timeSeries } from './reducers/time-series';
import { timeSeriesDetails } from './reducers/timeSeriesDetails';
import { adjustmentRangeEditor } from './reducers/adjustmentRangeEditor';
import { homepageNewItemModal } from './reducers/homepageNewItemModal';
import { workspaceModal } from './reducers/workspaceModal';
import { workspaceHitCount } from './reducers/workspaceHitCount';
import { etl } from './reducers/etl';
import { etlJob } from './reducers/etlJob';
import { etlJobHistory } from './reducers/etlJobHistory';
import { dashboardTiles } from './reducers/dashboard-tiles';
import { roles } from './reducers/roles';
import { schemas } from './reducers/schemas';
import { lngDeals } from './reducers/lngDeals';
import { storageAuctions } from './reducers/storageAuctions';
import { toolbox } from './reducers/toolbox';
import { instanceViewer } from './reducers/instanceViewer';
import { screenCapture } from './reducers/screenCapture';
import { actionsEditor } from './reducers/actionsEditor';
import { queueStatus } from './reducers/queueStatus';
import { historyViewer } from './reducers/historyViewer';
import { composeWithDevTools } from "@redux-devtools/extension";
import { createReduxHistoryContext } from "redux-first-history";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  selectRouterState: (state) => state.get('router')
});

let middleware = [
  routerMiddleware,
  thunk
];

if (process.env.NODE_ENV === 'development') {
  const logger = require('redux-logger');

  middleware.push(logger.createLogger({
    level: 'info',
    collapsed: true
  }));
}

const store = createStore(combineReducers({
  router: routerReducer,
  application,
  outages,
  sharedComponents,
  dashboard,
  homepage,
  analysis,
  analysisCrossSchemaSearch,
  reports,
  events,
  log,
  lngDeals,
  storageAuctions,
  userSettings,
  referenceData,
  homepageNewItemModal,
  workspaceModal,
  workspaceHitCount,
  timeseriesSearch2,
  timeSeries,
  timeSeriesDetails,
  adjustmentRangeEditor,
  etl,
  etlJob,
  etlJobHistory,
  dashboardTiles,
  roles,
  schemas,
  toolbox,
  instanceViewer,
  historyViewer,
  screenCapture,
  actionsEditor,
  queueStatus
}), 
getInitialState(), 
composeWithDevTools(applyMiddleware(...middleware)));

export const history = createReduxHistory(store);

export const getStore = () => store;