import React, { createRef } from 'react';
import { HotTable } from '@handsontable/react';
import { toJS } from '../../../../utility/immutable-utility';
import {
  registerCellType,
  DateCellType,
  NumericCellType,
  CheckboxCellType
} from 'handsontable/cellTypes';

import {
  registerPlugin,
  CopyPaste,
  Autofill
} from 'handsontable/plugins';

registerCellType(NumericCellType);
registerCellType(CheckboxCellType);
registerCellType(DateCellType);

registerPlugin(CopyPaste);
registerPlugin(Autofill);

export default class AdjustmentsTableEditor extends React.Component {
  static columns = [{
    data: 'formattedStartUtc',
    type: 'date',
    className: 'htRight',
    readOnly: true,
    colWidth: 200
  }, {
    data: 'formattedEndUtc',
    type: 'date',
    className: 'htRight',
    readOnly: true,
    colWidth: 200
  }, {
    data: 'value',
    type: 'numeric',
    readOnly: true,
    colWidth: 200,
    numericFormat: {
      pattern: '0,0.0000',
      culture: 'en-US'
    }
  }, {
    data: 'adjustedValue',
    type: 'numeric',
    readonly: false,
    colWidth: 200,
    numericFormat: {
      pattern: '0,0.0000',
      culture: 'en-US'
    }
  }, {
    data: 'isAdjustmentDeletion',
    type: 'checkbox',
    className: 'htCenter',
    readonly: false,
    colWidth: 70
  }, {
    data: 'isTemporary',
    type: 'checkbox',
    className: 'htCenter',
    readOnly: true,
    colWidth: 70
  }];

  constructor(props) {
    super(props);

    this.dataPointsTableRef = createRef();
  }

  handleBeforeChange(changes, source) {
    if (source === 'edit' || source === 'CopyPaste.paste' || source === 'Autofill.fill') {
      let newChanges = [];

      changes.forEach(change => {
        switch (change[1]) {
          case 'isAdjustmentDeletion':
            newChanges.push([change[0], 'adjustedValue', null, '']);
            break;
          case 'adjustedValue':
            newChanges.push([change[0], 'isAdjustmentDeletion', null, false]);
            break;
          default: break;
        }
      });

      changes.push(...newChanges);
    }
  }

  handleAfterChange(changes, source) {
    if (source === 'edit' || source === 'CopyPaste.paste' || source === 'Autofill.fill') {
      let data = this.dataPointsTableRef.current.hotInstance.getSourceData();
      this.props.onEdited(data, changes);
    }
  }

  render() {
    let granularity = toJS(this.props.timeseriesGranularity, {});
    let timezone = (this.props.timezone && `(${this.props.timezone})`) || '';
    let columnHeaders = [`Start Date ${timezone}`, `End Date ${timezone}`, 'Value', 'Adjusted', 'Deleted', 'Temp'];
    let columnsForType = this.props.isWizard ? AdjustmentsTableEditor.columns.slice(0) : AdjustmentsTableEditor.columns.slice(0, 3);
    let columnHeadsForType = this.props.isWizard ? columnHeaders.slice(0) : columnHeaders.slice(0, 3);

    if (granularity.granularityType !== 'Encoded') {
      columnsForType.splice(1, 1);
      columnHeadsForType.splice(1, 1);
    }

    let columnWidthsForType = columnsForType.map(i => i.colWidth);
    let tableWidth = columnWidthsForType.reduce((accumulator, value) => accumulator + value) + 40;

    return (
      <HotTable root='hot'
                ref={this.dataPointsTableRef}
                renderAllRows={true}
                data={this.props.dataPoints}
                colHeaders={columnHeadsForType}
                colWidths={columnWidthsForType}
                columns={columnsForType}
                rowHeaders={false}
                style={{ height: 'calc(100vh - 126px)', overflowX: 'hidden', width: `${tableWidth}px` }}
                beforeChange={this.handleBeforeChange.bind(this)}
                afterChange={this.handleAfterChange.bind(this)} />
            );
  }
}