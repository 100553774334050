import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const TIMESERIES_DETAILS_MATERIALISE_SET_RANGE_DIALOG_VISIBLE = 'TIMESERIES_DETAILS_MATERIALISE_SET_RANGE_DIALOG_VISIBLE';
export const timeSeriesDetailsMaterialiseSetRangeDialogVisible = createAction(TIMESERIES_DETAILS_MATERIALISE_SET_RANGE_DIALOG_VISIBLE, 'value');

export const TIMESERIES_DETAILS_MATERIALISE_SET_DATE = 'TIMESERIES_DETAILS_MATERIALISE_SET_DATE';
export const timeSeriesDetailsMaterialiseSetDate = createAction(TIMESERIES_DETAILS_MATERIALISE_SET_DATE, 'field', 'value');

export const TIMESERIES_DETAILS_MATERIALISE_SET_SUPPRESS_ARCHIVE = 'TIMESERIES_DETAILS_MATERIALISE_SET_SUPPRESS_ARCHIVE';
export const timeSeriesDetailsMaterialiseSetSuppressArchive = createAction(TIMESERIES_DETAILS_MATERIALISE_SET_SUPPRESS_ARCHIVE, 'value');

export const TIMESERIES_DETAILS_MATERIALISE_COMPLETE = 'TIMESERIES_DETAILS_MATERIALISE_COMPLETE';
export const timeSeriesDetailsMaterialiseComplete = createAction(TIMESERIES_DETAILS_MATERIALISE_COMPLETE);

export const timeSeriesDetailsSetMaterialiseStatusToEnabled = (callback) => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);  

  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/dependants/${identityId}/materialisationstatus/enabled`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(_ => {
    dispatch(logInfoNotification(`Set to enabled`));
    if (typeof callback === 'function') callback(identityId);
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const timeSeriesDetailsRematerialise = (callback) => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);
  const suppressArchive = state.getIn(['timeSeriesDetails', 'materialise', 'suppressArchive']);

  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/dependants/${identityId}/rematerialise?suppressArchive=${suppressArchive}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      if (data && data.length)
        dispatch(logInfoNotification(`Rematerialise requested for ${data.join(', ')}`));
      else
        dispatch(logInfoNotification(`No dependants to rematerialise`));

      dispatch(timeSeriesDetailsMaterialiseComplete())

      if (typeof callback === 'function') callback(identityId);
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const timeSeriesDetailsRematerialiseWithRange = (callback) => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries', 'id']);
  const fromUtc = state.getIn(['timeSeriesDetails', 'materialise', 'fromUtc']);
  const toUtc = state.getIn(['timeSeriesDetails', 'materialise', 'toUtc']);
  const suppressArchive = state.getIn(['timeSeriesDetails', 'materialise', 'suppressArchive']);
  const range = {
    firstDataPointUtc: fromUtc,
    lastDataPointUtc: toUtc
  };

  authFetch(`${ANALYSIS_API_ROOT_URL}/v2/dependants/${identityId}/rematerialise?suppressArchive=${suppressArchive}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(range)
  })
    .then(response => response.status !== 204 ? response.json() : {})
    .then(data => {
      if (data && data.length)
        dispatch(logInfoNotification(`Rematerialise requested for ${data.join(', ')}`));
      else
        dispatch(logInfoNotification(`No dependants to rematerialise`));

      dispatch(timeSeriesDetailsMaterialiseComplete())

      if (typeof callback === 'function') callback(identityId);
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};
