export function getToggleAllCheckStyle(list, isSelectedFunc, defaultValue = 'far fa-square', allSelectedValue = 'fas fa-check-square', partialValue = 'far fa-check-square') {
  const selectedCount = list.filter(isSelectedFunc).length;
  const totalCount = list.length;
  let selectionIndicator = defaultValue;
  if (selectedCount > 0) {
    selectionIndicator = selectedCount === totalCount ? allSelectedValue : partialValue = 'far fa-check-square';
  }

  return {selectedCount, totalCount, selectionIndicator};
}

export function toggleAll(list, isSelectedFunc, setFunc) {
  const isSelected = list.some(o => !isSelectedFunc(o));
  list.forEach(o => {
    setFunc(o, isSelected);
  });
}