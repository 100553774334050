import React, { useEffect, useState } from 'react';
import { DelayedExecution } from '../../shared/DelayedExecution';
import { TableClipboardToolbar } from './TableClipboardToolbar';

const delayedUpdateFontSize = new DelayedExecution();
const delayedUpdateRowSize = new DelayedExecution();
const delayedUpdateDateFormat = new DelayedExecution();
const delayedUpdateDecimalPlaces = new DelayedExecution();

export function TableDesignPanel({ isHorizontal, headerType, hideDate, fontSize, rowSize, dateFormat, decimalPlaces,
  forceReMount,
  updateProperty, updateHeaderWidths, updateFontSize, updateHideDate, updateRowSize, updateTitle, updateDateFormat, updateDecimalPlaces }) {
  const [localFontSize = '', setLocalFontSize] = useState(fontSize ?? 11);
  const [localRowSize, setLocalRowSize] = useState(rowSize ?? 20);
  const [localColumnSize, setLocalColumnSize] = useState('');
  const [localDateFormat, setLocalDateFormat] = useState(dateFormat);
  const [localDecimalPlaces, setLocalDecimalPlaces] = useState(decimalPlaces);

  useEffect(() => setLocalFontSize(fontSize), [fontSize]);

  function onUpdateTitle(value) {
    updateTitle(value);
  }

  function onUpdateFontSize(e) {
    setLocalFontSize(e.target.value);
    delayedUpdateFontSize.execute(updateFontSize, Number(e.target.value));
  }

  function onUpdateRowSize(e) {
    setLocalRowSize(e.target.value);
    delayedUpdateRowSize.execute(updateRowSize, Number(e.target.value));
  }

  function onUpdateOrientation(value) {
    updateProperty('displayData', value);
  }

  function onUpdateHideDate(value) {
    updateHideDate(value);
  }

  function onApplyColumnSize() {
    if (localColumnSize)
      updateHeaderWidths(Number(localColumnSize));
  }

  function onKeyDownColumnSize(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      onApplyColumnSize();
    }
  }

  // dateFormat, decimalPlaces
  function onUpdateDateFormat(e) {
    setLocalDateFormat(e.target.value);
    delayedUpdateDateFormat.execute(updateDateFormat, e.target.value ? e.target.value : undefined);
  }

  function onUpdateDecimalPlaces(e) {
    setLocalDecimalPlaces(e.target.value);
    delayedUpdateDecimalPlaces.execute(updateDecimalPlaces, e.target.value ? e.target.value : undefined);
  }

  return (
    <div className='analysis-table-panel'>
      <div className='w-100 d-flex justify-content-between align-items-center pb-2'>
        <div className='btn-group btn-group-sm ' >
          <TableClipboardToolbar/>
        </div>

        <div className='btn-group btn-group-sm ' >
          <button title='Force Redraw' className='btn btn-sm btn-outline-primary text-nowrap' type='button' onClick={forceReMount}>
            <i className='far fa-eye fa-fw mr-1' />
            <span>Force Redraw</span>
          </button>
        </div>
      </div>

      <div className='w-100' style={{ height: 'calc(100% - 35px)', overflowY: 'auto' }}>
        <div className='row no-gutters mb-2'>
          <label className='col-sm-4 font-weight-bold'>Header Type</label><br />
          <div className='col-sm-8'>
            <div className='custom-control custom-radio custom-control-inline'>
              <input type='radio' id='chart-data-header-type-id' name='chart-data-header-type' className='custom-control-input' value='id'
                checked={!headerType || headerType === 'id'} onChange={e => onUpdateTitle(e.target.value)} />
              <label className='custom-control-label' htmlFor='chart-data-header-type-id'>Id</label>
            </div>
            <div className='custom-control custom-radio custom-control-inline'>
              <input type='radio' id='chart-data-header-type-name' name='chart-data-header-type' className='custom-control-input' value='name'
                checked={headerType === 'name'} onChange={e => onUpdateTitle(e.target.value)} />
              <label className='custom-control-label' htmlFor='chart-data-header-type-name'>Name</label>
            </div>
            <div className='custom-control custom-radio custom-control-inline'>
              <input type='radio' id='chart-data-header-type-both' name='chart-data-header-type' className='custom-control-input' value='both'
                checked={headerType === 'both'} onChange={e => onUpdateTitle(e.target.value)} />
              <label className='custom-control-label' htmlFor='chart-data-header-type-both'>Both</label>
            </div>
          </div>
        </div>

        <div className='row no-gutters mb-2'>
          <label className='col-sm-4 font-weight-bold'>Display Data</label><br />
          <div className='col-sm-8'>
            <div className='custom-control custom-radio custom-control-inline'>
              <input type='radio' id='chart-data-orientation-standard' name='chart-data-orientation' className='custom-control-input' value='standard'
                checked={!isHorizontal} onChange={e => onUpdateOrientation('vertical')} />
              <label className='custom-control-label' htmlFor='chart-data-orientation-standard'>Vertically</label>
            </div>
            <div className='custom-control custom-radio custom-control-inline'>
              <input type='radio' id='chart-data-orientation-pivoted' name='chart-data-orientation' className='custom-control-input' value='pivoted'
                checked={isHorizontal} onChange={e => onUpdateOrientation('horizontal')} />
              <label className='custom-control-label' htmlFor='chart-data-orientation-pivoted'>Horizontally</label>
            </div>
          </div>
        </div>

        <div className='row no-gutters mb-2'>
          <div className='col-sm-4' />
          <div className='col-sm-8'>
            <div className='custom-control custom-checkbox'>
              <input type='checkbox' id='chart-data-hide-date' className='custom-control-input'
                checked={hideDate} onChange={e => onUpdateHideDate(e.target.checked)} />
              <label className='custom-control-label' htmlFor='chart-data-hide-date'>Hide Date</label>
            </div>
          </div>
        </div>

        <div className='row no-gutters mb-2'>
          <label className='col-sm-4 font-weight-bold'>Font Size</label>
          <input type='number' className='col-sm-8 form-control form-control-sm rounded-right'
            value={localFontSize} onChange={onUpdateFontSize} />
        </div>

        <div className='row no-gutters mb-2'>
          <label className='col-sm-4 font-weight-bold'>Row Height</label>
          <input type='number' className='col-sm-8 form-control form-control-sm rounded-right'
            value={localRowSize} onChange={onUpdateRowSize} />
        </div>

        <div className='row no-gutters mb-2'>
          <label className='col-sm-4 font-weight-bold'>Column Width</label>
          <div className='col-sm-8 input-group m-0 p-0'>
            <input type='number' className='form-control form-control-sm rounded-right'
              value={localColumnSize} onChange={e => setLocalColumnSize(e.target.value)}
              onKeyDown={onKeyDownColumnSize} />
            <div className='input-group-append'>
              <button className='btn btn-sm btn-primary' type='button' onClick={onApplyColumnSize}>
                <span className='d-none d-md-inline-block'>Apply</span>
              </button>
            </div>
          </div>
        </div>

        <div className='row no-gutters mb-2'>
          <label className='col-sm-4 font-weight-bold'>Date format</label>
          <input className='col-sm-8 form-control form-control-sm rounded-right' placeholder='eg: ddd DD MMM - y'
            value={localDateFormat} onChange={onUpdateDateFormat} />
        </div>

        <div className='row no-gutters mb-2'>
          <label className='col-sm-4 font-weight-bold'>Decimal places</label>
          <input type='number' className='col-sm-8 form-control form-control-sm rounded-right'
            value={localDecimalPlaces} onChange={onUpdateDecimalPlaces} />
        </div>
      </div>
    </div >
  );
}