import React from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router';
import InlineDataPointsActionEdit from './InlineDataPointsActionEdit';
import ScheduledActionEdit from './ScheduledActionEdit';
import TriggeredActionEdit from './TriggeredActionEdit';
import { ActionsEditorConnected } from './ActionsEditorConnected';

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const Actions = connect(
  mapStateToProps,
  mapDispatchToProps
)(() => {
  return <main className='d-flex flex-column'>
    <Routes>
      <Route path='/inline/:id/edit' element={<InlineDataPointsActionEdit/>} />
      <Route path='/inline/new' element={<InlineDataPointsActionEdit/>} />

      <Route path='/scheduled/:id/edit' element={<ScheduledActionEdit/>} />
      <Route path='/scheduled/new' element={<ScheduledActionEdit/>} />
      
      <Route path='/triggered/:id/edit' element={<TriggeredActionEdit/>} />
      <Route path='/triggered/new' element={<TriggeredActionEdit/>} />

      <Route exact path='/' element={<ActionsEditorConnected/>} />
    </Routes>
  </main>;
});

export default Actions;