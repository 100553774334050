import React from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router';
import DatasetsList from './DataSetsList';
import DatasetEdit from './DataSetEdit';

const Datasets = connect()(() => {
  return <main className='d-flex flex-column'>
    <Routes>
      <Route path='/:id' element={<DatasetEdit />} />
      <Route exact path='/' element={<DatasetsList />} />
    </Routes>
  </main>;
});

export default Datasets;