import React from 'react';
import { connect } from 'react-redux';
import {
  reportsTableToggleAdjustmentPanel
} from '../../../actions/reports';

export const AdjustmentsSidebar = connect(
  (state) => ({
    isWizard: state.getIn(['roles', 'isWizard']),
    isEditing: state.getIn(['reports', 'adjustments', 'isEditing']),
    isAdjustmentsPanelVisible: state.getIn(['reports', 'adjustments', 'isAdjustmentsPanelVisible']),
    validationMessages: state.getIn(['reports', 'adjustments', 'validation', 'messages'])
  }),
  (dispatch) => ({
    toggleAdjustmentPanel() {
      dispatch(reportsTableToggleAdjustmentPanel());
    }
  })
)(({ isEditing, isWizard, isAdjustmentsPanelVisible, toggleAdjustmentPanel,
  validationMessages }) => {
  if (!isEditing || !isWizard) return <></>
  const hasValidationMessages = validationMessages.size > 0;

  return <div className='list-group'>
    <button title='toggle adjustments panel' type='button' className={`list-group-item list-group-item-action ${isAdjustmentsPanelVisible ? 'active' : ''}`} onClick={toggleAdjustmentPanel} >
      <i className="fas fa-sliders-h fa-fw"></i>
      {hasValidationMessages && <i className="fas fa-info-circle fa-fas text-danger" style={{position: 'absolute', top: '5px', left: '5px'}}></i>}
    </button>
  </div>
});