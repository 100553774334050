import React from 'react';
import { connect } from 'react-redux';
import { analysisAddInstance } from '../../../../actions/analysis';
import {
  archiveInstance,
  loadInstances,
  loadInstanceDataPoints,
  promoteInstance,
  instancesSetPromotedAsAtUtcFunction,
  instancesSetPromoteVisible,
  instancesSetToolbarProperty,
  instancesSetPage,
  instancesToggleSelection
} from '../../../../actions/instanceViewer';
import InstanceViewer from './InstanceViewer';

const InstanceViewerConnected = connect(
  (state) => ({
    dataPointUtc: state.getIn(['instanceViewer', 'toolbar', 'dataPointUtc']),
    fromUtc: state.getIn(['instanceViewer', 'toolbar', 'fromUtc']),
    identityId: state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']),
    instanceRows: state.getIn(['instanceViewer', 'instances', 'rows']),
    instanceDataPointRows: state.getIn(['instanceViewer', 'selectedInstanceDataPoints']),
    instancePromoteAsAtUtc: state.getIn(['instanceViewer', 'instancePromoteAsAtUtc']),
    instancePromoteDialogVisible: state.getIn(['instanceViewer', 'instancePromoteDialogVisible']),
    instanceTotal: state.getIn(['instanceViewer', 'instances', 'total']),
    isLoading: state.getIn(['instanceViewer', 'isLoading']),
    isWizard: state.getIn(['roles', 'isWizard']),
    page: state.getIn(['instanceViewer', 'instances', 'page']),
    pageSize: state.getIn(['instanceViewer', 'instances', 'pageSize']),
    selectedInstance: state.getIn(['instanceViewer', 'selectedInstance']),    
    toUtc: state.getIn(['instanceViewer', 'toolbar', 'toUtc']),    
  }),
  (dispatch) => ({
    addToBasketFunction() {
      dispatch(analysisAddInstance());
    },
    archiveInstanceFunction() {
      dispatch(archiveInstance());
    },
    closeInstancePromoteDialogFunction() {
      dispatch(instancesSetPromoteVisible(false));
    },
    openInstancePromoteDialogFunction() {
      dispatch(instancesSetPromoteVisible(true));
    },
    loadInstancesFunction() {
      dispatch(loadInstances());
    },
    refreshFunction() {
      dispatch(loadInstances());
    },
    savePromotedInstanceFunction() {
      dispatch(promoteInstance());
    },
    setPromotedAsAtUtcFunction(value) {
      dispatch(instancesSetPromotedAsAtUtcFunction(value));
    },
    setToolbarPropertyFunction(name, value) {
      dispatch(instancesSetToolbarProperty(name, value));
    },
    toggleSelectionFunction(instance) {
      dispatch(instancesToggleSelection(instance));
      dispatch(loadInstanceDataPoints());
    },
    updatePageFunction(page) {
      dispatch(instancesSetPage(page));
      dispatch(loadInstances());
    }
  })
)(({ identityId, isLoading, instanceRows, instanceDataPointRows, instanceTotal, isWizard, 
  fromUtc, page, pageSize, toUtc, dataPointUtc, selectedInstance, instancePromoteDialogVisible, instancePromoteAsAtUtc,
  addToBasketFunction, archiveInstanceFunction, closeInstancePromoteDialogFunction, openInstancePromoteDialogFunction, loadInstancesFunction, refreshFunction, 
  savePromotedInstanceFunction, setPromotedAsAtUtcFunction, setToolbarPropertyFunction, toggleSelectionFunction, updatePageFunction }) => {

  return <InstanceViewer identityId={identityId}
                         isLoading={isLoading}
                         isWizard={isWizard}
                         instanceDataPointRows={instanceDataPointRows}
                         instanceRows={instanceRows} 
                         instancePromoteAsAtUtc={instancePromoteAsAtUtc}
                         instancePromoteDialogVisible={instancePromoteDialogVisible}
                         instanceTotal={instanceTotal}
                         fromUtc={fromUtc}
                         page={page}
                         pageSize={pageSize}
                         toUtc={toUtc}
                         dataPointUtc={dataPointUtc}
                         selectedInstance={selectedInstance}
                         addToBasketFunction={addToBasketFunction}
                         archiveInstanceFunction={archiveInstanceFunction}
                         closeInstancePromoteDialogFunction={closeInstancePromoteDialogFunction}
                         openInstancePromoteDialogFunction={openInstancePromoteDialogFunction}
                         loadInstancesFunction={loadInstancesFunction} 
                         refreshFunction={refreshFunction}                      
                         savePromotedInstanceFunction={savePromotedInstanceFunction}   
                         setPromotedAsAtUtcFunction={setPromotedAsAtUtcFunction}
                         setToolbarPropertyFunction={setToolbarPropertyFunction}                         
                         toggleSelectionFunction={toggleSelectionFunction}
                         updatePageFunction={updatePageFunction} />;
});

export default InstanceViewerConnected;