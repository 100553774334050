import React from 'react';
import { connect } from 'react-redux';
import {
  loadHistory,
  historyToggleSelection,
  revertFromHistory
} from '../../../../actions/historyViewer';
import HistoryViewer from './HistoryViewer';

const HistoryViewerConnected = connect(
  (state) => ({
    identityId: state.getIn(['timeSeriesDetails', 'currentTimeSeriesId']),
    isItemSelected: state.getIn(['historyViewer', 'isItemSelected']),
    isLoading: state.getIn(['historyViewer', 'isLoading']),
    selectedIdentityVersion: state.getIn(['historyViewer', 'selectedIdentityVersion']),    
    rows: state.getIn(['historyViewer', 'rows']),
  }),
  (dispatch) => ({
    loadHistoryFunction() {
      dispatch(loadHistory());
    },
    refreshFunction() {
      dispatch(loadHistory());
    },
    revertFunction() {
      dispatch(revertFromHistory());
      dispatch(loadHistory());
    },
    toggleSelectionFunction(row) {
      dispatch(historyToggleSelection(row));
    }
  })
)(({ identityId, isItemSelected, isLoading, rows, selectedIdentityVersion, 
      loadHistoryFunction, refreshFunction, revertFunction, toggleSelectionFunction }) => {

  let _selectedIdentityVersion = selectedIdentityVersion ? selectedIdentityVersion.toJS() : null;

  return <HistoryViewer identityId={identityId}
                        isItemSelected={isItemSelected}
                        isLoading={isLoading}
                        rows={rows}
                        selectedIdentityVersion={_selectedIdentityVersion}                                                
                        loadHistoryFunction={loadHistoryFunction} 
                        refreshFunction={refreshFunction}
                        revertFunction={revertFunction}
                        toggleSelectionFunction={toggleSelectionFunction} />;
});

export default HistoryViewerConnected;