import { push } from "redux-first-history";
import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import { getOnDemandDisplayMapKeys } from '../utility/reportstable-utility';
import { copyToClipboard, getClipboardTextAsync, mapCSVToArray } from '../utility/text-utility';
import { buildAdjustmentsRequest } from '../reducer-functions/reports';
import { authFetch } from '../auth';
import { saveDefinedReportsAdjustmentsAsync, validateDefinedReportsAdjustmentsAsync } from './adjustments';
import { ANALYSIS_API_URL, ANALYSIS_REPORT_API_ROOT_URL, REPORTING_API_ROOT_URL, SNAPSHOT_API_ROOT_URL, WORKSPACES_API_URL } from '../config';
import { saveAs } from '../utility/file-utility';
import moment from 'moment';
import qs from 'querystring';
import { mapScenarioOverrides, hasLensChanged, buildGetReportsNodesRequest, displayKeyParseRoot, reduceScenarios } from '../mapper/reportMapper';
import {
  logInfoNotification,
  logErrorNotification,
  logWarningNotification
} from './log';
import {
  mapTableSelectionToCSV,
  mapSelectionToGrid
} from '../reducer-functions/reports-clipboard-mapper';
import { workspaceUpdateHitCount } from './workspace';

export const REPORTS_SHOW_OVERLAY = 'REPORTS_SHOW_OVERLAY';
export const reportsShowOverlay = createAction(REPORTS_SHOW_OVERLAY);

export const REPORTS_HIDE_OVERLAY = 'REPORTS_HIDE_OVERLAY';
export const reportsHideOverlay = createAction(REPORTS_HIDE_OVERLAY);

export const reportsToggleOnDemand = (key, nodeLevel, displayKey, callback) => (dispatch, getState) => {
  dispatch(reportsToggleOnDemandBegin(key, nodeLevel, displayKey));
  
  const state = getState();

  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']),
        lens = state.getIn(['reports', 'criteria', 'lens']),
        lastCriteriaLens = state.getIn(['reports', 'lastCriteria', 'lens']),
        fromDate = state.getIn(['reports', 'criteria', 'fromDate']),
        toDate = state.getIn(['reports', 'criteria', 'toDate']),
        timeZoneId = state.getIn(['reports', 'criteria', 'timeZoneId']),
        asAt = state.getIn(['reports', 'criteria', 'asAt']),
        relativeAsAt = state.getIn(['reports', 'criteria', 'relativeAsAt']),
        mode = state.getIn(['reports', 'criteria', 'mode']),
        snapshotAsAt = state.getIn(['reports', 'criteria', 'snapshotAsAt']),
        snapshotRelativeDate = state.getIn(['reports', 'criteria', 'snapshotRelativeDate']);

  const groupings = state.getIn(['reports', 'criteria', 'groupings']).toJS();
  const filters = state.getIn(['reports', 'criteria', 'filters']).toJS();
  const displayMap = state.getIn(['reports', 'results', 'displayMap']).toJS();
  const columns = state.getIn(['reports', 'results', 'columns']).toJS();
  const rows = state.getIn(['reports', 'results', 'rows']).toJS();
  const scenarioOverrides = reduceScenarios(state.getIn(['reports', 'results', 'scenarioOverrideMap']).toJS());
  const scenarioOverridesMapped = mapScenarioOverrides(scenarioOverrides, displayMap, rows, columns, !hasLensChanged(lastCriteriaLens, lens));
 
  const request = buildGetReportsNodesRequest(
    key, 
    displayKeyParseRoot(displayKey),
    reportPath,
    lens,
    fromDate,
    toDate,
    timeZoneId,
    asAt,
    mode,
    relativeAsAt,
    snapshotAsAt,
    snapshotRelativeDate,
    groupings,
    filters,
    nodeLevel,
    scenarioOverridesMapped);

  dispatch(reportsSetDisplayMode(key, nodeLevel, displayKey, 'Loading'));

  authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports/nodes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(reportsToggleOnDemandComplete(key, data, displayKey));
      dispatch(reportsSetDisplayMode(key, nodeLevel, displayKey, 'Open'));

      if (typeof callback === 'function') callback();
    })
    .catch(error => {
      dispatch(reportsSetDisplayMode(key, nodeLevel, displayKey, 'OnDemand'));
      dispatch(logErrorNotification(error));
    });
};

export const REPORTS_TOGGLE_ONDEMAND_BEGIN = 'REPORTS_TOGGLE_ONDEMAND_BEGIN';
const reportsToggleOnDemandBegin = createAction(REPORTS_TOGGLE_ONDEMAND_BEGIN, 'key', 'data', 'displayKey');

export const REPORTS_TOGGLE_ONDEMAND_COMPLETE = 'REPORTS_TOGGLE_ONDEMAND_COMPLETE';
const reportsToggleOnDemandComplete = createAction(REPORTS_TOGGLE_ONDEMAND_COMPLETE, 'key', 'data', 'displayKey');

export const REPORTS_SET_DISPLAY_MODE = 'REPORTS_SET_DISPLAY_MODE';
export const reportsSetDisplayMode = createAction(REPORTS_SET_DISPLAY_MODE, 'key', 'depth', 'displayKey', 'displayMode');

export const REPORTS_SET_ALL_SCENARIO_OVERRIDE = 'REPORTS_SET_ALL_SCENARIO_OVERRIDE';
export const reportsSetAllScenarioOverride = createAction(REPORTS_SET_ALL_SCENARIO_OVERRIDE, 'value');

export const REPORTS_SET_SCENARIO_OVERRIDE = 'REPORTS_SET_SCENARIO_OVERRIDE';
export const reportsSetScenarioOverride = createAction(REPORTS_SET_SCENARIO_OVERRIDE, 'key', 'value');

export const REPORTS_SET_SCENARIO_WIDTH = 'REPORTS_SET_SCENARIO_WIDTH';
export const reportsSetScenarioWidth = createAction(REPORTS_SET_SCENARIO_WIDTH, 'value');

export const REPORTS_TOGGLE_ANNOTATION_EXPAND = 'REPORTS_TOGGLE_ANNOTATION_EXPAND';
export const reportsToggleAnnotationExpand = createAction(REPORTS_TOGGLE_ANNOTATION_EXPAND, 'sectionKey');

export const REPORTS_TOGGLE_EXPAND = 'REPORTS_TOGGLE_EXPAND';
export const reportsToggleExpand = createAction(REPORTS_TOGGLE_EXPAND, 'key', 'depth', 'displayKey');

export const REPORTS_TOGGLE_EXPAND_TIER = 'REPORTS_TOGGLE_EXPAND_TIER';
export const reportsToggleExpandTier = createAction(REPORTS_TOGGLE_EXPAND_TIER, 'tier', 'value');

export const REPORTS_CHART_REFRESH_STARTED = 'REPORTS_CHART_REFRESH_STARTED';
export const reportsChartRefreshStarted = createAction(REPORTS_CHART_REFRESH_STARTED);

export const REPORTS_CHART_SET_LENS = 'REPORTS_CHART_SET_LENS';
export const reportsChartSetLens = createAction(REPORTS_CHART_SET_LENS, 'lens');

export const REPORTS_CHART_SET_SELECTED = 'REPORTS_CHART_SET_SELECTED';
export const reportsChartSetSelected = createAction(REPORTS_CHART_SET_SELECTED, 'timeSeriesIds');

export const REPORTS_CHART_SET_SELECTED_SCENARIOS = 'REPORTS_CHART_SET_SELECTED_SCENARIOS';
export const reportsChartSetSelectedScenarios = createAction(REPORTS_CHART_SET_SELECTED_SCENARIOS, 'selectedScenarios');

export const reportLaunchAnalysis = () => (dispatch, getState) => {
  const state = getState();
  const localLens = state.getIn(['reports', 'chart', 'lens']);
  const defaultLens = state.getIn(['reports', 'criteria', 'lens']);
  const keys = toJS(state.getIn(['reports', 'chart', 'analysisLaunchKeys']));

  const params = {
    lens: localLens ? localLens : defaultLens,
    fromUtc: state.getIn(['reports', 'criteria', 'fromDate']),
    toUtc: state.getIn(['reports', 'criteria', 'toDate']),
    timeZoneId: state.getIn(['reports', 'criteria', 'timeZoneId'])
    // re-introduce the following to include pass from ui to URL
    // conversionUnit: state.getIn(['reports', 'results', 'criteria', 'conversionUnit']),
    // operation: state.getIn(['reports', 'results', 'criteria', 'operation']),
    // variantMode: state.getIn(['reports', 'results', 'criteria', 'variantMode']),
    // materialisationMode: state.getIn(['reports', 'results', 'criteria', 'materialisationMode'])
  };

  var mode = state.getIn(['reports', 'criteria', 'mode']);
  if (mode === 'AsAt' || mode === 'AsAtDelta') {
    params.asAtUtc = state.getIn(['reports', 'criteria', 'asAt']);
  }

  if (keys && keys.length > 0) {
    const timeSeriesKeys = keys.map((key) => 'key=' + encodeURIComponent(key));
    const searchArgs = Object.keys(params).map(k =>  ({k, v: params[k]})).filter(kv => kv.v).map(kv => encodeURIComponent(kv.k) + '=' + encodeURIComponent(kv.v));
    const url = `/analysis/chart?${timeSeriesKeys.join('&')}&${searchArgs.join('&')}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  }
}

export const reportsChartRefresh = ({ timeSeriesIds, parentKeys, options = {} }) => (dispatch, getState) => {
  dispatch(reportsChartRefreshStarted());

  const state = getState(); 

  const selectedScenarios = state.getIn(['reports', 'chart', 'selectedScenarios']).toJS();

  const lens = state.getIn(['reports', 'criteria', 'lens']),
        fromDate = state.getIn(['reports', 'criteria', 'fromDate']),
        toDate = state.getIn(['reports', 'criteria', 'toDate']),
        timeZoneId = state.getIn(['reports', 'criteria', 'timeZoneId']),
        selectedIds = toJS(state.getIn(['reports', 'chart', 'selectedIds']));

  // we can take data directly from the report if we're using the same lens and there are no scenarios selected
  const useLocalData = selectedScenarios.length === 0 && (!options.lens || lens === options.lens);
  if (!timeSeriesIds || !timeSeriesIds.length || useLocalData) {
    dispatch(reportsChartRefreshComplete({
      timeSeries: [],
      rows: [],
      parentKeys: parentKeys ? parentKeys : toJS(state.getIn(['reports', 'chart', 'localParentKeys']), []),
      lens
    }));
    return;
  }

  const ids = selectedIds.filter(id => id > 0);
  const timeSeries = ids.map(id => {
                      return { id: id,key: id};
                     });

  // request a potential scenario override for all ts
  // we will only show overriden, this will be checked in the reducer
  if(selectedScenarios.length > 0) {
    for (let i = 0; i < selectedScenarios.length; i++) {
      const scenario = selectedScenarios[i];
      for (let j = 0; j < ids.length; j++) {
        const id = ids[j];
        timeSeries.push({ id: id, key: `${id}|${scenario}`, scenario: scenario });
      }
    }
  }

  const criteria = {
    timeSeries: timeSeries,
    window: {
      absFromDate: moment.utc(fromDate).format(),
      absToDate: moment.utc(toDate).format()
    },
    timeZoneId: timeZoneId,
    lens: lens,
    includeTimeseries: true,
    ...options
  };

  var mode = state.getIn(['reports', 'criteria', 'mode']);
  if (mode === 'AsAt' || mode === 'AsAtDelta') {
    criteria.asAt = state.getIn(['reports', 'criteria', 'asAt']);
  }

  authFetch(`${ANALYSIS_REPORT_API_ROOT_URL}/v5/timeseries-report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(criteria)
  })
  .then(response => response.json())
  .then(data => {
    const { timeSeries = [] } = data;

    timeSeries.filter(i => i.error && i.error.length).forEach(i => {
      i.error.forEach(error => dispatch(logErrorNotification(`${i.key}: ${error}`)));
    });

    return { ...data, parentKeys, lens };
  })
  .then(data => {
    dispatch(reportsChartRefreshComplete(data));
  })
  .catch(error => {
    dispatch(reportsChartRefreshComplete());
    dispatch(logErrorNotification(error));
  });
};

export const REPORTS_CHART_REFRESH_COMPLETE = 'REPORTS_CHART_REFRESH_COMPLETE';
export const reportsChartRefreshComplete = createAction(REPORTS_CHART_REFRESH_COMPLETE, 'data');

export const REPORTS_WORKSPACE_UPDATE = 'REPORTS_WORKSPACE_UPDATE';
export const reportsWorkspaceUpdate = createAction(REPORTS_WORKSPACE_UPDATE, 'data');

export const REPORTS_WORKSPACE_CREATE_STARTED = 'REPORTS_WORKSPACE_CREATE_STARTED';
export const reportsWorkspaceCreateStarted = createAction(REPORTS_WORKSPACE_CREATE_STARTED);

export const reportsWorkspaceCreate = (_id, reportPath) => (dispatch, getState) => {
  const state = getState();

  const workspace = state.getIn(['reports', 'workspace']).toJS();

  const params = {
    ...workspace, reportPath
  };

  dispatch(reportsWorkspaceCreateStarted());

  authFetch(`${REPORTING_API_ROOT_URL}/v1/timeseries-reports-from-composition/definition?${qs.stringify(params)}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(() => {
    dispatch(reportsWorkspaceCreateComplete());
    dispatch(logInfoNotification('Report definition created'));
  })
  .catch(error => {
    dispatch(reportsWorkspaceCreateComplete());
    dispatch(logErrorNotification(error));
  });
};

export const REPORTS_WORKSPACE_CREATE_COMPLETE = 'REPORTS_WORKSPACE_CREATE_COMPLETE';
export const reportsWorkspaceCreateComplete = createAction(REPORTS_WORKSPACE_CREATE_COMPLETE, 'data');

export const reportsWorkspaceLoad = (key, url) => (dispatch, getState) => {
  authFetch(`${ANALYSIS_API_URL}/workspaces/${key}`)
    .then(response => response.json())
    .then(data => {
      dispatch(reportsWorkspaceLoadComplete(key, data));

      if (url) dispatch(push(url));
    })
    .catch(error => {
      dispatch(reportsWorkspaceLoadComplete(key));
      dispatch(logErrorNotification(error));
    });
};

export const reportsWorkspacePathLoad = (path, url) => (dispatch, getState) => {
  if (!path) return;

  authFetch(`${WORKSPACES_API_URL}/workspaces${path.startsWith('/') ? path : `/${path}`}`)
    .then(response => response.json())
    .then(data => {
      dispatch(reportsWorkspaceLoadComplete(data.id, data));

      if (url) dispatch(push(url));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const REPORTS_WORKSPACE_LOAD_COMPLETE = 'REPORTS_WORKSPACE_LOAD_COMPLETE';
export const reportsWorkspaceLoadComplete = createAction(REPORTS_WORKSPACE_LOAD_COMPLETE, 'key', 'data');

export const reportsWorkspaceCopy = (key, path, scope, callback) => (dispatch, getState) => {
  authFetch(`${WORKSPACES_API_URL}/workspaces/${key}/copy?path=${path}${scope ? `&scope=${scope}` : ''}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      dispatch(logInfoNotification(`Report '${path}' saved`));

      if (typeof callback === 'function') callback(data);
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const reportsUpdate = (data, callback) => (dispatch, getState) => {
  const state = getState();

  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']);

  authFetch(`${REPORTING_API_ROOT_URL}/v1/timeseries-defined-reports/definition?reportPath=${reportPath}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(() => {
    dispatch(logInfoNotification('Report definition updated'));

    if (typeof callback === 'function') callback();
  })
  .catch(error => {
    dispatch(logErrorNotification(error));
  });
};

export const REPORTS_RELOAD_STARTED = 'REPORTS_RELOAD_STARTED';
export const reportsReloadStarted = createAction(REPORTS_RELOAD_STARTED);

export const reportsInitialiseSettings = callback => (dispatch, getState) => {
  const state = getState();
  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']);
  const lens = state.getIn(['reports', 'criteria', 'lens']);
  const fromDate = state.getIn(['reports', 'criteria', 'fromDate']);
  const toDate = state.getIn(['reports', 'criteria', 'toDate']);
  const timeZoneId = state.getIn(['reports', 'criteria', 'timeZoneId']);

  const request = {
    reportPath: reportPath,
    lens: lens,
    fromDate: fromDate,
    toDate: toDate,
    timeZoneId: timeZoneId
  };
  
  dispatch(reportsInitialiseSettingsStarted());

  authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports/initialise`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(workspaceUpdateHitCount(data.id, 'defined-report'));
      dispatch(reportsInitialiseSettingsComplete(data));
      if (typeof callback === 'function') callback();
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
}

export const REPORTS_INITIALISE_SETTINGS_STARTED = 'REPORTS_INITIALISE_SETTINGS_STARTED';
export const reportsInitialiseSettingsStarted = createAction(REPORTS_INITIALISE_SETTINGS_STARTED, 'isLoading');

export const REPORTS_INITIALISE_SETTINGS_COMPLETE = 'REPORTS_INITIALISE_SETTINGS_COMPLETE';
export const reportsInitialiseSettingsComplete = createAction(REPORTS_INITIALISE_SETTINGS_COMPLETE, 'data');

export const reportsReload = callback => (dispatch, getState) => {
  const state = getState();

  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']);
  const lens = state.getIn(['reports', 'criteria', 'lens']);
  const lastCriteriaLens = state.getIn(['reports', 'lastCriteria', 'lens']);
  const fromDate = state.getIn(['reports', 'criteria', 'fromDate']);
  const toDate = state.getIn(['reports', 'criteria', 'toDate']);
  const timeZoneId = state.getIn(['reports', 'criteria', 'timeZoneId']);
  const asAt = state.getIn(['reports', 'criteria', 'asAt']);
  const mode = state.getIn(['reports', 'criteria', 'mode']);
  const relativeAsAt = state.getIn(['reports', 'criteria', 'relativeAsAt']);
  const snapshotAsAt = state.getIn(['reports', 'criteria', 'snapshotAsAt']);
  const snapshotRelativeDate = state.getIn(['reports', 'criteria', 'snapshotRelativeDate']);
  const shapeName = state.getIn(['reports', 'criteria', 'shapeName']);
  const conversionUnit = state.getIn(['reports', 'criteria', 'conversionUnit']);
  const scenarioOverrides = reduceScenarios(state.getIn(['reports', 'results', 'scenarioOverrideMap']).toJS());
  const displayMap = state.getIn(['reports', 'results', 'displayMap']).toJS();
  const rows = state.getIn(['reports', 'results', 'rows']).toJS();
  const columns = state.getIn(['reports', 'results', 'columns']).toJS();
  const groupings = state.getIn(['reports', 'criteria', 'groupings']).toJS();
  const filters = state.getIn(['reports', 'criteria', 'filters']).toJS();
  const filter = filters.filter(x => x.enabled)
                        .map(x => `in(${x.category},'${x.values.filter(y => y.enabled)
                                                             .map(y => y.value)
                                                             .join(',')}')`);

  const grouping = groupings ? groupings : [];
  const request = {
    reportPath: reportPath,
    lens: lens,
    fromDate: fromDate,
    toDate: toDate,
    timeZoneId: timeZoneId,
    mode: mode,
    scenarioOverrides: mapScenarioOverrides(scenarioOverrides, displayMap, rows, columns, !hasLensChanged(lastCriteriaLens, lens)),
    grouping: grouping,
    filter: filter,
    shapeName: shapeName,
    conversionUnit: conversionUnit
  };

  switch (mode) {
    case 'AsAt': 
    case 'AsAtDelta': request.asAt = asAt; break;
    case 'AsAtDeltaRelative': request.relativeAsAt = relativeAsAt; break;
    case 'Snapshot':
    case 'SnapshotDelta': request.snapshotAsAt = snapshotAsAt; break;
    case 'SnapshotDeltaRelative': request.snapshotRelativeDate = snapshotRelativeDate; break;
    default: break;
  }

  dispatch(reportsReloadStarted());

  authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(reportsReloadComplete(data));
      dispatch(reportsReloadOnDemand());

      if (typeof callback === 'function') callback();
    })
    .catch(error => {
      dispatch(reportsReloadComplete());
      dispatch(logErrorNotification(error));
    });
};

export const REPORTS_RELOAD_COMPLETE = 'REPORTS_RELOAD_COMPLETE';
export const reportsReloadComplete = createAction(REPORTS_RELOAD_COMPLETE, 'data');

export const reportsReloadOnDemandParams = () => (dispatch, getState) => {
  const state = getState();

  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']),
        lens = state.getIn(['reports', 'criteria', 'lens']),
        fromDate = state.getIn(['reports', 'criteria', 'fromDate']),
        toDate = state.getIn(['reports', 'criteria', 'toDate']),
        timeZoneId = state.getIn(['reports', 'criteria', 'timeZoneId']),
        asAt = state.getIn(['reports', 'criteria', 'asAt']),
        mode = state.getIn(['reports', 'criteria', 'mode']),
        relativeAsAt = state.getIn(['reports', 'criteria', 'relativeAsAt']),
        snapshotAsAt = state.getIn(['reports', 'criteria', 'snapshotAsAt']),
        snapshotRelativeDate = state.getIn(['reports', 'criteria', 'snapshotRelativeDate']),
        shapeName = state.getIn(['reports', 'criteria', 'shapeName']),
        conversionUnit = state.getIn(['reports', 'criteria', 'conversionUnit']);

  const groupings = state.getIn(['reports', 'criteria', 'groupings']).toJS();
  const filters = state.getIn(['reports', 'criteria', 'filters']).toJS();

  let filter = filters.filter(x => x.enabled)
                      .map(x => `in(${x.category},'${x.values.filter(y => y.enabled)
                                                             .map(y => y.value)
                                                             .join(',')}')`);

  let grouping = groupings ? groupings : [];
  let params = {
    reportPath,
    lens,
    fromDate,
    toDate,
    timeZoneId,
    asAt,
    mode,
    relativeAsAt,
    snapshotAsAt,
    snapshotRelativeDate,
    grouping,
    filter,
    shapeName,
    conversionUnit,
    useVariants: true
  };

  for (let i in params) if (params[i] === undefined) delete params[i];

  dispatch(reportsReloadOnDemand());
};

function validateOnDemand(getState, before) {
  const state = getState();
  const after = {
    reportPath: state.getIn(['reports', 'criteria', 'reportPath']),
    lens: state.getIn(['reports', 'criteria', 'lens']),
    fromDate: state.getIn(['reports', 'criteria', 'fromDate']),
    toDate: state.getIn(['reports', 'criteria', 'toDate']),
    timeZoneId: state.getIn(['reports', 'criteria', 'timeZoneId']),
    mode: state.getIn(['reports', 'criteria', 'mode']),
    asAt : state.getIn(['reports', 'criteria', 'asAt']),
    relativeAsAt: state.getIn(['reports', 'criteria', 'relativeAsAt']),
    snapshotAsAt: state.getIn(['reports', 'criteria', 'snapshotAsAt']),
    snapshotRelativeDate: state.getIn(['reports', 'criteria', 'snapshotRelativeDate']),
    shapeName: state.getIn(['reports', 'criteria', 'shapeName']),
    conversionUnit: state.getIn(['reports', 'criteria', 'conversionUnit'])
  };

  let isValid = before.reportPath === after.reportPath
    && before.lens === after.lens
    && before.fromDate === after.fromDate
    && before.toDate === after.toDate
    && before.timeZoneId === after.timeZoneId
    && before.mode === after.mode
    && before.asAt === after.asAt
    && before.relativeAsAt === after.relativeAsAt
    && before.snapshotAsAt === after.snapshotAsAt
    && before.snapshotRelativeDate === after.snapshotRelativeDate
    && before.shapeName === after.shapeName
    && before.conversionUnit === after.conversionUnit;

  return { isValid, after };
}

export const reportsReloadOnDemand = () => (dispatch, getState) => {
  const state = getState();
  const before = {
    reportPath: state.getIn(['reports', 'criteria', 'reportPath']),
    lens: state.getIn(['reports', 'criteria', 'lens']),
    lastCriteriaLens: state.getIn(['reports', 'lastCriteria', 'lens']),
    fromDate: state.getIn(['reports', 'criteria', 'fromDate']),
    toDate: state.getIn(['reports', 'criteria', 'toDate']),
    timeZoneId: state.getIn(['reports', 'criteria', 'timeZoneId']),
    asAt: state.getIn(['reports', 'criteria', 'asAt']),
    mode: state.getIn(['reports', 'criteria', 'mode']),
    relativeAsAt: state.getIn(['reports', 'criteria', 'relativeAsAt']),
    snapshotAsAt: state.getIn(['reports', 'criteria', 'snapshotAsAt']),
    snapshotRelativeDate: state.getIn(['reports', 'criteria', 'snapshotRelativeDate']),
    shapeName: state.getIn(['reports', 'criteria', 'shapeName']),
    conversionUnit: state.getIn(['reports', 'criteria', 'conversionUnit'])
   };

  const displayMap = state.getIn(['reports', 'results', 'displayMap']).toJS();
  const columns = state.getIn(['reports', 'results', 'columns']).toJS();
  const rows = state.getIn(['reports', 'results', 'rows']).toJS();
  const scenarioOverrides = reduceScenarios(state.getIn(['reports', 'results', 'scenarioOverrideMap']).toJS());
  const scenarioOverridesMapped = mapScenarioOverrides(scenarioOverrides, displayMap, rows, columns, !hasLensChanged(before.lastCriteriaLens, before.lens));

  const keys = getOnDemandDisplayMapKeys(displayMap);

  if (!keys || !keys.length) return;

  for (let i = 0; i < keys.length; i++) {
    const { key, displayKey, nodeLevel } = keys[i];

    let request = {
      ...before,
      nodeLevel: nodeLevel,
      identityId: key,
      rootId: displayKeyParseRoot(displayKey),
      scenarioOverrides: scenarioOverridesMapped
    };

    dispatch(reportsSetDisplayMode(key, nodeLevel, displayKey, 'Loading'));

    authFetch(`${REPORTING_API_ROOT_URL}/v3/timeseries-defined-reports/nodes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      })
      .then(response => response.json())
      .then(data => {
        /*
          Protect against a race condition where the criteria could change by the time we finish loading the on-demand nodes
        */
        const { isValid, after } = validateOnDemand(getState, before);

        if (isValid) {
          dispatch(reportsToggleOnDemandComplete(key, data, displayKey));
          dispatch(reportsSetDisplayMode(key, nodeLevel, displayKey, 'Open'));
        }
        else {
          console.log('On-Demand criteria difference detected', { key, before, after });
          dispatch(reportsSetDisplayMode(key, nodeLevel, displayKey, 'OnDemand'));
        }
      })
      .catch(error => {
        dispatch(reportsSetDisplayMode(key, nodeLevel, displayKey, 'OnDemand'));
        dispatch(logErrorNotification(error));
      });
  }
};

export const REPORTS_SNAPSHOTS_CREATE_STARTED = 'REPORTS_SNAPSHOTS_CREATE_STARTED';
export const reportsSnapshotsCreateStarted = createAction(REPORTS_SNAPSHOTS_CREATE_STARTED);

export const reportsSnapshotsCreate = () => (dispatch, getState) => {
  const state = getState();

  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']),
        lens = state.getIn(['reports', 'criteria', 'lens']),
        fromDate = state.getIn(['reports', 'criteria', 'fromDate']),
        toDate = state.getIn(['reports', 'criteria', 'toDate']),
        timeZoneId = state.getIn(['reports', 'criteria', 'timeZoneId']),
        shapeName = state.getIn(['reports', 'criteria', 'shapeName']);

  dispatch(logInfoNotification('Snapshot requested'));

  authFetch(`${SNAPSHOT_API_ROOT_URL}/v3/report-snapshots/?lens=${lens}&reportPath=${reportPath}&timeZoneId=${encodeURIComponent(timeZoneId)}&fromDate=${fromDate}&toDate=${toDate}&shapeName=${shapeName}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(() => {
      dispatch(logInfoNotification('Snapshot created successfully'));
      dispatch(reportsSnapshotsRefresh());
    })
    .catch(error => {
      if (error.response && (error.response.status === 400 || error.response.status === 404 || error.response.status === 500))
        dispatch(logErrorNotification('An error occurred creating the snapshot'));
    });
};

export const REPORTS_SNAPSHOTS_CREATE_COMPLETE = 'REPORTS_SNAPSHOTS_CREATE_COMPLETE';
export const reportsSnapshotsCreateComplete = createAction(REPORTS_SNAPSHOTS_CREATE_COMPLETE, 'data');

export const REPORTS_SNAPSHOTS_REFRESH_STARTED = 'REPORTS_SNAPSHOTS_REFRESH_STARTED';
export const reportsSnapshotsRefreshStarted = createAction(REPORTS_SNAPSHOTS_REFRESH_STARTED);

export const reportsSnapshotsRefresh = () => (dispatch, getState) => {
  const state = getState();

  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']),
        lens = state.getIn(['reports', 'criteria', 'lens']),
        timeZoneId = state.getIn(['reports', 'criteria', 'timeZoneId']),
        beforeDate = state.getIn(['reports', 'criteria', 'snapshotLookupDate']),
        top = state.getIn(['reports', 'criteria', 'snapshotLookupCount']);

  dispatch(reportsSnapshotsRefreshStarted());

  authFetch(`${REPORTING_API_ROOT_URL}/v2/report-snapshots/no-scenario/${lens}?${qs.stringify({ reportPath, timeZoneId, beforeDate, top })}`)
    .then(response => response.json())
    .then(data => {
      dispatch(reportsSnapshotsRefreshComplete(data));
    })
    .catch(error => {
      dispatch(reportsSnapshotsRefreshComplete());
      dispatch(logErrorNotification(error));
    });
};

export const REPORTS_SNAPSHOTS_REFRESH_COMPLETE = 'REPORTS_SNAPSHOTS_REFRESH_COMPLETE';
export const reportsSnapshotsRefreshComplete = createAction(REPORTS_SNAPSHOTS_REFRESH_COMPLETE, 'data');

export const REPORTS_UPDATE_CRITERIA_PROPERTY = 'REPORTS_UPDATE_CRITERIA_PROPERTY';
export const reportsUpdateCriteriaProperty = createAction(REPORTS_UPDATE_CRITERIA_PROPERTY, 'key', 'value');

export const reportsUpdateMode = (value) => (dispatch, getState) => {
  dispatch(reportsModeUpdateComplete(value));
};

export const REPORTS_MODE_UPDATE_COMPLETE = 'REPORTS_MODE_UPDATE_COMPLETE';
export const reportsModeUpdateComplete = createAction(REPORTS_MODE_UPDATE_COMPLETE, 'value');

export const REPORTS_EXCEL_EXPORT_STARTED = 'REPORTS_EXCEL_EXPORT_STARTED';
export const reportsExcelExportStarted = createAction(REPORTS_EXCEL_EXPORT_STARTED);

export const REPORTS_CLEAR = 'REPORTS_CLEAR';
export const reportsClear = createAction(REPORTS_CLEAR);

export const REPORTS_RESET_CRITERIA = 'REPORTS_RESET_CRITERIA';
export const reportsResetCriteria = createAction(REPORTS_RESET_CRITERIA);

export const reportsExcelExport = () => (dispatch, getState) => {
  const state = getState();

  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']),
        lens = state.getIn(['reports', 'criteria', 'lens']),
        lastCriteriaLens = state.getIn(['reports', 'lastCriteria', 'lens']),
        fromDate = state.getIn(['reports', 'criteria', 'fromDate']),
        toDate = state.getIn(['reports', 'criteria', 'toDate']),
        timeZoneId = state.getIn(['reports', 'criteria', 'timeZoneId']),
        asAt = state.getIn(['reports', 'criteria', 'asAt']),
        mode = state.getIn(['reports', 'criteria', 'mode']),
        relativeAsAt = state.getIn(['reports', 'criteria', 'relativeAsAt']),
        snapshotAsAt = state.getIn(['reports', 'criteria', 'snapshotAsAt']),
        snapshotRelativeDate = state.getIn(['reports', 'criteria', 'snapshotRelativeDate']),
        shapeName = state.getIn(['reports', 'criteria', 'shapeName']),
        conversionUnit = state.getIn(['reports', 'criteria', 'conversionUnit']);

  const scenarioOverrides = reduceScenarios(state.getIn(['reports', 'results', 'scenarioOverrideMap']).toJS());  
  const groupings = state.getIn(['reports', 'criteria', 'groupings']).toJS();
  const filters = state.getIn(['reports', 'criteria', 'filters']).toJS();
  const displayMap = state.getIn(['reports', 'results', 'displayMap']).toJS();
  const columns = state.getIn(['reports', 'results', 'columns']).toJS();
  const rows = state.getIn(['reports', 'results', 'rows']).toJS();

  let filter = filters.filter(x => x.enabled)
                      .map(x => `in(${x.category},'${x.values.filter(y => y.enabled)
                                                             .map(y => y.value)
                                                             .join(',')}')`);

  let grouping = groupings ? groupings : [];
  const request = {
    reportPath: reportPath,
    lens: lens,
    fromDate: fromDate,
    toDate: toDate,
    timeZoneId: timeZoneId,
    asAt: asAt,
    mode: mode,
    scenarioOverrides: mapScenarioOverrides(scenarioOverrides, displayMap, rows, columns, !hasLensChanged(lastCriteriaLens, lens)),
    relativeAsAt: relativeAsAt,
    snapshotAsAt: snapshotAsAt,
    snapshotRelativeDate: snapshotRelativeDate,
    grouping: grouping,
    filter: filter,
    shapeName: shapeName,
    conversionUnit: conversionUnit
  };

  dispatch(reportsExcelExportStarted());

  authFetch(`${SNAPSHOT_API_ROOT_URL}/v3/timeseries-defined-reports`, {
    method: 'POST',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)})
    .then(response => {
      let filename = `report-export-${new Date().getTime()}`;
      if (response.headers && response.headers.has('content-disposition')) {
        const contentDisposition = response.headers.get('content-disposition');
        const matches = /filename="?([^";]+)/.exec(contentDisposition);

        if (matches.length > 1) filename = matches[1];
      }

      return response.blob().then(blob => saveAs(filename, blob));
    })
    .then(() => {
      dispatch(reportsExcelExportComplete());
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(reportsExcelExportComplete());
    })
};

export const REPORTS_EXCEL_EXPORT_COMPLETE = 'REPORTS_EXCEL_EXPORT_COMPLETE';
export const reportsExcelExportComplete = createAction(REPORTS_EXCEL_EXPORT_COMPLETE);

export const REPORTS_FILTER_ENABLED = 'REPORTS_FILTER_ENABLED';
export const reportsFilterEnabled = createAction(REPORTS_FILTER_ENABLED, 'index', 'enabled');

export const REPORTS_FILTER_VALUE_ENABLED = 'REPORTS_FILTER_VALUE_ENABLED';
export const reportsFilterValueEnabled = createAction(REPORTS_FILTER_VALUE_ENABLED, 'parentIndex', 'index', 'enabled');

export const REPORTS_COMPOSITION_REFRESH_STARTED = 'REPORTS_COMPOSITION_REFRESH_STARTED';
export const reportsCompositionRefreshStarted = createAction(REPORTS_COMPOSITION_REFRESH_STARTED, 'reportPath');

export const reportsCompositionRefresh = () => (dispatch, getState) => {
  const state = getState();

  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']),
        lens = state.getIn(['reports', 'composition', 'criteria', 'lens']),
        timeZoneId = state.getIn(['reports', 'composition', 'criteria', 'timeZoneId']),
        includeAllInputs = true;

  dispatch(reportsCompositionRefreshStarted(reportPath));

  authFetch(`${REPORTING_API_ROOT_URL}/v1/query-composition/reports?reportPath=${reportPath}&timeZoneId=${encodeURIComponent(timeZoneId)}&lens=${lens}&includeAllInputs=${includeAllInputs}&variantMode=Disabled`)
    .then(response => response.json())
    .then(data => {
      dispatch(reportsCompositionRefreshComplete(reportPath, data));
    })
    .catch(error => {
      dispatch(reportsCompositionRefreshComplete());
      dispatch(logErrorNotification(error));
    });
};

export const reportsCompositionApplyOverrides = () => (dispatch, getState) => {
  const state = getState();
  const reportPath = state.getIn(['reports', 'criteria', 'reportPath']);
  const lens = state.getIn(['reports', 'composition', 'criteria', 'lens']);
  const timeZoneId = state.getIn(['reports', 'composition', 'criteria', 'timeZoneId']);
  const scenarioOverrides = reduceScenarios(state.getIn(['reports', 'composition', 'results', 'scenarioOverrideMap']).toJS());

  const request = {
    reportPath: reportPath,
    lens: lens,
    timeZoneId: timeZoneId,
    scenarioOverrides: mapScenarioOverrides(scenarioOverrides),
    includeAllInputs: true
  };

  dispatch(reportsCompositionApplyOverridesStarted());

  authFetch(`${REPORTING_API_ROOT_URL}/v1/query-composition/reports`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(reportsCompositionApplyOverridesComplete(data));
    })
    .catch(error => {
      dispatch(reportsCompositionApplyOverridesComplete());
      dispatch(logErrorNotification(error));
    });
};

export const REPORTS_COMPOSITION_APPLY_OVERRIDES_STARTED = 'REPORTS_COMPOSITION_APPLY_OVERRIDES_STARTED';
export const reportsCompositionApplyOverridesStarted = createAction(REPORTS_COMPOSITION_APPLY_OVERRIDES_STARTED);

export const REPORTS_COMPOSITION_APPLY_OVERRIDES_COMPLETE = 'REPORTS_COMPOSITION_APPLY_OVERRIDES_COMPLETE';
export const reportsCompositionApplyOverridesComplete = createAction(REPORTS_COMPOSITION_APPLY_OVERRIDES_COMPLETE, 'data');

export const REPORTS_COMPOSITION_REFRESH_COMPLETE = 'REPORTS_COMPOSITION_REFRESH_COMPLETE';
export const reportsCompositionRefreshComplete = createAction(REPORTS_COMPOSITION_REFRESH_COMPLETE, 'reportPath', 'data');

export const REPORTS_COMPOSITION_TOGGLE_EXPAND = 'REPORTS_COMPOSITION_TOGGLE_EXPAND';
export const reportsCompositionToggleExpand = createAction(REPORTS_COMPOSITION_TOGGLE_EXPAND, 'key');

export const REPORTS_COMPOSITION_ITEM_SELECTED = 'REPORTS_COMPOSITION_ITEM_SELECTED';
export const reportsCompositionItemSelected = createAction(REPORTS_COMPOSITION_ITEM_SELECTED, 'timeSeriesId');

export const REPORTS_COMPOSITION_UPDATE_REQUEST_PROPERTY = 'REPORTS_COMPOSITION_UPDATE_REQUEST_PROPERTY';
export const reportsCompositionUpdateRequestProperty = createAction(REPORTS_COMPOSITION_UPDATE_REQUEST_PROPERTY, 'name', 'value');

export const REPORTS_COMPOSITION_SET_SCENARIO_OVERRIDE = 'REPORTS_COMPOSITION_SET_SCENARIO_OVERRIDE';
export const reportsCompositionSetScenarioOverride = createAction(REPORTS_COMPOSITION_SET_SCENARIO_OVERRIDE, 'key', 'value');

export const REPORTS_COMPOSITION_SET_SCENARIO_CSV_ID = 'REPORTS_COMPOSITION_SET_SCENARIO_CSV_ID';
export const reportsCompositionSetScenarioCsvIds = createAction(REPORTS_COMPOSITION_SET_SCENARIO_CSV_ID, 'value');

export const reportsCompositionAddScenarioByCsvIds = () => (dispatch, getState) => {
  const state = getState();
  const scenarioCsvIds = state.getIn(['reports', 'composition', 'toolbar', 'scenarioCsvIds']);

  const scenarioIds = `${scenarioCsvIds}`.replace(/\s/g, '').split(',').filter(i => i);

  dispatch(reportsCompositionAddScenarioByIds(scenarioIds));
};

export const reportsCompositionAddScenarioByIds = (scenarioIds) => (dispatch, getState) => {
  if (!scenarioIds || !scenarioIds.length)
    return;

  const state = getState();
  const identityId = state.getIn(['reports', 'composition', 'results', 'selectedTimeSeriesId']);

  const request = {
    identityId,
    alternateOfIdentityIds: scenarioIds
  };

  dispatch(reportsCompositionUpdateScenarioByIdStarted());

  return authFetch(`${ANALYSIS_API_URL}/scenario/${identityId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
  .then(response => response.json())
  .then(data => {
    dispatch(reportsCompositionUpdateScenarioByIdCompleted(identityId, data));
    dispatch(reportsCompositionSetScenarioCsvIds(''));
    dispatch(logInfoNotification(`Added scenario ${scenarioIds.join(', ')}`));
  })
  .catch(error => {
    dispatch(reportsCompositionUpdateScenarioByIdCompleted());
    dispatch(logErrorNotification(error));
  });
};

export const REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STARTED = 'REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STARTED';
export const reportsCompositionUpdateScenarioByIdStarted = createAction(REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STARTED);

export const REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STOPPED = 'REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STOPPED';
export const reportsCompositionUpdateScenarioByIdStopped = createAction(REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STOPPED);

export const REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_COMPLETED = 'REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_COMPLETED';
export const reportsCompositionUpdateScenarioByIdCompleted = createAction(REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_COMPLETED, 'identityId', 'data');

export const reportsCompositionRemoveAllScenarios = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['reports', 'composition', 'results', 'selectedTimeSeriesId']);
  const scenarioRows = toJS(state.getIn(['reports', 'composition', 'results', 'scenarioRows']), []);
  const scenarioIds = scenarioRows.filter(i => `${i.id}` === `${identityId}`).map(i => i.scenarioId);

  dispatch(reportsCompositionRemoveScenarioByIds(scenarioIds));
};

export const reportsCompositionRemoveScenarioById = (scenarioId) => (dispatch, getState) => {
  if (!scenarioId)
    return;

  dispatch(reportsCompositionRemoveScenarioByIds([scenarioId]));
};

export const reportsCompositionRemoveScenarioByIds = (scenarioIds) => (dispatch, getState) => {
  if (!scenarioIds || !scenarioIds.length)
    return;

  const state = getState();
  const identityId = state.getIn(['reports', 'composition', 'results', 'selectedTimeSeriesId']);

  const params = qs.stringify({ scenarioIds });

  dispatch(reportsCompositionUpdateScenarioByIdStarted());

  return authFetch(`${ANALYSIS_API_URL}/scenario/${identityId}?${params}`, {
    method: 'DELETE'
  })
  .then(response => response.json())
  .then(data => {
    // dispatch(reportsCompositionUpdateScenarioByIdCompleted(identityId, data));
    dispatch(reportsCompositionUpdateScenarioByIdStopped());
    dispatch(logInfoNotification(`Removed scenario ${scenarioIds.join(', ')}`));
  })
  .catch(error => {
    // dispatch(reportsCompositionUpdateScenarioByIdCompleted());
    dispatch(reportsCompositionUpdateScenarioByIdStopped());
    dispatch(logErrorNotification(error));
  });
};

export const REPORTS_COMPOSITION_ADD_SCENARIOS = 'REPORTS_COMPOSITION_ADD_SCENARIOS';
export const reportsCompositionAddScenarios = createAction(REPORTS_COMPOSITION_ADD_SCENARIOS, 'data');

export const reportsCompositionAutoAddScenarios = () => (dispatch, getState) => {
  const state = getState();
  const identityId = state.getIn(['reports', 'composition', 'results', 'selectedTimeSeriesId']);
  const excludedCategories = toJS(state.getIn(['reports', 'composition', 'results', 'excludedScenarioCategories']), []);

  const params = qs.stringify({ excludedCategories });

  return authFetch(`${ANALYSIS_API_URL}/scenario/${identityId}/suggestions?${params}`)
    .then(response => response.json())
    .then(data => {
      if (data && data.length)
        dispatch(reportsCompositionAddScenarioByIds(data.map(i => i.id)));
      else
        dispatch(logInfoNotification('No scenario suggestions found'));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
};

export const REPORTS_COMPOSITION_RESIZE_PANELS = 'REPORTS_COMPOSITION_RESIZE_PANELS';
export const reportsCompositionResizePanels = createAction(REPORTS_COMPOSITION_RESIZE_PANELS, 'panelGroupName', 'panels');

export const REPORTS_COMPOSITION_TOGGLE_SHOW_ONLY_SCENARIOS = 'REPORTS_COMPOSITION_TOGGLE_SHOW_ONLY_SCENARIOS';
export const reportsCompositionToggleShowOnlyScenarios = createAction(REPORTS_COMPOSITION_TOGGLE_SHOW_ONLY_SCENARIOS);

export const REPORTS_COMPOSITION_SELECT_FILTERED_SCENARIOS = 'REPORTS_COMPOSITION_SELECT_FILTERED_SCENARIOS';
export const reportsCompositionSelectFilteredScenarios = createAction(REPORTS_COMPOSITION_SELECT_FILTERED_SCENARIOS, 'values');

export const REPORTS_UPDATE_EXPERIMENTAL_SETTINGS = 'REPORTS_UPDATE_EXPERIMENTAL_SETTINGS';
export const reportsUpdateExperimentalSettings = createAction(REPORTS_UPDATE_EXPERIMENTAL_SETTINGS, 'data');

export const REPORTS_ADJUSTMENTS_BEGIN_EDIT = 'REPORTS_ADJUSTMENTS_BEGIN_EDIT';
export const reportsAdjustmentsBeginEdit = createAction(REPORTS_ADJUSTMENTS_BEGIN_EDIT);

export const REPORTS_ADJUSTMENTS_END_EDIT = 'REPORTS_ADJUSTMENTS_END_EDIT';
export const reportsAdjustmentsEndEdit = createAction(REPORTS_ADJUSTMENTS_END_EDIT);

export const REPORTS_ADJUSTMENTS_SET_SELECTION_START = 'REPORTS_ADJUSTMENTS_SET_SELECTION_START';
export const reportsAdjustmentsSetSelectionStart = createAction(REPORTS_ADJUSTMENTS_SET_SELECTION_START, 'value');

export const REPORTS_ADJUSTMENTS_SET_SELECTION_END = 'REPORTS_ADJUSTMENTS_SET_SELECTION_END';
export const reportsAdjustmentsSetSelectionEnd = createAction(REPORTS_ADJUSTMENTS_SET_SELECTION_END, 'value');

export const REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_CELL = 'REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_CELL';
export const reportsAdjustmentsSetAdjustmentCell = createAction(REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_CELL, 'value');

export const REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE = 'REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE';
export const reportsAdjustmentsSetAdjustmentValue = createAction(REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE, 'value');

export const REPORTS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE = 'REPORTS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE';
export const reportsAdjustmentsSetSelectionAdjustmentValue = createAction(REPORTS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE);

export const REPORTS_ADJUSTMENTS_UNDO_SELECTED_ADJUSTMENTS = 'REPORTS_ADJUSTMENTS_UNDO_SELECTED_ADJUSTMENTS';
export const reportsAdjustmentsUndoSelectedAdjustments = createAction(REPORTS_ADJUSTMENTS_UNDO_SELECTED_ADJUSTMENTS);

export const REPORTS_ADJUSTMENTS_NAVIGATE_CELL = 'REPORTS_ADJUSTMENTS_NAVIGATE_CELL';
export const reportsAdjustmentsNavigateCell = createAction(REPORTS_ADJUSTMENTS_NAVIGATE_CELL, 'value');

export const REPORTS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS = 'REPORTS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS';
export const reportsAdjustmentsRemoveAllAdjustments = createAction(REPORTS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS);

export const REPORTS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS = 'REPORTS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS';
export const reportsAdjustmentsRemoveSelectedAdjustments = createAction(REPORTS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS);

export const reportsTableSelectionCopy = (includeHeader) => (dispatch, getState) => {
  const state = getState();
  const rows = state.getIn(['reports', 'results', 'rows']);
  const columns = toJS(state.getIn(['reports', 'results', 'columns']));
  const displayMap = state.getIn(['reports', 'results', 'displayMap']);

  const {success, message, doc} = mapTableSelectionToCSV(displayMap, columns, rows, includeHeader);
  if (!success){
    if (message) dispatch(logWarningNotification(message));
  }else{
    copyToClipboard(doc);
    dispatch(reportsTableSelectionCopyComplete());
  }
}

export const REPORTS_TABLE_SELECTION_COPY_COMPLETE = 'REPORTS_TABLE_SELECTION_COPY_COMPLETE';
export const reportsTableSelectionCopyComplete = createAction(REPORTS_TABLE_SELECTION_COPY_COMPLETE);

export const reportsTablePasteToSelection = (text) => async (dispatch, getState) => {
  const state = getState();
  const isEditing = state.getIn(['reports', 'adjustments', 'isEditing']);
  if (!isEditing)
    return;

  const rows = state.getIn(['reports', 'results', 'rows']);
  const displayMap = state.getIn(['reports', 'results', 'displayMap']);
  const selectionGrid = mapSelectionToGrid(displayMap, rows);
  if (!selectionGrid.success){
    if (selectionGrid.message) dispatch(logWarningNotification(selectionGrid.message));
    return;
  }

  if (text === undefined) {
    const clipboardResult = await getClipboardTextAsync();
    if (!clipboardResult.success){
      dispatch(logWarningNotification(clipboardResult.message));
      return;
    } else {
      text = clipboardResult.text;
    }
  }

  const {success, message, table} = mapCSVToArray(text);
  if (!success){
    if (message) dispatch(logWarningNotification(message));
  }else{
    if ((selectionGrid.gridResult.length === 1 && selectionGrid.gridResult[0].cells.length === 1) ||
        (table.length === selectionGrid.gridResult.length && table[0].length === selectionGrid.gridResult[0].cells.length)){
      dispatch(reportsTablePasteToSelectionComplete(table, selectionGrid.gridResult));
      dispatch(reportAdjustmentsValidate());
    }else {
      dispatch(logWarningNotification(`The data you're pasting isn't the same shape as your selection`));
    }
  }
}

export const REPORTS_TABLE_PASTE_TO_SELECTION_COMPLETE = 'REPORTS_TABLE_PASTE_TO_SELECTION_COMPLETE';
const reportsTablePasteToSelectionComplete = createAction(REPORTS_TABLE_PASTE_TO_SELECTION_COMPLETE, 'table', 'targetGrid');

export const REPORTS_SAVE_ADJUSTMENTS = 'REPORTS_SAVE_ADJUSTMENTS';
export const reportsSaveAdjustments = () => async (dispatch, getState) => {
  const state = getState();
  const request = buildAdjustmentsRequestInner(state);
  if (request.timeSeriesAdjustments.length === 0)
    return;

  dispatch(reportsSaveAdjustmentsBegin());
  const saveResponse = await saveDefinedReportsAdjustmentsAsync(request, dispatch);
  if (!saveResponse){
    dispatch(reportsSaveAdjustmentsComplete(false));
    return;
  }

  const {success, messages } = saveResponse;

  if (!success) {
    dispatch(logErrorNotification('Failed to save adjustments'));
    dispatch(reportsSaveAdjustmentsComplete(success));
    return;
  }

  dispatch(reportsSaveAdjustmentsComplete(success, messages));
  dispatch(reportsReload());
}

export const REPORTS_SAVE_ADJUSTMENTS_BEGIN = 'REPORTS_SAVE_ADJUSTMENTS_BEGIN';
const reportsSaveAdjustmentsBegin = createAction(REPORTS_SAVE_ADJUSTMENTS_BEGIN);

export const REPORTS_SAVE_ADJUSTMENTS_COMPLETE = 'REPORTS_SAVE_ADJUSTMENTS_COMPLETE';
const reportsSaveAdjustmentsComplete = createAction(REPORTS_SAVE_ADJUSTMENTS_COMPLETE, 'success', 'messages');

export const REPORTS_ADJUSTMENTS_PANEL_TOGGLE_VISIBILITY = 'REPORTS_ADJUSTMENTS_PANEL_TOGGLE_VISIBILITY';
export const reportsTableToggleAdjustmentPanel = createAction(REPORTS_ADJUSTMENTS_PANEL_TOGGLE_VISIBILITY);

export const REPORTS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY = 'REPORTS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY';
export const reportAdjustmentsUpdateTimeSeriesMetaProperty = createAction(REPORTS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY, 'tsKey','propertyName','value');

export const reportAdjustmentsValidate = () => async (dispatch, getState) => {
  const state = getState();
  const request = buildAdjustmentsRequestInner(state);
  dispatch(reportsAdjustmentsValidateBegin());
  if (request.timeSeriesAdjustments.length === 0){
    dispatch(reportsAdjustmentsValidateComplete(true, []));
    return;
  }

  var validateResponse = await validateDefinedReportsAdjustmentsAsync(request, dispatch);
  if (!validateResponse){
    dispatch(reportsAdjustmentsValidateComplete(false));
    return;
  }

  const {messages} = validateResponse;
  dispatch(reportsAdjustmentsValidateComplete(true, messages));
};

export const REPORTS_ADJUSTMENTS_VALIDATE_BEGIN = 'REPORTS_ADJUSTMENTS_VALIDATE_BEGIN'; 
const reportsAdjustmentsValidateBegin = createAction(REPORTS_ADJUSTMENTS_VALIDATE_BEGIN);

export const REPORTS_ADJUSTMENTS_VALIDATE_COMPLETE = 'REPORTS_ADJUSTMENTS_VALIDATE_COMPLETE';
const reportsAdjustmentsValidateComplete = createAction(REPORTS_ADJUSTMENTS_VALIDATE_COMPLETE, 'success', 'messages');

function buildAdjustmentsRequestInner(state){
  const defaultTimeSeriesMeta = toJS(state.getIn(['reports', 'adjustments', 'timeSeriesMeta']));
  const timeSeriesMetas = toJS(state.getIn(['reports', 'adjustments', 'editSelection', 'timeSeriesMeta']));
  const rows = state.getIn(['reports', 'results', 'rows']);
  const dateTimes = toJS(state.getIn(['reports', 'results', 'headers'])).map(h => h.dateTime);
  const criteria = toJS(state.getIn(['reports', 'results', 'criteria']));
  return buildAdjustmentsRequest(defaultTimeSeriesMeta, timeSeriesMetas, criteria, dateTimes, rows);
}

export const REPORTS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY = 'REPORTS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY';
export const reportsAdjustmentsSetSaveConfirmationVisibility = createAction(REPORTS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY, 'isVisible');
