import React, { Fragment, useRef } from 'react';
import IdentityId from '../../shared/IdentityId2';
import Loading from '../../shared/Loading';
import { ResizeableCol } from '../../shared/ColResizer';

function Row({
  children, displayMap, isLastChild = false, level = 1, nodeKey, request, toggleExpand, parentKeys = [], showOnlyScenarios, filteredScenarios
}) {
  const hasChildren = !!(children && children.length);
  const displayKey = [...parentKeys, nodeKey].join('-');
  const displayChildrenMode = displayMap.hasOwnProperty(displayKey) ? displayMap[displayKey] : 'Open';
  let iconClassName;
  switch (displayChildrenMode) {
    case 'Open': iconClassName = 'icon icon-chevron-down'; break;
    case 'Closed': iconClassName = 'icon icon-chevron-right'; break;
    default: break;
  }

  const scenarios = request.scenarioAlternatesSummary;
  const hasScenarios = !!scenarios && scenarios.length > 0;
  const hasFilteredScenarios = !!filteredScenarios && filteredScenarios.length > 0;

  if (showOnlyScenarios && !hasScenarios)
    return null;

  if (hasFilteredScenarios && !filteredScenarios.some(fs => scenarios.some(s => fs.scenarioName === s.scenarioName)))
    return null;

  return (
    <Fragment>
      <tr style={{ height: '20px' }}>
        <td className='sticky-cell py-0' style={{ paddingLeft: `${level * 16 - (level > 1 ? 14 : 0)}px` }} >
          {level > 1 && (
            <div className='d-inline-block'>
              <i className='fas fa-fw text-dark'>{isLastChild ? '└' : '├'}</i>
            </div>
          )}
          <button type='button' className='btn btn-sm btn-link p-0 mr-2'
            style={{ lineHeight: 0, visibility: hasChildren ? 'visible' : 'hidden' }}
            disabled={!hasChildren}
            onClick={_ => toggleExpand(displayKey)}>
            <i className={`fa-fw ${iconClassName}`} style={{ fontSize: '.75em', fontWeight: 'bolder' }} />
          </button>
          {request.name}
          {request.timeSeriesId > 0 && (
            <IdentityId hideLabel={true} noButtonBorders={true}>{request.timeSeriesId}</IdentityId>
          )}
        </td>
        <td className='p-0 px-1'>
          {`${request.granularity.granularityFrequency} ${request.granularity.granularityType}`}
        </td>
        <td className='p-0 px-1'>
          {request.derivationType}
        </td>
        <td className='p-0 px-1'>
          {request.timeZoneId}
        </td>
      </tr>
      { hasChildren && displayChildrenMode === "Open" && children.map((i, ix) => (
        <Row key={i.key}
          children={i.children}
          displayMap={displayMap}
          isLastChild={ix === children.length - 1}
          level={level + 1}
          nodeKey={i.key}
          request={i.request}
          toggleExpand={toggleExpand}
          showOnlyScenarios={showOnlyScenarios}
          filteredScenarios={filteredScenarios}
          parentKeys={[...parentKeys, nodeKey]} />
      ))}
    </Fragment>
  );
}

export default function CompositionOverrideTable({ displayMap, isLoading, rows, toggleExpand, showOnlyScenarios, filteredScenarios }) {
  const tableRef = useRef();

  if (isLoading)
    return <Loading isLoading={isLoading} message='Loading with overrides...' />;

  return (
    <div>
      <table ref={ tableRef } className='table table-sm'>
        <thead>
          <tr>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Name</span>
              </div>
            </ResizeableCol>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Granularity</span>
              </div>
            </ResizeableCol>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center text-nowrap'>
                <span>Derivation Type</span>
              </div>
            </ResizeableCol>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Timezone</span>
              </div>
            </ResizeableCol>
          </tr>
        </thead>
        {rows && rows.length > 0 && (
          <tbody className='border-top-0'>
            { rows.map((r, _rx) => {
              return <Row key={r.key}
                displayMap={displayMap}
                level={r.level}
                nodeKey={r.key}
                request={r.request}
                children={r.children}
                toggleExpand={toggleExpand}
                showOnlyScenarios={showOnlyScenarios}
                filteredScenarios={filteredScenarios} />
            })}
          </tbody>
        )}
      </table>
    </div>
  );
}