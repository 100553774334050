import { createReducer } from '../utility/redux-utility';
import { LOCATION_CHANGE } from "redux-first-history";

export const application = createReducer(null, {
  [LOCATION_CHANGE](state, action) {
    const { location } = action.payload;
    let { pathname = '' } = location;
    pathname = decodeURIComponent(pathname);

    if (pathname.startsWith('/'))
    pathname = pathname.substring(1);
    
    pathname = pathname.split('/');
    return state.setIn(['routePath'], pathname);
  }
});