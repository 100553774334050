import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logErrorNotification, logInfoNotification
} from './log';
import { toJS } from '../utility/immutable-utility';
import {
  analysisRefreshTemplateNameExpressions
} from './analysis';
import {
  analysisCrossSchemaSearchBegin,
  analysisCrossSchemaSearchComplete,
  analysisCrossSchemaSearchCreateSearch
} from './analysis-cross-schema-search';
import {
  mapToFilteredRequest,
  mapToApplyStrictRequest,
  mergeDynamicWorkspaceBaselineToDynamicWorkspace,
  mapToFacetRequest,
} from '../mapper/analysisDynamicWorkspaceMapper';
import {
  DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, encodeCriteriaToUrlArgs
} from '../utility/analysis-search-utility';
import { cloneInstance } from '../utility/property-utlility';
import { fromJS, is } from 'immutable';

const searchDisplayOptions = { favouritesToggle: false, excludeDiscontinued: true, multiSelectToggle: true, addRemoveButtons: false, exportButton: false };

export const analysisDynamicWorkspaceEditNew = () => (dispatch, getState) => {
  dispatch(analysisCrossSchemaSearchCreateSearch(DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, searchDisplayOptions));
  const state = getState();
  const searchCriteria = toJS(state.getIn(['analysisCrossSchemaSearch', DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, 'criteria']));
  dispatch(analysisDynamicWorkspaceBeginEdit(undefined, searchCriteria));
};

export const analysisDynamicWorkspaceEditExisting = (key) => (dispatch, getState) => {
  const state = getState();
  const dynamicWorkspaces = toJS(state.getIn(['analysis', 'workspace', 'dynamicWorkspaces']));
  const dynamicWorkspace = dynamicWorkspaces.find(d => d.key === key);
  dispatch(analysisCrossSchemaSearchCreateSearch(DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, searchDisplayOptions, dynamicWorkspace.searchCriteria));
  dispatch(analysisDynamicWorkspaceBeginEdit(key));
};

export const ANALYSIS_DYNAMIC_WORKSPACE_BEGIN_EDIT = 'ANALYSIS_DYNAMIC_WORKSPACE_BEGIN_EDIT';
const analysisDynamicWorkspaceBeginEdit = createAction(ANALYSIS_DYNAMIC_WORKSPACE_BEGIN_EDIT, 'key', 'searchCriteria');

export const ANALYSIS_DYNAMIC_WORKSPACE_END_EDIT = 'ANALYSIS_DYNAMIC_WORKSPACE_END_EDIT';
export const analysisDynamicWorkspaceEndEdit = createAction(ANALYSIS_DYNAMIC_WORKSPACE_END_EDIT);

export const ANALYSIS_DYNAMIC_WORKSPACE_DELETE = 'ANALYSIS_DYNAMIC_WORKSPACE_DELETE';
export const analysisDynamicWorkspaceDelete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_DELETE);

export const analysisDynamicWorkspaceSave = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(analysisDynamicWorkspaceSaveEditBegin());

  const dynamicWorkspaceBaseline = toJS(state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicWorkspaceBaseline']));
  const dynamicWorkspace = toJS(state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicWorkspace']));
  dynamicWorkspace.dynamicFilters = state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicFilters']).toJS();
  dynamicWorkspace.searchCriteria.page = 1;
  dynamicWorkspace.searchCriteria.pageSize = 100;
  dynamicWorkspace.dynamicFilters = mergeDynamicWorkspaceBaselineToDynamicWorkspace(dynamicWorkspace, dynamicWorkspaceBaseline);

  const requestParams = mapToFilteredRequest(dynamicWorkspace.searchCriteria, dynamicWorkspaceBaseline.searchCriteria, dynamicWorkspace.dynamicFilters, dynamicWorkspace.substitutionKeyExclusionCategories, false);
  if (requestParams.error) {
    dispatch(logErrorNotification(requestParams.error));
    dispatch(analysisDynamicWorkspaceSaveEditComplete());
    return;
  }

  return await authFetch(requestParams.isMultiSelect ? `${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/multifiltered` : `${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/filtered`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestParams)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(analysisDynamicWorkspaceSaveEditComplete(dynamicWorkspace, data.dynamicRows));
      dispatch(analysisRefreshTemplateNameExpressions());
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(analysisDynamicWorkspaceSaveEditComplete());
    });
}

export const ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BEGIN';
const analysisDynamicWorkspaceSaveEditBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BEGIN);

export const ANALYSIS_DYNAMIC_WORKSPACE_SAVE_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_SAVE_COMPLETE';
const analysisDynamicWorkspaceSaveEditComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_SAVE_COMPLETE, 'dynamicWorkspace', 'dynamicRows');

export const analysisDynamicWorkspaceEditorSetFilterValue = (index, value) => async (dispatch, getState) => {
  dispatch(analysisDynamicWorkspaceEditorSetFilterValueBegin(index, value));
  await analysisDynamicWorkspaceDynamicRowsSearchAsync(dispatch, getState(), false);
  dispatch(analysisDynamicWorkspaceEditorSetFilterValueComplete(index, value));
}

export const analysisDynamicWorkspaceEditorRebuildExclusions = () => async (dispatch, getState) => {  
  const state = getState();
  const searchCriteria = toJS(state.getIn(['analysisCrossSchemaSearch', DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, 'criteria']));
  const dynamicWorkspace = toJS(state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicWorkspace']));
  const dynamicFilters = toJS(state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicFilters']));
  
  const requestParams = mapToFilteredRequest(searchCriteria, {}, dynamicFilters, dynamicWorkspace.substitutionKeyExclusionCategories, true);
  if (requestParams.error) {
    dispatch(logErrorNotification(requestParams.error));
    return;
  }

  dispatch(analysisDynamicWorkspaceEditorRebuildExclusionsBegin());
  return authFetch(`${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/exclusions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestParams)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(analysisDynamicWorkspaceEditorRebuildExclusionsComplete(data));
      analysisDynamicWorkspaceDynamicRowsSearchAsync(dispatch, getState(), true);
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(analysisDynamicWorkspaceEditorRebuildExclusionsComplete([]));
    }); 
}

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_BEGIN';
const analysisDynamicWorkspaceEditorRebuildExclusionsBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_BEGIN);

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_COMPLETE';
const analysisDynamicWorkspaceEditorRebuildExclusionsComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_REBUILD_EXCLUSIONS_COMPLETE, 'exclusions');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_ADD_DYNAMIC_FILTER = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_ADD_DYNAMIC_FILTER';
export const analysisDynamicWorkspaceEditorAddDynamicFilter = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_ADD_DYNAMIC_FILTER);

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DESIGN_CHANGED = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DESIGN_CHANGED';
export const analysisDynamicWorkspaceEditorDynamicWorkspaceDesignChanged = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DESIGN_CHANGED);

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DELETE_DYNAMIC_FILTER = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DELETE_DYNAMIC_FILTER';
export const analysisDynamicWorkspaceEditorDeleteDynamicFilter = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_DELETE_DYNAMIC_FILTER, 'index');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_BEGIN';
const analysisDynamicWorkspaceEditorSetFilterValueBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_BEGIN, 'index', 'value');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_COMPLETE';
const analysisDynamicWorkspaceEditorSetFilterValueComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_FILTER_VALUE_COMPLETE, 'index', 'value');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_TOGGLE_EXCLUSION_CATEGORY = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_TOGGLE_EXCLUSION_CATEGORY';
export const analysisDynamicWorkspaceEditorToggleExclusionCategory = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_TOGGLE_EXCLUSION_CATEGORY, 'categories');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_ALIAS = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_ALIAS';
export const analysisDynamicWorkspaceEditorSetAlias = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_ALIAS, 'index', 'value');

export const analysisDynamicWorkspaceEditorSetCategory = (index, value) => async (dispatch, getState) => {
  dispatch(analysisDynamicWorkspaceEditorSetCategoryBegin(index, value));
  await analysisDynamicWorkspaceDynamicRowsSearchAsync(dispatch, getState(), true);
  dispatch(analysisDynamicWorkspaceEditorSetCategoryComplete(index, value));
}

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_BEGIN';
const analysisDynamicWorkspaceEditorSetCategoryBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_BEGIN, 'index', 'value');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_COMPLETE';
const analysisDynamicWorkspaceEditorSetCategoryComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_CATEGORY_COMPLETE, 'index', 'value');

export const analysisDynamicWorkspaceEditorSetStrictValue = (index, isStrictValue, value) => async (dispatch, getState) => {
  dispatch(analysisDynamicWorkspaceEditorSetSetStrictBegin(index, isStrictValue, value));
  let state = getState();

  const isStrict = state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicFilters', index, 'strict']);
  if (isStrict) {
    const dynamicWorkspace = toJS(state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicWorkspace']));
    const dynamicFilter = toJS(state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicFilters', index]));
    const requestParams = mapToApplyStrictRequest(dynamicWorkspace.searchCriteria, dynamicFilter, dynamicWorkspace.substitutionKeyExclusionCategories);
    if (requestParams.error) {
      dispatch(logErrorNotification(requestParams.error));
      return;
    }

    if (requestParams.strictDynamicFilter.schemas.length === 0 || !dynamicFilter.selectedCategory || !dynamicFilter.strictSelectedValue) {
      dispatch(analysisDynamicWorkspaceEditorSetSetStrictComplete(index, []));
      return;
    }

    return authFetch(`${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/applystrict`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestParams)
    })
      .then(response => response.json())
      .then(data => {
        dispatch(analysisDynamicWorkspaceEditorSetSetStrictComplete(index, data));
      })
      .catch(error => {
        dispatch(logErrorNotification(error));
        dispatch(analysisDynamicWorkspaceEditorSetSetStrictComplete(index, []));
      });
  } else {
    dispatch(analysisDynamicWorkspaceEditorSetSetStrictComplete(index, []))
  }
}

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_BEGIN';
const analysisDynamicWorkspaceEditorSetSetStrictBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_BEGIN, 'index', 'isStrict', 'value');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_COMPLETE';
const analysisDynamicWorkspaceEditorSetSetStrictComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_STRICT_VALUE_COMPLETE, 'index', 'data');

export const analysisDynamicWorkspaceEditorSetIsMultiSelect = (index, value) => async (dispatch, getState) => {
  dispatch(analysisDynamicWorkspaceEditorSetIsMultiSelectBegin(index, value));
  dispatch(analysisDynamicWorkspaceEditorSetIsMultiSelectComplete(index, value));
}

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_BEGIN';
const analysisDynamicWorkspaceEditorSetIsMultiSelectBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_BEGIN, 'index', 'value');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_COMPLETE';
const analysisDynamicWorkspaceEditorSetIsMultiSelectComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_IS_MULTISELECT_COMPLETE, 'index', 'value');

export const analysisDynamicWorkspaceEditorSetMultiSelectValue = (index, value) => async (dispatch, getState) => {
  dispatch(analysisDynamicWorkspaceEditorSetMultiSelectValueBegin(index, value));
  await analysisDynamicWorkspaceDynamicRowsSearchAsync(dispatch, getState(), false);
  dispatch(analysisDynamicWorkspaceEditorSetMultiSelectValueComplete(index, value));
}

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_MULTISELECT_VALUE_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_MULTISELECT_VALUE_BEGIN';
const analysisDynamicWorkspaceEditorSetMultiSelectValueBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_MULTISELECT_VALUE_BEGIN, 'index', 'values');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_MULTISELECT_VALUE_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_MULTISELECT_VALUE_COMPLETE';
const analysisDynamicWorkspaceEditorSetMultiSelectValueComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_SET_MULTISELECT_VALUE_COMPLETE, 'index', 'values');

export const analysisDynamicWorkspaceCategoriesSearch = (includeExclusions) => async (dispatch, getState) => {
  const state = getState();
  const searchCriteria = toJS(state.getIn(['analysisCrossSchemaSearch', DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, 'criteria']));
  dispatch(analysisDynamicWorkspaceCategoriesSearchBegin());

  const requestParams = mapToFacetRequest(searchCriteria);
  if (requestParams.error) {
    dispatch(logErrorNotification(requestParams.error));
    return;
  }

  const searchArgs = encodeCriteriaToUrlArgs(searchCriteria);
  dispatch(analysisCrossSchemaSearchBegin(DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, requestParams, searchCriteria.page));

  return authFetch(`${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/facet`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestParams)
  })
    .then(response => response.json())
    .then(data => {
      const searchResult = {
        schemas: data
      };
      dispatch(analysisCrossSchemaSearchComplete(DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, searchResult, searchArgs));
      dispatch(analysisDynamicWorkspaceCategoriesSearchComplete(searchCriteria, searchResult));
      dispatch(analysisDynamicWorkspaceDynamicRowsSearch(includeExclusions));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(analysisDynamicWorkspaceCategoriesSearchComplete());
      dispatch(analysisCrossSchemaSearchComplete(DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT));
      dispatch(logErrorNotification(error));
    });
};

export const ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_BEGIN';
const analysisDynamicWorkspaceCategoriesSearchBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_BEGIN);

export const ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_COMPLETE';
const analysisDynamicWorkspaceCategoriesSearchComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_CATEGORIES_SEARCH_COMPLETE, 'searchCriteria', 'data');

export const ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_RESET_PREVIOUS_DYNAMIC_KEYS = 'ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_RESET_PREVIOUS_DYNAMIC_KEYS';
export const analysisDynamicWorkspaceEditorResetPreviousDynamicKeys = createAction(ANALYSIS_DYNAMIC_WORKSPACE_EDITOR_RESET_PREVIOUS_DYNAMIC_KEYS);

export const analysisDynamicWorkspaceDynamicRowsSearch = (includeExclusions) => async (dispatch, getState) => {
  return analysisDynamicWorkspaceDynamicRowsSearchAsync(dispatch, getState(), includeExclusions);
};

async function analysisDynamicWorkspaceDynamicRowsSearchAsync (dispatch, state, includeExclusions) {
  const searchCriteria = toJS(state.getIn(['analysisCrossSchemaSearch', DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, 'criteria']));
  const dynamicWorkspace = toJS(state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicWorkspace']));
  const dynamicFilters = toJS(state.getIn(['analysis', 'dynamicWorkspaceDesign', 'dynamicFilters']));
  const previousSearchCriteria = toJS(state.getIn(['analysis', 'dynamicWorkspaceDesign', 'previousSearchCriteria']));
  if (hasSearchCriteriaChanged(searchCriteria, previousSearchCriteria)) {
    searchCriteria.page = 1;
  }

  const requestParams = mapToFilteredRequest(searchCriteria, previousSearchCriteria, dynamicFilters, dynamicWorkspace.substitutionKeyExclusionCategories, includeExclusions);
  if (requestParams.error) {
    dispatch(logErrorNotification(requestParams.error));
    return;
  }

  dispatch(analysisDynamicWorkspaceDynamicRowsSearchBegin());
  dispatch(analysisCrossSchemaSearchBegin(DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, requestParams, searchCriteria.page));

  return await authFetch(requestParams.isMultiSelect ? `${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/multifiltered` : `${ANALYSIS_API_ROOT_URL}/v3/dynamicsearch/filtered`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestParams)
  })
    .then(response => response.json())
    .then(data => {
      dispatch(analysisCrossSchemaSearchComplete(DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, { results: data.dynamicRows }, requestParams.searchRequest));

      const schemaCategories = toJS(state.getIn(['analysisCrossSchemaSearch', DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT, 'results', 'schemaFacets']));
      dispatch(analysisDynamicWorkspaceDynamicRowsSearchComplete(data.dynamicRows, data.exclusions, schemaCategories, searchCriteria));

      if (data.exclusions && data.exclusions.length > 0) {
        dispatch(logInfoNotification(`Exclusions added:\n${(data.exclusions.join(', '))}`));
      }
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(analysisCrossSchemaSearchComplete(DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT));
      dispatch(analysisDynamicWorkspaceDynamicRowsSearchComplete());
    });
};

export const ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_BEGIN = 'ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_BEGIN';
const analysisDynamicWorkspaceDynamicRowsSearchBegin = createAction(ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_BEGIN);

export const ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_COMPLETE = 'ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_COMPLETE';
const analysisDynamicWorkspaceDynamicRowsSearchComplete = createAction(ANALYSIS_DYNAMIC_WORKSPACE_DYNAMIC_ROWS_SEARCH_COMPLETE, 'results', 'exclusions', 'schemaCategories', 'searchCriteria');

function hasSearchCriteriaChanged(searchCriteria, previousSearchCriteria) {
  searchCriteria = cloneInstance(searchCriteria);
  delete searchCriteria.page;
  delete searchCriteria.pageSize;
  delete searchCriteria.isDirty;
  previousSearchCriteria = cloneInstance(previousSearchCriteria);
  delete previousSearchCriteria.page;
  delete previousSearchCriteria.pageSize;
  delete previousSearchCriteria.isDirty;

  return !is(fromJS(searchCriteria), fromJS(previousSearchCriteria));
}