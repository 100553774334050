import { assertType } from '../utility/type-checking';
import PropTypes from 'prop-types';
import { buildInlineAdjustmentsRequests } from '../reducer-functions/reports';

export function flattenCompositionRows(rows) {
  let flat = [];
  rows.map(x => flattenCompositionRowsRecursive(x, flat))
  return flat;
}

function flattenCompositionRowsRecursive(row, flat = [], level = 1) {
  flat.push({
    level: level,
    parentId: row.parentId,
    id: row.id,
    key: row.key,
    name: row.request.name,
    granularity: row.request.granularity,
    timeZoneId: row.request.timeZoneId,
    scenarioAlternates: row.request.scenarioAlternates,
    scenarioAlternatesSummary: row.request.scenarioAlternatesSummary
  });

  if (row.children)
    row.children.map(x => flattenCompositionRowsRecursive(x, flat, level + 1));

  return flat;
}

export function hasLensChanged(previous, current){
  if(previous && previous !== current) {
    return true;
  }

  return false;
}

export function mapScenarioOverrides(overrides, displayMap, rows, columns, includeInlineAdjustments) {
  let result = [];
  for (const [k, v] of Object.entries(overrides)) {
    if (v === undefined || v === null) continue;
    let parts = k.split('|');
    if (parts.length !== 2) continue;
    result.push({ dependentId: Number(parts[0]), id: Number(parts[1]), scenario: v })
  }

  if (includeInlineAdjustments && rows && columns) {
    const inlineAdjustments = buildInlineAdjustmentsRequests(rows, columns);
    inlineAdjustments.forEach(inlineAdjustment => {
      const displayMapItem = displayMap[inlineAdjustment.rowKey];
      const existingScenario = result.find(e => e.dependentId === displayMapItem.dependentId && e.id === displayMapItem.overridenId);
      if (existingScenario){
        existingScenario.adjustments = inlineAdjustment.adjustments;
      }else {
        result.push({
          dependentId: displayMapItem.dependentId,
          id: displayMapItem.overridenId,
          adjustments: inlineAdjustment.adjustments
        });
      }
    });
  }

  return result;
}

export function rebuildScenarioAlternatesSummary(rows, scenarioAlternates) {
  let _rows = [...rows];
  let _scenarioAlternates = { ...scenarioAlternates };

  let allScenarios = [];
  for (let row of _rows) {
    let scenarios = [...(_scenarioAlternates[row.id] || [])];

    if (row.children) {
      let { rows: childRows, scenarios: childScenarios } = rebuildScenarioAlternatesSummary(row.children, scenarioAlternates);

      row.children = childRows;
      scenarios.push(...childScenarios);
    }

    const summary = Object.entries(scenarios.reduce((accumulator, { scenarioName }) => {
      const name = scenarioName || '-';

      accumulator[name] = accumulator.hasOwnProperty(name) ? accumulator[name] + 1 : 1;

      return accumulator;
    }, {})).map(([value, count]) => ({ scenarioName: value, label: `${value} (${count})` })).sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    row.request.scenarioAlternatesSummary = summary;

    allScenarios.push(...scenarios);
  }

  return { rows: _rows, scenarios: allScenarios };
}

export function rebuildScenarioOverrides(flat, scenarioOverrides) {
  return Object.entries(scenarioOverrides).reduce((accumulator, [key, value]) => {
    const row = flat.find(r => r.key === key);

    if (row) {
      const summary = row.scenarioAlternatesSummary || [];

      if (summary.some(s => s.scenarioName === value))
        accumulator[key] = value;
    }

    return accumulator;
  }, {});
}

export function mapToScenarioRows(id, flat, scenarioAlternates, maxDepth = 0) {
  const scenarios = (scenarioAlternates || {})[id] || [];

  let scenarioRows = [];
  let startLevel = -1;
  let endLevel = -1;
  for (let x of flat) {
    if (x.id === id) {
      startLevel = x.level;
      if (maxDepth > 0) endLevel = x.level + maxDepth;
      if (scenarios) {
        scenarios.forEach(y => {
          scenarioRows.push({
            id: x.id,
            name: x.name,
            scenarioId: y.identityId,
            scenarioName: y.name,
            scenario: y.scenarioName,
            granularity: y.granularity ? `${y.granularity.granularityFrequency} ${y.granularity.granularityType}` : '',
            sourceTimeZoneId: y.sourceTimeZoneId
          });
        });
      }
    }
    else if (endLevel !== -1 && x.level >= endLevel) {
      break;
    }
    else if (startLevel !== -1 && x.level > startLevel && scenarios && scenarios.length > 0) {
      scenarios.forEach(y => {
        scenarioRows.push({
          id: x.id,
          name: x.name,
          scenarioId: y.identityId,
          scenarioName: y.name,
          scenario: y.scenarioName,
          granularity: y.granularity ? `${y.granularity.granularityFrequency} ${y.granularity.granularityType}` : '',
          sourceTimeZoneId: y.sourceTimeZoneId
        });
      });
    }
    else if (x.level <= startLevel) {
      break;
    }
  }
  return scenarioRows;
}

export function flattenReportRows(parentId = -1, rows) {
  let flat = [];
  rows.map(x => flattenReportRowsRecursive(parentId, x, flat))
  return flat;
}

export function buildReportDisplayMap(rowsJs, displayMap = {}, path = [], dependentId = -1) {
  if (rowsJs) {
    for (let i = 0, row; i < rowsJs.length && (row = rowsJs[i]); i++) {
      const rowPath = [...path, `${row.overridenId}`];
      const displayKey = rowPath.join('-');

      displayMap[displayKey] = createDisplayMapItem(row.displayChildrenMode, row.id, row.overridenId, dependentId);

      if (row.children) {
        displayMap = buildReportDisplayMap(row.children, displayMap, rowPath, row.overridenId);
      }
    }
  }

  return displayMap;
}

export function createDisplayMapItem(displayMode, id, overridenId, dependentId) {
  return { displayMode, id, overridenId, dependentId };
}

export function flattenReportRowsRecursive(parentId, row, flat = [], level = 1) {
  // always set the parentId on the row object
  row.parentId = parentId;

  flat.push({
    level: level,
    parentId: parentId,
    id: row.id,
    overridenId: row.overridenId,
    key: row.key,
    name: row.name,
    scenarioAlternates: row.scenarioAlternates ? row.scenarioAlternates.filter(i => i.scenarioName) : [],
    scenarioAlternatesSummary: row.scenarioAlternatesSummary
  });

  if (row.children)
    row.children.map(x => flattenReportRowsRecursive(row.overridenId, x, flat, level + 1));

  return flat;
}

export function buildCompositionDisplayMap(rows, displayMap = {}, path = [], depth = 0) {
  if (rows) for (let i = 0, row; i < rows.length && (row = rows[i]); i++) {
    const rowPath = [...path, row.key];
    const displayKey = rowPath.join('-');

    displayMap[displayKey] = depth >= 3 ? 'Closed' : 'Open';

    if (row.children) displayMap = buildCompositionDisplayMap(row.children, displayMap, rowPath, depth + 1);
  }

  return displayMap;
}

export function buildGetReportsNodesRequest(key, rootKey, reportPath, lens, fromDate, toDate, timeZoneId, asAt, mode, relativeAsAt, snapshotAsAt, snapshotRelativeDate, groupings, filters, nodeLevel, scenarioOverrides) {
  assertType({
    key: PropTypes.any.isRequired,
    rootKey: PropTypes.string,
    reportPath: PropTypes.string.isRequired,
    lens: PropTypes.string.isRequired,
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
    timeZoneId: PropTypes.string.isRequired,
    asAt: PropTypes.string,
    mode: PropTypes.string,
    relativeAsAt: PropTypes.string,
    snapshotAsAt: PropTypes.string,
    snapshotRelativeDate: PropTypes.string,
    groupings: PropTypes.array,
    filters: PropTypes.array,
    nodeLevel: PropTypes.number.isRequired,
    scenarioOverrides: PropTypes.array
  }, { key, reportPath, lens, fromDate, toDate, timeZoneId, asAt, mode, relativeAsAt, snapshotAsAt, snapshotRelativeDate, groupings, filters, nodeLevel, scenarioOverrides });

  const filter = filters ? filters.filter(x => x.enabled)
    .map(x => `in(${x.category},'${x.values.filter(y => y.enabled)
      .map(y => y.value)
      .join(',')}')`) : [];

  const params = {
    identityId: key,
    rootId: rootKey,
    reportPath,
    lens,
    fromDate,
    toDate,
    timeZoneId,
    mode,
    grouping: groupings ?? [],
    filter,
    nodeLevel,
    useVariants: true,
    scenarioOverrides
  };

  switch (mode) {
    case 'AsAt':
    case 'AsAtDelta': params.asAt = asAt; break;
    case 'AsAtDeltaRelative': params.relativeAsAt = relativeAsAt; break;
    case 'Snapshot':
    case 'SnapshotDelta': params.snapshotAsAt = snapshotAsAt; break;
    case 'SnapshotDeltaRelative': params.snapshotRelativeDate = snapshotRelativeDate; break;
    default: break;
  }

  // get rid of undefined args
  for (let i in params) if (params[i] === undefined) delete params[i];

  return params;
}

export function displayKeyParseRoot(displayKey) {
  if(!displayKey) return -1;
  var parts = displayKey.split('-');
  if(parts.length > 0) return parts[0];
  return -1;
}

export function reduceScenarios(scenarioOverrides) {
  scenarioOverrides ??= {};
  const scenarioOverrideMap = Object.keys(scenarioOverrides)
      .map(k => {
          const [parentId, id] = k.split('|');
          return { parent: undefined, parentId, id, value: scenarioOverrides[k] };
        });  

  scenarioOverrideMap.forEach(o => {
    o.parent = scenarioOverrideMap.find(s => s.id === o.parentId);
    delete o.parentId;
  });

  scenarioOverrideMap.forEach(o => {
    var parent = o.parent;
    if (o.value !== '') {
      o.shouldInclude = parent == null;
      if (parent) {
          o.shouldInclude = true;

        parent = parent.parent;
      }
    }
  });

  const response = Object.fromEntries(scenarioOverrideMap.filter(s => s.shouldInclude).map(s => [`${s.parent ? s.parent.id : -1}|${s.id}`, s.value]));
  return response;
}

export function debugLogScenarioOverride(scenarioOverrides) {
  scenarioOverrides ??= {};
  const scenarioOverrideMap = Object.keys(scenarioOverrides)
      .map(k => {
          const [parentId, id] = k.split('|');
          return { parent: undefined, parentId, id, value: scenarioOverrides[k] };
        });  

  scenarioOverrideMap.forEach(o => {
    o.parent = scenarioOverrideMap.find(s => s.id === o.parentId);
    delete o.parentId;
  });

  scenarioOverrideMap.forEach(o => {
    var parent = o.parent;
    if (parent) {
      if (!parent.children) parent.children = [];
      parent.children.push(o);
    }

    if (parent) {
      parent = parent.parent;
    }
  });

    const log = [];
    log.push('------------');
    scenarioOverrideMap.filter(i => !i.parent).forEach(n => {
      processNode(n, 0);
    });

    function processNode(node, depth) {
      log.push(`${"".padStart(depth, ' ')} ${node.id} [${node.value}]`);
      if (node.children) {
        node.children.forEach(c => processNode(c, depth + 2));
      }
    }

    log.push('------------');
    console.log(log.join('\n'));
}