import moment from 'moment';

export function parseDate(value) {
  const date = moment(value);
  return new Date(date.year(), date.month(), date.date(), date.hour(), date.minute(), 0);
}

export function formatDate(value, options) {
  const { inFormat = undefined, outFormat = undefined, defaultValue = null } = options ?? {};
  const date = moment(value, inFormat);
  return date.isValid() ? date.format(outFormat) : defaultValue;
}

export const formatDateToIso = value => formatDate(value, { outFormat: 'YYYY-MM-DD HH:mm' });

// gas year
export function parseYearToDate(text) {
  return moment.utc(`01-JAN-${text}`, 'DD-MMM-YYYY');
}

// gas year
export function parseGasYearToDate(text) {
  return moment.utc(`01-OCT-${text}`, 'DD-MMM-YYYY');
}

// gas season
export const gasSeasonNames = ['Sum', 'Win'];

export function parseGasSeasonToDate(text) {
  const splits = text.split('-');
  return moment.utc(`01-${splits[0] === 'Sum' ? 'APR' : 'OCT'}-${splits[1]}`, 'DD-MMM-YYYY');
}

export function isGasSeasonSummer(date) {
  const month = date.month() + 1;
  return month >= 4 && month < 10;
}

export function toGasSeasonFormat(date) {
  return `${isGasSeasonSummer(date) ? 'Sum' : 'Win'}-${date.format('YYYY')}`;
}

// quarter
export const quarterNames = ['Q1', 'Q2', 'Q3', 'Q4'];

export function parseQuarterToDate(text) {
  const split = text.split('-');
  const q = Number(split[0][1]);
  let month;
  switch (q) {
    case 1: month = 'JAN'; break;
    case 2: month = 'APR'; break;
    case 3: month = 'JUL'; break;
    case 4: month = 'OCT'; break;
    default:
      throw new Error(`parseQuarterToDate expects a string like Q2-1998 not '${text}'`);
  }

  return moment.utc(`${split[1]}-${month}-01`, 'YYYY-MMM-DD');
}

export function toQuarterFormat(date) {
  let q = undefined;
  const month = date.month() + 1;
  if (month >= 1 && month < 4) {
    q = 1;
  }
  else if (month >= 4 && month < 7) {
    q = 2;
  }
  else if (month >= 7 && month < 10) {
    q = 3;
  }
  else if (month >= 10 && month <= 12) {
    q = 4;
  }
  else {
    throw new Error(`toQuarterFormat not supported for '${date.format()}'`);
  }

  return `Q${q}-${date.format('YYYY')}`;
}

export function absRelDateToString(mode, abs, rel) {
  if (mode !== 'abs')
    return rel;

  return moment(abs).format('DD/MM/YYYY');
}

export function parseTextToRelativeDate(text) {
  if (text && text.length >= 2) {
    const matches = text.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
    if (matches && matches.length === 1) {
      return {
        type: text.replace(matches[0], '').substring(0, 1),
        value: matches[0]
      };
    }
  };
}

export function relativeDateToAbs(text){
  const rel = parseTextToRelativeDate(text);
  let date = moment();
  date = date.add(rel.value, {'D': 'days','H': 'hours','W': 'weeks','M': 'months','Y': 'years'}[rel.type]);
  return date.format('YYYY-MM-DDT00:00'); 
}

export const lensedOptionsLookUp = {
  'Day': ['D', 'W', 'M', 'Y'],
  'Week': ['W', 'M', 'Y'] ,
  'Month': ['M', 'Y'] ,
  'Quarter': ['M', 'Y'] ,
  'GasSeason': [ 'M', 'Y'] ,
  'GasYear': ['Y'] ,
  'Year': ['Y'] 
};
  
export const relativeDateNames = {
 'H': { short: 'H', desc: 'Hours' },
 'D': { short: 'D', desc: 'Days' },
 'W': { short: 'W', desc: 'Weeks' },
 'M': { short: 'M', desc: 'Months' },
 'Y': { short: 'Y', desc: 'Years' }
}