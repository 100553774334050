import React from 'react';
import { ComparisonsEditorConnectComponent } from './ComparisonsEditorConnectComponent';
import { ModalDialogButton } from '../../shared/ModalDialogButton';
import { comparisonModes } from '../../../utility/comparisonmode-utility';

export function ComparisonsDatesPicker({ comparisonModeInit, comparisonMode, workspaceSelection, removeSelectedDate, removeAllSelection }) {
  const onRemoveSelectedDate = (value) => {
    removeSelectedDate(comparisonMode, value);
  }

  const onRemoveAllSelection = () => {
    removeAllSelection(comparisonMode);
  }

  const onShowDateOnDatePicker = () => {
    comparisonModeInit(comparisonMode);
  }

  return <div className='input-group' >
    <div className='form-control' style={{ height: "auto", paddingLeft: "0px", paddingTop: "1px", paddingBottom: "1px" }}>
      <div style={{ maxHeight: "10em", overflowY: "auto" }}>
        {workspaceSelection.map(s => <div key={`${s.mode}-${s.name}`} title={s.description}
          className='btn btn-outline-secondary btn-sm mr-1 ml-1 mb-1 mt-1'
          style={{ paddingLeft: "6px", paddingRight: "6px", paddingTop: "2px", paddingBottom: "2px" }} onClick={() => onRemoveSelectedDate(s)} >
            {comparisonMode === comparisonModes.gasYear && <span>GY&nbsp;</span>}
          {s.name}
          <i className='fas fa-times fa-fw' />
        </div>)}
      </div>
    </div>
    <div className='input-group-append dropdown' style={{ height: "2.7em" }}>
      {workspaceSelection.length > 0 && <button type='button' className='btn btn-sm btn-outline-secondary' onClick={onRemoveAllSelection} >
        <i className='fas fa-times fa-fw' />
      </button>}
      <ModalDialogButton size='md' buttonContent={<i className='fas fa-plus fa-fw' />} buttonClassName='btn btn-sm btn-secondary' onOpenDialog={onShowDateOnDatePicker} >
        <ComparisonsEditorConnectComponent/>
      </ModalDialogButton>
    </div>
  </div>;
}