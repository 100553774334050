import { fromJS } from 'immutable';

import {
  ANALYSIS_EDIT_WORKSPACE_BEGIN,
  ANALYSIS_EDIT_WORKSPACE_END,
  ANALYSIS_EDIT_WORKSPACE_UPDATE,
  ANALYSIS_EDIT_WORKSPACE_APPLY_CHANGES
} from '../actions/analysis';

export const analysisWorkspaceReducer = {
  [ANALYSIS_EDIT_WORKSPACE_BEGIN](state, action) {
    return state.setIn(['edit-workspace'], state.getIn(['workspace']));
  },
  [ANALYSIS_EDIT_WORKSPACE_END](state, action) {
    return state.deleteIn(['edit-workspace']);
  },
  [ANALYSIS_EDIT_WORKSPACE_UPDATE](state, action) {
    return state.setIn(['edit-workspace'], fromJS(action.data));
  },
  [ANALYSIS_EDIT_WORKSPACE_APPLY_CHANGES](state, action) {
    return state.setIn(['workspace'], state.getIn(['edit-workspace']));
  }
}