import { isEmpty, isNotEmpty } from '../utility/text-utility';

export const getDefaultedAdjustment = data => {
  let { adjustedValue, operation, type } = data;

  adjustedValue = isNotEmpty(adjustedValue) && adjustedValue !== '-' ? adjustedValue : undefined;
  operation = isNotEmpty(operation) && operation !== '-' ? operation : undefined;
  type = isNotEmpty(type) && type !== '-' ? type : undefined;

  const requiresAdjustedValue = operation !== 'Allow' && operation !== 'Block';
  const hasAdjustedValue = isNotEmpty(adjustedValue);

  if (requiresAdjustedValue && !hasAdjustedValue)
    return { ...data, adjustedValue: undefined, operation: undefined, type: undefined };

  adjustedValue = requiresAdjustedValue
    ? hasAdjustedValue ? Number(adjustedValue) : undefined
    : undefined;

  return { ...data, adjustedValue, operation, type };
};

export const convertDataPointMapToArray = dataPointMap => Object.entries(dataPointMap)
  .map(([_, value]) => value)
  .sort((a, b) => a.periodStartUtc - b.periodStartUtc);

export const mergeGeneratedDataPointsIntoEditedDataPoints = (editedDataPoints, generatedDataPoints, range, rangeProperty) => {
  const dataPointMap = Object.fromEntries(editedDataPoints
    .filter(i => i.periodStart >= range.periodStart && i.periodStart < range.periodEnd)
    .map(i => [i.periodStartUtc, i]));

  const setValue = (periodStartUtc, rangeProperty) => {
    const value = range[rangeProperty];
    if (isNotEmpty(value) && value !== '-')
      dataPointMap[periodStartUtc][rangeProperty] = rangeProperty === 'adjustedValue' ? Number(value) : value;
    else if (value === '')
      dataPointMap[periodStartUtc][rangeProperty] = undefined;
  };

  const rangeProperties = ['adjustedValue', 'operation', 'type'];
  const isAdjustedValueChange = rangeProperty === 'adjustedValue';
  const isRangeChange = rangeProperties.some(i => i === rangeProperty);
  const isVariedRange = rangeProperties.some(i => range[i] === '-');

  generatedDataPoints.forEach(i => {
    let dataPoint = dataPointMap[i.periodStartUtc];
    if (!dataPoint) // Is new
      dataPointMap[i.periodStartUtc] = dataPoint = { ...getDefaultedAdjustment(i), result: undefined, reason: undefined };

    // Range value changed that should be applied to all data points
    if (isRangeChange) {
      const hasPropertiesWithValues = isNotEmpty(dataPoint.operation) || isNotEmpty(dataPoint.type);
      // If we have varied values, we will only set that specific property, so long as the property is not
      // the adjustedValue and the data point already has operation / type specified
      if (isVariedRange && (!isAdjustedValueChange || hasPropertiesWithValues))
        setValue(i.periodStartUtc, rangeProperty);
      else // Set a defaulted adjustment (adjustedValue, operation = 'Overwrite', type = 'Standard')
        dataPointMap[i.periodStartUtc] = {
          ...getDefaultedAdjustment({ ...i, [rangeProperty]: range[rangeProperty] }), result: undefined, reason: undefined
        };
    }
  });

  return convertDataPointMapToArray(dataPointMap);
};

export const overlayEditedDataPointsOntoOriginalDataPoints = (originalDataPoints, editedDataPoints) => {
  const { periodStartUtc: firstPeriodStartUtc } = editedDataPoints[0];
  const { periodStartUtc: lastPeriodStartUtc } = editedDataPoints[editedDataPoints.length - 1];
  const dataPointMap = Object.fromEntries(originalDataPoints.map(i => [
    i.periodStartUtc,
    i.periodStartUtc >= firstPeriodStartUtc && i.periodStartUtc <= lastPeriodStartUtc
      ? { ...i, adjustedValue: undefined, operation: undefined, type: undefined, result: undefined, reason: undefined, isDimmed: false }
      : { ...i, isDimmed: true }
  ]));

  editedDataPoints.forEach(i => dataPointMap[i.periodStartUtc] = {
    ...i, result: undefined, isDimmed: false, ...validateAdjustment(i)
  });

  return convertDataPointMapToArray(dataPointMap);
};

export const mergeAdjustmentsIntoRange = (editedDataPoints, dataPointItems) => {
  const dataPointMap = Object.fromEntries(editedDataPoints.map(i => [i.periodStartUtc, i]));

  dataPointItems.forEach(i => dataPointMap[i.periodStartUtc] = { ...i, result: undefined, reason: undefined });

  return convertDataPointMapToArray(dataPointMap);
};

export const convertAdjustmentsToRange = dataPoints => dataPoints.filter(i => isNotEmpty(i.adjustedValue)
  || isNotEmpty(i.operation)
  || isNotEmpty(i.type)).reduce((accumulator, item, ix) => {
  const { periodStart, periodStart: periodEnd, adjustedValue, operation, type } = item;

  if (ix === 0)
    return { periodStart, periodEnd, adjustedValue, operation, type };

  if (accumulator.periodStart > periodStart) accumulator.periodStart = periodStart;
  if (accumulator.periodEnd < periodEnd) accumulator.periodEnd = periodEnd;
  if (accumulator.adjustedValue !== adjustedValue) accumulator.adjustedValue = '-';
  if (accumulator.operation !== operation) accumulator.operation = '-';
  if (accumulator.type !== type) accumulator.type = '-';

  return accumulator;
}, {});

export const validateRange = ({ periodStart, periodEnd, adjustedValue, operation, type }) => {
  return operation === 'Allow' || operation === 'Block'
    ? !!(periodStart && periodEnd && operation && type)
    : !!(periodStart && periodEnd && operation && type && adjustedValue !== undefined);
};

export const validateAdjustment = ({ adjustedValue, operation, type }) => {
  let messages = [];

  if (adjustedValue || operation || type) {
    if (isNotEmpty(adjustedValue) && (operation === 'Allow' || operation === 'Block'))
      messages.push(`- ${operation} operation does not accept an adjusted value`);
    else if (isEmpty(adjustedValue) && operation !== 'Allow' && operation !== 'Block')
      messages.push('- Missing adjusted value');

    if (isNotEmpty(adjustedValue) && isNaN(adjustedValue))
      messages.push('- Adjusted value is not a number');

    if (isEmpty(operation))
      messages.push('- Missing operation');

    if (isEmpty(type))
      messages.push('- Missing type');
  }

  return { isInvalid: messages.length > 0, messages };
};

export const mapRangeReasons = ranges => Object.fromEntries(ranges.map(({ id, reason }) => [id, reason]));

export const mapComments = dataPoints => dataPoints.reduce((accumulator, { isInvalid, messages }, ix) => {
  if (!isInvalid)
    return accumulator;

  return [...accumulator, {
    row: ix, col: 0,
    comment: {
      value: messages.join('\n'),
      style: { width: 320, height: messages.length * 22 },
      readOnly: true
    }
  }];
}, []);

export const validateRangeForm = (range, comments) => validateRange(range) && comments.length === 0;