import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import { authFetch } from '../auth';
import { WORKSPACES_API_URL_ROOT, WORKSPACES_API_URL } from '../config';
import { logInfoNotification, logErrorNotification } from './log';

export const WORKSPACE_MODAL_CLEAR = 'WORKSPACE_MODAL_CLEAR';
export const workspaceModalClear = createAction(WORKSPACE_MODAL_CLEAR);

export const workspaceModalInitialise = (mode, workspaceId) => (dispatch, getState) =>  {
  const state = getState();
  const isLoading = state.getIn(['workspaceModal', 'isLoading']);

  if (isLoading) return Promise.resolve();

  dispatch(workspaceModalLoadStarted());
  dispatch(workspaceModalSetMode(mode));

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v2/workspaces/${workspaceId}`)
    .then(response => response.json())
    .then(data => {
      dispatch(workspaceModalSetSelectedWorkspace(data));
      return data;
    })
    .then(() => {
      dispatch(workspaceModalRefresh());
    });
};

export const WORKSPACE_MODAL_LOAD_STARTED = 'WORKSPACE_MODAL_LOAD_STARTED';
export const workspaceModalLoadStarted = createAction(WORKSPACE_MODAL_LOAD_STARTED);

export const WORKSPACE_MODAL_LOAD_STOPPED = 'WORKSPACE_MODAL_LOAD_STOPPED';
export const workspaceModalLoadStopped = createAction(WORKSPACE_MODAL_LOAD_STOPPED);

export const WORKSPACE_MODAL_LOAD_COMPLETE = 'WORKSPACE_MODAL_LOAD_COMPLETE';
export const workspaceModalLoadComplete = createAction(WORKSPACE_MODAL_LOAD_COMPLETE, 'data');

export const workspaceModalRefresh = () => (dispatch, getState) => {
  const state = getState();
  const type = state.getIn(['workspaceModal', 'type']);

  if (!type) return Promise.resolve();

  dispatch(workspaceModalLoadStarted());

  const tasks = [
    authFetch(`${WORKSPACES_API_URL}/workspaces/${type}/private`)
      .then(response => response.json())
      .then(data => ({ property: 'private', data: data || [] })),

    authFetch(`${WORKSPACES_API_URL}/workspaces/${type}/shared`)
      .then(response => response.json())
      .then(data => ({ property: 'shared', data: data || [] })),

    authFetch(`${WORKSPACES_API_URL}/workspaces/shared-folders`)
      .then(response => response.json())
      .then(data => ({ property: 'sharedFolders', data: (data || []).map(i => ({ folderPath: i })) }))
  ];

  return Promise.allSettled(tasks)
    .then(results => {
      dispatch(workspaceModalLoadComplete(results));
    })
    .catch(error => {
      dispatch(workspaceModalLoadStopped());
      dispatch(logErrorNotification(error));
    });
};

export const WORKSPACE_MODAL_DELETE_STARTED = 'WORKSPACE_MODAL_DELETE_STARTED';
export const workspaceModalDeleteStarted = createAction(WORKSPACE_MODAL_DELETE_STARTED);

export const WORKSPACE_MODAL_DELETE_STOPPED = 'WORKSPACE_MODAL_DELETE_STOPPED';
export const workspaceModalDeleteStopped = createAction(WORKSPACE_MODAL_DELETE_STOPPED);

export const WORKSPACE_MODAL_DELETE_COMPLETE = 'WORKSPACE_MODAL_DELETE_COMPLETE';
export const workspaceModalDeleteComplete = createAction(WORKSPACE_MODAL_DELETE_COMPLETE);

export const workspaceModalDelete = (workspaceId, onSuccess) => (dispatch, getState) => {
  dispatch(workspaceModalDeleteStarted());

  return authFetch(`${WORKSPACES_API_URL}/workspaces/${workspaceId}`, {
      method: 'DELETE'
    })
    .then(() => {
      dispatch(logInfoNotification(`Workspace '${workspaceId}' deleted`));
      dispatch(workspaceModalDeleteComplete());

      if (typeof onSuccess === 'function') onSuccess(workspaceId);
    })
    .catch(error => {
      dispatch(workspaceModalDeleteStopped());
      dispatch(logErrorNotification(error));
    });
};

export const WORKSPACE_MODAL_SAVE_STARTED = 'WORKSPACE_MODAL_SAVE_STARTED';
export const workspaceModalSaveStarted = createAction(WORKSPACE_MODAL_SAVE_STARTED);

export const WORKSPACE_MODAL_SAVE_STOPPED = 'WORKSPACE_MODAL_SAVE_STOPPED';
export const workspaceModalSaveStopped = createAction(WORKSPACE_MODAL_SAVE_STOPPED);

export const WORKSPACE_MODAL_SAVE_COMPLETE = 'WORKSPACE_MODAL_SAVE_COMPLETE';
export const workspaceModalSaveComplete = createAction(WORKSPACE_MODAL_SAVE_COMPLETE);

export const workspaceModalSave = (workspaceId, onSuccess) => (dispatch, getState) => {
  const state = getState();
  const workspace = toJS(state.getIn(['workspaceModal', 'selectedWorkspace']), {});
  const fullPath = state.getIn(['workspaceModal', 'fullPath']);
  const mode = state.getIn(['workspaceModal', 'mode']);
  const scope = state.getIn(['workspaceModal', 'scope']);
  const folderPath = state.getIn(['workspaceModal', 'folderPath']);
  const subFolderPath = state.getIn(['workspaceModal', 'subFolderPath']);
  const name = state.getIn(['workspaceModal', 'name']);

  const { id, type, thumbImageId, data, version } = workspace;
  const params = {
    id: mode === 'Edit' ? id : undefined,
    data,
    thumbImageId,
    version,
    scope,
    type,
    folderPath,
    subFolderPath,
    name
  };

  dispatch(workspaceModalSaveStarted());

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v2/workspaces`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(data => {
      dispatch(logInfoNotification(`Workspace '${fullPath}' saved`));
      dispatch(workspaceModalSaveComplete());

      if (typeof onSuccess === 'function') onSuccess(data);
    })
    .catch(error => {
      dispatch(workspaceModalSaveStopped());
      dispatch(logErrorNotification(error));
    });
};

export const WORKSPACE_MODAL_SET_MODE = 'WORKSPACE_MODAL_SET_MODE';
export const workspaceModalSetMode = createAction(WORKSPACE_MODAL_SET_MODE, 'value');

export const WORKSPACE_MODAL_SET_TYPE = 'WORKSPACE_MODAL_SET_TYPE';
export const workspaceModalSetType = createAction(WORKSPACE_MODAL_SET_TYPE, 'value');

export const WORKSPACE_MODAL_SET_SCOPE = 'WORKSPACE_MODAL_SET_SCOPE';
export const workspaceModalSetScope = createAction(WORKSPACE_MODAL_SET_SCOPE, 'value');

export const WORKSPACE_MODAL_SET_PATH = 'WORKSPACE_MODAL_SET_PATH';
export const workspaceModalSetPath = createAction(WORKSPACE_MODAL_SET_PATH, 'data');

export const WORKSPACE_MODAL_SET_SELECTED_WORKSPACE = 'WORKSPACE_MODAL_SET_SELECTED_WORKSPACE';
export const workspaceModalSetSelectedWorkspace = createAction(WORKSPACE_MODAL_SET_SELECTED_WORKSPACE, 'data');