import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import CollapsePanel from '../../shared/CollapsePanel';
import {
  reportAdjustmentsUpdateTimeSeriesMetaProperty
} from '../../../actions/reports';

export const AdjustmentsPanel = connect(
  (state) => ({
    isAdjustmentsPanelVisible: state.getIn(['reports', 'adjustments', 'isAdjustmentsPanelVisible']),
    isWizard: state.getIn(['roles', 'isWizard']),
    defaultTimeSeriesMetas: state.getIn(['reports', 'adjustments', 'timeSeriesMeta']),
    selectedTimeSeriesMetas: state.getIn(['reports', 'adjustments', 'editSelection', 'timeSeriesMeta']),
    validationMessages: state.getIn(['reports', 'adjustments', 'validation', 'messages']),
    defaultAnnotationText: state.getIn(['userSettings', 'settings', 'analysis', 'adjustments', 'annotation'])
  }),
  (dispatch) => ({
    updateAdjustmentTimeSeriesMetaProperty(tskey, propertyName, value) {
      dispatch(reportAdjustmentsUpdateTimeSeriesMetaProperty(tskey, propertyName, value));
    }
  })
)(({ isAdjustmentsPanelVisible, isWizard,
  updateAdjustmentTimeSeriesMetaProperty, defaultTimeSeriesMetas, defaultAnnotationText, selectedTimeSeriesMetas,
  validationMessages }) => {
  if (!isWizard || !isAdjustmentsPanelVisible) return <></>

  const _defaultTimeSeriesMetas = toJS(defaultTimeSeriesMetas, { annotation: defaultAnnotationText });
  const _selectedTimeSeriesMetas = toJS(selectedTimeSeriesMetas, []);
  const _validationMessages = toJS(validationMessages);
  const selectedTimeSeriesMeta = _selectedTimeSeriesMetas.filter(m => m.isSelected);

  function onUpdateAnnotation(key, value) {
    updateAdjustmentTimeSeriesMetaProperty(key, 'annotation', value);
  }

  function onUpdateAdjustmentType(key, checked) {
    updateAdjustmentTimeSeriesMetaProperty(key, 'adjustmentType', checked ? 'Temporary' : 'Standard');
  }

  return <div className='analysis-table-panel'>
    <div className='adjustments-panel-section'>
      <CollapsePanel idKey='adj-basic-details' title='Default' headerStyle={{ cursor: 'pointer' }}>
        <AdjustmentsSettings
          title='Default'
          tsKey=''
          annotation={_defaultTimeSeriesMetas.annotation}
          updateAnnotation={onUpdateAnnotation}
          adjustmentType={_defaultTimeSeriesMetas.adjustmentType}
          updateAdjustmentType={onUpdateAdjustmentType} />
      </CollapsePanel>
    </div>

    {selectedTimeSeriesMeta.length === 1 && <div className='adjustments-panel-section'>
      <CollapsePanel idKey='adj-ts-details'
        title={<div style={{ overflowX: 'hidden', width: 'calc(100%-30px)' }} title={selectedTimeSeriesMeta[0].name}>
          {<span className='text-nowrap' >{selectedTimeSeriesMeta[0].name}</span>}
        </div>} headerStyle={{ cursor: 'pointer' }}>
        <AdjustmentsSettings
          title={selectedTimeSeriesMeta[0].name}
          tsKey={selectedTimeSeriesMeta[0].key}
          annotation={selectedTimeSeriesMeta[0].annotation}
          updateAnnotation={onUpdateAnnotation}
          adjustmentType={selectedTimeSeriesMeta[0].adjustmentType}
          updateAdjustmentType={onUpdateAdjustmentType}
        />
      </CollapsePanel>
    </div>}

    <div className='adjustments-panel-section'>
      <CollapsePanel idkey='adj-validation-messages' title='Validation' isOpen={true} canCollapse={false}>
        {_validationMessages && _validationMessages.map((tsMessages, vi) => <div key={`vi-${vi}}`} className='adjustments-validation-group'>
          <label className='adjustments-validation-group' title={tsMessages.name}>
            {tsMessages.name}
          </label>
          {tsMessages.errors.length > 0 && <div className='adjustments-validation-group'>
            <div className='adjustments-validation-group-title'><i className='text-danger fas fa-exclamation-triangle fa-fw' />Errors</div>
            {tsMessages.errors.map((m, i) => <div key={`m-${i}`} className='adjustments-validation-item'>{m}</div>)}
          </div>}
          {tsMessages.warnings.length > 0 && <div className='adjustments-validation-group'>
            <div className='adjustments-validation-group-title'><i className='text-warning fas fa-exclamation-triangle fa-fw' />Warnings</div>
            {tsMessages.warnings.map((m, i) => <div key={`m-${i}`} className='adjustments-validation-item'>{m}</div>)}
          </div>}
          {tsMessages.informations.length > 0 && <div className='adjustments-validation-group'>
            <div className='adjustments-validation-group-title'><i className='text-info fas fa-info-circle fa-fw' />Information</div>
            {tsMessages.informations.map((m, i) => <div key={`m-${i}`} className='adjustments-validation-item'>{m}</div>)}
          </div>}
        </div>)}
      </CollapsePanel>
    </div>
  </div>
});

function AdjustmentsSettings({ tsKey, updateAnnotation, annotation, updateAdjustmentType, adjustmentType }) {
  const tempId = `${tsKey}-is-temp`;
  return <>
    <div className='form-row mb-2'>

      <label className='col-md-12 font-weight-bold'>Reason</label>
      <div className="col-md-12">
        <textarea className='form-control form-control-sm' rows={4} value={annotation ?? ''} onChange={e => updateAnnotation(tsKey, e.target.value)}></textarea>
      </div>

    </div>

    <div className='form-row mb-2'>
      <div className='col-md-12 ml-3'>
        <input type='checkbox' id={tempId} className='form-check-input' checked={adjustmentType === 'Temporary'} onChange={e => updateAdjustmentType(tsKey, e.target.checked)} />
        <label htmlFor={tempId} className='form-check-label'>Make temporary</label>
      </div>
    </div>

  </>
}