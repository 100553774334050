import { createAction } from '../utility/redux-utility';
import { authFetch, FetchError } from '../auth';
import { toJS } from '../utility/immutable-utility';
import {
  WORKSPACES_API_URL_ROOT
} from '../config';
import {
  logErrorNotification
} from './log';
import {
  dashboardTileWorkspaceLoadBegin,
  dashboardTileWorkspaceLoadComplete,
  dashboardTileEditStart,
  dashboardTileEditCancel,
  dashboardTileEditComplete,
  dashboardTileClone
} from './dashboard-tile';
import { replace } from "redux-first-history";
import { assertType } from '../utility/type-checking';
import PropTypes from 'prop-types';
import { workspaceUpdateHitCount } from './workspace';
import { GetRegisteredDashboardItem, mapApiTilesConfig, upgradeDashboardTileKeyToStateKey, prepareDashboardForPersistence } from '../mapper/dashboardTileMapper';
import { dashboardTilesDynamicWorkspaceDropdownsRebuildOptions } from './dashboard-tiles-dynamic-workspace';
import { mergeUrlToDynamicWorkspaceDropdowns } from '../mapper/analysisDynamicWorkspaceMapper';
import { guid } from '../utility/uid-utility';
import { cloneInstance } from '../utility/property-utlility';
import { fetchPeriodsAbs } from './analysis';
import { mergePeriodCollectionLists, updateAbsValues } from '../utility/period-utility';

export const DASHBOARD_TILES_LOAD_STARTED = 'DASHBOARD_TILES_LOAD_STARTED';
const dashboardTilesLoadStarted = createAction(DASHBOARD_TILES_LOAD_STARTED);

export const DASHBOARD_TILES_LOAD_COMPLETE = 'DASHBOARD_TILES_LOAD_COMPLETE';
const dashboardTilesLoadComplete = createAction(DASHBOARD_TILES_LOAD_COMPLETE, 'workspace', 'data', 'workspacePath', 'isEditing');

export const dashboardTilesLoad = ({ path, isEditing }) => async (dispatch, getState) => {
  if (path) {
    dispatch(dashboardTilesLoadStarted());
    try {
      const { fetchError, workspace } = await fetchTileWorkspaceAsync(dispatch, `Dashboard/${path}`);
      if (fetchError) {
        dispatch(logErrorNotification(fetchError ?? `Failed to load ${path}`));
        dispatch(dashboardTilesLoadComplete());
      } else {
        if (!workspace.data) workspace.data = '{}';
        const data = JSON.parse(workspace.data);
        upgradeDashboardTileKeyToStateKey(data);
        data.tilesConfig = mapApiTilesConfig(data);
        data.dynamicWorkspaceDropdowns = mergeUrlToDynamicWorkspaceDropdowns(data.dynamicWorkspaceDropdowns, window.location.search);
        data.initUrlSearch = window.location.search;

        dispatch(dashboardTilesLoadComplete(workspace, data, path, isEditing));
        dispatch(dashboardTilesRefreshAll());
        if (isEditing) {
          dispatch(replace(window.location.pathname));
        }
      }
    }
    catch (error) {
      dispatch(logErrorNotification(error));
      dispatch(dashboardTilesLoadComplete());
    }
  }
};

export const dashboardTilesEdit = () => (dispatch, getState) => {
  dispatch(dashboardTilesEditStart());
  const state = getState();
  const tilesConfig = toJS(state.getIn(['dashboardTiles', 'tilesConfig']));
  Object.keys(tilesConfig).forEach(stateKey => {
    dispatch(dashboardTileEditStart(stateKey, tilesConfig[stateKey].type));
  });
}

export const dashboardTilesEditSave = () => (dispatch, getState) => {
  dispatch(dashboardTilesEditSaveBegin());
  const state = getState();
  const dashboardTiles = toJS(state.getIn(['dashboardTiles']));
  const periodsReferenceData = state.getIn(['dashboardTiles', 'ui', 'periodsReferenceData']).toJS();
  const periodsWorkspaces = state.getIn(['dashboardTiles', 'ui', 'periodsWorkspaces']).toJS();
  const periods = mergePeriodCollectionLists(periodsWorkspaces, periodsReferenceData);
  prepareDashboardForPersistence(dashboardTiles, periods);

  try {
    saveTileWorkspaceAsync({
      ...dashboardTiles.workspace,
      data: JSON.stringify({
        criteria: dashboardTiles.criteria,
        tilesConfig: dashboardTiles.tilesConfig,
        dynamicWorkspaceDropdowns: dashboardTiles.dynamicWorkspaceDropdowns
      })
    });
  }
  catch (error) {
    dispatch(logErrorNotification(error));
  }

  try {
    const tilesConfigRevertState = toJS(state.getIn(['dashboardTiles', 'tilesConfigRevertState']));
    Object.keys(tilesConfigRevertState).forEach(stateKey => {
      dispatch(dashboardTileEditComplete(stateKey, tilesConfigRevertState[stateKey].type));
    });
  }
  catch (error) {
    dispatch(logErrorNotification(error));
  }

  dispatch(dashboardTilesEditSaveComplete());
}

export const dashboardTilesEditCancel = () => (dispatch, getState) => {
  dispatch(dashboardTilesEditCancelBegin());
  const state = getState();
  const tilesConfigRevertState = toJS(state.getIn(['dashboardTiles', 'tilesConfigRevertState']));
  const isEditing = state.getIn(['dashboardTiles', 'isEditing']);
  if (isEditing) {
    try {
      Object.keys(tilesConfigRevertState).forEach(stateKey => {
        dispatch(dashboardTileEditCancel(stateKey, tilesConfigRevertState[stateKey].type));
      });
    }
    catch (error) {
      dispatch(logErrorNotification(error));
    }
  }

  try {
    Object.keys(tilesConfigRevertState).forEach(stateKey => {
      dispatch(dashboardTileEditComplete(stateKey, tilesConfigRevertState[stateKey].type));
    });
  }
  catch (error) {
    dispatch(logErrorNotification(error));
  }

  dispatch(dashboardTilesEditCancelComplete());
}

export const DASHBOARD_TILES_EDIT_START = 'DASHBOARD_TILES_EDIT_START';
const dashboardTilesEditStart = createAction(DASHBOARD_TILES_EDIT_START);

export const DASHBOARD_TILES_EDIT_SAVE_BEGIN = 'DASHBOARD_TILES_EDIT_SAVE_BEGIN';
const dashboardTilesEditSaveBegin = createAction(DASHBOARD_TILES_EDIT_SAVE_BEGIN);

export const DASHBOARD_TILES_EDIT_SAVE_COMPLETE = 'DASHBOARD_TILES_EDIT_SAVE_COMPLETE';
const dashboardTilesEditSaveComplete = createAction(DASHBOARD_TILES_EDIT_SAVE_COMPLETE);

export const DASHBOARD_TILES_EDIT_CANCEL_BEGIN = 'DASHBOARD_TILES_EDIT_CANCEL_BEGIN';
const dashboardTilesEditCancelBegin = createAction(DASHBOARD_TILES_EDIT_CANCEL_BEGIN);

export const DASHBOARD_TILES_EDIT_CANCEL_COMPLETE = 'DASHBOARD_TILES_EDIT_CANCEL_COMPLETE';
const dashboardTilesEditCancelComplete = createAction(DASHBOARD_TILES_EDIT_CANCEL_COMPLETE);

export const DASHBOARD_TILES_UPDATE_CRITERIA_PROPERTY = 'DASHBOARD_TILES_UPDATE_CRITERIA_PROPERTY';
export const dashboardTilesUpdateCriteriaProperty = createAction(DASHBOARD_TILES_UPDATE_CRITERIA_PROPERTY, 'key', 'value');

export const DASHBOARD_TILE_EDIT_LAYOUT_COLUMNS = 'DASHBOARD_TILE_EDIT_LAYOUT_COLUMNS';
export const dashboardTilesEditLayoutToColumns = createAction(DASHBOARD_TILE_EDIT_LAYOUT_COLUMNS, 'columns');

export const DASHBOARD_TILE_EDIT_CLONE_COMPLETE = 'DASHBOARD_TILE_EDIT_CLONE_COMPLETE';
const dashboardTileEditCloneComplete = createAction(DASHBOARD_TILE_EDIT_CLONE_COMPLETE, 'toStateKey', 'dashboardTileType', 'tileState', 'tileConfig');

export const dashboardTileEditClone = (stateKey) => (dispatch, getState) => {
  const state = getState();
  const sourceConfig = state.getIn(['dashboardTiles', 'tilesConfig', stateKey]).toJS();
  const sourceState = state.getIn(['dashboardTiles', 'tilesState', stateKey]).toJS();
  const dashboardWidth = state.getIn(['dashboardTiles', 'options', 'dashboardWidth']);

  const clonedConfig = {
    ...cloneInstance(sourceConfig),
    x: sourceConfig.x,
    y: sourceConfig.y + sourceConfig.height,
    stateKey: guid(),
  };

  const clonedState = {
    ...cloneInstance(sourceState),
    layoutChanged: false,
    isBusy: false
  }

  const tilesConfig = state.getIn(['dashboardTiles', 'tilesConfig']).toJS();
  const orderedList = Object.keys(tilesConfig).map(k => tilesConfig[k]);
  orderedList.sort((a, b) => (a.x + (a.y * dashboardWidth)) - (b.x + (b.y * dashboardWidth)));
  orderedList.filter(f => (f.y) > (sourceConfig.y)).forEach(f => {
    f.y += cloneInstance.height;
  });

  dispatch(dashboardTileClone(stateKey, clonedConfig.stateKey, clonedConfig.type, clonedState, clonedConfig));

  dispatch(dashboardTileEditCloneComplete(clonedConfig.stateKey, clonedConfig.type, clonedState, clonedConfig));
};

export const dashboardTileEditDelete = (stateKey) => (dispatch, getState) => {
  dispatch(dashboardTileEditDeleteComplete(stateKey));
  dispatch(dashboardTilesDynamicWorkspaceDropdownsRebuildOptions());
};

export const DASHBOARD_TILE_EDIT_DELETE_COMPLETE = 'DASHBOARD_TILE_EDIT_DELETE_COMPLETE';
const dashboardTileEditDeleteComplete = createAction(DASHBOARD_TILE_EDIT_DELETE_COMPLETE, 'stateKey');

export const DASHBOARD_TILE_EDIT_MOVED = 'DASHBOARD_TILE_EDIT_MOVED';
export const dashboardTileEditMoved = createAction(DASHBOARD_TILE_EDIT_MOVED, 'stateKey', 'x', 'y', 'width', 'height');

export const DASHBOARD_TILE_EDIT_ADD_NEW = 'DASHBOARD_TILE_EDIT_ADD_NEW';
export const dashboardTileEditAddNew = createAction(DASHBOARD_TILE_EDIT_ADD_NEW, 'dashboardTileType', 'properties', 'initialState');

export const DASHBOARD_TILE_EDIT_SET_VALUE = 'DASHBOARD_TILE_EDIT_SET_VALUE';
export const dashboardTileEditSetValue = createAction(DASHBOARD_TILE_EDIT_SET_VALUE, 'key', 'value');

export const DASHBOARD_TILE_SET_PROPERTIES = 'DASHBOARD_TILE_SET_PROPERTIES';
export const dashboardTileSetProperties = createAction(DASHBOARD_TILE_SET_PROPERTIES, 'stateKey', 'properties', 'requiresSave', 'indicateRefreshRequired', 'forceRefresh');

export const DASHBOARD_TILE_SET_PROPERTY_BAG = 'DASHBOARD_TILE_SET_PROPERTY_BAG';
export const dashboardTileSetPropertyBag = createAction(DASHBOARD_TILE_SET_PROPERTY_BAG, 'stateKey', 'value');

export const DASHBOARD_TILE_BEGIN_PROPERTY_BAG = 'DASHBOARD_TILE_BEGIN_PROPERTY_BAG';
export const dashboardTileBeginPropertyBag = createAction(DASHBOARD_TILE_BEGIN_PROPERTY_BAG, 'stateKey');

export const DASHBOARD_TILE_SAVE_PROPERTY_BAG = 'DASHBOARD_TILE_SAVE_PROPERTY_BAG';
export const dashboardTileSavePropertyBag = createAction(DASHBOARD_TILE_SAVE_PROPERTY_BAG, 'stateKey');

export const DASHBOARD_TILES_REFRESH_ALL_STARTED = 'DASHBOARD_TILES_REFRESH_ALL_STARTED';
const dashboardTilesRefreshAllStarted = createAction(DASHBOARD_TILES_REFRESH_ALL_STARTED);

export async function fetchPeriodsAsync(dispatch, state) {
  const periodsReferenceData = state.getIn(['dashboardTiles', 'ui', 'periodsReferenceData']).toJS();
  const periodsWorkspaces = state.getIn(['dashboardTiles', 'ui', 'periodsWorkspaces']).toJS();
  const periodSettings = state.getIn(['dashboardTiles', 'criteria', 'periodSettings']).toJS();

  const periods = mergePeriodCollectionLists(periodsWorkspaces, periodsReferenceData);
  const periodsAbs = await fetchPeriodsAbs(periods);
  const periodsAbsLookups = Object.fromEntries(periodsAbs.map(p => [p.name, p]));

  periods.forEach(period => {
    const periodSetting = periodSettings[period.name];
    const periodAbs = periodsAbsLookups[period.name];

    if (periodSetting){
      if (periodSetting.overriddenByPeriod) {
        updateAbsValues(periodAbs, periodsAbsLookups[periodSetting.overriddenByPeriod]);
      } else {
        periodAbs.from = periodSetting.from;
        periodAbs.to = periodSetting.to;
      }
    }
  });

  dispatch(dashboardFetchPeriodsCompleted(periods, periodsAbs));
  return periodsAbs;
}
export const DASHBOARD_FETCH_PERIODS_COMPLETED = 'DASHBOARD_FETCH_PERIODS_COMPLETED';
const dashboardFetchPeriodsCompleted = createAction(DASHBOARD_FETCH_PERIODS_COMPLETED, 'periods', 'periodsAbs');

export const dashboardTilesRefreshAll = () => async (dispatch, getState) => {
  dispatch(dashboardTilesRefreshAllStarted());
}

export const dashboardTileRefresh = (stateKey, dashboardTileType) => async (dispatch, getState) => {
  const state = getState();
  
  if (dashboardTileType){
    const tilesConfig = toJS(state.getIn(['dashboardTiles', 'tilesConfig']));
    dashboardTileType = tilesConfig[stateKey].type;
  }
  
  const dashboardTile = GetRegisteredDashboardItem(dashboardTileType);
  if (dashboardTile && dashboardTile.refreshAction) {
    dispatch(dashboardTile.refreshAction(stateKey));
    return;
  }  

  dispatch(dashboardTileRefreshStarted(stateKey));
}

export const DASHBOARD_TILES_REFRESH_ONE_STARTED = 'DASHBOARD_TILES_REFRESH_ONE_STARTED';
const dashboardTileRefreshStarted = createAction(DASHBOARD_TILES_REFRESH_ONE_STARTED, 'stateKey');

export const DASHBOARD_TILE_REFRESH_BEGIN = 'DASHBOARD_TILE_REFRESH_BEGIN';
export const dashboardTileRefreshBegin = createAction(DASHBOARD_TILE_REFRESH_BEGIN, 'stateKey');

export const DASHBOARD_TILE_REFRESH_COMPLETE = 'DASHBOARD_TILE_REFRESH_COMPLETE';
export const dashboardTileRefreshComplete = createAction(DASHBOARD_TILE_REFRESH_COMPLETE, 'stateKey');

export const DASHBOARD_TILE_REFRESH_ERROR = 'DASHBOARD_TILE_REFRESH_ERROR';
export const dashboardTileRefreshError = createAction(DASHBOARD_TILE_REFRESH_ERROR, 'stateKey', 'error');

export const DASHBOARD_TILE_HIDE_ERROR = 'DASHBOARD_TILE_HIDE_ERROR';
export const dashboardTileHideError = createAction(DASHBOARD_TILE_HIDE_ERROR, 'stateKey', 'index');

export const DASHBOARD_TILE_HIDE_ALL_ERRORS = 'DASHBOARD_TILE_HIDE_ALL_ERRORS';
export const dashboardTileHideAllErrors = createAction(DASHBOARD_TILE_HIDE_ALL_ERRORS, 'stateKey');

export const DASHBOARD_TILE_EXPAND = 'DASHBOARD_TILE_EXPAND';
export const dashboardTileExpand = createAction(DASHBOARD_TILE_EXPAND, 'stateKey', 'dashboardTileType');

export const DASHBOARD_TILES_UPDATE_EXPERIMENTAL_SETTINGS = 'DASHBOARD_TILES_UPDATE_EXPERIMENTAL_SETTINGS';
export const dashboardTilesUpdateExperimentalSettings = createAction(DASHBOARD_TILES_UPDATE_EXPERIMENTAL_SETTINGS, 'data');

export const DASHBOARD_WORKSPACES_INITIALISE_EDIT = 'DASHBOARD_WORKSPACES_INITIALISE_EDIT';
export const dashboardWorkspacesInitialiseEdit = createAction(DASHBOARD_WORKSPACES_INITIALISE_EDIT);

export const DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_STARTED = 'DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_STARTED';
const dashboardWorkspacesLoadForEditStarted = createAction(DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_STARTED);

export const DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_COMPLETE = 'DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_COMPLETE';
const dashboardWorkspacesLoadForEditComplete = createAction(DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_COMPLETE, 'data');

export const dashboardWorkspacesLoadForEdit = ({ workspaceType, workspacePath }) => async (dispatch, getState) => {
  try {
    dispatch(dashboardWorkspacesLoadForEditStarted());

    let workspace = await fetchWorkspaceAsync(`/${workspaceType}/${workspacePath}`, dispatch, getState(), undefined);
    if (workspace)
      dispatch(dashboardWorkspacesLoadForEditComplete(workspace));
  }
  catch (error) {
    dispatch(dashboardWorkspacesLoadForEditComplete({ type: 'error', message: 'no content' }));
  }
};

export const DASHBOARD_WORKSPACES_SAVE_FOR_EDIT_STARTED = 'DASHBOARD_WORKSPACES_SAVE_FOR_EDIT_STARTED';
const dashboardWorkspacesSaveForEditStarted = createAction(DASHBOARD_WORKSPACES_SAVE_FOR_EDIT_STARTED);

export const DASHBOARD_WORKSPACES_SAVE_FOR_EDIT_COMPLETE = 'DASHBOARD_WORKSPACES_SAVE_FOR_EDIT_COMPLETE';
const dashboardWorkspacesSaveForEditComplete = createAction(DASHBOARD_WORKSPACES_SAVE_FOR_EDIT_COMPLETE, 'data');

export const dashboardWorkspacesSaveForEdit = (workspace, callback) => async (dispatch, getState) => {
  try {
    dispatch(dashboardWorkspacesSaveForEditStarted());
    await saveTileWorkspaceAsync(workspace);
    dispatch(dashboardWorkspacesLoadForEditComplete(workspace));
  }
  catch (error) {
    dispatch(dashboardWorkspacesSaveForEditComplete({ type: 'error', message: 'no content' }));
  }
};

const fetchTileWorkspaceAsync = async (dispatch, workspacePath) => {
  assertType({
    workspacePath: PropTypes.string.isRequired
  }, { workspacePath });

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v3/workspaces?dataVersion=3&path=${encodeURIComponent(workspacePath)}`)
    .then(async response => {
      if (response.status === 204)
        return { fetchError: `${workspacePath} not found`, workspace: undefined };
      if (response.status === 200) {
        const workspace = await response.json();
        dispatch(workspaceUpdateHitCount(workspace.id, 'dashboard'));
        return { fetchError: undefined, workspace };
      }

      const message = await response.text();
      throw new FetchError(`${message}`, response);
    });
};

const saveTileWorkspaceAsync = workspace => {
  assertType({
    workspace: PropTypes.object.isRequired
  }, { workspace });  

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v2/workspaces`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(workspace)
  });
};

export function isWorkspaceLoaded(workspacePath, state, stateKey) {
  const sourceWorkspacePath = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'sourceWorkspacePath']);
  if (sourceWorkspacePath === workspacePath) {
    const workspace = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'sourceWorkspace']);
    if (workspace) {
      return true;
    }
  }

  return false;
}

export const fetchWorkspaceAsync = async (workspacePath, dispatch, state, stateKey) => {
  assertType({
    workspacePath: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.immutable.isRequired,
    stateKey: PropTypes.string
  }, { workspacePath, dispatch, state, stateKey });

  const dashboardTileType = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'type']);

  if (stateKey) {
    const sourceWorkspacePath = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'sourceWorkspacePath']);
    if (sourceWorkspacePath === workspacePath) {
      const workspace = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'sourceWorkspace']);
      if (workspace) {
        return new Promise((resolve) => {
          resolve(toJS(workspace));
        });
      }
    }
  }

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v3/workspaces?dataVersion=3&path=${encodeURIComponent(workspacePath)}`)
    .then(async response => {
      if (response.status === 204) {
        dispatch(logErrorNotification(`(${response.status}) ${workspacePath} not found`));
        return null;
      }

      if (response.status === 200) {
        const workspace = await response.json();
        dispatch(workspaceUpdateHitCount(workspace.id, 'dashboard-content'));

        if (stateKey){          
          let dashboardOptions = {
            workspacePeriods : []
          };

          const dashboardItem = GetRegisteredDashboardItem(dashboardTileType);
          if (dashboardItem && dashboardItem.dashboardCriteriaOptions) {
            const workspaceData = JSON.parse(workspace.data);
            const tileConfig = {
              ...state.getIn(['dashboardTiles', 'tilesConfig', stateKey]).toJS(),
              useDashboardLens: true,
              useDashboardTimezone: true,
              useDashboardDates: true
            };

            dashboardOptions = dashboardItem.dashboardCriteriaOptions(workspaceData, tileConfig );
          }

          if (dashboardOptions.workspacePeriods.length > 0) {
            const periodsAbs = await fetchPeriodsAbs(dashboardOptions.workspacePeriods);
            const periodsAbsLookups = Object.fromEntries(periodsAbs.map(p => [p.name, p]));

            dashboardOptions.workspacePeriods.forEach(period => {
              const periodAbs = periodsAbsLookups[period.name];
              if (periodAbs)
                updateAbsValues(period, periodAbs);
            });
          }

          dispatch(dashboardTileWorkspaceLoadedComplete(stateKey, workspacePath, workspace, dashboardOptions));
        }

        return workspace;
      }

      const message = await response.text();
      dispatch(logErrorNotification(`(${response.status}) ${message}`));
      return null;
    });
};

export const DASHBOARD_TILE_WORKSPACE_LOADED_COMPLETE = 'DASHBOARD_TILE_WORKSPACE_LOADED_COMPLETE';
const dashboardTileWorkspaceLoadedComplete = createAction(DASHBOARD_TILE_WORKSPACE_LOADED_COMPLETE, 'stateKey', 'workspacePath', 'workspace', 'dashboardOptions');

export const dashboardTileLoadWorkspace = (stateKey) => async (dispatch, getState) => {
  const state = getState();
  const dashboardTileType = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'type']);
  const workspacePath = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'workspacePath']);
  dispatch(dashboardTileRefreshBegin(stateKey));
  dispatch(dashboardTileWorkspaceLoadBegin(stateKey));
  try {
    if (!workspacePath || workspacePath.trim().length === 0)
      return;

    let workspace = await fetchWorkspaceAsync(`/${dashboardTileType}/${workspacePath}`, dispatch, getState(), stateKey);
    if (!workspace) {
      dispatch(dashboardTileRefreshError(stateKey, { type: 'error', message: 'no content' }));
      return;
    }

    const data = JSON.parse(workspace.data);
    delete workspace.data;
    const tilesConfig = getState().getIn(['dashboardTiles', 'tilesConfig', stateKey]).toJS();
    dispatch(dashboardTileWorkspaceLoadComplete(stateKey, dashboardTileType, workspace, data, tilesConfig));
  }
  catch (error) {
    dispatch(dashboardTileRefreshError(stateKey, { type: 'error', message: error }));
    dispatch(logErrorNotification(error));
  }
  finally {
    dispatch(dashboardTileRefreshComplete(stateKey));
  }
};

export const DASHBOARD_SET_VALUE = 'DASHBOARD_SET_VALUE';
export const dashboardSetValue = createAction(DASHBOARD_SET_VALUE, 'key', 'value', 'updateReferences');

export const DASHBOARD_SET_VALUES = 'DASHBOARD_SET_VALUES';
export const dashboardSetValues = createAction(DASHBOARD_SET_VALUES, 'list', 'updateReferences');

export const dashboardSetPeriodOverride = (periodName, value) => async (dispatch, getState) => {
  if (!value || !periodName)
    return;

  dispatch(dashboardSetPeriodOverrideStarted());
  const state = getState();
  const periodsReferenceData = state.getIn(['dashboardTiles', 'ui', 'periodsReferenceData']).toJS();
  const periodsWorkspaces = state.getIn(['dashboardTiles', 'ui', 'periodsWorkspaces']).toJS();

  const periods = mergePeriodCollectionLists(periodsWorkspaces, periodsReferenceData).filter(p => p.name === value);
  const periodsAbs = await fetchPeriodsAbs(periods);
  dispatch(dashboardSetPeriodOverrideCompleted(periodName, value, periodsAbs[0]));
};

export const DASHBOARD_SET_PERIOD_OVERRIDE_STARTED = 'DASHBOARD_SET_PERIOD_OVERRIDE_STARTED';
const dashboardSetPeriodOverrideStarted = createAction(DASHBOARD_SET_PERIOD_OVERRIDE_STARTED);

export const DASHBOARD_SET_PERIOD_OVERRIDE_COMPLETED = 'DASHBOARD_SET_PERIOD_OVERRIDE_COMPLETED';
const dashboardSetPeriodOverrideCompleted = createAction(DASHBOARD_SET_PERIOD_OVERRIDE_COMPLETED, 'periodName', 'value', 'periodsAbs');

export const DASHBOARD_SET_PERIOD_FROM = 'DASHBOARD_SET_PERIOD_FROM';
export const dashboardSetPeriodFrom = createAction(DASHBOARD_SET_PERIOD_FROM, 'period', 'value');

export const DASHBOARD_SET_PERIOD_TO = 'DASHBOARD_SET_PERIOD_TO';
export const dashboardSetPeriodTo = createAction(DASHBOARD_SET_PERIOD_TO, 'period', 'value');