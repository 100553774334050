import React, { useCallback, useEffect, useState } from 'react';
import LoadingIcon from '../../shared/LoadingIcon';

const CATEGORY_VIEW_TYPE = {
  STANDARD: 'categories',
  SOURCE: 'source-categories'
};

export default function AnalysisSearchCategories({ id, categories, sourceCategories, loadCategories }) {
  const [viewMode, setViewMode] = useState(CATEGORY_VIEW_TYPE.STANDARD);

  useEffect(() => {
    if (!categories) loadCategories(id);
  }, [categories, id, loadCategories]);

  const onChangeView = useCallback(e => {
    e.preventDefault();

    setViewMode(e.target.dataset.category);
  }, []);

  return <div className='w-100'>
    { sourceCategories && Object.keys(sourceCategories).length > 0 && (
      <div style={{ clear: 'both' }}>
        <div className='btn-group btn-group-toggle'>
          <button type='button' className={ 'btn btn-sm btn-outline-secondary text-nowrap' + (viewMode === CATEGORY_VIEW_TYPE.STANDARD ? ' active' : '') }
                  data-category={ CATEGORY_VIEW_TYPE.STANDARD }
                  onClick={ onChangeView }>Categories
          </button>
          <button type='button' className={ 'btn btn-sm btn-outline-secondary text-nowrap' + (viewMode === CATEGORY_VIEW_TYPE.SOURCE ? ' active' : '') }
                  data-category={ CATEGORY_VIEW_TYPE.SOURCE }
                  onClick={ onChangeView }>Source Categories
          </button>
        </div>
      </div>
    ) }

    { viewMode === CATEGORY_VIEW_TYPE.STANDARD && (
      <table style={{ clear: 'both' }} className='table table-sm table-borderless'>
        <tbody>
          { !categories ? (
            <tr>
              <td colSpan='2'>
                <div className='d-flex align-items-center pl-2'>
                  <LoadingIcon width='1.25em' /> <span className='pl-2'>Loading categories...</span>
                </div>
              </td>
            </tr>
          ) : !Object.keys(categories).length ? (
            <tr>
              <td colSpan='2'>
                <div className='d-flex align-items-center pl-2'>
                  <i className='fas fa-exclamation-triangle'></i> <span className='pl-2'>No category data found.</span>
                </div>
              </td>
            </tr>
          ) : Object.keys(categories).map((name, index) => (
            <tr key={ index }><td><b>{ name }</b></td><td>{ categories[name] || '-' }</td></tr>
          )) }
        </tbody>
      </table>
    ) }

    { viewMode === CATEGORY_VIEW_TYPE.SOURCE && (
      <table style={{ clear: 'both' }} className='table table-sm table-borderless'>
        <tbody>
          { !sourceCategories || !Object.keys(sourceCategories).length ? (
            <tr>
              <td colSpan='2'>
                <div className='d-flex align-items-center pl-2'>
                  <i className='fas fa-exclamation-triangle'></i> <span className='pl-2'>No source category data found.</span>
                </div>
              </td>
            </tr>
          ) : Object.keys(sourceCategories).map((name, index) => (
            <tr key={ index }><td><b>{ name }</b></td><td>{ sourceCategories[name].elementName || '-' }</td></tr>
          )) }
        </tbody>
      </table>
    ) }
  </div>;
}