import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { WORKSPACES_API_URL_ROOT } from '../config';
import { logErrorNotification } from './log';

export const WORKSPACE_HIT_COUNT_CLEAR = 'WORKSPACE_HIT_COUNT_CLEAR';
export const workspaceHitCountClear = createAction(WORKSPACE_HIT_COUNT_CLEAR);

export const WORKSPACE_HIT_COUNT_LOAD_STARTED = 'WORKSPACE_HIT_COUNT_LOAD_STARTED';
export const workspaceHitCountLoadStarted = createAction(WORKSPACE_HIT_COUNT_LOAD_STARTED);

export const workspaceHitCountLoad = (workspaceId) => (dispatch, getState) =>  {
  if (!workspaceId) return Promise.resolve();

  const state = getState();
  const isLoading = state.getIn(['workspaceHitCount', 'isLoading']);
  if (isLoading) return Promise.resolve();

  dispatch(workspaceHitCountLoadStarted());

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v3/workspaces/hits/${workspaceId}`)
    .then(response => response.json())
    .then(data => {
      dispatch(workspaceHitCountLoadComplete(data));
    })
    .catch(error => {
      dispatch(workspaceHitCountLoadStopped());
      dispatch(logErrorNotification(error));
    });
};

export const WORKSPACE_HIT_COUNT_LOAD_STOPPED = 'WORKSPACE_HIT_COUNT_LOAD_STOPPED';
export const workspaceHitCountLoadStopped = createAction(WORKSPACE_HIT_COUNT_LOAD_STOPPED);

export const WORKSPACE_HIT_COUNT_LOAD_COMPLETE = 'WORKSPACE_HIT_COUNT_LOAD_COMPLETE';
export const workspaceHitCountLoadComplete = createAction(WORKSPACE_HIT_COUNT_LOAD_COMPLETE, 'data');