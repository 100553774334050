import React from 'react';
import FormattedDateTime from '../../shared/FormattedDateTime';

export default function OutagesDetailsEventPanel({ data, labelColumnWidth, valueCellStyles }) {
  const { status, eventStartUtc, eventEndUtc, publishedUtc } = data ?? {};

  return (
    <table className='w-100 mt-3'>
      <thead>
        <tr>
          <th width={labelColumnWidth}>Event</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Status</td>
          <td style={valueCellStyles}>{status}</td>
        </tr>
        <tr>
          <td>Start (UTC)</td>
          <td style={valueCellStyles}><FormattedDateTime>{eventStartUtc}</FormattedDateTime></td>
        </tr>
        <tr>
          <td>End (UTC)</td>
          <td style={valueCellStyles}><FormattedDateTime>{eventEndUtc}</FormattedDateTime></td>
        </tr>
        <tr>
          <td>Published (UTC)</td>
          <td style={valueCellStyles}><FormattedDateTime>{publishedUtc}</FormattedDateTime></td>
        </tr>
      </tbody>
    </table>
  );
}