import React from 'react';
import LoadingIcon from '../../../shared/LoadingIcon';
import ConfirmButton from '../../../shared/ConfirmButton';
import DateTimeInput from '../../../shared/DateTimeInput';

const cellClassName = 'px-0';
const cellStyle = { verticalAlign: 'top' };
const customSelectStyle = {
  background: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a10' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .25rem center/8px 10px`,
  backgroundColor: '#fff',
  paddingLeft: '.25rem',
  paddingRight: 0
};

export default function AdjustmentRangeEditorTableEditRow({ hasChanges, range, operations, types, isDateOnly,
  cancelRange, saveRange, editRangeProperty, editRangeReason }) {
  const { periodStart, periodEnd, operation, adjustedValue, type, reason, isSaving, isValidForm } = range;

  return (
    <>
      <tr className='table-active'>
        <td className={cellClassName} />
        <td className={cellClassName} style={cellStyle}>
          <DateTimeInput style={{ fontSize: '12.6px' }}
            isDateOnly={isDateOnly}
            value={periodStart}
            onChange={value => editRangeProperty('periodStart', value)} />
        </td>
        <td className={cellClassName} style={cellStyle}>
          <DateTimeInput style={{ fontSize: '12.6px' }}
            isDateOnly={isDateOnly}
            value={periodEnd}
            onChange={value => editRangeProperty('periodEnd', value)} />
        </td>
        {(operation !== 'Allow' && operation !== 'Block') ? (
          <td className={cellClassName} style={cellStyle}>
            <input type='text' className='form-control form-control-sm rounded-right'
              value={adjustedValue || ''}
              onChange={e => editRangeProperty('adjustedValue', e.target.value)} />
          </td>
        ) : <td />}
        <td className={cellClassName} style={cellStyle}>
          <select className='custom-select custom-select-sm'
            style={customSelectStyle}
            value={operation || ''}
            onChange={e => editRangeProperty('operation', e.target.value)}>
            {operation === '-' && <option value='-'>-</option>}
            {operations.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </td>
        <td className={cellClassName} style={cellStyle}>
          <select className='custom-select custom-select-sm'
            style={customSelectStyle}
            value={type || ''}
            onChange={e => editRangeProperty('type', e.target.value)}>
            {type === '-' && <option value='-'>-</option>}
            {types.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </td>
        <td className={`${cellClassName} pr-1`} colSpan={4} style={{ ...cellStyle, width: '60px' }}>
          <textarea type='text' className='form-control form-control-sm rounded-right'
            value={reason || ''}
            onChange={e => editRangeReason(e.target.value)} />
        </td>
      </tr>
      <tr className='table-active'>
        <td colSpan={10} className={`${cellClassName} pr-1 text-right border-top-0`}>
          {isSaving ? (
            <LoadingIcon width='1.25em' height='1.25em' stroke='#333' />
          ) : (
            <>
              <ConfirmButton type='button' tabIndex={-1}
                className='btn btn-sm btn-link'
                content='Cancel'
                confirmClassName='btn btn-sm btn-outline-warning text-dark'
                confirm='Discard changes?'
                requiresConfirmation={hasChanges}
                onClick={cancelRange}>
              </ConfirmButton>
              <button type='button' className='btn btn-sm btn-primary ml-2' onClick={saveRange} disabled={!isValidForm}>Save</button>
            </>
          )}
        </td>
      </tr>
    </>
  );
}