import { authFetch } from '../auth';
import { createAction } from '../utility/redux-utility';
import { ANALYSIS_API_ROOT_URL } from '../config';
import {
  logErrorNotification
} from './log';

export const TOOLBOX_EXEC_START = 'TOOLBOX_EXEC_START';
export const toolboxExecStart = createAction(TOOLBOX_EXEC_START);

export const TOOLBOX_EXEC_STOP = 'TOOLBOX_EXEC_STOP';
export const toolboxExecStop = createAction(TOOLBOX_EXEC_STOP);

export const TOOLBOX_EXEC_COMPLETE = 'TOOLBOX_EXEC_COMPLETE';
export const toolboxExecComplete = createAction(TOOLBOX_EXEC_COMPLETE, 'data');

export const toolboxExec = (identityId) => (dispatch, getState) => {
  const state = getState();
  const request = {
    id: identityId,
    identity: state.getIn(['toolbox', 'testUnsavedChanges']) ? state.getIn(['timeSeriesDetails', 'timeSeriesEditor', 'timeSeries']).toJS() : null,
    lens: state.getIn(['toolbox', 'lens']),
    operation: state.getIn(['toolbox', 'operation']),
    fromDate: state.getIn(['toolbox', 'fromDate']),
    toDate: state.getIn(['toolbox', 'toDate']),
    timezone: state.getIn(['toolbox', 'timezone']),
    asAtDate: state.getIn(['toolbox', 'asAtDate']),
    shapeName: state.getIn(['toolbox', 'shapeName']),
    takeFirst: state.getIn(['toolbox', 'take']) === 'First',
    dpCount: state.getIn(['toolbox', 'dpCount']),
    disableMaterialisation: state.getIn(['toolbox', 'disableMaterialisation']),
    disableVariants: state.getIn(['toolbox', 'disableVariants'])    
  };

  dispatch(toolboxExecStart());
  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/timeseries/diagnostics`, {
    method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    })  
     .then(response => response.json())
     .then(data => {
       dispatch(toolboxExecComplete(data));
       dispatch(toolboxExecStop());
     })
     .catch(error => {
       dispatch(logErrorNotification(error));
       dispatch(toolboxExecStop());
     });
};

export const TOOLBOX_TOGGLE_MATERIALISATION = 'TOOLBOX_TOGGLE_MATERIALISATION';
export const toolboxToggleMaterialisation = createAction(TOOLBOX_TOGGLE_MATERIALISATION);

export const TOOLBOX_TOGGLE_VARIANTS = 'TOOLBOX_TOGGLE_VARIANTS';
export const toolboxToggleVariants = createAction(TOOLBOX_TOGGLE_VARIANTS);

export const TOOLBOX_TOGGLE_TEST_UNSAVED_CHANGES = 'TOOLBOX_TOGGLE_TEST_UNSAVED_CHANGES';
export const toolboxToggleTestUnsavedChanges = createAction(TOOLBOX_TOGGLE_TEST_UNSAVED_CHANGES);

export const TOOLBOX_UPDATE_PROPERTY = 'TOOLBOX_UPDATE_PROPERTY';
export const toolboxUpdateProperty = createAction(TOOLBOX_UPDATE_PROPERTY, 'prop', 'value');
