import { fromJS } from 'immutable'
import { createReducer } from '../utility/redux-utility';
import { timeSeriesDetailsBasicReducer } from './timeSeriesDetails-basic';
import { timeSeriesDetailsAnnotationsReducer } from './timeSeriesDetails-annotations';
import { timeSeriesDetailsCompositionReducer } from './timeSeriesDetails-composition';
import { timeSeriesDetailsMaterialiseReducer } from './timeSeriesDetails-materialise';
import { timeSeriesDetailsVariantsReducer } from './timeSeriesDetails-variants';
import { timeSeriesDetailsDerivationReducer } from './timeSeriesDetails-derivation';
import { timeSeriesDetailsBasedOnReducer } from './timeSeriesDetails-based-on';
import { timeSeriesDetailsFunctionsReducer } from './timeSeriesDetails-functions';
import { timeSeriesDetailsCategoriesReducer } from './timeSeriesDetails-categories';
import { timeSeriesDetailsAdjustmentsReducer } from './timeSeriesDetails-adjustments';
import { timeSeriesDetailsToolbarReducer } from './timeSeriesDetails-toolbar';
import qs, { stringify } from 'querystring';
import moment from 'moment';
import { copyToClipboard } from '../utility/text-utility';
import {
  TIMESERIES_DETAILS_INITIALISE,
  TIMESERIES_DETAILS_INITIALISE_INPUTKEY_STYLE,
  TIMESERIES_DETAILS_LOAD_STARTED,
  TIMESERIES_DETAILS_LOAD_COMPLETE,
  TIMESERIES_DETAILS_NEW_TIMESERIES,
  TIMESERIES_DETAILS_CLONE_TIMESERIES_COMPLETE,
  TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_STARTED,
  TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_COMPLETE,
  TIMESERIES_DETAILS_CATEGORIES_LOAD_STARTED,
  TIMESERIES_DETAILS_CATEGORIES_LOAD_COMPLETE,
  TIMESERIES_DETAILS_UPDATE_OBJECT,
  TIMESERIES_DETAILS_UPDATE_VALUE,
  TIMESERIES_DETAILS_SET_CURRENT_ID,
  TIMESERIES_DETAILS_SET_VIEW,
  TIMESERIES_DETAILS_TOGGLE_EDITOR_COLLAPSE,
  TIMESERIES_DETAILS_TOGGLE_SIDEBAR_COLLAPSE,
  TIMESERIES_DETAILS_SET_FOCUS,
  TIMESERIES_DETAILS_SET_HAS_FOCUSED,
  TIMESERIES_DETAILS_COPY_LINK_TO_CLIPBOARD
} from '../actions/timeSeriesDetails';

const timeSeriesDetailsReducer = {
  [TIMESERIES_DETAILS_INITIALISE](state, action){
    const { search } = window.location;
    if (search && search.startsWith('?')) {
      const queryParams = qs.parse(search.substring(1));
      if (queryParams.fromDate){
        const fromDate = moment(queryParams.fromDate);
        if (fromDate.isValid()){
          state = state.setIn(['adjustmentsEditor', 'dataPointsEdit', 'filterFromUtc'], fromDate.toDate());
        }
      }

      if (queryParams.toDate){
        const toDate = moment(queryParams.toDate);
        if (toDate.isValid()){
          state = state.setIn(['adjustmentsEditor', 'dataPointsEdit', 'filterToUtc'], toDate.toDate());
        }
      }

      if (queryParams.timeZoneId){
        state = state.setIn(['adjustmentsEditor', 'dataPointsEdit', 'filterTimezone'], queryParams.timeZoneId);
      }
    }

    if (action.view) {
      let view = action.view.toLowerCase();
      if (view !== 'variants' &&
          view !== 'annotations' &&
          view !== 'composition' &&
          view !== 'adjustments' &&
          view !== 'adjustment-ranges') {
        view = '';
      }

      state = state.setIn(['currentTimeSeriesView'], view);
    }

    const [route, timeseriesid] = window.location.pathname.split('/').filter(p => p);
    window.history.replaceState({}, '',`/${route}/${timeseriesid}`);
    return state;
  },
  [TIMESERIES_DETAILS_INITIALISE_INPUTKEY_STYLE](state, action) {
    return state.setIn(['timeSeriesEditor', 'inputKeysStyle'], action.style);
  },
  [TIMESERIES_DETAILS_NEW_TIMESERIES](state, action) {
    const timeSeries = action.data;
    return state.setIn(['currentTimeSeriesId'], action.id)
      .setIn(['selectedTimeSeriesId'], timeSeries.id)
      .setIn(['timeSeriesEditor', 'timeSeries'], fromJS(timeSeries))
      .setIn(['annotationsEditor', 'annotations'], fromJS([]))
      .setIn(['variantsEdit', 'variants'], fromJS([]))
      .setIn(['composition'], fromJS({}))
      .setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPoints'], fromJS([]));
  },
  [TIMESERIES_DETAILS_CLONE_TIMESERIES_COMPLETE](state, action) {
    const timeSeries = action.data;
    return state.setIn(['timeSeriesEditor', 'timeSeries'], fromJS(timeSeries))
      .setIn(['selectedTimeSeriesId'], timeSeries.id)
      .setIn(['annotationsEditor', 'annotations'], fromJS([]))
      .setIn(['variantsEdit', 'variants'], fromJS([]))
      // do not clear the composition as its a method to fallback to select the original or other related timeseries
      //.setIn(['composition'], fromJS({}))
      .setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPoints'], fromJS([]));
  },
  [TIMESERIES_DETAILS_LOAD_STARTED](state, action) {
    return state.setIn(['timeSeriesEditor', 'timeSeries'], fromJS({}))
      .setIn(['annotationsEditor', 'annotations'], fromJS([]))
      .setIn(['variantsEdit', 'variants'], fromJS([]))
      .setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPoints'], fromJS([]));
  },
  [TIMESERIES_DETAILS_LOAD_COMPLETE](state, action) {
    const timeSeries = action.data;

    // if the time series is Derived and we have no current view jump to the composition view
    if (timeSeries.style === 'Derived') {
      const currentTimeSeriesView = state.getIn(['currentTimeSeriesView']);
      if (!currentTimeSeriesView)
        state = state.setIn(['currentTimeSeriesView'], 'variants');
    }

    return state.setIn(['selectedTimeSeriesId'], timeSeries.id)
                .setIn(['timeSeriesEditor', 'timeSeries'], fromJS(action.data));
  },
  [TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_STARTED](state, action) {
    return state;
  },
  [TIMESERIES_DETAILS_LOAD_LOOKUP_DATA_COMPLETE](state, action) {
    const { source, unit } = action.data;

    const lookupDataSource = (source || []).map(i => i.value);
    const lookupDataUnit = (unit || []).map(i => i.value);

    return state.setIn(['timeSeriesEditor', 'lookupData', 'source'], fromJS(lookupDataSource))
      .setIn(['timeSeriesEditor', 'lookupData', 'unit'], fromJS(lookupDataUnit));
  },
  [TIMESERIES_DETAILS_CATEGORIES_LOAD_STARTED](state, action) {
    return state;
  },
  [TIMESERIES_DETAILS_CATEGORIES_LOAD_COMPLETE](state, action) {
    let categories = action.data;
    const categoriesToExclude = ['datatype', 'granularity', 'granularitytype', 'sourcetimezoneid', 'source', 'style', 'unit'];
    categories = categories.filter(c => categoriesToExclude.indexOf(c.key.toLowerCase()) < 0);
    return state.setIn(['timeSeriesEditor', 'lookupData', 'categories'], fromJS(categories));
  },
  [TIMESERIES_DETAILS_UPDATE_OBJECT](state, action) {
    return state.setIn(['timeSeriesEditor', 'timeSeries'], fromJS(action.data));
  },
  [TIMESERIES_DETAILS_UPDATE_VALUE](state, action) {
    let keyPath = Array.isArray(action.keyPath) ? action.keyPath : [action.keyPath];
    let value = typeof action.value === 'object' ? fromJS(action.value) : action.value;
    return state.setIn(['timeSeriesEditor', 'timeSeries', ...keyPath], value);
  },
  [TIMESERIES_DETAILS_SET_CURRENT_ID](state, action) {
    return state.setIn(['currentTimeSeriesId'], action.key);
  },
  [TIMESERIES_DETAILS_SET_VIEW](state, action) {
    //only allow valid view modes into state
    let view = action.view;
    if (view &&
        view.toLowerCase() !== 'variants' &&
        view.toLowerCase() !== 'annotations' &&
        view.toLowerCase() !== 'composition' &&
        view.toLowerCase() !== 'instances' &&
        view.toLowerCase() !== 'history' &&
        view.toLowerCase() !== 'schemas' &&
        view.toLowerCase() !== 'adjustments' &&
        view.toLowerCase() !== 'adjustment-ranges' &&
        view.toLowerCase() !== 'toolbox') {
      view = '';
    }

    return state.setIn(['currentTimeSeriesView'], view);
  },
  [TIMESERIES_DETAILS_TOGGLE_EDITOR_COLLAPSE](state, action) {
    const isEditorCollapsed = state.getIn(['isEditorCollapsed']);

    return state.setIn(['isEditorCollapsed'], !isEditorCollapsed);
  },
  [TIMESERIES_DETAILS_TOGGLE_SIDEBAR_COLLAPSE](state, action) {
    const isSideBarCollapsed = state.getIn(['isSideBarCollapsed']);

    return state.setIn(['isSideBarCollapsed'], !isSideBarCollapsed);
  },
  [TIMESERIES_DETAILS_SET_FOCUS](state, action){
    return state.setIn(['timeSeriesEditor', 'inputFocusId'], action.id);
  },
  [TIMESERIES_DETAILS_SET_HAS_FOCUSED](state, action){
    return state.setIn(['timeSeriesEditor', 'inputFocusId'], '');
  },
  [TIMESERIES_DETAILS_COPY_LINK_TO_CLIPBOARD](state, action){
    let locationSearch = window.location.search ?? '';
    if (locationSearch.startsWith('?')) {
      locationSearch = locationSearch.substring(1);
    }

    const currentTimeSeriesId = state.getIn(['currentTimeSeriesId']);
    const currentTimeSeriesView = state.getIn(['currentTimeSeriesView']) || 'adjustments';
    const adjustmentsParameters = {};
    if (currentTimeSeriesView === 'adjustments'){
      const fromDate = state.getIn(['adjustmentsEditor', 'dataPointsEdit', 'filterFromUtc']);
      if (fromDate) adjustmentsParameters.fromDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');

      const toDate = state.getIn(['adjustmentsEditor', 'dataPointsEdit', 'filterToUtc']);
      if (toDate) adjustmentsParameters.toDate = moment(toDate).format('YYYY-MM-DD HH:mm:ss');

      const timeZoneId = state.getIn(['adjustmentsEditor', 'dataPointsEdit', 'filterTimezone']);
      if (timeZoneId) adjustmentsParameters.timeZoneId = state.getIn(['adjustmentsEditor', 'dataPointsEdit', 'filterTimezone']);
    }

    const searchParameters = stringify({
      ...qs.parse(locationSearch),
      ...adjustmentsParameters
    });

    const url = `${window.location.origin}/timeseries/${currentTimeSeriesId}/${currentTimeSeriesView}${searchParameters ? ('?' + searchParameters) : ''}`;
    copyToClipboard(url);
    return state;
  }
};

export const timeSeriesDetails = createReducer(null, {
  ...timeSeriesDetailsReducer,
  ...timeSeriesDetailsBasicReducer,
  ...timeSeriesDetailsAnnotationsReducer,
  ...timeSeriesDetailsCompositionReducer,
  ...timeSeriesDetailsMaterialiseReducer,
  ...timeSeriesDetailsVariantsReducer,
  ...timeSeriesDetailsDerivationReducer,
  ...timeSeriesDetailsBasedOnReducer,
  ...timeSeriesDetailsFunctionsReducer,
  ...timeSeriesDetailsCategoriesReducer,
  ...timeSeriesDetailsAdjustmentsReducer,
  ...timeSeriesDetailsToolbarReducer
});