import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import RelativeDate from '../../shared/RelativeDate';
import InfoPopup from '../../shared/InfoPopup';
import { comparisonModeOptions, comparisonModes, isComparisonModeDateOnDate } from '../../../utility/comparisonmode-utility';
import { toJS } from '../../../utility/immutable-utility';
import {
  analysisUpdateUiValues,
  analysisUpdateChartSettings,
  analysisReportCriteriaUpdateCustomPeriod,
  analysisRefresh,
  analysisToggleMaterialisationMode,
  analysisToggleVariantMode,
  analysisToggleEvolution,
  analysisExport,
  analysisChartDataExport,
  analysisRefreshTemplateNameExpressions,
  analysisReportCriteriaUpdatePeriodAbs,
  analysisWorkspaceUpdatePeriodsFromUserSettings
} from '../../../actions/analysis';
import { extractTimeSeriesItems } from '../../../utility/analysis-basket-utility';
import { analysisSetXAxisSeries } from '../../../actions/analysis-basket-v2';
import { analysisUpdateBasketFromComparisonModeSettings2 } from '../../../actions/analysis-comparisonmode-v2';
import { ComparisonsDatesPickerConnectComponent } from '../analysis-comparisons/ComparisonsDatesPickerConnectComponent';
import { ShapesPickerConnectComponent } from '../analysis-shapes/ShapesPickerConnectComponent'
import { PopoverButton } from '../../shared/PopoverButton';
import { Autocomplete, Dialog, TextField } from '@mui/material';
import { mergePeriodCollectionLists } from '../../../utility/period-utility';

const AnalysisChartSettings = connect(
  (state) => ({
    isWizard: state.getIn(['roles', 'isWizard']),
    isLoading: state.getIn(['analysis', 'chart', 'isLoading']),
    alignTimeZones: state.getIn(['analysis', 'workspace', 'alignTimeZones']),
    comparisonLenses: state.getIn(['referenceData', 'comparisonLenses']),
    timezones: state.getIn(['referenceData', 'timezones']),
    conversionUnits: state.getIn(['referenceData', 'conversionUnits']),
    operations: state.getIn(['referenceData', 'operations']),
    lens: state.getIn(['analysis', 'workspace', 'lens']),
    operation: state.getIn(['analysis', 'workspace', 'operation']),
    fromDateMode: state.getIn(['analysis', 'workspace', 'fromDateMode']),
    fromUtc: state.getIn(['analysis', 'workspace', 'fromUtc']),
    relFrom: state.getIn(['analysis', 'workspace', 'relFrom']),
    toDateMode: state.getIn(['analysis', 'workspace', 'toDateMode']),
    toUtc: state.getIn(['analysis', 'workspace', 'toUtc']),
    relTo: state.getIn(['analysis', 'workspace', 'relTo']),
    asAtUtc: state.getIn(['analysis', 'workspace', 'asAtUtc']),
    timeZoneId: state.getIn(['analysis', 'workspace', 'timeZoneId']),
    comparisonMode: state.getIn(['analysis', 'workspace', 'comparisonMode']),
    conversionUnit: state.getIn(['analysis', 'workspace', 'conversionUnit']),
    conversionFactor: state.getIn(['analysis', 'workspace', 'conversionFactor']),
    conversionUnitStrictErrors: state.getIn(['analysis', 'workspace', 'conversionUnitStrictErrors']),
    dataWindowRelativeToAsAt: state.getIn(['analysis', 'workspace', 'dataWindowRelativeToAsAt']),
    force24HourDST: state.getIn(['analysis', 'workspace', 'force24HourDST']),
    isDirty: state.getIn(['analysis', 'workspace', 'isDirty']),
    isEvolution: state.getIn(['analysis', 'workspace', 'isEvolution']),
    fromAsAtDateMode: state.getIn(['analysis', 'workspace', 'fromAsAtDateMode']),
    asAtFromUtc: state.getIn(['analysis', 'workspace', 'asAtFromUtc']),
    relAsAtFrom: state.getIn(['analysis', 'workspace', 'relAsAtFrom']),
    toAsAtDateMode: state.getIn(['analysis', 'workspace', 'toAsAtDateMode']),
    asAtToUtc: state.getIn(['analysis', 'workspace', 'asAtToUtc']),
    relAsAtTo: state.getIn(['analysis', 'workspace', 'relAsAtTo']),
    xAxisTimeSeries: state.getIn(['analysis', 'workspace', 'xAxisTimeSeries']),
    timeSeries: state.getIn(['analysis', 'workspace', 'timeseries']),
    materialisationMode: state.getIn(['analysis', 'workspace', 'materialisationMode']),
    variantMode: state.getIn(['analysis', 'workspace', 'variantMode']),
    seriesStyle: state.getIn(['analysis', 'workspace', 'seriesStyle']),
    isPeriodEditorVisible: state.getIn(['analysis', 'ui', 'isPeriodEditorVisible']),
    period: state.getIn(['analysis', 'workspace', 'period']),
    userSettingPeriods: state.getIn(['analysis', 'ui', 'periodsUserSettings']),
    referenceDataPeriods: state.getIn(['analysis', 'ui', 'periodsReferenceData']),
    workspacePeriods: state.getIn(['analysis', 'workspace', 'periods']),
    periodsUnsyncronised: state.getIn(['analysis', 'ui', 'periodsUnsyncronised'])
  }),
  (dispatch) => ({
    updateUiValues(list){
      dispatch(analysisUpdateUiValues(list));
    },
    updateChartSettings(key, value) {
      dispatch(analysisUpdateChartSettings(key, value));
    },
    setPeriod(period) {
      dispatch(analysisUpdateChartSettings('period', period));
      dispatch(analysisReportCriteriaUpdatePeriodAbs());
    },      
    updateCustomPeriod(key, value) {
      dispatch(analysisReportCriteriaUpdateCustomPeriod(key, value));
    },        
    updateComparisonModeSetting2(key, value,) {
      dispatch(analysisUpdateChartSettings(key, value));
      dispatch(analysisUpdateBasketFromComparisonModeSettings2());
      dispatch(analysisRefreshTemplateNameExpressions());
    },
    updateXAxisSeries(value) {
      dispatch(analysisSetXAxisSeries(value));
      dispatch(analysisRefreshTemplateNameExpressions());
    },
    refresh() {
      dispatch(analysisRefresh());
    },
    tableExport() {
      dispatch(analysisChartDataExport());
    },
    excelExport() {
      dispatch(analysisExport());
    },
    toggleEvolution() {
      dispatch(analysisToggleEvolution());
    },
    toggleMaterialisationMode() {
      dispatch(analysisToggleMaterialisationMode());
    },
    toggleVariantMode() {
      dispatch(analysisToggleVariantMode());
    },
    updatePeriodsFromUserSettings(update){
      dispatch(analysisWorkspaceUpdatePeriodsFromUserSettings(update));
      dispatch(analysisReportCriteriaUpdatePeriodAbs());
    }
  })
)(({ isEvolution, alignTimeZones, isLoading, isDirty,
  comparisonLenses, operations, timezones, operation, lens, 
  fromDateMode, fromUtc, relFrom, toDateMode, toUtc, relTo,
  fromAsAtDateMode, asAtFromUtc, relAsAtFrom,
  toAsAtDateMode, asAtToUtc, relAsAtTo, dataWindowRelativeToAsAt,
  asAtUtc, timeZoneId, updateChartSettings, refresh, excelExport, tableExport, materialisationMode, variantMode,
  toggleEvolution, toggleMaterialisationMode, toggleVariantMode, toggleIsPinned, updateComparisonModeSetting2, comparisonMode, conversionUnits, conversionUnit, conversionFactor,
  conversionUnitStrictErrors, force24HourDST, xAxisTimeSeries, updateXAxisSeries,
  timeSeries, isWizard, seriesStyle,
  updateUiValues, 
  setPeriod, updateCustomPeriod,
  isPeriodEditorVisible = true, period, referenceDataPeriods, userSettingPeriods, workspacePeriods, periodsUnsyncronised, updatePeriodsFromUserSettings }) => {
  
  const enableEvolution = true;
  const showForce24HourDST = lens === 'Hour' || lens === 'HalfHour' || lens === 'QuarterHour';

  const _periods = mergePeriodCollectionLists(
    referenceDataPeriods ? referenceDataPeriods.toJS() : undefined,
    userSettingPeriods ? userSettingPeriods.toJS() : undefined,
    workspacePeriods ? workspacePeriods.toJS() : undefined,
  );
  const _comparisonLenses = useMemo(() => toJS(comparisonLenses, {}), [comparisonLenses]);
  const _conversionUnits = useMemo(() => toJS(conversionUnits, []), [conversionUnits]);
  const _operations = useMemo(() => toJS(operations, {}), [operations]);
  const _xAxisInfo = useMemo(() => {
    const ts = extractTimeSeriesItems({ basket:toJS(timeSeries, []) });
    const response = {
      availableTimeSeries: ts.map(ts => ({...ts, name:ts.sourceTimeSeriesName ?? ts.name})),
      xAxisTimeSeries: toJS(xAxisTimeSeries)
    };
    return response;
  }, [timeSeries, xAxisTimeSeries]);

  const onApply = useCallback(e => {
    if (e)
      e.preventDefault();
      refresh();
  }, [refresh]);

  const onChangeAlignTimeZones = useCallback((e) => {
    updateChartSettings('force24HourDST', e.target.checked);
    updateChartSettings('alignTimeZones', e.target.checked);
  }, [updateChartSettings]);

  const onChangeForce24HourDST = useCallback((e) => {
    updateChartSettings('force24HourDST', e.target.checked);
  }, [updateChartSettings]);

  const setComparisonMode = useCallback((e) => {
    let mode = e.target.value;
    let targetModeLenses = _comparisonLenses[mode || 'none'];

    if (targetModeLenses.indexOf(lens) < 0)
      updateChartSettings('lens', targetModeLenses[targetModeLenses.length - 1]);

    updateComparisonModeSetting2('comparisonMode', mode);
  }, [_comparisonLenses, updateChartSettings, updateComparisonModeSetting2, lens]);

  let _lenses = [];
  if (_comparisonLenses && _comparisonLenses[comparisonMode || 'none']) {
    _lenses = _comparisonLenses[comparisonMode || 'none'];
  }
  
  return <>
      {periodsUnsyncronised && <Dialog open={true}>
        <div data_id='update-user-setting-periods' className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Updated Periods</h5>
          </div>
          <div className='modal-body'>
              Do you want to apply your updated User Setting Periods to this workspace?
          </div>
          <div className='modal-footer'>
            <button data_id='discard' type='button' className='btn btn-secondary' disabled={isLoading} data-dismiss='modal' onClick={() => updatePeriodsFromUserSettings(false)} >Discard Changes</button>
            <button data_id='apply' type='button' className='btn btn-primary' disabled={isLoading} data-dismiss='modal' onClick={() => updatePeriodsFromUserSettings(true)} >Apply Changes</button>
          </div>
        </div>
      </Dialog>}

      <div data_id='report-criteria' style={{height:'100%', width:'100%', }}>
        <div className='d-flex justify-content-center align-items-center w-100 p-1' style={{ position: 'relative' }}>
          <h6 className='mb-0'>Report Criteria</h6>
          <button data_id='pin-report-criteria' className='btn btn-sm btn-xs btn-outline-primary m-1' type='button' onClick={toggleIsPinned} style={{ position: 'absolute', top: '0', right: '0' }}>
            <i className='fas fa-thumbtack fa-fw'></i>
          </button>
        </div>

        <div className='w-100 mt-3' style={{ height:'calc(100% - 80px)', overflowY: 'scroll', flexGrow: 1 }}>
          <form onSubmit={onApply} className='px-2'>
            <>
              <div className='form-row pb-1'>
                <label className='col-sm-3 font-weight-bold mt-2'>Comparison</label>
                <div className="col-sm-9">
                  <select data_id='comparision-mode' className='form-control'
                          value={comparisonMode}
                          onChange={setComparisonMode}>
                            {comparisonModeOptions.map(c => <option key={c.value} value={c.value}>{c.name}</option>)}
                  </select>
                </div>
              </div>

              <div className='form-row pb-1'>
                <label className='col-sm-3 font-weight-bold mt-2'>Lens</label>
                <div className="col-sm-9">
                  <select data_id='lens' className='form-control'
                          value={lens}
                          onChange={e => updateChartSettings('lens', e.target.value)}>
                    {_lenses.map(lens => <option key={lens} value={lens}>{lens}</option>)}
                  </select>
                </div>
              </div>

              <div className='form-row pb-1'>
                <label className='col-sm-3 font-weight-bold mt-2'>Operation</label>
                <div className="col-sm-9">
                  <select data_id='operation' className='form-control'
                    value={operation}
                    onChange={e => updateChartSettings('operation', e.target.value)}>
                    {_operations.map(operation => <option key={operation} value={operation}>{operation}</option>)}
                  </select>
                </div>
              </div>

              <div className='form-row pb-1'>
                <label className='col-sm-3 font-weight-bold mt-2'>Shape</label>
                <div className="col-sm-9">
                  <ShapesPickerConnectComponent />
                </div>
              </div>             
            </>

            {!isComparisonModeDateOnDate(comparisonMode) && (
            <>
              <div className='mb-2'>
                <div className='form-row'>
                  <label className='col-sm-3 font-weight-bold mt-2'>Period{period && !_periods.find(p => p.name === period) && <InfoPopup iconClassName={'text-warning'} faIconClassName={'fa-exclamation-triangle'}>The period '{period}' is not defined</InfoPopup>}</label>
                  <div className='col-sm-9 d-flex mui-default-md' style={{height:'35px'}}>
                    <Autocomplete 
                      className={`form-control form-control-no-border no-border-bottom-right-radius no-border-top-right-radius ${isPeriodEditorVisible ? 'no-border-bottom-left-radius ': ''}`}
                      data_id='period' 
                      disableClearable={true}                      
                      value={period ?? ''}
                      sx={{ padding: 0 }}
                      onChange={(event, newValue) => {
                        setPeriod(newValue.id);
                      }}
                      options={_periods.map(p => ({ label: p.name, id: p.name}))}
                      isOptionEqualToValue={(o,v) => o.id === v} 
                      renderOption={(props, option) => (
                        <div {...props} className='text-nowrap MuiAutocomplete-option'>                
                          {option.label}
                        </div>
                      )}

                      renderInput={(item) => <TextField {...item} />}
                    />
                    <div className={`btn btn-sm btn-secondary user-select-none no-border-top-left-radius no-border-bottom-left-radius ${isPeriodEditorVisible ? 'no-border-bottom-right-radius' : ''}`} onClick={e => updateUiValues([[['isPeriodEditorVisible'], !isPeriodEditorVisible]])}>
                      <i className={'mt-2 fas fa-fw ' + (isPeriodEditorVisible ? 'fa-chevron-up' : 'fa-chevron-down')} />
                    </div>
                  </div>
                </div>

                <div className='pt-2 px-2 drop-shadow' style={ {display: isPeriodEditorVisible ? 'inherit' : 'none', marginTop:'1px'}} >
                  <RelativeAbsDateInput data_id='date-from' name='From'
                                        onChangeDateMode={value => updateCustomPeriod('fromDateMode', value)}
                                        onUpdateAbsValue={value => updateCustomPeriod('fromUtc', value)}
                                        onUpdateRelValue={value => updateCustomPeriod('relFrom', value)}
                                        dateMode={fromDateMode}
                                        absDate={fromUtc}
                                        relDate={relFrom}/>

                  <RelativeAbsDateInput data_id='date-to' name='To'
                                        onChangeDateMode={value => updateCustomPeriod('toDateMode', value)}
                                        onUpdateAbsValue={value => updateCustomPeriod('toUtc', value)}
                                        onUpdateRelValue={value => updateCustomPeriod('relTo', value)}
                                        dateMode={toDateMode}
                                        absDate={toUtc}
                                        relDate={relTo}/>
                </div>
              </div>

                <div className='form-row pb-1'>
                  <label className='col-sm-3 font-weight-bold'>As At</label>
                  <div className="col-sm-9">
                    <input data_id='as-at' className='form-control' type='datetime-local' min="0001-01-01T00:00" max="9999-12-31T23:59"
                      value={asAtUtc}
                      onChange={e => updateChartSettings('asAtUtc', e.target.value)} />
                  </div>
                </div>        
              </>
            )}

            <div className='form-row'>
              <label className='col-sm-3 font-weight-bold'>Timezone</label>
                <div className='form-check ml-2 mr-2'>
                  <input data_id='align-timezone' type='checkbox' className='form-check-input' id='align-tz-toggle' checked={alignTimeZones} onChange={onChangeAlignTimeZones} />
                  <label className='form-check-label' htmlFor='align-tz-toggle'>Align</label>
                </div>
                {showForce24HourDST && 
                  <div className='form-check mr-2'>
                    <input data_id='force-24-hour-dst' type='checkbox' className='form-check-input' id='force24HourDST-toggle' checked={force24HourDST} onChange={onChangeForce24HourDST} />
                    <label className='form-check-label' htmlFor='force24HourDST-toggle'>Force 24 Hour DST</label>
                  </div>
                }
                <InfoPopup>
                  Align will shift all time series to the timezone of the most common time series selected<br />
                  Force 24 Hour DST will always return a 24 hr day regardless of clock change
                </InfoPopup>
            </div>

            {!alignTimeZones && 
              <div className='form-row'>
                <div className='col-sm-3'/>
                <div className='col-sm-9'>
                  <select data_id='timezone' className='form-control'
                    value={timeZoneId}
                    onChange={e => updateChartSettings('timeZoneId', e.target.value)}>
                    {timezones.map(timezone => <option key={timezone} value={timezone}>{timezone}</option>)}
                  </select>
                </div>
              </div>
            }    

            {(comparisonMode !== comparisonModes.none) &&
              <div className='form-row pb-1 mt-2'>
                <label className='col-sm-3 font-weight-bold mt-2'>Dates</label>
                  <div className="col-sm-9">
                    <ComparisonsDatesPickerConnectComponent/>
                  </div>
              </div>   
            }

            <>
              <div className='form-row  mt-2'>
                <div className='col-md-4'>
                  <label className='font-weight-bold'>Unit</label>
                  <select data_id='conversion-unit' className='form-control' value={conversionUnit} onChange={e => updateChartSettings('conversionUnit', e.target.value)}>
                    <option key='none' value=''></option>
                    {_conversionUnits && _conversionUnits.map(cu =>
                      <optgroup key={cu.group} label={cu.group}>
                        {cu.units && cu.units.map(u => <option key={u.name} value={u.name}>{u.name}</option>)}
                      </optgroup>
                    )}
                  </select>
                </div>
                <div className='col-md-4'>
                  <label className='font-weight-bold'>Factor</label>
                  <input data_id='conversion-factor' type='number' className='form-control' value={conversionFactor} onChange={e => updateChartSettings('conversionFactor', e.target.value)} />
                </div>
                <div className='col-md-4 form-group'>
                  <InfoPopup>
                    Strict: if unit conversion fails an error will be returned and no data<br />
                    Loose: if unit conversion fails a warning will be returned and original unconverted values
                  </InfoPopup>
                  <br />
                  <div className='form-check d-inline-block'>
                    <input data_id='is-loose-conversions' className='form-check-input' type='radio' id='is-loose-conversions'
                      checked={!conversionUnitStrictErrors}
                      onChange={e => updateChartSettings('conversionUnitStrictErrors', false)} />
                    <label className='form-check-label' htmlFor='is-loose-conversions'>Loose</label>
                  </div>
                  <div className='form-check d-inline-block ml-2'>
                    <input data_id='is-strict-conversions' className='form-check-input' type='radio' id='is-strict-conversions'
                      checked={conversionUnitStrictErrors}
                      onChange={e => updateChartSettings('conversionUnitStrictErrors', true)} />
                    <label className='form-check-label' htmlFor='is-strict-conversions'>Strict</label>
                  </div>
                </div>
              </div>
            </>

            <>
              <div className='form-row mt-2'>
                <label className='font-weight-bold col-md-12'>X-Axis</label>
                <select data_id='x-axis' className='form-control'
                  value={_xAxisInfo && _xAxisInfo.xAxisTimeSeries ? _xAxisInfo.xAxisTimeSeries.identityId : ''}
                  onChange={e => updateXAxisSeries(e.target.value ? Number(e.target.value) : undefined)}>
                  <option key='none' value='' ></option>
                  {_xAxisInfo && _xAxisInfo.availableTimeSeries.map(ts => <option key={ts.identityId} value={ts.identityId}>{ts.name}</option>)}
                </select>
              </div>
            </>

          {isEvolution
              ?
              <>
                <div className='mt-5'>
                  <div className='form-row mb-2'>
                    <label className='col-sm-3 font-weight-bold'>Evolution
                      <InfoPopup>
                        All items in the basket will be returned with the asAt on the x-axis<br/>
                        A derived time series will require a lens to be specified as they do not have actual asAts<br/>
                        To view asAt evolution of individual items, edit from basket and enable AsAt Window                      
                      </InfoPopup>
                    </label>
                      <div className='form-check ml-2 mr-2'>
                        <input data_id='is-relative-to-asat' type='checkbox' className='form-check-input' id='relasat-toggle' checked={dataWindowRelativeToAsAt}  onChange={e => updateChartSettings('dataWindowRelativeToAsAt', e.target.checked)} />
                        <label className='form-check-label' htmlFor='relasat-toggle'>Data relative to asAt</label>
                      </div>
                      <InfoPopup>
                        When selected any relative from or to dates will be relative to each asAt returned
                      </InfoPopup>
                  </div>

                  <RelativeAbsDateInput data_id='asat-from' name='As At From'
                                        onChangeDateMode={value => updateChartSettings('fromAsAtDateMode', value)}
                                        onUpdateAbsValue={value => updateChartSettings('asAtFromUtc', value)}
                                        onUpdateRelValue={value => updateChartSettings('relAsAtFrom', value)}
                                        dateMode={fromAsAtDateMode}
                                        absDate={asAtFromUtc}
                                        relDate={relAsAtFrom}/>

                  <RelativeAbsDateInput data_id='asat-to' name='As At To'
                                        onChangeDateMode={value => updateChartSettings('toAsAtDateMode', value)}
                                        onUpdateAbsValue={value => updateChartSettings('asAtToUtc', value)}
                                        onUpdateRelValue={value => updateChartSettings('relAsAtTo', value)}
                                        dateMode={toAsAtDateMode}
                                        absDate={asAtToUtc}
                                        relDate={relAsAtTo}/>             
                </div>
              </>              
              :
              <div />
            }

            <div className='form-row mt-4' >
              <label className='col-sm-4 font-weight-bold'>Series style</label>
              <div className='form-check ml-2 mr-2'>
                <input data_id='series-style-master-build' className='form-check-input' type='radio' id='timeseries-series-style-default' name='series-build-style'
                  value='masterBuild' checked={!seriesStyle || seriesStyle === 'masterBuild'}
                  onChange={e => updateChartSettings('seriesStyle', e.target.value)} />
                <label className='form-check-label' htmlFor='timeseries-series-style-default'>Default</label>
              </div>
              <div className='form-check ml-2 mr-2'>
                <input data_id='series-style-just-instance' className='form-check-input' type='radio' id='timeseries-series-style-instance' name='series-build-style'
                  value='justInstance' checked={seriesStyle === 'justInstance'}
                  onChange={e => updateChartSettings('seriesStyle', e.target.value)} />
                <label className='form-check-label' htmlFor='timeseries-series-style-instance'>JustInstance</label>
              </div>
              <InfoPopup>
                Default: data is a blend of master or instances<br />
                JustInstance: data is for the select instance only
              </InfoPopup>
            </div>           

            {isWizard && (
              <>
              <div className='form-row mt-4'>
                <div className="form-check form-switch">
                  <input data_id='disable-materialisation-mode' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckMateialisationMode" checked={materialisationMode === 'Disabled'} onChange={toggleMaterialisationMode} />
                  <label className="form-check-label" htmlFor="flexSwitchCheckMateialisationMode">Disable materialisation mode</label>
                </div>
              </div>
              <div className='form-row mt-2'>
                <div className="form-check form-switch">
                  <input data_id='disable-variant-mode' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckVariantMode" checked={variantMode === 'Disabled'} onChange={toggleVariantMode} />
                  <label className="form-check-label" htmlFor="flexSwitchCheckVariantMode">Disable variant mode</label>
                </div>
              </div>              
            </>
            )}
            
            <div className='form-group float-right d-none'>
              <button data_id='refresh' type='submit' className={`btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`}>
                <i className='fas fa-sync-alt fa-fw' />
                <span className='ml-2'>Refresh</span>
              </button>
            </div>
          </form>
        </div>

        <div className='w-100'>
          {comparisonMode === 'none' && enableEvolution
            ? <button data_id='show-evolution' type='button' className={'btn btn-secondary float-left m-1'} onClick={toggleEvolution}>
              <span className='ml-2'>{isEvolution ? 'Hide evolution' : 'Show evolution'}</span>
            </button>
            : <div />
          }
          <div className='btn-group float-right m-1 mb-2'>
            <button data_id='export-table' type='button' className='btn btn-outline-primary text-nowrap' onClick={_ => tableExport()}>
              <i className='fas fa-download fa-fw' />
              <span className='ml-2'>Export</span>
            </button>
            <div className="btn-group btn-group-sm">
              <PopoverButton data_id='export-options' buttonClassName='btn btn-outline-primary btn-sm p-1' buttonContent={<><i className='fas fa-caret-down fa-fw ' /></>}>
                <div className='d-flex flex-column '>
                  <button data_id='export-table' type='button' className='btn text-nowrap dropdown-item' onClick={_ => tableExport()}>
                    <i className='fas fa-download fa-fw' />
                    <span className='ml-2'>Export</span>
                  </button>
                  <button data_id='export-excel' type='button' className='btn text-nowrap dropdown-item' onClick={_ => excelExport()}>
                    <i className='fas fa-download fa-fw' />
                    <span className='ml-2'>Direct Export</span>
                  </button>
                </div>
              </PopoverButton>
            </div>
            <button data_id='refresh' type='button' className={`btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={_ => onApply()}>
              <i className='fas fa-sync-alt fa-fw' />
              <span className='ml-2'>Refresh</span>
            </button>
          </div>
        </div>
      </div>
    </>  
});

export default AnalysisChartSettings;

function RelativeAbsDateInput({name, onChangeDateMode, onUpdateAbsValue, onUpdateRelValue, dateMode, absDate, relDate, data_id}){
  const id = name.replace(' ', '-');
  return <>
    <div className='form-row pb-2'>
      <label className='col-sm-3 font-weight-bold'>{name}</label>
      <div className="col-sm-9 d-flex">
        <div style={{width:'100%'}}>
          {(!dateMode || dateMode === 'abs') && <input data_id={`${data_id}-abs`} className='form-control' type='datetime-local' min="0001-01-01T00:00" max="9999-12-31T23:59"
              value={absDate}
              onChange={e => onUpdateAbsValue(e.target.value)} />}

          {dateMode === 'rel' && <RelativeDate data_id={`${data_id}-rel`} value={relDate} onChange={onUpdateRelValue} options = {['D', 'W', 'M', 'Y']} />}
        </div>
        <div className='form-check ml-1'>
          <input data_id={`${data_id}-mode-abs`} className='form-check-input' type='radio' value='none' name={`datemode-abs-${id}`} id={`datemode-abs-${id}`}
            checked={dateMode === 'abs'}
            onChange={() => onChangeDateMode('abs')} />
          <label className='form-check-label' htmlFor={`datemode-abs-${name}`} >Abs</label>
        </div>
        <div className='form-check ml-1'>
          <input data_id={`${data_id}-mode-rel`} className='form-check-input' type='radio' value='none' name={`datemode-rel-${id}`} id={`datemode-rel-${id}`}
            checked={dateMode === 'rel'}
            onChange={() => onChangeDateMode('rel')} />
          <label className='form-check-label' htmlFor={`datemode-rel-${id}`}>Rel</label>
        </div>
      </div>
    </div>
  </>;
}