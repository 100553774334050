import React, { useCallback } from 'react';
import Timezone from '../../shared/Timezone';
import IdentityId from '../../shared/IdentityId2';
import FormattedDateTime from '../../shared/FormattedDateTime';
import FormattedNumber from '../../shared/FormattedNumber';

import { DebugPopup } from '../../shared/DebugPopup'
import { SearchResultCategoriesConnected } from './SearchResultCategoriesConnected';
import { SearchResultInfoConnected } from './SearchResultInfoConnected';
import { SearchResultStatisticsConnected } from './SearchResultStatisticsConnected';

import AnalysisCompositionTreeConnected from '../analysis/AnalysisCompositionTree';
import JsonEditor from '../../shared/JsonEditor';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { getSchemaColour } from '../../../utility/analysis-search-utility';

export const AnalysisSearchColumns = [
  null, 
  {
    key: 'id',
    title: 'Id'
  }, {
    key: 'source',
    title: 'Source',
    sortable: false
  }, {
    key: 'name',
    title: 'Name',
    sortable: true
  }, 
  null, {
    key: 'dataType',
    title: 'Type',
    filterable: true,
    style: {
      minWidth: 85
    },
    sortable: false
  }, {
    key: 'style',
    title: 'Style',
    filterable: true,
    style: {
      minWidth: 60
    },
    sortable: false
  }, {
    key: 'derivationType',
    title: 'Derivation Type',
    filterable: true,
    style: {
      minWidth: 120
    },
    sortable: false
  }, {
    key: 'granularity',
    title: 'Granularity',
    style: {
      minWidth: 80
    },
    sortable: false
  }, {
    key: 'sourceTimeZoneId',
    title: 'Source Timezone',
    style: {
      minWidth: 115
    },
    sortable: false
  }, {
    key: 'unit',
    title: 'Unit',
    style: {
      minWidth: 40
    },
    sortable: false
  }, {
    key: 'lastUpdatedUtc',
    title: <span>Last Updated <Timezone /></span>,
    sortable: true,
    style: {
      minWidth: 140
    }
  }, {
    key: 'queryCount',
    title: 'Queries',
    filterable: true,
    sortable: true,
    style: {
      minWidth: 85
    },
  }, null, null];

export const AnalysisSearchColumnsCompact = [{
  key: 'id',
  title: 'Id'
}, {
  key: 'source',
  title: 'Source'
}, {
  key: 'name',
  title: 'Name',
  sortable: true
}, {
  key: 'dataType',
  title: 'Type',
  style: {
    minWidth: 85
  }
}, {
  key: 'style',
  title: 'Style',
  style: {
    minWidth: 60
  }
}, {
  key: 'granularity',
  title: 'Granularity',
  style: {
    minWidth: 80
  }
}, {
  key: 'sourceTimeZoneId',
  title: 'Source Timezone',
  style: {
    minWidth: 115
  }
}, {
  key: 'unit',
  title: 'Unit',
  style: {
    minWidth: 40
  }
}, {
  key: 'lastUpdatedUtc',
  title: <span>Last Updated <Timezone /></span>,
  sortable: true,
  style: {
    minWidth: 140
  }
}, {
  key: 'queryCount',
  title: 'Queries',
  sortable: true,
  style: {
    minWidth: 85
  }
}, null, null];

export const SearchResultRow = function ({ stateContext, allSchemas, toggleExpand, toggleExpandDetails, toggleSelection,
  cellData, isActiveTest, addFavourite, removeFavourite, isCompactView, addtionalResultRowDisplay, data_id }) {
  cellData = cellData.toJS()
  const { id, source, name, dataType, style, granularity, unit, sourceTimeZoneId, derivationType, lastUpdatedUtc, queryCount,
    isFavourite = false, isExpanded = false, index, isDetailsExpanded = false, displaySchemas = [] } = cellData;

  const isActive = isActiveTest && isActiveTest(id);
  const canToggleFavourites = !isCompactView && !!addFavourite && !!removeFavourite;
  const onToggleFavourite = useCallback(e => {
    e.stopPropagation();

    isFavourite ? removeFavourite(id) : addFavourite(id);
  }, [addFavourite, removeFavourite, id, isFavourite]);

  const columnStyles = Object.fromEntries(AnalysisSearchColumns.filter(i => i).map(({ key, style }) => [key, style]));

  return <>
      <TableRow data_id={data_id} onClick={() => toggleSelection(id, cellData)}>
          {!isCompactView && <TableCell className='p-0'>
            <button data_id='toggle-expand' type='button' className='btn btn-sm btn-link' onClick={e => e.stopPropagation() || toggleExpand(id)}>
              <i className={'fas fa-fw ' + (isExpanded ? 'fa-chevron-down' : 'fa-chevron-right')} />
            </button>
          </TableCell>}
          <TableCell className='text-right'>
            <IdentityId isFavourite={isFavourite} isCompactView={isCompactView}>{id}</IdentityId>
          </TableCell>
          <TableCell data_id='source' className='text-nowrap'>{source}</TableCell>
          <TableCell data_id='name' className='w-100'>
            <DebugPopup>{() => <JsonEditor data={cellData}/>}</DebugPopup>{name}
            {addtionalResultRowDisplay !== undefined && <>{addtionalResultRowDisplay(cellData)}</>}
          </TableCell>
          <TableCell data_id='schemas' className='text-nowrap'>
            {displaySchemas.map((x, xi) => <div data_id={`schema-${xi}`} key={`s${xi}`} style={getSchemaColour(x, allSchemas)}><span data_id='name'>{x}</span></div>)}
          </TableCell>
          <TableCell style={columnStyles.dataType}>{dataType}</TableCell>
          <TableCell style={columnStyles.style}>{style}</TableCell>
          <TableCell className='text-nowrap' style={columnStyles.derivationType}>{derivationType}</TableCell>
          <TableCell className='text-nowrap' style={columnStyles.granularity}>{granularity}</TableCell>
          <TableCell className='text-nowrap' style={columnStyles.sourceTimeZoneId}>{sourceTimeZoneId}</TableCell>
          <TableCell className='text-nowrap' style={columnStyles.unit}>{unit}</TableCell>
          <TableCell className='text-nowrap text-right' style={columnStyles.lastUpdatedUtc}>
            <FormattedDateTime>{lastUpdatedUtc}</FormattedDateTime>
          </TableCell>
          <TableCell className='text-right' style={columnStyles.queryCount}>
            <FormattedNumber>{queryCount}</FormattedNumber>
          </TableCell>
          {canToggleFavourites ? (
            <TableCell data_id='favourite-toggle' className='text-primary user-select-none cursor-pointer' style={{ minWidth: 30 }} onClick={onToggleFavourite}>
              <i className='fas fa-star fa-lg fa-fw' style={{ color: isFavourite ? 'gold' : 'rgba(0, 0, 0, .1)' }} />
            </TableCell>
          ) : <TableCell className='text-primary' style={{ minWidth: 30 }} />}
          <TableCell className='text-primary' style={{ minWidth: 30 }}>
            <i className={'fas fa-check-circle fa-lg fa-fw ' + (isActive ? 'visible' : 'invisible')} />
          </TableCell>
        </TableRow>
        
        { isExpanded && (
          <TableRow>
            <TableCell className='border-bottom-0' />
            <TableCell className='border-bottom-0 p-0 text-left' colSpan={AnalysisSearchColumns.length - 1}>
              <button type='button' className='btn btn-sm btn-link w-100 text-left text-decoration-none text-dark' onClick={e => e.stopPropagation() || toggleExpandDetails(id)}>
                <i className={'fas fa-fw ' + (isDetailsExpanded ? 'fa-chevron-down' : 'fa-chevron-right')} /> <strong>Time series details</strong>
              </button>
            </TableCell>
          </TableRow>
        ) }
        { (isExpanded && isDetailsExpanded) && <>
          <TableRow>
            <TableCell className='border-0' colSpan='2' />
            <TableCell className='border-0' colSpan={AnalysisSearchColumns.length - 2}>
              <div className='d-flex flew-row flex-md-nowrap fota-cross-schema-search-row-details'>
                <div className='fota-cross-schema-search-row-detail-view' >
                  <SearchResultCategoriesConnected stateContext={stateContext} index={index} id={id} />
                </div>
                <div className='fota-cross-schema-search-row-detail-view' >
                  <SearchResultStatisticsConnected stateContext={stateContext} index={index} id={id} />
                </div>
                <div className='fota-cross-schema-search-row-detail-view' >
                  <SearchResultInfoConnected stateContext={stateContext} index={index} id={id} />
                </div>
              </div>
            </TableCell>
          </TableRow>
        </>}
      
      {(isExpanded) && <>
        <AnalysisCompositionTreeConnected id={id} />
      </>}
    </>
}