import { createReducer } from '../utility/redux-utility';
import { fromJS } from 'immutable';
import {
  SCREEN_CAPTURE_SET_DEFAULTS,
  SCREEN_CAPTURE_CLEAR,
  SCREEN_CAPTURE_LOAD_STARTED,
  SCREEN_CAPTURE_LOAD_STOPPED,
  SCREEN_CAPTURE_LOAD_COMPLETE,
  SCREEN_CAPTURE_SAVE_EDIT_STARTED,
  SCREEN_CAPTURE_SAVE_EDIT_STOPPED,
  SCREEN_CAPTURE_SAVE_EDIT_COMPLETE,
  SCREEN_CAPTURE_SELECT,
  SCREEN_CAPTURE_OPEN_NEW_EDIT,
  SCREEN_CAPTURE_OPEN_EDIT,
  SCREEN_CAPTURE_CLOSE_EDIT,
  SCREEN_CAPTURE_EDIT_PROPERTY_CHANGE,
  SCREEN_CAPTURE_SET_WARNING
} from '../actions/screenCapture';

function validateEditForm({ cronSpec, recipients, subject, targetUrl }) {
  return cronSpec && recipients && subject && targetUrl;
}

export const screenCapture = createReducer(null, {
  [SCREEN_CAPTURE_SET_DEFAULTS](state, action) {
    return state.set('defaults', fromJS(action.data));
  },
  [SCREEN_CAPTURE_CLEAR](state, action) {
    return state.set('hasWarning', false)
                .set('warningMessage', '')
                .set('isVisible', false)
                .set('isLoading', false)
                .set('isEditing', false)
                .set('actions', fromJS([]))
                .set('selectedId', 0)
                .setIn(['edit', 'id'], 0)
                .setIn(['edit', 'isEnabled'], false)
                .setIn(['edit', 'isValidForm'], false)
                .setIn(['edit', 'cronSpec'], '')
                .setIn(['edit', 'recipients'], '')
                .setIn(['edit', 'subject'], '')
                .setIn(['edit', 'targetUrl'], '');
  },
  [SCREEN_CAPTURE_LOAD_STARTED](state, action) {
    return state.set('isVisible', true)
                .set('isLoading', true);
  },
  [SCREEN_CAPTURE_LOAD_STOPPED](state, action) {
    return state.set('isLoading', false);
  },
  [SCREEN_CAPTURE_LOAD_COMPLETE](state, action) {
    const data = action.data.map(i => ({ ...i, isInvalid: i.parameters.pageCaptures.length > 1 }));
    return state.set('isLoading', false)
                .set('actions', fromJS(data));
  },
  [SCREEN_CAPTURE_SAVE_EDIT_STARTED](state, action) {
    return state.set('isSaving', true);
  },
  [SCREEN_CAPTURE_SAVE_EDIT_STOPPED](state, action) {
    return state.set('isSaving', false);
  },
  [SCREEN_CAPTURE_SAVE_EDIT_COMPLETE](state, action) {
    return state.set('isSaving', false);
  },
  [SCREEN_CAPTURE_SELECT](state, action) {
    return state.get('selectedId') === action.id
      ? state.set('selectedId', 0)
      : state.set('selectedId', action.id);
  },
  [SCREEN_CAPTURE_OPEN_NEW_EDIT](state, action) {
    const { cronSpec, recipients, subject, targetUrl } = state.get('defaults').toJS();
    const isValidForm = validateEditForm({ cronSpec, recipients, subject, targetUrl });
    return state.set('isEditing', true)
                .set('selectedId', 0)
                .setIn(['edit', 'id'], 0)
                .setIn(['edit', 'isEnabled'], true)
                .setIn(['edit', 'isValidForm'], isValidForm)
                .setIn(['edit', 'cronSpec'], cronSpec)
                .setIn(['edit', 'recipients'], recipients)
                .setIn(['edit', 'subject'], subject)
                .setIn(['edit', 'targetUrl'], targetUrl);
  },
  [SCREEN_CAPTURE_OPEN_EDIT](state, action) {
    const editAction = state.get('actions').find(i => i.get('id') === action.id);
    const { id, isEnabled, cronSpec, recipients, subject, targetUrl } = editAction.toJS();
    const isValidForm = validateEditForm({ cronSpec, recipients, subject, targetUrl });
    return state.set('isEditing', true)
                .set('selectedId', 0)
                .setIn(['edit', 'id'], id)
                .setIn(['edit', 'isEnabled'], isEnabled)
                .setIn(['edit', 'isValidForm'], isValidForm)
                .setIn(['edit', 'cronSpec'], cronSpec)
                .setIn(['edit', 'recipients'], recipients)
                .setIn(['edit', 'subject'], subject)
                .setIn(['edit', 'targetUrl'], targetUrl);
  },
  [SCREEN_CAPTURE_CLOSE_EDIT](state, action) {
    return state.set('isEditing', false)
                .setIn(['edit', 'id'], 0)
                .setIn(['edit', 'isEnabled'], false)
                .setIn(['edit', 'isValidForm'], false)
                .setIn(['edit', 'cronSpec'], '')
                .setIn(['edit', 'recipients'], '')
                .setIn(['edit', 'subject'], '')
                .setIn(['edit', 'targetUrl'], '');
  },
  [SCREEN_CAPTURE_EDIT_PROPERTY_CHANGE](state, action) {
    const editForm = state.get('edit').toJS();
    const isValidForm = validateEditForm({ ...editForm, [action.key]: action.value });
    return state.setIn(['edit', action.key], action.value)
                .setIn(['edit', 'isValidForm'], isValidForm);
  },
  [SCREEN_CAPTURE_SET_WARNING](state, action) {
    return state.set('hasWarning', true)
                .set('warningMessage', action.message);
  }
});