export const NATIVE_SCHEMA = '_legacy';
export const SOURCE_CATEGORY_SCHEMA = '_sourceCategory';
export const DYNAMIC_WORKSPACE_SEARCH_CRITERIA_STATE_CONTEXT = 'dynamicWorkspaceDesign';

export function formatTitle(name) {
  switch (name) {
    case NATIVE_SCHEMA:
      return 'Native';
    case SOURCE_CATEGORY_SCHEMA:
      return 'Source';
    default:
      return name;
  }
}

export function encodeCriteriaToUrlArgs(urlCriteria = {}, previousUrlArgs, doNotMapUrlCriteria) {
  const criteria = doNotMapUrlCriteria ? urlCriteria : mapStateToUrlCriteria(urlCriteria);
  const urlArgs = new URLSearchParams(previousUrlArgs ?? '');
  urlArgs.set('onlyFavourites', criteria.onlyFavourites === true);

  urlArgs.set('enableDiscontinued', criteria.enableDiscontinued === true);

  if (window.__APIKEY__)
    urlArgs.set('_ak', window.__APIKEY__);

  if (window.__IS_SCREEN_CAPTURE_ALLOWED)
    urlArgs.set('_no_screen_capture', 'true');

  if (criteria.page !== undefined)
    urlArgs.set('page', criteria.page);

  if (criteria.pageSize !== undefined)
    urlArgs.set('pageSize', criteria.pageSize);

  if (criteria.query !== undefined)
    urlArgs.set('query', criteria.query);

  if (criteria.customFilter !== undefined)
    urlArgs.set('customFilter', criteria.customFilter);

  if (criteria.orderBy !== undefined && criteria.orderBy !== 'name')
    urlArgs.set('orderBy', criteria.orderBy);

  if (criteria.orderByDirection && criteria.orderByDirection !== 'asc')
    urlArgs.set('orderByDirection', criteria.orderByDirection);

  urlArgs.set('view', criteria.isMultiSelect === true ? 'filters' : 'facets');

  if (criteria.filters) {
    while (urlArgs.has('filters'))
      urlArgs.delete('filters');

    criteria.filters.forEach(f => {
      const { name, value } = f;
      urlArgs.append('filters', `${encodeURIComponent(name)},${encodeURIComponent(value)}`);
    });
  }

  if (criteria.schemas) {
    while (urlArgs.has('schema'))
      urlArgs.delete('schema');

    Object.keys(criteria.schemas).forEach(schemaName => {
      const schema = criteria.schemas[schemaName];
      const schemaKeys = Object.keys(schema);
      if (schemaKeys.length > 0) {
        schemaKeys.forEach(facetKey => {
          const schemaArg = [];
          schemaArg.push(schemaName);
          schemaArg.push(encodeURIComponent(facetKey));
          const facetValues = schema[facetKey];
          facetValues.forEach(v => schemaArg.push(encodeURIComponent(v)));
          urlArgs.append('schema', schemaArg.join(','));
        });
      } else {
        urlArgs.append('schema', schemaName);
      }
    });
  }

  urlArgs.delete('workspacePath');
  urlArgs.delete('dynamicFilter');
  urlArgs.delete('key');
  urlArgs.delete('lens');
  urlArgs.delete('fromUtc');
  urlArgs.delete('toUtc');
  urlArgs.delete('asAtUtc');
  urlArgs.delete('timeZoneId');

  return urlArgs.toString();
}

export function mapStateToUrlCriteria(criteria) {
  const response = {
    onlyFavourites: criteria.enableFavouritesFilter === true,
    enableDiscontinued: criteria.enableDiscontinuedFilter === true,
    query: criteria.query,
    orderBy: criteria.orderBy ?? 'name',
    orderByDirection: criteria.orderByDirection,
    isMultiSelect: criteria.isMultiSelect,
    customFilter: criteria.customFilter,
    filters: criteria.filters === undefined ? [] : criteria.filters.map(f => ({ name: f.name, value: `${f.value}` })),
    schemas: criteria.schemas === undefined ? {} : Object.fromEntries(
      criteria.schemas
        .filter(s => s.isSelected)
        .map(selectedSchema => {
          const filters = {};
          if (selectedSchema.facets) {
            selectedSchema.facets.forEach(facet => {
              const facetValues = facet.results.filter(r => r.isSelected).map(r => r.value);
              if (facetValues.length > 0)
                filters[facet.key] = facetValues;
            });
          }

          return [selectedSchema.schemaName, filters];
        }))
  };

  if (criteria.page)
    response.page = criteria.page;

  if (criteria.pageSize)
    response.pageSize = criteria.pageSize;

  return response;
}

export function decodeUrlArgsToCriteria(url = '') {
  const criteria = {
    // onlyFavourites
    // enableDiscontinued
    // page
    // pageSize
    // query
    // customFilter
    // filters
    // schemas
    // searchSchema// from old search
  };

  const urlArgs = new URLSearchParams(url);
  for (const [key, argValue] of urlArgs) {
    switch (key.toLocaleLowerCase()) {
      case 'onlyfavourites':
        criteria.onlyFavourites = argValue.toLocaleLowerCase() === 'true';
        break;
      case 'enablediscontinued':
        criteria.enableDiscontinued = argValue.toLocaleLowerCase() === 'true';
        break;
      case 'page':
        criteria.page = Number(argValue);
        break;
      case 'pagesize':
        criteria.pageSize = Number(argValue);
        break;
      case 'query':
        criteria.query = argValue;
        break;
      case 'customfilter':
        criteria.customFilter = argValue;
        break;
      case 'orderby':
        criteria.orderBy = argValue;
        break;
      case 'orderbydirection':
        criteria.orderByDirection = argValue;
        break;
      case 'view':
        if (argValue === 'filters')
          criteria.isMultiSelect = true;
        if (argValue === 'facets')
          criteria.isMultiSelect = false;
        break;
      case 'filters':
        const [name, value] = argValue.split(',');
        criteria.filters = [{ name: decodeURIComponent(name), value: decodeURIComponent(value) }];
        break;
      case 'schema':
        const [schemaName, facetKey, ...facetValues] = argValue.split(',');
        if (facetKey !== undefined && facetValues !== undefined) {
          if (!criteria.schemas) 
            criteria.schemas = {};

          if (!criteria.schemas[schemaName])
            criteria.schemas[schemaName] = {};

          criteria.schemas[schemaName][decodeURIComponent(facetKey)] = facetValues.map(decodeURIComponent);
        }
        break;
      default:
        break;
    }
  }

  return criteria;
}

export function assignCriteria(target, override) {
  if (override.onlyFavourites !== undefined)
    target.enableFavouritesFilter = override.onlyFavourites;

  if (override.enableDiscontinued !== undefined)
    target.enableDiscontinuedFilter = override.enableDiscontinued;

  if (override.page !== undefined)
    target.page = override.page;

  if (override.pageSize !== undefined)
    target.pageSize = override.pageSize;

  if (override.query !== undefined)
    target.query = override.query;

  if (override.customFilter !== undefined)
    target.customFilter = override.customFilter;

  target.orderBy = override.orderBy ?? target.orderBy;
  target.orderByDirection = override.orderByDirection ?? target.orderByDirection;
  target.isMultiSelect = override.isMultiSelect ?? target.isMultiSelect;
  target.filters = override.filters === undefined ? [] : override.filters.map(f => ({ name: f.name, value: `${f.value}` }));

  target.schemas = target.schemas ?? [];

  // deselect existing schema selection
  target.schemas.forEach(s => s.isSelected = false);

  // apply selection from URL
  if (override.schemas) {
    Object.keys(override.schemas).forEach(schemaName => {
      const overrideSchema = override.schemas[schemaName];
      const schema = target.schemas.find(s => s.schemaName === schemaName);
      if (schema) {
        schema.isSelected = true;
        schema.facets = schema.facets ?? [];
        Object.keys(overrideSchema).forEach(cf => {
          let facet = schema.facets.find(f => f.key === cf)
          if (!facet) {
            facet = {
              key: cf,
              results: []
            };

            schema.facets.push(facet);
          }

          overrideSchema[cf].forEach(value => {
            const kv = facet.results.find(k => k.value === value);
            if (kv) {
              kv.isSelected = true;
            } else {
              facet.results.push({ value, isSelected: true });
            }
          })
        })
      }

    });
  }

  return target;
}

export function getSchemaColour(schemaName, allSchemas) {
  const match = allSchemas.find(x => x.name === schemaName);
  const colour = (match && match.colour) ? match.colour : 'Grey';
  return { marginLeft: '5px', marginBottom: '1px', borderRadius: '5px', backgroundColor: colour, color: 'White', paddingLeft: '2px', paddingRight: '2px', paddingBottom: '2px', textAlign: 'center' };
}
