export function getValueWithFallback(o, property) {
  if (!property)
    return o;

  const fallbackProperty = property.charAt(0).toLowerCase() + property.substring(1);

  return o ? o[property] || o[fallbackProperty] : o;
}

export function getObjectWithDefinedProps(o, ...properties) {
  if (!properties)
    return o;
     
  const definedEntries = Object.entries(o).filter(([key, value]) => properties.some(i => i === key) && value !== undefined);

  return Object.fromEntries(definedEntries);
}

export function getObjectWithProps(o) {
  return getObjectWithDefinedProps(o, ...Object.keys(o));
}

export function cloneInstance(instance){
  if (instance === undefined || instance === null)
    return instance;

  return JSON.parse(JSON.stringify(instance));
}

export function to2dp (val) {
  return (val === null || val === undefined) ? val : Math.round(val * 100) / 100.0;
}