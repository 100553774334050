import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import {  ANALYSIS_API_ROOT_URL } from '../config';
import {
  logErrorNotification,
  logInfoNotification
} from './log';

export const QUEUE_STATUS_REFRESH_STARTED = 'QUEUE_STATUS_REFRESH_STARTED';
export const queueStatusRefreshStarted = createAction(QUEUE_STATUS_REFRESH_STARTED);

export const QUEUE_STATUS_REFRESH_STOPPED = 'QUEUE_STATUS_REFRESH_STOPPED';
export const queueStatusRefreshStopped = createAction(QUEUE_STATUS_REFRESH_STOPPED);

export const QUEUE_STATUS_FETCH_COMPLETE = 'QUEUE_STATUS_FETCH_COMPLETE';
export const queueStatusFetchComplete = createAction(QUEUE_STATUS_FETCH_COMPLETE, 'data');

export const queueStatusRefresh = () => (dispatch, getState) => {
  dispatch(queueStatusRefreshStarted());

  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/queuestatus`)
    .then(response => response.json())
    .then(data => {
      dispatch(queueStatusFetchComplete(data));
    })
    .catch(error => {
      dispatch(queueStatusRefreshStopped());
      dispatch(logErrorNotification(error));
    });
};

export const queueStatusReplayDlq = (queueType, topicName, subscriptionName) => (dispatch, getState) => {
  dispatch(queueStatusReplayDlqStarted(queueType, topicName, subscriptionName));
  dispatch(logInfoNotification(`Replay submitted for ${topicName} / ${subscriptionName}`));

  authFetch(`${ANALYSIS_API_ROOT_URL}/v1/replayDeadLetters/${topicName}/${subscriptionName}`, {
    method: 'PUT'
  })
    .then(response => response.json())
    .then(data => {
      dispatch(queueStatusReplayDlqComplete(queueType, topicName, subscriptionName));
      dispatch(queueStatusRefresh());
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(queueStatusReplayDlqStopped(queueType, topicName, subscriptionName));
    });
};

export const QUEUE_STATUS_REPLAY_DLQ_STARTED = 'QUEUE_STATUS_REPLAY_DLQ_STARTED';
const queueStatusReplayDlqStarted = createAction(QUEUE_STATUS_REPLAY_DLQ_STARTED, 'queueType', 'topicName', 'subscriptionName');

export const QUEUE_STATUS_REPLAY_DLQ_STOPPED = 'QUEUE_STATUS_REPLAY_DLQ_STOPPED';
const queueStatusReplayDlqStopped = createAction(QUEUE_STATUS_REPLAY_DLQ_STOPPED, 'queueType', 'topicName', 'subscriptionName');

export const QUEUE_STATUS_REPLAY_DLQ_COMPLETE = 'QUEUE_STATUS_REPLAY_DLQ_COMPLETE';
const queueStatusReplayDlqComplete = createAction(QUEUE_STATUS_REPLAY_DLQ_COMPLETE, 'queueType', 'topicName', 'subscriptionName');
