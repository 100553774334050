import React, { useEffect } from 'react';
import FormattedDateTime from '../../shared/FormattedDateTime';
import FormattedNumber from '../../shared/FormattedNumber';
import Timezone from '../../shared/Timezone';
import LoadingIcon from '../../shared/LoadingIcon';

export default function AnalysisSearchStats({ id, statistics, loadStatistics }) {
  useEffect(() => {
    if (!statistics) loadStatistics(id);
  }, [statistics, id, loadStatistics]);


  return (
    <table className='table table-sm table-borderless ml-4'>
      { !statistics ? (
          <tbody>
            <tr>
              <td colSpan='2'>
                <div className='d-flex align-items-center pl-2'>
                  <LoadingIcon width='1.25em' /> <span className='pl-2'>Loading statistics...</span>
                </div>
              </td>
            </tr>
            </tbody>
          ) : (        
          <tbody>
            {/* <tr><td><b>Percentage loaded</b></td><td><FormattedNumber displayIfNaN='-' maxDecimals='1' minDecimals='1'>{ statistics.sourcePercentageLoaded }</FormattedNumber></td  ></tr> */}
            <tr><td><b>Average Value</b></td><td><FormattedNumber>{ statistics.averageValue }</FormattedNumber></td></tr>
            <tr><td><b>Min Value</b></td><td><FormattedNumber>{ statistics.minValue }</FormattedNumber></td></tr>
            <tr><td><b>Max Value</b></td><td><FormattedNumber>{ statistics.maxValue }</FormattedNumber></td></tr>
            <tr><td><b>Instances</b></td><td><FormattedNumber>{ statistics.instanceCount }</FormattedNumber></td></tr>            
            <tr><td><b>Data Points</b></td><td><FormattedNumber>{ statistics.dataPointCount }</FormattedNumber></td></tr>
            <tr><td><b>First Data Point <Timezone/></b></td><td>{ statistics.firstDataPointUtc ? <FormattedDateTime>{statistics.firstDataPointUtc}</FormattedDateTime> : '-' }</td></tr>
            <tr><td><b>Last Data Point <Timezone/></b></td><td>{ statistics.lastDataPointUtc ? <FormattedDateTime>{statistics.lastDataPointUtc}</FormattedDateTime> : '-' }</td></tr>
            <tr><td><b>Last Updated <Timezone/></b></td><td>{ statistics.lastUpdatedUtc ? <FormattedDateTime>{statistics.lastUpdatedUtc}</FormattedDateTime> : '-' }</td></tr>
            <tr><td><b>Query Count</b></td><td>{ statistics.queryCount ? statistics.queryCount : '-' }</td></tr>
            <tr><td><b>Last Queried <Timezone/></b></td><td>{ statistics.lastQueriedUtc ? <FormattedDateTime>{statistics.lastQueriedUtc}</FormattedDateTime> : '-' }</td></tr>
            <tr><td><b>Last Queried By</b></td><td>{ statistics.lastQueriedBy ? statistics.lastQueriedBy : '-' }</td></tr>
          </tbody> 
      )}
    </table>
  );
}