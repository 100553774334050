import React, { memo } from 'react';

function parseUserName(value) {
  const parts = value.split('@');
  return [parts[0], value];
}

export default memo(function UserNameLink({ children, className, style }) {
  const [displayName, emailAddress] = parseUserName(children);
  return (
    <a className={className}
      style={style}
      title={emailAddress}
      href={`mailto:${emailAddress}`}
      onClick={e => e.stopPropagation()}>
      {displayName}
    </a>
  );
});