import { fromJS } from 'immutable'
import moment from 'moment';
import {
  TIMESERIES_DETAILS_ADJUSTMENTS_LOAD_COMPLETE,
  TIMESERIES_DETAILS_ADJUSTMENTS_EDITED,
  TIMESERIES_DETAILS_ADJUSTMENTS_SAVED,
  TIMESERIES_DETAILS_ADJUSTMENTS_SET_FILTER_PROPERTY,
  TIMESERIES_DETAILS_ADJUSTMENTS_RANGE_APPLY_COMPLETE,
  TIMESERIES_DETAILS_ADJUSTMENTS_SET_RANGE_EDITOR_PROPERTY,
  TIMESERIES_DETAILS_ADJUSTMENTS_SET_SAVE_PROPERTY
} from '../actions/timeSeriesDetails-adjustments';

function rawUtcToDate(dateText) {
  const utc = moment.utc(dateText);
  return new Date(utc.year(), utc.month(), utc.date(), utc.hour(), utc.minute(), 0);
}

export const timeSeriesDetailsAdjustmentsReducer = {
  [TIMESERIES_DETAILS_ADJUSTMENTS_LOAD_COMPLETE](state, action) {
    if (!action.data)
      return state;

    const dataPoints = action.data.dataPoints || [];
    const hasData = dataPoints && dataPoints.length > 0;
    const fromUtc = hasData ? rawUtcToDate(dataPoints[0].periodStart) : null;
    const toUtc = hasData ? rawUtcToDate(dataPoints[dataPoints.length - 1].periodStart) : null;

    return state
      .setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPoints'], fromJS(dataPoints))
      .setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPointsTimezone'], action.data.timezone)
      .setIn(['adjustmentsEditor', 'dataPointsEdit', 'filterTimezone'], action.data.timezone)
      .setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPointsIsDirty'], false)
      .setIn(['adjustmentsEditor', 'dataPointsRangeEdit', 'fromUtc'], fromUtc)
      .setIn(['adjustmentsEditor', 'dataPointsRangeEdit', 'toUtc'], toUtc);
  },
  [TIMESERIES_DETAILS_ADJUSTMENTS_RANGE_APPLY_COMPLETE](state, action) {
    return state.setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPointsIsDirty'], true)
      .setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPoints'], fromJS(action.data));
  },
  [TIMESERIES_DETAILS_ADJUSTMENTS_EDITED](state, action) {
    return state.setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPointsIsDirty'], true)
      .setIn(['adjustmentsEditor', 'dataPointsEdit', 'dataPoints'], fromJS(action.data));
  },
  [TIMESERIES_DETAILS_ADJUSTMENTS_SET_FILTER_PROPERTY](state, action) {
    return state.setIn(['adjustmentsEditor', 'dataPointsEdit', ...action.properties], action.value);
  },
  [TIMESERIES_DETAILS_ADJUSTMENTS_SET_RANGE_EDITOR_PROPERTY](state, action) {
    return state.setIn(['adjustmentsEditor', 'dataPointsRangeEdit', ...action.properties], action.value);
  },
  [TIMESERIES_DETAILS_ADJUSTMENTS_SET_SAVE_PROPERTY](state, action) {
    return state.setIn(['adjustmentsEditor', 'dataPointsSaveModal', ...action.properties], action.value);
  },
  [TIMESERIES_DETAILS_ADJUSTMENTS_SAVED](state, action) {
    return state.setIn(['adjustmentsEditor', 'dataPointsSaveModal', 'reason'], '')
      .setIn(['adjustmentsEditor', 'dataPointsSaveModal', 'commentMarkdown'], '')
      .setIn(['adjustmentsEditor', 'dataPointsSaveModal', 'adjustmentType'], 'Standard')
    }
};