import React from 'react';
import AdjustmentRangeEditorTableRow from './AdjustmentRangeEditorTableRow';
import AdjustmentRangeEditorTableEditRow from './AdjustmentRangeEditorTableEditRow';

export default function AdjustmentRangeEditorTable({ isWizard, ranges, rangeEdit, operations, types, isDateOnly,
  cancelRange, saveRange, deleteRange, selectRange, editRangeProperty, editRangeReason }) {
  const { isEditing, hasChanges } = rangeEdit;
  const dateTimeWidth = isDateOnly ? '110px' : '140px';

  return (
    <table className='table table-sm table-hover user-select-none' style={{ width: 'auto' }}>
      <thead>
        <tr className='sticky-header'>
          <th style={{ width: '16px' }} />
          <th style={{ width: dateTimeWidth }}>Start</th>
          <th style={{ width: dateTimeWidth }}>End</th>
          <th style={{ width: '80px' }}>Adjustment</th>
          <th style={{ width: '75px' }}>Operation</th>
          <th style={{ width: '80px' }}>Type</th>
          <th style={{ width: '60px' }}>Reason</th>
          <th style={{ width: '80px' }}>Owner</th>
          <th style={{ width: '140px' }}>Updated (UTC)</th>
          {isWizard && <th style={{ width: '25px' }} />}
        </tr>
      </thead>
      <tbody>
        {isWizard && isEditing && (
          <AdjustmentRangeEditorTableEditRow
            range={rangeEdit}
            operations={operations}
            types={types}
            isDateOnly={isDateOnly}
            cancelRange={cancelRange}
            saveRange={saveRange}
            editRangeProperty={editRangeProperty}
            editRangeReason={editRangeReason}
            hasChanges={hasChanges} />
        )}
        {ranges.map((i, ix) => (
          <AdjustmentRangeEditorTableRow
            key={ix}
            range={i}
            isDateOnly={isDateOnly}
            deleteRange={deleteRange}
            selectRange={selectRange}
            isWizard={isWizard} />
        ))}
      </tbody>
    </table>
  );
}