import React from 'react';
import { connect } from 'react-redux';
import {
  analysisCrossSchemaSearchLoadStatistics,
} from '../../../actions/analysis-cross-schema-search';
import { toJS } from '../../../utility/immutable-utility';
import { SearchResultStatistics } from './SearchResultStatistics';

export const SearchResultStatisticsConnected = connect(
  (state, { index, stateContext }) => ({
    isLoading: state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'data', index, 'statistics', 'isLoading']),
    statistics: state.getIn(['analysisCrossSchemaSearch', stateContext, 'results', 'data', index, 'statistics', 'data'])
  }),
  (dispatch, { index, id, stateContext}) => ({
    loadStatistics() {
      dispatch(analysisCrossSchemaSearchLoadStatistics(stateContext, index, id));
    }
  })
)(({ loadStatistics, isLoading, statistics }) => {
  const _statistics = statistics ? toJS(statistics) : undefined;
  return <SearchResultStatistics isLoading={isLoading} loadStatistics={loadStatistics} statistics={_statistics} />
});