import { toJS } from '../utility/immutable-utility';
import { isNotEmpty } from '../utility/text-utility';
import { stringify } from 'querystring';

export function buildAnalysisShareUrl({ state }) {
  const workspace = state.getIn(['analysis', 'workspace']);
  const { id, type, scope, folderPath, subFolderPath, name: workspaceName } = toJS(workspace, {});

  if (!id || scope !== 'Shared') return null;

  const workspacePath = `/${type}/${scope}/${folderPath || ''}/${subFolderPath || ''}/${workspaceName || ''}`.replace(/\/+/g, '/');

  const name = workspaceName;
  const url = `${window.location.origin}/analysis?${stringify({ workspacePath })}`;

  return { name, url };
}

export function buildReportsShareUrl({ state }) {
  const reportScope = state.getIn(['reports', 'criteria', 'reportScope']);

  if (reportScope !== 'Shared') return null;

  const reportCriteria = toJS(state.getIn(['reports', 'criteria']), {});
  const { reportPath, reportName, lens, fromDate, toDate, timeZoneId, mode, asAt, groupings = [], filters: reportFilters = [] } = reportCriteria;
  const filters = reportFilters.filter(f => f.enabled).map(f => `in(${f.category},'${f.values.filter(y => y.enabled).map(f => f.value).join(',')}')`);

  let criteria = {};
  Object.entries({ lens, fromDate, toDate, timeZoneId, mode, asAt, groupings, filters }).forEach(([key, value]) => {
    if (value && !(key === 'mode' && value.includes('Snapshot'))) criteria[key] = value;
  });

  const name = reportName;
  const url = `${window.location.origin}/reports/${reportPath.replace(/\s/g, '%20')}?${stringify(criteria)}`;

  return { name, url };
}

export function buildOutagesShareUrl({ state }) {
  let criteria = {
    source: state.getIn(['outages', 'criteria', 'source']),
    biddingZone: state.getIn(['outages', 'criteria', 'biddingZone']),
    productionType: state.getIn(['outages', 'criteria', 'productionType']),
    unitEic: state.getIn(['outages', 'criteria', 'unitEic']),
    eventStart: state.getIn(['outages', 'criteria', 'eventStart']),
    eventEnd: state.getIn(['outages', 'criteria', 'eventEnd']),
    fotaStatus: state.getIn(['outages', 'criteria', 'fotaStatus']),
    messageVersions: state.getIn(['outages', 'criteria', 'messageVersions']),
    messageStatus: state.getIn(['outages', 'criteria', 'messageStatus']),
    generationData: state.getIn(['outages', 'criteria', 'generationData'])
  };

  const messageId = state.getIn(['outages', 'criteria', 'messageId']);
  if (messageId) criteria.filters = [`messageId|${messageId}`];

  const skip = state.getIn(['outages', 'criteria', 'skip']);
  const take = state.getIn(['outages', 'criteria', 'take']);
  if (skip > 0) criteria.page = (skip / take) + 1;
  if (take > 0) criteria.pageSize = take;

  for (let i in criteria)
    if (criteria[i] === undefined || criteria[i] === null || criteria[i] === '')
      delete criteria[i];

  const outagesPath = encodeURIComponent(`/power/outages?${stringify(criteria)}`);
  const url = `${window.location.origin}/power/outages?outagesPath=${outagesPath}`;
  const name = [criteria.fotaStatus, criteria.source, 'Outages'].filter(i => i).join(' ');

  return { name, url }
}

export function buildDashboardShareUrl({ pathname }) {
  const parts = pathname.split('/').filter(isNotEmpty);

  const name = parts[parts.length - 1];
  const url = window.location.href;

  return { name, url };
}