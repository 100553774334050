import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import QueueStatus from './QueueStatus';
import {
  queueStatusRefresh,
  queueStatusReplayDlq
} from '../../../actions/queueStatus';

const QueueStatusConnected = connect(
  (state) => ({
    initialised: state.getIn(['queueStatus', 'initialised']),
    isLoading: state.getIn(['queueStatus', 'isLoading']),
    lastRefreshed: state.getIn(['queueStatus', 'lastRefreshed']),
    loaders: state.getIn(['queueStatus', 'loaders']),
    subscriptions: state.getIn(['queueStatus', 'subscriptions']),
    isWizard: state.getIn(['roles', 'isWizard'])
  }),
  (dispatch) => ({
    refresh() {
      dispatch(queueStatusRefresh());
    },
    replayDlq(queueType, topicName, subscriptionName){
      dispatch(queueStatusReplayDlq(queueType, topicName, subscriptionName));
    }
  })
)(({ initialised, isLoading, loaders, subscriptions, refresh, isWizard, replayDlq, lastRefreshed }) => {
  useEffect(() => {
    if (initialised === false)
      refresh();
  });

  return (
    <QueueStatus isWizard={isWizard}
                 isLoading={isLoading} 
                 loaders={loaders}
                 subscriptions={subscriptions}
                 refresh={refresh}
                 replayDlq={replayDlq}
                 lastRefreshed={lastRefreshed}/>
  );
});

export default QueueStatusConnected;