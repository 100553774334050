import React from 'react';
import ConfirmButton from '../../../shared/ConfirmButton';
import { formatDateTime } from '../../../shared/FormattedDateTime';

const groupStyle = { marginBottom: '.5em' };
const labelStyle = { fontSize: '.9em', marginBottom: 0 };

export default function InstanceDataPointToolbar({ isWizard, selectedInstance, addToBasketFunction, archiveInstanceFunction, openInstancePromoteDialogFunction  }) {
  const title = selectedInstance ? `AsAt ${formatDateTime(selectedInstance.get('asAtUtc'))} (UTC)` : '';
  const showButtons = isWizard && title ? true : false;
  return (
      <div className='ml-0 form-row'>
        <div className='form-group ml-1' style={groupStyle}>
          <label className='font-weight-bold text-nowrap' style={labelStyle}>
            {title}
          </label>
          <div>
            {showButtons && (
              <div className='btn-group btn-group-sm'>
                <ConfirmButton type='button'
                               className='btn text-nowrap btn-outline-secondary'
                               confirmClassName='btn text-nowrap btn-warning'
                               onClick={() => archiveInstanceFunction()}
                               content={<span className='px-2 d-none d-md-inline-block'>Archive</span>}
                               confirm={<span className='px-2 d-none d-md-inline-block'>Confirm?</span>} />
              <button className='btn btn-outline-secondary' onClick={() => openInstancePromoteDialogFunction()}>
                  <i className='fas fa-solid fa-arrow-up-right-dots' />
                  <span className='px-2 d-none d-md-inline-block'>Promote</span>
              </button>                                           
              <button className='btn btn-outline-secondary' onClick={() => addToBasketFunction()}>
                  <i className='fas fa-solid fa-broom fa-fw' />
                  <span className='px-2 d-none d-md-inline-block'>Send to analysis</span>
              </button>                                           
            </div>
            )}            
          </div>
        </div>        
      </div>
  );
}