import React from 'react';

export default function TimeSeriesNameExpression({ templateNameExpression, updateTemplateNameExpression, fillerValues, setFillerValue }) {
    const {isEditing, expression} = fillerValues.get('timeSeriesNameExpression').toJS();

    function onEditTemplateNameBegin() {
      setFillerValue(['timeSeriesNameExpression', 'expression'], templateNameExpression);
      setFillerValue(['timeSeriesNameExpression', 'isEditing'], true);
    }
  
    function onSetTemplateName(e) {
      setFillerValue(['timeSeriesNameExpression', 'expression'], e.target.value);
    }
  
    function onTemplateNameKeyPress(e) {
      const keyCode = e.charCode || e.keyCode || 0;
      if (keyCode === 13)
        e.target.blur();
  
      // if escape is pressed - cancel the edit
      if (keyCode === 27)
        setFillerValue(['timeSeriesNameExpression', 'isEditing'], false);
    }
  
    function onTemplateNameHasLostFocus(e) {
      if (isEditing)
        updateTemplateNameExpression(expression);
  
      setFillerValue(['timeSeriesNameExpression', 'isEditing'], false);
    }
  
    return (
      <div className='d-flex justify-content-end w-100'>
        {isEditing ? (
          <input className='form-control form-control-sm border-0 p-0 ml-3 mr-1' style={{ height: '19px' }}
            placeholder='Please enter an expression' type='text' value={expression}
            autoFocus={true}
            onChange={onSetTemplateName}
            onKeyUp={onTemplateNameKeyPress}
            onBlur={onTemplateNameHasLostFocus} />
        ) : (
          <span className='font-weight-normal' onClick={onEditTemplateNameBegin}>{templateNameExpression}</span>
        )}
        <button className='btn btn-xs xbtn-light' onClick={onEditTemplateNameBegin}>
          <i className='fas fa-pen fa-fw' style={{ color: 'rgba(0, 0, 0, 0.3)' }} />
        </button>
        
      </div>
    );
  }