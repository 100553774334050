import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DateTimeInput from '../../../shared/DateTimeInput';

const groupStyle = { marginBottom: '.5em' };
const labelStyle = { fontSize: '.9em', marginBottom: 0 };

export default class AdjustmentRangeEditorToolbar extends Component {
  static propTypes = {
    isWizard: PropTypes.bool.isRequired,
    isDateOnly: PropTypes.bool.isRequired,
    periodStart: PropTypes.instanceOf(moment),
    periodEnd: PropTypes.instanceOf(moment),
    refresh: PropTypes.func.isRequired,
    updateToolbarProperty: PropTypes.func.isRequired,
    addRange: PropTypes.func.isRequired
  };

  onRefresh(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.refresh();
  }

  render() {
    const { periodStart, periodEnd } = this.props;
    const isRefreshDisabled = (!!periodStart && !periodEnd) || (!periodStart && !!periodEnd);
    return (
      <form noValidate onSubmit={this.onRefresh.bind(this)}>
        <div className='form-row'>
          <div className='form-group ml-1' style={groupStyle}>
            <label className='font-weight-bold text-nowrap' style={labelStyle}>From date</label>
            <DateTimeInput style={{ fontSize: '12.6px' }}
              isDateOnly={this.props.isDateOnly}
              value={periodStart}
              onChange={value => this.props.updateToolbarProperty('periodStart', value)} />
          </div>
          <div className='form-group ml-1' style={groupStyle}>
            <label className='font-weight-bold text-nowrap' style={labelStyle}>To date</label>
            <DateTimeInput style={{ fontSize: '12.6px' }}
              isDateOnly={this.props.isDateOnly}
              value={periodEnd}
              onChange={value => this.props.updateToolbarProperty('periodEnd', value)} />
          </div>
          <div className='form-group ml-1 d-flex align-items-end' style={groupStyle}>
            <div className='btn-group btn-group-sm'>
              <button className='btn text-nowrap btn-primary rounded' type='submit' disabled={isRefreshDisabled}>
                <i className='fas fa-sync-alt fa-fw' />
                <span className='px-2 d-none d-md-inline-block'>Refresh</span>
              </button>
            </div>
          </div>
          {this.props.isWizard && (
            <div className='form-group ml-5 d-flex align-items-end' style={groupStyle}>
              <div className='btn-group btn-group-sm'>
                <button className='btn text-nowrap btn-secondary rounded' type='button'
                  onClick={() => this.props.addRange({ periodStart, periodEnd })}>
                  <span className='px-2 d-none d-md-inline-block'>Add Range</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}