export const createAction = (type, ...argNames) => (...args) => {
  let action = { type };

  if (argNames.includes('type')) throw new Error(`Cannot pass 'type' argument to createAction ${type}`);

  argNames.forEach((_arg, index) => action[argNames[index]] = args[index]);

  return action;
};

export const createReducer = (initialState, handlers) => (state = initialState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action)
    : state;
};