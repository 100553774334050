import React from 'react';
import InfoPopup from '../../shared/InfoPopup';
import IdentityId from '../../shared/IdentityId2';
import ConfirmButton from '../../shared/ConfirmButton';

function ScenarioRow({ index, data, removeScenarioById }) {
  let rowClass = index % 2 ? 'bg-light' : '';
  let popupMessage = '';

  if (!data.scenario) {
    rowClass = 'alert alert-warning';
    popupMessage = 'This time series does not have a scenario category';
  }

  return (
    <tr className={rowClass}>
      <td className='py-0'>
        <ConfirmButton type='button'
          className='btn btn-sm btn-secondary py-0 px-1'
          confirmClassName='btn btn-sm btn-outline-warning py-0 px-1'
          onClick={() => removeScenarioById(data.scenarioId)}
          content={<i className='fas fa-trash fa-fw' />}
          confirm={<i className='fas fa-exclamation-circle fa-fw' />}>
        </ConfirmButton>
      </td>
      <td className='text-right'>
        <IdentityId>{data.scenarioId}</IdentityId>
      </td>
      <td>
        {data.scenarioName}
        { !!popupMessage && (
          <InfoPopup iconClassName='text-warning ml-1' faIconClassName='fa-exclamation-triangle'>
            {popupMessage}
          </InfoPopup>
        ) }
      </td>
      <td>{data.scenario}</td>
      <td>{data.granularity}</td>
      <td>{data.sourceTimeZoneId}</td>
    </tr>
  );
}

export default function ScenarioTable({ rows, removeAllScenarios, removeScenarioById }) {
  return (
    <div className='sticky-table' style={{ overflowY: 'auto', height: 'calc(100% - 30px)' }}>
      <table className='table table-sm'>
        <thead>
            <tr className='sticky-header'>
              <th>
                <ConfirmButton type='button'
                  className='btn btn-sm btn-secondary py-0 px-1'
                  confirmClassName='btn btn-sm btn-outline-warning py-0 px-1'
                  onClick={() => removeAllScenarios()}
                  content={<i className='fas fa-trash fa-fw' />}
                  confirm={<i className='fas fa-exclamation-circle fa-fw' />}>
                </ConfirmButton>
              </th>
              <th style={{ width: '150px' }}>
                <div className='d-flex justify-content-end align-items-center'>
                  <span>Scenario Id</span>
                </div>
              </th>
              <th>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>Scenario Name</span>
                </div>
              </th>
              <th>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>Scenario</span>
                </div>
              </th>
              <th>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>Granularity</span>
                </div>
              </th>
              <th>
                <div className='d-flex justify-content-between align-items-center text-nowrap'>
                  <span>Source Timezone</span>
                </div>
              </th>
            </tr>
        </thead>
        { rows && rows.length > 0 && (
          <tbody className='border-top-0'>
            { rows.map((i, ix) => <ScenarioRow key={ix} index={ix} data={i} removeScenarioById={removeScenarioById} />) }
          </tbody>
        )}
      </table>
    </div>
  );
}