import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import { LOCATION_CHANGE } from "redux-first-history";
import { createReducer } from '../utility/redux-utility';
import { guid } from '../utility/uid-utility';
import { GetRegisteredDashboardItem, mapTileConfig } from '../mapper/dashboardTileMapper.js'
import {
  DASHBOARD_TILES_LOAD_STARTED,
  DASHBOARD_TILES_LOAD_COMPLETE,

  DASHBOARD_TILES_EDIT_START,
  DASHBOARD_TILES_EDIT_SAVE_BEGIN,
  DASHBOARD_TILES_EDIT_SAVE_COMPLETE,
  DASHBOARD_TILES_EDIT_CANCEL_BEGIN,
  DASHBOARD_TILES_EDIT_CANCEL_COMPLETE,

  DASHBOARD_TILES_UPDATE_CRITERIA_PROPERTY,

  DASHBOARD_TILE_EDIT_CLONE_COMPLETE,
  DASHBOARD_TILE_EDIT_DELETE_COMPLETE,
  DASHBOARD_TILE_EDIT_MOVED,
  DASHBOARD_TILE_EDIT_ADD_NEW,
  DASHBOARD_TILE_EDIT_LAYOUT_COLUMNS,
  DASHBOARD_TILE_EDIT_SET_VALUE,

  DASHBOARD_TILES_REFRESH_ALL_STARTED,
  
  DASHBOARD_TILE_REFRESH_BEGIN,
  DASHBOARD_TILE_REFRESH_ERROR,
  DASHBOARD_TILE_REFRESH_COMPLETE,
  DASHBOARD_TILE_SET_PROPERTIES,
  DASHBOARD_TILE_EXPAND,
  DASHBOARD_TILE_WORKSPACE_LOADED_COMPLETE,

  DASHBOARD_WORKSPACES_INITIALISE_EDIT,
  DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_STARTED,
  DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_COMPLETE,
  DASHBOARD_WORKSPACES_SAVE_FOR_EDIT_COMPLETE,

  DASHBOARD_TILES_UPDATE_EXPERIMENTAL_SETTINGS,
  DASHBOARD_TILE_HIDE_ERROR,
  DASHBOARD_TILE_HIDE_ALL_ERRORS,
  DASHBOARD_TILE_SET_PROPERTY_BAG,

  DASHBOARD_SET_VALUE,
  DASHBOARD_SET_VALUES,
  DASHBOARD_FETCH_PERIODS_COMPLETED,
  DASHBOARD_TILES_REFRESH_ONE_STARTED,
  DASHBOARD_SET_PERIOD_FROM,
  DASHBOARD_SET_PERIOD_TO,
  DASHBOARD_SET_PERIOD_OVERRIDE_COMPLETED,
  DASHBOARD_TILE_SAVE_PROPERTY_BAG,
  DASHBOARD_TILE_BEGIN_PROPERTY_BAG
} from '../actions/dashboard-tiles';

import {
  DASHBOARD_TILE_WORKSPACE_LOAD_COMPLETE,
  DASHBOARD_TILE_SET_PROPERTY,
  DASHBOARD_TILE_WORKSPACE_LOAD_BEGIN
} from '../actions/dashboard-tile';

import {
  DASHBOARD_TILES_WORKSPACEPICKER_SELECT_WORKSPACE,
  DASHBOARD_TILES_WORKSPACEPICKER_SET_TITLE
} from '../actions/dashboard-tiles-workspace-picker';

import { 
  REFERENCE_DATA_LOAD_PERIODS_COMPLETE,
  REFERENCE_DATA_LOAD_SHARED_LOOKUPS_COMPLETE 
} from '../actions/referenceData.js';

import { dashboardTilesAnalysisReducer } from './dashboard-tiles-analysis';
import { dashboardTilesDefinedReportsReducer } from './dashboard-tiles-definedReports';
import { dashboardTilesWebLinkReducer } from './dashboard-tiles-webLink';
import { dashboardTilesShortcutReducer } from './dashboard-tiles-shortcut';
import { dashboardTilesAnalysisTableReducer } from './dashboard-tiles-analysis-table';
import { dashboardTilesDynamicWorkspaceReducer } from './dashboard-tiles-dynamic-workspace';
import { dashboardTilesDynamicWorkspaceDropdownsReducer } from './dashboard-tiles-dynamic-workspace-dropdowns';

import getInitialState from '../state';
import { USER_SETTINGS_LOAD_COMPLETE } from '../actions/userSettings.js';
import { mergePeriodCollectionLists, ofPeriodTypes, updateAbsValues } from '../utility/period-utility.js';
import moment from 'moment';

const LOAD_BATCH_SIZE = 4;
const revertStateKeys = ['tilesConfig', 'tilesState', 'tilesConfig', 'dynamicWorkspaceDropdowns'];

function getInitialDefaultState() {
  const { dashboardTiles } = toJS(getInitialState());
  return dashboardTiles;
}

function getInsertLocation(tilesConfig) {
  let maxY = 0;
  let canFitAtTop = true;
  Object.keys(tilesConfig).forEach(k => {
    if (tilesConfig[k].x < 9 && tilesConfig[k].y < 5)
      canFitAtTop = false;
  });

  if (!canFitAtTop) {
    Object.keys(tilesConfig).forEach(k => {
      const y = tilesConfig[k].y + tilesConfig[k].height;
      if (y > maxY)
        maxY = y;
    });
  }

  return { x: 0, y: maxY };
}

function makeRestorePoint(state){
  revertStateKeys.forEach(k => {
    state = state.set(`${k}RevertState`, state.get(k));
  });

  return state;
}

function deleteRestorePoint(state){
  revertStateKeys.forEach(k => {
    state = state.delete(`${k}RevertState`);
  });

  return state;
}

function restoreRestorePoint(state){
  revertStateKeys.forEach(k => {
    const revertState = state.get(`${k}RevertState`);
    if (revertState !== undefined)
      state = state.set(k, revertState);
  });

  return state;
}

function getRestoreState(state){
  const response = {};
  revertStateKeys.forEach(k => {
    response[k] = toJS(state.get(`${k}RevertState`));
  });

  return response;
}

function updateLayoutState(state){
  const tilesConfig = state.getIn(['tilesConfig']);
  const dashboardWidth = state.getIn(['options', 'dashboardWidth']);
  
  const layouts = [];
  tilesConfig.forEach(ts => {
      layouts.push({
        i: `t-${ts.get('stateKey')}`,
        stateKey: ts.get('stateKey'),
        type: ts.get('type'),
        x: ts.get('x'),
        y: ts.get('y'),
        w: ts.get('width'),
        h: ts.get('height'),
        static: false});
  });

  const gridRows = [];
  (layouts.length === 0 ? [{ y: 0, h: 1 }] : layouts).forEach(l => {
    const cols = [];
    for (let index = 0; index < dashboardWidth; index++)
      cols.push(index);

    while (gridRows.length < (l.y + l.h + 5) ||
      gridRows.length < 10)
      gridRows.push(cols);
  });

  return state.setIn(['ui', 'gridRows'], fromJS(gridRows))
                 .setIn(['ui', 'layouts'], fromJS(layouts))
}

function updateReferences(state) {
  const tilesConfig = state.getIn(['tilesConfig']).toJS();
  const dashboardOptions = [];
  Object.keys(tilesConfig).forEach(stateKey => {
    const dashboardTileType = tilesConfig[stateKey].type;
    const dashboardItem = GetRegisteredDashboardItem(dashboardTileType);
    if (dashboardItem && dashboardItem.dashboardCriteriaOptions) {
      const sourceWorkspace = toJS(state.getIn(['tilesState', stateKey, 'sourceWorkspace']));
      if (sourceWorkspace)
        dashboardOptions.push(dashboardItem.dashboardCriteriaOptions(JSON.parse(sourceWorkspace.data), tilesConfig[stateKey]));
    }
  });
                
  const mergedDashboardOption = {
    useDashboardLens : false,
    useDashboardTimezone : false,
    useDashboardDates : false,
    useDashboardPeriods: false
  };

  dashboardOptions.forEach(d => {
    if (d.useDashboardLens) mergedDashboardOption.useDashboardLens = true;
    if (d.useDashboardTimezone) mergedDashboardOption.useDashboardTimezone = true;
    if (d.useDashboardDates) mergedDashboardOption.useDashboardDates = true;
  });

  let workspacePeriods = [];
  dashboardOptions.forEach(d => {
    workspacePeriods = [...workspacePeriods, ...d.workspacePeriods];
  });
  workspacePeriods = [...new Set(workspacePeriods.map(p => p.name))];

  const periodsWorkspaces = state.getIn(['ui', 'periodsWorkspaces']).toJS();
  periodsWorkspaces.forEach(p => {
    p.isUsed = workspacePeriods.exists(n => n === p.name);
  });

  mergedDashboardOption.useDashboardPeriods = periodsWorkspaces.some(p => p.isUsed);

  state = state.setIn(['ui', 'periodsWorkspaces'], fromJS(periodsWorkspaces))
               .setIn(['ui', 'dashboardOptions'], fromJS(mergedDashboardOption));

  return state;
}

const dashboardTilesReducer = {
  [DASHBOARD_TILES_LOAD_STARTED](state, action) {
    return state.setIn(['isLoading'], true);
  },
  [DASHBOARD_TILES_LOAD_COMPLETE](state, action) {
    state = state.setIn(['isLoading'], false);
    if (!action.data) {
      return state.setIn([], fromJS({}))
        .setIn(['dashboardTilesWorkspaceLoadStatus'], 'no-data')
    }

    const { 
      criteria = getInitialState().getIn(['dashboardTiles', 'criteria']).toJS(), 
      tilesConfig = {},
      dynamicWorkspaceDropdowns = [], 
      initUrlSearch, 
      dashboardWidth = 72 
    } = action.data;
    
    const tilesState = {};
    const dashboardTiles = [];
    criteria.periodSettings = criteria.periodSettings ?? {};
    Object.keys(tilesConfig).forEach(stateKey => {
      const tileConfig = tilesConfig[stateKey];
      dashboardTiles.push(tileConfig);

      tilesState[stateKey] = {
        layoutChanged: false,
        isBusy: false,
        initUrlSearch: initUrlSearch
      };

      if (tileConfig.useDashboardCriteria === true) {
        tileConfig.useDashboardLens = true;
        tileConfig.useDashboardTimezone = true;
        tileConfig.useDashboardDates = true;
      }
      delete tileConfig.useDashboardCriteria;

      if (tileConfig.isTitleVisible === undefined)
        tileConfig.isTitleVisible = true;

      if (tileConfig.dynamicWorkspaceToolbarStyle === undefined)
        tileConfig.dynamicWorkspaceToolbarStyle = tileConfig.hideDynamicWorkspaceToolbar === true ? 'hidden' : 'default';

      delete tileConfig.hideDynamicWorkspaceToolbar;
      
    });

    state = state
      .setIn(['criteria'], fromJS(criteria))
      .setIn(['workspace'], fromJS(action.workspace))
      .setIn(['workspacePath'], fromJS(action.workspacePath))
      .setIn(['tilesConfig'], fromJS(tilesConfig))
      .setIn(['tilesState'], fromJS(tilesState))
      .setIn(['dynamicWorkspaceDropdowns'], fromJS(dynamicWorkspaceDropdowns))
      .setIn(['dashboardTilesWorkspaceLoadStatus'], 'loaded')
      .setIn(['isEditing'], action.isEditing ?? false)
      .setIn(['options'], fromJS({dashboardWidth}))
      .setIn(['editorState', 'dashboardTiles'], fromJS(dashboardTiles));

    return updateLayoutState(state);
  },
  [DASHBOARD_TILE_WORKSPACE_LOADED_COMPLETE](state, action) {
    const { stateKey, workspace, workspacePath } = action;
    if (!stateKey || !workspace || !workspacePath)
      return state;

    const {dashboardOptions : {workspacePeriods}} = action;
    if (Array.isArray(workspacePeriods)){
      workspacePeriods.forEach(p => p.selectedPeriod = p.name);

      const allPeriodsWorkspaces = mergePeriodCollectionLists(
        workspacePeriods,
        state.getIn(['ui', 'periodsWorkspaces']).toJS());
    
      state = state.setIn(['ui', 'periodsWorkspaces'], fromJS(allPeriodsWorkspaces))
    }

    state = state.setIn(['tilesState', stateKey, 'sourceWorkspacePath'], workspacePath)
                .setIn(['tilesState', stateKey, 'sourceWorkspace'], fromJS(workspace));
    return updateReferences(state);
  },
  [DASHBOARD_FETCH_PERIODS_COMPLETED](state, action) {
    const {periods, periodsAbs} = action;
    const periodsAbsLookups = Object.fromEntries(periodsAbs.map(p => [p.name, p]));

    periods.forEach(p => {
      if (p.selectedPeriod !== '' && periodsAbsLookups[p.selectedPeriod])
        updateAbsValues(p, periodsAbsLookups[p.selectedPeriod]);
    });

    const periodSettings = state.getIn(['criteria', 'periodSettings']).toJS();
    Object.keys(periodSettings).forEach(name => {
      const periodSetting = periodSettings[name];
      if (periodSetting.overriddenByUser !== true) {
        const periodsAbs = periodsAbsLookups[periodSetting.overriddenByPeriod ?? name];
        if (periodsAbs) {
          periodSetting.from = {
            mode: 'abs',
            abs: periodsAbs.absFromDate
          };
          periodSetting.to = {
            mode: 'abs',
            abs: periodsAbs.absToDate
          };
        }
      }
    });

    state = state.setIn(['ui', 'periodsAbs'], fromJS(periodsAbs))
                 .setIn(['criteria', 'periodSettings'], fromJS(periodSettings));
    return state;
  },
  [DASHBOARD_SET_PERIOD_OVERRIDE_COMPLETED](state, action) {
    let {periodName, value, periodsAbs} = action;
    state = state.setIn(['criteria', 'periodSettings', periodName, 'overriddenByUser'], false)
                 .setIn(['criteria', 'periodSettings', periodName, 'overriddenByPeriod'], value)
                 .setIn(['criteria', 'periodSettings', periodName, 'from'], fromJS({mode: 'abs',abs: periodsAbs.absFromDate}))
                 .setIn(['criteria', 'periodSettings', periodName, 'to'], fromJS({mode: 'abs', abs: periodsAbs.absToDate}));

    return state;
  },
  [DASHBOARD_SET_PERIOD_FROM](state, action) {
    let {period:name, value} = action;

    if (value.toJS)
      value = value.toJS();

    const period = state.getIn(['ui', 'periodsWorkspaces']).find(p => p.get('name') === name);
    let settings = state.getIn(['criteria', 'periodSettings', name]);
    settings = settings ? settings.toJS() : {};

    if (value.mode === 'abs' && 
      period.get('syncToDate') === true &&
      period.getIn(['from', 'mode']) === 'abs' &&
      period.getIn(['to', 'mode']) === 'abs') {
     
      const newFromAbs = moment(value.abs);
      const prevFromAbs = moment(settings.validFromAbs ?? period.getIn(['from','abs']));
      const deltaFrom = {
        days : newFromAbs.date() - prevFromAbs.date(),
        months: newFromAbs.month() - prevFromAbs.month(),
        years : newFromAbs.year() - prevFromAbs.year()
      };
      
      const prevToAbs = moment(settings.validToAbs ?? period.getIn(['to','abs']));
      const newToAbs = prevToAbs
        .add(deltaFrom.days, 'days')
        .add(deltaFrom.months, 'months')
        .add(deltaFrom.years, 'years');

      if (newFromAbs.isValid())
        state = state.setIn(['criteria', 'periodSettings', name, 'validFromAbs'], value.abs);

      if (newToAbs.isValid())
        state = state.setIn(['criteria', 'periodSettings', name, 'validToAbs'], newToAbs.format('YYYY-MM-DDTHH:mm'));

      state = state.setIn(['criteria', 'periodSettings', name, 'from'], fromJS(value))
                   .setIn(['criteria', 'periodSettings', name, 'to'], fromJS({mode: 'abs', abs: newToAbs.format('YYYY-MM-DDTHH:mm')}))
                   .setIn(['criteria', 'periodSettings', name, 'overriddenByUser'], true)
                   .setIn(['criteria', 'periodSettings', name, 'overriddenByPeriod'], undefined);
    } else {
      if (moment(value.abs).isValid())
        state = state.setIn(['criteria', 'periodSettings', name, 'validFromAbs'], value.abs) 
                     .setIn(['criteria', 'periodSettings', name, 'from'], fromJS(value))
                     .setIn(['criteria', 'periodSettings', name, 'overriddenByUser'], true)
                     .setIn(['criteria', 'periodSettings', name, 'overriddenByPeriod'], undefined);
    }

    return state;
  },
  [DASHBOARD_SET_PERIOD_TO](state, action) {
    let {period:name, value} = action;
    
    if (value.toJS)
      value = value.toJS();

    if (moment(value.abs).isValid())
      state = state.setIn(['criteria', 'periodSettings', name, 'validToAbs'], value.abs);

    state = state.setIn(['criteria', 'periodSettings', name, 'to'], fromJS(value))
                 .setIn(['criteria', 'periodSettings', name, 'overriddenByPeriod'], undefined);
    return state;
  },
  [DASHBOARD_TILES_EDIT_START](state, action) {
    const isDirty = state.getIn(['isDirty']);
    if (isDirty) {
      return state.setIn(['isEditing'], true);
    }

    state = makeRestorePoint(state);
    return state.setIn(['isEditing'], true);
  },
  [DASHBOARD_TILES_EDIT_SAVE_BEGIN](state, action) {
    return state;
  },
  [DASHBOARD_TILES_EDIT_SAVE_COMPLETE](state, action) {
    state = deleteRestorePoint(state);
    return state
      .setIn(['isEditing'], false)
      .setIn(['isDirty'], false);
  },
  [DASHBOARD_TILES_EDIT_CANCEL_BEGIN](state, action) {
    return state;
  },
  [DASHBOARD_TILES_EDIT_CANCEL_COMPLETE](state, action) {
    const restore = getRestoreState(state);   

    const refreshRequired = [];
    Object.keys(restore.tilesState).forEach(k => {
      // use a deep compare to decide if we have made any changes that may require a refresh
      const currentTileState = JSON.stringify(toJS(state.getIn(['tilesState', k])));
      const prevTileState = JSON.stringify(restore.tilesState[k]);

      const currentTileConfig = JSON.stringify(toJS(state.getIn(['tilesConfig', k])));
      const prevTileConfig = JSON.stringify(restore.tilesConfig[k]);

      if (currentTileState !== prevTileState || currentTileConfig !== prevTileConfig)
        refreshRequired.push(k);
    });

    state = restoreRestorePoint(state);
    state = deleteRestorePoint(state);
    refreshRequired.forEach(stateKey => {
      state = state.setIn(['tilesState', stateKey, 'refreshRequired'], true)
                   .setIn(['tilesState', stateKey, 'layoutChanged'], !state.getIn(['tilesState', stateKey, 'layoutChanged']));
    });

    state = state.setIn(['isEditing'], false)
                 .setIn(['isDirty'], false);

    state = updateReferences(state);
    return updateLayoutState(state)
  },
  [DASHBOARD_TILE_EDIT_DELETE_COMPLETE](state, action) {
    const stateKey = action.stateKey;
    state = state
      .setIn(['isDirty'], true)
      .deleteIn(['tilesConfig', stateKey]);
  
    state = updateReferences(state);
    return updateLayoutState(state);
  },
  [DASHBOARD_TILE_EDIT_MOVED](state, action) {
    const {stateKey, x, y, width, height} = action;
    const isEditing = state.getIn(['isEditing']);
    if (isEditing) state = state.setIn(['isDirty'], true);

    if (x !== null || x !== undefined) state = state.setIn(['tilesConfig', stateKey, 'x'], x);
    if (y !== null || y !== undefined) state = state.setIn(['tilesConfig', stateKey, 'y'], y);
    if (width !== null || width !== undefined) state = state.setIn(['tilesConfig', stateKey, 'width'], width);
    if (height !== null || height !== undefined) state = state.setIn(['tilesConfig', stateKey, 'height'], height);

    if (width !== null || width !== undefined || height !== null || height !== undefined)
      state = state.setIn(['tilesState', stateKey, 'layoutChanged'], !state.getIn(['tilesState', stateKey, 'layoutChanged']));

    return updateLayoutState(state);
  },
  [DASHBOARD_TILE_EDIT_CLONE_COMPLETE](state, action) {
    const { toStateKey, tileState, tileConfig } = action;
    const tilesConfig = toJS(state.getIn(['tilesConfig']), {});
    tilesConfig[toStateKey] = tileConfig;

    const tilesState = toJS(state.getIn(['tilesState']), {});
    tilesState[toStateKey] = tileState;

    state = state
      .setIn(['isDirty'], true)
      .setIn(['tilesConfig'], fromJS(tilesConfig))
      .setIn(['tilesState'], fromJS(tilesState));

    state = updateReferences(state);
    return updateLayoutState(state);
  },
  [DASHBOARD_TILE_EDIT_ADD_NEW](state, action) {
    const { dashboardTileType, properties, initialState = {} } = action;
    if (!dashboardTileType || !properties)
      return state;

    const tilesConfig = toJS(state.getIn(['tilesConfig']), {});
    const stateKey = guid();

    tilesConfig[stateKey] = {
      ...mapTileConfig({
        stateKey,
        type: dashboardTileType,
        y: getInsertLocation(tilesConfig).y
      }),
      ...properties
    };

    const tilesState = toJS(state.getIn(['tilesState']), {});
    tilesState[stateKey] = {
      layoutChanged: false,
      isBusy: false,
      refreshRequired: true,
      ...initialState,
    };

    state = state
      .setIn(['isDirty'], true)
      .setIn(['tilesConfig'], fromJS(tilesConfig))
      .setIn(['tilesState'], fromJS(tilesState))
    return updateLayoutState(state);
  },
  [DASHBOARD_TILE_EDIT_LAYOUT_COLUMNS](state, action) {
    const tilesConfig = toJS(state.getIn(['tilesConfig']), {});
    if (Object.keys(tilesConfig).length === 0)
      return state;

    const {columns} = action;
    const height = Math.max(...Object.keys(tilesConfig).map(k => tilesConfig[k].height));
    const dashboardWidth = state.getIn(['options', 'dashboardWidth']);
    const width = dashboardWidth / columns;
    let row = 0;
    let col = 0;

    const orderedList = Object.keys(tilesConfig).map(k => tilesConfig[k]);
    orderedList.sort((a, b) => (a.x + (a.y * dashboardWidth)) - (b.x + (b.y * dashboardWidth)));
    orderedList.forEach(tile => {
      tile.height = height;
      tile.width = width;
      tile.x = width * col;
      tile.y = height * row;
      col++
      if (col >= columns) {
        col = 0;
        row++;
      }
    });

    const tilesState = toJS(state.getIn(['tilesState']), {});
    Object.keys(tilesState).forEach(k => {
      tilesState[k].layoutChanged = true;
    });

    state = state
      .setIn(['isDirty'], true)
      .setIn(['tilesConfig'], fromJS(tilesConfig))
      .setIn(['tilesState'], fromJS(tilesState));    
    return updateLayoutState(state);
  },  
  [DASHBOARD_TILE_SET_PROPERTIES](state, action) {
    const { stateKey, properties = {}, requiresSave = false, indicateRefreshRequired = false, forceRefresh = false } = action;
    if (!stateKey)
      return state;

    delete properties.requiresSave;
    delete properties.requiresRefresh;
    delete properties.forceRefresh;

    const isEditing = state.getIn(['isEditing']);
    const isDirty = state.getIn(['isDirty']);
    // create an edit/undo state
    if (!isEditing && !isDirty) {
      state = makeRestorePoint(state);
    }

    if (forceRefresh)
      state = state.setIn(['tilesState', stateKey, 'refreshRequired'], true);

    if (indicateRefreshRequired)
      state = state.setIn(['tilesState', stateKey, 'indicateRefreshRequired'], true);

    if (requiresSave)
      state = state.setIn(['isDirty'], true);

    const previousProperties = toJS(state.getIn(['tilesConfig', stateKey])) ?? {};
    const newProperties = {
      ...previousProperties,
      ...properties
    };
    state = state.setIn(['tilesConfig', stateKey], fromJS(newProperties));
    state = updateReferences(state);
    return state;
  },
  [DASHBOARD_TILE_BEGIN_PROPERTY_BAG](state, action) {
    const {stateKey} = action;

    const propertyBag = toJS(state.getIn(['tilesConfig', stateKey])) ?? {};
    state = state.setIn(['tilesStatePropertyBag', stateKey], fromJS(propertyBag));      

    return state;
  },
  [DASHBOARD_TILE_SET_PROPERTY_BAG](state, action) {
    const {stateKey} = action;
    let propertyBag = action.value;

    const existingPropertyBag = toJS(state.getIn(['tilesStatePropertyBag', stateKey])) ?? {};
    const newPropertyBag = { ...existingPropertyBag, ...propertyBag };
    state = state.setIn(['tilesStatePropertyBag', stateKey], fromJS(newPropertyBag));

    return state;
  },
  [DASHBOARD_TILE_SAVE_PROPERTY_BAG](state, action) {
    const {stateKey} = action;

    const propertyBag = toJS(state.getIn(['tilesStatePropertyBag', stateKey])) ?? {};
    state = state.setIn(['isDirty'], true)
                 .setIn(['tilesState', stateKey, 'indicateRefreshRequired'], true)
                 .setIn(['tilesState', stateKey, 'refreshRequired'], true)
                 .setIn(['tilesConfig', stateKey], fromJS(propertyBag));      

    state = updateReferences(state);                 
    return state;
  },
  [DASHBOARD_TILE_SET_PROPERTY](state, action) {
    const { stateKey, key, value } = action;
    state = state.setIn(['tilesStatePropertyBag', stateKey, ...key], fromJS(value));
    state = state.setIn(['tilesConfig', stateKey, ...key], fromJS(value));

    const isEditing = state.getIn(['isEditing']);
    if (isEditing)
      state = state.setIn(['isDirty'], true);

    return state;
  },
  [DASHBOARD_TILES_REFRESH_ALL_STARTED](state, action) {
    const tilesConfig = toJS(state.getIn(['tilesConfig']), {});
    const tilesConfigList = Object.keys(tilesConfig).map(k => tilesConfig[k]);
    tilesConfigList.sort((a, b) => a.y - b.y);
    const allRefreshQueueKeys = tilesConfigList.map(k => k.stateKey);
    if (allRefreshQueueKeys.length > 0) {
      let batch = allRefreshQueueKeys.slice(0, LOAD_BATCH_SIZE);
      let refreshQueueKeys = allRefreshQueueKeys.slice(LOAD_BATCH_SIZE);
      state = state.setIn(['refreshQueueKeys'], fromJS(refreshQueueKeys));

      for (let index = 0; index < batch.length; index++) {
        const stateKey = batch[index];
        state = state
          .setIn(['dashboardTilesWorkspaceLoadStatus'], 'tiles-loading')
          .setIn(['tilesState', stateKey, 'refreshRequired'], true)
          .setIn(['tilesState', stateKey, 'isBusy'], true)
          .setIn(['tilesState', stateKey, 'error'], fromJS([]));
      }
    }

    return state;
  },
  [DASHBOARD_TILES_REFRESH_ONE_STARTED](state, action) {
    const { stateKey } = action; 
    return state.setIn(['tilesState', stateKey, 'refreshRequired'], true);
  },
  [DASHBOARD_TILE_REFRESH_BEGIN](state, action) {
    const { stateKey } = action;    
    return state.setIn(['tilesState', stateKey, 'error'], fromJS([]))
      .setIn(['tilesState', stateKey, 'refreshRequired'], true)
      .setIn(['tilesState', stateKey, 'isBusy'], true);
  },
  [DASHBOARD_TILE_REFRESH_COMPLETE](state, action) {
    const { stateKey } = action;
    let refreshQueueKeys = toJS(state.getIn(['refreshQueueKeys']));
    if (refreshQueueKeys) {
      refreshQueueKeys = refreshQueueKeys.filter(k => k !== stateKey);
      let batch = refreshQueueKeys.slice(0, 1);
      refreshQueueKeys = refreshQueueKeys.slice(1);
      for (let index = 0; index < batch.length; index++) {
        const batchStatePath = batch[index];
        state = state
          .setIn(['tilesState', batchStatePath, 'refreshRequired'], true)
          .setIn(['tilesState', batchStatePath, 'isBusy'], true)
          .setIn(['tilesState', batchStatePath, 'error'], fromJS([]));
      }

      if (refreshQueueKeys.length === 0) {
        state = state.setIn(['dashboardTilesWorkspaceLoadStatus'], 'tiles-loaded')
                     .setIn(['refreshQueueKeys'], undefined);
      } else {
        state = state.setIn(['refreshQueueKeys'], fromJS(refreshQueueKeys));
      }
    }

    return state.setIn(['tilesState', stateKey, 'isBusy'], false)
      .setIn(['tilesState', stateKey, 'refreshRequired'], false)
      .setIn(['tilesState', stateKey, 'indicateRefreshRequired'], false);
  },
  [DASHBOARD_TILE_REFRESH_ERROR](state, action) {
    const { stateKey, error } = action;
    const errors = toJS(state.getIn(['tilesState', stateKey, 'error']), []);
    errors.push({
      ...error
    });

    return state.setIn(['tilesState', stateKey, 'error'], fromJS(errors))
      .setIn(['tilesState', stateKey, 'refreshRequired'], false);
  },
  [DASHBOARD_TILE_WORKSPACE_LOAD_BEGIN](state, action) {
    const { stateKey } = action;
    state = state.setIn(['tilesState', stateKey, 'workspaceIsLoaded'], true);
    return state;
  },
  [DASHBOARD_TILE_WORKSPACE_LOAD_COMPLETE](state, action) {
    const { stateKey } = action;
    state = state.setIn(['tilesState', stateKey, 'workspaceIsInitialised'], true)
      .setIn(['tilesState', stateKey, 'workspaceIsLoaded'], true);
    return state;
  },
  [DASHBOARD_TILE_HIDE_ERROR](state, action) {
    const { stateKey, index } = action;
    let errors = toJS(state.getIn(['tilesState', stateKey, 'error']), []);
    errors.splice(index, 1)
    return state.setIn(['tilesState', stateKey, 'error'], fromJS(errors));
  },
  [DASHBOARD_TILE_HIDE_ALL_ERRORS](state, action) {
    const { stateKey } = action;
    return state.setIn(['tilesState', stateKey, 'error'], fromJS([]));
  },
  [DASHBOARD_TILE_EXPAND](state, action) {
    const {stateKey, dashboardTileType} = action;
    state = state.setIn(['ui', 'expandedTile', 'stateKey'], stateKey)
                 .setIn(['ui', 'expandedTile', 'dashboardTileType'], dashboardTileType);

    return state;
  },
  [DASHBOARD_WORKSPACES_INITIALISE_EDIT](state, action) {
    return state.deleteIn(['editWorkspace']);
  },
  [DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_STARTED](state, action) {
    return state.deleteIn(['editWorkspace']);
  },
  [DASHBOARD_WORKSPACES_LOAD_FOR_EDIT_COMPLETE](state, action) {
    return state.setIn(['editWorkspace'], fromJS(action.data));
  },
  [DASHBOARD_WORKSPACES_SAVE_FOR_EDIT_COMPLETE](state, action) {
    return state.setIn(['editWorkspace'], fromJS(action.data));
  },
  [DASHBOARD_TILES_UPDATE_EXPERIMENTAL_SETTINGS](state, action) {
    return state.setIn(['experimental'], toJS(action.data));
  },
  [DASHBOARD_TILES_WORKSPACEPICKER_SET_TITLE](state, action) {
    return state.setIn(['workspacePicker', 'title'], action.value);
  },
  [DASHBOARD_TILES_WORKSPACEPICKER_SELECT_WORKSPACE](state, action) {
    return state.setIn(['workspacePicker', 'selectedWorkspace'], action.value);
  },
  [DASHBOARD_TILE_EDIT_SET_VALUE](state, action) {
    if (!action.key)
      return state;

    if (action.value === undefined) {
      return state.deleteIn(['editorState', action.key]);
    }

    return state.setIn(['editorState', action.key], action.value);
  },
  // DASHBOARD_TILES_UPDATE_CRITERIA_PROPERTY should be replaced with DASHBOARD_SET_VALUE 
  [DASHBOARD_TILES_UPDATE_CRITERIA_PROPERTY](state, action) {    
    if (!state.getIn(['isEditing']) && !state.getIn(['isDirty']))
      state = makeRestorePoint(state);

    return state
      .setIn(['isDirty'], true)
      .setIn(['criteria', action.key], typeof action.value === 'object' ? fromJS(action.value) : action.value);
  },
  [DASHBOARD_SET_VALUE](state, action) {
    const { key, value, updateReferences:shouldUpdateReferences = false } = action;
    if (!key)
      return state;

    if (!state.getIn(['isEditing']) && !state.getIn(['isDirty']))
      state = makeRestorePoint(state);

    if (value === undefined)
      state = state.deleteIn(key);
    else
      state = state.setIn(key, value);

    if (shouldUpdateReferences)
      state = updateReferences(state);

    return state.setIn(['isDirty'], true);
  },
  [DASHBOARD_SET_VALUES](state, action) {
    const { list, updateReferences:shouldUpdateReferences = false } = action;
    if (!list)
      return state;

    if (!state.getIn(['isEditing']) && !state.getIn(['isDirty']))
      state = makeRestorePoint(state);

    list.forEach(l => {
      if (l) {
        const [key, value] = l;
        
        if (value === undefined)
          state = state.deleteIn(key);
        else
          state = state.setIn(key, value);
      }
    });

    if (shouldUpdateReferences)
      state = updateReferences(state);

    return state.setIn(['isDirty'], true);
  },
  [REFERENCE_DATA_LOAD_SHARED_LOOKUPS_COMPLETE](state, action) {
    if (!action.data)
      return state;

    const {data : {periods}} = action;
    if (Array.isArray(periods)){
      state = state.setIn(['ui', 'periodsReferenceData'], fromJS(
        mergePeriodCollectionLists(state.getIn(['ui', 'periodsReferenceData']).toJS(),
        ofPeriodTypes(periods))));
    }

    return state;
  },
  [REFERENCE_DATA_LOAD_PERIODS_COMPLETE](state, action) {
    if (!action.data)
      return state;

    const {data :periods} = action;
    if (Array.isArray(periods)){
      state = state.setIn(['ui', 'periodsReferenceData'], fromJS(
        mergePeriodCollectionLists(state.getIn(['ui', 'periodsReferenceData']).toJS(),
        ofPeriodTypes(periods))));
    }

    return state;
  },
  [USER_SETTINGS_LOAD_COMPLETE](state, action) {
    return state;
  },
  [LOCATION_CHANGE](state, action) {
    const { location } = action.payload;
    let { pathname = '', search = '' } = location;
    pathname = decodeURIComponent(pathname);

    if (pathname.toLocaleLowerCase().indexOf('/dashboard') < 0) return state;

    const { groups: result } = /(\/edit)$/.test(pathname)
      ? { ...pathname.match(/\/dashboard\/?(?<scope>Private|Shared)(?<folderPath>\/.+)?\/(?<name>[^/]+)\/(?<edit>[^/]+$)/i) }
      : /(\/comp)$/.test(pathname)
        ? { ...pathname.match(/\/dashboard\/?(?<scope>Private|Shared)(?<folderPath>\/.+)?\/(?<name>[^/]+)\/(?<comp>[^/]+$)/i) }
        : { ...pathname.match(/\/dashboard\/?(?<scope>Private|Shared)(?<folderPath>\/.+)?\/(?<name>[^/]+$)/i) };

    const { scope = '', folderPath = '', name } = result || {};

    const nextWorkspacePath = `${scope}${decodeURIComponent(`${folderPath ? folderPath + '/' : '/'}${name}`)}`;
    const currentWorkspacePath = state.getIn(['workspacePath']);
    if (currentWorkspacePath === nextWorkspacePath)
      return state;

    const isEditing = search.indexOf('edit') >= 0;

    const defaultState = {
      ...getInitialDefaultState(),
      workspace: '',
      tilesConfig: {},
      tilesState: {},
      dashboardTilesWorkspaceLoadStatus: '',
      dashboardPath: {
        path: nextWorkspacePath,
        isEditing
      }
    };

    return state
      .setIn([], fromJS(defaultState));
  }
};

export const dashboardTiles = createReducer(null, {
  ...dashboardTilesReducer,
  ...dashboardTilesAnalysisReducer,
  ...dashboardTilesDefinedReportsReducer,
  ...dashboardTilesWebLinkReducer,
  ...dashboardTilesShortcutReducer,
  ...dashboardTilesAnalysisTableReducer,
  ...dashboardTilesDynamicWorkspaceReducer,
  ...dashboardTilesDynamicWorkspaceDropdownsReducer
});