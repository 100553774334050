import React, { useState } from 'react';
import Tooltip from '../../shared/Tooltip';

export default function TimeSeriesSideBar({ isWizard, currentTimeSeriesView, setTimeSeriesView, isEditorCollapsed, toggleEditorCollapse, toggleSideBarCollapse, copyLinkToClipboard }) {
  const [shareClicked, setShareClicked] = useState(false);
  const onShare = () => {
    copyLinkToClipboard();
    setShareClicked(true);
    setTimeout(() => setShareClicked(false), 500);
  };

  const adjustmentsClassName = 'list-group-item list-group-item-action' + (currentTimeSeriesView === 'adjustments' || !currentTimeSeriesView ? ' active' : '');
  const instancesClassName = 'list-group-item list-group-item-action' + (currentTimeSeriesView === 'instances' ? ' active' : '');
  const schemasClassName = 'list-group-item list-group-item-action' + (currentTimeSeriesView === 'schemas' ? ' active' : '');
  const annotationsClassName = 'list-group-item list-group-item-action' + (currentTimeSeriesView === 'annotations' ? ' active' : '');
  const compositionClassName = 'list-group-item list-group-item-action' + (currentTimeSeriesView === 'composition' ? ' active' : '');
  const dependenciesClassName = 'list-group-item list-group-item-action' + (currentTimeSeriesView === 'variants' ? ' active' : '');
  const toolboxClassName = 'list-group-item list-group-item-action' + (currentTimeSeriesView === 'toolbox' ? ' active' : '');
  const historyClassName = 'list-group-item list-group-item-action' + (currentTimeSeriesView === 'history' ? ' active' : '');
  const menuItemStyle = { cursor: 'pointer' };

  const adjustmentsOnClick = () => setTimeSeriesView('adjustments');
  const instancesOnClick = () => setTimeSeriesView('instances');
  const schemasOnClick = () => setTimeSeriesView('schemas');
  const annotationsOnClick = () => setTimeSeriesView('annotations');
  const compositionOnClick = () => setTimeSeriesView('composition');
  const dependenciesOnClick = () => setTimeSeriesView('variants');
  const toolboxOnClick = () => setTimeSeriesView('toolbox');
  const historyOnClick = () => setTimeSeriesView('history');

  return (
    <>
      <div data_id='timeseries-edit-sidebar' className='list-group'>
        <button className='list-group-item list-group-item-action bg-secondary text-light' type='button' onClick={ toggleSideBarCollapse }>
          <i className='fas fa-chevron-right fa-fw' />
          <i className='fas fa-chevron-left fa-fw' />
        </button>
        <li data_id='sidebar-adjustments' className={ adjustmentsClassName } style={ menuItemStyle } title='Adjustments'
            onClick={ adjustmentsOnClick }>
          <i className='fas fa-sliders-h fa-fw' />
          <span className='ml-4'>Adjustments</span>
        </li>
        <li data_id='sidebar-instances' className={ instancesClassName } style={ menuItemStyle } title='Instances'
            onClick={ instancesOnClick }>
          <i className='fas fa-layer-group fa-fw' />
          <span className='ml-4'>Instances</span>
        </li>        
        <li data_id='sidebar-schemas' className={ schemasClassName } style={ menuItemStyle } title='Schemas'
            onClick={ schemasOnClick }>
          <i className='fas fa-list-alt fa-fw' />
          <span className='ml-4'>Schemas</span>
        </li>
        <li data_id='sidebar-annotations' className={ annotationsClassName } style={ menuItemStyle } title='Annotations'
            onClick={ annotationsOnClick }>
          <i className='fas fa-comment-alt fa-fw' />
          <span className='ml-4'>Annotations</span>
        </li>
        <li data_id='sidebar-dependants' className={ dependenciesClassName } style={ menuItemStyle } title='Dependants'
            onClick={ dependenciesOnClick }>
          <i className='fas fa-code-branch fa-fw' />
          <span className='ml-4'>Dependants</span>
        </li>
        <li data_id='sidebar-composition' className={ compositionClassName } style={ menuItemStyle } title='Composition'
            onClick={ compositionOnClick }>
          <i className='fas fa-sitemap fa-fw' />
          <span className='ml-4'>Composition</span>
        </li>
        <li data_id='sidebar-toolbox' className={ toolboxClassName } style={ menuItemStyle } title='Toolbox'
            onClick={ toolboxOnClick }>
          <i className='fas fa-toolbox fa-fw' />
          <span className='ml-4'>Toolbox</span>
        </li>
        {isWizard && (
          <li data_id='sidebar-history' className={ historyClassName } 
              style={ menuItemStyle } 
              title='History'
              onClick={ historyOnClick }>
            <i className='fas fa-history fa-fw' />
            <span className='ml-4'>History</span>
          </li>        
        )}
        
      </div>
      <Tooltip placement='right' title={shareClicked ? 'Copied' : 'Copy Url to clipboard'}>
        <button type='button' onClick={ onShare } style={{ marginTop: '.5rem', marginLeft: '.4rem' }}
          className={ 'fota-toggle-button btn btn-sm shadow ' + (shareClicked ? 'bg-white text-primary border-primary' : 'btn-primary') }>
          <i className={ `${shareClicked ? 'far fa-copy' : 'fas fa-share'} fa-fw` } />
        </button>
      </Tooltip>

      <div className='list-group' style={{ justifyContent: 'center', width: '25px', padding: '5em 0' }}>
        <button type='button' className='list-group-item list-group-item-action bg-secondary-outline text-dark fota-ignore-collapse'
          style={{ padding: '1em 0.15em 1em 0.15em', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          onClick={toggleEditorCollapse}>
          {isEditorCollapsed
            ? <i className='fas fa-chevron-right fa-fw' />
            : <i className='fas fa-chevron-left fa-fw' />}
        </button>      
      </div>
    </>
  );
}