import React, { useRef, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import JSONEditor from 'jsoneditor';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Loading from '../../shared/Loading';
import {
  userSettingsSave
} from '../../../actions/userSettings';

const UserSettings = connect(
  (state) => ({
    isLoading: state.getIn(['userSettings', 'isLoading']),
    settings: state.getIn(['userSettings', 'settings'])
  }),
  (dispatch) => ({
    save(settings) {
      dispatch(userSettingsSave(settings, false));
    }
  })
)(({ isLoading, settings, save }) => {
  const [editor, setEditor] = useState(null);

  const editorRef = useRef();

  const createEditor = useCallback(() => {
    if (editor) return editor;

    return new JSONEditor(editorRef.current, {
      schema: schema,
      schemaRefs: { analysis, reports },
      mode: 'code'
    }, settings);
  }, [editor, settings]);

  useDidMountEffect(() => {
    let editor = createEditor();

    setEditor(editor);

    return () => editor.destroy();
  });

  const onSubmit = useCallback(e => {
    e.preventDefault();

    const data = editor.get();

    save(data);
  }, [save, editor]);

  const onRefresh = useCallback(e => {
    e.preventDefault();

    editor.set(settings);
  }, [settings, editor]);

  return (
    <main className='d-flex flex-column'>
      <Loading isLoading={ isLoading } message='Loading...'>
        <div className='h-100'>
          <div ref={ editorRef } className='h-100'></div>
        </div>
        <div>
          <div className='py-2 px-2'>
            <form className='d-flex justify-content-center form-inline' onSubmit={ onSubmit }>
              <div className='form-group'>
                <button type='button' className='btn btn-secondary' onClick={ onRefresh }>Reset</button>
              </div>
              <div className='form-group ml-2'>
                <button type='submit' className='btn btn-primary'>Save</button>
              </div>
            </form>
          </div>
        </div>
      </Loading>
    </main>
  );
});

export default UserSettings;

const schema = {
  "title": "User Settings",
  "description": "Default settings",
  "type": "object",
  "properties": {
    "analysis": {
      "$ref": "analysis"
    },
    "reports": {
      "$ref": "reports"
    }
  },
  "required": ["analysis", "reports"]
};

const analysis = {
  "type": "object",
  "required": ["search", "chart"],
  "properties": {
    "search": {
      "type": "object",
      "properties": {
        "facets": {
          "type": "array",
          "examples": [
            "source",
            "commodity",
            "location",
            "aggregation",
            "contract",
            "currency",
            "eicCode",
            "grade",
            "granularity",
            "granularityType",
            "horizon",
            "hub",
            "location2",
            "location2From",
            "location2To",
            "locationFrom",
            "locationTo",
            "model",
            "owner",
            "periodMonth",
            "periodType",
            "periodYear",
            "provider",
            "scenario",
            "shape",
            "sourceTimeZoneId",
            "status",
            "style",
            "subTechnology",
            "supplyDemand",
            "supplyDemandName",
            "supplyDemandType",
            "technology",
            "tso",
            "unit",
            "venue"
          ]
        },
        "criteria": {
          "type": "object",
          "properties": {
            "query": {
              "type": "string"
            },
            "customFilter": {
              "type": "string",
              "examples": [
                "search.in(commodity,'Gas,Power')"
              ]
            },
            "discontinuedFilterMonths": {
              "type": "number"
            },
            "enableDiscontinuedFilter": {
              "type": "boolean"
            },
            "filters": {
              "type": "array",
              "examples": [
                "source|PointConnect",
                "style|Forecast",
                "provider|Elexon Ltd."
              ]
            },
            "orderBy": {
              "enum": ["", "source", "lastUpdatedUtc", "dataPointCount", "queryCount", "sourcePercentageLoaded"]
            },
            "orderByDirection": {
              "enum": ["", "asc", "desc"]
            },
            "pageSize": {
              "type": "number"
            }
          }
        }
      }
    },
    "chart": {
      "type": "object",
      "properties": {
        "comparison": {
          "enum": ["", "none", "dayOnDay", "monthOnMonth", "yearOnYear"]
        },
        "lens": {
          "enum": ["", "None", "QuarterHour", "HalfHour", "Hour", "Day", "Week", "Month", "Quarter", "Year"]
        },
        "operation": {
          "enum": ["", "WAvg", "Avg", "Max", "Min", "Sum"]
        },
        "timeZoneId": {
          "type": "string"
        },
        "stacking": {
          "enum": ["", "normal", "percent"]
        }
      }
    }
  }
};

const reports = {
  "type": "object",
  "properties": {
    "criteria": {
      "type": "object",
      "properties": {
        "lens": {
          "type": "string",
          "enum": ["", "None", "QuarterHour", "HalfHour", "Hour", "Day", "Week", "Month", "Quarter", "Year"]
        },
        "timeZoneId": {
          "type": "string"
        }
      }
    }
  }
};