
import { fromJS } from 'immutable';
import {
  ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_BEGIN,
  ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_COMPLETE,
  ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BASELINE,
  ANALYSIS_DYNAMIC_WORKSPACE_DISASSOCIATE_FROM_DYNAMIC_FILTER,
  ANALYSIS_DYNAMIC_WORKSPACE_TOGGLE_CATEGORY_GROUPING_COMPLETE,
} from '../actions/analysis-dynamic-workspace';
import { toJS } from '../utility/immutable-utility';
import {
  applyDynamicWorkspaceTimeSeries,
  updateBaseline
} from '../reducer-functions/analysis-dynamic-workspace';

export const dynamicWorkspaceReducerV2 = {
  [ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_BEGIN](state, action) {
    return state
      .setIn(['isDynamicWorkspaceFilterLoading'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_SET_TOOLBAR_DYNAMIC_FILTER_VALUE_COMPLETE](state, action) {
    state = state.setIn(['isDynamicWorkspaceFilterLoading'], false);

    const { dynamicWorkspaces, dynamicWorkspace, dynamicRows } = action;
    if (!dynamicWorkspace || !dynamicRows)
      return state;

    const basket = applyDynamicWorkspaceTimeSeries(
      toJS(state.getIn(['workspace', 'timeseries'])),
      toJS(state.getIn(['workspace', 'baselineBasket'])) ?? [],
      dynamicWorkspace,
      dynamicRows,
      false);

    return state
      .setIn(['workspace', 'dynamicWorkspaces'], fromJS(dynamicWorkspaces))
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['workspace', 'isDirty'], true);
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_SAVE_BASELINE](state, action) {
    const { baselineBasket } = action;
    return state.setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket));
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_DISASSOCIATE_FROM_DYNAMIC_FILTER](state, action) {
    const { key } = action;
    const basket = toJS(state.getIn(['workspace', 'timeseries']))
    const basketItem = basket.find(b => b.key === key);
    if (basketItem) {
      delete basketItem.dynamicWorkspace;

      const baselineBasket = updateBaseline(
        toJS(state.getIn(['workspace', 'baselineBasket'])),
        basket,
        basketItem);

      return state
        .setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket))
        .setIn(['workspace', 'timeseries'], fromJS(basket))
        .setIn(['workspace', 'isDirty'], true);
    } else {
      return state;
    }
  },
  [ANALYSIS_DYNAMIC_WORKSPACE_TOGGLE_CATEGORY_GROUPING_COMPLETE](state, action) {
    return state.setIn(['workspace', 'dynamicWorkspaceGroupingEnabled'], action.value)
      .setIn(['workspace', 'isDirty'], true);
  }
};