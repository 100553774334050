import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import {
  workspaceModalClear,
} from '../../../actions/workspaceModal';
import {
  homepageAddFavourite,
  homepageDeleteFavourite,
  homepageToggleOnlyFavourites,
  homepageSetOrderBy,
  homepageSetSearchText,
  homepageEnableSearchMode,
  homepageClearSearch,
  homepageRefresh,
  homepageSetLocation
} from '../../../actions/homepage';
import {
  homepageNewItemModalClear,
  homepageNewItemModalSetScope,
  homepageNewItemModalSetPath
} from '../../../actions/homepageNewItemModal';
import '../../../styles/homepage.css';
import Home2 from './Home2';

const WorkspaceSelector = connect(
  (state) => ({
    isInitialised: state.getIn(['homepage', 'isInitialised']),
    isLoading: state.getIn(['homepage', 'isLoading']),
    isSearchMode: state.getIn(['homepage', 'isSearchMode']),
    orderBy: state.getIn(['homepage', 'orderBy']),
    orderByDirection: state.getIn(['homepage', 'orderByDirection']),
    path: state.getIn(['homepage', 'path']),
    scope: state.getIn(['homepage', 'scope']),
    folder: state.getIn(['homepage', 'folder']),
    subFolder: state.getIn(['homepage', 'subFolder']),
    items: state.getIn(['homepage', 'items']),
    searchText: state.getIn(['homepage', 'searchText']),
    displayFavourites: state.getIn(['homepage', 'onlyFavourites'])
  }),
  (dispatch) => ({
    clearWorkspaceModel() {
      dispatch(workspaceModalClear());
    },
    initHomeItemModel(scope, folderPath, subFolderPath) {
      dispatch(homepageNewItemModalClear());
      dispatch(homepageNewItemModalSetScope(scope));
      dispatch(homepageNewItemModalSetPath({ folderPath, subFolderPath }));
    },
    setSearchText(value) {
      dispatch(homepageSetSearchText(value));
    },
    clearSearch() {
      dispatch(homepageClearSearch());
      dispatch(homepageRefresh());
    },
    setLocation({ path, scope, item }) {
      if (item) {
        if (item.scope === 'Private' && item.path.indexOf('Private') === 0) {
          item.path = item.path.substring('Private/'.length);
        }
        dispatch(homepageSetLocation(item.path, item.scope));
      }
      else {
        dispatch(homepageSetLocation(path, scope));
      }
    },
    addFavourite(workspaceId) {
      dispatch(homepageAddFavourite(workspaceId));
    },
    removeFavourite(workspaceId) {
      dispatch(homepageDeleteFavourite(workspaceId));
    },
    toggleDisplayFavourites() {
      dispatch(homepageToggleOnlyFavourites());
      dispatch(homepageRefresh());
    },
    startSearch() {
      dispatch(homepageEnableSearchMode());
      dispatch(homepageRefresh());
    },
    sortBy(name, direction) {
      dispatch(homepageSetOrderBy(name, direction));
      dispatch(homepageRefresh());
    },
    refresh() {
      dispatch(homepageRefresh());
    }
  })
)(({ className, style, isInitialised, isLoading, isSearchMode, orderBy, orderByDirection, scope, path, folder, subFolder, items, searchText, displayFavourites, setLocation, sortBy, setSearchText, clearSearch, startSearch, refresh,
  addFavourite, removeFavourite, toggleDisplayFavourites, initHomeItemModel, clearWorkspaceModel, onChange, onLocationChange, onLoaded,
  showOnlyItems = ['Folder', 'Analysis', 'Report'] }) => {

  let _items = toJS(items, []);
  _items = _items.filter(i => showOnlyItems.indexOf(i.type) >= 0);

  function onSelectWorkspace(args) {
    const { e, item } = args;
    if (e)
      e.preventDefault();

    if (item) {
      if (onChange) onChange(item);
    } else {
      if (onChange) onChange(args);
    }
  }

  function onSetLocation({ path, scope, item }) {
    setLocation({ path, scope, item });

    if (onLocationChange)
      onLocationChange(path, scope, item);
  }

  return <div className={className} style={{height:'450px', ...style}}>
    <Home2
      isInitialised={isInitialised}
      isLoading={isLoading}
      isSearchMode={isSearchMode}
      displayMode='list-compact'
      orderBy={orderBy}
      orderByDirection={orderByDirection}
      scope={scope}
      path={path}
      folder={folder}
      subFolder={subFolder}
      items={_items}
      searchText={searchText}
      displayFavourites={displayFavourites}
      setPath={onSetLocation}
      sortBy={sortBy}
      setSearchText={setSearchText}
      clearSearch={clearSearch}
      startSearch={startSearch}
      refresh={refresh}
      addFavourite={addFavourite}
      removeFavourite={removeFavourite}
      toggleDisplayFavourites={toggleDisplayFavourites}
      initHomeItemModel={initHomeItemModel}
      clearWorkspaceModel={clearWorkspaceModel}

      selectAnalysisWorkspace={onSelectWorkspace}
      selectReportWorkspace={onSelectWorkspace}
      selectWebLink={onSelectWorkspace}
      selectDashboardLink={onSelectWorkspace}
      selectAnalysisTableLink={onSelectWorkspace}
      selectItem={onSelectWorkspace}
      showSetDisplayMode={false}
      showNewItem={false}
      showUploadImage={false}
      showItemMenu={false}
    />
  </div>
});

export default WorkspaceSelector;