import React, { useEffect, useRef } from 'react';

export default function ScreenCaptureTableEditRow({ isEnabled, isValidForm, cronSpec, subject, recipients, targetUrl, onPropertyChange, saveEdit, closeEdit }) {
  const subjectInputRef = useRef();
  useEffect(() => subjectInputRef.current.focus(), [subjectInputRef]);

  function onKeyUp(e) {
    if (!isValidForm) return;
    const keyCode = e.charCode || e.keyCode || 0;
    if (keyCode === 13) saveEdit();
  }
  return (
    <tr onKeyUp={onKeyUp}>
      <td className='text-primary text-center d-flex' style={{ verticalAlign: 'middle', cursor: 'pointer', justifyContent: 'flex-start', alignItems: 'center' }} onClick={() => closeEdit()}>
        <i className='fas fa-times fa-fw mx-1' style={{ lineHeight: '1.5' }} />
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <div className='custom-control custom-checkbox text-center'>
          <input className='form-check-input' type='checkbox' checked={isEnabled} onChange={e => onPropertyChange('isEnabled', e.target.checked)} />
        </div>
      </td>
      <td style={{ verticalAlign: 'middle', width: '100px' }}>
        <div className='d-flex'>
          <input className='form-control text-center py-0 px-1' type='text' style={{ height: 'auto' }} value={cronSpec} onChange={e => onPropertyChange('cronSpec', e.target.value)} />
          <a target='_blank' href={ `https://crontab.guru/#${encodeURIComponent(cronSpec)}` } title='Click for help with Cron' rel='noopener noreferrer'>
            <i className='fas fa-question-circle fa-fw ml-1' />
          </a>
        </div>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <input ref={subjectInputRef} className='form-control py-0 px-1' type='text' style={{ height: 'auto' }} value={subject} onChange={e => onPropertyChange('subject', e.target.value)} />
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <input className='form-control py-0 px-1' type='text' style={{ height: 'auto' }} value={recipients} onChange={e => onPropertyChange('recipients', e.target.value)} />
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <input className='form-control py-0 px-1' type='text' style={{ height: 'auto' }} value={targetUrl} onChange={e => onPropertyChange('targetUrl', e.target.value)} />
      </td>
      <td style={{ verticalAlign: 'middle' }} colSpan='2'>
        <button className='btn btn-primary btn-sm py-0 px-1 ml-1' type='button' style={{ fontSize: '1rem' }} onClick={() => saveEdit()} disabled={!isValidForm}>Save</button>
      </td>
    </tr>
  );
}