import React from 'react';
import { DataSet, Network } from 'vis';

class TimeSeriesComposition extends React.Component {
  constructor(props) {
    super(props);

    this.Net = null;
    this.NodeMap = [];
    this.Counter = 0;
  }

  componentDidMount() {
    this.updateNetwork();
  }

  componentDidUpdate() {
    this.updateNetwork();
  }

  componentWillUnmount() {
    this.destroyNetwork();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.composition != null && !this.props.composition.equals(nextProps.composition);
  }

  map(parentNode, current, nodes, edges) {
    // get node
    let isDerived = current.get('style') === 'Derived';

    let node = isDerived ? !parentNode ?
      {
        id: this.Counter++,
        color: '#32a852',
        label: current.get('name').split(' ').join('\n'),
        shape: 'circle',
        margin: 25,
        borderWidth: 2,
        borderWidthSelected: 3,
        font: { size: 18 }
      } : {
        id: this.Counter++,
        color: '#EFC028',
        label: current.get('name'),
        shape: 'ellipse',
        font: parentNode ? { size: 18 } : null
      } : {
        id: this.Counter++,
        color: parentNode ? '#00ABD8' : '#32a852',
        label: current.get('name'),
        shape: 'box',
        font: parentNode ? { size: 18 } : {size: 20}
      };

    // push to node
    nodes.push(node);

    // and edges if parent
    if (parentNode) edges.push({ from: node.id, to: parentNode.id, arrows: 'middle' });

    // store in node map
    this.NodeMap.push({ id: node.id, tsid: current.get('id'), tsi: current });

    // call rec for all children
    let children = current.get('inputs');
    let childrenJs = [];

    for (let i = 0; i < children.size; i++) {
      let child = children.get(i);
      this.map(node, child, nodes, edges);
      childrenJs.push(child.toJS());
    }

    if (isDerived && current.hasIn(['derivationData', 'functions'])) {
      let functions = current.getIn(['derivationData', 'functions']).toJS() || [];
      let prev = node;

      for (let i = functions.length - 1; i > -1; i--) {
        let fun = functions[i] || {};
        let f = {
          id: this.Counter++,
          color: fun.disabled ? '#a3a3c2' : '#f44242',
          label: fun.customFunctionName ? fun.customFunctionName : fun.function,
          shape: 'ellipse',
        }

        nodes.push(f);
        edges.push({ from: f.id, to: prev.id, arrows: 'middle', dashes: true });
        prev = f;

        let keys = fun.keys;
        if (keys) {
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let match = childrenJs.find(x => x.id.toString() === key);

            if (match) {
              let fc = {
                id: this.Counter++,
                color: '#00ABD8',
                label: match.name,
                shape: 'box',
                font: null
              };

              nodes.push(fc);
              edges.push({ from: fc.id, to: f.id, arrows: 'middle', dashes: true });
            }
          }
        }
      }
    }
  }

  updateNetwork() {
    if (!this.props.composition || !this.props.composition.has('inputs')) {
      this.destroyNetwork();
      return;
    }

    this.NodeMap = [];
    this.Counter = 0;

    let nodes = [];
    let edges = [];
    let selectedNodeId = null;

    this.map(null, this.props.composition, nodes, edges);

    let nodesDS = new DataSet(nodes);
    let edgesDS = new DataSet(edges);

    let data = {
      nodes: nodesDS,
      edges: edgesDS
    };

    let options = {
      autoResize: true,
      height: '100%',
      width: '100%',
      edges: {
        smooth: {
          forceDirection: 'none'
        }
      },
      physics: {
        forceAtlas2Based: {
          springLength: 100,
          avoidOverlap: 0.8
        },
        minVelocity: 0.1,
        solver: 'forceAtlas2Based'
      },
      nodes: {
        chosen: {
          node: function (values, id, selected, hovering) {
            if (typeof this.props.selectTimeseries !== 'function') return;

            if (id >= 0 && selectedNodeId !== id) {
              const node = this.NodeMap.find((node) => node.id === id);

              if (node) {
                selectedNodeId = id;

                this.props.selectTimeseries({timeSeriesId:node.tsid, doNotLoadComposition:true});
              }
            }
          }.bind(this)
        }
      }
    };

    this.createNetwork(data, options);
  }

  createNetwork(data, options) {
    let container = document.getElementById('vis-network');

    this.Net = new Network(container, data, options);

    this.Net.on('stabilizationIterationsDone', function () {
      this.Net.setOptions({ physics: false });
    }.bind(this));
  }

  destroyNetwork() {
    if (!this.Net) return;

    this.Net.destroy();
    this.Net = null;
  }

  render() {
    return (
      <div id='vis-network' style={{ flexGrow: 1, height: '100%' }} />
    );
  }
}

export default TimeSeriesComposition;