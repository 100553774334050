import React, { useCallback, useState, useEffect, useRef } from 'react';
import FormattedNumber from '../../shared/FormattedNumber';

export default function EventsSearchToolbar({ query = '', customFilter = '', timeRangeFilter, updateTimeRangeFilter, clearTimeRangeFilter,
  displayCount, totalCount, filterCount, updateQuery, clearFilters, refreshData, exportData }) {
  const [searchText, setSearchText] = useState(query);
  const [customFilterText, setCustomFilterText] = useState(customFilter);
  const searchInputRef = useRef();

  useEffect(() => {
    setSearchText(query);
    setCustomFilterText(customFilter);

    searchInputRef.current.focus();
  }, [query, customFilter]);

  function onSearch(e) {
    e.preventDefault();

    if (query !== searchText || customFilter !== customFilterText) updateQuery(searchText, customFilterText);
    else refreshData();
  }

  const onClearQuery = useCallback(() => {
    setSearchText('');
    updateQuery('', customFilterText);
  }, [customFilterText, updateQuery]);

  const onClearCustomFilter = useCallback(() => {
    setCustomFilterText('');
    updateQuery(searchText, '');
  }, [searchText, updateQuery]);

  return (
    <form className='m-2' noValidate onSubmit={ onSearch }>
      <div className='form-row'>
        <div className='col-md-3'>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <div className='input-group-text'>Search</div>
            </div>
            <input ref={ searchInputRef } type='text' className={ 'form-control' + (searchText.length ? '' : ' rounded-right') } placeholder='Search text'
                   value={ searchText }
                   onChange={ e => setSearchText(e.target.value) } />
            <div className='input-group-append'>
              <button type='button' className={ 'btn btn-outline-secondary' + (searchText.length ? '' : ' d-none') }
                      onClick={ onClearQuery }>
                <i className='fas fa-times' />
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <div className='input-group-text'>Filter</div>
            </div>
            <input type='text' className={ 'form-control' + (customFilterText.length ? '' : ' rounded-right') } placeholder='Filter text'
                   value={ customFilterText }
                   onChange={ e => setCustomFilterText(e.target.value) } />
            <div className='input-group-append'>
              <button type='button' className={ 'btn btn-outline-secondary' + (customFilterText.length ? '' : ' d-none') }
                      onClick={ onClearCustomFilter }>
                <i className='fas fa-times' />
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-3 d-flex align-content-center'>
          <button type='submit' className='btn btn-primary mr-2'>
            <i className='fas fa-search fa-fw' />
          </button>
          <small className='col-form-label text-muted'>
            <FormattedNumber>{ displayCount }</FormattedNumber> of <FormattedNumber>{ totalCount }</FormattedNumber> results
          </small>
        </div>
        <div className='col-md-3 d-flex align-content-center justify-content-end'>
          <div className='btn-toolbar'>
            <div className='btn-group'>
              <button type='button' className='btn btn-outline-secondary text-nowrap mr-1' onClick={ exportData }>
                <i className='fas fa-download fa-fw' />
                <span className='px-2 d-none d-md-inline-block'>Export</span>
              </button>
              <button type='button' className={ `btn btn-outline-secondary text-nowrap mr-1 ${filterCount ? '' : 'd-none'}` } onClick={ clearFilters }>
                <i className='fas fa-filter fa-fw' />
                <span className='px-2 d-none d-md-inline-block'>Clear filters ({ filterCount })</span>
              </button>
              <button type='button' className='btn btn-primary text-nowrap' onClick={ refreshData }>
                <i className='fas fa-sync-alt fa-fw' />
                <span className='px-2 d-none d-md-inline-block'>Refresh</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='form-row mt-2'>
        <div className='col-6 d-flex justify-content-end'>
          <div className='btn-toolbar'>
            <div className='btn-group-sm btn-group-toggle'>
              <button type='button' className='btn btn-sm btn-outline-primary text-nowrap rounded-pill py-0'
                      onClick={ clearTimeRangeFilter }>
                <i className='fas fa-times' />
              </button>
            </div>
            <div className='btn-group-sm btn-group-toggle ml-2'>
              <button type='button' className={ 'btn btn-sm btn-outline-primary text-nowrap rounded-pill py-0' + (timeRangeFilter === '30M' ? ' active' : '') }
                      onClick={ () => updateTimeRangeFilter('30M') }>Past 30 Minutes
              </button>
            </div>            
            <div className='btn-group-sm btn-group-toggle ml-2'>
              <button type='button' className={ 'btn btn-sm btn-outline-primary text-nowrap rounded-pill py-0' + (timeRangeFilter === '1H' ? ' active' : '') }
                      onClick={ () => updateTimeRangeFilter('1H') }>Past Hour
              </button>
            </div>
            <div className='btn-group-sm btn-group-toggle ml-2'>
              <button type='button' className={ 'btn btn-sm btn-outline-primary text-nowrap rounded-pill py-0' + (timeRangeFilter === '12H' ? ' active' : '') }
                      onClick={ () => updateTimeRangeFilter('12H') }>Past 12 Hours
              </button>
            </div>
            <div className='btn-group-sm btn-group-toggle ml-2'>
              <button type='button' className={ 'btn btn-sm btn-outline-primary text-nowrap rounded-pill py-0' + (timeRangeFilter === '24H' ? ' active' : '') }
                      onClick={ () => updateTimeRangeFilter('24H') }>Past Day
              </button>
            </div>
            <div className='btn-group-sm btn-group-toggle ml-2'>
              <button type='button' className={ 'btn btn-sm btn-outline-primary text-nowrap rounded-pill py-0' + (timeRangeFilter === '3D' ? ' active' : '') }
                      onClick={ () => updateTimeRangeFilter('3D') }>Past 3 Days
              </button>
            </div>            
            <div className='btn-group-sm btn-group-toggle ml-2'>
              <button type='button' className={ 'btn btn-sm btn-outline-primary text-nowrap rounded-pill py-0' + (timeRangeFilter === '7D' ? ' active' : '') }
                      onClick={ () => updateTimeRangeFilter('7D') }>Past 7 Days
              </button>
            </div>
            <div className='btn-group-sm btn-group-toggle ml-2'>
              <button type='button' className={ 'btn btn-sm btn-outline-primary text-nowrap rounded-pill py-0' + (timeRangeFilter === '30D' ? ' active' : '') }
                      onClick={ () => updateTimeRangeFilter('30D') }>Past 30 Days
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}