import { authFetch } from '../auth';
import { createAction } from '../utility/redux-utility';
import { WORKSPACES_API_URL_ROOT } from '../config';
import {
  logErrorNotification
} from './log';

export const workspaceUpdateHitCount = (workspaceId, component) => async (dispatch, getState) => {
  return authFetch(`${WORKSPACES_API_URL_ROOT}/v3/workspaces/hitcount/${workspaceId}/${component}`, { method: 'POST' })
    .then(_ => _)
    .catch(error => {
      dispatch(workspaceUpdateHitCountComplete(workspaceId));
      dispatch(logErrorNotification(error));
    });
};

export const WORKSPACE_UPDATE_HIT_COUNT_COMPLETE = 'WORKSPACE_UPDATE_HIT_COUNT_COMPLETE';
const workspaceUpdateHitCountComplete = createAction(WORKSPACE_UPDATE_HIT_COUNT_COMPLETE, 'userTimeSeriesSettings');