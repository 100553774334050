import { fromJS } from 'immutable';
import { toJS } from '../utility/immutable-utility';
import {
  extractComparisonOptionsForBasketItem,
  projectComparisonsToBasket,
  rebuildDisplayNamesToState
} from '../utility/analysis-basket-utility';

import {
  ANALYSIS_COMPARISONMODE_INIT_FROM_BASKET,
  ANALYSIS_COMPARISONMODE_APPLY_TO_BASKET,
  ANALYSIS_COMPARISONMODE_BASKET_LEVEL_SET_COMPARISON_MODE,

  ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_PRESELECTION_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_PRESELECTION_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_RANGE_TO_SELECTION,
  ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_SELECTED_DATE,
  ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_ALL_SELECTION,

} from '../actions/analysis-comparisonmode-v2';

import { createCalendar, canAddPreselection, createAggregateAbsWindowRange, createAggregateRelWindowRange, createRelativeIndividualWindow, createAggregateIndividualWindow } from './analysis-comparisonmode-v2';
import { mergeBasketToBaseline } from '../reducer-functions/analysis-dynamic-workspace';
import { getPlotOrigins, initialisePlotOrigins } from '../utility/comparisonmode-utility';

export const comparisonModeBasketLevelReducer = {
  [ANALYSIS_COMPARISONMODE_INIT_FROM_BASKET](state, action) {
    const allComparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings']));
    const { key, comparisonMode, timeSeriesName, selection, plotOrigins } = extractComparisonOptionsForBasketItem({
      topLevelWindow: { 
        absFromDate : state.getIn(['workspace', 'fromUtc']) 
      },
      basket: toJS(state.getIn(['workspace', 'timeseries']), []),
      key: action.key,
      comparisonMode : allComparisonSettings.basket.comparisonMode
    });
    
    initialisePlotOrigins(allComparisonSettings, plotOrigins);
    state = state.setIn(['ui', 'comparisonSettings'], fromJS(allComparisonSettings));

    const basket = {
      comparisonMode: comparisonMode,
      key: key,
      timeSeriesName: timeSeriesName,
      selection: selection
    };

    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    createCalendar(comparisonMode, comparisonSettings);

    return state
      .setIn(['ui', 'comparisonSettings', 'basket'], fromJS(basket))
      .setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', comparisonSettings.activeView, 'showAddPreselectionErrorMessage'], false)
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', comparisonSettings.activeView, 'showAddRangePreselectionErrorMessage'], false);
  },
  [ANALYSIS_COMPARISONMODE_BASKET_LEVEL_SET_COMPARISON_MODE](state, action) {
    const active = {
      ...toJS(state.getIn(['ui', 'comparisonSettings', 'active'])),
      comparisonMode: action.comparisonMode
    };

    const settingsComparisons = toJS(state.getIn(['ui', 'comparisonSettings', active.comparisonMode]), {});
    createCalendar(active.comparisonMode, settingsComparisons);

    return state
      .setIn(['ui', 'comparisonSettings', 'active'], fromJS(active))
      .setIn(['ui', 'comparisonSettings', 'basket', 'comparisonMode'], active.comparisonMode)
      .setIn(['ui', 'comparisonSettings', active.comparisonMode], fromJS(settingsComparisons))
      .setIn(['ui', 'comparisonSettings', active.comparisonMode, 'view', settingsComparisons.activeView, 'showAddPreselectionErrorMessage'], false)
      .setIn(['ui', 'comparisonSettings', active.comparisonMode, 'view', settingsComparisons.activeView, 'showAddRangePreselectionErrorMessage'], false);
  },
  [ANALYSIS_COMPARISONMODE_APPLY_TO_BASKET](state, action) {
    const basketChanges = toJS(state.getIn(['ui', 'comparisonSettings', 'basket']));

    const plotOrigins = getPlotOrigins(state);

    const basket = projectComparisonsToBasket({
      basket: toJS(state.getIn(['workspace', 'timeseries']), []),
      key: basketChanges.key,
      selection: toJS(state.getIn(['ui', 'comparisonSettings', 'basket', 'selection'])),
      plotOrigins
    });

    const baselineBasket = mergeBasketToBaseline(
      toJS(state.getIn(['workspace', 'baselineBasket'])),
      basket);   

    state = state
      .setIn(['workspace', 'baselineBasket'], fromJS(baselineBasket))
      .setIn(['workspace', 'timeseries'], fromJS(basket))
      .setIn(['workspace', 'isDirty'], true);

      state = rebuildDisplayNamesToState(state);
    return state;
  },
  [ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_PRESELECTION_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const basket = toJS(state.getIn(['ui', 'comparisonSettings', 'basket']));
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;
    const { hasSelection } = canAddPreselection(comparisonMode, comparisonSettings);
    if (hasSelection) {
      comparisonSettings.view[activeView].preselection.forEach(pre => {
        // check for existing items first
        if (basket.selection.some(s => s.name === pre.name && s.mode === comparisonMode))
          return;

        basket.selection.push({
          ...pre,
          mode: comparisonMode
        });
      });

      // clear the pre-selection list
      comparisonSettings.view[activeView].preselection = [];
      createCalendar(comparisonMode, comparisonSettings);
    }

    return state
      .setIn(['ui', 'comparisonSettings', 'basket'], fromJS(basket))
      .setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddPreselectionErrorMessage'], !hasSelection);
  },
  [ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_PRESELECTION_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const basket = toJS(state.getIn(['ui', 'comparisonSettings', 'basket']));
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;
    const { hasSelection, hasAggregations } = canAddPreselection(comparisonMode, comparisonSettings);

    if (hasSelection && hasAggregations) {
      comparisonSettings.view[activeView].operations.forEach(op => {
        const newWindow = createAggregateIndividualWindow(comparisonSettings.view[activeView].preselection, op);

        // check for existing items first
        if (!basket.selection.some(s => s.name === newWindow.name && s.mode === comparisonMode)) {
          basket.selection.push({
            ...newWindow,
            mode: comparisonMode
          });
        }
      });

      // clear the pre-selection list
      comparisonSettings.view[activeView].preselection = [];
      createCalendar(comparisonMode, comparisonSettings);
    }

    return state
      .setIn(['ui', 'comparisonSettings', 'basket'], fromJS(basket))
      .setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddPreselectionErrorMessage'], !(hasSelection & hasAggregations));
  },
  [ANALYSIS_COMPARISONMODE_BASKET_LEVEL_AGGREGATE_ADD_RANGE_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;
    const basket = toJS(state.getIn(['ui', 'comparisonSettings', 'basket']));

    const { hasAggregations } = canAddPreselection(comparisonMode, comparisonSettings);
    if (hasAggregations) {
      comparisonSettings.view[activeView].operations.forEach(op => {
        const newWindow = createAggregateAbsWindowRange(comparisonMode, comparisonSettings.rangeStart, comparisonSettings.rangeEnd, op);

        // check for existing items first
        if (!basket.selection.some(s => s.name === newWindow.name && s.mode === comparisonMode)) {
          basket.selection.push({
            ...newWindow,
            mode: comparisonMode
          });
        }
      });
    }

    return state
      .setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddRangePreselectionErrorMessage'], !hasAggregations)
      .setIn(['ui', 'comparisonSettings', 'basket'], fromJS(basket));
  },
  [ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_INDIVIDUAL_RELATIVE_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const relativeDate = state.getIn(['ui', 'comparisonSettings', comparisonMode, 'view', 'individualRel', 'relativeDate']);

    const newWindow = createRelativeIndividualWindow(relativeDate);
    const basket = toJS(state.getIn(['ui', 'comparisonSettings', 'basket']));
    if (!basket.selection.some(s => s.name === newWindow.name && s.mode === comparisonMode)) {
      basket.selection.push({
        ...newWindow,
        mode: comparisonMode
      });
    }

    return state
      .setIn(['ui', 'comparisonSettings', 'basket'], fromJS(basket));
  },
  [ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_DATES_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;
    const basket = toJS(state.getIn(['ui', 'comparisonSettings', 'basket']));

    const { hasSelection, hasAggregations } = canAddPreselection(comparisonMode, comparisonSettings);
    if (hasSelection && hasAggregations) {
      comparisonSettings.view[activeView].operations.forEach(op => {
        const newWindow = createAggregateIndividualWindow(comparisonSettings.view[activeView].options.dates.map(d => ({
          name: d.name,
          isSelected: d.isSelected,
          window: {
            relFromDate: d.relFromDate,
            relToDate: d.relToDate,
          }
        })), op);

        // check for existing items first
        if (!basket.selection.some(s => s.name === newWindow.name && s.mode === comparisonMode)) {
          basket.selection.push({
            ...newWindow,
            mode: comparisonMode
          });
        }
      });

      // clear the pre-selection list
      comparisonSettings.view[activeView].options.dates = [];
    }

    return state
      .setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddPreselectionErrorMessage'], !(hasSelection & hasAggregations))
      .setIn(['ui', 'comparisonSettings', 'basket'], fromJS(basket));
  },
  [ANALYSIS_COMPARISONMODE_BASKET_LEVEL_ADD_AGGREGATE_RELATIVE_RANGE_TO_SELECTION](state, action) {
    const comparisonMode = action.comparisonMode;
    const comparisonSettings = toJS(state.getIn(['ui', 'comparisonSettings', comparisonMode]));
    const activeView = comparisonSettings.activeView;
    const basket = toJS(state.getIn(['ui', 'comparisonSettings', 'basket']));

    const { hasAggregations } = canAddPreselection(comparisonMode, comparisonSettings);
    if (hasAggregations) {
      comparisonSettings.view[activeView].operations.forEach(op => {
        const newWindow = createAggregateRelWindowRange(comparisonSettings.view.aggregationRel.relativeFromDate, comparisonSettings.view.aggregationRel.relativeToDate, op);

        // check for existing items first
        if (!basket.selection.some(s => s.name === newWindow.name && s.mode === comparisonMode)) {
          basket.selection.push({
            ...newWindow,
            mode: comparisonMode
          });
        }
      });
    }

    return state
      .setIn(['ui', 'comparisonSettings', comparisonMode], fromJS(comparisonSettings))
      .setIn(['ui', 'comparisonSettings', comparisonMode, 'view', activeView, 'showAddRangePreselectionErrorMessage'], !hasAggregations)
      .setIn(['ui', 'comparisonSettings', 'basket'], fromJS(basket));
  },
  [ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_SELECTED_DATE](state, action) {
    const value = action.value;
    let selection = toJS(state.getIn(['ui', 'comparisonSettings', 'basket', 'selection']));
    selection = selection.filter(s => !(s.name === value.name && s.mode === value.mode));
    return state.setIn(['ui', 'comparisonSettings', 'basket', 'selection'], fromJS(selection));
  },
  [ANALYSIS_COMPARISONMODE_BASKET_LEVEL_REMOVE_ALL_SELECTION](state, action) {
    return state.setIn(['ui', 'comparisonSettings', 'basket', 'selection'], fromJS([]));
  },
};
