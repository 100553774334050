import React, { useState } from 'react';

export function IdentityQuickInput({ addSelectionByCsv }) {
  const [identityIds, setIdentityIds] = useState('');

  const onChangeIdentityIds = (e, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onAddSelection(value);
    }
  };

  const onAddSelection = (value) => {
    addSelectionByCsv(value);
    setIdentityIds('');
  };

  return <div className='input-group input-group-sm'>
    <input data_id='csv-identities' type='text' className='form-control' value={identityIds} style={{ width: '200px' }}
      onKeyDown={e => onChangeIdentityIds(e, identityIds)}
      onChange={e => setIdentityIds(e.target.value)} />
    <div className='input-group-append'>
      <button data_id='csv-identities-add' type='button' className='btn btn-xs btn-secondary'
        onClick={() => onAddSelection(identityIds)}>
        <i className='fas fa-plus fa-fw' />
      </button>
    </div>
  </div>
}