import { createAction } from '../utility/redux-utility';

export const logInfoNotification = message => dispatch => dispatch(logMessage({message, type: 'info' }));

export const logWarningNotification = message => dispatch => dispatch(logMessage({message, type: 'warning' }));

export const logErrorNotification = message => dispatch => dispatch(logMessage({message, type: 'error' }));

export const LOG_MESSAGE = 'LOG_MESSAGE';
export const logMessage = createAction(LOG_MESSAGE, 'data');

export const LOG_DISMISS_MESSAGE = 'LOG_DISMISS_MESSAGE';
export const logDismissMessage = createAction(LOG_DISMISS_MESSAGE, 'id');