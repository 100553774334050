import React from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useParams } from 'react-router';
import { APP_TITLE } from './config';
import DocumentTitle from 'react-document-title';
import { areEmpty } from './utility/text-utility';

export default function AppTitle() {
  return (
    <Routes>
      <Route path='/analysis/*' element={<AnalysisTitle />} />
      <Route path='/reports/*' element={<ReportsTitle />} />
      <Route path='/dashboard/*' element={<DashboardTitle />} />
      <Route path='/events/*' element={<EventsTitle />} />
      <Route path='/datasets/:id?' element={<DatasetsTitle />} />
      <Route path='/actions/:type?/:id?/:mode?' element={<ActionsTitle />} />
      <Route path='/functions/*' element={<FunctionsTitle />} />
      <Route path='/user-settings/*' element={<UserSettingsTitle />} />
      <Route path='/etl-jobs/*' element={<EtlJobsTitle />} />
      <Route path='/etl-job/:id?' element={<EtlJobsTitle />} />
      <Route path='/timeseries/:id?' element={<TimeseriesTitle />} />
      <Route path='/log/*' element={<LogTitle />} />
      <Route path='*' element={<HomepageTitle />} />
    </Routes>
  )
};

const getTitle = (...args) => [...args.filter(i => !areEmpty(i)), APP_TITLE].join('<');

const AnalysisTitle = connect(
  (state) => ({
    workspaceId: state.getIn(['analysis', 'workspace', 'id']),
    workspaceName: state.getIn(['analysis', 'workspace', 'name']),
    workspaceScope: state.getIn(['analysis', 'workspace', 'scope']),
    workspaceFolderPath: state.getIn(['analysis', 'workspace', 'folderPath']),
    workspaceSubFolderPath: state.getIn(['analysis', 'workspace', 'subFolderPath'])
  })
)(({ workspaceId, workspaceName, workspaceScope, workspaceFolderPath, workspaceSubFolderPath }) => {
  const reversedWorkspacePath = workspaceId > 0
    ? [workspaceName, ...(workspaceSubFolderPath || '').split('/').reverse(), workspaceFolderPath, workspaceScope]
    : [];

  return (
    <Routes>
      <Route path='/split-chart/*' element={ <DocumentTitle title={ getTitle(...reversedWorkspacePath, 'Split Chart', 'Analysis') } /> } />
      <Route path='/chart-data/*' element={ <DocumentTitle title={ getTitle(...reversedWorkspacePath, 'Chart Data', 'Analysis') } /> } />
      <Route path='/chart/*' element={ <DocumentTitle title={ getTitle(...reversedWorkspacePath, 'Chart', 'Analysis') } /> } />
      <Route path='*' element={ <DocumentTitle title={ getTitle(...reversedWorkspacePath, 'Analysis') } /> } />
    </Routes>
  );
});

const ReportsTitle = connect(
  (state) => ({
    reportScope: state.getIn(['reports', 'criteria', 'reportScope']),
    reportFolderPath: state.getIn(['reports', 'criteria', 'reportFolderPath']),
    reportName: state.getIn(['reports', 'criteria', 'reportName'])
  })
)(({ reportScope, reportFolderPath, reportName }) => {
  const reversedFolderPath = (reportFolderPath || '').split('/').reverse();

  return <DocumentTitle title={ getTitle(reportName, ...reversedFolderPath, reportScope, 'Reports') } />;
});

const EventsTitle = () => <DocumentTitle title={ getTitle('Events') } />;

const DatasetsTitle = connect()(() => {
  const { id } = useParams();

  return <DocumentTitle title={ getTitle(...(id > 0 ? [id, 'Edit', 'Datasets'] : ['Datasets'])) } />;
});

const ActionsTitle = connect()(() => {
  const { mode, type, id } = useParams();

  let modeName = '';
  switch ((mode || '').toLocaleLowerCase()) {
    case 'edit': modeName = 'Edit'; break;
    default: break;
  }

  let typeName = '';
  switch ((type || '').toLocaleLowerCase()) {
    case 'inline': typeName = 'Inline'; break;
    case 'scheduled': typeName = 'Scheduled'; break;
    case 'triggered': typeName = 'Triggered'; break;
    default: break;
  }

  return <DocumentTitle title={ getTitle(id > 0 ? id : '', typeName, modeName, 'Actions') } />;
});

const EtlJobsTitle = connect(
  (state) => ({
    jobName: state.getIn(['etlJob', 'job', 'name'])
  })
)(({ jobName }) => {
  const { id } = useParams();

  return <DocumentTitle title={ getTitle(id > 0 ? jobName : '', 'ETL') } />;
});

const FunctionsTitle = () => <DocumentTitle title={ getTitle('ETL Jobs') } />;
const UserSettingsTitle = () => <DocumentTitle title={ getTitle('User Settings') } />;

const TimeseriesTitle = connect()(() => {
  const { id } = useParams();

  return <DocumentTitle title={ getTitle(id > 0 ? id : '', 'Time Series') } />;
});

const LogTitle = () => <DocumentTitle title={ getTitle('Log') } />;

const HomepageTitle = connect(
  (state) => ({
    scope: state.getIn(['homepage', 'scope']),
    folderPath: state.getIn(['homepage', 'path'])
  })
)(({ scope, folderPath }) => {
  const reversedFolderPath = (folderPath || '').split('/').reverse();

  return <DocumentTitle title={ getTitle(...(folderPath ? [...reversedFolderPath, scope, 'Home'] : [])) } />;
});

const DashboardTitle = connect(
  (state) => ({
    scope: state.getIn(['dashboardTiles', 'workspace', 'scope']),
    folderPath: state.getIn(['dashboardTiles', 'workspace', 'folderPath']),
    name: state.getIn(['dashboardTiles', 'workspace', 'name'])
  })
)(({ scope, folderPath, name }) => {
  const reversedFolderPath = (folderPath || '').split('/').reverse();

  return <DocumentTitle title={ getTitle(name, ...reversedFolderPath, scope, 'Dashboards') } />;
});