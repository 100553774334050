import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import { authFetch } from '../auth';
import { WORKSPACES_API_URL_ROOT, WORKSPACES_API_URL } from '../config';
import { logInfoNotification, logErrorNotification } from './log';
import { push } from "redux-first-history";

export const HOMEPAGE_NEW_ITEM_MODAL_CLEAR = 'HOMEPAGE_NEW_ITEM_MODAL_CLEAR';
export const homepageNewItemModalClear = createAction(HOMEPAGE_NEW_ITEM_MODAL_CLEAR);

export const homepageNewItemModalInitialise = (mode, workspaceId) => (dispatch, getState) =>  {
  const state = getState();
  const isLoading = state.getIn(['homepageNewItemModal', 'isLoading']);

  if (isLoading) return Promise.resolve();

  dispatch(homepageNewItemModalLoadStarted());
  dispatch(homepageNewItemModalSetMode(mode));

  if (!workspaceId)
    return dispatch(homepageNewItemModalRefresh());

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v2/workspaces/${workspaceId}`)
    .then(response => response.json())
    .then(data => {
      dispatch(homepageNewItemModalSetSelectedWorkspace(data));
      return data;
    })
    .then(() => {
      dispatch(homepageNewItemModalRefresh());
    });
};

export const HOMEPAGE_NEW_ITEM_MODAL_LOAD_STARTED = 'HOMEPAGE_NEW_ITEM_MODAL_LOAD_STARTED';
export const homepageNewItemModalLoadStarted = createAction(HOMEPAGE_NEW_ITEM_MODAL_LOAD_STARTED);

export const HOMEPAGE_NEW_ITEM_MODAL_LOAD_STOPPED = 'HOMEPAGE_NEW_ITEM_MODAL_LOAD_STOPPED';
export const homepageNewItemModalLoadStopped = createAction(HOMEPAGE_NEW_ITEM_MODAL_LOAD_STOPPED);

export const HOMEPAGE_NEW_ITEM_MODAL_LOAD_COMPLETE = 'HOMEPAGE_NEW_ITEM_MODAL_LOAD_COMPLETE';
export const homepageNewItemModalLoadComplete = createAction(HOMEPAGE_NEW_ITEM_MODAL_LOAD_COMPLETE, 'data');

export const homepageNewItemModalRefresh = () => (dispatch, getState) => {
  dispatch(homepageNewItemModalLoadStarted());

  return authFetch(`${WORKSPACES_API_URL}/workspaces/shared-folders`)
    .then(response => response.json())
    .then(data => (data || []).map(i => ({ folderPath: i })))
    .then(data => {
      dispatch(homepageNewItemModalLoadComplete(data));
    })
    .catch(error => {
      dispatch(homepageNewItemModalLoadStopped());
      dispatch(logErrorNotification(error));
    });
};

export const HOMEPAGE_NEW_ITEM_MODAL_SAVE_STARTED = 'HOMEPAGE_NEW_ITEM_MODAL_SAVE_STARTED';
export const homepageNewItemModalSaveStarted = createAction(HOMEPAGE_NEW_ITEM_MODAL_SAVE_STARTED);

export const HOMEPAGE_NEW_ITEM_MODAL_SAVE_STOPPED = 'HOMEPAGE_NEW_ITEM_MODAL_SAVE_STOPPED';
export const homepageNewItemModalSaveStopped = createAction(HOMEPAGE_NEW_ITEM_MODAL_SAVE_STOPPED);

export const HOMEPAGE_NEW_ITEM_MODAL_SAVE_COMPLETE = 'HOMEPAGE_NEW_ITEM_MODAL_SAVE_COMPLETE';
export const homepageNewItemModalSaveComplete = createAction(HOMEPAGE_NEW_ITEM_MODAL_SAVE_COMPLETE);

export const homepageNewItemModalSave = () => (dispatch, getState) => {
  const state = getState();
  const workspace = toJS(state.getIn(['homepageNewItemModal', 'selectedWorkspace']), {});
  const fullPath = state.getIn(['homepageNewItemModal', 'fullPath']);
  const mode = state.getIn(['homepageNewItemModal', 'mode']);
  const type = state.getIn(['homepageNewItemModal', 'type']);
  const scope = state.getIn(['homepageNewItemModal', 'scope']);
  const folderPath = state.getIn(['homepageNewItemModal', 'folderPath']);
  const subFolderPath = state.getIn(['homepageNewItemModal', 'subFolderPath']);
  const name = state.getIn(['homepageNewItemModal', 'name']);
  const data = state.getIn(['homepageNewItemModal', 'data']);

  const { id, thumbImageId, version } = workspace;
  const params = {
    id: mode === 'Edit' ? id : undefined,
    data,
    thumbImageId,
    version,
    scope,
    type,
    folderPath,
    subFolderPath,
    name
  };

  dispatch(homepageNewItemModalSaveStarted());

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v2/workspaces`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(data => {
      const responseWorkspace = {...params, id:data};
      if (responseWorkspace.type === 'Dashboard'){
        const pathSegments = [responseWorkspace.folderPath, responseWorkspace.subFolderPath, responseWorkspace.name].filter(s => s);
        const url = `/dashboard/${responseWorkspace.scope}/${pathSegments.join('/')}?edit=true`;
        dispatch(homepageNewItemModalSaveComplete());
        dispatch(push(url));
      }else{
        dispatch(logInfoNotification(`Workspace '${fullPath}' saved`));
        dispatch(homepageNewItemModalSaveComplete());
      }
    })
    .catch(error => {
      dispatch(homepageNewItemModalSaveStopped());
      dispatch(logErrorNotification(error));
    });
};

export const HOMEPAGE_NEW_ITEM_MODAL_SET_MODE = 'HOMEPAGE_NEW_ITEM_MODAL_SET_MODE';
export const homepageNewItemModalSetMode = createAction(HOMEPAGE_NEW_ITEM_MODAL_SET_MODE, 'value');

export const HOMEPAGE_NEW_ITEM_MODAL_SET_TYPE = 'HOMEPAGE_NEW_ITEM_MODAL_SET_TYPE';
export const homepageNewItemModalSetType = createAction(HOMEPAGE_NEW_ITEM_MODAL_SET_TYPE, 'value');

export const HOMEPAGE_NEW_ITEM_MODAL_SET_SCOPE = 'HOMEPAGE_NEW_ITEM_MODAL_SET_SCOPE';
export const homepageNewItemModalSetScope = createAction(HOMEPAGE_NEW_ITEM_MODAL_SET_SCOPE, 'value');

export const HOMEPAGE_NEW_ITEM_MODAL_SET_DATA = 'HOMEPAGE_NEW_ITEM_MODAL_SET_DATA';
export const homepageNewItemModalSetData = createAction(HOMEPAGE_NEW_ITEM_MODAL_SET_DATA, 'value');

export const HOMEPAGE_NEW_ITEM_MODAL_SET_PATH = 'HOMEPAGE_NEW_ITEM_MODAL_SET_PATH';
export const homepageNewItemModalSetPath = createAction(HOMEPAGE_NEW_ITEM_MODAL_SET_PATH, 'data');

export const HOMEPAGE_NEW_ITEM_MODAL_SET_SELECTED_WORKSPACE = 'HOMEPAGE_NEW_ITEM_MODAL_SET_SELECTED_WORKSPACE';
export const homepageNewItemModalSetSelectedWorkspace = createAction(HOMEPAGE_NEW_ITEM_MODAL_SET_SELECTED_WORKSPACE, 'data');