import React from 'react';
import { connect } from 'react-redux';
import { getDefaultFilter } from '../../../state/storage-auctions';
import {
  storageAuctionsInit,
  storageAuctionsCloseWorkspace,
  storageAuctionsCopyWorkspaceUrlToClipboard,
  storageAuctionsRefresh,
  storageAuctionsSetPageIndex,
  storageAuctionsSetOrderBy,
  storageAuctionsSetValue,
  storageAuctionsResetFilters,
  storageAuctionsUpdateFilters
} from '../../../actions/storageAuctions';
import { dashboardTileSetProperty } from '../../../actions/dashboard-tile';
import { StorageAuctions } from './StorageAuctions';
import { is } from 'immutable';
import { RegisterDashboardItem } from '../../../mapper/dashboardTileMapper'
import { StorageAuctionsTileSettingsConnected } from './StorageAuctionsTileSettingsConnected';

const StorageAuctionsConnected = connect(
  (state, { stateKey }) => ({
    stateKey,
    workspaceName: state.getIn(['storageAuctions', 'workspaceLocation', 'name']),
    canShareWorkspace: state.getIn(['storageAuctions', 'canShareWorkspace']),
    isLoading: state.getIn(['storageAuctions', 'tiles', stateKey, 'isLoading']),
    anyFiltersApplied: state.getIn(['storageAuctions', 'tiles', stateKey, 'anyFiltersApplied']),
    workspace: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace']),
    showDiagnostics: state.getIn(['storageAuctions', 'tiles', stateKey, 'showDiagnostics']),
    resultsWorkspace: state.getIn(['storageAuctions', 'tiles', stateKey, 'resultsWorkspace']),
    pageIndex: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'pageIndex']),
    pageSize: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'pageSize']),
    orderBy: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'orderBy']),
    orderByDirection: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'orderByDirection']),
    dateFrom: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'dateFrom']),
    dateTo: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'dateTo']),
    filters: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'filters']),
    hiddenColumns: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'hiddenColumns']),
    hiddenFilters: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'hiddenFilters']),
    hidePagination: state.getIn(['storageAuctions', 'tiles', stateKey, 'workspace', 'hidePagination']),
    availableFilters: state.getIn(['storageAuctions', 'tiles', stateKey, 'availableFilters']),
    auctions: state.getIn(['storageAuctions', 'tiles', stateKey, 'auctions']),
    auctionsTotalCount: state.getIn(['storageAuctions', 'tiles', stateKey, 'auctionsTotalCount']),
    pageCount: state.getIn(['storageAuctions', 'tiles', stateKey, 'auctionsPageCount']),
    elapsed: state.getIn(['storageAuctions', 'tiles', stateKey, 'elapsed']),
    useDashboardDates : state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'useDashboardDates']),
    dashboardCriteria : state.getIn(['dashboardTiles', 'criteria']),
  }),
  (dispatch, { stateKey }) => ({
    init() {
      dispatch(storageAuctionsInit(stateKey));
    },
    closeWorkspace() {
      dispatch(storageAuctionsCloseWorkspace());
    },
    refresh() {
      dispatch(storageAuctionsRefresh(stateKey));
    },
    setPageIndex(value) {
      dispatch(storageAuctionsSetPageIndex(stateKey, value));
      dispatch(storageAuctionsRefresh(stateKey));
    },
    setOrderBy(value) {
      dispatch(storageAuctionsSetOrderBy(stateKey, value));
      dispatch(storageAuctionsRefresh(stateKey));
    },
    setValue(key, value) {
      dispatch(dashboardTileSetProperty(stateKey, key, value));
      dispatch(storageAuctionsSetValue(stateKey, key, value));
    },
    setFilterValue(key, value) {
      dispatch(dashboardTileSetProperty(stateKey, ['workspace', 'filters', ...key], value));
      dispatch(storageAuctionsSetValue(stateKey, ['workspace', 'filters', ...key], value));
      if (value.size === 0)
        dispatch(storageAuctionsUpdateFilters(stateKey));
    },
    updateFilters() {
      dispatch(storageAuctionsUpdateFilters(stateKey));
    },
    resetFilters() {
      dispatch(dashboardTileSetProperty(stateKey, ['workspace', 'filters'], getDefaultFilter()));
      dispatch(storageAuctionsResetFilters(stateKey));
    },
    shareWorkspace() {
      dispatch(storageAuctionsCopyWorkspaceUrlToClipboard());
    }
  })
)(({ stateKey, init,
  workspaceName, closeWorkspace, workspace, resultsWorkspace, canShareWorkspace, shareWorkspace,
  isLoading, refresh,
  pageIndex, setPageIndex, pageSize,
  orderBy, orderByDirection, setOrderBy,
  anyFiltersApplied, updateFilters, setFilterValue, resetFilters, availableFilters, filters, dateFrom, dateTo,
  setValue,
  showDiagnostics,
  auctions, auctionsTotalCount, pageCount, elapsed,
  hiddenFilters, hiddenColumns, hidePagination, useDashboardDates, dashboardCriteria
}) => {
  let refreshRequired = !is(workspace, resultsWorkspace);

  return <StorageAuctions
    stateKey={stateKey}
    showDiagnostics={showDiagnostics}
    hiddenFilters={hiddenFilters}
    hiddenColumns={hiddenColumns}
    hidePagination={hidePagination}
    init={init}
    closeWorkspace={closeWorkspace}
    canShareWorkspace={canShareWorkspace}
    shareWorkspace={shareWorkspace}
    workspaceName={workspaceName}
    refreshRequired={refreshRequired}
    refresh={refresh}
    isLoading={isLoading}
    pageIndex={pageIndex}
    pageSize={pageSize}
    pageCount={pageCount}
    setPageIndex={setPageIndex}
    orderBy={orderBy}
    orderByDirection={orderByDirection}
    setOrderBy={setOrderBy}
    setValue={setValue}
    dateFrom={dateFrom}
    dateTo={dateTo}
    availableFilters={availableFilters}
    setFilterValue={setFilterValue}
    updateFilters={updateFilters}
    resetFilters={resetFilters}
    filters={filters}
    anyFiltersApplied={anyFiltersApplied}
    auctions={auctions}
    auctionsTotalCount={auctionsTotalCount}
    elapsed={elapsed}
    useDashboardDates={useDashboardDates}
    dashboardCriteria={dashboardCriteria}    
  />
});

export default StorageAuctionsConnected;

RegisterDashboardItem({
  type :'StorageAuction',
  refreshAction : storageAuctionsRefresh,
  component : StorageAuctionsConnected,
  settingsView : StorageAuctionsTileSettingsConnected,
  includeInAddWorkspacePicker:true,
  onOpenWorkspace :(args) => {
    const { state, stateKey } = args;
    const workspaceId = state.getIn(['storageAuctions', 'tiles', stateKey, 'workspaceLocation', 'id']);
    const url = `/storage-auctions?workspace=${workspaceId}`;
    window.open(url)},
  dashboardFilterOptions : ['Dates'],
  dashboardCriteriaOptions : (workspace, tileConfig) => {
    const dashboardOption = {
      useDashboardLens : false,
      useDashboardTimezone : false,
      useDashboardDates : tileConfig.useDashboardDates ?? false,
      workspacePeriods: []
    }

    return dashboardOption;
  }
});