import React, { Fragment, useRef } from 'react';
import IdentityId from '../../shared/IdentityId2';
import Loading from '../../shared/Loading';
import { ResizeableCol } from '../../shared/ColResizer';

const caretDownSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 448"><path transform="matrix(1 0 0 -1 0 448)" fill="%23007bff" d="M31.2998 256h257.3c17.8008 0 26.7002 -21.5 14.1006 -34.0996l-128.601 -128.7c-7.7998 -7.7998 -20.5 -7.7998 -28.2998 0l-128.6 128.7c-12.6006 12.5996 -3.7002 34.0996 14.0996 34.0996z" /></svg>';

function Row({
  children, displayMap, isLastChild = false, level = 1, nodeKey, request, scenarioOverrideMap, selectedTimeSeriesId,
  setScenarioOverride, toggleExpand, itemSelected, parentKeys = [], showOnlyScenarios, filteredScenarios, scenarioAlternates
}) {
  const hasChildren = !!(children && children.length);
  const displayKey = [...parentKeys, nodeKey].join('-');
  const displayChildrenMode = displayMap.hasOwnProperty(displayKey) ? displayMap[displayKey] : 'Open';

  let iconClassName;
  switch (displayChildrenMode) {
    case 'Open': iconClassName = 'icon icon-chevron-down'; break;
    case 'Closed': iconClassName = 'icon icon-chevron-right'; break;
    default: break;
  }

  const rowScenarioAlternates = scenarioAlternates[request.timeSeriesId] || [];
  const rowClass = rowScenarioAlternates.length > 0 ? 'alert alert-primary' : '';
  const scenarios = request.scenarioAlternatesSummary;
  const hasScenarios = !!scenarios && scenarios.length > 0;
  const hasFilteredScenarios = !!filteredScenarios && filteredScenarios.length > 0;

  if (showOnlyScenarios && !hasScenarios)
    return null;

  if (hasFilteredScenarios && !filteredScenarios.some(fs => scenarios.some(s => fs.scenarioName === s.scenarioName)))
    return null;

  const scenarioOverride = scenarioOverrideMap.hasOwnProperty(nodeKey) ? scenarioOverrideMap[nodeKey] : '';

  return (
    <Fragment>
      <tr className={rowClass} style={{ height: '20px' }}>
        <td className='sticky-cell py-0' style={{ paddingLeft: `${level * 16 - (level > 1 ? 14 : 0)}px` }} >
          {level > 1 && (
            <div className='d-inline-block'>
              <i className='fas fa-fw text-dark'>{isLastChild ? '└' : '├'}</i>
            </div>
          )}
          <button type='button' className='btn btn-sm btn-link p-0 mr-2'
            style={{ lineHeight: 0, visibility: hasChildren ? 'visible' : 'hidden' }}
            disabled={!hasChildren}
            onClick={_ => toggleExpand(displayKey)}>
            <i className={`fa-fw ${iconClassName}`} style={{ fontSize: '.75em', fontWeight: 'bolder' }} />
          </button>
          {request.name}
          {request.timeSeriesId > 0 && (
            <IdentityId hideLabel={true} noButtonBorders={true}>{request.timeSeriesId}</IdentityId>
          )}
        </td>
        <td className='p-0 px-1'>
          {request.granularity.granularityFrequency} {request.granularity.granularityType}
        </td>
        <td className='p-0 px-1'>
          {request.derivationType}
        </td>
        <td className='p-0 px-1'>
          {request.timeZoneId}
        </td>
        <td className='p-0 px-1'>
          {rowScenarioAlternates.length > 0 ? rowScenarioAlternates.length : '-'}
        </td>
        <td className='p-0'>
          <select className='form-control form-control-sm p-0 border-0'
            style={{
              height: '15px', minWidth: '75px',
              backgroundColor: 'inherit',
              backgroundImage: scenarios.length > 0 ? `url('data:image/svg+xml;charset=UTF-8,${caretDownSvg}')` : 'none',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right .2em top 50%, 0 0',
              backgroundSize: '.65em auto, 100%',
              appearance: 'none',
              marginLeft: '3px'
            }}
            value={scenarioOverride} disabled={!hasScenarios}
            onChange={e => setScenarioOverride(nodeKey, e.target.value)}>
            <option value=''>-</option>
            {scenarios.map(({ scenarioName, label }, ix) => <option key={ix} value={scenarioName}>{label || '-'}</option>)}
          </select>
        </td>
        <td className='p-0 px-1'>
          <input className='form-radio-input'
            type='radio'
            checked={selectedTimeSeriesId === request.timeSeriesId}
            onChange={_ => itemSelected(request.timeSeriesId)} />
        </td>
      </tr>
      { hasChildren && displayChildrenMode === 'Open' && children.map((i, ix) => (
        <Row key={i.key}
          children={i.children}
          displayMap={displayMap}
          isLastChild={ix === children.length - 1}
          itemSelected={itemSelected}
          level={level + 1}
          nodeKey={i.key}
          request={i.request}
          scenarioOverrideMap={scenarioOverrideMap}
          selectedTimeSeriesId={selectedTimeSeriesId}
          setScenarioOverride={setScenarioOverride}
          toggleExpand={toggleExpand}
          showOnlyScenarios={showOnlyScenarios}
          filteredScenarios={filteredScenarios}
          scenarioAlternates={scenarioAlternates}
          parentKeys={[...parentKeys, nodeKey]} />
      ))}
    </Fragment>
  );
}

export default function CompositionWithOverridesTable({
  displayMap, isLoading, rows, scenarioOverrideMap, selectedTimeSeriesId, setScenarioOverride, toggleExpand,
  itemSelected, showOnlyScenarios, filteredScenarios, scenarioAlternates
}) {
  const tableRef = useRef();

  if (isLoading)
    return <Loading isLoading={isLoading} message='Loading...' />;

  return (
    <div>
      <table ref={ tableRef } className='table table-sm'>
        <thead>
          <tr>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Name</span>
              </div>
            </ResizeableCol>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Granularity</span>
              </div>
            </ResizeableCol>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center text-nowrap'>
                <span>Derivation Type</span>
              </div>
            </ResizeableCol>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Timezone</span>
              </div>
            </ResizeableCol>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Scenarios</span>
              </div>
            </ResizeableCol>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Override</span>
              </div>
            </ResizeableCol>
            <ResizeableCol tableRef={ tableRef }>
              <div className='d-flex justify-content-between align-items-center' />
            </ResizeableCol>
          </tr>
        </thead>
        {rows && rows.length > 0 && (
          <tbody className='border-top-0'>
            { rows.map((r, _rx) => (
              <Row key={r.key}
                children={r.children}
                displayMap={displayMap}
                itemSelected={itemSelected}
                level={r.level}
                nodeKey={r.key}
                request={r.request}
                scenarioOverrideMap={scenarioOverrideMap}
                selectedTimeSeriesId={selectedTimeSeriesId}
                setScenarioOverride={setScenarioOverride}
                toggleExpand={toggleExpand}
                showOnlyScenarios={showOnlyScenarios}
                filteredScenarios={filteredScenarios}
                scenarioAlternates={scenarioAlternates} />
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
}