import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatDate } from '../../../../utility/date-utility';
import { isEmpty, isNotEmpty } from '../../../../utility/text-utility';
import { autocompleteRenderer } from 'handsontable/renderers/autocompleteRenderer';
import { textRenderer } from 'handsontable/renderers/textRenderer';
import { numericRenderer } from 'handsontable/renderers/numericRenderer';
import { HotTable, HotColumn } from '@handsontable/react';
import {
  AutoColumnSize,
  Autofill,
  AutoRowSize,
  ColumnSorting,
  Comments,
  CopyPaste,
  DropdownMenu,
  Filters,
  HiddenRows,
  registerPlugin,
} from 'handsontable/plugins';
import {
  registerCellType,
  CheckboxCellType,
  DropdownCellType,
  AutocompleteCellType
} from 'handsontable/cellTypes';

registerPlugin(AutoRowSize);
registerPlugin(AutoColumnSize);
registerPlugin(Autofill);
registerPlugin(ColumnSorting);
registerPlugin(Comments);
registerPlugin(CopyPaste);
registerPlugin(DropdownMenu);
registerPlugin(HiddenRows);
registerPlugin(Filters);

registerCellType(AutocompleteCellType);
registerCellType(CheckboxCellType);
registerCellType(DropdownCellType);

const numericFormat = { pattern: '0,0.0000', culture: 'en-US' };
const defaultAdjustment = { operation: 'Overwrite', type: 'Standard' };

const applyCellProperties = (cellProperties, data) => {
  cellProperties.readOnly |= data.isOffGran;
};

const applyHeaderStyle = TH => {
  TH.style.backgroundColor = '#fff';
  TH.style.borderTop = '1px solid #dee2e6';
  TH.style.borderBottom = '2px solid #dee2e6';
  TH.style.borderLeftWidth = '0';
  TH.style.borderRightWidth = '0';
  TH.style.color = 'rgb(33, 37, 41)';
  TH.style.fontSize = '.9rem';
  TH.style.fontWeight = 'bold';
  TH.style.height = '26px';
  TH.style.textAlign = 'left';
};

const applyCellStyle = (TD, prop, data) => {
  TD.style.borderTop = '1px solid #dee2e6';
  TD.style.borderBottom = '0';
  TD.style.borderLeft = '0';
  TD.style.borderRight = '0';
  TD.style.verticalAlign = 'middle';

  if (prop === 'periodStart' || prop === 'value' || prop === 'result' || prop === 'reason') {
    TD.style.backgroundColor = 'rgba(240, 240, 240, 1)';
    TD.style.fontWeight = '700';
  }

  if (prop === 'value')
    TD.style.borderRight = '2px solid #333';

  if (data.operation === 'Block' && (prop === 'periodStart' || prop === 'value'))
    TD.style.textDecoration = 'line-through';

  if (data.isDimmed) {
    TD.style.backgroundColor = 'rgba(250, 250, 250, 1)';
    TD.style.opacity = .5;
  }

  if (data.type === 'Temporary')
    TD.style.color = 'orange';

  if (data.isInvalid) {
    TD.classList.add('fota-cell-invalid');
    TD.style.color = 'red';
  }

  if (data.isOffGran) {
    TD.style.backgroundColor = 'rgba(250, 250, 250, 1)';
    TD.style.color = 'red';
    TD.style.opacity = .5;
  }

  return TD;
};

export default class AdjustmentRangeEditorDataPointTable extends Component {
  static propTypes = {
    isWizard: PropTypes.bool.isRequired,
    firstSelectedRow: PropTypes.number,
    operations: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired,
    isDateOnly: PropTypes.bool.isRequired,
    dataPoints: PropTypes.arrayOf(PropTypes.shape({
      rangeId: PropTypes.number,
      periodStart: PropTypes.instanceOf(moment).isRequired,
      value: PropTypes.number,
      adjustedValue: PropTypes.number,
      result: PropTypes.number,
      operation: PropTypes.string,
      type: PropTypes.string,
      reason: PropTypes.string,
      isDimmed: PropTypes.bool,
      isOffGran: PropTypes.bool
    })).isRequired,
    comments: PropTypes.arrayOf(PropTypes.shape({
      row: PropTypes.number,
      col: PropTypes.number,
      comment: PropTypes.shape({
        value: PropTypes.string
      })
    })).isRequired,
    editRangeItems: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.tableRef = createRef();
  }

  onAfterChange(changes, source) {
    if (source !== 'edit' && source !== 'CopyPaste.paste' && source !== 'Autofill.fill') return;

    const newChanges = changes.filter(([,, before, after]) => before !== after);
    if (!newChanges.length) return;

    const isAdjustedValuesOnly = !newChanges.some(([, property,,]) => property !== 'adjustedValue');
    const hot = this.tableRef.current.hotInstance;
    const sourceData = hot.getSourceData();
    const newData = newChanges.map(([ix, property,, value]) => {
      const sourceDataRow = sourceData[ix];
      value = value && value.trim ? value.trim() : value;
      value = value === '' ? null : value;
      return isAdjustedValuesOnly && isNotEmpty(value) && isEmpty(sourceDataRow.operation) && isEmpty(sourceDataRow.type)
        ? { ...sourceDataRow, ...defaultAdjustment, [property]: value }
        : { ...sourceDataRow, [property]: value };
    });

    this.props.editRangeItems(newData);
  }

  afterGetColHeader(_, TH) {
    applyHeaderStyle(TH);
  }

  hotDateRenderer(instance, TD, row, col, prop, value, cellProperties) {
    const data = instance.getSourceDataAtRow(row);
    const dateValue = formatDate(value, { outFormat: cellProperties.dateFormat });
    applyCellProperties(cellProperties, data);
    const rendererFunc = cellProperties.readOnly ? textRenderer : autocompleteRenderer;
    rendererFunc.apply(this, [instance, TD, row, col, prop, dateValue, cellProperties]);
    return applyCellStyle(TD, prop, data);
  }

  hotNumericRenderer(instance, TD, row, col, prop, value, cellProperties) {
    const data = instance.getSourceDataAtRow(row);
    applyCellProperties(cellProperties, data);
    numericRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties]);
    return applyCellStyle(TD, prop, instance.getSourceDataAtRow(row));
  }

  hotTextRenderer(instance, TD, row, col, prop, value, cellProperties) {
    const data = instance.getSourceDataAtRow(row);
    applyCellProperties(cellProperties, data);
    textRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties]);
    if (cellProperties.wordWrap === false) {
      TD.title = value;
      TD.style.textOverflow = 'ellipsis';
    }
    return applyCellStyle(TD, prop, instance.getSourceDataAtRow(row));
  }

  hotOptionsRenderer(instance, TD, row, col, prop, value, cellProperties) {
    const data = instance.getSourceDataAtRow(row);
    applyCellProperties(cellProperties, data);
    const rendererFunc = cellProperties.readOnly ? textRenderer : autocompleteRenderer;
    rendererFunc.apply(this, [instance, TD, row, col, prop, value, cellProperties]);
    return applyCellStyle(TD, prop, instance.getSourceDataAtRow(row));
  }

  scrollToSelectedRow(row) {
    const hot = this.tableRef.current.hotInstance;
    const plugin = hot.getPlugin('AutoRowSize');
    const firstVisibleRow = plugin.getFirstVisibleRow();
    const lastVisibleRow = plugin.getLastVisibleRow();

    if (firstVisibleRow > row || lastVisibleRow < row)
      hot.scrollViewportTo(row, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.firstSelectedRow !== this.props.firstSelectedRow)
      this.scrollToSelectedRow(this.props.firstSelectedRow);
  }

  render() {
    const dateFormat = this.props.isDateOnly ? 'DD-MMM-YYYY' : 'DD-MMM-YYYY HH:mm';
    const isReadOnly = !this.props.isWizard;
    const columnWidth = {
      periodStart: this.props.isDateOnly ? 90 : 128,
      value: 80,
      adjustedValue: 80,
      result: 80,
      operation: isReadOnly ? 75 : 80,
      type: isReadOnly ? 75 : 85,
      reason: 60
    };
    const tableWidth = Object.values(columnWidth).reduce((sum, i) => sum + i) + 18;

    return (
      <HotTable root='hot'
        licenseKey='non-commercial-and-evaluation'
        ref={this.tableRef}
        style={{ height: 'calc(100vh - 205px)', overflow: 'hidden' }}
        width={tableWidth}
        rowHeights={30.5}
        autoRowSize={false}
        autoColumnSize={false}
        readOnly={isReadOnly}
        data={this.props.dataPoints}
        comments={true}
        cell={this.props.comments}
        afterGetColHeader={this.afterGetColHeader}
        afterChange={this.onAfterChange.bind(this)}>
        <HotColumn title='Date' data='periodStart' type='text' dateFormat={dateFormat} width={columnWidth.periodStart} readOnly={true}
          renderer={this.hotDateRenderer} />
        <HotColumn title='Value' data='value' type='numeric' numericFormat={numericFormat} width={columnWidth.value} readOnly={true}
          renderer={this.hotNumericRenderer} />
        <HotColumn title='Adjustment' data='adjustedValue' type='numeric' numericFormat={numericFormat} width={columnWidth.adjustedValue}
          renderer={this.hotNumericRenderer} />
        <HotColumn title='Result' data='result' type='numeric' numericFormat={numericFormat} width={columnWidth.result} readOnly={true}
          renderer={this.hotNumericRenderer} />
        <HotColumn title='Operation' data='operation' type='dropdown'
          width={columnWidth.operation}
          source={this.props.operations}
          renderer={this.hotOptionsRenderer} />
        <HotColumn title='Type' data='type' type='dropdown'
          width={columnWidth.type}
          source={this.props.types}
          renderer={this.hotOptionsRenderer} />
        <HotColumn title='Reason' data='reason' type='text' width={columnWidth.reason} wordWrap={false} readOnly={true}
          renderer={this.hotTextRenderer} />
      </HotTable>
    );
  }
}
