import { createAction } from '../utility/redux-utility';
import { toJS } from '../utility/immutable-utility';
import { authFetch } from '../auth';
import { ETL_API_URL } from '../config';
import {
  logErrorNotification
} from './log';

export const LNG_DEALS_ADD = 'LNG_DEALS_ADD';
export const lngDealsAdd = createAction(LNG_DEALS_ADD);

export const LNG_DEALS_EDITED = 'LNG_DEALS_EDITED';
export const lngDealsEdited = createAction(LNG_DEALS_EDITED, 'id', 'property', 'value');

export const LNG_DEALS_REFRESH_STARTED = 'LNG_DEALS_REFRESH_STARTED';
export const lngDealsRefreshStarted = createAction(LNG_DEALS_REFRESH_STARTED);

export const LNG_DEALS_REFRESH_STOPPED = 'LNG_DEALS_REFRESH_STOPPED';
export const lngDealsRefreshStopped = createAction(LNG_DEALS_REFRESH_STOPPED);

export const LNG_DEALS_REFRESH_COMPLETE = 'LNG_DEALS_REFRESH_COMPLETE';
export const lngDealsRefreshComplete = createAction(LNG_DEALS_REFRESH_COMPLETE, 'data');

export const lngDealsRefresh = () => (dispatch, getState) => {
  dispatch(lngDealsRefreshStarted());
  authFetch(`${ETL_API_URL}/lng/deals`)
    .then(response => response.json())
    .then(data => {
      dispatch(lngDealsRefreshComplete(data));
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
      dispatch(lngDealsRefreshStopped());
    });
};

export const lngDealsSave = () => (dispatch, getState) => {
  const state = getState();
  const deals = toJS(state.getIn(['lngDeals', 'deals']));
  const modified = deals.filter(x => x.modified);
  
  if(modified.length > 0) {
    authFetch(`${ETL_API_URL}/lng/deals`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(modified)
    })
    .then(() => {
      dispatch(lngDealsRefresh());
    })
    .catch(error => {
      dispatch(logErrorNotification(error));
    });
  }
};
