import React from 'react';

export function ScenarioFiller({ fillerValues, setFillerValue, updateAllSeries }) {
  const scenario = fillerValues.getIn(['scenario', 'scenario']) ?? 1;
  const complexScenario = fillerValues.getIn(['scenario', 'complexScenario']) ?? 1;

  function onApply(e) {
    e.preventDefault();
    updateAllSeries(['scenario'], scenario);
    updateAllSeries(['complexScenario'], complexScenario);
  }

  function onClear() {
    updateAllSeries(['scenario'], '');
    updateAllSeries(['complexScenario'], '');
  }

  return <form onSubmit={onApply} >
    <div className='modal-content'>
      <div className='modal-body'>
        <div className='form-row pb-1'>
          <label className='font-weight-bold col-sm-3 mt-2'>Scenario</label>
          <div className='col-sm-9'>
            <input data_id='lineWidth' className='form-control' type='text'
              value={scenario} onChange={e => setFillerValue(['scenario', 'scenario'], e.target.value)} />
          </div>
        </div>
        <div className='form-row pb-1'>
          <label className='font-weight-bold col-sm-3 mt-2'>Complex Scenario</label>
          <div className='col-sm-9'>
            <input data_id='lineWidth' className='form-control' type='text'
              value={complexScenario} onChange={e => setFillerValue(['scenario', 'complexScenario'], e.target.value)} />
          </div>
        </div>
      </div>

      <div className='modal-footer'>
        <button data_id='clear' className='btn btn-sm btn-secondary' type='button' data-close-popover onClick={onClear}>Clear</button>
        <button data_id='apply' className='btn btn-sm btn-primary' type='submit' data-close-popover>Apply</button>
      </div>
    </div>
  </form>
}